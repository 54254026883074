import React from "react";
import PropTypes from "prop-types";
import { Header } from "semantic-ui-react";

function PersonalDetails({ entity }) {
  const entityType = entity && entity.entity_type;
  if (entityType !== "Individual") {
    return null;
  }
  return (
    <span>
      <Header as="h3">Personal Details:</Header>
      <p>
        <b>D.O.B.:</b>
        {` ${entity.dob}`}
        <br />
        <b>Driver&rsquo;s No:</b>
        {` ${entity.drivers_license_no}`}
        <br />
        <b>Driver&rsquo;s License St:</b>
        {` ${entity.drivers_license_st}`}
        <br />
        <b>Annual Income:</b>
        {` ${entity.personal_annual_income}`}
        <br />
        <b>US Citizen:</b>
        {` ${entity.is_us_citizen ? "Yes" : "No"}`}
        <br />
        <b>Rent/Own:</b>
        {` ${entity.rent_or_own}`}
        <br />
      </p>
    </span>
  );
}

PersonalDetails.propTypes = {
  entity: PropTypes.shape({
    uuid: PropTypes.string,
    entity_type: PropTypes.string,
    dob: PropTypes.string,
    drivers_license_no: PropTypes.string,
    drivers_license_st: PropTypes.string,
    personal_annual_income: PropTypes.number,
    is_us_citizen: PropTypes.bool,
    rent_or_own: PropTypes.string
  })
};

export default PersonalDetails;
