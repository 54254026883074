import TypeCheck from "typecheck-extended";
import { relationshipTypes } from "../../../../services/Constants/relationshipTypes";

import {
  CreditRequests,
  Entities,
  LoanApp,
  Relationships
} from "../../../../services/ApiLib";

let authToken = "";

function processOwner(rsp, callbacks) {
  callbacks.onSuccess(rsp.data, "personalInfo");
}

function processOwnerRel(rsp, callbacks) {
  TypeCheck(rsp.data[0].child_uuid, "string");
  TypeCheck(callbacks.onError, "function");
  TypeCheck(callbacks.onSuccess, "function");

  const entityUuid = rsp.data[0].child_uuid;
  let queryParams;
  Entities.get(
    authToken,
    processOwner,
    callbacks.onError,
    entityUuid,
    queryParams,
    callbacks
  );
}

function processBorrower(rsp, callbacks) {
  TypeCheck(rsp.data.uuid, "string");

  callbacks.onSuccess(rsp.data, "businessInfo");
  const relationship = {
    parent_uuid: rsp.data.uuid,
    rel_type: relationshipTypes.OWNER
  };
  Relationships.get(
    relationship,
    authToken,
    processOwnerRel,
    callbacks.onError,
    callbacks
  );
}

function processBorrowerRel(rsp, callbacks) {
  TypeCheck(rsp.data[0].child_uuid, "string");
  TypeCheck(callbacks.onError, "function");
  TypeCheck(callbacks.onSuccess, "function");

  const entityUuid = rsp.data[0].child_uuid;
  let queryParams;
  Entities.get(
    authToken,
    processBorrower,
    callbacks.onError,
    entityUuid,
    queryParams,
    callbacks
  );
}

function processLoanRequest(rsp, callbacks) {
  TypeCheck(rsp.data[0].uuid, "string");

  callbacks.onSuccess(rsp.data[0], "loanRequestInfo");
}

function processLoanApp(rsp, callbacks) {
  TypeCheck(rsp.data, "object");
  TypeCheck(callbacks.onError, "function");
  TypeCheck(callbacks.onSuccess, "function");

  callbacks.onSuccess(rsp.data, "loanAppInfo");

  const relationship = {
    parent_uuid: rsp.data.uuid,
    rel_type: relationshipTypes.BORROWER
  };
  Relationships.get(
    relationship,
    authToken,
    processBorrowerRel,
    callbacks.onError,
    callbacks
  );

  const queryParam = { loan_app_uuid: rsp.data.uuid };
  const jwt = authToken;
  const onSuccess = processLoanRequest;
  let uuid;
  CreditRequests.get(
    jwt,
    onSuccess,
    callbacks.onError,
    uuid,
    queryParam,
    callbacks
  );
}

function ResumeApp(jwt, onSuccess, onError, loanAppUuid, callbackData) {
  TypeCheck(jwt, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(loanAppUuid, "string");

  const callbacks = {
    callbackData,
    onError,
    onSuccess
  };

  authToken = jwt;
  LoanApp.get(jwt, processLoanApp, onError, loanAppUuid, callbacks);
}

export {
  processBorrower,
  processBorrowerRel,
  processLoanApp,
  processLoanRequest,
  processOwner,
  processOwnerRel,
  ResumeApp
};
