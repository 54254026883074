import React from "react";
import { Route, Switch } from "react-router-dom";
import DocTitle from "../../components/DocTitle";

import LoanManagementObject from "./scenes/LoanManagement";
import { PrintViewInterface } from "./scenes/LoanManagement/scenes/TransactionHistory";
import SearchLoans from "./scenes/LoanManagement/scenes/SearchLoans";

export function LoanManagementObj() {
  return (
    <div>
      <DocTitle title="Loan Management - BLAST Portal" />
      <Switch>
        <Route
          path="/loan_management/transaction_history/:uuid"
          render={({ match }) => (
            <PrintViewInterface loanUuid={match.params.uuid} />
          )}
        />
        <Route
          path="/loan_management/search_loans"
          render={() => <SearchLoans />}
        />
        <Route
          path="/loan_management/:uuid"
          render={({ match }) => (
            <LoanManagementObject loanUuid={match.params.uuid} />
          )}
        />
      </Switch>
    </div>
  );
}

export default LoanManagementObj;
