export const SideSheetBody = ({ children }) => {
  return (
    <div
      className="grid grid-cols-3 gap-6 pt-6"
      data-testid={editLoanRequestSideSheetBodyTestId}
    >
      {children}
    </div>
  );
};

export const editLoanRequestSideSheetBodyTestId =
  "edit-loan-request-side-sheet-body";
