import React from "react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";

function Layout({ children }) {
  const nodes = React.Children.toArray(children);
  return (
    <Grid divided="vertically">
      <Grid.Row>
        <Grid padded>
          <Grid.Row columns="3">
            <Grid.Column>{nodes[0]}</Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>{nodes[1]}</Grid.Column>
            <Grid.Column>{nodes[2]}</Grid.Column>
            <Grid.Column>{nodes[3]}</Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column width="4">{nodes[4]}</Grid.Column>
            <Grid.Column width="4">{nodes[5]}</Grid.Column>
            <Grid.Column width="8">{nodes[6]}</Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>{nodes[7]}</Grid.Column>
            <Grid.Column>{nodes[8]}</Grid.Column>
            <Grid.Column>{nodes[9]}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Row>
      <Grid.Row>
        <Grid padded>
          <Grid.Row columns="2">
            <Grid.Column>{nodes[10]}</Grid.Column>
          </Grid.Row>
          <Grid.Row columns="2">
            <Grid.Column>{nodes[11]}</Grid.Column>
            <Grid.Column>{nodes[12]}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>{nodes.slice(13)}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Row>
    </Grid>
  );
}

Layout.propTypes = {
  children: PropTypes.node
};

export default Layout;
