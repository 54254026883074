import React from "react";
import { Route } from "react-router-dom";
import DocTitle from "../../components/DocTitle";
import CurrentRequests from "./scenes";

function PPPCurrentRequestObj() {
  return (
    <div>
      <DocTitle title="PPP Current Requests - BLAST Portal">
        <Route
          exact
          path="/ppp-applications"
          render={() => <CurrentRequests source="PPP" />}
        />
      </DocTitle>
    </div>
  );
}

export default PPPCurrentRequestObj;
