import React, { useState, useEffect, useRef } from "react";
import { Container, Segment, Message } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import SearchInput from "../../../../../../components/CustomFormElements/components/SearchInput";
import { Loans } from "../../../../../../services/ApiLib";
import PaginationControls from "../../../../../../components/Pagination";
import ResultsTable from "./components/resultsTable";
import Constant from "../../../../../../services/Constants/strings";

function SearchLoans() {
  const USER_TYPE_INTERRUPT = "userTypeInterrupt";
  const paginationName = "SearchLoans";
  const previousController = useRef();
  const pagination = useSelector(
    state => state.PaginationReducer[paginationName]
  );
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [loans, setLoans] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lastPage, setLastPage] = useState(true);
  const ifNumber = inputValue => /^[0-9,-]*$/.test(inputValue);

  const handleChange = e => {
    const inputValue = e.target.value;
    if (previousController.current) {
      previousController.current.abort(USER_TYPE_INTERRUPT);
    }
    if (ifNumber(inputValue)) {
      setValue(inputValue);
      dispatch({
        type: "PAGINATION_UPDATE_NUMBER",
        number: 0,
        name: paginationName
      });
    } else {
      setError({ title: "", message: "Loan search is numeric only." });
      e.preventDefault();
    }
  };
  useEffect(() => {
    if (value.length >= Constant.minInputValueCount) {
      setError(null);
      previousController.current = new AbortController();
      const { signal } = previousController.current;
      const getLoans = setTimeout(() => {
        setLoading(true);
        Loans.searchLoans(
          {
            direction: "next",
            lookup: value
          },
          signal,
          pagination
        )
          .then(({ data, metaData }) => {
            setLoans(data);
            setLoading(false);
            setLastPage(metaData.last_page);
          })
          .catch(() => {
            if (signal.aborted && signal.reason === USER_TYPE_INTERRUPT) return;
            setError({
              title: "Network Error",
              message: "Unable to fetch loans"
            });
            setLoading(false);
          });
      }, 300);
      return () => clearTimeout(getLoans);
    }
    setLoading(false);
    return () => setLoading(false);
  }, [pagination, value]);
  return (
    <>
      <h1 className="mb-6">Loan Management</h1>

      <Container className="leftAlign" fluid textAlign="left">
        {error && (
          <Message error header={error.title} content={error.message} />
        )}
        <Segment>
          <SearchInput
            handleChange={handleChange}
            value={value}
            asForm={false}
            loading={loading}
            fluid
            placeholder="Search by account no."
          />
        </Segment>
      </Container>
      <Container
        style={{ marginTop: "1rem" }}
        className="leftAlign"
        fluid
        textAlign="left"
      >
        {loans && loans.length !== 0 && (
          <ResultsTable data-testid="results-table" loans={loans} />
        )}
        {!loans && (
          <Segment
            className="flex items-center justify-center"
            data-testid="message"
          >
            Search for loans using account no.
          </Segment>
        )}
        {loans && loans.length === 0 && (
          <Segment
            className="flex items-center justify-center"
            data-testid="message"
          >
            No loans found
          </Segment>
        )}
      </Container>
      <Segment>
        <PaginationControls name={paginationName} lastPage={lastPage} />
      </Segment>
    </>
  );
}
export default SearchLoans;
