import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { CollateralDocuments } from "../../../../../../components/CollateralDocuments";
import DragAndDrop from "../../../../../../components/DragAndDrop";
import entityDataProps from "../../../../../../services/PropTypes/entityData";

function AccountDocuments({
  loanUuid,
  entityData,
  data,
  onViewCreditAccountDocuments,
  loanBpOrganizationUuid
}) {
  const institutionUuid = entityData.institution_uuid;
  useEffect(() => {
    if (loanUuid && institutionUuid) {
      onViewCreditAccountDocuments({
        loanUuid,
        institutionUuid
      });
    }
  }, [loanUuid, institutionUuid, onViewCreditAccountDocuments]);

  return (
    <>
      <DragAndDrop
        entityUuid={entityData.uuid}
        docCategory="Loan"
        institutionUuid={entityData.institution_uuid}
        onUploadSuccess={() => {
          onViewCreditAccountDocuments({ loanUuid, institutionUuid });
        }}
        parentRelType="Loan"
        parentUuid={loanUuid}
        bpOrganizationUuid={loanBpOrganizationUuid}
      />
      <CollateralDocuments
        data={data}
        type="LOAN_MANAGEMENT/UPDATE_ACCOUNT_DOCUMENTS"
        institutionUuid={entityData.institution_uuid}
        managementType="Loan"
      />
    </>
  );
}

AccountDocuments.propTypes = {
  loanUuid: PropTypes.string.isRequired,
  entityData: entityDataProps.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onViewCreditAccountDocuments: PropTypes.func,
  loanBpOrganizationUuid: PropTypes.string
};

export default AccountDocuments;
