/* eslint-disable camelcase */
import createAPIService from "../../../FetchService/createAPIService";
import APIEndpoints from "../../../ApiEndpoints";
import mockFees from "./mock-fees";

const feesService = createAPIService({
  baseUrl: APIEndpoints.baseUri,
  create: APIEndpoints.fees,
  retrieve: [APIEndpoints.fees, APIEndpoints.fee],
  update: APIEndpoints.fee,
  delete: APIEndpoints.fee,
  options: { pagination: true }
});

/** TODO:
 * The FEES Service does not exist as far as I know, so it is mocked below until there is
 * a working endpoint to hit
 */
mockFees(feesService);

export default feesService;
