import React, { useEffect, useState } from "react";
import {
  useInterestScheduleQuery,
  InterestScheduleParameters
} from "../../../../../../services/RTKQuery/interestSchedules";

export const ReadSingleTest = ({
  scheduleUuid,
  loanUuid,
  setStatusInParent
}) => {
  const statusUnknown = "❓Unknown Status";
  const statusPass = "✅ Pass";
  const statusFail = "❌ Fail";
  const statusLoading = "Loading ...";

  const [statusReadSingle, setStatus] = useState(statusUnknown);
  const [detailsText, setDetailsText] = useState("");

  const options: InterestScheduleParameters = {
    uuid: scheduleUuid,
    loanUuid: loanUuid
  };
  const { data: scheduleData } = useInterestScheduleQuery(options);
  useEffect(() => {
    setStatus(statusLoading);
    if (scheduleData?.uuid && scheduleUuid === scheduleData?.uuid) {
      setStatus(statusPass);
      setDetailsText(`:  Matched record on: ${scheduleData?.uuid}`);
      setStatusInParent("Pass");
    } else {
      console.error("Fail: useInterestScheduleQuery");
      setStatus(statusFail);
    }
  }, [scheduleData?.uuid, scheduleUuid, setStatusInParent]);

  return (
    <>
      <b>{statusReadSingle}</b>
      <small>{detailsText}</small>
    </>
  );
};
