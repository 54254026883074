import TypeCheck from "typecheck-extended";
import ApiEndpoints from "../../../ApiEndpoints";
import FetchService from "../../../FetchService";
import { FormatUrlV2 } from "../../../FormatUrl";
import { ConcatQueryParams, RemoveNullKeys } from "../Utilities";

function get(onSuccess, onError, uuid, queryParams, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string", false);
  TypeCheck(queryParams, "object", false);
  TypeCheck(callbackData, "object", false);

  let params = "";
  if (queryParams) {
    params = ConcatQueryParams(queryParams);
  }

  let url = ApiEndpoints.baseUri + ApiEndpoints.loan5300Codes + params;
  if (uuid) {
    url = FormatUrlV2(ApiEndpoints.loan5300Code, { uuid }) + params;
  }

  FetchService("GET", url, onSuccess, onError, null, callbackData);
}

function add(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  const url = ApiEndpoints.baseUri + ApiEndpoints.loan5300CodePost;
  const convertedBody = RemoveNullKeys(body);
  FetchService("POST", url, onSuccess, onError, convertedBody, callbackData);
}

export default {
  get,
  add
};
