import React from "react";
import PropTypes from "prop-types";
import { Divider, Segment } from "semantic-ui-react";
import SoldLoanInformationTable from "./tables/SoldLoanInformationTable";
import SoldLoanParticipantsTable from "./tables/SoldLoanParticipantsTable";

function ParticipationSold({
  loan,
  institutionMap,
  dispatch,
  retainedAccount,
  participationIsSetup,
  participantData,
  participationBuyersToBe
}) {
  return (
    <Segment>
      <SoldLoanParticipantsTable
        retainedAccount={retainedAccount}
        institutionMap={institutionMap}
        participationIsSetup={participationIsSetup}
        dispatch={dispatch}
        participantData={
          participationIsSetup ? participantData : participationBuyersToBe
        }
      />
      <Divider />
      <SoldLoanInformationTable
        participationIsSetup={participationIsSetup}
        retainedAccount={retainedAccount}
        loan={loan}
        participantData={
          participationIsSetup ? participantData : participationBuyersToBe
        }
      />
    </Segment>
  );
}

ParticipationSold.propTypes = {
  dispatch: PropTypes.func,
  loan: PropTypes.shape({
    uuid: PropTypes.string,
    institution_uuid: PropTypes.string,
    participation_bought: PropTypes.bool,
    participation_sold: PropTypes.bool
  }).isRequired,
  institutionMap: PropTypes.any,
  retainedAccount: PropTypes.any,
  participationIsSetup: PropTypes.bool,
  participantData: PropTypes.any,
  participationBuyersToBe: PropTypes.any
};

export default ParticipationSold;
