import React from "react";
import PropTypes from "prop-types";
import { Form, Grid, Dropdown } from "semantic-ui-react";
import {
  DateInput,
  DollarField
} from "../../../../../../components/CustomFormElements";
import { EditModal } from "../../../../../../components/Modals";
import { depositFeeOptions } from "../../../../../../services/Constants/additionalFeeOptions";

const defaultSettlementItem = {
  reason_deposits: depositFeeOptions[0].value
};

function AddDepositModal({
  defaultValues = defaultSettlementItem,
  header = "",
  ...props
}) {
  return (
    <EditModal
      header={header}
      size="small"
      defaultValues={defaultValues}
      required={{
        amount: {},
        disbursement_date: {},
        reason_deposits: {
          condition: "Other",
          required: ["reason_other"]
        }
      }}
      {...props}
    >
      {({ onChange, values }) => {
        const isOther = values.reason_deposits === "Other";
        return (
          <Grid padded basic="true">
            <Grid.Row>
              <Grid.Column>
                <DateInput
                  asForm
                  label="Deposit Date"
                  name="disbursement_date"
                  onChange={(ev, { name, value }) => onChange({ name, value })}
                  value={values.disbursement_date}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <DollarField
                  label="Deposit Amount"
                  name="amount"
                  onChange={onChange}
                  value={values.amount}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form.Field
                  control={Dropdown}
                  label="Deposit Reasons"
                  name="reason_deposits"
                  options={depositFeeOptions}
                  value={values.reason_deposits}
                  selection
                  onChange={(ev, { name, value }) => onChange({ name, value })}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form.Input
                  label="Other Reason"
                  name="reason_other"
                  disabled={!isOther}
                  value={values.reason_other || ""}
                  onChange={(ev, { name, value }) => onChange({ name, value })}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        );
      }}
    </EditModal>
  );
}

AddDepositModal.propTypes = {
  defaultValues: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ),
  header: PropTypes.string
};

export default AddDepositModal;
