import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Dropdown } from "semantic-ui-react";
import Constants from "../../../../../../../services/Constants/strings";

function HeaderInputs({
  assignUser,
  assignedUser,
  hasAppManagement,
  loanApp,
  makeFinalizationModal,
  onPhaseChange,
  options,
  userUuid
}) {
  const [input, setInput] = useState(null);

  const {
    phase,
    status,
    assigned_to_user: assignedToUser,
    institution_contact: institutionContact
  } = loanApp;

  useEffect(() => {
    const userIsAssigned =
      assignedToUser === userUuid || institutionContact === userUuid;
    const userCanChangePhase = userIsAssigned && hasAppManagement;
    const loanIsReadyForFinalization =
      userCanChangePhase && phase === Constants.PHASE_CREDIT_ADMIN;
    const loanIsReadyForCompletion =
      userCanChangePhase &&
      phase === Constants.PHASE_CREDIT_ADMIN &&
      status === "Boarded";
    if (loanIsReadyForCompletion) {
      setInput(
        <Button
          primary
          value="Complete"
          onClick={onPhaseChange}
          content="Complete Loan"
        />
      );
    } else if (loanIsReadyForFinalization) {
      setInput(
        <Button
          primary
          onClick={makeFinalizationModal}
          content="Finalize Loan"
        />
      );
    } else if (userCanChangePhase) {
      setInput(
        <Dropdown
          name="phasesSelect"
          id="phasesSelect"
          data-testid="phase-dropdown"
          value={phase || "Initial Review"}
          options={options}
          selection
          onChange={onPhaseChange}
        />
      );
    } else if (hasAppManagement) {
      setInput(<Button primary content="Assign to Me" onClick={assignUser} />);
    }
  }, [
    assignUser,
    assignedToUser,
    hasAppManagement,
    institutionContact,
    makeFinalizationModal,
    onPhaseChange,
    options,
    phase,
    status,
    userUuid
  ]);

  return (
    <>
      {assignedUser ? (
        <>
          <b>Assigned to:</b> {assignedUser}
          {phase === "Complete" ? "Loan Completed" : null}
        </>
      ) : (
        "Unassigned"
      )}
      <br />
      {input}
    </>
  );
}

HeaderInputs.propTypes = {
  assignUser: PropTypes.func.isRequired,
  assignedUser: PropTypes.string,
  hasAppManagement: PropTypes.bool,
  loanApp: PropTypes.shape(),
  makeFinalizationModal: PropTypes.func,
  onPhaseChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  userUuid: PropTypes.string
};

export default HeaderInputs;
