import { shape, number, string, bool } from "prop-types";

export default shape({
  annual_revenue: number,
  business_start_date: string,
  company_name: string,
  county_of_reg: string,
  credit_score_equifax: number,
  credit_score_experian: number,
  credit_score_transunion: number,
  dob: string,
  drivers_license_no: string,
  drivers_license_st: string,
  duns_number: string,
  email: string,
  entity_type: string,
  fico_sbss: number,
  first_name: string,
  fiscal_year_end: string,
  industry_experience: number,
  industry_type: string,
  institution_id: number,
  is_operating_entity: bool,
  is_us_citizen: bool,
  last_name: string,
  mail_address_city: string,
  mail_address_country: string,
  mail_address_line1: string,
  mail_address_line2: string,
  mail_address_state: string,
  mail_address_zip: string,
  member_since: string,
  middle_name: string,
  mobile_phone_no: string,
  naics_code: number,
  next_review_date: string,
  officer: string,
  personal_annual_income: number,
  phone_no: string,
  phy_address_city: string,
  phy_address_country: string,
  phy_address_line1: string,
  phy_address_line2: string,
  phy_address_state: string,
  phy_address_zip: string,
  previous_review_date: string,
  rent_or_own: string,
  risk_rating: number,
  state_of_reg: string,
  tax_reporting_form: string,
  tin_type: string,
  tin: string,
  uuid: string,
  website: string,
  years_in_business: number
});
