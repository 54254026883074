import React, { useState } from "react";
import { Modal, Button } from "semantic-ui-react";
import PropTypes from "prop-types";

import EntitySearch from "../../../../../components/EntitySearch";

function AddRelatedDetailsModal({
  open = "",
  institutionUuid,
  onCancel,
  onAddRelatedDetail
}) {
  const [individualEntity, setIndividualEntity] = useState();
  const [businessEntity, setBusinessEntity] = useState();
  const [errorMsg, setErrorMsg] = useState();
  return (
    <Modal open={!!open} size="small">
      <Modal.Header>Add Related Details</Modal.Header>
      <Modal.Content>
        <b>Search Individual Entity</b>
        <br />
        {!errorMsg && institutionUuid ? (
          <EntitySearch
            onError={setErrorMsg}
            entityType="Individual"
            selectedEntityCallback={setIndividualEntity}
            overrideInstitution={institutionUuid}
            institutionUuid={institutionUuid}
          />
        ) : (
          <div style={{ color: "red" }}>{errorMsg}</div>
        )}

        <b>Search Business Entity</b>
        <br />
        {!errorMsg && institutionUuid ? (
          <EntitySearch
            onError={setErrorMsg}
            entityType="Business"
            selectedEntityCallback={setBusinessEntity}
            overrideInstitution={institutionUuid}
            institutionUuid={institutionUuid}
          />
        ) : (
          <div style={{ color: "red" }}>{errorMsg}</div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button id="cancelButton" color="grey" onClick={() => onCancel(null)}>
          Cancel
        </Button>
        <Button
          inverted={!individualEntity || !businessEntity || errorMsg}
          color="green"
          disabled={!individualEntity || !businessEntity || errorMsg}
          onClick={() => {
            onAddRelatedDetail({ individualEntity, businessEntity });
            setIndividualEntity(null);
            setBusinessEntity(null);
            onCancel(null);
          }}
        >
          Add Related Details
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

AddRelatedDetailsModal.propTypes = {
  open: PropTypes.string,
  institutionUuid: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onAddRelatedDetail: PropTypes.func.isRequired
};

export default AddRelatedDetailsModal;
