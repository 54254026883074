import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HighFrictionDialog, IconButton } from "@bafsllc/ui-shared";
import { Entities } from "../../../../../../services/ApiLib";
import { permCheck } from "../../../../../../services/Auth";

function BpAccessBlock() {
  const dispatch = useDispatch();
  const { entityData, entityInstitution, bpAccessControls } = useSelector(
    ({ CreditManagementReducer }) => CreditManagementReducer
  );
  const [showModal, setShowModal] = useState(false);

  const readPerms = permCheck([
    "admin",
    "get_bp_access_controls",
    "get_bp_access_controls_unfiltered"
  ]);
  const updatePerms = permCheck([
    "admin",
    "put_bp_access_controls",
    "put_bp_access_controls_unfiltered"
  ]);
  const hasBorrowerPortalService =
    entityInstitution?.borrower_portal_service || false;

  const getBpAccessControls = useCallback(() => {
    if (entityData?.uuid && (hasBorrowerPortalService || readPerms)) {
      Entities.bpAccessControlsGetByRecordUuid(entityData?.uuid).then(
        response => {
          dispatch({
            type: "CMS_BP_ACCESS_CONTROLS",
            bpAccessControls: response
          });
        }
      );
    }
  }, [dispatch, entityData?.uuid, hasBorrowerPortalService, readPerms]);

  const updateBpAccessControlsEditableByBorrower = () => {
    Entities.bpAccessControlsUpdateEditableByBorrower(
      bpAccessControls?.uuid,
      entityData?.uuid,
      entityInstitution?.uuid,
      false
    ).then(() => {
      setShowModal(false);
      getBpAccessControls();
    });
  };

  useEffect(() => {
    getBpAccessControls();
  }, [getBpAccessControls]);

  return hasBorrowerPortalService && readPerms ? (
    <>
      <div className="flex items-center justify-end">
        <IconButton
          disabled={!updatePerms || !bpAccessControls?.editable_by_borrower}
          message={{ id: "BORROWER_EDIT" }}
          iconLeft={{
            icon: bpAccessControls?.editable_by_borrower ? "unlock" : "lock"
          }}
          size="tiny"
          variant="secondary"
          onClick={() => {
            setShowModal(true);
          }}
        />
      </div>

      {showModal && (
        <HighFrictionDialog
          affirmative={{ id: "CONFIRM" }}
          negative={{ id: "CANCEL" }}
          header={{ id: "BP_ORGANIZATION" }}
          confirmationMessages={[
            { message: { id: "BORROWER_EDIT_CONF_MSG" } },
            { message: { id: "FRICTION_DIALOG_ENTER_MESSAGE" } }
          ]}
          onHidden={() => {}}
          onDismiss={dismissVariant => {
            if (dismissVariant === "affirmative") {
              updateBpAccessControlsEditableByBorrower();
            }
          }}
        />
      )}
    </>
  ) : null;
}

export default BpAccessBlock;
