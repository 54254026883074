import { Loan } from "./components/Loan";
import { LoanAppSettlementSheetStatus } from "./components/LoanAppSettlementSheetStatus";
import { EntitiesStatus } from "./components/EntitiesStatus";
import { InterestSchedulesStatus } from "./components/InterestSchedulesStatus";
import { PaymentScheduleStatus } from "./components/PaymentScheduleStatus";
import { l1Wrapper, l2Wrapper } from "../../appStatusStyles";

const RTKStatus = () => {
  return (
    <div style={l1Wrapper}>
      <h2>RTK Query Libraries:</h2>
      <div style={l2Wrapper}>
        <Loan />
        <LoanAppSettlementSheetStatus />
        <EntitiesStatus />
        <InterestSchedulesStatus />
        <PaymentScheduleStatus />
      </div>
    </div>
  );
};

export default RTKStatus;
