import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  DollarCell,
  PropertyTable,
  DateCell
} from "../../../../../../../../../../components/Tables";

function PartialPaymentTable({ loan }) {
  const rows = useMemo(
    () => [
      {
        Header: "Partial Payment Date",
        accessor: "partial_payment_date",
        Cell: DateCell
      },
      {
        Header: "Partial Payment Amount",
        accessor: "partial_payment_amount",
        Cell: DollarCell
      },
      {
        Header: "Pay Ahead Balance",
        accessor: "pay_ahead_balance",
        Cell: DollarCell
      }
    ],
    []
  );

  return <PropertyTable columnsConfig={rows} data={loan} />;
}

PartialPaymentTable.propTypes = {
  loan: PropTypes.shape({
    prev_payment_date: PropTypes.string,
    partial_payment_amount: PropTypes.number
  }).isRequired
};

export default PartialPaymentTable;
