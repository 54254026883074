import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  DollarCell,
  PropertyTable
} from "../../../../../../../../../../components/Tables";

function AveragesTable({ loan }) {
  const rows = useMemo(
    () => [
      {
        Header: "Average Current Balance Month-to-Date",
        accessor: "average_daily_balance_mtd",
        Cell: DollarCell
      }
    ],
    []
  );

  return <PropertyTable columnsConfig={rows} data={{ ...loan }} />;
}

AveragesTable.propTypes = {
  loan: PropTypes.shape({
    last_advance_date: PropTypes.string
  }).isRequired
};

export default AveragesTable;
