export const handleDownload = ({ dataArray, fileType, name, type }) => {
  const newBlob = new Blob(dataArray, { type: fileType || "application/pdf" });
  const data = window.URL.createObjectURL(newBlob);
  if (type === "preview") {
    window.open(data);
  } else {
    const link = document.createElement("a");
    link.href = data;
    link.download = name || "File";
    link.click();
  }
};

const downloadFile = ({ response, callbackData, type }) => {
  if (
    !response.status === 200 ||
    !response.status === 201 ||
    !response.status === 204
  ) {
    throw response;
  }
  const { fileName: name, fileType } = callbackData;
  const reader = response.body.getReader();
  return new ReadableStream({
    start(controller) {
      // The following function handles each data chunk
      function push(dataArray = []) {
        // "done" is a Boolean and value a "Uint8Array" a.k.a chunks of data
        reader.read().then(({ done, value }) => {
          // Is there no more data to read it downloads the file
          if (done) {
            // Tell the browser that we have finished sending data
            controller.close();
            handleDownload({
              dataArray,
              fileType,
              name,
              type
            });
            return;
          }
          dataArray.push(value);
          // Get the data and send it to the browser via the controller
          controller.enqueue(value);
          push(dataArray);
        });
      }
      push();
    }
  });
};
export default downloadFile;
