import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useLoanApplicationGuarantors } from "../../../../../../../../store/models/loan-applications";
import GuarantorComponent from "../../../../../../components/Guarantors/Guarantors";

function Guarantors({ loanAppUuid, readOnly = false }) {
  const [
    {
      owners,
      ownerGuarantors,
      thirdPartyGuarantors,
      loanRequests,
      institutionUuid
    },
    { setIsGuarantor, updateGuarantor }
  ] = useLoanApplicationGuarantors({ loanAppUuid });

  const handleGuaratorTypeChange = useCallback(
    (guarantor, value) => {
      if (value === "Full") {
        updateGuarantor(
          {
            full_vs_limited: value,
            percent_guaranteed: 1,
            amount_or_percent: "percent"
          },
          guarantor
        );
      } else if (value === "Limited") {
        updateGuarantor(
          {
            full_vs_limited: value,
            amount_or_percent: "percent",
            percent_guaranteed: 0,
            amount_guaranteed: 0
          },
          guarantor
        );
      }
    },
    [updateGuarantor]
  );

  const handleAmountTypeChange = useCallback(
    (guarantor, value) => {
      updateGuarantor({ amount_or_percent: value }, guarantor);
    },
    [updateGuarantor]
  );
  const handleAmountChange = useCallback(
    (guarantor, value) => {
      if (guarantor.amount_or_percent === "percent") {
        updateGuarantor({ percent_guaranteed: value }, guarantor);
      } else if (guarantor.amount_or_percent === "amount") {
        updateGuarantor({ amount_guaranteed: value }, guarantor);
      }
    },
    [updateGuarantor]
  );

  const handleAddThirdPartyGuarantor = useCallback(
    (entityUuid, loanRequest) => {
      setIsGuarantor(
        {
          entity: { uuid: entityUuid, institution_uuid: institutionUuid },
          loanRequestUuid: loanRequest.uuid
        },
        true
      );
    },
    [setIsGuarantor, institutionUuid]
  );

  return (
    <GuarantorComponent
      loanRequests={loanRequests}
      owners={owners}
      ownerGuarantors={ownerGuarantors}
      thirdPartyGuarantors={thirdPartyGuarantors}
      institutionUuid={institutionUuid}
      readOnly={readOnly}
      onIsGuarantorChange={setIsGuarantor}
      onGuarantorTypeChange={handleGuaratorTypeChange}
      onAmountTypeChange={handleAmountTypeChange}
      onAmountChange={handleAmountChange}
      onAddThirdPartyGuarantor={handleAddThirdPartyGuarantor}
    />
  );
}

Guarantors.propTypes = {
  loanAppUuid: PropTypes.string,
  readOnly: PropTypes.bool
};

export default Guarantors;
