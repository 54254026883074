import { MessageProps } from "../../../../types/text";
import { FormattedMessage } from "react-intl";
import { IconWithRef } from "../../../elements/icon/icon";
import { Dropdown } from "../dropdown/dropdown";
import { DropdownProps } from "../dropdown/dropdown";

/**This component renders a dropdown with the `select` variant. It renders a x icon that
 *  allows you to reset the input and clear it's value.*/
export function ClearableDropdown({
  children,
  onChildSelect,
  onClear,
  selectedKey,
  selectedValue,
  placeholder = { defaultMessage: "Select an Option" },
  ...props
}: ClearableDropdownProps) {
  const formattedPlaceholder =
    "id" in placeholder ? (
      <FormattedMessage {...placeholder} />
    ) : (
      placeholder.defaultMessage
    );

  const formattedSelectedValue =
    selectedValue &&
    ("id" in selectedValue ? (
      <FormattedMessage {...selectedValue} />
    ) : (
      selectedValue.defaultMessage
    ));

  const ClearableDropdownControl = () => (
    <>
      <div className={`${formattedSelectedValue ? "" : "default"} text`}>
        {formattedSelectedValue || formattedPlaceholder}
      </div>
      <IconWithRef icon="dropdown" />
      <IconWithRef
        data-testid="clear"
        icon="remove"
        onIconSelect={e => {
          e.stopPropagation();
          onClear();
        }}
      />
    </>
  );
  const dropdownProps: DropdownProps = {
    ...props,
    selectedKey: selectedKey,
    variant: "select",
    control: <ClearableDropdownControl />,
    updateDropdownClassnames: classNames => [
      ...classNames,
      "clearable",
      "selection"
    ],
    children,
    onChildSelect
  };

  return <Dropdown {...dropdownProps} />;
}

export interface ClearableDropdownProps extends Omit<DropdownProps, "control"> {
  /**This function determines what happens when the 'x' icon is clicked*/
  onClear: () => void;
  /**Determines the UUID selected */
  selectedKey?: string;
  /**Displays the value of the dropdown. The type of this property is determined by the component consuming the `ClearableDropdown`*/
  selectedValue: MessageProps | null;
  /**Determines the text that will be visible on the control when no option is selected*/
  placeholder?: MessageProps;
}
