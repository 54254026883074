import createAPIService from "../../FetchService/createAPIService";
import APIEndpoints from "../../ApiEndpoints";

export default createAPIService({
  baseUrl: APIEndpoints.baseUri,
  create: APIEndpoints.loanPaymentSchedules,
  retrieve: [
    APIEndpoints.loanPaymentSchedules,
    APIEndpoints.loanPaymentSchedule
  ],
  update: APIEndpoints.loanPaymentSchedule,
  delete: APIEndpoints.loanPaymentSchedule,
  options: { pagination: true }
});
