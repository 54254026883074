const bestTemplateMatch = (urlTemplates, identifiers) => {
  const templateScorePairs = urlTemplates.map(template => {
    const matches = template.match(/{([\s\S]+?)}/g) || [];
    const keys = matches.map(match => match.replace(/{|}/g, ""));
    const neededSet = new Set(keys); // dedupe
    const score = [...neededSet].filter(id => id in identifiers).length;
    return [template, neededSet.size, score];
  });
  templateScorePairs.sort(
    ([, aNumNeeded], [, bNumNeeded]) => aNumNeeded - bNumNeeded
  );
  templateScorePairs.sort(([, , aScore], [, , bScore]) => bScore - aScore);
  return templateScorePairs[0][0];
};

const buildURL = (
  urlTemplates,
  baseUrl,
  identifiers = {},
  meta = {},
  { pagination = false } = {}
) => {
  const urlTemplate = bestTemplateMatch(urlTemplates, identifiers);
  const matches = urlTemplate.match(/{([\s\S]+?)}/g) || [];
  const idents = { ...identifiers };
  const path = matches.reduce((template, match) => {
    const key = match.replace(/{|}/g, "");
    if (!(key in identifiers)) {
      throw Error(
        `URL Template required ${key} property to be passed as an identifier`
      );
    }
    delete idents[key];
    return template.replace(match, identifiers[key]);
  }, urlTemplate);
  const url = new URL(path, baseUrl);
  const search = new URLSearchParams();

  Object.entries(idents).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(currValue => {
        search.append(key, currValue);
      });
    } else {
      search.append(key, value);
    }
  });

  [...search].forEach(([key, value]) => url.searchParams.append(key, value));
  if (pagination && meta.pagination) {
    ["page_number", "page_size"].forEach(prop => {
      if (
        meta.pagination[prop] !== null &&
        meta.pagination[prop] !== undefined
      ) {
        url.searchParams.set(prop, meta.pagination[prop]);
      }
    });
  }
  return `${url}`;
};

export const findAmbiguousTemplates = templates => {
  const templateAndMatcher = templates.map(template => {
    const matches = template.match(/{([\s\S]+?)}/g) || [];
    const keys = matches.map(match => match.replace(/{|}/g, "").trim());
    const deduped = [...new Set(keys)];
    deduped.sort();
    return [template, deduped.join()];
  });
  // duplicate matches
  const ambiguousMatchers = [
    ...new Set(
      templateAndMatcher
        .map(([, matcher]) => matcher)
        .filter((v, i, l) => l.indexOf(v) !== i)
    )
  ];
  return templateAndMatcher
    .filter(([, matcher]) => ambiguousMatchers.includes(matcher))
    .map(([template]) => template);
};

export default buildURL;
