import { ToastVariant } from "../../../../fomantic/modules/toast/toast-box-display/toast-box-display";
import React, { useRef, useState } from "react";
import { DownloadBlobs } from "../../../../custom/collections/document-preview/blobs/download-blobs";
import { Button } from "../button/button";
import { MessageProps } from "../../../../types/text";

/**
 * A download button that is wrapped by a form which makes a post call to the url provided
 * allowing automatic (non-programmatic) download of a file.
 */
export function DownloadButton({
  children,
  className,
  errorAction,
  url,
  method = "POST",
  token,
  filename,
  ...props
}: React.PropsWithChildren<DownloadButtonProps>) {
  const [downloadBlob, setDownloadBlob] = useState<Blob>();
  const [isLoading, setIsLoading] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  async function download() {
    setIsLoading(true);
    setDownloadBlob(undefined);

    try {
      const response = await fetch(url, {
        method,
        headers: {
          authorization: token,
          accept: "application/octet-stream"
        }
      });
      setIsLoading(false);
      setDownloadBlob(await response.blob());
    } catch (ex) {
      setIsLoading(false);
      if (errorAction) {
        errorAction("error");
      }
    }
  }

  return (
    <>
      {downloadBlob && (
        <DownloadBlobs blob={downloadBlob} fileName={filename} />
      )}
      <Button
        {...props}
        ref={buttonRef}
        onClick={download}
        disabled={isLoading}
        size="large"
        updateClassNames={classes => [...classes, "fill"]}
      >
        {children}
      </Button>
    </>
  );
}

export interface DownloadButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  url: string;
  method?: string;
  errorAction?: (variant: ToastVariant) => void;
  token: string;
  filename: string;
  message: MessageProps;
}
