import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Form, Segment } from "semantic-ui-react";
import moment from "moment";
import { DateInput } from "../../../../../../../../components/CustomFormElements";
import { EditModal } from "../../../../../../../../components/Modals";

const typeOptions = [
  { value: "Premium", text: "Premium" },
  { value: "Discount", text: "Discount" }
];
const calculationOptions = [
  { value: "Average Life Method", text: "Average Life Method" }
];

const defaultValues = {
  category: "Premium",
  premium_discount_starting_amount: undefined,
  amortization_start_date: "",
  amortization_end_date: undefined,
  status: "Active"
};

const calculateAmortizationEndDate = (startDate, months) => {
  if (startDate && months) {
    const endDate = moment(startDate).add(months, "months");
    return endDate.format("YYYY-MM-DD");
  }
  return "";
};

function PremiumDiscountAddModal({ onSave }) {
  const [duration, setDuration] = useState("3");

  return (
    <EditModal
      onClose={() => {}}
      onSave={onSave}
      header="Premium/Discount"
      size="small"
      defaultValues={defaultValues}
      defaultOpen
    >
      {({ onChange, values = {} }) => (
        <Segment>
          <Grid columns="two">
            <Grid.Row>
              <Grid.Column>
                <Form.Select
                  onChange={(ev, data) => onChange(data)}
                  name="category"
                  label="Type"
                  options={typeOptions}
                  value={values.category}
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Input
                  onChange={ev => onChange(ev.target)}
                  name="premium_discount_starting_amount"
                  label="Premium/Discount Amount"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form.Select
                  options={calculationOptions}
                  onChange={(ev, data) => onChange(data)}
                  label="Amortization Calculation Method"
                  value="Average Life Method"
                  disabled
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Input
                  onChange={ev => {
                    setDuration(ev.target.value);
                    onChange({
                      name: "amortization_end_date",
                      value: calculateAmortizationEndDate(
                        values.amortization_start_date,
                        ev.target.value
                      )
                    });
                  }}
                  label="Amortization Life Method Length (months)"
                  name="amortization_duration"
                  value={duration}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <DateInput
                  asForm
                  name="amortization_start_date"
                  label="Amortization Start Date"
                  value={values.amortization_start_date}
                  onChange={(e, { name, value }) => {
                    onChange({
                      name: "amortization_end_date",
                      value: calculateAmortizationEndDate(
                        e.target.value,
                        duration
                      )
                    });
                    onChange({ name, value });
                  }}
                  min={new Date()}
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Input
                  name="amortization_end_date"
                  label="Amortization End Date"
                  type="date"
                  value={values.amortization_end_date}
                  disabled
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Form.Input type="hidden" name="status" value="Active" />
        </Segment>
      )}
    </EditModal>
  );
}

PremiumDiscountAddModal.propTypes = {
  onSave: PropTypes.func
};

export default PremiumDiscountAddModal;
