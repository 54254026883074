export default function feeReadableReason(fee) {
  const reason = fee?.bill_to_borrower_reason;
  const reasonOther = fee?.bill_to_borrower_reason_other;

  if (reason === "Other") {
    let readableReasonOther = "Other: ";

    if (reasonOther?.length > 10) {
      readableReasonOther += `${reasonOther.substring(0, 10)}...`;
    } else {
      readableReasonOther += `${reasonOther}`;
    }

    return readableReasonOther;
  }

  return reason;
}
