import React from "react";
import PropTypes from "prop-types";
import { Feed } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { prettyDate } from "../../../../services/DateTime";
import { useUserName } from "../../../../services/Hooks";

const regex = /(<([^>]+)>)/gi;
export const removeHtmlTag = message => {
  const replaceTags = message.replace(regex, "");
  const replaceUrl = replaceTags.replace(/(?:https?|ftp):\/\/[\n\S]+/g, "");
  return replaceUrl;
};
function getMessageWithUserName(msg, userName) {
  return msg.message.replace("USER_NAME", userName);
}

function InlineLink({ msg }) {
  return (
    <>
      <span>{removeHtmlTag(msg.message)}</span>
      {regex.exec(msg.message) !== null && (
        <Link to={`/los/${msg.parent_uuid}/phases`}> Application</Link>
      )}
    </>
  );
}

InlineLink.propTypes = {
  msg: PropTypes.shape({
    message: PropTypes.string,
    parent_uuid: PropTypes.string
  })
};

function FeedContainer({ msg }) {
  const userName = useUserName(msg.user_uuid);
  const ifHtml = regex.test(msg.message);
  return (
    <Feed.Event key={msg.uuid}>
      <Feed.Content>
        <Feed.Label icon="comment alternate" />
        <Feed.Content>
          <Feed.User as="span">{userName}</Feed.User> added a message:
        </Feed.Content>
        <Feed.Extra text style={{ whiteSpace: "pre-wrap" }}>
          {msg.message_metadata === "LOS_PHASE_CHANGE_MESSAGE" || !ifHtml ? (
            getMessageWithUserName(msg, userName)
          ) : (
            <InlineLink msg={msg} />
          )}
        </Feed.Extra>
        <Feed.Meta>
          <Feed.Date>{prettyDate(msg.date, true)}</Feed.Date>
        </Feed.Meta>
      </Feed.Content>
    </Feed.Event>
  );
}

FeedContainer.propTypes = {
  msg: PropTypes.shape({
    uuid: PropTypes.string,
    user_uuid: PropTypes.string,
    message: PropTypes.string,
    message_metadata: PropTypes.string,
    date: PropTypes.string
  })
};

export default messages => messages.map(msg => <FeedContainer msg={msg} />);
