import React from "react";
import { Label, Icon } from "@bafsllc/ui-shared";
import "./index.css";
import PropTypes from "prop-types";

const WHITE_SPACE_REGEX = /\s/gi;

export const statusToPropsMap = autoSpreadsStatus => {
  const options = {
    Processing: {
      icon: "circle",
      color: "gray"
    },
    "Needs Validation": {
      icon: "exclamation triangle",
      color: "yellow"
    },
    "Ready for Use": {
      icon: "check circle",
      color: "green"
    },
    Error: {
      icon: "times",
      color: "red"
    }
  };
  return options[autoSpreadsStatus];
};

export default function StatusColumn({
  row,
  column,
  value: autoSpreadsStatus
}) {
  if (!autoSpreadsStatus) {
    return null;
  }

  return (
    <>
      <Label
        message={{
          defaultMessage: autoSpreadsStatus,
          id: `AUTO_SPREADS_STATUS_${autoSpreadsStatus
            .replace(WHITE_SPACE_REGEX, "_")
            .toUpperCase()}`
        }}
        {...(autoSpreadsStatus === "Error" && {
          onClick: column?.onClickError
        })}
        {...statusToPropsMap(autoSpreadsStatus)}
      />
      {autoSpreadsStatus === "Needs Validation" ? (
        <button
          data-testid="validate-auto-spread-button"
          className="validate-auto-spread"
          onClick={event => {
            event.preventDefault();
            column?.onClickValidate(row?.original);
          }}
          type="button"
        >
          <Icon icon="user check" size="small" />
        </button>
      ) : null}
    </>
  );
}

StatusColumn.propTypes = {
  row: PropTypes.object.isRequired,
  column: PropTypes.shape({
    onClickError: PropTypes.func.isRequired,
    onClickValidate: PropTypes.func.isRequired
  }).isRequired,
  value: PropTypes.string.isRequired
};
