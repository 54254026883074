import TypeCheck from "typecheck-extended";
import ApiEndpoints from "../../../ApiEndpoints";
import FetchService from "../../../FetchService";
import { FormatUrlV2 } from "../../../FormatUrl";

function add(jwt, onSuccess, onError, body, uuid, callbackData) {
  TypeCheck(jwt, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(body, "object");
  TypeCheck(uuid, "string");
  const url = FormatUrlV2(ApiEndpoints.loanPayoff, { uuid });
  FetchService("POST", url, onSuccess, onError, body, callbackData);
}

function get(jwt, onSuccess, onError, params, uuid, callbackData) {
  TypeCheck(jwt, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(params, "object");
  TypeCheck(uuid, "string");
  const url = FormatUrlV2(ApiEndpoints.loanPayoff, params);
  FetchService("GET", url, onSuccess, onError, null, callbackData);
}

export default {
  add,
  get
};
