import { SideSheet } from "@bafsllc/ui-shared";
import { CommonMessages } from "@blast-client/constants";
import { IconButton } from "@bafsllc/ui-shared";
import { useState } from "react";

const { ADD, PAYMENT_SCHEDULE } = CommonMessages;
export const AddPaymentScheduleSideSheet = ({
  isOpen,
  setIsOpen
}: AddPaymentScheduleSideSheetProps) => {
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
  return (
    <SideSheet
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      size="two-third"
    >
      <div className="flex justify-between items-center border-b border-neutral-300">
        <div>
          <p className="mb-0 text-sm">{ADD}</p>
          <h1 className="mt-0 text-xl font-semibold">{PAYMENT_SCHEDULE}</h1>
          <div className="text-sm text-neutral-600">Loan Request 1</div>
        </div>
        <div className="flex justify-end flex-row col-end-7 col-span-2 content-center">
          <div className="basis-1/4 ps-12 py-8">
            <IconButton
              message={{ id: "CANCEL" }}
              onClick={() => {
                setIsOpen(false);
                setSaveButtonEnabled(false);
              }}
              variant="secondary"
              size="tiny"
            />
          </div>

          <div className="basis-1/4 ps-4 py-8">
            <IconButton
              message={{ id: "SAVE" }}
              onClick={() => {
                setIsOpen(false);
                setSaveButtonEnabled(false);
              }}
              variant="primary"
              size="tiny"
              disabled={!saveButtonEnabled}
            />
          </div>
        </div>
      </div>
    </SideSheet>
  );
};

interface AddPaymentScheduleSideSheetProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
