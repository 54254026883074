import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { format, parse } from "date-fns";
import { Button } from "semantic-ui-react";
import { useToast } from "@bafsllc/ui-shared";
import { useSpreads } from "../../../../../../../../../../store/models/spreads";
import Constants from "../../../../../../../../../../services/Constants/strings";
import { ifFinancialModelExists } from "../../../../../../../../../../services/Constants/Models";
import {
  createFinancialAnalysisReport,
  pollObjectContent
} from "../../../../../../../../../../services/CMSRS";
import { Cms } from "../../../../../../../../../../services/ApiLib";
import {
  DateCell,
  CheckboxCell,
  LockCell,
  DefaultCell
} from "../../../../../../../../../../components/Tables";
import { getBlastClientApplicationBaseUrl } from "../../../../../../../../../../components/BlastEmbed/getBaseUrl";
import PaginatedTable from "../PaginatedTable";

function Spreads({ entityData, onTabChange }) {
  const { uuid: entityUuid, institution_uuid: institutionUuid } = entityData;
  const [{ spreads }, actions] = useSpreads({ entityUuid, institutionUuid });
  const [selectedRow, setSelectedRow] = useState();
  const [viewArchives, setViewArchives] = useState(false);
  const [creatingAnalysis, setCreatingAnalysis] = useState(false);
  const { addToast } = useToast();

  const columns = useMemo(
    () => [
      {
        Header: Constants.SELECT,
        id: "selected",
        textAlign: "center",
        accessor: "selected",
        singleSelection: true,
        Cell: CheckboxCell
      },
      {
        Header: Constants.SPREAD_ID,
        id: "uuid",
        accessor: "uuid",
        Cell: DefaultCell
      },
      {
        Header: Constants.ANALYSIS_PERIOD,
        id: "analysisPeriod",
        accessor: originalRow => {
          const { startDate, endDate } = originalRow.periods.reduce(
            (acc, period) => {
              if (!acc.startDate || acc.startDate > period.startDate) {
                acc.startDate = period.startDate;
              }
              if (!acc.endDate || acc.endDate < period.endDate) {
                acc.endDate = period.endDate;
              }
              return acc;
            },
            {}
          );
          if (!startDate || !endDate) return "—";
          const formattedStartDate = format(
            parse(startDate, "yyyy-MM-dd", new Date()),
            "MM/dd/yyyy"
          );
          const formattedEndDate = format(
            parse(endDate, "yyyy-MM-dd", new Date()),
            "MM/dd/yyyy"
          );
          return `${formattedStartDate} - ${formattedEndDate}`;
        },
        Cell: DefaultCell
      },
      {
        Header: Constants.ANALYST,
        id: "analyst",
        accessor: row =>
          row.user?.first_name
            ? `${row.user?.first_name} ${row.user?.last_name}`
            : row.analystUuid,
        Cell: DefaultCell
      },
      {
        Header: Constants.FINANCIAL_MODEL,
        id: "financialModel",
        accessor: row =>
          ifFinancialModelExists(row.FinancialSpreadsModelsModel),
        Cell: DefaultCell
      },
      {
        Header: Constants.SPREAD_CREATION_DATE,
        id: "createdDatetime",
        accessor: row => row?.createdAt,
        Cell: DateCell
      },
      {
        Header: Constants.COMPLETE,
        id: "completed",
        textAlign: "center",
        accessor: originalRow => originalRow.status === "Completed",
        disabled: true,
        Cell: LockCell
      }
    ],
    []
  );

  useEffect(() => {
    if (spreads?.length) {
      spreads.forEach(spread => {
        if (
          spread.status !== "Completed" &&
          spread.periods.every(period => period.status === "Completed")
        ) {
          actions.updateSpread({ ...spread, status: "Completed" });
        }
      });
    }
  }, [actions, spreads]);

  const filteredSpreads = useMemo(() => {
    if (spreads) {
      return viewArchives
        ? spreads
        : spreads.filter(spread => !spread.archived);
    }
    return null;
  }, [spreads, viewArchives]);

  const handleClickEdit = () => {
    const blastBaseUrl = getBlastClientApplicationBaseUrl();
    window.open(
      `${blastBaseUrl}/spreads/${entityData.institution_uuid}/${selectedRow.uuid}`
    );
  };

  const renderToast = variant => {
    const idMap = {
      error: {
        title: Constants.FINANCIAL_ANALYSIS_ERROR_TITLE,
        message: Constants.FINANCIAL_ANALYSIS_ERROR_MESSAGE
      }
    };
    addToast({
      id: "BASIC_TOAST",
      title: {
        id: idMap[variant].title,
        defaultMessage: "Error Generating Report"
      },
      message: {
        id: idMap[variant].message,
        defaultMessage:
          "There was an error generating the financial analysis. Please try again."
      },
      variant
    });
  };

  const handleClickCreateAnalysis = async () => {
    if (!creatingAnalysis) {
      setCreatingAnalysis(true);
      const { data } = await Cms.createObjectsV2Placeholder(
        "Financial-Analysis-Report.docx",
        institutionUuid
      );
      const { uuid: docxUuid } = data;
      let financialAnalysisUuid;
      try {
        const response = await createFinancialAnalysisReport(
          selectedRow.uuid,
          institutionUuid,
          docxUuid
        );
        financialAnalysisUuid = response?.uuid || null;
      } catch (e) {
        setCreatingAnalysis(false);
        renderToast("error");
        return;
      }

      if (financialAnalysisUuid) {
        try {
          await pollObjectContent(docxUuid, institutionUuid, 2000);
          onTabChange(Constants.ANALYSIS);
        } catch {
          setCreatingAnalysis(false);
          renderToast("error");
        }
      } else {
        setCreatingAnalysis(false);
        renderToast("error");
      }
    }
  };

  const handleClickArchive = () => {
    actions.updateSpread({ ...selectedRow, archived: true });
  };

  const handleViewArchives = (e, { checked }) => {
    setViewArchives(checked);
  };

  return filteredSpreads ? (
    <PaginatedTable
      columns={columns}
      data={filteredSpreads}
      onSelectedRowChange={rows => setSelectedRow(...rows)}
      handleViewArchives={handleViewArchives}
      filterOptions={[{ text: "Clear Filter", value: "" }]}
      tableName="spreadsTable"
      leftButtons={
        <>
          <Button
            disabled={
              !(
                selectedRow &&
                !selectedRow?.archived &&
                selectedRow.status !== "Completed"
              )
            }
            onClick={handleClickEdit}
          >
            Edit
          </Button>
          <Button
            className="ml-2"
            disabled={!(selectedRow && !selectedRow?.archived)}
            onClick={handleClickArchive}
          >
            Archive
          </Button>
        </>
      }
      rightButtons={
        <Button
          disabled={!(selectedRow && !selectedRow.archived) || creatingAnalysis}
          onClick={handleClickCreateAnalysis}
        >
          <i
            className={`${creatingAnalysis ? "spinner icon loading" : ""}`}
            data-testid="spinner"
          />
          Create Financial Analysis
        </Button>
      }
    />
  ) : (
    "Loading..."
  );
}

Spreads.propTypes = {
  entityData: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    institution_uuid: PropTypes.string.isRequired
  }).isRequired,
  onTabChange: PropTypes.func.isRequired
};

export default Spreads;
