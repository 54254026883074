import _ from "lodash";
import { createActions } from "redux-actions";
import {
  Entities,
  EntityDebts,
  Loans,
  LoanApp,
  LoanInterestSchedules,
  LoanPaymentSchedules,
  Relationships
} from "../../../../services/ApiLib";
import { relationshipTypes } from "../../../../services/Constants/relationshipTypes";
import parseStringAsNumber from "../../../../services/ParseStringAsNumber";
import { LORReducerKey } from "../../../../store/models/loan-applications";

const getLORState = getState => getState()[LORReducerKey] || {};

export const actionCreators = createActions(
  {
    addLoan: () => (dispatch, getState) => {
      const { loanAppUuid } = getLORState(getState);
      Loans.asyncAddBlank({ loanUuid: loanAppUuid })
        .then(({ data }) => {
          dispatch({ type: "ALERT_TRIGGER_ALERT" });
          dispatch({
            type: "LOR_POPULATE_CREDIT_REQUESTS",
            data: Array.isArray(data) ? data.loan : [data.loan]
          });
          dispatch(
            actionCreators.populateInterestSchedules(data.interest_schedule)
          );
          dispatch(
            actionCreators.populatePaymentSchedules(data.payment_schedule)
          );
        })
        .catch(() => {
          dispatch({ type: "LOR_CLEAR_ERROR" });
        });
    },
    deleteLoan: e => (dispatch, getState) => {
      const { paymentSchedules, interestSchedules } = getLORState(getState);
      const loanId = e.target.dataset.id;
      dispatch(
        actionCreators.updateCreditRequest({
          uuid: loanId,
          deleted: true
        })
      );
      if (paymentSchedules[loanId]) {
        dispatch(
          actionCreators.updatePaymentSchedule({
            uuid: paymentSchedules[loanId].uuid,
            deleted: true
          })
        );
      }
      if (interestSchedules[loanId]) {
        dispatch(
          actionCreators.updateInterestSchedule({
            uuid: interestSchedules[loanId].uuid,
            loan_uuid: loanId,
            deleted: true
          })
        );
      }
      dispatch(actionCreators.deletePaymentSchedule({ loanUuid: loanId }));
      dispatch(actionCreators.deleteInterestSchedule({ loanUuid: loanId }));
      dispatch({
        type: "LOR_DELETE_LOAN",
        loanId
      });
    },
    loadLoanAppAsync: loanAppUuid => async dispatch => {
      if (!loanAppUuid) return;
      dispatch({
        type: "LOR_POPULATE_LOANAPP_UUID",
        loanAppUuid
      });

      await Promise.all([
        dispatch(actionCreators.readLoanApp(loanAppUuid)),
        dispatch(actionCreators.readRelationship(loanAppUuid))
      ]);
    },
    loadLoanApp: loanAppUuid => dispatch => {
      if (!loanAppUuid) return;
      dispatch({
        type: "LOR_POPULATE_LOANAPP_UUID",
        loanAppUuid
      });

      dispatch(actionCreators.getLoanApp(loanAppUuid));
      dispatch(actionCreators.getRelationship(loanAppUuid));
    },
    updateDocument: (relType, doc) => dispatch => {
      dispatch({ type: "LOR_UPDATE_DOC", doc, relType });
    },
    getLoanApp: loanAppUuid => (dispatch, getState) => {
      const { loanApp, loanRequests } = getLORState(getState);
      if (loanApp && loanApp.uuid) {
        if (Object.keys(loanRequests).length === 0) {
          dispatch(actionCreators.getCreditRequests(loanApp.uuid));
        }

        return;
      }
      LoanApp.get(
        null,
        ({ data }) => {
          dispatch({ type: "LOR_POPULATE_LOANAPP", data });
          dispatch(actionCreators.getCreditRequests(data.uuid));
        },
        ({ message }) => {
          dispatch(actionCreators.handleError(message));
        },
        loanAppUuid
      );
    },
    readLoanApp: loanAppUuid => async dispatch => {
      const { data } = await LoanApp.read({ loanAppUuid });
      dispatch({ type: "LOR_POPULATE_LOANAPP", data });
    },
    getCreditRequests: uuid => (dispatch, getState) => {
      const { loanRequests } = getLORState(getState);
      if (loanRequests && Object.keys(loanRequests).length > 0) {
        return;
      }
      Loans.get(
        ({ data }) => {
          dispatch({ type: "LOR_POPULATE_CREDIT_REQUESTS", data });
          dispatch(actionCreators.getPaymentSchedules(data));
          dispatch(actionCreators.getInterestSchedules(data));
        },
        ({ message }) => {
          dispatch(actionCreators.handleError(message));
        },
        undefined,
        {
          loan_app_uuid: uuid,
          deleted: false,
          page_size: 300,
          page_number: 0
        }
      );
    },
    readCreditRequests: uuid => async dispatch => {
      const { data } = await Loans.asyncRead(
        { loan_app_uuid: uuid, deleted: false },
        { number: 0, size: 300 }
      );
      dispatch({ type: "LOR_POPULATE_CREDIT_REQUESTS", data });
      dispatch(actionCreators.getPaymentSchedules(data));
      dispatch(actionCreators.getInterestSchedules(data));
      return data;
    },
    getEntities: body => (dispatch, getState) => {
      const { entityDetails } = getLORState(getState);
      if (entityDetails && entityDetails.uuid) {
        return;
      }
      Entities.get(
        null,
        ({ data }) => {
          dispatch({ type: "LOR_POPULATE_ENTITY_DETAILS", data });
          dispatch(actionCreators.getEntityDebts(data));
        },
        ({ message }) => {
          dispatch(actionCreators.handleError(message));
        },
        body[0].child_uuid
      );
    },
    getEntityDebts:
      ({ uuid: entityUuid }) =>
      (dispatch, getState) => {
        const { entityDebt } = getLORState(getState);
        if (
          entityDebt !== undefined &&
          entityDebt.direct !== undefined &&
          entityDebt.indirect !== undefined
        ) {
          return;
        }
        EntityDebts.read({ entityUuid })
          .then(({ data }) => dispatch({ type: "LOR_POPULATE_DEBTS", data }))
          .catch(({ message }) =>
            dispatch(actionCreators.handleError(message))
          );
      },
    getRelationship: loanAppUuid => (dispatch, getState) => {
      const { loanAppPrimaryRelationship } = getLORState(getState);
      if (loanAppPrimaryRelationship && loanAppPrimaryRelationship.uuid) {
        return;
      }
      Relationships.get(
        { parent_uuid: loanAppUuid, rel_type: relationshipTypes.BORROWER },
        null,
        ({ data }) => {
          dispatch({
            type: "LOR_POPULATE_LOANAPP_RELATIONSHIP",
            data: data[0]
          });
          dispatch(actionCreators.getEntities(data));
        },
        ({ message }) => {
          dispatch(actionCreators.handleError(message));
        }
      );
    },
    readRelationship: loanAppUuid => async dispatch => {
      const { data } = await Relationships.asyncRead({
        parent_uuid: loanAppUuid,
        rel_type: relationshipTypes.BORROWER
      });
      dispatch({ type: "LOR_POPULATE_LOANAPP_RELATIONSHIP", data: data[0] });
    },
    getPaymentSchedules: loanReqs => dispatch => {
      loanReqs.forEach(req => {
        LoanPaymentSchedules.get(
          ({ data }) =>
            dispatch(actionCreators.populatePaymentSchedules(data[0])),
          ({ message }) => dispatch(actionCreators.handleError(message)),
          null,
          req.uuid,
          null,
          { sort_by: "first_payment_date", sort_order: 1, page_size: 1 }
        );
      });
    },
    getInterestSchedules: loanReqs => dispatch => {
      loanReqs.forEach(req => {
        LoanInterestSchedules.get(
          ({ data }) =>
            dispatch(actionCreators.populateInterestSchedules(data[0])),
          ({ message }) => dispatch(actionCreators.handleError(message)),
          null,
          req.uuid,
          null,
          { sort_by: "start_date", sort_order: 1, page_size: 1 }
        );
      });
    },
    handleChange: (e, table) => (dispatch, getState) => {
      const { loanRequests, paymentSchedules, interestSchedules } =
        getLORState(getState);
      const id = e["data-id"] || e.id;
      const { name, value } = e;
      switch (table) {
        case "loanRequest": {
          dispatch(
            actionCreators.updateCreditRequest({
              ...loanRequests[id],
              [name]: value
            })
          );
          return dispatch({
            type: "LOR_UPDATE_LOAN_FIELD",
            id,
            name,
            value
          });
        }
        case "paymentSchedule": {
          const paySchedule = {
            ...paymentSchedules[id],
            [name]: value
          };

          const updateSchedule =
            actionCreators.updatePaymentSchedule(paySchedule);
          dispatch(updateSchedule);

          const populateSchedules =
            actionCreators.populatePaymentSchedules(paySchedule);
          return dispatch(populateSchedules);
        }
        case "interestSchedule": {
          const interestSchedule = {
            ...interestSchedules[id],
            [name]: value
          };
          if (!interestSchedule.uuid && !interestSchedule.id) {
            return getLORState(getState);
          }
          dispatch(actionCreators.updateInterestSchedule(interestSchedule));
          return dispatch(
            actionCreators.populateInterestSchedules(interestSchedule)
          );
        }
        default:
          return "Unknown table";
      }
    },
    handleChangeNumber: (data, table) => dispatch => {
      const newValue = parseStringAsNumber(data.value);
      const newData = _.cloneDeep(data);
      newData.value = newValue;
      dispatch(actionCreators.handleChange(newData, table));
    },
    handleError: message => dispatch => {
      dispatch({
        type: "LOR_SET_ERROR",
        errorMessage: message || "An error occurred, please reload the page"
      });
    },
    radioChange: e => (dispatch, getState) => {
      const { loanRequests } = getLORState(getState);
      dispatch(
        actionCreators.updateCreditRequest({
          ...loanRequests[e.target.dataset.id],
          [e.target.name]: e.target.value
        })
      );
      dispatch({
        type: "LOR_UPDATE_LOAN_FIELD",
        id: e.target.dataset.id,
        name: e.target.name,
        value: e.target.value
      });
    },
    updateCreditRequest: body => dispatch => {
      Loans.update(
        () => {
          if (body.rate_type && body.rate_type === "Fixed") {
            dispatch(actionCreators.getCreditRequests(body.loan_app_uuid));
          }
        },
        () => dispatch({ type: "LOR_CLEAR_ERROR" }),
        body
      );
    },
    updateInterestSchedule: body => dispatch => {
      LoanInterestSchedules.update(
        () => {},
        () => dispatch({ type: "LOR_CLEAR_ERROR" }),
        body
      );
    },
    updateMultiplePropsOnLoanApp: body => async (dispatch, getState) => {
      const { loanAppUuid } = getLORState(getState);
      try {
        const { data } = await LoanApp.asyncUpdate({ uuid: loanAppUuid }, body);
        dispatch({ type: "LOR_UPDATE_LOANAPP_MULTI_PROP", data });
        dispatch({ type: "LOR_CLEAR_ERROR" });
      } catch (e) {
        if (e.message) {
          dispatch(actionCreators.handleError(e.message));
        } else if (e.detail) {
          dispatch(actionCreators.handleError(e.detail));
        } else if (e.error) {
          dispatch(actionCreators.handleError(e.error));
        } else {
          dispatch(actionCreators.handleError("Something went wrong"));
        }
      }
    },
    updateLoanApp:
      ({ name, value }) =>
      (dispatch, getState) => {
        const { loanAppUuid } = getLORState(getState);
        dispatch({ type: "LOR_UPDATE_LOANAPP", name, value });
        LoanApp.update(
          null,
          () => {},
          ({ message }) => dispatch(actionCreators.handleError(message)),
          loanAppUuid,
          { [name]: value }
        );
      },
    updatePaymentSchedule: body => dispatch => {
      LoanPaymentSchedules.update(
        () => {},
        () => dispatch({ type: "LOR_CLEAR_ERROR" }),
        body
      );
    }
  },
  "POPULATE_PAYMENT_SCHEDULES",
  "POPULATE_INTEREST_SCHEDULES",
  "DELETE_INTEREST_SCHEDULE",
  "DELETE_PAYMENT_SCHEDULE",
  {
    prefix: "LOR"
  }
);

export default actionCreators;
