/* eslint-env browser */
import TypeCheck from "typecheck-extended";
import { RefreshToken, getToken } from "../Auth";

import asyncFetchService, {
  asyncFetchFileService
} from "./services/asyncFetchService";
import downloadFile from "./services/downloadFile";

export { asyncFetchService, asyncFetchFileService };

/*
  This service is used in lieu of fetch(), but is only to be used in the ApiLibs.
*/
export default function FetchService(
  method,
  url,
  onSuccess,
  onError,
  body = null,
  callbackData = null
) {
  TypeCheck(method, "string");
  TypeCheck(url, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(body, "object", false);

  // TODO: When available, use GlobalError service on all errors below. [GH #1327]

  const validMethods = ["DELETE", "GET", "PATCH", "POST", "PUT"];
  if (!validMethods.includes(method)) {
    throw new Error(
      `FetchService Error: (${method}) is not a valid REST Method`
    );
  }

  if (method !== "GET") {
    RefreshToken();
  }

  const fetchParams = {
    method,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json"
    }
  };

  if (body) {
    if (method === "GET") {
      throw new Error("FetchService Error: Body not allowed on GET Requests");
    }
    fetchParams.body = JSON.stringify(body);
  }

  fetch(url, fetchParams)
    .then(response => {
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        return response.json();
      }
      throw response;
    })
    .then(data => {
      onSuccess(data, callbackData);
    })
    .catch(error => {
      try {
        error.json().then(
          errorJSON => onError(errorJSON, callbackData),
          () => onError(error, callbackData)
        );
      } catch (err) {
        onError(error, callbackData);
      }
    });
}

export function ObjectUpload(
  url,
  onSuccess,
  onError,
  fileObj,
  callbackData = null
) {
  TypeCheck(url, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(callbackData, "object", false); // Returned (unprocessed) to success/error callbacks.

  const formData = new FormData();
  formData.append("fileData", fileObj);
  formData.append("fileName", fileObj.name);
  if (
    callbackData &&
    callbackData.objMetadataBody &&
    callbackData.objMetadataBody.category
  ) {
    formData.append("category", callbackData.objMetadataBody.category);
  }

  fetch(url, {
    body: formData,
    method: "POST",
    headers: {
      Authorization: getToken()
    }
  })
    .then(response => {
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        return response.json();
      }
      throw response;
    })
    .then(data => {
      onSuccess(data, callbackData);
    })
    .catch(error => {
      try {
        error.json().then(
          errorJSON => onError(errorJSON, callbackData),
          () => onError(error, callbackData)
        );
      } catch (err) {
        onError(error, callbackData);
      }
    });
}

export async function asyncObjectUpload(url, fileObj) {
  TypeCheck(url, "string");

  const formData = new FormData();
  formData.append("fileData", fileObj);

  try {
    const response = await fetch(url, {
      body: formData,
      method: "POST",
      headers: {
        Authorization: getToken()
      }
    });
    return response.json();
  } catch (err) {
    return err;
  }
}

export function FetchFileService(
  method,
  url,
  onSuccess,
  onError,
  body = null,
  callbackData = null,
  type = "download"
) {
  TypeCheck(method, "string");
  TypeCheck(url, "string");
  TypeCheck(onSuccess, "function", false);
  TypeCheck(onError, "function");
  TypeCheck(body, "object", false);
  TypeCheck(callbackData, "object", false); // Returned (unprocessed) to success/error callbacks.
  TypeCheck(type, "string", false);

  // TODO: When available, use GlobalError service on all errors below. [GH #1327]

  const validMethods = ["GET"];
  if (!validMethods.includes(method)) {
    throw new Error(`FetchFileService Error: (${method}) is not allowed.`);
  }

  const fetchParams = {
    method,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json"
    }
  };

  if (body) {
    if (method === "GET") {
      throw new Error("FetchService Error: Body not allowed on GET Requests");
    }
    fetchParams.body = JSON.stringify(body);
  }

  fetch(url, fetchParams)
    .then(response => downloadFile({ response, callbackData, type }))
    .then(data => {
      onSuccess(data, callbackData);
    })
    .catch(error => {
      onError(error, callbackData);
    });
}
