// eslint-disable-next-line import/prefer-default-export
export const updateShowAssetAccessModal =
  (bool, data = null) =>
  dispatch => {
    dispatch({
      type: "CMS_TOGGLE_ASSET_ACCESS_MODAL",
      showAssetAccessModal: bool,
      assetAccessModalData: data
    });
  };
