import { Button, Modal } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import PropTypes, { bool } from "prop-types";
import RealEstateForm from "../../../../components/CollateralAddEdit/real-estate-form";
import PersonalPropertyForm from "../../../../components/CollateralAddEdit/personal-property-form";
import AccountForm from "../../../../components/CollateralAddEdit/account-form";
import { floodInsuranceRequiredZones } from "../../../../components/CollateralAddEdit/flood-zones";

function AssetsModal({
  asset,
  modalInfo,
  updateValue,
  setAssetModalOpen,
  open
}) {
  const [info, setInfo] = useState({});

  useEffect(() => {
    setInfo(modalInfo);
  }, [modalInfo]);

  const updatePolicyNumber = ({ name, value }) => {
    const insurancePolicies = value.split(",", value.length);
    const newValue = { ...info, [name]: insurancePolicies };
    setInfo(newValue);
  };

  const handleUpdate = ({ name, value }) => {
    if (name === "life_insurance_policy_number") {
      updatePolicyNumber({ name, value });
    } else if (name === "flood_zone") {
      if (floodInsuranceRequiredZones.includes(value)) {
        const newValue = { ...info, [name]: value, flood_required: true };
        setInfo(newValue);
      } else {
        const newValue = { ...info, [name]: value, flood_required: false };
        setInfo(newValue);
      }
    } else {
      const newValue = { ...info, [name]: value };
      setInfo(newValue);
    }
  };
  return (
    <Modal open={open} closeOnDimmerClick>
      <Modal.Header>Edit Asset</Modal.Header>
      <Modal.Content>
        {asset.asset_group === "Real Estate" && (
          <RealEstateForm realEstate={info} updateValue={handleUpdate} />
        )}
        {asset.asset_group === "Personal Property" &&
          asset.asset_class !== "Accounts" && (
            <PersonalPropertyForm
              personalProperty={info}
              updateValue={handleUpdate}
            />
          )}
        {asset.asset_group === "Personal Property" &&
          asset.asset_class === "Accounts" && (
            <AccountForm accountDetail={info} updateValue={handleUpdate} />
          )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          data-testid="cancel-asset-btn"
          onClick={() => {
            setAssetModalOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          data-testid="save-asset-btn"
          color="green"
          onClick={() => {
            updateValue(info);
            setAssetModalOpen(false);
          }}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

AssetsModal.propTypes = {
  asset: PropTypes.shape().isRequired,
  modalInfo: PropTypes.shape().isRequired,
  updateValue: PropTypes.func.isRequired,
  setAssetModalOpen: PropTypes.func.isRequired,
  open: bool
};
export default AssetsModal;
