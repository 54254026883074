/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import { useTable } from "react-table";
import {
  DateCell,
  DollarCell
} from "../../../../../../../../../components/Tables";

const columns = [
  {
    Header: "Date",
    id: "effective_date",
    accessor: "effective_date",
    Cell: DateCell
  },
  {
    Header: "Change",
    id: "description",
    accessor: "description",
    width: "6"
  },
  {
    Header: "Original",
    id: "principal",
    accessor: "principal",
    Cell: DollarCell
  },
  {
    Header: "New",
    id: "recalc_principal",
    accessor: "recalc_principal",
    Cell: DollarCell
  },
  {
    Header: "Change By",
    id: "entered_by",
    accessor: "entered_by"
  }
];

function AcctHistoryTable({ logs = [] }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: logs });

  return (
    <Table {...getTableProps()} compact celled>
      <Table.Header>
        {headerGroups.map(headerGroup => (
          <Table.Row {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <Table.HeaderCell {...column.getHeaderProps()}>
                {column.render("Header")}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        ))}
      </Table.Header>
      <Table.Body {...getTableBodyProps()}>
        {rows.map(
          row =>
            prepareRow(row) || (
              <Table.Row {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <Table.Cell
                    {...cell.getCellProps({
                      width: cell.column.id === "description" ? 6 : null
                    })}
                  >
                    {cell.render("Cell")}
                  </Table.Cell>
                ))}
              </Table.Row>
            )
        )}
      </Table.Body>
    </Table>
  );
}

AcctHistoryTable.propTypes = {
  logs: PropTypes.arrayOf(PropTypes.shape())
};

export default AcctHistoryTable;
