import { blastApi, Tag } from "./blastApi";
import { ListResponse, PaginatedQueryOptions, SingleResponse } from "./types";
import { InterestSchedule } from "./ModelTypes/interestSchedule"; // Assuming the correct path
import {
  extractData,
  createSingleTag,
  createListTags,
  filteredQuery
} from "./utilities";

const servicePath = "/loans/v1"; // Adjust the service path if necessary

export interface InterestScheduleQueryOptions extends PaginatedQueryOptions {
  institution_uuid?: string;
  loan_uuid?: string; // see loanUuid in InterestScheduleParameters
}

export interface InterestScheduleParameters {
  uuid?: string;
  loanUuid: string; // This is required here in camelCase and optional above in snake_case
  filters?: InterestScheduleQueryOptions;
}

const extendedApi = blastApi.injectEndpoints({
  endpoints: build => ({
    interestSchedule: build.query<InterestSchedule, InterestScheduleParameters>(
      {
        query: options => {
          const { loanUuid, uuid } = options;
          return `${servicePath}/${loanUuid}/interest_schedules/${uuid}`;
        },
        providesTags: result => createSingleTag(result, Tag.InterestSchedule),
        transformResponse: (response: SingleResponse<InterestSchedule>) =>
          extractData<InterestSchedule>(response)
      }
    ),
    interestSchedules: build.query<
      ListResponse<InterestSchedule>,
      InterestScheduleParameters
    >({
      query: options => {
        const { loanUuid, filters } = options;
        const url = `${servicePath}/${loanUuid}/interest_schedules`;
        return filteredQuery(url, filters);
      },
      providesTags: results => createListTags(results, Tag.InterestSchedule)
    }),
    createInterestSchedule: build.mutation<
      SingleResponse<InterestSchedule>,
      Partial<InterestSchedule>
    >({
      query: interestScheduleData => ({
        url: `${servicePath}/${interestScheduleData.loan_uuid}/interest_schedules`,
        method: "POST",
        body: interestScheduleData
      }),
      invalidatesTags: [Tag.InterestSchedule] // Optionally invalidate interest schedule cache
    }),
    updateInterestSchedule: build.mutation<
      SingleResponse<InterestSchedule>,
      Partial<InterestSchedule>
    >({
      query: interestScheduleData => ({
        url: `${servicePath}/${interestScheduleData.loan_uuid}/interest_schedules/${interestScheduleData.uuid}`,
        method: "PUT",
        body: interestScheduleData
      }),
      invalidatesTags: [Tag.InterestSchedule] // Optionally invalidate interest schedule cache
    }),
    deleteInterestSchedule: build.mutation<
      SingleResponse<InterestSchedule>,
      Partial<InterestSchedule>
    >({
      query: interestScheduleData => ({
        url: `${servicePath}/${interestScheduleData.loan_uuid}/interest_schedules/${interestScheduleData.uuid}`,
        method: "DELETE"
      }),
      invalidatesTags: [Tag.InterestSchedule] // Optionally invalidate interest schedule cache
    })
  }),
  overrideExisting: false
});

export const {
  useCreateInterestScheduleMutation,
  useDeleteInterestScheduleMutation,
  useInterestScheduleQuery,
  useInterestSchedulesQuery,
  useUpdateInterestScheduleMutation
} = extendedApi;
