/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */
import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  actions as reduxActions,
  modelsSelector,
  errorsSelector
} from "./financial-spreads-models";

const useActions = dispatch =>
  useMemo(
    () =>
      Object.entries(reduxActions)
        .map(([key, act]) => [key, (...args) => dispatch(act(...args))])
        .reduce((actionMap, [key, act]) => ({ ...actionMap, [key]: act }), {}),
    [dispatch]
  );

export const useModels = ({ institutionUuid }) => {
  const dispatch = useDispatch();
  const actions = useActions(dispatch);

  // Kick off data fetching when component is rendered
  useEffect(() => {
    if (institutionUuid) {
      dispatch(reduxActions.retrieveProductionModels({ institutionUuid }));
    }
    return () => dispatch(reduxActions.cleanUpModels());
  }, [institutionUuid, dispatch]);

  const models = useSelector(modelsSelector);
  const errors = useSelector(errorsSelector);

  return useMemo(
    () => [{ models, errors }, actions],
    [models, errors, actions]
  );
};
