import { connect } from "react-redux";
import Collateral from "./Collateral";
import { actionCreators } from "../../reducer";

const {
  loadCollateralByLoanUuid,
  loadEntitiesByInstitution,
  addCollateralToggle,
  editCollateralToggle,
  deleteCollateralToggle,
  deleteCollateral,
  updateCollateralModalAsset,
  collateralModalCollateralUpdate,
  saveCollateral,
  closeAssetsForm,
  setAdditionalAssetValue,
  updateAdditionalAsset,
  addAdditionalAsset,
  resetAdditionalAssets
} = actionCreators;

const mapStateToProps = state => ({
  assets: state.LoanManagementReducer.assets,
  data: state.LoanManagementReducer.data,
  institutionUuid: state.LoanManagementReducer.entityData.institution_uuid,
  entityAssets: state.LoanManagementReducer.entityAssets,
  removedAssets: state.LoanManagementReducer.removedAssets,
  collateralModalData: state.LoanManagementReducer.collateralModalData,
  collateralModalAsset: state.LoanManagementReducer.collateralModalAsset,
  collateralModalIsAdd: state.LoanManagementReducer.collateralModalIsAdd,
  collateralModalOpen: state.LoanManagementReducer.collateralModalOpen,
  collateralDeleteModalIsOpen:
    state.LoanManagementReducer.collateralDeleteModalOpen,
  pagination: state.PaginationReducer.loanMgmtCollateral,
  lastPage: state.LoanManagementReducer.collateralDataLastPage,
  openAssetsForm: state.LoanManagementReducer.openAssetsForm,
  additionalAssetData: state.LoanManagementReducer.additionalAssetData,
  assetDataFound: state.LoanManagementReducer.assetDataFound
});

export const mapDispatchToProps = (
  dispatch,
  { entityData, collateralDeleteUuid }
) => ({
  onViewCollateralTable: loadUuid =>
    dispatch(loadCollateralByLoanUuid(loadUuid)),
  onDeleteAssetModalToggle: assetUuid =>
    dispatch(deleteCollateralToggle({ assetUuid })),
  onEditCollateralModalToggle: collateralUuid => {
    dispatch(editCollateralToggle({ collateralUuid }));
  },
  onAddCollateralModalToggle: () => {
    dispatch(addCollateralToggle());
    dispatch(loadEntitiesByInstitution(entityData.institution_uuid));
  },
  onCancelDeleteCollateralAsset: () => dispatch(deleteCollateralToggle()),
  onDeleteCollateralAsset: () =>
    dispatch(deleteCollateral(collateralDeleteUuid)),
  // TODO: remove the event arg from here
  onCollateralEditorAssetChange: (e, { name, value }) => {
    dispatch(updateCollateralModalAsset({ name, value }));
  },
  // TODO: remove the event arg from here
  onAssetSelectChange: (e, { name, value }) => {
    dispatch(updateCollateralModalAsset({ name, value }));
  },
  onCollateralEditorCollateralChange: (e, { name, value }) => {
    dispatch(collateralModalCollateralUpdate({ name, value }));
  },
  onCollateralEditorSave: () => dispatch(saveCollateral()),
  setCloseAssetsForm: () => dispatch(closeAssetsForm()),
  setAdditonalAssetValue: ({ name, value }) =>
    dispatch(setAdditionalAssetValue({ name, value })),
  resetAdditionalAssets: () => dispatch(resetAdditionalAssets()),
  updateAdditionalAssetData: (group, assetClass) =>
    dispatch(updateAdditionalAsset(group, assetClass)),
  addAdditionalAssetData: (group, assetClass) =>
    dispatch(addAdditionalAsset(group, assetClass))
});

export default connect(mapStateToProps, mapDispatchToProps)(Collateral);
