// NAIC Codes
const NAICCodes = {
  112519: "Other Aquaculture",
  112920: "Horses and Other Equine Production",
  112511: "Finfish Farming and Fish Hatcheries",
  112512: "Shellfish Farming",
  922110: "Courts",
  522293: "International Trade Financing",
  444130: "Hardware Stores",
  611430: "Professional and Management Development Training",
  442110: "Furniture Stores",
  512240: "Sound Recording Studios",
  562112: "Hazardous Waste Collection",
  562111: "Solid Waste Collection",
  711110: "Theater Companies and Dinner Theaters",
  524128: "Other Direct Insurance (except Life, Health, and Medical) Carriers",
  541990: "All Other Professional, Scientific, and Technical Services",
  424510: "Grain and Field Bean Merchant Wholesalers",
  812910: "Pet Care (except Veterinary) Services",
  491110: "Postal Service",
  112210: "Hog and Pig Farming",
  523120: "Securities Brokerage",
  424450: "Confectionery Merchant Wholesalers",
  326150: "Urethane and Other Foam Product (except Polystyrene) Manufacturing",
  524126: "Direct Property and Casualty Insurance Carriers",
  524127: "Direct Title Insurance Carriers",
  922190: "Other Justice, Public Order, and Safety Activities",
  424120: "Stationery and Office Supplies Merchant Wholesalers",
  335110: "Electric Lamp Bulb and Part Manufacturing",
  541690: "Other Scientific and Technical Consulting Services",
  425110: "Business to Business Electronic Markets",
  443142: "Electronics Stores",
  425120: "Wholesale Trade Agents and Brokers",
  443141: "Household Appliance Stores",
  621512: "Diagnostic Imaging Centers",
  423130: "Tire and Tube Merchant Wholesalers",
  621111: "Offices of Physicians (except Mental Health Specialists)",
  326291: "Rubber Product Manufacturing for Mechanical Use",
  332911: "Industrial Valve Manufacturing",
  332912: "Fluid Power Valve and Hose Fitting Manufacturing",
  332913: "Plumbing Fixture Fitting and Trim Manufacturing",
  326299: "All Other Rubber Product Manufacturing",
  337110: "Wood Kitchen Cabinet and Countertop Manufacturing",
  332919: "Other Metal Valve and Pipe Fitting Manufacturing",
  448310: "Jewelry Stores",
  238990: "All Other Specialty Trade Contractors",
  112990: "All Other Animal Production",
  423860:
    "Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers",
  922160: "Fire Protection",
  712120: "Historical Sites",
  423420: "Office Equipment Merchant Wholesalers",
  445120: "Convenience Stores",
  562119: "Other Waste Collection",
  332710: "Machine Shops",
  424430: "Dairy Product (except Dried or Canned) Merchant Wholesalers",
  561790: "Other Services to Buildings and Dwellings",
  722514: "Cafeterias, Grill Buffets, and Buffets",
  331210: "Iron and Steel Pipe and Tube Manufacturing from Purchased Steel",
  722511: "Full-Service Restaurants",
  722513: "Limited-Service Restaurants",
  332993: "Ammunition (except Small Arms) Manufacturing",
  423430:
    "Computer and Computer Peripheral Equipment and Software Merchant Wholesalers",
  424420: "Packaged Frozen Food Merchant Wholesalers",
  448140: "Family Clothing Stores",
  423610:
    "Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers",
  522110: "Commercial Banking",
  522294: "Secondary Market Financing",
  523910: "Miscellaneous Intermediation",
  488390: "Other Support Activities for Water Transportation",
  451120: "Hobby, Toy, and Game Stores",
  111150: "Corn Farming",
  453930: "Manufactured (Mobile) Home Dealers",
  423510: "Metal Service Centers and Other Metal Merchant Wholesalers",
  337122: "Nonupholstered Wood Household Furniture Manufacturing",
  331529: "Other Nonferrous Metal Foundries (except Die-Casting)",
  337121: "Upholstered Household Furniture Manufacturing",
  721110: "Hotels (except Casino Hotels) and Motels",
  337127: "Institutional Furniture Manufacturing",
  337124: "Metal Household Furniture Manufacturing",
  337125: "Household Furniture (except Wood and Metal) Manufacturing",
  238190: "Other Foundation, Structure, and Building Exterior Contractors",
  331523: "Nonferrous Metal Die-Casting Foundries",
  331524: "Aluminum Foundries (except Die-Casting)",
  316210: "Footwear Manufacturing",
  561591: "Convention and Visitors Bureaus",
  322230: "Stationery Product Manufacturing",
  453998: "All Other Miscellaneous Store Retailers (except Tobacco Stores)",
  238290: "Other Building Equipment Contractors",
  711190: "Other Performing Arts Companies",
  541910: "Marketing Research and Public Opinion Polling",
  334614:
    "Software and Other Prerecorded Compact Disc, Tape, and Record Reproducing",
  484210: "Used Household and Office Goods Moving",
  334613: "Blank Magnetic and Optical Recording Media Manufacturing",
  811112: "Automotive Exhaust System Repair",
  111160: "Rice Farming",
  561210: "Facilities Support Services",
  811111: "General Automotive Repair",
  519190: "All Other Information Services",
  237310: "Highway, Street, and Bridge Construction",
  323113: "Commercial Screen Printing",
  811118: "Other Automotive Mechanical and Electrical Repair and Maintenance",
  323111: "Commercial Printing (except Screen and Books)",
  323117: "Books Printing",
  114210: "Hunting and Trapping",
  515112: "Radio Stations",
  926140: "Regulation of Agricultural Marketing and Commodities",
  238330: "Flooring Contractors",
  484230: "Specialized Freight (except Used Goods) Trucking, Long-Distance",
  221118: "Other Electric Power Generation",
  488410: "Motor Vehicle Towing",
  611519: "Other Technical and Trade Schools",
  812922: "One-Hour Photofinishing",
  333244: "Printing Machinery and Equipment Manufacturing",
  333243: "Sawmill, Woodworking, and Paper Machinery Manufacturing",
  333242: "Semiconductor Machinery Manufacturing",
  333241: "Food Product Machinery Manufacturing",
  453991: "Tobacco Stores",
  445210: "Meat Markets",
  321219: "Reconstituted Wood Product Manufacturing",
  333249: "Other Industrial Machinery Manufacturing",
  523930: "Investment Advice",
  812921: "Photofinishing Laboratories (except One-Hour)",
  621112: "Offices of Physicians, Mental Health Specialists",
  532289: "All Other Consumer Goods Rental",
  531311: "Residential Property Managers",
  532284: "Recreational Goods Rental",
  531312: "Nonresidential Property Managers",
  221320: "Sewage Treatment Facilities",
  321920: "Wood Container and Pallet Manufacturing",
  532281: "Formal Wear and Costume Rental",
  312230: "Tobacco Manufacturing",
  221111: "Hydroelectric Power Generation",
  332439: "Other Metal Container Manufacturing",
  713990: "All Other Amusement and Recreation Industries",
  212210: "Iron Ore Mining",
  622310: "Specialty (except Psychiatric and Substance Abuse) Hospitals",
  332431: "Metal Can Manufacturing",
  442210: "Floor Covering Stores",
  624221: "Temporary Shelters",
  446199: "All Other Health and Personal Care Stores",
  113310: "Logging",
  813211: "Grantmaking Foundations",
  312120: "Breweries",
  446191: "Food (Health) Supplement Stores",
  326111: "Plastics Bag and Pouch Manufacturing",
  424930:
    "Flower, Nursery Stock, and Florists\u2019 Supplies Merchant Wholesalers",
  326113:
    "Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing",
  326112:
    "Plastics Packaging Film and Sheet (including Laminated) Manufacturing",
  335210: "Small Electrical Appliance Manufacturing",
  561720: "Janitorial Services",
  541370: "Surveying and Mapping (except Geophysical) Services",
  921190: "Other General Government Support",
  336320: "Motor Vehicle Electrical and Electronic Equipment Manufacturing",
  812220: "Cemeteries and Crematories",
  112310: "Chicken Egg Production",
  444220: "Nursery, Garden Center, and Farm Supply Stores",
  561440: "Collection Agencies",
  325120: "Industrial Gas Manufacturing",
  322122: "Newsprint Mills",
  525190: "Other Insurance Funds",
  327410: "Lime Manufacturing",
  321991: "Manufactured Home (Mobile Home) Manufacturing",
  321992: "Prefabricated Wood Building Manufacturing",
  212325: "Clay and Ceramic and Refractory Minerals Mining",
  212322: "Industrial Sand Mining",
  314999: "All Other Miscellaneous Textile Product Mills",
  711320:
    "Promoters of Performing Arts, Sports, and Similar Events without Facilities",
  212321: "Construction Sand and Gravel Mining",
  314994: "Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills",
  321999: "All Other Miscellaneous Wood Product Manufacturing",
  922120: "Police Protection",
  112420: "Goat Farming",
  112130: "Dual-Purpose Cattle Ranching and Farming",
  561990: "All Other Support Services",
  512290: "Other Sound Recording Industries",
  313220: "Narrow Fabric Mills and Schiffli Machine Embroidery",
  512110: "Motion Picture and Video Production",
  211130: "Natural Gas Extraction",
  621492: "Kidney Dialysis Centers",
  621493: "Freestanding Ambulatory Surgical and Emergency Centers",
  424460: "Fish and Seafood Merchant Wholesalers",
  621491: "HMO Medical Centers",
  621498: "All Other Outpatient Care Centers",
  561611: "Investigation Services",
  423850: "Service Establishment Equipment and Supplies Merchant Wholesalers",
  561613: "Armored Car Services",
  315110: "Hosiery and Sock Mills",
  321213: "Engineered Wood Member (except Truss) Manufacturing",
  321212: "Softwood Veneer and Plywood Manufacturing",
  321211: "Hardwood Veneer and Plywood Manufacturing",
  321214: "Truss Manufacturing",
  221117: "Biomass Electric Power Generation",
  221116: "Geothermal Electric Power Generation",
  221115: "Wind Electric Power Generation",
  221114: "Solar Electric Power Generation",
  221113: "Nuclear Electric Power Generation",
  221112: "Fossil Fuel Electric Power Generation",
  237130: "Power and Communication Line and Related Structures Construction",
  561311: "Employment Placement Agencies",
  424940: "Tobacco and Tobacco Product Merchant Wholesalers",
  712110: "Museums",
  332111: "Iron and Steel Forging",
  324110: "Petroleum Refineries",
  811412: "Appliance Repair and Maintenance",
  811411: "Home and Garden Equipment Repair and Maintenance",
  238150: "Glass and Glazing Contractors",
  541511: "Custom Computer Programming Services",
  336120: "Heavy Duty Truck Manufacturing",
  551111: "Offices of Bank Holding Companies",
  311340: "Nonchocolate Confectionery Manufacturing",
  327110: "Pottery, Ceramics, and Plumbing Fixture Manufacturing",
  561312: "Executive Search Services",
  327120: "Clay Building Material and Refractories Manufacturing",
  423140: "Motor Vehicle Parts (Used) Merchant Wholesalers",
  423490: "Other Professional Equipment and Supplies Merchant Wholesalers",
  423990: "Other Miscellaneous Durable Goods Merchant Wholesalers",
  551114: "Corporate, Subsidiary, and Regional Managing Offices",
  541213: "Tax Preparation Services",
  313310: "Textile and Fabric Finishing Mills",
  611310: "Colleges, Universities, and Professional Schools",
  311225: "Fats and Oils Refining and Blending",
  311224: "Soybean and Other Oilseed Processing",
  483113: "Coastal and Great Lakes Freight Transportation",
  483112: "Deep Sea Passenger Transportation",
  311221: "Wet Corn Milling",
  483114: "Coastal and Great Lakes Passenger Transportation",
  333611: "Turbine and Turbine Generator Set Units Manufacturing",
  333612: "Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing",
  333613: "Mechanical Power Transmission Equipment Manufacturing",
  441110: "New Car Dealers",
  423460: "Ophthalmic Goods Merchant Wholesalers",
  333618: "Other Engine Equipment Manufacturing",
  487210: "Scenic and Sightseeing Transportation, Water",
  334290: "Other Communications Equipment Manufacturing",
  451110: "Sporting Goods Stores",
  445299: "All Other Specialty Food Stores",
  423720:
    "Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers",
  335932: "Noncurrent-Carrying Wiring Device Manufacturing",
  335931: "Current-Carrying Wiring Device Manufacturing",
  327999: "All Other Miscellaneous Nonmetallic Mineral Product Manufacturing",
  488510: "Freight Transportation Arrangement",
  541310: "Architectural Services",
  327212: "Other Pressed and Blown Glass and Glassware Manufacturing",
  327992: "Ground or Treated Mineral and Earth Manufacturing",
  327993: "Mineral Wool Manufacturing",
  327991: "Cut Stone and Stone Product Manufacturing",
  441120: "Used Car Dealers",
  212393: "Other Chemical and Fertilizer Mineral Mining",
  212392: "Phosphate Rock Mining",
  212391: "Potash, Soda, and Borate Mineral Mining",
  813311: "Human Rights Organizations",
  923110: "Administration of Education Programs",
  713290: "Other Gambling Industries",
  314110: "Carpet and Rug Mills",
  423310: "Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers",
  212399: "All Other Nonmetallic Mineral Mining",
  621330: "Offices of Mental Health Practitioners (except Physicians)",
  336330:
    "Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing",
  541410: "Interior Design Services",
  484220: "Specialized Freight (except Used Goods) Trucking, Local",
  311824:
    "Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour",
  311821: "Cookie and Cracker Manufacturing",
  488210: "Support Activities for Rail Transportation",
  327390: "Other Concrete Product Manufacturing",
  488190: "Other Support Activities for Air Transportation",
  325520: "Adhesive Manufacturing",
  521110: "Monetary Authorities-Central Bank",
  481112: "Scheduled Freight Air Transportation",
  813910: "Business Associations",
  111110: "Soybean Farming",
  621610: "Home Health Care Services",
  336510: "Railroad Rolling Stock Manufacturing",
  485310: "Taxi Service",
  611210: "Junior Colleges",
  711310:
    "Promoters of Performing Arts, Sports, and Similar Events with Facilities",
  333111: "Farm Machinery and Equipment Manufacturing",
  488320: "Marine Cargo Handling",
  713120: "Amusement Arcades",
  423740: "Refrigeration Equipment and Supplies Merchant Wholesalers",
  493110: "General Warehousing and Storage",
  921150: "American Indian and Alaska Native Tribal Governments",
  334220:
    "Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing",
  488991: "Packing and Crating",
  454110: "Electronic Shopping and Mail-Order Houses",
  812990: "All Other Personal Services",
  488999: "All Other Support Activities for Transportation",
  926110: "Administration of General Economic Programs",
  811212: "Computer and Office Machine Repair and Maintenance",
  339950: "Sign Manufacturing",
  311710: "Seafood Product Preparation and Packaging",
  711130: "Musical Groups and Artists",
  541214: "Payroll Services",
  532310: "General Rental Centers",
  722410: "Drinking Places (Alcoholic Beverages)",
  311911: "Roasted Nuts and Peanut Butter Manufacturing",
  111940: "Hay Farming",
  236210: "Industrial Building Construction",
  311919: "Other Snack Food Manufacturing",
  921120: "Legislative Bodies",
  492210: "Local Messengers and Local Delivery",
  541890: "Other Services Related to Advertising",
  312112: "Bottled Water Manufacturing",
  312113: "Ice Manufacturing",
  312111: "Soft Drink Manufacturing",
  336612: "Boat Building",
  336611: "Ship Building and Repairing",
  512250: "Record Production and Distribution",
  112910: "Apiculture",
  541420: "Industrial Design Services",
  444120: "Paint and Wallpaper Stores",
  453220: "Gift, Novelty, and Souvenir Stores",
  111421: "Nursery and Tree Production",
  111422: "Floriculture Production",
  561730: "Landscaping Services",
  511191: "Greeting Card Publishers",
  812310: "Coin-Operated Laundries and Drycleaners",
  511110: "Newspaper Publishers",
  561492: "Court Reporting and Stenotype Services",
  561491: "Repossession Services",
  452319: "All Other General Merchandise Stores",
  327420: "Gypsum Product Manufacturing",
  326160: "Plastics Bottle Manufacturing",
  561499: "All Other Business Support Services",
  452311: "Warehouse Clubs and Supercenters",
  624230: "Emergency and Other Relief Services",
  335121: "Residential Electric Lighting Fixture Manufacturing",
  335122:
    "Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing",
  113210: "Forest Nurseries and Gathering of Forest Products",
  335129: "Other Lighting Equipment Manufacturing",
  487110: "Scenic and Sightseeing Transportation, Land",
  112340: "Poultry Hatcheries",
  448120: "Women\u2019s Clothing Stores",
  424480: "Fresh Fruit and Vegetable Merchant Wholesalers",
  721214: "Recreational and Vacation Camps (except Campgrounds)",
  541340: "Drafting Services",
  446110: "Pharmacies and Drug Stores",
  721211: "RV (Recreational Vehicle) Parks and Campgrounds",
  424720:
    "Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)",
  721191: "Bed-and-Breakfast Inns",
  423450:
    "Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers",
  711410:
    "Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures",
  485510: "Charter Bus Industry",
  511120: "Periodical Publishers",
  722515: "Snack and Nonalcoholic Beverage Bars",
  444190: "Other Building Material Dealers",
  424110: "Printing and Writing Paper Merchant Wholesalers",
  519110: "News Syndicates",
  313210: "Broadwoven Fabric Mills",
  722310: "Food Service Contractors",
  812210: "Funeral Homes and Funeral Services",
  221122: "Electric Power Distribution",
  332999: "All Other Miscellaneous Fabricated Metal Product Manufacturing",
  221121: "Electric Bulk Power Transmission and Control",
  332994: "Small Arms, Ordnance, and Ordnance Accessories Manufacturing",
  624120: "Services for the Elderly and Persons with Disabilities",
  332996: "Fabricated Pipe and Pipe Fitting Manufacturing",
  336214: "Travel Trailer and Camper Manufacturing",
  611630: "Language Schools",
  332991: "Ball and Roller Bearing Manufacturing",
  332992: "Small Arms Ammunition Manufacturing",
  332410: "Power Boiler and Heat Exchanger Manufacturing",
  335313: "Switchgear and Switchboard Apparatus Manufacturing",
  811420: "Reupholstery and Furniture Repair",
  523920: "Portfolio Management",
  115115: "Farm Labor Contractors and Crew Leaders",
  115114: "Postharvest Crop Activities (except Cotton Ginning)",
  115116: "Farm Management Services",
  115111: "Cotton Ginning",
  115113: "Crop Harvesting, Primarily by Machine",
  115112: "Soil Preparation, Planting, and Cultivating",
  713910: "Golf Courses and Country Clubs",
  212291: "Uranium-Radium-Vanadium Ore Mining",
  336370: "Motor Vehicle Metal Stamping",
  336111: "Automobile Manufacturing",
  335220: "Major Household Appliance Manufacturing",
  423210: "Furniture Merchant Wholesalers",
  212299: "All Other Metal Ore Mining",
  324122: "Asphalt Shingle and Coating Materials Manufacturing",
  324121: "Asphalt Paving Mixture and Block Manufacturing",
  445110: "Supermarkets and Other Grocery (except Convenience) Stores",
  488310: "Port and Harbor Operations",
  541519: "Other Computer Related Services",
  311314: "Cane Sugar Manufacturing",
  525990: "Other Financial Vehicles",
  333112:
    "Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing",
  311313: "Beet Sugar Manufacturing",
  322220: "Paper Bag and Coated and Treated Paper Manufacturing",
  238110: "Poured Concrete Foundation and Structure Contractors",
  325414: "Biological Product (except Diagnostic) Manufacturing",
  485210: "Interurban and Rural Bus Transportation",
  531210: "Offices of Real Estate Agents and Brokers",
  313320: "Fabric Coating Mills",
  623220: "Residential Mental Health and Substance Abuse Facilities",
  423690: "Other Electronic Parts and Equipment Merchant Wholesalers",
  812320: "Drycleaning and Laundry Services (except Coin-Operated)",
  442291: "Window Treatment Stores",
  926150:
    "Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors",
  711211: "Sports Teams and Clubs",
  442299: "All Other Home Furnishings Stores",
  311920: "Coffee and Tea Manufacturing",
  311615: "Poultry Processing",
  562998: "All Other Miscellaneous Waste Management Services",
  531320: "Offices of Real Estate Appraisers",
  311611: "Animal (except Poultry) Slaughtering",
  311612: "Meat Processed from Carcasses",
  311613: "Rendering and Meat Byproduct Processing",
  336213: "Motor Home Manufacturing",
  336212: "Truck Trailer Manufacturing",
  336211: "Motor Vehicle Body Manufacturing",
  451140: "Musical Instrument and Supplies Stores",
  453910: "Pet and Pet Supplies Stores",
  315990: "Apparel Accessories and Other Apparel Manufacturing",
  321918: "Other Millwork (including Flooring)",
  335312: "Motor and Generator Manufacturing",
  335311: "Power, Distribution, and Specialty Transformer Manufacturing",
  711212: "Racetracks",
  524210: "Insurance Agencies and Brokerages",
  335314: "Relay and Industrial Control Manufacturing",
  321911: "Wood Window and Door Manufacturing",
  321912: "Cut Stock, Resawing Lumber, and Planing",
  221330: "Steam and Air-Conditioning Supply",
  325411: "Medicinal and Botanical Manufacturing",
  928110: "National Security",
  325413: "In-Vitro Diagnostic Substance Manufacturing",
  325412: "Pharmaceutical Preparation Manufacturing",
  813219: "Other Grantmaking and Giving Services",
  485999: "All Other Transit and Ground Passenger Transportation",
  424820: "Wine and Distilled Alcoholic Beverage Merchant Wholesalers",
  522120: "Savings Institutions",
  541219: "Other Accounting Services",
  423930: "Recyclable Material Merchant Wholesalers",
  485991: "Special Needs Transportation",
  813319: "Other Social Advocacy Organizations",
  311812: "Commercial Bakeries",
  311813: "Frozen Cakes, Pies, and Other Pastries Manufacturing",
  311811: "Retail Bakeries",
  483111: "Deep Sea Freight Transportation",
  315190: "Other Apparel Knitting Mills",
  924110:
    "Administration of Air and Water Resource and Solid Waste Management Programs",
  622110: "General Medical and Surgical Hospitals",
  925110: "Administration of Housing Programs",
  522291: "Consumer Lending",
  533110:
    "Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)",
  812191: "Diet and Weight Reducing Centers",
  111930: "Sugarcane Farming",
  325110: "Petrochemical Manufacturing",
  332119: "Metal Crown, Closure, and Other Metal Stamping (except Automotive)",
  926120: "Regulation and Administration of Transportation Programs",
  322130: "Paperboard Mills",
  332114: "Custom Roll Forming",
  812199: "Other Personal Care Services",
  332117: "Powder Metallurgy Part Manufacturing",
  326121: "Unlaminated Plastics Profile Shape Manufacturing",
  332112: "Nonferrous Forging",
  493120: "Refrigerated Warehousing and Storage",
  551112: "Offices of Other Holding Companies",
  335991: "Carbon and Graphite Product Manufacturing",
  453310: "Used Merchandise Stores",
  482111: "Line-Haul Railroads",
  452210: "Department Stores",
  335999:
    "All Other Miscellaneous Electrical Equipment and Component Manufacturing",
  339910: "Jewelry and Silverware Manufacturing",
  532411:
    "Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing",
  541820: "Public Relations Agencies",
  921110: "Executive Offices",
  486990: "All Other Pipeline Transportation",
  327332: "Concrete Pipe Manufacturing",
  523991: "Trust, Fiduciary, and Custody Activities",
  327331: "Concrete Block and Brick Manufacturing",
  921130: "Public Finance Activities",
  336112: "Light Truck and Utility Vehicle Manufacturing",
  523999: "Miscellaneous Financial Investment Activities",
  922130: "Legal Counsel and Prosecution",
  721310: "Rooming and Boarding Houses, Dormitories, and Workers\u2019 Camps",
  611512: "Flight Training",
  112120: "Dairy Cattle and Milk Production",
  525920: "Trusts, Estates, and Agency Accounts",
  712190: "Nature Parks and Other Similar Institutions",
  713950: "Bowling Centers",
  446130: "Optical Goods Stores",
  333997: "Scale and Balance Manufacturing",
  334419: "Other Electronic Component Manufacturing",
  334418: "Printed Circuit Assembly (Electronic Assembly) Manufacturing",
  524113: "Direct Life Insurance Carriers",
  333996: "Fluid Power Pump and Motor Manufacturing",
  334413: "Semiconductor and Related Device Manufacturing",
  334412: "Bare Printed Circuit Board Manufacturing",
  531190: "Lessors of Other Real Estate Property",
  334417: "Electronic Connector Manufacturing",
  312140: "Distilleries",
  424470: "Meat and Meat Product Merchant Wholesalers",
  333511: "Industrial Mold Manufacturing",
  333994: "Industrial Process Furnace and Oven Manufacturing",
  333517: "Machine Tool Manufacturing",
  333515: "Cutting Tool and Machine Tool Accessory Manufacturing",
  333514: "Special Die and Tool, Die Set, Jig, and Fixture Manufacturing",
  444110: "Home Centers",
  333519: "Rolling Mill and Other Metalworking Machinery Manufacturing",
  423220: "Home Furnishing Merchant Wholesalers",
  337910: "Mattress Manufacturing",
  493190: "Other Warehousing and Storage",
  722330: "Mobile Food Services",
  211120: "Crude Petroleum Extraction",
  531390: "Other Activities Related to Real Estate",
  237120: "Oil and Gas Pipeline and Related Structures Construction",
  492110: "Couriers and Express Delivery Services",
  334112: "Computer Storage Device Manufacturing",
  541512: "Computer Systems Design Services",
  334111: "Electronic Computer Manufacturing",
  339994: "Broom, Brush, and Mop Manufacturing",
  518210: "Data Processing, Hosting, and Related Services",
  238160: "Roofing Contractors",
  424610: "Plastics Materials and Basic Forms and Shapes Merchant Wholesalers",
  334118:
    "Computer Terminal and Other Computer Peripheral Equipment Manufacturing",
  423520: "Coal and Other Mineral and Ore Merchant Wholesalers",
  925120:
    "Administration of Urban Planning and Community and Rural Development",
  311351: "Chocolate and Confectionery Manufacturing from Cacao Beans",
  315220: "Men\u2019s and Boys\u2019 Cut and Sew Apparel Manufacturing",
  311352: "Confectionery Manufacturing from Purchased Chocolate",
  532120:
    "Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing",
  813410: "Civic and Social Organizations",
  541940: "Veterinary Services",
  541513: "Computer Facilities Management Services",
  611699: "All Other Miscellaneous Schools and Instruction",
  722320: "Caterers",
  813990:
    "Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)",
  311211: "Flour Milling",
  311212: "Rice Milling",
  311213: "Malt Manufacturing",
  623110: "Nursing Care Facilities (Skilled Nursing Facilities)",
  423410: "Photographic Equipment and Supplies Merchant Wholesalers",
  713920: "Skiing Facilities",
  336340: "Motor Vehicle Brake System Manufacturing",
  486210: "Pipeline Transportation of Natural Gas",
  424910: "Farm Supplies Merchant Wholesalers",
  711219: "Other Spectator Sports",
  423730:
    "Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers",
  811113: "Automotive Transmission Repair",
  423820: "Farm and Garden Machinery and Equipment Merchant Wholesalers",
  927110: "Space Research and Technology",
  611692: "Automobile Driving Schools",
  332811: "Metal Heat Treating",
  423810:
    "Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers",
  541830: "Media Buying Agencies",
  423320:
    "Brick, Stone, and Related Construction Material Merchant Wholesalers",
  331110: "Iron and Steel Mills and Ferroalloy Manufacturing",
  621340:
    "Offices of Physical, Occupational and Speech Therapists, and Audiologists",
  522130: "Credit Unions",
  624310: "Vocational Rehabilitation Services",
  325920: "Explosives Manufacturing",
  311999: "All Other Miscellaneous Food Manufacturing",
  236118: "Residential Remodelers",
  322212: "Folding Paperboard Box Manufacturing",
  333120: "Construction Machinery Manufacturing",
  322211: "Corrugated and Solid Fiber Box Manufacturing",
  325510: "Paint and Coating Manufacturing",
  311991: "Perishable Prepared Food Manufacturing",
  236117: "New Housing For-Sale Builders",
  236116: "New Multifamily Housing Construction (except For-Sale Builders)",
  236115: "New Single-Family Housing Construction (except For-Sale Builders)",
  322219: "Other Paperboard Container Manufacturing",
  713210: "Casinos (except Casino Hotels)",
  814110: "Private Households",
  326122: "Plastics Pipe and Pipe Fitting Manufacturing",
  485320: "Limousine Service",
  111120: "Oilseed (except Soybean) Farming",
  336419:
    "Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing",
  523210: "Securities and Commodity Exchanges",
  921140: "Executive and Legislative Offices, Combined",
  111310: "Orange Groves",
  811219: "Other Electronic and Precision Equipment Repair and Maintenance",
  445230: "Fruit and Vegetable Markets",
  711510: "Independent Artists, Writers, and Performers",
  811198: "All Other Automotive Repair and Maintenance",
  316110: "Leather and Hide Tanning and Finishing",
  811211: "Consumer Electronics Repair and Maintenance",
  482112: "Short Line Railroads",
  811213: "Communication Equipment Repair and Maintenance",
  561410: "Document Preparation Services",
  511210: "Software Publishers",
  621320: "Offices of Optometrists",
  541720: "Research and Development in the Social Sciences and Humanities",
  441228: "Motorcycle, ATV, and All Other Motor Vehicle Dealers",
  424990: "Other Miscellaneous Nondurable Goods Merchant Wholesalers",
  113110: "Timber Tract Operations",
  522220: "Sales Financing",
  621910: "Ambulance Services",
  441222: "Boat Dealers",
  423940:
    "Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers",
  562910: "Remediation Services",
  238340: "Tile and Terrazzo Contractors",
  325991: "Custom Compounding of Purchased Resins",
  325992: "Photographic Film, Paper, Plate, and Chemical Manufacturing",
  561320: "Temporary Help Services",
  541330: "Engineering Services",
  325998:
    "All Other Miscellaneous Chemical Product and Preparation Manufacturing",
  484122: "General Freight Trucking, Long-Distance, Less Than Truckload",
  541110: "Offices of Lawyers",
  561110: "Office Administrative Services",
  336412: "Aircraft Engine and Engine Parts Manufacturing",
  523110: "Investment Banking and Securities Dealing",
  424340: "Footwear Merchant Wholesalers",
  488330: "Navigational Services to Shipping",
  337920: "Blind and Shade Manufacturing",
  532412:
    "Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing",
  111411: "Mushroom Production",
  114119: "Other Marine Fishing",
  611511: "Cosmetology and Barber Schools",
  333999: "All Other Miscellaneous General Purpose Machinery Manufacturing",
  611513: "Apprenticeship Training",
  114112: "Shellfish Fishing",
  111419: "Other Food Crops Grown Under Cover",
  333995: "Fluid Power Cylinder and Actuator Manufacturing",
  114111: "Finfish Fishing",
  333993: "Packaging Machinery Manufacturing",
  333992: "Welding and Soldering Equipment Manufacturing",
  333991: "Power-Driven Handtool Manufacturing",
  453920: "Art Dealers",
  448130: "Children\u2019s and Infants\u2019 Clothing Stores",
  541860: "Direct Mail Advertising",
  532420: "Office Machinery and Equipment Rental and Leasing",
  333414: "Heating Equipment (except Warm Air Furnaces) Manufacturing",
  333415:
    "Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing",
  333413:
    "Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing",
  611691: "Exam Preparation and Tutoring",
  511130: "Book Publishers",
  541618: "Other Management Consulting Services",
  541614: "Process, Physical Distribution, and Logistics Consulting Services",
  541613: "Marketing Consulting Services",
  541612: "Human Resources Consulting Services",
  541611:
    "Administrative Management and General Management Consulting Services",
  525910: "Open-End Investment Funds",
  562219: "Other Nonhazardous Waste Treatment and Disposal",
  311423: "Dried and Dehydrated Food Manufacturing",
  311422: "Specialty Canning",
  311421: "Fruit and Vegetable Canning",
  423110: "Automobile and Other Motor Vehicle Merchant Wholesalers",
  423440: "Other Commercial Equipment Merchant Wholesalers",
  424320:
    "Men\u2019s and Boys\u2019 Clothing and Furnishings Merchant Wholesalers",
  561920: "Convention and Trade Show Organizers",
  811122: "Automotive Glass Replacement Shops",
  922140: "Correctional Institutions",
  424490: "Other Grocery and Related Products Merchant Wholesalers",
  512132: "Drive-In Motion Picture Theaters",
  561910: "Packaging and Labeling Services",
  517410: "Satellite Telecommunications",
  512131: "Motion Picture Theaters (except Drive-Ins)",
  485119: "Other Urban Transit Systems",
  624110: "Child and Youth Services",
  488119: "Other Airport Operations",
  485113: "Bus and Other Motor Vehicle Transit Systems",
  485112: "Commuter Rail Systems",
  485111: "Mixed Mode Transit Systems",
  453110: "Florists",
  334310: "Audio and Video Equipment Manufacturing",
  623312: "Assisted Living Facilities for the Elderly",
  339991: "Gasket, Packing, and Sealing Device Manufacturing",
  811430: "Footwear and Leather Goods Repair",
  339993: "Fastener, Button, Needle, and Pin Manufacturing",
  311514: "Dry, Condensed, and Evaporated Dairy Product Manufacturing",
  311513: "Cheese Manufacturing",
  311512: "Creamery Butter Manufacturing",
  311511: "Fluid Milk Manufacturing",
  424920: "Book, Periodical, and Newspaper Merchant Wholesalers",
  339999: "All Other Miscellaneous Manufacturing",
  325320: "Pesticide and Other Agricultural Chemical Manufacturing",
  515111: "Radio Networks",
  326220: "Rubber and Plastics Hoses and Belting Manufacturing",
  524298: "All Other Insurance Related Activities",
  541870: "Advertising Material Distribution Services",
  524292: "Third Party Administration of Insurance and Pension Funds",
  331420: "Copper Rolling, Drawing, Extruding, and Alloying",
  531120: "Lessors of Nonresidential Buildings (except Miniwarehouses)",
  524291: "Claims Adjusting",
  212111: "Bituminous Coal and Lignite Surface Mining",
  212113: "Anthracite Mining",
  212112: "Bituminous Coal Underground Mining",
  481219: "Other Nonscheduled Air Transportation",
  423830: "Industrial Machinery and Equipment Merchant Wholesalers",
  561330: "Professional Employer Organizations",
  532112: "Passenger Car Leasing",
  481211: "Nonscheduled Chartered Passenger Air Transportation",
  481212: "Nonscheduled Chartered Freight Air Transportation",
  532111: "Passenger Car Rental",
  424710: "Petroleum Bulk Stations and Terminals",
  238120: "Structural Steel and Precast Concrete Contractors",
  454390: "Other Direct Selling Establishments",
  111219: "Other Vegetable (except Potato) and Melon Farming",
  811310:
    "Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance",
  325212: "Synthetic Rubber Manufacturing",
  325211: "Plastics Material and Resin Manufacturing",
  532490:
    "Other Commercial and Industrial Machinery and Equipment Rental and Leasing",
  325194: "Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing",
  311930: "Flavoring Syrup and Concentrate Manufacturing",
  562920: "Materials Recovery Facilities",
  111211: "Potato Farming",
  541350: "Building Inspection Services",
  238310: "Drywall and Insulation Contractors",
  448110: "Men\u2019s Clothing Stores",
  424810: "Beer and Ale Merchant Wholesalers",
  339920: "Sporting and Athletic Goods Manufacturing",
  621310: "Offices of Chiropractors",
  447110: "Gasoline Stations with Convenience Stores",
  335911: "Storage Battery Manufacturing",
  923130:
    "Administration of Human Resource Programs (except Education, Public Health, and Veterans\u2019 Affairs Programs)",
  335912: "Primary Battery Manufacturing",
  331491:
    "Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding",
  331492:
    "Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum)",
  212230: "Copper, Nickel, Lead, and Zinc Mining",
  336310: "Motor Vehicle Gasoline Engine and Engine Parts Manufacturing",
  335929: "Other Communication and Energy Wire Manufacturing",
  611110: "Elementary and Secondary Schools",
  337211: "Wood Office Furniture Manufacturing",
  337212: "Custom Architectural Woodwork and Millwork Manufacturing",
  337214: "Office Furniture (except Wood) Manufacturing",
  337215: "Showcase, Partition, Shelving, and Locker Manufacturing",
  561520: "Tour Operators",
  332510: "Hardware Manufacturing",
  519120: "Libraries and Archives",
  238220: "Plumbing, Heating, and Air-Conditioning Contractors",
  316992: "Women\u2019s Handbag and Purse Manufacturing",
  813930: "Labor Unions and Similar Labor Organizations",
  454310: "Fuel Dealers",
  923140: "Administration of Veterans\u2019 Affairs",
  111910: "Tobacco Farming",
  623311: "Continuing Care Retirement Communities",
  316998: "All Other Leather Good and Allied Product Manufacturing",
  483212: "Inland Water Passenger Transportation",
  424130: "Industrial and Personal Service Paper Merchant Wholesalers",
  483211: "Inland Water Freight Transportation",
  111320: "Citrus (except Orange) Groves",
  532282: "Video Tape and Disc Rental",
  532283: "Home Health Equipment Rental",
  561422: "Telemarketing Bureaus and Other Contact Centers",
  561421: "Telephone Answering Services",
  926130:
    "Regulation and Administration of Communications, Electric, Gas, and Other Utilities",
  423710: "Hardware Merchant Wholesalers",
  621210: "Offices of Dentists",
  326130:
    "Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing",
  812113: "Nail Salons",
  541120: "Offices of Notaries",
  517911: "Telecommunications Resellers",
  522310: "Mortgage and Nonmortgage Loan Brokers",
  112330: "Turkey Production",
  441310: "Automotive Parts and Accessories Stores",
  517919: "All Other Telecommunications",
  334416:
    "Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing",
  327320: "Ready-Mix Concrete Manufacturing",
  623210: "Residential Intellectual and Developmental Disability Facilities",
  541713: "Research and Development in Nanotechnology",
  541714:
    "Research and Development in Biotechnology (except Nanobiotechnology)",
  541715:
    "Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)",
  112930: "Fur-Bearing Animal and Rabbit Production",
  541850: "Outdoor Advertising",
  424590: "Other Farm Product Raw Material Merchant Wholesalers",
  811121: "Automotive Body, Paint, and Interior Repair and Maintenance",
  313240: "Knit Fabric Mills",
  311119: "Other Animal Food Manufacturing",
  611420: "Computer Training",
  311111: "Dog and Cat Food Manufacturing",
  423920: "Toy and Hobby Goods and Supplies Merchant Wholesalers",
  713930: "Marinas",
  446120: "Cosmetics, Beauty Supplies, and Perfume Stores",
  334516: "Analytical Laboratory Instrument Manufacturing",
  334517: "Irradiation Apparatus Manufacturing",
  334514: "Totalizing Fluid Meter and Counting Device Manufacturing",
  334515:
    "Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals",
  334512:
    "Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use",
  334513:
    "Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables",
  334510: "Electromedical and Electrotherapeutic Apparatus Manufacturing",
  334511:
    "Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing",
  451211: "Book Stores",
  524114: "Direct Health and Medical Insurance Carriers",
  523130: "Commodity Contracts Dealing",
  451212: "News Dealers and Newsstands",
  424440: "Poultry and Poultry Product Merchant Wholesalers",
  541840: "Media Representatives",
  326140: "Polystyrene Foam Product Manufacturing",
  334519: "Other Measuring and Controlling Device Manufacturing",
  484121: "General Freight Trucking, Long-Distance, Truckload",
  924120: "Administration of Conservation Programs",
  541620: "Environmental Consulting Services",
  561621: "Security Systems Services (except Locksmiths)",
  237110: "Water and Sewer Line and Related Structures Construction",
  511199: "All Other Publishers",
  561622: "Locksmiths",
  311412: "Frozen Specialty Food Manufacturing",
  423120: "Motor Vehicle Supplies and New Parts Merchant Wholesalers",
  311411: "Frozen Fruit, Juice, and Vegetable Manufacturing",
  524130: "Reinsurance Carriers",
  424210: "Drugs and Druggists\u2019 Sundries Merchant Wholesalers",
  621511: "Medical Laboratories",
  712130: "Zoos and Botanical Gardens",
  339992: "Musical Instrument Manufacturing",
  713940: "Fitness and Recreational Sports Centers",
  322299: "All Other Converted Paper Product Manufacturing",
  325613: "Surface Active Agent Manufacturing",
  325612: "Polish and Other Sanitation Good Manufacturing",
  325611: "Soap and Other Detergent Manufacturing",
  523140: "Commodity Contracts Brokerage",
  315210: "Cut and Sew Apparel Contractors",
  322291: "Sanitary Paper Product Manufacturing",
  424690: "Other Chemical and Allied Products Merchant Wholesalers",
  339113: "Surgical Appliance and Supplies Manufacturing",
  339112: "Surgical and Medical Instrument Manufacturing",
  339115: "Ophthalmic Goods Manufacturing",
  339114: "Dental Equipment and Supplies Manufacturing",
  339116: "Dental Laboratories",
  339995: "Burial Casket Manufacturing",
  562211: "Hazardous Waste Treatment and Disposal",
  562212: "Solid Waste Landfill",
  562213: "Solid Waste Combustors and Incinerators",
  621420: "Outpatient Mental Health and Substance Abuse Centers",
  112111: "Beef Cattle Ranching and Farming",
  112112: "Cattle Feedlots",
  813212: "Voluntary Health Organizations",
  115310: "Support Activities for Forestry",
  331513: "Steel Foundries (except Investment)",
  624410: "Child Day Care Services",
  325312: "Phosphatic Fertilizer Manufacturing",
  325311: "Nitrogenous Fertilizer Manufacturing",
  448150: "Clothing Accessories Stores",
  561450: "Credit Bureaus",
  325314: "Fertilizer (Mixing Only) Manufacturing",
  423330: "Roofing, Siding, and Insulation Material Merchant Wholesalers",
  238210: "Electrical Contractors and Other Wiring Installation Contractors",
  451130: "Sewing, Needlework, and Piece Goods Stores",
  111140: "Wheat Farming",
  332323: "Ornamental and Architectural Metal Work Manufacturing",
  332322: "Sheet Metal Work Manufacturing",
  332321: "Metal Window and Door Manufacturing",
  525120: "Health and Welfare Funds",
  812930: "Parking Lots and Garages",
  811192: "Car Washes",
  423620:
    "Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers",
  811191: "Automotive Oil Change and Lubrication Shops",
  331511: "Iron Foundries",
  311520: "Ice Cream and Frozen Dessert Manufacturing",
  331512: "Steel Investment Foundries",
  315280: "Other Cut and Sew Apparel Manufacturing",
  333131: "Mining Machinery and Equipment Manufacturing",
  333132: "Oil and Gas Field Machinery and Equipment Manufacturing",
  326212: "Tire Retreading",
  721120: "Casino Hotels",
  336350: "Motor Vehicle Transmission and Power Train Parts Manufacturing",
  326211: "Tire Manufacturing (except Retreading)",
  325910: "Printing Ink Manufacturing",
  485410: "School and Employee Bus Transportation",
  541922: "Commercial Photography",
  561710: "Exterminating and Pest Control Services",
  454210: "Vending Machine Operators",
  336360: "Motor Vehicle Seating and Interior Trim Manufacturing",
  238170: "Siding Contractors",
  238910: "Site Preparation Contractors",
  611410: "Business and Secretarial Schools",
  111130: "Dry Pea and Bean Farming",
  562991: "Septic Tank and Related Services",
  311941: "Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing",
  311942: "Spice and Extract Manufacturing",
  541430: "Graphic Design Services",
  115210: "Support Activities for Animal Production",
  611610: "Fine Arts Schools",
  541921: "Photography Studios, Portrait",
  541930: "Translation and Interpretation Services",
  541360: "Geophysical Surveying and Mapping Services",
  445220: "Fish and Seafood Markets",
  522210: "Credit Card Issuing",
  333314: "Optical Instrument and Lens Manufacturing",
  333316: "Photographic and Photocopying Equipment Manufacturing",
  333318: "Other Commercial and Service Industry Machinery Manufacturing",
  325220: "Artificial and Synthetic Fibers and Filaments Manufacturing",
  221310: "Water Supply and Irrigation Systems",
  453210: "Office Supplies and Stationery Stores",
  561510: "Travel Agencies",
  541320: "Landscape Architectural Services",
  441210: "Recreational Vehicle Dealers",
  332216: "Saw Blade and Handtool Manufacturing",
  332215:
    "Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing",
  531110: "Lessors of Residential Buildings and Dwellings",
  621991: "Blood and Organ Banks",
  522190: "Other Depository Credit Intermediation",
  332420: "Metal Tank (Heavy Gauge) Manufacturing",
  237990: "Other Heavy and Civil Engineering Construction",
  238350: "Finish Carpentry Contractors",
  313110: "Fiber, Yarn, and Thread Mills",
  928120: "International Affairs",
  484110: "General Freight Trucking, Local",
  486910: "Pipeline Transportation of Refined Petroleum Products",
  517311: "Wired Telecommunications Carriers",
  512230: "Music Publishers",
  522298: "All Other Nondepository Credit Intermediation",
  517312: "Wireless Telecommunications Carriers (except Satellite)",
  314910: "Textile Bag and Canvas Mills",
  812332: "Industrial Launderers",
  812331: "Linen Supply",
  333922: "Conveyor and Conveying Equipment Manufacturing",
  112390: "Other Poultry Production",
  312130: "Wineries",
  493130: "Farm Product Warehousing and Storage",
  623990: "Other Residential Care Facilities",
  561612: "Security Guards and Patrol Services",
  488490: "Other Support Activities for Road Transportation",
  624210: "Community Food Services",
  624190: "Other Individual and Family Services",
  621999: "All Other Miscellaneous Ambulatory Health Care Services",
  445291: "Baked Goods Stores",
  445292: "Confectionery and Nut Stores",
  423840: "Industrial Supplies Merchant Wholesalers",
  325130: "Synthetic Dye and Pigment Manufacturing",
  424520: "Livestock Merchant Wholesalers",
  322110: "Pulp Mills",
  327215: "Glass Product Manufacturing Made of Purchased Glass",
  541199: "All Other Legal Services",
  541211: "Offices of Certified Public Accountants",
  333914: "Measuring, Dispensing, and Other Pumping Equipment Manufacturing",
  327211: "Flat Glass Manufacturing",
  333912: "Air and Gas Compressor Manufacturing",
  327213: "Glass Container Manufacturing",
  322121: "Paper (except Newsprint) Mills",
  541191: "Title Abstract and Settlement Offices",
  213114: "Support Activities for Metal Mining",
  213115: "Support Activities for Nonmetallic Minerals (except Fuels) Mining",
  213112: "Support Activities for Oil and Gas Operations",
  213113: "Support Activities for Coal Mining",
  481111: "Scheduled Passenger Air Transportation",
  213111: "Drilling Oil and Gas Wells",
  321113: "Sawmills",
  321114: "Wood Preservation",
  922150: "Parole Offices and Probation Offices",
  424310: "Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers",
  813940: "Political Organizations",
  621410: "Family Planning Centers",
  112410: "Sheep Farming",
  332722: "Bolt, Nut, Screw, Rivet, and Washer Manufacturing",
  332721: "Precision Turned Product Manufacturing",
  519130: "Internet Publishing and Broadcasting and Web Search Portals",
  813110: "Religious Organizations",
  624229: "Other Community Housing Services",
  561740: "Carpet and Upholstery Cleaning Services",
  512120: "Motion Picture and Video Distribution",
  721199: "All Other Traveler Accommodation",
  515210: "Cable and Other Subscription Programming",
  238320: "Painting and Wall Covering Contractors",
  424410: "General Line Grocery Merchant Wholesalers",
  313230: "Nonwoven Fabric Mills",
  511140: "Directory and Mailing List Publishers",
  813312: "Environment, Conservation and Wildlife Organizations",
  212324: "Kaolin and Ball Clay Mining",
  326199: "All Other Plastics Product Manufacturing",
  326191: "Plastics Plumbing Fixture Manufacturing",
  331222: "Steel Wire Drawing",
  331221: "Rolled Steel Shape Manufacturing",
  532210: "Consumer Electronics and Appliances Rental",
  424950: "Paint, Varnish, and Supplies Merchant Wholesalers",
  448210: "Shoe Stores",
  621399: "Offices of All Other Miscellaneous Health Practitioners",
  448190: "Other Clothing Stores",
  515120: "Television Broadcasting",
  621391: "Offices of Podiatrists",
  238140: "Masonry Contractors",
  111991: "Sugar Beet Farming",
  325620: "Toilet Preparation Manufacturing",
  111992: "Peanut Farming",
  424330:
    "Women\u2019s, Children\u2019s, and Infants\u2019 Clothing and Accessories Merchant Wholesalers",
  315240:
    "Women\u2019s, Girls\u2019, and Infants\u2019 Cut and Sew Apparel Manufacturing",
  331410: "Nonferrous Metal (except Aluminum) Smelting and Refining",
  111998: "All Other Miscellaneous Crop Farming",
  332813: "Electroplating, Plating, Polishing, Anodizing, and Coloring",
  332812:
    "Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers",
  531130: "Lessors of Miniwarehouses and Self-Storage Units",
  336390: "Other Motor Vehicle Parts Manufacturing",
  332613: "Spring Manufacturing",
  512191: "Teleproduction and Other Postproduction Services",
  323120: "Support Activites for Printing",
  711120: "Dance Companies",
  336411: "Aircraft Manufacturing",
  512199: "Other Motion Picture and Video Industries",
  336413: "Other Aircraft Parts and Auxiliary Equipment Manufacturing",
  332618: "Other Fabricated Wire Product Manufacturing",
  336415:
    "Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing",
  336414: "Guided Missile and Space Vehicle Manufacturing",
  311230: "Breakfast Cereal Manufacturing",
  336999: "All Other Transportation Equipment Manufacturing",
  331315: "Aluminum Sheet, Plate, and Foil Manufacturing",
  331314: "Secondary Smelting and Alloying of Aluminum",
  331313: "Alumina Refining and Primary Aluminum Production",
  487990: "Scenic and Sightseeing Transportation, Other",
  448320: "Luggage and Leather Goods Stores",
  336991: "Motorcycle, Bicycle, and Parts Manufacturing",
  336992: "Military Armored Vehicle, Tank, and Tank Component Manufacturing",
  238130: "Framing Contractors",
  561599: "All Other Travel Arrangement and Reservation Services",
  331318: "Other Aluminum Rolling, Drawing, and Extruding",
  522390: "Other Activities Related to Credit Intermediation",
  611710: "Educational Support Services",
  112320: "Broilers and Other Meat Type Chicken Production",
  541490: "Other Specialized Design Services",
  325180: "Other Basic Inorganic Chemical Manufacturing",
  111199: "All Other Grain Farming",
  314120: "Curtain and Linen Mills",
  339930: "Doll, Toy, and Game Manufacturing",
  111191: "Oilseed and Grain Combination Farming",
  335921: "Fiber Optic Cable Manufacturing",
  444210: "Outdoor Power Equipment Stores",
  923120: "Administration of Public Health Programs",
  325199: "All Other Basic Organic Chemical Manufacturing",
  212222: "Silver Ore Mining",
  212221: "Gold Ore Mining",
  423910: "Sporting and Recreational Goods and Supplies Merchant Wholesalers",
  111920: "Cotton Farming",
  324199: "All Other Petroleum and Coal Products Manufacturing",
  238390: "Other Building Finishing Contractors",
  332312: "Fabricated Structural Metal Manufacturing",
  332313: "Plate Work Manufacturing",
  332311: "Prefabricated Metal Building and Component Manufacturing",
  221210: "Natural Gas Distribution",
  541810: "Advertising Agencies",
  324191: "Petroleum Lubricating Oil and Grease Manufacturing",
  447190: "Other Gasoline Stations",
  813920: "Professional Organizations",
  812112: "Beauty Salons",
  311830: "Tortilla Manufacturing",
  525110: "Pension Funds",
  811490: "Other Personal and Household Goods Repair and Maintenance",
  812111: "Barber Shops",
  111335: "Tree Nut Farming",
  111334: "Berry (except Strawberry) Farming",
  111336: "Fruit and Tree Nut Combination Farming",
  111331: "Apple Orchards",
  541380: "Testing Laboratories",
  111333: "Strawberry Farming",
  111332: "Grape Vineyards",
  333924:
    "Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing",
  111339: "Other Noncitrus Fruit Farming",
  333923: "Overhead Traveling Crane, Hoist, and Monorail System Manufacturing",
  333921: "Elevator and Moving Stairway Manufacturing",
  561431: "Private Mail Centers",
  445310: "Beer, Wine, and Liquor Stores",
  486110: "Pipeline Transportation of Crude Oil",
  522320:
    "Financial Transactions Processing, Reserve, and Clearinghouse Activities",
  327910: "Abrasive Product Manufacturing",
  561439: "Other Business Service Centers (including Copy Shops)",
  713110: "Amusement and Theme Parks",
  325193: "Ethyl Alcohol Manufacturing",
  611620: "Sports and Recreation Instruction",
  423390: "Other Construction Material Merchant Wholesalers",
  339940: "Office Supplies (except Paper) Manufacturing",
  441320: "Tire Dealers",
  334210: "Telephone Apparatus Manufacturing",
  237210: "Land Subdivision",
  522292: "Real Estate Credit",
  212312: "Crushed and Broken Limestone Mining and Quarrying",
  327310: "Cement Manufacturing",
  622210: "Psychiatric and Substance Abuse Hospitals",
  488111: "Air Traffic Control",
  212319: "Other Crushed and Broken Stone Mining and Quarrying",
  212313: "Crushed and Broken Granite Mining and Quarrying",
  236220: "Commercial and Institutional Building Construction",
  212311: "Dimension Stone Mining and Quarrying"
};

export default NAICCodes;

export const IndustryType = [];

Object.values(NAICCodes).forEach(code => {
  IndustryType.push({
    key: code.toString(),
    value: code.toString(),
    text: code.toString()
  });
});

export const IndustrySearchKeyNAICs = [];
Object.keys(NAICCodes).forEach(k => {
  IndustrySearchKeyNAICs.push({
    key: k,
    value: k,
    text: NAICCodes[k],
    title: NAICCodes[k]
  });
});
