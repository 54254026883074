import React from "react";
import { Route } from "react-router-dom";
import DocTitle from "../../components/DocTitle";

import CurrentRequests from "./scenes";

function CurrentRequestObj() {
  return (
    <div>
      <DocTitle title="Current Requests - BLAST Portal">
        <Route
          exact
          path="/application-phases"
          render={() => <CurrentRequests source="LOS" />}
        />
      </DocTitle>
    </div>
  );
}

export default CurrentRequestObj;
