import React from "react";
import { Route } from "react-router-dom";

import LorDocApproval from "./scenes/LorDocApproval";
import TicklersExceptions from "./scenes/TicklersExceptions";

function CreditManagement() {
  return (
    <div>
      <Route path="/docs/mgmt" component={LorDocApproval} />
      <Route path="/docs/ticklers_exceptions/" component={TicklersExceptions} />
    </div>
  );
}

export default CreditManagement;
