import React, { useEffect, useState, useCallback } from "react";
import { IntlProvider } from "react-intl";
import PropTypes from "prop-types";
import { Header, Icon, Label, Checkbox, Button } from "semantic-ui-react";
import "./index.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { SearchListDropdown } from "@bafsllc/ui-shared";
import AnalysisSelection from "./Components/AnalysisSelection";
import Constants from "../../../../../../../../services/Constants/strings";
import messages from "../../../../../../../../assets/messages/common.en-US.json";
import { concealTin } from "../../../../../../../../services/FormatTin";

function EntityAnalysisTable({
  rows,
  rowsToShow,
  handleAddRow,
  handleAnalysisSelectionChange,
  handleUpdateEntityAnalyses,
  handleSelectRow,
  handleSelectAll,
  handleRemoveRows,
  handleSortRows
}) {
  const SORT_ASC = "ASC";
  const SORT_DESC = "DESC";
  const ascIconName = "chevron down";
  const descIconName = "chevron up";

  const { CREDIT_MEMO_ADD_ENTITY_MESSAGE } = Constants;

  const [dropdownListItems, setDropdownListItems] = useState([]);
  const [tableRows, setTableRows] = useState();
  const [removeButtonDisabled, setRemoveButtonDisabled] = useState(true);
  const [allCheckBoxChecked, setAllCheckBoxChecked] = useState(true);
  const [entitySorted, setEntitySorted] = useState();
  const [typeSorted, setTypeSorted] = useState();

  useEffect(() => {
    if (rows?.length) {
      const dropdownList = rows
        .filter(row => !row.active)
        .map(row => {
          const { entityType, name, tin, uuid } = row;
          return {
            key: uuid,
            searchableText: `${tin} | ${name} | ${entityType}`,
            displayText: name,
            entityType,
            tin,
            concealedTin: concealTin(tin, entityType)
          };
        });

      setDropdownListItems(dropdownList);
    }
  }, [rows]);

  useEffect(() => {
    setTableRows(rowsToShow);

    if (rowsToShow?.length) {
      setRemoveButtonDisabled(
        !rowsToShow.some(rowToShow => rowToShow?.checked)
      );
      setAllCheckBoxChecked(rowsToShow.every(rowToShow => rowToShow?.checked));
    } else {
      setRemoveButtonDisabled(true);
      setAllCheckBoxChecked(false);
    }
  }, [rowsToShow]);

  const sortByProperty = (data, propertyName, sortOrder) => {
    const sortedData = data?.length ? [...data] : [];
    sortedData.sort((datum1, datum2) => {
      const propertyValue1 = datum1[propertyName];
      const propertyValue2 = datum2[propertyName];
      const elem1 = Array.isArray(propertyValue1)
        ? propertyValue1[0]
        : propertyValue1;
      const elem2 = Array.isArray(propertyValue2)
        ? propertyValue2[0]
        : propertyValue2;
      if (sortOrder === SORT_ASC) {
        return elem1.toLowerCase() <= elem2.toLowerCase() ? -1 : 1;
      }
      return elem1.toLowerCase() < elem2.toLowerCase() ? 1 : -1;
    });
    return sortedData;
  };

  const handleHeaderClick = headerName => {
    const headerSorted = headerName === "name" ? entitySorted : typeSorted;
    const nextSort =
      !headerSorted || headerSorted === SORT_DESC ? SORT_ASC : SORT_DESC;
    const sortedRows = sortByProperty(tableRows, headerName, nextSort);
    setTableRows(sortedRows);
    handleSortRows(sortedRows);
    if (headerName === "name") {
      setEntitySorted(nextSort);
      setTypeSorted(SORT_ASC);
    } else {
      setTypeSorted(nextSort);
      setEntitySorted(SORT_ASC);
    }
  };

  const handleDropdownListSelection = item => {
    const { key } = item;
    const previousDropdownListItems = dropdownListItems?.length
      ? [...dropdownListItems]
      : [];
    handleAddRow(key);
    setDropdownListItems(
      [...previousDropdownListItems].filter(option => option.key !== key)
    );
  };

  const onDragEnd = useCallback(
    event => {
      const arr = tableRows.slice();
      const item = arr.splice(event.source.index, 1)[0];
      arr.splice(event.destination.index, 0, item);
      handleUpdateEntityAnalyses(arr);
    },
    [tableRows, handleUpdateEntityAnalyses]
  );

  function makeCustomListItem({ key, displayText, entityType, concealedTin }) {
    return (
      <li
        data-item-key={key}
        key={key}
        className="flex flex-col !items-start justify-start p-4 item-custom"
      >
        <div className="font-semibold text-slate-950 mb-1">{displayText}</div>
        {entityType && (
          <div className="text-neutral-600 mb-1">{entityType}</div>
        )}
        <div className="text-neutral-600">{concealedTin}</div>
      </li>
    );
  }

  return (
    <IntlProvider locale="en" messages={messages}>
      <div data-testid="entity-analysis-table">
        <Header size="small">Entity Analysis Selection</Header>
        <div className="ui grid">
          <div className="eight wide column">
            <p>Select one Analysis for each business entity.</p>
          </div>
          <div className="seven wide column right aligned">
            <Button
              className="grey basic"
              data-testid="remove-entity-button"
              disabled={removeButtonDisabled}
              onClick={handleRemoveRows}
            >
              <Icon name="close" className="red" /> Remove
            </Button>{" "}
            {dropdownListItems && (
              <SearchListDropdown
                makeCustomListItem={makeCustomListItem}
                updateDropdownClassnames={initialClassNames => [
                  ...initialClassNames,
                  "EntityAnalysisTable-AddExisting"
                ]}
                items={dropdownListItems}
                onChange={handleDropdownListSelection}
                icon={<i className="icon chevron down" />}
                button={{
                  message: {
                    id: "ADD_EXISTING",
                    defaultMessage: "Add Existing"
                  },
                  iconLeft: { ariaLabel: { id: "ICON" }, icon: "plus" }
                }}
                placeholder="Entity TIN/Name..."
              />
            )}
          </div>
        </div>
        <div className="ui segment basic">
          <div className="ui grid">
            <div className="five wide column">
              <div className="ui grid">
                <div className="one wide column">
                  <Checkbox
                    data-testid="entity-name-header-checkbox"
                    onChange={() => handleSelectAll(allCheckBoxChecked)}
                    checked={allCheckBoxChecked}
                    disabled={!tableRows?.length}
                  />
                </div>
                <div
                  className="nine wide column"
                  data-testid="entity-button"
                  role="button"
                  onClick={() => handleHeaderClick("name")}
                  onKeyDown={() => handleHeaderClick("name")}
                  tabIndex={0}
                >
                  Entity{" "}
                  <Icon
                    name={
                      !entitySorted || entitySorted === SORT_ASC
                        ? ascIconName
                        : descIconName
                    }
                  />
                </div>
              </div>
            </div>
            <div
              data-testid="type-button"
              className="five wide column"
              role="button"
              onClick={() => handleHeaderClick("types")}
              onKeyDown={() => handleHeaderClick("types")}
              tabIndex={0}
            >
              Type{" "}
              <Icon
                name={
                  !typeSorted || typeSorted === SORT_ASC
                    ? ascIconName
                    : descIconName
                }
              />
            </div>
            <div
              data-testid="analysis-selection-sort-button"
              className="five wide column"
            >
              Analysis Selection
            </div>
          </div>
        </div>
        {tableRows?.length ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-1">
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <AnalysisList
                    tableRows={tableRows}
                    handleSelectRow={handleSelectRow}
                    handleAnalysisSelectionChange={
                      handleAnalysisSelectionChange
                    }
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <div
            className="ui centered grid segment very padded"
            style={{ border: "dashed grey" }}
          >
            <div className="centered aligned six wide column">
              <h4>Add Existing Entity</h4>
              <p>{CREDIT_MEMO_ADD_ENTITY_MESSAGE}</p>
            </div>
          </div>
        )}
      </div>
    </IntlProvider>
  );
}

EntityAnalysisTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  rowsToShow: PropTypes.arrayOf(PropTypes.object),
  handleAddRow: PropTypes.func,
  handleAnalysisSelectionChange: PropTypes.func,
  handleSelectRow: PropTypes.func,
  handleSelectAll: PropTypes.func,
  handleRemoveRows: PropTypes.func,
  handleUpdateEntityAnalyses: PropTypes.func,
  handleSortRows: PropTypes.func
};

export default EntityAnalysisTable;

function AnalysisItem({
  index,
  tableRow,
  handleSelectRow,
  handleAnalysisSelectionChange
}) {
  return (
    <Draggable draggableId={`draggable-${index}`} index={index}>
      {provided => (
        <div
          className="ui segment"
          key={tableRow.uuid + tableRow.type}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className="ui grid">
            <div className="five wide column" data-testid="entity-name">
              <div className="ui grid">
                <div className="one wide column">
                  <Checkbox
                    data-testid="entity-name-checkbox"
                    onChange={() => handleSelectRow(tableRow.uuid)}
                    checked={tableRow.checked}
                  />
                </div>
                <div className="nine wide column">{tableRow.name}</div>
              </div>
            </div>
            <div className="five wide column" data-testid="entity-type">
              {tableRow.types.map(type => (
                <Label key={type} basic color="green">
                  {type}
                </Label>
              ))}
            </div>
            <div className="five wide column">
              <AnalysisSelection
                analysisList={tableRow.financialAnalysis}
                defaultAnalysis={tableRow.selectedAnalysis}
                onAnalysisChange={selectedAnalysis =>
                  handleAnalysisSelectionChange(selectedAnalysis, tableRow.uuid)
                }
              />
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}

AnalysisItem.propTypes = {
  index: PropTypes.number.isRequired,
  tableRow: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    type: PropTypes.string,
    checked: PropTypes.bool,
    name: PropTypes.string.isRequired,
    types: PropTypes.arrayOf(PropTypes.string).isRequired,
    financialAnalysis: PropTypes.array.isRequired,
    selectedAnalysis: PropTypes.string.isRequired
  }).isRequired,
  handleSelectRow: PropTypes.func.isRequired,
  handleAnalysisSelectionChange: PropTypes.func.isRequired
};

const AnalysisList = React.memo(
  ({ tableRows, handleSelectRow, handleAnalysisSelectionChange }) =>
    tableRows.map((tableRow, index) => (
      <AnalysisItem
        key={tableRow.uuid}
        index={index}
        handleSelectRow={handleSelectRow}
        tableRow={tableRow}
        handleAnalysisSelectionChange={handleAnalysisSelectionChange}
      />
    ))
);

AnalysisList.propTypes = {
  tableRows: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired
    })
  ).isRequired,
  handleSelectRow: PropTypes.func.isRequired,
  handleAnalysisSelectionChange: PropTypes.func.isRequired
};

AnalysisList.displayName = "AnalysisList";
