import { Route, useParams } from "react-router-dom";
import LoanOriginationPrepareDocsHeader from "./components/LoanOriginationPrepareDocsHeader";
import { PrepareDocsTabs, TabObject } from "./components/PrepareDocsTabs";
import LoanData from "./scenes/LoanData";

const tabs: TabObject[] = [
  { name: "Parties Involved", slug: "parties-involved" },
  { name: "Loan Data", slug: "loan-data" },
  { name: "Collateral", slug: "collateral" },
  { name: "Settlement Sheet", slug: "settlement-sheet" },
  { name: "Details", slug: "details" }
];

function LoanOriginationPrepareDocs() {
  const { uuid } = useParams<{ uuid: string; tab: string }>();
  const baseRoute = `/los/${uuid}/prepare-documents`;

  return (
    <div className="prepare-documents bg-[#ffffff]" style={{ margin: "-1rem" }}>
      <div className="ui view render">
        <LoanOriginationPrepareDocsHeader loanAppUuid={uuid} />
        <PrepareDocsTabs
          tabs={tabs}
          baseRoute={baseRoute}
          relativeRoute=""
          style="tabular"
        >
          <Route
            path="/los/:uuid/prepare-documents/:tab"
            component={PrepareDocsTabsContent}
          />
        </PrepareDocsTabs>
      </div>
    </div>
  );
}

function PrepareDocsTabsContent() {
  const baseRoute = `/los/:uuid/prepare-documents`;
  return (
    <>
      <Route
        path={`${baseRoute}/parties-involved`}
        component={() => <>{tabs[0].name}</>}
      />
      <Route path={`${baseRoute}/loan-data/:tab?`}>
        <LoanData />
      </Route>
      <Route
        path={`${baseRoute}/collateral`}
        component={() => <>{tabs[2].name}</>}
      />
      <Route
        path={`${baseRoute}/settlement-sheet`}
        component={() => <>{tabs[3].name}</>}
      />
      <Route
        path={`${baseRoute}/details`}
        component={() => <>{tabs[4].name}</>}
      />
    </>
  );
}

export default LoanOriginationPrepareDocs;
