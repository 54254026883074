import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Segment } from "semantic-ui-react";
import { Route } from "react-router-dom";

import EntityDetails from "../../../../components/EntityDetails";
import Collateral from "../../../../components/Collateral";
import RelatedDetails from "../../../../components/RelatedDetails";
import CreditRequest from "../../../../components/CreditRequest";
import Guarantors from "../../../LoanOriginationStepper/scenes/StepTabs/scenes/Guarantors";

import MenuBar from "./components/MenuBar";

export const routeDetails = [
  {
    component: uuid => <MenuBar loanAppUuid={uuid} />,
    key: "Menu Bar Shows on All Phases > App Details Pages",
    path: "/los/:uuid/phases/app_details"
  },
  {
    component: (uuid, readOnly) => (
      <EntityDetails loanAppUuid={uuid} readOnly={readOnly} />
    ),
    key: "Borrowers",
    path: "/los/:uuid/phases/app_details/borrowers"
  },
  {
    component: (uuid, readOnly) => (
      <CreditRequest loanAppUuid={uuid} readOnly={readOnly} />
    ),
    key: "Credit Request",
    path: "/los/:uuid/phases/app_details/credit_request"
  },
  {
    component: (uuid, readOnly) => (
      <Guarantors loanAppUuid={uuid} readOnly={readOnly} />
    ),
    key: "Guarantors",
    path: "/los/:uuid/phases/app_details/guarantors"
  },
  {
    component: (uuid, readOnly) => (
      <Collateral loanAppUuid={uuid} readOnly={readOnly} />
    ),
    key: "Collateral",
    path: "/los/:uuid/phases/app_details/collateral"
  },
  {
    component: (uuid, readOnly) => (
      <RelatedDetails loanAppUuid={uuid} readOnly={readOnly} />
    ),
    key: "Related Details",
    path: "/los/:uuid/phases/app_details/related_details"
  }
];

export function AppDetailsObj(props) {
  return (
    <Segment>
      {routeDetails.map(item => (
        <Route
          key={item.key}
          path={item.path}
          render={({ match }) =>
            item.component(match.params.uuid, props.readOnly)
          }
        />
      ))}
    </Segment>
  );
}

AppDetailsObj.propTypes = {
  readOnly: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  readOnly:
    state.LosPhasesReducer.readOnly || state.LosPhasesReducer.readOnlyPhase
});

const AppDetails = connect(mapStateToProps)(AppDetailsObj);

export default AppDetails;
