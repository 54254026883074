import { func, instanceOf, oneOfType, string, bool, number } from "prop-types";
import React, { useCallback, useState } from "react";
import { Input, Form, Label } from "semantic-ui-react";
import moment from "moment";
import { dateFromString } from "../../../../services/DateTime";

/**
 * @param {string} value
 * @param {string} max
 * @param {string} min
 */
function getError(value, max, min) {
  let error = "";
  if (value) {
    const yearBeingChanged = value.split("-")[0].startsWith("0");
    if (!yearBeingChanged) {
      if (max && new Date(max) < new Date(value)) {
        error = `Date should not be greater than ${moment(max).format(
          "DD/MM/YYYY"
        )}`;
      } else if (min && new Date(min) > new Date(value)) {
        error = `Date should not be less than ${moment(min).format(
          "DD/MM/YYYY"
        )}`;
      }
    }
  }

  return error;
}

/**
 * Allow a user to enter a date.
 * @param {DateInputProps} props
 * @returns {JSX}
 */
export default function DateInput({
  asForm,
  disabled,
  label,
  max,
  min,
  name,
  onChange,
  value,
  width
}) {
  const [internalValue, setInternalValue] = useState("");

  const normalizeValue = useCallback(
    val =>
      typeof val !== "string" && val instanceof Date
        ? val.toISOString().slice(0, 10)
        : val,
    []
  );

  const processValueError = useCallback(val => {
    if (!val) {
      return "";
    }

    return !/^[0-9-/]+$/.test(val) ? `'${val}' is not a valid date.` : null;
  }, []);

  const processValueBoundsError = useCallback(
    (val, mx, mn) => (!val ? "" : getError(val, mx, mn)),
    []
  );

  const handleChange = useCallback(
    (evt, { value: val }) => {
      if (!onChange) {
        return;
      }

      // Handling the year change is tricky when the user is entering a date using
      // the keyboard. The browser selects the portion of the field corresponding
      // to a month, day, or year, and automatically advances the selection once a
      // valid number has been entered. So if the month portion is selected and
      // the user enters "0" the value provided in this event (`targetValue`) will
      // be an empty string and "00" will be be displayed; once the user enters a
      // following number the selection will advance to the next portion and the
      // display will update to something like "06".
      //
      // The problem occurs when the user gets to the year portion. When the user
      // types the first number the targetValue will not be an empty string, it
      // will have a zero padded year like "0002-06-01". If this value is passed
      // back using `onChange` and the value is provided in props and set as the
      // `input` value then the display will be corrupted to something like
      // "mm/dd/0000". So the first thing is to return an empty string in
      // `onChange` just like when the month has leading zeroes. The second part
      // is to store `targetValue` in state and provide that value to the input
      // when the component is rendered. Once a year without leading zeroes is
      // provided set the value of `internalValue` to null and use the prop value.
      //
      // Note that Firefox and Google Chrome have slightly different behavior of
      // course!
      const [year] = val.split("-");
      let safeValue;
      if (year.startsWith("0")) {
        safeValue = "";
        setInternalValue(val);
      } else {
        safeValue = val;
        setInternalValue("");
      }

      if (onChange) {
        onChange(evt, { name, value: dateFromString(safeValue) });
      }
    },
    [name, onChange]
  );

  const nextValue = internalValue || normalizeValue(value);
  let error = processValueError(nextValue);
  error = error || processValueBoundsError(nextValue, max, min);

  const InputComponent = asForm ? Form.Input : Input;

  const inputProps = {};
  if (width) {
    inputProps.width = width;
  }

  return (
    <>
      <InputComponent
        {...inputProps}
        disabled={disabled}
        label={label}
        max={max}
        min={min}
        name={name}
        onChange={handleChange}
        value={nextValue}
        type="date"
      />
      {error ? (
        <Label basic color="red" pointing>
          {error}
        </Label>
      ) : null}
    </>
  );
}

DateInput.propTypes = {
  asForm: bool,
  disabled: bool,
  label: string,
  max: string,
  min: string,
  name: string,
  onChange: func,
  value: oneOfType([string, instanceOf(Date)]),
  width: number
};

DateInput.defaultProps = {
  asForm: false,
  disabled: false,
  max: "9999-12-31",
  min: null,
  value: ""
};

/**
 * @typedef DateInputProps
 * @property {bool} [asForm=false]
 * @property {bool} [disabled=false]
 * @property {string} [label] Displayed name.
 * @property {string} [max=9999-12-31]
 * @property {string} [min=1970-01-01]
 * @property {string} [name]
 * @property {(evt: Event, data: DateInputProps) => void} [onChange] Invoked when the value changes.
 * @property {string|Date} [value] The value of the date.
 */
