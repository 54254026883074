import { SearchListDropdown, Button, Tooltip } from "@bafsllc/ui-shared";
import { useState, useEffect, useCallback } from "react";
import {
  useUserInfoQuery,
  useUserPermissionsInfoQuery
} from "../../../../../../services/RTKQuery/usersInfo";
import { useUpdateLoanAppMutation } from "../../../../../../services/RTKQuery/loanApps";
import { UserPermissionsQueryOptions } from "../../../../../../services/RTKQuery/usersInfo";
import { isBafs, permCheck } from "../../../../../../services/Auth";
import { LoanApp } from "../../../../../../services/RTKQuery/types";
import { FormattedMessage } from "react-intl";

function AssignUserDropdown({ loanApp }) {
  const { assigned_to_user } = loanApp;
  const [assignedUser, setAssignedUser] = useState("");
  const [updateLoanAppMutation] = useUpdateLoanAppMutation();
  const [users, setUsers] = useState<any>([]);

  const { data } = useUserInfoQuery(assigned_to_user, {
    skip: assigned_to_user === ""
  });

  const message = () => {
    if (assignedUser) {
      return { defaultMessage: `Assigned to: ${assignedUser}` };
    }

    return { id: "ASSIGNED_TO" };
  };

  const filters: UserPermissionsQueryOptions = {
    institutionUuid: loanApp?.institution_uuid,
    permissions: "application_management"
  };

  const response = useUserPermissionsInfoQuery(filters);

  const getUsers = useCallback(() => {
    let transformedUsersListItems = [];
    response?.data?.data.map(user => {
      if (user?.uuid != assigned_to_user) {
        let searchableText = `${user?.first_name} ${user?.last_name}` || "";
        transformedUsersListItems.push({
          key: user?.uuid,
          value: user?.uuid,
          searchableText,
          displayText: `${user?.first_name} ${user?.last_name}`
        });
      }
    });

    setUsers(transformedUsersListItems || []);
  }, [response, assigned_to_user]);

  const onSelection = item => {
    const { key, displayText } = item;
    const body: Partial<LoanApp> = {
      uuid: loanApp.uuid,
      assigned_to_user: key
    };
    setAssignedUser(displayText);
    updateLoanAppMutation(body);

    if (users.includes(item)) {
      setUsers(prev => [...prev].filter(prevKey => prevKey.key !== key));
    } else {
      setUsers(prev => [item, ...prev]);
    }
  };

  const onClearSelection = () => {
    const body: Partial<LoanApp> = {
      uuid: loanApp.uuid,
      assigned_to_user: ""
    };

    setAssignedUser("");
    updateLoanAppMutation(body);
  };

  useEffect(() => {
    if (data) {
      setAssignedUser(`${data.first_name} ${data.last_name}`);
    }
  }, [data]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const hasPermissions = permCheck(["application_management"]) || isBafs();

  return (
    <div data-testid="search-dropdown">
      <Tooltip
        content={<FormattedMessage id="INSUFFICIENT_PERMISSIONS" />}
        inverted
        disabled={hasPermissions}
        position="bottom"
        contentClassName="text-xs !py-2"
        arrow
      >
        <SearchListDropdown
          items={users}
          onChange={onSelection}
          placeholder="Search..."
          button={{
            size: "tiny",
            variant: "secondary",
            message: message()
          }}
          disabled={!hasPermissions}
          updateDropdownClassnames={names => [...names, "right"]}
          preventAutoClose
          listFooter={
            <div className="flex justify-center p-2 border-t-gray-200">
              <Button
                message={{ id: "CLEAR_SELECTION" }}
                size="tiny"
                onClick={() => onClearSelection()}
                style={{ width: "100%" }}
              />
            </div>
          }
        />
      </Tooltip>
    </div>
  );
}

export default AssignUserDropdown;
