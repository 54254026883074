import { createId, FormLabel } from "../FormLabel";

export const TextInput = ({
  className = "",
  handleInputChange,
  labelText,
  name,
  onBlur,
  placeholderText,
  required = false,
  testId = "",
  value
}: TextInputProps) => {
  return (
    <div className={`flex flex-col flex-1 ${className}`}>
      <FormLabel labelText={labelText} required={required} />
      <div className="border border-neutral-300 flex flex-row rounded">
        <input
          className="flex-1 py-2 pr-2 pl-1"
          id={createId(labelText, "label")}
          name={name}
          onChange={handleInputChange}
          placeholder={placeholderText || labelText}
          type="text"
          data-testid={testId}
          value={value}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
};

export interface TextInputProps {
  className?: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelText: string;
  name: string;
  onBlur: () => void;
  placeholderText?: string;
  required?: boolean;
  testId?: string;
  value: string;
}
