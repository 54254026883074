import TypeCheck from "typecheck-extended";

import errorToState from "../../../errorToState";
import { Collateral } from "../../../../../../../../services/ApiLib";
import { getToken } from "../../../../../../../../services/Auth";
import { assetsToState } from "../assetToState";

export function dispatchCollaterals(rsp, callbackData) {
  TypeCheck(rsp.data, "array");
  TypeCheck(callbackData.dispatch, "function");
  TypeCheck(callbackData.losScene, "string");

  const { dispatch, losScene } = callbackData;
  const stepperDispatchType = "LOR_COLLATERAL_REDUCER_UPDATE_COLLATERAL";
  const phasesDispatchType = "TODO_SET_THIS_UP"; // TODO: This is needed for this to work w/ Phases
  const type =
    losScene === "stepper" ? stepperDispatchType : phasesDispatchType;

  // no collateral data to display, return out
  if (!rsp.data[0]) return;

  dispatch({ collaterals: rsp.data, uuid: rsp.data[0].loan_uuid, type });
  // dispatch new collateral to sync with Loan origination reducer
  dispatch({
    type: "loanApplications/collateral/sync_collaterals",
    payload: {
      collaterals: rsp.data,
      loan_uuid: rsp.data[0].loan_uuid
    }
  });
  dispatch({
    type: "PAGINATION_UPDATE_LAST_PAGE",
    name: "collateralAssetPagination",
    lastPage: rsp.metaData?.last_page
  });
  rsp.data.forEach(collat =>
    assetsToState(collat.asset_uuid, dispatch, losScene)
  );
}

export function collateralsToState(
  loanReqUuid,
  paginationObj,
  dispatch,
  losScene
) {
  TypeCheck(loanReqUuid, "string");
  TypeCheck(dispatch, "function");
  TypeCheck(losScene, "enum", true, ["phases", "stepper"]);
  TypeCheck(paginationObj, "object");
  const jwt = getToken();
  const callbackData = { dispatch, losScene };

  const onSuccess = dispatchCollaterals;
  const onError = errorToState;

  Collateral.get(
    jwt,
    onSuccess,
    onError,
    null,
    { loan_uuid: loanReqUuid, ...paginationObj },
    callbackData
  );
}
