import TypeCheck from "typecheck-extended";

export function ConcatName(entityDetails) {
  TypeCheck(entityDetails, "object");

  let entityName = "";
  if (entityDetails?.company_name) {
    entityName = entityDetails.company_name;
  } else if (entityDetails?.first_name) {
    entityName = entityDetails.first_name;

    if (entityDetails?.middle_name) {
      entityName += ` ${entityDetails.middle_name}`;
    }

    if (entityDetails?.last_name) {
      entityName += ` ${entityDetails.last_name}`;
    }

    if (entityDetails?.indiv_suffix) {
      entityName += ` ${entityDetails.indiv_suffix}`;
    }
  }
  return entityName;
}

export function GetEntityName(entityUuid) {
  TypeCheck(entityUuid, "string");
  return "TODO: Add GetEntityName Functionality";
}
