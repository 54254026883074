import React from "react";
import PropTypes from "prop-types";
import { Segment } from "semantic-ui-react";
import NavTabs, { Tab } from "../../components/NavTabs";
import Constants from "../../../../services/Constants/strings";
import { userCanViewPage } from "../../../../services/Auth";

function LoanManagementTabs({ isParticipatingLoan, loan, match }) {
  return (
    <Segment className="overflow-x-auto">
      <NavTabs>
        <Tab href={`${match.url}/details`}>{Constants.DETAILS}</Tab>
        <Tab href={`${match.url}/relationships`}>{Constants.RELATIONSHIPS}</Tab>
        <Tab href={`${match.url}/document-tracking`}>
          {Constants.DOCUMENT_TRACKING}
        </Tab>
        <Tab href={`${match.url}/account-documents`}>
          {Constants.ACCOUNT_DOCUMENTS}
        </Tab>
        {userCanViewPage(`${match.path}/credit-risk-review`) && (
          <Tab href={`${match.url}/credit-risk-review`}>Credit Risk Review</Tab>
        )}
        <Tab href={`${match.url}/credit-analysis`}>Credit Analysis</Tab>
        <Tab href={`${match.url}/collateral`}>Collateral</Tab>
        {!isParticipatingLoan ? (
          <Tab href={`${match.url}/payments`}>
            {Constants.POST_TRANSACTIONS}
          </Tab>
        ) : (
          userCanViewPage(`${match.path}/payments`) && (
            <Tab href={`${match.url}/payments`}>
              {Constants.POST_TRANSACTIONS}
            </Tab>
          )
        )}
        <Tab href={`${match.url}/payoff-calculator`}>Payoff Calculator</Tab>
        <Tab href={`${match.url}/transaction-history`}>Transaction History</Tab>
        {loan.premium_discount ? (
          <Tab href={`${match.url}/premium-discount`}>Premium/Discount</Tab>
        ) : null}
        {isParticipatingLoan ? (
          <Tab href={`${match.url}/participation`}>Participation</Tab>
        ) : null}
        <Tab href={`${match.url}/nightly-process`}>Nightly Process</Tab>
        <Tab href={`${match.url}/snapshots`}>Snapshots</Tab>
      </NavTabs>
    </Segment>
  );
}

LoanManagementTabs.propTypes = {
  isParticipatingLoan: PropTypes.bool,
  loan: PropTypes.shape({ premium_discount: PropTypes.bool.isRequired })
    .isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired
};

export default LoanManagementTabs;
