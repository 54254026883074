import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Modal, Menu } from "semantic-ui-react";
import InstitutionForm from "./Forms/InstitutionForm";
import WiringInstructionsForm from "./Forms/WiringInstructionsForm";
import AchInstructionsForm from "./Forms/AchInstructionsForm";
import ParticipationServicers from "../../../../../../services/ApiLib/services/ParticipationServicers";
import logger from "../../../../../../services/logger";

export class ParticipationEditModalObj extends Component {
  constructor(props) {
    super(props);
    this.genActionContent = this.genActionContent.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(e, { name, value }) {
    const { dispatch } = this.props;
    dispatch({
      type: "SYSTEM_ADMIN_UPDATE_INSTITUTION_VALUE",
      name,
      value
    });
  }

  onSaveModal() {
    const { participationServicer, onClose } = this.props;
    ParticipationServicers.update(onClose, logger.error, participationServicer);
  }

  changeItem(menuItem) {
    const { dispatch } = this.props;
    dispatch({
      type: "SYSTEM_ADMIN_CHANGE_MENU_ITEM",
      menuItem
    });
  }

  genActionContent() {
    const { menuItem, onClose } = this.props;
    return (
      <Modal.Actions>
        <Button basic onClick={onClose} content="Cancel" />
        <Button
          color="green"
          onClick={() => {
            this.onSaveModal();
          }}
          content="Save and Close"
        />
        {menuItem < 2 ? (
          <Button
            color="green"
            onClick={() => this.changeItem(menuItem + 1)}
            content="Next"
          />
        ) : (
          ""
        )}
      </Modal.Actions>
    );
  }

  genMenuContent() {
    const { menuItem } = this.props;
    return (
      <Menu pointing secondary color="green">
        <Menu.Item
          active={menuItem === 0}
          onClick={() => this.changeItem(0)}
          content="Institution"
        />
        <Menu.Item
          active={menuItem === 1}
          onClick={() => this.changeItem(1)}
          content="Wiring Instructions"
        />
        <Menu.Item
          active={menuItem === 2}
          onClick={() => this.changeItem(2)}
          content="ACH Instructions"
        />
      </Menu>
    );
  }

  genModalContent() {
    const { participationServicer, menuItem } = this.props;
    let form;
    switch (menuItem) {
      case 0:
        form = (
          <InstitutionForm
            institution={participationServicer}
            onInputChange={this.onInputChange}
          />
        );
        break;

      case 1:
        form = (
          <WiringInstructionsForm
            institution={participationServicer}
            onInputChange={this.onInputChange}
          />
        );
        break;

      case 2:
        form = (
          <AchInstructionsForm
            institution={participationServicer}
            onInputChange={this.onInputChange}
          />
        );
        break;

      default:
        form = "";
    }

    return (
      <Modal.Content>
        {this.genMenuContent()}
        {form}
      </Modal.Content>
    );
  }

  render() {
    const { open } = this.props;
    const content = this.genModalContent();
    const actions = this.genActionContent();
    return (
      <Modal open={open}>
        <Modal.Header>Edit Participation Institution</Modal.Header>
        {content}
        {actions}
      </Modal>
    );
  }
}

ParticipationEditModalObj.propTypes = {
  onClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  participationServicer: PropTypes.objectOf(PropTypes.string).isRequired,
  menuItem: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  participationServicer: state.SystemAdminReducer.participationServicer,
  menuItem: state.SystemAdminReducer.menuItem
});

const ParticipationEditModal = connect(mapStateToProps)(
  ParticipationEditModalObj
);

export default ParticipationEditModal;
