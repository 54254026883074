const usStates = [
  { AL: "Alabama" },
  { AK: "Alaska" },
  { AS: "American Samoa" },
  { AZ: "Arizona" },
  { AR: "Arkansas" },
  { CA: "California" },
  { CO: "Colorado" },
  { CT: "Connecticut" },
  { DE: "Delaware" },
  { DC: "District of Columbia" },
  { FM: "Federated States of Micronesia" },
  { FL: "Florida" },
  { GA: "Georgia" },
  { GU: "Guam" },
  { HI: "Hawaii" },
  { ID: "Idaho" },
  { IL: "Illinois" },
  { IN: "Indiana" },
  { IA: "Iowa" },
  { KS: "Kansas" },
  { KY: "Kentucky" },
  { LA: "Louisiana" },
  { ME: "Maine" },
  { MH: "Marshall Islands" },
  { MD: "Maryland" },
  { MA: "Massachusetts" },
  { MI: "Michigan" },
  { MN: "Minnesota" },
  { MS: "Mississippi" },
  { MO: "Missouri" },
  { MT: "Montana" },
  { NE: "Nebraska" },
  { NV: "Nevada" },
  { NH: "New Hampshire" },
  { NJ: "New Jersey" },
  { NM: "New Mexico" },
  { NY: "New York" },
  { NC: "North Carolina" },
  { ND: "North Dakota" },
  { MP: "Northern Mariana Islands" },
  { OH: "Ohio" },
  { OK: "Oklahoma" },
  { OR: "Oregon" },
  { PW: "Palau" },
  { PA: "Pennsylvania" },
  { PR: "Puerto Rico" },
  { RI: "Rhode Island" },
  { SC: "South Carolina" },
  { SD: "South Dakota" },
  { TN: "Tennessee" },
  { TX: "Texas" },
  { UT: "Utah" },
  { VT: "Vermont" },
  { VI: "Virgin Island" },
  { VA: "Virginia" },
  { WA: "Washington" },
  { WV: "West Virginia" },
  { WI: "Wisconsin" },
  { WY: "Wyoming" }
];

export const BUSINESS_TYPES_OPTIONS: OptionType[] = [
  { key: "Corporation", value: "Corporation" },
  { key: "General Partnership", value: "General Partnership" },
  { key: "Government", value: "Government" },
  {
    key: "Limited Liability Company",
    value: "Limited Liability Company"
  },
  {
    key: "Limited Liability Partnership",
    value: "Limited Liability Partnership"
  },
  {
    key: "Limited Partnership",
    value: "Limited Partnership"
  },
  { key: "Non-Profit", value: "Non-Profit" },
  {
    key: "Professional Association",
    value: "Professional Association"
  },
  {
    key: "Single Member LLC",
    value: "Single Member LLC"
  },
  {
    key: "Sole Proprietership",
    value: "Sole Proprietorship"
  },
  { key: "Trust", value: "Trust" },
  { key: "Individual", value: "Individual" }
];

export function getConditionalBusinessTypesOptions(
  entityType: string
): OptionType[] {
  let conditionalEntityTypeOptions = [];
  if (!entityType) {
    conditionalEntityTypeOptions = [...BUSINESS_TYPES_OPTIONS];
  } else if (entityType == "Individual") {
    conditionalEntityTypeOptions = BUSINESS_TYPES_OPTIONS.filter(
      entityTypeOption => entityTypeOption.value == "Individual"
    );
  } else {
    conditionalEntityTypeOptions = BUSINESS_TYPES_OPTIONS.filter(
      entityTypeOption => entityTypeOption.value != "Individual"
    );
  }
  return conditionalEntityTypeOptions;
}

export const NAICS_OPTIONS: OptionType[] = [
  { key: "112519", value: "Other Aquaculture" },
  { key: "112920", value: "Horses and Other Equine Production" },
  { key: "112511", value: "Finfish Farming and Fish Hatcheries" },
  { key: "112512", value: "Shellfish Farming" },
  { key: "922110", value: "Courts" },
  { key: "522293", value: "International Trade Financing" },
  { key: "444130", value: "Hardware Stores" },
  { key: "611430", value: "Professional and Management Development Training" },
  { key: "442110", value: "Furniture Stores" },
  { key: "512240", value: "Sound Recording Studios" },
  { key: "562112", value: "Hazardous Waste Collection" },
  { key: "562111", value: "Solid Waste Collection" },
  { key: "711110", value: "Theater Companies and Dinner Theaters" },
  {
    key: "524128",
    value: "Other Direct Insurance (except Life, Health, and Medical) Carriers"
  },
  {
    key: "541990",
    value: "All Other Professional, Scientific, and Technical Services"
  },
  { key: "424510", value: "Grain and Field Bean Merchant Wholesalers" },
  { key: "812910", value: "Pet Care (except Veterinary) Services" },
  { key: "491110", value: "Postal Service" },
  { key: "112210", value: "Hog and Pig Farming" },
  { key: "523120", value: "Securities Brokerage" },
  { key: "424450", value: "Confectionery Merchant Wholesalers" },
  {
    key: "326150",
    value: "Urethane and Other Foam Product (except Polystyrene) Manufacturing"
  },
  { key: "524126", value: "Direct Property and Casualty Insurance Carriers" },
  { key: "524127", value: "Direct Title Insurance Carriers" },
  {
    key: "922190",
    value: "Other Justice, Public Order, and Safety Activities"
  },
  {
    key: "424120",
    value: "Stationery and Office Supplies Merchant Wholesalers"
  },
  { key: "335110", value: "Electric Lamp Bulb and Part Manufacturing" },
  {
    key: "541690",
    value: "Other Scientific and Technical Consulting Services"
  },
  { key: "425110", value: "Business to Business Electronic Markets" },
  { key: "443142", value: "Electronics Stores" },
  { key: "425120", value: "Wholesale Trade Agents and Brokers" },
  { key: "443141", value: "Household Appliance Stores" },
  { key: "621512", value: "Diagnostic Imaging Centers" },
  { key: "423130", value: "Tire and Tube Merchant Wholesalers" },
  {
    key: "621111",
    value: "Offices of Physicians (except Mental Health Specialists)"
  },
  { key: "326291", value: "Rubber Product Manufacturing for Mechanical Use" },
  { key: "332911", value: "Industrial Valve Manufacturing" },
  { key: "332912", value: "Fluid Power Valve and Hose Fitting Manufacturing" },
  { key: "332913", value: "Plumbing Fixture Fitting and Trim Manufacturing" },
  { key: "326299", value: "All Other Rubber Product Manufacturing" },
  { key: "337110", value: "Wood Kitchen Cabinet and Countertop Manufacturing" },
  { key: "332919", value: "Other Metal Valve and Pipe Fitting Manufacturing" },
  { key: "448310", value: "Jewelry Stores" },
  { key: "238990", value: "All Other Specialty Trade Contractors" },
  { key: "112990", value: "All Other Animal Production" },
  {
    key: "423860",
    value:
      "Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers"
  },
  { key: "922160", value: "Fire Protection" },
  { key: "712120", value: "Historical Sites" },
  { key: "423420", value: "Office Equipment Merchant Wholesalers" },
  { key: "445120", value: "Convenience Stores" },
  { key: "562119", value: "Other Waste Collection" },
  { key: "332710", value: "Machine Shops" },
  {
    key: "424430",
    value: "Dairy Product (except Dried or Canned) Merchant Wholesalers"
  },
  { key: "561790", value: "Other Services to Buildings and Dwellings" },
  { key: "722514", value: "Cafeterias, Grill Buffets, and Buffets" },
  {
    key: "331210",
    value: "Iron and Steel Pipe and Tube Manufacturing from Purchased Steel"
  },
  { key: "722511", value: "Full-Service Restaurants" },
  { key: "722513", value: "Limited-Service Restaurants" },
  { key: "332993", value: "Ammunition (except Small Arms) Manufacturing" },
  {
    key: "423430",
    value:
      "Computer and Computer Peripheral Equipment and Software Merchant Wholesalers"
  },
  { key: "424420", value: "Packaged Frozen Food Merchant Wholesalers" },
  { key: "448140", value: "Family Clothing Stores" },
  {
    key: "423610",
    value:
      "Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers"
  },
  { key: "522110", value: "Commercial Banking" },
  { key: "522294", value: "Secondary Market Financing" },
  { key: "523910", value: "Miscellaneous Intermediation" },
  { key: "488390", value: "Other Support Activities for Water Transportation" },
  { key: "451120", value: "Hobby, Toy, and Game Stores" },
  { key: "111150", value: "Corn Farming" },
  { key: "453930", value: "Manufactured (Mobile) Home Dealers" },
  {
    key: "423510",
    value: "Metal Service Centers and Other Metal Merchant Wholesalers"
  },
  {
    key: "337122",
    value: "Nonupholstered Wood Household Furniture Manufacturing"
  },
  {
    key: "331529",
    value: "Other Nonferrous Metal Foundries (except Die-Casting)"
  },
  { key: "337121", value: "Upholstered Household Furniture Manufacturing" },
  { key: "721110", value: "Hotels (except Casino Hotels) and Motels" },
  { key: "337127", value: "Institutional Furniture Manufacturing" },
  { key: "337124", value: "Metal Household Furniture Manufacturing" },
  {
    key: "337125",
    value: "Household Furniture (except Wood and Metal) Manufacturing"
  },
  {
    key: "238190",
    value: "Other Foundation, Structure, and Building Exterior Contractors"
  },
  { key: "331523", value: "Nonferrous Metal Die-Casting Foundries" },
  { key: "331524", value: "Aluminum Foundries (except Die-Casting)" },
  { key: "316210", value: "Footwear Manufacturing" },
  { key: "561591", value: "Convention and Visitors Bureaus" },
  { key: "322230", value: "Stationery Product Manufacturing" },
  {
    key: "453998",
    value: "All Other Miscellaneous Store Retailers (except Tobacco Stores)"
  },
  { key: "238290", value: "Other Building Equipment Contractors" },
  { key: "711190", value: "Other Performing Arts Companies" },
  { key: "541910", value: "Marketing Research and Public Opinion Polling" },
  {
    key: "334614",
    value:
      "Software and Other Prerecorded Compact Disc, Tape, and Record Reproducing"
  },
  { key: "484210", value: "Used Household and Office Goods Moving" },
  {
    key: "334613",
    value: "Blank Magnetic and Optical Recording Media Manufacturing"
  },
  { key: "811112", value: "Automotive Exhaust System Repair" },
  { key: "111160", value: "Rice Farming" },
  { key: "561210", value: "Facilities Support Services" },
  { key: "811111", value: "General Automotive Repair" },
  { key: "519190", value: "All Other Information Services" },
  { key: "237310", value: "Highway, Street, and Bridge Construction" },
  { key: "323113", value: "Commercial Screen Printing" },
  {
    key: "811118",
    value: "Other Automotive Mechanical and Electrical Repair and Maintenance"
  },
  { key: "323111", value: "Commercial Printing (except Screen and Books)" },
  { key: "323117", value: "Books Printing" },
  { key: "114210", value: "Hunting and Trapping" },
  { key: "515112", value: "Radio Stations" },
  {
    key: "926140",
    value: "Regulation of Agricultural Marketing and Commodities"
  },
  { key: "238330", value: "Flooring Contractors" },
  {
    key: "484230",
    value: "Specialized Freight (except Used Goods) Trucking, Long-Distance"
  },
  { key: "221118", value: "Other Electric Power Generation" },
  { key: "488410", value: "Motor Vehicle Towing" },
  { key: "611519", value: "Other Technical and Trade Schools" },
  { key: "812922", value: "One-Hour Photofinishing" },
  { key: "333244", value: "Printing Machinery and Equipment Manufacturing" },
  {
    key: "333243",
    value: "Sawmill, Woodworking, and Paper Machinery Manufacturing"
  },
  { key: "333242", value: "Semiconductor Machinery Manufacturing" },
  { key: "333241", value: "Food Product Machinery Manufacturing" },
  { key: "453991", value: "Tobacco Stores" },
  { key: "445210", value: "Meat Markets" },
  { key: "321219", value: "Reconstituted Wood Product Manufacturing" },
  { key: "333249", value: "Other Industrial Machinery Manufacturing" },
  { key: "523930", value: "Investment Advice" },
  { key: "812921", value: "Photofinishing Laboratories (except One-Hour)" },
  { key: "621112", value: "Offices of Physicians, Mental Health Specialists" },
  { key: "532289", value: "All Other Consumer Goods Rental" },
  { key: "531311", value: "Residential Property Managers" },
  { key: "532284", value: "Recreational Goods Rental" },
  { key: "531312", value: "Nonresidential Property Managers" },
  { key: "221320", value: "Sewage Treatment Facilities" },
  { key: "321920", value: "Wood Container and Pallet Manufacturing" },
  { key: "532281", value: "Formal Wear and Costume Rental" },
  { key: "312230", value: "Tobacco Manufacturing" },
  { key: "221111", value: "Hydroelectric Power Generation" },
  { key: "332439", value: "Other Metal Container Manufacturing" },
  { key: "713990", value: "All Other Amusement and Recreation Industries" },
  { key: "212210", value: "Iron Ore Mining" },
  {
    key: "622310",
    value: "Specialty (except Psychiatric and Substance Abuse) Hospitals"
  },
  { key: "332431", value: "Metal Can Manufacturing" },
  { key: "442210", value: "Floor Covering Stores" },
  { key: "624221", value: "Temporary Shelters" },
  { key: "446199", value: "All Other Health and Personal Care Stores" },
  { key: "113310", value: "Logging" },
  { key: "813211", value: "Grantmaking Foundations" },
  { key: "312120", value: "Breweries" },
  { key: "446191", value: "Food (Health) Supplement Stores" },
  { key: "326111", value: "Plastics Bag and Pouch Manufacturing" },
  {
    key: "424930",
    value:
      "Flower, Nursery Stock, and Florists\u2019 Supplies Merchant Wholesalers"
  },
  {
    key: "326113",
    value:
      "Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing"
  },
  {
    key: "326112",
    value:
      "Plastics Packaging Film and Sheet (including Laminated) Manufacturing"
  },
  { key: "335210", value: "Small Electrical Appliance Manufacturing" },
  { key: "561720", value: "Janitorial Services" },
  {
    key: "541370",
    value: "Surveying and Mapping (except Geophysical) Services"
  },
  { key: "921190", value: "Other General Government Support" },
  {
    key: "336320",
    value: "Motor Vehicle Electrical and Electronic Equipment Manufacturing"
  },
  { key: "812220", value: "Cemeteries and Crematories" },
  { key: "112310", value: "Chicken Egg Production" },
  { key: "444220", value: "Nursery, Garden Center, and Farm Supply Stores" },
  { key: "561440", value: "Collection Agencies" },
  { key: "325120", value: "Industrial Gas Manufacturing" },
  { key: "322122", value: "Newsprint Mills" },
  { key: "525190", value: "Other Insurance Funds" },
  { key: "327410", value: "Lime Manufacturing" },
  { key: "321991", value: "Manufactured Home (Mobile Home) Manufacturing" },
  { key: "321992", value: "Prefabricated Wood Building Manufacturing" },
  { key: "212325", value: "Clay and Ceramic and Refractory Minerals Mining" },
  { key: "212322", value: "Industrial Sand Mining" },
  { key: "314999", value: "All Other Miscellaneous Textile Product Mills" },
  {
    key: "711320",
    value:
      "Promoters of Performing Arts, Sports, and Similar Events without Facilities"
  },
  { key: "212321", value: "Construction Sand and Gravel Mining" },
  {
    key: "314994",
    value: "Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills"
  },
  {
    key: "321999",
    value: "All Other Miscellaneous Wood Product Manufacturing"
  },
  { key: "922120", value: "Police Protection" },
  { key: "112420", value: "Goat Farming" },
  { key: "112130", value: "Dual-Purpose Cattle Ranching and Farming" },
  { key: "561990", value: "All Other Support Services" },
  { key: "512290", value: "Other Sound Recording Industries" },
  {
    key: "313220",
    value: "Narrow Fabric Mills and Schiffli Machine Embroidery"
  },
  { key: "512110", value: "Motion Picture and Video Production" },
  { key: "211130", value: "Natural Gas Extraction" },
  { key: "621492", value: "Kidney Dialysis Centers" },
  {
    key: "621493",
    value: "Freestanding Ambulatory Surgical and Emergency Centers"
  },
  { key: "424460", value: "Fish and Seafood Merchant Wholesalers" },
  { key: "621491", value: "HMO Medical Centers" },
  { key: "621498", value: "All Other Outpatient Care Centers" },
  { key: "561611", value: "Investigation Services" },
  {
    key: "423850",
    value: "Service Establishment Equipment and Supplies Merchant Wholesalers"
  },
  { key: "561613", value: "Armored Car Services" },
  { key: "315110", value: "Hosiery and Sock Mills" },
  {
    key: "321213",
    value: "Engineered Wood Member (except Truss) Manufacturing"
  },
  { key: "321212", value: "Softwood Veneer and Plywood Manufacturing" },
  { key: "321211", value: "Hardwood Veneer and Plywood Manufacturing" },
  { key: "321214", value: "Truss Manufacturing" },
  { key: "221117", value: "Biomass Electric Power Generation" },
  { key: "221116", value: "Geothermal Electric Power Generation" },
  { key: "221115", value: "Wind Electric Power Generation" },
  { key: "221114", value: "Solar Electric Power Generation" },
  { key: "221113", value: "Nuclear Electric Power Generation" },
  { key: "221112", value: "Fossil Fuel Electric Power Generation" },
  {
    key: "237130",
    value: "Power and Communication Line and Related Structures Construction"
  },
  { key: "561311", value: "Employment Placement Agencies" },
  { key: "424940", value: "Tobacco and Tobacco Product Merchant Wholesalers" },
  { key: "712110", value: "Museums" },
  { key: "332111", value: "Iron and Steel Forging" },
  { key: "324110", value: "Petroleum Refineries" },
  { key: "811412", value: "Appliance Repair and Maintenance" },
  { key: "811411", value: "Home and Garden Equipment Repair and Maintenance" },
  { key: "238150", value: "Glass and Glazing Contractors" },
  { key: "541511", value: "Custom Computer Programming Services" },
  { key: "336120", value: "Heavy Duty Truck Manufacturing" },
  { key: "551111", value: "Offices of Bank Holding Companies" },
  { key: "311340", value: "Nonchocolate Confectionery Manufacturing" },
  {
    key: "327110",
    value: "Pottery, Ceramics, and Plumbing Fixture Manufacturing"
  },
  { key: "561312", value: "Executive Search Services" },
  {
    key: "327120",
    value: "Clay Building Material and Refractories Manufacturing"
  },
  { key: "423140", value: "Motor Vehicle Parts (Used) Merchant Wholesalers" },
  {
    key: "423490",
    value: "Other Professional Equipment and Supplies Merchant Wholesalers"
  },
  {
    key: "423990",
    value: "Other Miscellaneous Durable Goods Merchant Wholesalers"
  },
  {
    key: "551114",
    value: "Corporate, Subsidiary, and Regional Managing Offices"
  },
  { key: "541213", value: "Tax Preparation Services" },
  { key: "313310", value: "Textile and Fabric Finishing Mills" },
  { key: "611310", value: "Colleges, Universities, and Professional Schools" },
  { key: "311225", value: "Fats and Oils Refining and Blending" },
  { key: "311224", value: "Soybean and Other Oilseed Processing" },
  { key: "483113", value: "Coastal and Great Lakes Freight Transportation" },
  { key: "483112", value: "Deep Sea Passenger Transportation" },
  { key: "311221", value: "Wet Corn Milling" },
  { key: "483114", value: "Coastal and Great Lakes Passenger Transportation" },
  {
    key: "333611",
    value: "Turbine and Turbine Generator Set Units Manufacturing"
  },
  {
    key: "333612",
    value: "Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing"
  },
  {
    key: "333613",
    value: "Mechanical Power Transmission Equipment Manufacturing"
  },
  { key: "441110", value: "New Car Dealers" },
  { key: "423460", value: "Ophthalmic Goods Merchant Wholesalers" },
  { key: "333618", value: "Other Engine Equipment Manufacturing" },
  { key: "487210", value: "Scenic and Sightseeing Transportation, Water" },
  { key: "334290", value: "Other Communications Equipment Manufacturing" },
  { key: "451110", value: "Sporting Goods Stores" },
  { key: "445299", value: "All Other Specialty Food Stores" },
  {
    key: "423720",
    value:
      "Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers"
  },
  { key: "335932", value: "Noncurrent-Carrying Wiring Device Manufacturing" },
  { key: "335931", value: "Current-Carrying Wiring Device Manufacturing" },
  {
    key: "327999",
    value: "All Other Miscellaneous Nonmetallic Mineral Product Manufacturing"
  },
  { key: "488510", value: "Freight Transportation Arrangement" },
  { key: "541310", value: "Architectural Services" },
  {
    key: "327212",
    value: "Other Pressed and Blown Glass and Glassware Manufacturing"
  },
  { key: "327992", value: "Ground or Treated Mineral and Earth Manufacturing" },
  { key: "327993", value: "Mineral Wool Manufacturing" },
  { key: "327991", value: "Cut Stone and Stone Product Manufacturing" },
  { key: "441120", value: "Used Car Dealers" },
  { key: "212393", value: "Other Chemical and Fertilizer Mineral Mining" },
  { key: "212392", value: "Phosphate Rock Mining" },
  { key: "212391", value: "Potash, Soda, and Borate Mineral Mining" },
  { key: "813311", value: "Human Rights Organizations" },
  { key: "923110", value: "Administration of Education Programs" },
  { key: "713290", value: "Other Gambling Industries" },
  { key: "314110", value: "Carpet and Rug Mills" },
  {
    key: "423310",
    value: "Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers"
  },
  { key: "212399", value: "All Other Nonmetallic Mineral Mining" },
  {
    key: "621330",
    value: "Offices of Mental Health Practitioners (except Physicians)"
  },
  {
    key: "336330",
    value:
      "Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing"
  },
  { key: "541410", value: "Interior Design Services" },
  {
    key: "484220",
    value: "Specialized Freight (except Used Goods) Trucking, Local"
  },
  {
    key: "311824",
    value:
      "Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour"
  },
  { key: "311821", value: "Cookie and Cracker Manufacturing" },
  { key: "488210", value: "Support Activities for Rail Transportation" },
  { key: "327390", value: "Other Concrete Product Manufacturing" },
  { key: "488190", value: "Other Support Activities for Air Transportation" },
  { key: "325520", value: "Adhesive Manufacturing" },
  { key: "521110", value: "Monetary Authorities-Central Bank" },
  { key: "481112", value: "Scheduled Freight Air Transportation" },
  { key: "813910", value: "Business Associations" },
  { key: "111110", value: "Soybean Farming" },
  { key: "621610", value: "Home Health Care Services" },
  { key: "336510", value: "Railroad Rolling Stock Manufacturing" },
  { key: "485310", value: "Taxi Service" },
  { key: "611210", value: "Junior Colleges" },
  {
    key: "711310",
    value:
      "Promoters of Performing Arts, Sports, and Similar Events with Facilities"
  },
  { key: "333111", value: "Farm Machinery and Equipment Manufacturing" },
  { key: "488320", value: "Marine Cargo Handling" },
  { key: "713120", value: "Amusement Arcades" },
  {
    key: "423740",
    value: "Refrigeration Equipment and Supplies Merchant Wholesalers"
  },
  { key: "493110", value: "General Warehousing and Storage" },
  {
    key: "921150",
    value: "American Indian and Alaska Native Tribal Governments"
  },
  {
    key: "334220",
    value:
      "Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing"
  },
  { key: "488991", value: "Packing and Crating" },
  { key: "454110", value: "Electronic Shopping and Mail-Order Houses" },
  { key: "812990", value: "All Other Personal Services" },
  { key: "488999", value: "All Other Support Activities for Transportation" },
  { key: "926110", value: "Administration of General Economic Programs" },
  {
    key: "811212",
    value: "Computer and Office Machine Repair and Maintenance"
  },
  { key: "339950", value: "Sign Manufacturing" },
  { key: "311710", value: "Seafood Product Preparation and Packaging" },
  { key: "711130", value: "Musical Groups and Artists" },
  { key: "541214", value: "Payroll Services" },
  { key: "532310", value: "General Rental Centers" },
  { key: "722410", value: "Drinking Places (Alcoholic Beverages)" },
  { key: "311911", value: "Roasted Nuts and Peanut Butter Manufacturing" },
  { key: "111940", value: "Hay Farming" },
  { key: "236210", value: "Industrial Building Construction" },
  { key: "311919", value: "Other Snack Food Manufacturing" },
  { key: "921120", value: "Legislative Bodies" },
  { key: "492210", value: "Local Messengers and Local Delivery" },
  { key: "541890", value: "Other Services Related to Advertising" },
  { key: "312112", value: "Bottled Water Manufacturing" },
  { key: "312113", value: "Ice Manufacturing" },
  { key: "312111", value: "Soft Drink Manufacturing" },
  { key: "336612", value: "Boat Building" },
  { key: "336611", value: "Ship Building and Repairing" },
  { key: "512250", value: "Record Production and Distribution" },
  { key: "112910", value: "Apiculture" },
  { key: "541420", value: "Industrial Design Services" },
  { key: "444120", value: "Paint and Wallpaper Stores" },
  { key: "453220", value: "Gift, Novelty, and Souvenir Stores" },
  { key: "111421", value: "Nursery and Tree Production" },
  { key: "111422", value: "Floriculture Production" },
  { key: "561730", value: "Landscaping Services" },
  { key: "511191", value: "Greeting Card Publishers" },
  { key: "812310", value: "Coin-Operated Laundries and Drycleaners" },
  { key: "511110", value: "Newspaper Publishers" },
  { key: "561492", value: "Court Reporting and Stenotype Services" },
  { key: "561491", value: "Repossession Services" },
  { key: "452319", value: "All Other General Merchandise Stores" },
  { key: "327420", value: "Gypsum Product Manufacturing" },
  { key: "326160", value: "Plastics Bottle Manufacturing" },
  { key: "561499", value: "All Other Business Support Services" },
  { key: "452311", value: "Warehouse Clubs and Supercenters" },
  { key: "624230", value: "Emergency and Other Relief Services" },
  {
    key: "335121",
    value: "Residential Electric Lighting Fixture Manufacturing"
  },
  {
    key: "335122",
    value:
      "Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing"
  },
  { key: "113210", value: "Forest Nurseries and Gathering of Forest Products" },
  { key: "335129", value: "Other Lighting Equipment Manufacturing" },
  { key: "487110", value: "Scenic and Sightseeing Transportation, Land" },
  { key: "112340", value: "Poultry Hatcheries" },
  { key: "448120", value: "Women\u2019s Clothing Stores" },
  { key: "424480", value: "Fresh Fruit and Vegetable Merchant Wholesalers" },
  {
    key: "721214",
    value: "Recreational and Vacation Camps (except Campgrounds)"
  },
  { key: "541340", value: "Drafting Services" },
  { key: "446110", value: "Pharmacies and Drug Stores" },
  { key: "721211", value: "RV (Recreational Vehicle) Parks and Campgrounds" },
  {
    key: "424720",
    value:
      "Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)"
  },
  { key: "721191", value: "Bed-and-Breakfast Inns" },
  {
    key: "423450",
    value:
      "Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers"
  },
  {
    key: "711410",
    value:
      "Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures"
  },
  { key: "485510", value: "Charter Bus Industry" },
  { key: "511120", value: "Periodical Publishers" },
  { key: "722515", value: "Snack and Nonalcoholic Beverage Bars" },
  { key: "444190", value: "Other Building Material Dealers" },
  { key: "424110", value: "Printing and Writing Paper Merchant Wholesalers" },
  { key: "519110", value: "News Syndicates" },
  { key: "313210", value: "Broadwoven Fabric Mills" },
  { key: "722310", value: "Food Service Contractors" },
  { key: "812210", value: "Funeral Homes and Funeral Services" },
  { key: "221122", value: "Electric Power Distribution" },
  {
    key: "332999",
    value: "All Other Miscellaneous Fabricated Metal Product Manufacturing"
  },
  { key: "221121", value: "Electric Bulk Power Transmission and Control" },
  {
    key: "332994",
    value: "Small Arms, Ordnance, and Ordnance Accessories Manufacturing"
  },
  {
    key: "624120",
    value: "Services for the Elderly and Persons with Disabilities"
  },
  { key: "332996", value: "Fabricated Pipe and Pipe Fitting Manufacturing" },
  { key: "336214", value: "Travel Trailer and Camper Manufacturing" },
  { key: "611630", value: "Language Schools" },
  { key: "332991", value: "Ball and Roller Bearing Manufacturing" },
  { key: "332992", value: "Small Arms Ammunition Manufacturing" },
  { key: "332410", value: "Power Boiler and Heat Exchanger Manufacturing" },
  {
    key: "335313",
    value: "Switchgear and Switchboard Apparatus Manufacturing"
  },
  { key: "811420", value: "Reupholstery and Furniture Repair" },
  { key: "523920", value: "Portfolio Management" },
  { key: "115115", value: "Farm Labor Contractors and Crew Leaders" },
  {
    key: "115114",
    value: "Postharvest Crop Activities (except Cotton Ginning)"
  },
  { key: "115116", value: "Farm Management Services" },
  { key: "115111", value: "Cotton Ginning" },
  { key: "115113", value: "Crop Harvesting, Primarily by Machine" },
  { key: "115112", value: "Soil Preparation, Planting, and Cultivating" },
  { key: "713910", value: "Golf Courses and Country Clubs" },
  { key: "212291", value: "Uranium-Radium-Vanadium Ore Mining" },
  { key: "336370", value: "Motor Vehicle Metal Stamping" },
  { key: "336111", value: "Automobile Manufacturing" },
  { key: "335220", value: "Major Household Appliance Manufacturing" },
  { key: "423210", value: "Furniture Merchant Wholesalers" },
  { key: "212299", value: "All Other Metal Ore Mining" },
  {
    key: "324122",
    value: "Asphalt Shingle and Coating Materials Manufacturing"
  },
  { key: "324121", value: "Asphalt Paving Mixture and Block Manufacturing" },
  {
    key: "445110",
    value: "Supermarkets and Other Grocery (except Convenience) Stores"
  },
  { key: "488310", value: "Port and Harbor Operations" },
  { key: "541519", value: "Other Computer Related Services" },
  { key: "311314", value: "Cane Sugar Manufacturing" },
  { key: "525990", value: "Other Financial Vehicles" },
  {
    key: "333112",
    value:
      "Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing"
  },
  { key: "311313", value: "Beet Sugar Manufacturing" },
  {
    key: "322220",
    value: "Paper Bag and Coated and Treated Paper Manufacturing"
  },
  {
    key: "238110",
    value: "Poured Concrete Foundation and Structure Contractors"
  },
  {
    key: "325414",
    value: "Biological Product (except Diagnostic) Manufacturing"
  },
  { key: "485210", value: "Interurban and Rural Bus Transportation" },
  { key: "531210", value: "Offices of Real Estate Agents and Brokers" },
  { key: "313320", value: "Fabric Coating Mills" },
  {
    key: "623220",
    value: "Residential Mental Health and Substance Abuse Facilities"
  },
  {
    key: "423690",
    value: "Other Electronic Parts and Equipment Merchant Wholesalers"
  },
  {
    key: "812320",
    value: "Drycleaning and Laundry Services (except Coin-Operated)"
  },
  { key: "442291", value: "Window Treatment Stores" },
  {
    key: "926150",
    value:
      "Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors"
  },
  { key: "711211", value: "Sports Teams and Clubs" },
  { key: "442299", value: "All Other Home Furnishings Stores" },
  { key: "311920", value: "Coffee and Tea Manufacturing" },
  { key: "311615", value: "Poultry Processing" },
  { key: "562998", value: "All Other Miscellaneous Waste Management Services" },
  { key: "531320", value: "Offices of Real Estate Appraisers" },
  { key: "311611", value: "Animal (except Poultry) Slaughtering" },
  { key: "311612", value: "Meat Processed from Carcasses" },
  { key: "311613", value: "Rendering and Meat Byproduct Processing" },
  { key: "336213", value: "Motor Home Manufacturing" },
  { key: "336212", value: "Truck Trailer Manufacturing" },
  { key: "336211", value: "Motor Vehicle Body Manufacturing" },
  { key: "451140", value: "Musical Instrument and Supplies Stores" },
  { key: "453910", value: "Pet and Pet Supplies Stores" },
  {
    key: "315990",
    value: "Apparel Accessories and Other Apparel Manufacturing"
  },
  { key: "321918", value: "Other Millwork (including Flooring)" },
  { key: "335312", value: "Motor and Generator Manufacturing" },
  {
    key: "335311",
    value: "Power, Distribution, and Specialty Transformer Manufacturing"
  },
  { key: "711212", value: "Racetracks" },
  { key: "524210", value: "Insurance Agencies and Brokerages" },
  { key: "335314", value: "Relay and Industrial Control Manufacturing" },
  { key: "321911", value: "Wood Window and Door Manufacturing" },
  { key: "321912", value: "Cut Stock, Resawing Lumber, and Planing" },
  { key: "221330", value: "Steam and Air-Conditioning Supply" },
  { key: "325411", value: "Medicinal and Botanical Manufacturing" },
  { key: "928110", value: "National Security" },
  { key: "325413", value: "In-Vitro Diagnostic Substance Manufacturing" },
  { key: "325412", value: "Pharmaceutical Preparation Manufacturing" },
  { key: "813219", value: "Other Grantmaking and Giving Services" },
  {
    key: "485999",
    value: "All Other Transit and Ground Passenger Transportation"
  },
  {
    key: "424820",
    value: "Wine and Distilled Alcoholic Beverage Merchant Wholesalers"
  },
  { key: "522120", value: "Savings Institutions" },
  { key: "541219", value: "Other Accounting Services" },
  { key: "423930", value: "Recyclable Material Merchant Wholesalers" },
  { key: "485991", value: "Special Needs Transportation" },
  { key: "813319", value: "Other Social Advocacy Organizations" },
  { key: "311812", value: "Commercial Bakeries" },
  {
    key: "311813",
    value: "Frozen Cakes, Pies, and Other Pastries Manufacturing"
  },
  { key: "311811", value: "Retail Bakeries" },
  { key: "483111", value: "Deep Sea Freight Transportation" },
  { key: "315190", value: "Other Apparel Knitting Mills" },
  {
    key: "924110",
    value:
      "Administration of Air and Water Resource and Solid Waste Management Programs"
  },
  { key: "622110", value: "General Medical and Surgical Hospitals" },
  { key: "925110", value: "Administration of Housing Programs" },
  { key: "522291", value: "Consumer Lending" },
  {
    key: "533110",
    value:
      "Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)"
  },
  { key: "812191", value: "Diet and Weight Reducing Centers" },
  { key: "111930", value: "Sugarcane Farming" },
  { key: "325110", value: "Petrochemical Manufacturing" },
  {
    key: "332119",
    value: "Metal Crown, Closure, and Other Metal Stamping (except Automotive)"
  },
  {
    key: "926120",
    value: "Regulation and Administration of Transportation Programs"
  },
  { key: "322130", value: "Paperboard Mills" },
  { key: "332114", value: "Custom Roll Forming" },
  { key: "812199", value: "Other Personal Care Services" },
  { key: "332117", value: "Powder Metallurgy Part Manufacturing" },
  { key: "326121", value: "Unlaminated Plastics Profile Shape Manufacturing" },
  { key: "332112", value: "Nonferrous Forging" },
  { key: "493120", value: "Refrigerated Warehousing and Storage" },
  { key: "551112", value: "Offices of Other Holding Companies" },
  { key: "335991", value: "Carbon and Graphite Product Manufacturing" },
  { key: "453310", value: "Used Merchandise Stores" },
  { key: "482111", value: "Line-Haul Railroads" },
  { key: "452210", value: "Department Stores" },
  {
    key: "335999",
    value:
      "All Other Miscellaneous Electrical Equipment and Component Manufacturing"
  },
  { key: "339910", value: "Jewelry and Silverware Manufacturing" },
  {
    key: "532411",
    value:
      "Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing"
  },
  { key: "541820", value: "Public Relations Agencies" },
  { key: "921110", value: "Executive Offices" },
  { key: "486990", value: "All Other Pipeline Transportation" },
  { key: "327332", value: "Concrete Pipe Manufacturing" },
  { key: "523991", value: "Trust, Fiduciary, and Custody Activities" },
  { key: "327331", value: "Concrete Block and Brick Manufacturing" },
  { key: "921130", value: "Public Finance Activities" },
  { key: "336112", value: "Light Truck and Utility Vehicle Manufacturing" },
  { key: "523999", value: "Miscellaneous Financial Investment Activities" },
  { key: "922130", value: "Legal Counsel and Prosecution" },
  {
    key: "721310",
    value: "Rooming and Boarding Houses, Dormitories, and Workers\u2019 Camps"
  },
  { key: "611512", value: "Flight Training" },
  { key: "112120", value: "Dairy Cattle and Milk Production" },
  { key: "525920", value: "Trusts, Estates, and Agency Accounts" },
  { key: "712190", value: "Nature Parks and Other Similar Institutions" },
  { key: "713950", value: "Bowling Centers" },
  { key: "446130", value: "Optical Goods Stores" },
  { key: "333997", value: "Scale and Balance Manufacturing" },
  { key: "334419", value: "Other Electronic Component Manufacturing" },
  {
    key: "334418",
    value: "Printed Circuit Assembly (Electronic Assembly) Manufacturing"
  },
  { key: "524113", value: "Direct Life Insurance Carriers" },
  { key: "333996", value: "Fluid Power Pump and Motor Manufacturing" },
  { key: "334413", value: "Semiconductor and Related Device Manufacturing" },
  { key: "334412", value: "Bare Printed Circuit Board Manufacturing" },
  { key: "531190", value: "Lessors of Other Real Estate Property" },
  { key: "334417", value: "Electronic Connector Manufacturing" },
  { key: "312140", value: "Distilleries" },
  { key: "424470", value: "Meat and Meat Product Merchant Wholesalers" },
  { key: "333511", value: "Industrial Mold Manufacturing" },
  { key: "333994", value: "Industrial Process Furnace and Oven Manufacturing" },
  { key: "333517", value: "Machine Tool Manufacturing" },
  {
    key: "333515",
    value: "Cutting Tool and Machine Tool Accessory Manufacturing"
  },
  {
    key: "333514",
    value: "Special Die and Tool, Die Set, Jig, and Fixture Manufacturing"
  },
  { key: "444110", value: "Home Centers" },
  {
    key: "333519",
    value: "Rolling Mill and Other Metalworking Machinery Manufacturing"
  },
  { key: "423220", value: "Home Furnishing Merchant Wholesalers" },
  { key: "337910", value: "Mattress Manufacturing" },
  { key: "493190", value: "Other Warehousing and Storage" },
  { key: "722330", value: "Mobile Food Services" },
  { key: "211120", value: "Crude Petroleum Extraction" },
  { key: "531390", value: "Other Activities Related to Real Estate" },
  {
    key: "237120",
    value: "Oil and Gas Pipeline and Related Structures Construction"
  },
  { key: "492110", value: "Couriers and Express Delivery Services" },
  { key: "334112", value: "Computer Storage Device Manufacturing" },
  { key: "541512", value: "Computer Systems Design Services" },
  { key: "334111", value: "Electronic Computer Manufacturing" },
  { key: "339994", value: "Broom, Brush, and Mop Manufacturing" },
  { key: "518210", value: "Data Processing, Hosting, and Related Services" },
  { key: "238160", value: "Roofing Contractors" },
  {
    key: "424610",
    value: "Plastics Materials and Basic Forms and Shapes Merchant Wholesalers"
  },
  {
    key: "334118",
    value:
      "Computer Terminal and Other Computer Peripheral Equipment Manufacturing"
  },
  {
    key: "423520",
    value: "Coal and Other Mineral and Ore Merchant Wholesalers"
  },
  {
    key: "925120",
    value:
      "Administration of Urban Planning and Community and Rural Development"
  },
  {
    key: "311351",
    value: "Chocolate and Confectionery Manufacturing from Cacao Beans"
  },
  {
    key: "315220",
    value: "Men\u2019s and Boys\u2019 Cut and Sew Apparel Manufacturing"
  },
  {
    key: "311352",
    value: "Confectionery Manufacturing from Purchased Chocolate"
  },
  {
    key: "532120",
    value:
      "Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing"
  },
  { key: "813410", value: "Civic and Social Organizations" },
  { key: "541940", value: "Veterinary Services" },
  { key: "541513", value: "Computer Facilities Management Services" },
  { key: "611699", value: "All Other Miscellaneous Schools and Instruction" },
  { key: "722320", value: "Caterers" },
  {
    key: "813990",
    value:
      "Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)"
  },
  { key: "311211", value: "Flour Milling" },
  { key: "311212", value: "Rice Milling" },
  { key: "311213", value: "Malt Manufacturing" },
  {
    key: "623110",
    value: "Nursing Care Facilities (Skilled Nursing Facilities)"
  },
  {
    key: "423410",
    value: "Photographic Equipment and Supplies Merchant Wholesalers"
  },
  { key: "713920", value: "Skiing Facilities" },
  { key: "336340", value: "Motor Vehicle Brake System Manufacturing" },
  { key: "486210", value: "Pipeline Transportation of Natural Gas" },
  { key: "424910", value: "Farm Supplies Merchant Wholesalers" },
  { key: "711219", value: "Other Spectator Sports" },
  {
    key: "423730",
    value:
      "Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers"
  },
  { key: "811113", value: "Automotive Transmission Repair" },
  {
    key: "423820",
    value: "Farm and Garden Machinery and Equipment Merchant Wholesalers"
  },
  { key: "927110", value: "Space Research and Technology" },
  { key: "611692", value: "Automobile Driving Schools" },
  { key: "332811", value: "Metal Heat Treating" },
  {
    key: "423810",
    value:
      "Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers"
  },
  { key: "541830", value: "Media Buying Agencies" },
  {
    key: "423320",
    value:
      "Brick, Stone, and Related Construction Material Merchant Wholesalers"
  },
  { key: "331110", value: "Iron and Steel Mills and Ferroalloy Manufacturing" },
  {
    key: "621340",
    value:
      "Offices of Physical, Occupational and Speech Therapists, and Audiologists"
  },
  { key: "522130", value: "Credit Unions" },
  { key: "624310", value: "Vocational Rehabilitation Services" },
  { key: "325920", value: "Explosives Manufacturing" },
  { key: "311999", value: "All Other Miscellaneous Food Manufacturing" },
  { key: "236118", value: "Residential Remodelers" },
  { key: "322212", value: "Folding Paperboard Box Manufacturing" },
  { key: "333120", value: "Construction Machinery Manufacturing" },
  { key: "322211", value: "Corrugated and Solid Fiber Box Manufacturing" },
  { key: "325510", value: "Paint and Coating Manufacturing" },
  { key: "311991", value: "Perishable Prepared Food Manufacturing" },
  { key: "236117", value: "New Housing For-Sale Builders" },
  {
    key: "236116",
    value: "New Multifamily Housing Construction (except For-Sale Builders)"
  },
  {
    key: "236115",
    value: "New Single-Family Housing Construction (except For-Sale Builders)"
  },
  { key: "322219", value: "Other Paperboard Container Manufacturing" },
  { key: "713210", value: "Casinos (except Casino Hotels)" },
  { key: "814110", value: "Private Households" },
  { key: "326122", value: "Plastics Pipe and Pipe Fitting Manufacturing" },
  { key: "485320", value: "Limousine Service" },
  { key: "111120", value: "Oilseed (except Soybean) Farming" },
  {
    key: "336419",
    value:
      "Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing"
  },
  { key: "523210", value: "Securities and Commodity Exchanges" },
  { key: "921140", value: "Executive and Legislative Offices, Combined" },
  { key: "111310", value: "Orange Groves" },
  {
    key: "811219",
    value: "Other Electronic and Precision Equipment Repair and Maintenance"
  },
  { key: "445230", value: "Fruit and Vegetable Markets" },
  { key: "711510", value: "Independent Artists, Writers, and Performers" },
  { key: "811198", value: "All Other Automotive Repair and Maintenance" },
  { key: "316110", value: "Leather and Hide Tanning and Finishing" },
  { key: "811211", value: "Consumer Electronics Repair and Maintenance" },
  { key: "482112", value: "Short Line Railroads" },
  { key: "811213", value: "Communication Equipment Repair and Maintenance" },
  { key: "561410", value: "Document Preparation Services" },
  { key: "511210", value: "Software Publishers" },
  { key: "621320", value: "Offices of Optometrists" },
  {
    key: "541720",
    value: "Research and Development in the Social Sciences and Humanities"
  },
  {
    key: "441228",
    value: "Motorcycle, ATV, and All Other Motor Vehicle Dealers"
  },
  {
    key: "424990",
    value: "Other Miscellaneous Nondurable Goods Merchant Wholesalers"
  },
  { key: "113110", value: "Timber Tract Operations" },
  { key: "522220", value: "Sales Financing" },
  { key: "621910", value: "Ambulance Services" },
  { key: "441222", value: "Boat Dealers" },
  {
    key: "423940",
    value:
      "Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers"
  },
  { key: "562910", value: "Remediation Services" },
  { key: "238340", value: "Tile and Terrazzo Contractors" },
  { key: "325991", value: "Custom Compounding of Purchased Resins" },
  {
    key: "325992",
    value: "Photographic Film, Paper, Plate, and Chemical Manufacturing"
  },
  { key: "561320", value: "Temporary Help Services" },
  { key: "541330", value: "Engineering Services" },
  {
    key: "325998",
    value:
      "All Other Miscellaneous Chemical Product and Preparation Manufacturing"
  },
  {
    key: "484122",
    value: "General Freight Trucking, Long-Distance, Less Than Truckload"
  },
  { key: "541110", value: "Offices of Lawyers" },
  { key: "561110", value: "Office Administrative Services" },
  { key: "336412", value: "Aircraft Engine and Engine Parts Manufacturing" },
  { key: "523110", value: "Investment Banking and Securities Dealing" },
  { key: "424340", value: "Footwear Merchant Wholesalers" },
  { key: "488330", value: "Navigational Services to Shipping" },
  { key: "337920", value: "Blind and Shade Manufacturing" },
  {
    key: "532412",
    value:
      "Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing"
  },
  { key: "111411", value: "Mushroom Production" },
  { key: "114119", value: "Other Marine Fishing" },
  { key: "611511", value: "Cosmetology and Barber Schools" },
  {
    key: "333999",
    value: "All Other Miscellaneous General Purpose Machinery Manufacturing"
  },
  { key: "611513", value: "Apprenticeship Training" },
  { key: "114112", value: "Shellfish Fishing" },
  { key: "111419", value: "Other Food Crops Grown Under Cover" },
  { key: "333995", value: "Fluid Power Cylinder and Actuator Manufacturing" },
  { key: "114111", value: "Finfish Fishing" },
  { key: "333993", value: "Packaging Machinery Manufacturing" },
  { key: "333992", value: "Welding and Soldering Equipment Manufacturing" },
  { key: "333991", value: "Power-Driven Handtool Manufacturing" },
  { key: "453920", value: "Art Dealers" },
  { key: "448130", value: "Children\u2019s and Infants\u2019 Clothing Stores" },
  { key: "541860", value: "Direct Mail Advertising" },
  { key: "532420", value: "Office Machinery and Equipment Rental and Leasing" },
  {
    key: "333414",
    value: "Heating Equipment (except Warm Air Furnaces) Manufacturing"
  },
  {
    key: "333415",
    value:
      "Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing"
  },
  {
    key: "333413",
    value:
      "Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing"
  },
  { key: "611691", value: "Exam Preparation and Tutoring" },
  { key: "511130", value: "Book Publishers" },
  { key: "541618", value: "Other Management Consulting Services" },
  {
    key: "541614",
    value: "Process, Physical Distribution, and Logistics Consulting Services"
  },
  { key: "541613", value: "Marketing Consulting Services" },
  { key: "541612", value: "Human Resources Consulting Services" },
  {
    key: "541611",
    value:
      "Administrative Management and General Management Consulting Services"
  },
  { key: "525910", value: "Open-End Investment Funds" },
  { key: "562219", value: "Other Nonhazardous Waste Treatment and Disposal" },
  { key: "311423", value: "Dried and Dehydrated Food Manufacturing" },
  { key: "311422", value: "Specialty Canning" },
  { key: "311421", value: "Fruit and Vegetable Canning" },
  {
    key: "423110",
    value: "Automobile and Other Motor Vehicle Merchant Wholesalers"
  },
  { key: "423440", value: "Other Commercial Equipment Merchant Wholesalers" },
  {
    key: "424320",
    value:
      "Men\u2019s and Boys\u2019 Clothing and Furnishings Merchant Wholesalers"
  },
  { key: "561920", value: "Convention and Trade Show Organizers" },
  { key: "811122", value: "Automotive Glass Replacement Shops" },
  { key: "922140", value: "Correctional Institutions" },
  {
    key: "424490",
    value: "Other Grocery and Related Products Merchant Wholesalers"
  },
  { key: "512132", value: "Drive-In Motion Picture Theaters" },
  { key: "561910", value: "Packaging and Labeling Services" },
  { key: "517410", value: "Satellite Telecommunications" },
  { key: "512131", value: "Motion Picture Theaters (except Drive-Ins)" },
  { key: "485119", value: "Other Urban Transit Systems" },
  { key: "624110", value: "Child and Youth Services" },
  { key: "488119", value: "Other Airport Operations" },
  { key: "485113", value: "Bus and Other Motor Vehicle Transit Systems" },
  { key: "485112", value: "Commuter Rail Systems" },
  { key: "485111", value: "Mixed Mode Transit Systems" },
  { key: "453110", value: "Florists" },
  { key: "334310", value: "Audio and Video Equipment Manufacturing" },
  { key: "623312", value: "Assisted Living Facilities for the Elderly" },
  { key: "339991", value: "Gasket, Packing, and Sealing Device Manufacturing" },
  { key: "811430", value: "Footwear and Leather Goods Repair" },
  { key: "339993", value: "Fastener, Button, Needle, and Pin Manufacturing" },
  {
    key: "311514",
    value: "Dry, Condensed, and Evaporated Dairy Product Manufacturing"
  },
  { key: "311513", value: "Cheese Manufacturing" },
  { key: "311512", value: "Creamery Butter Manufacturing" },
  { key: "311511", value: "Fluid Milk Manufacturing" },
  {
    key: "424920",
    value: "Book, Periodical, and Newspaper Merchant Wholesalers"
  },
  { key: "339999", value: "All Other Miscellaneous Manufacturing" },
  {
    key: "325320",
    value: "Pesticide and Other Agricultural Chemical Manufacturing"
  },
  { key: "515111", value: "Radio Networks" },
  {
    key: "326220",
    value: "Rubber and Plastics Hoses and Belting Manufacturing"
  },
  { key: "524298", value: "All Other Insurance Related Activities" },
  { key: "541870", value: "Advertising Material Distribution Services" },
  {
    key: "524292",
    value: "Third Party Administration of Insurance and Pension Funds"
  },
  { key: "331420", value: "Copper Rolling, Drawing, Extruding, and Alloying" },
  {
    key: "531120",
    value: "Lessors of Nonresidential Buildings (except Miniwarehouses)"
  },
  { key: "524291", value: "Claims Adjusting" },
  { key: "212111", value: "Bituminous Coal and Lignite Surface Mining" },
  { key: "212113", value: "Anthracite Mining" },
  { key: "212112", value: "Bituminous Coal Underground Mining" },
  { key: "481219", value: "Other Nonscheduled Air Transportation" },
  {
    key: "423830",
    value: "Industrial Machinery and Equipment Merchant Wholesalers"
  },
  { key: "561330", value: "Professional Employer Organizations" },
  { key: "532112", value: "Passenger Car Leasing" },
  {
    key: "481211",
    value: "Nonscheduled Chartered Passenger Air Transportation"
  },
  { key: "481212", value: "Nonscheduled Chartered Freight Air Transportation" },
  { key: "532111", value: "Passenger Car Rental" },
  { key: "424710", value: "Petroleum Bulk Stations and Terminals" },
  { key: "238120", value: "Structural Steel and Precast Concrete Contractors" },
  { key: "454390", value: "Other Direct Selling Establishments" },
  { key: "111219", value: "Other Vegetable (except Potato) and Melon Farming" },
  {
    key: "811310",
    value:
      "Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance"
  },
  { key: "325212", value: "Synthetic Rubber Manufacturing" },
  { key: "325211", value: "Plastics Material and Resin Manufacturing" },
  {
    key: "532490",
    value:
      "Other Commercial and Industrial Machinery and Equipment Rental and Leasing"
  },
  {
    key: "325194",
    value: "Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing"
  },
  { key: "311930", value: "Flavoring Syrup and Concentrate Manufacturing" },
  { key: "562920", value: "Materials Recovery Facilities" },
  { key: "111211", value: "Potato Farming" },
  { key: "541350", value: "Building Inspection Services" },
  { key: "238310", value: "Drywall and Insulation Contractors" },
  { key: "448110", value: "Men\u2019s Clothing Stores" },
  { key: "424810", value: "Beer and Ale Merchant Wholesalers" },
  { key: "339920", value: "Sporting and Athletic Goods Manufacturing" },
  { key: "621310", value: "Offices of Chiropractors" },
  { key: "447110", value: "Gasoline Stations with Convenience Stores" },
  { key: "335911", value: "Storage Battery Manufacturing" },
  {
    key: "923130",
    value:
      "Administration of Human Resource Programs (except Education, Public Health, and Veterans\u2019 Affairs Programs)"
  },
  { key: "335912", value: "Primary Battery Manufacturing" },
  {
    key: "331491",
    value:
      "Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding"
  },
  {
    key: "331492",
    value:
      "Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum)"
  },
  { key: "212230", value: "Copper, Nickel, Lead, and Zinc Mining" },
  {
    key: "336310",
    value: "Motor Vehicle Gasoline Engine and Engine Parts Manufacturing"
  },
  { key: "335929", value: "Other Communication and Energy Wire Manufacturing" },
  { key: "611110", value: "Elementary and Secondary Schools" },
  { key: "337211", value: "Wood Office Furniture Manufacturing" },
  {
    key: "337212",
    value: "Custom Architectural Woodwork and Millwork Manufacturing"
  },
  { key: "337214", value: "Office Furniture (except Wood) Manufacturing" },
  {
    key: "337215",
    value: "Showcase, Partition, Shelving, and Locker Manufacturing"
  },
  { key: "561520", value: "Tour Operators" },
  { key: "332510", value: "Hardware Manufacturing" },
  { key: "519120", value: "Libraries and Archives" },
  {
    key: "238220",
    value: "Plumbing, Heating, and Air-Conditioning Contractors"
  },
  { key: "316992", value: "Women\u2019s Handbag and Purse Manufacturing" },
  { key: "813930", value: "Labor Unions and Similar Labor Organizations" },
  { key: "454310", value: "Fuel Dealers" },
  { key: "923140", value: "Administration of Veterans\u2019 Affairs" },
  { key: "111910", value: "Tobacco Farming" },
  { key: "623311", value: "Continuing Care Retirement Communities" },
  {
    key: "316998",
    value: "All Other Leather Good and Allied Product Manufacturing"
  },
  { key: "483212", value: "Inland Water Passenger Transportation" },
  {
    key: "424130",
    value: "Industrial and Personal Service Paper Merchant Wholesalers"
  },
  { key: "483211", value: "Inland Water Freight Transportation" },
  { key: "111320", value: "Citrus (except Orange) Groves" },
  { key: "532282", value: "Video Tape and Disc Rental" },
  { key: "532283", value: "Home Health Equipment Rental" },
  { key: "561422", value: "Telemarketing Bureaus and Other Contact Centers" },
  { key: "561421", value: "Telephone Answering Services" },
  {
    key: "926130",
    value:
      "Regulation and Administration of Communications, Electric, Gas, and Other Utilities"
  },
  { key: "423710", value: "Hardware Merchant Wholesalers" },
  { key: "621210", value: "Offices of Dentists" },
  {
    key: "326130",
    value:
      "Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing"
  },
  { key: "812113", value: "Nail Salons" },
  { key: "541120", value: "Offices of Notaries" },
  { key: "517911", value: "Telecommunications Resellers" },
  { key: "522310", value: "Mortgage and Nonmortgage Loan Brokers" },
  { key: "112330", value: "Turkey Production" },
  { key: "441310", value: "Automotive Parts and Accessories Stores" },
  { key: "517919", value: "All Other Telecommunications" },
  {
    key: "334416",
    value:
      "Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing"
  },
  { key: "327320", value: "Ready-Mix Concrete Manufacturing" },
  {
    key: "623210",
    value: "Residential Intellectual and Developmental Disability Facilities"
  },
  { key: "541713", value: "Research and Development in Nanotechnology" },
  {
    key: "541714",
    value:
      "Research and Development in Biotechnology (except Nanobiotechnology)"
  },
  {
    key: "541715",
    value:
      "Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)"
  },
  { key: "112930", value: "Fur-Bearing Animal and Rabbit Production" },
  { key: "541850", value: "Outdoor Advertising" },
  {
    key: "424590",
    value: "Other Farm Product Raw Material Merchant Wholesalers"
  },
  {
    key: "811121",
    value: "Automotive Body, Paint, and Interior Repair and Maintenance"
  },
  { key: "313240", value: "Knit Fabric Mills" },
  { key: "311119", value: "Other Animal Food Manufacturing" },
  { key: "611420", value: "Computer Training" },
  { key: "311111", value: "Dog and Cat Food Manufacturing" },
  {
    key: "423920",
    value: "Toy and Hobby Goods and Supplies Merchant Wholesalers"
  },
  { key: "713930", value: "Marinas" },
  { key: "446120", value: "Cosmetics, Beauty Supplies, and Perfume Stores" },
  { key: "334516", value: "Analytical Laboratory Instrument Manufacturing" },
  { key: "334517", value: "Irradiation Apparatus Manufacturing" },
  {
    key: "334514",
    value: "Totalizing Fluid Meter and Counting Device Manufacturing"
  },
  {
    key: "334515",
    value:
      "Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals"
  },
  {
    key: "334512",
    value:
      "Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use"
  },
  {
    key: "334513",
    value:
      "Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables"
  },
  {
    key: "334510",
    value: "Electromedical and Electrotherapeutic Apparatus Manufacturing"
  },
  {
    key: "334511",
    value:
      "Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing"
  },
  { key: "451211", value: "Book Stores" },
  { key: "524114", value: "Direct Health and Medical Insurance Carriers" },
  { key: "523130", value: "Commodity Contracts Dealing" },
  { key: "451212", value: "News Dealers and Newsstands" },
  { key: "424440", value: "Poultry and Poultry Product Merchant Wholesalers" },
  { key: "541840", value: "Media Representatives" },
  { key: "326140", value: "Polystyrene Foam Product Manufacturing" },
  {
    key: "334519",
    value: "Other Measuring and Controlling Device Manufacturing"
  },
  {
    key: "484121",
    value: "General Freight Trucking, Long-Distance, Truckload"
  },
  { key: "924120", value: "Administration of Conservation Programs" },
  { key: "541620", value: "Environmental Consulting Services" },
  { key: "561621", value: "Security Systems Services (except Locksmiths)" },
  {
    key: "237110",
    value: "Water and Sewer Line and Related Structures Construction"
  },
  { key: "511199", value: "All Other Publishers" },
  { key: "561622", value: "Locksmiths" },
  { key: "311412", value: "Frozen Specialty Food Manufacturing" },
  {
    key: "423120",
    value: "Motor Vehicle Supplies and New Parts Merchant Wholesalers"
  },
  { key: "311411", value: "Frozen Fruit, Juice, and Vegetable Manufacturing" },
  { key: "524130", value: "Reinsurance Carriers" },
  {
    key: "424210",
    value: "Drugs and Druggists\u2019 Sundries Merchant Wholesalers"
  },
  { key: "621511", value: "Medical Laboratories" },
  { key: "712130", value: "Zoos and Botanical Gardens" },
  { key: "339992", value: "Musical Instrument Manufacturing" },
  { key: "713940", value: "Fitness and Recreational Sports Centers" },
  { key: "322299", value: "All Other Converted Paper Product Manufacturing" },
  { key: "325613", value: "Surface Active Agent Manufacturing" },
  { key: "325612", value: "Polish and Other Sanitation Good Manufacturing" },
  { key: "325611", value: "Soap and Other Detergent Manufacturing" },
  { key: "523140", value: "Commodity Contracts Brokerage" },
  { key: "315210", value: "Cut and Sew Apparel Contractors" },
  { key: "322291", value: "Sanitary Paper Product Manufacturing" },
  {
    key: "424690",
    value: "Other Chemical and Allied Products Merchant Wholesalers"
  },
  { key: "339113", value: "Surgical Appliance and Supplies Manufacturing" },
  { key: "339112", value: "Surgical and Medical Instrument Manufacturing" },
  { key: "339115", value: "Ophthalmic Goods Manufacturing" },
  { key: "339114", value: "Dental Equipment and Supplies Manufacturing" },
  { key: "339116", value: "Dental Laboratories" },
  { key: "339995", value: "Burial Casket Manufacturing" },
  { key: "562211", value: "Hazardous Waste Treatment and Disposal" },
  { key: "562212", value: "Solid Waste Landfill" },
  { key: "562213", value: "Solid Waste Combustors and Incinerators" },
  {
    key: "621420",
    value: "Outpatient Mental Health and Substance Abuse Centers"
  },
  { key: "112111", value: "Beef Cattle Ranching and Farming" },
  { key: "112112", value: "Cattle Feedlots" },
  { key: "813212", value: "Voluntary Health Organizations" },
  { key: "115310", value: "Support Activities for Forestry" },
  { key: "331513", value: "Steel Foundries (except Investment)" },
  { key: "624410", value: "Child Day Care Services" },
  { key: "325312", value: "Phosphatic Fertilizer Manufacturing" },
  { key: "325311", value: "Nitrogenous Fertilizer Manufacturing" },
  { key: "448150", value: "Clothing Accessories Stores" },
  { key: "561450", value: "Credit Bureaus" },
  { key: "325314", value: "Fertilizer (Mixing Only) Manufacturing" },
  {
    key: "423330",
    value: "Roofing, Siding, and Insulation Material Merchant Wholesalers"
  },
  {
    key: "238210",
    value: "Electrical Contractors and Other Wiring Installation Contractors"
  },
  { key: "451130", value: "Sewing, Needlework, and Piece Goods Stores" },
  { key: "111140", value: "Wheat Farming" },
  {
    key: "332323",
    value: "Ornamental and Architectural Metal Work Manufacturing"
  },
  { key: "332322", value: "Sheet Metal Work Manufacturing" },
  { key: "332321", value: "Metal Window and Door Manufacturing" },
  { key: "525120", value: "Health and Welfare Funds" },
  { key: "812930", value: "Parking Lots and Garages" },
  { key: "811192", value: "Car Washes" },
  {
    key: "423620",
    value:
      "Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers"
  },
  { key: "811191", value: "Automotive Oil Change and Lubrication Shops" },
  { key: "331511", value: "Iron Foundries" },
  { key: "311520", value: "Ice Cream and Frozen Dessert Manufacturing" },
  { key: "331512", value: "Steel Investment Foundries" },
  { key: "315280", value: "Other Cut and Sew Apparel Manufacturing" },
  { key: "333131", value: "Mining Machinery and Equipment Manufacturing" },
  {
    key: "333132",
    value: "Oil and Gas Field Machinery and Equipment Manufacturing"
  },
  { key: "326212", value: "Tire Retreading" },
  { key: "721120", value: "Casino Hotels" },
  {
    key: "336350",
    value: "Motor Vehicle Transmission and Power Train Parts Manufacturing"
  },
  { key: "326211", value: "Tire Manufacturing (except Retreading)" },
  { key: "325910", value: "Printing Ink Manufacturing" },
  { key: "485410", value: "School and Employee Bus Transportation" },
  { key: "541922", value: "Commercial Photography" },
  { key: "561710", value: "Exterminating and Pest Control Services" },
  { key: "454210", value: "Vending Machine Operators" },
  {
    key: "336360",
    value: "Motor Vehicle Seating and Interior Trim Manufacturing"
  },
  { key: "238170", value: "Siding Contractors" },
  { key: "238910", value: "Site Preparation Contractors" },
  { key: "611410", value: "Business and Secretarial Schools" },
  { key: "111130", value: "Dry Pea and Bean Farming" },
  { key: "562991", value: "Septic Tank and Related Services" },
  {
    key: "311941",
    value: "Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing"
  },
  { key: "311942", value: "Spice and Extract Manufacturing" },
  { key: "541430", value: "Graphic Design Services" },
  { key: "115210", value: "Support Activities for Animal Production" },
  { key: "611610", value: "Fine Arts Schools" },
  { key: "541921", value: "Photography Studios, Portrait" },
  { key: "541930", value: "Translation and Interpretation Services" },
  { key: "541360", value: "Geophysical Surveying and Mapping Services" },
  { key: "445220", value: "Fish and Seafood Markets" },
  { key: "522210", value: "Credit Card Issuing" },
  { key: "333314", value: "Optical Instrument and Lens Manufacturing" },
  {
    key: "333316",
    value: "Photographic and Photocopying Equipment Manufacturing"
  },
  {
    key: "333318",
    value: "Other Commercial and Service Industry Machinery Manufacturing"
  },
  {
    key: "325220",
    value: "Artificial and Synthetic Fibers and Filaments Manufacturing"
  },
  { key: "221310", value: "Water Supply and Irrigation Systems" },
  { key: "453210", value: "Office Supplies and Stationery Stores" },
  { key: "561510", value: "Travel Agencies" },
  { key: "541320", value: "Landscape Architectural Services" },
  { key: "441210", value: "Recreational Vehicle Dealers" },
  { key: "332216", value: "Saw Blade and Handtool Manufacturing" },
  {
    key: "332215",
    value:
      "Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing"
  },
  { key: "531110", value: "Lessors of Residential Buildings and Dwellings" },
  { key: "621991", value: "Blood and Organ Banks" },
  { key: "522190", value: "Other Depository Credit Intermediation" },
  { key: "332420", value: "Metal Tank (Heavy Gauge) Manufacturing" },
  { key: "237990", value: "Other Heavy and Civil Engineering Construction" },
  { key: "238350", value: "Finish Carpentry Contractors" },
  { key: "313110", value: "Fiber, Yarn, and Thread Mills" },
  { key: "928120", value: "International Affairs" },
  { key: "484110", value: "General Freight Trucking, Local" },
  {
    key: "486910",
    value: "Pipeline Transportation of Refined Petroleum Products"
  },
  { key: "517311", value: "Wired Telecommunications Carriers" },
  { key: "512230", value: "Music Publishers" },
  { key: "522298", value: "All Other Nondepository Credit Intermediation" },
  {
    key: "517312",
    value: "Wireless Telecommunications Carriers (except Satellite)"
  },
  { key: "314910", value: "Textile Bag and Canvas Mills" },
  { key: "812332", value: "Industrial Launderers" },
  { key: "812331", value: "Linen Supply" },
  { key: "333922", value: "Conveyor and Conveying Equipment Manufacturing" },
  { key: "112390", value: "Other Poultry Production" },
  { key: "312130", value: "Wineries" },
  { key: "493130", value: "Farm Product Warehousing and Storage" },
  { key: "623990", value: "Other Residential Care Facilities" },
  { key: "561612", value: "Security Guards and Patrol Services" },
  { key: "488490", value: "Other Support Activities for Road Transportation" },
  { key: "624210", value: "Community Food Services" },
  { key: "624190", value: "Other Individual and Family Services" },
  {
    key: "621999",
    value: "All Other Miscellaneous Ambulatory Health Care Services"
  },
  { key: "445291", value: "Baked Goods Stores" },
  { key: "445292", value: "Confectionery and Nut Stores" },
  { key: "423840", value: "Industrial Supplies Merchant Wholesalers" },
  { key: "325130", value: "Synthetic Dye and Pigment Manufacturing" },
  { key: "424520", value: "Livestock Merchant Wholesalers" },
  { key: "322110", value: "Pulp Mills" },
  {
    key: "327215",
    value: "Glass Product Manufacturing Made of Purchased Glass"
  },
  { key: "541199", value: "All Other Legal Services" },
  { key: "541211", value: "Offices of Certified Public Accountants" },
  {
    key: "333914",
    value: "Measuring, Dispensing, and Other Pumping Equipment Manufacturing"
  },
  { key: "327211", value: "Flat Glass Manufacturing" },
  { key: "333912", value: "Air and Gas Compressor Manufacturing" },
  { key: "327213", value: "Glass Container Manufacturing" },
  { key: "322121", value: "Paper (except Newsprint) Mills" },
  { key: "541191", value: "Title Abstract and Settlement Offices" },
  { key: "213114", value: "Support Activities for Metal Mining" },
  {
    key: "213115",
    value: "Support Activities for Nonmetallic Minerals (except Fuels) Mining"
  },
  { key: "213112", value: "Support Activities for Oil and Gas Operations" },
  { key: "213113", value: "Support Activities for Coal Mining" },
  { key: "481111", value: "Scheduled Passenger Air Transportation" },
  { key: "213111", value: "Drilling Oil and Gas Wells" },
  { key: "321113", value: "Sawmills" },
  { key: "321114", value: "Wood Preservation" },
  { key: "922150", value: "Parole Offices and Probation Offices" },
  {
    key: "424310",
    value: "Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers"
  },
  { key: "813940", value: "Political Organizations" },
  { key: "621410", value: "Family Planning Centers" },
  { key: "112410", value: "Sheep Farming" },
  { key: "332722", value: "Bolt, Nut, Screw, Rivet, and Washer Manufacturing" },
  { key: "332721", value: "Precision Turned Product Manufacturing" },
  {
    key: "519130",
    value: "Internet Publishing and Broadcasting and Web Search Portals"
  },
  { key: "813110", value: "Religious Organizations" },
  { key: "624229", value: "Other Community Housing Services" },
  { key: "561740", value: "Carpet and Upholstery Cleaning Services" },
  { key: "512120", value: "Motion Picture and Video Distribution" },
  { key: "721199", value: "All Other Traveler Accommodation" },
  { key: "515210", value: "Cable and Other Subscription Programming" },
  { key: "238320", value: "Painting and Wall Covering Contractors" },
  { key: "424410", value: "General Line Grocery Merchant Wholesalers" },
  { key: "313230", value: "Nonwoven Fabric Mills" },
  { key: "511140", value: "Directory and Mailing List Publishers" },
  {
    key: "813312",
    value: "Environment, Conservation and Wildlife Organizations"
  },
  { key: "212324", value: "Kaolin and Ball Clay Mining" },
  { key: "326199", value: "All Other Plastics Product Manufacturing" },
  { key: "326191", value: "Plastics Plumbing Fixture Manufacturing" },
  { key: "331222", value: "Steel Wire Drawing" },
  { key: "331221", value: "Rolled Steel Shape Manufacturing" },
  { key: "532210", value: "Consumer Electronics and Appliances Rental" },
  { key: "424950", value: "Paint, Varnish, and Supplies Merchant Wholesalers" },
  { key: "448210", value: "Shoe Stores" },
  {
    key: "621399",
    value: "Offices of All Other Miscellaneous Health Practitioners"
  },
  { key: "448190", value: "Other Clothing Stores" },
  { key: "515120", value: "Television Broadcasting" },
  { key: "621391", value: "Offices of Podiatrists" },
  { key: "238140", value: "Masonry Contractors" },
  { key: "111991", value: "Sugar Beet Farming" },
  { key: "325620", value: "Toilet Preparation Manufacturing" },
  { key: "111992", value: "Peanut Farming" },
  {
    key: "424330",
    value:
      "Women\u2019s, Children\u2019s, and Infants\u2019 Clothing and Accessories Merchant Wholesalers"
  },
  {
    key: "315240",
    value:
      "Women\u2019s, Girls\u2019, and Infants\u2019 Cut and Sew Apparel Manufacturing"
  },
  {
    key: "331410",
    value: "Nonferrous Metal (except Aluminum) Smelting and Refining"
  },
  { key: "111998", value: "All Other Miscellaneous Crop Farming" },
  {
    key: "332813",
    value: "Electroplating, Plating, Polishing, Anodizing, and Coloring"
  },
  {
    key: "332812",
    value:
      "Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers"
  },
  { key: "531130", value: "Lessors of Miniwarehouses and Self-Storage Units" },
  { key: "336390", value: "Other Motor Vehicle Parts Manufacturing" },
  { key: "332613", value: "Spring Manufacturing" },
  { key: "512191", value: "Teleproduction and Other Postproduction Services" },
  { key: "323120", value: "Support Activites for Printing" },
  { key: "711120", value: "Dance Companies" },
  { key: "336411", value: "Aircraft Manufacturing" },
  { key: "512199", value: "Other Motion Picture and Video Industries" },
  {
    key: "336413",
    value: "Other Aircraft Parts and Auxiliary Equipment Manufacturing"
  },
  { key: "332618", value: "Other Fabricated Wire Product Manufacturing" },
  {
    key: "336415",
    value:
      "Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing"
  },
  { key: "336414", value: "Guided Missile and Space Vehicle Manufacturing" },
  { key: "311230", value: "Breakfast Cereal Manufacturing" },
  { key: "336999", value: "All Other Transportation Equipment Manufacturing" },
  { key: "331315", value: "Aluminum Sheet, Plate, and Foil Manufacturing" },
  { key: "331314", value: "Secondary Smelting and Alloying of Aluminum" },
  { key: "331313", value: "Alumina Refining and Primary Aluminum Production" },
  { key: "487990", value: "Scenic and Sightseeing Transportation, Other" },
  { key: "448320", value: "Luggage and Leather Goods Stores" },
  { key: "336991", value: "Motorcycle, Bicycle, and Parts Manufacturing" },
  {
    key: "336992",
    value: "Military Armored Vehicle, Tank, and Tank Component Manufacturing"
  },
  { key: "238130", value: "Framing Contractors" },
  {
    key: "561599",
    value: "All Other Travel Arrangement and Reservation Services"
  },
  { key: "331318", value: "Other Aluminum Rolling, Drawing, and Extruding" },
  { key: "522390", value: "Other Activities Related to Credit Intermediation" },
  { key: "611710", value: "Educational Support Services" },
  { key: "112320", value: "Broilers and Other Meat Type Chicken Production" },
  { key: "541490", value: "Other Specialized Design Services" },
  { key: "325180", value: "Other Basic Inorganic Chemical Manufacturing" },
  { key: "111199", value: "All Other Grain Farming" },
  { key: "314120", value: "Curtain and Linen Mills" },
  { key: "339930", value: "Doll, Toy, and Game Manufacturing" },
  { key: "111191", value: "Oilseed and Grain Combination Farming" },
  { key: "335921", value: "Fiber Optic Cable Manufacturing" },
  { key: "444210", value: "Outdoor Power Equipment Stores" },
  { key: "923120", value: "Administration of Public Health Programs" },
  { key: "325199", value: "All Other Basic Organic Chemical Manufacturing" },
  { key: "212222", value: "Silver Ore Mining" },
  { key: "212221", value: "Gold Ore Mining" },
  {
    key: "423910",
    value: "Sporting and Recreational Goods and Supplies Merchant Wholesalers"
  },
  { key: "111920", value: "Cotton Farming" },
  {
    key: "324199",
    value: "All Other Petroleum and Coal Products Manufacturing"
  },
  { key: "238390", value: "Other Building Finishing Contractors" },
  { key: "332312", value: "Fabricated Structural Metal Manufacturing" },
  { key: "332313", value: "Plate Work Manufacturing" },
  {
    key: "332311",
    value: "Prefabricated Metal Building and Component Manufacturing"
  },
  { key: "221210", value: "Natural Gas Distribution" },
  { key: "541810", value: "Advertising Agencies" },
  {
    key: "324191",
    value: "Petroleum Lubricating Oil and Grease Manufacturing"
  },
  { key: "447190", value: "Other Gasoline Stations" },
  { key: "813920", value: "Professional Organizations" },
  { key: "812112", value: "Beauty Salons" },
  { key: "311830", value: "Tortilla Manufacturing" },
  { key: "525110", value: "Pension Funds" },
  {
    key: "811490",
    value: "Other Personal and Household Goods Repair and Maintenance"
  },
  { key: "812111", value: "Barber Shops" },
  { key: "111335", value: "Tree Nut Farming" },
  { key: "111334", value: "Berry (except Strawberry) Farming" },
  { key: "111336", value: "Fruit and Tree Nut Combination Farming" },
  { key: "111331", value: "Apple Orchards" },
  { key: "541380", value: "Testing Laboratories" },
  { key: "111333", value: "Strawberry Farming" },
  { key: "111332", value: "Grape Vineyards" },
  {
    key: "333924",
    value:
      "Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing"
  },
  { key: "111339", value: "Other Noncitrus Fruit Farming" },
  {
    key: "333923",
    value: "Overhead Traveling Crane, Hoist, and Monorail System Manufacturing"
  },
  { key: "333921", value: "Elevator and Moving Stairway Manufacturing" },
  { key: "561431", value: "Private Mail Centers" },
  { key: "445310", value: "Beer, Wine, and Liquor Stores" },
  { key: "486110", value: "Pipeline Transportation of Crude Oil" },
  {
    key: "522320",
    value:
      "Financial Transactions Processing, Reserve, and Clearinghouse Activities"
  },
  { key: "327910", value: "Abrasive Product Manufacturing" },
  {
    key: "561439",
    value: "Other Business Service Centers (including Copy Shops)"
  },
  { key: "713110", value: "Amusement and Theme Parks" },
  { key: "325193", value: "Ethyl Alcohol Manufacturing" },
  { key: "611620", value: "Sports and Recreation Instruction" },
  { key: "423390", value: "Other Construction Material Merchant Wholesalers" },
  { key: "339940", value: "Office Supplies (except Paper) Manufacturing" },
  { key: "441320", value: "Tire Dealers" },
  { key: "334210", value: "Telephone Apparatus Manufacturing" },
  { key: "237210", value: "Land Subdivision" },
  { key: "522292", value: "Real Estate Credit" },
  { key: "212312", value: "Crushed and Broken Limestone Mining and Quarrying" },
  { key: "327310", value: "Cement Manufacturing" },
  { key: "622210", value: "Psychiatric and Substance Abuse Hospitals" },
  { key: "488111", value: "Air Traffic Control" },
  {
    key: "212319",
    value: "Other Crushed and Broken Stone Mining and Quarrying"
  },
  { key: "212313", value: "Crushed and Broken Granite Mining and Quarrying" },
  {
    key: "236220",
    value: "Commercial and Institutional Building Construction"
  },
  { key: "212311", value: "Dimension Stone Mining and Quarrying" }
];

export const VETERAN_STATUS_OPTIONS: OptionType[] = [
  { key: "Non-Veteran", value: "Non-Veteran" },
  { key: "Veteran", value: "Veteran" },
  { key: "Service-Disabled Veteran", value: "Service-Disabled Veteran" },
  { key: "Spouse of Veteran", value: "Spouse of Veteran" },
  { key: "Not Disclosed", value: "Not Disclosed" }
];

export const ETHNICITY_OPTIONS: OptionType[] = [
  { key: "Hispanic or Latino", value: "Hispanic or Latino" },
  { key: "Not Hispanic or Latino", value: "Not Hispanic or Latino" },
  { key: "Not Disclosed", value: "Not Disclosed" }
];

export const GENDER_OPTIONS: OptionType[] = [
  { key: "Male", value: "Male" },
  { key: "Female", value: "Female" },
  { key: "N/A", value: "N/A" },
  { key: "Not Disclosed", value: "Not Disclosed" }
];

export const RACE_OPTIONS: OptionType[] = [
  {
    key: "American Indian or Alaska Native",
    value: "American Indian or Alaska Native"
  },
  { key: "Asian", value: "Asian" },
  { key: "Black or African American", value: "Black or African American" },
  {
    key: "Native Hawaiian or Other Pacific Islander",
    value: "Native Hawaiian or Other Pacific Islander"
  },
  { key: "White", value: "White" },
  { key: "Not Applicable", value: "Not Applicable" },
  { key: "Not Disclosed", value: "Not Disclosed" }
];

/** State abbreviations sorted by abbreviation. */
export const STATE_ABBREVIATION_OPTIONS = usStates
  .map(state => Object.keys(state)[0])
  .sort()
  .map<OptionType>(abbr => ({ key: abbr, value: abbr }));

export const LOAN_CLASS_OPTIONS = [
  {
    key: "CRE",
    value: "Commercial Real Estate"
  },
  {
    key: "C&I",
    value: "Commercial & Industrial"
  },
  { key: "Unsecured", value: "Unsecured" },
  { key: "Business Credit Card", value: "Business Credit Card" }
];

export const DROP_DOWN_PLACEHOLDER = "PLEASE_MAKE_SELECTION";

interface OptionType {
  key: string;
  value: string;
}
