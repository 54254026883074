import React from "react";
import { string, func, arrayOf, object } from "prop-types";
import { Form } from "semantic-ui-react";
import {
  Wrapper,
  DisclaimerContainer,
  DisclaimerText
} from "./EditFinalizedThroughDateStyle";
import { EditModal } from "../../../components/Modals";
import { getDateString } from "../../../services/DateTime";

function EditMonthEndDate({
  institutionUuid,
  meFinalizedThroughDate,
  institutionName,
  onClose,
  onSave,
  validDates,
  coreOfRecord,
  ...props
}) {
  const handleSave = body => {
    onSave({ body, coreOfRecord });
  };
  const disclaimerText =
    "Be sure that all transactions for a month have been recorded before finalizing for that month. Month-end reports will be locked and cannot be updated once the month is finalized.";
  return (
    <EditModal
      key={institutionUuid}
      onSave={handleSave}
      defaultValues={{
        me_finalized_through: getDateString(new Date(meFinalizedThroughDate)),
        uuid: institutionUuid
      }}
      onClose={onClose}
      header={`Change Finalized Through Date for ${institutionName}`}
      {...props}
    >
      {({ onChange, values }) => (
        <Wrapper>
          <DisclaimerContainer>
            <DisclaimerText>{disclaimerText}</DisclaimerText>
          </DisclaimerContainer>
          <div>
            {meFinalizedThroughDate ? (
              <Form.Select
                label="Finalized Through Date"
                name="me_finalized_through"
                onChange={(ev, { name, value }) => onChange({ name, value })}
                options={validDates}
                value={values.me_finalized_through || ""}
              />
            ) : (
              <Form.Input
                type="date"
                label="Finalized Through Date"
                name="me_finalized_through"
                onChange={(ev, { name, value }) => onChange({ name, value })}
                value={values.me_finalized_through || ""}
              />
            )}
          </div>
        </Wrapper>
      )}
    </EditModal>
  );
}

EditMonthEndDate.propTypes = {
  meFinalizedThroughDate: string,
  institutionName: string,
  institutionUuid: string,
  onClose: func,
  onSave: func,
  validDates: arrayOf(object),
  coreOfRecord: string
};

export default EditMonthEndDate;
