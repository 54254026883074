import React from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "semantic-ui-react";

import { stepList } from "../../services/constants";

function Buttons({
  currentPage,
  disabled,
  onSave,
  onWithdraw,
  onSaveAndNext,
  submitForDecision
}) {
  return (
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column floated="left" width="5">
          <Button
            color="orange"
            content="Withdraw Application"
            disabled={disabled}
            onClick={onWithdraw}
          />
        </Grid.Column>
        <Grid.Column floated="right" width="5">
          {currentPage === stepList[4] ? (
            <Button
              content="Submit for Decision"
              disabled={false}
              onClick={submitForDecision}
              primary
            />
          ) : (
            <div>
              <Button
                content="Save"
                disabled={disabled}
                onClick={onSave}
                primary
              />
              <Button
                content="Save & Next"
                disabled={disabled}
                onClick={onSaveAndNext}
              />
            </div>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

Buttons.defaultProps = {
  onSave: undefined,
  submitForDecision: undefined
};

Buttons.propTypes = {
  currentPage: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  onWithdraw: PropTypes.func.isRequired,
  onSaveAndNext: PropTypes.func.isRequired,
  submitForDecision: PropTypes.func
};

export default Buttons;
