import React from "react";
import { Table } from "semantic-ui-react";
import { string, number, shape, arrayOf } from "prop-types";
import { FormatCurrency } from "num-format";

const propTypes = {
  loans: arrayOf(
    shape({
      account_type: string,
      bafs_account_number: string,
      current_balance: number,
      available_balance: number,
      total_exposure: number,
      uuid: string,
      institution_account_number: string,
      loan_class: string,
      loan_type: string
    })
  )
};
function ResultsRow({ loans }) {
  return (
    <>
      {loans.map(loan => (
        <Table.Row key={loan.uuid}>
          <Table.Cell>
            <a
              href={`/loan_management/${loan.uuid}/details/balance`}
              rel="noreferrer noopener"
              target="_blank"
            >
              {loan.bafs_account_number}
            </a>
          </Table.Cell>
          <Table.Cell>{loan.institution_account_number}</Table.Cell>
          <Table.Cell>
            {loan.current_balance &&
              FormatCurrency(loan.current_balance, false)}
          </Table.Cell>
          <Table.Cell>
            {loan.available_balance &&
              FormatCurrency(loan.available_balance, false)}
          </Table.Cell>
          <Table.Cell>
            {loan.total_exposure && FormatCurrency(loan.total_exposure, false)}
          </Table.Cell>
          <Table.Cell>{loan.account_type}</Table.Cell>
          <Table.Cell>{loan.loan_class}</Table.Cell>
          <Table.Cell>{loan.loan_type}</Table.Cell>
          <Table.Cell>{loan.month_end_date}</Table.Cell>
        </Table.Row>
      ))}
    </>
  );
}
ResultsRow.propTypes = propTypes;
export default ResultsRow;
