export const Environment = {
  Local: "local",
  Development: "dev",
  Testing: "testing",
  Demo: "demo",
  Stage: "stage",
  Production: "production"
};

const envMap = {
  "bafs.com": Environment.Production,
  "bafsdev.net": Environment.Development,
  "bafstest.net": Environment.Testing,
  "bafsdemo.net": Environment.Demo
};

export function getEnv() {
  const href = new URL(window.location.href);
  const hostWithoutSubdomains = href.hostname.split(".").slice(-2).join(".");
  return envMap[hostWithoutSubdomains] || Environment.Local;
}

const sampleRateMap = {
  [Environment.Local]: 0.0, // Turn this up if testing Sentry locally.
  [Environment.Development]: 1.0,
  [Environment.Testing]: 1.0,
  [Environment.Demo]: 1.0,
  [Environment.Production]: 0.25
};

const sampleReplayRateMap = {
  [Environment.Local]: 0.0,
  [Environment.Development]: 0.0,
  [Environment.Testing]: 0.0,
  [Environment.Demo]: 0.0,
  [Environment.Production]: 0.1
};

export function getSentrySampleRate() {
  return sampleRateMap[getEnv()] || sampleRateMap[Environment.Local];
}

export function getSentrySampleReplayRate() {
  return (
    sampleReplayRateMap[getEnv()] || sampleReplayRateMap[Environment.Local]
  );
}
