export const initialState = {
  email: "",
  jwt: null,
  userUuid: null,
  firstName: "",
  lastName: "",
  authenticated: false,
  loginFailed: null,
  institutionName: "",
  institutionUuid: "",
  msg: "",
  openModal: false,
  errorMessage: ""
};

// eslint-disable-next-line default-param-last
export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_LOGIN": {
      return {
        ...state,
        jwt: action.data.token,
        userUuid: action.data.user_uuid,
        institutionUuid: action.data.institution_uuid,
        firstName: action.data.first_name,
        lastName: action.data.last_name,
        authenticated: true,
        loginFailed: null,
        errorMessage: ""
      };
    }

    case "LOGIN_ERROR_MSG": {
      return {
        ...state,
        errorMessage: action.data
      };
    }

    case "LOGIN_ERROR": {
      return {
        ...state,
        jwt: null,
        userUuid: null,
        authenticated: false,
        loginFailed: "failed"
      };
    }

    case "LOGIN_CLEAR_ERROR": {
      return {
        ...state,
        loginFailed: "",
        errorMessage: ""
      };
    }

    case "LOGIN_TOGGLE_MODAL":
      return {
        ...state,
        openModal: !state.openModal,
        email: "",
        msg: "",
        errorMessage: ""
      };

    case "LOGIN_HANDLE_CHANGE":
      return {
        ...state,
        email: action.value
      };

    case "LOGOUT": {
      return {
        ...state,
        jwt: undefined,
        userUuid: undefined,
        firstName: undefined,
        errorMessage: "",
        lastName: undefined,
        authenticated: false,
        loginFailed: null
      };
    }
    case "AUTH_GET_INST_DATA": {
      return {
        ...state,
        institutionName: action.data.name,
        institutionHasBp: action.data.borrower_portal_service
      };
    }

    case "LOGIN_PASSWORD_RESET_MESSAGE":
      return { ...state, msg: action.msg };

    default:
      return state;
  }
}
