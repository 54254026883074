import React, { useState } from "react";
import {
  summaryBlockStyle,
  codeText,
  tableStyle,
  trStyle
} from "../../../../appStatusStyles";
import { CreateTest } from "./create";
import { ReadSingleTest } from "./readSingle";
import { UpdateTest } from "./update";
import { ReadManyTest } from "./readMany";
import { DeleteTest } from "./delete";

/* Test Setup Data - Hardcoded UUIDs must already be in the database
  These tests will write records to the database. If the cleanup fails,
  it'll be dumping garbage data into the DB. Only run this on records
  tied to the BAFS Bank testing institution:
+----------------+-----------+--------------------------------------+
| institution_id | name      | uuid                                 |
+----------------+-----------+--------------------------------------+
| 16             | BAFS Bank | institut-ions-0016-IBLS-TestBranch00 |
+----------------+-----------+--------------------------------------+
*/
const institutionUuid = "institut-ions-0016-IBLS-TestBranch00";

export const Loan = () => {
  const [newRecordUuid, setRecordUuid] = useState("");
  const [statusCreate, setStatusCreate] = useState("Waiting...");
  const [statusReadSingle, setStatusReadSingle] = useState("Waiting...");
  const [statusUpdate, setStatusUpdate] = useState("Waiting...");
  const [statusReadMany, setStatusReadMany] = useState("Waiting...");
  const [statusDelete, setStatusDelete] = useState("Waiting...");

  function setUuidOfCreatedRecord(uuid: string) {
    if (!newRecordUuid) {
      setRecordUuid(uuid);
    }
  }

  function headerWithStatus() {
    const headerTxt = "Loans";
    if (
      statusCreate == "Pass" &&
      statusReadSingle == "Pass" &&
      statusUpdate == "Pass" &&
      statusReadMany == "Pass" &&
      statusDelete == "Pass"
    ) {
      return <h3>✅ {headerTxt}</h3>;
    } else if (
      statusCreate == "Fail" ||
      statusReadSingle == "Fail" ||
      statusUpdate == "Fail" ||
      statusReadMany == "Fail" ||
      statusDelete == "Fail"
    ) {
      return <h3>❌ {headerTxt}</h3>;
    }
    return <h3>⏲️ {headerTxt}</h3>;
  }

  return (
    <>
      {headerWithStatus()}
      <div style={summaryBlockStyle}>
        <table style={tableStyle}>
          <tbody>
            <tr style={trStyle}>
              <td>
                <b>Create:</b>
              </td>
              <td>
                <code style={codeText}>{"useCreateLoanMutation()"}</code>
              </td>
              <td>
                {!newRecordUuid ? (
                  <CreateTest
                    institutionUuid={institutionUuid}
                    setUuidOfCreatedRecord={setUuidOfCreatedRecord}
                    setStatusInParent={setStatusCreate}
                  />
                ) : (
                  <>
                    <b>✅ Pass:</b>{" "}
                    <small>Record Created: {newRecordUuid}</small>
                  </>
                )}
              </td>
            </tr>
            <tr style={trStyle}>
              <td>
                <b>Read - Single:</b>
              </td>
              <td>
                <code style={codeText}>{"useLoanQuery()"}</code>
              </td>
              <td>
                {newRecordUuid ? (
                  <ReadSingleTest
                    loanUuid={newRecordUuid}
                    setStatusInParent={setStatusReadSingle}
                  />
                ) : (
                  "Waiting on CreateTest to finish ..."
                )}
              </td>
            </tr>
            <tr style={trStyle}>
              <td>
                <b>Update:</b>
              </td>
              <td>
                <code style={codeText}>{"useUpdateMutation()"}</code>
              </td>
              <td>
                {statusReadSingle === "Pass" ? (
                  <UpdateTest
                    loanUuid={newRecordUuid}
                    setStatusInParent={setStatusUpdate}
                  />
                ) : (
                  "Waiting on ReadSingleTest to finish ..."
                )}
              </td>
            </tr>
            <tr style={trStyle}>
              <td>
                <b>Read - Many:</b>
              </td>
              <td>
                <code style={codeText}>{"useLoansQuery()"}</code>
              </td>
              <td>
                {statusUpdate === "Pass" ? (
                  <ReadManyTest
                    institutionUuid={institutionUuid}
                    setStatusInParent={setStatusReadMany}
                  />
                ) : (
                  "Waiting on UpdateTest to finish ..."
                )}
              </td>
            </tr>
            <tr style={trStyle}>
              <td>
                <b>Delete:</b>
              </td>
              <td>
                <code style={codeText}>{"useDeleteLoanMutation()"}</code>
              </td>
              <td>
                {statusReadMany === "Pass" ? (
                  <DeleteTest
                    loanUuid={newRecordUuid}
                    setStatusInParent={setStatusDelete}
                  />
                ) : (
                  "Waiting on ReadManyTest to finish ..."
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
