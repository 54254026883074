import React from "react";
import { Menu } from "semantic-ui-react";
import PropTypes from "prop-types";
import NavTab from "./components/NavTab";

function NavTabs({ children = null }) {
  return (
    <Menu pointing secondary color="green">
      {children}
    </Menu>
  );
}

NavTabs.propTypes = {
  children: PropTypes.node
};

NavTabs.Tab = NavTab;

export default NavTabs;
