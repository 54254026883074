/*
Takes a dispatch parameter, and returns
document details to selected reducer
*/

import ApiEndpoints from "../ApiEndpoints";

function toggleLoading(dispatch) {
  dispatch({
    type: "DOCS_LOADING_TOGGLE"
  });
}

function handleError(dispatch, errorMessage) {
  dispatch({
    type: "DOCS_ERROR_MESSAGE",
    errorMessage
  });
}

function getDocDetails(dispatch, docUris, jwt) {
  const uris = docUris || [];
  const uriCount = uris.length;
  for (let i = 0; i < uriCount; i += 1) {
    const url = ApiEndpoints.baseUri + uris[i];
    const fetchParams = {
      method: "GET",
      headers: {
        Authorization: jwt
      }
    };
    fetch(url, fetchParams)
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: "DOCS_POPULATE",
          docs: data.data
        });
      })
      .catch(() => handleError(dispatch, "Can not get document details."));
    if (i === uriCount - 1) {
      toggleLoading(dispatch);
    }
  }
}

function updateMetaData(dispatch, metaData) {
  Object.keys(metaData).map(key =>
    dispatch({
      type: "DOCS_PAGINATION_PARAMS",
      param: key,
      paramValue: metaData[key]
    })
  );
}

function getDocUris(dispatch, urlParams, jwt) {
  const url = ApiEndpoints.baseUri + ApiEndpoints.docsGetUris + urlParams;
  const fetchParams = {
    method: "GET",
    headers: {
      Authorization: jwt
    }
  };
  fetch(url, fetchParams)
    .then(response => response.json())
    .then(data => {
      getDocDetails(dispatch, data.uris, jwt);
      updateMetaData(dispatch, data.metaData);
    })
    .catch(() => handleError(dispatch, "Can not get document list."));
}

export default function loadDocs(dispatch, filters, pagination, jwt) {
  let urlParams = "";
  const { number, size } = pagination;
  const rowParam = `?page_size=${size}`;
  const pageParam = `&page_number=${number}`;
  urlParams += rowParam + pageParam;

  if (filters.institution) {
    urlParams += `&institution=${filters.institution}`;
  }
  if (filters.category) {
    urlParams += `&category=${filters.category}`;
  }
  if (filters.subcategory) {
    urlParams += `&subcategory=${filters.subcategory}`;
  }
  if (filters.name) {
    urlParams += `&name=${filters.name}`;
  }
  if (filters.tin) {
    urlParams += `&tin=${filters.tin}`;
  }

  getDocUris(dispatch, urlParams, jwt);
}
