import { useState, useEffect } from "react";
import { SortingHeader } from "./sorting-header";
import { SortingBody } from "./sorting-body";
import {
  SortingTableNoChecklistProps,
  SortingTableProps
} from "../../../../types/collections";
import { SortingTableContext } from "./sorting-table-context";

export function SortingTable<T extends object>({
  list,
  idProperty,
  disabledProperty,
  headers,
  sortState,
  onSortStateChanged: setSortState,
  className = "",
  ...props
}: SortingTableProps<T> | SortingTableNoChecklistProps<T>) {
  const [tableData, setTableData] = useState<any[]>(list);

  useEffect(() => {
    setTableData(list);
  }, [list]);

  return (
    <SortingTableContext.Provider
      value={{
        headers: headers,
        idProperty: idProperty,
        disabledProperty: disabledProperty,
        tableData: tableData,
        setTableData: setTableData,
        onSortStateChanged: setSortState,
        sortState: sortState,
        ...props
      }}
    >
      <div className={`ui grid sorting-table ${className}`}>
        <div className="sixteen wide column table-scroll">
          <SortingHeader />
          <SortingBody />
        </div>
      </div>
    </SortingTableContext.Provider>
  );
}
