import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Segment } from "semantic-ui-react";
import ReviewTable from "../../../../../../components/reviewTable";

function CreditRiskReview({
  loanUuid,
  data,
  onCreateNewCreditReview,
  onViewCreditRiskReview
}) {
  useEffect(() => {
    onViewCreditRiskReview(loanUuid);
  }, [loanUuid, onViewCreditRiskReview]);

  return (
    <>
      <Segment>
        <Button onClick={onCreateNewCreditReview}>New</Button>
      </Segment>
      <ReviewTable
        linkToEdit="/credit_risk_review/{uuid}"
        linkToView="/credit_risk_review/{uuid}/view"
        reviewData={data}
      />
    </>
  );
}

CreditRiskReview.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loanUuid: PropTypes.string.isRequired,
  onCreateNewCreditReview: PropTypes.func,
  onViewCreditRiskReview: PropTypes.func
};

export default CreditRiskReview;
