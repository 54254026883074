import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import { Ticklers, ObjectMetadata } from "../../../../../../services/ApiLib";
import FetchUserNameV2 from "../../../../../../services/UserInfo";

import ScheduleForm from "../ScheduleForm";
import TicklerTable from "../TicklerTable";
import logger from "../../../../../../services/logger";

function onError(response) {
  logger.error(response);
}

class EditModal extends Component {
  constructor(props) {
    super(props);

    this.onModalOpen = this.onModalOpen.bind(this);
    this.onSuccessObject = this.onSuccessObject.bind(this);
    this.onSuccessTicklers = this.onSuccessTicklers.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.editModal !== prevProps.editModal && this.props.editModal) {
      this.onModalOpen();
    }
  }

  async onModalOpen() {
    try {
      const uuid = this.props.scheduleUuid;
      const result = await Ticklers.asyncRead({ scheduleUuid: uuid });
      this.onSuccessTicklers(result);
    } catch (error) {
      onError(error);
    }
  }

  async onSuccessObject(object) {
    this.props.dispatch({
      type: "DOC_TRACKING_EDIT_MODAL_UPDATE_UPLOAD_DATES",
      uuid: object.data.uuid,
      uploadDate: object.data.uploadDate
    });
    const fetchedUserName = await FetchUserNameV2(
      this.props.users,
      this.props.dispatch,
      object.data.uploadedBy
    );
    if (fetchedUserName.error) {
      onError(fetchedUserName.error);
    }
    this.props.dispatch({
      type: "DOC_TRACKING_EDIT_MODAL_UPDATE_UPLOAD_BY_UUID",
      uuid: object.data.uuid,
      uploadedBy: object.data.uploadedBy
    });
  }

  onSuccessTicklers(ticklers) {
    this.props.dispatch({
      type: "DOC_TRACKING_EDIT_MODAL_UPDATE_TICKLERS",
      ticklers: ticklers.data
    });
    const { onSuccessObject } = this;
    const { dispatch } = this.props;
    ticklers.data.forEach(async tickler => {
      if (tickler.approvedBy) {
        const fetchedUserName = await FetchUserNameV2(
          this.props.users,
          dispatch,
          tickler.approvedBy
        );
        if (fetchedUserName.error) {
          onError(fetchedUserName.error);
        }
      }
      if (tickler.objectUuid) {
        ObjectMetadata.asyncRead(tickler.objectUuid)
          .then(result => {
            onSuccessObject(result);
          })
          .catch(error => {
            onError(error);
          });
      }
    });
  }

  render() {
    const { onCloseEditModal, onEditSave, modalIsValid } = this.props;
    return (
      <Modal open={this.props.editModal} size="large">
        <Modal.Header>Edit Document Schedule</Modal.Header>
        <Modal.Content>
          <ScheduleForm docType={this.props.docType} />
          History:
          <TicklerTable
            ticklers={this.props.ticklers}
            uploadByUuids={this.props.uploadByUuids}
            uploadDates={this.props.uploadDates}
            users={this.props.users}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            data-testid="close-edit-schedule-button"
            onClick={onCloseEditModal}
          >
            Cancel
          </Button>
          <Button
            color="green"
            disabled={!modalIsValid}
            data-testid="edit-schedule-button"
            onClick={() => onEditSave("editModal")}
          >
            Save and Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

EditModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  docType: PropTypes.string.isRequired,
  editModal: PropTypes.bool.isRequired,
  onCloseEditModal: PropTypes.func.isRequired,
  onEditSave: PropTypes.func.isRequired,
  scheduleUuid: PropTypes.string.isRequired,
  ticklers: PropTypes.arrayOf(PropTypes.object).isRequired,
  uploadDates: PropTypes.objectOf(PropTypes.string).isRequired,
  uploadByUuids: PropTypes.objectOf(PropTypes.string).isRequired,
  users: PropTypes.objectOf(PropTypes.string).isRequired,
  modalIsValid: PropTypes.bool
};

const mapStateToProps = state => ({
  jwt: state.auth.jwt,
  ticklers: state.DocTrackingViewReducer.ticklers,
  uploadByUuids: state.DocTrackingViewReducer.uploadByUuids,
  uploadDates: state.DocTrackingViewReducer.uploadDates,
  users: state.UserInfoReducer.users,
  modalIsValid: state.DocTrackingViewReducer.modalIsValid
});

export default connect(mapStateToProps)(EditModal);
