/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { v4 as createUuid } from "uuid";
import { omit } from "lodash";

const SLICE_NAME = "spreads";

export const initialState = {
  spreads: undefined,
  periods: undefined,
  models: undefined,
  errors: undefined,
  analysts: {}
};

// Utils
const apiAction = (type, service, identifiers, data, meta) => {
  const action = {
    type: `${service}/${type}`,
    payload: {}
  };
  if (identifiers !== undefined) action.payload.identifiers = identifiers;
  if (data) action.payload.data = data;
  if (meta) action.meta = meta;
  return action;
};

const { actions, name, reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    cleanUpPeriods(state) {
      state.periods = null;
      state.newlyCreatedSpread = null;
    },
    cleanUpSpreads(state) {
      state.spreads = null;
    },
    cleanUpModels(state) {
      state.models = null;
    },
    cleanUpAnalysts(state) {
      state.analysts = {};
    },
    confirmLock(state, { payload }) {
      state.pendingLockConfirmation = payload;
    },
    clearLock(state) {
      state.pendingLockConfirmation = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(`${SLICE_NAME}/updated`, (state, { payload }) => {
        if (Array.isArray(payload.rows)) {
          state.spreads = payload.rows;
          return;
        }
        state.spreads = state.spreads.map(spread => {
          if (spread.uuid === payload.uuid) {
            return payload;
          }
          return spread;
        });
      })
      .addCase(`${SLICE_NAME}/created`, (state, { payload }) => {
        state.newlyCreatedSpread = payload;
      })
      .addCase(`${SLICE_NAME}/periods/updated`, (state, { payload }) => {
        if (Array.isArray(payload.rows)) {
          state.periods = payload.rows;
          return;
        }
        state.periods = state.periods.map(period => {
          if (period.uuid === payload.uuid) {
            return payload;
          }
          return period;
        });
      })
      .addCase(`${SLICE_NAME}/models/updated`, (state, { payload }) => {
        state.models = payload;
      })
      .addCase(
        `${SLICE_NAME}/analysts/updated`,
        (state, { payload, originAction }) => {
          state.analysts[originAction.payload.identifiers.uuid] = payload;
        }
      );
  }
});

// SELECTORS
const sliceSelector = state => state[name];
export const spreadsSliceSelector = sliceSelector;
export const spreadsSelector = createSelector(
  sliceSelector,
  state => state.spreads
);

export const periodsSelector = createSelector(
  sliceSelector,
  state => state.periods
);

export const analystsSelector = createSelector(
  sliceSelector,
  state => state.analysts
);

export const modelsSelector = createSelector(
  sliceSelector,
  state => state.models
);

export const errorsSelector = createSelector(
  sliceSelector,
  state => state.errors
);

export const pendingLockConfirmationSelector = createSelector(
  sliceSelector,
  state => state.pendingLockConfirmation
);

export const newlyCreatedSpreadSelector = createSelector(
  sliceSelector,
  state => state.newlyCreatedSpread
);

const selectors = {
  spreadsSelector,
  periodsSelector,
  modelsSelector,
  errorsSelector,
  pendingLockConfirmationSelector
};

// OTHER ACTIONS
const asyncActions = {
  // Spreads
  retrieveSpreads:
    ({ entityUuid, institutionUuid }) =>
    async dispatch => {
      await dispatch(
        apiAction("retrieve", "spreads", {
          filter: `entityUuid=${entityUuid}`,
          institutionUuid,
          include: ["FinancialSpreadsModels", "PeriodStatements", "Users"],
          pageNumber: 1,
          pageSize: 999
        })
      );
    },
  updateSpread:
    ({ uuid, ...body }) =>
    async dispatch => {
      await dispatch(
        apiAction(
          "patch",
          "spreads",
          {
            uuid,
            include: ["FinancialSpreadsModels", "PeriodStatements", "Users"]
          },
          omit(body, [
            "analyst",
            "user",
            "periods",
            "FinancialSpreadsModelsModel"
          ])
        )
      );
    },
  createSpread:
    ({
      analystUuid,
      entityUuid,
      institutionUuid,
      financialModelUuid,
      periodUuids,
      templateUuid
    }) =>
    async dispatch => {
      await dispatch(
        apiAction(
          "create",
          "spreads",
          {},
          {
            analystUuid,
            entityUuid,
            archived: false,
            createdDate: () => new Date(Date.now()).toISOString(),
            editable: true,
            institutionUuid,
            periodUuids,
            status: "In Progress",
            financialModelUuid,
            templateUuid,
            uuid: createUuid()
          }
        )
      );
    },
  // Periods
  retrievePeriods:
    ({ entityUuid, institutionUuid }) =>
    async dispatch => {
      await dispatch(
        apiAction("retrieve", "spreads/periods", {
          filter: `entityUuid=${entityUuid}`,
          institutionUuid,
          include: ["FinancialSpreadsModels", "Users"],
          pageNumber: 1,
          pageSize: 999
        })
      );
    },
  updatePeriod:
    ({ entityUuid, uuid, ...body }) =>
    async dispatch => {
      await dispatch(
        apiAction(
          "patch",
          "spreads/periods",
          {
            filter: `entityUuid=${entityUuid}`,
            periodUuid: uuid,
            include: ["FinancialSpreadsModels", "Users"]
          },
          omit(body, ["user", "FinancialSpreadsModelsModel"])
        )
      );
    },
  // Models
  retrieveModels:
    ({ entityUuid }) =>
    async dispatch => {
      await dispatch(apiAction("retrieve", "spreads/models", { entityUuid }));
    },
  // Analysts
  retrieveAnalyst:
    ({ uuid }) =>
    async dispatch => {
      await dispatch(apiAction("retrieve", "spreads/analysts", { uuid }));
    }
};

Object.assign(actions, asyncActions);

// EXPORTS
export { actions, name, reducer, selectors };

export default {
  name,
  actions,
  reducer,
  selectors,
  initialState
};

export const useActions = dispatch =>
  useMemo(
    () =>
      Object.entries(actions)
        .map(([key, act]) => [key, (...args) => dispatch(act(...args))])
        .reduce((actionMap, [key, act]) => ({ ...actionMap, [key]: act }), {}),
    [dispatch]
  );
