/* eslint-disable camelcase */
import createAPIService from "../../../FetchService/createAPIService";
import APIEndpoints from "../../../ApiEndpoints";
import mockFees from "./mock-fees";

const govGuaranteeFeesService = createAPIService({
  baseUrl: APIEndpoints.baseUri,
  retrieve: [APIEndpoints.govGuaranteeFees, APIEndpoints.govGuaranteeFee],
  options: { pagination: true }
});

/** TODO:
 * The FEES Service does not exist as far as I know, so it is mocked below until there is
 * a working endpoint to hit
 */
mockFees(govGuaranteeFeesService);

export default govGuaranteeFeesService;
