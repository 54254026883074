import "font-awesome/css/font-awesome.min.css";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import PasswordChange from "./components/PasswordChange";
import Notifications from "./components/Notifications";

function SettingsPageObj(props) {
  const { formatMessage } = useIntl();
  return (
    <div>
      <h1 className="mb-6">{formatMessage({ id: "MY_ACCOUNT" })}</h1>
      <Notifications
        jwt={props.jwt}
        userUuid={props.userUuid}
        notificationSettings={props.notificationSettings}
        saveMessage={props.saveMessage}
      />
      <hr style={{ margin: "2rem 0" }} />
      <PasswordChange jwt={props.jwt} userUuid={props.userUuid} />
    </div>
  );
}

SettingsPageObj.propTypes = {
  jwt: PropTypes.string.isRequired,
  notificationSettings: PropTypes.shape({
    web: PropTypes.bool,
    email: PropTypes.bool
  }).isRequired,
  saveMessage: PropTypes.string.isRequired,
  userUuid: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  jwt: state.auth.jwt,
  notificationSettings: state.settings.notificationSettings,
  saveMessage: state.settings.saveMessage,
  userUuid: state.auth.userUuid
});

const Settings = connect(mapStateToProps)(SettingsPageObj);

export default Settings;
