export const initialState = {
  settlementSheets: {}
};

// eslint-disable-next-line default-param-last
const LorSettlementSheetReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOR_SETTELMENT_SHEET_GET": {
      return {
        ...state,
        settlementSheets: {
          ...state.settlementSheets,
          ...action.settlementSheets
        }
      };
    }
    case "LOR_SETTELMENT_SHEET_ADD": {
      const update = [
        ...state.settlementSheets[action.uuid],
        action.settlementSheet
      ];
      return {
        ...state,
        settlementSheets: { ...state.settlementSheets, [action.uuid]: update }
      };
    }

    case "LOR_SETTELMENT_SHEET_UPDATE": {
      const updatedSettlementSheet = state.settlementSheets[action.uuid].map(
        settlement => {
          if (settlement.uuid === action.settlementUuid) {
            return action.settlementSheet;
          }
          return settlement;
        }
      );
      return {
        ...state,
        settlementSheets: {
          ...state.settlementSheets,
          [action.uuid]: updatedSettlementSheet
        }
      };
    }
    case "LOR_SETTELMENT_SHEET_REMOVE": {
      const removeSettlementSheet = state.settlementSheets[action.uuid].filter(
        settlement => settlement.uuid !== action.settlementUuid
      );
      return {
        ...state,
        settlementSheets: {
          ...state.settlementSheets,
          [action.uuid]: removeSettlementSheet
        }
      };
    }
    default:
      return state;
  }
};

export default LorSettlementSheetReducer;
