/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */
import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  actions as reduxActions,
  loanAppSelector,
  borrowersSelector,
  selectedBorrowerUuidSelector,
  ownersSelector,
  guarantorsSelector,
  selectedBorrowerHoldingsSubsidiariesSelector,
  selectedBorrowerOwnersSelector,
  selectedBorrowerOfficersSelector,
  selectedBorrowerOtherCapacitiesSelector,
  selectedBorrowerAffiliatesSelector,
  documentsSelector,
  loanRequestsSelector,
  collateralsSelector,
  affiliatesSelector,
  assetsForEntitySelector,
  errorsSelector,
  analystsSelector,
  docsReadySelector,
  holdingsSubsidiariesSelector,
  collateralGrantorsSelector
} from "./loan-applications";

export const useLoanApplicationCollateralDetails = ({ loanAppUuid }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      reduxActions.retrieveLoanApplicationEntityDetails({ loanAppUuid })
    );
  }, [loanAppUuid, dispatch]);

  const borrowers = useSelector(borrowersSelector);
  const owners = useSelector(ownersSelector);
  const guarantors = useSelector(guarantorsSelector);

  useEffect(() => {
    const nextCollateralEntities = {};
    const currentEntities = (borrowers || [])
      .concat(owners || [])
      .concat(guarantors || []);
    currentEntities.map(
      ({ uuid, owner_entity_data, child_uuid, entity_uuid }) => {
        let entUuid = uuid;
        if (!uuid && owner_entity_data && owner_entity_data.uuid) {
          entUuid = owner_entity_data.uuid;
        }
        if (entUuid) {
          nextCollateralEntities[entUuid] = true;
        }
        if (child_uuid) {
          nextCollateralEntities[child_uuid] = true;
        }
        if (entity_uuid) {
          nextCollateralEntities[entity_uuid] = true;
        }
        return null;
      }
    );
    dispatch(reduxActions.updateCollateralEntities(nextCollateralEntities));
  }, [borrowers, dispatch, guarantors, owners]);
  return null;
};

export const useLoanApplicationEntityDetails = ({ loanAppUuid }) => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      Object.entries(reduxActions)
        .map(([key, act]) => [key, (...args) => dispatch(act(...args))])
        .reduce((actionMap, [key, act]) => ({ ...actionMap, [key]: act }), {}),
    [dispatch]
  );

  // Kick off data fetching when component is rendered
  useEffect(() => {
    dispatch(
      reduxActions.retrieveLoanApplicationEntityDetails({ loanAppUuid })
    );
    dispatch(reduxActions.retrieveLoanRequests({ loanAppUuid }));
  }, [loanAppUuid, dispatch]);

  const borrowers = useSelector(borrowersSelector);
  const holdingsSubsidiaries = useSelector(
    selectedBorrowerHoldingsSubsidiariesSelector
  );
  const selectedBorrowerUuid = useSelector(selectedBorrowerUuidSelector);
  const owners = useSelector(selectedBorrowerOwnersSelector);
  const officers = useSelector(selectedBorrowerOfficersSelector);
  const otherCapacities = useSelector(selectedBorrowerOtherCapacitiesSelector);
  const affiliates = useSelector(selectedBorrowerAffiliatesSelector);
  const errors = useSelector(errorsSelector);

  // if there is no selectedBorrower, set the first borrow as selected
  useEffect(() => {
    if (!selectedBorrowerUuid && borrowers && borrowers[0]) {
      dispatch(reduxActions.updateSelectedBorrowerUuid(borrowers[0].uuid));
    }
  }, [borrowers, selectedBorrowerUuid, dispatch]);

  return useMemo(
    () => [
      {
        borrowers,
        selectedBorrowerUuid,
        owners,
        officers,
        otherCapacities,
        affiliates,
        holdingsSubsidiaries,
        errors
      },
      actions
    ],
    [
      borrowers,
      selectedBorrowerUuid,
      owners,
      officers,
      otherCapacities,
      affiliates,
      holdingsSubsidiaries,
      errors,
      actions
    ]
  );
};

export const useLoanApplicationGuarantors = ({ loanAppUuid }) => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      Object.entries(reduxActions)
        .map(([key, act]) => [key, (...args) => dispatch(act(...args))])
        .reduce((actionMap, [key, act]) => ({ ...actionMap, [key]: act }), {}),
    [dispatch]
  );

  // Kick off data fetching when component is rendered
  useEffect(() => {
    dispatch(reduxActions.retrieveLoanRequests({ loanAppUuid }));
  }, [loanAppUuid, dispatch]);

  const borrowers = useSelector(borrowersSelector);
  const owners = useSelector(ownersSelector);
  const loanRequests = useSelector(loanRequestsSelector);
  const guarantors = useSelector(guarantorsSelector);
  const errors = useSelector(errorsSelector);

  const ownersWithBorrowers = useMemo(
    () =>
      owners &&
      owners
        .map(owner => ({
          ...owner,
          borrower: borrowers
            ? borrowers.find(
                borrower => owner.borrowerUuid === borrower.child_uuid
              )
            : {}
        }))
        .sort((a, b) =>
          a.borrower && b.borrower && a.borrower.uuid > b.borrower.uuid ? -1 : 1
        ),
    [owners, borrowers]
  );

  const [ownerGuarantors, thirdPartyGuarantors] = useMemo(() => {
    if (owners && guarantors) {
      const ownerEntityUuids = owners.map(
        owner => owner.owner_entity_data && owner.owner_entity_data.uuid
      );
      return [
        guarantors.filter(g => ownerEntityUuids.includes(g.entity_uuid)),
        guarantors.filter(g => !ownerEntityUuids.includes(g.entity_uuid))
      ];
    }
    return [];
  }, [owners, guarantors]);

  const primaryBorrower = borrowers && borrowers.find(b => b.primary_borrower);
  const institutionUuid = primaryBorrower && primaryBorrower.institution_uuid;

  return useMemo(
    () => [
      {
        owners: ownersWithBorrowers,
        ownerGuarantors,
        thirdPartyGuarantors,
        loanRequests,
        institutionUuid,
        errors
      },
      actions
    ],
    [
      ownersWithBorrowers,
      ownerGuarantors,
      thirdPartyGuarantors,
      loanRequests,
      institutionUuid,
      errors,
      actions
    ]
  );
};

export const useLoanApplicationDocuments = ({ loanAppUuid }) => {
  const dispatch = useDispatch();
  const [{ ownerGuarantors: guarantors, thirdPartyGuarantors }] =
    useLoanApplicationGuarantors({ loanAppUuid });
  const actions = useMemo(
    () =>
      Object.entries(reduxActions)
        .map(([key, act]) => [key, (...args) => dispatch(act(...args))])
        .reduce((actionMap, [key, act]) => ({ ...actionMap, [key]: act }), {}),
    [dispatch]
  );

  // Kick off data fetching when component is rendered
  useEffect(() => {
    dispatch(reduxActions.retrieveLoanApplicationDocuments({ loanAppUuid }));
  }, [loanAppUuid, dispatch]);

  const loanApp = useSelector(loanAppSelector);
  const documents = useSelector(documentsSelector);
  const borrowers = useSelector(borrowersSelector);
  const owners = useSelector(ownersSelector);
  const collateralsByLoanRequest = useSelector(collateralsSelector);
  const affiliates = useSelector(affiliatesSelector);
  const ownerGuarantors = guarantors && guarantors.filter(g => g.is_guarantor);
  const collaterals =
    collateralsByLoanRequest && Object.values(collateralsByLoanRequest);
  const analysts = useSelector(analystsSelector);
  const loanRequests = useSelector(loanRequestsSelector);
  const docsReady = useSelector(docsReadySelector);
  const holdingsSubsidiaries = useSelector(holdingsSubsidiariesSelector);
  const collateralGrantors = useSelector(collateralGrantorsSelector);

  return useMemo(
    () => [
      {
        documents,
        loanApp,
        borrowers,
        owners,
        affiliates,
        guarantors,
        ownerGuarantors,
        thirdPartyGuarantors,
        collaterals,
        analysts,
        loanRequests,
        docsReady,
        holdingsSubsidiaries,
        collateralGrantors
      },
      actions
    ],
    [
      documents,
      loanApp,
      borrowers,
      owners,
      collaterals,
      affiliates,
      guarantors,
      ownerGuarantors,
      thirdPartyGuarantors,
      actions,
      analysts,
      loanRequests,
      docsReady,
      holdingsSubsidiaries,
      collateralGrantors
    ]
  );
};

export const useAssetsForEntity = ({ entityUuid }) => {
  const dispatch = useDispatch();
  // Kick off data fetching when component is rendered
  useEffect(() => {
    dispatch(reduxActions.retrieveAssetsForEntity({ entityUuid }));
  }, [entityUuid, dispatch]);
  return useSelector(assetsForEntitySelector);
};
