import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Menu, Segment } from "semantic-ui-react";

import {
  PremiumDiscount,
  PremiumDiscountHistory
} from "../../../../../../services/ApiLib";
import EditModal from "./components/PremiumDiscountAddModal";
import LoanPremiumDiscountDetails from "./scenes/Details";
import LoanPremiumDiscountHistory from "./scenes/History";
import logger from "../../../../../../services/logger";

const onError = e => {
  logger.error(e);
};

function PremiumDiscountComponent({ loanUuid, institutionUuid }) {
  const [activeItem, setActiveItem] = useState("details");
  const [loanPremiumDiscount, setLoanPremiumDiscount] = useState(null);
  const [loanPremiumDiscountHistory, setLoanPremiumDiscountHistory] =
    useState(null);

  function setPremiumDiscountDetails({ data }) {
    setLoanPremiumDiscount(data && data[0]);
  }

  function setPremiumDiscountHistory({ data }) {
    setLoanPremiumDiscountHistory(
      data.sort((a, b) =>
        new Date(a.transaction_date) < new Date(b.transaction_date) ? 1 : -1
      )
    );
  }

  function createPremiumDiscount(values) {
    // preprocess data
    const preparedValues = {
      ...values,
      premium_discount_starting_amount:
        +values.premium_discount_starting_amount,
      premium_discount_balance: +values.premium_discount_starting_amount,
      loan_uuid: loanUuid,
      institution_uuid: institutionUuid
    };

    PremiumDiscount.add(
      ({ data }) => setLoanPremiumDiscount(data),
      onError,
      preparedValues
    );
  }

  useEffect(() => {
    PremiumDiscount.get(setPremiumDiscountDetails, onError, null, null, {
      loan_uuid: loanUuid
    });
    PremiumDiscountHistory.get(setPremiumDiscountHistory, onError, null, null, {
      loan_uuid: loanUuid,
      page_size: 9999
    });
  }, [loanUuid]);

  const createMenuTabs = () => (
    <Menu pointing secondary color="green">
      <Menu.Item
        name="Details"
        active={activeItem === "details"}
        onClick={() => {
          setActiveItem("details");
        }}
      />
      <Menu.Item
        name="History"
        active={activeItem === "history"}
        onClick={() => {
          setActiveItem("history");
        }}
      />
    </Menu>
  );

  return (
    <Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            {createMenuTabs()}
            {activeItem === "details" && (
              <LoanPremiumDiscountDetails
                loanPremiumDiscount={loanPremiumDiscount}
                loanPremiumDiscountHistory={loanPremiumDiscountHistory}
              />
            )}
            {activeItem === "history" && (
              <LoanPremiumDiscountHistory
                loanPremiumDiscountHistory={loanPremiumDiscountHistory}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {typeof loanPremiumDiscount === "undefined" && (
        <EditModal onSave={createPremiumDiscount} />
      )}
    </Segment>
  );
}

PremiumDiscountComponent.propTypes = {
  loanUuid: PropTypes.string.isRequired,
  institutionUuid: PropTypes.string.isRequired
};

export default PremiumDiscountComponent;
