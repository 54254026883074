import React, { useEffect, useState } from "react";
import { SideSheet, useToast } from "@bafsllc/ui-shared";
import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import BorrowerPortalLinksSideSheetHeader from "./side-sheet-header";
import BorrowerPortalSideSheetDropDownSearch from "./drop-down-search";
import { useBPLinksContext } from "../bp-links-context";
import { getEntityInformation } from "../../../../../../../actions/entities";
import { Entities } from "../../../../../../../../../services/ApiLib";

// eslint-disable-next-line import/prefer-default-export
export function BorrowerPortalLinksSideSheet({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const entityData = useSelector(
    ({ CreditManagementReducer }) => CreditManagementReducer.entityData
  );
  const {
    bpOrgUuIdToLink,
    signUpData,
    emailOptions,
    linkButtonDisabled,
    fetchBpUsers,
    linkEntitiesToOrg,
    updateBpOrgUuidToLink,
    updateIsLoading
  } = useBPLinksContext(entityData);
  const { addToast } = useToast();
  const [isConfirmationModalClose, setConfirmationModal] = useState(true);

  const onDismiss = async dismissVariant => {
    const toastBoilerplate = {
      id: "BASIC_TOAST",
      variant: "success"
    };

    const affirmativeDismiss = dismissVariant === "affirmative";

    if (affirmativeDismiss) {
      const entityUuid = entityData.uuid;
      if (signUpData) {
        // New BP Organization
        await Entities.sendBpUserSignupEmail(entityUuid, signUpData);
      } else if (bpOrgUuIdToLink) {
        // Link Existing BP Organization
        await linkEntitiesToOrg([entityData?.uuid], bpOrgUuIdToLink);
      }
      addToast({
        title: { id: "LINK_COMPLETED_SUCCESSFULLY" },
        message: {
          id: "LINK_COMPLETED_SUCCESSFULLY"
        },
        ...toastBoilerplate
      });
      dispatch(getEntityInformation(entityData?.uuid));
    } else if (dismissVariant === "reinvite") {
      addToast({
        title: { id: "INVITATION_SENT_SUCCESSFULLY" },
        message: {
          id: "INVITATION_SENT_SUCCESSFULLY"
        },
        ...toastBoilerplate
      });
    }

    updateBpOrgUuidToLink("");
    setConfirmationModal(true);
    updateIsLoading(false);

    if (affirmativeDismiss) onClose();
  };

  const handleSearchChange = searchQuery => {
    fetchBpUsers(searchQuery);
  };

  const handleOnChange = email => {
    const { bpOrgUuId } = emailOptions.find(eO => eO.value === email);

    updateBpOrgUuidToLink(bpOrgUuId);
  };

  useEffect(() => {
    fetchBpUsers();
  }, [fetchBpUsers]);

  return (
    <SideSheet isOpen={isOpen} onClose={onClose} size="two-third">
      <BorrowerPortalLinksSideSheetHeader
        entityData={entityData}
        isConfirmationModalClose={isConfirmationModalClose}
        setConfirmationModal={setConfirmationModal}
        linkButtonDisabled={linkButtonDisabled}
        onClose={onClose}
        onDismiss={onDismiss}
      />
      <BorrowerPortalSideSheetDropDownSearch
        onSearchChange={handleSearchChange}
        onChange={handleOnChange}
      />
    </SideSheet>
  );
}

BorrowerPortalLinksSideSheet.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
