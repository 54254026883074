export const initialState = {
  entities: {},
  institutionIds: {},
  header: {},
  requests: []
};

export default function CurrentRequestMainReducer(state, action) {
  const safeState = state ?? initialState;
  switch (action.type) {
    case "CURRENT_REQUEST_MAIN_SAVE_DATA":
      return {
        ...safeState,
        requests: action.requests
      };

    case "CURRENT_REQUEST_MAIN_SAVE_HEADER_DATA": {
      return {
        ...safeState,
        header: action.header
      };
    }

    case "CURRENT_REQUEST_SAVE_ENTITY_NAME":
      return {
        ...safeState,
        entities: { ...safeState.entities, [action.key]: action.value }
      };

    case "CURRENT_REQUEST_SAVE_INSTITUTION_ID":
      return {
        ...safeState,
        institutionIds: {
          ...safeState.institutionIds,
          [action.key]: action.value
        }
      };

    default:
      return safeState;
  }
}
