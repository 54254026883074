import { Icon } from "@bafsllc/ui-shared";
import React from "react";
import { FormattedMessage } from "react-intl";

export default function AutoSpreadsStatusHeader() {
  return (
    <>
      <Icon icon="cogs" />
      <FormattedMessage id="AUTO_SPREADS_STATUS" />
    </>
  );
}
