import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  DollarCell,
  PropertyTable
} from "../../../../../../../../../../components/Tables";

function ChargeFee({ loan }) {
  const columns = useMemo(
    () => [
      {
        Header: "Fees Accrued",
        accessor: "fees_accrued",
        Cell: DollarCell,
        permission: "all"
      },
      {
        Header: "Late Fees YTD",
        accessor: "fees_accrued_ytd",
        Cell: DollarCell,
        permission: "all"
      },
      {
        Header: "Late Fees LTD",
        accessor: "fees_accrued_ltd",
        Cell: DollarCell,
        permission: "all"
      },
      {
        Header: "Maximum Fee Amount",
        accessor: "fee_max_threshold",
        Cell: DollarCell,
        permission: "all"
      },
      {
        Header: "Minimum Fee Amount",
        accessor: "fee_min_threshold",
        Cell: DollarCell,
        permission: "all"
      }
    ],
    []
  );
  return <PropertyTable columnsConfig={columns} data={loan} />;
}

ChargeFee.propTypes = {
  loan: PropTypes.shape({}).isRequired
};

export default ChargeFee;
