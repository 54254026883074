import { useState, useEffect, useCallback } from "react";
import { dateStringToISO } from "../../DateTime";
import { Assets, Entities } from "../../ApiLib";
import logger from "../../logger";

function onError(rsp) {
  logger.error("Error: ", rsp); // TODO: Handle errors properly
}

const assetsBpAccessControls = async uuid => {
  const response = await Entities.bpAccessControlsGetByRecordUuid(uuid);

  return { bpAccessControls: response?.data ? response?.data : null };
};

function useAssets({ parentKey, parentValue }, { size = 10, number = 0 }) {
  const [lastPage, setLastPage] = useState(false);
  const [assets, setAssets] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const readAssets = useCallback(async () => {
    if (!parentValue || !parentKey) {
      return;
    }

    try {
      setIsFetching(true);
      const response = await Assets.asyncRead(
        { [parentKey]: parentValue },
        { size, number }
      );

      if (!response.data) {
        setLastPage(response.metaData ? response.metaData.last_page : false);
        setAssets(response.data ? response.data : []);
        return;
      }

      const promises = response?.data.map(async ({ uuid }) => [
        // TODO utilize new API to get bpAccessControls by multiple recordUuids
        await assetsBpAccessControls(uuid)
      ]);

      try {
        const promiseResponses = await Promise.all(promises);

        const assetsList = response?.data.reduce(
          (allAssets, currentAsset, i) => {
            const asset = {
              ...currentAsset
            };

            asset.bpAccessControls = promiseResponses[i][0].bpAccessControls;

            return [...allAssets, asset];
          },
          []
        );

        setLastPage(response.metaData ? response.metaData.last_page : false);
        setAssets(assetsList);
      } catch (err) {
        onError(err);
      } finally {
        setIsFetching(false);
      }
    } catch (err) {
      onError(err);
      setIsFetching(false);
    }
  }, [number, parentKey, parentValue, size]);

  async function addAsset(assetInfo) {
    const body = {
      ...Object.keys(assetInfo).reduce((output, key) => {
        if (!assetInfo[key] && assetInfo[key] !== 0) {
          return output;
        }

        return {
          ...output,
          [key]: assetInfo[key]
        };
      }, {})
    };

    if (body.acquisition_date) {
      body.acquisition_date = dateStringToISO(body.acquisition_date);
    }

    try {
      if (!body.uuid) {
        // no uuid implies a new asset
        await Assets.asyncAdd(body);
      } else {
        await Assets.asyncUpdate(body);
      }

      readAssets();
    } catch (err) {
      onError(err);
    }
  }

  useEffect(() => {
    // gets data on pagination or entity change
    readAssets();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [readAssets]);

  return {
    lastPage,
    assets,
    isFetching,
    addAsset,
    readAssets
  };
}

export default useAssets;
