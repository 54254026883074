export const initialState = {
  currentBalance: 0,
  displayReceipt: false,
  error: "",
  form: {
    description: "",
    tran_amount: 0,
    effective_date: "",
    tran_code: ""
  },
  receipt: {
    printAmt: "",
    intAmt: "",
    lateFeeAmt: "",
    curBal: "",
    tranAmt: ""
  },
  // This is for the v2 submitVersion, this should be updated with UI changes
  newReceipt: {},
  displaySuccess: false,
  deferModalIsOpen: false
};

// eslint-disable-next-line default-param-last
export default function PaymentsReducer(state = initialState, action) {
  switch (action.type) {
    case "PAYMENT_REDUCER_UPDATE_DATE":
      return {
        ...state,
        form: { ...state.form, effective_date: action.paymentDate }
      };

    case "PAYMENT_REDUCER_UPDATE_AMOUNT":
      return {
        ...state,
        form: { ...state.form, tran_amount: action.tran_amount }
      };

    case "PAYMENT_REDUCER_UPDATE_FIELD":
      return {
        ...state,
        form: { ...state.form, [action.field]: action.value }
      };

    case "PAYMENT_REDUCER_RESET_FORM":
      return { ...state, form: { ...initialState.form } };

    case "PAYMENT_REDUCER_UPDATE_CURRENT_BALANCE":
      return { ...state, currentBalance: action.currentBalance };

    case "PAYMENT_REDUCER_UPDATE_ERROR":
      return { ...state, error: action.error };

    case "PAYMENT_REDUCER_UPDATE_PAYMENT_RECEIPT":
      return { ...state, receipt: action.receipt };

    case "PAYMENT_REDUCER_UPDATE_DISPLAY_SUCCESS":
      return { ...state, displaySuccess: !action.displaySuccess };

    case "PAYMENT_REDUCER_SET_DEFER_MODAL_IS_OPEN":
      return { ...state, deferModalIsOpen: action.deferModalIsOpen };

    case "PAYMENT_REDUCER_RESET_STATE":
      return initialState;

    default:
      return state;
  }
}
