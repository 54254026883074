/**
 * @param {*} usersArray [{ email: '', uuid: '', ...}, ...]
 * @returns User emails formatted for Semantic UI Dropdown Component
 */
const createEmailOptions = (usersArray = []) => {
  if (!Array.isArray(usersArray)) {
    return [];
  }

  if (!usersArray.length) {
    return [];
  }

  return usersArray.map(({ email, uuid, bp_organization_uuid: bpOrgUuId }) => ({
    key: uuid,
    bpOrgUuId,
    text: email,
    value: email
  }));
};

export default createEmailOptions;
