/* eslint-env browser */
export const initialState = {
  businessInfo: {},
  confirmationVisibility: {
    submit: false,
    withdraw: false
  },
  currentPageIndex: 0,
  decisionApproved: false,
  errorMsg: "",
  formIsValid: false,
  loading: false,
  loanAppInfo: {
    phase: "New Credit Application"
  },
  loanRequestInfo: {
    loan_type: "Unsecured Loan",
    payment_type: "Line of Credit"
  },
  personalInfo: {
    is_us_citizen: false
  },
  relationshipsCreated: {
    owner: false,
    borrower: false
  },
  userMessage: ""
};

export default function Reducer(state, action) {
  const safeState = state ?? initialState;
  switch (action.type) {
    case "AUTO_DESC_MESSAGE":
      return {
        ...safeState,
        errorMsg: "",
        userMessage: "",
        [action.messageType]: action.message
      };

    case "AUTO_DESC_RESET_STATE":
      return initialState;

    case "AUTO_DESC_TOGGLE_CONFIRMATIONS":
      return {
        ...safeState,
        confirmationVisibility: {
          ...safeState.confirmationVisibility,
          [action.whichConfirmation]: action.isVisible
        }
      };

    case "AUTO_DESC_UPDATE_FORM": // TODO: Rename 'AUTO_DESC_UPDATE_FORM_VALUE'
      return {
        ...safeState,
        [action.whichForm]: {
          ...safeState[action.whichForm],
          [action.name]: action.value
        },
        errorMsg: "",
        userMessage: ""
      };

    case "AUTO_DESC_REQUEST_STATUS":
      return {
        ...safeState,
        [action.messageType]: action.message,
        decisionApproved: action.decisionApproved,
        loanAppInfo: {
          ...safeState.loanAppInfo,
          phase: action.phase
        }
      };

    case "AUTO_DESC_REPLACE_FORM":
      return {
        ...safeState,
        [action.whichForm]: action.newFormValues
      };

    case "AUTO_DESC_RESUME_APP":
      return {
        ...safeState,
        businessInfo: action.businessInfo,
        loading: false,
        loanAppInfo: action.loanAppInfo,
        loanRequestInfo: action.loanRequestInfo,
        personalInfo: action.personalInfo,
        relationshipsCreated: { owner: true, borrower: true }
      };

    case "AUTO_DESC_MARK_REL_SUCCESS":
      return {
        ...safeState,
        relationshipsCreated: {
          ...safeState.relationshipsCreated,
          [action.relType]: true
        }
      };

    case "AUTO_DESC_SET_CURRENT_PAGE":
      return {
        ...safeState,
        currentPageIndex: action.currentPageIndex
      };

    case "AUTO_DESC_TOGGLE_LOADING":
      return {
        ...safeState,
        loading: !safeState.loading
      };

    case "AUTO_DESC_TOGGLE_FORM_VALIDATION":
      return {
        ...safeState,
        formIsValid: action.formIsValid
      };

    case "AUTO_DESC_WITHDRAW_APP":
      return {
        ...safeState,
        loanAppInfo: {
          ...safeState.loanAppInfo,
          phase: "Withdrawn"
        }
      };

    default:
      return safeState;
  }
}
