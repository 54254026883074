/* eslint-disable camelcase */
import React from "react";
import { Form } from "semantic-ui-react";
import {
  suffixOptions,
  educationOptions,
  jobTitleOptions,
  titleOptions,
  individualOperatingStatus,
  maritalStatusOptions,
  genderOptions,
  employmentOptions,
  countryOptions
} from "../../../../../../../services/FormElements/components/entityModalOptions";
import { semanticUIStates } from "../../../../../../../services/FormElements";
import { DateInput } from "../../../../../../CustomFormElements";
import IdentificationNumber from "../../../../../../CustomFormElements/components/IdentificationNumberInput/identification-number";
import useEntity from "../../../hooks/useEntity/useEntity";

function IndividualEntityInfo() {
  const {
    citizenship_country,
    citizenship_state,
    dob,
    education,
    employer,
    employment_date,
    employment_status,
    first_name,
    gender,
    marital_status,
    handleInputChange,
    indiv_suffix,
    indiv_title,
    industry_type,
    job_title,
    last_name,
    loan_officer_uuid,
    member_since,
    middle_name,
    naics_code,
    occupation,
    operating_status,
    tin,
    loanOfficers
  } = useEntity();
  return (
    <Form>
      <Form.Group size="large">
        <Form.Select
          name="indiv_title"
          label="Title"
          width={2}
          compact
          options={titleOptions}
          value={indiv_title || ""}
          onChange={handleInputChange}
        />
        <Form.Input
          name="first_name"
          label="First Name"
          width={5}
          value={first_name || ""}
          onChange={handleInputChange}
        />
        <Form.Input
          name="middle_name"
          label="Middle Name"
          width={5}
          value={middle_name || ""}
          onChange={handleInputChange}
        />
        <Form.Input
          name="last_name"
          label="Last Name"
          width={5}
          value={last_name || ""}
          onChange={handleInputChange}
        />
        <Form.Select
          name="indiv_suffix"
          clearable
          label="Suffix"
          width={2}
          compact
          options={suffixOptions}
          value={indiv_suffix || ""}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Select
          name="gender"
          label="Gender"
          width={3}
          options={genderOptions}
          value={gender || ""}
          onChange={handleInputChange}
        />
        <IdentificationNumber
          id="tin"
          label="SSN"
          name="tin"
          numberType="ssn"
          // onBlur={(e) => (tinCheck(entityData.uuid, e.target.value))}
          onChange={handleInputChange}
          value={tin || ""}
          width={5}
        />
        <DateInput
          asForm
          name="dob"
          label="DOB"
          width={5}
          value={dob || ""}
          onChange={handleInputChange}
        />
        <Form.Select
          name="marital_status"
          clearable
          label="Marital Status"
          width={5}
          compact
          options={maritalStatusOptions}
          value={marital_status || ""}
          onChange={handleInputChange}
        />
        <Form.Select
          name="education"
          clearable
          label="Education"
          width={5}
          value={education || ""}
          options={educationOptions}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Input
          name="naics_code"
          label="NAICS Code"
          width={6}
          value={naics_code || ""}
          readOnly
        />
        <Form.Input
          name="industry_type"
          label="Industry Type"
          width={6}
          readOnly
          value={industry_type || ""}
        />
        <Form.Input
          name="occupation"
          label="Occupation"
          width={6}
          value={occupation || ""}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Select
          name="job_title"
          label="Job Title"
          width={4}
          value={job_title || ""}
          options={jobTitleOptions}
          onChange={handleInputChange}
        />
        <Form.Input
          name="employer"
          label="Employer"
          width={4}
          value={employer || ""}
          onChange={handleInputChange}
        />
        <Form.Select
          name="employment_status"
          label="Employment Status"
          width={4}
          value={employment_status || ""}
          options={employmentOptions}
          onChange={handleInputChange}
        />
        <DateInput
          asForm
          width={4}
          label="Employment Date"
          name="employment_date"
          value={employment_date || ""}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Select
          name="citizenship_country"
          label="Citizenship"
          width={9}
          value={citizenship_country}
          options={countryOptions()}
          onChange={handleInputChange}
        />
        <Form.Select
          name="citizenship_state"
          label="State"
          width={9}
          options={semanticUIStates}
          disabled={
            citizenship_country && citizenship_country !== "United States"
          }
          value={citizenship_state || ""}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group>
        <DateInput
          asForm
          name="member_since"
          label="Client Since"
          width={6}
          value={member_since || ""}
          onChange={handleInputChange}
        />
        <Form.Select
          name="operating_status"
          label="Operating Status"
          width={6}
          value={operating_status || ""}
          options={individualOperatingStatus}
          onChange={handleInputChange}
        />
        <Form.Dropdown
          placeholder="Select Loan Officer"
          name="loan_officer_uuid"
          label="Loan Officer"
          width={6}
          value={loan_officer_uuid || ""}
          fluid
          search
          selection
          onChange={handleInputChange}
          options={loanOfficers}
        />
      </Form.Group>
    </Form>
  );
}

export default IndividualEntityInfo;
