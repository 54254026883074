import React, { useEffect } from "react";
import { useScript } from "./hooks";
import { Context } from "./context";
import { DocEditor, EditorConfig } from "./types";

declare global {
  interface Window {
    DocsAPI?: {
      DocEditor: DocEditor;
    };
  }
}

export interface DocumentEditorProviderProps {
  config: EditorConfig;
  editorId: string;
  scriptUrl: string;
  children?: React.ReactNode;
}

export function DocumentEditorProvider({
  editorId,
  scriptUrl,
  children,
  config
}: DocumentEditorProviderProps) {
  const { events } = config;
  const [loaded, error] = useScript(scriptUrl);

  useEffect(() => {
    if (!loaded || !window.DocsAPI) return;
    const editor = new window.DocsAPI.DocEditor(editorId, {
      ...config,
      events: { ...events }
    });
    return () => editor?.destroyEditor();
  }, [loaded, config, editorId, events]);

  return (
    <Context.Provider value={{ editorId, error }}>
      <div
        className="document-editor-container"
        data-testid="document-editor-container"
      >
        {children ? children : null}
        <div id={editorId} />
      </div>
    </Context.Provider>
  );
}
