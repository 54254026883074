import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Tabs } from "@bafsllc/ui-shared";
import { NestedRouteProps } from "@blast-client/types";
import classnames from "classnames";

export function PrepareDocsTabs({
  tabs,
  style = "tabular",
  baseRoute,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  relativeRoute,
  children
}: PrepareDocsTabsProps) {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState("");

  const navigateToTabByPathname = () => {
    const pathname = location.pathname.split("/");
    const currTab = tabs.filter(t => pathname.includes(t.slug));
    if (currTab.length) {
      setSelectedTab(currTab[0].name);
      history.push(location.pathname);
    } else {
      navToTab(tabs[0].name);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(navigateToTabByPathname, []);

  const findPathFromName = tabName => tabs.find(t => t.name === tabName);

  const navToTab = (tabName: string) => {
    if (selectedTab === tabName) return;
    setSelectedTab(tabName);
    history.push(`${baseRoute}/${findPathFromName(tabName).slug}`);
  };

  const renderButton = ({ active, currentTab }) => (
    <button
      type="button"
      className={classnames("item tab", {
        active
      })}
      key={currentTab}
      onClick={() => navToTab(currentTab)}
      role="tab"
      aria-selected={active}
    >
      {currentTab}
    </button>
  );

  return (
    <div className={style === "tabular" ? "tabular wrapper" : ""}>
      <Tabs
        tabs={tabs.map(t => t.name)}
        selectedTab={selectedTab}
        renderButton={renderButton}
        style={style}
      />
      <div className="ui bottom attached segment tab tab-pane !mb-0 active">
        {children}
      </div>
    </div>
  );
}

export type TabObject = {
  name: string;
  slug: string;
};

export interface PrepareDocsTabsProps extends NestedRouteProps {
  tabs: TabObject[];
  style?: "tabular" | "underline";
  children?: JSX.Element | JSX.Element[] | Function;
}
