import { createSlice } from "@reduxjs/toolkit";
import TypeCheck from "typecheck-extended";

export const defaultInitialState = {
  data: [],
  isLoading: false,
  pagination: null, // { number, size, isLastPage }
  error: null
};

const modify = (data, identifiers, meta) => {
  const action = { payload: { data, identifiers } };
  if (meta !== undefined && meta !== null) action.meta = meta;
  return action;
};
const paginationMapToQueryParams = action => {
  if (action.meta && action.meta.pagination) {
    const {
      meta,
      meta: { pagination }
    } = action;
    return {
      ...action,
      meta: {
        ...meta,
        pagination: pagination && {
          page_number: pagination.number,
          page_size: pagination.size
        }
      }
    };
  }
  return action;
};
const identify = (identifiers, meta) =>
  paginationMapToQueryParams({ payload: { identifiers }, meta });

const createCRUDSlice = (
  name,
  idKey = "uuid",
  {
    shortName = name,
    initialState = defaultInitialState,
    extraReducers = {}
  } = {}
) => {
  TypeCheck(name, "string");
  TypeCheck(idKey, "string");
  TypeCheck(shortName, "string");

  const selectors = {
    [shortName]: ({ [name]: { data } }) => data,
    isLoading: ({ [name]: { isLoading } }) => isLoading,
    pagination: ({ [name]: { pagination } }) => pagination,
    error: ({ [name]: { error } }) => error
  };

  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
      create: {
        reducer: (state, { payload, error }) =>
          error
            ? {
                ...state,
                error: payload,
                isLoading: false
              }
            : {
                ...state,
                isLoading: true
              },
        prepare: modify
      },
      retrieve: {
        reducer: (state, { payload, error }) =>
          error
            ? {
                ...state,
                error: payload,
                isLoading: false
              }
            : { ...state, isLoading: true },
        prepare: identify
      },
      update: {
        reducer: (state, { payload, error }) =>
          error
            ? {
                ...state,
                error: payload,
                isLoading: false
              }
            : { ...state, isLoading: true },
        prepare: modify
      },
      delete: {
        reducer: (state, { payload, error }) => {
          const { identifiers = {} } = payload;
          if (error) {
            return {
              ...state,
              error: payload,
              isLoading: false
            };
          }
          if (!identifiers[idKey]) {
            return {
              ...state,
              data: undefined,
              isLoading: false
            };
          }
          return {
            ...state,
            data: state.data.filter(
              instance => instance[idKey] !== identifiers[idKey]
            ),
            isLoading: false
          };
        },
        prepare: identify
      },
      clearPagination: state => ({
        ...state,
        pagination: null
      }),
      clearError: state => ({
        ...state,
        error: null
      })
    },
    extraReducers: builder => {
      builder
        .addCase(`${name}/created`, (state, { payload }) => ({
          ...state,
          data: [...state.data, payload],
          isLoading: false
        }))
        .addCase(`${name}/updated`, (state, { payload, meta, error }) => {
          if (error) {
            return {
              ...state,
              error: payload,
              isLoading: false
            };
          }
          if (Array.isArray(payload)) {
            return {
              ...state,
              data: payload,
              isLoading: false,
              pagination: meta && {
                number: meta.page_number,
                size: meta.page_size,
                isLastPage: meta.last_page
              }
            };
          }
          return {
            ...state,
            data: state.data.map(instance =>
              instance[idKey] === payload[idKey]
                ? { ...instance, ...payload }
                : instance
            ),
            isLoading: false
          };
        })
        .addCase(`${name}/deleted`, state => ({
          ...state,
          isLoading: false
        }));
      if (extraReducers) {
        Object.keys(extraReducers).forEach(key => {
          builder.addCase(key, extraReducers[key]);
        });
      }
    }
  });

  return {
    name,
    reducer,
    actions,
    initialState,
    selectors
  };
};

export default createCRUDSlice;
