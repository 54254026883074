import TC from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import { asyncFetchService } from "../../../FetchService";
import { ConcatQueryParams, RemoveNullKeys } from "../Utilities";
import { FormatUrlV2 } from "../../../FormatUrl";
import logger from "../../../logger";

async function create(body) {
  const funcName = "====  LoanAdditionalFees.create(body)  ====";
  logger.debug(funcName, [body]);
  TC(body, "object");

  const endpoint = ApiEndpoints.additionalFees;
  const url = FormatUrlV2(endpoint);
  const rsp = await asyncFetchService("POST", url, body);
  return rsp;
}

async function read(filters) {
  const funcName = "====  LoanAdditionalFees.read(filters)  ====";
  logger.debug(funcName, [filters]);
  TC(filters.feeUuid, "string", false);

  const filterCount = Object.keys(filters).length;
  if ((filters.feeUuid && filterCount > 1) || filterCount === 0) {
    let thrownError;
    logger.error(thrownError, "Error: Invalid Filter Combination");
    // TODO: [#5740] Put a checkable status here.
    const errorRsp = {};
    return errorRsp;
  }

  return asyncFetchService(
    "GET",
    filters.feeUuid
      ? FormatUrlV2(ApiEndpoints.additionalFee, { feeUuid: filters.feeUuid })
      : FormatUrlV2(ApiEndpoints.additionalFees) + ConcatQueryParams(filters)
  );
}

async function update(feeUuid, body) {
  const funcName = "====  LoanAdditionalFees.update(feeUuid, body)  ====";
  logger.debug(funcName, [feeUuid, body]);
  TC(feeUuid, "string");
  TC(body, "object");

  const sanitizedBody = RemoveNullKeys(body);
  const endpoint = ApiEndpoints.additionalFee;
  const url = FormatUrlV2(endpoint, { feeUuid });
  const rsp = await asyncFetchService("PUT", url, sanitizedBody);
  return rsp;
}

async function remove(feeUuid) {
  const funcName = "====  LoanAdditionalFees.remove(feeUuid)  ====";
  logger.debug(funcName, [feeUuid]);
  TC(feeUuid, "string");

  const endpoint = ApiEndpoints.additionalFee;
  const url = FormatUrlV2(endpoint, { feeUuid });
  const rsp = await asyncFetchService("DELETE", url);
  return rsp;
}

export default {
  create,
  read,
  remove,
  update
};
