import { CSSProperties } from "react";

export const summaryBlockStyle = {
  borderLeft: "3px dotted rgba(69, 86, 121, 0.3)",
  margin: "1%",
  paddingLeft: "1%"
};

export const l1Wrapper = { marginTop: "1%" };

export const l2Wrapper = {
  margin: "1% 0 0 1%",
  paddingLeft: "1%",
  borderLeft: "1px solid rgba(0, 0, 0, 0.1)"
};

export const codeText = {
  backgroundColor: "rgba(0, 0, 0, 0.1)",
  fontFamily: "Courier New, monospace",
  fontSize: "0.9em",
  margin: "0 0.5% 0 0.5%",
  padding: "0.2em 0.2em 0.2em 0.4em"
};

export const tableStyle: CSSProperties = {
  width: "100%",
  tableLayout: "auto"
};
export const trStyle: CSSProperties = {
  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  overflow: "visible",
  whiteSpace: "nowrap"
};
