import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Message, Header } from "semantic-ui-react";
import { getAccountNumber } from "../../../../../../../services/TextFormatting";

export const getLoanPreviewItems = transactionPreviewData =>
  transactionPreviewData.loan_data_updates.map((loanPreviewItem, i) => (
    <div style={{ marginTop: "10px" }} key={loanPreviewItem.uuid}>
      <Header as="h3" size="small">
        Loan {i + 1} {getAccountNumber(loanPreviewItem)}
      </Header>

      <Message>
        <p>
          Current Balance: <code>{loanPreviewItem.current_balance}</code>
        </p>
        <p>
          Accrued Interest: <code>{loanPreviewItem.interest_accrued}</code>
        </p>
        <p>
          Fees Accrued: <code>{loanPreviewItem.fees_accrued}</code>
        </p>
        <p>
          Service Fee Accrued:{" "}
          <code>{loanPreviewItem.service_fee_accrued}</code>
        </p>
        <p>
          Past Due Date: <code>{loanPreviewItem.past_due_date}</code>
        </p>
        <p>
          Past Due Amount: <code>{loanPreviewItem.past_due_amount}</code>
        </p>
        <p>
          Due Date: <code>{loanPreviewItem.due_date}</code>
        </p>
        <p>
          Next Payment Date: <code>{loanPreviewItem.next_payment_date}</code>
        </p>
      </Message>
    </div>
  ));

export const getTransactionItems = transactionPreviewData =>
  transactionPreviewData.transaction_history_updates
    .filter(
      transactionItem =>
        transactionItem.tran_code !== "535" &&
        transactionItem.tran_code !== "545"
    )
    .map((transactionItem, i) => (
      <div style={{ marginTop: "10px" }} key={transactionItem.uuid}>
        <Header as="h3" size="small">
          Transaction {i + 1}{" "}
          {transactionItem.description
            ? `| ${transactionItem.description}`
            : null}
        </Header>

        <Message>
          <p>
            Effective Date: <code>{transactionItem.effective_date}</code>
          </p>
          <p>
            Tran Code: <code>{transactionItem.tran_code}</code>
          </p>
          <p>
            Transaction Amount: <code>{transactionItem.tran_amount}</code>
          </p>
          <p>
            Principal Split: <code>{transactionItem.principal_impact}</code>
          </p>
          <p>
            Interest Split: <code>{transactionItem.interest_paid}</code>
          </p>
          <p>
            Fee Split: <code>{transactionItem.fees_paid_amount}</code>
          </p>
          <p>
            Service Fee Split: <code>{transactionItem.service_fee}</code>
          </p>
          <p>
            Ending Balance: <code>{transactionItem.principal}</code>
          </p>
          <p>
            Ending Interest: <code>{transactionItem.interest_accumulated}</code>
          </p>
        </Message>
      </div>
    ));

export const getBillItems = transactionPreviewData => {
  const billKeys = Object.keys(transactionPreviewData.bill_updates);
  const validBillKeys = billKeys.filter(
    key =>
      transactionPreviewData.bill_updates[key].status !== "Satisfied" &&
      transactionPreviewData.bill_updates[key].status !== "Waived" &&
      transactionPreviewData.bill_updates[key].status !== "Skipped"
  );

  const nextBillItems = validBillKeys.map(key => {
    const bill = transactionPreviewData.bill_updates[key];

    return (
      <div style={{ marginTop: "10px" }} key={bill.uuid}>
        <Header as="h3" size="small">
          Bill {bill.bill_number}
        </Header>

        <Message>
          <p>
            Bill Number: <code>{bill.bill_number}</code>
          </p>
          <p>
            Bill Type: <code>{bill.bill_type}</code>
          </p>
          <p>
            Bill Amount: <code>{bill.amount}</code>
          </p>
          <p>
            Amount Satisfied: <code>{bill.amount_satisfied}</code>
          </p>
          <p>
            Interest Due Total: <code>{bill.interest_due_total}</code>
          </p>
          <p>
            Interest Due Satisfied: <code>{bill.interest_due_satisfied}</code>
          </p>
        </Message>
      </div>
    );
  });
  if (nextBillItems.length === 0) {
    return <p>After this payment there are no remaining unsatisfied bills.</p>;
  }
  return nextBillItems;
};

function PreviewTransactionResult({ transactionPreviewData }) {
  const [loanPreview, setLoanPreview] = useState(null);
  const [transactionPreview, setTransactionPreview] = useState(null);
  const [billPreview, setBillPreview] = useState(null);

  useEffect(() => {
    // set data for each section
    setLoanPreview(getLoanPreviewItems(transactionPreviewData));
    setTransactionPreview(getTransactionItems(transactionPreviewData));
    setBillPreview(getBillItems(transactionPreviewData));
  }, [transactionPreviewData]);

  return (
    <>
      <Header as="h2" size="medium">
        Loan Data
      </Header>
      {loanPreview}
      <br />
      <hr />
      <Header as="h2" size="medium">
        Transaction Data
      </Header>
      {transactionPreview}
      <br />
      <hr />
      <Header as="h2" size="medium">
        Bill Data
      </Header>
      {billPreview}
    </>
  );
}

PreviewTransactionResult.propTypes = {
  transactionPreviewData: PropTypes.object
};

export default PreviewTransactionResult;
