/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from "@reduxjs/toolkit";

const SLICE_NAME = "models";

export const initialState = {
  models: undefined
};

// Utils
const apiAction = (type, service, identifiers, data, meta) => {
  const action = {
    type: `${service}/${type}`,
    payload: {}
  };
  if (identifiers !== undefined) action.payload.identifiers = identifiers;
  if (data) action.payload.data = data;
  if (meta) action.meta = meta;
  return action;
};

const { actions, name, reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    cleanUpModels(state) {
      state.models = null;
    }
  },
  extraReducers: builder => {
    builder.addCase(`${SLICE_NAME}/updated`, (state, { payload }) => {
      if (Array.isArray(payload.rows)) {
        state.models = payload.rows;
      }
    });
  }
});

// SELECTORS
const sliceSelector = state => state[name];

export const modelsSelector = createSelector(
  sliceSelector,
  state => state.models
);

export const errorsSelector = createSelector(
  sliceSelector,
  state => state.errors
);

const selectors = {
  modelsSelector,
  errorsSelector
};

// OTHER ACTIONS
const asyncActions = {
  // Models
  retrieveProductionModels:
    ({ institutionUuid }) =>
    async dispatch => {
      await dispatch(
        apiAction("retrieve", "models", {
          filter: "status=Production",
          institutionUuid,
          pageNumber: 1,
          pageSize: 999
        })
      );
    }
};

Object.assign(actions, asyncActions);

// EXPORTS
export { actions, name, reducer, selectors };

export default {
  name,
  actions,
  reducer,
  selectors,
  initialState
};
