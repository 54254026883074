/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
import _, { omitBy, isNil } from "lodash";
import { createActions } from "redux-actions";

import Constants from "../../../../services/Constants/strings";
import {
  Loans,
  Loan5300Codes,
  LoanCodeRelationships,
  Messages,
  Entities,
  ObjectMetadata,
  Assets,
  Collateral,
  Institutions,
  Cms,
  Schedule,
  Ticklers,
  CreditRisk,
  LoanPaymentSchedules as scheduleService
} from "../../../../services/ApiLib";
import { RemoveNullKeys } from "../../../../services/ApiLib/services/Utilities";
import logger from "../../../../services/logger";

const ticklerStatus = {
  Satisfied: "Overridden",
  Waived: "Waived"
};

export const initialState = {
  activeItem: "",
  assets: {},
  codeOptions: {},
  collateralDeleteModalOpen: false,
  collateralDeleteUuid: "",
  collateralModalAsset: {},
  collateralModalData: {},
  collateralModalIsAdd: false,
  collateralModalOpen: false,
  data: [],
  docTrackingData: [],
  docTrackingItem: "Ticklers",
  docTrackingDataLastPage: false,
  collateralDataLastPage: false,
  entities: [],
  entityAssets: [],
  removedAssets: [],
  entityData: { institution_uuid: "", uuid: "" },
  institution: {},
  loan: undefined,
  selectedCodeOptions: {},
  openAssetsForm: false,
  additionalAssetData: {},
  assetDataFound: false
};

export const actionCreators = createActions(
  {
    loadLoan: uuid => dispatch => {
      if (!uuid) return;
      Loans.asyncRead({ loanUuid: uuid })
        .then(({ data }) => {
          dispatch(actionCreators.populateLoan(data));
          dispatch(actionCreators.loadEntityData(data.entity_uuid));
        })
        .catch(({ error }) => dispatch(actionCreators.loanError(error)));
    },
    loadEntityData: uuid => dispatch => {
      Cms.asyncRead(uuid)
        .then(({ data }) => {
          dispatch(actionCreators.populateEntityData(data));
          dispatch(actionCreators.loadInstitution(data.institution_uuid));
        })
        .catch(({ error }) => dispatch(actionCreators.entityDataError(error)));
    },
    loadInstitution: uuid => dispatch => {
      Institutions.asyncRead({ institutionUuid: uuid })
        .then(({ data }) => dispatch(actionCreators.populateInstitution(data)))
        .catch(({ error }) => dispatch(actionCreators.institutionError(error)));
    },
    loadEntitiesByInstitution: institutionUuid => async dispatch => {
      try {
        const entities = await Entities.asyncReadByInstitutionUuid(
          institutionUuid
        );
        if (entities.data) {
          dispatch(actionCreators.collateralLoadEntities(entities.data));
        }
        if (entities.error) {
          logger.error(entities.error);
        }
      } catch (err) {
        logger.error(err);
      }
    },
    loadCollateralByLoanUuid: loanUuid => async dispatch => {
      try {
        const entities = await Collateral.asyncGet(loanUuid, {
          number: 0,
          size: 300
        });
        if (entities.data) {
          dispatch(
            actionCreators.populateCollateralTable({
              data: entities.data,
              meta: entities.metaData
            })
          );
          // eslint-disable-next-line camelcase
          entities.data.forEach(({ asset_uuid }) =>
            dispatch(actionCreators.loadAsset(asset_uuid))
          );
        }
        if (entities.error) {
          logger.error(entities.error);
          dispatch(
            actionCreators.docListLoadError({
              errorMessage: Constants.CMS_ERROR_LOAD_DOC_LIST
            })
          );
        }
      } catch (err) {
        logger.error(err);
      }
    },
    loadAsset: assetUuid => dispatch => {
      Assets.asyncRead({ assetUuid })
        .then(({ data }) =>
          dispatch(actionCreators.collateralPopulateAsset(data))
        )
        .catch(err => logger.error(err));
    },
    loadAdditionalAsset: (uuid, group, assetClass) => async dispatch => {
      if (group === "Real Estate") {
        try {
          const assetData = await Assets.readRealEstateByAssetUuid({
            asset_uuid: uuid
          });
          if (assetData.error) {
            logger.error(assetData.error);
          } else {
            dispatch(
              actionCreators.setAdditionalAssets({
                data: assetData.data.length ? assetData.data[0] : "",
                assetFound: !!assetData.data.length
              })
            );
          }
        } catch (err) {
          logger.error(err);
        }
      }
      if (group === "Personal Property" && assetClass !== "Accounts") {
        try {
          const assetData = await Assets.readPersonalPropertyByAssetUuid({
            asset_uuid: uuid
          });
          logger.debug(assetData);
          if (assetData.error) {
            logger.error(assetData.error);
          } else {
            dispatch(
              actionCreators.setAdditionalAssets({
                data: assetData.data.length ? assetData.data[0] : "",
                assetFound: !!assetData.data.length
              })
            );
          }
        } catch (err) {
          logger.error(err);
        }
      }
      if (group === "Personal Property" && assetClass === "Accounts") {
        try {
          const assetData = await Assets.readAccountByAssetUuid({
            asset_uuid: uuid
          });
          if (assetData.error) {
            logger.error(assetData.error);
          } else {
            dispatch(
              actionCreators.setAdditionalAssets({
                data: assetData.data.length ? assetData.data[0] : "",
                assetFound: !!assetData.data.length
              })
            );
          }
        } catch (err) {
          logger.error(err);
        }
      }
    },
    updateAdditionalAsset:
      (group, assetClass) => async (dispatch, getState) => {
        const { additionalAssetData } = getState().LoanManagementReducer;
        if (group === "Real Estate") {
          try {
            const assetData = await Assets.asyncRealEstateAssetUpdate(
              additionalAssetData,
              {
                uuid: additionalAssetData.uuid,
                institution_uuid: additionalAssetData.institution_uuid
              }
            );
            if (assetData.error) {
              logger.error(assetData.error);
            }
          } catch (err) {
            logger.error(err);
          }
        }
        if (group === "Personal Property" && assetClass !== "Accounts") {
          try {
            const assetData = await Assets.asyncPersonalPropertyAssetUpdate(
              additionalAssetData,
              {
                uuid: additionalAssetData.uuid,
                institution_uuid: additionalAssetData.institution_uuid
              }
            );
            if (assetData.error) {
              logger.error(assetData.error);
            }
          } catch (err) {
            logger.error(err);
          }
        }
        if (group === "Personal Property" && assetClass === "Accounts") {
          try {
            const assetData = await Assets.asyncAccountAssetUpdate(
              additionalAssetData,
              {
                uuid: additionalAssetData.uuid,
                institution_uuid: additionalAssetData.institution_uuid
              }
            );
            if (assetData.error) {
              logger.error(assetData.error);
            }
          } catch (err) {
            logger.error(err);
          }
        }

        dispatch(
          actionCreators.editCollateralToggle({ collateralUuid: undefined })
        );
      },
    addAdditionalAsset: (group, assetClass) => async (dispatch, getState) => {
      const { additionalAssetData, collateralModalAsset } =
        getState().LoanManagementReducer;
      if (group === "Real Estate") {
        try {
          const assetData = await Assets.asyncRealEstateAssetAdd(
            additionalAssetData,
            collateralModalAsset.uuid,
            collateralModalAsset.institution_uuid
          );
          if (assetData.error) {
            logger.error(assetData.error);
          }
        } catch (err) {
          logger.error(err);
        }
      }
      if (group === "Personal Property" && assetClass !== "Accounts") {
        try {
          const assetData = await Assets.asyncPersonalPropertyAssetAdd(
            additionalAssetData,
            collateralModalAsset.uuid,
            collateralModalAsset.institution_uuid
          );
          if (assetData.error) {
            logger.error(assetData.error);
          }
        } catch (err) {
          logger.error(err);
        }
      }
      if (group === "Personal Property" && assetClass === "Accounts") {
        try {
          const assetData = await Assets.asyncAccountAssetAdd(
            additionalAssetData,
            collateralModalAsset.uuid,
            collateralModalAsset.institution_uuid
          );
          if (assetData.error) {
            logger.error(assetData.error);
          }
        } catch (err) {
          logger.error(err);
        }
      }
      dispatch(
        actionCreators.editCollateralToggle({ collateralUuid: undefined })
      );
    },
    loadScheduleByLoanId: loanUuid => (dispatch, getState) => {
      let pagination = null;
      // TODO: pagination should "probably" be added by the UI, not the store (debateable)
      const schedulePagination = getState().PaginationReducer.loanMgmtSchedule;
      if (schedulePagination) {
        pagination = {
          number: schedulePagination.number,
          size: schedulePagination.size
        };
      }
      Schedule.get(loanUuid, pagination)
        .then(({ data, metaData }) => {
          dispatch(
            actionCreators.populateDocTrackingData({ data, meta: metaData })
          );
        })
        .catch(resp => {
          logger.error(resp);
          dispatch(
            actionCreators.docListLoadError({
              errorMessage: Constants.CMS_ERROR_LOAD_DOC_LIST
            })
          );
        });
    },
    loadActiveTicklersByLoan: loanUuid => (dispatch, getState) => {
      // documents/v1/tickler?state=Active&parentUuid
      let pagination = null;
      // TODO: pagination should "probably" be added by the UI, not the store (debateable)
      const ticklerPagination = getState().PaginationReducer.loanMgmtTickler;
      if (ticklerPagination) {
        pagination = {
          number: ticklerPagination.number,
          size: ticklerPagination.size
        };
      }
      Ticklers.asyncReadAllActive(loanUuid, null, pagination)
        .then(({ data, metaData }) =>
          dispatch(
            actionCreators.loadTicklerMessagesAndMetadata({
              data,
              meta: metaData
            })
          )
        )
        .catch(resp => {
          logger.error(resp);
          dispatch(
            actionCreators.docListLoadError({
              errorMessage: Constants.CMS_ERROR_LOAD_DOC_LIST
            })
          );
        });
    },
    loadTicklerMessagesAndMetadata:
      ({ data, meta }) =>
      dispatch => {
        data.forEach(doc => {
          Messages.get(
            ({ data: messages = [] }, { uuid }) => {
              dispatch({
                type: "DOC_TRACKING_TICKLERS_GET_MESSAGES",
                messages,
                uuid
              });
            },
            err => logger.error(err), // TODO: handle error
            null,
            { parentUuid: doc.uuid, number: 0, size: 10 },
            { uuid: doc.uuid }
          );
          if (doc.objectUuid) {
            ObjectMetadata.get(
              null, // used to be jwt
              (metadata, { uuid }) => {
                const messages = metadata.data.messages || [];
                const objectMetadata = { messages, ...metadata.data };
                dispatch({
                  type: "DOC_TRACKING_TICKLERS_GET_OBJECT_DATA",
                  objectMetadata,
                  uuid
                });
              },
              err => logger.error(err), // TODO: handle error
              doc.objectUuid,
              null,
              null,
              { uuid: doc.uuid }
            );
          }
        });
        dispatch(actionCreators.populateDocTrackingData({ data, meta }));
      },
    updateLoan:
      ({ uuid, ...updates }) =>
      (dispatch, getState) => {
        if (!uuid) return;
        const {
          LoanManagementReducer: { loan: oldLoan }
        } = getState();
        dispatch(actionCreators.optimisticUpdateLoan(updates));
        const newBody = { ...updates, uuid };
        Loans.update(
          ({ data }) => {
            dispatch(actionCreators.populateLoan(data));
          },
          ({ error, detail, title }, oldLoanData) => {
            dispatch(actionCreators.populateLoan(oldLoanData));
            dispatch(actionCreators.loanError(error || detail || title));
          },
          newBody,
          oldLoan
        );
      },
    deleteCollateral: () => async (dispatch, getState) => {
      const {
        LoanManagementReducer: { collateralDeleteUuid: uuid, assets }
      } = getState();
      try {
        const removedCollateral = await Collateral.asyncRemove(uuid);
        if (removedCollateral.data) {
          dispatch(actionCreators.deleteCollateralSuccess({ uuid, assets }));
        }
        if (removedCollateral.error) {
          logger.error(removedCollateral.error);
        }
      } catch (err) {
        logger.error(err);
      }
    },
    updateCollateralModalAsset:
      ({ name, value }) =>
      (dispatch, getState) => {
        const {
          LoanManagementReducer: { collateralModalIsAdd, assets, removedAssets }
        } = getState();
        if (name === "entity_uuid") {
          if (collateralModalIsAdd) {
            Assets.get(
              null,
              ({ data }) =>
                dispatch(
                  actionCreators.populateCollateralEntityAssets({
                    entityAssets: data,
                    entityUuid: value,
                    assets,
                    removedAssets
                  })
                ),
              err => logger.error(err), // TODO: handle error
              null,
              { entity_uuid: value, page_size: 300, page_number: 0 }
            );
          } else {
            Assets.get(
              null,
              ({ data }) =>
                dispatch(
                  actionCreators.populateEditCollateralEntityAssets({
                    entityAssets: data
                  })
                ),
              err => logger.error(err), // TODO: handle error
              null,
              { entity_uuid: value }
            );
          }
        } else if (name === "uuid") {
          if (value === "new") {
            const { entityData } = getState().LoanManagementReducer;
            const asset =
              entityData && entityData.uuid
                ? { entity_uuid: entityData.uuid, [name]: value }
                : {};
            dispatch(actionCreators.collateralModalSetAsset({ asset }));
          } else {
            const { entityAssets = [] } = getState().LoanManagementReducer;
            const asset = entityAssets.find(x => x.uuid === value);
            dispatch(actionCreators.collateralModalSetAsset({ asset }));
          }
        } else if (name === "asset_group") {
          dispatch(
            actionCreators.collateralModalAssetGroupUpdate({ name, value })
          );
        } else {
          dispatch(actionCreators.collateralModalAssetUpdate({ name, value }));
        }
      },
    saveCollateral: () => async (dispatch, getState) => {
      const state = getState();
      const {
        institution,
        collateralModalData,
        collateralModalIsAdd,
        collateralModalAsset,
        loan: { uuid: loan_uuid }
      } = state.LoanManagementReducer;

      if (collateralModalIsAdd) {
        const asset = {
          ...collateralModalAsset,
          institution_uuid: institution.uuid
        };
        const onSuccess = async ({ data }) => {
          const collateral = {
            ...collateralModalData,
            asset_uuid: data.uuid,
            institution_uuid: institution.uuid,
            loan_uuid
          };
          try {
            const newCollateral = await Collateral.asyncAdd(collateral);
            if (newCollateral.data) {
              if (collateralModalAsset.asset_group !== "Other") {
                dispatch(actionCreators.openAssetsForm());
              } else {
                dispatch(
                  actionCreators.editCollateralToggle({
                    collateralUuid: undefined
                  })
                );
              }
              dispatch(
                actionCreators.updateTableAfterAdd({ data: newCollateral.data })
              );
              dispatch(actionCreators.loadAsset(newCollateral.data.asset_uuid));
              dispatch(
                actionCreators.loadAdditionalAsset(
                  newCollateral.data.asset_uuid,
                  collateralModalAsset.asset_group,
                  collateralModalAsset.asset_class
                )
              );
              dispatch(actionCreators.collateralModalSetAsset({ asset: data }));
            }
            if (newCollateral.error) {
              logger.error(newCollateral.error);
            }
          } catch (err) {
            logger.error(err);
          }
        };
        const onError = err => logger.error(err);

        if (!asset.uuid || asset.uuid === "new") {
          try {
            const newAssets = await Assets.asyncAdd(RemoveNullKeys(asset));
            if (newAssets.data) {
              onSuccess(newAssets);
            }
            if (newAssets.error) {
              onError(newAssets.error);
            }
          } catch (err) {
            onError(err);
          }
        } else {
          try {
            const newAssets = await Assets.asyncUpdate(RemoveNullKeys(asset));
            if (newAssets.data) {
              onSuccess(newAssets);
            }
            if (newAssets.error) {
              onError(newAssets.error);
            }
          } catch (err) {
            onError(err);
          }
        }
        return;
      }
      dispatch(actionCreators.editCollateral());
    },
    editCollateral: () => async (dispatch, getState) => {
      const state = getState();
      const {
        data: tableData,
        institution,
        collateralModalData,
        collateralModalAsset
      } = state.LoanManagementReducer;
      const asset = {
        uuid: collateralModalData.asset_uuid,
        ...collateralModalAsset,
        institution_uuid: institution.uuid
      };
      const newAssets = await Assets.asyncUpdate(RemoveNullKeys(asset));
      if (newAssets.data) {
        const {
          data: { uuid }
        } = newAssets;
        const collateral = {
          ...collateralModalData,
          uuid: collateralModalData.uuid,
          asset_uuid: uuid,
          institution_uuid: institution.uuid
        };
        const newCollateral = await Collateral.asyncUpdate(collateral);
        if (newCollateral.data) {
          const responseData = newCollateral.data;
          const newData = tableData.map(oldData => {
            if (oldData.uuid === responseData.uuid) {
              return newCollateral.data;
            }
            return oldData;
          });
          if (newAssets.data.asset_group !== "Other") {
            dispatch(actionCreators.openAssetsForm());
          } else {
            dispatch(
              actionCreators.editCollateralToggle({ collateralUuid: undefined })
            );
          }
          dispatch(actionCreators.updateTableAfterEdit({ data: newData }));
          dispatch(actionCreators.loadAsset(responseData.asset_uuid));
          dispatch(
            actionCreators.loadAdditionalAsset(
              responseData.asset_uuid,
              collateralModalAsset.asset_group,
              collateralModalAsset.asset_class
            )
          );
        }

        if (newCollateral.error) {
          logger.error(newCollateral.error);
        }
      }
      if (newAssets.error) {
        logger.error(newAssets.error);
      }
    },
    createNewCreditReview: () => async (dispatch, getState) => {
      const state = getState();
      const { entityData, institution, loanUuid } = state.LoanManagementReducer;
      const { userUuid } = state.auth;

      const body = {
        currentRiskRating: -1,
        entityUuid: entityData.uuid,
        farReports: [],
        instApprovedRiskRating: -1,
        institutionUuid: institution.uuid,
        loanReviewOfficer: userUuid,
        loanReviewReccRiskRating: -1,
        loanUuid,
        narrativeData: {},
        originalBorrowerRiskRating: -1
      };
      try {
        const creditRisk = await CreditRisk.add(body);
        if (creditRisk.data) {
          actionCreators.loadCreditRiskReviewForLoan(loanUuid);
        }
        if (creditRisk.error) {
          logger.error(creditRisk.error);
        }
      } catch (err) {
        logger.error(err);
      }
    },
    loadCreditRiskReviewForLoan: loanUuid => async dispatch => {
      try {
        const creditRisk = await CreditRisk.get(loanUuid);
        if (creditRisk.data) {
          dispatch(
            actionCreators.populateTable({
              data: creditRisk.data,
              activeItem: "CREDIT_RISK_REVIEW"
            })
          );
        }
        if (creditRisk.error) {
          logger.error(creditRisk.error);
          dispatch(
            actionCreators.docListLoadError({
              errorMessage: Constants.CMS_ERROR_LOAD_DOC_LIST
            })
          );
        }
      } catch (err) {
        logger.error(err);
      }
    },
    loadAccountDocuments:
      ({ loanUuid, institutionUuid }) =>
      async dispatch => {
        const { participationBought, leadLoanUuid, investorInstitutionUuid } =
          await Loans.isParticipationBought(loanUuid);
        const pagination = { number: 0, size: 9999 };

        if (participationBought) {
          const uuid = null;
          const queryParams = { parentUuid: leadLoanUuid, institutionUuid };
          const leadDocInfo = await ObjectMetadata.asyncRead(
            uuid,
            queryParams,
            pagination
          );
          const leadDocsData = leadDocInfo.data || [];
          const invesstorSpecificDocInfo = await ObjectMetadata.asyncRead(
            null,
            { parentUuid: loanUuid, institutionUuid: investorInstitutionUuid },
            pagination
          );
          const investorOnlyDocs = invesstorSpecificDocInfo.data || [];
          dispatch(
            actionCreators.populateTable({
              data: [...leadDocsData, ...investorOnlyDocs]
            })
          );
        } else {
          ObjectMetadata.asyncRead(
            null,
            { parentUuid: loanUuid, institutionUuid },
            pagination
          )
            .then(({ data }) => {
              dispatch(actionCreators.populateTable({ data: [...data] }));
            })
            .catch(resp => {
              logger.error(resp);
              dispatch(
                actionCreators.docListLoadError({
                  errorMessage: Constants.CMS_ERROR_LOAD_DOC_LIST
                })
              );
            });
        }
      },

    /* Schedule Items */
    loadPaymentSchedules: loanUuid => dispatch => {
      scheduleService.get(
        ({ data }) => dispatch(actionCreators.populatePaymentSchedules(data)),
        resp => {
          logger.error(resp);
        },
        null,
        loanUuid
      );
    },
    createPaymentSchedule: PaymentScheduleData => dispatch => {
      scheduleService.add(
        ({ data }) =>
          dispatch(actionCreators.createPaymentScheduleSuccess(data)),
        resp => {
          logger.error(resp);
          dispatch(actionCreators.createPaymentScheduleError(resp));
        },
        PaymentScheduleData
      );
    },
    deletePaymentSchedule:
      ({ loan_uuid, uuid }) =>
      dispatch => {
        scheduleService.remove(
          ({ data }, meta) => {
            if (data.ok) {
              dispatch(actionCreators.deletePaymentScheduleSuccess(meta));
            }
          },
          resp => {
            logger.error(resp);
            dispatch(actionCreators.deletePaymentScheduleError(resp));
          },
          { loan_uuid, uuid },
          { loan_uuid, uuid }
        );
      },
    getLoanCodeRelationships: loanUuid => dispatch => {
      LoanCodeRelationships.get(
        data => dispatch(actionCreators.populateSelectedCodeOptions(data.data)),
        error => logger.error(error),
        { loan_uuid: loanUuid }
      );
    },
    getCodeOptions: () => dispatch => {
      Loan5300Codes.get(
        data => dispatch(actionCreators.populateCodeOptions(data.data)),
        error => logger.error(error)
      );
    },
    onViewCharacteristicsModal: loanUuid => dispatch => {
      dispatch(actionCreators.getCodeOptions());
      dispatch(actionCreators.getLoanCodeRelationships(loanUuid));
    },
    updateCodeOptions: (newCodeOptions, loan) => (dispatch, getState) => {
      const { selectedCodeOptions } = getState().LoanManagementReducer;
      Object.values(selectedCodeOptions).forEach(oldOption => {
        if (!newCodeOptions.includes(oldOption.loan_5300_code_uuid)) {
          LoanCodeRelationships.remove(
            () =>
              dispatch(
                actionCreators.removeSelectedCodeOptions(
                  oldOption.loan_5300_code_uuid
                )
              ),
            error => logger.error(error),
            oldOption.uuid
          );
        }
      });

      newCodeOptions.forEach(codeUuid => {
        if (!selectedCodeOptions[codeUuid]) {
          LoanCodeRelationships.add(
            data => dispatch(actionCreators.addSelectedCodeOptions(data.data)),
            error => logger.error(error),
            {
              loan_uuid: loan.uuid,
              loan_5300_code_uuid: codeUuid,
              institution_uuid: loan.institution_uuid
            }
          );
        }
      });
    },
    POPULATE_DOC_TRACKING_DATA: [({ data }) => data, ({ meta }) => meta]
  },
  "ADD_SELECTED_CODE_OPTIONS",
  "REMOVE_SELECTED_CODE_OPTIONS",
  "POPULATE_LOAN",
  "OPTIMISTIC_UPDATE_LOAN",
  "UPDATE_TABLE_AFTER_EDIT",
  "UPDATE_TABLE_AFTER_ADD",
  "LOAN_ERROR",
  "POPULATE_ENTITY_DATA",
  "ENTITY_DATA_ERROR",
  "POPULATE_COLLATERAL_ENTITY_ASSETS",
  "POPULATE_TABLE",
  "POPULATE_COLLATERAL_TABLE",
  "UPDATE_TICKLERS_DATA",
  "TICKLER_UPDATE_DOCUMENT_UPLOAD",
  "TICKLER_DELETE_DOCUMENT_UPLOAD",
  "POPULATE_PAYMENT_SCHEDULES",
  "CREATE_PAYMENT_SCHEDULE_SUCCESS",
  "CREATE_PAYMENT_SCHEDULE_ERROR",
  "DELETE_PAYMENT_SCHEDULE_SUCCESS",
  "DELETE_PAYMENT_SCHEDULE_ERROR",
  "SCHEDULE_EDIT_MODAL_SAVE",
  "SCHEDULE_NEW_MODAL_SAVE",
  "SCHEDULE_UPDATE_TOGGLE",
  "UPDATE_ACCOUNT_DOCUMENTS",
  "POPULATE_INSTITUTION",
  "POPULATE_CODE_OPTIONS",
  "POPULATE_SELECTED_CODE_OPTIONS",
  "INSTITUTION_ERROR",
  "ADD_COLLATERAL_TOGGLE",
  "EDIT_COLLATERAL_TOGGLE",
  "DELETE_COLLATERAL_TOGGLE",
  "DELETE_COLLATERAL_SUCCESS",
  "COLLATERAL_LOAD_ENTITIES",
  "COLLATERAL_MODAL_COLLATERAL_UPDATE",
  "COLLATERAL_MODAL_ASSET_UPDATE",
  "COLLATERAL_MODAL_ASSET_GROUP_UPDATE",
  "COLLATERAL_MODAL_SET_ASSET",
  "COLLATERAL_POPULATE_ASSET",
  "POPULATE_EDIT_COLLATERAL_ENTITY_ASSETS",
  "DOC_LIST_LOAD_ERROR",
  "OPEN_ASSETS_FORM",
  "CLOSE_ASSETS_FORM",
  "SET_ADDITIONAL_ASSETS",
  "SET_ADDITIONAL_ASSET_VALUE",
  "RESET_ADDITIONAL_ASSETS",
  {
    prefix: "LOAN_MANAGEMENT"
  }
);

// eslint-disable-next-line default-param-last
export default function LoanManagementReducer(state = initialState, action) {
  const { type } = action;
  const payload = action.payload ? action.payload : action;
  /**
   * FORGIVE: It appears some actions expect the payload to be wrapped in a
   * payload object, while others do not.
   *
   * Rather than going through each instantiation of each action, I am grabbing
   * the payload off the payload object if it exists, and just taking the raw
   * action if it does not.
   *
   * This seems to work in all cases, but we should revisit this reducer and
   * refactor it to be in line with the other reducers.
   */

  switch (type) {
    case "LOAN_MANAGEMENT/POPULATE_LOAN": {
      return { ...state, loan: payload, loanError: undefined };
    }
    case "LOAN_MANAGEMENT/SET_ADDITIONAL_ASSETS": {
      return {
        ...state,
        additionalAssetData: payload.data,
        assetDataFound: payload.assetFound
      };
    }
    case "LOAN_MANAGEMENT/SET_ADDITIONAL_ASSET_VALUE": {
      const { name, value } = payload;
      let newState = {};
      if (name === "life_insurance_policy_number") {
        const insurancePolicies = value.split(",", value.length);
        newState = { ...state.additionalAssetData, [name]: insurancePolicies };
      } else {
        newState = {
          ...state.additionalAssetData,
          [payload.name]: payload.value
        };
      }
      return { ...state, additionalAssetData: { ...newState } };
    }
    case "LOAN_MANAGEMENT/RESET_ADDITIONAL_ASSETS": {
      return { ...state, additionalAssetData: {} };
    }
    case "LOAN_MANAGEMENT/OPEN_ASSETS_FORM": {
      return { ...state, openAssetsForm: true };
    }
    case "LOAN_MANAGEMENT/CLOSE_ASSETS_FORM": {
      return { ...state, openAssetsForm: false };
    }
    case "LOAN_MANAGEMENT/OPTIMISTIC_UPDATE_LOAN": {
      return { ...state, loan: { ...state.loan, ...payload } };
    }

    case "LOAN_MANAGEMENT/LOAN_ERROR": {
      return { ...state, loanError: payload };
    }

    case "LOAN_MANAGEMENT/POPULATE_ENTITY_DATA": {
      return {
        ...state,
        entityData: omitBy(payload, isNil),
        entityDataError: undefined
      };
    }

    case "LOAN_MANAGEMENT/ENTITY_DATA_ERROR": {
      return { ...state, entityData: null, entityDataError: payload };
    }

    case "LOAN_MANAGEMENT/POPULATE_INSTITUTION": {
      return { ...state, institution: payload };
    }

    case "LOAN_MANAGEMENT/INSTITUTION_ERROR": {
      return { ...state, entityData: null, entityDataError: payload };
    }

    case "LOAN_MANAGEMENT/POPULATE_COLLATERAL_ENTITY_ASSETS":
      for (const element of payload.entityAssets) {
        if (Object.keys(payload.assets).includes(element.uuid)) {
          const assetIndex = payload.entityAssets.indexOf(element);
          payload.removedAssets.push(
            payload.entityAssets.splice(assetIndex, 1)[0]
          );
        }
      }

      return {
        ...state,
        entityAssets: payload.entityAssets,
        collateralModalAsset: { entity_uuid: payload.entityUuid },
        removedAssets: payload.removedAssets
      };
    case "LOAN_MANAGEMENT/POPULATE_EDIT_COLLATERAL_ENTITY_ASSETS":
      return {
        ...state,
        entityAssets: payload.entityAssets
      };

    case "LOAN_MANAGEMENT/POPULATE_TABLE": {
      return {
        ...state,
        activeItem: payload.activeItem,
        docTrackingData: payload.data,
        data: payload.data,
        assets: {}
      };
    }

    case "LOAN_MANAGEMENT/POPULATE_COLLATERAL_TABLE": {
      return {
        ...state,
        activeItem: payload.activeItem,
        data: payload.data,
        assets: {},
        collateralDataLastPage: payload.meta ? payload.meta.last_page : false
      };
    }

    case "LOAN_MANAGEMENT/POPULATE_DOC_TRACKING_DATA": {
      return {
        ...state,
        docTrackingData: payload,
        docTrackingDataLastPage: action.meta.last_page
      };
    }

    case "LOAN_MANAGEMENT/UPDATE_DOC_TRACKING_ITEM":
      return {
        ...state,
        docTrackingData: payload.data,
        docTrackingItem: action.name
      };

    case "LOAN_MANAGEMENT/UPDATE_TICKLERS_DATA": {
      const newData = _.cloneDeep(state.docTrackingData);
      let index;
      newData.forEach((obj, i) => {
        if (obj.uuid === payload.rowUuid) {
          index = i;
        }
      });
      newData[index].state = payload.value;
      newData[index].status = ticklerStatus[payload.value];
      return { ...state, docTrackingData: newData };
    }

    case "LOAN_MANAGEMENT/TICKLER_UPDATE_DOCUMENT_UPLOAD": {
      const newData = _.cloneDeep(state.docTrackingData);
      let index;
      newData.forEach((obj, i) => {
        if (obj.uuid === payload.rowUuid) {
          index = i;
        }
      });
      newData[index].objectUuid = payload.objectUuid;
      return {
        ...state,
        docTrackingData: newData
      };
    }

    case "LOAN_MANAGEMENT/TICKLER_DELETE_DOCUMENT_UPLOAD": {
      const newData = _.cloneDeep(state.data);
      let index;
      newData.forEach((obj, i) => {
        if (obj.uuid === payload.rowUuid) {
          index = i;
        }
      });
      delete newData[index].file;
      return {
        ...state,
        data: newData
      };
    }

    case "LOAN_MANAGEMENT/SCHEDULE_EDIT_MODAL_SAVE": {
      const newData = _.cloneDeep(state.docTrackingData);
      let index;
      newData.forEach((obj, i) => {
        if (obj.uuid === payload.rowUuid) {
          index = i;
        }
      });
      newData[index] = payload.form;
      return {
        ...state,
        docTrackingData: newData
      };
    }

    case "LOAN_MANAGEMENT/POPULATE_PAYMENT_SCHEDULES": {
      // TODO: verify this is right
      return { ...state, paymentSchedules: payload };
    }

    case "LOAN_MANAGEMENT/CREATE_PAYMENT_SCHEDULE_SUCCESS": {
      // TODO: create new schedule item
      return {
        ...state,
        paymentSchedules: [...state.paymentSchedules, payload]
      };
    }

    case "LOAN_MANAGEMENT/CREATE_PAYMENT_SCHEDULE_ERROR": {
      // TODO: show error
      return { ...state, payment_schedule_error: payload };
    }

    case "LOAN_MANAGEMENT/DELETE_PAYMENT_SCHEDULE_SUCCESS": {
      // TODO: delete new schedule item
      return {
        ...state,
        paymentSchedules: state.paymentSchedules.filter(
          item => item.uuid !== payload.uuid
        )
      };
    }

    case "LOAN_MANAGEMENT/DELETE_PAYMENT_SCHEDULE_ERROR": {
      // TODO: show error
      return { ...state, payment_schedule_error: payload };
    }

    // TODO: this needs to be removed when possible and update the component
    //       to dispatch on of actions from /store/loan-payment-schedules instead
    case "LOAN_MANAGEMENT/SCHEDULE_NEW_MODAL_SAVE": {
      const newData = _.cloneDeep(state.docTrackingData);
      newData.push(payload.form);
      return {
        ...state,
        docTrackingData: newData
      };
    }

    // TODO: this needs to be removed when possible and update the component
    //       to dispatch on of actions from /store/loan-payment-schedules instead
    case "LOAN_MANAGEMENT/SCHEDULE_UPDATE_TOGGLE": {
      const copyData = _.cloneDeep(state.data);
      for (let i = 0; i < copyData.length; i += 1) {
        copyData[i].state = payload.state;
      }
      return {
        ...state,
        data: copyData
      };
    }

    case "LOAN_MANAGEMENT/UPDATE_ACCOUNT_DOCUMENTS": {
      const newData = _.cloneDeep(state.data);
      let index;
      newData.forEach((obj, i) => {
        if (obj.uuid === payload.data.uuid) {
          index = i;
        }
      });
      newData[index] = payload.data;
      return {
        ...state,
        data: newData
      };
    }

    case "LOAN_MANAGEMENT/UPDATE_SINGLE_ACCOUNT_DOCUMENT": {
      const documents = state.data;
      const { doc } = payload;
      const newDocuments = documents.map(document => {
        if (document.uuid === doc.uuid) {
          let newDoc = { ...document };
          newDoc = doc;

          return newDoc;
        }

        return document;
      });
      return { ...state, data: newDocuments };
    }

    case "LOAN_MANAGEMENT/ADD_COLLATERAL_TOGGLE": {
      return {
        ...state,
        collateralModalIsAdd: true,
        collateralModalAsset: {},
        collateralModalData: {},
        collateralModalOpen: true
      };
    }

    case "LOAN_MANAGEMENT/EDIT_COLLATERAL_TOGGLE": {
      if (state.collateralModalOpen) {
        return {
          ...state,
          collateralModalAsset: {},
          collateralModalData: {},
          collateralModalIsAdd: false,
          collateralModalOpen: false,
          openAssetsForm: false
        };
      }
      const selectedCollateral =
        state.data[_.findIndex(state.data, { uuid: payload.collateralUuid })];
      return {
        ...state,
        collateralModalOpen: true,
        collateralModalData: selectedCollateral,
        collateralModalAsset: state.assets[selectedCollateral.asset_uuid]
      };
    }

    case "LOAN_MANAGEMENT/DELETE_COLLATERAL_TOGGLE": {
      if (state.collateralDeleteModalOpen) {
        return {
          ...state,
          collateralDeleteModalOpen: false,
          collateralDeleteUuid: ""
        };
      }
      return {
        ...state,
        collateralDeleteModalOpen: true,
        collateralDeleteUuid: payload.assetUuid
      };
    }

    case "LOAN_MANAGEMENT/DELETE_COLLATERAL_SUCCESS": {
      delete payload.assets[state.additionalAssetData.asset_uuid];
      return {
        ...state,
        data: state.data.filter(collateral => collateral.uuid !== payload.uuid),
        assets: payload.assets,
        collateralDeleteModalOpen: false,
        collateralDeleteUuid: ""
      };
    }

    case "LOAN_MANAGEMENT/COLLATERAL_LOAD_ENTITIES": {
      return { ...state, entities: payload };
    }

    case "LOAN_MANAGEMENT/COLLATERAL_MODAL_COLLATERAL_UPDATE": {
      return {
        ...state,
        collateralModalData: {
          ...state.collateralModalData,
          [payload.name]: payload.value
        }
      };
    }

    case "LOAN_MANAGEMENT/COLLATERAL_MODAL_ASSET_UPDATE": {
      return {
        ...state,
        collateralModalAsset: {
          ...state.collateralModalAsset,
          [payload.name]: payload.value
        }
      };
    }

    case "LOAN_MANAGEMENT/COLLATERAL_MODAL_ASSET_GROUP_UPDATE": {
      return {
        ...state,
        collateralModalAsset: {
          ...state.collateralModalAsset,
          asset_group: payload.value,
          asset_class: ""
        }
      };
    }

    case "LOAN_MANAGEMENT/COLLATERAL_MODAL_SET_ASSET": {
      return {
        ...state,
        collateralModalAsset: payload.asset,
        collateralModalData: {
          ...state.collateralModalData,
          asset_uuid: payload.value
        }
      };
    }

    case "LOAN_MANAGEMENT/COLLATERAL_POPULATE_ASSET": {
      return { ...state, assets: { ...state.assets, [payload.uuid]: payload } };
    }

    case "LOAN_MANAGEMENT/POPULATE_CODE_OPTIONS": {
      const newCodeOptions = {};
      payload.forEach(option => {
        newCodeOptions[option.uuid] = option;
      });
      return { ...state, codeOptions: newCodeOptions };
    }

    case "LOAN_MANAGEMENT/POPULATE_SELECTED_CODE_OPTIONS": {
      const newSelectedOptions = {};
      payload.forEach(option => {
        newSelectedOptions[option.loan_5300_code_uuid] = option;
      });
      return { ...state, selectedCodeOptions: newSelectedOptions };
    }

    case "LOAN_MANAGEMENT/ADD_SELECTED_CODE_OPTIONS": {
      return {
        ...state,
        selectedCodeOptions: {
          ...state.selectedCodeOptions,
          [payload.loan_5300_code_uuid]: payload
        }
      };
    }

    case "LOAN_MANAGEMENT/REMOVE_SELECTED_CODE_OPTIONS": {
      const newSelectedOptions = { ...state.selectedCodeOptions };
      delete newSelectedOptions[payload];
      return {
        ...state,
        selectedCodeOptions: newSelectedOptions
      };
    }

    case "LOAN_MANAGEMENT/UPDATE_TABLE_AFTER_EDIT": {
      return {
        ...state,
        activeItem: payload.activeItem,
        data: [...payload.data]
      };
    }

    case "LOAN_MANAGEMENT/UPDATE_TABLE_AFTER_ADD": {
      return {
        ...state,
        activeItem: payload.activeItem,
        data: [...state.data, payload.data]
      };
    }

    default:
      return state;
  }
}
