import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Grid, Header, Segment } from "semantic-ui-react";
import DetailsBlock from "../DetailsBlock";
import { isBafs } from "../../../../../../services/Auth";
import { ConcatName } from "../../../../../../services/Entities";
import { Entities, EntityDebts } from "../../../../../../services/ApiLib";

function DataBlock() {
  const dispatch = useDispatch();
  const { entityData, entityInstitution } = useSelector(
    ({ CreditManagementReducer }) => CreditManagementReducer
  );
  const [visible, setVisible] = useState(true);

  const handleVisibleSwitch = bool => {
    setVisible(bool);
  };

  const readEntityDebts = useCallback(() => {
    if (entityData?.uuid) {
      EntityDebts.read({
        entityUuid: entityData?.uuid
      }).then(res => {
        if (!res) {
          return;
        }
        const direct = res?.data?.direct;
        const indirect = res?.data?.indirect;

        dispatch({
          type: "CMS_AGGREGATE_DEBT_LOAD",
          aggregateDebt: (direct || 0) + (indirect || 0)
        });
      });
    }
  }, [dispatch, entityData?.uuid]);

  const getBpUserInfo = useCallback(() => {
    if (entityData?.institution_uuid && entityData?.bp_organization_uuid) {
      Entities.getBpUserByOrgUuid(
        entityData?.institution_uuid,
        entityData?.bp_organization_uuid
      ).then(res => {
        dispatch({
          type: "CMS_BP_ORG_USER_EMAIL",
          bpOrgUserEmail: res?.data?.email || null
        });
        dispatch({
          type: "CMS_BP_ORG_USER_ACTIVE_STATUS",
          bpOrgUserActiveStatus: res?.data?.active ?? null
        });
      });
      return;
    }

    // When removing BP Org, nullify bpOrgUserEmail
    dispatch({
      type: "CMS_BP_ORG_USER_EMAIL",
      bpOrgUserEmail: null
    });
  }, [
    dispatch,
    entityData?.bp_organization_uuid,
    entityData?.institution_uuid
  ]);

  useEffect(() => {
    getBpUserInfo();
    readEntityDebts();
  }, [dispatch, getBpUserInfo, readEntityDebts]);

  return (
    <Segment>
      <Grid>
        <Grid.Row columns="2">
          <Grid.Column width="12">
            <Header as="h1">{ConcatName(entityData)}</Header>
          </Grid.Column>
          <Grid.Column textAlign="right" width="4">
            <Checkbox
              checked={visible}
              toggle
              onClick={() => handleVisibleSwitch(!visible)}
              label="Display Entity Details"
            />
            {isBafs() ? <h3>{entityInstitution?.name}</h3> : ""}
          </Grid.Column>
        </Grid.Row>
        {visible ? (
          <Grid.Row columns="1">
            <Grid.Column>
              <Segment>
                <DetailsBlock />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        ) : (
          ""
        )}
      </Grid>
    </Segment>
  );
}

export default DataBlock;
