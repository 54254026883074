import React from "react";
import { Input, Form } from "semantic-ui-react";
import { func, bool, string } from "prop-types";

const defaultProps = {
  asForm: false,
  loading: false,
  placeholder: "Search",
  fluid: false,
  label: null
};

const propTypes = {
  handleChange: func.isRequired,
  value: string.isRequired,
  asForm: bool,
  loading: bool,
  placeholder: string,
  fluid: bool,
  label: string
};

function SearchInput({
  handleChange,
  value,
  asForm,
  loading,
  placeholder,
  fluid,
  label,
  ...props
}) {
  let BaseComponent;
  if (asForm) {
    BaseComponent = Form.Input;
  } else {
    BaseComponent = Input;
  }
  return (
    <BaseComponent
      icon="search"
      label={label}
      loading={loading}
      onChange={handleChange}
      value={value}
      type="text"
      placeholder={placeholder}
      fluid={fluid}
      {...props}
    />
  );
}
SearchInput.defaultProps = defaultProps;
SearchInput.propTypes = propTypes;
export default SearchInput;
