import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Message, Form, Checkbox } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { ConfirmationModal } from "../../../../../../../components/Modals";
import { Loans } from "../../../../../../../services/ApiLib";
import logger from "../../../../../../../services/logger";
import { DollarField } from "../../../../../../../components/CustomFormElements";

function WarningDetails({ error, formFields, setFormFields, disable }) {
  return (
    <>
      {error && <Message negative content={error} />}
      <p>
        <strong>Are you sure you want to finalize this loan?</strong>
      </p>
      <Form>
        <Form.Field
          control="input"
          type="date"
          required
          data-testid="contract-date-input"
          id="contract-date-input"
          label="Contract Date"
          name="contract_date"
          value={formFields.contract_date}
          onChange={({ target }) => {
            setFormFields({
              ...formFields,
              contract_date: target.value,
              first_advance_date: ""
            });
          }}
        />
        <Form.Field
          control="input"
          type="date"
          required
          data-testid="first_advance_date"
          id="first_advance_date"
          name-="first_advance_date"
          label="First Advance Date"
          disabled={!formFields.contract_date}
          min={formFields.contract_date}
          value={formFields.first_advance_date}
          onChange={({ target }) => {
            setFormFields({
              ...formFields,
              first_advance_date: target.value
            });
          }}
        />
        <DollarField
          data-testid="first_advance_amount"
          name="first_advance_amount"
          id="first_advance_amount"
          required
          label="First Advance Amount"
          value={formFields.first_advance_amount}
          onChange={({ value }) => {
            setFormFields({
              ...formFields,
              first_advance_amount: value
            });
          }}
        />
        <Checkbox
          toggle
          name="core_of_record"
          label="Core Of Record"
          checked={formFields.core_of_record}
          readOnly={disable}
          onChange={({ target }) => {
            setFormFields({
              ...formFields,
              core_of_record: target.value
            });
          }}
        />
      </Form>
      <br />
      <p>
        Once this change is made, the loan will be marked as Finalized and
        running nightly processes / interest accruals will be available. The
        accrued through date will be set to the day before the first advance
        date, and the first advance will need to be processed manually. Are you
        sure you want to continue?
      </p>
    </>
  );
}

function FinalizationModal({ updatePhaseChange, loanRequest, handleCancel }) {
  const { institutionCoreOfRecord } = useSelector(storeState => ({
    institutionCoreOfRecord: storeState.LosPhasesReducer.institutionCoreOfRecord
  }));
  const initialState = {
    core_of_record:
      (loanRequest.core_of_record == null
        ? institutionCoreOfRecord
        : loanRequest.core_of_record) || false,
    first_advance_date: "",
    first_advance_amount: "",
    contract_date: ""
  };

  const [redirect, setRedirect] = useState(null);
  const [error, setError] = useState(null);
  const [formFields, setFormFields] = useState(initialState);
  const disable = !!(
    loanRequest.core_of_record == null && institutionCoreOfRecord
  );
  const disableSubmit =
    !formFields.first_advance_amount ||
    !formFields.first_advance_date ||
    !formFields.contract_date;
  const handleConfirm = () => {
    setError(null);
    Loans.finalize(
      () => {
        updatePhaseChange();
        setRedirect(
          <Redirect
            to={`/loan_management/${loanRequest.uuid}/details/balance`}
          />
        );
      },
      err => {
        logger.error(err);
        setError(err.error);
      },
      loanRequest.uuid,
      {
        ...formFields
      }
    );
  };

  return (
    redirect || (
      <ConfirmationModal
        open
        yesButtonLabel="Finalize Loan"
        showLogo={false}
        disabled={disableSubmit}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        prompt="Finalize Loan"
        warningDetails={
          <WarningDetails
            error={error}
            setFormFields={setFormFields}
            formFields={formFields}
            disable={disable}
          />
        }
        size="tiny"
      />
    )
  );
}

FinalizationModal.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  handleCancel: PropTypes.func.isRequired,
  updatePhaseChange: PropTypes.func.isRequired
};
WarningDetails.propTypes = {
  formFields: PropTypes.shape().isRequired,
  disable: PropTypes.bool,
  setFormFields: PropTypes.func,
  error: PropTypes.string
};

export default FinalizationModal;
