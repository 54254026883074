const parseStringAsNumber = (value, round = false) => {
  let newValue = Number.parseFloat(
    value.toString().replace(/,/g, "").replace(/%/g, "")
  );

  if (Number.isNaN(newValue)) {
    newValue = 0;
  }
  if (round) {
    newValue = Math.round(parseFloat(newValue) * 1e2) / 1e2;
  }
  return newValue;
};

export default parseStringAsNumber;
