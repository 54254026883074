import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Message, Grid } from "semantic-ui-react";
import { DateInput } from "../../../../../../../../../../components/CustomFormElements";
import { Loans } from "../../../../../../../../../../services/ApiLib";
import logger from "../../../../../../../../../../services/logger";

function FinalizeLoanModal({ modalOpen, closeModal, loan }) {
  const [accrueThroughDate, setAccrueThroughDate] = useState("");
  const [contractDate, setContractDate] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const finalizeLoan = () => {
    const body = {
      contract_date: contractDate,
      accrue_through: accrueThroughDate
    };
    Loans.finalize(
      () => {
        closeModal();
      },
      error => {
        setErrorMsg(error.error);
        logger.error(error);
      },
      loan.uuid,
      body
    );
  };
  useEffect(() => {
    setAccrueThroughDate("");
    setErrorMsg("");
  }, [modalOpen]);
  return (
    <Modal closeIcon onClose={closeModal} open={modalOpen}>
      <Modal.Header>Finalize Loan</Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <p>
                Once a loan is funded and ready to finalize, fill out the date
                to accrue interest through, and click &quot;Finalize Loan&quot;.
              </p>
              <p>
                <b>Note</b>: This cannot be undone, be sure the loan is ready to
                finalize.
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <DateInput
                name="contract_date"
                label="Contract Date"
                value={contractDate}
                onChange={event => setContractDate(event.target.value)}
                readOnly
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <DateInput
                onChange={event => setAccrueThroughDate(event.target.value)}
                name="accrue_through"
                label="Finalize then accrue through"
                value={accrueThroughDate}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {errorMsg && (
          <Message negative>
            <Message.Header>{errorMsg}</Message.Header>
          </Message>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal}>Cancel</Button>
        <Button onClick={finalizeLoan} disabled={!accrueThroughDate}>
          Finalize Loan
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

FinalizeLoanModal.propTypes = {
  loan: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    contract_date: PropTypes.string.isRequired
  }).isRequired,
  closeModal: PropTypes.func,
  modalOpen: PropTypes.bool
};

export default FinalizeLoanModal;
