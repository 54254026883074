import React from "react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";

function CharacteristicsLayout({ children }) {
  const nodes = React.Children.toArray(children);
  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column textAlign="right">{nodes[0]}</Grid.Column>
      </Grid.Row>
      <Grid.Row columns="3">
        <Grid.Column>{nodes[1]}</Grid.Column>
        <Grid.Column>{nodes[2]}</Grid.Column>
        <Grid.Column>{nodes[3]}</Grid.Column>
      </Grid.Row>
      <Grid.Row columns="1">
        <Grid.Column>{nodes.slice(4)}</Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

CharacteristicsLayout.propTypes = {
  children: PropTypes.node
};

export default CharacteristicsLayout;
