import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import FetchService, { asyncFetchService } from "../../../FetchService";
import logger from "../../../logger";
import { ConcatQueryParams } from "../Utilities";
import { FormatUrlV2 } from "../../../FormatUrl";

function add(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(callbackData, "object", false);

  const url = ApiEndpoints.baseUri + ApiEndpoints.users;
  FetchService("POST", url, onSuccess, onError, body, callbackData);
}

// get function is deprecated. New code should use async read instead.
function get(onSuccess, onError, queryParams, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(queryParams, "object", false);

  let url = ApiEndpoints.baseUri + ApiEndpoints.users;
  if (queryParams) {
    url += ConcatQueryParams(queryParams);
  }

  FetchService("GET", url, onSuccess, onError, null, callbackData);
}

async function asyncRead(filters) {
  const funcName = "====  Users.asyncRead(filters)  ====";
  logger.debug(funcName, [filters]);
  TypeCheck(filters.userUuid, "string", false);

  const filterCount = Object.keys(filters).length;
  if ((filters.userUuid && filterCount > 1) || filterCount === 0) {
    logger.debug("Error: Invalid Filter Combination");
    // TODO: Put a checkable status here.
    const errorRsp = {};
    return errorRsp;
  }

  return asyncFetchService(
    "GET",
    filters.userUuid
      ? FormatUrlV2(ApiEndpoints.userInfo, { uuid: filters.userUuid })
      : FormatUrlV2(ApiEndpoints.userInfoAll) + ConcatQueryParams(filters)
  );
}

export default { add, get, asyncRead };
