import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  Header,
  Modal,
  Segment,
  Form,
  Input
} from "semantic-ui-react";
import {
  Loans,
  Institutions,
  ParticipationServicers
} from "../../../../../../../services/ApiLib";

function ParticipationBought({ loan }) {
  const [leadInstitution, setLeadInstitution] = useState(null);
  const [parentLoan, setParentLoan] = useState(null);
  const [leadContact, setLeadContact] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [formData, setFormData] = useState({});

  useMemo(() => {
    (async () => {
      const { data: parentLoanData } = await Loans.asyncRead({
        loanUuid: loan.participation_parent_uuid
      });
      setParentLoan(parentLoanData);

      const { data: leadInstitutionData } = await Institutions.asyncRead({
        institutionUuid: parentLoanData.institution_uuid
      });
      setLeadInstitution(leadInstitutionData);

      const { data: participationServicerData } =
        await ParticipationServicers.read({
          institution_uuid: loan.institution_uuid,
          lead_institution_uuid: parentLoanData.institution_uuid
        });
      setLeadContact(
        participationServicerData.length > 0 ? participationServicerData[0] : {}
      );
    })();
  }, [loan]);

  const saveContactInfo = async () => {
    const isNewContact = !leadContact.uuid;
    let data;
    if (isNewContact) {
      ({ data } = await ParticipationServicers.create({
        institution_uuid: loan.institution_uuid,
        lead_institution_uuid: parentLoan.institution_uuid,
        primary_first_name: formData.firstName,
        primary_last_name: formData.lastName,
        primary_phone: formData.phone,
        primary_email: formData.email
      }));
    } else {
      ({ data } = await ParticipationServicers.asyncUpdate(
        {
          // TODO: get proper query
          participationUuid:
            "institut-ions-participation-servicers-0999-BAFS-TestBranch00"
        },
        {
          primary_first_name: formData.firstName,
          primary_last_name: formData.lastName,
          primary_phone: formData.phone,
          primary_email: formData.email
        }
      ));
    }
    if (data) {
      setLeadContact({
        ...data
      });
    }
    setShowEditModal(false);
  };

  return (
    <Segment>
      <Header dividing as="h2">
        Bought Loan Information
      </Header>
      <Grid>
        <Grid.Column width="7">
          <Header as="h3">Lead Institution</Header>
          {leadInstitution && leadInstitution.name}
        </Grid.Column>
        <Grid.Column width="7">
          <Header as="h3">Contact/Officer</Header>
          {leadContact.uuid ? (
            <>
              <p>
                {leadContact.primary_first_name} {leadContact.primary_last_name}
              </p>
              <p>{leadContact.primary_phone}</p>
              <p>{leadContact.primary_email}</p>
            </>
          ) : (
            <Button
              color="green"
              onClick={() => {
                setShowEditModal(true);
              }}
            >
              Add lead contact information
            </Button>
          )}
        </Grid.Column>
        <Grid.Column width="2" textAlign="right">
          {leadContact.uuid && (
            <Button
              icon="pencil"
              data-testid="contact-edit-btn"
              color="green"
              inverted
              onClick={() => {
                setFormData({
                  firstName: leadContact.primary_first_name,
                  lastName: leadContact.primary_last_name,
                  phone: leadContact.primary_phone,
                  email: leadContact.primary_email
                });
                setShowEditModal(true);
              }}
              circular
            />
          )}
        </Grid.Column>
      </Grid>
      <Modal
        onClose={() => setShowEditModal(false)}
        onOpen={() => {
          setShowEditModal(true);
        }}
        open={showEditModal}
      >
        {leadContact.uuid ? (
          <Modal.Header>Edit Contact Information</Modal.Header>
        ) : (
          <Modal.Header>Add Contact Information</Modal.Header>
        )}
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Field
                control={Input}
                label="First Name"
                name="firstName"
                id="firstName"
                value={formData.firstName || ""}
                onChange={({ target }) =>
                  setFormData({ ...formData, firstName: target.value })
                }
                type="text"
              />
              <Form.Field
                control={Input}
                label="Last Name"
                name="lastName"
                id="lastName"
                value={formData.lastName || ""}
                onChange={({ target }) =>
                  setFormData({ ...formData, lastName: target.value })
                }
                type="text"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                control={Input}
                label="Email"
                name="email"
                id="email"
                value={formData.email || ""}
                onChange={({ target }) =>
                  setFormData({ ...formData, email: target.value })
                }
                type="email"
              />
              <Form.Field
                control={Input}
                label="Phone"
                name="phone"
                id="phone"
                value={formData.phone || ""}
                onChange={({ target }) =>
                  setFormData({ ...formData, phone: target.value })
                }
                type="tel"
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setShowEditModal(false)}>Close</Button>
          <Button
            color="green"
            data-testid="submit-btn"
            onClick={() => saveContactInfo()}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    </Segment>
  );
}

ParticipationBought.propTypes = {
  loan: PropTypes.shape({
    uuid: PropTypes.string,
    institution_uuid: PropTypes.string,
    participation_bought: PropTypes.bool,
    participation_sold: PropTypes.bool,
    participation_parent_uuid: PropTypes.string
  }).isRequired
};

export default ParticipationBought;
