/* eslint-disable no-console */
/* eslint-env browser */
import { getUserGroup } from "../Auth";

const allowedLevels = {
  error: ["error"],
  warn: ["error", "warn"],
  info: ["error", "warn", "info"],
  debug: ["error", "warn", "info", "debug"],
  trace: ["error", "warn", "info", "debug", "trace"],
  all: ["error", "warn", "info", "debug", "trace", "all"],
  off: []
};

const visualPadding = {
  error: "",
  warn: "",
  info: " ",
  debug: "\n",
  trace: "\n\n",
  all: "\n\n",
  off: ""
};

const objLogger = objectsArray => {
  objectsArray.forEach(itemToLog => {
    console.log(itemToLog);
  });
};

// eslint-disable-next-line default-param-last
const log = (message, additionalData = [], loggerLevel) => {
  let currentLogLevel;
  const userGroup = getUserGroup();

  if (userGroup === "Admin") {
    currentLogLevel = window.localStorage.logLevel || "warn";
  } else if (userGroup === "BAFS") {
    currentLogLevel = window.localStorage.logLevel || "error";
  } else {
    currentLogLevel = window.localStorage.logLevel || "off";
  }

  let objectsArray = additionalData;
  if (!Array.isArray(additionalData)) {
    objectsArray = [additionalData];
  }

  let consoleLogType = loggerLevel;
  if (loggerLevel === "debug" || loggerLevel === "all") {
    consoleLogType = "log";
  }

  if (allowedLevels[currentLogLevel].includes(loggerLevel)) {
    console.log(visualPadding[loggerLevel]);
    console[consoleLogType](message);
    objLogger(objectsArray);
    console.log(visualPadding[loggerLevel]);
  }
};

const logger = {
  error: (message, additionalData) => {
    log(message, additionalData, "error");
  },
  warn: (message, additionalData) => {
    log(message, additionalData, "warn");
  },
  info: (message, additionalData) => {
    log(message, additionalData, "info");
  },
  debug: (message, additionalData) => {
    log(message, additionalData, "debug");
  },
  trace: (message, additionalData) => {
    log(message, additionalData, "trace");
  },
  all: (message, additionalData) => {
    log(message, additionalData, "all");
  }
};

export default logger;
