import React from "react";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import { Label } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { permCheck } from "../../../../../../../../../../../services/Auth";

function BorrowerEditStatusCell({ row }) {
  const asset = row?.original;
  const editableByBorrower = asset?.bpAccessControls?.editable_by_borrower;

  const { entityInstitution } = useSelector(
    ({ CreditManagementReducer }) => CreditManagementReducer
  );
  const hasBorrowerPortalService =
    entityInstitution?.borrower_portal_service || false;
  const readPerms = permCheck([
    "admin",
    "get_bp_access_controls",
    "get_bp_access_controls_unfiltered"
  ]);

  if (!hasBorrowerPortalService) {
    return null;
  }

  if (!readPerms) {
    return (
      <Label className="bg-yellow-100">
        <FontAwesome name="minus-circle" className="text-yellow-700" />
        <span
          data-test-id="BorrowerEditStatusCell-Editable"
          className="ml-2 text-yellow-700"
        >
          <FormattedMessage id="INSUFFICIENT_ACCESS" />
        </span>
      </Label>
    );
  }

  if (editableByBorrower) {
    return (
      <Label className="bg-cyan-100">
        <FontAwesome name="unlock" className="text-cyan-600" />
        <span
          data-test-id="BorrowerEditStatusCell-Editable"
          className="ml-2 text-cyan-600"
        >
          <FormattedMessage id="EDITABLE" />
        </span>
      </Label>
    );
  }

  if (editableByBorrower === false) {
    return (
      <Label className="bg-neutral-200">
        <FontAwesome name="lock" className="text-neutral-400" />
        <span
          data-test-id="BorrowerEditStatusCell-ReadOnly"
          className="ml-2 text-neutral-400"
        >
          <FormattedMessage id="READ_ONLY" />
        </span>
      </Label>
    );
  }

  return <span data-test-id="BorrowerEditStatusCell-noData" />;
}

export default BorrowerEditStatusCell;

BorrowerEditStatusCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape()
  })
};
