import { connect } from "react-redux";
import { actionCreators } from "../../reducer";
import CreditRiskReview from "./CreditRiskReview";

const mapStateToProps = state => ({
  data: state.LoanManagementReducer.data
});

const mapDispatchToProps = dispatch => ({
  onCreateNewCreditReview: () =>
    dispatch(actionCreators.createNewCreditReview()),
  onViewCreditRiskReview: loanUuid => {
    dispatch(actionCreators.loadCreditRiskReviewForLoan(loanUuid));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditRiskReview);
