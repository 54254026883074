import loanPaymentSchedules from "./loan/payment-schedules";
import loanInterestSchedules from "./loan/interest-schedules";
import fees from "./loan/fees";
import govtGuarFees from "./loan/govt-guar-fees";
import acctHistoryLogs from "./loan/account-history-logs";
import loanApplications from "./loan-application";
import spreads from "./cms/spreads";
import models from "./cms/rs/financial-spreads-models";
import financialAnalysisReport from "./cms/rs/financial-analysis-report";

// API Services here that are compatible with the API Service Middleware
export default {
  loanPaymentSchedules,
  loanInterestSchedules,
  fees,
  govtGuarFees,
  acctHistoryLogs,
  loanApplications,
  spreads,
  models,
  financialAnalysisReport
};
