import React, { useEffect, useRef, useState } from "react";
import {
  useInterestSchedulesQuery,
  InterestScheduleQueryOptions,
  InterestScheduleParameters
} from "../../../../../../services/RTKQuery/interestSchedules";

export const ReadManyTest = ({
  scheduleUuid,
  loanUuid,
  institutionUuid,
  setStatusInParent
}) => {
  const statusUnknown = "❓Unknown Status";
  const statusPass = "✅ Pass";
  const statusFail = "❌ Fail";

  const testAlreadyPassedRef = useRef(false);
  const [statusReadMany, setStatusReadMany] = useState(statusUnknown);
  const [detailsText, setDetailsText] = useState("");

  const filters: InterestScheduleQueryOptions = {
    institution_uuid: institutionUuid,
    loan_uuid: loanUuid,
    page_size: 999999
  };
  const params: InterestScheduleParameters = { loanUuid, filters };
  const { data: interestSchedulesApiRsp } = useInterestSchedulesQuery(params);
  useEffect(() => {
    // This shouldn't be needed, but if it's removed, the test will
    // rerun after the DELETE test is run and then fail.
    if (testAlreadyPassedRef.current) {
      return;
    }
    let createdRecord;
    const listOfSchedules = interestSchedulesApiRsp?.data;
    if (listOfSchedules && listOfSchedules.length > 0) {
      for (const record of listOfSchedules) {
        if (record.uuid === scheduleUuid) {
          createdRecord = record;
          break;
        }
      }
    }
    // These strings are repeated in two tests. They can get out of sync and cause a test to fail.
    const newRateIndex = "1 Yr CMT";
    const newRate = 0.07614;
    const isRecordUpdated =
      createdRecord?.rate_index === newRateIndex &&
      createdRecord?.interest_rate === newRate;
    if (createdRecord && isRecordUpdated) {
      setStatusInParent("Pass");
      setDetailsText(
        `: rate_index and interest_rate match updated value in record ${createdRecord.uuid}`
      );
      testAlreadyPassedRef.current = true;
      setStatusReadMany(statusPass);
    } else {
      console.error(
        "createdRecord && isRecordUpdated check failed. See interestSchedulesApiRsp: ",
        interestSchedulesApiRsp
      );
      setStatusReadMany(statusFail);
      if (interestSchedulesApiRsp?.metaData?.last_page === false) {
        setDetailsText(
          ": Not all records in database considered during this test."
        );
        console.warn(
          "Not all records in database considered during this test."
        );
      }
      testAlreadyPassedRef.current = false;
      console.error("Fail: useInterestSchedulesQuery");
    }
  }, [interestSchedulesApiRsp, scheduleUuid, setStatusInParent]);
  return (
    <>
      <b>{statusReadMany}</b>
      <small>{detailsText}</small>
    </>
  );
};
