import styled from "@emotion/styled";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1.5rem;
`;
const DisclaimerContainer = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
`;

const DisclaimerText = styled.p`
  font-size: 1em;
  color: #000000d9;
`;

export { Wrapper, DisclaimerContainer, DisclaimerText };
