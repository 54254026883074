import React from "react";
import { Field } from "../../../fomantic/collections/form/field/field";
import { MessageProps } from "../../../types/text";

/**
 * Provides a multi-line form field.
 * It uses Field as a wrapper and it can take all the textarea element props
 */
export function TextArea({
  labelMessage,
  labelClasses,
  name,
  value = "",
  required,
  onChange,
  error,
  ...props
}: TextAreaProps) {
  return (
    <Field
      labelMessage={{ ...labelMessage }}
      labelClasses={labelClasses}
      required={required}
    >
      <textarea
        name={name}
        onChange={onChange}
        {...props}
        defaultValue={value}
        id={`${name}-text-area-input`}
      />
    </Field>
  );
}

/**
 * Interface for the TextArea props
 */
export interface TextAreaProps
  extends React.ComponentPropsWithoutRef<"textarea"> {
  /** Used to build the textarea label display text */
  labelMessage: MessageProps;
  /** Defines the textarea name to be referenced in the form data and to create the id of the element */
  labelClasses?: string;
  name: Required<React.TextareaHTMLAttributes<HTMLTextAreaElement>>["name"];
  /** Function that executes when the onChange event is triggered */
  onChange: Required<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>
  >["onChange"];
  /** If an error string is passed by, the form will display the error class in the input */
  error?: string;
}
