import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";

const tableHeaders = [
  "Tickler Id",
  "Uploaded Date",
  "Uploaded By",
  "Approved Date",
  "Approved By"
];

function TicklerTable(props) {
  const headers = tableHeaders.map(header => (
    <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
  ));

  const tableRows = props.ticklers.map(tickler => (
    <Table.Row key={tickler.uuid}>
      <Table.Cell>{tickler.id}</Table.Cell>
      <Table.Cell>{props.uploadDates[tickler.objectUuid]}</Table.Cell>
      <Table.Cell>
        {props.users[props.uploadByUuids[tickler.objectUuid]]}
      </Table.Cell>
      <Table.Cell>{tickler.approvedDate}</Table.Cell>
      <Table.Cell>{props.users[tickler.approvedBy]}</Table.Cell>
    </Table.Row>
  ));

  return (
    <Table compact celled selectable>
      <Table.Header>
        <Table.Row>{headers}</Table.Row>
      </Table.Header>
      <Table.Body>{tableRows}</Table.Body>
    </Table>
  );
}

TicklerTable.propTypes = {
  ticklers: PropTypes.arrayOf(PropTypes.object).isRequired,
  uploadByUuids: PropTypes.objectOf(PropTypes.string).isRequired,
  uploadDates: PropTypes.objectOf(PropTypes.string).isRequired,
  users: PropTypes.objectOf(PropTypes.string).isRequired
};

export default TicklerTable;
