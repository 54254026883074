import TypeCheck from "typecheck-extended";
import ApiEndpoints from "../../../ApiEndpoints";
import { asyncFetchService } from "../../../FetchService";
import { FormatUrlV2 } from "../../../FormatUrl";
import { RemoveNullKeys } from "../Utilities";

async function asyncUpdate(filters, body) {
  TypeCheck(filters.loanUuid, "string", true);
  TypeCheck(filters.itemUuid, "string", true);

  const url = FormatUrlV2(ApiEndpoints.settlementSheetById, {
    loan_uuid: filters.loanUuid,
    item_uuid: filters.itemUuid
  });
  const convertedBody = RemoveNullKeys(body);

  const rsp = await asyncFetchService("PUT", url, convertedBody);
  return rsp;
}

async function asyncAdd(filters, body) {
  TypeCheck(filters.loanUuid, "string", true);

  const url = FormatUrlV2(ApiEndpoints.settlementSheet, {
    loan_uuid: filters.loanUuid
  });
  const convertedBody = RemoveNullKeys(body);

  const rsp = await asyncFetchService("POST", url, convertedBody);
  return rsp;
}

async function asyncGet(filters) {
  TypeCheck(filters.loanUuid, "string", true);

  const url = FormatUrlV2(ApiEndpoints.settlementSheet, {
    loan_uuid: filters.loanUuid
  });
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

async function asyncRemove(filters) {
  TypeCheck(filters.loanUuid, "string", true);
  TypeCheck(filters.itemUuid, "string", true);

  const url = FormatUrlV2(ApiEndpoints.settlementSheetById, {
    loan_uuid: filters.loanUuid,
    item_uuid: filters.itemUuid
  });
  const rsp = await asyncFetchService("DELETE", url);
  return rsp;
}

export default {
  asyncGet,
  asyncAdd,
  asyncUpdate,
  asyncRemove
};
