import { Icon } from "semantic-ui-react";
import { Tooltip } from "@bafsllc/ui-shared";

export default function CreditMemoTooltip() {
  const text =
    "Please select a credit memo template, then select an analysis for each entity. After all entities have a required selection, the PDF Download will enable.";
  return (
    <Tooltip className="text-sm text-slate-900" content={text}>
      <Icon name="info" className="hover:cursor-help" /> Help with selecting a
      credit memo template.
    </Tooltip>
  );
}
