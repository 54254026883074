import React from "react";
import { PropTypes } from "prop-types";
import { Button, Loader, Label, Icon } from "semantic-ui-react";
import { HighFrictionDialog } from "@bafsllc/ui-shared";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useBPLinksContext } from "../bp-links-context";
import { ConcatName } from "../../../../../../../../../services/Entities";
import { Entities } from "../../../../../../../../../services/ApiLib";
import { environment } from "../../../../../../../../../environments/environment";

const clientId = environment.auth0ClientId;

export default function BorrowerPortalLinksSideSheetHeader({
  onClose,
  onDismiss,
  isConfirmationModalClose,
  setConfirmationModal
}) {
  const { entityData, bpOrgUserActiveStatus, bpOrgUserEmail } = useSelector(
    ({ CreditManagementReducer }) => CreditManagementReducer
  );
  const {
    updateUserFeedbackKey,
    institutionData,
    bpOrgUuIdToLink,
    updateIsLoading,
    isLoading,
    linkButtonDisabled,
    updateLinkButtonDisabled
  } = useBPLinksContext(entityData);
  const linkOrResendMsgId =
    bpOrgUserActiveStatus === false ? "RESEND_INVITE" : "LINK";
  const resendInvite = linkOrResendMsgId === "RESEND_INVITE";
  const auth0OrgId = institutionData?.auth0_org_id;
  const showPendingLabel = bpOrgUserActiveStatus === false;
  const resendOrConfirm = bpOrgUserActiveStatus !== null;
  const resendInviteData = {
    data: {
      type: "auth0_organization",
      attributes: {
        clientId,
        inviter: ConcatName(entityData),
        invitee: bpOrgUserEmail,
        userMetadata: {
          auth0_org_id: auth0OrgId
        },
        appMetadata: {
          deleted: false
        },
        organizationId: auth0OrgId
      }
    }
  };

  const entityName = ConcatName(entityData);

  const modalMessageIdArgs = bpOrgUuIdToLink
    ? {
        id: "LINK_TO_EXISTING_BP_ORG",
        values: {
          entityName
        }
      }
    : {
        id: "BP_ORGANIZATION_EMAIL_CONFIRMATION_MESSAGE"
      };

  const modalHeaderMsgId = bpOrgUuIdToLink
    ? "LINK_TO_BORROWER_PORTAL_ORGANIZATION"
    : "BP_ORGANIZATION";

  const getActionIcon = () => {
    if (isLoading) {
      return (
        <span style={{ minWidth: "23px" }}>
          <Loader
            active
            className="mr-2"
            inline
            size="tiny"
            data-testid="loader"
            style={{
              color: "white",
              marginRight: "0.5rem"
            }}
          />
        </span>
      );
    }
    if (resendInvite) {
      return (
        <span style={{ minWidth: "23px" }}>
          <Icon name="envelope" data-testid="envelope-icon" />
        </span>
      );
    }
    return null;
  };

  if (bpOrgUuIdToLink || resendInvite) updateLinkButtonDisabled(false);

  return (
    <div className="ui grid">
      <div className="row sixteen wide large screen">
        <div className="eight wide column">
          <div className="setup-text">
            <FormattedMessage id="SETUP" />
          </div>
          <div className="bp-link-text">
            <FormattedMessage id="BORROWER_PORTAL_LINK" />{" "}
            {showPendingLabel && (
              <Label color="yellow" size="medium" basic>
                <Icon name="envelope" color="grey" />
                Pending
              </Label>
            )}
          </div>
          <div className="entity-info-text">
            {`${ConcatName(entityData)} | ${entityData?.entity_type}`}
          </div>
        </div>
        <div className="eight wide column link-buttons">
          <Button primary onClick={onClose} basic>
            Cancel
          </Button>
          <Button
            onClick={async () => {
              updateIsLoading(true);
              if (resendOrConfirm) {
                await Entities.sendBpUserSignupEmail(
                  entityData.uuid,
                  resendInviteData
                );
                onDismiss("reinvite");
              } else {
                updateUserFeedbackKey("emailEligibleInfo");
                setConfirmationModal(false);
              }
            }}
            disabled={linkButtonDisabled}
          >
            <span className="flex items-center">
              {getActionIcon()}
              <FormattedMessage id={linkOrResendMsgId} />
            </span>
          </Button>
          {!isConfirmationModalClose && (
            <HighFrictionDialog
              affirmative={{ id: "CONFIRM" }}
              negative={{ id: "CANCEL" }}
              confirmationMessages={[
                { message: { ...modalMessageIdArgs } },
                { message: { id: "FRICTION_DIALOG_ENTER_MESSAGE" } }
              ]}
              onHidden={() => {}}
              headerTitle={{ id: modalHeaderMsgId }}
              onDismiss={onDismiss}
              compareFunction={(input, confirmationValue) =>
                input === confirmationValue
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}

BorrowerPortalLinksSideSheetHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  isConfirmationModalClose: PropTypes.bool.isRequired,
  setConfirmationModal: PropTypes.func.isRequired
};
