import React from "react";
import { Message } from "../../../custom/elements/message/message";
import { Tooltip } from "../../../custom/elements/Tooltip";
import type { SidebarItem, SidebarItemInternal } from "./sidebar-types";

/**
 * Renders the display of a Sidebar list item.
 * @param props
 */
export function SidebarListItemContent({
  onGetAnchorElement,
  onItemSelect,
  ...props
}: SidebarListItemContentProps) {
  const {
    routes = [],
    href,
    icon,
    message,
    submenu,
    submenuItem,
    expanded
  } = props;

  const liClassNames = ["item"];
  const pathname = window?.location?.pathname || "";
  const pathnameArray = pathname.split("/");

  if (
    href === pathname ||
    (pathnameArray.length > 1 && routes?.includes(pathnameArray[1]))
  ) {
    liClassNames.push("active");
  }

  submenuItem && liClassNames.push("submenu");
  submenuItem && submenu?.expanded && liClassNames.push("show");

  const anchorElement = (onGetAnchorElement && onGetAnchorElement(href)) ?? (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a href={href} />
  );

  const handleClick: React.MouseEventHandler = evt => onItemSelect(evt, props);

  const itemTextClassnames = ["item-text"];
  submenu && itemTextClassnames.push("submenu");

  return (
    <Tooltip
      contentClassName="text-lg font-semibold text-slate-600"
      position="right"
      content={<Message {...message} />}
      disabled={expanded}
    >
      <li className={liClassNames.join(" ")}>
        {React.cloneElement(
          anchorElement,
          { className: "item-content", onClick: handleClick }, // Add a click handler, there is probably special handling that needs to be done when the item is clicked.
          <>
            <span className="icon">
              {icon ? <i className={`icon ${icon}`}></i> : null}
            </span>
            <span className={itemTextClassnames.join(" ")}>
              <Message {...message} />
            </span>
            {submenu && !submenuItem ? ( // Menu items that have a submenu - but are NOT a submenu item - get a caret to indicate they have a submenu and the direction indicates the expanded state of the submenu.
              <i
                className={`submenu icon dropdown${
                  submenu.expanded ? " expanded" : ""
                }`}
              />
            ) : null}
          </>
        )}
      </li>
    </Tooltip>
  );
}

export interface SidebarListItemContentProps
  extends Omit<SidebarItemInternal, "submenu"> {
  /** A `key` is required. */
  key: React.Key;
  /** When the user selects a menu item via click or keyboard this event will be
   * raised. If you want to prevent the click from navigating the browser use
   * `preventDefault()`. */
  onItemSelect: (evt: React.SyntheticEvent, item: SidebarItemInternal) => void;
  /**
   * By default each item in the menu will have an `<a>` tag for navigation. To
   * provide a different component (for example a Router `<Link>` component)
   * provide a handler function for onGetAnchorElement which will be invoked for
   * each entry in `allMenuItems`. To use the default `<a>` anchor for an item
   * return `null` from onGetAnchorElement.
   */
  onGetAnchorElement?: (href: SidebarItem["href"]) => JSX.Element | null;
  /** Information about the state of a submenu. */
  submenu?: {
    /** If true the caret icon will point up, if false down. */
    expanded?: boolean;
  };

  /** If true the menu is open. */
  expanded?: boolean;
}
