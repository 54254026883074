import React, { Component } from "react";
import PropTypes from "prop-types";
import TypeCheck from "typecheck-extended";

/*
  ==================================================================================================
  Right now sending a large list to a SemanticUI Select causes significant browser performance
  issues. This component solves the performance issue, but should be updated to allow search AND
  scroll while still remaining performant.

  Don't use this unless you are ok with it eventually changing to the above stated functionality.
  ==================================================================================================
*/

export function generateOptions(options) {
  TypeCheck(options, "object");

  const htmlOptions = [];
  Object.keys(options).forEach(key => {
    htmlOptions.push(
      <option key={key} value={key}>
        {`${key} - ${options[key]}`}
      </option>
    );
  });
  htmlOptions.unshift(<option key="nothing_selected" value="" />);

  return htmlOptions;
}

export default class LongSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.convertToSemanticOnChange = this.convertToSemanticOnChange.bind(this);
  }

  convertToSemanticOnChange(event) {
    TypeCheck(event.target.value, "string");
    TypeCheck(event.target.id, "string");

    const data = {
      name: event.target.id,
      value: event.target.value
    };
    const { onChange } = this.props;
    onChange(event, data);
  }

  render() {
    const { label, name, options, required, validationError, value } =
      this.props;

    const divStyle = { padding: "0 0 0 0.5em" };
    const labelStyle = {
      fontSize: "0.92857143em",
      fontWeight: "bold"
    };
    const selectStyle = {
      height: "38px",
      margin: "24px, 5px, 1px, 5px !important",
      padding: "0.678571em, 1em, 0.678571em, 1em"
    };
    const errorStyle = {
      ...selectStyle,
      background: "#FFF6F6",
      borderColor: "#E0B4B4",
      color: "#9F3A38"
    };
    const requiredMarker = required ? (
      <span style={{ color: "#D24F15" }}> *</span>
    ) : (
      ""
    );
    return (
      <div style={divStyle}>
        <label style={labelStyle} htmlFor={name}>
          {label}
          {requiredMarker}
        </label>
        <select
          id={name}
          name={name}
          onChange={e => this.convertToSemanticOnChange(e)}
          style={validationError ? errorStyle : selectStyle}
          value={value}
        >
          {generateOptions(options)}
        </select>
      </div>
    );
  }
}

LongSelect.defaultProps = {
  required: false,
  validationError: false
};

LongSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.objectOf(PropTypes.string).isRequired,
  required: PropTypes.bool,
  validationError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.string.isRequired
};
