import { createActions } from "redux-actions";
import {
  Cms,
  Entities,
  Relationships,
  Spreads,
  ObjectMetadata,
  EntityAnalyses,
  EntityRelationships,
  Institutions
} from "../../../../../../services/ApiLib";
import { getToken } from "../../../../../../services/Auth";

export const initialState = {
  creditNarrativeTemplates: [],
  creditMemoEntityRelationships: {
    Borrower: [],
    Owner: [],
    Officer: [],
    "Other Capacity": [],
    Affiliate: [],
    "Holdings & Subsidiary": [],
    Guarantor: [],
    "Third Party Guarantor": []
  },
  creditMemoFinancialAnalysis: [],
  creditMemoObjectMetadata: {},
  entityAnalyses: {},
  institution: {}
};

function removeDuplicates(arr) {
  return arr.reduce((acc, curr) => {
    if (!curr?.uuid || acc.some(single => single.uuid === curr.uuid)) {
      return acc;
    }

    acc.push(curr);
    return acc;
  }, []);
}

export const actionCreators = createActions(
  {
    getCreditNarrativeTemplates: institutionUuid => async dispatch => {
      const res = await Cms.readCreditNarrativeByInstitution(institutionUuid);
      dispatch(actionCreators.setCreditNarrativeTemplates(res.rows));
    },
    getEntityRelationships:
      (institutionUuid, loanAppUuid, loanRequestUuid) => async dispatch => {
        const [
          { data: borrowerRelationships },
          { data: guarantorRelationships }
        ] = await Promise.all([
          Relationships.asyncRead({
            parent_uuid: loanAppUuid
          }),
          Relationships.asyncRead({
            parent_uuid: loanRequestUuid,
            rel_type: "Guarantor"
          })
        ]);

        const [
          borrowersResp,
          ownersResp,
          officersResp,
          otherCapacitiesResp,
          affiliatesResp,
          holdingsSubsidiariesResp,
          guarantorsResp
        ] = await Promise.all([
          new Promise((resolve, reject) => {
            Entities.get(
              getToken(),
              res => {
                resolve(res.data);
              },
              err => {
                reject(err);
              },
              null,
              {
                uuidList: borrowerRelationships.map(
                  relationship => relationship.child_uuid
                )
              }
            );
          }),
          Promise.all(
            borrowerRelationships.map(relationship =>
              EntityRelationships.asyncRead(relationship.child_uuid, "Owner")
            )
          ),
          Promise.all(
            borrowerRelationships.map(relationship =>
              EntityRelationships.asyncRead(relationship.child_uuid, "Officer")
            )
          ),
          Promise.all(
            borrowerRelationships.map(relationship =>
              EntityRelationships.asyncRead(
                relationship.child_uuid,
                "Other Capacities"
              )
            )
          ),
          Promise.all(
            borrowerRelationships.map(relationship =>
              EntityRelationships.asyncRead(
                relationship.child_uuid,
                "Affiliate"
              )
            )
          ),
          Promise.all(
            borrowerRelationships.map(async relationship => {
              const holdingsRelationships = await Relationships.asyncRead({
                child_uuid: relationship.child_uuid,
                rel_type: "Owner"
              });
              let holdingsSubsidiaries = [];

              if (holdingsRelationships.data?.length) {
                holdingsSubsidiaries = await new Promise((resolve, reject) => {
                  Entities.get(
                    getToken(),
                    res => {
                      resolve(res.data);
                    },
                    err => {
                      reject(err);
                    },
                    null,
                    {
                      uuidList: holdingsRelationships.data.map(
                        holdingsRelationship => holdingsRelationship.parent_uuid
                      )
                    }
                  );
                });
              }
              return holdingsSubsidiaries;
            })
          ),
          new Promise((resolve, reject) => {
            if (guarantorRelationships.length) {
              Entities.get(
                getToken(),
                res => {
                  resolve(res.data);
                },
                err => {
                  reject(err);
                },
                null,
                {
                  uuidList: guarantorRelationships.map(
                    relationship => relationship.child_uuid
                  )
                }
              );
            } else {
              resolve([]);
            }
          })
        ]);

        const borrowers = removeDuplicates(borrowersResp);
        const owners = removeDuplicates(
          ownersResp.reduce((acc, singleOwnersResp) => {
            acc.push(
              ...singleOwnersResp.data.flatMap(
                ownerData => ownerData.owner_entity_data
              )
            );
            return acc;
          }, [])
        );
        const officers = removeDuplicates(
          officersResp.reduce((acc, singleOfficersResp) => {
            acc.push(
              ...singleOfficersResp.data.flatMap(
                officerData => officerData.officer_entity_data
              )
            );
            return acc;
          }, [])
        );
        const otherCapacities = removeDuplicates(
          otherCapacitiesResp.reduce((acc, singleOtherCapacitiesResp) => {
            acc.push(
              ...singleOtherCapacitiesResp.data.flatMap(
                otherCapacityData => otherCapacityData.entity_data
              )
            );
            return acc;
          }, [])
        );
        const affiliates = removeDuplicates(
          affiliatesResp.reduce((acc, singleAffiliatesResp) => {
            acc.push(
              ...singleAffiliatesResp.data.flatMap(
                affiliateData => affiliateData.affiliate_data
              )
            );
            return acc;
          }, [])
        );
        const holdingsSubsidiaries = removeDuplicates(
          holdingsSubsidiariesResp.reduce((acc, holdings) => {
            acc.push(...holdings);
            return acc;
          }, [])
        );
        const guarantors = removeDuplicates(guarantorsResp);

        const ownerGuarantors = guarantors.filter(guarantor =>
          owners.find(owner => owner.uuid === guarantor.uuid)
        );
        const thirdPartyGuarantors = guarantors.filter(
          guarantor =>
            !ownerGuarantors.some(
              ownerGuarantor => ownerGuarantor.uuid === guarantor.uuid
            )
        );

        const [guarantorAffiliatesResp, guarantorHoldingsSubsidiariesResp] =
          await Promise.all([
            Promise.all(
              guarantors.map(guarantor =>
                EntityRelationships.asyncRead(guarantor.uuid, "Affiliate")
              )
            ),
            Promise.all(
              thirdPartyGuarantors.map(async guarantor => {
                const guarantorHoldingsSubsidiariesRelationships =
                  await Relationships.asyncRead({
                    child_uuid: guarantor.uuid,
                    rel_type: "Owner"
                  });
                let guarantorHoldingsSubsidiaries = [];

                if (guarantorHoldingsSubsidiariesRelationships.data?.length) {
                  guarantorHoldingsSubsidiaries = await new Promise(
                    (resolve, reject) => {
                      Entities.get(
                        getToken(),
                        res => {
                          resolve(res.data);
                        },
                        err => {
                          reject(err);
                        },
                        null,
                        {
                          uuidList:
                            guarantorHoldingsSubsidiariesRelationships.data.map(
                              holdingsRelationship =>
                                holdingsRelationship.parent_uuid
                            )
                        }
                      );
                    }
                  );
                }
                return guarantorHoldingsSubsidiaries;
              })
            )
          ]);

        const guarantorAffiliates = removeDuplicates(
          guarantorAffiliatesResp.reduce(
            (acc, singleGuarantorAffiliatesResp) => {
              acc.push(
                ...singleGuarantorAffiliatesResp.data.flatMap(
                  guarantorAffiliateData =>
                    guarantorAffiliateData.affiliate_data
                )
              );
              return acc;
            },
            []
          )
        );
        const guarantorHoldingsSubsidiaries = removeDuplicates(
          guarantorHoldingsSubsidiariesResp.reduce(
            (acc, singleGuarantorHoldingsSubsidiariesResp) => {
              acc.push(...singleGuarantorHoldingsSubsidiariesResp);
              return acc;
            },
            []
          )
        );

        const allAffiliates = removeDuplicates([
          ...affiliates,
          ...guarantorAffiliates
        ]);
        const allHoldingsSubsidiaries = removeDuplicates([
          ...holdingsSubsidiaries,
          ...guarantorHoldingsSubsidiaries
        ]);

        dispatch(
          actionCreators.setCreditMemoEntityRelationships({
            Borrower: borrowers,
            Owner: owners,
            Officer: officers,
            "Other Capacity": otherCapacities,
            Affiliate: allAffiliates,
            "Holdings & Subsidiary": allHoldingsSubsidiaries,
            Guarantor: ownerGuarantors,
            "Third Party Guarantor": thirdPartyGuarantors
          })
        );

        const entityUuids = removeDuplicates([
          ...borrowers,
          ...owners,
          ...officers,
          ...otherCapacities,
          ...allAffiliates,
          ...allHoldingsSubsidiaries,
          ...guarantors
        ]).map(entity => entity.uuid);

        dispatch(
          actionCreators.getFinancialAnalysis(entityUuids, institutionUuid)
        );
      },
    getFinancialAnalysis: (entityUuids, institutionUuid) => async dispatch => {
      await new Promise(resolve => {
        Spreads.readManyEntities(
          getToken(),
          res => {
            if (res?.rows?.length) {
              dispatch(actionCreators.setCreditMemoFinancialAnalysis(res.rows));
            }
            resolve();
          },
          () => {
            resolve();
          },
          entityUuids,
          institutionUuid
        );
      });
    },
    getObjectMetadata: (objectUuid, params) => async dispatch => {
      const res = await ObjectMetadata.asyncRead(objectUuid, params);
      dispatch(actionCreators.setCreditMemoObjectMetadata(res.data));
    },
    getEntityAnalyses: filters => async dispatch => {
      const res = await EntityAnalyses.asyncRead(filters);
      if (res.count) {
        dispatch(actionCreators.setEntityAnalyses(res.rows[0]));
      } else {
        dispatch(actionCreators.createEntityAnalyses(filters));
      }
    },
    createEntityAnalyses: body => async dispatch => {
      const res = await EntityAnalyses.asyncAdd(body);
      dispatch(actionCreators.setEntityAnalyses(res));
    },
    updateEntityAnalyses: (uuid, body) => async dispatch => {
      const res = await EntityAnalyses.asyncUpdate(uuid, body);
      dispatch(actionCreators.setEntityAnalyses(res));
    },
    loadInstitution: uuid => dispatch => {
      Institutions.asyncRead({ institutionUuid: uuid })
        .then(({ data }) => dispatch(actionCreators.populateInstitution(data)))
        .catch(({ error }) => dispatch(actionCreators.institutionError(error)));
    }
  },
  "SET_CREDIT_NARRATIVE_TEMPLATES",
  "SET_CREDIT_MEMO_ENTITY_RELATIONSHIPS",
  "SET_CREDIT_MEMO_FINANCIAL_ANALYSIS",
  "SET_ENTITY_ANALYSIS_TABLE",
  "SET_CREDIT_MEMO_OBJECT_METADATA",
  "SET_ENTITY_ANALYSES",
  "POPULATE_INSTITUTION",
  "INSTITUTION_ERROR"
);

// eslint-disable-next-line default-param-last
export default function CreditMemoReducer(state = initialState, action) {
  switch (action.type) {
    case "POPULATE_INSTITUTION": {
      return { ...state, institution: action.payload };
    }
    case "INSTITUTION_ERROR": {
      return { ...state, institution: null, institutionError: action.payload };
    }
    case "SET_CREDIT_NARRATIVE_TEMPLATES":
      return {
        ...state,
        creditNarrativeTemplates: action.payload
      };
    case "SET_CREDIT_MEMO_ENTITY_RELATIONSHIPS":
      return {
        ...state,
        creditMemoEntityRelationships: action.payload
      };
    case "SET_CREDIT_MEMO_FINANCIAL_ANALYSIS":
      return {
        ...state,
        creditMemoFinancialAnalysis: action.payload
      };
    case "SET_CREDIT_MEMO_OBJECT_METADATA":
      return {
        ...state,
        creditMemoObjectMetadata: action.payload
      };
    case "SET_ENTITY_ANALYSES":
      return {
        ...state,
        entityAnalyses: action.payload
      };
    default:
      return state;
  }
}
