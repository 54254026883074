import TypeCheck from "typecheck-extended";
import ApiEndpoints from "../../../ApiEndpoints";
import FetchService, { asyncFetchService } from "../../../FetchService";
import { FormatUrlV2 } from "../../../FormatUrl";
import logger from "../../../logger";

function add() {
  logger.error("TODO: Spreads.add() not setup.");
}

function get() {
  logger.error("TODO: Spreads.get() not setup.");
}

async function asyncRead(uuids) {
  TypeCheck(uuids, "object", false);
  const params = "?page_size=500";
  const url = FormatUrlV2(ApiEndpoints.spreadsPeriodDatav2, uuids) + params;
  return asyncFetchService("GET", url);
}

function update(jwt, onSuccess, onError, entityUuid, periodUuid, periodData) {
  TypeCheck(jwt, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(entityUuid, "string");
  TypeCheck(periodUuid, "string");
  TypeCheck(periodData, "object");

  const uri = FormatUrlV2(ApiEndpoints.spreadsPeriodDatumv2, {
    filter: `entityUuid=${entityUuid}`,
    periodUuid
  });
  FetchService("PUT", uri, onSuccess, onError, periodData);
}

function readManyEntities(
  jwt,
  onSuccess,
  onError,
  entityUuids,
  institutionUuid
) {
  TypeCheck(jwt, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(entityUuids, "array");
  TypeCheck(institutionUuid, "string");

  // Echo-5170: This is counter-intuitive but we were getting HTTP 431 when sending long lists of
  // entity IDs and fetch does not allow GET with a a body
  FetchService(
    "PATCH",
    `${FormatUrlV2(
      ApiEndpoints.spreadsv2
    )}/get?institutionUuid=${institutionUuid}&pageNumber=1&pageSize=999&filter=archived=false&fields=uuid,entityUuid,financialAnalysisCombinedReportDocumentUuid`,
    onSuccess,
    onError,
    { entityUuids }
  );
}

export default {
  add,
  get,
  update,
  asyncRead,
  readManyEntities
};
