import {
  relationshipTypesData,
  relationshipParentChildTypesData
} from "./RelationshipTypesData";

export const relationshipTypes = {};
export const relationshipParentChildTypes = {};

relationshipTypesData.forEach(typeString => {
  relationshipTypes[
    typeString.toUpperCase().replace(/ /g, "_").replace("-", "")
  ] = typeString;
});

relationshipParentChildTypesData.forEach(typeString => {
  relationshipParentChildTypes[
    typeString.toUpperCase().replace(/ /g, "_").replace("-", "")
  ] = typeString;
});

export default { relationshipTypes, relationshipParentChildTypes };
