import createAPIService from "../../../../FetchService/createAPIService";
import APIEndpoints from "../../../../ApiEndpoints";
import { getBlastAPIBaseUrl } from "../../../../../components/BlastEmbed/getBaseUrl";

const baseUri = getBlastAPIBaseUrl();

const service = createAPIService({
  baseUrl: baseUri,
  retrieve: [APIEndpoints.cmsSpreadsModelsv2]
});

export default service;
