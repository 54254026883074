import React from "react";
import PropTypes from "prop-types";
import { Form, Grid, Dropdown } from "semantic-ui-react";
import {
  DateInput,
  DollarField
} from "../../../../../../components/CustomFormElements";
import { EditModal } from "../../../../../../components/Modals";
import { displayAndBorrowerFeeType } from "../../../../../../services/Constants/additionalFeeOptions";

const defaultSettlementItem = {
  reason_charges_and_fees: displayAndBorrowerFeeType[0].value
};

function AddFeesChargesModal({
  defaultValues = defaultSettlementItem,
  header = "",
  ...props
}) {
  return (
    <EditModal
      header={header}
      size="small"
      defaultValues={defaultValues}
      required={{
        amount: {},
        disbursement_date: {},
        reason_charges_and_fees: {
          condition: "Other",
          required: ["reason_other"]
        }
      }}
      {...props}
    >
      {({ onChange, values }) => {
        const isOther = values.reason_charges_and_fees === "Other";
        return (
          <Grid padded basic="true">
            <Grid.Row>
              <Grid.Column>
                <DateInput
                  asForm
                  label="Disbursement Date"
                  name="disbursement_date"
                  onChange={(ev, { name, value }) => onChange({ name, value })}
                  value={values.disbursement_date}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <DollarField
                  label="Disbursement Amount"
                  name="amount"
                  onChange={onChange}
                  value={values.amount}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form.Input
                  label="Paid To"
                  name="paid_to"
                  value={values.paid_to || ""}
                  onChange={(ev, { name, value }) => onChange({ name, value })}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form.Field
                  control={Dropdown}
                  label="Charge and Fees Reasons"
                  name="reason_charges_and_fees"
                  options={displayAndBorrowerFeeType}
                  value={values.reason_charges_and_fees}
                  selection
                  onChange={(ev, { name, value }) => onChange({ name, value })}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form.Input
                  label="Other Reason"
                  name="reason_other"
                  disabled={!isOther}
                  value={values.reason_other || ""}
                  onChange={(ev, { name, value }) => onChange({ name, value })}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form.Checkbox
                  label="Paid Outside of Closing"
                  name="paid_outside_of_closing"
                  onChange={(e, { name, checked }) =>
                    onChange({ name, value: checked })
                  }
                  checked={values.paid_outside_of_closing}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form.Checkbox
                  label="Include on Loan Docs"
                  name="include_on_loan_docs"
                  onChange={(e, { name, checked }) =>
                    onChange({ name, value: checked })
                  }
                  checked={values.include_on_loan_docs}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        );
      }}
    </EditModal>
  );
}

AddFeesChargesModal.propTypes = {
  defaultValues: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ),
  header: PropTypes.string
};

export default AddFeesChargesModal;
