import React, { useEffect, useState } from "react";
import { useLoanQuery } from "../../../../../../services/RTKQuery/loans";

export const ReadSingleTest = ({ loanUuid, setStatusInParent }) => {
  const statusUnknown = "❓Unknown Status";
  const statusPass = "✅ Pass";
  const statusFail = "❌ Fail";
  const statusLoading = "Loading ...";

  const [statusReadSingle, setStatus] = useState(statusUnknown);
  const [detailsText, setDetailsText] = useState("");

  const loan = useLoanQuery(loanUuid);
  const loanData = loan?.data;

  useEffect(() => {
    setStatus(statusLoading);
    if (loanData?.uuid) {
      setStatus(statusPass);
      setDetailsText(`:  Matched record on: ${loanData?.uuid}`);
      setStatusInParent("Pass");
    } else {
      console.error("Fail: useLoanQuery");
      setStatus(statusFail);
    }
  }, [loanData?.uuid, setStatusInParent]);

  return (
    <>
      <b>{statusReadSingle}</b>
      <small>{detailsText}</small>
    </>
  );
};
