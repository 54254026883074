import { Button } from "@bafsllc/ui-shared";
import { FormattedMessage } from "react-intl";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";

export default function GenerateDocumentView({ generateDocument }) {
  const { selectedCreditAnalysis } = useSelector(state => ({
    selectedCreditAnalysis:
      state.CreditAnalysisManagementReducer.selectedCreditAnalysis
  }));
  return (
    <div className="ui secondary segment">
      <FormattedMessage id="GENERATE_DOCUMENT" tagName="h5" />
      <FormattedMessage id="GENERATE_DOCUMENT_CONTENT" tagName="p" />
      <Button
        type="button"
        disabled={!selectedCreditAnalysis?.is_complete}
        onClick={generateDocument}
        updateClassNames={classes => [...classes, "fill"]}
        message={{ id: "GENERATE_AND_VIEW_DOCUMENT" }}
      />
    </div>
  );
}

GenerateDocumentView.propTypes = {
  generateDocument: PropTypes.func.isRequired
};
