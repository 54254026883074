/* eslint-disable camelcase */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Entities, Users } from "../../../../../../services/ApiLib";
import { getToken } from "../../../../../../services/Auth";
import { dateFromString } from "../../../../../../services/DateTime";
import { bureauNameToKey } from "../../../../../../services/Constants/creditBureaus";
import { ConcatName } from "../../../../../../services/Entities";
import logger from "../../../../../../services/logger";
import { loanOfficerOptions } from "../../../../../../services/FormElements/components/entityModalOptions";

function mapToOutputName(formName, entityData) {
  let outputName = formName;
  if (formName === "credit_score_date") {
    outputName = `${bureauNameToKey[entityData.credit_bureau]}_date`;
  }

  if (formName === "credit_score") {
    if (entityData.credit_bureau === "Equifax") {
      outputName = "credit_score_equifax";
    } else if (entityData.credit_bureau === "Experian") {
      outputName = "credit_score_experian";
    } else {
      outputName = "credit_score_transunion";
    }
  }

  return outputName;
}

let fetchingEntity = false;
let fetchingLoanOfficers = false;
let fetchingTin = false;

export default function useEntity({ entityUuid, institutionUuid } = {}) {
  const dispatch = useDispatch();
  const {
    duplicateTinName,
    entityData,
    isMailingAddress,
    loanOfficers,
    stepNumber
  } = useSelector(storeState => ({
    duplicateTinName: storeState.EntityModalReducer.duplicateTinName,
    entityData: storeState.EntityModalReducer.data,
    isMailingAddress: storeState.EntityModalReducer.isMailingAddress,
    loanOfficers: storeState.EntityModalReducer.loanOfficers,
    stepNumber: storeState.EntityModalReducer.stepNumber
  }));
  const [fetchInstitutionUuid, setFetchInstitutionUuid] = useState(
    institutionUuid || null
  );
  const [needsEntityType, setNeedsEntityType] = useState(false);
  const {
    annual_income,
    annual_revenue,
    business_operating_status,
    business_start_date,
    citizenship_country,
    citizenship_state,
    company_name,
    county_of_reg,
    credit_bureau,
    credit_score_equifax_date,
    credit_score_equifax,
    credit_score_experian_date,
    credit_score_experian,
    credit_score_transunion_date,
    credit_score_transunion,
    dob,
    marital_status,
    duns_number,
    education,
    email,
    employer,
    employment_date,
    employment_status,
    entity_type,
    fico_sbss,
    first_name,
    fiscal_year_end,
    gender,
    home_phone,
    indiv_suffix,
    indiv_title,
    industry_type,
    is_operating_entity,
    job_title,
    last_financial_statement_date,
    last_name,
    last_tax_return_date,
    loan_officer_uuid,
    loan_review_type,
    mail_address_city,
    mail_address_line1,
    mail_address_line2,
    mail_address_state,
    mail_address_zip,
    member_since,
    middle_name,
    mobile_phone_no,
    naics_code,
    next_review_date,
    occupation,
    operating_status,
    personal_email,
    personal_total_assets,
    phone_no,
    phy_address_city,
    phy_address_line1,
    phy_address_line2,
    phy_address_state,
    phy_address_zip,
    previous_review_date,
    risk_rating_date,
    risk_rating,
    state_of_reg,
    tax_reporting_form,
    tin,
    website,
    uuid
  } = entityData;

  const populateModal = useCallback(
    ({ data: entity }) => {
      if (entity.uuid === entityUuid) {
        dispatch({
          type: "ENTITY_MODAL_POPULATE_DATA",
          data: entity
        });
      }
      setFetchInstitutionUuid(entity.institution_uuid);
    },
    [entityUuid, dispatch]
  );

  useEffect(() => {
    setNeedsEntityType(uuid && !entity_type);
  }, [uuid, entity_type, setNeedsEntityType]);

  // Get the entity information.
  useEffect(() => {
    if (!entityUuid || entity_type) {
      return;
    }

    if (uuid && !entity_type) {
      return;
    }

    if (fetchingEntity) {
      return;
    }

    fetchingEntity = true;
    logger.debug("useEntity: fetching entity.");

    Entities.get(
      getToken(),
      response => {
        populateModal(response);
        fetchingEntity = false;
      },
      args => {
        fetchingEntity = false;
        throw Error(`Failed to get entity data. args=${args.toString()}`);
      },
      entityUuid
    );
  }, [entityUuid, entity_type, populateModal, uuid]);

  // Get the loan officers for this institution.
  useEffect(() => {
    if (loanOfficers && loanOfficers.length) {
      return;
    }
    if (fetchingLoanOfficers || !fetchInstitutionUuid) {
      return;
    }

    fetchingLoanOfficers = true;
    logger.debug("useEntity: fetching loan officers.");
    (async function fetch() {
      try {
        const { data } = await Users.asyncRead({
          institution_uuid: fetchInstitutionUuid,
          has_lending_authority: true
        });
        dispatch({
          type: "ENTITY_MODAL_SET_LOAN_OFFICERS",
          loanOfficers: loanOfficerOptions(data)
        });
      } catch (err) {
        logger.error("Read users error.", err);
      }
      fetchingLoanOfficers = false;
    })();
  }, [
    dispatch,
    entityUuid,
    fetchInstitutionUuid,
    institutionUuid,
    loanOfficers
  ]);

  // Check for a duplicate TIN
  useEffect(() => {
    if ((!entityUuid && entityUuid !== "") || !tin) {
      return;
    }

    if (fetchingTin) {
      return;
    }

    fetchingTin = true;
    logger.debug(`useEntity: fetching tin; current tin='${tin}'`);

    (async function fetch() {
      try {
        const { data } = await Entities.read({ tin });
        let results;
        if (entityUuid === "") {
          results = data.filter(item => item.tin === tin);
        } else {
          results = data.filter(
            item => item.uuid !== entityUuid && item.tin === tin
          );
        }

        if (results && results.length) {
          dispatch({
            type: "ENTITY_MODAL_DUPLICATE_TIN",
            entityName: ConcatName(results[0])
          });
        } else {
          dispatch({ type: "ENTITY_MODAL_DUPLICATE_TIN", entityName: "" });
        }
      } catch (err) {
        logger.error("Read entities error.", err);
      }
      fetchingTin = false;
    })();
  }, [dispatch, entityUuid, tin]);

  const setStepNumber = useCallback(
    step => {
      dispatch({
        type: "ENTITY_MODAL_CHANGE_STEP",
        stepNumber: step
      });
    },
    [dispatch]
  );

  const [creditScoreDate, creditScoreValue] = useMemo(() => {
    let date = "";
    let score = "";
    if (credit_bureau === "Equifax") {
      score = credit_score_equifax;
      date = credit_score_equifax_date;
    } else if (credit_bureau === "Experian") {
      score = credit_score_experian;
      date = credit_score_experian_date;
    } else if (credit_bureau === "Transunion") {
      score = credit_score_transunion;
      date = credit_score_transunion_date;
    }

    return [date, score];
  }, [
    credit_bureau,
    credit_score_equifax,
    credit_score_equifax_date,
    credit_score_experian,
    credit_score_experian_date,
    credit_score_transunion,
    credit_score_transunion_date
  ]);

  function handleChangeDate(name, date) {
    dispatch({
      type: "ENTITY_MODAL_UPDATE_DATA_VALUES",
      name: mapToOutputName(name, entityData),
      value: dateFromString(date)
    });
  }

  function handleChangeNumber(e, { name, value }) {
    let intValue;

    if (
      name === "annual_revenue" ||
      name === "annual_income" ||
      name === "personal_total_assets"
    ) {
      intValue = value.replace("$", "");
      intValue = parseFloat(intValue.split(",").join(""));
    } else {
      intValue = parseInt(value, 10);
    }

    dispatch({
      type: "ENTITY_MODAL_UPDATE_DATA_VALUES",
      name: mapToOutputName(name, entityData),
      value: intValue
    });
  }

  function handleInputChange(e, { name, value }) {
    dispatch({
      type: "ENTITY_MODAL_UPDATE_DATA_VALUES",
      name: mapToOutputName(name, entityData),
      value
    });
    if (name === "citizenship_country") {
      dispatch({
        type: "ENTITY_MODAL_UPDATE_DATA_VALUES",
        name: "citizenship_state",
        value: ""
      });
    }
  }

  function handleSaveModal(add, onSuccess, onError) {
    let body;
    if (add) {
      body = { ...entityData, institution_uuid: fetchInstitutionUuid };
    } else {
      body = { ...entityData };
    }

    if (isMailingAddress) {
      body.mail_address_city = body.phy_address_city;
      body.mail_address_country = "USA";
      body.mail_address_line1 = body.phy_address_line1;
      body.mail_address_line2 = body.phy_address_line2;
      body.mail_address_state = body.phy_address_state;
      body.mail_address_zip = body.phy_address_zip;
    }

    [
      "dob",
      "business_start_date",
      "previous_review_date",
      "next_review_date"
    ].forEach(date => {
      if (body[date]) {
        body[date] = dateFromString(body[date]);
      }
    });

    const cleanEntityData = Object.keys(body).reduce((acc, key) => {
      if (body[key] !== null && body[key] !== undefined) {
        acc[key] = body[key];
      }
      return acc;
    }, {});

    if (add) {
      Entities.add(onSuccess, onError, cleanEntityData);
    } else {
      Entities.update(getToken(), onSuccess, onError, cleanEntityData);
    }
  }
  return {
    annual_income,
    annual_revenue,
    business_operating_status,
    business_start_date,
    citizenship_country,
    citizenship_state,
    company_name,
    county_of_reg,
    credit_bureau,
    creditScoreDate,
    creditScoreValue,
    dob,
    marital_status,
    duns_number,
    duplicateTinName,
    education,
    email,
    employer,
    employment_date,
    employment_status,
    entity_type,
    fico_sbss,
    first_name,
    fiscal_year_end,
    gender,
    handleChangeDate,
    handleChangeNumber,
    home_phone,
    indiv_suffix,
    indiv_title,
    industry_type,
    is_operating_entity,
    isMailingAddress,
    job_title,
    last_financial_statement_date,
    last_name,
    last_tax_return_date,
    loan_officer_uuid,
    loan_review_type,
    loanOfficers,
    mail_address_city,
    mail_address_line1,
    mail_address_line2,
    mail_address_state,
    mail_address_zip,
    member_since,
    middle_name,
    mobile_phone_no,
    naics_code,
    next_review_date,
    occupation,
    handleInputChange,
    handleSaveModal,
    operating_status,
    personal_email,
    personal_total_assets,
    phone_no,
    phy_address_city,
    phy_address_line1,
    phy_address_line2,
    phy_address_state,
    phy_address_zip,
    previous_review_date,
    risk_rating_date,
    risk_rating,
    setStepNumber,
    state_of_reg,
    stepNumber,
    tax_reporting_form,
    tin,
    website,
    needsEntityType
  };
}
