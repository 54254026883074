import TypeCheck from "typecheck-extended";
import ApiEndpoints from "../../../ApiEndpoints";
import { asyncFetchFileService } from "../../../FetchService";
import { FormatUrlV2 } from "../../../FormatUrl";
import logger from "../../../logger";

async function download(filters, callbackData) {
  const funcName = "====  Documents.download(filters)  ====";
  logger.debug(funcName, [filters]);
  TypeCheck(filters.uuid, "string", true);
  TypeCheck(filters.common, "boolean", false);

  const endpoint =
    filters.common === true
      ? ApiEndpoints.objectCommonDownloads
      : ApiEndpoints.objectDownloads;
  const url = FormatUrlV2(endpoint, { uuid: filters.uuid });

  let rsp;
  try {
    rsp = await asyncFetchFileService(
      "GET",
      url,
      null,
      callbackData,
      "download"
    );
  } catch (e) {
    throw new Error(e);
  }
  return rsp;
}

async function preview(filters, callbackData) {
  const funcName = "====  Documents.preview(filters)  ====";
  logger.debug(funcName, [filters]);
  TypeCheck(filters.uuid, "string", true);

  const url = FormatUrlV2(ApiEndpoints.objectDownloads, { uuid: filters.uuid });

  let rsp;
  try {
    rsp = await asyncFetchFileService(
      "GET",
      url,
      null,
      callbackData,
      "preview"
    );
  } catch (e) {
    throw new Error(e);
  }
  return rsp;
}

export default { preview, download };
