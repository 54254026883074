/* eslint-disable camelcase */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Message } from "semantic-ui-react";
import { FormInput } from "../../../../../../services/FormElements";
import { FrictionModal } from "../../../../../../components/Modals";
import getInputs from "./getInputs";

function ScheduleForm({
  institution: propsInstitution,
  foundTin,
  updateStore
}) {
  const [institution, setInstitution] = useState({ ...propsInstitution });
  const [frictionObj, updatefrictionObj] = useState(null);

  const updateInstitution = nextFields => {
    setInstitution({ ...institution, ...nextFields });
  };

  const getTinString = tin => {
    const taxIdNoDash = tin.replace(/-/g, "");
    const nextTaxId = taxIdNoDash
      ? `${taxIdNoDash.slice(0, 1)}-${taxIdNoDash.slice(1)}`
      : "";
    return nextTaxId;
  };

  const handleCoreOfRecord = ({ core_of_record }) => {
    const onSubmit = () => {
      updatefrictionObj(null);
      updateStore({ core_of_record });
      setInstitution({ ...institution, core_of_record });
    };
    const friction = {
      onSubmit,
      header: "Core of Record",
      message:
        "Are you sure you want to change this Institution's Core of Record",
      validateString: "CORE"
    };
    updatefrictionObj(friction);
  };

  const inputs = getInputs({
    handleCoreOfRecord,
    getTinString,
    updateInstitution,
    updateStore
  });

  return (
    <Form>
      {frictionObj && (
        <FrictionModal
          onSubmit={frictionObj.onSubmit}
          onCancel={() => updatefrictionObj(null)}
          header={frictionObj.header}
          message={frictionObj.message}
          validateString={frictionObj.validateString}
          open
        />
      )}
      {foundTin && (
        <Message
          color="red"
          disabled={!foundTin}
          header={`TIN ${institution.tax_id} is already associated with ${
            foundTin ? foundTin.name : ""
          }`}
          content="Institutions must have unique TINs."
        />
      )}
      {inputs.map(inputGroup => (
        <Form.Group
          key={`institution-input-group-${inputGroup.row}`}
          style={{ alignItems: "center" }}
          widths="equal"
        >
          {inputGroup.inputs.map(input => (
            <FormInput
              key={input.name}
              input={input}
              set={updateInstitution}
              update={updateStore}
              fields={institution}
            />
          ))}
        </Form.Group>
      ))}
    </Form>
  );
}

ScheduleForm.propTypes = {
  institution: PropTypes.shape({
    name: PropTypes.string,
    core_of_record: PropTypes.bool,
    daily_transaction_file: PropTypes.bool,
    tax_id: PropTypes.string,
    institution_type: PropTypes.string,
    routing_number: PropTypes.string,
    charter_number: PropTypes.string,
    regulator: PropTypes.string,
    institution_primary_address: PropTypes.string,
    institution_primary_address_2: PropTypes.string,
    institution_city: PropTypes.string,
    institution_state: PropTypes.string,
    institution_postal_code: PropTypes.string,
    mailing_primary_address: PropTypes.string,
    mailing_primary_address_2: PropTypes.string,
    mailing_city: PropTypes.string,
    mailing_state: PropTypes.string,
    mailing_postal_code: PropTypes.string,
    non_standard_file: PropTypes.bool,
    foundTin: PropTypes.string
  }).isRequired,
  updateStore: PropTypes.func.isRequired,
  foundTin: PropTypes.shape()
};

export default ScheduleForm;
