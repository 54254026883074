import { useEffect, useState } from "react";
import { Divider } from "semantic-ui-react";
import { getToken } from "@bafsllc/auth-shared";
import { Button, Icon, IconButton, Tooltip } from "@bafsllc/ui-shared";
import logger from "../../../../../../services/logger";
import { LoanApp, ObjectMetadata } from "../../../../../../services/ApiLib";
import { AccountDetails } from "../../../../../../components/AccountDetails";
import { useLoanApplicationEntityDetails } from "../../../../../../store/models/loan-applications";
import { LOSBreadcrumbs } from "../LOSBreadcrumbs";
import { CheckAuth } from "../../../../../../services/Auth";
import { environment } from "../../../../../../environments/environment";
import { useToast } from "@bafsllc/ui-shared";
import { getBlastClientApplicationBaseUrl } from "../../../../../../components/BlastEmbed/getBaseUrl";
import { FormattedMessage } from "react-intl";

function onError(error) {
  logger.error(error);
}

export function LoanOriginationPrepareDocsHeader({ loanAppUuid }) {
  const [loanApp, setLoanApp] = useState({
    borrower_uuid: "",
    borrower_name: "",
    app_id: "",
    uuid: "",
    institution_uuid: ""
  });

  const [primaryBorrowerUuid, setPrimaryBorrowerUuid] = useState("");
  const [transactionData, setTransactionData] = useState([]);
  const [isTransactionDataLoaded, setIsTransactionDataLoaded] = useState(false);

  const { addToast } = useToast();

  // The following code produces a React mem leak warning, but it's not actually an issue:
  // https://github.com/reactwg/react-18/discussions/82
  useEffect(() => {
    LoanApp.get(getToken(), d => setLoanApp(d.data), onError, loanAppUuid);
  }, [loanAppUuid]);

  useEffect(() => {
    async function fetchMetaData() {
      const { data } = await ObjectMetadata.asyncGetMetaData(
        {
          institution_uuid: loanApp.institution_uuid,
          parent_uuid: `CSI-Session-Data-${loanAppUuid}`
        },
        {
          number: 0,
          size: 10
        }
      );
      setTransactionData(data);
      setIsTransactionDataLoaded(true);
    }
    fetchMetaData();
  }, [loanAppUuid, loanApp.institution_uuid]);

  const transactionButtonMessage = transactionData.length
    ? "RESUME_TRANSACTION"
    : "START_TRANSACTION";

  // @ts-ignore
  const [{ borrowers = [] }] = useLoanApplicationEntityDetails({ loanAppUuid });
  const primaryBorrower = borrowers.filter(item => item.primary_borrower)[0];

  useEffect(() => {
    if (!primaryBorrower) return;
    setPrimaryBorrowerUuid(primaryBorrower.child_uuid);
  }, [primaryBorrower, setPrimaryBorrowerUuid]);

  const handlePrintLoanData = () => {
    const token = CheckAuth();

    // eslint-disable-next-line no-undef
    if (typeof visualize === "undefined" || !visualize || !token) {
      return;
    }

    // eslint-disable-next-line no-undef
    visualize.config({
      auth: {
        token: token,
        preAuth: true,
        tokenName: "pp"
      }
    });

    const institutionUUID = loanApp.institution_uuid;
    const domain = environment.jasperDomain;
    const url = `https://${domain}/jasperserver-pro/rest_v2/reports/public/BLAST_Reports/Loan_Data_Sheet.pdf?App_ID=${loanApp.app_id}&Institution_UUID=${institutionUUID}`;
    return (document.location = url);
  };

  return (
    <>
      <LOSBreadcrumbs loanApp={loanApp} pageName="Prepare Document Package" />

      <header className="mb-6 flex justify-between items-center">
        <div>
          <h1 className="!mt-0 mb-4 primary-header font-semibold">
            Prepare Document Package
          </h1>
          <a
            href={`/credit_management/entity/${primaryBorrowerUuid}`}
            target="_blank"
            className="underline text-slate-600"
          >
            <h2 className="secondary-header font-semibold">
              {loanApp.borrower_name}
            </h2>
          </a>
        </div>
        <div>
          <Tooltip
            inverted
            content={<FormattedMessage id="TRANSACTION_LAST_SAVED_NOTICE" />}
            small
            arrow
          >
            <div className="font-semibold">
              Transaction Last Saved{" "}
              <span className="hover:cursor-help">
                <Icon icon="info circle" />
              </span>
            </div>
          </Tooltip>
          &mdash;
        </div>
      </header>

      <AccountDetails loanApp={loanApp} title="Application Details" />

      <div className="flex justify-end space-x-4 mt-6">
        <IconButton
          icon="print"
          variant="secondary"
          message={{ id: "PRINT_LOAN_DATA" }}
          onClick={() => handlePrintLoanData()}
        />

        <Button
          message={{ id: "MANAGE_TRANSACTION" }}
          variant="secondary"
          disabled // remove when hooked up
        />
        {isTransactionDataLoaded && (
          <Button
            message={{ id: transactionButtonMessage }}
            variant="primary"
            disabled={transactionButtonMessage === "START_TRANSACTION"} // remove when Start Transaction hooked up
            onClick={() => {
              if (transactionButtonMessage === "RESUME_TRANSACTION") {
                const url = new URL(getBlastClientApplicationBaseUrl());
                url.pathname = "/csi-host/";
                url.searchParams.append(
                  "institution_uuid",
                  loanApp.institution_uuid
                );
                url.searchParams.append("loan_application_uuid", loanApp.uuid);
                url.searchParams.append("entity_uuid", primaryBorrowerUuid);
                url.searchParams.append("resume", "1");
                window.open(url, "_blank");
              }
            }}
          />
        )}
      </div>

      <Divider className="my-6" />
    </>
  );
}

export default LoanOriginationPrepareDocsHeader;
