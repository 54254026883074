/* eslint-disable camelcase */
import React from "react";
import { Form } from "semantic-ui-react";
import { DateInput } from "../../../../../../CustomFormElements";
import useEntity from "../../../hooks/useEntity/useEntity";
import readableNumFormat from "../../../../../../../services/FormElements/services/readableNumFormat";

function IndividualFinancialInfo() {
  const {
    annual_income,
    handleChangeNumber,
    handleInputChange,
    last_financial_statement_date,
    last_tax_return_date,
    personal_total_assets
  } = useEntity();

  return (
    <Form>
      <Form.Group>
        <DateInput
          asForm
          width={6}
          label="Last Financial Statement Date"
          value={last_financial_statement_date || ""}
          name="last_financial_statement_date"
          onChange={handleInputChange}
        />
        <Form.Input
          name="annual_income"
          label="Total Annual Income"
          width={9}
          value={annual_income ? readableNumFormat(annual_income) : ""}
          onChange={handleChangeNumber}
        />
        <Form.Input
          name="personal_total_assets"
          label="Total Assets"
          width={9}
          value={
            personal_total_assets
              ? readableNumFormat(personal_total_assets)
              : ""
          }
          onChange={handleChangeNumber}
        />
      </Form.Group>
      <Form.Group>
        <Form.Input
          readOnly
          name="tax_reporting_form"
          label="Tax Reporting Form"
          width={9}
          value="1040"
        />
        <DateInput
          asForm
          width={9}
          label="Last Tax Return Date"
          value={last_tax_return_date || ""}
          name="last_tax_return_date"
          onChange={handleInputChange}
        />
      </Form.Group>
    </Form>
  );
}

export default IndividualFinancialInfo;
