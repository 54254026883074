import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Form } from "semantic-ui-react";

export const isOnSaveDisabled = (required, values) => {
  if (!Object.keys(required).length) {
    return false;
  }
  let disable = false;
  Object.entries(required).forEach(([key, obj]) => {
    if (!values[key] && values[key] !== 0) {
      disable = true;
      return disable;
    }
    if (obj.condition && values[key] === obj.condition) {
      obj.required.forEach(field => {
        if (!values[field] && values[field] !== 0) {
          disable = true;
        }
      });
    }
    return disable;
  });
  return disable;
};

function EditModal({
  children,
  onClose,
  onSave,
  currentValues = null,
  defaultValues = {},
  loading,
  required = {},
  size = "tiny",
  ...props
}) {
  const [values, setValues] = useState(defaultValues);
  const disableOnSave = isOnSaveDisabled(required, values);

  const handleChange = ({ name, value }) => {
    setValues(state => ({ ...state, [name]: value }));
  };

  const handleClose = () => {
    setValues(defaultValues);
    onClose();
  };

  const handleMultipleChanges = updates => {
    setValues(state => ({ ...state, ...updates }));
  };

  const handleSave = () => {
    // get values from parent if handled there, otherwise get values
    // maintained in this component
    const finalValues = currentValues || values;
    if (Object.keys(finalValues).length) {
      onSave(finalValues);
    }
    onClose();
  };

  return (
    <Modal
      header="Edit"
      content={
        <Form loading={loading}>
          {typeof children === "function"
            ? children({
                onChange: handleChange,
                values,
                onMultipleChanges: handleMultipleChanges
              })
            : children}
        </Form>
      }
      actions={[
        {
          key: "cancel",
          content: "Cancel",
          basic: true,
          onClick: handleClose,
          "data-testid": "edit-modal-close-button"
        },
        {
          key: "save",
          content: "Save",
          color: "green",
          disabled: disableOnSave,
          onClick: (e, action) => handleSave(e, action),
          "data-testid": "edit-modal-save-button"
        }
      ]}
      size={size}
      dimmer="blurring"
      {...props}
    />
  );
}

EditModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  defaultValues: PropTypes.shape({}), // used for values handled here
  currentValues: PropTypes.shape({}), // used for values handled in parent component
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  size: PropTypes.string,
  required: PropTypes.objectOf(PropTypes.object)
};

export default EditModal;
