import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  AnimationStage,
  DialogAnimationProps,
  DialogComponentProps
} from "../../../../types/modules";
import { Dialog } from "../dialog/dialog";

/**
 * This component will create dialogs that are animated as they are shown and
 * hidden. Any component that uses AnimatedDialog needs to keep this component
 * mounted until the onHidden function has been invoked indicating that the
 * "hide" animation has completed.
 * This is exclusively to be use in the Dialogs components
 *
 */
export function AnimatedDialog({
  children,
  hide,
  onHidden = () => {},
  classes,
  size
}: React.PropsWithChildren<AnimatedDialogProps>) {
  const [animationStage, setAnimationStage] =
    useState<AnimationStage>("fadeIn");
  const hidden = useRef(false);

  useEffect(() => {
    if (hide && animationStage === "fadeIn") {
      setAnimationStage("fadeOut");
    }
  }, [animationStage, hide]);

  const handleAnimationEnd = useCallback(
    (stage: AnimationStage) => {
      if (stage === "fadeOut") {
        hidden.current = true;
        onHidden();
      }
    },
    [onHidden]
  );

  return (
    <Dialog
      onAnimationEnd={handleAnimationEnd}
      size={size}
      stage={animationStage}
      classes={classes}
    >
      {children}
    </Dialog>
  );
}

export interface AnimatedDialogProps
  extends DialogComponentProps,
    DialogAnimationProps {}
