import Constants from "./strings";

const {
  PHASE_NEW_CREDIT_APPLICATION,
  PHASE_INITIAL_REVIEW,
  PHASE_FINANCIAL_ANALYSIS,
  PHASE_UNDERWRITING,
  PHASE_DOC_PREP,
  PHASE_CREDIT_ADMIN,
  PHASE_THIRD_PARTY_ACT,
  PHASE_PENDING_DECISION,
  PHASE_PRE_CLOSING_CONDITIONS
} = Constants;

const ACTIVE_PHASES = {
  [PHASE_NEW_CREDIT_APPLICATION]: true,
  [PHASE_INITIAL_REVIEW]: true,
  [PHASE_FINANCIAL_ANALYSIS]: true,
  [PHASE_UNDERWRITING]: true,
  [PHASE_DOC_PREP]: true,
  [PHASE_CREDIT_ADMIN]: true,
  [PHASE_THIRD_PARTY_ACT]: true,
  [PHASE_PENDING_DECISION]: true,
  [PHASE_PRE_CLOSING_CONDITIONS]: true
};

export default ACTIVE_PHASES;
