import { useCallback } from "react";

export function DownloadBlobs({ blob, fileName }: DownloadBlobProps) {
  const href = window.URL.createObjectURL(blob);
  const linkEl = useCallback(node => {
    if (node) {
      node.click();
    }
  }, []);

  return <a className="hidden" ref={linkEl} href={href} download={fileName} />;
}

interface DownloadBlobProps {
  blob: Blob;
  fileName: string;
}
