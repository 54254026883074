/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
import React from "react";
import { arrayOf, element, func, shape } from "prop-types";
import { Button, Icon, Segment, Table, Grid } from "semantic-ui-react";
import { PrettyNumber } from "../../../../services/PrettyNumber";
import { isNumber } from "../../../../services/ApiLib/services/Utilities";
import { EditButton } from "../../../../components/CustomFormElements";

function collateralTable({
  assets,
  collateralData,
  deleteCallback,
  editCallback,
  optionalHeaderComponent,
  showDeleteTest
}) {
  const headerNames = [
    "Primary",
    "Description",
    "Class",
    "Type",
    "Market Value",
    "Amount of Prior Liens",
    "Advance Rate",
    "Cost",
    "Cross-Collaterized",
    "Lien Position",
    "Value Date",
    "Value Source",
    ""
  ];

  const headers = headerNames.map(name => (
    <Table.HeaderCell key={name}>{name}</Table.HeaderCell>
  ));
  let rows = [];
  if (assets) {
    rows = collateralData.map(
      ({
        advance_rate,
        asset_uuid,
        cross_collateralized,
        is_primary,
        lien_position,
        prior_liens,
        uuid
      }) => {
        const {
          asset_class,
          asset_type,
          cost,
          description,
          market_value,
          value_date,
          value_source
        } = assets[asset_uuid] || {};

        return (
          <Table.Row key={uuid}>
            <Table.Cell>{is_primary ? <Icon name="check" /> : ""}</Table.Cell>
            <Table.Cell>
              <a
                href={`/asset_management/${asset_uuid}`}
                rel="noreferrer noopener"
                target="_blank"
              >
                {description}
              </a>
            </Table.Cell>
            <Table.Cell>{asset_class}</Table.Cell>
            <Table.Cell>{asset_type}</Table.Cell>
            <Table.Cell>
              {isNumber(market_value)
                ? `$${PrettyNumber(parseFloat(market_value))}`
                : ""}
            </Table.Cell>
            <Table.Cell>
              {isNumber(prior_liens)
                ? `$${PrettyNumber(parseFloat(prior_liens))}`
                : ""}
            </Table.Cell>
            <Table.Cell>
              {advance_rate ? `${advance_rate * 100}%` : ""}
            </Table.Cell>
            <Table.Cell>
              {isNumber(cost) ? `$${PrettyNumber(parseFloat(cost))}` : ""}
            </Table.Cell>
            <Table.Cell>{cross_collateralized ? "Yes" : "No"}</Table.Cell>
            <Table.Cell>{lien_position}</Table.Cell>
            <Table.Cell>{value_date}</Table.Cell>
            <Table.Cell>{value_source}</Table.Cell>
            <Table.Cell singleLine>
              <EditButton onClick={() => editCallback(uuid)} />
              {showDeleteTest() ? (
                <Button
                  basic
                  circular
                  icon={<Icon name="x" color="green" />}
                  onClick={() => deleteCallback(uuid)}
                />
              ) : (
                ""
              )}
            </Table.Cell>
          </Table.Row>
        );
      }
    );
  }

  return (
    <Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="right">{optionalHeaderComponent}</Grid.Column>
        </Grid.Row>
      </Grid>
      <Table celled>
        <Table.Header>
          <Table.Row>{headers}</Table.Row>
        </Table.Header>
        <Table.Body>{rows}</Table.Body>
      </Table>
    </Segment>
  );
}

collateralTable.defaultProps = {
  optionalHeaderComponent: null
};

collateralTable.propTypes = {
  assets: shape().isRequired,
  collateralData: arrayOf(shape()).isRequired,
  deleteCallback: func.isRequired,
  editCallback: func.isRequired,
  optionalHeaderComponent: element,
  showDeleteTest: func.isRequired
};

export default collateralTable;
