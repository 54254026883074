import {
  TokenRefreshEventListeners,
  SessionWatchWarning
} from "@bafsllc/auth-shared";

export const BlastSessionManager = () => {
  /* 
    BlastSessionManager is included at the root of the app with an exclusion for Borrower Portal.
    Anything related to session management should be included here to have it automatically applied
    to all BLAST applications.
    
    The following components are included:
    - TokenRefreshEventListeners: Watches for user activity and keeps token refreshed for active users.
    - SessionWatchWarning: Displays a warning message when the session is about to expire.

    Visuals / Styling:
    - All of the visuals are handled by the components themselves, and are all hidden to the UI.
    - You almost certainly want any new component you add to also be hidden.

    Application Roots: 
    - Commotion and Non-Commotion BLAST have separate application roots: 
      - non-commotion BLAST: libs/application-host
      - commotion BLAST: Commotion.jsx
    - Borrower Portal auth/session management is handled separately and unrelated to this file.
  */
  return (
    <>
      <TokenRefreshEventListeners />
      <SessionWatchWarning />
    </>
  );
};
