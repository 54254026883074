/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

const SEPARATOR = "-";

export default function IdentificationNumber({
  children,
  name,
  numberType = "tin",
  onBlur,
  onChange,
  onFocus,
  onSubmit,
  value,
  ...props
}) {
  const [displayValue, setDisplayValue] = useState();
  const [focused, setFocused] = useState(false);
  const keyDownData = useRef();

  useEffect(
    () => setDisplayValue(toDisplayValue(value, numberType)),
    [value, numberType]
  );

  useEffect(() => {
    if (!keyDownData.current) {
      return;
    }

    const { key, selectionStart, target } = keyDownData.current;
    keyDownData.current = null;
    const nextSelectionStart =
      key === "Backspace" ? selectionStart - 1 : selectionStart;
    target.setSelectionRange(nextSelectionStart, nextSelectionStart);
  }, [displayValue]);

  const handleBlur = useCallback(
    evt => {
      setFocused(false);
      onBlur &&
        onBlur({
          ...evt,
          target: { ...evt.target, value: toNumberValue(evt.target.value) }
        });
    },
    [onBlur]
  );

  const handleChange = useCallback(
    evt => {
      setDisplayValue(toDisplayValue(evt.target.value, numberType));
      onChange &&
        onChange(
          {
            ...evt,
            target: { ...evt.target, value: toNumberValue(evt.target.value) }
          },
          { name, value: toNumberValue(evt.target.value) }
        );
    },
    [numberType, onChange, name]
  );

  const handleFocus = useCallback(
    evt => {
      setFocused(true);
      onFocus && onFocus(evt);
    },
    [onFocus]
  );

  const handleKeyDown = useCallback(evt => {
    const { key, target } = evt;
    if (key !== "Backspace" && key !== "Delete") {
      return;
    }

    const charIndex =
      key === "Backspace" ? target.selectionStart - 1 : target.selectionStart;

    if (target.value.charAt(charIndex) === SEPARATOR) {
      // In React the synthetic events are reused, so here in keydown or in
      // keyup collect the data that's needed from target because its
      // properties will change (like selectionStart).
      keyDownData.current = {
        key,
        selectionStart: target.selectionStart,
        target
      };
      evt.preventDefault();
    }
  }, []);

  const handleKeyUp = useCallback(() => {
    if (!keyDownData.current) {
      return;
    }

    const {
      key,
      selectionStart,
      target: { value: keyDownValue }
    } = keyDownData.current;

    const leftIndex = key === "Backspace" ? selectionStart - 2 : selectionStart;
    const rightIndex =
      key === "Backspace" ? selectionStart : selectionStart + 2;
    const left = keyDownValue.substring(0, leftIndex);
    const right = keyDownValue.substring(rightIndex);

    setDisplayValue(toDisplayValue(left + right, numberType));
  }, [numberType]);

  const handleSubmit = useCallback(
    evt => {
      onSubmit &&
        onSubmit({
          ...evt,
          target: { ...evt.target, value: toNumberValue(evt.target.value) }
        });
    },
    [onSubmit]
  );

  const inputProps = {
    ...props,
    maxLength: numberType === "ssn" ? 11 : 10,
    minLength: numberType === "ssn" ? 11 : 10,
    name,
    onBlur: handleBlur,
    onChange: handleChange,
    onFocus: handleFocus,
    onKeyDown: handleKeyDown,
    onKeyUp: handleKeyUp,
    onSubmit: handleSubmit,
    placeholder: numberType === "ssn" ? "XXX-XX-XXXX" : "XX-XXXXXXX",
    type: "text",
    value: displayValue || ""
  };

  if (!focused && !isIdentificationNumberValue(value, numberType)) {
    inputProps.error = `'${toDisplayValue(
      value,
      numberType
    )}' is an invalid number`;
  }

  return <Form.Input {...inputProps}>{children || null}</Form.Input>;
}

IdentificationNumber.propTypes = {
  ...Form.Input.propTypes,
  numberType: PropTypes.oneOf(["ein", "ssn", "tin"]),
  onBlur: PropTypes.func,
  value: PropTypes.string
};

export function isIdentificationNumberValue(value, numberType) {
  if (!value) {
    return true;
  }

  const displayValue = toNumberValue(value, numberType);
  if (displayValue.length !== 9) {
    return false;
  }

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(displayValue)) {
    return false;
  }

  return true;
}

/**
 * Converts from a number string to a display string with hyphens.
 * @param {string} value
 * @param {'ein' | 'ssn' | 'tin'} numberType
 * @returns {string}
 */
export function toDisplayValue(value, numberType) {
  const num = toNumberValue(value).replace(/[^0-9]/g, "");
  let result = "";
  let c;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < num.length; i++) {
    c = num.charAt(i);
    if (numberType !== "ssn" && i === 2) {
      result += SEPARATOR + c;
    } else if (numberType === "ssn" && (i === 3 || i === 5)) {
      result += SEPARATOR + c;
    } else {
      result += c;
    }
  }

  return result;
}

/**
 * Converts from a display number to a string with only digits.
 * @param {string} value
 * @returns {string}
 */
export function toNumberValue(value = "") {
  return value.replace(new RegExp(SEPARATOR, "g"), "");
}
