import React, { useEffect, useState } from "react";
import { useDeleteLoanMutation } from "../../../../../../services/RTKQuery/loans";

export const DeleteTest = ({ loanUuid, setStatusInParent }) => {
  const statusUnknown = "❓Unknown Status";
  const statusPass = "✅ Pass";
  const statusFail = "❌ Fail";

  const [statusReadSingle, setStatus] = useState(statusUnknown);
  const [detailsText, setDetailsText] = useState("statusUnknown");

  const [deleteLoan] = useDeleteLoanMutation();
  useEffect(() => {
    deleteLoan(loanUuid)
      .unwrap()
      .then(fulfilled => {
        if (
          fulfilled?.data?.uuid === loanUuid &&
          fulfilled?.data?.deleted === true
        ) {
          setStatus(statusPass);
          setDetailsText(`: Soft deleted item: ${loanUuid}`);
          setStatusInParent("Pass");
        } else {
          console.error("delete fulfilled: ", fulfilled);
          setStatus(statusFail);
          setDetailsText(`: Did NOT delete item: ${loanUuid}`);
          setStatusInParent("Fail");
        }
      })
      .catch(error => {
        console.error("Fail: useDeleteLoanMutation", error);
        setStatus(statusFail);
        setStatusInParent("Fail");
      });
  }, [deleteLoan, loanUuid, setStatusInParent]);

  return (
    <>
      <b>{statusReadSingle}</b>
      <small>{detailsText}</small>
    </>
  );
};
