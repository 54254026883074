import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Grid, Modal, Divider, Message } from "semantic-ui-react";

import DocumentDetails from "./components/DocumentDetails";
import PdfViewer from "./components/PdfViewer";

function DocEditModal({ docDetails, onSave, onClose }) {
  const [docFormData, setDocFormData] = useState({});
  const [error, setError] = useState();
  if (!docDetails) {
    return null;
  }
  const handleSave = () => {
    if (!docFormData.parentUuid) {
      setError("Select correct values for Assets/Loans as applicable!");
      return;
    }
    onSave(docFormData);
  };
  const { httpHeaders, url, uuid, fileName } = docDetails;
  return (
    <Modal dimmer onClose={() => onClose()} open size="fullscreen">
      <Modal.Header>{`Document Details: ${fileName}`}</Modal.Header>
      <Modal.Content>
        <Grid width={2}>
          <Grid.Row>
            <Grid.Column width={5}>
              <DocumentDetails
                docDetails={docDetails}
                onChange={formData => setDocFormData(formData)}
              />
              <Divider hidden />
              <Grid>
                <Grid.Row>
                  {error && (
                    <Grid.Column columns={2}>
                      <Message negative content={error} />
                    </Grid.Column>
                  )}
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Button fluid basic onClick={() => onClose({})}>
                      Cancel
                    </Button>
                  </Grid.Column>
                  <Grid.Column>
                    <Button fluid onClick={handleSave}>
                      Save
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column stretched width={11}>
              <PdfViewer uuid={uuid} fileName={fileName} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
}

DocEditModal.propTypes = {
  docDetails: PropTypes.shape({
    fileName: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    docCategory: PropTypes.string,
    docDate: PropTypes.string,
    docType: PropTypes.string,
    entityUuid: PropTypes.string,
    institutionUuid: PropTypes.string,
    parentUuid: PropTypes.string,
    periodEnd: PropTypes.string,
    periodStart: PropTypes.string,
    status: PropTypes.string,
    uploadDate: PropTypes.string,
    uuid: PropTypes.string,
    httpHeaders: PropTypes.shape({ Authorization: PropTypes.string })
  }),
  onSave: PropTypes.func,
  onClose: PropTypes.func
};

export default DocEditModal;
