import React, { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon, Segment } from "semantic-ui-react";
import BlastEmbed from "../../../../components/BlastEmbed";
import DocTitle from "../../../../components/DocTitle";
import { CombinedTicklerFilters } from "../../../../services/Ticklers";
import PaginationControls from "../../../../components/Pagination";
import ApiEndpoints from "../../../../services/ApiEndpoints";
import {
  COMPONENT_NAME,
  filterOptionsParentRelType,
  filterOptionsStatus
} from "./constants";
import useBlastActions from "./useBlastActions";
import TicklerDocApprovalModal from "./TicklerDocApprovalModal";

const { ticklersExceptions } = ApiEndpoints;
const loadingIcon = <Icon name="spinner" size="large" loading />;

function TicklersExceptions() {
  const [filterKey, setFilterKey] = useState(null);
  const [filters, setFilters] = useState({});
  const [selectedValue, setSelectedValue] = useState(null);

  const dispatch = useDispatch();

  const { statePagination } = useSelector(state => ({
    statePagination: state.PaginationReducer[COMPONENT_NAME]
  }));
  const { number, size } = statePagination ?? {};
  const pagination = useMemo(
    () => ({
      page_number: number ?? 0,
      page_size: size ?? 10
    }),
    [number, size]
  );

  const {
    onUpdate,
    dispatchToBlast,
    action,
    unsetCureModalData,
    cureModalOpen,
    cureModalTickler,
    cureModalDocDetails,
    cureModalUsers,
    paginationLastPage,
    errorMessage
  } = useBlastActions({ filters, pagination });

  useEffect(() => {
    // dispatch pagination changes to blast iframe
    dispatchToBlast({
      type: "DOC_MGMT_TICKLERS_UPDATE_PAGINATION",
      pagination
    });

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [pagination]);

  useEffect(() => {
    if (filterKey === null) {
      return;
    }

    // dispatch filter changes to blast iframe
    const nextFilters = filters;
    nextFilters[filterKey] = selectedValue;
    setFilters(nextFilters);
    dispatchToBlast({
      type: "DOC_MGMT_TICKLERS_FILTER_UPDATE_VALUE",
      filterKey,
      selectedValue
    });
    dispatch({
      type: "PAGINATION_UPDATE_NUMBER",
      name: COMPONENT_NAME,
      number: 0
    });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [filterKey, selectedValue, filters]);

  return (
    <DocTitle title="Ticklers - BLAST Portal">
      <div>
        <h1 className="mb-6">Ticklers &amp; Exceptions</h1>
        {errorMessage && <h2 style={{ color: "red" }}>{errorMessage}</h2>}
      </div>

      <TicklerDocApprovalModal
        dispatchToBlast={dispatchToBlast}
        cureModalOpen={cureModalOpen}
        cureModalDocDetails={cureModalDocDetails}
        cureModalUsers={cureModalUsers}
        unsetCureModalData={unsetCureModalData}
        cureModalTickler={cureModalTickler}
      />
      <CombinedTicklerFilters
        loader={loadingIcon}
        onChange={(nextSelectedValue, nextFilterKey) => {
          setFilterKey(nextFilterKey);
          setSelectedValue(nextSelectedValue);
        }}
        options={{
          parentRelType: filterOptionsParentRelType,
          status: filterOptionsStatus
        }}
        values={{
          institution: filters.filterSelectedInstitution || "",
          parentRelType: filters.filterSelectedParentRelType || "",
          status: filters.filterSelectedStatus || ""
        }}
      />
      <Segment>
        <BlastEmbed
          queryParams={{}}
          urlPath={ticklersExceptions}
          onUpdate={onUpdate}
          action={action}
        />
      </Segment>
      <Segment>
        <PaginationControls
          lastPage={paginationLastPage}
          name={COMPONENT_NAME}
          showPageNumber
        />
      </Segment>
    </DocTitle>
  );
}

export default TicklersExceptions;
