import _ from "lodash";

export const initialState = {
  add: false,
  data: {},
  duplicateTinName: "",
  isMailingAddress: false,
  loanOfficers: [],
  newEntityType: "",
  stepNumber: 0
};

export default (state, action) => {
  const safeState = state ?? initialState;
  switch (action.type) {
    case "ENTITY_MODAL_CHANGE_STEP": {
      return { ...safeState, stepNumber: action.stepNumber };
    }

    case "ENTITY_MODAL_SET_LOAN_OFFICERS": {
      return { ...safeState, loanOfficers: action.loanOfficers };
    }

    case "ENTITY_MODAL_CLEAR_DUPLICATE_TIN": {
      return {
        ...safeState,
        duplicateTinName: "",
        data: { ...safeState.data, tin: "" }
      };
    }

    case "ENTITY_MODAL_DUPLICATE_TIN": {
      return { ...safeState, duplicateTinName: action.entityName };
    }

    case "ENTITY_MODAL_POPULATE_DATA": {
      return { ...safeState, data: action.data };
    }

    case "ENTITY_MODAL_UPDATE_DATA_VALUES": {
      const newData = _.cloneDeep(safeState.data);
      newData[action.name] = action.value;
      return { ...safeState, data: newData };
    }

    case "ENTITY_MODAL_TOGGLE_ADDRESS": {
      return { ...safeState, isMailingAddress: !safeState.isMailingAddress };
    }

    case "ENTITY_MODAL_CLEAR_STATE": {
      return initialState;
    }

    default:
      return safeState;
  }
};
