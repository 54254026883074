import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Grid, Input, Radio, Segment } from "semantic-ui-react";

import DocCategories from "./components/DocCategories";
import DragAndDrop from "../../../../../../../../components/DragAndDrop";
import { DocApprovalModal } from "../../../../../../../../components/Modals";

function CreditDocument(props) {
  const {
    addNewUploadToState,
    creditDocsFilter,
    data,
    handleCreditDocSearch,
    entityData,
    openDocApproval,
    selectedCreditDoc,
    selectedDocument,
    toggleDocApprovalModal,
    onSave,
    institutionUuid
  } = props;
  const newData = data.filter(obj =>
    obj?.docType?.toLowerCase().startsWith(creditDocsFilter)
  );

  const docTypekeys = [];

  newData.forEach(doc => {
    if (!docTypekeys.includes(doc.docType)) {
      docTypekeys.push(doc.docType);
    }
  });
  docTypekeys.sort();

  const filteredData = newData.reduce((acc, doc) => {
    docTypekeys.forEach(key => {
      if (!acc[key]) {
        acc[key] = [];
      }
      if (key === doc.docType) {
        acc[key].push(doc);
      }
    });
    return acc;
  }, {});

  const tableBody = Object.keys(filteredData).map(key => (
    <DocCategories
      creditDoc={props.selectedCreditDoc}
      data={filteredData[key]}
      documentType={key}
      institutionUuid={entityData.institution_uuid}
      entityUuid={entityData.uuid}
      key={key}
      onPreview={props.onPreview}
      onSave={props.onSave}
      openDocApproval={props.openDocApproval}
      selectedDocument={props.selectedDocument}
      toggleDocApprovalModal={props.toggleDocApprovalModal}
    />
  ));

  return (
    <Segment.Group>
      <Segment>
        {/* {TODO make buttons work until further clarification} */}
        <Grid>
          <Grid.Row columns="2">
            <Grid.Column textAlign="left">
              <Input
                icon="search"
                onChange={handleCreditDocSearch}
                placeholder="Search Document Type"
                value={creditDocsFilter}
              />
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Radio toggle label="Include Archived Documents" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <DragAndDrop
        entityUuid={entityData.uuid}
        institutionUuid={entityData.institution_uuid}
        onUploadSuccess={addNewUploadToState}
        parentRelType="Entity"
        parentUuid={entityData.uuid}
        bpOrganizationUuid={entityData.bp_organization_uuid}
      />
      <Segment>{tableBody}</Segment>
      {openDocApproval ? (
        <DocApprovalModal
          approvalType="Document"
          commentFormField=""
          ticklerDetails={selectedCreditDoc}
          open
          docDetails={selectedDocument}
          onClose={toggleDocApprovalModal}
          onSave={onSave}
          institutionUuid={institutionUuid}
        />
      ) : (
        ""
      )}
    </Segment.Group>
  );
}

CreditDocument.propTypes = {
  addNewUploadToState: PropTypes.func.isRequired,
  creditDocsFilter: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  entityData: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    institution_uuid: PropTypes.string.isRequired,
    bp_organization_uuid: PropTypes.string
  }).isRequired,
  handleCreditDocSearch: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  openDocApproval: PropTypes.bool.isRequired,
  selectedCreditDoc: PropTypes.shape().isRequired,
  selectedDocument: PropTypes.shape({
    category: PropTypes.string,
    documentName: PropTypes.string,
    description: PropTypes.string,
    comments: PropTypes.arrayOf(PropTypes.object),
    status: PropTypes.string,
    docCategory: PropTypes.string,
    docType: PropTypes.string,
    docDate: PropTypes.string,
    periodStart: PropTypes.string,
    periodEnd: PropTypes.string,
    uploadDate: PropTypes.string
  }).isRequired,
  institutionUuid: PropTypes.string,
  toggleDocApprovalModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  selectedCreditDoc: state.CreditManagementReducer.creditDoc,
  data: state.CreditManagementReducer.data,
  openDocApproval: state.CreditManagementReducer.docApprovalModal
});

export default connect(mapStateToProps)(CreditDocument);
