import React from "react";
import { Table, Radio, Dropdown } from "semantic-ui-react";
import PropTypes from "prop-types";

import { prettyDate } from "../../../../../../../../../../services/DateTime";

const statusOptions = [
  { text: "Completed", value: "Completed" },
  { text: "In Progress", value: "In Progress" },
  { text: "Archived", value: "Archived" }
];

function Financial(props) {
  return (
    <Table.Row
      key={props.data.uuid}
      active={props.data.uuid === props.statementSelected}
    >
      <Table.Cell>
        <Radio
          name="selection"
          value={props.data.uuid}
          checked={props.statementSelected === props.data.uuid}
          onClick={event => props.handleRowClick(event, props.data)}
        />
      </Table.Cell>
      <Table.Cell>
        {`${props.data.startDate ? prettyDate(props.data.startDate) : ""} -
      ${props.data.endDate ? prettyDate(props.data.endDate) : ""}`}
      </Table.Cell>
      <Table.Cell>{props.data.analystName}</Table.Cell>
      {/* TODO: eventually analyst name should be looked up from uuid */}
      <Table.Cell>
        {props.data.createdDate &&
          new Date(props.data.createdDate).toLocaleDateString()}
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          placeholder="Select Status"
          options={statusOptions}
          onChange={(event, item) =>
            props.handleChange(event, item, props.data)
          }
          value={props.data.status}
        />
      </Table.Cell>
    </Table.Row>
  );
}

Financial.propTypes = {
  data: PropTypes.shape({
    uuid: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    analystName: PropTypes.string,
    createdDate: PropTypes.string,
    status: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  statementSelected: PropTypes.string.isRequired
};

export default Financial;
