import React from "react";
import PropTypes from "prop-types";
import { FormatCurrency } from "num-format";
import { Button, Grid, Header, Icon } from "semantic-ui-react";
import { ConcatName as getEntityName } from "../../../../../../services/Entities";
import LightHeader from "../../../../../../components/LightHeader";

function BasicInformation({ entity, readOnly, onBasicInfoEdit }) {
  const sectionHeaderMargin = { paddingTop: "8px" };
  return (
    <>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column style={sectionHeaderMargin}>
            <Header
              as="h3"
              content={`Basic Information – ${getEntityName(entity)}`}
            />
          </Grid.Column>

          <Grid.Column>
            {!readOnly && (
              <Button
                basic
                circular
                icon
                floated="right"
                onClick={onBasicInfoEdit}
                aria-label="edit basic information"
              >
                <Icon
                  name="pencil alternate"
                  color={readOnly ? "grey" : "green"}
                />
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid columns={9}>
        <Grid.Row>
          <Grid.Column>
            <LightHeader>Entity Type</LightHeader>
            <b>{entity.entity_type}</b>
          </Grid.Column>
          <Grid.Column>
            <LightHeader>State of Registration</LightHeader>
            <b>{entity.state_of_reg}</b>
          </Grid.Column>
          <Grid.Column width={3}>
            <LightHeader>County/Parish of Registration</LightHeader>
            <b>{entity.county_of_reg}</b>
          </Grid.Column>
          <Grid.Column>
            <LightHeader>TIN</LightHeader>
            <b>{entity.tin}</b>
          </Grid.Column>
          <Grid.Column>
            <LightHeader>Business Start Date</LightHeader>
            <b>{entity.business_start_date}</b>
          </Grid.Column>
          <Grid.Column>
            <LightHeader>Years In Business</LightHeader>
            <b>{entity.years_in_business}</b>
          </Grid.Column>
          <Grid.Column>
            <LightHeader>Tax Reporting Form:</LightHeader>
            <b>{entity.tax_reporting_form}</b>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {entity.entity_type === "Individual" ? (
            ""
          ) : (
            <Grid.Column width={8} style={{ marginRight: "2.5em" }}>
              <LightHeader>Industry Type</LightHeader>
              <b>{entity.industry_type}</b>
            </Grid.Column>
          )}
          <Grid.Column>
            <LightHeader>Is Operating Entity:</LightHeader>
            <b>{entity.is_operating_entity ? "yes" : "no"}</b>
          </Grid.Column>
          <Grid.Column>
            <LightHeader>Annual Revenue:</LightHeader>
            <b>
              {FormatCurrency(
                entity.annual_revenue ? entity.annual_revenue : 0
              )}
            </b>
          </Grid.Column>
          <Grid.Column>
            <LightHeader>Fiscal Year End:</LightHeader>
            <b>{entity.fiscal_year_end}</b>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

BasicInformation.propTypes = {
  entity: PropTypes.shape({
    entity_type: PropTypes.string,
    state_of_reg: PropTypes.string,
    county_of_reg: PropTypes.string,
    tin: PropTypes.string,
    business_start_date: PropTypes.string,
    years_in_business: PropTypes.number,
    tax_reporting_form: PropTypes.string,
    industry_type: PropTypes.string,
    is_operating_entity: PropTypes.string,
    annual_revenue: PropTypes.number,
    fiscal_year_end: PropTypes.string
  }),
  readOnly: PropTypes.bool,
  onBasicInfoEdit: PropTypes.func
};

export default BasicInformation;
