import "@bafsllc/style";
import "./index.css";

import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";

import Commotion from "./Commotion";
import version from "./version.json";
import {
  getEnv,
  getSentrySampleRate,
  getSentrySampleReplayRate
} from "./environment";
import { environment as env } from "./environments/environment";
import { initFlowbite } from "flowbite";

const detectedEnv = getEnv();
Sentry.init({
  release: version.commit || "",
  dsn: env.sentryDsn,
  environment: detectedEnv,
  tracesSampleRate: getSentrySampleRate(),
  replaysSessionSampleRate: getSentrySampleReplayRate(),
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Sentry.Replay()]
});

window.version = {
  commit: "local"
};

// eslint-disable-next-line no-console
console.log(`Commotion: commit='${version.commit}' running in: ${detectedEnv}`);

const flagsmithOptions = {
  environmentID: env.flagsmithKey,
  cacheFlags: true,
  api: "https://fs.bafs.com/api/v1/"
};

function AppRoot() {
  useEffect(() => initFlowbite(), []);
  return (
    <FlagsmithProvider options={flagsmithOptions} flagsmith={flagsmith}>
      <Commotion spa />
    </FlagsmithProvider>
  );
}

ReactDOM.render(<AppRoot />, document.getElementById("root"));
