import React from "react";
import PropTypes from "prop-types";

import { Button, Comment, Form, Grid, Segment } from "semantic-ui-react";

import { prettyDate } from "../../../../../../services/DateTime";

function TicklerDetails(props) {
  return (
    <div>
      <Segment attached color="grey" inverted size="large">
        Tickler Details
      </Segment>
      <Segment className="attached fluid">
        <p>
          <strong>Category</strong>
          <br />
          {props.docDetails.parentRelType === "Entity"
            ? "Credit"
            : "Collateral"}
        </p>

        <p>
          <strong>Document Name</strong>
          <br />
          {props.docDetails.documentName}
        </p>

        <p>
          <strong>Entity/Loan No./Asset</strong>
          <br />
          {props.docDetails.parentRelType}
        </p>

        <p>
          <strong>Description</strong>
          <br />
          {props.docDetails.description}
        </p>

        <Segment className="fluid">
          <strong>Comments</strong>
          <br />
          {props.messages.map(obj => (
            <Grid.Row columns="1" key={obj.uuid}>
              <Grid.Column width="15">
                <Comment>
                  <Comment.Content>
                    <Comment.Author>
                      <b>
                        {props.messagesNames[obj.uuid] ||
                          props.messagesNames[obj.user_uuid] ||
                          "Not Found"}
                      </b>
                      {`${prettyDate(obj.date, true)}`}
                    </Comment.Author>
                    <Comment.Text>{obj.message}</Comment.Text>
                  </Comment.Content>
                </Comment>
              </Grid.Column>
            </Grid.Row>
          ))}
          <br />
          <Grid.Row columns={1}>
            <Grid.Column width="16">
              <Form>
                <Form.TextArea
                  onChange={(e, data) => props.commentOnChange(data)}
                  placeholder="New Comment"
                  value={props.commentFormField}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
          <br />
          <Grid width={2}>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Button fluid onClick={props.commentAdd} size="tiny">
                  Add Comment
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Segment>
    </div>
  );
}

TicklerDetails.propTypes = {
  commentFormField: PropTypes.string.isRequired,
  commentOnChange: PropTypes.func.isRequired,
  commentAdd: PropTypes.func.isRequired,
  docDetails: PropTypes.shape({
    category: PropTypes.string,
    comments: PropTypes.arrayOf(PropTypes.object),
    description: PropTypes.string,
    documentName: PropTypes.string,
    parentRelType: PropTypes.string,
    uuid: PropTypes.string
  }).isRequired,
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  messagesNames: PropTypes.objectOf(PropTypes.string).isRequired
};

export default TicklerDetails;
