import React, { useState } from "react";
import PropTypes from "prop-types";
import { Segment, Icon, Label, Header, Button } from "semantic-ui-react";
import { FormatCurrency } from "num-format";
import DepositsTable from "../DepositsTable";
import FeesChargesTable from "../FeesChargesTable";
import AddDepositModal from "../AddDepositModal";
import AddFeesChargesModal from "../AddFeesChargesModal";
import { ConfirmationModal } from "../../../../../../components/Modals";
import { permCheck } from "../../../../../../services/Auth";

function SettlementTable({
  settlementData,
  entity,
  index,
  onAddSettlement,
  onUpdateSettlement
}) {
  const [collapsed, setCollapsed] = useState(true);
  const iconName = collapsed ? "chevron down" : "chevron up";
  const [editRow, setEditSettlement] = useState({});
  const [openDeposit, setOpenDeposit] = useState(false);
  const [openFees, setOpenFees] = useState(false);

  const [openEditDeposit, setOpenEditDeposit] = useState(false);
  const [openEditFees, setOpenEditFees] = useState(false);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [docToToggle, setDocToToggle] = useState(null);
  const calculateSettlementAmount = () => {
    const filteredData = settlementData.filter(
      rec => rec.item_type === "Charges and Fees" || rec.item_type === "Deposit"
    );
    if (filteredData.length === 1) {
      return filteredData[0].item_type === "Deposit"
        ? filteredData[0].amount
        : -filteredData[0].amount;
    }
    if (filteredData.length === 2) {
      return filteredData[0].item_type === "Deposit"
        ? filteredData[0].amount - filteredData[1].amount
        : filteredData[1].amount - filteredData[0].amount;
    }
    return 0;
  };
  return (
    <Segment padded>
      <div
        className="ui grid cursor-pointer"
        data-testid="loan-index-panel"
        role="button"
        tabIndex={0}
        onKeyDown={() => setCollapsed(!collapsed)}
        onClick={() => setCollapsed(!collapsed)}
      >
        <div className="ten wide column">
          <Header>
            {`Loan #${index}`}{" "}
            <Label
              color="blue"
              content={
                entity.original_amount != null
                  ? `Settlement Balance: ${FormatCurrency(
                      calculateSettlementAmount()
                    )}`
                  : "N/A"
              }
            />
          </Header>
        </div>
        <div className="six wide column right aligned">
          <Icon name={iconName} />
        </div>
      </div>
      {!collapsed ? (
        <>
          {permCheck(["show_borrower_deposits"]) && (
            <div style={{ marginTop: "20px" }}>
              <div className="ui grid padded">
                <div className="ten wide column middle verticalAlign">
                  <h3 className="ui header">
                    <div className="content">Deposits</div>
                  </h3>
                </div>
                <div className="six wide column right aligned">
                  <Button
                    basic
                    circular
                    color="green"
                    icon="plus"
                    data-testid="deposit-add-btn"
                    onClick={() => setOpenDeposit(true)}
                  />
                </div>
                <AddDepositModal
                  key="deposit-add-modal"
                  header="Add Settlement Deposit"
                  onSave={onAddSettlement}
                  open={openDeposit}
                  onClose={() => setOpenDeposit(false)}
                />
                {openEditDeposit && (
                  <AddDepositModal
                    key="deposit-edit-modal"
                    header="Edit Settlement Deposit"
                    onSave={onUpdateSettlement}
                    open={openEditDeposit}
                    defaultValues={editRow}
                    onClose={() => {
                      setEditSettlement({});
                      setOpenEditDeposit(false);
                    }}
                  />
                )}
              </div>
              <DepositsTable
                docs={settlementData.filter(rec => rec.item_type === "Deposit")}
                onEdit={row => {
                  setEditSettlement(row);
                  setOpenEditDeposit(true);
                }}
              />
            </div>
          )}
          <div style={{ marginTop: "20px" }}>
            <div className="ui grid padded">
              <div className="ten wide column middle verticalAlign">
                <h3 className="ui header">
                  <div className="content">Fees and Charges</div>
                </h3>
              </div>
              <div className="six wide column right aligned">
                <Button
                  basic
                  circular
                  color="green"
                  icon="plus"
                  data-testid="fees-add-btn"
                  onClick={() => setOpenFees(true)}
                />
              </div>
              <AddFeesChargesModal
                key="fees-add-modal"
                header="Add Settlement Fees & Charges"
                onSave={onAddSettlement}
                open={openFees}
                onClose={() => setOpenFees(false)}
              />
              {openEditFees && (
                <AddFeesChargesModal
                  key="fees-edit-modal"
                  header="Edit Settlement Fees & Charges"
                  onSave={onUpdateSettlement}
                  open={openEditFees}
                  defaultValues={editRow}
                  onClose={() => {
                    setEditSettlement({});
                    setOpenEditFees(false);
                  }}
                />
              )}
              <ConfirmationModal
                open={confirmModalIsOpen}
                yesButtonLabel="Edit"
                showLogo={false}
                handleCancel={() => setConfirmModalIsOpen(false)}
                handleConfirm={() => {
                  if (docToToggle) {
                    onUpdateSettlement(docToToggle);
                  }
                  setConfirmModalIsOpen(false);
                }}
                prompt="Edit Settlement Data"
                warningDetails="Are you sure you want to edit this data?"
                size="tiny"
              />
            </div>
            <FeesChargesTable
              docs={settlementData.filter(
                rec => rec.item_type === "Charges and Fees"
              )}
              onEdit={row => {
                setEditSettlement(row);
                setOpenEditFees(true);
              }}
              onToggle={(uuid, column, value) => {
                const updateData = { uuid };
                setConfirmModalIsOpen(true);
                updateData[column] = value;
                setDocToToggle(updateData);
              }}
            />
          </div>
        </>
      ) : null}
    </Segment>
  );
}

SettlementTable.propTypes = {
  settlementData: PropTypes.arrayOf(PropTypes.object),
  entity: PropTypes.shape({
    original_amount: PropTypes.number
  }),
  index: PropTypes.number.isRequired,
  onAddSettlement: PropTypes.func.isRequired,
  onUpdateSettlement: PropTypes.func.isRequired
};

export default SettlementTable;
