import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import { ConcatName as getEntityName } from "../../../../../../../../../../../../services/Entities";

export default function HoldingsSubsidiariesTable({ holdingsSubsidiaries }) {
  return holdingsSubsidiaries.length ? (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width="1">Business Name</Table.HeaderCell>
          <Table.HeaderCell width="1">Ownership Percentage</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {holdingsSubsidiaries.map(holdingSubsidiary => (
          <Table.Row key={holdingSubsidiary.uuid}>
            <Table.Cell>
              <a
                href={`/credit_management/entity/${holdingSubsidiary.uuid}`}
                rel="noreferrer noopener"
                target="_blank"
              >
                <i className="linkify icon" />
                {holdingSubsidiary.company_name ||
                  getEntityName(holdingSubsidiary)}
              </a>
            </Table.Cell>
            <Table.Cell>
              <h5>{`${holdingSubsidiary.percent_owned * 100}%`}</h5>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  ) : null;
}

HoldingsSubsidiariesTable.propTypes = {
  holdingsSubsidiaries: PropTypes.arrayOf(PropTypes.object).isRequired
};
