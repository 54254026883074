import PropTypes from "prop-types";
import { Menu } from "semantic-ui-react";

function TabBar(props) {
  return (
    <Menu pointing secondary className="m-0 bg-white h-6">
      {props.items.map(item => (
        <Menu.Item
          id={item}
          key={item}
          className="py-4"
          active={props.activeItem === item}
          name={item}
          onClick={() => props.onClick(item)}
        />
      ))}
    </Menu>
  );
}

TabBar.propTypes = {
  activeItem: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired
};

export default TabBar;
