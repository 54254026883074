import Constants from "../../../../../../../../../../services/Constants/strings";

const tableHeaders = {
  "Financial Statements": [
    Constants.SELECT,
    Constants.STATEMENT_DATE,
    Constants.STATEMENT_TYPE,
    Constants.PERIOD,
    Constants.START_DATE,
    Constants.END_DATE,
    Constants.ANALYST,
    Constants.CREATED_DATE,
    Constants.FINANCIAL_MODEL,
    Constants.STATUS,
    Constants.ADD_TO_VIEW
  ],
  "Financial Analysis": [
    Constants.SELECT,
    Constants.ANALYSIS_PERIOD,
    Constants.ANALYST,
    Constants.CREATED_DATE,
    Constants.STATUS
  ],
  "Credit Request": [
    Constants.APP_ID,
    Constants.CLASS,
    Constants.TYPE,
    Constants.AMOUNT_REQUESTED,
    Constants.STATUS,
    Constants.UNDERWRITING_REC,
    Constants.INSTITUTION_DECISION,
    Constants.CREDIT_MEMO
  ],
  Loans: [
    Constants.BAFS_ACCOUNT_NUMBER,
    Constants.INSTITUTION_ACCOUNT_NUMBER,
    Constants.ACCOUNT_NUMBER_FULL,
    Constants.CURRENT_BALANCE,
    Constants.AVAILABLE_BALANCE,
    Constants.TOTAL_EXPOSURE,
    Constants.ACCOUNT_TYPE,
    Constants.LOAN_CLASS,
    Constants.LOAN_TYPE,
    Constants.FACILITY_GRADE,
    Constants.DEAL_RATING,
    Constants.STATUS,
    Constants.PARTICIPATION
  ]
};

export default tableHeaders;
