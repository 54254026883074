import ConvertToNumber from "../ConvertToNumber";

const isEmpty = (assets, collateral, loanRequests) =>
  !!(
    !Object.keys(loanRequests).length ||
    !Object.keys(collateral).length ||
    !Object.keys(assets).length
  );

export const getTransformedCollateral = collaterals => {
  const transformedCollateral = {};
  Object.values(collaterals).forEach(collatArray => {
    collatArray.forEach(c => {
      transformedCollateral[c.uuid] = c;
    });
  });
  return transformedCollateral;
};

const getCoverageAmountForACollateral = (asset, collat) => {
  const reqMarketValue = ConvertToNumber(asset.market_value);
  const reqDiscountValue = ConvertToNumber(collat.advance_rate);
  const reqPriorLiens = ConvertToNumber(collat.prior_liens);

  const coverageAmount = reqMarketValue * reqDiscountValue - reqPriorLiens;
  return {
    reqMarketValue,
    reqPriorLiens,
    coverageAmount,
    reqDiscountValue
  };
};

const getLtvAndDesr = (
  reqCombinedMarketValue,
  reqCombinedPriorLiens,
  reqSumCoverageAmount,
  loanReq
) => {
  const marketValueLessPriorLiens =
    reqCombinedMarketValue - reqCombinedPriorLiens;
  const loanReqLtv =
    marketValueLessPriorLiens > 0
      ? loanReq.original_amount / marketValueLessPriorLiens
      : 100;
  const loanReqDscr =
    loanReq.original_amount > 0
      ? reqSumCoverageAmount / loanReq.original_amount
      : 0;

  const loanAmount = ConvertToNumber(loanReq.original_amount);
  return { loanReqLtv, loanReqDscr, loanAmount };
};

const getCombinedValuesList = (loanReqCollats, assets) => {
  let reqCombinedPriorLiens = 0;
  let reqCombinedMarketValue = 0;
  let reqSumCoverageAmount = 0;
  Object.keys(loanReqCollats).forEach(cUuid => {
    const collat = loanReqCollats[cUuid];
    const asset = assets[collat.asset_uuid] || {};

    const { reqMarketValue, reqPriorLiens, coverageAmount } =
      getCoverageAmountForACollateral(asset, collat);

    reqCombinedMarketValue += reqMarketValue;
    reqCombinedPriorLiens += reqPriorLiens;
    reqSumCoverageAmount += coverageAmount;
  });

  return {
    reqCombinedPriorLiens,
    reqCombinedMarketValue,
    reqSumCoverageAmount
  };
};
const getCombinedValuesUpdate = (collaterals, assets, loanUuid, modalData) => {
  let marketValue = 0;
  let priorLiens = 0;
  let coverageValue = 0;

  if (collaterals[loanUuid]) {
    collaterals[loanUuid].forEach(collat => {
      if (collat.uuid !== modalData.collat.uuid) {
        const { reqMarketValue, reqPriorLiens, coverageAmount } =
          getCoverageAmountForACollateral(assets[collat.asset_uuid], collat);

        marketValue += reqMarketValue;
        priorLiens += reqPriorLiens;
        coverageValue += coverageAmount;
      }
    });
  }
  const { reqMarketValue, reqPriorLiens, coverageAmount } =
    getCoverageAmountForACollateral(modalData.asset, modalData.collat);

  marketValue += reqMarketValue;
  priorLiens += reqPriorLiens;
  coverageValue += coverageAmount;

  return {
    marketValue,
    priorLiens,
    coverageValue
  };
};

const processLoanReqCollatForCombinedValues = (
  loanReqCollats,
  assets,
  loanUuid = null,
  modalData = [],
  isUpdate = false
) => {
  if (isUpdate) {
    const { marketValue, priorLiens, coverageValue } = getCombinedValuesUpdate(
      loanReqCollats,
      assets,
      loanUuid,
      modalData
    );
    return {
      marketValue,
      priorLiens,
      coverageValue
    };
  }
  const {
    reqCombinedPriorLiens,
    reqCombinedMarketValue,
    reqSumCoverageAmount
  } = getCombinedValuesList(loanReqCollats, assets);
  return {
    reqCombinedPriorLiens,
    reqCombinedMarketValue,
    reqSumCoverageAmount
  };
};

export default (assets, collateral, loanRequests) => {
  let sumloanReqLtv = 0;
  let sumloanReqDscr = 0;

  const perLoan = Object.keys(loanRequests).map(loanReqUuid => {
    const loanReq = loanRequests[loanReqUuid];
    if (isEmpty(assets, collateral, loanRequests)) {
      return {};
    }

    const loanReqCollatUuids = Object.keys(collateral).filter(
      cUuid => collateral[cUuid].loan_uuid === loanReqUuid
    );
    const loanReqCollats = {};
    loanReqCollatUuids.forEach(u => {
      loanReqCollats[u] = collateral[u];
    });

    const {
      reqCombinedPriorLiens,
      reqCombinedMarketValue,
      reqSumCoverageAmount
    } = processLoanReqCollatForCombinedValues(loanReqCollats, assets);
    const { loanReqLtv, loanReqDscr } = getLtvAndDesr(
      reqCombinedMarketValue,
      reqCombinedPriorLiens,
      reqSumCoverageAmount,
      loanReq
    );
    sumloanReqLtv += loanReqLtv;
    sumloanReqDscr += loanReqDscr;
    return {
      uuid: loanReqUuid,
      loanReqLtv: Math.round(loanReqLtv * 1000) / 1000,
      loanReqDscr: Math.round(loanReqDscr * 1000) / 1000
    };
  });
  const overallLtv = Math.round((sumloanReqLtv / perLoan.length) * 1000) / 1000;
  const overallCoverage =
    Math.round((sumloanReqDscr / perLoan.length) * 1000) / 1000;

  return {
    overall: {
      overallLtv,
      overallCoverage
    },
    perLoan
  };
};

export const getOriginationDscrLtv = (
  loanUuid,
  modalData,
  loanRequests,
  collaterals,
  assets
) => {
  const loan = { ...loanRequests[loanUuid] };
  const { marketValue, priorLiens, coverageValue } =
    processLoanReqCollatForCombinedValues(
      collaterals,
      assets,
      loanUuid,
      modalData,
      true
    );
  const { loanReqLtv, loanReqDscr } = getLtvAndDesr(
    marketValue,
    priorLiens,
    coverageValue,
    loan
  );
  const originationDscr = Math.round(loanReqDscr * 1000) / 1000;
  const originalLtv = Math.round(loanReqLtv * 1000) / 1000;
  return { originationDscr, originalLtv, loan };
};
