import { createId, FormLabel } from "../FormLabel";
import { KeyboardEvent, SetStateAction, useState } from "react";
import { SingleValue } from "react-select";
import { CommonMessages } from "@blast-client/constants";
import {
  allowedKeys,
  dollarPlaceholderText,
  inputParentDivTestId,
  isAnInvalidCharacter,
  isSelectOrClipboardAction,
  isShiftPlusLateralArrowKey,
  matchFormatToDropdownValue,
  parentDivTestId,
  percentPlaceholderText,
  whichFormatIsSelected
} from "./services/utils";
import { DollarSign } from "./components/DollarSign";
import { FormatDropdown, formatOptions } from "./components/FormatDropdown";

const { DOLLAR_AMOUNT } = CommonMessages;

export const NumberInputWithLabel = ({
  dollarOption,
  dropdown,
  format,
  handleInputChange,
  labelText,
  onBlur = () => {},
  placeholderText,
  required = false,
  setFormat,
  testId,
  value
}: IntegerInputWithLabelProps) => {
  const [inputValue, setInputValue] = useState<string>(value || "");
  const [selectedDropdownValue, setSelectedDropdownValue] = useState<
    SingleValue<OptionType> | undefined
  >(
    format
      ? matchFormatToDropdownValue(format, formatOptions)
      : formatOptions[0]
  );

  const { dollarFormatSelected, percentageFormatSelected } =
    whichFormatIsSelected(dollarOption, selectedDropdownValue, dropdown);

  const checkForValidCharacters = (e: KeyboardEvent<HTMLInputElement>) => {
    if (
      isShiftPlusLateralArrowKey(e) ||
      allowedKeys.includes(e.key) ||
      isSelectOrClipboardAction(e)
    ) {
      return true;
    }
    if (
      isAnInvalidCharacter(e, dollarFormatSelected, percentageFormatSelected)
    ) {
      e.preventDefault();
      return false;
    }
    return false;
  };

  const handleChange = (option: SingleValue<OptionType>) => {
    setSelectedDropdownValue(option);
    if (option && setFormat) {
      setFormat(
        option.value === DOLLAR_AMOUNT ? "dollar" : option.value.toLowerCase()
      );
    }
  };

  if (dropdown) {
    selectedDropdownValue?.value === DOLLAR_AMOUNT
      ? (placeholderText = dollarPlaceholderText)
      : (placeholderText = percentPlaceholderText);
  }

  return (
    <div
      className="flex flex-col flex-1"
      data-testid={testId || parentDivTestId}
    >
      <FormLabel labelText={labelText} required={required} />
      <div
        className="border border-neutral-300 flex flex-row rounded"
        data-testid={inputParentDivTestId}
      >
        {dollarFormatSelected && <DollarSign />}
        <input
          className="flex-1 py-2 pr-2 pl-1"
          id={createId(labelText, "label")}
          onBlur={onBlur}
          onChange={e => {
            setInputValue(e.target.value);
            handleInputChange(e.target.value);
          }}
          onKeyDown={checkForValidCharacters}
          placeholder={placeholderText || labelText}
          type="text"
          value={inputValue}
        ></input>
        {dropdown && (
          <FormatDropdown
            onChange={handleChange}
            value={selectedDropdownValue}
          />
        )}
      </div>
    </div>
  );
};

interface IntegerInputWithLabelProps {
  dollarOption?: boolean;
  dropdown?: boolean;
  format?: string;
  handleInputChange: (value: string) => void;
  labelText: string;
  onBlur?: () => void;
  placeholderText?: string;
  required?: boolean;
  setFormat?: React.Dispatch<SetStateAction<string>>;
  testId?: string;
  value?: string;
}

export interface OptionType {
  label: string;
  value: string;
}
