export const financialModelOptions = [
  {
    text: "Standard Business",
    value: "20181120-585e-4dd8-92bb-b44d3bd275ae"
  },
  {
    text: "Individual",
    value: "20190207-f99c-4ebd-bf2d-4785ff7f9d17"
  },
  {
    text: "Tax Form 1065",
    value: "a3381263-aa6f-45cc-8989-6e422dad9bc9"
  }
];

export const financialModelInfo = {
  "20181120-585e-4dd8-92bb-b44d3bd275ae": {
    name: "Standard Business",
    uuid: "20181120-585e-4dd8-92bb-b44d3bd275ae"
  },
  "20190207-f99c-4ebd-bf2d-4785ff7f9d17": {
    name: "Individual",
    uuid: "20190207-f99c-4ebd-bf2d-4785ff7f9d17"
  },
  "a3381263-aa6f-45cc-8989-6e422dad9bc9": {
    name: "Tax Form 1065",
    uuid: "a3381263-aa6f-45cc-8989-6e422dad9bc9"
  }
};

export const creditMemoModelOptions = [
  // When changing this add them to the test results for creditMemoModelUuidToName
  {
    name: "Standard Memo",
    uuid: "cMemoMdl-e1b0-4b86-aa48-99034e1dc678",
    text: "Standard Memo",
    value: "cMemoMdl-e1b0-4b86-aa48-99034e1dc678"
  },
  {
    name: "Small Loan Analysis",
    uuid: "7f152e04-9570-4785-8256-de7605160dda",
    text: "Small Loan Analysis",
    value: "7f152e04-9570-4785-8256-de7605160dda"
  }
];

export const creditMemoModelUuidToName = () => {
  const names = {};
  creditMemoModelOptions.forEach(opt => {
    names[opt.uuid] = opt.text;
  });
  return names;
};

export const ifFinancialModelExists = model => {
  if (model === undefined || model === null) return "—";
  return `${model.name} | V${model.version}`;
};

const collateralCodes = {
  4: "DDA",
  5: "Savings",
  6: "Certificate of Deposit",
  7: "Life Insurance",
  8: "Bonds",
  9: "Publicly Traded Stocks",
  10: "Publicly Held Stocks",
  11: "Blanket UCC",
  12: "Business Receivables",
  13: "Business Inventory",
  14: "Equipment",
  15: "Fixtures",
  16: "Timber",
  17: "General Intangibles",
  18: "Motor Vehicles",
  19: "Marine",
  20: "Trailer",
  21: "Church",
  22: "Restaurant",
  23: "Billboards",
  24: "Boat Storage Facility",
  25: "Land/Lot",
  26: "Construction",
  27: "Farmland",
  28: "Single 1-4 Family Dwelling",
  29: "Manufactured Home",
  30: "Retail",
  31: "Office",
  32: "Warehouse",
  33: "Manufacturing",
  34: "Multifamily",
  35: "Multi Purpose",
  36: "Oil & Gas",
  37: "Unsecured",
  38: "Assignment of Credit Sales",
  39: "Mobile Home",
  40: "Day Care Facility",
  41: "Automotive Facility",
  42: "Convenience Store",
  43: "Other Real Estate",
  45: "Hotels",
  46: "Multi 1-4 Family Dwellings",
  47: "Medical Office",
  48: "Nursing Home & Assisted Living Center",
  49: "Mobile Home Park",
  50: "Commercial Land & Lot Development",
  51: "Residential Land & Lot Development",
  52: "Aircraft",
  53: "Unimproved Land",
  54: "Construction (1-4 Family Residential) Speculative",
  55: "Construction (1-4 Family Residential) Pre-Sold",
  56: "Timberland",
  57: "Hospital & Surgery Center",
  58: "Golf Course, Entertainment & Recreational Facility",
  59: "Poultry Farm",
  60: "Cattle - Feeder / Feed Lot",
  61: "Cattle - Cow / Calf Operation",
  62: "Dairy Farm",
  63: "Letter of Credit",
  64: "Motor Home",
  65: "Other Livestock",
  66: "Crops",
  67: "Farm Equipment",
  68: "18 Wheeler",
  69: "1-4 Dwelling Secondary",
  70: "1-4 Family Rental Property - No Slab",
  71: "1-4 Family Rental Property - On Slab",
  72: "1-4 Family Investment Property",
  73: "Car Wash",
  74: "Mini Storage Buildings"
};

const createOption = (code, text) => ({
  key: `${code} | ${text}`,
  text: `${code} | ${text}`,
  value: code
});

export function genCollateralCodesOptions(collateralCodesObj) {
  const options = [];

  Object.keys(collateralCodesObj).forEach(collateralCode => {
    options.push(createOption(collateralCode, collateralCodes[collateralCode]));
  });

  return options;
}

export const collateralCodesOptions =
  genCollateralCodesOptions(collateralCodes);
