import { connect } from "react-redux";
import { arrayOf, bool, func, object } from "prop-types";
import { Button, Checkbox, Label } from "semantic-ui-react";
import _ from "lodash";
import "../../index.css";

import { Notifications } from "../../../../services/ApiLib";
import NotificationTable from "../NotificationTable";
import ErrorReporter from "../../../../services/ErrorReporter";

export const markNotificationRead = uuid => {
  Notifications.markRead(
    () => {},
    () => ErrorReporter("An error occurred while marking notification read."),
    uuid
  );
};

export const markAllRead = notifications => {
  notifications.forEach(n => {
    if (!n.read) {
      markNotificationRead(n.uuid);
    }
  });
};

export function NotificationsSidebarObj({
  dispatch,
  includeRead,
  notifications
}) {
  return (
    <div className="notifications-sidebar h-100 w-100 p-4">
      <div className="pb-4 flex items-center justify-between">
        <div className="h3">Notifications</div>
        <div className="ml-3">
          <Label circular size="large">
            {_.filter(notifications, n => !n.read).length}
          </Label>
        </div>
      </div>

      <div className="pb-4 flex items-center justify-between">
        <Checkbox
          label="Include Read"
          checked={includeRead}
          onClick={() => dispatch({ type: "NOTIFICATIONS_TOGGLE_READ" })}
          toggle
        />

        <Button basic onClick={() => markAllRead(notifications)}>
          Mark All as Read
        </Button>
      </div>

      <NotificationTable
        notifications={notifications}
        markRead={uuid => {
          markNotificationRead(uuid);
          dispatch({ type: "NOTIFICATIONS_PANEL" });
        }}
      />
    </div>
  );
}

NotificationsSidebarObj.propTypes = {
  includeRead: bool.isRequired,
  notifications: arrayOf(object).isRequired,
  dispatch: func.isRequired
};

const mapStateToProps = state => ({
  includeRead: state.NotificationsReducer.includeRead,
  notifications: state.NotificationsReducer.notifications
});

export default connect(mapStateToProps)(NotificationsSidebarObj);
