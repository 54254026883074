import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import FetchService from "../../../FetchService";
import {
  ConcatQueryParams,
  PaginationParams,
  RemoveNullKeys
} from "../Utilities";
import { FormatUrlV2 } from "../../../FormatUrl";

function get(
  onSuccess,
  onError,
  pagination,
  loanUuid,
  scheduleUuid,
  queryParams,
  callbackData
) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(loanUuid, "string");
  TypeCheck(scheduleUuid, "string", false);
  TypeCheck(queryParams, "object", false);

  if (scheduleUuid && queryParams) {
    onError("Error: Invalid Filter Combination");
  }

  let appendToUrl = "";
  if (scheduleUuid) {
    appendToUrl += `/${scheduleUuid}`;
  }
  if (queryParams) {
    appendToUrl = ConcatQueryParams(queryParams);
  }
  if (pagination) {
    appendToUrl = PaginationParams(appendToUrl, pagination);
  }
  const url =
    FormatUrlV2(ApiEndpoints.loanInterestSchedules, { loan_uuid: loanUuid }) +
    appendToUrl;

  FetchService("GET", url, onSuccess, onError, null, callbackData);
}

function update(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  const url = FormatUrlV2(ApiEndpoints.loanInterestSchedule, {
    loan_uuid: body.loan_uuid,
    uuid: body.uuid
  });
  const convertedBody = RemoveNullKeys(body);

  FetchService("PUT", url, onSuccess, onError, convertedBody, callbackData);
}

function add(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  const url = FormatUrlV2(ApiEndpoints.loanInterestSchedules, {
    loan_uuid: body.loan_uuid
  });
  const convertedBody = RemoveNullKeys(body);
  FetchService("POST", url, onSuccess, onError, convertedBody, callbackData);
}

function remove(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  const url = FormatUrlV2(ApiEndpoints.loanInterestSchedule, {
    loan_uuid: body.loan_uuid,
    uuid: body.uuid
  });
  const convertedBody = RemoveNullKeys(body);
  FetchService("DELETE", url, onSuccess, onError, convertedBody, callbackData);
}

export default {
  get,
  update,
  add,
  remove
};
