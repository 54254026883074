import React, { useEffect, useState } from "react";
import {
  usePaymentScheduleQuery,
  PaymentScheduleParameters
} from "../../../../../../services/RTKQuery/paymentSchedules";

export const ReadSingleTest = ({ loanUuid, itemUuid, setStatusInParent }) => {
  const statusUnknown = "❓Unknown Status";
  const statusPass = "✅ Pass";
  const statusFail = "❌ Fail";
  const statusLoading = "Loading ...";

  const [statusReadSingle, setStatus] = useState(statusUnknown);
  const [detailsText, setDetailsText] = useState("");

  const filters: PaymentScheduleParameters = {
    uuid: itemUuid,
    loanUuid: loanUuid,
    filters: {}
  };

  const { data: paymentSchedule } = usePaymentScheduleQuery(filters);
  useEffect(() => {
    setStatus(statusLoading);
    if (paymentSchedule?.uuid && itemUuid === paymentSchedule?.uuid) {
      setStatus(statusPass);
      setDetailsText(`:  Matched record on: ${paymentSchedule?.uuid}`);
      setStatusInParent("Pass");
    } else {
      console.error("Fail: usePaymentScheduleQuery");
      setStatus(statusFail);
    }
  }, [itemUuid, paymentSchedule?.uuid, setStatusInParent]);

  return (
    <>
      <b>{statusReadSingle}</b>
      <small>{detailsText}</small>
    </>
  );
};
