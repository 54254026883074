import { blastApi, Tag } from "./blastApi";
import {
  ListResponse,
  PaginatedQueryOptions,
  SingleResponse,
  LoanApp
} from "./types";

import {
  extractData,
  createSingleTag,
  createListTags,
  filteredQuery
} from "./utilities";

const servicePath = "/loans/v1";

interface LoanAppQueryOptions extends PaginatedQueryOptions {
  institutionUuid: string;
  lookup: string;
}

const extendedApi = blastApi.injectEndpoints({
  endpoints: build => ({
    loanApp: build.query<LoanApp, string>({
      query: uuid => `${servicePath}/loan_apps/${uuid}`,
      providesTags: result => createSingleTag(result, Tag.LoanApp),
      transformResponse: (response: SingleResponse<LoanApp>) =>
        extractData<LoanApp>(response)
    }),
    loanApps: build.query<ListResponse<LoanApp>, LoanAppQueryOptions>({
      query: filters => filteredQuery(`${servicePath}/loan_apps`, filters),
      providesTags: results => createListTags(results, Tag.LoanApp)
    }),
    updateLoanApp: build.mutation<SingleResponse<LoanApp>, Partial<LoanApp>>({
      query: body => ({
        url: `${servicePath}/loan_apps/${body.uuid}`,
        method: "PUT",
        body: body
      }),
      invalidatesTags: [Tag.LoanApp]
    })
  }),
  overrideExisting: false
});

export const { useLoanAppQuery, useUpdateLoanAppMutation } = extendedApi;
