import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  DefaultCell,
  PropertyTable
} from "../../../../../../../../../../components/Tables";

function DealRatingTable({ loan }) {
  const rows = useMemo(
    () => [
      {
        Header: "Credit Risk Rating",
        accessor: `${
          loan.credit_risk_rating ? "credit_risk_rating" : "deal_rating"
        }`,
        primary: true,
        Cell: DefaultCell
      },
      {
        Header: "Collateral Code",
        accessor: "collateral_code",
        Cell: DefaultCell
      },
      {
        Header: "NAICS Code",
        accessor: "naics_code",
        Cell: DefaultCell
      },
      {
        Header: "Purpose",
        accessor: "purpose",
        Cell: DefaultCell
      },
      {
        Header: "5300 Code",
        accessor: "5300_code",
        Cell: DefaultCell
      },
      {
        Header: "Loan Officer",
        accessor: "loan_officer",
        Cell: DefaultCell
      }
    ],
    [loan.credit_risk_rating]
  );

  return <PropertyTable columnsConfig={rows} data={loan} />;
}

DealRatingTable.propTypes = {
  loan: PropTypes.shape({
    deal_rating: PropTypes.number,
    collateral_code: PropTypes.string,
    purpose: PropTypes.string,
    "5300_code": PropTypes.string,
    loan_officer: PropTypes.string,
    credit_risk_rating: PropTypes.number
  }).isRequired
};

export default DealRatingTable;
