import { FormattedMessage } from "react-intl";
import { format as formatDate } from "date-fns";

export function NotificationItem({
  item,
  onItemClicked
}: NotificationItemProps) {
  const { authorName, created, borrowerName, subject, unread } = item;

  const handleClick = () => onItemClicked && onItemClicked(item);

  return (
    <div className="notification-message">
      <div className="notification-title">
        <div>
          <span className="font-bold">
            <FormattedMessage
              id="NOTIFICATION_TITLE"
              values={{ authorName, borrowerName }}
            />
          </span>
        </div>
        {unread ? <i className="circle small red icon"></i> : null}
      </div>
      <div className="notification-date">
        <span className=" ui small grey text">
          {formatDate(created, "yyyy-MM-dd")}
        </span>
      </div>
      <div className="ui compact message">
        <p>{subject}</p>
      </div>
      <button className="ui basic button small" onClick={handleClick}>
        <FormattedMessage id="NOTIFICATION_DETAILS_BUTTON" />
      </button>
    </div>
  );
}

export type NotificationProps = {
  applicationUuid: string;
  author: string;
  authorName: string;
  borrowerName: string;
  created: Date;
  noteUuid: string;
  reader: string;
  subject: string;
  unread: boolean;
  uuid: string;
};

export type NotificationItemProps = {
  item: NotificationProps;
  onItemClicked?: (item: NotificationProps) => void;
};
