export const initialState = {
  errorDisplay: "",
  errorMessage: "",
  errorTriggered: false
};

// eslint-disable-next-line default-param-last
export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case "ERRORS_REPORT": {
      return {
        ...state,
        errorMessage: action.errorMessage,
        errorTriggered: true
      };
    }

    case "ERRORS_REPORT_V2": {
      return {
        ...state,
        errorMessage: action.errorMessage,
        errorTriggered: true,
        errorDisplay: action.errorDisplay
      };
    }

    case "ERRORS_CLEAR": {
      return {
        ...state,
        errorDisplay: "",
        errorMessage: "",
        errorTriggered: false
      };
    }

    default:
      return state;
  }
}
