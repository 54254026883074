import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Table, Icon, Button, Header, Segment } from "semantic-ui-react";
import { ObjectMetadata, Documents } from "../../services/ApiLib";
import { getFileMimeType } from "../../services/Constants/AllowedDocumentTypes";
import PaginationControls from "../../components/Pagination";
import logger from "../../services/logger";

const paginationName = "forms";

function Forms() {
  const [forms, setForms] = useState([]);
  const [lastPage, setLastPage] = useState(true);
  const { pagination } = useSelector(state => ({
    pagination: state.PaginationReducer[paginationName]
  }));
  const getForms = useCallback(async () => {
    try {
      const queryParams = { category: "Common", order_by: "doc_name" };
      const fetchedObject = await ObjectMetadata.asyncGetMetaData(
        queryParams,
        pagination
      );
      setLastPage(fetchedObject.metaData.last_page);
      setForms(fetchedObject.data);
    } catch (err) {
      logger.error(err);
    }
  }, [pagination]);
  useEffect(() => {
    getForms();
  }, [getForms, pagination]);
  const getMimeType = fileName => getFileMimeType(fileName).mimeType;
  const onDownload = async (uuid, fileType, fileName) => {
    await Documents.download({ uuid, common: true }, { fileType, fileName });
  };
  return (
    <>
      <Header as="h1">Forms</Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Form</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {forms.length
            ? forms.map(obj => (
                <Table.Row key={obj.uuid}>
                  <Table.Cell>{obj.doc_name}</Table.Cell>
                  <Table.Cell>
                    {" "}
                    <Button
                      data-testid="button"
                      basic
                      circular
                      floated="left"
                      icon={<Icon name="download" color="green" />}
                      onClick={() =>
                        onDownload(
                          obj.object_uuid,
                          getMimeType(obj.file_name),
                          obj.file_name
                        )
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))
            : null}
        </Table.Body>
      </Table>
      <Segment>
        <PaginationControls lastPage={lastPage} name={paginationName} />
      </Segment>
    </>
  );
}

export default Forms;
