import _ from "lodash";
import moment from "moment";
import TypeCheck from "typecheck-extended";

import { fixDateInput } from "../../../../services/DateTime";
import serializeRelationshipType from "../../../../services/Constants/serializeRelationshipType";
import { relationshipTypes } from "../../../../services/Constants/relationshipTypes";
import {
  loadCMSTable,
  addDocument,
  delDocument
} from "../../../../services/CMS";

const ticklerStatus = {
  Satisfied: "Overridden",
  Waived: "Waived"
};

export const initialState = {
  activeItem: "",
  creditDoc: {},
  data: [],
  documents: [],
  docApprovalModal: false,
  docName: "",
  docTrackingItem: "Ticklers",
  financialModelUuid: "",
  popup: "",
  isDuplicate: false,
  showNewDialog: false,
  entityInstitution: {},
  relLookupObj: {},
  selectedRowData: {
    accountingMethod: "",
    archive: 0,
    documentUuid: "",
    endDate: "",
    opinion: "",
    period: "",
    periodMetadata: {},
    sourceDocument: "",
    startDate: "",
    statementDate: "",
    statementType: "",
    status: "",
    editable: true,
    archived: false,
    uuid: ""
  },
  dupFormData: {
    accountingMethod: "",
    analystUuid: "",
    archive: 0,
    documentUuid: "",
    endDate: "",
    opinion: "",
    period: "",
    periodMetadata: {},
    sourceDocument: "",
    startDate: "",
    statementDate: "",
    statementType: "",
    status: "In Progress"
  },
  loadingTable: false,
  entityData: {},
  bpAccessControls: {
    editable_by_borrower: false
  },
  tempEntityData: {},
  errorMessage: "",
  addDupFormError: "",
  aggregateDebt: 0,
  bpOrgUserEmail: "",
  bpOrgUserActiveStatus: null,
  deleteDocError: "",
  showDeleteConfirmModal: false,
  showEditEntityModal: false,
  selectedPeriods: [],
  spreadsWindow: {},
  showArchivedEntries: false,
  farIncludeArchived: false,
  showClosedLoans: false,
  officers: [{}],
  owners: [],
  affiliates: [],
  otherCapacities: [],
  authorizedSigner: [],
  guarantors: [],
  guarantees: [],
  holdingsSubsidiaries: [],
  coborrowers: [],
  ownersFormData: {},
  officersFormData: {},
  othersFormData: {},
  relationshipsActiveItem: relationshipTypes.OWNER,
  relAddModalSearchString: "",
  assetModalNew: false,
  loanOfficers: [],
  loanModal: false,
  loanModalNew: false,
  loanModalInfo: {
    bafs_account_number: "",
    institution_account_number: "",
    current_balance: 0,
    available_balance: 0,
    current_contract_amount: 0,
    total_exposure: 0,
    account_type: "",
    loan_class: "",
    loan_type: "",
    facility_grade: "",
    deal_rating: null,
    status: "",
    participation_bought: false,
    participation_sold: false,
    due_date: "",
    phoenix_import: ""
  },
  memoModelStartedBlank: true,
  creditReqModalInfo: {
    loan_app_uuid: "",
    adjustment_period: "",
    amortization_period: "",
    app_id: "",
    institution_decision: "",
    loan_amount: 0,
    loan_class: "",
    loan_purpose: "",
    loan_rate: "",
    loan_rate_index: "",
    loan_rate_type: "",
    loan_type: "",
    num_payments: "",
    origination_fee: "",
    origination_fee_type: "",
    payment_period: "",
    payment_type: "",
    rate_ceiling: "",
    rate_floor: "",
    request_description: "",
    status: "",
    underwriter_recommendation: "",
    uuid: "",
    variable_rate_margin: ""
  },
  relationshipsSelectedEntity: "",
  relNewEntityType: "select entity",
  entities: [],
  creditDocsFilter: "",
  spreadsPath: "",
  // Assets
  showAssetAccessModal: false,
  assetAccessModalData: null
};

export default function CreditManagementReducer(state, action) {
  const safeState = state ?? initialState;
  switch (action.type) {
    case "CMS_UPDATE_SELECTED_DOCUMENT":
      return {
        ...safeState,
        docName: action.docName
      };

    case "CMS_UPDATE_ACTIVE_ITEM":
      return {
        ...safeState,
        activeItem: action.activeItem
      };
    case "CMS_POPULATE_TABLE": {
      return {
        ...safeState,
        activeItem: action.activeItem,
        data: action.data,
        loadingTable: false,
        statementSelected: ""
      };
    }

    case "CMS_LOAD_INITIAL_STATE_ON_UNMOUNT": {
      return initialState;
    }

    case "CMS_FAR_UPDATE_STATUS": {
      const { row } = action;
      const newData = _.cloneDeep(safeState.data);
      const rowIndex = newData.findIndex(r => r.uuid === row.uuid);
      newData[rowIndex] = row;

      return {
        ...safeState,
        data: newData
      };
    }

    case "CMS_FILTER_ARCHIVE_STATUS": {
      return {
        ...safeState,
        showArchivedEntries: !safeState.showArchivedEntries
      };
    }

    case "CMS_LOAN_TABLE_FILTER_CLOSED_LOANS": {
      return {
        ...safeState,
        showClosedLoans: !safeState.showClosedLoans
      };
    }

    case "CMS_LOAN_MODAL_UPDATE_INFO": {
      const newLoanModalInfo = _.cloneDeep(safeState.loanModalInfo);
      return {
        ...safeState,
        loanModalInfo: { ...newLoanModalInfo, ...action.payload }
      };
    }

    case "CMS_TOGGLE_LOAN_MODAL": {
      let { obj } = action;
      let loanModalNew = false;
      if (action.newItem) {
        obj = initialState.loanModalInfo;
        loanModalNew = true;
      }
      return {
        ...safeState,
        loanModal: !safeState.loanModal,
        loanModalInfo: obj,
        loanModalNew
      };
    }

    case "CMS_UPDATE_LOAN": {
      return { ...safeState, data: action.data };
    }

    case "CMS_LOAN_MODAL_RESET": {
      const newLoanModalInfo = initialState.loanModalInfo;

      return {
        ...safeState,
        loanModalInfo: newLoanModalInfo,
        loanModal: false
      };
    }

    case "CMS_CREDIT_REQUEST_SELECT_ROW": {
      return {
        ...safeState,
        statementSelected: action.value,
        creditReqModalInfo: action.rowData
      };
    }

    case "CMS_ERROR_LOAD_DOC_LIST": {
      return safeState;
    }

    case "CMS_TABLEVIEW_SELECT_ITEM": {
      return {
        ...safeState,
        statementSelected: action.value,
        selectedRowData: action.rowData,
        deleteDocError: ""
      };
    }

    case "CMS_ERROR_DELETE": {
      return {
        ...safeState,
        deleteDocError: action.errorMessage
      };
    }

    case "CMS_ERROR_ADD_DUP_FAIL": {
      return { ...safeState, addDupFormError: action.errorMessage };
    }

    case "CMS_ADD_DUP_DATA_ENTRY": {
      const dupFormDataCopy = { ...safeState.dupFormData };
      dupFormDataCopy[action.item] = action.value;
      if (action.item === "statementType" && action.value === "Projection") {
        dupFormDataCopy.sourceDocument = "Projection";
      }
      return { ...safeState, dupFormData: dupFormDataCopy };
    }

    case "CMS_ADD_DUP_SET_DUP_FORM_DATA": {
      return {
        ...safeState,
        dupFormData: { ...safeState.dupFormData, ...action.dupFormData }
      };
    }

    case "CMS_UPDATE_ASSET": {
      return { ...safeState, data: action.data };
    }

    case "CMS_TOGGLE_ASSET_ACCESS_MODAL": {
      return {
        ...safeState,
        showAssetAccessModal: action.showAssetAccessModal,
        assetAccessModalData: action.assetAccessModalData
      };
    }

    case "CMS_TABVIEW_BUTTON_DUPLICATE_CLICK": {
      const rowData = { ...safeState.selectedRowData };
      rowData.statementDate = rowData.statementDate
        ? new Date(fixDateInput(rowData.statementDate)).toLocaleDateString(
            "en-US"
          )
        : "";
      rowData.startDate = rowData.startDate
        ? new Date(fixDateInput(rowData.startDate)).toLocaleDateString("en-US")
        : "";
      rowData.endDate = rowData.endDate
        ? new Date(fixDateInput(rowData.endDate)).toLocaleDateString("en-US")
        : "";

      return {
        ...safeState,
        dupFormData: rowData,
        showNewDialog: true,
        isDuplicate: true,
        addDupFormError: ""
      };
    }

    case "CMS_TABVIEW_BUTTON_DUPLICATE_CANCEL": {
      const initialCopy = { ...initialState.dupFormData };
      return {
        ...safeState,
        docName: "",
        dupFormData: initialCopy,
        showNewDialog: false
      };
    }

    case "CMS_TABVIEW_BUTTON_NEW_CLICK": {
      const initialCopy = { ...initialState.dupFormData };
      return {
        ...safeState,
        dupFormData: initialCopy,
        showNewDialog: true,
        isDuplicate: false,
        addDupFormError: ""
      };
    }

    case "CMS_TABVIEW_BUTTON_NEW_CLOSE": {
      return {
        ...safeState,
        showNewDialog: false,
        addDupFormError: ""
      };
    }

    case "CMS_UPDATE_POPULATED_TABLE": {
      const newData = _.cloneDeep(safeState.data);

      if (newData[action.index]) {
        newData[action.index].analystName = action.name;
      }

      return {
        ...safeState,
        data: newData
      };
    }

    case "CMS_OPEN_AND_UPDATE_DUP_MODAL": {
      const dupFormDataCopy = { ...safeState.dupFormData };
      const document = action.documents[0];

      dupFormDataCopy[action.item] = action.value;
      if (action.item === "statementType" && action.value === "Projection") {
        dupFormDataCopy.sourceDocument = "Projection";
      }

      dupFormDataCopy.startDate = document.periodStart;
      dupFormDataCopy.endDate = document.periodEnd;
      dupFormDataCopy.statementDate = document.docDate;
      return {
        ...safeState,
        docName: action.docName,
        documents: action.documents,
        dupFormData: dupFormDataCopy,
        showNewDialog: true,
        isDuplicate: false,
        addDupFormError: ""
      };
    }

    case "CMS_ADD_DUP_ADD_SUBMIT": {
      const dupFormData = {
        ...safeState.dupFormData,
        institutionUuid: safeState.entityData.institution_uuid
      };
      addDocument(
        action.dispatch,
        action.jwt,
        dupFormData,
        safeState.entityData.uuid
      );
      return safeState;
    }

    case "CMS_SHOW_DELETE_CONFIRM_MODAL": {
      return { ...safeState, showDeleteConfirmModal: true };
    }

    case "CMS_HIDE_DELETE_MODAL": {
      return { ...safeState, showDeleteConfirmModal: false };
    }

    case "CMS_DELETE_CONFIRMED": {
      delDocument(
        action.dispatch,
        action.jwt,
        action.uuid,
        safeState.entityData.uuid
      );
      return { ...safeState, showDeleteConfirmModal: false };
    }

    case "CMS_DELETE_SUCCESSFUL": {
      loadCMSTable(
        action.dispatch,
        action.jwt,
        safeState.activeItem,
        safeState.entityData.uuid
      );
      return {
        ...safeState,
        statementSelected: "",
        selectedRowData: initialState.selectedRowData
      };
    }

    case "CMS_ENTITY_DATA_LOAD": {
      return { ...safeState, entityData: action.data };
    }

    case "CMS_LOAD_OFFICER": {
      let newEntityData = _.cloneDeep(safeState.entityData);
      newEntityData = { ...newEntityData, officer: action.name };
      return { ...safeState, entityData: newEntityData };
    }

    case "CMS_ENTITY_DATA_EDIT_MODAL_SUCCESS": {
      return {
        ...safeState,
        showEditEntityModal: action.open,
        errorMessage: ""
      };
    }

    case "CMS_ENTITY_DATA_EDIT_ERROR": {
      return { ...safeState, errorMessage: action.errorMessage };
    }

    case "CMS_AGGREGATE_DEBT_LOAD": {
      return { ...safeState, aggregateDebt: action.aggregateDebt };
    }

    case "CMS_BP_ORG_USER_EMAIL": {
      return { ...safeState, bpOrgUserEmail: action.bpOrgUserEmail };
    }

    case "CMS_BP_ORG_USER_ACTIVE_STATUS": {
      return {
        ...safeState,
        bpOrgUserActiveStatus: action.bpOrgUserActiveStatus
      };
    }

    case "CMS_BP_ACCESS_CONTROLS":
      return {
        ...safeState,
        bpAccessControls: action.bpAccessControls?.data
      };

    case "CMS_SPREADS_WINDOW": {
      return { ...safeState, spreadsWindow: action.win };
    }

    case "CMS_UPDATE_TICKLERS_DATA": {
      const newData = _.cloneDeep(safeState.data);
      let index;
      newData.forEach((obj, i) => {
        if (obj.uuid === action.rowUuid) {
          index = i;
        }
      });
      newData[index].state = action.value;
      newData[index].status = ticklerStatus[action.value];
      return { ...safeState, data: newData };
    }

    case "CMS_LOAD_DOCUMENTS":
      return { ...safeState, documents: action.documents };
    case "CMS_UPDATE_DOCUMENT": {
      const documents = safeState.data;
      const { doc } = action;
      const newDocuments = documents.map(document => {
        if (document.uuid === doc.uuid) {
          let newDoc = { ...document };
          newDoc = doc;

          return newDoc;
        }

        return document;
      });
      return { ...safeState, data: newDocuments };
    }

    case "CMS_UPDATE_DOC_TRACKING_ITEM":
      return { ...safeState, data: action.data, docTrackingItem: action.name };

    case "CMS_REL_SET_OWNER_DETAILS": {
      return {
        ...safeState,
        owners: action.relationshipOwners
      };
    }

    case "CMS_REL_SET_OTHERS_DETAILS": {
      return {
        ...safeState,
        otherCapacities: action.relationshipOthers
      };
    }

    case "CMS_REL_SET_AUTHORIZED_SIGNER": {
      return {
        ...safeState,
        authorizedSigner: action.relationshipSigner
      };
    }

    case "CMS_REL_SET_AFFILIATE_DETAILS": {
      return {
        ...safeState,
        affiliates: action.relationshipAffiliates
      };
    }

    case "CMS_REL_SET_OFFICER_DETAILS": {
      return {
        ...safeState,
        officers: action.relationshipOfficers
      };
    }

    case "CMS_REL_SET_GUARANTOR_DETAILS": {
      return {
        ...safeState,
        guarantors: action.relationshipGuarantors
      };
    }

    case "CMS_REL_SET_GUARANTEE_DETAILS": {
      return {
        ...safeState,
        guarantees: action.relationshipGuarantees
      };
    }

    case "CMS_REL_SET_HOLDING_SUBSIDIARY_DETAILS": {
      return {
        ...safeState,
        holdingsSubsidiaries: action.relationshipHoldingsSubsidiaries
      };
    }

    case "CMS_REL_SET_COBORROWER_DETAILS": {
      return {
        ...safeState,
        coborrowers: action.relationshipCoborrowers
      };
    }

    case "CMS_REL_SET_LOOKUP_DETAILS": {
      const nextRelLookupObj = { ...safeState.relLookupObj };
      nextRelLookupObj[action.entityData.uuid] = action.entityData;
      return {
        ...safeState,
        relLookupObj: nextRelLookupObj
      };
    }

    case "CMS_REL_EDIT_DATE_OWNERS": {
      TypeCheck(action.name, "string");

      const newOwners = _.cloneDeep(safeState.ownersFormData);

      newOwners[action.name] = action.value;
      return {
        ...safeState,
        ownersFormData: newOwners
      };
    }

    case "CMS_REL_EDIT_DATE_OFFICERS": {
      TypeCheck(action.name, "string");

      const newOfficers = _.cloneDeep(safeState.officersFormData);

      newOfficers[action.name] = action.value;
      return {
        ...safeState,
        officersFormData: newOfficers
      };
    }

    case "CMS_REL_EDIT_DATE_OTHERS": {
      TypeCheck(action.name, "string");

      const newOthers = _.cloneDeep(safeState.othersFormData);

      newOthers[action.name] = action.value;
      return {
        ...safeState,
        othersFormData: newOthers
      };
    }

    case "CMS_REL_MENU_ITEM":
      return {
        ...safeState,
        relationshipsActiveItem: action.menuItem
      };

    case "CMS_REL_UPDATE_OWNER_INFO": {
      const newRelInfo = _.cloneDeep(safeState.ownersFormData);

      newRelInfo[action.name] = action.value;

      return {
        ...safeState,
        ownersFormData: newRelInfo
      };
    }

    case "CMS_REL_UPDATE_OFFICER_INFO": {
      const newRelInfo = _.cloneDeep(safeState.officersFormData);

      newRelInfo[action.name] = action.value;

      return {
        ...safeState,
        officersFormData: newRelInfo
      };
    }

    case "CMS_REL_UPDATE_OTHERS_INFO": {
      const newRelInfo = _.cloneDeep(safeState.othersFormData);

      newRelInfo[action.name] = action.value;
      return {
        ...safeState,
        othersFormData: newRelInfo
      };
    }

    case "CMS_REL_UPDATE_OWNERS": {
      const newOwnerInfo = _.cloneDeep(safeState.owners);
      const { data } = action.data;

      newOwnerInfo[action.index].percent_owned = data.percent_owned;
      newOwnerInfo[action.index].voting_rights = data.voting_rights;
      newOwnerInfo[action.index].start_date = data.start_date;
      newOwnerInfo[action.index].end_date = data.end_date;
      return {
        ...safeState,
        owners: newOwnerInfo
      };
    }

    case "CMS_REL_UPDATE_OFFICERS": {
      const newOfficerInfo = _.cloneDeep(safeState.officers);
      const { data } = action.data;
      newOfficerInfo[action.index].title = data.title;
      newOfficerInfo[action.index].start_date = data.start_date;
      newOfficerInfo[action.index].end_date = data.end_date;
      return {
        ...safeState,
        officers: newOfficerInfo
      };
    }

    case "CMS_REL_UPDATE_OTHERS": {
      const newOthersInfo = _.cloneDeep(safeState.otherCapacities);
      const { data } = action.data;
      newOthersInfo[action.index].rel_type = serializeRelationshipType(
        data.rel_type
      );
      newOthersInfo[action.index].start_date = data.start_date;
      newOthersInfo[action.index].end_date = data.end_date;
      newOthersInfo[action.index].title = data.title;

      return {
        ...safeState,
        otherCapacities: newOthersInfo
      };
    }

    case "CMS_REL_EDIT_MODAL_OPEN": {
      const newOwnerInfo = _.cloneDeep(safeState.owners[action.index]);
      const newOfficerInfo = _.cloneDeep(safeState.officers[action.index]);
      const newOthersInfo = _.cloneDeep(
        safeState.otherCapacities[action.index]
      );

      return {
        ...safeState,
        ownersFormData: newOwnerInfo,
        officersFormData: newOfficerInfo,
        othersFormData: newOthersInfo
      };
    }

    case "CMS_REL_SELECTED_ENTITY":
      return {
        ...safeState,
        relationshipsSelectedEntity: action.selectedEntity
      };

    case "CMS_REL_CLEAR_SELECTED_ENTITY":
      return {
        ...safeState,
        relationshipsSelectedEntity: ""
      };

    case "CMS_REL_EDIT_MODAL_CLEAR_FORM":
      return {
        ...safeState,
        ownersFormData: {},
        officersFormData: {},
        othersFormData: {}
      };

    case "CMS_REL_SELECTED_ENTITY_TYPE":
      return {
        ...safeState,
        relNewEntityType: action.newEntityType
      };

    case "CMS_REL_LOAD_ENTITIES": {
      return {
        ...safeState,
        entities: action.data
      };
    }

    case "CMS_ADD_MODAL_UPDATE_SEARCH_STRING": {
      return {
        ...safeState,
        relAddModalSearchString: action.searchString
      };
    }

    case "CMS_ADD_MODAL_CLEAR_SEARCH_STRING": {
      return {
        ...safeState,
        relAddModalSearchString: ""
      };
    }

    case "CMS_TICKLER_UPDATE_DOCUMENT_UPLOAD": {
      const newData = _.cloneDeep(safeState.data);
      let index;
      newData.forEach((obj, i) => {
        if (obj.uuid === action.rowUuid) {
          index = i;
        }
      });
      newData[index].objectUuid = action.objectUuid;
      newData[index].status = action.status;
      return {
        ...safeState,
        data: newData
      };
    }

    case "CMS_TICKLER_DELETE_DOCUMENT_UPLOAD": {
      const newData = _.cloneDeep(safeState.data);
      let index;
      newData.forEach((obj, i) => {
        if (obj.uuid === action.rowUuid) {
          index = i;
        }
      });
      delete newData[index].file;
      return {
        ...safeState,
        data: newData
      };
    }

    case "CMS_SCHEDULE_EDIT_MODAL_SAVE": {
      const newData = _.cloneDeep(safeState.data);
      let index;
      newData.forEach((obj, i) => {
        if (obj.uuid === action.rowUuid) {
          index = i;
        }
      });
      newData[index] = action.form;
      return {
        ...safeState,
        data: newData
      };
    }

    case "CMS_SCHEDULE_NEW_MODAL_SAVE": {
      const newData = _.cloneDeep(safeState.data);
      newData.push(action.form);
      return {
        ...safeState,
        data: newData
      };
    }

    case "CMS_SCHEDULE_UPDATE_TOGGLE": {
      const copyData = _.cloneDeep(safeState.data);
      for (let i = 0; i < copyData.length; i += 1) {
        copyData[i].state = action.state;
      }
      return {
        ...safeState,
        data: copyData
      };
    }

    case "CMS_CREDIT_DOCUMENTS_SEARCH_FILTER": {
      return {
        ...safeState,
        creditDocsFilter: action.searchInfo.value
      };
    }

    case "CMS_CREDIT_DOCUMENTS_UPLOAD_FILE": {
      const newData = Object.assign([], safeState.data);
      newData.push(action.fileInfo);

      return {
        ...safeState,
        data: newData
      };
    }

    case "CMS_TOGGLE_FAR_INCLUDE_ARCHIVE": {
      return { ...safeState, farIncludeArchived: action.include };
    }

    case "CMS_CREDIT_DOCS_TOGGLE_MODAL": {
      return {
        ...safeState,
        docApprovalModal: !safeState.docApprovalModal
      };
    }

    case "CMS_CREDIT_DOCS_SELECT_DOCUMENT": {
      return {
        ...safeState,
        creditDoc: action.row
      };
    }

    case "CMS_UPDATE_CREDIT_DOCUMENTS": {
      const newData = _.cloneDeep(safeState.data);
      let index;
      newData.forEach((obj, i) => {
        if (obj.uuid === action.data.uuid) {
          index = i;
        }
      });
      newData[index] = action.data;
      return {
        ...safeState,
        data: newData
      };
    }

    case "CMS_FINANCIAL_STATEMENTS_SELECT_PERIOD": {
      const selected = _.cloneDeep(safeState.selectedPeriods);
      const index = selected.indexOf(action.uuid);

      if (index > -1) {
        selected.splice(index, 1);
      } else {
        selected.push(action.uuid);
      }
      selected.sort((a, b) => {
        const aDate = moment(_.find(safeState.data, d => d.uuid === a).endDate);
        const bDate = moment(_.find(safeState.data, d => d.uuid === b).endDate);
        if (aDate.isBefore(bDate)) {
          return -1;
        }
        if (aDate.isAfter(bDate)) {
          return 1;
        }
        return 0;
      });
      return {
        ...safeState,
        selectedPeriods: selected,
        financialModelUuid: action.financialModelUuid
      };
    }

    case "CMS_ENTITY_DATA_DATABLOCK_LOOKUP_INSTITUTION": {
      return {
        ...safeState,
        entityInstitution: action.instData.data
      };
    }

    case "CMS_SPREADS_PARAMS": {
      return {
        ...safeState,
        spreadsPath: action.path,
        selectedPeriods: action.selectedPeriods
      };
    }
    case "CMS_ENTITY_LOAN_OFFICERS":
      return { ...safeState, loanOfficers: action.loanOfficers };

    default:
      return safeState;
  }
}
