import { arrayOf, bool, func, string } from "prop-types";
import React from "react";
import { Button, Dropdown, Modal } from "semantic-ui-react";

export function selectorModal(props) {
  const {
    cancelHandler,
    isOpen,
    options,
    placeholder,
    saveHandler,
    selectionHandler,
    title,
    value
  } = props;

  return (
    <Modal open={isOpen}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Dropdown
          fluid
          multiple
          onChange={selectionHandler}
          options={options}
          placeholder={placeholder}
          search
          selection
          value={value}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={saveHandler} primary>
          Save
        </Button>
        <Button onClick={cancelHandler} basic>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

selectorModal.defaultProps = {
  placeholder: "Financial Analysis Reports"
};

selectorModal.propTypes = {
  cancelHandler: func.isRequired,
  isOpen: bool.isRequired,
  options: arrayOf({}).isRequired,
  placeholder: string,
  saveHandler: func.isRequired,
  selectionHandler: func.isRequired,
  title: string.isRequired,
  value: arrayOf(string).isRequired
};

export default selectorModal;
