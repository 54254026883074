import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  DollarCell,
  DateCell,
  PropertyTable,
  DefaultCell
} from "../../../../../../../../../../components/Tables";
import DeferralEndDateCell from "./DeferralEndDateCell";

function NextPaymentTable({
  loan,
  nextScheduledPaymentAmount,
  paymentFrequency,
  openModal
}) {
  const isInDeferral = loan?.is_in_deferral;

  const columns = useMemo(
    () => [
      {
        Header: "Next Payment Date",
        accessor: "next_payment_date",
        primary: true,
        Cell: DateCell
      },
      {
        Header: "Next Scheduled Payment Amount",
        accessor: "nextScheduledPaymentAmount",
        Cell: DollarCell
      },
      {
        Header: "Payment Frequency",
        accessor: "paymentFrequency",
        Cell: DefaultCell
      },
      {
        Header: "Last Payment Date",
        accessor: "prev_payment_date",
        Cell: DateCell
      },
      {
        Header: "Last Payment Amount",
        accessor: "prev_payment_amount",
        Cell: DollarCell
      },
      ...(isInDeferral
        ? [
            {
              Header: "Payments Deferred Until",
              accessor: "deferral_end_date",
              Cell: DeferralEndDateCell
            }
          ]
        : [])
    ],
    [isInDeferral]
  );

  return (
    <PropertyTable
      columnsConfig={columns}
      data={{ ...loan, nextScheduledPaymentAmount, paymentFrequency }}
      openModal={openModal}
    />
  );
}

NextPaymentTable.propTypes = {
  loan: PropTypes.shape({
    next_payment_date: PropTypes.string,
    next_scheduled_payment_amount: PropTypes.number,
    payment_frequency: PropTypes.string,
    prev_payment_date: PropTypes.string,
    prev_payment_amount: PropTypes.number
  }).isRequired,
  nextScheduledPaymentAmount: PropTypes.number,
  paymentFrequency: PropTypes.string,
  openModal: PropTypes.func
};

export default NextPaymentTable;
