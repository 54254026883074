import PropTypes from "prop-types";
import React from "react";
import { Form } from "semantic-ui-react";

import {
  individualTypes,
  businessTypes
} from "../../../../../../services/FormElements/components/entityModalOptions";
import { NAICCodes } from "../../../../../../services/FormElements";

function EntityTypeForm({ handleInputChange }) {
  return (
    <Form>
      <Form.Group>
        <Form.Select
          name="entity_type"
          label="Entity Type"
          options={[...individualTypes, ...businessTypes]}
          width={5}
          onChange={(e, { value }) => {
            handleInputChange(e, { name: "entity_type", value });
            handleInputChange(e, {
              name: "citizenship_country",
              value: "United States"
            });
            if (value === "Individual") {
              const individualNaicsCode = 814110;
              handleInputChange(e, {
                name: "naics_code",
                value: individualNaicsCode
              });
              handleInputChange(e, {
                name: "industry_type",
                value: NAICCodes[individualNaicsCode]
              });
            } else {
              handleInputChange(e, { name: "naics_code", value: undefined });
              handleInputChange(e, { name: "industry_type", value: "" });
            }
          }}
        />
      </Form.Group>
    </Form>
  );
}

EntityTypeForm.propTypes = {
  handleInputChange: PropTypes.func.isRequired
};

export default EntityTypeForm;
