import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Message, Segment } from "semantic-ui-react";
import { Route } from "react-router-dom";
import { actionCreators } from "./reducer";
import tabs from "./tabs";
import PPPMenuBar from "./PPPMenuBar";
import { RemoveNullKeys } from "../../../../../../services/ApiLib/services/Utilities";
import { LoanPPPData } from "../../../../../../services/ApiLib";

const { PPP2483, PPPUnderwriting, PPP2484 } = tabs;

export const routeDetails = [
  {
    component: uuid => <PPPMenuBar loanAppUuid={uuid} />,
    key: "Menu Bar Shows on All Phases > PPP Pages",
    path: "/los/:uuid/phases/ppp"
  },
  {
    component: uuid => <PPP2483 loanAppUuid={uuid} />,
    key: "2483 Data",
    path: "/los/:uuid/phases/ppp/2483"
  },
  {
    component: uuid => <PPPUnderwriting loanAppUuid={uuid} />,
    key: "PPP Underwriting Data",
    path: "/los/:uuid/phases/ppp/underwriting"
  },
  {
    component: uuid => <PPP2484 loanAppUuid={uuid} />,
    key: "2484 Data",
    path: "/los/:uuid/phases/ppp/2484"
  }
];

export function PhasesPPPTabObj({
  loanRequest,
  entityDetails,
  pppFormFields,
  pppLoanUuid,
  pppError,
  setPPPFormFields,
  unsetPPPError,
  setLoanFormFields,
  setEntityFormFields,
  setPPPLoanUuid,
  setPppError,
  setIsSecondDraw
}) {
  useEffect(() => {
    if (
      pppLoanUuid ||
      !loanRequest ||
      !loanRequest.uuid ||
      !entityDetails ||
      !entityDetails.uuid
    ) {
      return;
    }

    setLoanFormFields({ ...loanRequest });
    setEntityFormFields({ ...entityDetails });

    (async () => {
      const res = await LoanPPPData.read({
        loan_uuid: loanRequest.uuid
      });

      if (res.data.length > 1) {
        setPppError("More than one PPP loan is attached.");
        return;
      }
      if (res.data.length < 1) {
        setPppError("No PPP loan is attached.");
        return;
      }
      setPPPFormFields({ ...pppFormFields, ...RemoveNullKeys(res.data[0]) });
      setIsSecondDraw(res.data[0].second_draw_ppp_loan);
      setPPPLoanUuid(res.data[0].uuid);
    })();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [loanRequest, entityDetails]);

  return (
    <Segment>
      {pppError && (
        <Message color="red" onDismiss={unsetPPPError} header={pppError} />
      )}
      {routeDetails.map(item => (
        <Route
          key={item.key}
          path={item.path}
          render={({ match }) => item.component(match.params.uuid)}
        />
      ))}
    </Segment>
  );
}

PhasesPPPTabObj.propTypes = {
  pppFormFields: PropTypes.shape(),
  loanRequest: PropTypes.shape(),
  entityDetails: PropTypes.shape(),
  pppLoanUuid: PropTypes.string,
  pppError: PropTypes.string,
  setPPPFormFields: PropTypes.func,
  setLoanFormFields: PropTypes.func,
  setEntityFormFields: PropTypes.func,
  unsetPPPError: PropTypes.func,
  setPPPLoanUuid: PropTypes.func,
  setPppError: PropTypes.func,
  setIsSecondDraw: PropTypes.func
};

const mapStateToProps = state => ({
  pppFormFields: state.PPPReducer.pppFormFields,
  pppLoanUuid: state.PPPReducer.pppLoanUuid,
  pppError: state.PPPReducer.pppError,
  loanRequest: state.LosPhasesReducer.loanRequest,
  entityDetails: state.LosPhasesReducer.entityDetails
});

const mapDispatchToProps = dispatch => ({
  setPPPFormFields: fields => dispatch(actionCreators.setPPPFormFields(fields)),
  setLoanFormFields: fields =>
    dispatch(actionCreators.setLoanFormFields(fields)),
  unsetPPPError: () => dispatch({ type: "PPP/SET_PPP_ERROR", payload: null }),
  setPPPLoanUuid: fields => dispatch(actionCreators.setPPPLoanUuid(fields)),
  setPppError: error => dispatch(actionCreators.setPppError(error)),
  setEntityFormFields: fields =>
    dispatch(actionCreators.setEntityFormFields(fields)),
  setIsSecondDraw: isSecondDraw =>
    dispatch(actionCreators.setIsSecondDraw(isSecondDraw))
});
export default connect(mapStateToProps, mapDispatchToProps)(PhasesPPPTabObj);
