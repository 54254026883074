import {
  DocumentPreview,
  useSignedEditorConfiguration
} from "@bafsllc/ui-shared";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";
import { getToken } from "../../../../../services/Auth";
import GenerateDocumentView from "./GenerateDocumentView";
import ApiEndpoints from "../../../../../services/ApiEndpoints";
import { getOnlyOfficeUrl } from "../../../../BlastEmbed/getBaseUrl";

export default function CreditAnalysisDocumentsTab({ generateDocument }) {
  const token = useMemo(() => getToken(), []);
  const { institutionUuid, selectedCreditAnalysis } = useSelector(state => ({
    institutionUuid:
      state?.LoanManagementReducer?.institution?.uuid ??
      state?.LosPhasesReducer?.entityDetails?.institution_uuid,
    selectedCreditAnalysis:
      state.CreditAnalysisManagementReducer.selectedCreditAnalysis
  }));
  const docxReportUuid = selectedCreditAnalysis.docx_report_uuid ?? null;
  //   const pdfReportUuid = selectedCreditAnalysis.pdf_report_uuid ?? null;
  const ooUrl = getOnlyOfficeUrl();
  const { baseUri } = ApiEndpoints;
  return (
    <>
      <h3 className="page-region-title">
        <FormattedMessage id="DOCUMENT" />
      </h3>
      {docxReportUuid ? (
        <DocumentPreview
          filename="Credit-Analysis-Report.docx"
          token={token}
          downloadUrl={`${baseUri}/objects/v1/object/${docxReportUuid}?institutionUuid=${institutionUuid}`}
          ooUrl={ooUrl}
          // method="POST" this will be needed for the downloading the doc as a PDF
          signConfigurationHook={useSignedEditorConfiguration}
          signConfigurationHookArgs={{
            institutionUuid,
            documentUuid: docxReportUuid,
            desUrl: `${baseUri}/cms/v2`,
            mode: "view",
            plugins: false
          }}
        />
      ) : (
        <GenerateDocumentView generateDocument={generateDocument} />
      )}
    </>
  );
}

CreditAnalysisDocumentsTab.propTypes = {
  generateDocument: PropTypes.func.isRequired
};
