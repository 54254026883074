import { createActions } from "redux-actions";
import { RemoveNullKeys } from "../../../../../../services/ApiLib/services/Utilities";
import { ConvertToNumber } from "../../../../../../services/Formulae";
import {
  Loans,
  LoanPPPData,
  Entities
} from "../../../../../../services/ApiLib";
import { formValueTypes } from "./constants/pppFormValues";

export const parsingFunctions = {
  float: val => parseFloat(ConvertToNumber(val)),
  int: val => parseInt(ConvertToNumber(val), 10),
  bool: val => !!val,
  string: val => val.toString(),
  radio: val => !!val,
  checkbox: val => !!val
};

export const initialState = {
  pppFormFields: {
    // emails
    lender_email_2484: "",
    lender_email_promissory_note: "",

    // 2483 tab
    ppp_first_draw_loan_amount: 0.0,
    ppp_first_draw_sba_loan_number: "",
    period_1_quarter: 0,
    period_1_revenue: 0.0,
    period_2_quarter: 0,
    period_2_revenue: 0.0,
    received_eidl: false,
    refinance_of_eidl_amount: 0.0,
    refinance_of_eidl_loan_number: "",
    sba_status: "",

    // attestations -- loan_ppp_data table
    ineligible_bad_loan: true,
    ineligible_criminal_charges: true,
    ineligible_felony: true,
    ineligible_general: true,
    applicant_has_reduction_in_gross_receipts: true,
    applicant_is_eligible: true,
    applicant_meets_revenue_test_and_size_standard: true,
    applicant_no_shuttered_venue_grant: true,
    applicant_wont_receive_another_second_draw: true,
    loan_request_is_necessary: true
  },
  entityFormFields: {
    // attestations -- entities_v2 table
    all_payroll_employees_reside_in_us: true,
    franchise_code: "",
    is_franchise: true,
    is_sba_listed_franchise: true
  },
  loanFormFields: {},
  pppLoanUuid: "",
  pppError: null,
  attestationsValid: false,
  isSecondDraw: false
};

const setPPPValuesToString = pppFormFields => {
  // The API expects bools as bools, but everything else as strings.
  // Rather than change the validation on the inputs, I'm casting to
  // strings here
  const fieldNames = Object.keys(pppFormFields);
  const nextFields = {};
  for (let i = 0; i < fieldNames.length; i += 1) {
    const field = fieldNames[i];
    const fieldType = formValueTypes[field];
    const parsingFunc = parsingFunctions[fieldType];

    if (parsingFunc) {
      let fieldVal = parsingFunc(pppFormFields[field]);
      if (
        fieldType !== "bool" &&
        fieldType !== "radio" &&
        fieldType !== "checkbox"
      ) {
        fieldVal = fieldVal.toString();
      }
      if (fieldVal !== null && fieldVal !== undefined) {
        nextFields[field] = fieldVal;
      }
    }
  }
  return nextFields;
};

export const actionCreators = createActions(
  {
    setPPPFormFields: data => dispatch => {
      dispatch(actionCreators.setPppFormFieldData(data));
    },
    setLoanFormFields: data => dispatch => {
      dispatch(actionCreators.setLoanFormFieldData(data));
    },
    setEntityFormFields: data => dispatch => {
      dispatch(actionCreators.setEntityFormFieldData(data));
    },
    setPPPLoanUuid: uuid => dispatch => {
      dispatch(actionCreators.setPppLoanUuidData(uuid));
    },
    setIsSecondDraw: isSecondDraw => dispatch => {
      dispatch(actionCreators.setIsSecondDrawData(isSecondDraw));
    },

    updateLoanFields:
      (nextLoanFormFields = null) =>
      async (dispatch, getState) => {
        const nextFields =
          nextLoanFormFields || getState().PPPReducer.loanFormFields;
        try {
          await Loans.asyncUpdate(
            { loanUuid: getState().LosPhasesReducer.loanRequest.uuid },
            nextFields
          );
          if (getState().PPPReducer.pppError) {
            dispatch(actionCreators.setPppError(null));
          }
        } catch (e) {
          dispatch(
            actionCreators.setPppError(
              `Could not save information. ${
                e.detail || "There was an error updating the data"
              }`
            )
          );
        }
      },

    updatePPPFields:
      (nextPppFormFields = null) =>
      async (dispatch, getState) => {
        const nextFields = setPPPValuesToString(
          nextPppFormFields || getState().PPPReducer.pppFormFields
        );

        try {
          await LoanPPPData.asyncUpdate(
            { uuid: getState().PPPReducer.pppLoanUuid },
            nextFields
          );
          if (getState().PPPReducer.pppError) {
            dispatch(actionCreators.setPppError(null));
          }
        } catch (e) {
          dispatch(
            actionCreators.setPppError(
              `Could not save information. ${
                e.detail || "There was an error updating the data"
              }`
            )
          );
        }
      },

    updateEntityFields:
      (nextEntityFields = null) =>
      async (dispatch, getState) => {
        const nextFields =
          nextEntityFields || getState().PPPReducer.entityFormFields;

        try {
          await Entities.asyncUpdate(
            { uuid: getState().LosPhasesReducer.entityDetails.uuid },
            nextFields
          );
          if (getState().PPPReducer.pppError) {
            dispatch(actionCreators.setPppError(null));
          }
        } catch (e) {
          dispatch(
            actionCreators.setPppError(
              `Could not save information. ${
                e.detail || "There was an error updating the data"
              }`
            )
          );
        }
      }
  },
  "SET_PPP_FORM_FIELD_DATA",
  "SET_LOAN_FORM_FIELD_DATA",
  "SET_ENTITY_FORM_FIELD_DATA",
  "SET_PPP_LOAN_UUID_DATA",
  "SET_PPP_ERROR",
  "SET_ATTESTATIONS_VALID",
  "SET_IS_SECOND_DRAW_DATA",
  {
    prefix: "PPP"
  }
);

// eslint-disable-next-line default-param-last
export default function PPPReducer(state = initialState, action) {
  switch (action.type) {
    case "PPP/SET_PPP_FORM_FIELD_DATA":
      return {
        ...state,
        pppFormFields: {
          ...state.pppFormFields,
          ...RemoveNullKeys(action.payload)
        }
      };
    case "PPP/SET_LOAN_FORM_FIELD_DATA":
      return {
        ...state,
        loanFormFields: {
          ...state.loanFormFields,
          ...RemoveNullKeys(action.payload)
        }
      };
    case "PPP/SET_ENTITY_FORM_FIELD_DATA":
      return {
        ...state,
        entityFormFields: {
          ...state.entityFormFields,
          ...RemoveNullKeys(action.payload)
        }
      };
    case "PPP/SET_PPP_LOAN_UUID_DATA":
      return {
        ...state,
        pppLoanUuid: action.payload
      };
    case "PPP/SET_PPP_ERROR":
      return {
        ...state,
        pppError: action.payload
      };
    case "PPP/SET_ATTESTATIONS_VALID":
      return {
        ...state,
        attestationsValid: action.payload
      };
    case "PPP/SET_IS_SECOND_DRAW_DATA":
      return {
        ...state,
        isSecondDraw: action.payload
      };
    default:
      return state;
  }
}
