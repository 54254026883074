import { useState, useEffect, useCallback, useMemo } from "react";
import Select from "react-select";
import { DollarFormat } from "../../../../../../../components/CustomUIElements";
import { REQUEST_FILTER_SCOPE_NO_SHOW_ALL } from "../../../../../../../scenes/CurrentRequest/constants";
import {
  EntityDebts,
  Loans,
  LoanApp
} from "../../../../../../../services/ApiLib";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { FormLabel } from "@bafsllc/ui-shared";
import { useIntl } from "react-intl";
import { CommonMessages } from "@blast-client/constants";
import { DatePicker, TextArea } from "@bafsllc/ui-shared";
import { debounce } from "lodash";

const { PLEASE_MAKE_SELECTION } = CommonMessages;
const MAX_DESCRIPTION_LENGTH = 4096;

const transformOptions = options =>
  options.map(option => ({ value: option.value, label: option.text }));

export function LoanRequestInfo() {
  const { formatMessage } = useIntl();
  const { uuid: loanAppUuid } = useParams() as { uuid: string };
  const [loanInfo, setLoanInfo] = useState({
    scope: null,
    directIndebtedness: null,
    indirectIndebtedness: null,
    totalCommitment: null,
    proposedDebt: null,
    entityUuid: null,
    estimatedCloseDate: "",
    description: ""
  });
  const [loading, setLoading] = useState({
    directIndebtedness: true,
    indirectIndebtedness: true,
    proposedDebt: true,
    loanApp: true,
    description: true
  });

  const handleScopeChange = async selectedScope => {
    setLoanInfo(prev => ({ ...prev, scope: selectedScope }));
    await LoanApp.asyncUpdate(
      { uuid: loanAppUuid },
      {
        scope: selectedScope?.value || ""
      }
    );
  };

  const handleEstimatedCloseDateChange = async selectedDate => {
    setLoanInfo(prev => ({ ...prev, estimatedCloseDate: selectedDate }));
    await LoanApp.asyncUpdate(
      { uuid: loanAppUuid },
      {
        due_date: selectedDate
      }
    );
  };

  const updateDescription = useCallback(
    async description => {
      await LoanApp.asyncUpdate(
        { uuid: loanAppUuid },
        {
          app_description: description
        }
      );
    },
    [loanAppUuid]
  );

  const handleDescriptionChange = event => {
    const newDescription = event.target.value;
    setLoanInfo(prev => ({ ...prev, description: newDescription }));
    debouncedUpdateDescription(newDescription);
  };

  const debouncedUpdateDescription = useMemo(
    () => debounce(updateDescription, 1000),
    [updateDescription]
  );

  useEffect(() => {
    const fetchEntityDebts = async () => {
      try {
        if (loanInfo.entityUuid) {
          const result = await EntityDebts.read({
            entityUuid: loanInfo.entityUuid
          });
          setLoanInfo(prev => ({
            ...prev,
            directIndebtedness: result?.data?.direct || 0,
            indirectIndebtedness: result?.data?.indirect || 0
          }));
          setLoading(prev => ({
            ...prev,
            directIndebtedness: false,
            indirectIndebtedness: false
          }));
        }
      } catch (e) {
        console.error("Error fetching entity debts", e);
        setLoanInfo(prev => ({
          ...prev,
          directIndebtedness: null,
          indirectIndebtedness: null
        }));
        setLoading(prev => ({
          ...prev,
          directIndebtedness: false,
          indirectIndebtedness: false
        }));
      }
    };

    const fetchLoanDetails = async () => {
      try {
        const loans = await Loans.asyncLoansGetAllByLoanAppUuid({
          loanAppUuid
        });
        if (loans.data.length) {
          const totalProposedDebt = loans.data.reduce(
            (acc, loan) => acc + (loan.original_amount || 0),
            0
          );
          setLoanInfo(prev => ({
            ...prev,
            entityUuid: loans.data[0].entity_uuid,
            proposedDebt: totalProposedDebt
          }));
          setLoading(prev => ({ ...prev, proposedDebt: false }));
        }
      } catch (e) {
        console.error("Error fetching loan details", e);
        setLoanInfo(prev => ({ ...prev, proposedDebt: null }));
        setLoading(prev => ({ ...prev, proposedDebt: false }));
      }
    };

    const fetchLoanAppData = async () => {
      try {
        const result = await LoanApp.read({ loanAppUuid });
        if (result.data) {
          const option = REQUEST_FILTER_SCOPE_NO_SHOW_ALL.find(
            opt => opt.value === result.data.scope
          );
          setLoanInfo(prev => ({
            ...prev,
            scope: option ? { value: option.value, label: option.text } : null,
            estimatedCloseDate: result.data.due_date || "",
            description: result.data.app_description || ""
          }));
          setLoading(prev => ({ ...prev, loanApp: false }));
        }
      } catch (e) {
        console.error("Error fetching loan app data", e);
        setLoanInfo(prev => ({
          ...prev
        }));
        setLoading(prev => ({ ...prev, loanApp: false }));
      }
    };

    if (loading.loanApp) fetchLoanAppData();
    fetchEntityDebts();
    fetchLoanDetails();
  }, [
    loanAppUuid,
    loanInfo.entityUuid,
    loading.loanApp,
    loading.proposedDebt,
    loading.directIndebtedness,
    loading.indirectIndebtedness,
    loading.proposedDebt
  ]);

  return (
    <div className="grid grid-cols-4 gap-4" data-testid="loan-request-info">
      <div className="space-y-6">
        <div>
          <FormLabel
            className="block mb-1 text-sm font-bold"
            labelText={formatMessage({
              id: "SCOPE_OF_ANALYSIS"
            })}
          />
          <Select
            options={transformOptions(REQUEST_FILTER_SCOPE_NO_SHOW_ALL)}
            isClearable
            value={loanInfo.scope}
            onChange={handleScopeChange}
            placeholder={PLEASE_MAKE_SELECTION}
            inputId="scope-of-analysis-field"
            classNames={{ control: () => "border-neutral-300" }}
          />
        </div>
        <div>
          <span className="block mb-1 text-sm font-bold">
            <FormattedMessage id="DIRECT_INDEBTEDNESS" />
          </span>
          {loading.directIndebtedness ? (
            <span className="italic">Loading...</span>
          ) : (
            <DollarFormat value={loanInfo.directIndebtedness} />
          )}
        </div>
        <div>
          <span className="block mb-1 text-sm font-bold">
            <FormattedMessage id="PROPOSED_DEBT" />
          </span>
          {loading.proposedDebt ? (
            <span className="italic">Loading...</span>
          ) : (
            <DollarFormat value={loanInfo.proposedDebt} />
          )}
        </div>
      </div>
      <div>
        <DatePicker
          required
          labelMessage={{ id: "ESTIMATED_CLOSE_DATE" }}
          onDateChange={handleEstimatedCloseDateChange}
          name="estimated_close_date"
          value={loanInfo.estimatedCloseDate}
          disabled={false}
          className="h-11 border-neutral-300"
        />
        <div className="mt-6">
          <span className="block text-sm font-bold mb-1">
            <FormattedMessage id="INDIRECT_INDEBTEDNESS" />
          </span>
          {loading.indirectIndebtedness ? (
            <span className="italic">Loading...</span>
          ) : (
            <DollarFormat value={loanInfo.indirectIndebtedness} />
          )}
        </div>
        <div className="mt-6">
          <span className="block mt-8 mb-1 text-sm font-bold">
            <FormattedMessage id="TOTAL_COMMITMENT" />
          </span>
          {loading.directIndebtedness ||
          loading.indirectIndebtedness ||
          loading.proposedDebt ? (
            <span className="italic">Loading...</span>
          ) : (
            <DollarFormat
              value={
                loanInfo.directIndebtedness +
                loanInfo.indirectIndebtedness +
                loanInfo.proposedDebt
              }
            />
          )}
        </div>
      </div>
      <div className="col-span-2">
        <TextArea
          labelMessage={{ id: "DESCRIPTION" }}
          name="descriptions"
          onChange={handleDescriptionChange}
          value={loanInfo.description}
          required
          error=""
          rows={5}
          maxLength={MAX_DESCRIPTION_LENGTH}
          placeholder={formatMessage({
            id: "LOAN_APP_DESCRIPTION_PLACEHOLDER"
          })}
          className="flex flex-col p-4 w-full rounded-lg border border-neutral-300"
          labelClasses="text-sm font-bold mb-1"
        />
        {loanInfo?.description !== null && (
          <div className="text-right text-xs text-neutral-400">
            {MAX_DESCRIPTION_LENGTH - loanInfo?.description?.length}{" "}
            <FormattedMessage id="CHARACTERS_LEFT" />
          </div>
        )}
      </div>
    </div>
  );
}
