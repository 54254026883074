import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Menu, Segment } from "semantic-ui-react";

import Constants from "../../../../services/Constants/strings";

function TabBar(props) {
  return (
    <Segment>
      <Menu pointing secondary color="green">
        <Menu.Item
          active={props.activeItem === Constants.DOCUMENT_TRACKING}
          name={Constants.DOCUMENT_TRACKING}
          onClick={() => props.loadTable(Constants.DOCUMENT_TRACKING)}
        />
        <Menu.Item
          active={props.activeItem === Constants.ASSET_DOCUMENTS}
          name={Constants.ASSET_DOCUMENTS}
          onClick={() => props.loadTable(Constants.ASSET_DOCUMENTS)}
        />
        {props.assetType.group === "Personal Property" &&
          props.assetType.class !== "Accounts" && (
            <Menu.Item
              active={
                props.activeItem === Constants.PERSONAL_PROPERTY_ASSET_DETAILS
              }
              name={Constants.PERSONAL_PROPERTY_ASSET_DETAILS}
              onClick={() =>
                props.loadTable(Constants.PERSONAL_PROPERTY_ASSET_DETAILS)
              }
            />
          )}
        {props.assetType.group === "Personal Property" &&
          props.assetType.class === "Accounts" && (
            <Menu.Item
              active={props.activeItem === Constants.ACCOUNTS_ASSET_DETAILS}
              name={Constants.ACCOUNTS_ASSET_DETAILS}
              onClick={() => props.loadTable(Constants.ACCOUNTS_ASSET_DETAILS)}
            />
          )}
        {props.assetType.group === "Real Estate" && (
          <Menu.Item
            active={props.activeItem === Constants.REAL_ESTATE_ASSET_DETAILS}
            name={Constants.REAL_ESTATE_ASSET_DETAILS}
            onClick={() => props.loadTable(Constants.REAL_ESTATE_ASSET_DETAILS)}
          />
        )}
      </Menu>
    </Segment>
  );
}

const mapStateToProps = state => ({
  entityData: state.LoanManagementReducer.entityData
});

TabBar.propTypes = {
  activeItem: PropTypes.string.isRequired,
  loadTable: PropTypes.func.isRequired,
  assetType: PropTypes.shape({
    group: PropTypes.string,
    class: PropTypes.string
  })
};

export default connect(mapStateToProps)(TabBar);
