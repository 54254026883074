import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import entityDataProps from "../../../../../../services/PropTypes/entityData";
import DocTrackingView from "../../../../../../components/DocTrackingView/DocTrackingView";
import Constants from "../../../../../../services/Constants/strings";
import { actionCreators } from "../../reducer";

const { loadActiveTicklersByLoan, loadScheduleByLoanId } = actionCreators;

function DocumentTracking({
  loan,
  entityData,
  docTrackingData,
  docTrackingItem = Constants.TICKLERS,
  onDocTrackingItem
}) {
  useEffect(() => {
    onDocTrackingItem(docTrackingItem);
  }, [docTrackingItem, onDocTrackingItem]);
  if (!docTrackingData || !entityData.uuid) return null; // loading?

  const entityDataMod = {
    ...entityData,
    parent_uuid: loan.uuid
  };

  return (
    <DocTrackingView
      parentType="Loan"
      schedulePaginationName={Constants.loanSchedulePaginationName}
      ticklerPaginationName={Constants.loanTicklerPaginationName}
      data={docTrackingData}
      docTrackingItem={docTrackingItem}
      entityData={entityDataMod}
    />
  );
}

DocumentTracking.propTypes = {
  loan: PropTypes.shape().isRequired,
  entityData: entityDataProps.isRequired,
  docTrackingData: PropTypes.arrayOf(PropTypes.object),
  docTrackingItem: PropTypes.string,
  onDocTrackingItem: PropTypes.func
};

export default connect(
  state => ({
    docTrackingData: state.LoanManagementReducer.docTrackingData,
    docTrackingDataLastPage:
      state.LoanManagementReducer.docTrackingDataLastPage,
    docTrackingItem: state.LoanManagementReducer.docTrackingItem
  }),
  (dispatch, { loan }) => ({
    onDocTrackingItem(name) {
      if (loan.uuid) {
        if (name === Constants.TICKLERS) {
          dispatch(loadActiveTicklersByLoan(loan.uuid));
        }
        if (name === Constants.SCHEDULE) {
          dispatch(loadScheduleByLoanId(loan.uuid));
        }
      }
    },
    onDropdownChange() {
      // TODO: implement onDropDownChage
    }
  })
)(DocumentTracking);
