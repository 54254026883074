import { getToken } from "@bafsllc/auth-shared";
import { FormatUrlV2 } from "../../../FormatUrl";
import logger from "../../../logger";
import ApiEndpoints from "../../../ApiEndpoints";

const getFileBlobUrl = async (objectUuid, fileType = "application/pdf") => {
  const fetchParams = {
    method: "GET",
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json"
    }
  };
  const url = FormatUrlV2(ApiEndpoints.objectDownloads, { uuid: objectUuid });

  return await fetch(url, fetchParams)
    .then(async response => {
      const blob = await response.blob();
      const newBlob = new Blob([blob], { type: fileType });

      const blobUrl = window.URL.createObjectURL(newBlob, {});

      return blobUrl;
    })
    .catch(err => {
      logger.error(err);
    });
};

export default getFileBlobUrl;
