import { arrayOf, func, object } from "prop-types";
import { Link } from "react-router-dom";
import _ from "lodash";

import { List } from "semantic-ui-react";

import {
  relativeFriendlyDate,
  friendlyTimeFromDateString,
  dateFromString,
  rawDateToLocal
} from "../../../../services/DateTime";

import "./style.css";

export const groupNotifications = notifications => {
  const reverseDateSort = (a, b) => new Date(b) - new Date(a);
  const sorted = notifications.sort((a, b) =>
    reverseDateSort(a.timestamp, b.timestamp)
  );
  let uniqDays = _.uniq(sorted.map(d => dateFromString(d.timestamp)));
  uniqDays = uniqDays.sort(reverseDateSort);
  const days = uniqDays.map(d => dateFromString(d));
  const groupByDay = [];
  days.forEach(d => {
    groupByDay.push(sorted.filter(n => dateFromString(n.timestamp) === d));
  });
  return groupByDay;
};
export const removeBaseUrl = url => url.replace(/^https?:\/\/[a-z:0-9.]+/, "");

function NotificationTable({ notifications, markRead }) {
  const groupByDay = groupNotifications(notifications);

  return groupByDay.map(day => (
    <div key={day[0].timestamp}>
      <List>
        <List.Header className="border-b border-neutral-300">
          {relativeFriendlyDate(rawDateToLocal(day[0].timestamp))}
        </List.Header>
        <List.List>
          {day.map(notification => (
            <List.Item
              as={Link}
              key={notification.uuid}
              onClick={() => markRead(notification.uuid)}
              className={notification.read ? "read" : "unread"}
              to={removeBaseUrl(notification.action)}
            >
              <div className="float-right pl-2 text-neutral-400 text-sm">
                {friendlyTimeFromDateString(
                  rawDateToLocal(notification.timestamp)
                )}
              </div>
              {notification.message}
            </List.Item>
          ))}
        </List.List>
      </List>
    </div>
  ));
}

NotificationTable.propTypes = {
  notifications: arrayOf(object).isRequired,
  markRead: func.isRequired
};

export default NotificationTable;
