import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import Buttons from "./components/Buttons";
import Relationships from "./components/TableView/components/Relationships";
import TableView from "./components/TableView";
import Detail from "./components/Details";
import CreditDocument from "./components/CreditDocuments";
import DocTrackingView from "../../../../../../components/DocTrackingView/DocTrackingView";
import Loans from "./components/Loans";
import Assets from "./components/Assets";
import CreditRequest from "./components/CreditRequest";
// eslint-disable-next-line import/no-named-as-default
import FinancialStatements from "./components/FinancialStatements";
import BorrowerPortalLinks from "./components/BorrowerPortalLinks";
import { BPLinksProvider } from "./components/BorrowerPortalLinks/bp-links-context";

function TabView({
  addDocumentCallback,
  addNewUploadToState,
  creditDocsFilter,
  data,
  deleteDocumentCallback,
  duplicateDocumentCallback,
  entityData,
  handleCreditDocSearch,
  isDuplicate,
  onPreview,
  onSave,
  schedulePaginationName,
  selectedDocument,
  selectedRowData,
  statementSelected,
  ticklerPaginationName,
  toggleDocApprovalModal,
  docTrackingItem
}) {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/document-tracking`}>
        <DocTrackingView
          parentType="Entity"
          data={data}
          entityData={entityData}
          schedulePaginationName={schedulePaginationName}
          ticklerPaginationName={ticklerPaginationName}
          docTrackingItem={docTrackingItem}
        />
      </Route>
      <Route exact path={`${match.url}/loans`}>
        <Loans />
      </Route>
      <Route exact path={`${match.url}/credit-documents`}>
        <CreditDocument
          addNewUploadToState={addNewUploadToState}
          creditDocsFilter={creditDocsFilter}
          entityData={entityData}
          handleCreditDocSearch={handleCreditDocSearch}
          onPreview={onPreview}
          onSave={onSave}
          selectedDocument={selectedDocument}
          toggleDocApprovalModal={toggleDocApprovalModal}
        />
      </Route>
      <Route exact path={`${match.url}/assets`}>
        <Assets data={data} />
      </Route>
      <Route exact path={`${match.url}/relationships`}>
        <Relationships />
      </Route>
      <Route exact path={`${match.url}/credit-request`}>
        <CreditRequest entityUuid={entityData.uuid} />
      </Route>
      <Route exact path={`${match.url}/details`}>
        <Detail />
      </Route>
      <Route exact path={`${match.url}/financial-statements`}>
        <FinancialStatements
          duplicateDocumentCallback={duplicateDocumentCallback}
        />
      </Route>
      <Route exact path={`${match.url}/borrower-portal-links`}>
        <BPLinksProvider>
          <BorrowerPortalLinks />
        </BPLinksProvider>
      </Route>
      <Route path={`${match.url}`}>
        <Segment.Group>
          <Buttons
            statementSelected={statementSelected}
            data={data}
            isDuplicate={isDuplicate}
            addDocumentCallback={addDocumentCallback}
            duplicateDocumentCallback={duplicateDocumentCallback}
            selectedRowData={selectedRowData}
            deleteDocumentCallback={deleteDocumentCallback}
          />
          <TableView data={data} statementSelected={statementSelected} />
        </Segment.Group>
      </Route>
    </Switch>
  );
}

TabView.propTypes = {
  addNewUploadToState: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  entityData: PropTypes.shape().isRequired,
  isDuplicate: PropTypes.bool,
  statementSelected: PropTypes.string.isRequired,
  creditDocsFilter: PropTypes.string.isRequired,
  schedulePaginationName: PropTypes.string.isRequired,
  handleCreditDocSearch: PropTypes.func.isRequired,
  selectedDocument: PropTypes.shape().isRequired,
  ticklerPaginationName: PropTypes.string.isRequired,
  toggleDocApprovalModal: PropTypes.func.isRequired,
  addDocumentCallback: PropTypes.func.isRequired,
  deleteDocumentCallback: PropTypes.func.isRequired,
  duplicateDocumentCallback: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  selectedRowData: PropTypes.shape({
    accountingMethod: PropTypes.string,
    analyst: PropTypes.string,
    archive: PropTypes.number,
    createdDate: PropTypes.string,
    documentUuid: PropTypes.string,
    endDate: PropTypes.string,
    opinion: PropTypes.string,
    period: PropTypes.string,
    periodMetadata: PropTypes.object,
    sourceDocument: PropTypes.string,
    startDate: PropTypes.string,
    statementDate: PropTypes.string,
    statementType: PropTypes.string,
    status: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    uuid: PropTypes.string
  }).isRequired,
  docTrackingItem: PropTypes.string
};

TabView.defaultProps = {
  isDuplicate: false
};

export default TabView;
