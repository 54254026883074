import { useState, useEffect, useContext } from "react";
import { Context } from "./context";
import { EditorConfig } from "./types";

export interface SignedEditorConfigurationType {
  token: string;
  documentUuid: string;
  institutionUuid: string;
  desUrl: string;
  headerImageUrl?: string;
  imageRedirectUrl?: string;
  mode?: "view" | "edit";
  height?: string;
  width?: string;
  zoom?: number;
  download?: boolean;
  print?: boolean;
  chat?: boolean;
  comments?: boolean;
  help?: boolean;
  plugins?: boolean;
}

export const useSignedEditorConfiguration = ({
  token,
  documentUuid,
  institutionUuid,
  desUrl,
  headerImageUrl,
  imageRedirectUrl,
  mode = "edit",
  height = "100%",
  width = "100%",
  zoom = 100,
  download = true,
  print = true,
  chat = true,
  comments = true,
  help = true,
  plugins = true
}: SignedEditorConfigurationType) => {
  const [config, setConfig] = useState<EditorConfig | null>(null);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    async function getConfigurations() {
      let url = new URL(`${desUrl}/signed-configurations/${documentUuid}`);

      if (institutionUuid) {
        url.searchParams.append("institutionUuid", institutionUuid);
      }
      const response = await fetch(url.toString(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: token
        },
        body: JSON.stringify({
          mode,
          zoom,
          height,
          width,
          download,
          print,
          chat,
          comments,
          help,
          plugins,
          headerImageUrl,
          imageRedirectUrl
        })
      });
      const { status } = response;
      if (status !== 200) {
        const body = await response.text();
        setError(body);
      } else {
        const body = await response.json();
        setConfig(body);
      }
    }
    getConfigurations().catch(error => setError(error.message));
  }, [
    chat,
    comments,
    desUrl,
    documentUuid,
    download,
    height,
    help,
    headerImageUrl,
    imageRedirectUrl,
    institutionUuid,
    mode,
    plugins,
    print,
    token,
    width,
    zoom
  ]);
  return { config, error };
};

export const useScript = (src: string) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (document.getElementById(src)) {
      setLoaded(true);
      setError(null);
      return;
    }

    const script = document.createElement("script");
    script.setAttribute("id", src);
    script.setAttribute("src", src);
    script.async = true;

    const onScriptLoad = () => {
      setLoaded(true);
      setError(null);
      script.removeEventListener("load", onScriptLoad);
      script.removeEventListener("error", onScriptError);
    };
    const onScriptError = (error: any) => {
      setLoaded(true);
      setError(error);
    };

    script.addEventListener("load", onScriptLoad);
    script.addEventListener("error", onScriptError);

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [src]);

  return [loaded, error];
};

export const useOnlyOffice = (): {
  editor?: any;
  editorId?: string;
  error?: Error;
} => {
  const oo = useContext(Context);
  return oo || {};
};
