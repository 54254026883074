import React from "react";
import { func, string, number, oneOfType } from "prop-types";
import { Form, Input, Label } from "semantic-ui-react";

export function BasisPointsField(props) {
  const { onChange, value, label, suffix, ...restProps } = props;

  const displayValue =
    value == null || value === "" ? "" : parseFloat(+value * 10000).toFixed();

  const children = (
    <Input
      {...restProps}
      value={displayValue}
      labelPosition="right"
      type="number"
      onChange={({ target: { name, value: newValue } }) => {
        onChange({
          name,
          value: newValue === "" ? null : parseInt(newValue, 10) / 10000
        });
      }}
    >
      <input value={displayValue} />
      <Label basic>{suffix}</Label>
    </Input>
  );

  return <Form.Input label={label}>{children}</Form.Input>;
}

BasisPointsField.defaultProps = {
  onChange: () => {},
  label: "",
  placeholder: "",
  suffix: "bps"
};

BasisPointsField.propTypes = {
  onChange: func,
  label: string,
  name: string.isRequired,
  placeholder: string,
  suffix: string,
  value: oneOfType([number, string])
};

const singular = {
  short: "bp",
  long: "Basis Point",
  none: ""
};
const plural = {
  short: "bps",
  long: "Basis Points",
  none: ""
};

export function BasisPointsDisplay({ value, format = "short" }) {
  if (value == null) {
    return "-";
  }

  const parsedValue = parseFloat(+value * 10000).toFixed();

  return parsedValue === "1"
    ? `${parsedValue} ${singular[format]}`
    : `${parsedValue} ${plural[format]}`;
}
BasisPointsDisplay.propTypes = {
  value: oneOfType([number, string]),
  format: string
};

export const BasisPointsCell = ({
  cell: { value },
  column: { format = "short" }
}) => BasisPointsDisplay({ value, format });

export default {
  Field: BasisPointsField,
  Cell: BasisPointsCell,
  Display: BasisPointsDisplay
};
