import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { PropertyTable, DateCell } from "../../../../../../components/Tables";

function NightlyProcessTable({ loan }) {
  const columns = useMemo(
    () => [
      {
        Header: "Interest Accrued Through",
        accessor: "accrued_through_date",
        Cell: DateCell,
        permission: "all"
      },
      {
        Header: "Last Run Date for Bills/Fees",
        accessor: "last_run_date_bills_fees",
        Cell: DateCell,
        permission: "all"
      },
      {
        Header: "Last Run Date for Interest Schedules",
        accessor: "last_run_date_interest_schedules",
        Cell: DateCell,
        permission: "all"
      },
      {
        Header: "Last Run Date for Average Daily Balance",
        accessor: "last_run_date_avg_bal",
        Cell: DateCell,
        permission: "all"
      },
      {
        Header: "Last Run Date for Premium Discount Updates",
        accessor: "last_run_date_premium_discount",
        Cell: DateCell,
        permission: "all"
      }
    ],
    []
  );

  return <PropertyTable columnsConfig={columns} data={loan} />;
}

NightlyProcessTable.propTypes = {
  loan: PropTypes.shape({}).isRequired
};

export default NightlyProcessTable;
