import React from "react";
import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";
import IndividualCreditInfo from "../Individual";
import OtherCreditInfo from "../Other";

export default function CreditInfoContent({ entityType }) {
  return entityType === "Individual" ? (
    <Modal.Content>
      <IndividualCreditInfo />
    </Modal.Content>
  ) : (
    <Modal.Content>
      <OtherCreditInfo />
    </Modal.Content>
  );
}

CreditInfoContent.propTypes = {
  entityType: PropTypes.string.isRequired
};
