import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import {
  DollarCell,
  PropertyTable
} from "../../../../../../../../../../components/Tables";
import { Loans } from "../../../../../../../../../../services/ApiLib";

function InterestHistoryTable({ loan, dispatch }) {
  const [snapshot, setSnapshot] = useState({});

  const rows = useMemo(
    () => [
      {
        Header: "Accrued Interest MTD",
        accessor: "interest_accrued_mtd",
        Cell: DollarCell
      },
      {
        Header: "Accrued Interest YTD",
        accessor: "interest_accrued_ytd",
        Cell: DollarCell
      },
      {
        Header: "Accrued Interest LTD",
        accessor: "interest_accrued_ltd",
        Cell: DollarCell
      },
      {
        Header: "Interest Paid YTD",
        accessor: "interest_paid_ytd",
        Cell: DollarCell
      },
      {
        Header: "Interest Paid LTD",
        accessor: "interest_paid_ltd",
        Cell: DollarCell
      },
      {
        Header: "Interest Paid Last Year",
        accessor: "interest_paid_last_year",
        Cell: DollarCell
      },
      {
        Header: "Fractional Accrued Interest",
        accessor: "fractional_accrued_interest",
        Cell: DollarCell
      }
    ],
    []
  );

  useEffect(() => {
    const endDate = moment()
      .startOf("year")
      .subtract(1, "day")
      .format("YYYY-MM-DD");

    // Get previous year's end-of-year snapshot.
    Loans.get(
      ({ data }) => {
        setSnapshot(data[0] || {});
      },
      ({ error }) => {
        dispatch({ type: "LOAN_ERROR", payload: error });
      },
      null,
      {
        include_snapshot: true,
        month_end_parent_uuid: loan.uuid,
        month_end_date: endDate
      }
    );
  }, [loan, dispatch]);

  return (
    <PropertyTable
      columnsConfig={rows}
      data={{ ...loan, interest_paid_last_year: snapshot.interest_paid_ytd }}
    />
  );
}

InterestHistoryTable.propTypes = {
  loan: PropTypes.shape({
    uuid: PropTypes.string,
    interest_accrued_mtd: PropTypes.number,
    interest_accrued_ytd: PropTypes.number,
    interest_accrued_ltd: PropTypes.number,
    interest_paid_ytd: PropTypes.number,
    interest_paid_ltd: PropTypes.number,
    fractional_accrued_interest: PropTypes.number,
    interest_paid_last_year: PropTypes.number
  }).isRequired,
  dispatch: PropTypes.func
};

export default connect()(InterestHistoryTable);
