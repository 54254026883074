import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import FetchService, { asyncFetchService } from "../../../FetchService";
import { coerceTypes, ConcatQueryParams, RemoveNullKeys } from "../Utilities";
import { FormatUrlV2 } from "../../../FormatUrl";

const schemaToForce = {
  loan_amount: "float"
};

function rspToCallback(rsp, callbacks) {
  TypeCheck(rsp, "object");
  TypeCheck(callbacks.callbackData, "object", false);
  TypeCheck(callbacks.onSuccess, "function");

  callbacks.onSuccess(rsp, callbacks.callbackData);
}

function add(jwt, onSuccess, onError, body, callbackData) {
  TypeCheck(jwt, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(callbackData, "object", false);

  const url = ApiEndpoints.baseUri + ApiEndpoints.creditRequests;
  const callbacks = { callbackData, onSuccess };
  FetchService("POST", url, rspToCallback, onError, body, callbacks);
}

function get(jwt, onSuccess, onError, uuid, queryParams, callbackData) {
  TypeCheck(jwt, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string", false);
  TypeCheck(queryParams, "object", false);

  if (uuid && queryParams) {
    onError("Error: Invalid Filter Combination");
  }

  let appendToUrl = "";
  if (uuid) {
    appendToUrl += `/${uuid}`;
  }
  if (queryParams) {
    appendToUrl = ConcatQueryParams(queryParams);
  }
  const url = ApiEndpoints.baseUri + ApiEndpoints.creditRequests + appendToUrl;
  FetchService("GET", url, onSuccess, onError, null, callbackData);
}

function update(jwt, onSuccess, onError, body, callbackData) {
  TypeCheck(jwt, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");

  const url = FormatUrlV2(ApiEndpoints.creditRequest, { uuid: body.uuid });
  const callbacks = { callbackData, onSuccess };

  let convertedBody = RemoveNullKeys(body);
  convertedBody = coerceTypes(convertedBody, schemaToForce);
  FetchService("PUT", url, onSuccess, onError, convertedBody, callbacks);
}

async function asyncUpdate(filter, body) {
  TypeCheck(filter, "object", true);
  TypeCheck(body, "object");
  const url = FormatUrlV2(ApiEndpoints.creditRequest, { uuid: filter.uuid });
  return asyncFetchService("PUT", url, body);
}
export default {
  add,
  get,
  update,
  asyncUpdate
};
