/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */
import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  actions,
  spreadsSelector,
  periodsSelector,
  modelsSelector,
  errorsSelector,
  useActions
} from "./spreads";

export const usePeriods = ({ entityUuid, institutionUuid }) => {
  const dispatch = useDispatch();
  const actionCreators = useActions(dispatch);

  // Kick off data fetching when component is rendered
  useEffect(() => {
    if (entityUuid && institutionUuid) {
      dispatch(actions.retrievePeriods({ entityUuid, institutionUuid }));
    }
    return () => dispatch(actions.cleanUpPeriods());
  }, [entityUuid, institutionUuid, dispatch]);

  const periods = useSelector(periodsSelector);
  const errors = useSelector(errorsSelector);

  return useMemo(
    () => [{ periods, errors }, actionCreators],
    [periods, errors, actionCreators]
  );
};

export const useSpreads = ({ entityUuid, institutionUuid }) => {
  const dispatch = useDispatch();
  const actionCreators = useActions(dispatch);

  // Kick off data fetching when component is rendered
  useEffect(() => {
    dispatch(actions.retrieveSpreads({ entityUuid, institutionUuid }));
    return () => dispatch(actions.cleanUpSpreads());
  }, [entityUuid, institutionUuid, dispatch]);

  const spreads = useSelector(spreadsSelector);
  const errors = useSelector(errorsSelector);

  return useMemo(
    () => [{ spreads, errors }, actionCreators],
    [spreads, errors, actionCreators]
  );
};

export const useFinancialAnalysis = ({ entityUuid, institutionUuid }) => {
  const dispatch = useDispatch();
  const actionCreators = useActions(dispatch);

  // Fetch updated spreads with financial analysis reports
  useEffect(() => {
    dispatch(actions.retrieveSpreads({ entityUuid, institutionUuid }));
    return () => dispatch(actions.cleanUpSpreads());
  }, [entityUuid, institutionUuid, dispatch]);

  const spreads = useSelector(spreadsSelector);
  const errors = useSelector(errorsSelector);

  return useMemo(() => {
    const financialAnalysis = spreads?.filter(
      spread => !!spread?.financialAnalysisCombinedReportDocumentUuid
    );
    return [{ financialAnalysis, errors }, actionCreators];
  }, [spreads, errors, actionCreators]);
};

export const useModels = ({ entityUuid }) => {
  const dispatch = useDispatch();
  const actionCreators = useActions(dispatch);

  // Kick off data fetching when component is rendered
  useEffect(() => {
    dispatch(actions.retrieveModels({ entityUuid }));
    return () => dispatch(actions.cleanUpModels());
  }, [entityUuid, dispatch]);

  const models = useSelector(modelsSelector);
  const errors = useSelector(errorsSelector);

  return useMemo(
    () => [{ models, errors }, actionCreators],
    [models, errors, actionCreators]
  );
};
