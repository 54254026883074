import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import FetchService, { asyncFetchService } from "../../../FetchService";
import GenerateUrlParams from "../../../Ticklers/services/GenerateUrlParams";
import {
  ConcatQueryParams,
  RemoveNullKeys,
  PaginationParams
} from "../Utilities";
import { FormatUrlV2 } from "../../../FormatUrl";
import logger from "../../../logger";

function rspToCallback(rsp, callbacks) {
  TypeCheck(rsp, "object");
  TypeCheck(callbacks.data, "object", false);
  TypeCheck(callbacks.onSuccess, "function");

  callbacks.onSuccess(rsp, callbacks.callbackData);
}

function add() {
  logger.error("TODO: Ticklers.add() not setup.");
}

function get(
  jwt,
  onSuccess,
  onError,
  callbackData,
  filters,
  pagination,
  permissions
) {
  TypeCheck(jwt, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(callbackData, "object", false);
  TypeCheck(filters, "object");
  TypeCheck(pagination.number, "number");
  TypeCheck(pagination.direction, "enum", false, ["next", "previous"]);
  TypeCheck(pagination.size, "number");
  TypeCheck(permissions.hasPermissionBafs, "boolean");
  TypeCheck(permissions.institutionUuid, "string", false);

  const { hasPermissionBafs, institutionUuid } = permissions;
  let params = GenerateUrlParams(filters, hasPermissionBafs, institutionUuid);
  params = PaginationParams(params, pagination);
  const url = ApiEndpoints.baseUri + ApiEndpoints.ticklers + params;

  // TODO: Check this. It doesn't look like the callback data
  // will be returned to the onSuccess func.
  const callbacks = { data: callbackData, onSuccess };
  FetchService("GET", url, rspToCallback, onError, null, callbacks);
}

async function asyncRead(queryParams) {
  TypeCheck(queryParams, "object", false);

  let params = "";
  if (queryParams) {
    params = ConcatQueryParams(queryParams);
  }

  const url = ApiEndpoints.baseUri + ApiEndpoints.ticklers + params;
  const resp = await asyncFetchService("GET", url);
  return resp;
}

async function asyncReadCount(queryParams) {
  TypeCheck(queryParams, "object", false);
  let params = "";
  if (queryParams) {
    params = ConcatQueryParams(queryParams);
  }
  const url = ApiEndpoints.baseUri + ApiEndpoints.exceptionsCount + params;
  const resp = await asyncFetchService("GET", url);
  return resp;
}

async function asyncUpdate(filters, body) {
  TypeCheck(filters.uuid, "string", true);
  const { uuid } = filters;

  const url = FormatUrlV2(ApiEndpoints.tickler, { uuid });

  const convertedBody = RemoveNullKeys(body);

  const rsp = await asyncFetchService("PUT", url, convertedBody);
  return rsp;
}

async function asyncReadAllActive(
  parentUuid,
  queryParams = null,
  pagination = null
) {
  TypeCheck(parentUuid, "string", false);
  let params = null;
  if (queryParams) {
    params = ConcatQueryParams(queryParams);
  }
  if (pagination) {
    params = `&page_number=${pagination.number}&page_size=${pagination.size}`;
  }
  let url = "";
  if (parentUuid && params) {
    url =
      FormatUrlV2(ApiEndpoints.ticklersGetAllActive, { parentUuid }) + params;
  } else {
    url = FormatUrlV2(ApiEndpoints.ticklersGetAllActive, { parentUuid });
  }
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

async function asyncReadAll(parentUuid, queryParams = null, pagination = null) {
  TypeCheck(parentUuid, "string", false);
  let params = null;
  if (queryParams) {
    params = ConcatQueryParams(queryParams);
  }
  if (pagination) {
    params = `&page_number=${pagination.number}&page_size=${pagination.size}`;
  }
  let url = "";
  if (parentUuid && params) {
    url = FormatUrlV2(ApiEndpoints.ticklersGetAll, { parentUuid }) + params;
  } else {
    url = FormatUrlV2(ApiEndpoints.ticklersGetAll, { parentUuid });
  }
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

export default {
  add,
  get,
  asyncRead,
  asyncReadCount,
  asyncReadAllActive,
  asyncUpdate,
  asyncReadAll
};
