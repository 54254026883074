const CreditBureauOptions = [
  {
    key: "credit_score_equifax",
    text: "Equifax",
    value: "credit_score_equifax"
  },
  {
    key: "credit_score_experian",
    text: "Experian",
    value: "credit_score_experian"
  },
  {
    key: "credit_score_transunion",
    text: "Transunion",
    value: "credit_score_transunion"
  }
];

export default CreditBureauOptions;
