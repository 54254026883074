import { useContext, createContext } from "react";
import { Id, ToastContainer, toast } from "react-toastify";
import { ToastBoxDisplay } from "../toast-box-display/toast-box-display";
import { MessageDescriptor } from "react-intl";
import { ToastVariant } from "../toast-box-display/toast-box-display";
import "react-toastify/dist/ReactToastify.css";
const ToastContext = createContext<ToastContextType>({
  addToast: () => "",
  removeToast: () => {}
});

/**
 * A component that creates a context for toasts. To create
 * or destroy toasts, use the custom hook "useToast"
 *
 * @documentation https://fkhadra.github.io/react-toastify/introduction
 */
function ToastProvider({ children }: React.PropsWithChildren<unknown>) {
  const addToast = ({
    message,
    title,
    variant = "info",
    options = {}
  }: AddToastProps): Id => {
    const toastContent = (
      <ToastBoxDisplay message={message} title={title} variant={variant} />
    );

    if (Object.keys(options).length === 0) {
      return toast[variant](toastContent);
    } else {
      return toast[variant](toastContent, options);
    }
  };

  const removeToast = (id?: Id) => {
    id ? toast.dismiss(id) : toast.dismiss();
  };
  return (
    <>
      <ToastContainer draggable={false} position="top-center" hideProgressBar />
      <ToastContext.Provider
        value={{
          addToast,
          removeToast
        }}
      >
        {children}
      </ToastContext.Provider>
    </>
  );
}

/**
 * Custom hook that creates a ToastContext and returns functions
 * that can be used to add and remove Toasts
 * @returns addToast, removeToast
 * @addToast accepts ToastBoxProps and uses to creates a Toast
 * @removeToast accepts an ID and removes any Toast with a matching ID
 */
function useToast() {
  return useContext(ToastContext);
}

export { ToastProvider, ToastContext, useToast };

export type ToastContextType = {
  addToast: (options: AddToastProps) => Id;
  removeToast: (id?: Id) => void;
};

export interface AddToastProps {
  title: MessageDescriptor;
  message: MessageDescriptor & {
    values?: Record<
      string,
      string | number | boolean | null | undefined | Date
    >;
  };
  variant?: ToastVariant;
  /** https://fkhadra.github.io/react-toastify/api/toast */
  options?: any;
}
