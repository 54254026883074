import React, { forwardRef } from "react";
import { IndeterminateCheckboxProps } from "./types";

const IndeterminateCheckbox: React.ForwardRefRenderFunction<
  HTMLInputElement,
  IndeterminateCheckboxProps
> = ({ indeterminate = false, ...rest }, ref) => {
  const defaultRef = React.useRef<HTMLInputElement>();
  const resolvedRef = (ref ||
    defaultRef) as React.MutableRefObject<HTMLInputElement>;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={resolvedRef}
      {...rest}
      className={`${
        rest?.disabled
          ? "!cursor-not-allowed bg-gray-100"
          : "!cursor-pointer bg-white"
      } text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:ring-2`}
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default forwardRef(IndeterminateCheckbox);
