import React from "react";
import { Table, Radio, Checkbox } from "semantic-ui-react";
import PropTypes from "prop-types";
import { prettyDate } from "../../../../../../../../../../services/DateTime";
import Constants from "../../../../../../../../../../services/Constants/strings";
import { financialModelInfo } from "../../../../../../../../../../services/Constants/Models";

function FinancialStatements(props) {
  return (
    <Table.Row
      key={props.data.uuid}
      active={props.data.uuid === props.statementSelected}
      disabled={props.disabled}
    >
      <Table.Cell>
        <Radio
          name="selection"
          value={props.data.uuid}
          checked={props.statementSelected === props.data.uuid}
          onClick={event => props.handleRowClick(event, props.data)}
        />
      </Table.Cell>
      <Table.Cell>
        {props.data.statementDate && prettyDate(props.data.statementDate)}
      </Table.Cell>
      <Table.Cell>{props.data.statementType}</Table.Cell>
      <Table.Cell>{props.data.period}</Table.Cell>
      <Table.Cell>
        {props.data.startDate && prettyDate(props.data.startDate)}
      </Table.Cell>
      <Table.Cell>
        {props.data.endDate && prettyDate(props.data.endDate)}
      </Table.Cell>
      <Table.Cell>{props.data.analystName}</Table.Cell>
      <Table.Cell>
        {props.data.createdDate && prettyDate(props.data.createdDate)}
      </Table.Cell>
      <Table.Cell>
        {props.data.financialModelUuid
          ? financialModelInfo[props.data.financialModelUuid].name
          : ""}
      </Table.Cell>
      {props.data.status ? (
        <Table.Cell positive>{Constants.COMPLETED}</Table.Cell>
      ) : (
        <Table.Cell negative>{Constants.IN_PROGRESS}</Table.Cell>
      )}
      <Table.Cell>
        <Checkbox
          checked={props.selectedPeriods.indexOf(props.data.uuid) !== -1}
          disabled={props.disabled}
          toggle
          onClick={() =>
            props.editUUIDs(props.data.uuid, props.data.financialModelUuid)
          }
        />
      </Table.Cell>
    </Table.Row>
  );
}

FinancialStatements.propTypes = {
  statementSelected: PropTypes.string.isRequired,
  data: PropTypes.shape({
    analystName: PropTypes.string,
    archive: PropTypes.bool,
    createdDate: PropTypes.string,
    endDate: PropTypes.string,
    financialModelUuid: PropTypes.string,
    startDate: PropTypes.string,
    period: PropTypes.string,
    statementDate: PropTypes.string,
    statementType: PropTypes.string,
    status: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    sourceDocument: PropTypes.string,
    uuid: PropTypes.string
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  editUUIDs: PropTypes.func.isRequired,
  selectedPeriods: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default FinancialStatements;
