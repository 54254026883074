import React, { useMemo } from "react";
import {
  Modal,
  Button,
  Form,
  Grid,
  GridRow,
  GridColumn
} from "semantic-ui-react";
import PropTypes from "prop-types";

import Constants from "../../../../../../../../../../services/Constants/strings";
import {
  acctTypeOptions,
  dealRatings,
  loanFacilityGrade,
  loanStatusOptions
} from "../../../../../../../../../../services/FormElements";
import { useInstitutionLoanClassesQuery } from "../../../../../../../../../../services/RTKQuery/institutions";
import { serializeLoanClassesData } from "../../../../../../../../../../services/FormElements/components/loanOptions";
import parseStringAsNumber from "../../../../../../../../../../services/ParseStringAsNumber";
import SemanticDatePicker from "../../../../../../../../../../components/SemanticDatePicker";
import NumberInput from "../../../../../../../../../../components/CustomFormElements/components/NumberInput";

function LoanModal({
  institutionUuid,
  open,
  toggleLoanModal,
  loanModalNew,
  loanModalInfo,
  loanModalUpdateInfo,
  loanModalUpdateNumber,
  addLoan,
  changeDateLoan,
  isCrrmInstitution
}) {
  const { data: loanClasses } = useInstitutionLoanClassesQuery(institutionUuid);

  const serializedLoanClassesData = useMemo(() => {
    return serializeLoanClassesData({
      data: loanClasses?.loan_classes,
      loan_class: loanModalInfo?.loan_class,
      loan_type: loanModalInfo?.loan_type
    });
  }, [
    loanClasses?.loan_classes,
    loanModalInfo?.loan_class,
    loanModalInfo?.loan_type
  ]);

  return (
    <Modal open={open} size="large">
      <Modal.Header>{loanModalNew ? "Add Loan" : "Edit Loan"}</Modal.Header>
      <Modal.Content>
        <Form>
          <Grid>
            <GridRow>
              <GridColumn width="4">
                <Form.Input
                  label={Constants.BLAST_ACCOUNT_NUMBER}
                  value={loanModalInfo.bafs_account_number || ""}
                  name="bafs_account_number"
                  onChange={(e, { name, value }) =>
                    loanModalUpdateInfo({ [name]: value })
                  }
                />
              </GridColumn>
              <GridColumn width="4">
                <Form.Input
                  label={Constants.INSTITUTION_ACCOUNT_NUMBER}
                  value={loanModalInfo.institution_account_number || ""}
                  name="institution_account_number"
                  onChange={(e, { name, value }) =>
                    loanModalUpdateInfo({ [name]: value })
                  }
                />
              </GridColumn>
              <GridColumn width="4">
                <SemanticDatePicker
                  data-testid="loan-modal-change-date"
                  date={loanModalInfo.contract_date || ""}
                  label="Contract Date"
                  name="contract_date"
                  onChange={e => changeDateLoan(e, "contract_date")}
                />
              </GridColumn>
              <GridColumn width="4">
                <SemanticDatePicker
                  date={loanModalInfo.maturity_date || ""}
                  label="Maturity Date"
                  name="maturity_date"
                  onChange={e => changeDateLoan(e, "maturity_date")}
                />
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn width="4">
                <NumberInput
                  data-testid="loan-modal-current-balance"
                  decimalPlaces={2}
                  label={Constants.CURRENT_BALANCE}
                  value={loanModalInfo.current_balance}
                  name="current_balance"
                  onChange={loanModalUpdateNumber}
                  onBlur={() => {
                    loanModalUpdateNumber(null, {
                      name: "current_balance",
                      value: parseStringAsNumber(
                        loanModalInfo.current_balance,
                        true
                      )
                    });
                  }}
                />
              </GridColumn>
              <GridColumn width="4">
                <NumberInput
                  decimalPlaces={2}
                  label={Constants.AVAILABLE_BALANCE}
                  name="available_balance"
                  onChange={loanModalUpdateNumber}
                  value={loanModalInfo.available_balance}
                  onBlur={() => {
                    loanModalUpdateNumber(null, {
                      action: "blur",
                      name: "available_balance",
                      value: parseStringAsNumber(
                        loanModalInfo.available_balance,
                        true
                      )
                    });
                  }}
                />
              </GridColumn>
              <GridColumn width="4">
                <NumberInput
                  decimalPlaces={2}
                  label={Constants.CURRENT_CONTRACT_AMOUNT}
                  name="current_contract_amount"
                  onChange={loanModalUpdateNumber}
                  value={loanModalInfo.current_contract_amount}
                  onBlur={() => {
                    loanModalUpdateNumber(null, {
                      action: "blur",
                      name: "current_contract_amount",
                      value: parseStringAsNumber(
                        loanModalInfo.current_contract_amount,
                        true
                      )
                    });
                  }}
                />
              </GridColumn>
              <GridColumn width="4">
                <Form.Input
                  label={Constants.TOTAL_EXPOSURE}
                  value={loanModalInfo.total_exposure}
                  name="total_exposure"
                  onChange={loanModalUpdateNumber}
                  onBlur={() => {
                    loanModalUpdateNumber(null, {
                      action: "blur",
                      name: "total_exposure",
                      value: parseStringAsNumber(
                        loanModalInfo.total_exposure,
                        true
                      )
                    });
                  }}
                  type="number"
                />
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn width="4">
                <Form.Select
                  label={Constants.ACCOUNT_TYPE}
                  options={acctTypeOptions}
                  value={loanModalInfo.account_type}
                  onChange={(e, { name, value }) =>
                    loanModalUpdateInfo({ [name]: value })
                  }
                  name="account_type"
                />
              </GridColumn>
              <GridColumn width="4">
                <Form.Select
                  label={Constants.LOAN_CLASS}
                  options={serializedLoanClassesData?.classOptions}
                  value={loanModalInfo.loan_class}
                  onChange={(e, { name, value }) =>
                    loanModalUpdateInfo({ [name]: value })
                  }
                  name="loan_class"
                />
              </GridColumn>
              <GridColumn width="4">
                <Form.Select
                  label={Constants.LOAN_TYPE}
                  options={serializedLoanClassesData?.typeOptions}
                  disabled={!loanModalInfo?.loan_class}
                  value={loanModalInfo.loan_type}
                  onChange={(e, { name, value }) =>
                    loanModalUpdateInfo({ [name]: value })
                  }
                  name="loan_type"
                />
              </GridColumn>
              <GridColumn width="4">
                <Form.Select
                  label={Constants.FACILITY_GRADE}
                  name="facility_grade"
                  onChange={(e, { name, value }) =>
                    loanModalUpdateInfo({ [name]: value })
                  }
                  options={loanFacilityGrade}
                  value={loanModalInfo.facility_grade}
                />
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn width="8">
                <Form.Select
                  label={Constants.DEAL_RATING}
                  name="deal_rating"
                  onChange={loanModalUpdateNumber}
                  options={dealRatings}
                  type="number"
                  value={
                    loanModalInfo?.creditRiskRating
                      ? loanModalInfo.creditRiskRating
                      : loanModalInfo.deal_rating
                  }
                  disabled={
                    (loanModalNew && isCrrmInstitution) ||
                    !!loanModalInfo?.creditRiskRating
                  }
                />
              </GridColumn>
              <GridColumn width="8">
                <Form.Select
                  label={Constants.STATUS}
                  value={loanModalInfo.status}
                  onChange={(e, { name, value }) =>
                    loanModalUpdateInfo({ [name]: value })
                  }
                  name="status"
                  options={loanStatusOptions}
                />
              </GridColumn>
            </GridRow>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={toggleLoanModal}>
          Cancel
        </Button>
        <Button
          onClick={addLoan}
          color="green"
          data-testid="loan-modal-add-loan"
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

LoanModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleLoanModal: PropTypes.func.isRequired,
  loanModalNew: PropTypes.bool.isRequired,
  changeDateLoan: PropTypes.func.isRequired,
  loanModalUpdateInfo: PropTypes.func.isRequired,
  loanModalUpdateNumber: PropTypes.func.isRequired,
  loanModalInfo: PropTypes.shape({
    bafs_account_number: PropTypes.string,
    institution_account_number: PropTypes.string,
    accountNumber: PropTypes.string,
    current_balance: PropTypes.number,
    available_balance: PropTypes.number,
    current_contract_amount: PropTypes.number,
    total_exposure: PropTypes.number,
    account_type: PropTypes.string,
    loan_class: PropTypes.string,
    loan_type: PropTypes.string,
    facility_grade: PropTypes.string,
    deal_rating: PropTypes.number,
    status: PropTypes.string,
    contract_date: PropTypes.string,
    maturity_date: PropTypes.string,
    phoenixImport: PropTypes.string,
    creditRiskRating: PropTypes.number
  }).isRequired,
  addLoan: PropTypes.func.isRequired,
  isCrrmInstitution: PropTypes.bool.isRequired
};

export default LoanModal;
