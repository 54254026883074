import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Button } from "semantic-ui-react";
import SplitLayout from "../../components/SplitLayout";
import { ContractDatesTable, FutureDatesTable } from "./components/DatesTables";
import DatesEditModal from "./components/DatesEditModal";

function Dates({ loan, onUpdateLoan, isMonthEnd }) {
  const [modalOpen, setModalOpen] = useState(false);

  const editButton = !isMonthEnd ? (
    <Box>
      <Button
        data-testid="edit-date-button"
        circular
        basic
        icon="edit"
        onClick={() => setModalOpen(true)}
      />
    </Box>
  ) : null;

  return (
    <>
      {editButton}
      <SplitLayout>
        <ContractDatesTable loan={loan} />
        <FutureDatesTable loan={loan} />
      </SplitLayout>
      <DatesEditModal
        loan={loan}
        onSave={changes => onUpdateLoan({ ...changes, uuid: loan.uuid })}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
}

const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-right: 16px;
`;

Dates.propTypes = {
  loan: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    current_balance: PropTypes.number
  }).isRequired,
  onUpdateLoan: PropTypes.func,
  isMonthEnd: PropTypes.bool
};

export default Dates;
