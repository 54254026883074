import React from "react";
import PropTypes from "prop-types";
import { Button, Grid, Header, Icon } from "semantic-ui-react";
import { bureauNameToKey } from "../../../../../../services/Constants/creditBureaus";

function CreditInfo({ entity, readOnly, onCreditInfoEdit }) {
  const dataLabelStyle = {
    margin: 0,
    marginTop: "1em"
  };

  return (
    <>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column style={{ marginTop: "6px" }}>
            <Header as="h3">Credit Information</Header>
          </Grid.Column>
          <Grid.Column>
            {!readOnly && (
              <Button
                basic
                circular
                icon
                floated="right"
                onClick={onCreditInfoEdit}
                aria-label="edit credit information"
              >
                <Icon
                  name="pencil alternate"
                  color={readOnly ? "grey" : "green"}
                />
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid columns={3}>
        <Grid.Column>
          <Header as="h5" style={dataLabelStyle}>
            Previous Review Date
          </Header>
          <b>{entity.previous_review_date}</b>
          <br />
        </Grid.Column>
        <Grid.Column>
          <Header as="h5" style={dataLabelStyle}>
            Borrower Rating
          </Header>
          <b>{entity.risk_rating}</b>
          <br />
        </Grid.Column>
        {entity.entity_type === "Individual" ? (
          ""
        ) : (
          <Grid.Column>
            <Header as="h5" style={dataLabelStyle}>
              FICO SBSS:
            </Header>
            <b>{entity.fico_sbss}</b>
            <br />
          </Grid.Column>
        )}
        <Grid.Column>
          <Header as="h5" style={dataLabelStyle}>
            Next Review Date
          </Header>
          <b>{entity.next_review_date}</b>
          <br />
        </Grid.Column>
        {entity.entity_type === "Individual" ? (
          ""
        ) : (
          <Grid.Column>
            <Header as="h5" style={dataLabelStyle}>
              Duns Number:
            </Header>
            <b>{entity.duns_number}</b>
            <br />
          </Grid.Column>
        )}
        <Grid.Column>
          <Grid.Row columns={2}>
            <Header as="h5" style={dataLabelStyle}>
              Credit Score:
            </Header>
            <b>{entity.credit_bureau}</b>
          </Grid.Row>
          <b>{entity[bureauNameToKey[entity.credit_bureau]]}</b>
          <br />
        </Grid.Column>
      </Grid>
    </>
  );
}

CreditInfo.propTypes = {
  entity: PropTypes.shape({
    entity_type: PropTypes.string,
    previous_review_date: PropTypes.string,
    dataLabelStyle: PropTypes.string,
    risk_rating: PropTypes.number,
    fico_sbss: PropTypes.string,
    next_review_date: PropTypes.string,
    duns_number: PropTypes.string,
    credit_score_experian: PropTypes.number,
    credit_bureau: PropTypes.string
  }),
  readOnly: PropTypes.bool,
  onCreditInfoEdit: PropTypes.func
};

export default CreditInfo;
