import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  DateCell,
  PropertyTable
} from "../../../../../../../../../../components/Tables";

function ContractDatesTable({ loan }) {
  const rows = useMemo(
    () => [
      {
        Header: "Current Contract Date",
        accessor: "current_contract_date",
        primary: true,
        Cell: DateCell
      },
      {
        Header: "Original Contract Date",
        accessor: "contract_date",
        Cell: DateCell
      },
      {
        Header: "Maturity Date",
        accessor: "maturity_date",
        Cell: DateCell
      }
    ],
    []
  );

  return <PropertyTable columnsConfig={rows} data={loan} />;
}

ContractDatesTable.propTypes = {
  loan: PropTypes.shape({
    current_contract_date: PropTypes.string,
    contract_date: PropTypes.string,
    maturity_date: PropTypes.string
  }).isRequired
};

export default ContractDatesTable;
