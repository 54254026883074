import { FormattedMessage } from "react-intl";
import { AnimatedDialog } from "../animated-dialog/animated-dialog";
import {
  DialogAnimationProps,
  DialogComponentProps,
  DialogDismissProps
} from "../../../../types/modules";
import { Button } from "../../../elements/button/button/button";
import { MessageProps } from "../../../../types/text";

/**
 * A basic dialog that displays a message along with two button so the user can
 * make a choice.
 *
 * This is an animated dialog so it must remain mounted until the onHidden prop
 * function has been invoked.
 */
export function ConfirmationDialog({
  affirmative,
  content,
  subText,
  header,
  negative,
  onDismiss,
  ...animatedDialogProps
}: ConfirmationDialogProps) {
  return (
    <AnimatedDialog {...animatedDialogProps}>
      <div className="header">
        <span>
          {"id" in header ? (
            <FormattedMessage {...header} />
          ) : (
            header.defaultMessage
          )}
        </span>
      </div>
      <div className="scrolling content">
        <div className="description">
          {"id" in content ? (
            <FormattedMessage {...content} />
          ) : (
            content.defaultMessage
          )}
          {subText}
        </div>
      </div>
      <div className="actions">
        <Button
          message={negative}
          variant="secondary"
          onClick={() => onDismiss?.("negative")}
        />
        <Button
          message={affirmative}
          onClick={() => onDismiss?.("affirmative")}
        />
      </div>
    </AnimatedDialog>
  );
}

export interface ConfirmationDialogProps
  extends DialogComponentProps,
    DialogAnimationProps,
    DialogDismissProps {
  /** The Message to display on the affirmative (yes) button. */
  affirmative: MessageProps;
  /** The content to display in the body of the dialog. */
  content: MessageProps;
  /** The Message to display in a second paragraph. */
  subText?: JSX.Element;
  /** The Message to display in the header of the dialog. */
  header: MessageProps;
  /** The Message to display on the negative (no) button. */
  negative: MessageProps;
}
