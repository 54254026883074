import { ClearableDropdown, Field } from "@bafsllc/ui-shared";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";

export default function PurposeDropdown({
  onDropdownChange,
  selectedPurpose,
  isViewMode
}) {
  return (
    <Field labelMessage={{ id: "PURPOSE" }} required>
      <ClearableDropdown
        id="purpose"
        onChildSelect={e => {
          onDropdownChange({
            name: "selectedPurpose",
            value: e.target.getAttribute("value")
          });
        }}
        selectedValue={{
          id:
            selectedPurpose?.split(" ").join("_").toUpperCase() ??
            "CREDIT_ANALYSIS_DROPDOWN_PLACEHOLDER"
        }}
        onClear={() => {
          onDropdownChange({
            name: "selectedPurpose",
            value: null
          });
        }}
        placeholder={{ id: "CREDIT_ANALYSIS_DROPDOWN_PLACEHOLDER" }}
        fill
        disabled={isViewMode}
      >
        <li key="underwriting" value="Underwriting">
          <FormattedMessage id="UNDERWRITING" />
        </li>
        <li key="loan_review" value="Loan Review">
          <FormattedMessage id="LOAN_REVIEW" />
        </li>
        <li key="risk_assessment" value="Risk Assessment">
          <FormattedMessage id="RISK_ASSESSMENT" />
        </li>
      </ClearableDropdown>
    </Field>
  );
}

PurposeDropdown.propTypes = {
  onDropdownChange: PropTypes.func.isRequired,
  selectedPurpose: PropTypes.string.isRequired,
  isViewMode: PropTypes.bool.isRequired
};
