import PropTypes from "prop-types";
import React from "react";
import { Form, TextArea, Grid } from "semantic-ui-react";

import { DollarField } from "../../../../../../../../../../../../components/CustomFormElements";
import { displayAndBorrowerFeeType } from "../../../../../../../../../../../../services/Constants/additionalFeeOptions";

function BillToBorrowerForm({ updateValues, fee }) {
  return (
    <>
      <strong>Bill To Borrower Fee</strong> <br />
      <Grid>
        <Grid.Row columns="2">
          <Grid.Column>
            <DollarField
              label="Original Fee Amount"
              name="original_fee_amount"
              onChange={updateValues}
              value={fee.original_fee_amount || 0}
            />
          </Grid.Column>
          <Grid.Column>
            <DollarField
              label="Fee Amount Remaining"
              name="fee_amount"
              onChange={updateValues}
              value={fee.fee_amount || 0}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="2">
          <Grid.Column>
            <Form.Dropdown
              label="Fee Reason"
              selection
              name="bill_to_borrower_reason"
              options={displayAndBorrowerFeeType}
              value={fee.bill_to_borrower_reason || ""}
              onChange={(ev, data) => updateValues(data)}
            />
          </Grid.Column>
          {fee.bill_to_borrower_reason === "Other" && (
            <Grid.Column>
              <Form.Input
                label="Other Reason"
                name="bill_to_borrower_reason_other"
                value={fee.bill_to_borrower_reason_other || ""}
                onChange={(ev, data) => updateValues(data)}
                maxLength="250"
                disabled={!fee.bill_to_borrower_reason}
                required
              />
            </Grid.Column>
          )}
          <Grid.Column>
            <b>Description</b>
            <TextArea
              name="description"
              value={fee.description || ""}
              onChange={(e, data) => updateValues(data)}
              maxLength="250"
              placeholder="Max length 250"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

BillToBorrowerForm.propTypes = {
  fee: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ).isRequired,
  updateValues: PropTypes.func.isRequired
};

export default BillToBorrowerForm;
