import React from "react";
import PropTypes from "prop-types";
import TypeCheck from "typecheck-extended";
import { Step } from "semantic-ui-react";

import { stepList } from "../../services/constants";

export function toTitleCase(srcString) {
  TypeCheck(srcString, "string");

  let titleString = srcString.replace(/_/g, " ");
  titleString = titleString.toLowerCase().split(" ");
  for (let i = 0; i < titleString.length; i += 1) {
    titleString[i] =
      titleString[i].charAt(0).toUpperCase() + titleString[i].slice(1);
  }
  titleString = titleString.join(" ");
  return titleString;
}

export function isActive(currentStep, step) {
  TypeCheck(currentStep, "string");
  TypeCheck(step, "string");

  if (currentStep === step) {
    return true;
  }
  return false;
}

export function isDisabled(uuid, currentStep, step) {
  TypeCheck(uuid, "string", false);
  TypeCheck(currentStep, "string");
  TypeCheck(step, "string");

  if (currentStep === step) {
    return false;
  }

  if (!uuid || step === stepList[0]) {
    return true;
  }

  return false;
}

export function generateStepList(uuid, current) {
  TypeCheck(uuid, "string", false);
  TypeCheck(current, "string");

  const baseAdPath = `/auto_decision_request/${uuid}/`;
  const stepItems = [
    {
      active: isActive(current, stepList[0]),
      disabled: isDisabled(uuid, current, stepList[0]),
      href: `${baseAdPath}${stepList[0]}`,
      icon: "list",
      key: stepList[0],
      link: true,
      title: toTitleCase(stepList[0])
    },
    {
      active: isActive(current, stepList[1]),
      disabled: isDisabled(uuid, current, stepList[1]),
      href: `${baseAdPath}${stepList[1]}`,
      icon: "building",
      key: stepList[1],
      link: true,
      title: toTitleCase(stepList[1])
    },
    {
      active: isActive(current, stepList[2]),
      disabled: isDisabled(uuid, current, stepList[2]),
      href: `${baseAdPath}${stepList[2]}`,
      icon: "users",
      key: stepList[2],
      link: true,
      title: toTitleCase(stepList[2])
    },
    {
      active: isActive(current, stepList[3]),
      disabled: isDisabled(uuid, current, stepList[3]),
      href: `${baseAdPath}${stepList[3]}`,
      icon: "dollar sign",
      key: stepList[3],
      link: true,
      title: toTitleCase(stepList[3])
    },
    {
      active: isActive(current, stepList[4]),
      disabled: isDisabled(uuid, current, stepList[4]),
      href: `${baseAdPath}${stepList[4]}`,
      icon: "check square",
      key: stepList[4],
      link: true,
      title: toTitleCase(stepList[4])
    }
  ];
  return stepItems;
}

function Stepper(props) {
  const { uuid } = props;
  const { currentPage } = props;
  const stepItems = generateStepList(uuid, currentPage);
  return <Step.Group items={stepItems} />;
}

Stepper.defaultProps = {
  uuid: undefined
};

Stepper.propTypes = {
  currentPage: PropTypes.string.isRequired,
  uuid: PropTypes.string
};

export default Stepper;
