import React from "react";
import { bool, func, node, number } from "prop-types";
import { Visibility } from "semantic-ui-react";

import handleScroll from "./services/handleScroll";

/* InfiniteScroll takes the following:
lastPage - bool - which should be provided from
the metadata of a GET call.
loadCallback - func - which will be called when user has
  requested more data, it should take one parameter, a page number
loading - bool - whether or not the previous request is still loading

Wrap whatever data component (table / feed / etc) in InfiniteScroll to use.
*/

function InfiniteScroll({
  children,
  lastPage,
  loading,
  loadCallback,
  percentagePassed
}) {
  const [pageNumber, updatePage] = React.useState(0);
  return (
    <Visibility
      onUpdate={(e, { calculations }) =>
        handleScroll(
          calculations,
          updatePage,
          lastPage,
          loading,
          loadCallback,
          pageNumber,
          percentagePassed
        )
      }
    >
      {children}
    </Visibility>
  );
}

InfiniteScroll.defaultProps = {
  percentagePassed: 0.3
};

InfiniteScroll.propTypes = {
  children: node.isRequired,
  lastPage: bool.isRequired,
  loading: bool.isRequired,
  loadCallback: func.isRequired,
  percentagePassed: number
};

export default InfiniteScroll;
