/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { Grid, Header } from "semantic-ui-react";
import { DollarFormat } from "../../../../../../../../../../components/CustomUIElements";

function ChargeFee({ loan }) {
  const guaranteeBalance = loan.current_balance * loan.gov_guarantee_percentage;
  const {
    gov_guarantee_program,
    gov_guarantee_fee,
    gov_guarantee_program_number,
    gov_guarantee_percentage,
    gov_guarantee_agency_program,
    guaranteed_portion_sold,
    gov_guarantee_sba_status,
    sba_fee_accrued
  } = loan;

  return (
    <Grid textAlign="left">
      <Grid.Row>
        <Grid.Column width="3">
          <Header sub color="grey">
            Guaranteed Balance
          </Header>
          <DollarFormat value={guaranteeBalance} />
        </Grid.Column>
        {gov_guarantee_program === "sba" && (
          <Grid.Column width="3">
            <Header sub color="grey">
              SBA Fee Accrued
            </Header>
            <DollarFormat
              dataTestId="sba_fee_accrued"
              value={sba_fee_accrued}
            />
          </Grid.Column>
        )}
        <Grid.Column width="3">
          <Header sub color="grey">
            Guarantee Program
          </Header>
          <span data-testid="gov_guarantee_program">
            {gov_guarantee_program || ""}
          </span>
        </Grid.Column>
        <Grid.Column width="3">
          <Header sub color="grey">
            Guarantee Percentage
          </Header>
          <span data-testid="gov_guarantee_percentage">
            {typeof gov_guarantee_percentage === "number"
              ? `${gov_guarantee_percentage * 100}%`
              : "—"}
          </span>
        </Grid.Column>
        <Grid.Column width="3">
          <Header sub color="grey">
            Program Loan Number
          </Header>
          <span data-testid="gov_guarantee_program_number">
            {gov_guarantee_program_number || ""}
          </span>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="3">
          <Header sub color="grey">
            Guarantee Fee
          </Header>
          <span data-testid="gov_guarantee_fee">
            {typeof gov_guarantee_fee === "number"
              ? `${(gov_guarantee_fee * 10000).toFixed(2)}%`
              : "—"}
          </span>
        </Grid.Column>
        <Grid.Column width="3">
          <Header sub color="grey">
            Agency Program
          </Header>
          <span data-testid="gov_guarantee_agency_program">
            {gov_guarantee_agency_program}
          </span>
        </Grid.Column>
        <Grid.Column width="3">
          <Header sub color="grey">
            Guaranteed Portion Sold
          </Header>
          <span data-testid="guaranteed_portion_sold">
            {typeof guaranteed_portion_sold === "number"
              ? `${guaranteed_portion_sold * 100}%`
              : "—"}
          </span>
        </Grid.Column>
        {gov_guarantee_program === "sba" && (
          <Grid.Column width="3">
            <Header sub color="grey">
              SBA Status
            </Header>
            <span data-testid="gov_guarantee_sba_status">
              {gov_guarantee_sba_status}
            </span>
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
}

ChargeFee.propTypes = {
  loan: PropTypes.shape({
    current_balance: PropTypes.number,
    gov_guarantee_percentage: PropTypes.number,
    gov_guarantee_program: PropTypes.string,
    gov_guarantee_fee: PropTypes.number,
    gov_guarantee_program_number: PropTypes.string,
    gov_guarantee_agency_program: PropTypes.string,
    guaranteed_portion_sold: PropTypes.number,
    gov_guarantee_sba_status: PropTypes.string,
    sba_fee_accrued: PropTypes.number
  }).isRequired
};

export default ChargeFee;
