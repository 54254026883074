import React from "react";
import PropTypes from "prop-types";
import { Grid, Button, Header } from "semantic-ui-react";
import { ConcatName as getEntityName } from "../../../../../../services/Entities";
import { bureauNameToKey } from "../../../../../../services/Constants/creditBureaus";

function EntityRelationshipsList({
  entityRelationships = [],
  entityKey = "",
  heading = "",
  singularLabel = "",
  renderSummary = () => null,
  readOnly,
  createUniqueKey,
  onAddEntityRelationship,
  onEditEntityRelationship,
  onRemoveEntityRelationship
}) {
  const sectionHeaderMargin = { paddingTop: "8px" };

  return (
    <>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column style={sectionHeaderMargin}>
            <Header as="h3">{heading}</Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            {!readOnly && onAddEntityRelationship ? (
              <AddButton
                onClick={onAddEntityRelationship}
                aria-label={`add ${singularLabel.toLowerCase()}`}
              />
            ) : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        {entityRelationships.map(entityRelationship => {
          const creditBureau =
            entityRelationship && entityRelationship.officer_entity_data
              ? entityRelationship.officer_entity_data.credit_bureau
              : null;
          const creditScore =
            creditBureau &&
            entityRelationship.officer_entity_data[
              bureauNameToKey[creditBureau]
            ];
          const entity = entityKey
            ? entityRelationship[entityKey]
            : entityRelationship;
          const key = createUniqueKey
            ? createUniqueKey(entityRelationship)
            : entity.uuid;
          return (
            <Grid.Row columns={4} key={key}>
              <Grid.Column>
                <b>{getEntityName(entity)}</b>
              </Grid.Column>
              <Grid.Column>{renderSummary(entityRelationship)}</Grid.Column>
              <Grid.Column>
                {!readOnly && onEditEntityRelationship ? (
                  <EditButton
                    disabled={readOnly}
                    onClick={() => onEditEntityRelationship(entityRelationship)}
                    aria-label={`edit ${singularLabel.toLowerCase()}`}
                  />
                ) : null}
                {!readOnly && onRemoveEntityRelationship ? (
                  <RemoveButton
                    disabled={readOnly}
                    onClick={() =>
                      onRemoveEntityRelationship(entityRelationship)
                    }
                    aria-label={`remove ${singularLabel.toLowerCase()}`}
                  />
                ) : null}
              </Grid.Column>
              <Grid.Column>
                <b>
                  Credit Score:{" "}
                  {creditBureau ? `${creditScore} (${creditBureau})` : ""}
                </b>
              </Grid.Column>
            </Grid.Row>
          );
        })}
      </Grid>
    </>
  );
}

EntityRelationshipsList.propTypes = {
  entityRelationships: PropTypes.arrayOf(PropTypes.shape({})),
  entityKey: PropTypes.string,
  heading: PropTypes.node,
  singularLabel: PropTypes.string,
  renderSummary: PropTypes.func,
  readOnly: PropTypes.bool,
  createUniqueKey: PropTypes.func,
  onAddEntityRelationship: PropTypes.func,
  onEditEntityRelationship: PropTypes.func,
  onRemoveEntityRelationship: PropTypes.func
};

function AddButton({ disabled, ...props }) {
  return (
    <Button
      basic
      circular
      icon="plus"
      disabled={disabled}
      color={disabled ? "grey" : "green"}
      {...props}
    />
  );
}

function EditButton({ disabled, ...props }) {
  return (
    <Button
      basic
      circular
      icon="pencil alternate"
      disabled={disabled}
      color={disabled ? "grey" : "green"}
      {...props}
    />
  );
}

function RemoveButton({ disabled, ...props }) {
  return (
    <Button
      basic
      circular
      icon="times"
      disabled={disabled}
      color={disabled ? "grey" : "green"}
      {...props}
    />
  );
}

export default EntityRelationshipsList;
