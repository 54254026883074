/* eslint-disable camelcase */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Header, Modal, Menu, Grid, Divider } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import {
  EntityTypeForm,
  IndividualFinancialInfo,
  ContactInfoContent,
  CreditInfoContent,
  EntityInfoContent
} from "./components";
import { isIdentificationNumberValue } from "../../../CustomFormElements/components/IdentificationNumberInput/identification-number";
import logger from "../../../../services/logger";
import useEntity from "./hooks/useEntity/useEntity";

const STEP_ADD = 0;
const STEP_ENTITY_INFO = 1;
const STEP_CONTACT_INFO = 2;
const STEP_CREDIT_INFO = 3;
const STEP_FINANCIAL_INFO = 4;

export default function EntityModal({
  add = false,
  entityUuid,
  onCloseModal,
  onSuccess,
  open,
  selectedInstitution = ""
}) {
  const {
    entity_type,
    handleInputChange,
    handleSaveModal: onSaveModal,
    setStepNumber,
    stepNumber,
    tin,
    needsEntityType
  } = useEntity({ entityUuid, institutionUuid: selectedInstitution });

  useEffect(() => {
    if (add) {
      return;
    }
    if (needsEntityType) {
      setStepNumber(STEP_ADD);
      return;
    }

    setStepNumber(STEP_ENTITY_INFO);
  }, [add, needsEntityType, setStepNumber]);

  return (
    <Modal size="large" open={open}>
      <Modal.Header>
        <Grid>
          <Grid.Row>
            <Grid.Column width="8">
              <Header as="h2">{add ? "New Entity" : "Edit Entity"}</Header>
            </Grid.Column>
            <Grid.Column width="8">
              {stepNumber > STEP_ADD ? (
                <Header as="h4" content={`Entity Type: ${entity_type}`} />
              ) : (
                ""
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Divider />
      <Modal.Header size="tiny">
        <Menu pointing secondary color="green">
          {add || needsEntityType ? (
            <Menu.Item
              disabled={stepNumber > STEP_ADD}
              active={stepNumber === STEP_ADD}
              content="Entity Type"
            />
          ) : (
            ""
          )}
          <Menu.Item
            disabled={!entity_type}
            active={stepNumber === STEP_ENTITY_INFO}
            onClick={() => setStepNumber(STEP_ENTITY_INFO)}
            content="Entity Info"
          />
          <Menu.Item
            disabled={!entity_type}
            active={stepNumber === STEP_CONTACT_INFO}
            onClick={() => setStepNumber(STEP_CONTACT_INFO)}
            content="Contact Info"
          />
          <Menu.Item
            disabled={!entity_type}
            active={stepNumber === STEP_CREDIT_INFO}
            onClick={() => setStepNumber(STEP_CREDIT_INFO)}
            content="Credit Info"
          />
          {entity_type === "Individual" ? (
            <Menu.Item
              disabled={!entity_type}
              active={stepNumber === STEP_FINANCIAL_INFO}
              onClick={() => setStepNumber(STEP_FINANCIAL_INFO)}
              content="Financial Info"
            />
          ) : (
            ""
          )}
        </Menu>
      </Modal.Header>
      <EntityModalContent
        add={add}
        entityType={entity_type}
        handleInputChange={handleInputChange}
        stepNumber={stepNumber}
      />
      <EntityModalActions
        changeStep={setStepNumber}
        disabled={!isIdentificationNumberValue(tin) && stepNumber !== STEP_ADD}
        entityType={entity_type}
        onCloseModal={onCloseModal}
        onSaveModal={() =>
          onSaveModal(add, onSuccess, response =>
            logger.error("Entities.update error.", response)
          )
        }
        stepNumber={stepNumber}
      />
    </Modal>
  );
}

EntityModal.propTypes = {
  add: PropTypes.bool,
  entityUuid: PropTypes.string,
  onCloseModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedInstitution: PropTypes.string
};

function EntityModalActions({
  changeStep,
  disabled,
  entityType,
  onCloseModal,
  onSaveModal,
  stepNumber
}) {
  const dispatch = useDispatch();

  const maxStep =
    entityType === "Individual" ? STEP_FINANCIAL_INFO : STEP_CREDIT_INFO;
  const hasNext = stepNumber < maxStep;
  const hasSave = stepNumber > STEP_ADD;

  function handleCloseModal() {
    dispatch({
      type: "ENTITY_MODAL_CLEAR_STATE"
    });
    onCloseModal();
  }

  return (
    <Modal.Actions>
      <Button basic onClick={handleCloseModal} content="Cancel" />
      {hasSave ? (
        <Button
          color="green"
          disabled={disabled}
          onClick={() => {
            onSaveModal();
            handleCloseModal();
          }}
          content="Save and Close"
        />
      ) : null}
      {hasNext ? (
        <Button
          color="green"
          className="!ml-2"
          onClick={() => changeStep(stepNumber + 1)}
          disabled={disabled}
          content="Next"
        />
      ) : null}
    </Modal.Actions>
  );
}

function EntityModalContent({
  add,
  entityType,
  handleInputChange,
  stepNumber
}) {
  let content;
  switch (stepNumber) {
    case STEP_ADD: {
      content = (
        <Modal.Content>
          <EntityTypeForm handleInputChange={handleInputChange} />
        </Modal.Content>
      );
      break;
    }

    case STEP_ENTITY_INFO: {
      content = <EntityInfoContent add={add} />;
      break;
    }

    case STEP_CONTACT_INFO: {
      content = <ContactInfoContent entityType={entityType} />;
      break;
    }

    case STEP_CREDIT_INFO: {
      content = <CreditInfoContent entityType={entityType} />;
      break;
    }

    case STEP_FINANCIAL_INFO: {
      content = (
        <Modal.Content>
          <IndividualFinancialInfo />
        </Modal.Content>
      );
      break;
    }

    default: {
      break;
    }
  }

  return content;
}
