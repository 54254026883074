import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";

import type { MessageProps } from "../../../types/text";
import type { TextMenuItem } from "../../../types/collections";
import type { InstitutionSwitcherProps } from "../institution-switcher/institution-switcher";
import { InstitutionSwitcher } from "../institution-switcher/institution-switcher";
import type { NotificationProps } from "./notifications/notification-item/notification-item";
import { Notifications } from "./notifications/notifications";
import type { UserSettingsProps } from "./user-settings/user-settings";
import { UserSettings } from "./user-settings/user-settings";
import type { TertiaryMenuProps } from "../../../fomantic/collections/menus/tertiary-menu/tertiary-menu";
import { TertiaryMenu } from "../../../fomantic/collections/menus/tertiary-menu/tertiary-menu";
import { Message } from "../../elements/message/message";

export function GlobalHeader(props: GlobalHeaderProps): JSX.Element;
export function GlobalHeader<MenuItem extends TextMenuItem>(
  props: GlobalHeaderProps & GlobalHeaderSubmenuProps<MenuItem>
): JSX.Element;
/**
 * This component is used by all applications to access notifications and the
 * user's account information. If the user is a BAFS user they will also be able
 * to select the institution they are currently accessing.
 */
export function GlobalHeader({
  baseHref,
  hideMyAccountOption = false,
  hideUserSettings = false,
  home = "/",
  notification,
  user,
  loadHeaderAsInvisible,
  logout,
  portalName,
  toggleNotificationsPanel,
  unreadNotificationsCount = 0,
  ...props
}: GlobalHeaderProps) {
  const subMenu = isSubmenuProps(props) && (
    <TertiaryMenu
      items={props.submenuItems}
      onItemSelect={props.onSubmenuItemSelect}
    />
  );

  const logoURL =
    portalName === "borrower-portal"
      ? `${props.institutionAssetsUrl}/${props.institutionUuid}/logo.png`
      : "/institutions/logo.png";

  return (
    <div
      className="global-header bg-white"
      style={loadHeaderAsInvisible ? { display: "none" } : undefined}
    >
      <div className="global-header-top flex items-center justify-between px-4 py-2 border-0 !border-b border-red-400">
        <div className="global-header-institution flex items-center">
          <a href={home}>
            <img
              src={logoURL}
              className="global-header-institution-logo"
              alt="logo"
            />
          </a>
          {isTitleProps(props) ? (
            <div className="global-header-institution-name h5 ml-4">
              <Message {...props.title} />
              {isDefaultMessage(props) && portalName === "borrower-portal" && (
                <div className="global-header-app-name">Borrower Portal</div>
              )}
            </div>
          ) : (
            <div className="global-header-institution-filter h5 ml-4">
              <InstitutionSwitcher
                isBafsUser={props.isBafsUser}
                institution={props.institution}
                user={user}
              />
            </div>
          )}
        </div>
        <div className="right menu flex items-center mr-5">
          {toggleNotificationsPanel && (
            <>
              <button
                className="bg-none px-2 bold mr-4 !text-2xl text-neutral-500 hover:text-neutral-600"
                onClick={toggleNotificationsPanel}
              >
                <FontAwesomeIcon icon={faBell} />
                {unreadNotificationsCount > 0 && (
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-cyan-600 border border-white uppercase relative -top-4 -left-2">
                    {unreadNotificationsCount}
                  </span>
                )}
              </button>
            </>
          )}

          {notification && (
            <Notifications
              notifications={notification.notifications}
              onDismissed={notification.onDismissed}
              onNotificationClicked={notification.onNotificationClicked}
            />
          )}

          {!hideUserSettings && (
            <UserSettings
              hideMyAccountOption={hideMyAccountOption}
              portalName={portalName}
              logout={logout}
              user={user}
            />
          )}
        </div>
      </div>
      {subMenu}
    </div>
  );
}

export type GlobalHeaderProps = GlobalHeaderBafsUserProps;

export interface GlobalHeaderCommonProps extends UserSettingsProps {
  /** This is the application's base path that will be used to construct
   * absolute paths. This is not used for the logo's link: set the `home`
   * property if it needs to be changed, */
  baseHref: Required<React.ComponentProps<"img">>["src"];
  /** Removes the My Account option from the user settings dropdown; defaults to
   * false. */
  hideMyAccountOption?: boolean;
  /** If true the the user options dropdown will not be displayed; defaults to
   * false. */
  hideUserSettings?: boolean;
  /** The browser will be navigated to this location when: the logo image is
   * clicked. Defaults to '/'. */
  home?: React.ComponentProps<"a">["href"];
  /**
   * This prop contains the notifications list
   */
  /** Hide the Global Header, but keep global code (i.e. session management) running in background */
  loadHeaderAsInvisible?: boolean;
  notification?: {
    notifications?: NotificationProps[] | null;
    onDismissed?: () => void;
    onNotificationClicked?: (item: NotificationProps) => void;
  };
  portalName: "blast" | "borrower-portal";
  toggleNotificationsPanel?: () => void;
  unreadNotificationsCount?: number;
  institutionUuid?: string;
  institutionAssetsUrl?: string;
}

/** Provide these props when the user is a BAFS user. */
export interface GlobalHeaderBafsUserProps extends GlobalHeaderCommonProps {
  /** The institution switcher will be shown only when the user is a BAFS user.
   * */
  institution: InstitutionSwitcherProps;
  /** Set to true if the current user is a BAFS user, will cause a variety of
   * BAFS only controls to be displayed. */
  isBafsUser: true;
}

/** Provide these props when the user is NOT a BAFS user. */
export interface GlobalHeaderTitleProps extends GlobalHeaderCommonProps {
  /** The name of the user's institution that will be displayed in the UI. */
  title: MessageProps;
}

/**
 * If this interface is implemented the submenu at the bottom of the global
 * header will appear.
 */
export type GlobalHeaderSubmenuProps<Item extends TextMenuItem> =
  GlobalHeaderProps & {
    /** Objects to be displayed as items in the navigation submenu. */
    submenuItems: TertiaryMenuProps<Item>["items"];
    /** Invoked when one of the submenu items is selected by the user. */
    onSubmenuItemSelect: TertiaryMenuProps<Item>["onItemSelect"];
  };

function isSubmenuProps(
  props: any
): props is GlobalHeaderSubmenuProps<TextMenuItem> {
  return (
    props &&
    "submenuItems" in props &&
    !!props.submenuItems &&
    "onSubmenuItemSelect" in props &&
    !!props.onSubmenuItemSelect
  );
}

function isTitleProps(props: any): props is GlobalHeaderTitleProps {
  return props && "title" in props;
}

function isDefaultMessage(props: any): props is GlobalHeaderTitleProps {
  return props.title.defaultMessage && !props.title.id;
}
