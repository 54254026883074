import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";

export default function GuaranteesTable({ guarantees }) {
  const getRedirectUrl = guarantee =>
    guarantee.bafsAccountNumber || guarantee.institutionAccountNumber
      ? `/loan_management/${guarantee.parent_uuid}/details/balance`
      : `/los/${guarantee.loanAppUuid}/phases/app_details`;
  return guarantees.length ? (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width="1">Loan Number</Table.HeaderCell>
          <Table.HeaderCell width="1">Full/Limited</Table.HeaderCell>
          <Table.HeaderCell width="1">Guaranteed</Table.HeaderCell>
          <Table.HeaderCell width="1">Amount Guaranteed</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {guarantees.map(guarantee => (
          <Table.Row key={guarantee.parent_uuid}>
            <Table.Cell>
              <a
                href={getRedirectUrl(guarantee)}
                rel="noreferrer noopener"
                target="_blank"
              >
                <i className="linkify icon" />
                {guarantee.bafsAccountNumber ||
                  guarantee.institutionAccountNumber ||
                  guarantee.appId}
              </a>
            </Table.Cell>
            <Table.Cell>
              <h5>{guarantee.full_vs_limited || "-"}</h5>
            </Table.Cell>
            <Table.Cell>
              <h5>{`${guarantee.percent_guaranteed * 100}%`}</h5>
            </Table.Cell>
            <Table.Cell>
              <h5>
                {guarantee.amount_guaranteed
                  ? new Intl.NumberFormat("en-us", {
                      style: "currency",
                      currency: "USD"
                    }).format(guarantee.amount_guaranteed)
                  : "-"}
              </h5>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  ) : null;
}

GuaranteesTable.propTypes = {
  guarantees: PropTypes.arrayOf(PropTypes.object).isRequired
};
