import { useState, useEffect } from "react";
import { SideSheet, IconButton, Panel } from "@bafsllc/ui-shared";
import { Form, Radio } from "semantic-ui-react";

export const InterestScheduleSideSheet = ({
  isOpen,
  isEditMode = false,
  interestSchedule = {},
  onClose
}: InterestScheduleSideSheetProps) => {
  const [interestScheduleForm, setInterestScheduleForm] =
    useState(interestSchedule);

  useEffect(() => {
    if (isEditMode) {
      setInterestScheduleForm(interestSchedule);
    }
  }, [isEditMode, interestSchedule]);

  const handleOptionChange = value => {
    setInterestScheduleForm({ ...interestScheduleForm, rate_type: value });
  };

  const handleClose = () => {
    if (isEditMode) {
      setInterestScheduleForm({});
    }
    onClose();
  };

  const header = isEditMode
    ? "Edit Interest Schedule"
    : "New Interest Schedule";

  const title = isEditMode ? "Edit" : "Add";

  return (
    <SideSheet isOpen={isOpen} onClose={handleClose} size="two-third">
      <div className="flex justify-between items-center border-b border-neutral-300 pb-4">
        <div>
          <p className="mb-0 text-xs">{title}</p>
          <h3 className="text-lg font-semibold">{header}</h3>
          <p className="text-sm text-neutral-600">Loan Request 1</p>
        </div>
        <div className="flex">
          <span className="mr-3">
            <IconButton
              message={{ id: "CANCEL" }}
              onClick={handleClose}
              variant="secondary"
              size="tiny"
            />
          </span>
          <span>
            <IconButton
              message={{ id: "SAVE" }}
              onClick={handleClose}
              variant="primary"
              size="tiny"
            />
          </span>
        </div>
      </div>
      <Panel className="mt-1">
        <Form>
          <div className="mb-2">
            <h3 className="text-sm font-semibold m-0">
              <b>Rate Type:</b>
            </h3>
          </div>
          <div className="inline-flex items-center mr-4">
            <Radio
              label="Fixed"
              name="radioGroup"
              value="Fixed"
              data-testid="fixed-test-id"
              checked={interestScheduleForm["rate_type"] === "Fixed"}
              onChange={() => handleOptionChange("Fixed")}
            />
          </div>
          <div className="inline-flex items-center mr-4">
            <Radio
              label="Variable"
              name="radioGroup"
              value="Variable"
              data-testid="variable-test-id"
              checked={interestScheduleForm["rate_type"] === "Variable"}
              onChange={() => handleOptionChange("Variable")}
            />
          </div>
        </Form>
      </Panel>
    </SideSheet>
  );
};

interface InterestScheduleSideSheetProps {
  onClose: () => void;
  title?: string;
  isEditMode?: boolean;
  interestSchedule?: {};
  isOpen: boolean;
}
