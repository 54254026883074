/* eslint-env browser */
export const initialState = {
  error: "",
  entityDetails: {},
  loanApp: {
    uuid: ""
  },
  showDetails: true,
  readOnly: true,
  readOnlyPhase: false,
  warningModal: false,
  optOut: false,
  submittedUser: "",
  institution: {},
  institutionUser: "",
  institutionName: "",
  institutionIsBAFSManaged: false,
  assignedUser: "",
  completedByUser: "",
  hasAppManagement: false,
  institutionCoreOfRecord: false,
  loanRequest: {},
  totalLoanRequest: {},
  borrowers: [],
  entities: {},
  selectedBorrower: "",
  isOpen: false,
  relUuid: "",
  confirmModal: false
};

/*
  This Reducer should be used only for things shared across
  all of LoanOriginationPhases (i.e. the Header). The other
  tabs should use their own reducer for content unique to
  that tab.
*/
// eslint-disable-next-line default-param-last
export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case "LOS_PHASES_ERROR":
      return { ...state, error: action.error };

    case "LOS_PHASES_SET_ENTITY_DETAILS":
      return { ...state, entityDetails: action.entityDetails };

    case "LOS_PHASES_SET_LOAN_APP":
      return { ...state, loanApp: action.loanApp };

    case "LOS_PHASES_SET_LOAN_REQUEST":
      return { ...state, loanRequest: action.loanRequest };

    case "LOS_PHASES_SET_TOTAL_LOAN":
      return { ...state, totalLoanRequest: action.totalLoanRequest };

    case "LOS_PHASES_SHOW_DETAILS":
      return { ...state, showDetails: !state.showDetails };

    case "LOS_PHASES_SET_READ_ONLY":
      return {
        ...state,
        readOnly: action.readOnly
      };
    case "LOS_PHASES_SET_BORROWERS":
      return {
        ...state,
        borrowers: action.newBorrowers
      };
    case "LOS_PHASES_POPULATE_ENTITY_DETAILS": {
      return { ...state, entityDetails: action.data };
    }
    case "LOS_PHASES_TOGGLE_MODAL":
      return {
        ...state,
        isOpen: !state.isOpen
      };
    case "LOS_PHASES_UPDATE_SELECTED_RELATIONSHIP":
      return {
        ...state,
        confirmModal: !state.confirmModal,
        relUuid: action.relUuid,
        isOpen: !state.isOpen
      };
    case "LOS_PHASES_SET_ENTITY":
      return {
        ...state,
        entities: { ...state.entities, [action.uuid]: action.entity }
      };

    case "LOS_PHASES_UPDATE_SELECTED_BORROWER":
      return { ...state, selectedBorrower: action.selectedBorrower };

    // below differs from SET_READ_ONLY in that there is no way to change
    // read only state after a certain phase
    case "LOS_PHASES_SET_READ_ONLY_PHASE":
      return { ...state, readOnlyPhase: action.readOnlyPhase };

    case "LOS_PHASES_SET_WARNING_MODAL":
      return { ...state, warningModal: action.warningModal };

    case "LOS_PHASES_SET_OPT_OUT":
      return { ...state, optOut: action.optOut };

    case "LOS_PHASES_SET_ASSIGNED_USER":
      return { ...state, assignedUser: action.wholeName };

    case "LOS_PHASES_SET_COMPLETED_BY_USER":
      return { ...state, completedByUser: action.wholeName };

    case "LOS_PHASES_SET_INSTITUTION_USER":
      return { ...state, institutionUser: action.wholeName };

    case "LOS_PHASES_SET_SUBMITTED_USER":
      return { ...state, submittedUser: action.wholeName };

    case "LOS_PHASES_APP_MGMT_PERM":
      return { ...state, hasAppManagement: action.hasAppManagement };

    case "LOS_PHASES_SET_INSTITUTION_NAME":
      return { ...state, institutionName: action.wholeName };

    case "LOS_PHASES_SET_INSTITUTION":
      return { ...state, institution: action.institution };

    case "LOS_PHASES_SET_INSTITUTION_CORE_OF_RECORD":
      return { ...state, institutionCoreOfRecord: action.wholeName };

    case "LOS_PHASES_SET_INSTITUTION_IS_BAFS_MANAGED":
      return {
        ...state,
        institutionIsBAFSManaged: action.institutionIsBAFSManaged
      };

    default:
      return state;
  }
}
