import React from "react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";

function Layout({ children }) {
  const nodes = React.Children.toArray(children);
  return (
    <Grid>
      <Grid.Row columns="2">
        <Grid.Column>{nodes[0]}</Grid.Column>
        <Grid.Column />
      </Grid.Row>
      <Grid.Row columns="2">
        <Grid.Column>{nodes[1]}</Grid.Column>
        <Grid.Column>{nodes[2]}</Grid.Column>
      </Grid.Row>
      <Grid.Row columns="2">
        <Grid.Column>{nodes[3]}</Grid.Column>
        <Grid.Column>{nodes[4]}</Grid.Column>
      </Grid.Row>
      <Grid.Row columns="2">
        <Grid.Column>{nodes[5]}</Grid.Column>
        <Grid.Column>{nodes[6]}</Grid.Column>
      </Grid.Row>
      <Grid.Row columns="2">
        <Grid.Column>{nodes[7]}</Grid.Column>
        <Grid.Column>{nodes[8]}</Grid.Column>
      </Grid.Row>
      <Grid.Row columns="1">
        <Grid.Column>{nodes.slice(9)}</Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

Layout.propTypes = {
  children: PropTypes.node
};

export default Layout;
