import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import { useTable } from "react-table";

/*
 * The PropertyTable component, is just a way to show an object in the form of a table.
 * It typically takes one object as data and shows one key/value property per row
 */

/**
 *
 * Example columnsConfig:
 * Given: { balance_at_last_renewal: 40.00 }
 *
 * Balance at Last Renewal   40.00
 *
 * Cell takes a component which can alter the value of the accessor
 *
 * Note: Cell is normally not required in react-table, but this table is doing
 * some custom styling where the header is on the left and the value is on the right
 *
 *
  const columnsConfig = useMemo(() => [
    {
      Header: 'Balance at Last Renewal',
      accessor: 'balance_at_last_renewal',
      Cell: DollarCell,
    },
  ], []);
 *
 */

function PropertyTable({
  columnsConfig,
  data,
  onCellSelect = () => {}, // No instances of this component use this prop
  onToggle, // AdminCharacteristics
  openModal // NextPaymentsTable
}) {
  const {
    columns: allColumns,
    rows,
    prepareRow,
    getTableProps,
    getTableBodyProps
  } = useTable({
    columns: columnsConfig,
    data: Array.isArray(data) ? data : [data],
    ...(openModal && { openModal }),
    ...(onToggle && { onToggle })
  });
  const primaryColumns = allColumns.filter(col => col.primary);
  const columns = allColumns.filter(col => !col.primary);

  return (
    <Table {...getTableProps()} size="large" padded basic>
      {rows.map(
        row =>
          prepareRow(row) || (
            <Table.Header key={row.original.uuid}>
              {primaryColumns.map((column, i) => {
                // filter only primary cells
                const cell = row.cells.filter(c => c.column.primary)[i];
                return (
                  <Table.Row key={column.id}>
                    <Table.HeaderCell
                      {...column.getHeaderProps({ scope: "row" })}
                    >
                      {column.render("Header")}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      {...cell.getCellProps({
                        textAlign: "right",
                        selectable: !!(column.selectable && onCellSelect),
                        onClick() {
                          onCellSelect(cell);
                        }
                      })}
                    >
                      {cell.render("Cell")}
                    </Table.HeaderCell>
                  </Table.Row>
                );
              })}
            </Table.Header>
          )
      )}

      {rows.map(
        row =>
          prepareRow(row) || (
            <Table.Body key={row.original.uuid} {...getTableBodyProps()}>
              {columns.map((column, i) => {
                // filter only NON-primary cells
                const cell = row.cells.filter(c => !c.column.primary)[i];
                return (
                  <Table.Row key={column.id || column.accessor}>
                    <Table.Cell {...column.getHeaderProps({ scope: "row" })}>
                      {column.render("Header")}
                    </Table.Cell>
                    <Table.Cell
                      {...cell.getCellProps({
                        textAlign: "right",
                        selectable: !!(column.selectable && onCellSelect),
                        onClick() {
                          onCellSelect(cell);
                        }
                      })}
                    >
                      {cell.render("Cell")}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          )
      )}
    </Table>
  );
}

PropertyTable.propTypes = {
  columnsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      primary: PropTypes.bool,
      Header: PropTypes.node,
      selectable: PropTypes.bool,
      Cell: PropTypes.elementType.isRequired
    })
  ).isRequired,
  data: PropTypes.shape({
    uuid: PropTypes.string.isRequired
  }),
  onCellSelect: PropTypes.func,
  onToggle: PropTypes.func,
  openModal: PropTypes.func
};

export default PropertyTable;
