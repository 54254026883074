export const CREDIT_MANAGEMENT_ACTION_TYPES = () => ({
  docType: "Credit",
  types: {
    ticklerDropDown: "CMS_UPDATE_TICKLERS_DATA",
    ticklerUpload: "CMS_TICKLER_UPDATE_DOCUMENT_UPLOAD",
    ticklerDelete: "CMS_TICKLER_DELETE_DOCUMENT_UPLOAD",
    ticklerToggle: "CMS_POPULATE_TABLE",
    saveEditModal: "CMS_SCHEDULE_EDIT_MODAL_SAVE",
    saveNewModal: "CMS_SCHEDULE_NEW_MODAL_SAVE",
    scheduleToggle: "CMS_SCHEDULE_UPDATE_TOGGLE",
    docTrackingItemUpdate: "CMS_UPDATE_DOC_TRACKING_ITEM"
  }
});

export const ASSET_MANAGEMENT_ACTION_TYPES = uuid => ({
  docType: "Collateral",
  types: {
    ticklerDropDown: "ASSET_MANAGEMENT_UPDATE_TICKLERS_DATA",
    ticklerUpload: "ASSET_MANAGEMENT_TICKLER_UPDATE_DOCUMENT_UPLOAD",
    ticklerDelete: "ASSET_MANAGEMENT_TICKLER_DELETE_DOCUMENT_UPLOAD",
    ticklerToggle: "ASSET_MANAGEMENT_POPULATE_TABLE",
    saveEditModal: "ASSET_MANAGEMENT_SCHEDULE_EDIT_MODAL_SAVE",
    saveNewModal: "ASSET_MANAGEMENT_SCHEDULE_NEW_MODAL_SAVE",
    scheduleToggle: "ASSET_MANAGEMENT_SCHEDULE_UPDATE_TOGGLE",
    docTrackingItemUpdate: "ASSET_MANAGEMENT_UPDATE_DOC_TRACKING_ITEM"
  },
  api: {
    ticklersGetAll: { parentUuid: uuid },
    ticklersGetAllActive: { parentUuid: uuid }
  }
});

export const LOAN_MANAGEMENT_ACTION_TYPES = uuid => ({
  docType: "Loan",
  types: {
    ticklerDropDown: "LOAN_MANAGEMENT/UPDATE_TICKLERS_DATA",
    ticklerUpload: "LOAN_MANAGEMENT/TICKLER_UPDATE_DOCUMENT_UPLOAD",
    ticklerDelete: "LOAN_MANAGEMENT/TICKLER_DELETE_DOCUMENT_UPLOAD",
    ticklerToggle: "LOAN_MANAGEMENT/POPULATE_TABLE",
    saveEditModal: "LOAN_MANAGEMENT/SCHEDULE_EDIT_MODAL_SAVE",
    saveNewModal: "LOAN_MANAGEMENT/SCHEDULE_NEW_MODAL_SAVE",
    scheduleToggle: "LOAN_MANAGEMENT/SCHEDULE_UPDATE_TOGGLE",
    docTrackingItemUpdate: "LOAN_MANAGEMENT/UPDATE_DOC_TRACKING_ITEM"
  },
  api: {
    ticklersGetAll: { parentUuid: uuid },
    ticklersGetAllActive: { parentUuid: uuid }
  }
});
