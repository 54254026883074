import React from "react";
import { connect } from "react-redux";
import { arrayOf, bool, func, object, objectOf, string } from "prop-types";
import { Button, Feed, Grid, Ref, Sticky, TextArea } from "semantic-ui-react";

import { InfiniteScroll } from "../CustomUIElements";
import { Messages } from "../../services/ApiLib";
import FetchUserNameV2 from "../../services/UserInfo";
import messagesToEvents from "./services/messagesToEvents";
import { messagesToPdf } from "../../services/Messages/MessagesToPdf/messagesToPdf";
import logger from "../../services/logger";

export class MessagingInterfaceObj extends React.Component {
  contextRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      newMessages: []
    };
  }

  componentDidMount() {
    this.getMessages(0);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch({ type: "MESSAGING_RESET" });
  }

  getMessages = pageNumber => {
    const { dispatch, parentUuid } = this.props;
    const pageSize = 999;

    dispatch({ type: "MESSAGES_LOADING" });
    Messages.get(this.messagesSuccess, this.onError, undefined, {
      page_number: pageNumber,
      page_size: pageSize,
      parentUuid
    });
  };

  getUserInfo = async (user, dispatch, users) => {
    const fetchUserName = await FetchUserNameV2(users, dispatch, user);
    if (fetchUserName?.error) {
      this.onError();
    }
  };

  messagesSuccess = async ({ data, metaData }) => {
    const { dispatch, users } = this.props;
    this.dispatchMessages(data, metaData);
    const msgUsers = [];
    data.forEach(msg => msgUsers.push(msg.user_uuid));
    const userSet = new Set([...msgUsers]);
    userSet.forEach(user => this.getUserInfo(user, dispatch, users));
  };

  addMessage = () => {
    const { currentMessage, dispatch, institutionUuid, parentUuid } =
      this.props;

    Messages.post(
      this.onSuccess,
      this.onError,
      parentUuid,
      institutionUuid,
      currentMessage
    );

    dispatch({ type: "MESSAGING_UPDATE_MESSAGE", data: "" });
  };

  updateMessage = data => {
    const { dispatch } = this.props;
    dispatch({ type: "MESSAGING_UPDATE_MESSAGE", data });
  };

  dispatchMessages = (data, metaData) => {
    const { dispatch } = this.props;
    dispatch({
      data,
      lastPage: metaData.last_page,
      type: "MESSAGING_POPULATE_MESSAGES"
    });
  };

  onSuccess = ({ data }) => {
    const { newMessages } = this.state;
    this.setState({ newMessages: [data, ...newMessages] });
  };

  onError = errorMessage => {
    logger.error(errorMessage);
  };

  loadPrintView = () => {
    const { dispatch, messages, users } = this.props;
    const { newMessages } = this.state;

    const messagesWithUser = [...messages, ...newMessages].map(message => ({
      ...message,
      userName: users[message.user_uuid]
    }));

    messagesToPdf({
      dispatch,
      messages: messagesWithUser,
      progress: state =>
        logger.debug(`MessagingInterfaceObj: PDF creation state=${state}`)
    });
  };

  render() {
    const { currentMessage, lastPage, loading, messages, users } = this.props;
    const { newMessages } = this.state;

    const totalMessages = [...newMessages, ...messages];

    const editor = (
      <div>
        <TextArea
          onChange={e => this.updateMessage(e.target.value)}
          placeholder="Add Message"
          rows={20}
          style={{ width: "100%" }}
          value={currentMessage}
        />
        <Button primary floated="right" onClick={this.addMessage}>
          Add Message
        </Button>
      </div>
    );

    return (
      <div>
        <Grid columns={2} container>
          <Grid.Column />
          <Grid.Column>
            <Button floated="right" onClick={this.loadPrintView}>
              Print
            </Button>
          </Grid.Column>
        </Grid>
        <Ref innerRef={this.contextRef}>
          <Grid celled columns={2} container stretched>
            <Grid.Column width={10}>
              <InfiniteScroll
                lastPage={lastPage}
                loadCallback={this.getMessages}
                loading={loading}
              >
                <Feed>{messagesToEvents(totalMessages, users)}</Feed>
              </InfiniteScroll>
              {loading ? <div>Loading</div> : ""}
            </Grid.Column>
            <Grid.Column width={6}>
              <Sticky context={this.contextRef}>{editor}</Sticky>
            </Grid.Column>
          </Grid>
        </Ref>
      </div>
    );
  }
}

MessagingInterfaceObj.propTypes = {
  currentMessage: string.isRequired,
  dispatch: func.isRequired,
  institutionUuid: string.isRequired,
  lastPage: bool.isRequired,
  loading: bool.isRequired,
  messages: arrayOf(object).isRequired,
  parentUuid: string.isRequired,
  users: objectOf(string).isRequired
};

const mapStateToProps = state => ({
  currentMessage: state.MessagingReducer.currentMessage,
  lastPage: state.MessagingReducer.lastPage,
  loading: state.MessagingReducer.loading,
  messages: state.MessagingReducer.messages,
  users: state.UserInfoReducer.users
});

export default connect(mapStateToProps)(MessagingInterfaceObj);
