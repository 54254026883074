import React, { useState } from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { ConcatName } from "../../../../../../../../../../../../services/Entities";
import { relationshipTypes } from "../../../../../../../../../../../../services/Constants/relationshipTypes";
import { prettyDate } from "../../../../../../../../../../../../services/DateTime";
import { toDisplayValue } from "../../../../../../../../../../../../components/CustomFormElements/components/IdentificationNumberInput/identification-number";
import { EditButton } from "../../../../../../../../../../../../components/CustomFormElements";
import EditModal from "../EditModal";

export default function OfficerTable({ officers }) {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editModalOfficer, setEditModalOfficer] = useState({});
  const dispatch = useDispatch();

  const officerInputs = [
    { name: "title", type: "string", label: "Title" },
    { name: "start_date", type: "date", label: "Start Date" },
    { name: "end_date", type: "date", label: "End Date" }
  ];

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Start Date</Table.HeaderCell>
            <Table.HeaderCell>End Date</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {officers.map((officer, index) => (
            <Table.Row key={"officer".concat("", index)}>
              <Table.Cell>
                <a
                  href={`/credit_management/entity/${officer.officer_entity_data.uuid}`}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <i className="linkify icon" />
                  {ConcatName(officer.officer_entity_data)}
                </a>
              </Table.Cell>
              <Table.Cell>{officer.title}</Table.Cell>
              <Table.Cell>
                {officer.start_date ? prettyDate(officer.start_date) : ""}
              </Table.Cell>
              <Table.Cell>
                {officer.end_date ? prettyDate(officer.end_date) : ""}
              </Table.Cell>
              <Table.Cell collapsing>
                <EditButton
                  onClick={() => {
                    setEditModalOfficer({
                      ...officer,
                      start_date: officer.start_date || "",
                      end_date: officer.end_date || "",
                      title: officer.title || ""
                    });
                    setEditModalOpen(true);
                  }}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <EditModal
        relType={relationshipTypes.OFFICER}
        valid={!!(editModalOfficer.start_date && editModalOfficer.title)}
        editModalEntity={editModalOfficer}
        setModalEntity={setEditModalOfficer}
        inputs={officerInputs}
        update={entity => {
          const nextOfficers = officers.map(nextOfficer =>
            nextOfficer.relationship_uuid === entity.relationship_uuid
              ? entity
              : nextOfficer
          );
          dispatch({
            type: "CMS_REL_SET_OFFICER_DETAILS",
            relationshipOfficers: nextOfficers
          });
        }}
        open={editModalOpen}
        setModalOpen={setEditModalOpen}
        subHead={`${ConcatName(
          editModalOfficer.officer_entity_data || {}
        )} | ${toDisplayValue(
          editModalOfficer.officer_entity_data
            ? editModalOfficer.officer_entity_data.tin
            : ""
        )}`}
      />
    </>
  );
}

OfficerTable.propTypes = {
  officers: PropTypes.arrayOf(PropTypes.object).isRequired
};
