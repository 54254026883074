import PropTypes from "prop-types";
import React from "react";
import { Form, TextArea, Header, Grid } from "semantic-ui-react";

import {
  DollarField,
  DateInput,
  NumberInput,
  PercentInput
} from "../../../../../../../../../../../../components/CustomFormElements";
import { prettyDate } from "../../../../../../../../../../../../services/DateTime";
import { amortizedFeeNameReason } from "../../../../../../../../../../../../services/Constants/additionalFeeOptions";

function AmortizedForm({ updateValues, fee, interestRate, currentBalance }) {
  return (
    <>
      <strong>
        <Header>Amortized Fee</Header>
      </strong>{" "}
      <br />
      <strong>Interest (Coupon) Rate: {interestRate * 100}</strong> <br />
      <strong>Current Balance: {currentBalance}</strong> <br />
      <br />{" "}
      <Grid>
        <Grid.Row columns="3">
          <Grid.Column>
            <DollarField
              label="Original Book Value"
              name="original_book_value"
              onChange={updateValues}
              value={fee.original_book_value || 0}
            />
          </Grid.Column>
          <Grid.Column>
            <DollarField
              label="Current Book Value"
              name="current_book_value"
              onChange={updateValues}
              value={fee.current_book_value || 0}
            />
          </Grid.Column>
          <Grid.Column>
            <PercentInput
              label="CPR"
              name="cpr"
              onChange={(ev, data) => updateValues(data)}
              value={fee.cpr || ""}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="3">
          <Grid.Column>
            <DollarField
              label="Prev Period Amortization Amount"
              name="prev_period_amortization_amount"
              onChange={updateValues}
              value={fee.prev_period_amortization_amount || 0}
            />
          </Grid.Column>
          <Grid.Column>
            <DateInput
              asForm
              label="Amortized Start Date"
              name="amortization_start_date"
              value={fee.amortization_start_date || ""}
              onChange={(e, { name, value }) => updateValues({ name, value })}
              max={prettyDate(new Date())}
            />
          </Grid.Column>
          <Grid.Column>
            <DateInput
              asForm
              label="Amortized Through"
              name="amortized_through"
              value={fee.amortized_through || ""}
              onChange={(e, { name, value }) => updateValues({ name, value })}
              max={prettyDate(new Date())}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="3">
          <Grid.Column>
            <Form.Dropdown
              label="Fee Reason"
              data-testid="fee-reason-select"
              selection
              name="amortized_reason"
              options={amortizedFeeNameReason}
              value={fee.amortized_reason || ""}
              onChange={(ev, data) => updateValues(data)}
            />
          </Grid.Column>
          {fee.amortized_reason === "Other" && (
            <Grid.Column>
              <Form.Input
                label="Other Reason"
                name="amortized_reason_other"
                value={fee.amortized_reason_other || ""}
                onChange={(ev, data) => {
                  updateValues(data);
                }}
                placeholder="Max length 250"
                disabled={!fee.amortized_reason}
                required
              />
            </Grid.Column>
          )}
          <Form.Input
            label="Amortization Transaction Code"
            name="amortization_tran_code"
            value={fee.amortization_tran_code || ""}
            maxLength="250"
            disabled
            required
          />
          <Grid.Column>
            <NumberInput
              label="Original Periods to Amortize Fee"
              name="original_life_of_loan"
              onChange={(ev, { name, value }) =>
                updateValues({ name, value: Number(value) })
              }
              value={fee.original_life_of_loan || 0}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="3">
          <Grid.Column>
            <NumberInput
              label="Remaining Periods to Amortize Fee"
              name="remaining_life_of_loan"
              onChange={(ev, { name, value }) =>
                updateValues({ name, value: Number(value) })
              }
              value={fee.remaining_life_of_loan || 0}
            />
          </Grid.Column>
          <Grid.Column>
            <NumberInput
              label="Original Loan Amortization Term"
              name="original_loan_amortization_term"
              onChange={(ev, { name, value }) =>
                updateValues({ name, value: Number(value) })
              }
              value={fee.original_loan_amortization_term || 0}
            />
          </Grid.Column>
          <Grid.Column>
            <NumberInput
              label="Remaining Loan Amortization Term"
              name="remaining_loan_amortization_term"
              onChange={(ev, { name, value }) =>
                updateValues({ name, value: Number(value) })
              }
              value={fee.remaining_loan_amortization_term || 0}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="1">
          <Grid.Column>
            <b>Description</b>
            <TextArea
              name="description"
              value={fee.description || ""}
              onChange={(e, data) => updateValues(data)}
              maxLength="250"
              placeholder="Max length 250"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

AmortizedForm.propTypes = {
  fee: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ).isRequired,
  updateValues: PropTypes.func.isRequired,
  interestRate: PropTypes.number,
  currentBalance: PropTypes.number
};

export default AmortizedForm;
