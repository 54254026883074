import React, { useEffect, useCallback } from "react";
import { Button, Icon, Grid, Menu } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import {
  EntityRelationships,
  Loans,
  Relationships,
  Cms,
  Entities
} from "../../../../../../../../../../services/ApiLib";
import { relationshipTypes } from "../../../../../../../../../../services/Constants/relationshipTypes";
import AddModal from "./Components/AddModal";
import OwnerTable from "./Components/OwnerTable";
import OfficerTable from "./Components/OfficerTable";
import OtherTable from "./Components/OtherTable";
import AffiliateTable from "./Components/AffiliateTable";
import SignerTable from "./Components/SignerTable";
import GuarantorsCoborrowersTable from "./Components/GuarantorsCoborrowersTable";
import GuaranteesTable from "./Components/GuaranteesTable";
import HoldingsSubsidiariesTable from "./Components/HoldingsSubsidiariesTable";
import { BorderedContainer } from "../../../../../../../../../../components/CustomUIElements";
import logger from "../../../../../../../../../../services/logger";

function onError(rsp) {
  logger.error("Error: ", rsp); // TODO: Handle errors properly
}

function RelationshipsObj() {
  const dispatch = useDispatch();
  const {
    officers,
    owners,
    affiliates,
    otherCapacities,
    authorizedSigner,
    guarantors,
    guarantees,
    holdingsSubsidiaries,
    coborrowers,
    activeItem = relationshipTypes.OWNER,
    entityUuid,
    entityType,
    institutionUuid,
    relLookupObj = undefined
  } = useSelector(state => ({
    officers: state.CreditManagementReducer.officers,
    owners: state.CreditManagementReducer.owners,
    affiliates: state.CreditManagementReducer.affiliates,
    otherCapacities: state.CreditManagementReducer.otherCapacities,
    authorizedSigner: state.CreditManagementReducer.authorizedSigner,
    guarantors: state.CreditManagementReducer.guarantors,
    guarantees: state.CreditManagementReducer.guarantees,
    holdingsSubsidiaries: state.CreditManagementReducer.holdingsSubsidiaries,
    coborrowers: state.CreditManagementReducer.coborrowers,
    activeItem: state.CreditManagementReducer.relationshipsActiveItem,
    entityUuid: state.CreditManagementReducer.entityData.uuid,
    entityType: state.CreditManagementReducer.entityData.entity_type,
    institutionUuid: state.CreditManagementReducer.entityData.institution_uuid,
    relLookupObj: state.CreditManagementReducer.relLookupObj
  }));
  const lookupEntityUuid = useCallback(
    async (childUuid, relUuid) => {
      try {
        const fetchedEntity = await Cms.asyncRead(childUuid);
        dispatch({
          type: "CMS_REL_SET_LOOKUP_DETAILS",
          relUuid,
          entityData: fetchedEntity.data
        });
      } catch (err) {
        onError(err);
      }
    },
    [dispatch]
  );

  const setGuarantorDetails = useCallback(
    ({ data }, relatesTo) => {
      const nextGuarantors = [];
      data.forEach(async guarantor => {
        await lookupEntityUuid(guarantor.child_uuid, guarantor.uuid);
        nextGuarantors.push({ ...guarantor, relatesTo });
      });
      dispatch({
        type: "CMS_REL_SET_GUARANTOR_DETAILS",
        relationshipGuarantors: nextGuarantors
      });
    },
    [dispatch, lookupEntityUuid]
  );

  const setGuaranteeDetails = useCallback(
    ({ data }) => {
      const guaranteesData = [];
      data.forEach(async guarantee => {
        guaranteesData.push({ ...guarantee });
        for (let i = 0; i < guaranteesData.length; i += 1) {
          const filters = {};
          filters.loanUuid = guaranteesData[i].parent_uuid;
          Loans.asyncRead(filters)
            .then(loanRes => {
              guaranteesData[i].bafsAccountNumber =
                loanRes.data.bafs_account_number;
              guaranteesData[i].institutionAccountNumber =
                loanRes.data.institution_account_number;
              guaranteesData[i].appId = loanRes.data.app_id;
              guaranteesData[i].loanAppUuid = loanRes.data.loan_app_uuid;
            })
            .catch(err => onError(err));
        }
      });
      dispatch({
        type: "CMS_REL_SET_GUARANTEE_DETAILS",
        relationshipGuarantees: guaranteesData
      });
    },
    [dispatch]
  );

  const setHoldingsSubsidiariesDetails = useCallback(
    ({ data }) => {
      const holdingsSubsidiariesData = [];
      data.forEach(async holdingSubsidiary => {
        const filters = { entityUuid: holdingSubsidiary.parent_uuid };
        const fetchedEntity = await Entities.read(filters);
        const percentOwned = holdingSubsidiary.percent_owned;
        fetchedEntity.data.percent_owned = percentOwned;
        holdingsSubsidiariesData.push(fetchedEntity.data);
      });
      dispatch({
        type: "CMS_REL_SET_HOLDING_SUBSIDIARY_DETAILS",
        relationshipHoldingsSubsidiaries: holdingsSubsidiariesData
      });
    },
    [dispatch]
  );

  const setCoborrowerDetails = useCallback(
    ({ data }, relatesTo) => {
      const nextCoborrowers = [];
      data.forEach(async coborrower => {
        await lookupEntityUuid(coborrower.child_uuid, coborrower.uuid);
        nextCoborrowers.push({ ...coborrower, relatesTo });
      });
      dispatch({
        type: "CMS_REL_SET_COBORROWER_DETAILS",
        relationshipCoborrowers: nextCoborrowers
      });
    },
    [dispatch, lookupEntityUuid]
  );

  useEffect(() => {
    const relationships = [
      {
        type: "Owner",
        dispatchType: "CMS_REL_SET_OWNER_DETAILS",
        dispatchKey: "relationshipOwners"
      },
      {
        type: "Affiliate",
        dispatchType: "CMS_REL_SET_AFFILIATE_DETAILS",
        dispatchKey: "relationshipAffiliates"
      },
      {
        type: "Officer",
        dispatchType: "CMS_REL_SET_OFFICER_DETAILS",
        dispatchKey: "relationshipOfficers"
      },
      {
        type: "Other Capacities",
        dispatchType: "CMS_REL_SET_OTHERS_DETAILS",
        dispatchKey: "relationshipOthers"
      },
      {
        type: "Authorized Signer",
        dispatchType: "CMS_REL_SET_AUTHORIZED_SIGNER",
        dispatchKey: "relationshipSigner"
      }
    ];
    if (entityUuid) {
      for (let i = 0; i < relationships.length; i += 1) {
        EntityRelationships.asyncRead(entityUuid, relationships[i].type)
          .then(res => {
            dispatch({
              type: relationships[i].dispatchType,
              [relationships[i].dispatchKey]: res.data
            });
          })
          .catch(err => {
            onError(err);
          });
      }
      const guarantorFilters = {
        child_uuid: entityUuid,
        rel_type: "Guarantor"
      };
      Relationships.asyncRead(guarantorFilters)
        .then(guaranteeRes => {
          setGuaranteeDetails(guaranteeRes);
        })
        .catch(err => onError(err));

      const ownerFilters = {
        child_uuid: entityUuid,
        rel_type: "Owner",
        page_number: 0,
        page_size: 9999
      };
      Relationships.asyncRead(ownerFilters)
        .then(holdingsSubsidiariesRes => {
          setHoldingsSubsidiariesDetails(holdingsSubsidiariesRes);
        })
        .catch(err => onError(err));

      Loans.readByEntityAndInstitution(entityUuid, institutionUuid)
        .then(({ data }) => {
          // Coborrowers and guarantors relate to the loans of an entity, not the
          // entity itself. For each loan, get all coborrowers and guarantors.
          for (let i = 0; i < data.length; i += 1) {
            const loan = { ...data[i] };
            Relationships.asyncReadRelationshipByType(loan.uuid, "Co-borrower")
              .then(coborrowerRes => {
                setCoborrowerDetails(coborrowerRes, loan);
              })
              .catch(err => onError(err));
            Relationships.asyncReadRelationshipByType(loan.uuid, "Guarantor")
              .then(guarantorRes => {
                setGuarantorDetails(guarantorRes, loan);
              })
              .catch(err => onError(err));
          }
        })
        .catch(err => {
          onError(err);
        });
    }
  }, [
    dispatch,
    entityUuid,
    institutionUuid,
    setCoborrowerDetails,
    setGuaranteeDetails,
    setGuarantorDetails,
    setHoldingsSubsidiariesDetails
  ]);

  function handleItemClick(name) {
    dispatch({
      type: "CMS_REL_MENU_ITEM",
      menuItem: name
    });
  }

  let tableContents;
  switch (activeItem) {
    case relationshipTypes.OWNER:
      tableContents = <OwnerTable owners={owners} />;
      break;
    case relationshipTypes.OFFICER:
      tableContents = <OfficerTable officers={officers} />;
      break;
    case relationshipTypes.OTHER_CAPACITIES:
      tableContents = <OtherTable otherCapacities={otherCapacities} />;
      break;
    case relationshipTypes.AUTHORIZED_SIGNER:
      tableContents = <SignerTable authorizedSigner={authorizedSigner} />;
      break;
    case relationshipTypes.AFFILIATE:
      tableContents = <AffiliateTable affiliates={affiliates} />;
      break;
    case relationshipTypes.GUARANTOR:
      tableContents = (
        <GuarantorsCoborrowersTable
          relLookupObj={relLookupObj}
          guarantors={guarantors}
          coborrowers={coborrowers}
        />
      );
      break;
    case relationshipTypes.GUARANTEES:
      tableContents = <GuaranteesTable guarantees={guarantees} />;
      break;
    case relationshipTypes.HOLDINGS_SUBSIDIARIES:
      tableContents = (
        <HoldingsSubsidiariesTable
          holdingsSubsidiaries={holdingsSubsidiaries}
        />
      );
      break;
    default:
      tableContents = "failed to load";
  }
  const showAddModal =
    activeItem !== relationshipTypes.AFFILIATE &&
    activeItem !== relationshipTypes.GUARANTOR &&
    activeItem !== relationshipTypes.GUARANTEES &&
    activeItem !== relationshipTypes.HOLDINGS_SUBSIDIARIES;

  return (
    // TODO: styling fix/replace
    <BorderedContainer fluid>
      <Menu pointing secondary color="green">
        {entityType !== "Individual" && (
          <>
            <Menu.Item
              data-testid="owner-tab"
              name={relationshipTypes.OWNER}
              active={activeItem === relationshipTypes.OWNER}
              onClick={() => {
                handleItemClick(relationshipTypes.OWNER);
              }}
            />
            <Menu.Item
              data-testid="officer-tab"
              name={relationshipTypes.OFFICER}
              active={activeItem === relationshipTypes.OFFICER}
              onClick={() => {
                handleItemClick(relationshipTypes.OFFICER);
              }}
            />
          </>
        )}
        <Menu.Item
          data-testid="other-capacities-tab"
          name={relationshipTypes.OTHER_CAPACITIES}
          active={activeItem === relationshipTypes.OTHER_CAPACITIES}
          onClick={() => {
            handleItemClick(relationshipTypes.OTHER_CAPACITIES);
          }}
        />
        {entityType !== "Individual" && (
          <Menu.Item
            data-testid="authorized-signer-tab"
            name={relationshipTypes.AUTHORIZED_SIGNER}
            active={activeItem === relationshipTypes.AUTHORIZED_SIGNER}
            onClick={() => {
              handleItemClick(relationshipTypes.AUTHORIZED_SIGNER);
            }}
          />
        )}
        {entityType !== "Individual" && (
          <Menu.Item
            data-testid="affiliate-tab"
            name={relationshipTypes.AFFILIATE}
            active={activeItem === relationshipTypes.AFFILIATE}
            onClick={() => {
              handleItemClick(relationshipTypes.AFFILIATE);
            }}
          />
        )}
        <Menu.Item
          data-testid="guarantors-tab"
          name={relationshipTypes.GUARANTORS}
          active={activeItem === relationshipTypes.GUARANTOR}
          onClick={() => {
            handleItemClick(relationshipTypes.GUARANTOR);
          }}
        >
          Guarantors / Co-Borrowers
        </Menu.Item>

        <Menu.Item
          data-testid="guarantees-tab"
          name={relationshipTypes.GUARANTEES}
          active={activeItem === relationshipTypes.GUARANTEES}
          onClick={() => {
            handleItemClick(relationshipTypes.GUARANTEES);
          }}
        >
          Guarantees
        </Menu.Item>

        <Menu.Item
          data-testid="holdings-subsidiaries-tab"
          name={relationshipTypes.HOLDINGS_SUBSIDIARIES}
          active={activeItem === relationshipTypes.HOLDINGS_SUBSIDIARIES}
          onClick={() => {
            handleItemClick(relationshipTypes.HOLDINGS_SUBSIDIARIES);
          }}
        >
          Holdings & Subsidiaries
        </Menu.Item>
      </Menu>

      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column />
          <Grid.Column style={{ marginBottom: "15px" }}>
            {showAddModal ? (
              <AddModal />
            ) : (
              <Button basic circular icon style={{ visibility: "hidden" }}>
                <Icon name="plus" />
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {tableContents}
    </BorderedContainer>
  );
}

export default RelationshipsObj;
