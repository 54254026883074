export const initialState = {
  institutionUuid: "",
  institutionId: 0
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case "LOR_MESSAGES_POPULATE_INST_UUID":
      return {
        ...state,
        institutionUuid: action.data.institution_uuid
      };

    case "LOR_MESSAGES_RESET":
      return initialState;

    default:
      return state;
  }
};
