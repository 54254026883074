const {
  NX_AUTH0_CLIENT_ID,
  NX_BLAST_CLIENT_DOMAIN,
  NX_BLAST_HEAP_ID,
  NX_CREDIT_ANALYSIS_TEMPLATE_UUID,
  NX_FLAGSMITH_KEY,
  NX_JASPER_DOMAIN,
  NX_SENTRY_DSN
} = process.env;

if (!NX_AUTH0_CLIENT_ID) {
  throw Error("The NX_AUTH0_CLIENT_ID environment variable is missing.");
}

if (!NX_BLAST_CLIENT_DOMAIN) {
  throw Error("The NX_BLAST_CLIENT_DOMAIN environment variable is missing.");
}

if (!NX_CREDIT_ANALYSIS_TEMPLATE_UUID) {
  throw Error(
    "The NX_CREDIT_ANALYSIS_TEMPLATE_UUID environment variable is missing."
  );
}

if (!NX_JASPER_DOMAIN) {
  throw Error("The NX_JASPER_DOMAIN environment variable is missing.");
}

const baseHref = "commotion";
const envName = "prod";
const production = false;

// The following code should match the production file.
export const environment = {
  auth0ClientId: NX_AUTH0_CLIENT_ID,
  baseHref,
  blastClientDomain: NX_BLAST_CLIENT_DOMAIN,
  blastHeapId: NX_BLAST_HEAP_ID,
  creditAnalysisTemplateUuid: NX_CREDIT_ANALYSIS_TEMPLATE_UUID,
  envName,
  flagsmithKey: NX_FLAGSMITH_KEY,
  jasperDomain: NX_JASPER_DOMAIN,
  production,
  sentryDsn: NX_SENTRY_DSN
};
