import createAPIService from "../../../FetchService/createAPIService";
import APIEndpoints from "../../../ApiEndpoints";

const service = createAPIService({
  baseUrl: APIEndpoints.baseUri,
  create: APIEndpoints.spreadsv2,
  retrieve: [APIEndpoints.spreadsv2, APIEndpoints.spreadv2],
  update: APIEndpoints.spreadv2,
  delete: APIEndpoints.spreadv2,
  options: { pagination: true },
  patch: APIEndpoints.spreadv2,
  subServices: {
    periods: {
      create: APIEndpoints.spreadsPeriodDatav2,
      retrieve: [
        APIEndpoints.spreadsPeriodDatav2,
        APIEndpoints.spreadsPeriodDatumv2
      ],
      update: APIEndpoints.spreadsPeriodDatumv2,
      patch: APIEndpoints.spreadsPeriodDatumv2,
      delete: APIEndpoints.spreadsPeriodDatumv2
    },
    models: {
      retrieve: [
        APIEndpoints.cmsSpreadsModelsv2,
        APIEndpoints.cmsSpreadsModelv2
      ]
    },
    analysts: {
      retrieve: [APIEndpoints.userInfo, APIEndpoints.userInfoAll]
    }
  }
});

export default service;
