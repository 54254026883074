import { Switch, Route, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import DocTitle from "../../components/DocTitle";
import TabBar from "../../components/TabBar";
import Institutions from "./components/Institutions";
import SystemWideDetails from "./components/SystemWideDetails";
import Sftp from "./components/Sftp";
import StatusPage from "./components/StatusPage";
import { useEffect } from "react";

function SystemAdmin({ history, location }) {
  const { tab } = useParams();
  const loadTab = activeItem => {
    const slug = activeItem.replace(/ /g, "_").toLowerCase();
    history.push(`/system_admin/${slug}`);
  };

  const items = {
    institutions: "Institutions",
    system_wide_details: "System Wide Details",
    sftp: "SFTP",
    system_status: "System Status"
  };

  return (
    <div>
      <DocTitle title="System Admin - BLAST Portal" />
      <h1 className="mb-6">System Administration</h1>
      <TabBar
        activeItem={items[tab]}
        items={Object.values(items)}
        onClick={loadTab}
      />
      <Switch>
        <Route
          exact
          path="/system_admin/institutions"
          component={Institutions}
        />
        <Route
          exact
          path="/system_admin/system_wide_details"
          component={SystemWideDetails}
        />
        <Route exact path="/system_admin/sftp" component={Sftp} />
        <Route
          exact
          path="/system_admin/system_status"
          component={StatusPage}
        />
      </Switch>
    </div>
  );
}

SystemAdmin.propTypes = {
  history: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object,
      PropTypes.func
    ])
  ).isRequired,
  location: PropTypes.objectOf(PropTypes.string).isRequired
};

export default SystemAdmin;
