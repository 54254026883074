import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";

import { ConcatName } from "../../../../../../../../../../../../services/Entities";

export default function AffiliateTable({ affiliates }) {
  const consolidatedAffiliates = {};

  affiliates.forEach(ownerGrouping => {
    ownerGrouping.affiliate_data.forEach(affilateObj => {
      if (!consolidatedAffiliates[affilateObj.uuid]) {
        consolidatedAffiliates[affilateObj.uuid] = {
          ...affilateObj,
          owners: []
        };
      }

      consolidatedAffiliates[affilateObj.uuid].owners.push(
        ownerGrouping.owner_entity_data
      );
    });
  });
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Related To</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Object.keys(consolidatedAffiliates).map(affiliateUUID => (
          <Table.Row key={affiliateUUID}>
            <Table.Cell>
              <a
                href={`/credit_management/entity/${affiliateUUID}`}
                rel="noreferrer noopener"
                target="_blank"
              >
                <i className="linkify icon" />
                {ConcatName(consolidatedAffiliates[affiliateUUID])}
              </a>
            </Table.Cell>

            <Table.Cell>
              {consolidatedAffiliates[affiliateUUID].owners.map(
                (ownerObj, i) => (
                  <a
                    href={`/credit_management/entity/${ownerObj.uuid}`}
                    rel="noreferrer noopener"
                    target="_blank"
                    key={ownerObj.uuid}
                  >
                    <i className="linkify icon" />
                    {i ===
                    consolidatedAffiliates[affiliateUUID].owners.length - 1
                      ? ConcatName(ownerObj)
                      : `${ConcatName(ownerObj)}, `}
                  </a>
                )
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

AffiliateTable.propTypes = {
  affiliates: PropTypes.arrayOf(PropTypes.object).isRequired
};
