import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormatCurrency, FormatPercent } from "num-format";
import { Header, Table, Icon, Popup } from "semantic-ui-react";
import { useCurrentBalance } from "../../hooks";

const computeErrors = (originalInfo, totalInfo) => {
  const nextErrors = {};
  Object.keys(originalInfo).forEach(key => {
    if (key !== "percentage") {
      if (originalInfo[key] !== totalInfo[key]) {
        nextErrors[key] = true;
      } else if (originalInfo[key] === totalInfo[key]) {
        nextErrors[key] = false;
      }
    }
  });
  if (totalInfo.percentage !== "100.000%") {
    nextErrors.percentage = true;
  }
  return nextErrors;
};

const addParticipantData = (original, next) => {
  // manipulate original object instead of making copy and returning
  const nextOriginal = original;
  nextOriginal.currentBalance += next.current_balance || 0;
  nextOriginal.currentContractAmount += next.current_contract_amount || 0;
  nextOriginal.availableBalance += next.available_balance || 0;
  nextOriginal.percentage += next.participation_percentage_owned || 0;
};

const formatNumbers = infoObjects => {
  infoObjects.forEach(info => {
    const nextInfo = info;
    nextInfo.percentage = FormatPercent(info.percentage || 0, 3);
    nextInfo.currentContractAmount = FormatCurrency(
      info.currentContractAmount || 0,
      false
    );
    nextInfo.availableBalance = FormatCurrency(
      info.availableBalance || 0,
      false
    );
    nextInfo.currentBalance = FormatCurrency(info.currentBalance || 0, false);
  });
};

const soldLoanHeaders = [
  "",
  "Current Contract Amount",
  "Available Balance",
  "Current Balance",
  "Percentage"
].map(header => <Table.HeaderCell key={header}>{header}</Table.HeaderCell>);

function SoldLoanInformationTable({
  loan,
  participationIsSetup,
  participantData,
  retainedAccount
}) {
  const [isReady, setIsReady] = useState(false);
  const [errors, setErrors] = useState({});
  const [retainedLoanInfo, setRetainedLoanInfo] = useState({});
  const [soldLoanInfo, setSoldLoanInfo] = useState({});
  const [totalLoanInfo, setTotalLoanInfo] = useState({});
  const [originalLoanInfo, setOriginalLoanInfo] = useState({});
  const {
    current_contract_amount: currentContractAmount,
    institution_uuid: institutionUuid,
    current_balance: currentBalance,
    available_balance: availableBalance
  } = loan;
  const usedBalance = useCurrentBalance({ participants: participantData });

  useEffect(() => {
    setIsReady(false);
    const initialInfo = {
      currentBalance: 0,
      currentContractAmount: 0,
      availableBalance: 0,
      percentage: 0
    };
    const retainedInfo = { ...initialInfo };
    const soldInfo = { ...initialInfo };
    const totalInfo = { ...initialInfo };
    const originalInfo = {
      currentBalance,
      currentContractAmount,
      availableBalance,
      percentage: 0
    };

    if (!participationIsSetup) {
      addParticipantData(retainedInfo, retainedAccount);
      addParticipantData(totalInfo, retainedAccount);
    }

    participantData.forEach(loanData => {
      addParticipantData(totalInfo, loanData);
      const isRetainedLoan = loanData.institution_uuid === institutionUuid;
      if (isRetainedLoan) {
        addParticipantData(retainedInfo, loanData);
      } else {
        addParticipantData(soldInfo, loanData);
      }
    });

    formatNumbers([soldInfo, retainedInfo, totalInfo, originalInfo]);
    setErrors(computeErrors(originalInfo, totalInfo));
    setRetainedLoanInfo(retainedInfo);
    setSoldLoanInfo(soldInfo);
    setTotalLoanInfo(totalInfo);
    setOriginalLoanInfo(originalInfo);
    setIsReady(true);
  }, [
    availableBalance,
    currentContractAmount,
    institutionUuid,
    currentBalance,
    participantData,
    participationIsSetup,
    retainedAccount
  ]);

  return !isReady ? null : (
    <>
      <Header dividing as="h2">
        Sold Loan Information {!participationIsSetup && "(in progress)"}
      </Header>
      {!participationIsSetup && (
        <>
          Balance:{" "}
          {<b>{FormatCurrency(loan.current_contract_amount - usedBalance)}</b>}
          {" / "}
          {FormatCurrency(loan.current_contract_amount)}
        </>
      )}
      <Table celled>
        <Table.Header>
          <Table.Row>{soldLoanHeaders}</Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row key="retained">
            <Table.Cell>Retained</Table.Cell>
            <Table.Cell>{retainedLoanInfo.currentContractAmount}</Table.Cell>
            <Table.Cell>{retainedLoanInfo.availableBalance}</Table.Cell>
            <Table.Cell>{retainedLoanInfo.currentBalance}</Table.Cell>
            <Table.Cell>{retainedLoanInfo.percentage}</Table.Cell>
          </Table.Row>
          <Table.Row key="sold">
            <Table.Cell>Sold</Table.Cell>
            <Table.Cell>{soldLoanInfo.currentContractAmount}</Table.Cell>
            <Table.Cell>{soldLoanInfo.availableBalance}</Table.Cell>
            <Table.Cell>{soldLoanInfo.currentBalance}</Table.Cell>
            <Table.Cell>{soldLoanInfo.percentage}</Table.Cell>
          </Table.Row>

          <Table.Row key="total">
            <Table.Cell>Total</Table.Cell>
            <Table.Cell error={errors.currentContractAmount}>
              {errors.currentContractAmount && (
                <Popup
                  content="Total of participation loans does not match original loan value"
                  trigger={<Icon name="attention" />}
                />
              )}
              {totalLoanInfo.currentContractAmount}
            </Table.Cell>

            <Table.Cell error={errors.availableBalance}>
              {errors.availableBalance && (
                <Popup
                  content="Total of participation loans does not match original loan value"
                  trigger={<Icon name="attention" />}
                />
              )}
              {totalLoanInfo.availableBalance}
            </Table.Cell>

            <Table.Cell error={errors.currentBalance}>
              {errors.currentBalance && (
                <Popup
                  content="Total of participation loans does not match original loan value"
                  trigger={<Icon name="attention" />}
                />
              )}
              {totalLoanInfo.currentBalance}
            </Table.Cell>
            <Table.Cell error={errors.percentage}>
              {errors.percentage && (
                <Popup
                  content="Total percentages do not equal 100%"
                  trigger={<Icon name="attention" />}
                />
              )}
              {totalLoanInfo.percentage}
            </Table.Cell>
          </Table.Row>
          {Object.keys(errors).length > 0 && (
            <Table.Row key="original">
              <Table.Cell>Borrower&apos;s Loan</Table.Cell>
              <Table.Cell error={errors.currentContractAmount}>
                {errors.currentContractAmount && (
                  <Popup
                    content="Total of participation loans does not match original loan value"
                    trigger={<Icon data-testid="attn-icon" name="attention" />}
                  />
                )}
                {originalLoanInfo.currentContractAmount}
              </Table.Cell>
              <Table.Cell error={errors.availableBalance}>
                {errors.availableBalance && (
                  <Popup
                    content="Total of participation loans does not match original loan value"
                    trigger={<Icon data-testid="attn-icon" name="attention" />}
                  />
                )}
                {originalLoanInfo.availableBalance}
              </Table.Cell>

              <Table.Cell error={errors.currentBalance}>
                {errors.currentBalance && (
                  <Popup
                    content="Total of participation loans does not match original loan value"
                    trigger={<Icon name="attention" />}
                  />
                )}
                {originalLoanInfo.currentBalance}
              </Table.Cell>
              <Table.Cell>—</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  );
}

SoldLoanInformationTable.propTypes = {
  participantData: PropTypes.arrayOf(PropTypes.shape()),
  loan: PropTypes.shape({
    uuid: PropTypes.string,
    institution_uuid: PropTypes.string,
    participation_bought: PropTypes.bool,
    participation_sold: PropTypes.bool,
    current_contract_amount: PropTypes.number,
    interest_rate: PropTypes.number,
    original_amount: PropTypes.number,
    current_balance: PropTypes.number,
    available_balance: PropTypes.number
  }).isRequired,
  participationIsSetup: PropTypes.bool,
  retainedAccount: PropTypes.shape({
    institution_uuid: PropTypes.string
  })
};

export default SoldLoanInformationTable;
