import { useEffect, useState } from "react";
import { useUpdateInterestScheduleMutation } from "../../../../../../services/RTKQuery/interestSchedules";

export const UpdateTest = ({ scheduleUuid, loanUuid, setStatusInParent }) => {
  const statusUnknown = "❓Unknown Status";
  const statusPass = "✅ Pass";
  const statusFail = "❌ Fail";

  const [statusUpdate, setStatusUpdate] = useState(statusUnknown);
  const [detailsText, setDetailsText] = useState("statusUnknown");

  const [updateInterestScheduleTrigger] = useUpdateInterestScheduleMutation();
  useEffect(() => {
    const newRateIndex = "1 Yr CMT";
    const newRate = 0.07614; // old rate 0.0714 (see max decrease/increase)
    const updates = {
      interest_rate: newRate,
      loan_uuid: loanUuid,
      rate_index: newRateIndex,
      uuid: scheduleUuid
    };
    updateInterestScheduleTrigger(updates)
      .unwrap()
      .then(fulfilled => {
        if (
          fulfilled?.data?.uuid === scheduleUuid &&
          fulfilled?.data?.interest_rate === newRate &&
          fulfilled?.data?.rate_index === newRateIndex
        ) {
          setStatusUpdate(statusPass);
          setDetailsText(
            `: interest_rate & rate_index updated to: [${fulfilled.data.interest_rate}, ${fulfilled.data.rate_index}]`
          );
          setStatusInParent("Pass");
        } else {
          setStatusUpdate(statusFail);
          setStatusInParent("Fail");
        }
      })
      .catch(error => {
        console.error("Fail: useUpdateInterestScheduleMutation", error);
        setStatusUpdate(statusFail);
      });
  }, [
    loanUuid,
    scheduleUuid,
    setStatusInParent,
    updateInterestScheduleTrigger
  ]);

  return (
    <>
      <b>{statusUpdate}</b>
      <small>{detailsText}</small>
    </>
  );
};
