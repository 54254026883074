import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actionCreators } from "./reducer";
import LoanManagement from "./LoanManagement";

const mapStateToProps = ({
  LoanManagementReducer: { data, entityData, institution, loan }
}) => ({
  data,
  entityData,
  institution,
  loan
});

export const mapDispatchToProps = dispatch => ({
  onViewLoanManagement: loanUuid => dispatch(actionCreators.loadLoan(loanUuid)),
  onUpdateLoan: loanPatch => dispatch(actionCreators.updateLoan(loanPatch))
});

const LoanManagementObject = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoanManagement);

export default withRouter(LoanManagementObject);
