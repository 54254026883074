import React from "react";
import PropTypes from "prop-types";
import { PrettyNumber } from "../../../../../../../../../../../services/PrettyNumber";

function MarketValueCell({ row, value = "" }) {
  const uuid = row?.original?.uuid || "";

  return (
    <a
      href={`/asset_management/${uuid}/`}
      rel="noreferrer noopener"
      target="_blank"
    >
      {`$${PrettyNumber(parseFloat(value) || 0)}`}
    </a>
  );
}

export default MarketValueCell;

MarketValueCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape()
  }),
  value: PropTypes.number
};
