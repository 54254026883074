import _ from "lodash";

export const initialState = {
  borrowers: [],
  deleteModalToggle: false,
  loanAppUuid: "",
  editModalForm: {},
  editModalToggle: false,
  entityDetailsLookup: {},
  entityDetails: {
    annualRevenue: "",
    businessStartDate: "",
    companyName: "",
    countyOfReg: "",
    creditScoreEquifax: 0,
    creditScoreExperian: 0,
    creditScoreTransunion: 0,
    dob: "",
    driversLicenseNo: "",
    driversLicenseSt: "",
    dunsNumber: "",
    email: "",
    entityType: "",
    ficoSbss: 0,
    fiscalYearEnd: "",
    industryType: "",
    institution: 0,
    isOperatingEntity: false,
    isUsCitizen: "",
    mailAddressCity: "",
    mailAddressCountry: "",
    mailAddressLine1: "",
    mailAddressLine2: "",
    mailAddressState: "",
    mailAddressZip: "",
    mobilePhoneNo: "",
    nextReviewDate: "",
    personalAnnualIncome: "",
    phoneNo: "",
    phyAddressCity: "",
    phyAddressCountry: "",
    phyAddressLine1: "",
    phyAddressLine2: "",
    phyAddressState: "",
    phyAddressZip: "",
    previousReviewDate: "",
    rentOrOwn: "",
    riskRating: "",
    selectedBureau: "",
    showEditDetailsModal: false,
    stateOfReg: "",
    taxReportingForm: "",
    tin: "",
    website: "",
    yearsInBusiness: 0
  },
  entityRelCompany: {
    owners: [],
    officers: [],
    affiliates: [],
    others: []
  },
  relationshipModal: false,
  relType: "",
  relUuid: "",
  selectedEntity: ""
};

// eslint-disable-next-line default-param-last
export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case "LOR_ENTITY_DETAILS_REDUCER_POPULATE_ENTITY_DETAILS": {
      return { ...state, entityDetails: action.data };
    }

    case "LOR_ENTITY_DETAILS_REDUCER_TOGGLE_RELATIONSHIP_MODAL":
      return {
        ...state,
        relationshipModal: !state.relationshipModal,
        relType: action.relType
      };

    case "LOR_ENTITY_DETAILS_REDUCER_UPDATE_SELECTED_ENTITY":
      return { ...state, selectedEntity: action.uuid };

    case "LOR_ENTITY_DETAILS_REDUCER_CLEAN_RELATIONSHIP_MODAL":
      return {
        ...state,
        relType: "",
        selectedEntity: "",
        relationshipModal: false
      };

    case "LOR_ENTITY_DETAILS_REDUCER_SET_RELATIONSHIPS":
      return {
        ...state,
        entityRelCompany: {
          ...state.entityRelCompany,
          [action.relType]: action.entityData
        }
      };

    case "LOR_ENTITY_DETAILS_REDUCER_TOGGLE_EDIT_MODAL":
      return {
        ...state,
        editModalForm: action.form,
        editModalToggle: !state.editModalToggle,
        relType: action.relType
      };

    case "LOR_ENTITY_DETAILS_REDUCER_UPDATE_EDIT_MODAL_FORM":
      return {
        ...state,
        editModalForm: { ...state.editModalForm, [action.name]: action.value }
      };

    case "LOR_ENTITY_DETAILS_REDUCER_CLEAN_EDIT_MODAL":
      return {
        ...state,
        editModalForm: {},
        editModalToggle: false,
        relType: ""
      };

    case "LOR_ENTITY_DETAILS_REDUCER_TOGGLE_CONFIRMATION_MODAL":
      return {
        ...state,
        deleteModalToggle: !state.deleteModalToggle,
        relUuid: action.relUuid
      };

    case "LOR_ENTITY_DETAILS_SET_BORROWERS":
      return {
        ...state,
        borrowers: action.newBorrowers
      };

    case "LOR_ENTITY_DETAILS_ADD_LOOKUP": {
      const entityDetailsLookup = _.cloneDeep(state.entityDetailsLookup);
      entityDetailsLookup[action.entityUuid] = action.entityDetails;
      return {
        ...state,
        entityDetailsLookup
      };
    }

    default:
      return state;
  }
}
