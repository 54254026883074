import { useIntl } from "react-intl";
import { SideSheetDropdown } from "../../../SideSheetDropdown";
import { govGuaranteeAgencyProgramOptions } from "../../../../../../../../../../../../../../../services/FormElements/components/loanOptions";
import { StateAwareFieldChangeFunction } from "../../../../services/types";

export const AgencyProgramDropdown = ({
  disabled = false,
  handleFieldChange,
  value = ""
}: AgencyProgramDropdownProps) => {
  const { formatMessage } = useIntl();
  const handleChange = (option: string) => {
    handleFieldChange([
      { value: option, stateColumnName: "gov_guarantee_agency_program" }
    ]);
  };
  return (
    <SideSheetDropdown
      disabled={disabled}
      handleChange={handleChange}
      label={formatMessage({ id: "AGENCY_PROGRAM" })}
      options={govGuaranteeAgencyProgramOptions}
      value={value}
    />
  );
};

interface AgencyProgramDropdownProps {
  disabled?: boolean;
  handleFieldChange?: StateAwareFieldChangeFunction;
  value?: string;
}
