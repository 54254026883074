import React from "react";
import PropTypes from "prop-types";
import { Header, Grid, Button, Icon } from "semantic-ui-react";
import LightHeader from "../../../../../../components/LightHeader";
import NullToString from "../../../../../../services/NullToString";

function ContactInfo({ entity, readOnly, onContactInfoEdit }) {
  const sectionHeaderMargin = { paddingTop: "8px" };
  return (
    <>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column style={sectionHeaderMargin}>
            <Header as="h3">Contact Information</Header>
          </Grid.Column>
          <Grid.Column>
            {!readOnly && (
              <Button
                basic
                circular
                icon
                floated="right"
                onClick={onContactInfoEdit}
                aria-label="edit contact information"
              >
                <Icon
                  name="pencil alternate"
                  color={readOnly ? "grey" : "green"}
                />
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid columns={3}>
        <Grid.Column>
          <LightHeader>Physical Address:</LightHeader>
          <b>{entity.phy_address_line1}</b>
          <br />
          {entity.phy_address_line2 ? (
            <b>
              <span>
                {entity.phy_address_line2}
                <br />
              </span>
            </b>
          ) : (
            ""
          )}
          <span>
            <b>
              {`${NullToString(entity.phy_address_city)}, ${NullToString(
                entity.phy_address_state
              )}`}
            </b>
            <br />
          </span>
          <b>{NullToString(entity.phy_address_zip)}</b>
          <br />
          <b>{NullToString(entity.phy_address_country)}</b>
        </Grid.Column>
        <Grid.Column>
          <LightHeader>Mailing Address</LightHeader>
          <b>{entity.mail_address_line1}</b>
          <br />
          {entity.mail_address_line2 ? (
            <span>
              <b>{entity.mail_address_line2}</b>
              <br />
            </span>
          ) : (
            ""
          )}
          <span>
            <b>
              {`${NullToString(entity.mail_address_city)}, ${NullToString(
                entity.mail_address_state
              )}`}
            </b>
            <br />
          </span>
          <b>{NullToString(entity.mail_address_zip)}</b>
          <br />
          <b>{NullToString(entity.mail_address_country)}</b>
          <br />
        </Grid.Column>
        <Grid.Column>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column width={5}>
                <LightHeader>Phone Number</LightHeader>
                <b>{entity.phone_no}</b>
              </Grid.Column>
              <Grid.Column>
                <LightHeader>Phone Number (Mobile)</LightHeader>
                <b>{entity.mobile_phone_no}</b>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <LightHeader>Email</LightHeader>
                <b>{entity.email}</b>
              </Grid.Column>
              <Grid.Column>
                <LightHeader>Website</LightHeader>
                <b>{entity.website}</b>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid>
    </>
  );
}

ContactInfo.propTypes = {
  entity: PropTypes.shape({
    phy_address_line1: PropTypes.string,
    phy_address_line2: PropTypes.string,
    phy_address_city: PropTypes.string,
    phy_address_state: PropTypes.string,
    phy_address_zip: PropTypes.string,
    phy_address_country: PropTypes.string,
    mail_address_line1: PropTypes.string,
    mail_address_line2: PropTypes.string,
    mail_address_city: PropTypes.string,
    mail_address_state: PropTypes.string,
    mail_address_zip: PropTypes.string,
    mail_address_country: PropTypes.string,
    phone_no: PropTypes.string,
    mobile_phone_no: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string
  }),
  readOnly: PropTypes.bool,
  onContactInfoEdit: PropTypes.func
};

export default ContactInfo;
