/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import { Container, Segment, Form } from "semantic-ui-react";
import PropTypes from "prop-types";
import Constants from "../../../../../../../services/Constants/strings";
import { actionCreators } from "../reducer";
import PPPFormInputs from "../utilities/PPPFormInputs";

const { RECOMMEND_APPROVE, RECOMMEND_DECLINE, NO_RECOMMENDATION } = Constants;

export function PPPUnderwritingObj({
  loanFormFields,
  setLoanFormFields,
  updateLoanFields
}) {
  const loanInputElements = (
    <PPPFormInputs
      inputs={[
        {
          name: "underwriter_recommendation",
          label: "",
          type: "radio",
          stacked: true,
          options: [RECOMMEND_DECLINE, RECOMMEND_APPROVE, NO_RECOMMENDATION]
        },

        {
          name: "original_amount",
          label: "Loan Amount Approved",
          type: "float",
          set: ({ original_amount }) =>
            setLoanFormFields({
              ...loanFormFields,
              current_contract_amount: original_amount,
              original_amount
            }),
          update: ({ original_amount }) =>
            updateLoanFields({
              ...loanFormFields,
              current_contract_amount: original_amount,
              original_amount
            })
        },

        {
          name: "amount_requested",
          label: "Loan Amount Requested",
          type: "float"
        }
      ]}
      fields={loanFormFields}
      update={updateLoanFields}
      set={setLoanFormFields}
    />
  );

  return (
    <Segment>
      <Container textAlign="left">
        <Form autoComplete="off">{loanInputElements}</Form>
      </Container>
    </Segment>
  );
}

PPPUnderwritingObj.propTypes = {
  loanFormFields: PropTypes.shape(),
  setLoanFormFields: PropTypes.func,
  updateLoanFields: PropTypes.func
};

const mapStateToProps = state => ({
  loanFormFields: state.PPPReducer.loanFormFields
});

export const mapDispatchToProps = dispatch => ({
  setLoanFormFields: fields =>
    dispatch(actionCreators.setLoanFormFields(fields)),
  updateLoanFields: fields => dispatch(actionCreators.updateLoanFields(fields))
});

export default connect(mapStateToProps, mapDispatchToProps)(PPPUnderwritingObj);
