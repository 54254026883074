import React, { FormEvent, Key, forwardRef, useEffect, useState } from "react";
import levenshtein from "fast-levenshtein";
import { Text } from "@blast-client/types";
import { logr } from "@blast-client/logr";
import {
  SearchDropdown,
  SearchDropdownProps
} from "../search-dropdown/search-dropdown";
import { getFormattedTextComponent } from "../../../../util/text/text";
import { IconButtonProps } from "../../../elements/button/icon-button/icon-button";

/**
 * The items passed to this component will be displayed in an animated list that
 * appears when the button is clicked. The user can sort the list of items by
 * entering text in the search box.
 */
export const SearchListDropdown = forwardRef(
  (
    {
      makeCustomListItem,
      itemIconClass,
      items,
      onChange, // <-- FYI: This is may not mean what you think it means. Read the code.
      onInputChange = () => {
        return;
      },
      ...props
    }: SearchListDropdownProps,
    ref
  ) => {
    const log = logr("app");
    const [filteredItems, setFilteredItems] = useState(items);

    useEffect(() => {
      setFilteredItems(items || []);
    }, [items]);

    function handleInput(evt: FormEvent<HTMLInputElement> | null) {
      if (!evt) {
        setFilteredItems(items);
        return;
      }

      onInputChange(evt);

      const { value } = evt.currentTarget;
      const threshold = 2;
      const matchesWithDistanceProperty = sortItemsWithinThresholdByDistance(
        items,
        value,
        threshold
      );
      const matches = removeDistanceProperty(matchesWithDistanceProperty);
      log.debug(() => [`SearchListDropdown: matches=`, matches]);
      setFilteredItems(matches);
    }

    function handleChildSelect({ currentTarget }: { currentTarget: any }) {
      const selectedKey =
        currentTarget.attributes.getNamedItem("data-item-key");
      const selectedItem = items.find(item => item.key === selectedKey?.value);
      if (selectedItem) {
        onChange(selectedItem);
      }
    }

    return (
      <SearchDropdown
        {...props}
        ref={ref}
        onChildSelect={handleChildSelect}
        onInput={handleInput}
        parentClassNames={
          "search-list-dropdown " + (props.parentClassNames ?? "")
        }
      >
        {filteredItems.map(filteredItem =>
          makeListItem({ filteredItem, makeCustomListItem, itemIconClass })
        )}
      </SearchDropdown>
    );
  }
);

export const sortItemsWithinThresholdByDistance = (
  items: SearchListItem[],
  userInput: string,
  threshold: number
): SearchListItemWithDistance[] => {
  const itemsWithDistances = findItemDistances(items, userInput);
  return itemsWithDistances
    .filter(item => item.distance <= threshold)
    .sort((a, b) => a.distance - b.distance);
};

// Initially, this function handled an optional threshold, but then
//  I extracted that into a separate function. At this point, this function is
//  dead code, but it may be useful if we want to have the option of using
//  this dropdown component without filtering out any elements by distance
//  threshold.
export const sortItemsByDistance = (
  items: SearchListItem[],
  userInput: string
): SearchListItemWithDistance[] => {
  const itemsWithDistances = findItemDistances(items, userInput);
  return itemsWithDistances.sort((a, b) => a.distance - b.distance);
};

export const removeDistanceProperty = (
  items: SearchListItemWithDistance[]
): SearchListItem[] => {
  const modifiedItems = items.map(item => {
    const { distance, ...rest } = item;
    return rest;
  });
  return modifiedItems;
};

export const findItemDistances = (
  items: SearchListItem[],
  userInput: string
): SearchListItemWithDistance[] => {
  return items.map(item => {
    const searchableTextArray = convertSearchableTextStringToLowercaseArray(
      item.searchableText
    );
    const lowercaseUserInput = userInput.toLowerCase();

    if (hasExactStringMatch(lowercaseUserInput, searchableTextArray)) {
      return { ...item, distance: 0 };
    }

    return findDistanceOfItemWithoutExactTextMatch(
      item,
      searchableTextArray,
      lowercaseUserInput
    );
  });
};

export const convertSearchableTextStringToLowercaseArray = (
  searchableText: string
) => {
  return searchableText.split(" | ").map(text => text.toLowerCase());
};

export const hasExactStringMatch = (
  userInput: string,
  searchableTextArray: string[]
) => {
  for (let i = 0; i < searchableTextArray.length; i++) {
    const textItem = searchableTextArray[i];
    if (textItem === userInput || textItem.includes(userInput)) {
      return true;
    }
  }
  return false;
};

export const findDistanceOfItemWithoutExactTextMatch = (
  item: SearchListItem,
  searchableTextArray: string[],
  userInput: string
): SearchListItemWithDistance => {
  const distances = new Set<number>();

  for (let i = 0; i < searchableTextArray.length; i++) {
    const textItem = searchableTextArray[i];
    if (userInputIsShorterThanTextItem(userInput, textItem)) {
      const distance = findShortestSubstringDistance(userInput, textItem);
      distances.add(distance);
      continue;
    }

    distances.add(levenshtein.get(textItem, userInput));
  }

  let shortestDistance = [...distances][0];
  for (const distance of distances) {
    shortestDistance =
      distance < shortestDistance ? distance : shortestDistance;
  }

  return { ...item, distance: shortestDistance };
};

export const userInputIsShorterThanTextItem = (
  userInput: string,
  searchText: string
) => {
  return searchText.length >= userInput.length;
};

export const findShortestSubstringDistance = (
  userInput: string,
  searchableText: string
): number => {
  let shortestDistance: number | undefined;
  for (let i = 0; i <= searchableText.length - userInput.length; i++) {
    const substring = searchableText.substring(i, i + userInput.length);
    const distance = levenshtein.get(substring, userInput);
    shortestDistance =
      shortestDistance === undefined
        ? distance
        : Math.min(shortestDistance, distance);
  }

  if (shortestDistance === undefined) {
    throw new Error("No distances found.");
  }

  return shortestDistance;
};

const makeListItem = ({
  filteredItem,
  makeCustomListItem,
  itemIconClass
}: MakeListItemProps) => {
  if (makeCustomListItem) {
    return makeCustomListItem(filteredItem);
  }
  return makeRegularListItem({ itemInfo: filteredItem, itemIconClass });
};

const makeRegularListItem = ({
  itemInfo: { key, ...item },
  itemIconClass
}: makeRegularListItemProps) => {
  if (itemIconClass) {
    return makeIconListItem({ key, itemIconClass, item });
  }
  return makeDefaultListItem(key, item);
};

const makeIconListItem = ({
  key,
  itemIconClass,
  item
}: makeIconListItemProps) => {
  return (
    <li data-item-key={key} key={key} className="search-list-item item">
      <i className={`${itemIconClass} icon`}></i>
      {getFormattedTextComponent(item)}
    </li>
  );
};

const makeDefaultListItem = (
  key: React.Key,
  item: Omit<SearchListItem, "key">
) => {
  return React.cloneElement(
    getFormattedTextComponent(item, { key, tagName: "li" }),
    { "data-item-key": key }
  );
};

export interface SearchListDropdownProps
  extends Omit<
    SearchDropdownProps,
    "children" | "onChildClick" | "onChildSelect" | "onInput"
  > {
  /**
   * Custom JSX that can be provided for list elements.
   * Will override itemIconClass.
   * Props contain the text/message portion of the SearchListItem
   */
  makeCustomListItem?: (props: SearchListItem) => React.ReactElement;
  /**
   * Provide props for the control button
   */
  button: IconButtonProps;
  /**
   * Provide an optional icon class if an icon is needed when rendering items in the list.
   * The icon string is the icon class that defines which icon is being shown.
   */
  itemIconClass?: string;
  /**
   * Each element in `items` will become an item in the list that is displayed.
   * Each item will be wrapped in an `<li>` tag.
   */
  items: SearchListItem[];
  /**
   * When the user clicks on an item in the list this callback will be invoked
   * with the source SearchListItem.
   */
  onChange: (item: SearchListItem) => void;
  /**
   * Optionally, the consumer can pass in a function that will get called with the onChange event
   * of the input. Notice the difference between this and the onChange prop.
   */
  onInputChange?: (inputEvent: any) => any;
}

export interface SearchListItem extends Text {
  entityType?: string;
  /**
   * A React Key that uniquely identifies each item in the list.
   */
  key: Key;
  /**
   * The text that will be compared to the value of the search input; this will
   * almost always be the same as the text displayed to the user.
   */
  searchableText: string;
}

export interface SearchListItemWithDistance extends SearchListItem {
  distance: number;
}

export interface MakeListItemProps {
  filteredItem: SearchListItem;
  makeCustomListItem:
    | ((props: SearchListItem) => React.ReactElement)
    | undefined;
  itemIconClass: string | undefined;
}

export interface makeRegularListItemProps {
  itemInfo: SearchListItem;
  itemIconClass?: string;
}

export interface makeIconListItemProps {
  key: React.Key;
  itemIconClass: string;
  item: Omit<SearchListItem, "key">;
}
