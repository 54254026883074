import React, { useEffect, useState, useReducer } from "react";
import { connect } from "react-redux";

import { Modal, Button, Image, Divider } from "semantic-ui-react";

import { Form } from "formsy-semantic-ui-react";
import PropTypes from "prop-types";
import TypeCheck from "typecheck-extended";
import { useToast } from "@bafsllc/ui-shared";

import {
  validationErrors,
  validationLabel
} from "../../../../services/FormElements";

import bafsLogo from "../../../../assets/images/bafs-logo-color.svg?url";
import { Messages, Notifications } from "../../../../services/ApiLib";

import "./index.css";

function formReducer(state, event) {
  return {
    ...state,
    [event.name]: event.value
  };
}

function EngageBAFSModal(props) {
  const {
    headerText,
    open,
    institution,
    loanApp,
    userName,
    onCancel,
    onSuccess,
    confirmLabelText = "Submit",
    cancelLabelText = "Cancel"
  } = props;

  const { addToast } = useToast();

  const [formData, setFormData] = useReducer(formReducer, {});
  const [loading, setLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  useEffect(() => {
    setFormData({ name: "name", value: userName });
    setFormData({ name: "institution_name", value: institution.name });
    setFormData({ name: "institution_uuid", value: institution.uuid });
    setFormData({ name: "link", value: window.location.href });
  }, [institution, userName]);

  const resetState = () => {
    setLoading(false);
    setFormData({});
    setCanSubmit(false);
  };

  const handleSubmit = async () => {
    setCanSubmit(false);
    setLoading(true);

    // Post a message to internal chat
    const messageTemplate = `BAFS' Services Requested: ${
      formData.service_type
    }\n\nNotes:\n${formData.info || ""}`;
    const messageSend = Messages.asyncPost(
      {
        parent_uuid: loanApp.uuid,
        institution_uuid: institution.uuid,
        message: messageTemplate
      },
      "",
      false
    );

    // Send an email to the helpdesk
    const emailTemplate = `${formData.name} &lt;${
      formData.email
    }&gt; has requested assistance with an application.<br><b>Do not reply to this email.</b><br><br><b>Link:</b> ${
      formData.link
    }<br><b>Type:</b> ${formData.service_type}<br><b>App ID:</b> (${
      loanApp.app_id || ""
    })<br><b>App UUID:</b> (${loanApp.uuid || ""})<br><b>Institution:</b> ${
      institution.name
    } (${institution.uuid}).<br><b>Phone:</b> ${
      formData.phone || ""
    }<br><b>Notes:</b><br><pre>${formData.info || ""}</pre>`;
    const payload = {
      message: emailTemplate,
      subject: `Service requested for: ${institution.name}`
    };
    const noop = () => {};
    const notificationSend = Notifications.postInternalMessage(
      noop,
      noop,
      payload
    );

    Promise.all([messageSend, notificationSend])
      .then(() => {
        resetState();
        onCancel();
        if (onSuccess) onSuccess();
      })
      .catch(() => {
        setLoading(false);
        setCanSubmit(true);
        addToast({
          id: "MESSAGE_ERROR",
          title: {
            id: "BAFS_SERVICES_REQUEST_SENT_ERROR_TITLE",
            defaultMessage: "Error"
          },
          message: {
            id: "BAFS_SERVICES_REQUEST_SENT_ERROR_MESSAGE",
            defaultMessage:
              "We‘re very sorry, there was an error sending your message."
          },
          variant: "error"
        });
      });
  };

  const handleCancel = (...args) => {
    onCancel(...args);
    resetState();
  };

  const handleChange = (event, data) => {
    TypeCheck(data.name, "string");

    const { name, value } = data;

    // Not sure this is the best way to detect this, but it works.
    const isRadio = event.target.type === undefined;
    setFormData({
      name: isRadio ? name : event.target.name,
      value: isRadio ? value : event.target.value
    });
  };

  return (
    <Modal
      open={open}
      size="small"
      onClose={handleCancel}
      className="engageBAFS"
    >
      <Modal.Content>
        <div className="modalHeader">
          <div className="modalLogo">
            <Image src={bafsLogo} alt="BAFS Logo" />
          </div>
        </div>

        <h3 className="mt-5 mb-4">{headerText}</h3>

        <div className="modalBody">
          <Form
            onInvalid={() => setCanSubmit(false)}
            onValid={() => setCanSubmit(true)}
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Form.Group size="large">
              <Form.Input
                required
                name="name"
                label="Name"
                placeholder="Name"
                width={16}
                value={formData.name || ""}
                onChange={handleChange}
                errorLabel={validationLabel()}
                validationErrors={{
                  isExisty: "No numbers or special characters allowed",
                  isDefaultRequiredValue: "Name is Required"
                }}
                validations="isExisty"
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                required
                type="email"
                name="email"
                label="Email"
                placeholder="Email"
                width={16}
                value={formData.email || ""}
                onChange={handleChange}
                errorLabel={validationLabel()}
                validationErrors={validationErrors}
                validations="isEmail"
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                type="tel"
                name="phone"
                label="Phone Number"
                placeholder="Phone Number"
                width={16}
                value={formData.phone || ""}
                onChange={handleChange}
                errorLabel={validationLabel()}
                validationErrors={{
                  matchRegexp: "Only numbers, spaces, or: - + . ( )"
                }}
                validations={{
                  matchRegexp:
                    /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d*)\)?)[-. ]?)?((?:\(?\d{1,}\)?[-. ]?)+)(?:[-. ]?(?:#|ext.?|extension|x)[-. ]?(\d+))?$/i
                }}
                maxLength={20}
              />
            </Form.Group>

            <Form.RadioGroup
              required
              label="Please select the type of service you are requesting"
              name="service_type"
              onChange={handleChange}
              value={formData.service_type || ""}
              className="no-flex"
            >
              <Form.Radio
                name="service_type"
                label="Lending Services"
                value="Lending Services"
              />
              <Form.Radio
                name="service_type"
                label="Credit Admin Services"
                value="Credit Admin Services"
              />
            </Form.RadioGroup>

            <Form.Group>
              <Form.TextArea
                name="info"
                label="Please provide more details about the services requested"
                placeholder="Details of the request"
                width={16}
                value={formData.info || ""}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Form>
          <p>
            <span style={{ color: "#D24F15", fontWeight: "bold" }}>* </span>
            Required
          </p>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button basic onClick={handleCancel}>
          {cancelLabelText}
        </Button>
        <Button disabled={!canSubmit} onClick={handleSubmit}>
          {loading ? "Sending..." : confirmLabelText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

EngageBAFSModal.propTypes = {
  open: PropTypes.bool.isRequired,
  headerText: PropTypes.string.isRequired,
  institution: PropTypes.shape().isRequired,
  userName: PropTypes.string.isRequired,
  loanApp: PropTypes.shape(),
  confirmLabelText: PropTypes.string,
  cancelLabelText: PropTypes.string,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func
};

const mapStateToProps = state => ({
  userName: `${state.auth.firstName} ${state.auth.lastName}`
});

export default connect(mapStateToProps)(EngageBAFSModal);
