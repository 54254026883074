import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Container, Header, Icon, Table } from "semantic-ui-react";

import {
  prettyDate,
  formatDateTimeString
} from "../../../../../../../../../../services/DateTime";
import {
  getFileMimeType,
  FILE_MIME_TYPES_MAP
} from "../../../../../../../../../../services/Constants/AllowedDocumentTypes";

import { EditButton } from "../../../../../../../../../../components/CustomFormElements";
import { permCheck } from "../../../../../../../../../../services/Auth";
import BorrowerVisibilityToggle from "../../../../../../../../../LOS/components/Documents/components/DocTable/BorrowerVisibilityToggle";
import { ObjectMetadata } from "../../../../../../../../../../services/ApiLib";
import logger from "../../../../../../../../../../services/logger";

export function onError(response) {
  logger.error(response);
}

const tableHeaders = [
  "Document Type",
  "Period Start",
  "Period End",
  "Doc Date",
  "Uploaded Date",
  "Source",
  "Description",
  "Status",
  ""
];

const autoSpreadDocs = ["Tax Return", "Annual Financial Statement"];

function DocCategories(props) {
  const { onPreview, toggleDocApprovalModal } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = doc => {
    history.push(
      `/credit_management/entity/${props.entityUuid}/financial-statements?doc_uuid=${doc.uuid}`
    );
  };

  const sortedDocData = props.data
    .sort((currDoc, nextDoc) =>
      currDoc.docDate ? currDoc.docDate.localeCompare(nextDoc.docDate) : currDoc
    )
    .reverse();

  const onToggle = async doc => {
    try {
      const res = await ObjectMetadata.updateAsync(doc.uuid, {
        borrowerVisibility: !doc.borrowerVisibility
      });

      dispatch({
        type: "CMS_UPDATE_DOCUMENT",
        doc: res.data
      });
    } catch (error) {
      onError(error);
    }
  };

  sortedDocData.map(obj => {
    const header = "Borrower Visibility";
    const borrowerVisibility = tableHeaders.find(a => a === header);

    if (obj.bpOrganizationUuid) {
      if (borrowerVisibility) {
        return tableHeaders;
      }
      tableHeaders.splice(8, 0, header);

      return tableHeaders;
    }

    if (borrowerVisibility) {
      tableHeaders.splice(8, 1);
    }

    return tableHeaders;
  });

  const headers = tableHeaders.map(header => (
    <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
  ));
  const body = sortedDocData.map(obj => {
    const { mimeType } = getFileMimeType(obj.fileName);
    const iconName =
      mimeType === FILE_MIME_TYPES_MAP.pdf.mimeType ? "eye" : "download";
    const newPeriodButton = (
      <Button as="div" labelPosition="right">
        <Button
          icon
          basic
          size="small"
          onClick={() => {
            handleClick(obj);
          }}
        >
          <Button.Content>
            New Period {<Icon name="external alternate" />}
          </Button.Content>
        </Button>
      </Button>
    );
    return (
      <Table.Row key={obj.uuid}>
        <Table.Cell>{obj.docType}</Table.Cell>
        <Table.Cell>
          {obj.periodStart ? prettyDate(obj.periodStart) : ""}
        </Table.Cell>
        <Table.Cell>
          {obj.periodEnd ? prettyDate(obj.periodEnd) : ""}
        </Table.Cell>
        <Table.Cell>{obj.docDate ? prettyDate(obj.docDate) : ""}</Table.Cell>
        <Table.Cell>
          {obj.uploadDate ? formatDateTimeString(obj.uploadDate) : ""}
        </Table.Cell>
        <Table.Cell>{obj.source}</Table.Cell>
        <Table.Cell>{obj.description}</Table.Cell>
        <Table.Cell>{obj.status}</Table.Cell>
        {obj.bpOrganizationUuid ? (
          <Table.Cell>
            <BorrowerVisibilityToggle doc={obj} onToggle={onToggle} />
          </Table.Cell>
        ) : null}
        <Table.Cell>
          {autoSpreadDocs.includes(obj.docType) &&
          permCheck(["financial_statements_rw", "admin"])
            ? newPeriodButton
            : ""}
          <Button
            basic
            circular
            icon={<Icon name={iconName} color="green" />}
            onClick={() => onPreview(obj.uuid, mimeType, obj.fileName)}
          />
          <EditButton onClick={() => toggleDocApprovalModal(obj)} />
        </Table.Cell>
      </Table.Row>
    );
  });

  return (
    <Container style={{ marginTop: "3%" }}>
      <Header as="h1" textAlign="left" size="large">
        {props.documentType}
      </Header>
      <Table celled>
        <Table.Header>
          <Table.Row>{headers}</Table.Row>
        </Table.Header>
        <Table.Body>{body}</Table.Body>
      </Table>
    </Container>
  );
}

DocCategories.propTypes = {
  documentType: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      col1: PropTypes.string,
      col2: PropTypes.string,
      col3: PropTypes.string,
      col4: PropTypes.string,
      col5: PropTypes.string
    })
  ).isRequired,
  selectedDocument: PropTypes.shape({
    category: PropTypes.string,
    documentName: PropTypes.string,
    description: PropTypes.string,
    comments: PropTypes.arrayOf(PropTypes.object),
    status: PropTypes.string,
    docCategory: PropTypes.string,
    docType: PropTypes.string,
    docDate: PropTypes.string,
    periodStart: PropTypes.string,
    periodEnd: PropTypes.string,
    uploadDate: PropTypes.string
  }).isRequired,
  onPreview: PropTypes.func,
  entityUuid: PropTypes.string,
  toggleDocApprovalModal: PropTypes.func.isRequired
};

export default DocCategories;
