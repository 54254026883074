import React from "react";
import { func, shape } from "prop-types";
import { Table, Button, Icon } from "semantic-ui-react";
import { PrettyNumber } from "../../../../services/PrettyNumber";

function RealEstateTable({ data, onEdit }) {
  return (
    <>
      <div style={{ textAlign: "right" }}>
        <Button
          basic
          circular
          data-testid="edit-asset-row-btn"
          icon={<Icon name="pencil" color="green" />}
          onClick={() => onEdit(true)}
        />
      </div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Appraisal Amount</Table.HeaderCell>
            <Table.HeaderCell>Legal Description</Table.HeaderCell>
            <Table.HeaderCell>Address1</Table.HeaderCell>
            <Table.HeaderCell>Address2</Table.HeaderCell>
            <Table.HeaderCell>City</Table.HeaderCell>
            <Table.HeaderCell>State</Table.HeaderCell>
            <Table.HeaderCell>Zip Code</Table.HeaderCell>
            <Table.HeaderCell>Zip Plus4</Table.HeaderCell>
            <Table.HeaderCell>Flood Insurance Required</Table.HeaderCell>
            <Table.HeaderCell>Is Primary Residence</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row key={data.uuid}>
            <Table.Cell>{`$ ${
              data.appraisal_amount ? PrettyNumber(data.appraisal_amount) : ""
            }`}</Table.Cell>
            <Table.Cell>{data.legal_description}</Table.Cell>
            <Table.Cell>{data.address1}</Table.Cell>
            <Table.Cell>{data.address2}</Table.Cell>
            <Table.Cell>{data.city}</Table.Cell>
            <Table.Cell>{data.state}</Table.Cell>
            <Table.Cell>{data.zip_code}</Table.Cell>
            <Table.Cell>{data.zip_plus4}</Table.Cell>
            <Table.Cell>
              {data.flood_required ? "flood required" : ""}
            </Table.Cell>
            <Table.Cell>
              {data.is_primary_residence ? "primary residence" : ""}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}

RealEstateTable.propTypes = {
  data: shape().isRequired,
  onEdit: func.isRequired
};

export default RealEstateTable;
