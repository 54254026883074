import React from "react";
import type { TextMenuItem } from "../../../../types/collections";
import { Message } from "../../../../custom/elements/message/message";
import type { MenuBaseProps } from "../menu-base/menu-base";
import { MenuBase } from "../menu-base/menu-base";

/**
 * A menu that displays items horizontally. This component signature allows for
 * a custom implementation of item display; you probably want to use the other,
 * simpler, signature where the parent provides items that implement the
 * {@link TertiaryMenuItems} interface. In this signature the parent **must**
 * implement both `items` and `renderItem` to populate the menu items.
 *
 * @see {@link https://zpl.io/mDrAl6v Zeplin tertiary menu}
 */
export function TertiaryMenu<Item>(
  props: TertiaryMenuCustomItemProps<Item>
): JSX.Element;
/**
 * A menu that displays items horizontally. This component signature creates a
 * design system menu from the elements in the `items` prop that implement
 * {@link TertiaryMenuItems} (test only). If you need to customize the display
 * of the items you can use the more complex signature and implement the
 * `renderItem` prop.
 *
 * @see {@link https://zpl.io/mDrAl6v Zeplin tertiary menu}
 */
export function TertiaryMenu<Item extends TextMenuItem>(
  props: TertiaryMenuProps<Item>
): JSX.Element;
/**
 * A menu that displays items horizontally.
 * @see {@link https://zpl.io/mDrAl6v}
 */
export function TertiaryMenu<Item>(props: TertiaryMenuBaseProps<Item>) {
  const updateMenuClassnames: MenuBaseProps<Item>["updateMenuClassnames"] =
    names =>
      props.updateMenuClassnames
        ? props.updateMenuClassnames([...names, "pointing"])
        : [...names, "pointing"];

  const renderTertiaryItem: MenuBaseProps<Item>["renderItem"] = (
    item,
    className
  ) => {
    if (isCustomProps(props)) {
      return props.renderItem(item, className);
    }

    // At this point the props have to be `TertiaryMenuProps<Item extends
    // TextMenuItem>` so we know that `item` implements the `TextMenuItem`
    // interface.

    // In future versions of TypeScript (>=4.8) TS will be augmented so that it
    // knows `item` can be cast directly to `TextMenuItem`, but for now we have
    // to do this silly cast to `unknown` first.

    const liProps: React.HTMLProps<HTMLLIElement> = {
      className: `${className}${
        (item as unknown as TextMenuItem).active ? " active" : ""
      }`,
      key: (item as unknown as TextMenuItem).key
    };

    liProps.onClick = evt => (props as any).onItemSelect(evt, item);

    return (
      <li {...liProps}>
        <Message {...(item as unknown as TextMenuItem).message} />
      </li>
    );
  };

  return (
    <MenuBase
      {...props}
      renderItem={renderTertiaryItem}
      updateMenuClassnames={updateMenuClassnames}
    />
  );
}

/**
 * Implement this interface when the standard design of the menu items (text
 * only) is needed.
 */
export interface TertiaryMenuProps<Item extends TextMenuItem>
  extends Omit<MenuBaseProps<Item>, "renderItem"> {
  /** Invoked when the TertiaryMenu has created the menu items and one of the
   * menu items is clicked. */
  onItemSelect: (
    evt: React.MouseEvent<HTMLLIElement> | React.KeyboardEvent<HTMLLIElement>,
    item: Item
  ) => void;
}

/**
 * Implement this interface when the contents of the menu items must be
 * customized.
 */
export interface TertiaryMenuCustomItemProps<Item>
  extends MenuBaseProps<Item> {}

function isCustomProps(
  props: any
): props is TertiaryMenuCustomItemProps<unknown> {
  return "renderItem" in props && !!props.renderItem;
}

type TertiaryMenuBaseProps<Item> = Omit<MenuBaseProps<Item>, "renderItem">;
