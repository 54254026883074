import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Header, Icon, Dropdown } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import {
  Entities,
  EntityRelationships,
  Relationships
} from "../../../../../../../../../../../../services/ApiLib";
import { ErrorReport } from "../../../../../../../../../../../../services/ErrorReporter";
import logger from "../../../../../../../../../../../../services/logger";
import serializeRelationshipType from "../../../../../../../../../../../../services/Constants/serializeRelationshipType";
import {
  relationshipTypes,
  relationshipParentChildTypes
} from "../../../../../../../../../../../../services/Constants/relationshipTypes";
import { ConcatName } from "../../../../../../../../../../../../services/Entities";
import { EntityModal } from "../../../../../../../../../../../../components/Modals";
import Constants from "../../../../../../../../../../../../services/Constants/strings";

export const getDispatch = ({ dispatch, activeItem }) => {
  if (activeItem === relationshipTypes.OWNER) {
    return ({ data }) =>
      dispatch({
        type: "CMS_REL_SET_OWNER_DETAILS",
        relationshipOwners: data
      });
  }
  if (activeItem === relationshipTypes.OFFICER) {
    return ({ data }) =>
      dispatch({
        type: "CMS_REL_SET_OFFICER_DETAILS",
        relationshipOfficers: data
      });
  }
  if (activeItem === relationshipTypes.OTHER_CAPACITIES) {
    return ({ data }) =>
      dispatch({
        type: "CMS_REL_SET_OTHERS_DETAILS",
        relationshipOthers: data
      });
  }
  if (activeItem === relationshipTypes.AUTHORIZED_SIGNER) {
    return ({ data }) =>
      dispatch({
        type: "CMS_REL_SET_AUTHORIZED_SIGNER",
        relationshipSigner: data
      });
  }
  if (activeItem === relationshipTypes.AFFILIATE) {
    return ({ data }) =>
      dispatch({
        type: "CMS_REL_SET_AFFILIATE_DETAILS",
        relationshipAffiliates: data
      });
  }
  logger.error(`Uncaught relationship type: ${activeItem}`);
  return () => null;
};

function AddModal() {
  const [open, setOpen] = useState(false);
  const [entityModalOpen, setEntityModalOpen] = useState(false);
  const [moreAvailable, setMoreAvailable] = useState(false);
  const [entityFilterOptions, setEntityFilterOptions] = useState([]);
  const [needsNewButton, setNeedsNewButton] = useState(false);
  const [searchString, setSearchString] = useState("");
  const dispatch = useDispatch();

  const {
    activeItem,
    entities,
    institutionUuid,
    newEntityType,
    officers,
    otherCapacities,
    authorizedSigner,
    owners,
    parentUuid,
    selectedEntity
  } = useSelector(state => ({
    activeItem: state.CreditManagementReducer.relationshipsActiveItem,
    entities: state.CreditManagementReducer.entities,
    institutionUuid: state.CreditManagementReducer.entityData.institution_uuid,
    newEntityType: state.CreditManagementReducer.relNewEntityType,
    officers: state.CreditManagementReducer.officers,
    otherCapacities: state.CreditManagementReducer.otherCapacities,
    authorizedSigner: state.CreditManagementReducer.authorizedSigner,
    owners: state.CreditManagementReducer.owners,
    parentUuid: state.CreditManagementReducer.entityData.uuid,
    selectedEntity: state.CreditManagementReducer.relationshipsSelectedEntity
  }));

  function onError(thrownError) {
    const messageForUser = `Error: Could not save relationship for ${activeItem}.`;
    ErrorReport.toModal(thrownError, messageForUser);
  }

  useEffect(() => {
    // map entity options into dropdown format
    if (!entities) {
      return;
    }
    let propertyName;
    let existingEntities;

    switch (activeItem) {
      case relationshipTypes.OWNER:
        propertyName = Constants.OWNERENTITYDATA;
        existingEntities = owners;
        break;
      case relationshipTypes.OFFICER:
        propertyName = Constants.OFFICERENTITYDATA;
        existingEntities = officers;
        break;
      case relationshipTypes.OTHER_CAPACITIES:
        propertyName = Constants.ENTITYDATA;
        existingEntities = otherCapacities;
        break;
      case relationshipTypes.AUTHORIZED_SIGNER:
        propertyName = Constants.SIGNERENTITYDATA;
        existingEntities = authorizedSigner;
        break;
      default:
        logger.error(`Could not find relationship type for ${activeItem}`);
    }

    const existingEntityUuids = {};

    if (existingEntities && existingEntities.length !== 0) {
      // add hash of entity uuids already in the table
      let i = 0;
      for (; i < existingEntities.length; i += 1) {
        const entity = existingEntities[i];
        const entityData = entity[propertyName];
        existingEntityUuids[entityData.uuid] = true;
      }
    }

    const nextEntityFilterOptions = entities
      .map((entity, index) => ({
        key: index,
        text: `${entity.tin} | ${ConcatName(entity)}`,
        value: entity.uuid,
        entity_type: entity.entity_type
      }))
      // remove suggestions for entities that are already in the table
      .filter(entity => {
        if (activeItem === relationshipTypes.AUTHORIZED_SIGNER) {
          return (
            !existingEntityUuids[entity.value] &&
            entity.entity_type === "Individual"
          );
        }
        return !existingEntityUuids[entity.value];
      });

    const noSelectionMade = !!searchString && !selectedEntity;

    setMoreAvailable(nextEntityFilterOptions.length > 9 && noSelectionMade);
    setNeedsNewButton(nextEntityFilterOptions.length <= 9 && noSelectionMade);
    setEntityFilterOptions(nextEntityFilterOptions.slice(0, 9));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [entities]);

  useEffect(() => {
    // retrieve entities that match search string
    if (!searchString) {
      return;
    }
    (async () => {
      const uuids = {
        lookupString: searchString,
        institutionUuid
      };

      try {
        const { data } = await Entities.asyncSearchByInstitutionUuid(uuids);
        if (data) {
          dispatch({
            type: "CMS_REL_LOAD_ENTITIES",
            data
          });
        }
      } catch (err) {
        onError(err);
      }
    })();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [searchString]);

  function handleClose() {
    const data = [];
    setSearchString("");
    setOpen(false);
    dispatch({
      type: "CMS_REL_LOAD_ENTITIES",
      data
    });
    dispatch({
      type: "CMS_REL_CLEAR_SELECTED_ENTITY"
    });
  }

  async function onSuccessEntityPost() {
    try {
      const data = await EntityRelationships.asyncRead(parentUuid, activeItem);
      getDispatch({ activeItem, dispatch })(data);
      setEntityModalOpen(false);
      dispatch({
        type: "ENT_MGMT_CLEAR_ENTITY"
      });
      if (activeItem === "Owner" || activeItem === "Authorized Signer") {
        const affiliateData = await EntityRelationships.asyncRead(
          parentUuid,
          "Affiliate"
        );
        getDispatch({ activeItem: "Affiliate", dispatch })(affiliateData);
      }
    } catch (err) {
      onError(err);
    }
  }

  async function saveRelationship(childUuid) {
    const relationshipBody = {
      parent_uuid: parentUuid,
      child_uuid: childUuid,
      institution_uuid: institutionUuid,
      rel_type: serializeRelationshipType(
        activeItem === "Authorized Signer" ? "Signer" : activeItem
      ),
      parent_type: relationshipParentChildTypes.ENTITY,
      child_type: relationshipParentChildTypes.ENTITY
    };

    if (activeItem === "Owner") {
      relationshipBody.voting_rights = false;
    }

    try {
      await Relationships.asyncAdd(relationshipBody);
      onSuccessEntityPost();
      handleClose();
    } catch (err) {
      onError(err);
    }
  }

  return (
    <div>
      <Modal
        size="mini"
        trigger={
          <Button
            basic
            circular
            icon
            floated="right"
            onClick={() => {
              setOpen(true);
            }}
          >
            <Icon data-testid="open-add-modal" name="plus" color="green" />
          </Button>
        }
        open={open}
      >
        <Modal.Header>Add {activeItem}</Modal.Header>

        <Modal.Content>
          <Form>
            <Header size="small" color="blue" textAlign="left">
              Search TIN/Name:
            </Header>

            {moreAvailable && <div>&nbsp;More Results Available</div>}

            {needsNewButton && (
              <Button
                color="green"
                onClick={() => {
                  setEntityModalOpen(true);
                }}
              >
                Create New Entity
              </Button>
            )}

            <Dropdown
              icon="search"
              placeholder="Enter Entity Name"
              search
              scrolling
              fluid
              selection
              data-testid="dropdown"
              onSearchChange={(e, data) => {
                if (data.searchQuery) {
                  setSearchString(data.searchQuery);
                }
              }}
              options={entityFilterOptions}
              onChange={(e, data) => {
                dispatch({
                  type: "CMS_REL_SELECTED_ENTITY",
                  selectedEntity: data.value
                });
              }}
              selectOnBlur={false}
            />
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button
            data-testid="cancel-button"
            key="cancel"
            content="Cancel"
            onClick={() => handleClose()}
          />
          <Button
            color="green"
            key="select"
            content="Select"
            data-testid="save-button"
            onClick={() => {
              saveRelationship(selectedEntity);
            }}
            disabled={!selectedEntity}
          />
        </Modal.Actions>
      </Modal>

      {entityModalOpen && (
        <EntityModal
          newEntityType={newEntityType}
          selectedInstitution={institutionUuid}
          onCloseModal={() => {
            setEntityModalOpen(false);
            dispatch({
              type: "ENT_MGMT_CLEAR_ENTITY"
            });
          }}
          onSuccess={onSuccessEntityPost}
          open
          add
        />
      )}
    </div>
  );
}

export default AddModal;
