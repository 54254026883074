/*
Do not consider this as an example of how a reducer should be done.
This is antipattern to Redux and should be refactored.
*/
import { GetUserData, PutUserData } from "../../services/UserData";

const initialState = {
  saveMessage: "",
  notificationSettings: {
    web: false,
    email: false
  }
};

// eslint-disable-next-line default-param-last
export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_NOTIFICATION_VALUES": {
      GetUserData(action.dispatchParams, action.fetchParams);
      return state;
    }

    case "SET_NOTIFICATION_VALUES": {
      const metaData = action.data.user_metadata;
      return {
        ...state,
        notificationSettings: {
          web: metaData.web_notifications,
          email: metaData.email_notifications
        }
      };
    }

    case "SET_SINGLE_NOTIFICATION_VALUE": {
      const notificationSettingsCopy = { ...state.notificationSettings };
      notificationSettingsCopy[action.notificationType] = action.checked;
      return {
        ...state,
        saveMessage: "",
        notificationSettings: notificationSettingsCopy
      };
    }

    case "UPDATE_DATABASE": {
      const notificationSettings = {
        web: state.notificationSettings.web,
        email: state.notificationSettings.email
      };
      PutUserData(
        action.dispatchParams,
        action.fetchParams,
        notificationSettings
      );
      return state;
    }

    case "NOTIFY_OF_SAVE": {
      return {
        ...state,
        saveMessage: action.saveMessage
      };
    }

    case "RESET_USER_MESSAGE": {
      return {
        ...state,
        saveMessage: ""
      };
    }

    default:
      return state;
  }
}
