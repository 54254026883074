/* eslint-disable camelcase */
import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import FetchService, { asyncFetchService } from "../../../FetchService";
import { FormatUrlV2 } from "../../../FormatUrl";
import { ConcatQueryParams } from "../Utilities";

export const baseEndpoint = ApiEndpoints.baseUri + ApiEndpoints.messagesV3;

function post(
  onSuccess,
  onError,
  parentUuid,
  institutionUuid,
  message,
  // eslint-disable-next-line default-param-last
  message_metadata = "",
  callbackData
) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(parentUuid, "string");
  TypeCheck(institutionUuid, "string");
  TypeCheck(message, "string");

  if (!parentUuid || !institutionUuid || !message) {
    throw new Error(
      "parentUuid, institutionUuid, and message must be populated"
    );
  }
  const body = {
    institution_uuid: institutionUuid,
    message,
    parent_uuid: parentUuid,
    ...(message_metadata ? { message_metadata } : null)
  };

  FetchService("POST", baseEndpoint, onSuccess, onError, body, callbackData);
}
// This will use V2 as V3 endpoint doesn't work currently with DocumentTrackingView
async function asyncPost(
  messageBody,
  message_metadata = "",
  suppressErrors = true
) {
  TypeCheck(messageBody, "object", true);
  TypeCheck(message_metadata, "string");
  if (
    !messageBody.parent_uuid ||
    !messageBody.institution_uuid ||
    !messageBody.message
  ) {
    throw new Error(
      "parentUuid, institutionUuid, and message must be populated"
    );
  }
  const url = ApiEndpoints.baseUri + ApiEndpoints.messagesV3;
  const rsp = await asyncFetchService(
    "POST",
    url,
    messageBody,
    null,
    suppressErrors
  );
  return rsp;
}

function get(onSuccess, onError, uuid, params, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string", false);
  TypeCheck(params, "object", false);

  let url = baseEndpoint;
  if (uuid) {
    url = `${baseEndpoint}/${uuid}`;
  }
  if (params) {
    url = `${url}${ConcatQueryParams(params)}`;
  }
  FetchService("GET", url, onSuccess, onError, undefined, callbackData);
}

async function read(uuid, params) {
  TypeCheck(uuid, "string", false);
  TypeCheck(params, "object", false);
  let url = baseEndpoint;
  if (uuid) {
    url = `${baseEndpoint}/${uuid}`;
  }
  if (params) {
    url = `${url}${ConcatQueryParams(params)}`;
  }
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

function ack(onSuccess, onError, uuid, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string");

  const url = `${baseEndpoint}/${uuid}`;
  FetchService("PUT", url, onSuccess, onError, { ack: true }, callbackData);
}

async function readByParentUuid(parentUuid) {
  TypeCheck(parentUuid, "string", true);

  const url = FormatUrlV2(ApiEndpoints.messagesGetAllByParentUuid, {
    parentUuid
  });
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

export default {
  ack,
  post,
  get,
  read,
  asyncPost,
  readByParentUuid
};
