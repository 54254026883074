import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import FetchService from "../../../FetchService";
import { FormatUrlV2 } from "../../../FormatUrl";

function get(onSuccess, onError, uuid, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string");

  const url = FormatUrlV2(ApiEndpoints.passwordResetGet, { uuid });

  FetchService("GET", url, onSuccess, onError, null, callbackData);
}

function add(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");

  const url = ApiEndpoints.baseUri + ApiEndpoints.passwordReset;

  FetchService("POST", url, onSuccess, onError, body, callbackData);
}

function changePassword(onSuccess, onError, uuid, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string");
  TypeCheck(body, "object");

  const url = FormatUrlV2(ApiEndpoints.passwordResetGet, { uuid });

  FetchService("PUT", url, onSuccess, onError, body, callbackData);
}

export default { get, add, changePassword };
