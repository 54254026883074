import { Button, ButtonDropdown, IconButton, Label } from "@bafsllc/ui-shared";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";
import { CREDIT_ANALYSIS_ACTIONS } from "../../../reducer";
import CreditRatings from "../../../../../services/ApiLib/services/CreditRatings";
import { onError, CREDIT_ANALYSIS_STATUS_TAG_COLOR } from "../../../utils";
import ChangeAnalysisStatusModal from "../../ChangeAnalysisStatusModal";
import { permCheck } from "../../../../../services/Auth";

export default function CreditAnalysisSideSheetHeader({
  onClose,
  saveCreditAnalysis,
  generateDocument
}) {
  const { selectedCreditAnalysis } = useSelector(state => ({
    selectedCreditAnalysis:
      state.CreditAnalysisManagementReducer.selectedCreditAnalysis
  }));

  const dispatch = useDispatch();
  const isEditMode = selectedCreditAnalysis?.mode === "edit";
  const isViewMode = selectedCreditAnalysis?.mode === "view";

  const hasExpirePermission =
    permCheck(["expire_credit_risk_analysis"]) || permCheck(["admin"]);

  const showChangeStatusDropdown =
    selectedCreditAnalysis.is_complete &&
    (selectedCreditAnalysis?.status === "Draft" ||
      selectedCreditAnalysis?.status === "Recommendation" ||
      !selectedCreditAnalysis?.uuid);

  const [selectedStatus, setSelectedStatus] = useState();
  const [actualStatus, setActualStatus] = useState();
  const [newModalOpen, setNewModalOpen] = useState(false);

  const onStatusSelect = e => {
    setActualStatus(e.target.getAttribute("value"));
    setSelectedStatus(e.target.firstChild.value);
    setNewModalOpen(true);
  };

  const statusDropdownOptions = [
    {
      current_status: "Draft",
      list_options: [
        <li name="recommendation" value="Recommendation">
          <FormattedMessage id="PROMOTE_TO_RECOMMENDATION" />
        </li>
      ]
    },
    {
      current_status: "Recommendation",
      list_options: [
        <li name="current" value="Current">
          <FormattedMessage id="PROMOTE_TO_CURRENT" />
        </li>
      ]
    }
  ];

  if (hasExpirePermission) {
    statusDropdownOptions[1].list_options.push(
      <li name="expired" value="Expired">
        <FormattedMessage id="EXPIRE_RECOMMENDATION" />
      </li>
    );
  }

  const onStatusChange = async () => {
    const statusData = { status: actualStatus };
    let creditAnalysisRes = {};
    try {
      creditAnalysisRes = await CreditRatings.updateOne(
        selectedCreditAnalysis.uuid,
        statusData
      );
      dispatch({
        data: { ...creditAnalysisRes.data, mode: "view" },
        type: CREDIT_ANALYSIS_ACTIONS.update
      });
    } catch (err) {
      onError(err);
    }
  };

  return (
    <>
      <ChangeAnalysisStatusModal
        open={newModalOpen}
        setModalIsOpen={setNewModalOpen}
        confirmationAction={onStatusChange}
      />
      <div className="ui grid">
        <div className="one column row pt-4 pr-0 pb-0 pl-4">
          {isViewMode ? (
            <FormattedMessage id="VIEW" />
          ) : (
            <FormattedMessage id={isEditMode ? "EDIT" : "CREATE"} />
          )}
        </div>
        <div className="row">
          <div className="five wide column flex flex-row">
            <h3 className="page-region-title mr-1">
              <FormattedMessage id="CREDIT_RISK_RATING_ANALYSIS" />
            </h3>
            <span>
              <Label
                message={{
                  id: selectedCreditAnalysis?.status
                    ? `CREDIT_ANALYSIS_${selectedCreditAnalysis?.status?.toUpperCase()}_STATUS`
                    : "CREDIT_ANALYSIS_DRAFT_STATUS"
                }}
                color={
                  CREDIT_ANALYSIS_STATUS_TAG_COLOR[
                    selectedCreditAnalysis?.status
                  ]
                }
              />
            </span>
          </div>
          <div className="eight wide column right aligned">
            <IconButton
              disabled={!selectedCreditAnalysis.is_complete}
              onClick={generateDocument}
              iconPosition="left"
              iconLeft={{ icon: "file" }}
              message={{ id: "GENERATE_DOCUMENT" }}
              variant="secondary"
            />

            {showChangeStatusDropdown && (
              <ButtonDropdown
                id="change_status"
                onChildSelect={onStatusSelect}
                selectedValue={selectedStatus}
                onClear={() => {
                  setSelectedStatus(null);
                }}
                button={{ message: { id: "CHANGE_STATUS" } }}
                buttonVariant="secondary"
              >
                {statusDropdownOptions
                  .filter(
                    option =>
                      selectedCreditAnalysis?.status === option.current_status
                  )
                  .map(option => [option.list_options])}
              </ButtonDropdown>
            )}
          </div>
          <div
            className="three wide column right aligned"
            style={{ paddingLeft: "0" }}
          >
            <div>
              {!isViewMode && (
                <Button
                  message={{ id: "SAVE" }}
                  onClick={e => saveCreditAnalysis(e)}
                />
              )}
              <Button message={{ id: "CLOSE" }} onClick={onClose} />
            </div>
          </div>
        </div>
        <div
          className="row"
          style={{ padding: "0 0 16px 16px", alignItems: "center" }}
        >
          <h4 className="page-region-title gray-text">
            <FormattedMessage id="SINGLE_RISK_RATING" />
          </h4>
        </div>
      </div>
      <div className="ui divider" />
    </>
  );
}

CreditAnalysisSideSheetHeader.propTypes = {
  onClose: PropTypes.func,
  saveCreditAnalysis: PropTypes.func.isRequired,
  generateDocument: PropTypes.func.isRequired
};
