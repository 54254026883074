import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";

const fullLimitedOptions = [
  { text: "Full", value: "Full" },
  { text: "Limited", value: "Limited" }
];

function GuarantorTypeDropDownCell({
  cell: { value: guarantor },
  onGuarantorTypeChange,
  readOnly
}) {
  return (
    <Dropdown
      onChange={(event, { value }) => onGuarantorTypeChange(guarantor, value)}
      options={fullLimitedOptions}
      placeholder="--"
      value={guarantor.full_vs_limited || null}
      disabled={!guarantor.relationship_uuid || readOnly}
      style={{ minWidth: "5em" }}
    />
  );
}

GuarantorTypeDropDownCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.any,
    column: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }).isRequired,
  readOnly: PropTypes.bool,
  onGuarantorTypeChange: PropTypes.func.isRequired
};

export default GuarantorTypeDropDownCell;
