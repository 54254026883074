import { TextArea } from "@bafsllc/ui-shared";
import { PropTypes } from "prop-types";
import { useIntl } from "react-intl";

export default function JustificationTextarea({
  onDropdownChange,
  enteredJustification,
  isViewMode
}) {
  const { formatMessage } = useIntl();
  return (
    <TextArea
      labelMessage={{
        id: "JUSTIFICATION"
      }}
      name="justification"
      onChange={e => {
        onDropdownChange({
          name: "enteredJustification",
          value: e.target.value
        });
      }}
      placeholder={formatMessage({
        id: "CREDIT_ANALYSIS_JUSTIFICATION_PLACHOLDER"
      })}
      value={enteredJustification}
      required
      disabled={isViewMode}
    />
  );
}

JustificationTextarea.propTypes = {
  onDropdownChange: PropTypes.func.isRequired,
  enteredJustification: PropTypes.string.isRequired,
  isViewMode: PropTypes.bool.isRequired
};
