import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Table } from "semantic-ui-react";
import { ConcatName } from "../../../../../../../../../../../../services/Entities";
import { relationshipTypes } from "../../../../../../../../../../../../services/Constants/relationshipTypes";
import { prettyDate } from "../../../../../../../../../../../../services/DateTime";
import { toDisplayValue } from "../../../../../../../../../../../../components/CustomFormElements/components/IdentificationNumberInput/identification-number";
import { EditButton } from "../../../../../../../../../../../../components/CustomFormElements";
import EditModal from "../EditModal";

export default function OtherTable({ otherCapacities }) {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editModalOtherCapacity, setEditModalOtherCapacity] = useState({});
  const dispatch = useDispatch();

  const capOptions = [
    { key: "gu", text: "Guarantor", value: "Guarantor" },
    { key: "gh", text: "Guarantee Holder", value: "Guarantee Holder" },
    { key: "c", text: "Co-Signer", value: "Co-Signer" },
    { key: "gr", text: "Grantor", value: "Grantor" },
    { key: "a", text: "Agent", value: "Agent" },
    { key: "aif", text: "Attorney in Fact", value: "Attorney in Fact" },
    { key: "j", text: "Joint Applicant", value: "Joint Applicant" },
    { key: "tb", text: "Trust Beneficiory", value: "Trust Beneficiory" },
    { key: "o", text: "Other", value: "Other" }
  ];

  const othersInputs = [
    {
      name: "title",
      type: "select",
      options: capOptions,
      label: "Relationship Type"
    },
    { name: "start_date", type: "date", label: "Start Date" },
    { name: "end_date", type: "date", label: "End Date" }
  ];

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Relationship Type</Table.HeaderCell>
            <Table.HeaderCell>Start Date</Table.HeaderCell>
            <Table.HeaderCell>End Date</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {otherCapacities.map((otherCapacity, index) => (
            <Table.Row key={"otherCapacity".concat("", index)}>
              <Table.Cell>
                <a
                  href={`/credit_management/entity/${otherCapacity.entity_data.uuid}`}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <i className="linkify icon" />
                  {ConcatName(otherCapacity.entity_data)}
                </a>
              </Table.Cell>
              <Table.Cell>{otherCapacity.title}</Table.Cell>
              <Table.Cell>
                {otherCapacity.start_date
                  ? prettyDate(otherCapacity.start_date)
                  : ""}
              </Table.Cell>
              <Table.Cell>
                {otherCapacity.end_date
                  ? prettyDate(otherCapacity.end_date)
                  : ""}
              </Table.Cell>
              <Table.Cell collapsing>
                <EditButton
                  onClick={() => {
                    setEditModalOtherCapacity({
                      ...otherCapacity,
                      start_date: otherCapacity.start_date || "",
                      end_date: otherCapacity.end_date || ""
                    });
                    setEditModalOpen(true);
                  }}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <EditModal
        relType={relationshipTypes.OTHER_CAPACITIES}
        valid={
          !!(editModalOtherCapacity.start_date && editModalOtherCapacity.title)
        }
        editModalEntity={editModalOtherCapacity}
        setModalEntity={setEditModalOtherCapacity}
        inputs={othersInputs}
        update={entity => {
          const nextOtherCapacities = otherCapacities.map(nextOtherCapacity =>
            nextOtherCapacity.relationship_uuid === entity.relationship_uuid
              ? entity
              : nextOtherCapacity
          );
          dispatch({
            type: "CMS_REL_SET_OTHERS_DETAILS",
            relationshipOthers: nextOtherCapacities
          });
        }}
        open={editModalOpen}
        setModalOpen={setEditModalOpen}
        subHead={`${ConcatName(
          editModalOtherCapacity.entity_data || {}
        )} | ${toDisplayValue(
          editModalOtherCapacity.entity_data
            ? editModalOtherCapacity.entity_data.tin
            : ""
        )}`}
      />
    </>
  );
}

OtherTable.propTypes = {
  otherCapacities: PropTypes.arrayOf(PropTypes.object).isRequired
};
