import React from "react";
import { Grid, Header } from "semantic-ui-react";
import { shape } from "prop-types";

import entityDataProps from "../../../../services/PropTypes/entityData";
import { prettyDate } from "../../../../services/DateTime";
import { ConcatName } from "../../../../services/Entities";
import { getAccountNumber } from "../../../../services/TextFormatting";

function HeaderBlock({ entityData, loanData, institutionData }) {
  return (
    <Grid padded>
      <Grid.Row columns="2">
        <Grid.Column textAlign="left" verticalAlign="bottom" width="10">
          <Header as="h1">{entityData && ConcatName(entityData)}</Header>
          <Header as="h2" size="small">
            {`${loanData.loan_class} - ${loanData.loan_type}${getAccountNumber(
              loanData
            )}`}
          </Header>
        </Grid.Column>
        <Grid.Column textAlign="center" verticalAlign="middle" width="6">
          <Header as="h2" size="medium">
            {institutionData.name}
          </Header>
        </Grid.Column>
      </Grid.Row>
      {loanData.is_month_end && loanData.month_end_date && (
        <Grid.Row color="grey" columns="1">
          <Grid.Column verticalAlign="middle" width="6">
            <Header as="h2" size="medium">{`Viewing the ${prettyDate(
              loanData.month_end_date
            )} Snapshot Data`}</Header>
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
}

HeaderBlock.propTypes = {
  entityData: entityDataProps.isRequired,
  loanData: shape().isRequired,
  institutionData: shape().isRequired
};

export default HeaderBlock;
