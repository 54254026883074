/* eslint-disable camelcase */
import {
  regulatorOptions,
  institutionTypeOptions
} from "../../../../../../services/FormElements/components/Institutions";

const getInputs = ({
  handleCoreOfRecord,
  updateInstitution,
  getTinString,
  updateStore
}) => [
  {
    row: 1,
    inputs: [
      {
        name: "name",
        type: "string",
        label: "Name"
      },

      {
        name: "core_of_record",
        type: "bool",
        label: "Core Of Record",
        set: handleCoreOfRecord,
        update: handleCoreOfRecord
      },
      {
        name: "daily_transaction_file",
        type: "bool",
        label: "Daily Transaction File"
      }
    ]
  },
  {
    row: 2,
    inputs: [
      {
        name: "tax_id",
        type: "string",
        label: "Tax ID",
        max: 10,
        min: 10,
        set: ({ tax_id }) => {
          updateInstitution({ tax_id: getTinString(tax_id) });
        },
        update: ({ tax_id }) => {
          updateStore({
            tax_id: getTinString(tax_id)
          });
        }
      },
      {
        name: "institution_type",
        type: "select",
        label: "Institution Type",
        options: institutionTypeOptions
      }
    ]
  },
  {
    row: 3,
    inputs: [
      {
        name: "routing_number",
        type: "string",
        label: "Routing Number"
      },
      {
        name: "charter_number",
        type: "string",
        label: "Charter Number"
      },
      {
        name: "regulator",
        type: "select",
        label: "Regulator",
        options: regulatorOptions
      }
    ]
  },
  {
    row: 4,
    inputs: [
      {
        name: "institution_primary_address",
        type: "string",
        label: "Institution Primary Address"
      },
      {
        name: "institution_primary_address_2",
        type: "string",
        label: "Institution Primary Address 2"
      }
    ]
  },
  {
    row: 5,
    inputs: [
      {
        name: "institution_city",
        type: "string",
        label: "Institution City"
      },
      {
        name: "institution_state",
        type: "string",
        label: "Institution State"
      },
      {
        name: "institution_postal_code",
        type: "string",
        label: "Institution Postal Code"
      }
    ]
  },
  {
    row: 6,
    inputs: [
      {
        name: "mailing_primary_address",
        type: "string",
        label: "Mailing Primary Address"
      },
      {
        name: "mailing_primary_address_2",
        type: "string",
        label: "Mailing Primary Address 2"
      }
    ]
  },
  {
    row: 7,
    inputs: [
      {
        name: "mailing_city",
        type: "string",
        label: "Mailing City"
      },
      {
        name: "mailing_state",
        type: "string",
        label: "Mailing State"
      },
      {
        name: "mailing_postal_code",
        type: "string",
        label: "Mailing Postal Code"
      },
      {
        name: "non_standard_file",
        type: "bool",
        label: "Non Standard File"
      }
    ]
  }
];
export default getInputs;
