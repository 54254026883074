/* eslint-disable import/prefer-default-export */
import logger from "../../logger";
import { createMessagesPdf } from "./createMessagesPdf";

/**
 * Generate, and possibly prompt the user to save (browser dependent), a PDF
 * file with all the messages related to the `parentUuid`.
 * @param {MessagesToPdfOptions} options
 * @returns {Promise(null | {error: any})}
 */
export async function messagesToPdf({ messages, progress }) {
  progress("render");

  const result = await createMessagesPdf(messages, (percent, status) => {
    logger.debug(`PROGRESS=${percent}, STATUS='${status}'`);
    if (status === "save") {
      progress("save");
    }
  });

  progress("idle");

  return result ?? null;
}

/**
 * @typedef Message
 * @property {string} date
 * @property {string} message
 * @property {string} userName
 */

/**
 * @typedef MessagesToPdfOptions Options for MessagesToPdf.
 * @property {Message[]} messages The data source for the PDF.
 * @property {MessagesToPdfOptionsProgress} [progress] Get progress as the
 * operation proceeds.
 */

/**
 * @callback MessagesToPdfOptionsProgress
 * @param {"idle" | "render" | "save"} status The current state of the
 * operation.
 * @returns {void}
 */
