import { Users, Institutions, Cms, User } from "../../../services/ApiLib";
import logger from "../../../services/logger";

// eslint-disable-next-line import/prefer-default-export
export const getEntityInformation = entityUuid => async dispatch => {
  try {
    const cmsRsp = await Cms.asyncRead(entityUuid);
    dispatch({
      type: "CMS_ENTITY_DATA_LOAD",
      data: cmsRsp.data
    });

    if (cmsRsp.data.loan_officer_uuid) {
      const userInfo = await User.asyncGetUserInfo(
        cmsRsp.data.loan_officer_uuid
      );
      if (userInfo.first_name) {
        dispatch({
          type: "CMS_LOAD_OFFICER",
          name: `${userInfo.first_name} ${userInfo.last_name}`
        });
      } else {
        dispatch({
          type: "CMS_LOAD_OFFICER",
          name: "Officer Not Found"
        });
      }
    } else {
      dispatch({
        type: "CMS_LOAD_OFFICER",
        name: "Officer Not Found"
      });
    }
    const instInfo = await Institutions.asyncRead({
      institutionUuid: cmsRsp.data.institution_uuid
    });
    dispatch({
      type: "CMS_ENTITY_DATA_DATABLOCK_LOOKUP_INSTITUTION",
      instData: instInfo
    });

    const userRsp = await Users.asyncRead({
      institution_uuid: cmsRsp.data.institution_uuid,
      has_lending_authority: true
    });
    dispatch({ type: "CMS_ENTITY_LOAN_OFFICERS", loanOfficers: userRsp.data });
  } catch (err) {
    logger.error(err);
  }
};
