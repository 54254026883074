/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import { getToken } from "../../../services/Auth";
import {
  relationshipTypes,
  relationshipParentChildTypes
} from "../../../services/Constants/relationshipTypes";
import APIEndpoints from "../../../services/ApiEndpoints";
import { RemoveNullKeys } from "../../../services/ApiLib/services/Utilities";
import { actionCreators as LORActions } from "../../../scenes/LOS/scenes/LoanOriginationStepper/reducer";
import { Entities } from "../../../services/ApiLib";
import logger from "../../../services/logger";

export const initialState = {
  activeStep: 0,
  entityDetails: {},
  errorMessage: "",
  entityDebt: {},
  interestSchedules: {},
  loanApp: {},
  loanAppUuid: "",
  loanAppPrimaryRelationship: {},
  loanRequests: {},
  settlementSheets: {},
  paymentSchedules: {},
  borrowers: undefined,
  selectedBorrowerUuid: undefined,
  owners: undefined,
  officers: undefined,
  others: undefined,
  affiliates: undefined,
  holdingsSubsidiaries: undefined,
  guarantors: undefined,
  collateralEntities: undefined,
  collaterals: undefined,
  collateralGrantors: {},
  documents: {
    borrowers: {},
    affiliates: {},
    holdingsSubsidiaries: {},
    ownerGuarantors: {},
    thirdPartyGuarantors: {},
    assets: {},
    owners: {},
    loanRequests: {}
  },
  errors: undefined,

  // refactor - BigAB
  assetsForEntity: undefined
};

const ENTITY_RELATIONSHIP_TYPES_TO_STATE = {};
ENTITY_RELATIONSHIP_TYPES_TO_STATE[relationshipTypes.OWNER] = "owners";
ENTITY_RELATIONSHIP_TYPES_TO_STATE[relationshipTypes.OFFICER] = "officers";
ENTITY_RELATIONSHIP_TYPES_TO_STATE[relationshipTypes.OTHER_CAPACITIES] =
  "others";
ENTITY_RELATIONSHIP_TYPES_TO_STATE[relationshipTypes.AFFILIATE] = "affiliates";

const ENTITY_RELATIONSHIP_TYPES = {};
ENTITY_RELATIONSHIP_TYPES[relationshipTypes.OWNER] = relationshipTypes.OWNER;
ENTITY_RELATIONSHIP_TYPES[relationshipTypes.OFFICER] =
  relationshipTypes.OFFICER;
ENTITY_RELATIONSHIP_TYPES[relationshipTypes.OTHER_CAPACITIES] =
  relationshipTypes.OTHER_CAPACITIES;

const LOAN_RELATIONSHIP_TYPES = {};
LOAN_RELATIONSHIP_TYPES[relationshipTypes.GUARANTOR] =
  relationshipTypes.GUARANTOR;

const RELATIONSHIP_ENTITY_KEYS = {};
RELATIONSHIP_ENTITY_KEYS[relationshipTypes.OWNER] = "owner_entity_data";
RELATIONSHIP_ENTITY_KEYS[relationshipTypes.OFFICER] = "officer_entity_data";
RELATIONSHIP_ENTITY_KEYS[relationshipTypes.OTHER_CAPACITIES] = "entity_data";
// NOTE: RELATIONSHIP_ENTITY_KEYS.other was removed along with the 'Other' relationship type
// "Other" is deprecated, and different than "Other Capacities," which is still valid.

export const LORReducerKey = "LoanOriginationReducer";
export const LORPhaseReducerKey = "LosPhasesReducer";
export const LorSettlementSheetReducer = "LorSettlementSheetReducer";
const relationshipHasBeenRetrieved = (relType, getState) => {
  const relTypeAsState = ENTITY_RELATIONSHIP_TYPES_TO_STATE[relType];

  // borrowers are set to undefined by default, and an array after initial GET
  const relationshipsExistsInState =
    getState()[LORReducerKey] && getState()[LORReducerKey][relTypeAsState];
  return relationshipsExistsInState;
};

export const mergeListByKey = (key, list1 = [], list2 = []) => {
  if ((list1[0] && !(key in list1[0])) || (list2[0] && !(key in list2[0]))) {
    logger.error(`Unable to merge lists. Key "${key}" does not exist.`);
  }
  const oldIds = list1.map(rel => rel[key]);
  const newIds = list2.map(rel => rel[key]);
  return [...new Set([...oldIds, ...newIds])].map(
    id =>
      list2.find(rel => rel[key] === id) || list1.find(rel => rel[key] === id)
  );
};

const { actions, name, reducer } = createSlice({
  name: LORReducerKey,
  initialState,
  reducers: {
    loanAppUuid(state, { payload }) {
      state.loanAppUuid = payload;
    },
    loading(state, { payload }) {
      return { ...state, loading: payload };
    },
    docsReady(state, { payload }) {
      return { ...state, docsReady: payload };
    },
    updateSelectedBorrowerUuid(state, action) {
      const { payload } = action;
      state.selectedBorrowerUuid = payload;
    },
    updateCollateralEntities(state, action) {
      const { payload } = action;
      state.collateralEntities = { ...payload };
    },
    updateBorrower(state, { payload }) {
      return {
        ...state,
        borrowers: state.borrowers.map(b =>
          b.entity.uuid !== payload.uuid ? b : { ...b, entity: payload }
        )
      };
    },
    updateHoldingsSubsidiaries(state, action) {
      const { payload } = action;
      state.holdingsSubsidiaries = payload.entities;
    },
    resetState() {
      return { ...initialState };
    },
    resetEntityRelationships(state) {
      state.owners = [];
      state.officers = [];
      state.others = [];
      state.affiliates = [];
    },
    resetLoanApplicationEntityDetails(state) {
      state.selectedBorrowerUuid = undefined;
      state.borrowers = undefined;
    },
    resetLoanApplicationDocuments(state) {
      state.documents = {
        borrowers: {},
        affiliates: {},
        holdingsSubsidiaries: {},
        ownerGuarantors: {},
        assets: {},
        owners: {},
        loanRequests: {}
      };
    },
    resetAssetsForEntity(state) {
      state.assetsForEntity = undefined;
    },
    showErrors(state, { payload }) {
      Object.values(payload).forEach(err => logger.error(err));
      state.errors = payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase("LOR/DELETE_PAYMENT_SCHEDULE", (state, action) => {
        const paymentSchedules = _.cloneDeep(state.paymentSchedules);
        delete paymentSchedules[action.payload.loanUuid];
        return { ...state, paymentSchedules };
      })

      .addCase("LOR/DELETE_INTEREST_SCHEDULE", (state, action) => {
        const interestSchedules = _.cloneDeep(state.interestSchedules);
        delete interestSchedules[action.payload.loanUuid];
        return { ...state, interestSchedules };
      })

      .addCase("LOR/POPULATE_PAYMENT_SCHEDULES", (state, action) => {
        const paymentSchedules = _.cloneDeep(state.paymentSchedules);
        if (action.payload) {
          paymentSchedules[action.payload.loan_uuid] = action.payload;
        }
        return { ...state, paymentSchedules };
      })

      .addCase("LOR/POPULATE_INTEREST_SCHEDULES", (state, action) => {
        const interestSchedules = _.cloneDeep(state.interestSchedules);
        interestSchedules[action.payload.loan_uuid] = action.payload;
        return { ...state, interestSchedules };
      })

      .addCase("LOR_POPULATE_LOANAPP_UUID", (state, action) => ({
        ...state,
        loanAppUuid: action.loanAppUuid
      }))

      .addCase("LOR_POPULATE_LOANAPP", (state, action) => ({
        ...state,
        loanApp: action.data
      }))

      .addCase("LOR_UPDATE_LOANAPP", (state, action) => ({
        ...state,
        loanApp: { ...state.loanApp, [action.name]: action.value }
      }))

      .addCase("LOR_UPDATE_LOANAPP_MULTI_PROP", (state, action) => ({
        ...state,
        loanApp: { ...state.loanApp, ...action.data }
      }))

      .addCase("LOR_POPULATE_ENTITY_DETAILS", (state, action) => ({
        ...state,
        entityDetails: action.data
      }))

      .addCase("LOR_POPULATE_LOANAPP_RELATIONSHIP", (state, action) => ({
        ...state,
        loanAppPrimaryRelationship: action.data
      }))

      .addCase("LOR_SET_ERROR", (state, action) => ({
        ...state,
        errorMessage: action.errorMessage
      }))

      .addCase("LOR_CLEAR_ERROR", state => ({ ...state, errorMessage: "" }))

      .addCase("LOR_POPULATE_DEBTS", (state, action) => ({
        ...state,
        entityDebt: action.data
      }))

      .addCase("LOR_UPDATE_DOC", (state, action) => {
        const { relType, doc } = action;
        const newDocuments = _.cloneDeep(state.documents);
        if (newDocuments[relType][doc.entityUuid]) {
          const updatedDocs = newDocuments[relType][doc.entityUuid].map(
            document => {
              if (document.uuid === doc.uuid) {
                document = doc;
              }
              return document;
            }
          );
          newDocuments[relType][doc.entityUuid] = updatedDocs;
        } else {
          const updatedDocs = newDocuments[relType][doc.parentUuid].map(
            document => {
              if (document.uuid === doc.uuid) {
                document = doc;
              }
              return document;
            }
          );
          newDocuments[relType][doc.parentUuid] = updatedDocs;
        }

        return { ...state, documents: newDocuments };
      })

      .addCase("LOR_POPULATE_CREDIT_REQUESTS", (state, action) => {
        const loanRequests = { ...state.loanRequests };
        action.data.forEach(request => {
          if (!request.deleted) {
            loanRequests[request.uuid] = { ...request };
          }
        });
        return { ...state, loanRequests };
      })

      .addCase("LOR_SET_BORROWERS", state => ({ ...state }))

      .addCase("LOR_ADD_LOAN", (state, action) => ({
        ...state,
        loanRequests: action.loanRequests,
        loanCount: action.loanCount
      }))

      .addCase("LOR_DELETE_LOAN", (state, action) => {
        const loanRequests = _.cloneDeep(state.loanRequests);
        delete loanRequests[action.loanId];
        return {
          ...state,
          loanRequests
        };
      })

      .addCase("LOR_UPDATE_LOAN_FIELD", (state, action) => {
        const loanRequests = _.cloneDeep(state.loanRequests);
        loanRequests[action.id][action.name] = action.value;
        return {
          ...state,
          loanRequests
        };
      })

      .addCase("LOR_UPDATE_ACTIVE_STEPS", (state, action) => ({
        ...state,
        activeStep: action.activeStepsNum
      }))

      .addCase("LOR_RESET", () => ({ ...initialState }))

      .addCase("loanApplications/updated", (state, { payload = [] }) => {
        state.loanApp = payload;
      })
      .addCase(
        "loanApplications/borrowers/updated",
        (state, { payload = [] }) => {
          state.borrowers = [...payload].sort(b =>
            b.primary_borrower ? -1 : 1
          );
        }
      )
      .addCase(
        "loanApplications/entities/updated",
        (state, { payload, originAction }) => {
          if (originAction.entityType === relationshipTypes.BORROWER) {
            if (state.borrowers) {
              const borrower = state.borrowers.find(
                b => b.child_uuid === payload.uuid
              );
              if (borrower) {
                borrower.entity = payload;
              }
            }
          }
          if (originAction.entityType === relationshipTypes.GUARANTOR) {
            if (state.guarantors) {
              state.guarantors.forEach(b => {
                if (b.entity_uuid === payload.uuid) {
                  b.entity = payload;
                }
              });
            }
          }
          if (originAction.forCollateralGrantors) {
            if (payload) {
              state.collateralGrantors[payload.uuid] = payload;
            }
          }
        }
      )
      .addCase(
        "loanApplications/relationships/update",
        (state, { payload, relType }) => {
          if (relType === relationshipTypes.GUARANTOR) {
            if (state.guarantors) {
              const guarantor = state.guarantors.find(
                b => b.relationship_uuid === payload.identifiers.uuid
              );
              if (guarantor) {
                Object.assign(guarantor, payload.data);
              }
            }
          }
        }
      )
      .addCase(
        "loanApplications/entityRelationships/updated",
        (state, { payload, originAction }) => {
          const {
            payload: {
              identifiers: { entity_uuid, rel_type }
            }
          } = originAction;
          const newRelationships = (
            rel_type === relationshipTypes.AFFILIATE
              ? _.flatMap(
                  payload.filter(
                    // TODO: review this filter, copied from old, doesn't look right - BigAB
                    a => a.affiliate_data[0][1] !== 404
                  ),
                  a => a.affiliate_data
                ).map(
                  // There is no relationship_uuid key on affiliate relationships, so
                  // we must create one that is unique to the affiliate/affiliation
                  // by combining their UUIDs.
                  a => ({ ...a, relationship_uuid: a.uuid + a.entity_uuid })
                )
              : payload
          ).map(rel => ({ ...rel, borrowerUuid: entity_uuid }));
          const oldRelationships =
            state[ENTITY_RELATIONSHIP_TYPES_TO_STATE[rel_type]];
          state[ENTITY_RELATIONSHIP_TYPES_TO_STATE[rel_type]] = mergeListByKey(
            "relationship_uuid",
            oldRelationships,
            newRelationships
          );
        }
      )
      .addCase(
        "loanApplications/holdingsSubsidiaries/updated",
        (state, { payload = [] }) => {
          state.holdingsSubsidiaries = [];
          if (payload.length > 0) {
            const entities = payload.map(async res => {
              const fetchedEntity = await Entities.read({
                entityUuid: res.parent_uuid
              });
              fetchedEntity.data.child_uuid = res.child_uuid;
              return fetchedEntity.data;
            });

            state.holdingsSubsidiaries.push(entities);
          }
        }
      )
      .addCase(
        "loanApplications/relationships/deleted",
        (state, { originAction }) => {
          if (originAction.relType === relationshipTypes.BORROWER) {
            state.borrowers = state.borrowers.filter(
              b => b.uuid !== originAction.payload.identifiers.uuid
            );
            return;
          }
          if (originAction.relType === relationshipTypes.GUARANTOR) {
            state.guarantors = state.guarantors.filter(
              g => g.relationship_uuid !== originAction.payload.identifiers.uuid
            );
            return;
          }
          Object.keys(ENTITY_RELATIONSHIP_TYPES).forEach(relType => {
            if (
              state[ENTITY_RELATIONSHIP_TYPES_TO_STATE[relType]].find(
                rel =>
                  rel.relationship_uuid ===
                  originAction.payload.identifiers.uuid
              )
            ) {
              state[ENTITY_RELATIONSHIP_TYPES_TO_STATE[relType]] = state[
                ENTITY_RELATIONSHIP_TYPES_TO_STATE[relType]
              ].filter(
                rel =>
                  rel.relationship_uuid !==
                  originAction.payload.identifiers.uuid
              );
            }
          });
        }
      )
      .addCase(
        "loanApplications/loansRelationships/updated",
        (state, { payload, originAction }) => {
          const {
            payload: {
              identifiers: { uuid: loanRequestUuid }
            }
          } = originAction;
          const newRelationships = payload.map(rel => ({
            ...rel,
            loanRequestUuid
          }));
          const oldRelationships = state.guarantors;
          state.guarantors = mergeListByKey(
            "relationship_uuid",
            oldRelationships,
            newRelationships
          );
        }
      )
      .addCase(
        "loanApplications/collaterals/updated",
        (state, { payload = [], originAction }) => {
          const {
            payload: { identifiers }
          } = originAction;
          if (!state.collaterals) {
            state.collaterals = {};
          }
          state.collaterals = {
            ...state.collaterals,
            [identifiers.loan_uuid]: payload
          };
        }
      )
      // Sync assets between LOR Collateral Reducer and Loan Origination Reducer
      .addCase(
        "loanApplications/collateral/sync_assets",
        (state, { payload }) => {
          const { asset, uuid } = payload;
          if (!state.collaterals) {
            state.collaterals = {};
          }
          // Find the collateral with the loan uuid and only change the collateral that
          // has the corresponding asset_uuid
          const loanUuids = Object.keys(state.collaterals);
          if (Array.isArray(loanUuids)) {
            loanUuids.forEach(loan_uuid => {
              if (state.collaterals[loan_uuid]) {
                const collaterals = state.collaterals[loan_uuid];
                const newCollaterals = collaterals.map(collateral =>
                  collateral.asset_uuid === uuid
                    ? { ...collateral, asset }
                    : collateral
                );
                state.collaterals = {
                  ...state.collaterals,
                  [loan_uuid]: newCollaterals
                };
              }
            });
          }
        }
      )
      // This is to sync collaterals between LOR Collateral Reducer and Loan Origination Reducer
      .addCase(
        "loanApplications/collateral/sync_collaterals",
        (state, { payload }) => {
          const { collaterals, loan_uuid } = payload;
          if (!state.collaterals) {
            state.collaterals = {};
          }
          if (state.collaterals[loan_uuid]) {
            state.collaterals = {
              ...state.collaterals,
              [loan_uuid]: collaterals
            };
          }
        }
      )
      .addCase(
        "loanApplications/collateral/delete_collateral",
        (state, { payload }) => {
          const { collatUuid, loanReqUuid } = payload;
          if (!state.collaterals) {
            return;
          }

          // Find the collateral with the loan uuid and only change the collateral that
          // has the corresponding collateralUuid
          if (state.collaterals[loanReqUuid]) {
            const collaterals = state.collaterals[loanReqUuid];
            const newCollaterals = collaterals.filter(
              collateral => collateral.uuid !== collatUuid
            );
            state.collaterals = {
              ...state.collaterals,
              [loanReqUuid]: newCollaterals
            };
          }
        }
      )
      .addCase(
        "loanApplications/assets/updated",
        (state, { payload, originAction }) => {
          if (!Array.isArray(payload) && state.collaterals) {
            const collaterals = state.collaterals[originAction.loanUuid];
            const collateral = collaterals.find(
              c => c.asset_uuid === payload.uuid
            );
            if (collateral) {
              collateral.asset = payload;
            }
          }
          if (originAction.forEntity) {
            state.assetsForEntity = payload;
          }
        }
      )
      .addCase(
        "loanApplications/documents/updated",
        (state, { payload, originAction }) => {
          const {
            type,
            relType,
            payload: { identifiers }
          } = originAction;
          if (!state.documents[relType]) {
            state.documents[relType] = {};
          }
          const parentUuid = identifiers.parentUuid
            ? identifiers.parentUuid
            : identifiers.entityUuid;
          if (type === "loanApplications/documents/retrieve") {
            const docs = payload.map(doc => ({
              ...doc,
              httpHeaders: {
                Authorization: getToken()
              },
              url: `${APIEndpoints.baseUri}/objects/v1/object/${doc.uuid}`
            }));
            if (parentUuid) {
              state.documents[relType][parentUuid] = docs;
            }
          }
          if (type === "loanApplications/documents/update") {
            const nextParentUuid = payload.parentUuid
              ? payload.parentUuid
              : payload.entityUuid;
            const docs = state.documents[relType][nextParentUuid];
            state.documents[relType][nextParentUuid] = docs.map(doc =>
              doc.uuid === identifiers.uuid ? { ...doc, ...payload } : doc
            );
          }
        }
      )
      .addCase(
        "loanApplications/analysts/updated",
        (state, { payload: { first_name, last_name }, originAction }) => {
          state.analysts = state.analysts || {};
          state.analysts[originAction.payload.identifiers.uuid] = {
            firstName: first_name,
            lastName: last_name
          };
        }
      )
      .addCase("loanApplications/analysts/retrieving", (state, { payload }) => {
        state.analysts = state.analysts || {};
        state.analysts[payload.identifiers.uuid] = {};
      });
  }
});

// Utils
const apiAction = (
  type,
  service,
  identifiers,
  data,
  meta,
  extraProperties = {}
) => {
  const action = {
    type: `${service}/${type}`,
    payload: {}
  };
  if (identifiers !== undefined) action.payload.identifiers = identifiers;
  if (data) action.payload.data = data;
  if (meta) action.meta = meta;
  return { ...action, ...extraProperties };
};

// SELECTORS
const sliceSelector = state => state[LORReducerKey];
const settlementSheetSelector = state => state[LorSettlementSheetReducer];
const phaseDataSelector = state => state[LORPhaseReducerKey];
export const loanApplicationDetailsSelector = sliceSelector;
export const loanAppUuidSelector = createSelector(
  sliceSelector,
  details => details.loanAppUuid
);
export const phaseLoanAppSelector = createSelector(
  phaseDataSelector,
  details => details.loanApp
);
export const loanAppSelector = createSelector(
  sliceSelector,
  details => details.loanApp
);
export const borrowersSelector = createSelector(
  sliceSelector,
  details => details.borrowers
);
export const selectedBorrowerUuidSelector = createSelector(
  sliceSelector,
  details => details.selectedBorrowerUuid
);
export const ownersSelector = createSelector(
  sliceSelector,
  details => details.owners
);
export const officersSelector = createSelector(
  sliceSelector,
  details => details.officers
);
export const otherCapacitiesSelector = createSelector(
  sliceSelector,
  details => details.others
);
export const affiliatesSelector = createSelector(
  sliceSelector,
  details => details.affiliates
);
export const guarantorsSelector = createSelector(
  sliceSelector,
  details => details.guarantors
);
export const holdingsSubsidiariesSelector = createSelector(
  sliceSelector,
  details => details.holdingsSubsidiaries
);
export const selectedBorrowerOwnersSelector = createSelector(
  [borrowersSelector, selectedBorrowerUuidSelector, ownersSelector],
  // eslint-disable-next-line default-param-last
  (borrowers = [], selectedBorrowerUuid, owners = []) => {
    const selected = borrowers.find(b => b.uuid === selectedBorrowerUuid);
    if (selected) {
      return owners.filter(owner => owner.borrowerUuid === selected.child_uuid);
    }
    return [];
  }
);
export const selectedBorrowerHoldingsSubsidiariesSelector = createSelector(
  [
    borrowersSelector,
    selectedBorrowerUuidSelector,
    holdingsSubsidiariesSelector
  ],
  // eslint-disable-next-line default-param-last
  (borrowers = [], selectedBorrowerUuid, holdingsSubsidiaries = []) => {
    const selected = borrowers.find(b => b.uuid === selectedBorrowerUuid);
    if (selected) {
      return holdingsSubsidiaries.filter(
        holdingSubsidiary =>
          holdingSubsidiary.child_uuid === selected.child_uuid
      );
    }
    return [];
  }
);
export const selectedBorrowerOfficersSelector = createSelector(
  [borrowersSelector, selectedBorrowerUuidSelector, officersSelector],
  // eslint-disable-next-line default-param-last
  (borrowers = [], selectedBorrowerUuid, officers = []) => {
    const selected = borrowers.find(b => b.uuid === selectedBorrowerUuid);
    if (selected) {
      return officers.filter(
        officer => officer.borrowerUuid === selected.child_uuid
      );
    }
    return [];
  }
);
export const selectedBorrowerOtherCapacitiesSelector = createSelector(
  [borrowersSelector, selectedBorrowerUuidSelector, otherCapacitiesSelector],
  // eslint-disable-next-line default-param-last
  (borrowers = [], selectedBorrowerUuid, otherCapacities = []) => {
    const selected = borrowers.find(b => b.uuid === selectedBorrowerUuid);
    if (selected) {
      return otherCapacities.filter(
        other => other.borrowerUuid === selected.child_uuid
      );
    }
    return [];
  }
);
export const collateralGrantorsSelector = createSelector(
  sliceSelector,
  details => details.collateralGrantors
);
export const selectedBorrowerAffiliatesSelector = createSelector(
  [borrowersSelector, selectedBorrowerUuidSelector, affiliatesSelector],
  // eslint-disable-next-line default-param-last
  (borrowers = [], selectedBorrowerUuid, affiliates = []) => {
    const selected = borrowers.find(b => b.uuid === selectedBorrowerUuid);
    if (selected) {
      return affiliates.filter(
        affiliate => affiliate.borrowerUuid === selected.child_uuid
      );
    }
    return [];
  }
);
export const loanRequestsSelector = createSelector(sliceSelector, details => {
  if (details && details.loanRequests) {
    return Object.values(details.loanRequests).filter(
      loanRequest => !loanRequest.deleted
    );
  }
  return undefined;
});
export const collateralsSelector = createSelector(
  sliceSelector,
  details => details.collaterals
);
export const settlementSheet = createSelector(
  settlementSheetSelector,
  details => details.settlementSheets
);
export const documentsSelector = createSelector(
  sliceSelector,
  details => details.documents
);
export const assetsForEntitySelector = createSelector(
  sliceSelector,
  details => details.assetsForEntity
);
export const loadingSelector = createSelector(
  sliceSelector,
  details => details.loading
);
export const docsReadySelector = createSelector(
  sliceSelector,
  details => details.docsReady
);
export const errorsSelector = createSelector(
  sliceSelector,
  details => details.errors
);
export const analystsSelector = createSelector(
  sliceSelector,
  details => details.analysts
);

export const selectors = {
  loanAppUuidSelector,
  loanAppSelector,
  borrowersSelector,
  selectedBorrowerUuidSelector,
  ownersSelector,
  officersSelector,
  otherCapacitiesSelector,
  affiliatesSelector,
  collateralGrantorsSelector,
  holdingsSubsidiariesSelector,
  errorsSelector,
  loadingSelector
};

// OTHER ACTIONS
const asyncActions = {
  // ...LORActions, // <-- Problem
  retrieveLoanApp:
    ({ loanAppUuid: uuid }) =>
    async (dispatch, getState) => {
      const loanApp = loanAppSelector(getState());
      if (!loanApp || (loanApp.uuid && loanApp.uuid.length === 0)) {
        dispatch(actions.loading(true));
        await dispatch(LORActions.loadLoanAppAsync(uuid));
        if (!loanAppSelector(getState())) {
          await dispatch({
            errorMessage:
              "The selected loan application couldn't be found. Go to the LOS Current Requests table and try selecting the loan application there.",
            type: "ERRORS_REPORT"
          });
        }
      }
      return undefined;
    },
  retrieveBorrowers:
    ({ loanAppUuid }) =>
    async (dispatch, getState) => {
      if (!borrowersSelector(getState())) {
        await dispatch(
          apiAction("retrieve", "loanApplications/borrowers", { loanAppUuid })
        );
        const borrowers = borrowersSelector(getState());
        if (borrowers && borrowers.length) {
          return Promise.all(
            borrowers.map(
              borrower =>
                !borrower.entity &&
                dispatch({
                  type: "loanApplications/entities/retrieve",
                  payload: {
                    identifiers: { uuid: borrower.child_uuid }
                  },
                  entityType: relationshipTypes.BORROWER
                })
            )
          );
        }
      }
      return undefined;
    },
  retrieveAllAffiliates: relationships => async dispatch => {
    const newRelationships = relationships.flat().map(async relationship =>
      dispatch(
        apiAction("retrieve", "loanApplications/entityRelationships", {
          entity_uuid: relationship.child_uuid || relationship.entity_uuid,
          rel_type: relationshipTypes.AFFILIATE
        })
      )
    );

    await Promise.all(newRelationships);
  },
  retrieveHoldingSubsidiaries: relationships => async (dispatch, getState) => {
    if (!holdingsSubsidiariesSelector(getState())) {
      const allEntities = relationships.flat().map(async relationship => {
        await dispatch(
          apiAction("retrieve", "loanApplications/holdingsSubsidiaries", {
            childUuid: relationship.child_uuid || relationship.entity_uuid
          })
        );

        const holdingsSubsidiaries = holdingsSubsidiariesSelector(
          getState()
        ).flat();
        if (holdingsSubsidiaries) {
          const entities = (await Promise.all(holdingsSubsidiaries)).map(
            holdingSubsidiary => holdingSubsidiary
          );

          if (entities) {
            return entities;
          }
        }
        return undefined;
      });

      if (allEntities) {
        const allHoldingsSubsidiaries = await Promise.all(allEntities);
        return dispatch(
          actions.updateHoldingsSubsidiaries({
            entities: allHoldingsSubsidiaries.flat()
          })
        );
      }
    }
    return undefined;
  },
  retrieveLoanApplicationEntityDetails:
    ({ loanAppUuid }) =>
    async (dispatch, getState) => {
      try {
        const loanAppFetched = dispatch(
          asyncActions.retrieveLoanApp({ loanAppUuid })
        );
        const borrowerEntityFetched = dispatch(
          asyncActions.retrieveBorrowers({ loanAppUuid })
        );
        // make sure this is all done before resolving
        await Promise.all([loanAppFetched, borrowerEntityFetched]);

        const borrowers = borrowersSelector(getState());

        // Request each entityRelationship type
        await dispatch(asyncActions.retrieveEntityRelationships({ borrowers }));
        await dispatch(asyncActions.retrieveLoanRequests({ loanAppUuid }));
        const guarantors = guarantorsSelector(getState());
        const owners = ownersSelector(getState());
        const ownerEntityUuids = owners.map(
          owner => owner.owner_entity_data && owner.owner_entity_data.uuid
        );
        const ownerGuarantors = guarantors.filter(g =>
          ownerEntityUuids.includes(g.entity_uuid)
        );
        const thirdPartyGuarantors =
          guarantors.filter(g => !ownerEntityUuids.includes(g.entity_uuid)) ||
          [];
        await dispatch(
          asyncActions.retrieveAllAffiliates([
            borrowers,
            ownerGuarantors,
            thirdPartyGuarantors
          ])
        );
        await dispatch(
          asyncActions.retrieveHoldingSubsidiaries([
            borrowers,
            thirdPartyGuarantors
          ])
        );
      } catch (err) {
        dispatch(actions.showErrors({ general: err }));
      } finally {
        dispatch(actions.loading(false));
      }
    },
  addBorrower:
    ({ entityUuid }) =>
    async (dispatch, getState) => {
      const loanApp = _.isEmpty(loanAppSelector(getState()))
        ? phaseLoanAppSelector(getState())
        : loanAppSelector(getState());
      await dispatch({
        type: "loanApplications/relationships/create",
        payload: {
          data: {
            child_uuid: entityUuid,
            institution_uuid: loanApp.institution_uuid,
            parent_uuid: loanApp.uuid,
            parent_type: relationshipParentChildTypes.LOAN,
            child_type: relationshipParentChildTypes.ENTITY,
            rel_type: relationshipTypes.BORROWER
          }
        }
      });
      await dispatch(
        apiAction("retrieve", "loanApplications/borrowers", {
          loanAppUuid: loanApp.uuid
        })
      );
      const borrowers = borrowersSelector(getState());
      if (borrowers && borrowers.length) {
        dispatch(
          actions.updateSelectedBorrowerUuid(
            borrowers[borrowers.length - 1].uuid
          )
        );
        await Promise.all(
          borrowers.map(
            borrower =>
              !borrower.entity &&
              dispatch({
                type: "loanApplications/entities/retrieve",
                payload: {
                  identifiers: { uuid: borrower.child_uuid }
                },
                entityType: relationshipTypes.BORROWER
              })
          )
        );
      }
    },
  removeBorrower:
    ({ relationship_uuid }) =>
    async dispatch => {
      await dispatch({
        type: "loanApplications/relationships/delete",
        payload: {
          identifiers: {
            uuid: relationship_uuid
          }
        },
        relType: relationshipTypes.BORROWER
      });
    },
  updatePrimaryBorrower:
    ({ uuid: selectedUuid }) =>
    async (dispatch, getState) => {
      const state = getState();
      /** @type {any[]} */
      const borrowers = borrowersSelector(state);
      if (!borrowers) {
        return;
      }
      const selectedPrimaryIndex = borrowers.findIndex(
        b => b.uuid === selectedUuid
      );
      const currentPrimaryIndex = borrowers.findIndex(b => b.primary_borrower);

      if (
        borrowers[currentPrimaryIndex].uuid ===
        borrowers[selectedPrimaryIndex].uuid
      ) {
        return;
      }

      // eslint-disable-next-line no-use-before-define
      const formerPrimary = await dispatchLoanApplicationsRelationshipUpdate(
        dispatch,
        borrowers[currentPrimaryIndex]
      );

      // eslint-disable-next-line no-use-before-define
      const nextPrimary = await dispatchLoanApplicationsRelationshipUpdate(
        dispatch,
        borrowers[selectedPrimaryIndex],
        true
      );

      await dispatch({
        payload: borrowers.map((borrower, index) => {
          if (index === selectedPrimaryIndex) {
            return nextPrimary;
          }
          if (index === currentPrimaryIndex) {
            return formerPrimary;
          }

          return borrower;
        }),
        type: "loanApplications/borrowers/updated"
      });
    },

  retrieveEntityRelationships:
    ({ borrowers = [] }) =>
    async (dispatch, getState) => {
      const entityRelationshipRetrievals = _.flatMap(
        Object.keys(ENTITY_RELATIONSHIP_TYPES),
        relType =>
          borrowers.map(borrower => {
            if (relationshipHasBeenRetrieved(relType, getState)) {
              return null;
            }
            return dispatch(
              apiAction("retrieve", "loanApplications/entityRelationships", {
                entity_uuid: borrower.child_uuid,
                rel_type: relType
              })
            );
          })
      );
      await Promise.all(entityRelationshipRetrievals);
    },

  refreshEntityRelationships:
    ({ borrowers }) =>
    async (dispatch, getState) =>
      Promise.all(
        _.flatMap(Object.keys(ENTITY_RELATIONSHIP_TYPES), relType =>
          borrowers.map(borrower => {
            if (relationshipHasBeenRetrieved(relType, getState)) {
              return null;
            }
            return dispatch(
              apiAction("retrieve", "loanApplications/entityRelationships", {
                entity_uuid: borrower.child_uuid,
                rel_type: relType
              })
            );
          })
        )
      ),

  addEntityRelationship:
    ({ entityUuid, relType }) =>
    async (dispatch, getState) => {
      const state = getState();
      const loanApp = _.isEmpty(loanAppSelector(getState()))
        ? phaseLoanAppSelector(getState())
        : loanAppSelector(getState());
      const selectedBorrowerUuid = selectedBorrowerUuidSelector(state);
      const borrowers = borrowersSelector(state);
      const selectedBorrower = borrowers.find(
        b => b.uuid === selectedBorrowerUuid
      );
      await dispatch({
        type: "loanApplications/relationships/create",
        payload: {
          data: {
            child_uuid: entityUuid,
            institution_uuid: loanApp.institution_uuid,
            parent_uuid: selectedBorrower.entity.uuid,
            parent_type: relationshipParentChildTypes.ENTITY,
            child_type: relationshipParentChildTypes.ENTITY,
            rel_type: ENTITY_RELATIONSHIP_TYPES[relType]
          }
        }
      });
      // refresh entity relationships for that type
      await dispatch(
        apiAction("retrieve", "loanApplications/entityRelationships", {
          entity_uuid: selectedBorrower.child_uuid,
          rel_type: relType
        })
      );
    },
  updateEntityRelationship:
    (data, { relationship_uuid: uuid }, relType) =>
    async (dispatch, getState) => {
      const state = getState();
      const selectedBorrowerUuid = selectedBorrowerUuidSelector(state);
      const borrowers = borrowersSelector(state);
      const selectedBorrower = borrowers.find(
        b => b.uuid === selectedBorrowerUuid
      );

      if (data.percent_owned) {
        data.percent_owned = parseFloat(data.percent_owned);
      }

      const newData = RemoveNullKeys(data);
      delete newData[RELATIONSHIP_ENTITY_KEYS[relType]];
      delete newData.relationship_uuid;
      delete newData.borrowerUuid;
      await dispatch({
        type: "loanApplications/relationships/update",
        payload: {
          data: { ...newData, uuid },
          identifiers: {
            uuid
          }
        }
      });
      // refresh entity relationships for that type
      await dispatch(
        apiAction("retrieve", "loanApplications/entityRelationships", {
          entity_uuid: selectedBorrower.child_uuid,
          rel_type: relType
        })
      );
    },
  removeEntityRelationship:
    ({ relationship_uuid }) =>
    async dispatch => {
      await dispatch({
        type: "loanApplications/relationships/delete",
        payload: {
          identifiers: {
            uuid: relationship_uuid
          }
        }
      });
    },

  retrieveLoanRequests:
    ({ loanAppUuid }) =>
    async (dispatch, getState) => {
      let loanRequests = loanRequestsSelector(getState());
      if (!loanRequests || loanRequests.length === 0) {
        loanRequests = await dispatch(
          LORActions.readCreditRequests(loanAppUuid)
        );
      }
      try {
        let collaterals = collateralsSelector(getState());
        if (!collaterals) {
          await dispatch(asyncActions.retrieveCollaterals({ loanRequests }));
          collaterals = collateralsSelector(getState());
        }
        let guarantors = guarantorsSelector(getState());

        let guarantorsFetched = [];
        if (!guarantors) {
          guarantorsFetched = await Promise.all(
            loanRequests.map(loanRequest =>
              dispatch(
                apiAction("retrieve", "loanApplications/loansRelationships", {
                  uuid: loanRequest.uuid,
                  rel_type: relationshipTypes.GUARANTOR
                })
              )
            )
          ).then(async () => {
            guarantors = guarantorsSelector(getState());
            if (guarantors) {
              await Promise.all(
                guarantors.map(guarantor =>
                  dispatch({
                    type: "loanApplications/entities/retrieve",
                    payload: {
                      identifiers: { uuid: guarantor.entity_uuid }
                    },
                    entityType: relationshipTypes.GUARANTOR
                  })
                )
              );
            }
          });
        }

        if (!borrowersSelector(getState())) {
          await dispatch(asyncActions.retrieveBorrowers({ loanAppUuid }));
        }
        const borrowers = borrowersSelector(getState());
        const owners = ownersSelector(getState());

        if (borrowers && !owners) {
          const ownersFetched = borrowers.map(borrower =>
            dispatch(
              apiAction("retrieve", "loanApplications/entityRelationships", {
                entity_uuid: borrower.child_uuid,
                rel_type: relationshipTypes.OWNER
              })
            )
          );
          // make sure this is all done before resolving
          await Promise.all([...ownersFetched, guarantorsFetched]);
        }
      } catch (err) {
        dispatch(actions.showErrors({ general: err }));
      } finally {
        dispatch(actions.loading(false));
      }
    },

  setIsGuarantor:
    ({ entity, loanRequestUuid, relationship_uuid }, value) =>
    async (dispatch, getState) => {
      if (value === true) {
        const data = {
          parent_uuid: loanRequestUuid,
          child_uuid: entity.uuid,
          institution_uuid: entity.institution_uuid,

          parent_type: relationshipParentChildTypes.LOAN,
          child_type: relationshipParentChildTypes.ENTITY,
          rel_type: relationshipTypes.GUARANTOR,
          is_guarantor: true
        };
        await dispatch(
          apiAction("create", "loanApplications/relationships", {}, data)
        );

        await dispatch(
          apiAction("retrieve", "loanApplications/loansRelationships", {
            uuid: loanRequestUuid,
            rel_type: relationshipTypes.GUARANTOR
          })
        );

        const guarantors = guarantorsSelector(getState());
        if (guarantors) {
          await Promise.all(
            guarantors.map(guarantor => {
              if (!guarantor.entity) {
                dispatch({
                  type: "loanApplications/entities/retrieve",
                  payload: {
                    identifiers: { uuid: guarantor.entity_uuid }
                  },
                  entityType: relationshipTypes.GUARANTOR
                });
              }
              return undefined;
            })
          );
        }
      }
      if (value === false) {
        await dispatch({
          type: "loanApplications/relationships/delete",
          payload: {
            identifiers: {
              uuid: relationship_uuid
            }
          },
          relType: relationshipTypes.GUARANTOR
        });
      }
    },

  updateGuarantor:
    (data, { relationship_uuid: uuid }) =>
    async dispatch => {
      await dispatch({
        type: "loanApplications/relationships/update",
        payload: {
          data,
          identifiers: { uuid }
        },
        relType: relationshipTypes.GUARANTOR
      });
    },

  retrieveCollaterals:
    ({ loanRequests }) =>
    async (dispatch, getState) => {
      let collaterals = collateralsSelector(getState());
      if (!collaterals) {
        await Promise.all(
          loanRequests.map(loanRequest =>
            dispatch(
              apiAction("retrieve", "loanApplications/collaterals", {
                loan_uuid: loanRequest.uuid,
                page_number: 0,
                page_size: 9999
              })
            )
          )
        );
        collaterals = collateralsSelector(getState());
      }

      if (collaterals) {
        await Promise.all(
          Object.entries(collaterals).map(
            ([loanUuid, collateralsForLoanRequest]) =>
              collateralsForLoanRequest &&
              collateralsForLoanRequest.map(collateral =>
                dispatch(
                  apiAction(
                    "retrieve",
                    "loanApplications/assets",
                    {
                      uuid: collateral.asset_uuid
                    },
                    null,
                    null,
                    { loanUuid }
                  )
                )
              )
          )
        );
      }
      return undefined;
    },

  retrieveLoanApplicationDocument:
    (identifiers, relType) => async (dispatch, getState) => {
      let documents = documentsSelector(getState());
      await dispatch(
        apiAction(
          "retrieve",
          "loanApplications/documents",
          identifiers,
          null,
          null,
          { relType }
        )
      );

      const parentUuid = identifiers.parentUuid
        ? identifiers.parentUuid
        : identifiers.entityUuid;
      documents = documentsSelector(getState());
      const analysts = analystsSelector(getState()) || {};
      const entityDocuments = documents[relType][parentUuid] || {};
      const uploadedBy = Object.entries(entityDocuments).reduce(
        (output, [, value]) => {
          if (
            value.uploadedBy &&
            !analysts[value.uploadedBy] &&
            !output.includes(value.uploadedBy)
          ) {
            output.push(value.uploadedBy);
          }
          return output;
        },
        []
      );

      if (!uploadedBy.length) {
        return;
      }

      uploadedBy.forEach(uuid =>
        dispatch(asyncActions.retrieveAnalyst({ uuid }))
      );
    },
  retrieveCollateralGrantors: grantorUuids => async dispatch => {
    if (grantorUuids?.length) {
      return Promise.all(
        grantorUuids.map(uuid =>
          dispatch(
            apiAction(
              "retrieve",
              "loanApplications/entities",
              { uuid },
              null,
              null,
              { forCollateralGrantors: true }
            )
          )
        )
      );
    }
    return undefined;
  },
  retrieveLoanApplicationDocuments:
    ({ loanAppUuid }) =>
    async (dispatch, getState) => {
      try {
        await dispatch(
          asyncActions.retrieveLoanApplicationEntityDetails({ loanAppUuid })
        );
        const loanApp = loanAppSelector(getState());
        const borrowers = borrowersSelector(getState());
        const collaterals = collateralsSelector(getState()) || {};
        const assets = Object.values(collaterals).flat();
        await dispatch(
          asyncActions.retrieveCollateralGrantors(
            assets
              .filter(coll => coll.has_grantor)
              .map(coll => coll?.asset?.entity_uuid)
          )
        );
        const affiliates = affiliatesSelector(getState());
        const guarantors = guarantorsSelector(getState()) || [];
        const owners = ownersSelector(getState()) || [];
        const holdingsSubsidiaries =
          holdingsSubsidiariesSelector(getState()) || [];
        const ownerEntityUuids = owners.map(
          owner => owner.owner_entity_data && owner.owner_entity_data.uuid
        );
        const ownerGuarantors = guarantors.filter(g =>
          ownerEntityUuids.includes(g.entity_uuid)
        );
        const thirdPartyGuarantors = guarantors.filter(
          g => !ownerEntityUuids.includes(g.entity_uuid)
        );
        const loanRequests = loanRequestsSelector(getState());
        await Promise.all([
          Promise.all(
            borrowers.map(borrower =>
              Promise.resolve(
                dispatch(
                  asyncActions.retrieveLoanApplicationDocument(
                    {
                      entityUuid: borrower.child_uuid,
                      parentUuid: borrower.child_uuid,
                      institutionUuid: loanApp.institution_uuid,
                      page_size: 9999,
                      page_number: 0
                    },
                    "borrowers"
                  )
                )
              )
            )
          ),
          Promise.all(
            affiliates.map(affiliate =>
              Promise.resolve(
                dispatch(
                  asyncActions.retrieveLoanApplicationDocument(
                    {
                      entityUuid: affiliate.uuid,
                      parentUuid: affiliate.uuid,
                      institutionUuid: loanApp.institution_uuid,
                      page_size: 9999,
                      page_number: 0
                    },
                    "affiliates"
                  )
                )
              )
            )
          ),
          Promise.all(
            holdingsSubsidiaries.map(holdingSubsidiary =>
              Promise.resolve(
                dispatch(
                  asyncActions.retrieveLoanApplicationDocument(
                    {
                      entityUuid: holdingSubsidiary.uuid,
                      parentUuid: holdingSubsidiary.uuid,
                      institutionUuid: loanApp.institution_uuid,
                      page_size: 9999,
                      page_number: 0
                    },
                    "holdingsSubsidiaries"
                  )
                )
              )
            )
          ),
          Promise.all(
            assets.map(collateral =>
              Promise.resolve(
                dispatch(
                  asyncActions.retrieveLoanApplicationDocument(
                    {
                      parentUuid: collateral.asset_uuid,
                      institutionUuid: loanApp.institution_uuid,
                      page_size: 9999,
                      page_number: 0
                    },
                    "assets"
                  )
                )
              )
            )
          ),
          Promise.all(
            owners.map(owner =>
              Promise.resolve(
                dispatch(
                  asyncActions.retrieveLoanApplicationDocument(
                    {
                      entityUuid: owner.owner_entity_data.uuid,
                      parentUuid: owner.owner_entity_data.uuid,
                      institutionUuid: loanApp.institution_uuid,
                      page_size: 9999,
                      page_number: 0
                    },
                    "owners"
                  )
                )
              )
            )
          ),
          Promise.all(
            ownerGuarantors.map(ownerGuarantor =>
              Promise.resolve(
                dispatch(
                  asyncActions.retrieveLoanApplicationDocument(
                    {
                      entityUuid: ownerGuarantor.entity_uuid,
                      parentUuid: ownerGuarantor.entity_uuid,
                      institutionUuid: loanApp.institution_uuid,
                      page_size: 9999,
                      page_number: 0
                    },
                    "ownerGuarantors"
                  )
                )
              )
            )
          ),
          Promise.all(
            thirdPartyGuarantors.map(ownerGuarantor =>
              Promise.resolve(
                dispatch(
                  asyncActions.retrieveLoanApplicationDocument(
                    {
                      entityUuid: ownerGuarantor.entity_uuid,
                      parentUuid: ownerGuarantor.entity_uuid,
                      institutionUuid: loanApp.institution_uuid,
                      page_size: 9999,
                      page_number: 0
                    },
                    "thirdPartyGuarantors"
                  )
                )
              )
            )
          ),
          Promise.all(
            loanRequests.map(loanRequest =>
              Promise.resolve(
                dispatch(
                  asyncActions.retrieveLoanApplicationDocument(
                    {
                      entityUuid: loanRequest.entity_uuid,
                      parentUuid: loanRequest.uuid,
                      institutionUuid: loanApp.institution_uuid,
                      page_size: 9999,
                      page_number: 0
                    },
                    "loanRequests"
                  )
                )
              )
            )
          )
        ]);
        dispatch(actions.docsReady(true));
      } catch (err) {
        dispatch(actions.showErrors({ general: err }));
      } finally {
        dispatch(actions.loading(false));
      }
    },

  updateLoanApplicationDocument:
    ({ uuid, relType, ...data }) =>
    async dispatch => {
      delete data.httpHeaders;
      dispatch(
        apiAction(
          "update",
          "loanApplications/documents",
          { uuid },
          data,
          null,
          {
            relType
          }
        )
      );
    },

  deleteLoanApplicationDocument:
    ({ uuid }) =>
    async dispatch => {
      await dispatch(
        apiAction("delete", "loanApplications/documents", { uuid })
      );
    },

  // backward compatibility with the EntityModal
  openEntityModal: stepNumber => async dispatch => {
    dispatch({
      type: "ENTITY_MODAL_CHANGE_STEP",
      stepNumber
    });
  },

  retrieveAssetsForEntity:
    ({ entityUuid }) =>
    dispatch => {
      dispatch(
        apiAction(
          "retrieve",
          "loanApplications/assets",
          { entity_uuid: entityUuid, page_number: 0, page_size: 300 },
          null,
          null,
          { forEntity: true }
        )
      );
    },

  retrieveAnalyst:
    ({ uuid }) =>
    async dispatch => {
      dispatch({
        type: "loanApplications/analysts/retrieving",
        payload: { identifiers: { uuid } }
      });
      dispatch(apiAction("retrieve", "loanApplications/analysts", { uuid }));
    }
};

Object.assign(actions, asyncActions);

// EXPORTS
export { actions, name, reducer };

export default {
  name,
  actions,
  reducer,
  selectors,
  initialState
};

async function dispatchLoanApplicationsRelationshipUpdate(
  dispatch,
  entity,
  primaryBorrower = false
) {
  const nextEntity = { ...entity, primary_borrower: primaryBorrower };
  const {
    child_uuid,
    institution_uuid,
    parent_uuid,
    primary_borrower,
    rel_type,
    uuid,
    parent_type,
    child_type
  } = nextEntity;
  await dispatch(
    apiAction(
      "update",
      "loanApplications/relationships",
      {
        uuid: nextEntity.uuid
      },
      {
        child_uuid,
        institution_uuid,
        parent_uuid,
        primary_borrower,
        rel_type,
        uuid,
        parent_type,
        child_type
      }
    )
  );

  return nextEntity;
}
