export const institutionTypeOptions = [
  { value: "Bank", text: "Bank" },
  { value: "Credit Union", text: "Credit Union" }
];

export const regulatorOptions = [
  { value: "NCUA", text: "NCUA" },
  { value: "FDIC", text: "FDIC" },
  { value: "OCC", text: "OCC" },
  { value: "State", text: "State" },
  { value: "Other", text: "Other" }
];

export const accountTypeOptions = [
  { value: "Checking", text: "Checking" },
  { value: "General Ledger", text: "General Ledger" }
];
