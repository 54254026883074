import sortWithDirection from "./sortWithDirection";

/**
 *
 * @param {Array} list
 * @param {String} key
 * @param {'ascending | descending'} sortDirection
 *
 * @note 'ascending' oldest to newest
 * @note 'descending' newest to oldest
 *
 * @returns sorted Array
 */
function sortArrByDateKey(list, key, sortDirection = "ascending") {
  if (Array.isArray(list) && list.length > 0) {
    return list.sort((a, b) => {
      const dateA = new Date(a[key]).getTime();
      const dateB = new Date(b[key]).getTime();

      return sortWithDirection(dateA, dateB, sortDirection);
    });
  }

  return list;
}

export default sortArrByDateKey;
