/* eslint-env browser */
import PropTypes from "prop-types";
import React, { useEffect, useCallback } from "react";
import TypeCheck from "typecheck-extended";
import { useDispatch, useSelector } from "react-redux";
import { ToastProvider } from "@bafsllc/ui-shared";
import Constants from "../../../../services/Constants/strings";
import { ROUTE_TO_ACTIVE_ITEM } from "./constants";
import {
  ObjectMetadata,
  Documents,
  Users,
  Spreads,
  Cms
} from "../../../../services/ApiLib";
import { ConcatName } from "../../../../services/Entities";
import { useTailUrl } from "../../../../services/Hooks";
import { FILE_MIME_TYPES_MAP } from "../../../../services/Constants/AllowedDocumentTypes";

import DataBlock from "./components/DataBlock";
import TabBar from "./components/TabBar";
import TabView from "./components/TabView";
import { prettyDate } from "../../../../services/DateTime";
import logger from "../../../../services/logger";
import { getEntityInformation } from "../../actions/entities";
import BpAccessBlock from "./components/BpAccessBlock";

export function onError(response) {
  logger.error(response);
}

const schedulePaginationName = Constants.creditSchedulePaginationName;
const ticklerPaginationName = Constants.creditTicklerPaginationName;

function CreditManagement({ entityUuid }) {
  // Pull uuid at end of url, and then set that as default value for active item
  // This will cover refresh on sub-tabs
  const routeValues = Object.keys(ROUTE_TO_ACTIVE_ITEM);
  const tailUrl = useTailUrl();
  const dispatch = useDispatch();
  let urlActiveItem;

  const {
    activeItem,
    data,
    docTrackingItem,
    jwt,
    statementSelected = "",
    isDuplicate = false,
    selectedRowData,
    entityData,
    creditDocsFilter,
    selectedDocument
  } = useSelector(state => ({
    activeItem: state.CreditManagementReducer.activeItem,
    data: state.CreditManagementReducer.data,
    docTrackingItem: state.CreditManagementReducer.docTrackingItem,
    jwt: state.auth.jwt,
    statementSelected: state.CreditManagementReducer.statementSelected,
    isDuplicate: state.CreditManagementReducer.isDuplicate,
    selectedRowData: state.CreditManagementReducer.selectedRowData,
    entityData: state.CreditManagementReducer.entityData,
    creditDocsFilter: state.CreditManagementReducer.creditDocsFilter,
    selectedDocument: state.CreditManagementReducer.creditDoc
  }));

  if (routeValues.includes(tailUrl[0])) {
    urlActiveItem = ROUTE_TO_ACTIVE_ITEM[tailUrl[0]];
    if (urlActiveItem !== activeItem) {
      // This ensures that the data from one tab does not move into another when clicking
      // on multiple tabs
      dispatch({
        type: "CMS_UPDATE_LOAN",
        data: []
      });
      dispatch({
        type: "CMS_UPDATE_ACTIVE_ITEM",
        activeItem: urlActiveItem
      });
    }
  }

  const collectEntityInformation = useCallback(async () => {
    dispatch(getEntityInformation(entityUuid));
  }, [dispatch, entityUuid]);

  const displayLoadError = useCallback(() => {
    dispatch({
      type: "CMS_ERROR_LOAD_DOC_LIST",
      errorMessage: Constants.CMS_ERROR_LOAD_DOC_LIST
    });
  }, [dispatch]);

  // eslint-disable-next-line camelcase
  const populateUserInTableName = useCallback(
    ({ first_name, last_name }, { index }) => {
      dispatch({
        type: "CMS_UPDATE_POPULATED_TABLE",
        index,
        name: ConcatName({ first_name, last_name })
      });
    },
    [dispatch]
  );

  const populateTable = useCallback(
    (model, name) => {
      let newData = model;
      if (name === Constants.CREDIT_DOCUMENTS) {
        newData = model.data;
      }
      dispatch({
        activeItem: name,
        data: newData,
        type: "CMS_POPULATE_TABLE"
      });
    },
    [dispatch]
  );

  const loadTable = useCallback(
    async (name, loadEntityUuid) => {
      const uuids = {
        entityUuid: loadEntityUuid
      };
      switch (name) {
        case Constants.RELATIONSHIPS: {
          dispatch({
            type: "CMS_POPULATE_TABLE",
            data: [],
            activeItem: name
          });

          break;
        }

        case Constants.FINANCIAL_STATEMENTS: {
          try {
            const spreads = await Spreads.asyncRead(uuids);
            dispatch({
              type: "CMS_POPULATE_TABLE",
              data: spreads.rows,
              activeItem: name
            });
            spreads.rows.forEach(async (obj, index) => {
              if (!obj.financialModelUuid) {
                onError(
                  "financialModelUuid Now Required: All existing reports need to be updated."
                );
              }
              let userUuid;
              if (obj.analystUuid || obj.loanReviewOfficer) {
                userUuid = obj.analystUuid || obj.loanReviewOfficer;
                try {
                  const rsp = await Users.asyncRead({ userUuid });
                  populateUserInTableName(rsp.data, { index });
                } catch (err) {
                  populateUserInTableName(
                    {
                      first_name: "Analyst",
                      last_name: "Not Found",
                      username: "NotFound"
                    },
                    { index }
                  );
                }
              } else {
                populateUserInTableName(
                  {
                    first_name: "Analyst",
                    last_name: "Not Found",
                    username: "NotFound"
                  },
                  { index }
                );
              }
            });
            try {
              const queryParameters = new URLSearchParams(
                window.location.search
              );
              const docUUID = queryParameters.get("doc_uuid");
              const objectData = await ObjectMetadata.asyncRead(docUUID, {
                parentUuid: entityData.uuid,
                institutionUuid: entityData.institution_uuid
              });
              if (docUUID) {
                const doc = objectData.data;
                let docName = `${prettyDate(doc.uploadDate)}-${doc.docType}`;
                if (doc.description !== "") {
                  docName = `${docName}-${doc.description}`;
                }
                dispatch({
                  type: "CMS_OPEN_AND_UPDATE_DUP_MODAL",
                  docName,
                  value: doc.uuid,
                  item: "documentUuid",
                  documents: [objectData.data]
                });
              } else {
                dispatch({
                  type: "CMS_UPDATE_DOCUMENTS",
                  documents: objectData.data
                });
              }
            } catch (err) {
              onError(err);
            }
          } catch (err) {
            displayLoadError(err);
          }
          break;
        }

        case Constants.CREDIT_REQUEST: {
          // Call moved to CreditRequest component and data moved to local reducer.
          // Calling populateTable to continue clear `data` and set activeItem
          populateTable([], Constants.CREDIT_REQUEST);
          break;
        }

        case Constants.LOANS: {
          populateTable([], Constants.LOANS);
          break;
        }

        case Constants.DOCUMENT_TRACKING: {
          populateTable([], Constants.DOCUMENT_TRACKING);
          break;
        }

        case Constants.ASSETS: {
          populateTable([], Constants.ASSETS);
          break;
        }

        case Constants.BORROWER_PORTAL_LINKS: {
          populateTable([], Constants.BORROWER_PORTAL_LINKS);
          break;
        }

        case Constants.DETAILS: {
          try {
            const details = await Cms.asyncRead(entityData.uuid);
            dispatch({
              activeItem: name,
              data: details.data,
              type: "CMS_POPULATE_TABLE"
            });
          } catch (err) {
            onError(err);
          }
          break;
        }

        case Constants.CREDIT_DOCUMENTS: {
          try {
            const objects = await ObjectMetadata.asyncRead(
              null,
              {
                parentUuid: entityData.uuid,
                institutionUuid: entityData.institution_uuid
              },
              {
                number: 0,
                size: 9999
              }
            );
            populateTable(objects, name);
          } catch (err) {
            displayLoadError(err);
          }
          break;
        }

        default:
          // no default load, do nothing
          break;
      }
    },
    [
      dispatch,
      displayLoadError,
      entityData.institution_uuid,
      entityData.uuid,
      populateTable,
      populateUserInTableName
    ]
  );

  useEffect(() => {
    collectEntityInformation();
    return () => {
      dispatch({
        type: "CMS_LOAD_INITIAL_STATE_ON_UNMOUNT"
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, entityUuid, collectEntityInformation]);

  useEffect(() => {
    loadTable(activeItem, entityData.uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem, loadTable]);

  // Helper functions passed to other components:
  // TODO: refactor these functions so they don't need to be passed down via props
  // Issue number https://github.com/bafsllc/clearwater/issues/7639
  async function onPreview(objectUuid, fileType, fileName) {
    try {
      const endpointFileType =
        fileType === FILE_MIME_TYPES_MAP.pdf.mimeType ? "preview" : "download";
      if (endpointFileType === "preview") {
        await Documents.preview({ uuid: objectUuid }, { fileType, fileName });
      } else if (endpointFileType === "download") {
        await Documents.download({ uuid: objectUuid }, { fileType, fileName });
      }
    } catch (err) {
      onError(err);
    }
  }

  function onSaveDocApproval(metadata) {
    dispatch({
      type: "CMS_CREDIT_DOCS_TOGGLE_MODAL"
    });
    dispatch({
      type: "CMS_CREDIT_DOCS_SELECT_DOCUMENT",
      row: {}
    });
    dispatch({
      type: "DOC_APPROVAL_ADD_DOC_DETAILS",
      docDetails: {}
    });
    dispatch({
      type: "CMS_UPDATE_CREDIT_DOCUMENTS",
      data: metadata.data
    });
  }

  function addNewUploadToState(metadataRsp) {
    TypeCheck(metadataRsp.data.uuid, "string");

    dispatch({
      fileInfo: metadataRsp.data,
      type: "CMS_CREDIT_DOCUMENTS_UPLOAD_FILE"
    });
  }

  function addDocument() {
    const action = {
      type: "CMS_ADD_DUP_ADD_SUBMIT",
      jwt,
      dispatch
    };
    dispatch(action);
  }

  function duplicateDocument() {
    const action = {
      type: "CMS_ADD_DUP_ADD_SUBMIT",
      jwt,
      dispatch
    };
    dispatch(action);
  }

  function deleteDocument() {
    const action = {
      type: "CMS_DELETE_CONFIRMED",
      jwt,
      uuid: statementSelected,
      dispatch
    };
    dispatch(action);
  }

  function handleCreditDocSearch(e, searchInfo) {
    dispatch({
      type: "CMS_CREDIT_DOCUMENTS_SEARCH_FILTER",
      searchInfo
    });
  }

  function toggleDocApprovalModal(row) {
    dispatch({
      type: "CMS_CREDIT_DOCS_TOGGLE_MODAL"
    });
    dispatch({
      type: "CMS_CREDIT_DOCS_SELECT_DOCUMENT",
      row
    });
    dispatch({
      type: "DOC_APPROVAL_ADD_DOC_DETAILS",
      docDetails: row
    });
  }

  return (
    <div>
      <ToastProvider>
        <DataBlock />

        <BpAccessBlock />

        <TabBar activeItem={activeItem} />
        <TabView
          addDocumentCallback={addDocument}
          addNewUploadToState={addNewUploadToState}
          companyName={entityData.company_name}
          creditDocsFilter={creditDocsFilter}
          data={data}
          deleteDocumentCallback={deleteDocument}
          docTrackingItem={docTrackingItem}
          duplicateDocumentCallback={duplicateDocument}
          entityData={entityData}
          handleCreditDocSearch={handleCreditDocSearch}
          isDuplicate={isDuplicate}
          onPreview={onPreview}
          onSave={onSaveDocApproval}
          schedulePaginationName={schedulePaginationName}
          selectedDocument={selectedDocument}
          selectedRowData={selectedRowData}
          statementSelected={statementSelected}
          ticklerPaginationName={ticklerPaginationName}
          toggleDocApprovalModal={toggleDocApprovalModal}
        />
      </ToastProvider>
    </div>
  );
}

CreditManagement.propTypes = {
  entityUuid: PropTypes.string.isRequired
};

export default CreditManagement;
