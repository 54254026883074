import { ClearableDropdown, Field } from "@bafsllc/ui-shared";
import { PropTypes } from "prop-types";

export default function CreditRiskRatingDropdown({
  onDropdownChange,
  selectedSingleRiskScale,
  selectedSingleRiskScaleItem,
  isViewMode
}) {
  /* Created this recursive function because the credit risk rating
  dropdown options have many child Nodes and depending where you click,
  it selects the element where the cursor was.
  It could be the first one or the last one in the element three */
  const lookForParentValue = ({ node }) => {
    if (node.getAttribute("value")) {
      return node.getAttribute("value");
    }
    return lookForParentValue({ node: node.parentNode });
  };

  return (
    <Field labelMessage={{ id: "CREDIT_RISK_RATING" }} required>
      <ClearableDropdown
        id="credit_risk_rating"
        onChildSelect={e => {
          const singleRiskScaleItemUuid = lookForParentValue({
            node: e.target
          });
          onDropdownChange({
            name: "selectedSingleRiskScaleItem",
            value: selectedSingleRiskScale?.single_risk_scale_items.find(
              scaleItem => scaleItem.uuid === singleRiskScaleItemUuid
            )
          });
        }}
        selectedValue={{
          defaultMessage:
            selectedSingleRiskScaleItem &&
            `
                  ${selectedSingleRiskScaleItem?.rating} | 
                  ${selectedSingleRiskScaleItem?.name} | 
                  ${
                    selectedSingleRiskScaleItem?.passing_grade
                      ? "Passing"
                      : "Not Passing"
                  } \n 
                  ${selectedSingleRiskScaleItem?.description}`
        }}
        onClear={() => {
          onDropdownChange({
            name: "selectedSingleRiskScaleItem",
            value: null
          });
        }}
        placeholder={{ id: "CREDIT_ANALYSIS_DROPDOWN_PLACEHOLDER" }}
        fill
        disabled={isViewMode}
      >
        {
          /** The sort piece is a temp fix, it should be removed soon */
          selectedSingleRiskScale?.single_risk_scale_items ? (
            selectedSingleRiskScale.single_risk_scale_items
              .sort((a, b) => a.rating - b.rating)
              .map(scaleItem => (
                <li
                  key={scaleItem.uuid}
                  name="scale_item_uuid"
                  value={scaleItem.uuid}
                >
                  <div>
                    <span>
                      <b>{`${scaleItem?.rating} | `}</b>
                      <b>{`${scaleItem?.name} | `}</b>
                      <b>{`${
                        scaleItem?.passing_grade ? "Passing" : "Not Passing"
                      }`}</b>
                    </span>
                    <p>{scaleItem.description}</p>
                  </div>
                </li>
              ))
          ) : (
            <li />
          )
        }
      </ClearableDropdown>
    </Field>
  );
}

const singleRiskScaleItemShape = {
  uuid: PropTypes.string,
  rating: PropTypes.number,
  name: PropTypes.string,
  passing_grade: PropTypes.bool
};

CreditRiskRatingDropdown.propTypes = {
  onDropdownChange: PropTypes.func.isRequired,
  selectedSingleRiskScale: PropTypes.shape({
    single_risk_scale_items: PropTypes.arrayOf(
      PropTypes.shape(singleRiskScaleItemShape)
    )
  }).isRequired,
  selectedSingleRiskScaleItem: PropTypes.shape(singleRiskScaleItemShape)
    .isRequired,
  isViewMode: PropTypes.bool.isRequired
};
