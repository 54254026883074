import { CommonMessages } from "@blast-client/constants";
import { KeyboardEvent } from "react";
import { SingleValue } from "react-select";
import { OptionType } from "..";

const { DOLLAR_AMOUNT, PERCENTAGE } = CommonMessages;

export const allowedKeys = ["Backspace", "Tab", "ArrowLeft", "ArrowRight"];

export const isAnInvalidCharacter = (
  e: KeyboardEvent<HTMLInputElement>,
  dollarFormatSelected: boolean | undefined,
  percentageFormatSelected: boolean | undefined
) => {
  if (
    hasADisallowedCharacter(
      e,
      dollarFormatSelected,
      percentageFormatSelected
    ) ||
    isTypingMultipleDecimalPoints(e) ||
    isTypingLoneDecimalPoint(e) ||
    isAddingMoreThanTwoCharactersAfterDecimal(e)
  )
    return true;

  return false;
};

const hasADisallowedCharacter = (
  e: KeyboardEvent<HTMLInputElement>,
  dollarOption: boolean = false,
  percentageFormat: boolean = false
) => {
  const allowedCharacters =
    dollarOption || percentageFormat ? /^[0-9.]$/ : /^[0-9]$/;
  if (!allowedCharacters.test(e.key)) return true;
  return false;
};

const isTypingMultipleDecimalPoints = (e: KeyboardEvent<HTMLInputElement>) => {
  if (e.key === "." && e.currentTarget.value.includes(".")) return true;
  return false;
};

const isTypingLoneDecimalPoint = (e: KeyboardEvent<HTMLInputElement>) => {
  if (e.key === "." && e.currentTarget.value === "") return true;
  return false;
};

const isAddingMoreThanTwoCharactersAfterDecimal = (
  e: KeyboardEvent<HTMLInputElement>
) => {
  const value = e.currentTarget.value;
  const decimalIndex = value.indexOf(".");
  if (
    decimalIndex !== -1 &&
    value.substring(decimalIndex + 1).length >= 2 &&
    e.currentTarget.selectionStart &&
    e.currentTarget.selectionStart > decimalIndex
  )
    return true;
  return false;
};

export const isSelectOrClipboardAction = (
  e: KeyboardEvent<HTMLInputElement>
) => {
  if (
    ((e.metaKey || e.ctrlKey) && e.key === "a") ||
    ((e.metaKey || e.ctrlKey) && e.key === "v") ||
    ((e.metaKey || e.ctrlKey) && e.key === "c") ||
    ((e.metaKey || e.ctrlKey) && e.key === "x")
  )
    return true;
  return false;
};

export const isShiftPlusLateralArrowKey = (
  e: KeyboardEvent<HTMLInputElement>
) => {
  if (e.shiftKey && (e.key === "ArrowLeft" || e.key === "ArrowRight"))
    return true;
  return false;
};

export const matchFormatToDropdownValue = (
  format: string,
  formattingOptions: { label: string; value: string }[]
) => {
  return formattingOptions.find(option => {
    if (option.value === DOLLAR_AMOUNT && format === "dollar") return true;
    if (option.value.toLowerCase() === format) return true;
    return false;
  });
};

export const whichFormatIsSelected = (
  dollarOption: boolean | undefined,
  selectedDropdownValue: SingleValue<OptionType> | undefined,
  dropdown: boolean | undefined
) => {
  const dollarFormatSelected =
    dollarOption ||
    (selectedDropdownValue?.value === DOLLAR_AMOUNT && dropdown);
  const percentageFormatSelected =
    selectedDropdownValue?.value === PERCENTAGE && dropdown;
  return { dollarFormatSelected, percentageFormatSelected };
};

export const parentDivTestId = "parent-div";
export const inputParentDivTestId = "input-parent-div";
export const percentPlaceholderText = "Percent";
export const dollarPlaceholderText = "Dollar amount";
