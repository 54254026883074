import PropTypes from "prop-types";
import React, { Component } from "react";
import TypeCheck from "typecheck-extended";
import { connect } from "react-redux";
import { Dimmer, Loader } from "semantic-ui-react";
import { Redirect, Route } from "react-router-dom";

import Stepper from "./components/stepper";
import { ConfirmationModal } from "../../components/Modals";

import { CreditRequests, Entities, LoanApp } from "../../services/ApiLib";
import { relationshipTypes } from "../../services/Constants/relationshipTypes";
import { ResumeApp } from "./services/ResumeApp";
import { stepList } from "./services/constants";

import AppOverview from "./scenes/AppOverview";
import BusinessInfo from "./scenes/BusinessInfo";
import EntitySelection from "./scenes/EntitySelection";
import LoanRequest from "./scenes/LoanRequest";
import PersonalInfo from "./scenes/PersonalInfo";
import logger from "../../services/logger";

export class AutoDecisionObj extends Component {
  constructor(props) {
    super(props);
    this.dispatchEntireForm = this.dispatchEntireForm.bind(this);
    this.dispatchFormField = this.dispatchFormField.bind(this);
    this.formatRspAndDispatch = this.formatRspAndDispatch.bind(this);
    this.loanAppDispatchWithdrawal = this.loanAppDispatchWithdrawal.bind(this);
    this.loanAppSaveWithdrawal = this.loanAppSaveWithdrawal.bind(this);
    this.onError = this.onError.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onSaveAndNext = this.onSaveAndNext.bind(this);
    this.onSaveError = this.onSaveError.bind(this);
    this.onSaveSuccess = this.onSaveSuccess.bind(this);
    this.onWithdraw = this.onWithdraw.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.toggleConfirmations = this.toggleConfirmations.bind(this);
    this.toggleFormValidation = this.toggleFormValidation.bind(this);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch({
      type: "AUTO_DESC_RESET_STATE"
    });
  }

  onError(error) {
    let errorMsg = error;
    if (typeof errorMsg !== "string") {
      errorMsg = "Error Communicating with Server.";
    }
    logger.error(error);
    const { dispatch } = this.props;
    dispatch({
      message: errorMsg,
      messageType: "errorMsg",
      type: "AUTO_DESC_MESSAGE"
    });
  }

  onSaveAndNext() {
    let event;
    let data;
    const redirectOnSuccess = true;
    this.onSave(event, data, redirectOnSuccess);
  }

  onSaveSuccess(rsp, { callbackData }) {
    if (callbackData && callbackData.redirectOnSuccess) {
      this.redirectToCurrentPage();
    } else {
      const { dispatch } = this.props;
      dispatch({
        message: "✓ Saved",
        messageType: "userMessage",
        type: "AUTO_DESC_MESSAGE"
      });
    }
  }

  onSave(e, data, redirectOnSuccess) {
    TypeCheck(redirectOnSuccess, "boolean", false);

    const { onSaveSuccess } = this;
    const callbackData = { redirectOnSuccess };
    const {
      businessInfo,
      currentPageIndex,
      jwt,
      loanRequestInfo,
      personalInfo
    } = this.props;
    const onError = this.onSaveError;

    switch (stepList[currentPageIndex]) {
      case "business":
        Entities.update(
          jwt,
          onSaveSuccess,
          onError,
          { ...businessInfo },
          callbackData
        );
        break;

      case "owner":
        Entities.update(
          jwt,
          onSaveSuccess,
          onError,
          { ...personalInfo },
          callbackData
        );
        break;

      case "request":
        CreditRequests.update(
          jwt,
          onSaveSuccess,
          onError,
          { ...loanRequestInfo },
          callbackData
        );
        break;

      default:
        break;
    }
  }

  onSaveError(error) {
    logger.error(error); // TODO: Remove this.
    this.onError(
      "Error: Could not save form. Please refresh the page, and try again."
    );
  }

  onWithdraw() {
    this.toggleConfirmations("withdraw", true);
  }

  setCurrentPage(currentPageIndex, redirect) {
    TypeCheck(currentPageIndex, "number");
    TypeCheck(redirect, "boolean", false);

    const { dispatch } = this.props;
    dispatch({
      currentPageIndex,
      type: "AUTO_DESC_SET_CURRENT_PAGE"
    });

    if (redirect) {
      this.redirectToCurrentPage();
    }
  }

  redirectToCurrentPage() {
    const { currentPageIndex, loanAppInfo } = this.props;
    const url = `/auto_decision_request/${loanAppInfo.uuid}/${
      stepList[currentPageIndex + 1]
    }`;
    const { history } = this.props;
    history.push(url);
  }

  loanAppSaveWithdrawal() {
    const { jwt, loanAppInfo } = this.props;
    const onSuccess = this.loanAppDispatchWithdrawal;
    const { onError } = this;
    const loanAppUuid = loanAppInfo.uuid;
    const body = { phase: "Withdrawn" };
    let callbackData;
    LoanApp.update(jwt, onSuccess, onError, loanAppUuid, body, callbackData);
  }

  loanAppDispatchWithdrawal() {
    const { dispatch } = this.props;
    dispatch({ type: "AUTO_DESC_WITHDRAW_APP" });
  }

  toggleConfirmations(whichConfirmation, isVisible) {
    TypeCheck(whichConfirmation, "string");
    TypeCheck(isVisible, "boolean");

    const { dispatch } = this.props;
    dispatch({
      isVisible,
      type: "AUTO_DESC_TOGGLE_CONFIRMATIONS",
      whichConfirmation
    });
  }

  toggleFormValidation(formIsValid) {
    TypeCheck(formIsValid, "boolean");

    const { dispatch } = this.props;
    dispatch({
      type: "AUTO_DESC_TOGGLE_FORM_VALIDATION",
      formIsValid
    });
  }

  disableAppStart() {
    const { businessInfo, errorMsg, loanAppInfo, personalInfo } = this.props;

    if (
      !businessInfo.uuid ||
      !loanAppInfo.institution_uuid ||
      !personalInfo.uuid ||
      errorMsg
    ) {
      return true;
    }
    return false;
  }

  formatRspAndDispatch({ data }, { whichForm }) {
    TypeCheck(data, "object");
    TypeCheck(whichForm, "string");

    this.dispatchEntireForm(data, whichForm);
  }

  dispatchEntireForm(newFormValues, whichForm) {
    TypeCheck(newFormValues, "object");
    TypeCheck(whichForm, "string");

    const { dispatch } = this.props;
    dispatch({
      newFormValues,
      type: "AUTO_DESC_REPLACE_FORM",
      whichForm
    });
  }

  dispatchFormField(whichForm, name, value) {
    TypeCheck(whichForm, "string");
    TypeCheck(name, "string");

    const { dispatch } = this.props;
    dispatch({
      name,
      type: "AUTO_DESC_UPDATE_FORM",
      value,
      whichForm
    });
  }

  canProceedToNextStep() {
    const {
      businessInfo,
      errorMsg,
      loanAppInfo,
      loanRequestInfo,
      personalInfo,
      relationshipsCreated
    } = this.props;

    if (
      !errorMsg &&
      businessInfo.uuid &&
      loanAppInfo.uuid &&
      loanRequestInfo.uuid &&
      personalInfo.uuid &&
      relationshipsCreated.borrower &&
      relationshipsCreated.owner
    ) {
      return true;
    }
    return false;
  }

  // TODO: The resume logic needs to be refactored to prevent React Warning:
  //      "Cannot update during an existing state transition"
  resumeLoanApp(loanAppUuid) {
    TypeCheck(loanAppUuid, "string");

    const { dispatch, errorMsg, jwt, loading } = this.props;
    if (this.canProceedToNextStep()) {
      if (loading) {
        dispatch({ type: "AUTO_DESC_TOGGLE_LOADING" });
      }
      return;
    }

    if (!errorMsg && !loading) {
      dispatch({
        relType: relationshipTypes.BORROWER,
        type: "AUTO_DESC_MARK_REL_SUCCESS"
      });
      dispatch({
        relType: relationshipTypes.OWNER,
        type: "AUTO_DESC_MARK_REL_SUCCESS"
      });
      dispatch({ type: "AUTO_DESC_TOGGLE_LOADING" });
      ResumeApp(jwt, this.dispatchEntireForm, this.onError, loanAppUuid);
    }
  }

  render() {
    const {
      confirmationVisibility,
      currentPageIndex,
      decisionApproved,
      errorMsg,
      formIsValid,
      loading,
      loanAppInfo,
      userMessage
    } = this.props;
    // TODO: We still need to set decisionApproved on the decision being approved.
    if (decisionApproved || loanAppInfo.phase !== "New Credit Application") {
      return <Redirect to="/docs/decision_history" />;
    }
    const errorMsgDisplay = <h2 style={{ color: "red" }}>{errorMsg}</h2>;
    const userMsg = <h2 style={{ color: "green" }}>{userMessage}</h2>;
    let header = "Auto Decision Request";
    const appId = loanAppInfo.app_id;
    if (appId) {
      header += `: ${appId}`;
    }
    return (
      <div>
        <Stepper
          currentPage={stepList[currentPageIndex]}
          uuid={loanAppInfo.uuid}
        />
        <h1>{header}</h1>
        <hr />
        {/*
          TODO: Redirecting this way will not update the active tab color.
          TODO: Need the URL to include a UUID for all steps except step 0.
        */}
        {errorMsgDisplay}
        {userMsg}
        <Dimmer active={loading}>
          <Loader content="Loading..." />
        </Dimmer>
        <Route
          path={`/auto_decision_request/${stepList[0]}`}
          render={() =>
            this.canProceedToNextStep() ? (
              <Redirect
                to={{
                  pathname: `/auto_decision_request/${loanAppInfo.uuid}/${stepList[1]}`
                }}
              />
            ) : (
              <EntitySelection
                disabled={this.disableAppStart()}
                dispatchEntireForm={this.dispatchEntireForm}
                dispatchFormField={this.dispatchFormField}
                formatRspAndDispatch={this.formatRspAndDispatch}
                onError={this.onError}
                onSave={this.onSave}
                onWithdraw={this.onWithdraw}
              />
            )
          }
        />

        <Route
          path={`/auto_decision_request/:uuid/${stepList[1]}`}
          render={({ match }) => {
            this.resumeLoanApp(match.params.uuid);
            return (
              <BusinessInfo
                {...this.props}
                dispatchFormField={this.dispatchFormField}
                formIsValid={formIsValid}
                loanAppUuid={match.params.uuid}
                onSave={this.onSave}
                onSaveAndNext={this.onSaveAndNext}
                onWithdraw={this.onWithdraw}
                setCurrentPage={this.setCurrentPage}
                toggleFormValidation={this.toggleFormValidation}
              />
            );
          }}
        />

        <Route
          path={`/auto_decision_request/:uuid/${stepList[2]}`}
          render={({ match }) => {
            this.resumeLoanApp(match.params.uuid);
            return (
              <PersonalInfo
                {...this.props}
                dispatchFormField={this.dispatchFormField}
                loanAppUuid={match.params.uuid}
                onError={this.onError}
                onSave={this.onSave}
                onSaveAndNext={this.onSaveAndNext}
                onWithdraw={this.onWithdraw}
                setCurrentPage={this.setCurrentPage}
                toggleFormValidation={this.toggleFormValidation}
              />
            );
          }}
        />

        <Route
          path={`/auto_decision_request/:uuid/${stepList[3]}`}
          render={({ match }) => {
            this.resumeLoanApp(match.params.uuid);
            return (
              <LoanRequest
                {...this.props}
                dispatchFormField={this.dispatchFormField}
                loanAppUuid={match.params.uuid}
                onError={this.onError}
                onSave={this.onSave}
                onSaveAndNext={this.onSaveAndNext}
                onWithdraw={this.onWithdraw}
                setCurrentPage={this.setCurrentPage}
                toggleFormValidation={this.toggleFormValidation}
              />
            );
          }}
        />

        <Route
          path={`/auto_decision_request/:uuid/${stepList[4]}`}
          render={({ match }) => {
            this.resumeLoanApp(match.params.uuid);
            return (
              <AppOverview
                {...this.props}
                dispatchFormField={this.dispatchFormField}
                loanAppUuid={match.params.uuid}
                onError={this.onError}
                onSave={this.onSave}
                onSaveAndNext={this.onSaveAndNext}
                onWithdraw={this.onWithdraw}
                setCurrentPage={this.setCurrentPage}
                toggleConfirmations={this.toggleConfirmations}
              />
            );
          }}
        />

        <ConfirmationModal
          cancelButtonLabel="Return to Editing Request"
          handleCancel={() => this.toggleConfirmations("withdraw", false)}
          handleConfirm={this.loanAppSaveWithdrawal}
          open={confirmationVisibility.withdraw}
          prompt="Withdraw this Request?"
          showLogo={false}
          warningDetails="Clicking withdraw will cancel this loan request. This can not be undone."
          yesButtonLabel="Withdraw Loan Request"
        />
      </div>
    );
  }
}

AutoDecisionObj.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object,
      PropTypes.func
    ])
  ).isRequired,
  //
  businessInfo: PropTypes.shape({ uuid: PropTypes.string }).isRequired,
  confirmationVisibility: PropTypes.shape({
    submit: PropTypes.bool,
    withdraw: PropTypes.bool
  }).isRequired,
  currentPageIndex: PropTypes.number.isRequired,
  decisionApproved: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string.isRequired,
  formIsValid: PropTypes.bool.isRequired,
  relationshipsCreated: PropTypes.shape({
    owner: PropTypes.bool,
    borrower: PropTypes.bool
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  loanAppInfo: PropTypes.shape({
    app_id: PropTypes.string,
    institution_uuid: PropTypes.string,
    phase: PropTypes.string,
    uuid: PropTypes.string
  }).isRequired,
  loanRequestInfo: PropTypes.shape({ uuid: PropTypes.string }).isRequired,
  personalInfo: PropTypes.shape({
    credit_bureau: PropTypes.string,
    uuid: PropTypes.string
  }).isRequired,
  userMessage: PropTypes.string.isRequired,
  //
  jwt: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  businessInfo: state.AutoDecisionReducer.businessInfo,
  confirmationVisibility: state.AutoDecisionReducer.confirmationVisibility,
  currentPageIndex: state.AutoDecisionReducer.currentPageIndex,
  decisionApproved: state.AutoDecisionReducer.decisionApproved,
  errorMsg: state.AutoDecisionReducer.errorMsg,
  formIsValid: state.AutoDecisionReducer.formIsValid,
  loading: state.AutoDecisionReducer.loading,
  loanAppInfo: state.AutoDecisionReducer.loanAppInfo,
  loanRequestInfo: state.AutoDecisionReducer.loanRequestInfo,
  personalInfo: state.AutoDecisionReducer.personalInfo,
  relationshipsCreated: state.AutoDecisionReducer.relationshipsCreated,
  userMessage: state.AutoDecisionReducer.userMessage,

  jwt: state.auth.jwt
});

const AutoDecision = connect(mapStateToProps)(AutoDecisionObj);

export default AutoDecision;
