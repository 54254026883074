/* eslint-env browser */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import {
  Dimmer,
  Form,
  Header,
  Loader,
  Message,
  Segment
} from "semantic-ui-react";
import { PasswordResetLib } from "../../services/ApiLib";
import logger from "../../services/logger";

export const badPassword = password => {
  let count = 0;
  if (password.length < 8) {
    return "Password too short";
  }
  if (password.length >= 20) {
    return false;
  }
  count += /[a-z]/.test(password) ? 1 : 0;
  count += /[A-Z]/.test(password) ? 1 : 0;
  count += /\W/.test(password) ? 1 : 0;
  count += /\d/.test(password) ? 1 : 0;

  if (password.length >= 8 && count < 3) {
    return "Password is too weak please read the criteria above.";
  }
  return false;
};

export class PasswordResetObj extends Component {
  constructor(props) {
    super(props);

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onError = this.onError.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onSuccessPassword = this.onSuccessPassword.bind(this);
    this.onSubmitError = this.onSubmitError.bind(this);
    this.onSuccessPassReset = this.onSuccessPassReset.bind(this);
    this.submitError = this.submitError.bind(this);
  }

  componentDidMount() {
    const { onSuccessPassReset, onError } = this;
    PasswordResetLib.get(onSuccessPassReset, onError, this.props.uuid);
  }

  onError(rsp) {
    this.props.dispatch({
      type: "PASSWORD_RESET_ERROR",
      error: true
    });
    logger.debug(rsp);
  }

  onSave() {
    const { password, confirmPassword, logUuid } = this.props;
    const { submitError, onSuccessPassword, onSubmitError } = this;
    const result = badPassword(password);
    if (password !== confirmPassword) {
      submitError("Password does not match Confirm Password");
    } else if (result) {
      submitError(result);
    } else {
      const body = { password };
      const uuid = logUuid;
      PasswordResetLib.changePassword(
        onSuccessPassword,
        onSubmitError,
        uuid,
        body
      );
    }
  }

  onSuccessPassReset(rsp) {
    this.props.dispatch({
      type: "PASSWORD_RESET_LOG_UUID_UPDATE",
      uuid: rsp.data.uuid
    });
  }

  onSuccessPassword() {
    this.props.dispatch({
      type: "PASSWORD_RESET_PASSWORD_UPDATE"
    });
  }

  onSubmitError(rsp) {
    logger.debug(rsp);
    this.submitError("Something went wrong please contact your administrator");
  }

  handleFieldChange(e, { name, value }) {
    const { dispatch } = this.props;
    dispatch({
      type: "PASSWORD_RESET_FIELD_CHANGE",
      name,
      value
    });
  }

  submitError(errorMessage) {
    this.props.dispatch({
      type: "PASSWORD_RESET_SUBMIT_ERROR",
      errorMessage
    });
  }

  render() {
    const { error, errorMessage, password, confirmPassword, loading, reset } =
      this.props;
    if (reset) {
      return <Redirect to="/login" />;
    }

    if (loading) {
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );
    }

    if (error) {
      return (
        <Segment textAlign="center">
          <Header color="blue" as="h1" size="massive">
            Link Has Expired
          </Header>
          <Header as="h2">
            <a href="/login">Return to Login</a>
          </Header>
        </Segment>
      );
    }

    const { handleFieldChange, onSave } = this;
    return (
      <Segment.Group>
        <Segment color="blue" size="massive" textAlign="center" inverted>
          <Header as="h1" content="Password Reset" size="huge" />
        </Segment>
        <Segment textAlign="center" size="massive">
          {errorMessage ? (
            <Message error header="Error" content={errorMessage} />
          ) : (
            <div />
          )}
          <Header as="h6">
            All passwords must be 8 characters long. If your password is less
            <br />
            than 20 characters, it must include 3 of the following 4
            <br />
            character types: lowercase, uppercase, number, and/or symbol.
          </Header>
          <Form widths="equal">
            <Form.Group width="9">
              <Form.Input
                name="password"
                onChange={handleFieldChange}
                placeholder="Password"
                size="huge"
                style={{ width: "30%" }}
                type="password"
                value={password}
              />
            </Form.Group>
            <Form.Group width="9">
              <Form.Input
                name="confirmPassword"
                onChange={handleFieldChange}
                type="password"
                placeholder="Confirm Password"
                size="huge"
                style={{ width: "30%" }}
                value={confirmPassword}
              />
            </Form.Group>
            <Form.Group width="9">
              <Form.Button
                color="orange"
                onClick={onSave}
                size="huge"
                type="submit"
                style={{ width: "30%" }}
              >
                Submit
              </Form.Button>
            </Form.Group>
          </Form>
        </Segment>
      </Segment.Group>
    );
  }
}

PasswordResetObj.propTypes = {
  dispatch: PropTypes.func.isRequired,
  confirmPassword: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  logUuid: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  reset: PropTypes.bool.isRequired,
  uuid: PropTypes.string.isRequired
};

const mapStateToProps = state => ({ ...state.PasswordResetReducer });

const PasswordReset = connect(mapStateToProps)(PasswordResetObj);

export default PasswordReset;
