import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Button,
  Comment,
  Divider,
  Grid,
  Icon,
  Modal,
  TextArea
} from "semantic-ui-react";

import { prettyDate } from "../../../../services/DateTime";
import { Messages } from "../../../../services/ApiLib";
import PaginationControls, { paginationChanged } from "../../../Pagination";

const paginationName = "messagesModal";

export class MessageModalObj extends Component {
  constructor(props) {
    super(props);

    this.getMessages = this.getMessages.bind(this);
    this.onSuccessMessage = this.onSuccessMessage.bind(this);
    this.onError = this.onError.bind(this);

    this.state = {
      error: ""
    };
  }

  componentDidUpdate(prevProps) {
    const { getMessages } = this;
    if (paginationChanged(prevProps.pagination, this.props.pagination)) {
      getMessages(this.props.pagination || {});
    }
  }

  onSuccessMessage(rsp) {
    const { ticklerUuid, dispatch } = this.props;
    const sortedMessages = rsp.data.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
    dispatch({
      type: "DOC_TRACKING_TICKLERS_GET_MESSAGES",
      uuid: ticklerUuid,
      messages: sortedMessages
    });
  }

  onError(rsp) {
    this.setState({ error: rsp.data });
  }

  getMessages(pagination) {
    const { onSuccessMessage, onError } = this;
    const { ticklerUuid } = this.props;
    Messages.get(onSuccessMessage, onError, null, {
      ...pagination,
      parentUuid: ticklerUuid
    });
  }

  render() {
    const { comment, commentUpdate, messagesNames, onComment, onClose, open } =
      this.props;
    const { error } = this.state;
    const { messages, ticklerUuid } = this.props;
    let comments = [];
    if (messages[ticklerUuid]) {
      comments = messages[ticklerUuid].map(obj => {
        let initials = "? ?";
        if (messagesNames[obj.uuid]) {
          initials = messagesNames[obj.uuid]
            .split(" ")
            .map(word => word[0])
            .join("");
        }
        return (
          <Grid.Row className="comments" columns="2" key={obj.uuid}>
            <Grid.Column width="1" verticalAlign="middle">
              <Icon bordered size="large">
                {initials}
              </Icon>
            </Grid.Column>
            <Grid.Column width="15">
              <Comment>
                <Comment.Content>
                  <Comment.Author>
                    <b>{messagesNames[obj.uuid] || "Not Found"}</b>
                    {` ${prettyDate(obj.date, true)}`}
                  </Comment.Author>
                  <Comment.Text>{obj.message}</Comment.Text>
                </Comment.Content>
              </Comment>
            </Grid.Column>
          </Grid.Row>
        );
      });
    }
    return (
      <Modal onClose={onClose} open={open}>
        <Modal.Header>Comments</Modal.Header>
        <Modal.Content>
          {error ? <h2 style={{ color: "red" }}>{error}</h2> : ""}

          <Grid>
            {comments}
            <Grid.Column stretched width="16">
              <PaginationControls name={paginationName} />
            </Grid.Column>
            <Grid.Row columns="1">
              <Grid.Column stretched width="16">
                <TextArea
                  onChange={(e, data) => commentUpdate(data)}
                  placeholder="Add Comment."
                  rows={5}
                  value={comment}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button
                  color="grey"
                  onClick={onComment}
                  data-testid="message-modal-add-comment"
                >
                  Add Comment
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column textAlign="right">
                <Button color="grey" onClick={onClose}>
                  Close
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

MessageModalObj.propTypes = {
  comment: PropTypes.string.isRequired,
  commentUpdate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  messagesNames: PropTypes.shape({
    col1: PropTypes.string
  }).isRequired,
  onComment: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    number: PropTypes.number,
    size: PropTypes.number
  }),
  ticklerUuid: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  pagination: state.PaginationReducer[paginationName],
  messages: state.DocTrackingViewReducer.messages
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

const MessageModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageModalObj);

export default MessageModal;
