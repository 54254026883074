import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form, Header, Container } from "semantic-ui-react";
import { actionCreators } from "../../reducer";
import { FormInput } from "../../../../../../../../services/FormElements";

export function PromisoryNoteObj({
  pppFormFields,
  entityFormFields,
  loanFormFields,
  setPPPFormFields,
  updatePPPFields,
  updateLoanFields,
  updateEntityFields,
  setEntityFormFields,
  setLoanFormFields
}) {
  return (
    <Container textAlign="left">
      <Header as="h3" content="Email Addresses" />
      <Form autoComplete="off">
        <Form.Input
          validation="isEmail"
          type="email"
          label={<b>Borrower Email</b>}
          key="primary_contact_email"
          name="primary_contact_email"
          id="primary_contact_email"
          data-testid="primary_contact_email"
          onChange={({ target }) => {
            setEntityFormFields({
              ...entityFormFields,
              primary_contact_email: target.value
            });
          }}
          onBlur={() => updateEntityFields()}
          value={entityFormFields.primary_contact_email || ""}
        />
        <FormInput
          input={{
            name: "institution_account_number",
            type: "string",
            label: "Institution Account Number"
          }}
          set={setLoanFormFields}
          update={updateLoanFields}
          fields={loanFormFields}
        />
        <Form.Input
          validation="isEmail"
          type="email"
          required
          label={<b>Lender Email 2484</b>}
          key="lender_email_2484"
          name="lender_email_2484"
          id="lender_email_2484"
          data-testid="lender_email_2484"
          onChange={({ target }) => {
            setPPPFormFields({
              ...pppFormFields,
              lender_email_2484: target.value
            });
          }}
          onBlur={() => updatePPPFields()}
          value={pppFormFields.lender_email_2484 || ""}
        />
        <Form.Input
          validation="isEmail"
          type="email"
          required
          label={<b>Lender Email Promissory Note</b>}
          key="lender_email_promissory_note"
          name="lender_email_promissory_note"
          id="lender_email_promissory_note"
          data-testid="lender_email_promissory_note"
          onChange={({ target }) => {
            setPPPFormFields({
              ...pppFormFields,
              lender_email_promissory_note: target.value
            });
          }}
          onBlur={() => updatePPPFields()}
          value={pppFormFields.lender_email_promissory_note || ""}
        />
      </Form>
    </Container>
  );
}

PromisoryNoteObj.propTypes = {
  pppFormFields: PropTypes.shape(),
  loanFormFields: PropTypes.shape(),
  entityFormFields: PropTypes.shape(),
  setPPPFormFields: PropTypes.func,
  updatePPPFields: PropTypes.func,
  updateLoanFields: PropTypes.func,
  setEntityFormFields: PropTypes.func,
  setLoanFormFields: PropTypes.func,
  updateEntityFields: PropTypes.func
};

const mapStateToProps = state => ({
  pppFormFields: state.PPPReducer.pppFormFields,
  entityFormFields: state.PPPReducer.entityFormFields,
  loanFormFields: state.PPPReducer.loanFormFields
});

export const mapDispatchToProps = dispatch => ({
  setPPPFormFields: fields => dispatch(actionCreators.setPPPFormFields(fields)),
  updatePPPFields: fields => dispatch(actionCreators.updatePPPFields(fields)),
  setEntityFormFields: fields =>
    dispatch(actionCreators.setEntityFormFields(fields)),
  updateEntityFields: fields =>
    dispatch(actionCreators.updateEntityFields(fields)),
  updateLoanFields: fields => dispatch(actionCreators.updateLoanFields(fields)),
  setLoanFormFields: fields =>
    dispatch(actionCreators.setLoanFormFields(fields))
});

export default connect(mapStateToProps, mapDispatchToProps)(PromisoryNoteObj);
