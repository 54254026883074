import React from "react";
import { Segment } from "semantic-ui-react";
import PromisoryNote from "./components/promisoryNote";
import Attestations from "./components/Attestations";
import LoanOfficerDropdown from "./components/LoanOfficerDropdown";

function PPP2484() {
  return (
    <Segment>
      <LoanOfficerDropdown />
      <PromisoryNote />
      <Attestations />
    </Segment>
  );
}
export default PPP2484;
