/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Button, Header, Modal } from "semantic-ui-react";
import useEntity from "../../../hooks/useEntity/useEntity";
import IndividualEntityInfo from "../Individual";
import OtherEntityInfo from "../Other";

export default function EntityInfoContent({ add }) {
  const dispatch = useDispatch();
  const { duplicateTinName, entity_type, tin } = useEntity();

  function handleDuplicateTinModalDismiss(allowDuplicate = false) {
    dispatch(
      allowDuplicate
        ? { type: "ENTITY_MODAL_DUPLICATE_TIN", entityName: "" }
        : { type: "ENTITY_MODAL_CLEAR_DUPLICATE_TIN" }
    );
  }

  return entity_type === "Individual" ? (
    <Modal.Content>
      <IndividualEntityInfo />
    </Modal.Content>
  ) : (
    <Modal.Content>
      <OtherEntityInfo add={add} />
      {duplicateTinName && duplicateTinName.length ? (
        <Modal open>
          <Modal.Header>Duplicate Tin</Modal.Header>
          <Modal.Content>
            <Header color="orange">
              {`TIN ${tin} is currently associated with ${duplicateTinName}`}
            </Header>
            Duplicate TIN is only allowed in the case of Sole Proprietership DBA
            or Single Member LLC.
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => handleDuplicateTinModalDismiss(false)}
              content="Cancel"
            />
            <Button
              onClick={() => handleDuplicateTinModalDismiss(true)}
              content="Use Duplicate TIN"
            />
          </Modal.Actions>
        </Modal>
      ) : null}
    </Modal.Content>
  );
}

EntityInfoContent.propTypes = {
  add: PropTypes.bool
};
