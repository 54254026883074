import jwt from "jsonwebtoken";

// `getUserGroup` is a circular dependency that confuses the Jest module loader.
// If you need to mock `Auth` do not use `requireActual` - it will log an error
// message. Instead mock every `Auth` method that is invoked.
import getUserGroup from "./services/getUserGroup";
import userCanViewPage from "./services/userCanViewPage";

import FetchService from "../FetchService";
import ApiEndpoints from "../ApiEndpoints";
import logger from "../logger";

export const JWTPayload = token => jwt.decode(token);

export const getToken = () => window.localStorage.getItem("authToken");

export const CheckAuth = () => {
  const storageToken = window.localStorage.getItem("authToken");
  if (typeof storageToken === "string") {
    const now = new Date();
    const token = jwt.decode(storageToken);
    const tokenDate = new Date(`${token.expire}Z`);
    if (tokenDate > now) {
      token.token = storageToken;
      return token;
    }
  }
  return undefined;
};

export const SetToken = token => {
  window.localStorage.setItem("authToken", token);
};

export const succeed = data => SetToken(data.token);
export const fail = () => "token refresh failed";
// TODO: error more effectively
// TODO: @bafsllc/auth-shared has this functionality now. Consider switching to that.
export const RefreshToken = () => {
  const url =
    ApiEndpoints.baseUri + ApiEndpoints.authBase + ApiEndpoints.authLogin;
  FetchService("GET", url, succeed, fail);
};

export const UnsetToken = () => {
  window.localStorage.removeItem("authToken");
};

export const isBafs = () =>
  typeof window.localStorage.authToken === "undefined"
    ? false
    : jwt.decode(getToken()).institution_id === 999;

export const permCheck = permsToCheck => {
  const token = getToken();
  const decodedToken = jwt.decode(token);

  if (!decodedToken) {
    return false;
  }

  const permsUserHas = decodedToken.permissions;
  let hasPerms = false;
  if (!Array.isArray(permsToCheck)) {
    logger.warn("perm(s) should be passed in an array");
    return false;
  }
  if (!token) {
    return false;
  }
  permsToCheck.forEach(perm => {
    if (permsUserHas.indexOf(perm) > -1) {
      hasPerms = true;
    }
  });
  return hasPerms;
};

export function DecodeToken() {
  return typeof window.localStorage.authToken === "undefined"
    ? null
    : jwt.decode(window.localStorage.getItem("authToken"));
}

export const getInstitutionUuid = () =>
  jwt.decode(window.localStorage.getItem("authToken"))?.institution_uuid;

export { getUserGroup, userCanViewPage };
