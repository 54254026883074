/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { Divider, Form, Modal, Header, Button } from "semantic-ui-react";
import { NumberInput } from "../../../../../../../../../../components/CustomFormElements";

const createHeader = "Defer Payments for Account";
const createCopy =
  'Are you sure you want to defer payments for this account? All current bills with a status of "Upcoming", "Due", or "Past Due" will be skipped. Additionally, all regular payments will treated as principal only payments until the next bill is created, and no additional bills will be due until the date set below.';
const editHeader = "Edit Deferral Date for Account";
const editCopy =
  "Changing the date below will change the date at which the deferral will end. This date will be the due date of the next normal payment. Based on the current bill notification period for this account, the deferral will end {bill_notification_period} days before the date entered below to allow time for the borrower to get a bill and make a payment.";

function isEmpty(number) {
  return !number && number !== 0;
}

function DeferPaymentModal({
  dispatch,
  onSubmit,
  loan,
  deferModalIsOpen,
  billNotificationPeriod
}) {
  const {
    bill_notification_period,
    deferral_end_date,
    is_in_deferral,
    payments_remaining
  } = loan || {};
  const [minDate, setMinDate] = useState(null);
  const [deferDate, setDeferDate] = useState(deferral_end_date);
  const [formIsValid, setFormIsValid] = useState(false);
  const [copy] = useState(
    is_in_deferral
      ? editCopy.replace("{bill_notification_period}", bill_notification_period)
      : createCopy
  );
  const [header] = useState(is_in_deferral ? editHeader : createHeader);
  const [paymentsRemaining, setPaymentsRemaining] = useState(
    isEmpty(payments_remaining) ? "" : payments_remaining
  );

  useEffect(() => {
    // billNotificationPeriod should always be set, but default to 10 just in case
    const daysToAdd = billNotificationPeriod ? 1 + billNotificationPeriod : 11;

    setMinDate(moment().add(daysToAdd, "day").format("YYYY-MM-DD"));
  }, [billNotificationPeriod]);

  return (
    <Modal open={deferModalIsOpen} size="small">
      <Header content={header} />
      <Modal.Content>
        <Form>
          <p>{copy}</p>
          <Form.Group>
            <Form.Field
              control={Form.Input}
              type="date"
              placeholder="Deferral Date"
              min={minDate}
              name="defer_date"
              id="defer_date"
              defaultValue={deferDate}
              onChange={({ target }) => {
                if (target.value >= minDate) {
                  setDeferDate(target.value);
                  setFormIsValid(true);
                } else {
                  setDeferDate(null);
                  setFormIsValid(false);
                }
              }}
              label={`Defer Account until: (earliest deferral date: ${moment(
                minDate
              ).format("MM/DD/YYYY")})`}
            />
          </Form.Group>
          <Divider />
          <p>{`Currently there are ${paymentsRemaining} payments remaining for this account. How many payments will be remaining after the deferral?`}</p>
          <Form.Group style={{ alignItems: "flex-end", display: "flex" }}>
            <NumberInput
              decimalPlaces={0}
              min={0}
              label="Payments remaining"
              name="payments_remaining"
              onChange={(_, { value }) => setPaymentsRemaining(value)}
              placeholder="Remaining payments"
              value={paymentsRemaining}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            dispatch({
              type: "PAYMENT_REDUCER_SET_DEFER_MODAL_IS_OPEN",
              deferModalIsOpen: false
            });
            setDeferDate(deferral_end_date);
          }}
          basic
        >
          Cancel
        </Button>
        <Button
          onClick={() => onSubmit({ deferDate, paymentsRemaining })}
          disabled={!formIsValid}
          primary
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

DeferPaymentModal.propTypes = {
  loan: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    deferral_count: PropTypes.number,
    is_in_deferral: PropTypes.bool,
    deferral_end_date: PropTypes.string,
    bill_notification_period: PropTypes.number
  }),
  dispatch: PropTypes.func.isRequired,
  deferModalIsOpen: PropTypes.bool,
  billNotificationPeriod: PropTypes.number,
  onSubmit: PropTypes.func
};

const mapStateToProps = state => ({
  deferModalIsOpen: state.PaymentsReducer.deferModalIsOpen,
  billNotificationPeriod:
    state.LoanManagementReducer.loan.bill_notification_period
});

export default connect(mapStateToProps)(DeferPaymentModal);
