import { FormSection } from "@bafsllc/ui-shared";
import { useSelector } from "react-redux";
import { Form } from "semantic-ui-react";
import { PropTypes } from "prop-types";
import {
  creditAnalysisFormObjectProp,
  entityDataProp,
  getEntityName,
  loanDataProp
} from "../../../utils";
import LoanDropdown from "./FormFields/LoanDropdown";
import PrimaryBorrowerDropdown from "./FormFields/PrimaryBorrowerDropdown";
import ScaleDropdown from "./FormFields/ScaleDropdown";
import PurposeDropdown from "./FormFields/PurposeDropdown";
import ApprovalAuthorityDropdown from "./FormFields/ApprovalAuthorityDropdown";
import PeriodDatepickers from "./FormFields/PeriodDatepickers";
import CreditRiskRatingDropdown from "./FormFields/CreditRiskRatingDropdown";
import JustificationTextarea from "./FormFields/JustificationTextarea";

export default function CreditAnalysisSummaryTab({
  type,
  entity,
  loan,
  creditAnalysisFormObject: {
    selectedSingleRiskScale,
    selectedSingleRiskScaleItem,
    selectedPurpose,
    selectedApprovalAuthority,
    selectedPeriodStart,
    selectedPeriodEnd,
    enteredJustification
  },
  setCreditAnalysisFormObject
}) {
  const { selectedCreditAnalysis, singleRiskScalesList } = useSelector(
    state => ({
      selectedCreditAnalysis:
        state.CreditAnalysisManagementReducer.selectedCreditAnalysis,
      singleRiskScalesList:
        state.CreditAnalysisManagementReducer.singleRiskScalesList
    })
  );

  const isViewMode = selectedCreditAnalysis?.mode === "view";

  const onDropdownChange = ({ name, value }) => {
    setCreditAnalysisFormObject(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <Form>
      <ScaleDropdown
        singleRiskScalesList={singleRiskScalesList}
        onDropdownChange={onDropdownChange}
        selectedSingleRiskScale={selectedSingleRiskScale}
        isViewMode={isViewMode}
      />
      <PrimaryBorrowerDropdown
        entityName={getEntityName({ entityData: entity })}
      />
      <LoanDropdown
        name={type}
        uuid={loan?.uuid}
        id={type === "loan" ? loan?.institution_account_number : loan?.app_id}
        entityName={getEntityName({ entity })}
        subField1={type === "loan" ? loan?.loan_class : loan?.phase}
        subField2={type === "loan" ? loan?.loan_type : loan?.status}
        type={type}
        loan={loan}
      />
      <PurposeDropdown
        onDropdownChange={onDropdownChange}
        selectedPurpose={selectedPurpose}
        isViewMode={isViewMode}
      />
      <ApprovalAuthorityDropdown
        onDropdownChange={onDropdownChange}
        selectedApprovalAuthority={selectedApprovalAuthority}
        isViewMode={isViewMode}
      />
      <PeriodDatepickers
        onDropdownChange={onDropdownChange}
        selectedPeriodStart={selectedPeriodStart}
        selectedPeriodEnd={selectedPeriodEnd}
        isViewMode={isViewMode}
      />
      <FormSection
        heading={{
          id: "CREDIT_RISK_RATING"
        }}
        description={{
          defaultMessage:
            "Select a rating to be used for the Analysis. A written justification for your selection is required."
        }}
      >
        <CreditRiskRatingDropdown
          onDropdownChange={onDropdownChange}
          selectedSingleRiskScale={selectedSingleRiskScale}
          selectedSingleRiskScaleItem={selectedSingleRiskScaleItem}
          isViewMode={isViewMode}
        />
        <JustificationTextarea
          onDropdownChange={onDropdownChange}
          enteredJustification={enteredJustification}
          isViewMode={isViewMode}
        />
      </FormSection>
    </Form>
  );
}

CreditAnalysisSummaryTab.propTypes = {
  entity: entityDataProp.isRequired,
  type: PropTypes.string.isRequired,
  loan: loanDataProp.isRequired,
  creditAnalysisFormObject: creditAnalysisFormObjectProp,
  setCreditAnalysisFormObject: PropTypes.func.isRequired
};
