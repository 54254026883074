import { SingleResponse } from "./types";
import { Tag } from "./blastApi";

/**
 * Creates an RTK Query tag object of a given kind for a single record
 * response containing a UUID field. This reduces boilerplate in our API
 * definitions. (see also {@link createListTags}, which does the same
 * for APIs that return a list of objects)
 *
 * Instead of defining the same `providesTags` behavior for each
 * get-single query definition, as in the following:
 * ```
 *      entity: build.query<ApiResponseSingle<Entity>, string>({
 *        query: (uuid) => `/entities/v2/entities/${uuid}`,
 *        providesTags: (result) => result ? [{ type: 'Entity', id: result.data.uuid }, 'Entity'] : ['Entity'],
 *      }),
 *      loan: build.query<ApiResponseSingle<Loan>, string>({
 *        query: (uuid) => `/loans/v1/${uuid}`,
 *        providesTags: (result) => result ? [{ type: 'Loan', id: result.data.uuid }, 'Loan'] : ['Loan'],
 *      }),
 * ```
 *
 * We can more succinctly write (note the use of {@link extractData}
 * to get the returned object from the `data` key):
 * ```
 *      entity: build.query<Entity, string>({
 *        query: (uuid) => `/entities/v2/entities/${uuid}`,
 *        transformResponse: (response: ApiResponseSingle<Entity>) => transformSingleResponse<Entity>(response),
 *        providesTags: (result) => createSingleTag(result, 'Entity'),
 *      }),
 *      loan: build.query<Loan, string>({
 *        query: (uuid) => `/loans/v1/${uuid}`,
 *        transformResponse: (response: ApiResponseSingle<Entity>) => transformSingleResponse<Entity>(response),
 *        providesTags: (result) => createSingleTag(result, 'Loan'),
 *      }),
 * ```
 *
 * {@link https://redux-toolkit.js.org/rtk-query/api/createApi#providestags | RTK Query `providesTags` reference}
 */
export function createSingleTag<R extends { uuid: string }>(
  resultWithUuid: R | undefined,
  tagType: Tag
) {
  return resultWithUuid
    ? [{ type: tagType, id: resultWithUuid.uuid }, tagType]
    : [tagType];
}

/**
 * Generate an array of RTK Query tag objects of a given kind for
 * a response containing a list of records with UUIDs. This reduces
 * boilerplate in our API definitions. (see also {@link createSingleTag},
 * which does the same for APIs that return a single object)
 *
 * Instead of defining the same `providesTags` behavior for each
 * get-many query definition, as in the following:
 * ```
 *      entities: build.query<ListResponse<Entity>, EntityQueryOptions>({
 *        query: filters => filteredQuery(`${entitiesBasePath}/entities`, filters),
 *        providesTags: (results) =>
 *          results
 *            ? [...results.map(({ id }) => ({ type: 'Entity' as const, id })), 'Entity']
 *            : ['Entity'],
 *      }),
 *      loans: build.query<ListResponse<Loan>, LoanQueryOptions>({
 *        query: filters => filteredQuery(`${loansBasePath}/loans`, filters),
 *        providesTags: (results) =>
 *          results
 *            ? [...results.map(({ id }) => ({ type: 'Loan' as const, id })), 'Loan']
 *            : ['Loan'],
 *      }),
 * ```
 *
 * We can more succinctly write:
 * ```
 *      entities: build.query<ListResponse<Entity>, EntityQueryOptions>({
 *        query: filters => filteredQuery(`${entitiesBasePath}/entities`, filters),
 *        providesTags: results => createListTags(results, Tag.Entity)
 *      }),
 *      loans: build.query<ListResponse<Loan>, LoanQueryOptions>({
 *        query: filters => filteredQuery(`${loansBasePath}/loans`, filters),
 *        providesTags: results => createListTags(results, Tag.Loan)
 *      }),
 * ```
 *
 * {@link https://redux-toolkit.js.org/rtk-query/api/createApi#providestags | RTK Query `providesTags` reference}
 */
export function createListTags<R extends { data: { uuid: string }[] }>(
  resultsWithUuids: R | undefined,
  tagType: Tag
) {
  return resultsWithUuids
    ? [
        { type: tagType, id: "LIST" },
        ...resultsWithUuids.data.map(({ uuid }) => ({
          type: tagType,
          id: uuid
        }))
      ]
    : [{ type: tagType, id: "LIST" }];
}

/**
 * Function to simply pull out the value of the `data` key
 * from API responses (usually those with a UUID path parameter,
 * i.e.  * getting a single, specific record).
 *
 * Typically used for the `transformResponse` option invoked upon a
 * successful single item query, since in that case the `metaData` and `error`
 * keys have `null` value. (see the {@link SingleResponse} type)
 *
 * ```
 *      entity: build.query<Entity, string>({
 *        query: (uuid) => `/entities/v2/entities/${uuid}`,
 *        transformResponse: (response: ApiResponseSingle<Entity>) => extractData<Entity>(response),
 *        providesTags: (result) => createSingleTag(result, 'Entity'),
 *      }),
 * ```
 *
 * This allows RTK Query's generated hooks (i.e. `useEntityQuery` from
 * the above) to return the expected data directly, rather than forcing
 * the caller to pull it out of the
 * ```
 * {
 *   data: ...,
 *   metaData: null,
 *   error: null
 * }
 * ```
 * object.
 *
 * {@link https://redux-toolkit.js.org/rtk-query/api/createApi#transformresponse | RTK Query `transformResponse` reference}
 */
export function extractData<T>(response: SingleResponse<T>): T {
  return response.data;
}

export function filteredQuery(
  endpointPath: string,
  queryParams: Record<string, any>
) {
  return {
    url: endpointPath,
    params: queryParams
  };
}
