import React, { useEffect } from "react";
import { Loader } from "semantic-ui-react";
import PropTypes from "prop-types";

import { CheckAuth } from "../../services/Auth";
import "./index.css";
import logger from "../../services/logger";

function Widget({ id, columns, widgetTitle }) {
  const col = columns ?? "one";
  return (
    <div className={`overviewcard ${col}`}>
      <h3>{widgetTitle}</h3>
      <div className="chart" id={id}>
        <Loader active inline="centered">
          Loading Widget Data...
        </Loader>
      </div>
    </div>
  );
}

Widget.propTypes = {
  id: PropTypes.string,
  columns: PropTypes.string,
  widgetTitle: PropTypes.string
};

function Dashboard() {
  function success(id) {
    document.querySelector(`${id} .loader`).remove();
    // HACK!!! This removes the menu.css file that was messing up our styles.
    document.querySelector('link[href*="menu.css"]')?.remove();
  }

  function error(err) {
    logger.error(err);
  }

  useEffect(() => {
    const chartsList = [
      "Portfolio_Balance_History",
      "Portfolio_Balance_Report_v3",
      "Current_Past_Due_Loans",
      "Maturing_Loans_Report",
      "Past_Due_Exceptions",
      "Past_Due_Loan_Review_Chart",
      "Upcoming_Loan_Review_Chart",
      "LOS_Summary_Report"
    ];
    const token = CheckAuth();

    // eslint-disable-next-line no-undef
    if (typeof visualize === "undefined" || !visualize || !token) {
      return;
    }

    // eslint-disable-next-line no-undef
    visualize.config({
      auth: {
        token: token.token,
        preAuth: true,
        tokenName: "pp"
      }
    });

    const loadVisualization = id => {
      const ref = `#${id}`;
      const chart = v =>
        v(ref).report({
          showAdhocChartTitle: false,
          loadingOverlay: false,
          reportContainerWidth: document.querySelector(ref).clientWidth,
          resource: `/public/Dashboard_Reports/${id}`,
          container: ref,
          scale: "container",
          success: success(ref),
          error,
          params: {
            Institution_UUID: [token.institution_uuid]
          }
        });

      // eslint-disable-next-line no-undef
      visualize(chart);
    };

    chartsList.forEach(c => loadVisualization(c));
  }, []);

  return (
    <div className="grid-container !m-[-24px] bg-slate-300">
      <div className="main-overview">
        {/* Row One */}
        <Widget
          id="Portfolio_Balance_History"
          columns="two"
          widgetTitle="Historical Portfolio Balance"
        />
        <Widget
          id="Portfolio_Balance_Report_v3"
          columns="one"
          widgetTitle="Portfolio Balance"
        />
        {/* Row Two */}
        <Widget
          id="Current_Past_Due_Loans"
          widgetTitle="Current Past Due Loans"
        />
        <Widget
          id="Upcoming_Loan_Review_Chart"
          columns="one"
          widgetTitle="Upcoming Loan Reviews"
        />
        <Widget
          id="LOS_Summary_Report"
          columns="one"
          widgetTitle="Loan Origination Pipeline"
        />
        <Widget
          id="Past_Due_Loan_Review_Chart"
          widgetTitle="Past Due Loan Reviews"
        />
        <Widget id="Past_Due_Exceptions" widgetTitle="Past Due Exceptions" />
        <Widget
          id="Maturing_Loans_Report"
          widgetTitle="Matured or Maturing Loans"
        />
      </div>
      <div className="notice">
        <div className="wrapper">
          Client is responsible for the accuracy and completeness of the data
          submitted by Client in connection with the BLAST services and any
          errors in and with respect to data obtained from the BLAST platform
          because of any inaccurate or incomplete data provided by Client to the
          BLAST platform.
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
