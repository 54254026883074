import PropTypes from "prop-types";
import React, { Component } from "react";
import TypeCheck from "typecheck-extended";
import { Form } from "formsy-semantic-ui-react";
import DocTitle from "../../../../components/DocTitle";

import Buttons from "../../components/buttons";
import { DateInput } from "../../../../components/CustomFormElements";

import { stepList } from "../../services/constants";
import {
  CreditBureauOptions,
  forceValue,
  semanticUIStates,
  validationErrors,
  validationLabel
} from "../../../../services/FormElements";

export class PersonalInfoObj extends Component {
  constructor(props) {
    super(props);
    this.updateForm = this.updateForm.bind(this);
  }

  componentDidMount() {
    const { setCurrentPage } = this.props;
    setCurrentPage(2);
  }

  updateForm(event, data) {
    TypeCheck(data.name, "string");

    let { name, value } = data;
    if (name === "is_us_citizen") {
      value = data.value === "Yes";
    } else if (name === "credit_score") {
      const { personalInfo } = this.props;
      name = personalInfo.credit_bureau;
    }

    const { dispatchFormField } = this.props;
    dispatchFormField("personalInfo", name, value);
  }

  render() {
    const {
      currentPageIndex,
      loading,
      onSave,
      onWithdraw,
      personalInfo,
      onSaveAndNext,
      toggleFormValidation
    } = this.props;
    return (
      <div>
        <DocTitle
          title={`${stepList[2]} - Auto Decision Request - BLAST Portal`}
        />
        <h2>Personal Information:</h2>
        <Form
          autoComplete="off"
          key={personalInfo.uuid}
          onInvalidSubmit={() => {
            toggleFormValidation(false);
          }}
          onValidSubmit={() => {
            toggleFormValidation(true);
          }}
        >
          <Form.Group widths="equal">
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="First Name"
              name="first_name"
              onChange={this.updateForm}
              required
              type="text"
              validationErrors={validationErrors}
              value={forceValue(personalInfo.first_name)}
            />
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Last Name"
              name="last_name"
              onChange={this.updateForm}
              required
              type="text"
              validationErrors={validationErrors}
              value={forceValue(personalInfo.last_name)}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.RadioGroup
              errorLabel={validationLabel("left")}
              label="U.S. Citizen or Legal Resident"
              name="is_us_citizen"
              onChange={this.updateForm}
              required
              validationErrors={validationErrors}
              value={personalInfo.is_us_citizen ? "Yes" : "No"}
            >
              <Form.Radio
                disabled={loading}
                label="Yes"
                name="is_us_citizen"
                value="Yes"
              />
              <Form.Radio
                disabled={loading}
                label="No"
                name="is_us_citizen"
                value="No"
              />
            </Form.RadioGroup>

            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Social Security #"
              name="tin"
              onChange={this.updateForm}
              required
              type="text"
              validationErrors={validationErrors}
              value={forceValue(personalInfo.tin)}
            />
            <DateInput
              asForm
              disabled={loading}
              label="Birthdate (mm/dd/yyyy)"
              name="dob"
              onChange={this.updateForm}
              value={forceValue(personalInfo.dob)}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Driver's License #"
              name="drivers_license_no"
              onChange={this.updateForm}
              required
              type="text"
              validationErrors={validationErrors}
              value={forceValue(personalInfo.drivers_license_no)}
            />
            <Form.Select
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Driver's License State"
              name="drivers_license_st"
              onChange={this.updateForm}
              options={semanticUIStates || []}
              required
              search
              validationErrors={validationErrors}
              value={forceValue(personalInfo.drivers_license_st)}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.RadioGroup
              errorLabel={validationLabel("left")}
              label="Primary Residence"
              name="rent_or_own"
              onChange={this.updateForm}
              required
              validationErrors={validationErrors}
              value={personalInfo.rent_or_own}
            >
              <Form.Radio
                disabled={loading}
                label="Rent"
                name="rent_or_own"
                value="Rent"
              />
              <Form.Radio
                disabled={loading}
                label="Own"
                name="rent_or_own"
                value="Own"
              />
            </Form.RadioGroup>

            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Address"
              name="phy_address_line1"
              onChange={this.updateForm}
              required
              type="text"
              validationErrors={validationErrors}
              value={forceValue(personalInfo.phy_address_line1)}
            />
            <Form.Input
              autoComplete="off"
              disabled={loading}
              label="Suite or Apt. #"
              name="phy_address_line2"
              onChange={this.updateForm}
              type="text"
              value={forceValue(personalInfo.phy_address_line2)}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="City"
              name="phy_address_city"
              onChange={this.updateForm}
              required
              type="text"
              validationErrors={validationErrors}
              value={forceValue(personalInfo.phy_address_city)}
            />
            <Form.Select
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="State"
              name="phy_address_state"
              onChange={this.updateForm}
              options={semanticUIStates || []}
              required
              search
              validationErrors={validationErrors}
              value={forceValue(personalInfo.phy_address_state)}
            />
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Zip"
              name="phy_address_zip"
              onChange={this.updateForm}
              required
              type="text"
              validationErrors={validationErrors}
              validations="isInt"
              value={forceValue(personalInfo.phy_address_zip)}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Home Phone #"
              name="phone_no"
              onChange={this.updateForm}
              required
              type="text"
              validationErrors={validationErrors}
              value={forceValue(personalInfo.phone_no)}
            />
            <Form.Input
              autoComplete="off"
              disabled={loading}
              label="Mobile Phone #"
              name="mobile_phone_no"
              onChange={this.updateForm}
              type="text"
              value={forceValue(personalInfo.mobile_phone_no)}
            />
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Email"
              name="email"
              onChange={this.updateForm}
              required
              type="text"
              validationErrors={validationErrors}
              validations="isEmail"
              value={forceValue(personalInfo.email)}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Select
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Credit Bureau"
              name="credit_bureau"
              onChange={this.updateForm}
              options={CreditBureauOptions || []}
              required
              validationErrors={validationErrors}
              value={forceValue(personalInfo.credit_bureau)}
            />
            <Form.Input
              autoComplete="off"
              disabled={loading || !personalInfo.credit_bureau}
              errorLabel={validationLabel()}
              instantValidation
              label="Credit Score"
              name="credit_score"
              onChange={this.updateForm}
              required
              type="Number"
              validationErrors={validationErrors}
              validations="isInt"
              value={forceValue(personalInfo[personalInfo.credit_bureau])}
            />
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Total Annual Income"
              name="personal_annual_income"
              onChange={this.updateForm}
              required
              type="Number"
              validationErrors={validationErrors}
              validations="isInt"
              value={forceValue(personalInfo.personal_annual_income)}
            />
          </Form.Group>

          <Buttons
            currentPage={stepList[currentPageIndex]}
            currentPageIndex={currentPageIndex}
            disabled={loading}
            onSave={onSave}
            onSaveAndNext={onSaveAndNext}
            onWithdraw={onWithdraw}
          />
        </Form>
        <p>* Required</p>
      </div>
    );
  }
}

PersonalInfoObj.propTypes = {
  currentPageIndex: PropTypes.number.isRequired,
  dispatchFormField: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  personalInfo: PropTypes.shape({
    credit_bureau: PropTypes.string,
    credit_score_equifax: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    credit_score_experian: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    credit_score_transunion: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    dob: PropTypes.string,
    drivers_license_no: PropTypes.string,
    drivers_license_st: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string,
    is_us_citizen: PropTypes.bool,
    last_name: PropTypes.string,
    mobile_phone_no: PropTypes.string,
    personal_annual_income: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    phone_no: PropTypes.string,
    phy_address_city: PropTypes.string,
    phy_address_line1: PropTypes.string,
    phy_address_line2: PropTypes.string,
    phy_address_state: PropTypes.string,
    phy_address_zip: PropTypes.string,
    rent_or_own: PropTypes.string,
    tin: PropTypes.string,
    uuid: PropTypes.string
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onSaveAndNext: PropTypes.func.isRequired,
  onWithdraw: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  toggleFormValidation: PropTypes.func.isRequired
};

const PersonalInfo = PersonalInfoObj;

export default PersonalInfo;
