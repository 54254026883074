import React from "react";
import { useSelector } from "react-redux";
import { Grid, Header } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { NAICCodes } from "../../../../../../services/FormElements";
import MaskText from "../../../../../../components/MaskText";
import Constants from "../../../../../../services/Constants/strings";
import { prettyDate } from "../../../../../../services/DateTime";
import { permCheck } from "../../../../../../services/Auth";

function DetailsBlock() {
  const {
    entityData,
    entityInstitution,
    bpOrgUserEmail,
    bpAccessControls
    // aggregateDebt, // TODO: [#6494] EM: Aggregate Debt
  } = useSelector(({ CreditManagementReducer }) => CreditManagementReducer);

  const prevReviewDate = prettyDate(entityData?.previous_review_date);
  const nextReviewDate = prettyDate(entityData?.next_review_date);

  const readPerms = [
    "admin",
    "get_bp_access_controls_unfiltered",
    "get_bp_access_controls"
  ];

  const hasPerms = !permCheck(readPerms);

  const bpStatus = bpAccessControls?.editable_by_borrower
    ? "EDITABLE"
    : "READ_ONLY";

  return (
    <Grid textAlign="left" columns="equal">
      <Grid.Row>
        <Grid.Column>
          <Header sub color="grey">
            {Constants.FEDERAL_TAX_ID_EIN}
          </Header>
          <MaskText text={entityData?.tin || ""} />
        </Grid.Column>
        <Grid.Column>
          <Header sub color="grey">
            {Constants.ENTITY_TYPE}
          </Header>
          {entityData?.entity_type}
        </Grid.Column>
        <Grid.Column>
          <Header sub color="grey">
            {Constants.FISCAL_YEAR_END}
          </Header>
          {entityData?.fiscal_year_end}
        </Grid.Column>
        <Grid.Column>
          <Header sub color="grey">
            {Constants.LOAN_OFFICER}
          </Header>
          {entityData?.officer}
          {/* TODO: officer might need to be formatted a bit differently when there's actual data */}
        </Grid.Column>
        <Grid.Column>
          <Header sub color="grey">
            {Constants.LAST_REVIEW_DATE}
          </Header>
          {prevReviewDate}
        </Grid.Column>
        {entityInstitution?.borrower_portal_service ? (
          <Grid.Column>
            <Header sub color="grey">
              {Constants.BORROWER_PORTAL_ORGANIZATION}
            </Header>
            {hasPerms ? (
              <FormattedMessage id="INSUFFICIENT_ACCESS" />
            ) : (
              <>{bpOrgUserEmail || "No email found"}</>
            )}
          </Grid.Column>
        ) : (
          <Grid.Column />
        )}
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <Header sub color="grey">
            {Constants.NAICS_CODE}
          </Header>
          {entityData?.naics_code}
        </Grid.Column>
        <Grid.Column>
          <Header sub color="grey">
            {Constants.INDUSTRY_TYPE}
          </Header>
          {NAICCodes[entityData?.naics_code]}
        </Grid.Column>
        <Grid.Column>
          <Header sub color="grey">
            {Constants.RISK_RATING}
          </Header>
          {entityData?.risk_rating}
        </Grid.Column>
        <Grid.Column>
          {/*  // TODO: [#6494] EM: Aggregate Debt */}
          {/* <Header sub color="grey">{Constants.AGGREGATE_DEBT}</Header> */}
          {/* $ */}
          {/* {new Intl.NumberFormat().format(aggregateDebt)} */}
          <Header sub color="grey">
            &nbsp;
          </Header>
        </Grid.Column>
        <Grid.Column>
          <Header sub color="grey">
            {Constants.NEXT_REVIEW_DATE}
          </Header>
          {nextReviewDate}
        </Grid.Column>

        {entityInstitution?.borrower_portal_service ? (
          <Grid.Column>
            <Header sub color="grey">
              {Constants.BORROWER_PORTAL_EDIT_STATUS}
            </Header>
            {hasPerms ? (
              <FormattedMessage id="INSUFFICIENT_ACCESS" />
            ) : (
              <FormattedMessage id={bpStatus} />
            )}
          </Grid.Column>
        ) : (
          <Grid.Column />
        )}
      </Grid.Row>
    </Grid>
  );
}

export default DetailsBlock;
