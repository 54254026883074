import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  Menu,
  Table,
  Segment,
  Container
} from "semantic-ui-react";

import DollarFormat from "../../../../../../../components/CustomUIElements/components/DollarFormat";
import { EditButton } from "../../../../../../../components/CustomFormElements";
import { actionCreators } from "../reducer";
import { useCurrentBalance } from "../hooks";

const {
  prepareEditModal,
  toggleShowAddParticipationModal,
  resetParticipationBuyerList,
  toggleShowProcessModal
} = actionCreators;

function ParticipationBuyerTable({
  participants,
  retainedAccount,
  currentBalance,
  dispatch
}) {
  const usedBalance = useCurrentBalance({ participants });

  return (
    <>
      <Container key="participationBuyerTable">
        <Grid.Row columns="1">
          <Grid.Column width="4">
            <Menu className="header" pointing secondary color="green" compact>
              <Menu.Item name="Participants" active />
            </Menu>
          </Grid.Column>
        </Grid.Row>
        <Grid>
          <Grid.Row columns="2">
            <Grid.Column>
              Balance:{" "}
              {
                <b>
                  <DollarFormat value={currentBalance - usedBalance} />
                </b>
              }
              {" / "}
              {<DollarFormat value={currentBalance} />}
            </Grid.Column>
            <Grid.Column>
              <Button
                basic
                circular
                color="green"
                floated="right"
                icon="plus"
                onClick={() => dispatch(toggleShowAddParticipationModal(true))}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Segment>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Investor</Table.HeaderCell>
                <Table.HeaderCell>Dollar Amount</Table.HeaderCell>
                <Table.HeaderCell>Investor Interest Rate</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {participants.map((participant, index) => (
                <Table.Row key={participant.investor.uuid}>
                  <Table.Cell>
                    {participant.investor && participant.investor.name}
                  </Table.Cell>
                  <Table.Cell>
                    <DollarFormat value={participant.current_contract_amount} />
                  </Table.Cell>
                  <Table.Cell>{participant.interest_rate * 100}%</Table.Cell>
                  <Table.Cell>
                    <EditButton
                      floated="left"
                      onClick={() =>
                        dispatch(prepareEditModal({ ...participant, index }))
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
              <Table.Row key="retained">
                <Table.Cell>{retainedAccount.investor.name}</Table.Cell>
                <Table.Cell>
                  <DollarFormat
                    value={retainedAccount.current_contract_amount}
                  />
                </Table.Cell>
                <Table.Cell>{retainedAccount.interest_rate * 100}%</Table.Cell>
                <Table.Cell />
              </Table.Row>
            </Table.Body>
          </Table>
        </Segment>
      </Container>
      <Container textAlign="right" key="participationActionButtons">
        <br />
        <Button
          color="grey"
          onClick={() => dispatch(resetParticipationBuyerList())}
        >
          Cancel
        </Button>
        <Button
          color="green"
          disabled={usedBalance > 0.9 * currentBalance}
          onClick={() => dispatch(toggleShowProcessModal(true))}
        >
          Submit
        </Button>
      </Container>
    </>
  );
}

ParticipationBuyerTable.propTypes = {
  participants: PropTypes.arrayOf(PropTypes.object),
  currentBalance: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  retainedAccount: PropTypes.shape({
    investor: PropTypes.shape({
      name: PropTypes.string
    }),
    current_contract_amount: PropTypes.number,
    interest_rate: PropTypes.number
  })
};

export default ParticipationBuyerTable;
