import React from "react";
import PropTypes from "prop-types";
import { Header, Button, Icon, Form } from "semantic-ui-react";
import CreditMemoTooltip from "../CreditMemoTooltip";

export const downloadPDFMessage =
  "Click the button below to build a PDF file that includes the template selection and entity selections.";
export const downloadPDFButtonText = "Download PDF of Credit Memo";

function PdfDownload({ isDisabled, fetchingPdfContent, handlePdfDownload }) {
  return (
    <div data-testid="pdf-download">
      <Header size="small">Download PDF of Credit Memo</Header>
      <div className="ui segment basic">
        <div className="ui grid">
          <div className="row">
            <p>{downloadPDFMessage}</p>
          </div>
          <div className="row">
            <Form>
              <Form.Field>
                <Button
                  data-testid="download-pdf-button"
                  primary
                  disabled={isDisabled}
                  onClick={handlePdfDownload}
                >
                  {fetchingPdfContent ? (
                    <Icon name="spinner" title="spinner-icon" loading />
                  ) : (
                    <Icon name="cloud download" title="download-icon" />
                  )}{" "}
                  {downloadPDFButtonText}
                </Button>
              </Form.Field>
              <Form.Field>
                <CreditMemoTooltip />
              </Form.Field>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

PdfDownload.propTypes = {
  isDisabled: PropTypes.bool,
  fetchingPdfContent: PropTypes.bool,
  handlePdfDownload: PropTypes.func
};

export default PdfDownload;
