import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Grid, Header, Icon, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import entityDataProps from "../../../../../../../../services/PropTypes/entityData";
import { NAICCodes } from "../../../../../../../../services/FormElements";
import { prettyDate } from "../../../../../../../../services/DateTime";
import { PrettyNumber } from "../../../../../../../../services/PrettyNumber";

import IndividualDetailsView from "../../../../../../../../components/EntityDetailsViews/Individual";
import LightHeader from "../../../../../../../../components/LightHeader";
import { BorderedContainer } from "../../../../../../../../components/CustomUIElements";
import { EntityModal } from "../../../../../../../../components/Modals";

export class Details extends Component {
  static formatPhone(num) {
    const cleaned = `${num}`.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return "";
  }

  constructor(props) {
    super(props);

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onSuccessSave = this.onSuccessSave.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  onCloseModal() {
    const { dispatch } = this.props;
    dispatch({
      type: "CMS_DETAILS_SHOW_MODAL",
      showDetailsModal: false
    });
  }

  onSuccessSave(model) {
    const { dispatch } = this.props;
    this.onCloseModal();
    dispatch({
      data: model.data,
      type: "CMS_ENTITY_DATA_LOAD"
    });
  }

  showModal() {
    const { dispatch } = this.props;
    dispatch({
      type: "CMS_DETAILS_SHOW_MODAL",
      showDetailsModal: true
    });
  }

  render() {
    const { entityData, showDetailsModal, loanOfficers } = this.props;
    if (entityData.entity_type === "Individual") {
      return (
        <IndividualDetailsView
          entityData={entityData}
          showDetailsModal={showDetailsModal}
          loanOfficers={loanOfficers}
          showModal={this.showModal}
          onCloseModal={this.onCloseModal}
          onSuccessSave={this.onSuccessSave}
          formatPhone={Details.formatPhone}
        />
      );
    }
    return (
      <BorderedContainer fluid>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as="h1" size="large">
                Entity Information
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Button
                basic
                circular
                icon
                floated="right"
                onClick={this.showModal}
              >
                <Icon name="pencil alternate" color="green" />
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid columns={5}>
          <Grid.Row>
            <Grid.Column>
              <LightHeader>Entity Type</LightHeader>
              <b>{entityData.entity_type}</b>
            </Grid.Column>
            <Grid.Column>
              <LightHeader>State of Registration</LightHeader>
              <b>{entityData.state_of_reg}</b>
            </Grid.Column>
            <Grid.Column>
              <LightHeader>County of Registration</LightHeader>
              <b>{entityData.county_of_reg}</b>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {entityData.entity_type === "Individual" ? (
              ""
            ) : (
              <Grid.Column width={9} style={{ marginRight: "2.5em" }}>
                <LightHeader>Industry Type</LightHeader>
                <b>{NAICCodes[entityData.naics_code]}</b>
              </Grid.Column>
            )}
            <Grid.Column style={{ marginLeft: "27px" }}>
              <LightHeader>TIN</LightHeader>
              <b>{entityData.tin}</b>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <LightHeader>Loan Review Type</LightHeader>
              <b>{entityData.loan_review_type}</b>
            </Grid.Column>
            <Grid.Column>
              <LightHeader>Business Start Date</LightHeader>
              <b>{prettyDate(entityData.business_start_date)}</b>
            </Grid.Column>
            <Grid.Column>
              <LightHeader>Years In Business</LightHeader>
              <b>
                {entityData.business_start_date
                  ? moment(new Date()).format("YYYY") -
                    moment(entityData.business_start_date).format("YYYY")
                  : ""}
              </b>
            </Grid.Column>
            <Grid.Column>
              <LightHeader>Tax Reporting Form</LightHeader>
              <b>{entityData.tax_reporting_form}</b>
            </Grid.Column>
            <Grid.Column>
              <LightHeader>Client Since</LightHeader>
              <b>{prettyDate(entityData.member_since)}</b>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <LightHeader>Is Operating Entity</LightHeader>
              <b>{entityData.is_operating_entity ? "Yes" : "No"}</b>
            </Grid.Column>
            <Grid.Column>
              <LightHeader>Annual Revenue</LightHeader>
              <b>
                {entityData.annual_revenue
                  ? `$${PrettyNumber(entityData.annual_revenue)}`
                  : ""}
              </b>
            </Grid.Column>
            <Grid.Column>
              <LightHeader>Fiscal Year End</LightHeader>
              <b>{entityData.fiscal_year_end}</b>
            </Grid.Column>
            <Grid.Column>
              <LightHeader>Bus. Operating Status</LightHeader>
              <b>{entityData.business_operating_status}</b>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as="h1" size="large">
                Contact Information
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid columns={3}>
          <Grid.Column>
            <LightHeader>Physical Address:</LightHeader>
            <b>{entityData.phy_address_line1}</b>
            <br />
            {entityData.phy_address_line2 ? (
              <b>
                <span>
                  {entityData.phy_address_line2}
                  <br />
                </span>
              </b>
            ) : (
              ""
            )}
            <span>
              <b>
                {entityData.phy_address_city
                  ? `${entityData.phy_address_city}, `
                  : ""}
              </b>
              <b>{entityData.phy_address_state}</b>
              <br />
            </span>
            <b>{entityData.phy_address_zip}</b>
            <br />
            <b>{entityData.phy_address_country}</b>
          </Grid.Column>
          <Grid.Column>
            <LightHeader>Mailing Address</LightHeader>
            <b>{entityData.mail_address_line1}</b>
            <br />
            {entityData.mail_address_line2 ? (
              <span>
                <b>{entityData.mail_address_line2}</b>
                <br />
              </span>
            ) : (
              ""
            )}
            <span>
              <b>
                {entityData.mail_address_city
                  ? `${entityData.mail_address_city}, `
                  : ""}
              </b>
              <b>{entityData.mail_address_state}</b>
              <br />
            </span>
            <b>{entityData.mail_address_zip}</b>
            <br />
            <b>{entityData.mail_address_country}</b>
            <br />
          </Grid.Column>
          <Grid.Column>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column width={6}>
                  <LightHeader>Phone Number</LightHeader>
                  <b>{Details.formatPhone(entityData.phone_no)}</b>
                </Grid.Column>
                <Grid.Column>
                  <LightHeader>Phone Number (Mobile)</LightHeader>
                  <b>{Details.formatPhone(entityData.mobile_phone_no)}</b>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6}>
                  <LightHeader>Email</LightHeader>
                  <b>{entityData.email}</b>
                </Grid.Column>
                <Grid.Column>
                  <LightHeader>Website</LightHeader>
                  <b>{entityData.website}</b>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid>
        <Divider />
        <Grid>
          <Grid.Row>
            <Grid.Column style={{ marginTop: "6px" }}>
              <Header as="h1" size="large">
                Credit Information
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid columns={3}>
          <Grid.Column>
            <LightHeader>Previous Review Date</LightHeader>
            <b>{prettyDate(entityData.previous_review_date)}</b>
            <br />
          </Grid.Column>
          <Grid.Column>
            <LightHeader>Borrower Rating</LightHeader>
            <b>{entityData.risk_rating}</b>
            <br />
          </Grid.Column>
          {entityData.entity_type === "individual" ? (
            ""
          ) : (
            <Grid.Column>
              <LightHeader>FICO SBSS:</LightHeader>
              <b>{entityData.fico_sbss}</b>
              <br />
            </Grid.Column>
          )}
          <Grid.Column>
            <LightHeader>Next Review Date</LightHeader>
            <b>{prettyDate(entityData.next_review_date)}</b>
            <br />
          </Grid.Column>
          {entityData.entity_type === "individual" ? (
            ""
          ) : (
            <Grid.Column>
              <LightHeader>Duns Number:</LightHeader>
              <b>{entityData.duns_number}</b>
              <br />
            </Grid.Column>
          )}
          <Grid.Column>
            <Grid.Row columns={2}>
              <LightHeader>Credit Score:</LightHeader>
              <b>{entityData.credit_bureau}</b>
            </Grid.Row>
            <b>
              {entityData.credit_bureau === "Experian"
                ? entityData.credit_score_experian
                : ""}
            </b>
            <b>
              {entityData.credit_bureau === "Transunion"
                ? entityData.credit_score_transunion
                : ""}
            </b>
            <b>
              {entityData.credit_bureau === "Equifax"
                ? entityData.credit_score_equifax
                : ""}
            </b>
          </Grid.Column>
        </Grid>
        {showDetailsModal ? (
          <EntityModal
            entityUuid={entityData.uuid}
            onCloseModal={this.onCloseModal}
            onSuccess={this.onSuccessSave}
            loanOfficers={loanOfficers}
            open
          />
        ) : (
          ""
        )}
      </BorderedContainer>
    );
  }
}

Details.propTypes = {
  dispatch: PropTypes.func.isRequired,
  entityData: entityDataProps.isRequired,
  loanOfficers: PropTypes.arrayOf(PropTypes.object).isRequired,
  showDetailsModal: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  entityData: state.CreditManagementReducer.entityData,
  loanOfficers: state.CreditManagementReducer.loanOfficers,
  showDetailsModal: state.CreditManagementDetailsReducer.showDetailsModal
});

export default connect(mapStateToProps)(Details);
