import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Grid, Icon, Menu, Table } from "semantic-ui-react";

import FetchService from "../../../../../../services/FetchService";
import { relationshipTypes } from "../../../../../../services/Constants/relationshipTypes";
import { FormatUrlV2 } from "../../../../../../services/FormatUrl";
import ApiEndpoints from "../../../../../../services/ApiEndpoints";
import { ConcatName } from "../../../../../../services/Entities";
import LoanRelAddModal from "./components/LoanRelAddModal";
import LoanRelEditModal from "./components/LoanRelEditModal";
import { BorderedContainer } from "../../../../../../components/CustomUIElements";
import { EditButton } from "../../../../../../components/CustomFormElements";
import {
  Cms,
  Relationships as RelationshipsApi
} from "../../../../../../services/ApiLib";
import logger from "../../../../../../services/logger";

function onError(rsp) {
  logger.error("Error: ", rsp); // TODO: Handle errors properly
}

export class RelationshipsObj extends Component {
  static deleteRelationship(relUuid, onSuccess) {
    const url = FormatUrlV2(ApiEndpoints.relationshipsDelete, {
      uuid: relUuid
    });
    FetchService("DELETE", url, onSuccess, onError);
  }

  constructor(props) {
    super(props);
    this.createMenuTabs = this.createMenuTabs.bind(this);
    this.createCoBorrowerTable = this.createCoBorrowerTable.bind(this);
    this.createGuarantorsTable = this.createGuarantorsTable.bind(this);
    this.createTableRows = this.createTableRows.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.setCoborrowerDetails = this.setCoborrowerDetails.bind(this);
    this.setGuarantorDetails = this.setGuarantorDetails.bind(this);
    this.handleOpenLoanRelAddModal = this.handleOpenLoanRelAddModal.bind(this);
    this.getCoborrowers = this.getCoborrowers.bind(this);
    this.getGuarantors = this.getGuarantors.bind(this);
    this.handleOpenLoanRelEditModal =
      this.handleOpenLoanRelEditModal.bind(this);
    this.editModalContainer = this.editModalContainer.bind(this);
    this.processEntity = this.processEntity.bind(this);
  }

  componentDidMount() {
    this.getCoborrowers();
    this.getGuarantors();
  }

  getCoborrowers = () => {
    const { loanUuid } = this.props;
    RelationshipsApi.asyncRead({
      parent_uuid: loanUuid,
      rel_type: relationshipTypes.BORROWER,
      primary_borrower: false
    })
      .then(result => this.setCoborrowerDetails(result))
      .catch(error => onError(error));
  };

  getGuarantors() {
    const { loanUuid } = this.props;
    RelationshipsApi.asyncReadRelationshipByType(
      loanUuid,
      relationshipTypes.GUARANTOR
    )
      .then(result => this.setGuarantorDetails(result))
      .catch(error => onError(error));
  }

  setCoborrowerDetails({ data }) {
    data.forEach(coborrower => {
      this.lookupEntityUuid(coborrower.child_uuid, coborrower.uuid);
    });

    this.props.dispatch({
      type: "LOAN_MGMT_SET_COBORROWER_DETAILS",
      loanCoborrowers: data
    });
  }

  setGuarantorDetails({ data }) {
    data.forEach(guarantor => {
      this.lookupEntityUuid(guarantor.child_uuid, guarantor.uuid);
    });

    this.props.dispatch({
      type: "LOAN_MGMT_SET_GUARANTOR_DETAILS",
      loanGuarantors: data
    });
  }

  lookupEntityUuid = (childUuid, relUuid) => {
    Cms.asyncRead(childUuid)
      .then(result => {
        this.processEntity(result, { relUuid });
      })
      .catch(error => {
        onError(error);
      });
  };

  processEntity({ data }, relUuid) {
    this.props.dispatch({
      type: "LOAN_MGMT_SET_LOOKUP_DETAILS",
      relUuid,
      entityData: data
    });
  }

  handleItemClick(name) {
    this.props.dispatch({
      type: "LOAN_MGMT_MENU_ITEM",
      menuItem: name
    });
  }

  handleOpenLoanRelAddModal() {
    this.props.dispatch({
      type: "LOAN_MGMT_OPEN_ADD_MODAL"
    });
  }

  handleOpenLoanRelEditModal(guarantorIndex) {
    this.props.dispatch({
      type: "LOAN_MGMT_OPEN_EDIT_MODAL",
      guarantorIndex
    });
  }

  editModalContainer() {
    if (this.props.editModalFormData) {
      return <LoanRelEditModal openModal={this.props.loanRelEditModalOpen} />;
    }
    return null;
  }

  createMenuTabs() {
    return (
      <Menu pointing secondary color="green">
        <Menu.Item
          name="co-borrowers"
          active={this.props.activeItem === "co-borrowers"}
          onClick={() => {
            this.handleItemClick("co-borrowers");
          }}
        />
        <Menu.Item
          name={relationshipTypes.GUARANTOR}
          active={this.props.activeItem === relationshipTypes.GUARANTOR}
          onClick={() => {
            this.handleItemClick(relationshipTypes.GUARANTOR);
          }}
        />
      </Menu>
    );
  }

  createCoBorrowerTable() {
    const coBorrowerTable = (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>TIN</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.createTableRows()}</Table.Body>
      </Table>
    );
    return coBorrowerTable;
  }

  createGuarantorsTable() {
    const coBorrowerTable = (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>TIN</Table.HeaderCell>
            <Table.HeaderCell>Full vs. Limited</Table.HeaderCell>
            <Table.HeaderCell>Guaranteed Amount</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.createTableRows()}</Table.Body>
      </Table>
    );
    return coBorrowerTable;
  }

  createTableRows() {
    switch (this.props.activeItem) {
      case "co-borrowers": {
        return this.props.coBorrowers.map(coborrower => (
          <Table.Row key={coborrower.uuid + coborrower.child_uuid}>
            <Table.Cell>
              {this.props.lookupObj[coborrower.child_uuid]
                ? ConcatName(this.props.lookupObj[coborrower.child_uuid])
                : ""}
            </Table.Cell>
            <Table.Cell>
              {this.props.lookupObj[coborrower.child_uuid]
                ? this.props.lookupObj[coborrower.child_uuid].tin
                : ""}
            </Table.Cell>
            <Table.Cell collapsing>
              <Button
                basic
                circular
                icon={<Icon color="green" name="delete" />}
                onClick={() =>
                  RelationshipsObj.deleteRelationship(
                    coborrower.uuid,
                    this.getCoborrowers
                  )
                }
              />
            </Table.Cell>
          </Table.Row>
        ));
      }
      case relationshipTypes.GUARANTOR: {
        return this.props.guarantors.map((guarantor, guarantorIndex) => (
          <Table.Row key={guarantor.uuid}>
            <Table.Cell>
              {this.props.lookupObj[guarantor.child_uuid]
                ? ConcatName(this.props.lookupObj[guarantor.child_uuid])
                : ""}
            </Table.Cell>
            <Table.Cell>
              {this.props.lookupObj[guarantor.child_uuid]
                ? this.props.lookupObj[guarantor.child_uuid].tin
                : ""}
            </Table.Cell>
            <Table.Cell>{guarantor.full_vs_limited}</Table.Cell>
            <Table.Cell>
              {guarantor.amount_or_percent === "amount"
                ? `$${guarantor.amount_guaranteed}`
                : `${guarantor.percent_guaranteed * 100} %`}
            </Table.Cell>
            <Table.Cell collapsing>
              <Button
                basic
                circular
                icon={<Icon color="green" name="delete" />}
                onClick={() =>
                  RelationshipsObj.deleteRelationship(
                    guarantor.uuid,
                    this.getGuarantors
                  )
                }
              />
              <EditButton
                onClick={() => this.handleOpenLoanRelEditModal(guarantorIndex)}
              />
            </Table.Cell>
          </Table.Row>
        ));
      }
      default:
        break;
    }
    logger.debug("no valid activeItem");
    return null;
  }

  renderTable() {
    switch (this.props.activeItem) {
      case "co-borrowers":
        return this.createCoBorrowerTable();
      case relationshipTypes.GUARANTOR:
        return this.createGuarantorsTable();
      default:
        logger.debug("no valid activeItem");
    }
    return "failed to load";
  }

  render() {
    const { loanUuid } = this.props;
    const grid = (
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column />
          <Grid.Column>
            <Button
              basic
              circular
              icon
              floated="right"
              onClick={this.handleOpenLoanRelAddModal}
            >
              <Icon name="plus" color="green" />
            </Button>
          </Grid.Column>
        </Grid.Row>
        <LoanRelAddModal openModal={this.props.loanRelAddModalOpen} />
        {this.editModalContainer()}
      </Grid>
    );

    return (
      <BorderedContainer fluid>
        {this.createMenuTabs()}
        {loanUuid ? grid : null}
        {this.renderTable()}
      </BorderedContainer>
    );
  }
}

RelationshipsObj.defaultProps = {
  activeItem: "co-borrowers",
  lookupObj: undefined
};

RelationshipsObj.propTypes = {
  dispatch: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
  coBorrowers: PropTypes.arrayOf(PropTypes.object).isRequired,
  guarantors: PropTypes.arrayOf(PropTypes.object).isRequired,
  loanRelAddModalOpen: PropTypes.bool.isRequired,
  loanRelEditModalOpen: PropTypes.bool.isRequired,
  loanUuid: PropTypes.string,
  lookupObj: PropTypes.shape({
    first_name: PropTypes.string,
    middle_name: PropTypes.string,
    last_name: PropTypes.string,
    tin: PropTypes.string
  }),
  editModalFormData: PropTypes.shape({
    first_name: PropTypes.string,
    middle_name: PropTypes.string,
    last_name: PropTypes.string,
    tin: PropTypes.string
  }).isRequired
};

const mapStateToProps = state => ({
  activeItem: state.LoanManagementRelationshipsReducer.activeItem,
  entityUuid: state.LoanManagementReducer.entityData.uuid,
  loanUuid: state.LoanManagementReducer.loan.uuid,
  coBorrowers: state.LoanManagementRelationshipsReducer.coBorrowers,
  guarantors: state.LoanManagementRelationshipsReducer.guarantors,
  loanRelAddModalOpen:
    state.LoanManagementRelationshipsReducer.loanRelAddModalOpen,
  loanRelEditModalOpen:
    state.LoanManagementRelationshipsReducer.loanRelEditModalOpen,
  editModalFormData: state.LoanManagementRelationshipsReducer.editModalFormData,
  lookupObj: state.LoanManagementRelationshipsReducer.lookupObj
});

const Relationships = connect(mapStateToProps)(RelationshipsObj);

export default Relationships;
