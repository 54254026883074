import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import TypeCheck from "typecheck-extended";
import { Icon } from "semantic-ui-react";

import { Users, Entities, Institutions } from "../../services/ApiLib";
import { ConcatName } from "../../services/Entities";

export class UuidLookupsObj extends Component {
  constructor(props) {
    super(props);
    this.dispatchLookup = this.dispatchLookup.bind(this);
  }

  componentDidMount() {
    const { uuid, lookupType } = this.props;
    this.lookupString(uuid, lookupType);
  }

  dispatchLookup(uuid, string) {
    const { dispatch } = this.props;
    dispatch({
      string,
      type: "UUID_LOOKUPS_ADD_STRING",
      uuid
    });
  }

  lookupInstitution(uuid) {
    const filters = { institutionUuid: uuid };
    Institutions.asyncRead(filters).then(rsp => {
      const institutionName = rsp.data.name || "Institution Not Found";
      this.dispatchLookup(uuid, institutionName);
    });
  }

  lookupUser(uuid) {
    const filters = { userUuid: uuid };
    Users.asyncRead(filters).then(rsp => {
      const userFullName = ConcatName(rsp) || "User Not Found";
      this.dispatchLookup(uuid, userFullName);
    });
  }

  lookupEntity(uuid) {
    const filters = { entityUuid: uuid };
    Entities.read(filters).then(rsp => {
      const entityFullName = ConcatName(rsp.data) || "Entity Not Found";
      this.dispatchLookup(uuid, entityFullName);
    });
  }

  lookupString(uuid, lookupType) {
    TypeCheck(uuid, "string");
    TypeCheck(lookupType, "enum", true, ["user", "entity", "institution"]);

    const { lookupData } = this.props;
    if (lookupData[uuid]) {
      return;
    }

    switch (lookupType) {
      case "user":
        this.lookupUser(uuid);
        break;

      case "entity":
        this.lookupEntity(uuid);
        break;

      case "institution":
        this.lookupInstitution(uuid);
        break;

      default:
    }
  }

  render() {
    const { lookupData, uuid } = this.props;
    if (!lookupData[uuid]) {
      return <Icon name="spinner" loading />;
    }
    return lookupData[uuid];
  }
}

UuidLookupsObj.propTypes = {
  dispatch: PropTypes.PropTypes.func.isRequired,
  lookupData: PropTypes.objectOf(PropTypes.string).isRequired,
  lookupType: PropTypes.PropTypes.string.isRequired,
  uuid: PropTypes.PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  lookupData: state.UuidLookupsReducer.lookupData
});

const UuidLookups = connect(mapStateToProps)(UuidLookupsObj);

export default UuidLookups;
