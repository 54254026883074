import { connect } from "react-redux";
import {
  actions,
  selectors
} from "../../../../../../../../store/models/account-history-logs";
import AcctHistory from "./AcctHistory";

const removeEmptyKeys = obj =>
  Object.entries(obj).reduce(
    (o, [key, val]) => (val ? { ...o, [key]: val } : o),
    {}
  );

const mapStateToProps = state => ({
  logs: selectors.logs(state),
  pagination: selectors.pagination(state),
  error: selectors.error(state),
  tableOptions: { state: { sortBy: ["processing_order"] } }
});

const mapDispatchToProps = (dispatch, { loan }) => ({
  onView() {
    const action = actions.retrieve(
      { loan_uuid: loan.uuid },
      { pagination: { number: 0, size: 20 } }
    );
    dispatch(action);
  },
  onFilterChange({ searchQuery, startDate, endDate, pagination }) {
    const identifiers = removeEmptyKeys({
      loan_uuid: loan.uuid,
      searchQuery,
      startDate,
      endDate
    });
    const action = actions.retrieve(identifiers, { pagination });
    dispatch(action);
  },
  onCleanup() {
    dispatch(actions.clearPagination());
  },
  onPaginationChange(pagination, { searchQuery, startDate, endDate }) {
    const identifiers = removeEmptyKeys({
      loan_uuid: loan.uuid,
      searchQuery,
      startDate,
      endDate
    });
    dispatch(actions.retrieve(identifiers, { pagination }));
  },
  onDismissError() {
    dispatch(actions.clearError());
  }
  // TODO: implement reordering
});

export default connect(mapStateToProps, mapDispatchToProps)(AcctHistory);
