import React from "react";
import { node, string, bool } from "prop-types";
import { Container } from "semantic-ui-react";

function BorderedContainer({
  backgroundColor,
  border,
  children,
  margin,
  padding,
  width,
  fluid
}) {
  return (
    <Container
      style={{
        backgroundColor,
        border,
        margin,
        padding,
        width
      }}
      fluid={fluid}
    >
      {children}
    </Container>
  );
}

BorderedContainer.defaultProps = {
  backgroundColor: "white",
  border: "1px solid rgba(222,222,222,1)",
  margin: "",
  padding: "1rem",
  width: "",
  fluid: false
};

BorderedContainer.propTypes = {
  backgroundColor: string,
  border: string,
  children: node.isRequired,
  margin: string,
  padding: string,
  width: string,
  fluid: bool
};

export default BorderedContainer;
