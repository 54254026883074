import React, { useEffect, useRef, useState } from "react";
import { Checkbox, Label, Icon } from "semantic-ui-react";
import {
  ConfirmationDialog,
  SortingTable,
  useSortingTable,
  AddPanel,
  IconButton,
  HighFrictionDialog,
  SearchListDropdown,
  Button,
  usePagination,
  PaginationView
} from "@bafsllc/ui-shared";
import { useDispatch, useSelector } from "react-redux";
import logger from "../../../../../../../../services/logger";
import { Entities } from "../../../../../../../../services/ApiLib";
import { BorrowerPortalLinksSideSheet } from "./SideSheet";
import { useBPLinksContext } from "./bp-links-context";
import BorrowerPortalLinksHeaders from "./headers";
import "./index.css";
import { getEntityInformation } from "../../../../../../actions/entities";
import MESSAGES from "../../../../../../../../assets/messages/common.en-US.json";

import { PAGE_SIZE_OPTIONS } from "../../../../constants";

const { BORROWER_PORTAL_ORGANIZATION, VERIFIED, PENDING } = MESSAGES;

function BorrowerPortalLinks() {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const { entityData, bpOrgUserEmail, bpOrgUserActiveStatus } = useSelector(
    ({ CreditManagementReducer }) => CreditManagementReducer
  );

  const {
    currentLinkedEntities,
    dropdownListItems,
    fetchEntities,
    fetchLinkedEntities,
    handleEntityNameInputChange,
    linkedEntitiesTotalCount,
    linkEntitiesToOrg,
    resetBpSideSheetData
  } = useBPLinksContext(entityData);
  const [openUnlinkModal, setUnlinkModal] = useState(false);
  const {
    sortState,
    frontendSort,
    onSortStateChanged,
    checkList,
    onChecklistChanged
  } = useSortingTable();
  const [isBpSideSheetOpen, setIsBpSideSheetOpen] = useState(false);
  const [dropdownSelectedUuids, setDropdownSelectedUuids] = useState([]);
  const [entityUuidToUnlink, setEntityUuidToUnlink] = useState("");
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const { currentPage, pageSize, onPageChange, onPageSizeChange } =
    usePagination();

  /*
    dropdownOnChange is a handler for changes in checkbox selection.
    It's designed to add/remove entities from the "selected" state.
    See handleEntityNameInput() for handling as user types an entity name.
  */
  const dropdownOnChange = item => {
    const { key } = item;

    if (dropdownSelectedUuids.includes(key)) {
      setDropdownSelectedUuids(prev =>
        [...prev].filter(prevKey => prevKey !== key)
      );
    } else {
      setDropdownSelectedUuids(prev => [key, ...prev]);
    }
  };

  const dropdownMakeCustomListItem = ({
    key,
    displayText,
    entityType,
    concealedTin
  }) => (
    <li
      aria-label={displayText}
      data-testid={`addEntities-menuItem-${key}`}
      data-item-key={key}
      key={key}
      className="flex items-start !justify-start p-4 item-custom"
    >
      <Checkbox checked={dropdownSelectedUuids?.includes(key)} />
      <div className="ml-2">
        <div className="font-semibold text-slate-950 mb-1">{displayText}</div>
        <div className="text-neutral-600 mb-1">{entityType}</div>
        <div className="text-neutral-600">{concealedTin}</div>
      </div>
    </li>
  );

  const dropdownOnCollapsed = isCollapsed => {
    if (
      isCollapsed &&
      dropdownSelectedUuids.length > 0 &&
      !isOpenConfirmationModal
    ) {
      setDropdownSelectedUuids([]);
    }
  };

  const handleActionUnlink = selectedEntity => {
    setEntityUuidToUnlink(selectedEntity?.uuid);
    setUnlinkModal(true);
  };

  const unlinkEntities = async (uuids = []) => {
    const promises = uuids.map(async uuid => [
      await Entities.deleteBpOrgLink(uuid)
    ]);

    try {
      await Promise.all(promises);
      fetchLinkedEntities();
      fetchEntities();
    } catch (error) {
      logger.error(error);
    }
  };

  const headers = BorrowerPortalLinksHeaders(handleActionUnlink);

  const toggleBpSideSheet = isOpen => {
    setIsBpSideSheetOpen(isOpen);
    if (!isOpen) {
      resetBpSideSheetData();
    }
  };

  const handleCloseUnlinkModal = () => {
    setEntityUuidToUnlink("");
    onChecklistChanged([]);
    setUnlinkModal(false);
  };

  useEffect(() => {
    fetchLinkedEntities(currentPage, pageSize);
  }, [currentPage, pageSize, fetchLinkedEntities]);

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  if (!entityData?.uuid) {
    return null;
  }
  const activeStatus = bpOrgUserActiveStatus === false;
  const labelConfig = {
    color: activeStatus ? "yellow" : "green",
    size: "medium",
    basic: true,
    icon: {
      name: activeStatus ? "envelope" : "check",
      color: "grey"
    },
    text: activeStatus ? PENDING : VERIFIED
  };
  const renderPagination = linkedEntitiesTotalCount > 0;

  return (
    <>
      <div>
        <div className="flex justify-between mt-10 mb-8 items-center">
          <div>
            {bpOrgUserEmail && (
              <div className="flex items-center">
                <div className="text-slate-950 flex items-center">
                  <div className="font-semibold ml-1 mr-2">
                    {`${BORROWER_PORTAL_ORGANIZATION}:`}
                  </div>
                  <div className="mr-1">{bpOrgUserEmail}</div>
                </div>
                <Label
                  color={labelConfig.color}
                  size={labelConfig.size}
                  basic
                  className="ml-2"
                >
                  <Icon {...labelConfig.icon} />
                  {labelConfig.text}
                </Label>
              </div>
            )}
          </div>
          <div className="flex items-center">
            <div className="ml-4">
              <IconButton
                disabled={!checkList.length}
                message={{ id: "UNLINK" }}
                iconLeft={{ icon: "close" }}
                size="tiny"
                onClick={() => setUnlinkModal(true)}
                variant="secondary"
              />
            </div>

            <div className="ml-4">
              <SearchListDropdown
                ref={dropdownRef}
                makeCustomListItem={dropdownMakeCustomListItem}
                updateDropdownClassnames={initialClassNames => [
                  ...initialClassNames,
                  "BorrowerPortalLinks-AddExisting"
                ]}
                items={dropdownListItems}
                disabled={!entityData?.bp_organization_uuid}
                testid="BorrowerPortalLinks-AddExisting"
                button={{
                  size: "tiny",
                  variant: "secondary",
                  message: { id: "ADD_ENTITIES_LINK" }
                }}
                onChange={dropdownOnChange}
                onInputChange={handleEntityNameInputChange}
                onCollapsed={dropdownOnCollapsed}
                placeholder="Entity TIN/Name..."
                listFooter={
                  <div className="flex justify-center p-2 border-t-gray-200">
                    <Button
                      message={{ id: "LINK_TO_BP_ORG" }}
                      size="tiny"
                      onClick={async e => {
                        e.stopPropagation();

                        setIsOpenConfirmationModal(true);
                      }}
                      disabled={dropdownSelectedUuids.length === 0}
                      style={{ width: "100%" }}
                    />
                  </div>
                }
                preventAutoClose
              />
            </div>

            <div className="ml-4">
              <IconButton
                disabled={bpOrgUserActiveStatus}
                message={{ id: "SET_UP_BP_LINK_TEXT" }}
                iconLeft={{ icon: "user friends" }}
                size="tiny"
                onClick={() => toggleBpSideSheet(true)}
              />
            </div>
          </div>
        </div>
        <SortingTable
          idProperty="uuid"
          className="vertically padded"
          headers={headers}
          disabledProperty="disabled"
          partiallyDisabledProperty="partiallyDisabled"
          list={frontendSort(currentLinkedEntities)}
          checkList={checkList}
          onChecklistChanged={onChecklistChanged}
          sortState={sortState}
          onSortStateChanged={onSortStateChanged}
        />
        {renderPagination && (
          <PaginationView
            className="mb-6"
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            pageSize={pageSize}
            total={linkedEntitiesTotalCount}
            current={currentPage}
          />
        )}

        {!entityData?.bp_organization_uuid && (
          <AddPanel
            title={{ id: "NO_BORROWER_PORTAL_ORGANIZATION_LINK" }}
            description={{
              id: "NO_BORROWER_PORTAL_ORGANIZATION_LINK_DESCRIPTION"
            }}
          />
        )}
      </div>

      {isBpSideSheetOpen && (
        <BorrowerPortalLinksSideSheet
          isOpen={isBpSideSheetOpen}
          onClose={() => toggleBpSideSheet(false)}
        />
      )}

      {isOpenConfirmationModal && (
        <HighFrictionDialog
          affirmative={{ id: "CONFIRM" }}
          negative={{ id: "CANCEL" }}
          header={{ id: "BP_ORGANIZATION" }}
          confirmationMessages={[
            { message: { id: "BP_ORGANIZATION_LINK_MESSAGE" } },
            { message: { id: "FRICTION_DIALOG_ENTER_MESSAGE" } }
          ]}
          onDismiss={async dismissVariant => {
            if (dismissVariant === "affirmative") {
              await linkEntitiesToOrg(
                dropdownSelectedUuids,
                entityData?.bp_organization_uuid
              );
            }
            setDropdownSelectedUuids([]);
            setIsOpenConfirmationModal(false);
          }}
        />
      )}
      {openUnlinkModal && (
        <ConfirmationDialog
          affirmative={{ id: "CONFIRM" }}
          negative={{ id: "CANCEL" }}
          header={{ id: "BP_ORGANIZATION_UNLINK_HEADER" }}
          content={{ displayText: "" }}
          subText={
            <div>
              <b>Are you sure you want to proceed with this action?</b>
              <p>
                By unlinking the entity, the entity will no longer be available
                to the organization in Borrower Portal.
              </p>
            </div>
          }
          onDismiss={async data => {
            if (data === "affirmative") {
              let entityUuids = checkList.length > 0 ? checkList : [];

              if (entityUuidToUnlink) {
                // This logic and its placement is required to cover an edge
                // case when a user ticks at least one checkbox from the table
                // and then the user clicks the trash [unlink] button of a
                // different entity in its row in the table
                entityUuids = [entityUuidToUnlink];
              }

              await unlinkEntities(entityUuids);
              await dispatch(getEntityInformation(entityData?.uuid));

              handleCloseUnlinkModal();
              return;
            }

            // User clicked "Cancel"
            handleCloseUnlinkModal();
          }}
          hide={!openUnlinkModal}
        />
      )}
    </>
  );
}

export default BorrowerPortalLinks;
