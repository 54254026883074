import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Checkbox } from "semantic-ui-react";

export function NotificationSettingsObj({
  dispatch,
  jwt,
  notificationSettings,
  saveMessage,
  userUuid
}) {
  useEffect(() => {
    dispatch({
      type: "GET_NOTIFICATION_VALUES",
      fetchParams: {
        jwt,
        userUuid
      },
      dispatchParams: {
        dispatch,
        dispatchCallback: "SET_NOTIFICATION_VALUES"
      }
    });

    return () => {
      dispatch({
        type: "RESET_USER_MESSAGE"
      });
    };
  }, [dispatch, jwt, userUuid]);

  function toggleSetting(event, data) {
    const { checked } = data;
    const notificationType = data.name;
    dispatch({
      type: "SET_SINGLE_NOTIFICATION_VALUE",
      notificationType,
      checked
    });
    dispatch({
      type: "UPDATE_DATABASE",
      fetchParams: {
        jwt,
        userUuid
      },
      dispatchParams: {
        dispatch,
        dispatchCallback: "NOTIFY_OF_SAVE"
      }
    });
  }

  return (
    <div>
      <h3>Notification Settings</h3>
      <p>
        Please select how you would like to receive notifications:
        <br />
        {saveMessage}
      </p>
      <Checkbox
        name="web"
        toggle
        checked={notificationSettings.web}
        data-testid="web-notification-toggle"
        label={`Web Notifications ${notificationSettings.web ? " On" : " Off"}`}
        onChange={(event, data) => toggleSetting(event, data)}
      />
      <br />
      <Checkbox
        name="email"
        toggle
        checked={notificationSettings.email}
        data-testid="email-notification-toggle"
        label={`Email Notifications ${
          notificationSettings.email ? " On" : " Off"
        }`}
        onChange={(event, data) => toggleSetting(event, data)}
      />
    </div>
  );
}

NotificationSettingsObj.propTypes = {
  dispatch: PropTypes.func.isRequired,
  jwt: PropTypes.string.isRequired,
  notificationSettings: PropTypes.shape({
    web: PropTypes.bool,
    email: PropTypes.bool
  }).isRequired,
  saveMessage: PropTypes.string.isRequired,
  userUuid: PropTypes.string.isRequired
};

const notificationSettings = connect()(NotificationSettingsObj);

export default notificationSettings;
