import TypeCheck from "typecheck-extended";

import LoanApp from "../..";

function getByEntityUuidSuccess(rsp, origLoanAppsParams) {
  TypeCheck(rsp.data, "array");

  const { callbackData, filters, onError, onSuccess, pagination } =
    origLoanAppsParams;
  const jwt = "Deprecated in this API";
  rsp.data.forEach(loanApp => {
    const loanAppUuid = loanApp.parent_uuid;
    const cleanFilters = { ...filters };
    delete cleanFilters.entityUuid;
    LoanApp.get(
      jwt,
      loanAppRsp => onSuccess({ ...loanAppRsp, metaData: rsp.metaData }),
      onError,
      loanAppUuid,
      callbackData,
      cleanFilters,
      pagination
    );
  });
}

export default getByEntityUuidSuccess;
