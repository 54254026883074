import React from "react";
import TypeCheck from "typecheck-extended";
import { FormatCurrency, FormatNumber, FormatPercent } from "num-format";

import { FormatPhoneNumber } from "../../../TextFormatting";

const allowedModelFormats = [
  "currency",
  "currencyRounded",
  "number",
  "percentage",
  "phoneNumber"
];

export function TranslateValue(value) {
  switch (value) {
    case true:
      return "Yes";

    case false:
      return "No";

    default:
      return value;
  }
}

export function FormatValues(value, formatType) {
  TypeCheck(formatType, "enum", false, allowedModelFormats);

  switch (formatType) {
    case "currency": {
      const number = parseFloat(value);
      return FormatCurrency(number, false);
    }

    case "currencyRounded": {
      const number = parseFloat(value);
      return FormatCurrency(number);
    }

    case "number": {
      const number = parseFloat(value);
      return FormatNumber(number);
    }

    case "percentage": {
      const percent = parseFloat(value) / 100;
      return FormatPercent(percent);
    }

    case "phoneNumber":
      return FormatPhoneNumber(value);

    default:
      return value;
  }
}

export function GenerateSingleItem(dataSource, fieldModel) {
  TypeCheck(dataSource, "object");
  TypeCheck(fieldModel.displayName, "string");
  TypeCheck(fieldModel.key, "string");
  TypeCheck(fieldModel.required, "boolean");
  TypeCheck(fieldModel.format, "enum", false, allowedModelFormats);

  let value = TranslateValue(dataSource[fieldModel.key], fieldModel.required);
  value =
    value && fieldModel.format ? FormatValues(value, fieldModel.format) : value;
  let style = {};
  if (fieldModel.required && !value) {
    style = { color: "red", fontWeight: "bold" };
    value = "Missing Required Field";
  }
  return (
    <p key={fieldModel.key}>
      <b>{`${fieldModel.displayName}: `}</b>
      <span style={style}>{value}</span>
      <br />
    </p>
  );
}

export function SummarizeData(datasetModel, dataset) {
  TypeCheck(datasetModel, "array");
  TypeCheck(dataset, "object");

  return datasetModel.map(fieldModel =>
    GenerateSingleItem(dataset, fieldModel)
  );
}
