import errorReportHandler from "../errorReportHandler";

function toConsole(thrownError, messageForUser) {
  errorReportHandler(thrownError, messageForUser, "toConsole");
}

function toPage(thrownError, messageForUser) {
  errorReportHandler(thrownError, messageForUser, "toPage");
}

function toModal(thrownError, messageForUser) {
  errorReportHandler(thrownError, messageForUser, "toModal");
}

export default {
  toConsole,
  toPage,
  toModal
};
