import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Header,
  Modal,
  Message
} from "semantic-ui-react";

import DollarFormat from "../../../../../../../components/CustomUIElements/components/DollarFormat";
import {
  NumberInput,
  PercentInput
} from "../../../../../../../components/CustomFormElements";
import { forceValue } from "../../../../../../../services/FormElements";
import Constants from "../../../../../../../services/Constants/strings";
import parseStringAsNumber from "../../../../../../../services/ParseStringAsNumber";
import { actionCreators } from "../reducer";
import { useCurrentBalance, useAvailableInstitutions } from "../hooks";
import { formIsValid, updateAmounts } from "../utilities";

function ParticipationModal({
  open,
  handleCancel,
  handleConfirm,
  selectedInvestor,
  investors,
  error,
  dispatch,
  currentBalance,
  participants,
  participant,
  feesRolledDown,
  loan,
  isEdit = false
}) {
  const { updateParticipant, updateSelectedInvestor, participationError } =
    actionCreators;

  const { institution_uuid: institutionUuid } = loan;
  const usedBalance = useCurrentBalance({ participants, participant });
  const institutionOptions = useAvailableInstitutions({
    institutionUuid,
    investors,
    isEdit,
    participants
  });

  const handleUpdate = ({ key, value }) => {
    dispatch(updateParticipant({ [key]: value }));
    // update original field as well as any dependant fields as necessary
    if (key === "current_contract_amount") {
      const nextPartitipcationAmounts = updateAmounts(value, loan);
      dispatch(updateParticipant(nextPartitipcationAmounts));
    }
    if (key === "interest_rate") {
      const rateAdjustment = value - loan.interest_rate;
      dispatch(participationError(null));
      dispatch(updateParticipant({ rate_adjustment: rateAdjustment }));
    }
  };
  return (
    <Modal open={open}>
      <Modal.Content>
        <Form>
          <Modal.Description>
            <Header as="h1" color="green">
              {`${isEdit ? "Edit" : "Add"} Participant`}
            </Header>
          </Modal.Description>
          <Divider />
          {error && <Message negative content={error} />}
          <Form.Group>
            <Form.Input
              name="bafs_account_number"
              label={Constants.BLAST_ACCOUNT_NUMBER}
              value={forceValue(participant.bafs_account_number)}
              required
              onChange={({ target }) => {
                dispatch(
                  updateParticipant({
                    bafs_account_number: target.value
                  })
                );
              }}
            />
            <Form.Input
              label={Constants.INSTITUTION_ACCOUNT_NUMBER}
              required
              name="institution_account_number"
              id="institution_account_number"
              value={forceValue(participant.institution_account_number)}
              onChange={({ target }) => {
                dispatch(
                  updateParticipant({
                    institution_account_number: target.value
                  })
                );
              }}
            />
            <Form.Select
              control={Dropdown}
              search
              selection
              label="Investor Name"
              required
              name="institution_uuid"
              options={institutionOptions}
              onChange={(e, k) => {
                const selectedOpt = k.options.filter(
                  elem => elem.value === k.value
                );
                if (!selectedOpt || !selectedOpt[0]) {
                  return;
                }
                const investorDetails = selectedOpt[0].investor;
                dispatch(
                  updateParticipant({
                    institution_id: investorDetails.institution_id,
                    institution_uuid: investorDetails.uuid
                  })
                );
                dispatch(updateSelectedInvestor(selectedOpt[0]));
              }}
              value={selectedInvestor.value || ""}
            />
            <NumberInput
              decimalPlaces={2}
              name="current_contract_amount"
              id="current_contract_amount"
              required
              label="Current Contract Amount"
              width={5}
              value={forceValue(participant.current_contract_amount)}
              onBlur={e => {
                const change = {
                  key: "current_contract_amount",
                  value: parseStringAsNumber(e.target.value)
                };
                handleUpdate(change);
              }}
              onChange={e => {
                const change = {
                  current_contract_amount: parseStringAsNumber(e.target.value)
                };
                dispatch(updateParticipant(change));
              }}
            />
          </Form.Group>
          <Form.Group>
            <PercentInput
              label="Investor Interest Rate"
              name="interest_rate"
              icon="percent"
              onBlurCallback={nextValue => {
                const value = parseStringAsNumber(nextValue);
                const change = {
                  key: "interest_rate",
                  value
                };
                handleUpdate(change);
              }}
              onChange={(e, { value: eventValue }) => {
                const value = parseStringAsNumber(eventValue);
                const change = {
                  interest_rate: value
                };
                dispatch(updateParticipant(change));
              }}
              value={forceValue(participant.interest_rate)}
            />
          </Form.Group>

          {feesRolledDown && (
            <Form.Checkbox
              name="gets_fee_roll_downs"
              label="Allow Investor To Get Part Of Late Fees"
              checked={participant.gets_fee_roll_downs}
              onChange={(e, { checked }) => {
                dispatch(
                  updateParticipant({
                    gets_fee_roll_downs: checked
                  })
                );
              }}
            />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        Balance:{" "}
        {
          <b>
            <DollarFormat
              value={
                currentBalance -
                usedBalance -
                participant.current_contract_amount
              }
            />
          </b>
        }
        {" / "}
        {<DollarFormat value={currentBalance} />}
        <Button color="grey" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          color="green"
          disabled={formIsValid({ participant, selectedInvestor })}
          onClick={() => handleConfirm(isEdit)}
        >
          {isEdit ? "Update" : "Add"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

ParticipationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  selectedInvestor: PropTypes.shape({
    value: PropTypes.string,
    text: PropTypes.string,
    key: PropTypes.string,
    investor: PropTypes.object
  }),
  error: PropTypes.string,
  investors: PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
  currentBalance: PropTypes.number.isRequired,
  participants: PropTypes.arrayOf(PropTypes.object).isRequired,
  participant: PropTypes.shape({
    bafs_account_number: PropTypes.number,
    institution_account_number: PropTypes.number,
    current_contract_amount: PropTypes.number,
    interest_rate: PropTypes.number,
    gets_fee_roll_downs: PropTypes.bool
  }),
  feesRolledDown: PropTypes.bool,
  loan: PropTypes.shape({
    participation: PropTypes.string,
    bafs_account_number: PropTypes.string,
    current_balance: PropTypes.number.isRequired,
    institution_uuid: PropTypes.string.isRequired,
    interest_rate: PropTypes.number.isRequired,
    uuid: PropTypes.string.isRequired,
    participation_sold: PropTypes.bool.isRequired
  }),
  isEdit: PropTypes.bool
};

export default ParticipationModal;
