import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Grid, Icon, Select, Table } from "semantic-ui-react";

import MessageModal from "../../../Messaging/components/MessageModal";
import { TimeSpan, prettyDate } from "../../../../services/DateTime";
import { permCheck } from "../../../../services/Auth";

const tableHeaders = [
  "Tickler ID",
  "Document Name",
  "Description",
  "Status",
  "Action",
  "Due Date",
  "Days Past Due",
  "Upload Doc"
];

const dropDown = [
  { key: "--", text: "--", value: "" },
  { key: "Active", text: "Active", value: "Active" },
  { key: "Satisfied", text: "Satisfied", value: "Satisfied" },
  { key: "Waived", text: "Waived", value: "Waived" }
];

function TicklerTableObj(props) {
  const headers = tableHeaders.map(header => (
    <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
  ));

  const {
    onDelete,
    onDropdownChange,
    onShowModal,
    onUpload,
    messages,
    onOpenDocument,
    comment
  } = props;

  const tableRows = props.data.map(obj => {
    let days = 0;
    if (obj.dueDate && !(new Date(obj.dueDate) >= new Date())) {
      days = TimeSpan(obj.dueDate, "now");
    }
    let color;
    if (messages[obj.uuid]) {
      color = messages[obj.uuid].length > 0 ? "green" : "grey";
    }

    let documentName = <Grid.Column width="11">No File Chosen</Grid.Column>;
    if (props.objectMetadata[obj.objectUuid]) {
      documentName = (
        <Grid.Column
          onClick={() => onOpenDocument(obj.objectUuid, obj.objectUuid)}
          width="11"
        >
          {props.objectMetadata[obj.objectUuid].fileName || "Unnamed File"}
        </Grid.Column>
      );
    }
    return (
      <Table.Row key={obj.uuid}>
        <Table.Cell>{obj.id}</Table.Cell>
        <Table.Cell>{obj.documentName}</Table.Cell>
        <Table.Cell>
          <Grid>
            <Grid.Row columns="2">
              <Grid.Column>{obj.description}</Grid.Column>
              <Grid.Column textAlign="right">
                <Icon
                  data-testid="show-modal-icon"
                  circular
                  color={color}
                  name="comment alternate"
                  onClick={() => onShowModal(obj.uuid)}
                  size="small"
                  className="tickler-action-button"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Table.Cell>
        <Table.Cell>{obj.status}</Table.Cell>
        <Table.Cell width="2">
          {permCheck(["tickler_exception_cure_rw", "admin"]) && (
            <Select
              data-testid="tickler-dropdown"
              onChange={(e, { value }) => onDropdownChange(value, obj.uuid)}
              options={dropDown}
              placeholder="--"
              value={obj.state}
            />
          )}
        </Table.Cell>
        <Table.Cell>{prettyDate(obj.dueDate)}</Table.Cell>
        <Table.Cell>{days}</Table.Cell>
        <Table.Cell>
          <Grid>
            <Grid.Row columns="3">
              {documentName}
              <Grid.Column
                width="1"
                textAlign="right"
                className="doc-tracking-tickler-table-col"
              >
                <label htmlFor={obj.uuid}>
                  <input
                    data-testid="upload-tickler-doc"
                    hidden
                    id={obj.uuid}
                    onChange={e => onUpload(e, obj.uuid)}
                    type="file"
                  />
                  <Icon
                    disabled={
                      obj.status === "Pending Approval" ||
                      obj.status === "Satisfied"
                    }
                    circular
                    link={
                      obj.status !== "Pending Approval" &&
                      obj.status !== "Satisfied"
                    }
                    name="upload"
                    size="small"
                    className="tickler-action-button"
                  />
                </label>
              </Grid.Column>
              <Grid.Column
                width="2"
                textAlign="right"
                className="doc-tracking-tickler-table-col"
              >
                <Icon
                  data-testid="delete-document-icon"
                  disabled={obj.status !== "Pending Approval"}
                  circular
                  link={obj.status === "Pending Approval"}
                  name="close"
                  onClick={() =>
                    onDelete(
                      obj.uuid,
                      obj.objectUuid,
                      obj.dueDate,
                      obj.pastDuePeriodInDays
                    )
                  }
                  size="small"
                  className="tickler-action-button"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Table.Cell>
      </Table.Row>
    );
  });

  return (
    <Table celled selectable>
      <Table.Header>
        <Table.Row>{headers}</Table.Row>
      </Table.Header>
      <Table.Body>{tableRows}</Table.Body>
      <MessageModal
        comment={comment}
        commentUpdate={props.commentUpdate}
        messages={props.messages[props.rowUuid] || []}
        open={props.showModal}
        onComment={props.onComment}
        onClose={props.onCloseModal}
        messagesNames={props.messagesNames}
        ticklerUuid={props.rowUuid}
      />
    </Table>
  );
}

TicklerTableObj.defaultProps = {
  data: []
};

TicklerTableObj.propTypes = {
  comment: PropTypes.string.isRequired,
  commentUpdate: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  messages: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  messagesNames: PropTypes.shape({
    col1: PropTypes.string
  }).isRequired,
  objectMetadata: PropTypes.shape({
    col1: PropTypes.object
  }).isRequired,
  rowUuid: PropTypes.string.isRequired,
  onDropdownChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onOpenDocument: PropTypes.func.isRequired,
  onShowModal: PropTypes.func.isRequired,
  onComment: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  messages: state.DocTrackingViewReducer.messages,
  messagesNames: state.DocTrackingViewReducer.messagesNames,
  objectMetadata: state.DocTrackingViewReducer.objectMetadata
});

const TicklerTable = connect(mapStateToProps)(TicklerTableObj);

export default TicklerTable;
