export const initialState = {
  password: "",
  confirmPassword: "",
  error: false,
  errorMessage: "",
  loading: true,
  logUuid: "",
  reset: false
};

// eslint-disable-next-line default-param-last
export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case "PASSWORD_RESET_FIELD_CHANGE":
      return { ...state, [action.name]: action.value };

    case "PASSWORD_RESET_LOG_UUID_UPDATE":
      return { ...state, logUuid: action.uuid, loading: false };

    case "PASSWORD_RESET_ERROR":
      return { ...state, error: action.error, loading: false };

    case "PASSWORD_RESET_SUBMIT_ERROR":
      return { ...state, errorMessage: action.errorMessage };

    case "PASSWORD_RESET_PASSWORD_UPDATE":
      return { ...state, reset: !state.reset };

    default:
      return state;
  }
}
