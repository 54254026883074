import React, { useEffect, useState } from "react";
import { useUpdateEntityMutation } from "../../../../../../services/RTKQuery/entities";

export const UpdateTest = ({ entityUuid, setStatusInParent }) => {
  const statusUnknown = "❓Unknown Status";
  const statusPass = "✅ Pass";
  const statusFail = "❌ Fail";

  const [statusUpdate, setStatusUpdate] = useState(statusUnknown);
  const [detailsText, setDetailsText] = useState("statusUnknown");

  const [updateEntityMutation] = useUpdateEntityMutation();
  useEffect(() => {
    const newIncome = 231093;
    const newEmail = "adent@bafs.com";
    updateEntityMutation({
      uuid: entityUuid,
      annual_income: newIncome,
      email: newEmail
    })
      .unwrap()
      .then(fulfilled => {
        if (
          fulfilled?.data?.uuid === entityUuid &&
          fulfilled?.data?.email === newEmail &&
          fulfilled?.data?.annual_income === newIncome
        ) {
          setStatusUpdate(statusPass);
          setDetailsText(
            `: email & annual_income updated to: [${fulfilled.data.email}, ${fulfilled.data.annual_income}]`
          );
          setStatusInParent("Pass");
        } else {
          setStatusUpdate(statusFail);
          setStatusInParent("Fail");
        }
      })
      .catch(error => {
        console.error("Fail: useUpdateEntityMutation", error);
        setStatusUpdate(statusFail);
      });
  }, [entityUuid, setStatusInParent, updateEntityMutation]);

  return (
    <>
      <b>{statusUpdate}</b>
      <small>{detailsText}</small>
    </>
  );
};
