import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import FetchService, { asyncFetchService } from "../../../FetchService";
import { ConcatQueryParams, RemoveNullKeys } from "../Utilities";
import { FormatUrlV2 } from "../../../FormatUrl";

function add(jwt, onSuccess, onError, body, callbackData) {
  TypeCheck(jwt, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(callbackData, "object", false);

  const url = ApiEndpoints.baseUri + ApiEndpoints.collaterals;
  FetchService("POST", url, onSuccess, onError, body, callbackData);
}

async function asyncAdd(body) {
  const url = ApiEndpoints.baseUri + ApiEndpoints.collaterals;
  const rsp = await asyncFetchService("POST", url, body);
  return rsp;
}

function get(jwt, onSuccess, onError, uuid, queryParams, callbackData) {
  TypeCheck(jwt, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string", false);
  TypeCheck(queryParams, "object", false);

  let url = ApiEndpoints.baseUri + ApiEndpoints.collaterals;
  if (uuid) {
    url = FormatUrlV2(ApiEndpoints.collateral, { uuid });
  }
  if (queryParams) {
    url += ConcatQueryParams(queryParams);
  }

  FetchService("GET", url, onSuccess, onError, null, callbackData);
}

async function asyncGet(loanUuid, pagination) {
  TypeCheck(loanUuid, "string", false);

  let url = "";
  let params = "";
  if (pagination) {
    params = `&page_number=${pagination.number}&page_size=${pagination.size}`;
  }
  if (loanUuid) {
    url =
      FormatUrlV2(ApiEndpoints.collateralGetAllByLoanUuid, { loanUuid }) +
      params;
  }
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}
function update(jwt, onSuccess, onError, body, callbackData) {
  TypeCheck(jwt, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");

  const url = FormatUrlV2(ApiEndpoints.collateral, { uuid: body.uuid });

  const convertedBody = RemoveNullKeys(body);
  FetchService("PUT", url, onSuccess, onError, convertedBody, callbackData);
}

async function asyncUpdate(body) {
  TypeCheck(body.uuid, "string", true);

  const url = FormatUrlV2(ApiEndpoints.collateral, { uuid: body.uuid });
  const convertedBody = RemoveNullKeys(body);

  try {
    const rsp = await asyncFetchService("PUT", url, convertedBody);
    return rsp;
  } catch (err) {
    return err;
  }
}

function remove(jwt, onSuccess, onError, uuid, callbackData) {
  TypeCheck(jwt, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string");

  const url = FormatUrlV2(ApiEndpoints.collateral, { uuid });

  FetchService("DELETE", url, onSuccess, onError, callbackData);
}

async function asyncRemove(uuid) {
  TypeCheck(uuid, "string", true);

  const url = FormatUrlV2(ApiEndpoints.collateral, { uuid });

  const rsp = await asyncFetchService("DELETE", url);
  return rsp;
}

export default {
  add,
  asyncAdd,
  get,
  asyncGet,
  update,
  asyncUpdate,
  remove,
  asyncRemove
};
