import React, { useMemo } from "react";
import {
  arrayOf,
  bool,
  object,
  number,
  string,
  element,
  func
} from "prop-types";
import { connect } from "react-redux";
import { Table, Button, Grid, Header } from "semantic-ui-react";
import _ from "lodash";

import styled from "@emotion/styled";
import { prettyDate } from "../../../../../../../../services/DateTime";
import TransactionRevButton from "../TransRevButton";
import { permCheck } from "../../../../../../../../services/Auth";
import formatAsCurrency from "../../../../../../../../services/CurrencyFormatting";
import { actionCreators } from "../../reducer";
import { DateCell } from "../../../../../../../../components/Tables";

const tranCodes = {
  110: "Regular Payment",
  120: "Principal Reduction",
  130: "Interest Payment",
  140: "Late Fee Payment",
  146: "Prepayment Penalty Payment",
  147: "Misc Income Fee Payment",
  150: "Payoff",
  155: "Paydown",
  190: "Participation Service Fee Increase",
  192: "Participation Service Fee Decrease",
  220: "Loan Advance",

  340: "Late Fee Assessment",
  346: "Prepayment Penalty Assessment",
  347: "Misc Income Fee Assessment",

  420: "Manual Principal Increase",
  422: "Manual Principal Decrease",
  430: "Manual Accrued Interest Increase",
  431: "Manual Accrued Interest Nonaccrual Increase",
  432: "Manual Accrued Interest Decrease",
  433: "Manual Accrued Interest Nonaccrual Decrease",
  440: "Manual Fee Increase",
  442: "Manual Fee Decrease",
  441: "Manual Fee Nonaccrual Increase",
  443: "Manual Fee Nonaccrual Decrease",
  450: "Manual Reversal",

  510: "Automatic Payment Reversal",
  520: "Automatic Principal Increase",
  522: "Automatic Principal Decrease",
  530: "Automatic Accrued Interest Increase",
  531: "Automatic Fractional Accrued Interest Increase",
  532: "Automatic Accrued Interest Decrease",
  533: "Automatic Fractional Accrued Interest Decrease",
  535: "Automatic Interest Accumulation",
  537: "Automatic Interest Rate Change",
  540: "Automatic Fee Reversal",
  545: "Automatic Service Fee Accumulation",
  548: "Automatic SBA Fee Accumulation",

  660: "Contract Amount Increase",
  662: "Contract Amount Decrease",
  670: "Availability Increase",
  672: "Availability Decrease",

  735: "Reversal of Automatic Interest Accumulation",
  745: "Reversal of Automatic Service Fee Accumulation",
  748: "Reversal of Automatic SBA Fee Accumulation",

  935: "Interest Rate Change",
  985: "Status Change",
  9110: "Reverse Regular Payment",
  9220: "Reverse Loan Advance",
  9340: "Reverse Late Fee Assessed",
  9535: "Reverse Automatic Interest Accumulation",
  9545: "Reverse Automatic Service Fee Accumulation",
  9548: "Reverse Automatic SBA Fee Accumulation"
};

const recalcFields = [
  "fees_paid_amount",
  "fractional_accrued_interest",
  "interest_accumulated",
  "interest_paid",
  "per_diem",
  "principal",
  "principal_impact",
  "service_fee",
  "service_fee_ending_balance"
];

const formatForDisplay = transaction => ({
  created_datetime: prettyDate(transaction.created_datetime, true),
  effective_date: prettyDate(transaction.effective_date) || "——",
  reversal_date: transaction.reversal_date || "——",
  tran_code: transaction.tran_code
    ? `${transaction.tran_code} (${tranCodes[transaction.tran_code] || ""})`
    : "——",
  description: transaction.description || "None",
  tran_amount: transaction.tran_amount
    ? formatAsCurrency(transaction.tran_amount)
    : "$——",
  service_fee: transaction.service_fee
    ? formatAsCurrency(transaction.service_fee)
    : "$——",
  principal_impact: transaction.principal_impact
    ? formatAsCurrency(transaction.principal_impact)
    : "$——",
  interest_paid: transaction.interest_paid
    ? formatAsCurrency(transaction.interest_paid)
    : "$——",
  fees_paid_amount: transaction.fees_paid_amount
    ? formatAsCurrency(transaction.fees_paid_amount)
    : "$——",
  principal: transaction.principal
    ? formatAsCurrency(transaction.principal)
    : "$——"
});
const formatForRecalcFields = transaction => ({
  recalc_fees_paid_amount: transaction.recalc_fees_paid_amount
    ? formatAsCurrency(transaction.recalc_fees_paid_amount)
    : "$——",
  recalc_fractional_accrued_interest:
    transaction.recalc_fractional_accrued_interest
      ? formatAsCurrency(transaction.recalc_fractional_accrued_interest)
      : "$——",
  recalc_interest_accumulated: transaction.recalc_interest_accumulated
    ? formatAsCurrency(transaction.recalc_interest_accumulated)
    : "$——",
  recalc_interest_paid: transaction.recalc_interest_paid
    ? formatAsCurrency(transaction.recalc_interest_paid)
    : "$——",
  recalc_per_diem: transaction.recalc_per_diem
    ? formatAsCurrency(transaction.recalc_per_diem)
    : "$——",
  recalc_principal: transaction.recalc_principal
    ? formatAsCurrency(transaction.recalc_principal)
    : "$——",
  recalc_principal_impact: transaction.recalc_fees_paid_amount
    ? formatAsCurrency(transaction.recalc_fees_paid_amount)
    : "$——",
  recalc_service_fee: transaction.recalc_service_fee
    ? formatAsCurrency(transaction.recalc_service_fee)
    : "$——",
  recalc_service_fee_ending_balance:
    transaction.recalc_service_fee_ending_balance
      ? formatAsCurrency(transaction.recalc_service_fee_ending_balance)
      : "$——"
});

export const genReversalButton = (lookup, transaction) => {
  const button = <TransactionRevButton tran={transaction} lookup={lookup} />;

  if (+transaction.tran_code >= 300 || +transaction.tran_code < 100) {
    // nonreversible transaction
    return "";
  }
  if (permCheck(["recalc_testing"])) {
    return button;
  }
  return "";
};

export const columns = [
  {
    header: "Date Posted",
    field: "created_datetime",
    sortable: true
  },
  {
    header: "Effective Date",
    field: "effective_date",
    sortable: true,
    cell: DateCell
  },
  {
    header: "Reversal Date",
    field: "reversal_date",
    cell: DateCell
  },
  {
    header: "Transaction Code (type)",
    field: "tran_code"
  },
  {
    header: "Description",
    field: "description"
  },
  {
    header: "Transaction Amount",
    field: "tran_amount"
  },
  {
    header: "Service Fee",
    field: "service_fee"
  },
  {
    header: "Principal",
    field: "principal_impact",
    render(lookup, value, transaction, options) {
      return (
        <>
          {`${value} `}
          {!options.suppressActions && transaction.has_recalc && (
            <Button
              compact
              basic
              size="mini"
              onClick={() => options.onSelect(transaction)}
            >
              Log
            </Button>
          )}
        </>
      );
    }
  },
  {
    header: "Interest",
    field: "interest_paid"
  },
  {
    header: "Late Fee",
    field: "fees_paid_amount"
  },
  {
    header: "Principal Balance",
    field: "principal"
  },

  {
    header: "recalc_fees_paid_amount",
    field: "recalc_fees_paid_amount"
  },
  {
    header: "recalc_fractional_accrued_interest",
    field: "recalc_fractional_accrued_interest"
  },
  {
    header: "recalc_interest_accumulated",
    field: "recalc_interest_accumulated"
  },
  {
    header: "recalc_interest_paid",
    field: "recalc_interest_paid"
  },
  {
    header: "recalc_per_diem",
    field: "recalc_per_diem"
  },
  {
    header: "recalc_principal",
    field: "recalc_principal"
  },
  {
    header: "recalc_principal_impact",
    field: "recalc_principal_impact"
  },
  {
    header: "recalc_service_fee",
    field: "recalc_service_fee"
  },
  {
    header: "recalc_service_fee_ending_balance",
    field: "recalc_service_fee_ending_balance"
  },
  {
    header: "",
    field: "actions",
    cellProps: { textAlign: "center" },
    render(lookup, value, transaction, options) {
      return options.suppressActions ||
        new Date(`${transaction.effective_date}T00:00:00Z`) <
          new Date("2020-01-01T00:00:00Z")
        ? ""
        : genReversalButton(lookup, transaction);
    }
  }
];
export const removeRecalcColumns = type =>
  type === "detailed"
    ? columns
    : _.filter(columns, column => !/^recalc_/.test(column.header));

const genRows = ({ lookup, transactions, onSelect, suppressActions, type }) => {
  const rows = [];
  let i;
  // eslint-disable-next-line no-plusplus
  for (i = 0; i < transactions.length; i++) {
    const transaction = _.cloneDeep(transactions[i]);
    const displayTransaction =
      type === "detailed"
        ? {
            ...formatForDisplay(transaction),
            ...formatForRecalcFields(transaction)
          }
        : formatForDisplay(transaction);
    rows.push(
      <Table.Row key={transaction.uuid}>
        {removeRecalcColumns(type).map(({ field, render, cellProps = {} }) =>
          typeof render === "function" ? (
            <Table.Cell key={`${transaction.uuid}.${field}`} {...cellProps}>
              {render(lookup, displayTransaction[field], transaction, {
                suppressActions,
                onSelect
              })}
            </Table.Cell>
          ) : (
            <Table.Cell
              key={`${transaction.uuid}.${field}`}
              {...cellProps}
              content={displayTransaction[field]}
            />
          )
        )}
      </Table.Row>
    );
  }
  return rows;
};

export function HistoryTableBase(props) {
  const {
    historyData,
    printable,
    controls,
    sortField,
    sortDirection,
    showRecalculatedFields,
    type,
    onSortChange,
    lookup,
    dispatch,
    borrowerName,
    loanNumber
  } = props;

  const onSortChangeOrDefault =
    onSortChange ||
    ((name, value) => {
      const { transactionHistorySortField, transactionHistorySortDirection } =
        actionCreators[type];
      if (name === "sortDirection") {
        dispatch(transactionHistorySortDirection(value));
      } else {
        dispatch(transactionHistorySortField(value));
      }
    });
  const applyRecalculation = historyDataItem => {
    const processedItem = { has_recalc: !!historyDataItem.recalc_principal };
    Object.keys(historyDataItem).forEach(key => {
      if (!showRecalculatedFields && /^recalc_/.test(key)) {
        return;
      }
      if (recalcFields.includes(key)) {
        processedItem[key] =
          historyDataItem[`recalc_${key}`] == null
            ? historyDataItem[key]
            : historyDataItem[`recalc_${key}`];
      } else {
        processedItem[key] = historyDataItem[key];
      }
    });
    return processedItem;
  };

  const componentUuid = useMemo(() => Math.random().toString(16).slice(2), []);

  const onHeaderClick = field => ev => {
    ev.preventDefault();
    ev.stopPropagation();
    if (field === sortField) {
      onSortChangeOrDefault("sortDirection", -1 * sortDirection);
    } else {
      onSortChangeOrDefault("sortField", field);
    }
  };
  const removeReversedTransaction = transactions =>
    type === "detailed"
      ? transactions
      : _.filter(
          transactions,
          transaction => transaction.status !== "Reversed"
        );
  const filteredTransactions = historyData.map(applyRecalculation);
  return (
    <div>
      {printable && (
        <Grid padded>
          <Grid.Row>
            <Grid.Column textAlign="left" verticalAlign="bottom" width="10">
              {borrowerName && <Header as="h1">{borrowerName}</Header>}
              {loanNumber && (
                <Header as="h2" size="small">
                  {loanNumber}
                </Header>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      {controls}
      <TableContainer>
        <Table striped celled className="sortable">
          <Table.Header>
            <Table.Row>
              {removeRecalcColumns(type).map(({ header, field }) => (
                <Table.HeaderCell
                  key={`transaction_history.${componentUuid}.header.${field}`}
                  className={`${
                    sortDirection < 0 ? "descending" : "ascending"
                  } ${!printable && field === sortField ? "sorted" : ""}`}
                >
                  {!printable &&
                  ["effective_date", "created_datetime"].includes(field) ? (
                    <a href="#" onClick={onHeaderClick(field)}>
                      {header}
                    </a> /*eslint-disable-line*/
                  ) : (
                    header
                  )}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {genRows({
              ...props,
              lookup,
              transactions: removeReversedTransaction(filteredTransactions)
            })}
          </Table.Body>
        </Table>
      </TableContainer>
    </div>
  );
}

const TableContainer = styled.div`
  margin-top: 12px;
  overflow-x: auto;
`;

HistoryTableBase.propTypes = {
  controls: element,
  historyData: arrayOf(object).isRequired,
  lookup: func,
  printable: bool,
  modalOpen: bool, //eslint-disable-line
  onSelect: func, //eslint-disable-line
  suppressActions: bool, //eslint-disable-line
  sortField: string,
  sortDirection: number,
  showRecalculatedFields: bool,
  dispatch: func,
  onSortChange: func,
  type: string.isRequired,
  borrowerName: string,
  loanNumber: string
};

HistoryTableBase.defaultProps = {
  controls: <div />,
  modalOpen: false,
  printable: false,
  suppressActions: false,
  sortField: "effective_date",
  sortDirection: -1,
  showRecalculatedFields: true
};

const mapStateToProps = (state, ownProps) => ({
  ...state.LoanManagementTransactionHistory[ownProps.type],
  historyData:
    ownProps.historyData ||
    state.LoanManagementTransactionHistory[ownProps.type].historyData
});
export const HistoryTable = connect(mapStateToProps)(HistoryTableBase);
