/* eslint-disable camelcase */
import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { isEqual, uniqBy } from "lodash";
import { Select, Form, Input, Segment, Divider, Grid } from "semantic-ui-react";
import { ConcatName as getEntityName } from "../../../../../../services/Entities";
import {
  useAssetsForEntity,
  useLoanApplicationDocuments
} from "../../../../../../store/models/loan-applications";
import { loanAppUuidSelector } from "../../../../../../store/models/loan-applications/loan-applications";
import { dateFromString } from "../../../../../../services/DateTime";
import SemanticDatePicker from "../../../../../SemanticDatePicker";
import DocumentCategories from "../../../../../../services/Constants/DocumentCategories";
import { getAccountNumber } from "../../../../../../services/TextFormatting";
import { RefreshToken } from "../../../../../../services/Auth";

const categoryOptions = [
  { text: "Credit", value: "Credit" },
  { text: "Loan", value: "Loan" },
  { text: "Asset", value: "Asset" }
];

const docTypeListCollateral = DocumentCategories.filter(
  ({ doc_category }) => doc_category === "Collateral File"
).map(({ doc_subcategory }) => ({
  text: doc_subcategory,
  value: doc_subcategory
}));

const docTypeListCredit = DocumentCategories.filter(
  ({ doc_category }) => doc_category === "Credit File"
).map(({ doc_subcategory }) => ({
  text: doc_subcategory,
  value: doc_subcategory
}));

const docTypeListLoan = DocumentCategories.filter(
  ({ doc_category }) => doc_category === "Loan File"
).map(({ doc_subcategory }) => ({
  text: doc_subcategory,
  value: doc_subcategory
}));

const getDocumentTypeOptions = value => {
  if (value === "Credit") {
    return docTypeListCredit;
  }
  if (value === "Loan") {
    return docTypeListLoan;
  }
  if (value === "Asset") {
    return docTypeListCollateral;
  }
  return [];
};

const defaultFormData = {};

export const createOptions = (
  borrowers = [],
  affiliates = [],
  guarantors = [],
  thirdPartyGuarantors = [],
  collateralGrantors = [],
  owners = [],
  holdingsSubsidiaries = []
) => {
  const associatedEntities = [
    ...borrowers,
    ...affiliates,
    ...guarantors,
    ...thirdPartyGuarantors,
    ...collateralGrantors,
    ...owners,
    ...holdingsSubsidiaries
  ];

  if (associatedEntities) {
    return associatedEntities.map(entity => {
      const entityData = entity.entity || entity;
      const entityName = getEntityName(entityData);

      return {
        text: `${entityName} | ${entityData.entity_type} | ${entityData.tin}`,
        value: entityData.uuid
      };
    });
  }

  return [];
};

function DocumentDetails({ docDetails, onChange }) {
  const loanAppUuid = useSelector(loanAppUuidSelector);
  const [
    {
      borrowers,
      affiliates,
      ownerGuarantors,
      thirdPartyGuarantors,
      loanRequests,
      collateralGrantors,
      owners,
      holdingsSubsidiaries
    }
  ] = useLoanApplicationDocuments({ loanAppUuid });

  const [formData = defaultFormData, setFormData] = useState();

  const entityFilterCollateralGrantors = Object.values(collateralGrantors);
  const entityFilterOwners = owners
    .filter(o => Object.keys(o.owner_entity_data).length !== 0)
    .map(o => o.owner_entity_data);

  const assets = useAssetsForEntity({ entityUuid: formData.entityUuid });
  const loans = loanRequests;
  const entityFilterOptions = useMemo(
    () =>
      createOptions(
        borrowers,
        affiliates,
        ownerGuarantors,
        thirdPartyGuarantors,
        entityFilterCollateralGrantors,
        entityFilterOwners,
        holdingsSubsidiaries
      ),
    [
      borrowers,
      affiliates,
      ownerGuarantors,
      thirdPartyGuarantors,
      entityFilterCollateralGrantors,
      entityFilterOwners,
      holdingsSubsidiaries
    ]
  );
  const assetFilterOptions = useMemo(
    () =>
      assets
        ? assets.map(asset => ({
            text: `${asset.description} | ${asset.asset_class}-${asset.asset_type}`,
            value: asset.uuid
          }))
        : [],
    [assets]
  );
  const loanFilterOptions = useMemo(
    () =>
      loans
        ? loans.map(loan => ({
            text: `${loan.loan_class}-${loan.loan_type}${getAccountNumber(
              loan
            )}`,
            value: loan.uuid
          }))
        : [],
    [loans]
  );

  useEffect(() => {
    setFormData({ ...docDetails });
    RefreshToken();
    return () => {
      RefreshToken();
    };
  }, [docDetails]);

  useEffect(() => {
    if (formData !== defaultFormData && !isEqual(docDetails, formData)) {
      onChange(formData);
    }
  }, [docDetails, formData, onChange]);

  const {
    description,
    docCategory,
    docType,
    docYear,
    docDate,
    entityUuid,
    parentUuid,
    periodStart,
    periodEnd,
    uploadDate
  } = formData;

  const onInputChange = (key, value) => {
    setFormData(data => {
      if (data[key] !== value) {
        return {
          ...data,
          [key]:
            key === "parentRelType" && value === "Credit" ? "Entity" : value
        };
      }
      return data;
    });
  };
  const onChangeReportDate = (value, key) =>
    onInputChange(key, dateFromString(value));

  return (
    <>
      <Segment size="large" attached>
        Document Details - Edit
      </Segment>
      <Form className="attached fluid segment">
        <Form.Field>
          <b>Entity</b>
          <br />
          <Select
            type="text"
            icon="search"
            data-testid="entity-search"
            options={uniqBy(entityFilterOptions, "text")}
            fluid
            search
            selection
            onChange={(e, { value }) => {
              onInputChange("entityUuid", value);
              onInputChange("parentUuid", value);
            }}
            value={entityUuid}
          />
        </Form.Field>
        <Form.Field>
          <b>Category</b>
          <br />
          <Select
            search
            data-testid="category-search"
            fluid
            placeholder="Search Category"
            onChange={(e, { value }) => {
              onInputChange("docCategory", value);
              onInputChange("parentRelType", value);
              onInputChange("parentUuid", value === "Credit" ? entityUuid : "");
            }}
            options={categoryOptions}
            value={docCategory}
          />
        </Form.Field>
        <Divider />
        <Form.Field>
          <b>Document Type</b>
          <br />
          <Select
            search
            fluid
            placeholder="Search Document"
            value={docType}
            onChange={(e, { value }) => onInputChange("docType", value)}
            options={getDocumentTypeOptions(docCategory)}
          />
        </Form.Field>
        <Form.Field>
          <Grid divided>
            <Grid.Row>
              <Grid.Column />
              <Grid.Column width="15">
                <b>Asset</b>
                <br />
                <Select
                  disabled={docCategory !== "Asset"}
                  type="text"
                  icon="search"
                  data-testid="asset-search"
                  placeholder="Search Asset"
                  options={assetFilterOptions}
                  fluid
                  search
                  selection
                  onChange={(e, { value }) => {
                    onInputChange("parentUuid", value);
                  }}
                  value={parentUuid}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form.Field>
        <Form.Field>
          <Grid divided>
            <Grid.Row>
              <Grid.Column />
              <Grid.Column width="15">
                <b>Loan</b>
                <br />
                <Select
                  disabled={docCategory !== "Loan"}
                  type="text"
                  icon="search"
                  data-testid="loan-search"
                  placeholder="Search Loan"
                  options={loanFilterOptions}
                  fluid
                  search
                  selection
                  onChange={(e, { value }) => {
                    onInputChange("parentUuid", value);
                  }}
                  value={parentUuid}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form.Field>
        <Divider />

        <Form.Field>
          <b>Document Description</b>
          <br />
          <Input
            defaultValue={description}
            onChange={e => onInputChange("description", e.target.value)}
          />
        </Form.Field>

        <Form.Group>
          <Form.Field>
            <span>
              <b>Document Year</b>
            </span>
            <Form.Input
              value={docYear || ""}
              onChange={(e, { value }) => onInputChange("docYear", value)}
              maxLength={4}
            />
          </Form.Field>
          <Form.Field>{/* Just for spacing */}</Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field width="8">
            <SemanticDatePicker
              date={periodStart}
              label="Period Start"
              onChange={date => onChangeReportDate(date, "periodStart")}
            />
          </Form.Field>
          <Form.Field width="8">
            <SemanticDatePicker
              date={periodEnd}
              label="Period End"
              onChange={date => onChangeReportDate(date, "periodEnd")}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field width="8">
            <SemanticDatePicker
              date={docDate}
              label="Document Date"
              onChange={date => onChangeReportDate(date, "docDate")}
            />
          </Form.Field>
          <Form.Field width="8">
            <SemanticDatePicker
              date={uploadDate}
              label="Uploaded Date"
              onChange={date => onChangeReportDate(date, "uploadDate")}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </>
  );
}

DocumentDetails.propTypes = {
  docDetails: PropTypes.shape({
    url: PropTypes.string,
    entityUuid: PropTypes.string,
    institutionUuid: PropTypes.string
  }),
  onChange: PropTypes.func
};

export default DocumentDetails;
