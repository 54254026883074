import { useContext } from "react";
import { SortingTableContext } from "./sorting-table-context";
import { SortingIcons } from "./sorting-icons";

/**
 * This is the header that SortingTable uses and shouldn't be used outside SortingTable context
 */
export function SortingHeader() {
  const {
    headers,
    idProperty,
    tableData,
    setTableData,
    onSortStateChanged,
    sortState,
    disabledProperty,
    partiallyDisabledProperty,
    ...ctx
  } = useContext(SortingTableContext);

  const handleMasterCheckBox = () => {
    if ("onChecklistChanged" in ctx) {
      const { checkList, onChecklistChanged } = ctx;
      let allSelectableItemsAreChecked = true;
      const selectableItems = [];

      const checklistSet = new Set(checkList);

      for (const row of tableData) {
        if (
          !row?.[disabledProperty ?? ""] &&
          !row?.[partiallyDisabledProperty ?? ""]
        ) {
          const itemId = row[idProperty];
          selectableItems.push(itemId);

          if (!checklistSet.has(itemId)) {
            allSelectableItemsAreChecked = false;
          }
        }
      }

      if (allSelectableItemsAreChecked) {
        onChecklistChanged([]);
      } else {
        onChecklistChanged(selectableItems);
      }
    }
  };

  const handleSort = (id: string) => {
    if (sortState?.key !== id) {
      onSortStateChanged({ key: id, sort: "desc" });
    } else if (sortState.sort === "none") {
      onSortStateChanged({ key: id, sort: "desc" });
    } else if (sortState.sort === "desc") {
      onSortStateChanged({ key: id, sort: "asc" });
    } else {
      onSortStateChanged({ key: id, sort: "none" });
    }
  };
  let isMasterChecked = false;
  if ("checkList" in ctx) {
    isMasterChecked =
      tableData.length > 0 && tableData.length === ctx.checkList.length;
  }

  return (
    <div className="ui grid sorting-head">
      {"checkList" in ctx && (
        <div className="one wide column">
          <input
            data-testid="checkbox-table-header"
            id="table-header"
            name="table-header"
            onChange={handleMasterCheckBox}
            checked={isMasterChecked}
            type="checkbox"
          ></input>
        </div>
      )}

      {headers.map((header: any, index: number) => {
        const { sortable = true } = header;
        return (
          <div
            key={index}
            className={`${header.columnSize} wide column title ${
              sortState.sort !== "none" && sortState.key === header.key
                ? "sorted"
                : ""
            }${sortState.key === "hide" ? "not-sortable" : ""}`}
            onClick={() => {
              if (sortable) {
                handleSort(header.key);
              }
            }}
          >
            <div className="column-header">
              <div>{header.name}</div>
              {sortable && (
                <SortingIcons header={header} sortState={sortState} />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
