import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import { userCanViewPage } from "../../../../../../services/Auth";

import NavTabs, { Tab } from "../../../../components/NavTabs";
import Balance from "./scenes/Balance";
import Payments from "./scenes/Payments";
import Interest from "./scenes/Interest";
import AcctHistory from "./scenes/AcctHistory";
import Dates from "./scenes/Dates";
import ChargeFee from "./scenes/ChargeFee";
import Profitability from "./scenes/Profitability";
import GovtGuar from "./scenes/GovtGuar";
import Characteristics from "./scenes/Characteristics";
import Home from "./scenes/Home";
import Bills from "./scenes/Bills";

function Details({ loan, onUpdateLoan, isCoreOfRecord }) {
  const match = useRouteMatch();
  const permissionCheckUrl = match.url.replace(loan.uuid, ":uuid");
  const availableRoutes = {
    acctHistory: userCanViewPage(`${permissionCheckUrl}/account-history`),
    chargeAndFee: userCanViewPage(`${permissionCheckUrl}/charge-and-fee`),
    profitability: userCanViewPage(`${permissionCheckUrl}/profitability`)
  };

  const isMonthEnd = loan.is_month_end;
  return (
    <>
      <NavTabs>
        <Tab href={`${match.url}/balance`}>Balance</Tab>
        <Tab href={`${match.url}/payments`}>Payments</Tab>
        {isCoreOfRecord && !isMonthEnd ? (
          <Tab href={`${match.url}/bills`}>Bills</Tab>
        ) : (
          ""
        )}
        <Tab href={`${match.url}/interest`}>Interest</Tab>
        {availableRoutes.acctHistory && (
          <Tab href={`${match.url}/account-history`}>Account History</Tab>
        )}
        <Tab href={`${match.url}/dates`}>Dates</Tab>
        {availableRoutes.chargeAndFee && (
          <Tab href={`${match.url}/charge-and-fee`}>Charges & Fees</Tab>
        )}
        {availableRoutes.profitability && (
          <Tab href={`${match.url}/profitability`}>Profitability</Tab>
        )}
        {loan.gov_guarantee ? (
          <Tab href={`${match.url}/govt-guar`}>Gov&apos;t Guar</Tab>
        ) : null}
        <Tab href={`${match.url}/characteristics`}>Characteristics</Tab>
      </NavTabs>

      <Switch>
        <Route exact path={`${match.path}/balance`}>
          <Balance
            loan={loan}
            onUpdateLoan={onUpdateLoan}
            isCoreOfRecord={isCoreOfRecord}
            isMonthEnd={isMonthEnd}
          />
        </Route>

        <Route exact path={`${match.path}/payments`}>
          <Payments
            loan={loan}
            onUpdateLoan={onUpdateLoan}
            isCoreOfRecord={isCoreOfRecord}
            isMonthEnd={isMonthEnd}
          />
        </Route>

        {isCoreOfRecord && (
          <Route exact path={`${match.path}/bills`}>
            <Bills loan={loan} />
          </Route>
        )}

        <Route exact path={`${match.path}/interest`}>
          <Interest
            loan={loan}
            onUpdateLoan={onUpdateLoan}
            isCoreOfRecord={isCoreOfRecord}
            isMonthEnd={isMonthEnd}
          />
        </Route>

        {availableRoutes.acctHistory && (
          <Route exact path={`${match.path}/account-history`}>
            <AcctHistory loan={loan} />
          </Route>
        )}

        <Route exact path={`${match.path}/dates`}>
          <Dates
            loan={loan}
            onUpdateLoan={onUpdateLoan}
            isMonthEnd={isMonthEnd}
          />
        </Route>

        {availableRoutes.chargeAndFee && (
          <Route exact path={`${match.path}/charge-and-fee`}>
            <ChargeFee
              loan={loan}
              onUpdateLoan={onUpdateLoan}
              isMonthEnd={isMonthEnd}
            />
          </Route>
        )}

        {availableRoutes.profitability && (
          <Route exact path={`${match.path}/profitability`}>
            <Profitability loan={loan} />
          </Route>
        )}

        <Route exact path={`${match.path}/govt-guar`}>
          {loan.gov_guarantee ? (
            <GovtGuar
              loan={loan}
              onUpdateLoan={onUpdateLoan}
              isMonthEnd={isMonthEnd}
            />
          ) : null}
        </Route>

        <Route exact path={`${match.path}/characteristics`}>
          <Characteristics
            loan={loan}
            onUpdateLoan={onUpdateLoan}
            isCoreOfRecord={isCoreOfRecord}
            isMonthEnd={isMonthEnd}
          />
        </Route>

        <Route exact path="*">
          <Home />
        </Route>
      </Switch>
    </>
  );
}

Details.propTypes = {
  loan: PropTypes.shape().isRequired,
  onUpdateLoan: PropTypes.func,
  isCoreOfRecord: PropTypes.bool
};

export default Details;
