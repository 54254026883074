import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Header } from "semantic-ui-react";
import styled from "@emotion/styled";
import { DollarFormat } from "../../../../../../../../components/CustomUIElements";
import SplitLayout from "../../components/SplitLayout";
import { BalanceTable, BalanceInfoTable } from "./components/BalanceTable";
import BalanceEditModal from "./components/BalanceEditModal";
import FinalizeLoanModal from "./components/LoanFinalizeModal";

function Balance({ loan, onUpdateLoan, isMonthEnd }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [finalizeModalOpen, setFinalizeModalOpen] = useState(false);
  return (
    <SplitLayout>
      <HeaderCell inverted as="h2">
        <span>Current Balance</span>
        <DollarFormat value={loan.current_balance} />
      </HeaderCell>
      <Box>
        {(!loan.finalized || (loan.finalized && !loan.accrued_through_date)) &&
          !isMonthEnd && (
            <Button
              primary
              onClick={() => {
                setFinalizeModalOpen(true);
              }}
              content="Finalize Loan"
            />
          )}
        {!isMonthEnd && (
          <Button
            circular
            basic
            icon="edit"
            onClick={() => setModalOpen(true)}
            data-testid="edit-button"
          />
        )}{" "}
        <BalanceEditModal
          loan={loan}
          onSave={changes => onUpdateLoan({ ...changes, uuid: loan.uuid })}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
        <FinalizeLoanModal
          modalOpen={finalizeModalOpen}
          closeModal={() => setFinalizeModalOpen(false)}
          loan={loan}
        />
      </Box>
      <BalanceTable loan={loan} />
      <BalanceInfoTable loan={loan} />
    </SplitLayout>
  );
}

Balance.propTypes = {
  loan: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    current_balance: PropTypes.number,
    finalized: PropTypes.bool,
    accrued_through_date: PropTypes.string
  }).isRequired,
  onUpdateLoan: PropTypes.func,
  isMonthEnd: PropTypes.bool
};

const HeaderCell = styled(Header)`
  /* override semantic-ui-react styles with && */
  && {
    display: flex;
    justify-content: space-between;
    background-color: #01a49c;
    padding: 0.2em 1em;
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

export default Balance;
