import { Button, ButtonDropdown, Label, NavButton } from "@bafsllc/ui-shared";
import { FormattedDate, FormattedMessage } from "react-intl";
import { CREDIT_ANALYSIS_STATUS_TAG_COLOR, getEntityName } from "../../utils";

function loanManagementHeaders(handleActionSelect) {
  return [
    {
      name: "Primary Borrower",
      key: "primary_borrower",
      columnSize: "two",
      customColumn: creditAnalysis => (
        <span>
          {getEntityName({ entityData: creditAnalysis?.entity })}
          <br />
          {creditAnalysis?.entity?.entity_type ?? "-"}
          <br />
          {`xxx-xxx-${creditAnalysis?.entity?.tin?.slice(-4) ?? "-"}`}
        </span>
      )
    },
    {
      name: "Period",
      key: "period",
      columnSize: "one",
      customColumn: creditAnalysis => (
        <span>
          <span style={{ display: "flex", flexDirection: "row" }}>
            {creditAnalysis?.period_start && (
              <FormattedDate
                timeZone="UTC"
                value={creditAnalysis?.period_start}
              />
            )}
            {" - "}
          </span>
          {creditAnalysis?.period_end && (
            <FormattedDate timeZone="UTC" value={creditAnalysis?.period_end} />
          )}
        </span>
      )
    },
    {
      name: "Assessment By",
      key: "assessment_by",
      columnSize: "two",
      customColumn: creditAnalysis => (
        <span>
          {creditAnalysis?.last_modified_by?.slice(0, 10)}
          {" - "}
          <br />
          {creditAnalysis?.date_modified && (
            <FormattedDate
              timeZone="UTC"
              value={creditAnalysis?.date_modified}
            />
          )}
        </span>
      )
    },
    {
      name: "Credit Risk Rating",
      key: "credit_risk_rating",
      columnSize: "two",
      customColumn: creditAnalysis => {
        const selectedRating =
          creditAnalysis?.single_risk_scale?.single_risk_scale_items.find(
            scaleItem => scaleItem.uuid === creditAnalysis.scale_item_uuid
          )?.rating;
        return <span>{selectedRating ?? "-"}</span>;
      }
    },
    {
      name: "Scale",
      key: "scale",
      columnSize: "two",
      customColumn: creditAnalysis => (
        <span>
          {creditAnalysis?.single_risk_scale?.name
            ? `${creditAnalysis?.single_risk_scale?.name} | v${creditAnalysis?.single_risk_scale?.version}`
            : "-"}
        </span>
      )
    },
    {
      name: "Status",
      key: "status",
      columnSize: "two",
      customColumn: ({ status }) => (
        <Label
          color={CREDIT_ANALYSIS_STATUS_TAG_COLOR[status]}
          message={{
            id: status
              ? `CREDIT_ANALYSIS_${status.toUpperCase()}_STATUS`
              : "CREDIT_ANALYSIS_DRAFT_STATUS"
          }}
        />
      )
    },
    {
      name: "",
      key: "loan",
      columnSize: "two",
      sortable: false,
      customColumn: creditAnalysis => (
        <NavButton
          // eslint-disable-next-line no-alert
          to="details/balance"
          iconLeft={{ icon: "linkify" }}
          variant="tertiary"
          message={{
            defaultMessage: `Loan #${creditAnalysis?.loan?.institution_account_number}`
          }}
        />
      )
    },
    {
      name: "",
      key: "actions",
      columnSize: "one",
      sortable: false,
      customColumn: creditAnalysis =>
        creditAnalysis.status === "Draft" ? (
          <ButtonDropdown
            button={{ iconLeft: { icon: "ellipsis horizontal" } }}
            dropdownIcon={false}
            buttonVariant="secondary"
            onChildSelect={event => {
              handleActionSelect(event, creditAnalysis);
            }}
          >
            <li key="edit" value="Edit">
              <FormattedMessage id="EDIT" />
            </li>
            <li key="delete" value="Delete">
              <FormattedMessage id="DELETE" />
            </li>
          </ButtonDropdown>
        ) : (
          <Button
            value="View"
            onClick={event => {
              handleActionSelect(event, creditAnalysis);
            }}
            variant="secondary"
            message={{ id: "VIEW" }}
          />
        )
    }
  ];
}

function losHeaders(handleActionSelect) {
  return [
    {
      name: "Loan Request",
      key: "loan_request",
      columnSize: "two",
      customColumn: creditAnalysis => (
        <span>{creditAnalysis?.loan?.loan_request_number ?? ""}</span>
      )
    },
    {
      name: "Primary Borrower",
      key: "primary_borrower",
      columnSize: "two",
      customColumn: creditAnalysis => (
        <span>
          {getEntityName({ entityData: creditAnalysis?.entity })}
          <br />
          {creditAnalysis?.entity?.entity_type ?? "-"}
          <br />
          {`xxx-xxx-${creditAnalysis?.entity?.tin?.slice(-4) ?? "-"}`}
        </span>
      )
    },
    {
      name: "Period",
      key: "period",
      columnSize: "one",
      customColumn: creditAnalysis => (
        <span>
          <span style={{ display: "flex", flexDirection: "row" }}>
            {creditAnalysis?.period_start && (
              <FormattedDate
                timeZone="UTC"
                value={creditAnalysis?.period_start}
              />
            )}
            {" - "}
          </span>
          {creditAnalysis?.period_end && (
            <FormattedDate timeZone="UTC" value={creditAnalysis?.period_end} />
          )}
        </span>
      )
    },
    {
      name: "Assessment By",
      key: "assessment_by",
      columnSize: "two",
      customColumn: creditAnalysis => (
        <span>
          {creditAnalysis?.last_modified_by?.slice(0, 10)}
          {" - "}
          <br />
          {creditAnalysis?.date_modified && (
            <FormattedDate
              timeZone="UTC"
              value={creditAnalysis?.date_modified}
            />
          )}
        </span>
      )
    },
    {
      name: "Credit Risk Rating",
      key: "credit_risk_rating",
      columnSize: "two",
      customColumn: creditAnalysis => {
        const selectedRating =
          creditAnalysis?.single_risk_scale?.single_risk_scale_items.find(
            scaleItem => scaleItem.uuid === creditAnalysis.scale_item_uuid
          )?.rating;
        return <span>{selectedRating ?? "-"}</span>;
      }
    },
    {
      name: "Scale",
      key: "scale",
      columnSize: "two",
      customColumn: creditAnalysis => (
        <span>
          {creditAnalysis?.single_risk_scale?.name
            ? `${creditAnalysis?.single_risk_scale?.name} | v${creditAnalysis?.single_risk_scale?.version}`
            : "-"}
        </span>
      )
    },
    {
      name: "Status",
      key: "status",
      columnSize: "two",
      customColumn: ({ status }) => (
        <Label
          color={CREDIT_ANALYSIS_STATUS_TAG_COLOR[status]}
          message={{
            id: status
              ? `CREDIT_ANALYSIS_${status.toUpperCase()}_STATUS`
              : "CREDIT_ANALYSIS_DRAFT_STATUS"
          }}
        />
      )
    },
    {
      name: "",
      key: "actions",
      columnSize: "one",
      sortable: false,
      customColumn: creditAnalysis =>
        creditAnalysis.status === "Draft" ? (
          <Button
            value="edit"
            onClick={event => {
              handleActionSelect(event, creditAnalysis);
            }}
            variant="secondary"
            message={{ id: "EDIT" }}
          />
        ) : (
          <Button
            value="View"
            onClick={event => {
              handleActionSelect(event, creditAnalysis);
            }}
            variant="secondary"
            message={{ id: "VIEW" }}
          />
        )
    }
  ];
}

export default function getCreditAnalysisHeaders({ type, handleActionSelect }) {
  return type === "loan"
    ? loanManagementHeaders(handleActionSelect)
    : losHeaders(handleActionSelect);
}
