import PropTypes from "prop-types";
import React, { useState, useCallback, memo } from "react";
import styled from "@emotion/styled";

import ErrorReporter from "../../../../../../services/ErrorReporter";
import { FileUpload } from "../../../../../../services/ApiLib";
import { checkValidFiles } from "../../../../../../services/ApiLib/services/Utilities";

function DragAndDrop({
  entityUuid,
  docCategory,
  institutionUuid,
  onUploadSuccess,
  parentRelType,
  parentUuid,
  children,
  readOnly,
  bpOrganizationUuid
}) {
  const [hovering, setHovering] = useState(false);
  const [uploading, setUploading] = useState(false);
  const handleFileUploadSuccess = useCallback(
    (data, callbackData) => {
      setUploading(false);
      onUploadSuccess(data, callbackData);
    },
    [setUploading, onUploadSuccess]
  );

  const handleFileUploadError = useCallback(
    error => {
      setUploading(false);
      ErrorReporter("Could not upload the document.", error);
    },
    [setUploading]
  );
  const onDragOver = useCallback(
    ev => {
      ev.preventDefault();
      setHovering(true);
    },
    [setHovering]
  );
  const onDragLeave = useCallback(
    ev => {
      ev.preventDefault();
      setHovering(false);
    },
    [setHovering]
  );
  const onDragEnd = useCallback(
    ev => {
      ev.preventDefault();
      setHovering(false);
    },
    [setHovering]
  );
  const onDrop = useCallback(
    ev => {
      ev.preventDefault();
      setHovering(false);
      setUploading(true);
      const validFiles = checkValidFiles(ev.dataTransfer.files);
      if (validFiles) {
        FileUpload(
          ev,
          {
            entityUuid,
            institutionUuid,
            parentRelType,
            parentUuid,
            docCategory,
            bpOrganizationUuid
          },
          handleFileUploadSuccess,
          handleFileUploadError
        );
      } else {
        setUploading(false);
        ErrorReporter(
          "Could not upload the document, the file is of invalid type or size",
          "File is not of allowed type or size"
        );
      }
    },
    [
      entityUuid,
      institutionUuid,
      parentRelType,
      parentUuid,
      docCategory,
      bpOrganizationUuid,
      handleFileUploadSuccess,
      handleFileUploadError
    ]
  );

  return (
    <DragAndDropContainer
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
      onDrop={!readOnly ? onDrop : null}
      hovering={hovering}
      uploading={uploading}
      readOnly={readOnly}
    >
      {children}
    </DragAndDropContainer>
  );
}

const DragAndDropContainer = memo(styled.div`
  position: relative;
  ${({ uploading }) => (uploading ? "background: yellow" : "")}
  ${({ hovering, readOnly }) =>
    hovering &&
    !readOnly &&
    `
  &::after {
    content: '';
    position: absolute;
    pointer-events: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: olive;
    opacity: 0.5;
  }`}
  ${({ readOnly }) =>
    readOnly &&
    `
  &::after {
    content: '';
    position: absolute;
    pointer-events: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #E0E1E2;
    color: #E0E1E2;
    opacity: 0.5;
  }`}
`);

DragAndDrop.propTypes = {
  entityUuid: PropTypes.string.isRequired,
  docCategory: PropTypes.string.isRequired,
  institutionUuid: PropTypes.string.isRequired,
  onUploadSuccess: PropTypes.func.isRequired,
  parentRelType: PropTypes.string.isRequired,
  parentUuid: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  children: PropTypes.node,
  bpOrganizationUuid: PropTypes.string
};

export default DragAndDrop;
