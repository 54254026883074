import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu
} from "semantic-ui-react";
import FontAwesome from "react-fontawesome";
import { FormattedMessage } from "react-intl";
import { permCheck } from "../../../../../../../../../../../services/Auth";

function ActionsCell({ row, column, toggleAllRowsSelected }) {
  const asset = row?.original;
  const updatePerms = permCheck([
    "admin",
    "put_bp_access_controls",
    "put_bp_access_controls_unfiltered"
  ]);
  const editableByBorrower = asset?.bpAccessControls?.editable_by_borrower;

  const onEdit = () => {
    const {
      actionsCell,
      borrowerStatusCell,
      bpAccessControls,
      ...originalAsset
    } = asset;

    column?.onClickEdit(originalAsset);
  };

  return (
    <div className="flex justify-center">
      {column?.showActionsMenu ? (
        <Dropdown
          icon={<FontAwesome name="ellipsis-h" />}
          floating
          direction="left"
          className="ui tiny basic icon button"
        >
          <DropdownMenu className="floating ActionsCell-menu" direction="right">
            <DropdownItem
              data-testid="edit-asset-row-dropdown-item"
              onClick={onEdit}
            >
              <FormattedMessage id="EDIT" />
            </DropdownItem>
            <Divider className="bg-neutral-200 !m-0" />
            <DropdownItem
              data-testid="borrower-edit-lock-dropdown-item"
              disabled={!updatePerms || !editableByBorrower}
              onClick={() => {
                toggleAllRowsSelected(false); // Reset all selected checkboxes
                const { actionsCell, borrowerStatusCell, ...originalAsset } =
                  asset;

                column?.onClickBorrowerEditLock(originalAsset);
              }}
            >
              <FormattedMessage id="BORROWER_EDIT_LOCK" />
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ) : (
        <Button
          basic
          size="tiny"
          data-testid="edit-asset-row-btn"
          icon={<FontAwesome name="ellipsis-h" />}
          onClick={onEdit}
        >
          <FormattedMessage id="EDIT" />
        </Button>
      )}
    </div>
  );
}

export default ActionsCell;

ActionsCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape()
  }),
  toggleAllRowsSelected: PropTypes.func,
  column: PropTypes.shape({
    onClickEdit: PropTypes.func,
    onClickBorrowerEditLock: PropTypes.func,
    showActionsMenu: PropTypes.bool
  })
};
