export const initialState = {
  date: "",
  displayReceipt: false,
  error: "",
  receipt: {
    accrDr: "",
    curBal: "",
    deferCurBal: "",
    insDue: "",
    insRebate: "",
    lateFeesDue: "",
    payOffAmt: "",
    rebate: "",
    unApplFundBal: ""
  }
};

// eslint-disable-next-line default-param-last
export default function PayoffCalculatorReducer(state = initialState, action) {
  switch (action.type) {
    case "PAYOFF_REDUCER_UPDATE_DATE":
      return { ...state, date: action.date };

    case "PAYOFF_REDUCER_UPDATE_PAYOFF_RECEIPT":
      return { ...state, receipt: action.receipt };

    case "PAYOFF_REDUCER_UPDATE_DISPLAY_RECEIPT":
      return { ...state, displayReceipt: !action.displayReceipt };

    case "PAYOFF_REDUCER_UPDATE_ERROR":
      return { ...state, error: action.error };

    case "PAYOFF_REDUCER_RESET_STATE":
      return initialState;

    default:
      return state;
  }
}
