import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { omit } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import CharacteristicsLayout from "./components/CharacteristicsLayout";
import {
  DealRatingTable,
  LoanToValueTable
} from "./components/CharacteristicsTables";
import AdminCharacteristics from "./components/AdminCharacteristics";
import CharacteristicsEditModal from "./components/CharacteristicsEditModal";
import { useUserName } from "../../../../../../../../services/Hooks";
import CreditRatings from "../../../../../../../../services/ApiLib/services/CreditRatings";
import { CREDIT_ANALYSIS_ACTIONS } from "../../../../../../../../components/CreditAnalysis/reducer";
import logger from "../../../../../../../../services/logger";

export function onError(rsp) {
  logger.error("Error: ", rsp); // TODO: Handle errors properly
}

function Characteristics({
  codeOptions,
  isMonthEnd,
  isCoreOfRecord,
  loan,
  onSave,
  onViewModal,
  onUpdateLoan,
  selectedCodeOptions
}) {
  const [format5300CodeOptions, setFormattedOptions] = useState("--");
  const [modalOpen, setModalOpen] = useState(false);
  const officerName = useUserName(loan.loan_officer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loan.uuid) {
      onViewModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan.uuid]);

  useEffect(() => {
    if (Object.keys(codeOptions).length) {
      setFormattedOptions(
        Object.keys(selectedCodeOptions)
          .map(option =>
            codeOptions[option] ? codeOptions[option].code_5300 : ""
          )
          .join(", ") || null
      );
    }
  }, [codeOptions, selectedCodeOptions, setFormattedOptions]);

  const { selectedCreditAnalysis } = useSelector(state => ({
    selectedCreditAnalysis:
      state?.CreditAnalysisManagementReducer?.selectedCreditAnalysis
  }));

  const creditRiskRating =
    selectedCreditAnalysis?.[0]?.single_risk_scale_item?.rating;

  useEffect(() => {
    const readCurrentAnalysis = async () => {
      try {
        const currentAnalysisForLoan =
          await CreditRatings.readCurrentCreditAnalysisByLoan(loan.uuid);
        dispatch({
          type: CREDIT_ANALYSIS_ACTIONS.readCurrentAnalysisByLoan,
          data: currentAnalysisForLoan.data
        });
      } catch (err) {
        onError(err);
      }
    };
    readCurrentAnalysis();
  }, [dispatch, loan.uuid]);

  return (
    <CharacteristicsLayout>
      <>
        {!isMonthEnd && (
          <Button
            circular
            basic
            icon="edit"
            onClick={() => setModalOpen(true)}
            data-testid="edit-button"
          />
        )}
        {modalOpen ? (
          <CharacteristicsEditModal
            codeOptions={codeOptions}
            selectedCodeOptions={selectedCodeOptions}
            isCoreOfRecord={isCoreOfRecord}
            loan={{ ...loan }}
            onSave={changes => onSave({ ...changes, uuid: loan.uuid })}
            open={modalOpen}
            onClose={() => setModalOpen(false)}
          />
        ) : (
          ""
        )}
      </>
      <DealRatingTable
        loan={{
          ...loan,
          "5300_code": format5300CodeOptions,
          loan_officer: officerName,
          credit_risk_rating: creditRiskRating
        }}
      />
      <LoanToValueTable loan={loan} />

      <AdminCharacteristics
        loan={omit(loan, ["prepayment_penalty"])}
        onUpdate={onUpdateLoan}
        isCoreOfRecord={isCoreOfRecord}
        isMonthEnd={isMonthEnd}
      />
    </CharacteristicsLayout>
  );
}

Characteristics.propTypes = {
  codeOptions: PropTypes.objectOf(
    PropTypes.shape({ code_5300: PropTypes.string }).isRequired
  ),
  loan: PropTypes.shape({
    amortization_period: PropTypes.number,
    uuid: PropTypes.string.isRequired,
    loan_officer: PropTypes.string
  }).isRequired,
  onUpdateLoan: PropTypes.func,
  isCoreOfRecord: PropTypes.bool,
  isMonthEnd: PropTypes.bool,
  onSave: PropTypes.func,
  onViewModal: PropTypes.func,
  selectedCodeOptions: PropTypes.shape({})
};

export default Characteristics;
