import { NumberInputWithLabel } from "@bafsllc/ui-shared";
import { useIntl } from "react-intl";
import { StateAwareFieldChangeFunction } from "../../services/types";

export const RequestedLoanAmountInput = ({
  amount_requested,
  handleInputChange
}: RequestedLoanAmountInputProps) => {
  const { formatMessage } = useIntl();

  const handleChange = e => {
    handleInputChange([
      { value: parseFloat(e) || undefined, stateColumnName: "amount_requested" }
    ]);
  };

  return (
    <NumberInputWithLabel
      dollarOption
      handleInputChange={handleChange}
      labelText={formatMessage({ id: "REQUESTED_LOAN_AMOUNT" })}
      placeholderText={formatMessage({ id: "DOLLAR_AMOUNT" })}
      value={amount_requested?.toString()}
    />
  );
};

interface RequestedLoanAmountInputProps {
  amount_requested: number;
  handleInputChange: StateAwareFieldChangeFunction;
}
