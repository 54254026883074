import { addValidationRule } from "formsy-react";

export default function ValidationRules() {
  addValidationRule("isDate", (values, value) => {
    const dateRe = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    return dateRe.exec(value) && !Number.isNaN(new Date(value));
  });

  addValidationRule("isMonthYear", (values, value) => {
    const myRe = /^\d{1,2}\/\d\d$/;
    return myRe.exec(value);
  });

  addValidationRule("isPhone", (values, value) => {
    const phoneRe = /^\d{3}[-.]?\d{3}[-.]?\d{4}$/;
    return phoneRe.exec(value);
  });

  addValidationRule("isState", (values, value) => {
    const stateRe = /(^[A-Z]{2}$)|(^[a-z]{2}$)/g;
    return stateRe.exec(value);
  });

  addValidationRule("isTin", (values, value) => {
    const tinRe = /^[\d|-]+$/; // TODO: find proper rules
    return tinRe.exec(value);
  });

  addValidationRule("isZip", (values, value) => {
    const zipRe = /(^\d{5}$)|(^\d{5}-\d{4}$)/g;
    return zipRe.exec(value);
  });
}
