import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import Select from "react-select";
import { NumberInput, FormLabel } from "@bafsllc/ui-shared";
import { advanceTypeOptions } from "../../../../../LoanManagement/scenes/LoanManagement/scenes/Details/scenes/Characteristics/components/CharacteristicsEditModal/CharacteristicsEditModal";
import CommotionMessages from "../../../../../../assets/messages/common.en-US.json";
import {
  LoanQueryOptions,
  useLoansQuery,
  useUpdateLoanMutation
} from "../../../../../../services/RTKQuery/loans";
import { Checkbox } from "semantic-ui-react";
import { RemoveNullKeys } from "../../../../../../services/ApiLib/services/Utilities";

const { MAKE_SELECTION } = CommotionMessages;
export const remappedAdvanceTypeOptions = advanceTypeOptions.map(option => ({
  value: option.value,
  label: option.value
}));

export const TermsForm = () => {
  const useParamsRsp = useParams<{ uuid: string; tab: string }>();
  const { uuid } = useParamsRsp;
  const { formatMessage } = useIntl();

  const filters: LoanQueryOptions = {
    loan_app_uuid: uuid
  };
  const { data: loans, isFetching } = useLoansQuery(filters);

  const [updateLoanDB] = useUpdateLoanMutation();

  const [loanForm, setLoanForm] = useState({
    advance_type: null,
    loan_term: 0,
    amortization_period: 0,
    balloon: false
  });

  useEffect(() => {
    if (loans && loans.data && loans.data.length > 0) {
      const loanData = loans.data[0];
      setLoanForm({
        advance_type: loanData.advance_type,
        loan_term: loanData.loan_term,
        amortization_period: loanData.amortization_period,
        balloon: loanData.balloon
      });
    }
  }, [loans, uuid]);

  const handleDropdownChange = selectedOption => {
    const selectedValue = selectedOption ? selectedOption.value : null;
    setLoanForm({ ...loanForm, advance_type: selectedValue });
    if (loans.data && loans.data.length > 0) {
      updateLoanDB({ uuid: loans.data[0].uuid, advance_type: selectedValue });
    }
  };

  const handleBalloonChange = () => {
    updateLoanDB({ uuid: loans.data[0].uuid, balloon: !loanForm.balloon });
    setLoanForm({ ...loanForm, balloon: !loanForm.balloon });
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setLoanForm({ ...loanForm, [name]: parseFloat(value) });
  };

  const handleOnBlur = e => {
    const body = RemoveNullKeys(loanForm);
    updateLoanDB({ uuid: loans.data[0].uuid, ...body }); // Assuming this is how updateLoanDB works
  };

  if (isFetching) {
    return <div>Loading...</div>; // Handle loading state while fetching data
  }

  return (
    <div>
      <div
        className="flex flex-row gap-6 justify-between"
        data-testid={parentDivTestId}
      >
        <div className="flex flex-col flex-1">
          <FormLabel
            labelText={formatMessage({ id: "ADVANCE_TYPE" })}
            required
          />
          <Select
            classNames={{ control: () => "!border-neutral-300" }}
            inputId="advance-type-field"
            menuPlacement="auto"
            name="advance_type"
            onChange={handleDropdownChange}
            options={remappedAdvanceTypeOptions}
            placeholder={MAKE_SELECTION}
            value={remappedAdvanceTypeOptions.find(
              option => option.value === loanForm.advance_type
            )}
            required
          />
        </div>
        <NumberInput
          labelText={formatMessage({ id: "TERM_IN_MONTHS" })}
          handleInputChange={handleInputChange}
          name="loan_term"
          value={loanForm.loan_term}
          onBlur={handleOnBlur}
        />
        <NumberInput
          labelText={formatMessage({ id: "AMORTIZATION_IN_MONTHS" })}
          handleInputChange={handleInputChange}
          name="amortization_period"
          value={loanForm.amortization_period}
          onBlur={handleOnBlur}
          disabled={!loanForm.balloon}
        />
      </div>
      <div className="flex flex-row gap-6 justify-end mt-1">
        <Checkbox
          label="Balloon Payment"
          checked={loanForm.balloon}
          onClick={handleBalloonChange}
        />
      </div>
    </div>
  );
};

export const parentDivTestId = "terms-form-parent-div";
