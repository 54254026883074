import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Message } from "semantic-ui-react";
import { connect } from "react-redux";

function AlertObj({ message, isOpen, dispatch }) {
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        dispatch({ type: "ALERT_DISMISS_ALERT" });
      }, 2000);
    }
  }, [dispatch, isOpen]);

  return !isOpen ? null : (
    <Message
      color="green"
      onDismiss={() => dispatch({ type: "ALERT_DISMISS_ALERT" })}
      header={message}
    />
  );
}

AlertObj.propTypes = {
  message: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  dispatch: PropTypes.func
};

const mapStateToProps = state => ({
  isOpen: state.AlertReducer.isOpen
});

const Alert = connect(mapStateToProps)(AlertObj);

export default Alert;
