import createCRUDSlice, { defaultInitialState } from "../../create-crud-slice";

export const initialState = {
  ...defaultInitialState
};

const crudSlice = createCRUDSlice("loanInterestSchedules", "uuid", {
  shortName: "schedules",
  initialState
});

const slice = {
  ...crudSlice,
  selectors: {
    ...crudSlice.selectors
  },
  actions: {
    ...crudSlice.actions,
    retrieve: (identifiers, meta) => dispatch => {
      dispatch(crudSlice.actions.retrieve(identifiers, meta));
    }
  }
};

export const { actions, reducer, selectors } = slice;

export default {
  actions,
  reducer,
  selectors,
  initialState
};
