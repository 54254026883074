import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Divider,
  Header,
  Icon,
  Grid,
  Segment
} from "semantic-ui-react";
import { FormatCurrency } from "num-format";
import GuarantorsTable from "./components/GuarantorsTable";
import AddGuarantorModal from "./components/AddGuarantorModal";
import DeleteGuarantorModal from "./components/DeleteGuarantorModal";
import logger from "../../../../services/logger";

function Guarantors({
  owners = [],
  loanRequests = [],
  ownerGuarantors = [],
  thirdPartyGuarantors = [],
  institutionUuid,
  readOnly,

  onIsGuarantorChange,
  onGuarantorTypeChange,
  onAmountChange,
  onAmountTypeChange,
  onAddThirdPartyGuarantor
}) {
  const [
    addGuarantorModalOpenForLoanRequest,
    setAddGuarantorModalOpenForLoanRequest
  ] = useState(null);
  const [
    deleteGuarantorModalOpenForGuarantor,
    setDeleteGuarantorModalOpenForGuarantor
  ] = useState(null);

  /**
   * Determine all owners of borrowers and set them as guarantors on the
   * loan request object, for later use in the table.
   */
  const loanRequestsWithOwnerGuarntors = useMemo(
    () =>
      loanRequests.map(loanRequest => {
        const ownerMap = {};
        owners.forEach(owner => {
          // guarantors are determined by taking the owners of all borrowers
          let guarantor = ownerGuarantors.find(
            g =>
              owner.owner_entity_data &&
              owner.owner_entity_data.uuid === g.entity_uuid &&
              g.loanRequestUuid === loanRequest.uuid
          ) || {
            entity: owner.owner_entity_data,
            is_guarantor: false,
            loanRequestUuid: loanRequest.uuid
          };

          if (!guarantor.entity) {
            if (
              owner.owner_entity_data &&
              guarantor.entity_uuid === owner.owner_entity_data.uuid
            ) {
              guarantor = { ...guarantor, entity: owner.owner_entity_data };
            } else {
              // something wrong with data, ignore
              logger.error("No entity data for guarantor");
              return;
            }
          }
          const entityUuid = guarantor.entity.uuid;

          const nextOwner = {
            borrowers: [owner.borrower],
            ...guarantor
          };

          if (
            ownerMap[entityUuid] &&
            ownerMap[entityUuid].borrowers &&
            nextOwner.borrowers
          ) {
            // if an entity owns two different borrowers, add the borrowers to an array
            ownerMap[entityUuid].borrowers.push(nextOwner.borrowers[0]);
          } else {
            // if the entity is not yet listed, add it as an owner
            ownerMap[entityUuid] = nextOwner;
          }
        });

        return {
          ...loanRequest,
          ownerGuarantors: Object.keys(ownerMap).map(
            ownerUuid => ownerMap[ownerUuid]
          )
        };
      }),
    [loanRequests, ownerGuarantors, owners]
  );

  return (
    <>
      {loanRequestsWithOwnerGuarntors.map((loanRequest, index) => (
        <Segment key={loanRequest.uuid}>
          <Grid>
            <Grid.Row columns="2">
              <Grid.Column>
                <Header as="h3" textAlign="left" title={loanRequest.comments}>
                  {`Guarantors for Loan Request ${index + 1} - ${FormatCurrency(
                    loanRequest.original_amount || 0,
                    false
                  )}`}
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider />

          <GuarantorsTable
            guarantors={loanRequest.ownerGuarantors}
            loanRequest={loanRequest}
            onIsGuarantorChange={onIsGuarantorChange}
            onGuarantorTypeChange={onGuarantorTypeChange}
            onAmountChange={onAmountChange}
            onAmountTypeChange={onAmountTypeChange}
            readOnly={readOnly}
          />

          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column style={{ display: "flex", alignItems: "center" }}>
                <Header as="h4" textAlign="left">
                  Third Party Guarantors
                </Header>
              </Grid.Column>
              <Grid.Column>
                {!readOnly && (
                  <Button
                    basic
                    circular
                    icon
                    floated="right"
                    onClick={() =>
                      setAddGuarantorModalOpenForLoanRequest(loanRequest)
                    }
                  >
                    <Icon name="plus" color="green" />
                  </Button>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <GuarantorsTable
                  thirdParty
                  guarantors={thirdPartyGuarantors.filter(
                    guar => guar.loanRequestUuid === loanRequest.uuid
                  )}
                  loanRequest={loanRequest}
                  onIsGuarantorChange={guar =>
                    setDeleteGuarantorModalOpenForGuarantor(guar)
                  }
                  onGuarantorTypeChange={onGuarantorTypeChange}
                  onAmountChange={onAmountChange}
                  onAmountTypeChange={onAmountTypeChange}
                  readOnly={readOnly}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      ))}
      <AddGuarantorModal
        open={!!addGuarantorModalOpenForLoanRequest}
        institutionUuid={institutionUuid}
        onCancel={() => setAddGuarantorModalOpenForLoanRequest(null)}
        onAddThirdPartyGuarantor={entityUuid => {
          onAddThirdPartyGuarantor(
            entityUuid,
            addGuarantorModalOpenForLoanRequest
          );
          setAddGuarantorModalOpenForLoanRequest(null);
        }}
      />
      <DeleteGuarantorModal
        open={!!deleteGuarantorModalOpenForGuarantor}
        guarantor={deleteGuarantorModalOpenForGuarantor}
        onCancel={() => setDeleteGuarantorModalOpenForGuarantor(null)}
        onDeleteThirdPartyGuarantor={() => {
          onIsGuarantorChange(deleteGuarantorModalOpenForGuarantor, false);
          setDeleteGuarantorModalOpenForGuarantor(null);
        }}
      />
    </>
  );
}

Guarantors.propTypes = {
  loanRequests: PropTypes.arrayOf(PropTypes.shape({})),
  owners: PropTypes.arrayOf(PropTypes.shape({})),
  ownerGuarantors: PropTypes.arrayOf(PropTypes.shape({})),
  thirdPartyGuarantors: PropTypes.arrayOf(PropTypes.shape({})),
  institutionUuid: PropTypes.string,
  readOnly: PropTypes.bool,

  onIsGuarantorChange: PropTypes.func,
  onGuarantorTypeChange: PropTypes.func,
  onAmountChange: PropTypes.func,
  onAmountTypeChange: PropTypes.func,
  onAddThirdPartyGuarantor: PropTypes.func
};

export default Guarantors;
