import React from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";

function DeleteCell({ cell: { value }, onDelete, readOnly }) {
  return readOnly ? null : (
    <Button icon="delete" onClick={() => onDelete(value)} circular basic />
  );
}

DeleteCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.any
  }).isRequired,
  readOnly: PropTypes.bool,
  onDelete: PropTypes.func.isRequired
};

export default DeleteCell;
