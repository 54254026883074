import React from "react";
import { connect } from "react-redux";
import { arrayOf, bool, func, number, object, string } from "prop-types";

import { lookupAccount } from "../..";
import { HistoryTableBase } from "../../components/HistoryTable";
import { actionCreators } from "../../reducer";

const { transactionHistoryReset } = actionCreators;

export class PrintView extends React.Component {
  componentDidMount() {
    const {
      loanUuid,
      dispatch,
      filter,
      dateType,
      startDate,
      endDate,
      type,
      showExtraDetailed,
      sortField,
      sortDirection,
      pageNumber,
      pageSize
    } = this.props;

    let queryParams = {
      tran_code_group: type === "detailed" && showExtraDetailed ? "all" : type,
      sort_field: sortField,
      sort_direction: sortDirection
    };

    if (filter) {
      queryParams.lookup = filter;
    }

    if (startDate && endDate) {
      queryParams = {
        ...queryParams,
        date_column: dateType,
        start_date: startDate,
        end_date: endDate
      };
    }

    Object.assign(queryParams, {
      page_number: pageNumber || 0,
      page_size: pageSize || 999
    });

    const {
      sort_field: keyValue,
      sort_direction: sortValue,
      ...withoutSort
    } = queryParams;
    const queryParamsOrder = Object.assign(withoutSort, {
      order_by:
        keyValue === "effective_date"
          ? `${keyValue},${sortValue},created_datetime,-1`
          : `${keyValue},${sortValue}`
    });

    lookupAccount(dispatch, loanUuid, queryParamsOrder);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(transactionHistoryReset());
  }

  render() {
    const {
      filter,
      historyData,
      type,
      sortField,
      sortDirection,
      showExtraDetailed,
      borrowerName,
      loanNumber
    } = this.props;
    return (
      <div>
        <HistoryTableBase
          type={type}
          historyData={historyData.length ? historyData : []}
          sortField={sortField}
          sortDirection={sortDirection}
          printable
          suppressActions
          showExtraDetailed={showExtraDetailed}
          filter={filter}
          borrowerName={borrowerName}
          loanNumber={loanNumber}
        />
      </div>
    );
  }
}

PrintView.propTypes = {
  loanUuid: string.isRequired,
  dispatch: func.isRequired,
  type: string.isRequired,
  showExtraDetailed: bool,
  sortField: string,
  sortDirection: number,
  historyData: arrayOf(object).isRequired,
  filter: string,
  dateType: string,
  startDate: string,
  endDate: string,
  pageNumber: number,
  pageSize: number,
  borrowerName: string,
  loanNumber: string
};

const mapStateToProps = state => {
  const query = new URLSearchParams(window.location.search);
  const type = query.get("table");
  const showExtraDetailed = query.get("showExtraDetailed") === "true";
  const sortField = query.get("sortField");
  const sortDirection = +query.get("sortDirection");
  const filter = query.get("filter") || "";
  const dateType = query.get("dateType");
  const startDate = query.get("startDate");
  const endDate = query.get("endDate");
  const pageNumber = +query.get("page_number");
  const pageSize = +query.get("page_size");
  const borrowerName = query.get("borrowerName");
  const loanNumber = query.get("loanNumber");

  return {
    type,
    ...state.LoanManagementTransactionHistory[type],
    showExtraDetailed,
    sortField,
    sortDirection,
    filter,
    dateType,
    startDate,
    endDate,
    pageNumber,
    pageSize,
    borrowerName,
    loanNumber
  };
};

export default connect(mapStateToProps)(PrintView);
