import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";

const errorText = {
  fontWeight: "bold",
  color: "red"
};

function UserNotificationObj(props) {
  return (
    <div>
      <span style={errorText}>{props.message}</span>

      <Button label="Try Again" primary onClick={props.callback} />
    </div>
  );
}

UserNotificationObj.propTypes = {
  callback: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired
};

const UserNotification = connect()(UserNotificationObj);

export default UserNotification;
