import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

function WiringInstructionsForm({ institution, onInputChange }) {
  return (
    <Form>
      <Form.Group>
        <Form.Input
          name="remit_to"
          label="Remit To"
          width={5}
          value={institution.remit_to || ""}
          onChange={onInputChange}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          name="wiring_primary_address"
          label="Address Line 1"
          value={institution.wiring_primary_address || ""}
          onChange={onInputChange}
        />
        <Form.Input
          name="wiring_primary_address_2"
          label="Address Line 2"
          value={institution.wiring_primary_address_2 || ""}
          onChange={onInputChange}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          name="wiring_city"
          label="City"
          value={institution.wiring_city || ""}
          onChange={onInputChange}
        />
        <Form.Input
          name="wiring_state"
          label="State"
          value={institution.wiring_state || ""}
          onChange={onInputChange}
        />
        <Form.Input
          name="wiring_postal_code"
          label="ZIP"
          value={institution.wiring_postal_code || ""}
          onChange={onInputChange}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          name="aba_number"
          label="ABA/SWIFT Number"
          value={institution.aba_number || ""}
          onChange={onInputChange}
        />
        <Form.Input
          name="ach_account_number"
          label="Account Number"
          value={institution.ach_account_number || ""}
          onChange={onInputChange}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          name="credit_to"
          label="For Credit To"
          value={institution.credit_to || ""}
          onChange={onInputChange}
        />
        <Form.Input
          name="further_credit_to"
          label="For Further Credit To"
          value={institution.further_credit_to || ""}
          onChange={onInputChange}
        />
        <Form.Input
          name="credit_to_account_number"
          label="Account Number (For Further Credit To)"
          value={institution.credit_to_account_number || ""}
          onChange={onInputChange}
        />
      </Form.Group>
    </Form>
  );
}

WiringInstructionsForm.propTypes = {
  institution: PropTypes.objectOf(PropTypes.string).isRequired,
  onInputChange: PropTypes.func.isRequired
};

export default WiringInstructionsForm;
