import _ from "lodash";

const blankForm = {
  documentName: "",
  startDate: "",
  endDate: "",
  frequency: "",
  nextDueDate: "",
  notificationPeriodInDays: 0,
  pastDuePeriodInDays: 0,
  currentState: "",
  description: ""
};

const initialState = {
  filterData: "",
  rowUuid: "",
  confirmModal: false,
  comment: "",
  messages: {},
  messagesNames: {},
  objectMetadata: {},
  showModal: false,
  editModal: false,
  modalIsValid: false,
  newModal: false,
  form: blankForm,
  uploadByUuids: {},
  uploadDates: {},
  search: "",
  showWarning: false,
  ticklers: [],
  togglerState: false
};

export default function DocTrackingViewReducer(state, action) {
  const safeState = state ?? initialState;
  switch (action.type) {
    case "DOC_TRACKING_RESET_ROW":
      return {
        ...safeState,
        rowUuid: "",
        form: blankForm,
        search: ""
      };
    case "DOC_TRACKING_UPDATE_MODAL":
      return {
        ...safeState,
        comment: "",
        rowUuid: action.rowUuid,
        showModal: !action.showModal
      };

    case "DOC_TRACKING_UPDATE_FILTER_DATA": {
      return {
        ...safeState,
        filterData: action.filterData
      };
    }

    case "DOC_TRACKING_TICKLER_COMMENT_UPDATE":
      return {
        ...safeState,
        comment: action.comment
      };

    case "DOC_TRACKING_SCHEDULE_UPDATE_EDIT_MODAL": {
      return {
        ...safeState,
        search: action.form.documentName,
        form: action.form,
        rowUuid: action.rowUuid,
        editModal: !action.editModal
      };
    }

    case "DOC_TRACKING_SCHEDULE_CLOSE_EDIT_MODAL": {
      return {
        ...safeState,
        form: blankForm,
        rowUuid: "",
        editModal: !action.editModal
      };
    }

    case "DOC_TRACKING_SCHEDULE_MODAL_IS_VALID": {
      return {
        ...safeState,
        modalIsValid: action.modalIsValid
      };
    }

    case "DOC_TRACKING_SCHEDULE_UPDATE_SEARCH":
      return {
        ...safeState,
        search: action.search
      };

    case "DOC_TRACKING_SCHEDULE_UPDATE_DOCUMENT_NAME": {
      const newForm = _.cloneDeep(safeState.form);
      newForm.documentName = action.documentName.trim();
      return {
        ...safeState,
        form: newForm,
        search: action.documentName.trim()
      };
    }

    case "DOC_TRACKING_SCHEDULE_UPDATE_DATE_RANGE": {
      const newForm = _.cloneDeep(safeState.form);
      newForm[action.key] = action.date;
      return {
        ...safeState,
        form: newForm
      };
    }

    case "DOC_TRACKING_SCHEDULE_NEW_MODAL":
      return {
        ...safeState,
        form: blankForm,
        newModal: !action.newModal,
        search: ""
      };

    case "DOC_TRACKING_SCHEDULE_INPUT_CHANGE": {
      const newForm = _.cloneDeep(safeState.form);
      newForm[action.name] = action.value;
      return {
        ...safeState,
        form: newForm
      };
    }

    case "DOC_TRACKING_SCHEDULE_WARNING_MODAL":
      return { ...safeState, showWarning: !safeState.showWarning };

    case "DOC_TRACKING_UPDATE_TOGGLE":
      return {
        ...safeState,
        showWarning: !safeState.showWarning,
        confirmModal: !safeState.confirmModal
      };

    case "DOC_TRACKING_TICKLERS_GET_MESSAGES": {
      const newMessages = _.cloneDeep(safeState.messages);
      newMessages[action.uuid] = action.messages || [];
      return { ...safeState, messages: newMessages };
    }

    case "DOC_TRACKING_TICKLER_TOGGLE_UPDATE":
      return { ...safeState, togglerState: !safeState.togglerState };

    case "DOC_TRACKING_TICKLERS_ADD_MESSAGE": {
      const newMessages = _.cloneDeep(safeState.messages);
      newMessages[action.uuid].push(action.message);
      return {
        ...safeState,
        messages: newMessages,
        showModal: !safeState.showModal
      };
    }

    case "DOC_TRACKING_TICKLERS_ADD_NAMES": {
      const newNames = _.cloneDeep(safeState.messagesNames);
      newNames[action.uuid] = action.name;
      return { ...safeState, messagesNames: newNames };
    }

    case "DOC_TRACKING_TICKLER_UPDATE_DOCUMENT_UPLOAD": {
      const newData = _.cloneDeep(safeState.objectMetadata);
      newData[action.objectUuid] = {
        fileName: action.fileName,
        status: action.status,
        objectUuid: action.objectUuid
      };
      return {
        ...safeState,
        objectMetadata: newData
      };
    }

    case "DOC_TRACKING_TICKLERS_GET_OBJECT_DATA": {
      const newObjectData = _.cloneDeep(safeState.objectMetadata);
      newObjectData[action.uuid] = action.objectMetadata || {};
      return { ...safeState, objectMetadata: newObjectData };
    }

    case "DOC_TRACKING_UPDATE_OBJECT_METADATA": {
      const newObjectData = _.cloneDeep(safeState.objectMetadata);
      delete newObjectData[action.objectUuid];
      return { ...safeState, objectMetadata: newObjectData };
    }

    case "DOC_TRACKING_EDIT_MODAL_UPDATE_TICKLERS":
      return { ...safeState, ticklers: action.ticklers };

    case "DOC_TRACKING_EDIT_MODAL_UPDATE_UPLOAD_DATES": {
      const uploadDates = {
        ...safeState.users,
        [action.uuid]: action.uploadDate
      };
      return { ...safeState, uploadDates };
    }

    case "DOC_TRACKING_EDIT_MODAL_UPDATE_UPLOAD_BY_UUID": {
      const uploadByUuids = {
        ...safeState.users,
        [action.uuid]: action.uploadedBy
      };
      return { ...safeState, uploadByUuids };
    }

    default:
      return safeState;
  }
}
