import entityData from "../../../../../../services/DefaultEntityData";

export const initialState = {
  borrowers: [],
  confirmModal: false,
  entities: {},
  entityDetails: entityData,
  error: "",
  isOpen: false,
  relUuid: "",
  selectedBorrower: ""
};

// eslint-disable-next-line default-param-last
export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case "LOR_STEP_TAB_POPULATE_ENTITY_DETAILS": {
      return { ...state, entityDetails: action.data };
    }

    case "LOR_STEP_TAB_UPDATE_ERROR": {
      return { ...state, error: action.error };
    }

    case "LOR_STEP_TAB_SET_BORROWERS":
      return {
        ...state,
        borrowers: action.newBorrowers
      };

    case "LOR_STEP_TAB_TOGGLE_MODAL":
      return {
        ...state,
        isOpen: !state.isOpen
      };

    case "LOR_STEP_TAB_SET_ENTITY":
      return {
        ...state,
        entities: { ...state.entities, [action.uuid]: action.entity }
      };

    case "LOR_STEP_TAB_UPDATE_SELECTED_BORROWER":
      return { ...state, selectedBorrower: action.selectedBorrower };

    case "LOR_STEP_TAB_UPDATE_SELECTED_RELATIONSHIP":
      return {
        ...state,
        confirmModal: !state.confirmModal,
        relUuid: action.relUuid,
        isOpen: !state.isOpen
      };

    default:
      return state;
  }
}
