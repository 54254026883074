import TypeCheck from "typecheck-extended";
import ApiEndpoints from "../../../ApiEndpoints";
import { asyncFetchService } from "../../../FetchService";
import { FormatUrlV2 } from "../../../FormatUrl";

const asyncFetchUserName = async (dispatch, userUuid) => {
  TypeCheck(userUuid, "string");

  dispatch({
    type: "USER_INFO_LOOKING_UP_USER",
    userUuid
  });
  const url = FormatUrlV2(ApiEndpoints.userInfo, { uuid: userUuid });
  try {
    const rsp = await asyncFetchService("GET", url);

    if (rsp) {
      let userName = "Unknown User";
      if (rsp.first_name || rsp.last_name) {
        userName = `${rsp.first_name} ${rsp.last_name}`;
        userName = userName.trimEnd();
      }
      dispatch({
        type: "USER_INFO_UPDATE",
        userName,
        userUuid
      });
    }
    return rsp;
  } catch (err) {
    return err;
  }
};

export default {
  asyncFetchUserName
};
