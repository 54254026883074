import PropTypes from "prop-types";
import React, { Component } from "react";
import TypeCheck from "typecheck-extended";
import { Form } from "formsy-semantic-ui-react";
import DocTitle from "../../../../components/DocTitle";

import Buttons from "../../components/buttons";

import { stepList } from "../../services/constants";
import {
  forceValue,
  GenerateSelectOptions,
  validationErrors,
  validationLabel
} from "../../../../services/FormElements";

const loanRequestSelectFields = {
  loan_purpose: [
    "Cashflow for delayed receivables",
    "Cover a personal obligation (non-business purpose)",
    "Leasehold Improvements or expansion",
    "Marketing",
    "Opening a new location",
    "Payoff or Consolidate other business debt",
    "Pay a vendor, bill or rent",
    "Meet payroll",
    "Purchase equipment or vehicle",
    "Purchase inventory",
    "Repair or Maintenance for vehicle or equipment"
  ],
  payment_type: ["Line of Credit", "Term"],
  loan_type: ["Unsecured Loan"]
};

const loanRequestSelectOptions = GenerateSelectOptions(loanRequestSelectFields);

export class LoanRequestObj extends Component {
  constructor(props) {
    super(props);
    this.updateForm = this.updateForm.bind(this);
  }

  componentDidMount() {
    const { setCurrentPage } = this.props;
    setCurrentPage(3);
  }

  updateForm(e, { name, value }) {
    TypeCheck(name, "string");

    const { dispatchFormField } = this.props;
    dispatchFormField("loanRequestInfo", name, value);
  }

  render() {
    const {
      currentPageIndex,
      loading,
      loanRequestInfo,
      onSave,
      onWithdraw,
      onSaveAndNext,
      toggleFormValidation
    } = this.props;
    return (
      <div>
        <DocTitle
          title={`${stepList[3]} - Auto Decision Request - BLAST Portal`}
        />
        <h2>Loan Request:</h2>
        <Form
          autoComplete="off"
          onInvalidSubmit={() => {
            toggleFormValidation(false);
          }}
          onValidSubmit={() => {
            toggleFormValidation(true);
          }}
        >
          <Form.Group widths="equal">
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Loan Amount"
              name="loan_amount"
              onChange={this.updateForm}
              required
              type="number"
              validationErrors={validationErrors}
              validations="isNumeric"
              value={forceValue(loanRequestInfo.loan_amount)}
            />
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Loan Term (Months)"
              name="payment_period" // TODO: Confirm stroing in "payment_period" col.
              onChange={this.updateForm}
              required
              type="number"
              validationErrors={validationErrors}
              validations="isInt"
              value={forceValue(loanRequestInfo.payment_period)}
            />
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Interest Rate"
              name="loan_rate"
              onChange={this.updateForm}
              required
              type="number"
              validationErrors={validationErrors}
              validations="isNumeric"
              value={forceValue(loanRequestInfo.loan_rate)}
            />
            <Form.Select
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Use of Funds"
              name="loan_purpose" // TODO: Confirm storing in "loan_purpose" col
              onChange={this.updateForm}
              options={loanRequestSelectOptions.loan_purpose}
              required
              validationErrors={validationErrors}
              value={forceValue(loanRequestInfo.loan_purpose)}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Select
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Payment Type"
              name="payment_type"
              onChange={this.updateForm}
              options={loanRequestSelectOptions.payment_type}
              required
              validationErrors={validationErrors}
              value={forceValue(loanRequestInfo.payment_type)}
            />
            <Form.Select
              autoComplete="off"
              errorLabel={validationLabel()}
              instantValidation
              label="Loan Type"
              name="loan_type"
              onChange={this.updateForm}
              options={loanRequestSelectOptions.loan_type}
              required
              validationErrors={validationErrors}
              value={forceValue(loanRequestInfo.loan_type)}
            />
          </Form.Group>

          <Buttons
            currentPage={stepList[currentPageIndex]}
            currentPageIndex={currentPageIndex}
            disabled={loading}
            onSave={onSave}
            onSaveAndNext={onSaveAndNext}
            onWithdraw={onWithdraw}
          />
        </Form>
        <p>* Required</p>
      </div>
    );
  }
}

LoanRequestObj.propTypes = {
  currentPageIndex: PropTypes.number.isRequired,
  dispatchFormField: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loanRequestInfo: PropTypes.shape({
    loan_amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    loan_purpose: PropTypes.string,
    loan_rate: PropTypes.string,
    loan_type: PropTypes.string,
    payment_period: PropTypes.string,
    payment_type: PropTypes.string
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onSaveAndNext: PropTypes.func.isRequired,
  onWithdraw: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  toggleFormValidation: PropTypes.func.isRequired
};

const LoanRequest = LoanRequestObj;

export default LoanRequest;
