import React, { useEffect, useRef, useState } from "react";
import {
  usePaymentSchedulesQuery,
  PaymentScheduleParameters
} from "../../../../../../services/RTKQuery/paymentSchedules";

export const ReadManyTest = ({ loanUuid, itemUuid, setStatusInParent }) => {
  const statusUnknown = "❓Unknown Status";
  const statusPass = "✅ Pass";
  const statusFail = "❌ Fail";

  const statusReadManyRef = useRef(statusUnknown);
  const [detailsText, setDetailsText] = useState("");

  const filtersStepFour: PaymentScheduleParameters = {
    loanUuid: loanUuid,
    filters: {
      loan_uuid: loanUuid,
      institution_uuid: "institut-ions-0016-IBLS-TestBranch00"
    }
  };
  const { data: paymentSchedulesResp } =
    usePaymentSchedulesQuery(filtersStepFour);
  useEffect(() => {
    // This shouldn't be needed, but if it's removed, the test will
    // rerun after the DELETE test is run and then fail.
    if (statusReadManyRef.current === statusPass) {
      return;
    }
    let createdRecord;
    if (paymentSchedulesResp && paymentSchedulesResp.data.length > 0) {
      for (const record of paymentSchedulesResp.data) {
        if (record.uuid === itemUuid) {
          createdRecord = record;
          break;
        }
      }
    }
    // This str is repeated in two tests
    const billsRemainingExpected = 10;
    const billsRemaining =
      createdRecord?.bills_remaining === billsRemainingExpected;
    if (createdRecord && billsRemaining) {
      setStatusInParent("Pass");
      setDetailsText(
        `: bills_remaining matches updated value in record ${createdRecord.uuid}`
      );
      statusReadManyRef.current = statusPass;
    } else {
      console.error(
        "createdRecord?.bills_remaining =",
        createdRecord?.bills_remaining
      );
      statusReadManyRef.current = statusFail;
      console.error("Fail: usePaymentSchedulesQuery");
    }
  }, [itemUuid, paymentSchedulesResp, setStatusInParent]);
  return (
    <>
      <b>{statusReadManyRef.current}</b>
      <small>{detailsText}</small>
    </>
  );
};
