import React, { useState } from "react";
import { useSelector } from "react-redux";
import { shape, string, bool, number } from "prop-types";
import { Button, Grid, Segment } from "semantic-ui-react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";

import {
  LoanTransactionHistory,
  Loans
} from "../../../../../../../../services/ApiLib";
import PaymentType from "../../../../../../../../services/PaymentType";
import NavTabs, { Tab } from "../../../../../../components/NavTabs";
import AddTransactionModal from "../../components/AddTransactionModal.js";
import TransactionHistoryTable from "./TransactionHistoryTable";

export function TransactionHistoryBase(props) {
  const { match, loan, isCoreOfRecord } = props;
  const [openModal, setOpenModal] = useState(false);
  const userUuid = useSelector(state => state.auth.userUuid);
  const handleSubmit = async transactionData => {
    await LoanTransactionHistory.create(
      {
        ...transactionData,
        loan_uuid: loan.uuid,
        entered_by: userUuid,
        institution_uuid: loan.institution_uuid
      },
      true
    );
    await Loans.asyncUpdate(
      { loanUuid: loan.uuid },
      { current_balance: transactionData.principal }
    );
    setOpenModal(false);
  };
  return (
    <Segment>
      <Grid>
        <Grid.Column floated="left">
          <NavTabs>
            <Tab href={`${match.url}/effective`}>Effective</Tab>
            <Tab href={`${match.url}/detailed`}>Detailed</Tab>
          </NavTabs>
        </Grid.Column>
        <Grid.Column floated="right">
          {!isCoreOfRecord && (
            <Button
              basic
              circular
              color="green"
              floated="right"
              icon="plus"
              onClick={() => setOpenModal(true)}
            />
          )}
        </Grid.Column>
      </Grid>

      <AddTransactionModal
        open={openModal}
        paymentOptions={PaymentType.getPaymentTypeOptions(
          loan.advance_type,
          loan.available_balance
        )}
        onCancel={() => setOpenModal(false)}
        onSubmit={handleSubmit}
      />
      <Switch>
        <Route path={`${match.path}/effective`}>
          <div>
            <TransactionHistoryTable
              key="transactionHistoryEffective"
              type="effective"
              {...props}
            />
          </div>
        </Route>
        <Route path={`${match.path}/detailed`}>
          <div>
            <TransactionHistoryTable
              key="transactionHistoryDetailed"
              type="detailed"
              {...props}
            />
          </div>
        </Route>
        <Redirect exact from={`${match.path}`} to={`${match.url}/effective`} />
      </Switch>
    </Segment>
  );
}
TransactionHistoryBase.propTypes = {
  match: shape({
    url: string,
    path: string
  }).isRequired,
  isCoreOfRecord: bool,
  loan: shape({
    uuid: string,
    advance_type: string,
    available_balance: number
  }),
  borrowerName: string
  // location: object.isRequired,
  // history: object.isRequired,
};

const TransactionHistory = withRouter(TransactionHistoryBase);

export default TransactionHistory;
