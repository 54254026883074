import { collateralGroupClassTypeObj } from "../FormElements";

export const groupOptions = Object.keys(collateralGroupClassTypeObj).map(k => ({
  key: `group-${k}`,
  text: k,
  value: k
}));

export const classOptions = groupKey => {
  if (groupKey && collateralGroupClassTypeObj[groupKey]) {
    return Object.keys(collateralGroupClassTypeObj[groupKey]).map(key => ({
      key: `class-${key}`,
      value: key,
      text: key
    }));
  }
  return [];
};

export const typeOptions = (groupKey, classKey) => {
  if (
    groupKey &&
    classKey &&
    collateralGroupClassTypeObj[groupKey] &&
    collateralGroupClassTypeObj[groupKey][classKey]
  ) {
    return collateralGroupClassTypeObj[groupKey][classKey].map(item => ({
      key: item,
      text: item,
      value: item
    }));
  }
  return [];
};

export const serializeAssetTypesData = ({ data }) => {
  const defaultData = {
    typeOptions: []
  };

  if (!data) {
    return defaultData;
  }

  const serializedAssetTypesData = data.reduce((acc, curr) => {
    const result = {
      ...acc
    };

    if (curr?.asset_classes) {
      curr.asset_classes.forEach(ac => {
        if (Array.isArray(ac?.asset_types) && ac?.asset_types.length > 0) {
          const assetTypes = ac.asset_types.map(at => ({
            key: at.uuid,
            text: `${at?.core_collateral_type_code || "00"} | ${at?.name}`,
            value: at?.core_collateral_type_code || at.name
          }));

          result.typeOptions = [...result.typeOptions, ...assetTypes];
        }
      });
    }

    return result;
  }, defaultData);

  if (serializedAssetTypesData.typeOptions.length > 0) {
    serializedAssetTypesData.typeOptions.sort((a, b) => a.value - b.value);
  } else {
    serializedAssetTypesData.typeOptions = null;
  }

  return serializedAssetTypesData;
};

export const serializeAssetData = ({
  data,
  asset_group,
  asset_class,
  asset_type
}) => {
  const defaultData = {
    group: null,
    groupOptions: [],
    assetClass: null,
    classOptions: null,
    assetType: null
  };

  if (!data) {
    return defaultData;
  }

  const serializedAssetData = data.reduce((acc, curr) => {
    const result = {
      ...acc,
      groupOptions: [
        ...acc.groupOptions,
        {
          key: `group-${curr.name}`,
          text: curr.name,
          value: curr.name
        }
      ]
    };

    if (asset_group && curr.name === asset_group) {
      result.group = curr;
    }

    // Asset Class
    if (result.group) {
      result.classOptions = result.group.asset_classes.map(ac => ({
        key: ac.uuid,
        text: ac.name,
        value: ac.name // ac.uuid
      }));

      if (asset_class) {
        result.assetClass = result.group?.asset_classes.find(
          ac => ac.name === asset_class // ac.uuid
        );
      }
    }

    // Asset Types
    if (result.assetClass) {
      result.typeOptions = result.assetClass.asset_types.map(at => ({
        key: at.uuid,
        text: at.name,
        value: at.name // at.core_collateral_type_code || at.uuid
      }));

      if (asset_type) {
        result.assetType = result.assetClass?.asset_types.find(
          at => at.name === asset_type
        );
      }
    }

    return result;
  }, defaultData);

  return serializedAssetData;
};
