import { AppStatus, FormStateDataTypes } from "@blast-client/types";

export const FILE_SIZE_LIMIT_BYTES = 35 * 1024 * 1024; // 35 mb in bytes

export const ALLOWED_MIME_TYPES = [
  "application/pdf", // .pdf
  "application/msword", // .doc
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
  "image/jpeg", // .jpg; .jpeg
  "image/png", // .png
  "application/vnd.ms-excel", // .xls
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" // .xlsx
];

export const APP_STATUSES: Readonly<Record<string, AppStatus>> = Object.freeze({
  INITIAL_REVIEW_PENDING: "Initial Review Pending",
  BORROWER_SIGNATURE_PENDING: "Borrower Signature Pending",
  BORROWER_APPLICATION_ERROR: "Borrower Action Pending",
  BORROWER_INVITATION_SENT: "Borrower Invitation Sent",
  BORROWER_STARTED_APPLICATION: "Borrower Started Application",
  BORROWER_DOCUMENTS_REQUIRED: "Borrower Documents Required",
  DOCUMENT_CATEGORIES_PENDING: "Document Categories Pending",
  LENDER_RESPONSE_PENDING: "Lender Response Pending",
  LENDER_REVIEW_PENDING: "Lender Review Pending",
  POST_REVIEW_LENDER_RESPONSE_PENDING: "Post Review Lender Response Pending",
  SBA_RESUBMIT_REQUIRED: "SBA Resubmit Required",
  SBA_APPROVAL_PENDING: "SBA Approval Pending",
  SBA_DOCUMENTS_REQUIRED: "SBA Documents Required",
  SBA_REJECTED: "SBA Rejected",
  FORGIVEN: "Forgiven"
});

export const BORROWER_TYPE = "borrower";
export const LENDER_TYPE = "lender";
export const SBA_TYPE = "SBA";

export const FORM_STATE_DATA_TYPES: FormStateDataTypes = Object.freeze({
  calc_form_business_mortgage_int: "decimal",
  calc_form_business_rent_lease: "decimal",
  calc_form_business_utilities: "decimal",
  calc_form_modified_total: "decimal",
  calc_form_operations_exp: "decimal",
  calc_form_payroll_pct: "decimal",
  calc_form_pr_nonpr_cost_subtotal: "decimal",
  calc_form_property_damage: "decimal",
  calc_form_supplier_costs: "decimal",
  calc_form_total_wage_reduct: "decimal",
  calc_form_worker_protection: "decimal",
  covered_period_len: "integer",
  emp_at_app: "integer",
  emp_at_frgvn_app: "integer",
  forgive_lender_decision: "integer",
  frgvn_amount: "decimal",
  frgvn_end_date: "date",
  frgvn_start_date: "date",
  fte_reduction_quotient: "decimal",
  is_safe_harbor: "boolean",
  no_employees: "boolean",
  no_reduction_in_employees: "boolean",
  payroll_costs: "decimal",
  payroll_period_other: "string",
  payroll_period: "string",
  salary_wage_reduct: "decimal",
  sba_ppp_loan_amount: "decimal",
  ppp_loan_draw: "integer",
  sch_a_avg_fte_reference_period: "decimal",
  sch_a_emp_health_ins: "decimal",
  sch_a_emp_retirement: "decimal",
  sch_a_emp_state_local_tax: "decimal",
  sch_a_fte_reduction_yn: "boolean",
  sch_a_owner_comp: "decimal",
  sch_a_table1_avg_fte: "decimal",
  sch_a_table1_cash_comp: "decimal",
  sch_a_table2_avg_fte: "decimal",
  sch_a_table2_cash_comp: "decimal",
  sch_a_total_avg_fte: "decimal",
  two_million: "boolean"
});

export const FULL_FORM_STATE_DATA_TYPES = FORM_STATE_DATA_TYPES;

const {
  covered_period_len,
  emp_at_frgvn_app,
  frgvn_start_date,
  frgvn_end_date,
  two_million,
  payroll_costs,
  calc_form_business_mortgage_int,
  calc_form_business_rent_lease,
  calc_form_business_utilities,
  calc_form_pr_nonpr_cost_subtotal,
  calc_form_operations_exp,
  calc_form_property_damage,
  calc_form_supplier_costs,
  calc_form_worker_protection,
  sba_ppp_loan_amount,
  calc_form_payroll_pct,
  frgvn_amount,
  ppp_loan_draw
} = FORM_STATE_DATA_TYPES;

export const EZ_FORM_STATE_DATA_TYPES: FormStateDataTypes = {
  covered_period_len,
  emp_at_frgvn_app,
  frgvn_start_date,
  frgvn_end_date,
  payroll_costs,
  calc_form_business_mortgage_int,
  calc_form_business_rent_lease,
  calc_form_business_utilities,
  calc_form_pr_nonpr_cost_subtotal,
  calc_form_operations_exp,
  calc_form_property_damage,
  calc_form_supplier_costs,
  calc_form_worker_protection,
  sba_ppp_loan_amount,
  ppp_loan_draw,
  calc_form_payroll_pct,
  frgvn_amount,
  two_million
};

export const SIMPLE_FORM_STATE_DATA_TYPES: FormStateDataTypes = {
  covered_period_len,
  emp_at_frgvn_app,
  frgvn_amount,
  frgvn_start_date,
  frgvn_end_date,
  sba_ppp_loan_amount,
  ppp_loan_draw,
  two_million,
  payroll_costs
};

export const VALIDATION_DATA_TYPES: FormStateDataTypes = {
  address1: "string",
  address2: "string",
  alternative_covered_period: "integer",
  borrower_name: "string",
  borrower_tradename: "string",
  business_phone: "string",
  city: "string",
  contact_email: "string",
  contact_name: "string",
  emp_at_app: "integer",
  form_type: "string",
  lender_ppp_loan_no: "string",
  mobile_phone: "string",
  naics_code: "integer",
  sba_ppp_loan_no: "string",
  state: "string",
  tin: "string",
  zip: "string"
};

export const JWT_REFRESH_INTERVAL_MINUTES = 20;

export const LENDER_DECISION_MAP = Object.freeze({
  APPROVED_IN_FULL: 0,
  APPROVED_IN_PART: 1,
  DENIED: 2
});

export const LOCKED_APP_STATUSES: Readonly<AppStatus[]> = Object.freeze([
  APP_STATUSES.FORGIVEN
]);

export const LOCKED_APP_STATUSES_FOR_DOC_REF: Readonly<AppStatus[]> =
  Object.freeze([APP_STATUSES.FORGIVEN, APP_STATUSES.SBA_APPROVAL_PENDING]);

export const MAXIMUM_CHARACTERS = 1000;

export const FULL_FORM_FILE_TYPE = "SBA Form 3508";
export const EZ_FORM_FILE_TYPE = "SBA Form 3508EZ";
export const SIMPLE_FORM_FILE_TYPE = "SBA Form 3508S";
export const SBA_2483_FILE_TYPE = "SBA Form 2483";
export const SBA_2483_SD_FILE_TYPE = "SBA Form 2483SD";
export const SBA_2484_FILE_TYPE = "SBA Form 2484";
export const SBA_2484_SD_FILE_TYPE = "SBA Form 2484SD";
export const PROMISSORY_NOTE_FILE_TYPE = "Promissory Note";

export const SBA_3508_FILE_TYPES = Object.freeze([
  FULL_FORM_FILE_TYPE,
  EZ_FORM_FILE_TYPE,
  SIMPLE_FORM_FILE_TYPE
]);

export const SBA_FILE_TYPES: Readonly<string[]> = Object.freeze([
  SBA_2483_FILE_TYPE,
  SBA_2483_SD_FILE_TYPE,
  "Loan Application Supporting Docs (Payroll)",
  SBA_2484_FILE_TYPE,
  FULL_FORM_FILE_TYPE,
  EZ_FORM_FILE_TYPE,
  SIMPLE_FORM_FILE_TYPE,
  "Borrower Note",
  "Transcript of Account",
  "Forgiveness Supporting Docs (Payroll)",
  "Forgiveness Supporting Docs (FTE)",
  "Forgiveness Supporting Docs (Mortgage Interest Payments)",
  "Forgiveness Supporting Docs (Rent/Lease Payments)",
  "Forgiveness Supporting Docs (Utility Payments)",
  "PPP Borrower Demographic Information Form",
  "PPP Schedule A",
  "PPP Schedule A Worksheet",
  "3508-EZ Supporting Docs (Salary & Wage Certification)",
  "3508 & 3508-EZ Supporting Docs (Job Offer, Refusal, etc. Certification)",
  "3508-EZ Supporting Docs (FTE Certification)",
  "3508 & 3508-EZ Supporting Docs (Public Health Operating Restrictions)",
  "PPP Schedule A Worksheet - Table 1",
  "PPP Schedule A Worksheet - Table 2",
  "PPP Schedule A Worksheet - FTE Reduction Safe Harbor 2",
  "Miscellaneous"
]);

export const SBA_FILE_TYPES_ID_MAP = Object.freeze({
  "Denial Justification": 3,
  "Loan Application Supporting Documents for Self-Employed Individuals, Independent Contractors and Partners": 4,
  "Faith-Based Addendum to 2483": 5,
  "Addendum B to 2483": 6,
  "Addendum A to 2483": 7,
  "Forgiveness Supporting Docs (Mortgage Interest Payments)": 8,
  Miscellaneous: 9,
  "PPP Schedule A Worksheet - FTE Reduction Safe Harbor 2": 10,
  "PPP Schedule A Worksheet - Table 2": 11,
  "PPP Schedule A Worksheet - Table 1": 12,
  "3508 & 3508-EZ Supporting Docs (Public Health Operating Restrictions)": 13,
  "3508-EZ Supporting Docs (FTE Certification)": 14,
  "3508 & 3508-EZ Supporting Docs (Job Offer, Refusal, etc. Certification)": 15,
  "3508-EZ Supporting Docs (Salary & Wage Certification)": 16,
  "PPP Schedule A Worksheet": 17,
  "PPP Schedule A": 18,
  "PPP Borrower Demographic Information Form": 19,
  "Forgiveness Supporting Docs (Utility Payments)": 20,
  "Forgiveness Supporting Docs (Rent/Lease Payments)": 21,
  "Forgiveness Supporting Docs (FTE)": 22,
  "Forgiveness Supporting Docs (Payroll)": 23,
  "Transcript of Account": 24,
  "Borrower Note": 25,
  "SBA Form 3508EZ": 26,
  "SBA Form 3508": 27,
  "SBA Form 3508S": 35,
  [SBA_2483_FILE_TYPE]: 30,
  [SBA_2484_FILE_TYPE]: 31,
  [SBA_2483_SD_FILE_TYPE]: 30, // As of January 19, 2021 the SBA documents endpoint does not include an SD form id.
  "Loan Application Supporting Docs (Payroll)": 1
});

export const DOCUSIGN_LOCAL_STORAGE_KEY = "docusignConfig";
export const DOCUSIGN_ENABLED = "docusignEnabled";
export const ERROR_PHONE = "ERROR_PHONE";
export const ERROR_MFA = "ERROR_MFA";
export const ERROR_SET_PASSWORD = "ERROR_SET_PASSWORD";
export const ERROR_SET_EULA = "ERROR_SET_EULA";
export const ERROR_SEND_TOKEN = "ERROR_SEND_TOKEN";
export const ERROR_MISSING_INSTITUTION = "ERROR_MISSING_INSTITUTION";
export const ERROR_INSTITUTION_NOT_CONFIGURED =
  "ERROR_INSTITUTION_NOT_CONFIGURED";
export const ERROR_APP_NOT_FOUND = "ERROR_APP_NOT_FOUND";

export const FORM_TYPE_EZ = "EZ";
export const FORM_TYPE_FULL = "Full";
export const FORM_TYPE_SIMPLE = "Simple";
export const FORM_TYPE_2483 = "2483";
export const FORM_TYPE_2483_SD = "2483SD";
export const FORM_TYPE_2484 = "2484";
export const FORM_TYPE_2484_SD = "2484SD";
export const FORM_TYPE_PROMISSORY_NOTE = "PromissoryNote";

export const SBA_STATUSES = Object.freeze({
  PENDING_VALIDATION: "Pending Validation",
  UNDER_REVIEW: "Under Review",
  FULLY_APPROVED: "Fully Approved",
  NOT_APPROVED: "Not Approved",
  PARTIALLY_APPROVED: "Partially Approved",
  LENDER_ADDITIONAL_INFO_NEEDED: "Lender Additional Info Needed",
  PAYMENT_SENT: "Payment Sent",
  PAYMENT_CONFIRMED: "Payment Confirmed",
  PAYMENT_FAILED: "Payment Failed"
});

export const SBA_INCOMPLETE_STATUSES = Object.freeze([
  SBA_STATUSES.PENDING_VALIDATION,
  SBA_STATUSES.UNDER_REVIEW,
  SBA_STATUSES.FULLY_APPROVED,
  SBA_STATUSES.PARTIALLY_APPROVED,
  SBA_STATUSES.PAYMENT_SENT,
  SBA_STATUSES.PAYMENT_FAILED,
  SBA_STATUSES.LENDER_ADDITIONAL_INFO_NEEDED
]);

export const SBA_FORGIVEN_STATUESES = Object.freeze([
  SBA_STATUSES.FULLY_APPROVED,
  SBA_STATUSES.PARTIALLY_APPROVED,
  SBA_STATUSES.PAYMENT_SENT,
  SBA_STATUSES.PAYMENT_CONFIRMED,
  SBA_STATUSES.PAYMENT_FAILED
]);

export const SBA_ORIGINATION_STATUSES = Object.freeze({
  PENDING_VALIDATION: "Pending Validation",
  FAILED_VALIDATION: "Failed Validation",
  UNDER_REVIEW: "Under Review",
  APPROVED: "Approved by SBA",
  NOT_APPROVED: "Not Approved by SBA"
});

export const DOC_NOT_FOUND_MESSAGE = "Document Not Found!";

export const PPP_DRAW_NUMBER: Readonly<Record<string, any>> = Object.freeze({
  0: "First Draw",
  1: "Second Draw"
});
