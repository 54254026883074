/* eslint-env browser */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Radio, Segment, Input } from "semantic-ui-react";
import { Documents } from "../../services/ApiLib";
import { FILE_MIME_TYPES_MAP } from "../../services/Constants/AllowedDocumentTypes";

import { DocApprovalModal } from "../Modals";
import DocCategories from "./components/DocCategories";
import logger from "../../services/logger";

function onError(response) {
  logger.error(response);
}

class CollateralDocumentsObj extends Component {
  constructor(props) {
    super(props);
    this.onPreview = this.onPreview.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onSave = this.onSave.bind(this);
    this.handleCollateralDocSearch = this.handleCollateralDocSearch.bind(this);
  }

  handleCollateralDocSearch(e, searchInfo) {
    this.props.dispatch({
      type: "CMS_COLLATERAL_DOCUMENTS_SEARCH_FILTER",
      searchInfo: searchInfo.value
    });
  }

  onSave(metadata) {
    this.props.dispatch({
      type: "COLLATERAL_DOCUMENTS_SELECT_DOCUMENT",
      obj: {}
    });
    this.props.dispatch({
      type: "DOC_APPROVAL_ADD_DOC_DETAILS",
      docDetails: {}
    });
    this.props.dispatch({
      type: this.props.type,
      data: metadata.data
    });
  }

  onOpenModal(obj) {
    this.props.dispatch({
      type: "COLLATERAL_DOCUMENTS_SELECT_DOCUMENT",
      obj
    });
    this.props.dispatch({
      type: "DOC_APPROVAL_ADD_DOC_DETAILS",
      docDetails: obj
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async onPreview(objectUuid, fileType, fileName) {
    try {
      const endpointFileType =
        fileType === FILE_MIME_TYPES_MAP.pdf.mimeType ? "preview" : "download";
      if (endpointFileType === "preview") {
        await Documents.preview({ uuid: objectUuid }, { fileType, fileName });
      } else if (endpointFileType === "download") {
        await Documents.download({ uuid: objectUuid }, { fileType, fileName });
      }
    } catch (err) {
      onError(err);
    }
  }

  render() {
    const { data: newData, dispatch, managementType } = this.props;
    const data = newData.filter(obj =>
      obj?.docType
        ?.toLowerCase()
        .startsWith(this.props.collateralDocsFilter?.toLowerCase())
    );

    const docTypekeys = [];

    data.forEach(doc => {
      if (!docTypekeys.includes(doc.docType)) {
        docTypekeys.push(doc.docType);
      }
    });

    docTypekeys.sort();
    const filteredData = data.reduce((acc, doc) => {
      docTypekeys.forEach(key => {
        if (!acc[key]) {
          acc[key] = [];
        }
        if (key === doc.docType) {
          acc[key].push(doc);
        }
      });
      return acc;
    }, {});

    const tableBody = Object.keys(filteredData).map(key => (
      <DocCategories
        key={key}
        data={filteredData[key]}
        documentType={key}
        onPreview={this.onPreview}
        onOpenModal={this.onOpenModal}
        dispatch={dispatch}
        managementType={managementType}
      />
    ));

    return (
      <Segment.Group>
        <Segment>
          {/* {TODO make buttons work until further clarification} */}
          <Grid>
            <Grid.Row columns="2">
              <Grid.Column textAlign="left">
                <Input
                  icon="search"
                  onChange={this.handleCollateralDocSearch}
                  placeholder="Search Document Type"
                  value={this.props.collateralDocsFilter}
                />
              </Grid.Column>
              <Grid.Column textAlign="right">
                <Radio toggle label="Include Archived Documents" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment>{tableBody}</Segment>
        {this.props.openModal ? (
          <DocApprovalModal
            approvalType="Document"
            docDetails={this.props.selectedDocument}
            onSave={this.onSave}
            open
            onClose={this.onOpenModal}
            institutionUuid={this.props.institutionUuid}
          />
        ) : (
          ""
        )}
      </Segment.Group>
    );
  }
}

CollateralDocumentsObj.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  institutionUuid: PropTypes.string,
  openModal: PropTypes.bool,
  selectedDocument: PropTypes.shape({
    entity: PropTypes.string,
    category: PropTypes.string,
    docType: PropTypes.string,
    asset: PropTypes.string,
    loan: PropTypes.string,
    periodStart: PropTypes.string,
    periodEnd: PropTypes.string,
    docDate: PropTypes.string,
    uploadedDate: PropTypes.string,
    docDescription: PropTypes.string,
    status: PropTypes.string,
    uuid: PropTypes.string
  }),
  type: PropTypes.string.isRequired,
  managementType: PropTypes.string.isRequired,
  collateralDocsFilter: PropTypes.string
};

const mapStateToProps = state => ({
  openModal: state.CollateralDocumentsReducer.openModal,
  selectedDocument: state.CollateralDocumentsReducer.selectedDocument,
  collateralDocsFilter: state.CollateralDocumentsReducer.collateralDocsFilter
});

const CollateralDocuments = connect(mapStateToProps)(CollateralDocumentsObj);

export { CollateralDocuments, CollateralDocumentsObj };
