import { useState } from "react";
import { NumberInputWithLabel } from "@bafsllc/ui-shared";
import { useIntl } from "react-intl";
import { StateAwareFieldChangeFunction } from "../../services/types";

export const OriginationFeeInput = ({
  handleInputChange,
  origination_fee
}: OriginationFeeInputProps) => {
  const { formatMessage } = useIntl();
  const [format, setFormat] = useState<OriginationFeeFormat>("dollar");

  const handleChange = e => {
    handleInputChange([
      { value: parseFloat(e) || undefined, stateColumnName: "origination_fee" },
      e
        ? { value: format, stateColumnName: "origination_fee_type" }
        : { value: undefined, stateColumnName: "origination_fee_type" }
    ]);
  };

  return (
    <NumberInputWithLabel
      dropdown
      format={format}
      handleInputChange={handleChange}
      labelText={formatMessage({ id: "ORIGINATION_FEE" })}
      setFormat={setFormat}
      testId={originationFeeTestId}
      value={origination_fee?.toString()}
    />
  );
};

interface OriginationFeeInputProps {
  handleInputChange: StateAwareFieldChangeFunction;
  origination_fee: number;
}

export const originationFeeTestId = "origination-fee-input";

export type OriginationFeeFormat = "dollar" | "percentage";
