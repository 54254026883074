import React, { useState } from "react";
import { Modal, Button, Input, Form } from "semantic-ui-react";
import PropTypes from "prop-types";

function FrictionModal(props) {
  const {
    header,
    message,
    warning,
    validateString = "",
    open,
    onSubmit,
    onCancel,
    confirmLabelText = "Submit",
    cancelLabelText = "Cancel"
  } = props;
  const [inputValue, updateInput] = useState("");
  const handleSubmit = () => {
    if (inputValue === validateString) {
      onSubmit();
      updateInput("");
    }
  };
  const handleCancel = (...args) => {
    updateInput("");
    onCancel(...args);
  };
  return (
    <Modal open={open} size="small" onClose={handleCancel}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <p style={{ color: "#d14f13", fontWeight: 600 }}>{message}</p>
        {warning && (
          <div style={{ color: "#d14f13", whiteSpace: "pre-wrap" }}>
            {warning}
          </div>
        )}
        {validateString && (
          <>
            <p>{`Type in "${validateString}" below and click ${confirmLabelText}`}</p>
            <Form onSubmit={handleSubmit}>
              <Input
                placeholder="Enter Text"
                onChange={e => updateInput(e.target.value)}
                value={inputValue}
              />
            </Form>
          </>
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button basic onClick={handleCancel}>
          {cancelLabelText}
        </Button>
        <Button
          color="orange"
          onClick={handleSubmit}
          disabled={inputValue !== validateString}
        >
          {confirmLabelText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

FrictionModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  header: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  warning: PropTypes.string,
  validateString: PropTypes.string,
  confirmLabelText: PropTypes.string,
  cancelLabelText: PropTypes.string,
  open: PropTypes.bool.isRequired
};

export default FrictionModal;
