import { FormatUrlV2 } from "../FormatUrl";
import { getToken } from "../Auth";
import ApiEndpoints from "../ApiEndpoints";
import { ConcatQueryParams } from "../ApiLib/services/Utilities";
import { getBlastAPIBaseUrl } from "../../components/BlastEmbed/getBaseUrl";

const baseUri = getBlastAPIBaseUrl();

export async function renderFinancialAnalysisTemplate(
  spreadUuid,
  institutionUuid
) {
  const uuids = {
    spreadUuid,
    institutionUuid
  };
  const url = FormatUrlV2(
    ApiEndpoints.financialAnalysisTemplate,
    uuids,
    baseUri
  );
  const fetchParams = {
    method: "POST",
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json"
    }
  };

  const response = await fetch(url, fetchParams);
  if (!response.ok) {
    throw Error(response.statusText);
  }
  const res = await response.json();
  if (res.error) {
    throw res.error;
  }
  return res;
}

export async function createFinancialAnalysisReport(
  spreadUuid,
  institutionUuid,
  fileUuid
) {
  const uuids = {
    spreadUuid,
    institutionUuid,
    fileUuid
  };
  const url = FormatUrlV2(ApiEndpoints.financialAnalysisReport, uuids, baseUri);
  const fetchParams = {
    method: "POST",
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json"
    }
  };

  const response = await fetch(url, fetchParams);
  if (!response.ok) {
    throw Error(response.statusText);
  }
  const res = await response.json();
  if (res.error) {
    throw res.error;
  }
  return res;
}

export async function mergeDocuments(query) {
  const response = await fetch(
    `${baseUri}${ApiEndpoints.mergeDocuments}${ConcatQueryParams(query)}`,
    {
      method: "POST",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json"
      }
    }
  );

  if (!response.ok) {
    throw Error(response.statusText);
  }

  const responseJson = await response.json();

  if (responseJson.error) {
    throw responseJson.error;
  }

  return responseJson;
}

async function sleep(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

export async function pollObjectContent(
  docxUuid,
  institutionUuid,
  pollingInterval
) {
  let isReady = false;
  const fetchParams = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken()
    }
  };

  while (!isReady) {
    // eslint-disable-next-line no-await-in-loop
    const url = FormatUrlV2(ApiEndpoints.objectDownloadsWithInstitution, {
      uuid: docxUuid,
      institutionUuid
    });
    // eslint-disable-next-line no-await-in-loop
    const document = await fetch(url, fetchParams);
    if (!document.ok) {
      return document;
    }
    // eslint-disable-next-line no-await-in-loop
    const content = await document.blob();
    if (content.size) {
      isReady = true;
    } else {
      // eslint-disable-next-line no-await-in-loop
      await sleep(pollingInterval);
    }
  }
  return isReady;
}
