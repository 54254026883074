import React, { Component } from "react";
import { Button, Modal, Form, Select } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { PercentInput } from "../../../../../../../../components/CustomFormElements";
import { ConcatName } from "../../../../../../../../services/Entities";
import { relationshipTypes } from "../../../../../../../../services/Constants/relationshipTypes";
import { Relationships } from "../../../../../../../../services/ApiLib";
import logger from "../../../../../../../../services/logger";

function onError(rsp) {
  logger.error("Error: ", rsp); // TODO: Handle errors properly
}

export class LoanRelEditModalObj extends Component {
  constructor(props) {
    super(props);
    this.generateForm = this.generateForm.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChangeForm = this.handleChangeForm.bind(this);
    this.handleChangeNumber = this.handleChangeNumber.bind(this);
    this.onPutSuccess = this.onPutSuccess.bind(this);
    this.setGuarantorDetails = this.setGuarantorDetails.bind(this);
  }

  onPutSuccess() {
    const { loanUuid } = this.props;
    Relationships.asyncRead({
      parent_uuid: loanUuid,
      rel_type: relationshipTypes.GUARANTOR
    })
      .then(result => this.setGuarantorDetails(result))
      .catch(error => onError(error));
    this.handleCloseModal();
  }

  setGuarantorDetails(data) {
    this.props.dispatch({
      type: "LOAN_MGMT_SET_GUARANTOR_DETAILS",
      loanGuarantors: data
    });
  }

  handleCloseModal() {
    this.props.dispatch({
      type: "LOAN_MGMT_CLOSE_EDIT_MODAL"
    });
  }

  handleChangeForm({ name, value }) {
    this.props.dispatch({
      type: "LOAN_MGMT_UPDATE_EDIT_MODAL_INFO",
      name,
      value
    });
  }

  handleChangeNumber(data) {
    this.props.dispatch({
      type: "LOAN_MGMT_UPDATE_EDIT_MODAL_INFO",
      name: data.name,
      value: Number(data.value)
    });
  }

  handleSave() {
    const { editModalFormData } = this.props;
    const uuid = editModalFormData.relationshipUuid;

    let amountOrPercentKey;
    let amountOrPercentValue;
    if (editModalFormData.amount_or_percent === "amount") {
      amountOrPercentKey = "amount_guaranteed";
      amountOrPercentValue = editModalFormData.amount_guaranteed;
    } else {
      amountOrPercentKey = "percent_guaranteed";
      amountOrPercentValue = editModalFormData.percent_guaranteed;
    }

    const body = {
      full_vs_limited: editModalFormData.full_vs_limited,
      [amountOrPercentKey]: amountOrPercentValue,
      amount_or_percent: editModalFormData.amount_or_percent
    };

    Relationships.asyncUpdate(uuid, body)
      .then(result => {
        this.onPutSuccess(result);
      })
      .catch(error => {
        onError(error);
      });
  }

  generateForm() {
    const guarAmtOptions = [
      { text: "Amount Guaranteed", value: "amount" },
      { text: "Percent Guaranteed", value: "percent" }
    ];
    const fullVlimitedOptions = [
      { text: "Full", value: "Full" },
      { text: "Limited", value: "Limited" }
    ];

    const { editModalFormData } = this.props;
    let amountPercentInput;
    if (editModalFormData.amount_or_percent === "amount") {
      amountPercentInput = (
        <Form.Input
          name="amount_guaranteed"
          value={editModalFormData.amount_guaranteed}
          label="Amount Guaranteed"
          onChange={(e, data) => this.handleChangeNumber(data)}
        />
      );
    } else {
      amountPercentInput = (
        <PercentInput
          name="percent_guaranteed"
          value={editModalFormData.percent_guaranteed}
          label="Percent Guaranteed"
          onChange={(e, data) => this.handleChangeNumber(data)}
        />
      );
    }

    return (
      <Form>
        <Form.Group>
          <Form.Input
            label="Name"
            readOnly
            placeholder={
              Object.keys(editModalFormData).length !== 0
                ? ConcatName(editModalFormData)
                : ""
            }
            width={7}
          />
          <Form.Input
            label="TIN"
            readOnly
            placeholder={
              Object.keys(editModalFormData).length !== 0
                ? editModalFormData.tin
                : ""
            }
            width={7}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            name="full_vs_limited"
            control={Select}
            options={fullVlimitedOptions}
            value={editModalFormData.full_vs_limited}
            label="Full vs Limited"
            onChange={(e, data) => this.handleChangeForm(data)}
          />
          <Form.Input
            name="amount_or_percent"
            control={Select}
            options={guarAmtOptions}
            label="Guaranteed Amount"
            value={editModalFormData.amount_or_percent}
            onChange={(e, data) => this.handleChangeForm(data)}
          />
          {amountPercentInput}
        </Form.Group>
      </Form>
    );
  }

  render() {
    return (
      <Modal open={this.props.openModal} onClose={this.handleCloseModal}>
        <Modal.Header>Edit Guarantor</Modal.Header>
        <Modal.Content>{this.generateForm()}</Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.handleCloseModal}>
            Cancel
          </Button>
          <Button primary onClick={this.handleSave}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

LoanRelEditModalObj.defaultProps = {
  editModalFormData: {}
};

LoanRelEditModalObj.propTypes = {
  editModalFormData: PropTypes.shape({
    first_name: PropTypes.string,
    middle_name: PropTypes.string,
    last_name: PropTypes.string,
    tin: PropTypes.string,
    amount_or_percent: PropTypes.string,
    full_vs_limited: PropTypes.string,
    percent_guaranteed: PropTypes.number,
    amount_guaranteed: PropTypes.number,
    relationshipUuid: PropTypes.string
  }),
  openModal: PropTypes.bool.isRequired,
  loanUuid: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  editModalFormData: state.LoanManagementRelationshipsReducer.editModalFormData,
  guarantors: state.LoanManagementRelationshipsReducer.guarantors,
  loanUuid: state.LoanManagementReducer.loan.uuid
});

const LoanRelEditModal = connect(mapStateToProps)(LoanRelEditModalObj);

export default LoanRelEditModal;
