import React from "react";
import { Button, Container, Header, Icon, Table } from "semantic-ui-react";
import PropTypes from "prop-types";
import {
  prettyDate,
  formatDateTimeString
} from "../../../../services/DateTime";
import {
  getFileMimeType,
  FILE_MIME_TYPES_MAP
} from "../../../../services/Constants/AllowedDocumentTypes";
import { EditButton } from "../../../CustomFormElements";
import BorrowerVisibilityToggle from "../../../../scenes/LOS/components/Documents/components/DocTable/BorrowerVisibilityToggle";
import { ObjectMetadata } from "../../../../services/ApiLib";
import logger from "../../../../services/logger";

export function onError(response) {
  logger.error(response);
}

const tableHeaders = [
  "Document Type",
  "Period Start",
  "Period End",
  "Doc Date",
  "Uploaded Date",
  "Source",
  "Description",
  "Status"
];

function DocCategories(props) {
  const { onPreview, onOpenModal, dispatch, managementType } = props;
  const sortedDocData = props.data
    .sort((currDoc, nextDoc) =>
      currDoc.docDate ? currDoc.docDate.localeCompare(nextDoc.docDate) : currDoc
    )
    .reverse();

  const onToggle = async obj => {
    try {
      const res = await ObjectMetadata.updateAsync(obj.uuid, {
        borrowerVisibility: !obj.borrowerVisibility
      });
      if (managementType === "Loan") {
        dispatch({
          type: "LOAN_MANAGEMENT/UPDATE_SINGLE_ACCOUNT_DOCUMENT",
          doc: res.data
        });
      } else {
        dispatch({
          type: "ASSET_MANAGEMENT_UPDATE_SINGLE_ASSET_DOCUMENT",
          doc: res.data
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  sortedDocData.map(obj => {
    const header = "Borrower Visibility";
    const borrowerVisibility = tableHeaders.find(a => a === header);

    if (obj.bpOrganizationUuid) {
      if (borrowerVisibility) {
        return tableHeaders;
      }
      tableHeaders.splice(8, 0, header);

      return tableHeaders;
    }

    if (borrowerVisibility) {
      tableHeaders.splice(8, 1);
    }

    return tableHeaders;
  });
  const headers = tableHeaders.map(header => (
    <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
  ));

  headers.push(<Table.HeaderCell key="empty" />);
  const body = sortedDocData.map(obj => {
    const { mimeType } = getFileMimeType(obj.fileName);
    const iconName =
      mimeType === FILE_MIME_TYPES_MAP.pdf.mimeType ? "eye" : "download";
    return (
      <Table.Row key={obj.uuid}>
        <Table.Cell>{obj.docType}</Table.Cell>
        <Table.Cell>
          {obj.periodStart ? prettyDate(obj.periodStart) : ""}
        </Table.Cell>
        <Table.Cell>
          {obj.periodEnd ? prettyDate(obj.periodEnd) : ""}
        </Table.Cell>
        <Table.Cell>{obj.docDate ? prettyDate(obj.docDate) : ""}</Table.Cell>
        <Table.Cell>
          {obj.uploadDate ? formatDateTimeString(obj.uploadDate) : ""}
        </Table.Cell>
        <Table.Cell>{obj.source}</Table.Cell>
        <Table.Cell>{obj.description}</Table.Cell>
        <Table.Cell>{obj.status}</Table.Cell>
        {obj.bpOrganizationUuid ? (
          <Table.Cell>
            <BorrowerVisibilityToggle doc={obj} onToggle={onToggle} />
          </Table.Cell>
        ) : null}
        <Table.Cell>
          <Button
            basic
            circular
            data-testid={`document-preview-${obj.uuid}`}
            icon={<Icon color="green" name={iconName} />}
            onClick={() => onPreview(obj.uuid, mimeType, obj.fileName)}
          />
          <EditButton onClick={() => onOpenModal(obj)} />
        </Table.Cell>
      </Table.Row>
    );
  });

  return (
    <Container style={{ marginTop: "3%" }}>
      <Header as="h1" textAlign="left" size="large">
        {props.documentType}
      </Header>
      <Table celled>
        <Table.Header>
          <Table.Row>{headers}</Table.Row>
        </Table.Header>
        <Table.Body>{body}</Table.Body>
      </Table>
    </Container>
  );
}

DocCategories.propTypes = {
  documentType: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      col1: PropTypes.string,
      col2: PropTypes.string,
      col3: PropTypes.string,
      col4: PropTypes.string,
      col5: PropTypes.string
    })
  ).isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  managementType: PropTypes.string.isRequired
};

export default DocCategories;
