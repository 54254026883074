import { Route, Switch } from "react-router-dom";

import { ToastProvider } from "@bafsllc/ui-shared";
import LoanOriginationStepper from "./scenes/LoanOriginationStepper";
import LoanOriginationPhases from "./scenes/LoanOriginationPhases";
import LoanOriginationPrepareDocs from "./scenes/LoanOriginationPrepareDocs";

function LOR() {
  return (
    <div>
      <ToastProvider>
        <Switch>
          <Route path="/los/:uuid/stepper" component={LoanOriginationStepper} />
          <Route path="/los/:uuid/phases" component={LoanOriginationPhases} />
          <Route
            path="/los/:uuid/prepare-documents"
            component={LoanOriginationPrepareDocs}
          />
        </Switch>
      </ToastProvider>
    </div>
  );
}

export default LOR;
