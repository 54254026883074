import _ from "lodash";

export const initialState = {
  institutions: [],
  institution: {},
  templates: {},
  dataTemplatesDoneLoading: false,
  menuItem: 0,
  participationServicer: {},
  participationEditModalOpen: false
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case "SYSTEM_ADMIN_INSTITUTIONS":
      return { ...state, institutions: action.data };

    case "SYSTEM_ADMIN_INSTITUTIONS_ALL":
      return { ...state, allInstitutions: action.data };

    case "SYSTEM_ADMIN_INSTITUTION":
      return {
        ...state,
        institutions: state.institutions.map(i => {
          if (i.uuid === action.data.uuid) {
            return action.data;
          }
          return i;
        })
      };

    case "SYSTEM_ADMIN_INSTITUTION_NEW":
      return {
        ...state,
        institutions: [...state.institutions, action.data]
      };

    case "SET_SYSTEM_ADMIN_INSTITUTION":
      return {
        ...state,
        institution: action.institution
      };

    case "UPDATE_SYSTEM_ADMIN_INSTITUTION":
      return {
        ...state,
        institution: { ...state.institution, ...action.institution }
      };

    case "SYSTEM_ADMIN_STORE_DATA_TEMPLATES": {
      const { obj } = action;
      const newTemplates = _.cloneDeep(state.templates);

      newTemplates[obj.docCategory] = obj;
      return {
        ...state,
        templates: newTemplates
      };
    }

    case "SYSTEM_ADMIN_UPDATE_DATA_TEMPLATES": {
      const { template } = action;

      const newTemplates = _.cloneDeep(state.templates);
      delete newTemplates[template];

      return {
        ...state,
        templates: newTemplates
      };
    }

    case "SYSTEM_ADMIN_DATA_TEMPLATES_LOADED":
      return {
        ...state,
        dataTemplatesDoneLoading: true
      };

    case "SYSTEM_ADMIN_MODAL_OPEN": {
      return {
        ...state,
        participationEditModalOpen: true
      };
    }

    case "SYSTEM_ADMIN_MODAL_CLOSE": {
      return {
        ...state,
        participationEditModalOpen: false
      };
    }

    case "SYSTEM_ADMIN_INSTITUTIONS_ERROR":
      return { ...state, error: action.error };

    case "SYSTEM_ADMIN_UPDATE_SERVICER":
      return { ...state, participationServicer: action.participationServicer };

    case "SYSTEM_ADMIN_CHANGE_MENU_ITEM":
      return { ...state, menuItem: action.menuItem };

    case "SYSTEM_ADMIN_UPDATE_INSTITUTION_VALUE": {
      const newServicerData = _.cloneDeep(state.participationServicer);
      newServicerData[action.name] = action.value;
      return { ...state, participationServicer: newServicerData };
    }

    default:
      return state;
  }
};
