export const initialState = {
  isOpen: false
};

export default (state, action) => {
  const safeState = state ?? initialState;
  switch (action.type) {
    case "ALERT_TRIGGER_ALERT":
      return { ...safeState, isOpen: true };
    case "ALERT_DISMISS_ALERT":
      return { ...safeState, isOpen: false };
    default:
      return safeState;
  }
};
