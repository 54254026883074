import PropTypes from "prop-types";
import { FormatPercent } from "num-format";
import Constant from "../../../services/Constants/strings";

function PercentCell({ cell }) {
  if (cell.value === Constant.NotApplicable) {
    return Constant.NotApplicable;
  }
  if (typeof cell.value === "number") {
    return FormatPercent(cell.value, 3);
  }
  return "—";
}

PercentCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.node
    ])
  })
};

export default PercentCell;
