export const initialState = {
  lookupData: {}
};

export default function Reducer(state, action) {
  const safeState = state ?? initialState;
  switch (action.type) {
    case "UUID_LOOKUPS_ADD_STRING":
      return {
        ...safeState,
        lookupData: {
          ...safeState.lookupData,
          [action.uuid]: action.string
        }
      };

    case "UUID_LOOKUPS_RESET_STATE":
      return initialState;

    default:
      return safeState;
  }
}
