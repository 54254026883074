import { LoanTransactionHistory } from "../../../../../../services/ApiLib";
import logger from "../../../../../../services/logger";
import TransactionHistoryInterface from "./scenes/HistoryTableInterface";
import PrintViewInterface from "./scenes/PrintView";
import { actionCreators } from "./reducer";

const { transactionHistoryPopulate } = actionCreators;

export default TransactionHistoryInterface;

const lookupAccount = async (
  dispatch,
  loanUuid,
  params = undefined,
  populate = transactionHistoryPopulate
) => {
  try {
    const { data, metaData } = await LoanTransactionHistory.read({
      loan_uuid: loanUuid,
      ...params
    });

    dispatch(populate({ data, metaData }));
  } catch (e) {
    logger.error(e);
  }
};

export { lookupAccount, PrintViewInterface };
