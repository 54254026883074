/* eslint-disable camelcase */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import SplitLayout from "../../../../components/SplitLayout";
import { EditModal } from "../../../../../../../../../../components/Modals";
import { RemoveEmptyStrings } from "../../../../../../../../../../services/ApiLib/services/Utilities";

function DatesEditModal({ loan, onSave, ...props }) {
  const [values, setValues] = useState({
    uuid: loan.uuid,
    next_review_date: loan.next_review_date || "",
    last_renewal_date: loan.last_renewal_date || "",
    last_extension_date: loan.last_extension_date || "",
    closed_date: loan.closed_date || "",
    maturity_date: loan.maturity_date || "",
    prev_payment_date: loan.prev_payment_date || "",
    current_contract_date: loan.current_contract_date || "",
    last_advance_date: loan.last_advance_date || "",
    contract_date: loan.contract_date || ""
  });

  const handleChange = ({ target }) => {
    const nextValues = { ...values };
    nextValues[target.name] = target.value;
    setValues(nextValues);
  };

  const handleSave = () => {
    onSave(RemoveEmptyStrings(values));
  };

  return (
    <EditModal
      key={loan.uuid}
      currentValues={values}
      onSave={handleSave}
      header="Edit Dates"
      {...props}
    >
      <SplitLayout>
        <Form.Field
          control={Form.Input}
          type="date"
          name="next_review_date"
          id="next_review_date"
          defaultValue={values.next_review_date}
          onChange={handleChange}
          label="Next Review Date"
        />
        <Form.Field
          control={Form.Input}
          type="date"
          name="last_renewal_date"
          id="last_renewal_date"
          defaultValue={values.last_renewal_date}
          onChange={handleChange}
          label="Last Renewal Date"
        />
        <Form.Field
          control={Form.Input}
          type="date"
          name="last_advance_date"
          id="last_advance_date"
          label="Last Advance Date"
          onChange={handleChange}
          defaultValue={values.last_advance_date}
        />
        <Form.Field
          control={Form.Input}
          type="date"
          name="last_extension_date"
          id="last_extension_date"
          defaultValue={values.last_extension_date}
          onChange={handleChange}
          label="Last Extension Date"
        />
        <Form.Field
          control={Form.Input}
          type="date"
          name="closed_date"
          id="closed_date"
          label="Closed"
          onChange={handleChange}
          defaultValue={values.closed_date}
        />
        <Form.Field
          control={Form.Input}
          type="date"
          name="maturity_date"
          id="maturity_date"
          label="Maturity Date"
          onChange={handleChange}
          defaultValue={values.maturity_date}
        />
        <Form.Field
          control={Form.Input}
          type="date"
          name="prev_payment_date"
          id="prev_payment_date"
          label="Previous Payment Date"
          onChange={handleChange}
          defaultValue={values.prev_payment_date}
        />
        <Form.Field
          control={Form.Input}
          type="date"
          name="current_contract_date"
          id="current_contract_date"
          label="Current Contract Date"
          onChange={handleChange}
          defaultValue={values.current_contract_date}
        />

        <Form.Field
          control={Form.Input}
          type="date"
          name="contract_date"
          id="contract_date"
          label="Original Contract Date"
          onChange={handleChange}
          defaultValue={values.contract_date}
        />
      </SplitLayout>
    </EditModal>
  );
}

DatesEditModal.propTypes = {
  loan: PropTypes.shape(),
  onSave: PropTypes.func
};

export default DatesEditModal;
