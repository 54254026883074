import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import Collateral from "../../components/Collateral";
import CreditRequest from "../../components/CreditRequest";
import Documents from "./scenes/StepTabs/scenes/Documents";
import EntityDetails from "../../components/EntityDetails";
import Guarantors from "./scenes/StepTabs/scenes/Guarantors";
import Messages from "../../components/Messages";
import ReviewSubmitScene from "./scenes/StepTabs/scenes/ReviewSubmit";
import StepTabsWithToast from "./scenes/StepsTabsWithToast";

const steps = [
  "entity_details",
  "credit_requests",
  "guarantors",
  "collateral",
  "documents",
  "review_submit"
];

function LoanOrigination({ history }) {
  const match = useRouteMatch();
  const location = useLocation();
  const loanAppUuid = match.params.uuid;

  const segments = location.pathname.split("/");
  const activeStep = steps.indexOf(segments[segments.length - 1]) + 1;

  return (
    <Switch>
      <Route path={match.path}>
        <StepTabsWithToast
          history={history}
          loanAppUuid={loanAppUuid}
          activeStep={activeStep}
        />

        <Switch>
          <Route exact path={`${match.path}/entity_details`}>
            <EntityDetails loanAppUuid={loanAppUuid} />
          </Route>

          <Route exact path={`${match.path}/collateral`}>
            <Collateral loanAppUuid={loanAppUuid} />
          </Route>

          <Route exact path={`${match.path}/documents`}>
            <Documents loanAppUuid={loanAppUuid} />
          </Route>

          <Route exact path={`${match.path}/credit_requests`}>
            <CreditRequest loanAppUuid={loanAppUuid} readOnly={false} />
          </Route>

          <Route exact path={`${match.path}/guarantors`}>
            <Guarantors loanAppUuid={loanAppUuid} />
          </Route>

          <Route exact path={`${match.path}/review_submit`}>
            <ReviewSubmitScene loanAppUuid={loanAppUuid} />
          </Route>

          <Route exact path={`${match.path}/messages`}>
            <Messages loanAppUuid={loanAppUuid} />
          </Route>
        </Switch>
      </Route>
    </Switch>
  );
}

LoanOrigination.propTypes = {
  history: PropTypes.shape({
    length: PropTypes.number,
    push: PropTypes.func
  }).isRequired
};

export default LoanOrigination;
