import _ from "lodash";
import Constants from "../../../../../../services/Constants/strings";

const formData = {
  first_name: "",
  middle_name: "",
  last_name: "",
  tin: ""
};

const initialState = {
  data: {},
  activeItem: Constants.COBORROWERS,
  coBorrowers: [],
  guarantors: [],
  entities: [],
  lookupObj: {},
  loanRelAddModalOpen: false,
  loanRelEditModalOpen: false,
  selectedEntity: "",
  newEntityType: "select entity",
  editModalFormData: formData,
  entitySearchString: ""
};

// eslint-disable-next-line default-param-last
export default function LoanManagementRelationshipsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case "LOAN_MGMT_MENU_ITEM":
      return {
        ...state,
        activeItem: action.menuItem
      };

    case "LOAN_MGMT_SET_COBORROWER_DETAILS": {
      return {
        ...state,
        coBorrowers: action.loanCoborrowers
      };
    }

    case "LOAN_MGMT_SET_GUARANTOR_DETAILS": {
      return {
        ...state,
        guarantors: action.loanGuarantors
      };
    }

    case "LOAN_MGMT_SET_LOOKUP_DETAILS": {
      const lookupGuarObj = _.cloneDeep(state.lookupObj);
      lookupGuarObj[action.entityData.uuid] = action.entityData;
      return {
        ...state,
        lookupObj: lookupGuarObj
      };
    }

    case "LOAN_MGMT_LOAD_ENTITIES": {
      return {
        ...state,
        entities: action.data
      };
    }

    case "LOAN_MGMT_OPEN_ADD_MODAL": {
      return {
        ...state,
        loanRelAddModalOpen: true
      };
    }

    case "LOAN_MGMT_CLOSE_ADD_MODAL": {
      return {
        ...state,
        loanRelAddModalOpen: false
      };
    }

    case "LOAN_MGMT_OPEN_EDIT_MODAL": {
      const guarantorPath =
        state.lookupObj[state.guarantors[action.guarantorIndex].child_uuid];
      const guarantorEntityInfo = _.cloneDeep(guarantorPath);
      const guarantorRelInfo = _.cloneDeep(
        state.guarantors[action.guarantorIndex]
      );
      const completeGuarantorInfo = {
        ...guarantorEntityInfo,
        ...guarantorRelInfo,
        relationshipUuid: guarantorRelInfo.uuid
      };
      return {
        ...state,
        editModalFormData: completeGuarantorInfo,
        loanRelEditModalOpen: true
      };
    }

    case "LOAN_MGMT_UPDATE_EDIT_MODAL_INFO": {
      const newGuarantorInfo = _.cloneDeep(state.editModalFormData);
      newGuarantorInfo[action.name] = action.value;
      return {
        ...state,
        editModalFormData: newGuarantorInfo
      };
    }

    case "LOAN_MGMT_CLOSE_EDIT_MODAL": {
      return {
        ...state,
        loanRelEditModalOpen: false,
        editModalFormData: formData
      };
    }

    case "LOAN_MGMT_SELECTED_ENTITY": {
      return {
        ...state,
        selectedEntity: action.selectedEntity
      };
    }

    case "LOAN_MGMT_SELECTED_ENTITY_TYPE": {
      return {
        ...state,
        newEntityType: action.newEntityType
      };
    }

    case "LOAN_MGMT_UPDATE_SEARCH_STRING": {
      return {
        ...state,
        entitySearchString: action.searchString
      };
    }

    case "LOAN_MGMT_CLEAR_SEARCH_STRING": {
      return {
        ...state,
        entitySearchString: ""
      };
    }

    default:
      return state;
  }
}
