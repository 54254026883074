import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import { TimeSpan } from "../../../../../../../../../../services/DateTime";
import { EditButton } from "../../../../../../../../../../components/CustomFormElements";
import { PrettyNumber } from "../../../../../../../../../../services/PrettyNumber";

function LoanTableRows({ loans, toggleLoanModal }) {
  return loans.map(loan => (
    <Table.Row
      key={loan.uuid}
      positive={loan.due_date ? TimeSpan(loan.due_date, "now") > 0 : false}
      negative={loan.due_date ? TimeSpan(loan.due_date, "now") >= 30 : false}
    >
      <Table.Cell>
        <a
          href={`/loan_management/${loan.uuid}/details/balance`}
          rel="noreferrer noopener"
          target="_blank"
        >
          <i className="linkify icon" />
          {loan.bafs_account_number}
        </a>
      </Table.Cell>
      <Table.Cell>
        <a
          href={`/loan_management/${loan.uuid}/details/balance`}
          rel="noreferrer noopener"
          target="_blank"
        >
          <i className="linkify icon" />
          {loan.institution_account_number}
        </a>
      </Table.Cell>
      <Table.Cell>
        ${loan.current_balance ? PrettyNumber(loan.current_balance) : ""}
      </Table.Cell>
      <Table.Cell>
        ${loan.available_balance ? PrettyNumber(loan.available_balance) : ""}
      </Table.Cell>
      <Table.Cell>
        ${loan.total_exposure ? PrettyNumber(loan.total_exposure) : ""}
      </Table.Cell>
      <Table.Cell>{loan.account_type}</Table.Cell>
      <Table.Cell>{loan.loan_class}</Table.Cell>
      <Table.Cell>{loan.loan_type}</Table.Cell>
      <Table.Cell>{loan.facility_grade}</Table.Cell>
      <Table.Cell>
        {loan.creditRiskRating
          ? loan.creditRiskRating
          : loan.deal_rating ?? "-"}
      </Table.Cell>
      <Table.Cell>{loan.status}</Table.Cell>
      <Table.Cell>{loan.participation}</Table.Cell>
      <Table.Cell collapsing>
        {!loan.phoenix_import && (
          <EditButton onClick={() => toggleLoanModal(loan)} />
        )}
      </Table.Cell>
    </Table.Row>
  ));
}

LoanTableRows.propTypes = {
  loans: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  toggleLoanModal: PropTypes.func.isRequired
};

export default LoanTableRows;
