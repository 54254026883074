/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { Grid, Form } from "semantic-ui-react";
import {
  DollarField,
  DateInput
} from "../../../../../../../../../../components/CustomFormElements";
import { EditModal } from "../../../../../../../../../../components/Modals";
import { forceValue } from "../../../../../../../../../../services/FormElements";

function PaymentEditModal({ loan, onSave, ...props }) {
  const handleSave = values => {
    if (Object.keys(values).length) {
      onSave(values);
    }
  };

  const accountDueOptions = [
    { key: "Yes", value: true, text: "Yes" },
    { key: "No", value: false, text: "No" }
  ];
  const {
    next_payment_date,
    payments_remaining,
    past_due_date,
    past_due_amount,
    is_past_due,
    prev_payment_amount,
    uuid
  } = loan;
  return (
    <EditModal
      key={uuid}
      onSave={handleSave}
      defaultValues={{
        next_payment_date,
        payments_remaining,
        past_due_date,
        past_due_amount,
        is_past_due,
        prev_payment_amount
      }}
      header="Edit Payment"
      {...props}
    >
      {({ onChange, values }) => (
        <Grid padded>
          <Grid.Row columns="2">
            <Grid.Column>
              <DateInput
                asForm
                label="Next Payment Date"
                name="next_payment_date"
                value={forceValue(values.next_payment_date)}
                onChange={(e, { name, value }) => onChange({ name, value })}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="2">
            <Grid.Column>
              <Form.Select
                onChange={(e, { name, value }) => onChange({ name, value })}
                options={accountDueOptions}
                name="is_past_due"
                label="Account Past Due"
                value={values.is_past_due}
              />
            </Grid.Column>
            <Grid.Column>
              <DollarField
                name="prev_payment_amount"
                value={forceValue(values.prev_payment_amount)}
                label="Previous Payment Amount"
                onChange={onChange}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="2">
            <Grid.Column>
              <DateInput
                asForm
                label="Past Due Date"
                name="past_due_date"
                value={forceValue(values.past_due_date)}
                onChange={(e, { name, value }) => onChange({ name, value })}
              />
            </Grid.Column>
            <Grid.Column>
              <DollarField
                name="past_due_amount"
                value={forceValue(values.past_due_amount)}
                label="Past Due Amount"
                onChange={onChange}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </EditModal>
  );
}

PaymentEditModal.propTypes = {
  loan: PropTypes.shape(),
  onSave: PropTypes.func
};

export default PaymentEditModal;
