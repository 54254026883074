import { Link, useRouteMatch } from "react-router-dom";
import {
  ENTITY_MANAGEMENT
  // ENTITY_MANAGEMENT_ADVANCED_SEARCH
} from "../../constants/routes";

const Tabs = () => {
  const match = useRouteMatch();

  return (
    <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-neutral-300">
      <li className="me-2">
        <Link
          to={ENTITY_MANAGEMENT}
          aria-current="page"
          className={`${
            match.path === ENTITY_MANAGEMENT
              ? "bg-white !border-neutral-300 !text-neutral-500"
              : "border-transparent"
          } bottom-[-2px] relative border !border-b-0 inline-block px-4 py-2 text-cyan-600 rounded-t-lg`}
        >
          Search
        </Link>
      </li>
      {/* <li className="me-2">
        <Link
          to={ENTITY_MANAGEMENT_ADVANCED_SEARCH}
          aria-current="page"
          className={`${
            match.path === ENTITY_MANAGEMENT_ADVANCED_SEARCH
              ? "bg-white !border-neutral-300 !text-neutral-500"
              : "border-transparent"
          } bottom-[-2px] relative border !border-b-0 inline-block px-4 py-2 text-cyan-600 rounded-t-lg`}
        >
          CORE Search
        </Link>
      </li> */}
    </ul>
  );
};

export default Tabs;
