import React from "react";
import PropTypes from "prop-types";

import { Dropdown } from "semantic-ui-react";

import DocumentCategories from "../../services/Constants";

const docCategories = [
  {
    key: "",
    text: "",
    value: ""
  },
  {
    key: "Credit File",
    text: "Credit File",
    value: "Credit File"
  },
  {
    key: "Collateral File",
    text: "Collateral File",
    value: "Collateral File"
  }
];

const collateralOptions = [];
const creditOptions = [];
const totalDocOptions = DocumentCategories.length;

for (let i = 0; i < totalDocOptions; i += 1) {
  if (DocumentCategories[i].doc_category === "Collateral File") {
    const newOption = {
      key: DocumentCategories[i].doc_subcategory,
      text: DocumentCategories[i].doc_subcategory,
      value: DocumentCategories[i].doc_subcategory
    };
    collateralOptions.push(newOption);
  } else if (DocumentCategories[i].doc_category === "Credit File") {
    const newOption = {
      key: DocumentCategories[i].doc_subcategory,
      text: DocumentCategories[i].doc_subcategory,
      value: DocumentCategories[i].doc_subcategory
    };
    creditOptions.push(newOption);
  }
}

class DocTypeSelectsObj extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      docCat: this.props.docCat
    };

    this.handleChangeCat = this.handleChangeCat.bind(this);
    this.handleChangeSubCat = this.handleChangeSubCat.bind(this);
  }

  handleChangeCat(event, data) {
    this.setState({ docCat: data.value });
    this.props.callbackFilterParamChange("category", data);
  }

  handleChangeSubCat(event, data) {
    this.props.callbackFilterParamChange("subcategory", data);
  }

  render() {
    if (this.state.docCat === "Credit File") {
      this.menuItems = creditOptions;
    } else if (this.state.docCat === "Collateral File") {
      this.menuItems = collateralOptions;
    } else {
      this.menuItems = [];
    }
    return (
      <span>
        <Dropdown
          placeholder="Category"
          value={this.props.docCat}
          selection
          options={docCategories}
          onChange={(event, data) => this.handleChangeCat(event, data)}
        />
        <Dropdown
          placeholder="Subcategory"
          value={this.props.docSubCat}
          search
          selection
          options={this.menuItems}
          onChange={(event, data) => this.handleChangeSubCat(event, data)}
        />
      </span>
    );
  }
}

DocTypeSelectsObj.propTypes = {
  docCat: PropTypes.string.isRequired,
  docSubCat: PropTypes.string.isRequired,
  callbackFilterParamChange: PropTypes.func.isRequired
};

export default DocTypeSelectsObj;
