import { connect } from "react-redux";
import { actionCreators } from "../../reducer";
import ScheduleList from "./ScheduleList";

const mapStateToProps = ({
  PaymentScheduleReducer: { paymentSchedules, pagination, error, tableOptions }
}) => ({
  paymentSchedules,
  pagination,
  error,
  tableOptions
});

const mapDispatchToProps = (dispatch, { loan }) => ({
  onView: () => dispatch(actionCreators.loadPaymentSchedules(loan.uuid)),
  onCleanup: () => dispatch(actionCreators.onCleanup()),
  onAdd: scheduleData =>
    dispatch(actionCreators.onAddSchedule(loan, scheduleData)),
  onEdit: schedule => dispatch(actionCreators.onEditSchedule(loan, schedule)),
  onDelete: schedule =>
    dispatch(actionCreators.onDelete(loan.uuid, schedule.uuid)),
  onPaginationChange: (pagination, filter) => {
    dispatch(
      actionCreators.loadPaymentSchedules(loan.uuid, pagination, filter)
    );
  },
  onDismissError: () => dispatch(actionCreators.clearError()),
  onToggleChange: (filter, pagination) =>
    dispatch(actionCreators.loadPaymentSchedules(loan.uuid, pagination, filter))
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleList);
