import React from "react";
import PropTypes from "prop-types";
import { Header, Icon } from "semantic-ui-react";

const container = {
  position: "absolute",
  left: "50%",
  width: "90%",
  transform: "translate(-50%, -50%)"
};

const steps = {
  position: "relative",
  display: "flex",
  justifyContent: "space-between",
  width: "100%"
};

const header = {
  border: "none",
  display: "flex",
  fontWeight: "normal",
  fontSize: "60%",
  justifyContent: "center",
  margin: "0",
  padding: "0",
  whiteSpace: "nowrap"
};

const progress = {
  position: "absolute",
  left: "1%",
  width: "98%",
  height: "55%",
  borderBottom: "4px solid #DEDEDE",
  Zindex: "-1"
};

let percent = {
  position: "absolute",
  width: "0%",
  height: "12px",
  borderBottom: "4px solid #01A49C",
  Zindex: "1"
};

function ProgressBar(props) {
  const { phase, progressSteps, progressName } = props;
  const items = [];
  let iconColor = "#01A49C";
  let inverted = true;
  if (phase) {
    iconColor = "lightgrey";
    inverted = false;
  }
  progressSteps.forEach((itemName, index) => {
    items.push(
      <Icon
        key={`${itemName} icon`}
        inverted={inverted}
        name="circle"
        size="large"
        style={{ margin: "0", color: iconColor }}
      >
        <Header
          className={progressName === itemName ? "active" : ""}
          key={`${itemName} label`}
          as="h5"
          size="tiny"
          style={{
            ...header,
            fontWeight: progressName === itemName ? "bold" : "normal"
          }}
        >
          {itemName}
        </Header>
      </Icon>
    );
    if (progressName === itemName) {
      iconColor = "lightgrey";
      inverted = false;
      const progressPercent = (100 / (progressSteps.length - 1)) * index;
      percent = { ...percent, width: `${progressPercent}%` };
    }
  });

  return (
    <div style={{ padding: "33px" }}>
      <div style={container}>
        <div style={progress}>
          <div style={percent} />
        </div>
        <div style={steps}>{items}</div>
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  progressSteps: PropTypes.arrayOf(PropTypes.string).isRequired,
  progressName: PropTypes.string.isRequired,
  phase: PropTypes.bool.isRequired
};

export default ProgressBar;
