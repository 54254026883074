import { Fragment, useEffect } from "react";
import {
  useRowSelect,
  useSortBy,
  useTable,
  useBlockLayout,
  useExpanded
} from "react-table";
import { useSticky } from "react-table-sticky";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortDown,
  faSortUp,
  faSort
} from "@fortawesome/free-solid-svg-icons";
import { ReactTableProps, TableInstanceWithHooks } from "./types";
import { DashedPanel } from "../panels/DashedPanel";
import { IndeterminateCheckboxHeader } from "./Headers/IndeterminateCheckboxHeader";
import { IndeterminateCheckboxCell } from "./Cells/IndeterminateCheckboxCell";
import { ExpanderCell } from "./Cells/ExpanderCell";
import { ExpanderHeader } from "./Headers/ExpanderHeader";
import "./style.css";

export {
  IndeterminateCheckboxHeader,
  IndeterminateCheckboxCell,
  ExpanderHeader,
  ExpanderCell
};

// re: any https://github.com/TanStack/table/issues/1481#issuecomment-1870296754

export const ReactTable = <T extends object>({
  height = "auto",
  columns,
  data,
  isFetching = true,
  emptyDataTitle = "No data",
  emptyDataSubtitle = "",
  hiddenColumns = [],
  actionColumnIds = ["actionsCell"],
  handleHooks,
  sortByAccessorId = "name",
  sortByAccessorDesc = false,
  onSelectionChange,
  renderRowSubComponent
}: ReactTableProps) => {
  const dataIsEmpty = !data || (Array.isArray(data) && data.length === 0);
  const initialState = {
    sortBy: [
      {
        id: sortByAccessorId,
        desc: sortByAccessorDesc
      }
    ],
    hiddenColumns
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
    totalColumnsWidth,
    state: { selectedRowIds, expanded }
  } = useTable(
    {
      columns,
      initialState,
      data,
      defaultColumn: {
        // minWidth: 40, // v8 minSize
        width: 150 // v8 size
        // maxWidth: 250 // v8 maxSize
      }
    },
    useSortBy,
    useExpanded,
    useRowSelect,
    useBlockLayout,
    // @ts-ignore
    useSticky,
    hooks => (handleHooks ? handleHooks(hooks) : {})
  ) as TableInstanceWithHooks<T | any>;

  useEffect(() => {
    let selectedRows: any = [];

    if (Array.isArray(selectedFlatRows)) {
      selectedRows = selectedFlatRows.map(row => row.original);
    }

    onSelectionChange?.(selectedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSelectionChange, selectedRowIds]);

  if (isFetching && dataIsEmpty) {
    return (
      <div className="ReactTable-Loading">
        <Skeleton className="mb-4 border border-neutral-300 h-[64px]" />
        <Skeleton
          count={3}
          className="mb-3 border border-neutral-300 h-[45px]"
        />
        <Skeleton className="border border-neutral-300 h-[45px]" />
      </div>
    );
  }

  if (!isFetching && dataIsEmpty) {
    return (
      <DashedPanel>
        <div className="shadow-none text-center mx-auto max-w-[500px]">
          {emptyDataTitle && (
            <div className="text-xl font-semibold mb-5">{emptyDataTitle}</div>
          )}
          {emptyDataSubtitle && <p>{emptyDataSubtitle}</p>}
        </div>
      </DashedPanel>
    );
  }

  return (
    <div className="ReactTable-container">
      <div
        {...getTableProps()}
        className="ReactTable sticky"
        style={{
          // width: totalColumnsWidth,
          height
        }}
      >
        <div className="header !bg-neutral-50">
          <div style={{ minWidth: totalColumnsWidth }}>
            {headerGroups.map(headerGroup => (
              <div
                {...headerGroup.getHeaderGroupProps()}
                className="tr flex"
                style={{ minWidth: totalColumnsWidth }}
              >
                {headerGroup.headers.map((column: any) => {
                  return (
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="th px-3 py-6 text-sm font-semibold text-gray-900 text-left !text-sm"
                      data-testid={`ReactTable-TH-${column?.id}`}
                    >
                      <span className="p-0 text-nowrap align-middle">
                        {column.render("Header")}
                        {column.canSort && (
                          <span className="ml-2">
                            {column.isSorted ? (
                              <FontAwesomeIcon
                                icon={
                                  column.isSortedDesc ? faSortDown : faSortUp
                                }
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faSort}
                                style={{ opacity: ".25" }}
                              />
                            )}
                          </span>
                        )}
                      </span>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
        <div {...getTableBodyProps()} className="body">
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            const hookProps = {
              toggleAllRowsSelected
            };

            return (
              <Fragment key={`ReactTable-tr-container-${row?.id}`}>
                <div
                  {...row.getRowProps()}
                  className={`tr bg-white border-neutral-300 tr-${expanded.toString()}`}
                  data-testid={`table-row-${rowIndex}`}
                >
                  {row.cells.map(cell => {
                    return (
                      <div
                        {...cell.getCellProps({
                          style: {
                            ...(actionColumnIds.includes(cell?.column?.id)
                              ? { zIndex: rows.length - rowIndex }
                              : {})
                          }
                        })}
                        {...hookProps}
                        className="td px-3 py-6 table-tbody-td text-nowrap text-left !text-sm"
                        data-testid={`table-row-${rowIndex}-${cell?.column?.id}`}
                      >
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>

                {/* @ts-ignore */}
                {renderRowSubComponent && row.isExpanded && (
                  <div className="w-100">
                    <div className="w-100">
                      {renderRowSubComponent({ row })}
                    </div>
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const reactTableSortCaseInsensitive = (
  prev: any,
  curr: any,
  columnId: string
) => {
  if (typeof prev.original[columnId] === "string") {
    if (
      prev.original[columnId]?.toLowerCase() >
      curr.original[columnId]?.toLowerCase()
    ) {
      return 1;
    }
    if (
      prev.original[columnId]?.toLowerCase() <
      curr.original[columnId]?.toLowerCase()
    ) {
      return -1;
    }
    return 0;
  }

  if (prev.original[columnId] > curr.original[columnId]) {
    return 1;
  }
  if (prev.original[columnId] < curr.original[columnId]) {
    return -1;
  }
  return 0;
};
