import { IconButton } from "../../../../fomantic/elements/button/icon-button/icon-button";
import { MessageProps } from "../../../../types/text";
import React from "react";
import { FormattedMessage } from "react-intl";
import { DottedPanel } from "../dotted-panel/dotted-panel";

/**
 * Returns a dotted panel that can be used as a placeholder for an empty table,
 * also renders an optional button to handle that first interaction and has
 * a title wrapped with a bold text decorator and a subtitle with smaller font than the title
 */
export function AddPanel({
  title,
  description,
  buttonLabel,
  buttonOnClickHandler
}: React.PropsWithChildren<AddPanelProps>) {
  return (
    <DottedPanel>
      <div className="ui center aligned container add-card">
        {title && (
          <div className="ui header">
            <FormattedMessage {...title} />
          </div>
        )}
        {description && (
          <p>
            <FormattedMessage {...description} values={{ br: <br /> }} />
          </p>
        )}
        {buttonLabel && (
          <IconButton
            message={buttonLabel}
            onClick={buttonOnClickHandler}
            icon="plus"
          />
        )}
      </div>
    </DottedPanel>
  );
}

export interface AddPanelProps {
  /** Bold title, this will be the first text shown and will use a bigger font */
  title?: MessageProps;
  /** Description of the panel, this will be the second text shown and will be a smaller font */
  description?: MessageProps;
  /** Button text, this will be the third text and will be wrapped inside a button */
  buttonLabel?: MessageProps;
  /** Button click handler, action that will be triggered when the button gets clicked */
  buttonOnClickHandler?: (e: any) => void;
}
