import {
  getTimeToExpiration,
  logoutWarningText,
  msToFractionalMin,
  refreshTombstoneToken,
  sessionCheckInterval,
  warningMinToExp
} from "@bafsllc/auth-shared";
import { ComponentDialog } from "@bafsllc/ui-shared";

import React, { useState } from "react";

/* **BLAST Exclusive:**
  // This section is for commotion-BLAST and non-commotion-BLAST only, not Borrower Portal.
  // We should reevaluate having shared Auth/ApplicationHost/GlobalHeader dependencies
  // between BP and BLAST. For now, I'm going to bypass user facing code execution
  // when it runs in BP, but hooks are still getting unnecessarily setup in BP.
  */
export function SessionWatchWarning() {
  function handleRefreshTombstoneToken() {
    setPendingTokenRefresh(true);
    refreshTombstoneToken().then(status => {
      if (status !== 200) {
        setTokenRefreshError(true);
      }
    });
  }
  const [logoutRequestedByUser, requestLogout] = useState(false);
  const [tokenRefreshError, setTokenRefreshError] = useState(false);
  const [tokenRefreshPending, setPendingTokenRefresh] = useState(false);
  const [minToExpiration, setMinToExpiration] = useState(getTimeToExpiration());

  const looper = () => {
    setMinToExpiration(getTimeToExpiration());
    setPendingTokenRefresh(false);
  };
  setInterval(looper, sessionCheckInterval);

  if (
    minToExpiration <= msToFractionalMin(sessionCheckInterval) ||
    logoutRequestedByUser
  ) {
    window.location.href = `${window.location.origin}/logout`;
  }

  if (minToExpiration <= warningMinToExp && minToExpiration > 0) {
    return (
      <ComponentDialog
        buttons={[
          {
            message: { defaultMessage: "Logout Now" }, // TODO: [#6567] This key doesn't exist in a translation file.
            uid: "cancel",
            onClick: () => requestLogout(true)
          },
          {
            message: { defaultMessage: "Stay Logged In" }, // TODO: [#6567] This key doesn't exist in a translation file.
            disabled: tokenRefreshPending,
            isPrimary: true,
            uid: "submit",
            onClick: () => handleRefreshTombstoneToken()
          }
        ]}
        header={{ defaultMessage: "Are you still working?" }} // TODO: [#6567] This key doesn't exist in a translation file.
        hide={false}
        onDismiss={() => {}}
        onHidden={() => {}}
      >
        <p>{logoutWarningText(minToExpiration)}</p>
        {/* TODO: [#6567] This key doesn't exist in a translation file. */}
        {tokenRefreshError ? (
          <span style={{ color: "red" }}>
            ERROR: There was a problem renewing your login session. Please
            logout and login again.
          </span>
        ) : (
          ""
        )}
      </ComponentDialog>
    );
  }
  return null;
}
