import { useState, useMemo } from "react";

function useIframeSetup(context) {
  const blastContext = useMemo(() => context || "", [context]);
  const [callbackData, setCallbackData] = useState({ context });

  return {
    action: callbackData,
    dispatch: ({ type, ...data }) => {
      setCallbackData({ ...data, context: blastContext, type });
    }
  };
}

export default useIframeSetup;
