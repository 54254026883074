/*
  WARNING: It seems that developers have decided to ignore this file in
  favor of implementing their own version of `doc_category`s. Use this
  constant at your own risk of conflicting with other implementations.
*/

const DocumentCategories = [
  // Asset Documents
  {
    doc_category: "Collateral File",
    doc_subcategory: "Abstract of Title"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Acknowledgment of Existing Multiple Indebtedness Mortgage"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Acknowledgment of Pledge and Security Interest"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Acknowledgment of Pledge of Collateral Mortgage Note"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Act of Correction"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Act of Immobilization"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Affidavit of Completion"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Aircraft Appraisal"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Aircraft Bill of Sale"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Aircraft Registration"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Aircraft Security Agreement"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Aircraft Title Insurance"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Aircraft Title Search"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "ALTA Survey"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Application for Certificate of Documentation"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Appraisal"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Appraisal Acknowledgment"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Appraisal Bid Selection"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Appraisal Engagement Letter"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Appraisal Notice",
    auto_list_guarantor: true
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Appraisal Request Form"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Appraisal Review"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Appraisal Waiver"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Architectural Contract"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Asset Checklist"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Asset List"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Assignment and Assumption Agreement"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Assignment of Architects Contract"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Assignment of Construction Contracts"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Assignment of Contract Rights"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Assignment of Deposit Account"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Assignment of Engineering Contract"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Assignment of Lease"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Assignment of Lease With Right of Reassignment"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Assignment of Leases & Rents"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Assignment of Mortgage"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Assignment of Plans and Specifications"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Assignment of Rents"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Assumption Agreement"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Bill of Sale"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Builders Risk Insurance"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Business Valuation"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Buyers Order"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Certificate of Approval of Final Plans and Specs"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Certificate of Occupancy"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Closing Protection Letter"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Collateral Checklist"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Collateral Revaluation"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Commercial Pledge Agreement"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Commercial Security Agreement"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Confirmation of Lien"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Construction Costs"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Construction Deed of Trust"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Construction Mortgage"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Dealers Invoice"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Deed"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Deed of Trust"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "EDR Collateral Screen"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "EDR Environmental Pre-Screen Review Checklist"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "EDR Environmental Screen"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "EDR Loan Check Plus"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Environmental Certificate"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Environmental On-Site Checklist"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Environmental Questionnaire"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Environmental Review"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Equipment List"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Evaluation"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Final Title Policy"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Flood Determination"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Flood Insurance"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Flood Notice"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Hazardous Substances Certificate and Indemnity Agreement"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Hold on Account"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Inspection Report"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Invoice"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Landlord Waiver"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Lease Agreement"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Legal Description"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Loan Proceeds Check Image"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Marine Security Agreement"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Marine Survey"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Modification & Extension Agreement"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Modification Agreement"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Modification of Deed of Trust"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Modification of Mortgage"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Mortgage"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Multiple Indebtedness Mortgage"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "NADA Valuation"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "No Work Affidavit"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Non-Homestead Affidavit"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Notice of Aircraft Security Agreement"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Obligation Letter from Maritime Attorney"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "On-Site Inspection Report"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Other"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Partial Release"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Phase I Environmental Report"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Phase II Environmental Report"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Phase III Environmental Report"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Plat"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Pledge of Leases And Rents"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Preferred Mortgage"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Property Insurance"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Purchase Agreement"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Purchase Order"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Real Estate Tax Receipt"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Reinscription of Assignment of Leases & Rents"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Reinscription of Multiple Indebtedness Mortgage"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Reinscription of Pledge of Leases and Rents"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "SBA Asset Checklist"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "SBA Form 1059 Security Agreement"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "SBA Loan Agreement"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "SBA Modification Agreement"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Stock Certificate"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Stock Pledge Agreement"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Subordination Agreement"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Subordination, Non-Disturbance and Attornment Agreement"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Survey"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Tax Certificate"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Tenant Estoppel Certificate"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Termite Certificate"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Title Commitment"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Title Engagement Letter"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Title Opinion"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Title Search"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "UCC Search"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "UCC-1 Financing Statement"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "UCC-3 "
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "UCC-3 Continuation"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Valuation"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Vehicle Registration"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Vehicle Title "
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Vehicle Title Application"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Waiver"
  },
  {
    doc_category: "Collateral File",
    doc_subcategory: "Windstorm Insurance"
  },

  // Entity Documents
  {
    doc_category: "Credit File",
    doc_subcategory: "Account Summary"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Accounts Payable Aging"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Accounts Receivable Aging"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "ACH Authorization"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Act of Donation"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Adverse Action"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Affidavit of One and the Same Person"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Agreement to Publication"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Annual Financial Statement",
    auto_list_borrower: true,
    auto_list_guarantor: true,
    required_guarantor: true,
    auto_list_affiliate: true,
    required_affiliate: true
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Annual Review"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Application for Loan Guarantee"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Approval Notice Form"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Articles of Association"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Articles of Incorporation"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Articles of Partnership"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Assumed Name Certificate"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Bank Statement"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Board of Directors Minutes"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Borrower Correspondence",
    auto_list_borrower: true
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Borrowing Base Certificate"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Borrowing Resolution"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Bulk File"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Business Credit Report"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Business Debt Schedule",
    auto_list_borrower: true,
    required_borrower: true
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Business Entity Search"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Business License"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Business Plan",
    auto_list_borrower: true
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Business Valuation"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "CAIVRS Report"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Cash Flow Analysis"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Certificate of Existence"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Certificate of Filing"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Certificate of Formation"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Certificate of Good Standing"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Certificate of Limited Partnership"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Certificate of Merger"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Certificate of Trust"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Certification of Beneficial Owners"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Church Loan Questionnaire"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Commitment Letter"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Conditional Commitment"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Construction Budget"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Corporate By-Laws"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Corporate Resolution"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Corporate Resolution to Borrow/Grant Collateral"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Corporate Resolution to Grant Collateral/Guarantee"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Correspondence"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Credit File Checklist"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Credit Memorandum"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Customer Identification Profile"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Death Certificate"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Decisioned Credit Memo"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Drivers License"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Entity Checklist"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Feasibility Study"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Financial Analysis"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Franchise Agreement"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Grants Payable"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Home Mortgage Disclosure Act (HMDA)"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Income Statement"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Initial Report"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Interim Financial Statement",
    auto_list_borrower: true
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Inventory Report"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Inventory Status Report"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "IRS EIN"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "LLC Articles of Organization"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "LLC Operating Agreement"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "LLC Resolution to Borrow/Grant Collateral"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "LLC Resolution to Grant Collateral/Guarantee"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "LLP Articles of Organization"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Loan Application",
    auto_list_borrower: true,
    required_borrower: true
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Loan Documentation Review"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Loan Modification Request"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Loan Renewal Request"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Loan Review Memorandum"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Loan Review Response"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Memo to Entity"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Notice of Terms of Forgiveness of Debt"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Notice to Debtor and Guarantors"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Notice to Guarantor"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Occupancy Summary"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "OFAC"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Officer Memo to File",
    auto_list_borrower: true
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Other",
    auto_list_borrower: true
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Participation Certificate"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Participation Reservation"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Participation Summary"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Partnership Agreement"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Payoff"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Payroll Documentation"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Photo ID"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Power of Attorney"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Principal Credit Report",
    auto_list_guarantor: true,
    required_guarantor: true
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Pro-Forma Financial Statement",
    auto_list_borrower: true
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Proposal Letter"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Rating Review Memorandum"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Relationship Review Memorandum"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Rent Roll"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Request for Financials"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Request for Transcript - 4506-T"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Request for Transcript - 4506-C"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Resolution"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Resolution of Corporate Partner"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Resolution of LLC Member"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Resume"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SAM Report"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Acknowledgment of Receipt"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Adverse Action"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Alien Status Verification"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Application Package"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Borrower Certification to Immigration Law"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Certificate of Franchise Documents"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Certification of Child Support"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Correspondence"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Credit Memorandum"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Eligible Passive Company Addendum"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Eligibility Information for PLP Loans"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Eligibility Questionnaire"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Engagement Letter"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Etran Approval"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Form 1261, Statements Required by Law"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Form 160 Resolution of Board of Directors "
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Form 160A, Certificate as to Partners"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Form 1919 Borrowers Information"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Form 1920 Lenders Application"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Form 2237 7(a) Loan Post Approval Checklist"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Form 2483 Borrowers Application"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Form 2483-SD Borrowers Application"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Form 2484 Lenders Application"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Form 2484-SD Lenders Application"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Form 4-1 Lenders Application for Guaranty"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Form 413 Personal Financial Statement"
  },
  {
    doc_category: "Credit File",
    doc_subcategory:
      "SBA Form 652, Assurance of Compliance For Non-Discrimination"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Form 793, Notice to New SBA Borrowers"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Form 912 Statement of Personal History"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Form G-845. Alien Status Release Authorization"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Guaranty Purchase Request"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Notice of Reporting to Credit Bureaus"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Packaging Services for SBA 7(a)"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Post Submission Docs"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Resolution of Members"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Suspensions & Debarments Search"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "SBA Workout Plan"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "STAR Report"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Tax Return",
    auto_list_borrower: true,
    required_borrower: true,
    auto_list_guarantor: true,
    required_guarantor: true,
    auto_list_affiliate: true,
    required_affiliate: true
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Tax Return Transcript"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Trust Agreement"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Trust Certificate"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "USDA Application Checklist"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "USDA Application Package"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "USDA Authorization"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "USDA Certificate of Non-Relocation"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "USDA Lenders Certification"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "Waiver"
  },
  {
    doc_category: "Credit File",
    doc_subcategory: "W-2 Form"
  },

  // Loan Documents
  {
    doc_category: "Loan File",
    doc_subcategory: "ACH Authorization"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Acknowledgment of Assignment Of Life Insurance Policy"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Additional & Correction Documents Agreement"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Affidavit"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Affidavit of No Adverse Change"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Agreement to Provide Insurance"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Agreement to Waive Garnishment Protection"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Amortization Schedule"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Application Notes"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Assignment of Life Insurance"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Attorney Representation Notice"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Authorization of Partnership LLC Member"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Boarding Data Sheet"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Borrower's Certification"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Borrowing Resolution"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Building Permit"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Bulk File"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Business Loan Agreement"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Business Purpose Statement"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Cash Injection"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Certificate of Trust LLC Member"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Change in Terms Agreement"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Closing Agreement"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Closing Instructions"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Collateral Receipt"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Construction Agreement"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Construction Contract"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Construction Documentation"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Construction Draw Request"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Construction Loan Agreement"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Construction Plans"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Construction Specifications"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Corporate Resolution"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Corporate Resolution to Borrow/Grant Collateral"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Corporate Resolution to Grant Collateral/Guarantee"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Correspondence"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Credit Life Insurance"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Disbursement Request And Authorization"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Disbursements"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Disclosure"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Draw Inspection Report"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Engineering Contract"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Errors And Omissions Agreement"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Escrow Waiver"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "E-Sign/DocuSign"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Executed Loan Package"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Final Construction Plans"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Final Construction Specifications"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Guaranty Agreement"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Hazard Insurance Disclosure"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Initial Escrow Statement"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Letter of Credit"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Liability Insurance"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Lien Waiver"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Life Insurance Policy"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Life Insurance Statement"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Line of Credit Agreement"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "LLC Resolution to Borrow/Grant Collateral"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "LLC Resolution to Grant Collateral/Guarantee"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Loan Boarding Verification"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Loan Checklist"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Loan Documentation Review Report"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Loan History"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Loan Modification Request"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Loan Processing Documents"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Loan Request Summary"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Modification & Extension Agreement"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "NACCU Post Closing Review"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Negative Information Notice"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Notice of Final Agreement"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Notice of Insurance Requirements"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Other"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Participation Agreement"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Participation Boarding Data"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Partnership Authorization"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Performance and Payment Bond"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Policy Exception"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Post-Closing Checklist"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Power of Attorney"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Privacy Notice"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Promissory Note"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Proposed Construction Specifications"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Request For Advance"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Resolution"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Resolution of Corporate Partner"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Resolution of LLC Partner"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Resolution of LLC Member"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA (7a) Loan Submission Checklist"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Borrowers Certification"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Closing Instructions"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Correspondence"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Distribution of Loan Proceeds"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Form 1050 Settlement Statement"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Form 147 Note"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Form 148 Guaranty Agreement"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Form 155 Standby Agreement and Promissory Notes"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Form 159 Fee Disclosure and Compensation"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Form 159-7a Fee Disclosure & Compensation"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Form 1624 Debarment Certificate"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Form 1846 Statement Regarding Lobbying"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Form 4 Schedule of Collateral"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Form 601 Agreement of Compliance"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Form 722 Equal Opportunity Poster"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Guarantee Fee Payment"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Letter of Concurrence"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Loan Agreement"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Loan Authorization"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Loan File Checklist"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Post Approval Action"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Proof of Cash Injection"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA Use of Proceeds"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "SBA User of Proceeds"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Settlement Statement"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Statement of Anti-Coercion"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Stock Valuation"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Unexecuted Loan Package"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "USDA Request for Obligation of Funds"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Verification of Life Insurance Policy"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Waiver"
  },
  {
    doc_category: "Loan File",
    doc_subcategory: "Wiring Instructions"
  }
];

export default DocumentCategories;
