import Constants from "./strings";

const paymentTypes = [
  {
    key: "1",
    text: Constants.PAYMENT_TYPE_REGULAR_PAYMENT,
    value: "110",
    Institution: true
  },
  {
    key: "2",
    text: Constants.PAYMENT_TYPE_PRINCIPAL_ONLY_PAYMENT,
    value: "120",
    Institution: true
  },
  {
    key: "3",
    text: Constants.PAYMENT_TYPE_INTEREST_PAYMENT,
    value: "130",
    Institution: true
  },
  {
    key: "4",
    text: Constants.PAYMENT_TYPE_LATE_FEE_PAYMENT,
    value: "140",
    Institution: true
  },
  {
    key: "5",
    text: Constants.PAYMENT_TYPE_ADVANCE,
    value: "220",
    Institution: true
  },
  {
    key: "6",
    text: Constants.PAYMENT_TYPE_MANUAL_PRINCIPAL_INCREASE,
    value: "420",
    Institution: true
  },
  {
    key: "7",
    text: Constants.PAYMENT_TYPE_MANUAL_PRINCIPAL_DECREASE,
    value: "422",
    Institution: true
  },
  {
    key: "8",
    text: Constants.PAYMENT_TYPE_MANUAL_ACCRUED_INTEREST_INCREASE,
    value: "430",
    Institution: false
  },
  {
    key: "9",
    text: Constants.PAYMENT_TYPE_MANUAL_ACCRUED_INTEREST_DECREASE,
    value: "432",
    Institution: false
  },
  {
    key: "10",
    text: Constants.PAYMENT_TYPE_INTEREST_RATE_CHANGE,
    value: "935",
    Institution: false
  },
  {
    key: "11",
    text: Constants.PAYMENT_TYPE_PAYOFF,
    value: "150",
    Institution: false
  },
  {
    key: "12",
    text: Constants.PAYMENT_TYPE_PREPAYMENT_PENALTY_PAYMENT,
    value: "146",
    Institution: false
  },
  {
    key: "13",
    text: Constants.PAYMENT_TYPE_MISC_INCOME_FEE_PAYMENT,
    value: "147",
    Institution: false
  },
  {
    key: "14",
    text: Constants.PAYMENT_TYPE_PREPAYMENT_PENALTY_ASSESSMENT,
    value: "346",
    Institution: false
  },
  {
    key: "15",
    text: Constants.PAYMENT_TYPE_MISC_INCOME_FEE_ASSESSMENT,
    value: "347",
    Institution: false
  },
  {
    key: "16",
    text: Constants.PAYDOWN_LOAN_TO_ZERO,
    value: "155",
    Institution: false
  }
];
export default paymentTypes;
