export default (
  calcs,
  updatePage,
  lastPage,
  loading,
  loadCallback,
  pageNumber,
  percentagePassed
) => {
  if (
    !lastPage &&
    !loading &&
    calcs.direction === "down" &&
    (calcs.percentagePassed >= percentagePassed || calcs.bottomVisible === true)
  ) {
    loadCallback(pageNumber + 1);
    updatePage(pageNumber + 1);
  }
};
