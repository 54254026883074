// eslint-disable-next-line import/prefer-default-export
export const userFeedbackEnum = {
  emailEligibleInfo: {
    type: "success",
    id: "BP_ORG_NEW_LINK_EMAIL_ELIGIBLE_INFO",
    defaultMessage:
      "When added the Primary Administrator User will be invited to a new Borrower Portal instance with the current Entity linked and visible to the Borrower."
  },
  emailEligibleSuccess: {
    type: "success",
    id: "BP_ORG_NEW_LINK_EMAIL_ELIGIBLE_CLICK_LINK",
    defaultMessage:
      "Click Link to create the Borrower Portal Organization with a link to the entity."
  },
  emailEligibleErrorPartOfBpOrg: {
    type: "error",
    id: "BP_ORG_NEW_LINK_EMAIL_ELIGIBLE_ERROR",
    defaultMessage:
      "User is already part of a Borrower Portal Organization. Please enter a different email address."
  },
  emailEligibleErrorNotFound: {
    type: "error",
    id: "EMAIL_NOT_FOUND",
    defaultMessage: "Email not found."
  },
  emailInvalid: {
    type: "error",
    id: "ENTER_VALID_EMAIL",
    defaultMessage: "Please enter a valid email address."
  },
  linkEmailReady: {
    type: "success",
    id: "BP_ORG_LINK_READY_TO_LINK",
    defaultMessage:
      "Click Link to link the entity to the Borrower Portal Organization"
  }
};

// TESTING

export const mockBpUsersLookupResponse = {
  data: [
    {
      bp_organization_uuid: "47fe2456-ceec",
      email: "phil.something@company.com",
      institution_uuid: "institut-ions-0016-IBLS-TestBranch00",
      uuid: "cc30d447-e34e"
    },
    {
      bp_organization_uuid: "955a1e0e-1537",
      email: "bill.something@organization.org",
      institution_uuid: "institut-ions-0016-IBLS-TestBranch00",
      uuid: "9574d3f4-261a"
    }
  ],
  error: null,
  metaData: null
};

export const mockUsersValidateEmailResponse = {
  emailEligibleSuccess: {
    data: {
      email: "joe@iscool.com",
      institution_uuid: "60ac2391-45be-465a-ae50-ad48f193e945",
      uuid: "834c7688-e69f-4bca-b94e-1659ad2e1bc6"
    },
    error: null,
    metaData: null
  },
  emailEligibleErrorPartOfBpOrg: {
    data: {
      bp_organization_uuid: "4fefacf8-bb0c-4c27-86c6-618a334e1989",
      email: "justing@iscool.com",
      institution_uuid: "60ac2391-45be-465a-ae50-ad48f193e945",
      uuid: "834c7688-e69f-4bca-b94e-1659ad2e1bc6"
    },
    error: null,
    metaData: null
  },
  emailEligibleErrorNotFound: {
    data: null,
    error: "Email not found",
    statusCode: 404,
    metaData: null
  },
  emailInvalid: {
    data: null,
    error: "Bad request. Incorrect email format",
    statusCode: 400,
    metaData: null
  }
};
