import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useLocation
} from "react-router-dom";
import { Loader } from "semantic-ui-react";
import HeaderBlock from "../../components/HeaderBlock";
import LoanManagmentTabs from "./LoanManagementTabs";
import Details from "./scenes/Details";
import Relationships from "./scenes/Relationships";
import AccountDocuments from "./scenes/AccountDocuments";
import Collateral from "./scenes/Collateral";
import CreditRiskReview from "./scenes/CreditRiskReview";
import Payments from "./scenes/Payments";
import Participation from "./scenes/Participation";
import DocumentTracking from "./scenes/DocumentTracking";
import PayoffCalculator from "./scenes/PayoffCalculator";
import TransactionHistoryInterface from "./scenes/TransactionHistory";
import PremiumDiscount from "./scenes/PremiumDiscount";
import NightlyProcess from "./scenes/NightlyProcess";
import Snapshots from "./scenes/Snapshots";
import entityDataProps from "../../../../services/PropTypes/entityData";
import { userCanViewPage } from "../../../../services/Auth";
import { ConcatName } from "../../../../services/Entities";
import CreditAnalysis from "../../../../components/CreditAnalysis/scenes/CreditAnalysis";

function LoanManagement({
  entityData,
  institution,
  loan,
  onViewLoanManagement,
  onUpdateLoan
}) {
  const match = useRouteMatch();
  const { uuid } = useParams();

  const { state: locationState = {} } = useLocation();

  const viewLoanManagement = useCallback(() => {
    if (uuid) {
      onViewLoanManagement(uuid);
    }
  }, [onViewLoanManagement, uuid]);

  useEffect(() => {
    viewLoanManagement();
  }, [viewLoanManagement]);

  if (!loan) {
    return <Loader>Loading</Loader>;
  }

  const isCoreOfRecord =
    (loan.core_of_record == null
      ? institution.core_of_record
      : loan.core_of_record) || false;
  const isParticipatingLoan =
    "participation" in loan &&
    (loan.participation === "Active" || loan.participation === "Pending");

  return (
    <>
      <HeaderBlock
        entityData={entityData}
        institutionData={institution}
        loanData={loan}
      />

      {locationState.backlink && (
        <>
          <br />
          <Link to={locationState.backlink}>
            {"< "}
            {locationState.backtext || "Back"}
          </Link>
        </>
      )}

      <LoanManagmentTabs
        isParticipatingLoan={isParticipatingLoan}
        loan={loan}
        match={match}
      />
      <Switch>
        <Route path={`${match.path}/details`}>
          <Details
            loan={loan}
            isCoreOfRecord={isCoreOfRecord}
            onUpdateLoan={loanPatches =>
              onUpdateLoan({ uuid: loan.uuid, ...loanPatches })
            }
          />
        </Route>
        <Route exact path={`${match.path}/relationships`}>
          <Relationships loanUuid={uuid} />
        </Route>
        <Route exact path={`${match.path}/document-tracking`}>
          <DocumentTracking loan={loan} entityData={entityData} />
        </Route>
        <Route exact path={`${match.path}/account-documents`}>
          <AccountDocuments
            loanUuid={uuid}
            entityData={entityData}
            loanBpOrganizationUuid={loan.bp_organization_uuid}
          />
        </Route>

        {userCanViewPage(`${match.path}/credit-risk-review`) && (
          <Route exact path={`${match.path}/credit-risk-review`}>
            <CreditRiskReview loanUuid={uuid} />
          </Route>
        )}
        {institution?.risk_rating_model_type === "Single" && (
          <Route exact path={`${match.path}/credit-analysis`}>
            <CreditAnalysis type="loan" entity={entityData} loan={loan} />
          </Route>
        )}
        <Route exact path={`${match.path}/collateral`}>
          <Collateral loanUuid={uuid} entityData={entityData} />
        </Route>
        {!isParticipatingLoan ? (
          <Route exact path={`${match.path}/payments`}>
            <Payments loanUuid={uuid} />
          </Route>
        ) : (
          userCanViewPage(`${match.path}/payments`) && (
            <Route exact path={`${match.path}/payments`}>
              <Payments loanUuid={uuid} />
            </Route>
          )
        )}
        <Route exact path={`${match.path}/payoff-calculator`}>
          <PayoffCalculator loanUuid={uuid} />
        </Route>
        <Route path={`${match.path}/transaction-history`}>
          {loan.bafs_account_number ? (
            <TransactionHistoryInterface
              isCoreOfRecord={isCoreOfRecord}
              loan={loan}
              acctNumber={loan.bafs_account_number}
              borrowerName={entityData && ConcatName(entityData)}
            />
          ) : null}
        </Route>
        <Route exact path={`${match.path}/premium-discount`}>
          {loan.premium_discount ? (
            <PremiumDiscount
              loanUuid={uuid}
              institutionUuid={institution.uuid}
            />
          ) : null}
        </Route>
        <Route exact path={`${match.path}/participation`}>
          <Participation
            loan={loan}
            entityData={entityData}
            insitution={institution}
          />
        </Route>
        <Route exact path={`${match.path}/nightly-process`}>
          <NightlyProcess
            loan={loan}
            onViewLoanManagement={onViewLoanManagement}
          />
        </Route>
        <Route exact path={`${match.path}/snapshots`}>
          <Snapshots loanUuid={uuid} />
        </Route>
      </Switch>
    </>
  );
}

LoanManagement.propTypes = {
  entityData: entityDataProps.isRequired,
  institution: PropTypes.shape().isRequired,
  loan: PropTypes.shape(),
  onViewLoanManagement: PropTypes.func.isRequired,
  onUpdateLoan: PropTypes.func
};

export default LoanManagement;
