import React, { useEffect, useState } from "react";
import { Form, Icon } from "semantic-ui-react";
import * as PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { forceValue, semanticUIStates } from "../../services/FormElements";

const accountTypeOptions = [
  {
    key: "Deposit Accounts",
    text: "Deposit Accounts",
    value: "Deposit Accounts"
  },
  {
    key: "Investment Property (Securities)",
    text: "Investment Property (Securities)",
    value: "Investment Property (Securities)"
  },
  {
    key: "Life Insurance",
    text: "Life Insurance",
    value: "Life Insurance"
  }
];

/**
 * Gathers account information.
 * @param {object} props
 * @param {object} props.accountDetail
 * @param {object} props.updateValue
 */
function AccountDetailForm({ accountDetail, updateValue }) {
  const [securities, setSecurities] = useState(
    !isEmpty(accountDetail.securities)
      ? accountDetail.securities
      : [
          {
            number_of_shares: "",
            issuer_name: "",
            certificate_number: "",
            securities_type: ""
          }
        ]
  );

  const setSecuritiesValue = (index, { name, value }) => {
    setSecurities(current =>
      current.map((item, i) =>
        i !== index ? item : { ...item, [name]: value }
      )
    );
  };

  useEffect(
    () => updateValue({ name: "securities", value: securities }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [securities]
  );

  const isValidPreviousSecurity = () => {
    const recentSecurity =
      securities.length === 1
        ? securities[0]
        : securities[securities.length - 1];
    return (
      recentSecurity.number_of_shares &&
      recentSecurity.issuer_name &&
      recentSecurity.certificate_number &&
      recentSecurity.securities_type
    );
  };
  const addSecuritiesFields = () => {
    setSecurities([
      ...securities,
      {
        number_of_shares: "",
        issuer_name: "",
        certificate_number: "",
        securities_type: ""
      }
    ]);
  };
  const removeSecuritiesFields = index => {
    const newFormValues = [...securities];
    newFormValues.splice(index, 1);
    setSecurities(newFormValues);
    updateValue({ name: "securities", value: newFormValues });
  };
  return (
    <Form>
      <h2> Account Information:</h2>
      <Form.Group widths="equal">
        <Form.Input
          autoComplete="off"
          label="Account Number"
          name="account_number"
          onChange={(ev, data) => updateValue(data)}
          value={forceValue(accountDetail.account_number)}
        />
        <Form.Select
          value={forceValue(accountDetail.account_type)}
          label="Account Type"
          name="account_type"
          options={accountTypeOptions}
          onChange={(ev, data) => updateValue(data)}
        />
        <Form.Input
          autoComplete="off"
          label="Institution Name"
          name="institution_name"
          onChange={(ev, data) => updateValue(data)}
          value={forceValue(accountDetail.institution_name)}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          autoComplete="off"
          label="Institution Address"
          name="institution_address"
          onChange={(ev, data) => updateValue(data)}
          value={forceValue(accountDetail.institution_address)}
        />
        <Form.Input
          autoComplete="off"
          label="Institution City"
          name="institution_city"
          onChange={(ev, data) => updateValue(data)}
          value={forceValue(accountDetail.institution_city)}
        />
        <Form.Select
          autoComplete="off"
          label="Institution State"
          name="institution_state"
          options={semanticUIStates}
          onChange={(ev, data) => updateValue(data)}
          value={forceValue(accountDetail.institution_state)}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          autoComplete="off"
          label="Institution Postal Code"
          name="institution_postal_code"
          onChange={(ev, data) => updateValue(data)}
          value={forceValue(accountDetail.institution_postal_code)}
        />
        <Form.Input
          autoComplete="off"
          label="Insurance Policy"
          name="life_insurance_policy_number"
          placeholder="add comma separated values"
          onChange={(ev, data) => updateValue(data)}
          value={forceValue(accountDetail.life_insurance_policy_number)}
        />
      </Form.Group>
      <h2 className="ui header"> Securities</h2>
      {securities.length && isValidPreviousSecurity() && (
        <div style={{ textAlign: "right" }}>
          <Icon onClick={() => addSecuritiesFields()} name="plus" circular />
        </div>
      )}
      {securities.map((security, index) => (
        <React.Fragment key={`security-${index}`}>
          <h3 className="ui header">
            {" "}
            Securities
            {index + 1}
          </h3>
          {index !== 0 && (
            <div style={{ textAlign: "right" }}>
              <Icon
                onClick={() => removeSecuritiesFields(index)}
                name="close"
                circular
              />
            </div>
          )}
          <Form.Group widths="4">
            <Form.Input
              autoComplete="off"
              label="Number of shares"
              name="number_of_shares"
              type="number"
              onChange={(ev, data) => setSecuritiesValue(index, data)}
              value={forceValue(security.number_of_shares)}
            />
            <Form.Input
              autoComplete="off"
              label="Issuer Name"
              name="issuer_name"
              onChange={(ev, data) => setSecuritiesValue(index, data)}
              value={forceValue(security.issuer_name)}
            />
            <Form.Input
              autoComplete="off"
              label="Certificate Number"
              name="certificate_number"
              onChange={(ev, data) => setSecuritiesValue(index, data)}
              value={forceValue(security.certificate_number)}
            />
            <Form.Input
              autoComplete="off"
              label="Securities Type"
              name="securities_type"
              placeholder="EX. A Shares, B Shares, Municipal Bond. Free form"
              onChange={(ev, data) => setSecuritiesValue(index, data)}
              value={forceValue(security.securities_type)}
            />
          </Form.Group>
        </React.Fragment>
      ))}
    </Form>
  );
}

AccountDetailForm.propTypes = {
  accountDetail: PropTypes.shape({
    account_number: PropTypes.string,
    account_type: PropTypes.string,
    institution_address: PropTypes.string,
    institution_city: PropTypes.string,
    institution_name: PropTypes.string,
    institution_postal_code: PropTypes.string,
    institution_state: PropTypes.string,
    life_insurance_policy_number: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array
    ]),
    securities: PropTypes.arrayOf(
      PropTypes.shape({
        certificate_number: PropTypes.string.isRequired,
        issuer_name: PropTypes.string,
        number_of_shares: PropTypes.number,
        securities_type: PropTypes.string.isRequired
      })
    )
  }),
  updateValue: PropTypes.func.isRequired
};
export default AccountDetailForm;
