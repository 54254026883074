import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Segment, Form } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { IconButton } from "@bafsllc/ui-shared";
import { useSelector } from "react-redux";
import { Entities } from "../../../../../../../../../services/ApiLib";
import { useBPLinksContext } from "../bp-links-context";
import isEmail from "../../../../../../../../../services/FormElements/services/isEmail";
import logger from "../../../../../../../../../services/logger";
import { ConcatName } from "../../../../../../../../../services/Entities";
import { environment } from "../../../../../../../../../environments/environment";

const clientId = environment.auth0ClientId;

export default function BorrowerPortalSideSheetDropDownSearch({
  onChange,
  onSearchChange
}) {
  const { entityData, bpOrgUserEmail } = useSelector(
    ({ CreditManagementReducer }) => CreditManagementReducer
  );

  const {
    emailOptions,
    institutionData,
    userFeedback,
    updateUserFeedbackKey,
    updateLinkButtonDisabled,
    updateSignUpData
  } = useBPLinksContext(entityData);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(undefined);
  const [bpOrgCreateBtnEnabled, setBpOrgCreateBtnEnabled] = useState(true);

  const emailExists = async email => {
    const response = await Entities.checkBpOrgLinkEmailEligibility(email);

    if (response.data?.uuid) {
      setBpOrgCreateBtnEnabled(false);
      updateLinkButtonDisabled(true);
      updateUserFeedbackKey("emailEligibleErrorPartOfBpOrg");
    }

    if (response?.error === "Bad request. Incorrect email format") {
      setBpOrgCreateBtnEnabled(false);
      updateLinkButtonDisabled(true);
      updateUserFeedbackKey("emailInvalid");
    }

    logger.error(response.error);
  };

  const handleClickCreateNewBPOrganization = async (email = "") => {
    setLoading(true);
    setSelectedEmail(email);

    try {
      updateUserFeedbackKey("emailEligibleSuccess");

      const auth0OrgId = institutionData?.auth0_org_id;
      const signUpData = {
        data: {
          type: "auth0_organization",
          attributes: {
            clientId,
            inviter: ConcatName(entityData),
            invitee: email,
            userMetadata: {
              auth0_org_id: auth0OrgId
            },
            appMetadata: {
              deleted: false
            },
            organizationId: auth0OrgId
          }
        }
      };

      setBpOrgCreateBtnEnabled(false);
      updateSignUpData(signUpData);
      updateLinkButtonDisabled(false);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Invoked when any change in the input field
  const handleSearchChange = (e, data) => {
    const emailIsValid = isEmail(data.searchQuery);
    setSelectedEmail("");

    if (emailIsValid) {
      emailExists(data.searchQuery);
    }

    setBpOrgCreateBtnEnabled(true);
    updateLinkButtonDisabled(true);
    setValidEmail(emailIsValid);
    updateUserFeedbackKey(
      emailIsValid || !data.searchQuery ? "emailEligibleInfo" : "emailInvalid"
    );
    setSearchQuery(data.searchQuery);

    if (onSearchChange && (emailIsValid || data.searchQuery === "")) {
      onSearchChange(data.searchQuery);
    }
  };

  // Invoked when a user clicks an email in the list
  const handleChange = (e, data) => {
    updateUserFeedbackKey("linkEmailReady");
    setSearchQuery("");
    setSelectedEmail(data.value);
    updateLinkButtonDisabled(false);
    setBpOrgCreateBtnEnabled(true);

    if (onChange) {
      onChange(data.value);
    }
  };

  return (
    <Segment className="dropdown-background">
      <Form>
        <div className="bp-links-search-description">
          <FormattedMessage id="BORROWER_PORTAL_LINKS_SEARCH_DESCRIPTION" />
        </div>

        <Form.Dropdown
          label="Email"
          required
          placeholder="— Please make selection —"
          fluid
          selection
          search
          scrolling
          selectOnBlur={false}
          options={emailOptions}
          onChange={handleChange}
          onSearchChange={handleSearchChange}
          searchQuery={searchQuery} // Retains searchQuery in the field onBlur
          value={bpOrgUserEmail || selectedEmail}
          disabled={bpOrgUserEmail}
        />

        <div>
          {bpOrgCreateBtnEnabled &&
            validEmail &&
            searchQuery &&
            !emailOptions.length && (
              <div className="mt-4">
                <IconButton
                  size="tiny"
                  type="button"
                  iconPosition="left"
                  iconLeft={{ icon: "plus" }}
                  variant="primary"
                  disabled={loading}
                  message={{
                    id: loading ? "LOADING_BUTTON" : "BP_ORG_CREATE_NEW"
                  }}
                  onClick={event => {
                    event.preventDefault();
                    handleClickCreateNewBPOrganization(searchQuery);
                  }}
                />
              </div>
            )}
        </div>
      </Form>

      {userFeedback?.id && (selectedEmail || searchQuery?.length > 6) && (
        <div
          className={`flex items-center mt-2 ml-2 ${
            userFeedback?.type === "success" ? "text-lime-700" : "text-red-700"
          }`}
        >
          <i
            className={`${
              userFeedback.type === "success"
                ? "text-lime-700 check"
                : "text-red-700 times"
            } circle icon `} // ${validEmail ? 'text-lime-700' : 'text-red-700'}
          />
          <span
            data-testid="bpSideSheetUserFeedbackMessage"
            className={
              userFeedback.type === "success" ? "text-lime-700" : "text-red-700"
            }
          >
            <FormattedMessage
              id={userFeedback.id}
              defaultMessage={userFeedback.defaultMessage}
            />
          </span>
        </div>
      )}

      <div className="text-neutral-400 flex items-center mt-2 ml-2">
        <i className="info circle icon" />
        <FormattedMessage id="BORROWER_PORTAL_LINKS_SEARCH_INFO" />
      </div>
    </Segment>
  );
}

BorrowerPortalSideSheetDropDownSearch.propTypes = {
  onChange: PropTypes.func,
  onSearchChange: PropTypes.func
};
