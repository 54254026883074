import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Segment } from "semantic-ui-react";
import { Button, HighFrictionDialog, IconButton } from "@bafsllc/ui-shared";
import PaginationControls from "../../../../../../../../components/Pagination";
import AssetModal from "./components/AssetModal";
import AssetTable from "./components/AssetTable";
import { useAssets } from "../../../../../../../../services/Hooks";
import { Entities } from "../../../../../../../../services/ApiLib";
import { updateShowAssetAccessModal } from "./actions";
import { permCheck } from "../../../../../../../../services/Auth";

const paginationName = "creditManagementAssets";

function AssetsTab() {
  const dispatch = useDispatch();
  const {
    institutionUuid,
    entityUuid,
    entityInstitution,
    showAssetAccessModal,
    assetAccessModalData
  } = useSelector(({ CreditManagementReducer }) => ({
    showAssetAccessModal: CreditManagementReducer.showAssetAccessModal,
    assetAccessModalData: CreditManagementReducer.assetAccessModalData,
    entityUuid: CreditManagementReducer.entityData.uuid,
    institutionUuid: CreditManagementReducer.entityData.institution_uuid,
    entityInstitution: CreditManagementReducer.entityInstitution
  }));
  const { pagination } = useSelector(({ PaginationReducer }) => ({
    pagination: PaginationReducer[paginationName]
  }));
  // Borrower Portal Service
  const hasBorrowerPortalService =
    entityInstitution?.borrower_portal_service || false;
  const updatePerms = permCheck([
    "admin",
    "put_bp_access_controls",
    "put_bp_access_controls_unfiltered"
  ]);
  const borrowerPortalAccess = hasBorrowerPortalService || updatePerms;
  // Local State
  const [assetModalOpen, setAssetModalOpen] = useState(false);
  const [assetModalInfo, setAssetModalInfo] = useState({});
  const [selectedAssets, setSelectedAssets] = useState([]);

  const { assets, lastPage, addAsset, readAssets, isFetching } = useAssets(
    {
      parentKey: "entity_uuid",
      parentValue: entityUuid
    },
    pagination || {}
  );

  const resetUpdateShowAssetAccessModal = () => {
    dispatch(updateShowAssetAccessModal(false, null));
  };

  const updateBpAccessControlsEditableByBorrower = () => {
    let assetsToUpdate = [];

    // (multiple|single) User selected Table rows (checkboxes)
    if (Array.isArray(selectedAssets) && selectedAssets.length !== 0) {
      assetsToUpdate = selectedAssets;
    }
    // (single) User clicked "Edit Borrower Lock" in an asset "..." menu
    if (assetAccessModalData?.bpAccessControls) {
      assetsToUpdate = [assetAccessModalData];
    }

    const promises = assetsToUpdate.map(async asset => [
      await Entities.bpAccessControlsUpdateEditableByBorrower(
        asset?.bpAccessControls?.uuid,
        asset?.bpAccessControls?.record_uuid,
        asset?.bpAccessControls?.institution_uuid,
        false
      )
    ]);

    Promise.all(promises).then(() => {
      readAssets();
      resetUpdateShowAssetAccessModal();
    });
  };

  const onSelectionChange = useCallback(
    selectedRows => {
      setSelectedAssets(selectedRows);
    },
    [setSelectedAssets]
  );

  useEffect(() => {
    dispatch({
      type: "CMS_UPDATE_ASSET",
      data: { data: assets }
    });
  }, [dispatch, assets]);

  return (
    <>
      <Segment.Group>
        <Segment>
          <div className="flex align-center justify-end">
            {borrowerPortalAccess && (
              <Button
                disabled={
                  Array.isArray(selectedAssets) && selectedAssets.length === 0
                }
                message={{ id: "BORROWER_EDIT_ASSET" }}
                size="tiny"
                variant="secondary"
                onClick={() => {
                  dispatch(updateShowAssetAccessModal(true));
                }}
              />
            )}

            <div className="ml-3">
              <IconButton
                data-testid="new-asset-btn"
                message={{ id: "ASSET_ADD" }}
                iconLeft={{ icon: "plus" }}
                size="tiny"
                variant="primary"
                onClick={() => {
                  setAssetModalInfo({
                    institution_uuid: institutionUuid,
                    entity_uuid: entityUuid
                  });
                  setAssetModalOpen(true);
                }}
              />
            </div>
          </div>
        </Segment>

        <Segment>
          <AssetTable
            onSelectionChange={onSelectionChange}
            onClickEdit={asset => {
              setAssetModalInfo(asset);
              setAssetModalOpen(true);
            }}
            onClickBorrowerEditLock={asset => {
              dispatch(updateShowAssetAccessModal(true, asset));
            }}
            assets={assets}
            isFetching={isFetching}
            showActionsCell
            showActionsMenu={borrowerPortalAccess}
            showBorrowerStatusCell={borrowerPortalAccess}
            showSelectCell={borrowerPortalAccess}
          />
        </Segment>

        <Segment>
          <PaginationControls name={paginationName} lastPage={lastPage} />
        </Segment>
      </Segment.Group>

      {showAssetAccessModal && (
        <HighFrictionDialog
          affirmative={{ id: "CONFIRM" }}
          negative={{ id: "CANCEL" }}
          header={{ id: "BP_ORGANIZATION" }}
          confirmationMessages={[
            { message: { id: "BORROWER_EDIT_CONF_MSG" } },
            { message: { id: "FRICTION_DIALOG_ENTER_MESSAGE" } }
          ]}
          onHidden={() => {}}
          onDismiss={dismissVariant => {
            if (dismissVariant === "affirmative") {
              updateBpAccessControlsEditableByBorrower();
            } else {
              resetUpdateShowAssetAccessModal();
            }
          }}
        />
      )}

      {assetModalOpen && (
        <AssetModal
          data={assetModalInfo}
          setOpen={setAssetModalOpen}
          open={assetModalOpen}
          onChange={setAssetModalInfo}
          onSave={() => {
            addAsset(assetModalInfo);
          }}
        />
      )}
    </>
  );
}

export default AssetsTab;
