import React from "react";
import { Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import { func, string } from "prop-types";

import { LoanApp } from "../../../../services/ApiLib";
import MessagesInterface from "../../../../components/MessagingInterface";

export const dispatchReset = dispatch => {
  dispatch({ type: "LOR_RESET" });
  dispatch({ type: "LOR_MESSAGES_RESET" });
};

export const dispatchLoanApp = (data, dispatch) => {
  dispatch({ type: "LOR_MESSAGES_POPULATE_INST_UUID", data });
};

export const handleError = dispatch => {
  dispatch({
    type: "LOR_SET_ERROR",
    errorMessage: "Failed to load loan app, please reload."
  });
};

export const handleLoanAppUuid = (loanAppUuid, dispatch, jwt) => {
  dispatch({ type: "LOR_POPULATE_LOANAPP_UUID", loanAppUuid });
  dispatch({ type: "LOR_UPDATE_ACTIVE_STEPS", activeStepsNum: -1 });
  LoanApp.get(
    jwt,
    ({ data }) => dispatchLoanApp(data, dispatch),
    () => handleError(dispatch),
    loanAppUuid
  );
  return () => dispatchReset(dispatch);
};

export function MessagesObj({ dispatch, institutionUuid, loanAppUuid }) {
  React.useEffect(
    () => handleLoanAppUuid(loanAppUuid, dispatch),
    [loanAppUuid, dispatch]
  );
  return (
    <Segment>
      <MessagesInterface
        institutionUuid={institutionUuid}
        parentUuid={loanAppUuid}
      />
    </Segment>
  );
}

MessagesObj.propTypes = {
  dispatch: func.isRequired,
  institutionUuid: string.isRequired,
  loanAppUuid: string.isRequired
};

const mapStateToProps = state => ({
  institutionUuid: state.LorMessagesReducer.institutionUuid
});

export default connect(mapStateToProps)(MessagesObj);
