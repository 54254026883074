/* eslint-env browser */
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Segment,
  Label,
  Grid
} from "semantic-ui-react";
import { connect } from "react-redux";

import Constants from "../../../../../../../../services/Constants/strings";
import entityDataProps from "../../../../../../../../services/PropTypes/entityData";
import Intercom from "../../../../../../../../services/Intercom";

import AddDuplicateModal from "../../../AddDuplicateModal";
import { ConfirmationModal } from "../../../../../../../../components/Modals";

export class ButtonsObj extends Component {
  static findModel(data, selectedPeriods) {
    for (let i = 0; i < data.length; i += 1) {
      if (selectedPeriods[0] === data[i].uuid) {
        return data[i].financialModelUuid;
      }
    }
    return "nullModel";
  }

  constructor(props) {
    super(props);
    this.onNewClick = this.onNewClick.bind(this);
    this.onNewClose = this.onNewClose.bind(this);
    this.onDuplicateClick = this.onDuplicateClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.deleteConfirmCancel = this.deleteConfirmCancel.bind(this);
    this.openReports = this.openReports.bind(this);
    this.onViewArchiveClick = this.onViewArchiveClick.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.intercom = new Intercom();
  }

  onNewClick() {
    this.props.dispatch({
      type: "CMS_TABVIEW_BUTTON_NEW_CLICK"
    });
  }

  onNewClose() {
    this.props.dispatch({
      type: "CMS_TABVIEW_BUTTON_DUPLICATE_CANCEL"
    });
  }

  onDuplicateClick() {
    this.props.dispatch({
      type: "CMS_TABVIEW_BUTTON_DUPLICATE_CLICK"
    });
  }

  onDeleteClick() {
    this.props.dispatch({
      type: "CMS_SHOW_DELETE_CONFIRM_MODAL"
    });
  }

  onViewArchiveClick() {
    this.props.dispatch({
      type: "CMS_FILTER_ARCHIVE_STATUS"
    });
  }

  onEditClick() {
    window.open(
      `/credit_management/reports/${this.props.selectedRowData.uuid}`
    );
  }

  deleteConfirmCancel() {
    this.props.dispatch({
      type: "CMS_HIDE_DELETE_MODAL"
    });
  }

  openReports() {
    const { data, selectedPeriods, entityData } = this.props;
    const newReportTab = this.intercom.openNewTab(
      "/credit_management/reports/"
    );
    const financialModelUuid = ButtonsObj.findModel(data, selectedPeriods);
    this.intercom.sendMessage(
      {
        path: "/credit_management/spreads/",
        selectedPeriods,
        entityData,
        financialModelUuid
      },
      newReportTab
    );
  }

  render() {
    let button;
    if (this.props.activeItem === Constants.FINANCIAL_STATEMENTS) {
      button = (
        <div>
          {this.props.statementSelected ? (
            <Button floated="left" onClick={this.onDuplicateClick}>
              {Constants.DUPLICATE}
            </Button>
          ) : (
            ""
          )}
          <Button floated="left" onClick={this.onNewClick}>
            New
          </Button>
          <Button floated="right" onClick={this.openReports}>
            Create Financial Analysis
          </Button>
          <Button floated="right" onClick={this.onViewArchiveClick}>
            {this.props.showArchivedEntries
              ? Constants.HIDE_ARCHIVE
              : Constants.VIEW_ARCHIVE}
          </Button>
        </div>
      );
    } else if (this.props.activeItem === Constants.FINANCIAL) {
      button = (
        <Grid>
          <Grid.Row>
            <Grid.Column textAlign="right">
              <Checkbox
                checked={this.props.farIncludeArchived}
                label="Include Archived"
                onClick={() =>
                  this.props.dispatch({
                    include: !this.props.farIncludeArchived,
                    type: "CMS_TOGGLE_FAR_INCLUDE_ARCHIVE"
                  })
                }
                toggle
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }
    return (
      <Segment className="django-cms-buttons-bottom-padding">
        {this.props.activeItem === Constants.CREDIT_REQUEST ? (
          <Button
            floated="left"
            onClick={() => window.open("/lor/loan_request/")}
          >
            New
          </Button>
        ) : (
          <span />
        )}
        {this.props.statementSelected &&
        this.props.activeItem === Constants.FINANCIAL ? (
          <div>
            <Button floated="left" onClick={this.onEditClick}>
              {Constants.EDIT}
            </Button>
            <Button
              floated="left"
              onClick={() =>
                window.open(
                  `/credit_management/reports/${this.props.selectedRowData.uuid}/view`
                )
              }
            >
              View
            </Button>
            <Button
              name="spreads-link"
              floated="left"
              onClick={() =>
                window.open(
                  `/credit_management/spreads/${this.props.selectedRowData.uuid}`
                )
              }
            >
              Go to Spreads
            </Button>
          </div>
        ) : (
          ""
        )}
        {this.props.statementSelected &&
        this.props.activeItem === Constants.FINANCIAL_STATEMENTS ? (
          <div>
            <Button floated="left" onClick={this.onDeleteClick}>
              {Constants.DELETE}
            </Button>
            {this.props.deleteDocError ? (
              <Label color="red">{this.props.deleteDocError}</Label>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {button}

        <AddDuplicateModal
          openState={this.props.showNewDialog}
          close={this.onNewClose}
          isDuplicate={this.props.isDuplicate}
          addDocumentCallback={this.props.addDocumentCallback}
          duplicateDocumentCallback={this.props.duplicateDocumentCallback}
        />
        <ConfirmationModal
          handleConfirm={this.props.deleteDocumentCallback}
          open={this.props.showDeleteConfirmModal}
          handleCancel={this.deleteConfirmCancel}
          prompt={Constants.CMS_DELETE_PERIOD_WARNING}
          warningDetails={Constants.CMS_DELETE_PERIOD_WARNING_DETAILS}
          yesButtonLabel={Constants.CMS_DELETE_MODAL_CONFIRM_BUTTON}
        />
        <Divider hidden />
      </Segment>
    );
  }
}

ButtonsObj.propTypes = {
  activeItem: PropTypes.string.isRequired,
  showNewDialog: PropTypes.bool.isRequired,
  isDuplicate: PropTypes.bool.isRequired,
  addDocumentCallback: PropTypes.func.isRequired,
  duplicateDocumentCallback: PropTypes.func.isRequired,
  deleteDocumentCallback: PropTypes.func.isRequired,
  statementSelected: PropTypes.string.isRequired,
  entityData: entityDataProps.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
  deleteDocError: PropTypes.string.isRequired,
  showDeleteConfirmModal: PropTypes.bool.isRequired,
  selectedRowData: PropTypes.shape({
    uuid: PropTypes.string
  }).isRequired,
  selectedPeriods: PropTypes.arrayOf(PropTypes.string).isRequired,
  showArchivedEntries: PropTypes.bool.isRequired,
  farIncludeArchived: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  activeItem: state.CreditManagementReducer.activeItem,
  deleteDocError: state.CreditManagementReducer.deleteDocError,
  entityData: state.CreditManagementReducer.entityData,
  farIncludeArchived: state.CreditManagementReducer.farIncludeArchived,
  isDuplicate: state.CreditManagementReducer.isDuplicate,
  selectedPeriods: state.CreditManagementReducer.selectedPeriods,
  selectedRowData: state.CreditManagementReducer.selectedRowData,
  showArchivedEntries: state.CreditManagementReducer.showArchivedEntries,
  showDeleteConfirmModal: state.CreditManagementReducer.showDeleteConfirmModal,
  showNewDialog: state.CreditManagementReducer.showNewDialog,
  spreadsWindow: state.CreditManagementReducer.spreadsWindow
});

const Buttons = connect(mapStateToProps)(ButtonsObj);

export default Buttons;
