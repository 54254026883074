import React, { useState } from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { ConcatName } from "../../../../../../../../../../../../services/Entities";
import { prettyDate } from "../../../../../../../../../../../../services/DateTime";
import { EditButton } from "../../../../../../../../../../../../components/CustomFormElements";
import { OwnerModal } from "../../../../../../../../../../../../components/Modals";
import useEditableRelationshipCopy from "../../../../../../../../../../../../components/Modals/components/RelationshipModals/hooks/useEditableRelationshipCopy";
import { RemoveNullKeys } from "../../../../../../../../../../../../services/ApiLib/services/Utilities";
import {
  EntityRelationships,
  Relationships
} from "../../../../../../../../../../../../services/ApiLib";
import logger from "../../../../../../../../../../../../services/logger";

function onError(rsp) {
  logger.error("Error: ", rsp); // TODO: Handle errors properly
}
export default function OwnerTable({ owners }) {
  const dispatch = useDispatch();
  const { entityUuid } = useSelector(state => ({
    entityUuid: state.CreditManagementReducer.entityData.uuid
  }));
  const [relationshipToEdit, setRelationshipToEdit] = useState(null);
  const [edits, makeEdit] = useEditableRelationshipCopy(relationshipToEdit);

  function handleRelationshipToEdit(relationship) {
    setRelationshipToEdit(relationship);
  }

  function handleEntityRelationshipUpdates(key, value) {
    makeEdit(key, value);
  }

  const updateEntityRelationship = async data => {
    const newData = RemoveNullKeys(data);
    const body = { ...newData };
    delete body.owner_entity_data;
    delete body.entity_data;
    delete body.relationship_uuid;

    try {
      await Relationships.asyncUpdate(data.relationship_uuid, body);
      const entityData = await EntityRelationships.asyncRead(
        entityUuid,
        "Owner"
      );
      dispatch({
        type: "CMS_REL_SET_OWNER_DETAILS",
        relationshipOwners: entityData.data
      });
    } catch (e) {
      onError(e);
    }
  };

  function handleSaveEntityRelationshipEdit(data) {
    updateEntityRelationship(data);
    handleRelationshipToEdit(null);
  }

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Ownership</Table.HeaderCell>
            <Table.HeaderCell>Voting Rights</Table.HeaderCell>
            <Table.HeaderCell>Start Date</Table.HeaderCell>
            <Table.HeaderCell>End Date</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {owners.map((owner, index) => (
            <Table.Row key={"owner".concat("", index)}>
              <Table.Cell>
                <a
                  href={`/credit_management/entity/${owner.owner_entity_data.uuid}`}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <i className="linkify icon" />
                  {ConcatName(owner.owner_entity_data)}
                </a>
              </Table.Cell>
              <Table.Cell>{(owner.percent_owned * 100).toFixed(4)}%</Table.Cell>
              <Table.Cell>{owner.voting_rights ? "Yes" : "No"}</Table.Cell>
              <Table.Cell>
                {owner.start_date ? prettyDate(owner.start_date) : ""}
              </Table.Cell>
              <Table.Cell>
                {owner.end_date ? prettyDate(owner.end_date) : ""}
              </Table.Cell>
              <Table.Cell collapsing>
                <EditButton
                  onClick={() => {
                    handleRelationshipToEdit(owner);
                  }}
                  aria-label="edit owner"
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {relationshipToEdit && edits && (
        <OwnerModal
          form={edits}
          onClose={() => handleRelationshipToEdit(null)}
          onSave={() => handleSaveEntityRelationshipEdit(edits)}
          updateForm={(event, { name: key, value }) =>
            handleEntityRelationshipUpdates(key, value)
          }
        />
      )}
    </>
  );
}

OwnerTable.propTypes = {
  owners: PropTypes.arrayOf(PropTypes.object).isRequired
};
