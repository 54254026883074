import _ from "lodash";

export const initialState = {
  comment: "",
  form: {},
  entities: [],
  assets: [],
  loans: [],
  openReason: false,
  reason: "",
  loading: false,
  error: ""
};

export default function Reducer(state, action) {
  const safeState = state ?? initialState;
  switch (action.type) {
    case "DOC_APPROVAL_ADD_DOC_DETAILS":
      return { ...safeState, form: action.docDetails };

    case "DOC_APPROVAL_UPDATE_DOC_DETAILS": {
      const newForm = _.cloneDeep(safeState.form);
      newForm[action.name] = action.value;
      return {
        ...safeState,
        form: newForm
      };
    }

    case "DOC_APPROVAL_UPDATE_DATE_RANGE": {
      const newForm = _.cloneDeep(safeState.form);
      newForm[action.key] = action.date;
      return {
        ...safeState,
        form: newForm
      };
    }

    case "DOC_APPROVAL_LOAD_ENTITIES":
      return { ...safeState, entities: action.entities };

    case "DOC_APPROVAL_LOAD_ASSETS":
      return { ...safeState, assets: action.assets };

    case "DOC_APPROVAL_OPEN_REASON":
      return { ...safeState, openReason: !safeState.openReason };

    case "DOC_APPROVAL_UPDATE_REASONS":
      return { ...safeState, reason: action.value };

    case "DOC_APPROVAL_UPDATE_COMMENT":
      return { ...safeState, comment: action.value };

    case "DOC_APPROVAL_LOAD_LOANS":
      return { ...safeState, loans: action.loans };

    case "DOC_APPROVAL_UPDATE_LOADING_STATUS":
      return { ...safeState, loading: !safeState.loading };

    case "DOC_APPROVAL_UPDATE_ERROR":
      return { ...safeState, error: action.error };

    case "DOC_APPROVAL_RESET_STATE":
      return initialState;

    default:
      return safeState;
  }
}
