/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";
import {
  DollarField,
  PercentInput
} from "../../../../../../../../../../components/CustomFormElements";
import { EditModal } from "../../../../../../../../../../components/Modals";

function BalanceEditModal({ loan, onSave, ...props }) {
  const handleSave = values => {
    if (Object.keys(values).length) {
      onSave(values);
    }
  };
  const {
    available_balance,
    current_balance,
    original_amount,
    balance_at_last_renewal,
    uuid,
    current_contract_amount,
    current_contract_date,
    original_rate
  } = loan;

  return (
    <EditModal
      key={uuid}
      onSave={handleSave}
      defaultValues={{
        available_balance,
        current_balance,
        original_amount,
        balance_at_last_renewal,
        current_contract_amount,
        current_contract_date,
        original_rate
      }}
      header="Edit Balance"
      {...props}
    >
      {({ onChange, values }) => (
        <Grid padded>
          <Grid.Row columns="2">
            <Grid.Column>
              <DollarField
                name="current_balance"
                value={values.current_balance}
                label="Current Balance"
                onChange={onChange}
              />
            </Grid.Column>
            <Grid.Column>
              <DollarField
                name="original_amount"
                value={values.original_amount}
                label="Original Balance"
                onChange={onChange}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="2">
            <Grid.Column>
              <DollarField
                name="available_balance"
                value={values.available_balance}
                label="Available Balance"
                onChange={onChange}
              />
            </Grid.Column>
            <Grid.Column>
              <DollarField
                name="balance_at_last_renewal"
                value={values.balance_at_last_renewal}
                label="Balance at Last Renewal"
                onChange={onChange}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="2">
            <Grid.Column>
              <DollarField
                name="current_contract_amount"
                value={values.current_contract_amount}
                label="Current Contract Amount"
                onChange={onChange}
              />
            </Grid.Column>
            <Grid.Column>
              <PercentInput
                name="original_rate"
                scale={3}
                value={values.original_rate}
                label="Original Interest Rate"
                onChange={(e, { name, value }) => onChange({ name, value })}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </EditModal>
  );
}

BalanceEditModal.propTypes = {
  loan: PropTypes.shape(),
  onSave: PropTypes.func
};

export default BalanceEditModal;
