import { IconButton } from "@bafsllc/ui-shared";
import { useState } from "react";
import { CommonMessages } from "@blast-client/constants";
import { AddPaymentScheduleSideSheet } from "./components/AddPaymentScheduleSideSheet";

const { ADD } = CommonMessages;
export const Payments = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOnEdit = () => {
    setIsOpen(true);
  };
  return (
    <div className="flex justify-end">
      <div>
        <IconButton icon="plus" onClick={handleOnEdit} message={{ id: ADD }} />
      </div>
      {isOpen && (
        <AddPaymentScheduleSideSheet isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
    </div>
  );
};
