import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import FetchService, { asyncFetchService } from "../../../FetchService";
import { ConcatQueryParams } from "../Utilities";
import { FormatUrlV2 } from "../../../FormatUrl";
import { relationshipTypes } from "../../../Constants/relationshipTypes";

function get(onSuccess, onError, uuid, queryParams, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "object", false);
  TypeCheck(queryParams, "object", false);

  let url = FormatUrlV2(ApiEndpoints.entityRelationships, uuid);
  if (queryParams) {
    url += ConcatQueryParams(queryParams);
  }

  FetchService("GET", url, onSuccess, onError, null, callbackData);
}

async function asyncRead(entityUuid, type) {
  TypeCheck(entityUuid, "string");
  let url = "";
  if (type === relationshipTypes.OWNER) {
    url = FormatUrlV2(ApiEndpoints.entityRelationshipsOwners, {
      entity_uuid: entityUuid
    });
  }
  if (type === relationshipTypes.OFFICER) {
    url = FormatUrlV2(ApiEndpoints.entityRelationshipsOfficers, {
      entity_uuid: entityUuid
    });
  }
  if (type === relationshipTypes.OTHER_CAPACITIES) {
    url = FormatUrlV2(ApiEndpoints.entityRelationshipsOthers, {
      entity_uuid: entityUuid
    });
  }
  if (type === relationshipTypes.AUTHORIZED_SIGNER) {
    url = FormatUrlV2(ApiEndpoints.entityRelationshipsAuthorizedSigner, {
      entity_uuid: entityUuid
    });
  }
  if (type === relationshipTypes.AFFILIATE) {
    url = FormatUrlV2(ApiEndpoints.entityRelationshipsAffiliates, {
      entity_uuid: entityUuid
    });
  }
  const resp = await asyncFetchService("GET", url);
  return resp;
}
export default { get, asyncRead };
