import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import mime from "mime-types";
import { Header, Segment, Divider } from "semantic-ui-react";
import { useToast } from "@bafsllc/ui-shared";
import TemplateSelection from "./components/TemplateSelection";
import EntityAnalysisTable from "./components/EntityAnalysisTable";
import PdfDownload from "./components/PdfDownload";
import { actionCreators } from "./reducer";
import PaginationControls from "../../../../../../components/Pagination";
import { getToken } from "../../../../../../services/Auth";
import { FormatUrlV2 } from "../../../../../../services/FormatUrl";
import ApiEndpoints from "../../../../../../services/ApiEndpoints";
import Constants from "../../../../../../services/Constants/strings";
import { getFileMimeType } from "../../../../../../services/Constants/AllowedDocumentTypes";
import { Documents, Cms } from "../../../../../../services/ApiLib";
import { convertDocument } from "../../../../../../services/CMS";
import { pollObjectContent } from "../../../../../../services/CMSRS";
import { getBlastClientApplicationBaseUrl } from "../../../../../../components/BlastEmbed/getBaseUrl";
import CreditAnalysis from "../../../../../../components/CreditAnalysis/scenes/CreditAnalysis";

export const paginationName = "creditMemoPagination";
export const paginationSize = 5;
export const { CREDIT_MEMO_NOT_FOUND_MESSAGE } = Constants;
const toastVariant = "error";
const autoCloseDuration = 15000;
export const toastConfigs = {
  504: {
    title: {
      id: "FILE_TOO_LARGE_FOR_DOWNLOAD",
      defaultMessage: "File is too large for download."
    },
    message: {
      id: "CONTACT_ADMINISTRATOR",
      defaultMessage: "Please contact your administrator."
    },
    variant: toastVariant,
    options: {
      autoClose: autoCloseDuration
    }
  },
  "5xx": {
    title: {
      id: "NETWORK_FAILURE_TITLE",
      defaultMessage: "A network failure has occurred."
    },
    message: {
      id: "NETWORK_FAILURE_MESSAGE",
      defaultMessage: "Please try again or contact your Administrator."
    },
    variant: toastVariant,
    options: {
      autoClose: autoCloseDuration
    }
  },
  default: {
    title: {
      id: "ERROR_DOWNLOADING_TITLE",
      defaultMessage: "Error Downloading"
    },
    message: {
      id: "ERROR_DOWNLOADING_MESSAGE",
      defaultMessage: "There was an error downloading. Please try again."
    },
    variant: toastVariant
  }
};

export function CreditMemoObj() {
  const dispatch = useDispatch();
  const {
    institution,
    entityDetails,
    loanApp,
    loanRequest,
    creditNarrativeTemplates,
    creditMemoEntityRelationships,
    creditMemoFinancialAnalysis,
    creditMemoObjectMetadata,
    entityAnalyses,
    pagination,
    totalLoanRequest
  } = useSelector(state => ({
    entityDetails: state.LosPhasesReducer.entityDetails,
    loanApp: state.LosPhasesReducer.loanApp,
    loanRequest: state.LosPhasesReducer.loanRequest,
    totalLoanRequest: state.LosPhasesReducer.totalLoanRequest,
    institution: state.CreditMemoReducer.institution,
    creditNarrativeTemplates: state.CreditMemoReducer.creditNarrativeTemplates,
    creditMemoEntityRelationships:
      state.CreditMemoReducer.creditMemoEntityRelationships,
    creditMemoFinancialAnalysis:
      state.CreditMemoReducer.creditMemoFinancialAnalysis,
    creditMemoObjectMetadata: state.CreditMemoReducer.creditMemoObjectMetadata,
    entityAnalyses: state.CreditMemoReducer.entityAnalyses,
    pagination: state.PaginationReducer[paginationName]
  }));

  const {
    getCreditNarrativeTemplates,
    getEntityRelationships,
    getObjectMetadata,
    getEntityAnalyses,
    updateEntityAnalyses,
    loadInstitution
  } = actionCreators;

  const { addToast } = useToast();
  const blastServiceUrl = ApiEndpoints.baseUri;
  const blastApplicationUrl = getBlastClientApplicationBaseUrl();

  const [selectedTemplate, setSelectedTemplate] = useState();
  const [creatingCreditMemoDoc, setCreatingCreditMemoDoc] = useState(false);
  const [entityTableRows, setEntityTableRows] = useState([]);
  const [entityRowsToShow, setEntityRowsToShow] = useState();
  const [lastPage, setLastPage] = useState(false);
  const [pdfDownloadDisabled, setPdfDownloadDisabled] = useState(true);
  const [fetchingPdfContent, setFetchingPdfContent] = useState(false);

  const { institution_uuid: institutionUuid, uuid: entityUuid } = entityDetails;
  const { uuid: loanAppUuid } = loanApp;
  const { uuid: loanRequestUuid } = loanRequest;
  const { uuid: objectMetadataUuid, fileName: objectMetadataFilename } =
    creditMemoObjectMetadata;
  const {
    uuid: entityAnalysesUuid,
    creditMemoTemplateUuid: entityAnalysesTemplateUuid,
    entityAnalysisData
  } = entityAnalyses;

  // this array adds a UI only property (loan_request_number) that needs to be shown in the credit analysis table

  const loanRequestsForCreditAnalysis =
    totalLoanRequest.length > 0
      ? totalLoanRequest?.map((item, index) => ({
          ...item,
          // eslint-disable-next-line prefer-template
          loan_request_number: "Loan Request" + index + 1
        }))
      : [];

  useEffect(() => {
    if (institutionUuid) {
      dispatch(getCreditNarrativeTemplates(institutionUuid));
      dispatch(loadInstitution(institutionUuid));

      if (loanAppUuid && loanRequestUuid) {
        dispatch(
          getEntityRelationships(institutionUuid, loanAppUuid, loanRequestUuid)
        );
      }
    }
  }, [
    institutionUuid,
    entityUuid,
    loanAppUuid,
    loanRequestUuid,
    getCreditNarrativeTemplates,
    getEntityRelationships,
    loadInstitution,
    dispatch
  ]);

  useEffect(() => {
    if (institutionUuid && loanAppUuid) {
      dispatch(
        getEntityAnalyses({
          institutionUuid,
          loanUuid: loanAppUuid
        })
      );
    }
  }, [institutionUuid, loanAppUuid, getEntityAnalyses, dispatch]);

  useEffect(() => {
    if (entityAnalysesTemplateUuid) {
      dispatch(
        getObjectMetadata(entityAnalysesTemplateUuid, { institutionUuid })
      );
    }
  }, [
    dispatch,
    entityAnalysesTemplateUuid,
    getObjectMetadata,
    institutionUuid
  ]);

  useEffect(() => {
    if (creditNarrativeTemplates?.length && objectMetadataUuid) {
      setSelectedTemplate(
        creditNarrativeTemplates.find(template =>
          objectMetadataFilename.includes(template.templateUuid)
        )
      );
    }
  }, [creditNarrativeTemplates, objectMetadataUuid, objectMetadataFilename]);

  const getToastConfig = error => {
    const status = Number(error?.message);
    let config;

    if (status >= 500 && status < 600 && status !== 504) {
      config = toastConfigs["5xx"];
    } else {
      config = toastConfigs[`${status}`];
    }

    return config || toastConfigs.default;
  };

  const renderErrorToast = error => {
    const toastConfig = getToastConfig(error);
    addToast(toastConfig);
  };

  const tableRows = useMemo(() => {
    const nextTableRows = [];

    if (creditMemoEntityRelationships && creditMemoFinancialAnalysis?.length) {
      Object.entries(creditMemoEntityRelationships).reduce(
        (accEntities, [relationshipType, entities]) => {
          if (!entities?.length) {
            return accEntities;
          }

          entities.reduce((acc, entity) => {
            if (!entity?.uuid) {
              return acc;
            }
            const entityIndex = nextTableRows.findIndex(
              nextTableRow => nextTableRow.uuid === entity.uuid
            );
            if (entityIndex > -1) {
              nextTableRows[entityIndex].types = [
                ...nextTableRows[entityIndex].types,
                relationshipType
              ].sort();
            } else {
              const matchedFinancialAnalysis =
                creditMemoFinancialAnalysis.filter(
                  analysis =>
                    analysis.entityUuid === entity.uuid &&
                    analysis.financialAnalysisCombinedReportDocumentUuid
                );
              nextTableRows.push({
                uuid: entity.uuid,
                name:
                  entity.company_name ||
                  `${entity.first_name} ${entity.last_name}`,
                entityType: entity.entity_type,
                types: [relationshipType],
                financialAnalysis: matchedFinancialAnalysis,
                selectedAnalysis: "",
                tin: entity.tin,
                checked: false,
                active: false
              });
            }
            return acc;
          }, []);

          return accEntities;
        },
        []
      );
    }

    return nextTableRows;
  }, [creditMemoEntityRelationships, creditMemoFinancialAnalysis]);

  useEffect(() => {
    if (tableRows.length) {
      if (entityAnalysisData) {
        let cacheOutdated = false;
        const newEntityTableRows = tableRows.map(tableRow => {
          const cachedEntityData =
            entityAnalysisData.find(
              cacheRow => tableRow.uuid === cacheRow.uuid
            ) || {};
          if (!cachedEntityData?.uuid) {
            cacheOutdated = true;
          }
          return { ...tableRow, ...cachedEntityData };
        });
        cacheOutdated =
          entityAnalysisData.some(
            cacheRow =>
              !tableRows.some(tableRow => cacheRow.uuid === tableRow.uuid)
          ) || cacheOutdated;
        newEntityTableRows.sort(
          (entity1, entity2) =>
            entityAnalysisData.findIndex(
              cachedEntityData => cachedEntityData.uuid === entity1.uuid
            ) -
            entityAnalysisData.findIndex(
              cachedEntityData => cachedEntityData.uuid === entity2.uuid
            )
        );
        setEntityTableRows(newEntityTableRows);
        if (cacheOutdated) {
          dispatch(
            updateEntityAnalyses(entityAnalysesUuid, {
              entityAnalysisData: newEntityTableRows.map(newEntityTableRow => {
                const { uuid, selectedAnalysis, checked, active } =
                  newEntityTableRow;
                return {
                  uuid,
                  selectedAnalysis,
                  checked,
                  active
                };
              })
            })
          );
        }
      } else {
        setEntityTableRows(tableRows);
        dispatch(
          updateEntityAnalyses(entityAnalysesUuid, {
            entityAnalysisData: tableRows.map(tableRow => {
              const { uuid, selectedAnalysis, checked, active } = tableRow;
              return {
                uuid,
                selectedAnalysis,
                checked,
                active
              };
            })
          })
        );
      }
    }
  }, [
    tableRows,
    entityAnalysisData,
    dispatch,
    updateEntityAnalyses,
    entityAnalysesUuid
  ]);

  useEffect(() => {
    const activeRows = entityTableRows.filter(row => row.active);
    if (pagination && activeRows?.length > 0) {
      const { size: pageSize, number: pageNumber } = pagination;
      const startIndex = pageNumber * pageSize;
      const endIndex =
        (pageNumber + 1) * pageSize < activeRows.length
          ? (pageNumber + 1) * pageSize
          : activeRows.length;
      setEntityRowsToShow(activeRows.slice(startIndex, endIndex));
      setLastPage(endIndex === activeRows.length);
    }

    if (!activeRows?.length) {
      setEntityRowsToShow([]);
    }
  }, [entityTableRows, pagination]);

  useEffect(() => {
    const activeRows = entityTableRows.filter(row => row.active);
    if (selectedTemplate?.templateUuid) {
      if (activeRows?.length) {
        setPdfDownloadDisabled(
          !activeRows.every(row => !!row?.selectedAnalysis)
        );
      } else {
        setPdfDownloadDisabled(false);
      }
    } else {
      setPdfDownloadDisabled(true);
    }
  }, [entityTableRows, selectedTemplate]);

  const handleTemplateChange = async templateUuid => {
    setCreatingCreditMemoDoc(true);
    const nextTemplate = creditNarrativeTemplates.find(
      template => template.uuid === templateUuid
    );

    if (nextTemplate?.templateUuid) {
      const renderTemplateUrl = `${blastServiceUrl}/cms-reports/render/${nextTemplate?.templateUuid}?institutionUuid=${institutionUuid}&loanAppUuid=${loanApp.uuid}`;
      const renderTemplateResponse = await fetch(renderTemplateUrl, {
        method: "POST",
        headers: {
          Authorization: getToken(),
          Accept: "application/octet-stream"
        }
      });

      const document = await renderTemplateResponse.blob();
      const filename = `credit-memo-${nextTemplate?.templateUuid}.docx`;
      const form = new FormData();
      form.append("fileData", document, {
        filename,
        contentType: mime.lookup(filename)
      });
      form.append("fileName", filename);
      form.append("institutionUuid", institutionUuid);
      const url = FormatUrlV2(ApiEndpoints.objectUploadsv2);
      const uploadDocResponse = await fetch(url, {
        method: "POST",
        body: form,
        headers: {
          Authorization: getToken()
        }
      });

      const { data } = await uploadDocResponse.json();
      dispatch(actionCreators.setCreditMemoObjectMetadata(data));
      dispatch(
        updateEntityAnalyses(entityAnalysesUuid, {
          creditMemoTemplateUuid: data.uuid
        })
      );
    } else {
      dispatch(actionCreators.setCreditMemoObjectMetadata({}));
      dispatch(
        updateEntityAnalyses(entityAnalysesUuid, { creditMemoTemplateUuid: "" })
      );
    }
    setSelectedTemplate(nextTemplate);
    setCreatingCreditMemoDoc(false);
  };

  const handleEditTemplate = () => {
    if (objectMetadataUuid) {
      window.open(
        `${blastApplicationUrl}/document-editor/${objectMetadataUuid}?institutionUuid=${institutionUuid}`,
        "_blank"
      );
    }
  };

  const handleAnalysisSelectionChange = (selectedAnalysis, analysisUuid) => {
    const nextEntityTableRows = entityTableRows.map(row =>
      row.uuid === analysisUuid ? { ...row, selectedAnalysis } : row
    );
    setEntityTableRows(nextEntityTableRows);
    dispatch(
      updateEntityAnalyses(entityAnalysesUuid, {
        entityAnalysisData: nextEntityTableRows.map(tableRow => {
          const {
            uuid,
            selectedAnalysis: updatedSelectedAnalysis,
            checked,
            active
          } = tableRow;
          return {
            uuid,
            selectedAnalysis: updatedSelectedAnalysis,
            checked,
            active
          };
        })
      })
    );
  };

  const handleUpdateEntityAnalyses = nextEntityTableRows => {
    setEntityTableRows(nextEntityTableRows);
    dispatch(
      updateEntityAnalyses(entityAnalysesUuid, {
        entityAnalysisData: nextEntityTableRows.map(tableRow => {
          const { uuid, selectedAnalysis, checked, active } = tableRow;
          return {
            uuid,
            selectedAnalysis,
            checked,
            active
          };
        })
      })
    );
  };

  const handleAddRow = analysisUuid => {
    const nextEntityTableRows = entityTableRows.map(row =>
      row.uuid === analysisUuid ? { ...row, active: true } : row
    );
    setEntityTableRows(nextEntityTableRows);
    dispatch(
      updateEntityAnalyses(entityAnalysesUuid, {
        entityAnalysisData: nextEntityTableRows.map(tableRow => {
          const { uuid, selectedAnalysis, checked, active } = tableRow;
          return {
            uuid,
            selectedAnalysis,
            checked,
            active
          };
        })
      })
    );
  };

  const handleRemoveRows = () => {
    const nextEntityTableRows = entityTableRows.map(row =>
      row.checked
        ? {
            ...row,
            selectedAnalysis: "",
            active: false,
            checked: false
          }
        : row
    );
    setEntityTableRows(nextEntityTableRows);
    dispatch(
      updateEntityAnalyses(entityAnalysesUuid, {
        entityAnalysisData: nextEntityTableRows.map(tableRow => {
          const { uuid, selectedAnalysis, checked, active } = tableRow;
          return {
            uuid,
            selectedAnalysis,
            checked,
            active
          };
        })
      })
    );
  };

  const handleSortRows = sortedEntityTableRows => {
    setEntityTableRows(sortedEntityTableRows);
    dispatch(
      updateEntityAnalyses(entityAnalysesUuid, {
        entityAnalysisData: sortedEntityTableRows.map(tableRow => {
          const { uuid, selectedAnalysis, checked, active } = tableRow;
          return {
            uuid,
            selectedAnalysis,
            checked,
            active
          };
        })
      })
    );
  };

  const handleSelectRow = analysisUuid => {
    const nextTableRows = entityTableRows.map(row =>
      row.uuid === analysisUuid ? { ...row, checked: !row?.checked } : row
    );
    setEntityTableRows(nextTableRows);
  };

  const handleSelectAll = allSelected => {
    const nextTableRows = entityTableRows.map(row => ({
      ...row,
      checked: !allSelected
    }));
    setEntityTableRows(nextTableRows);
  };

  const handlePdfDownload = async () => {
    setFetchingPdfContent(true);

    const selectedAnalysisDocumentUuids = entityTableRows
      .filter(row => row.active && row.selectedAnalysis)
      .map(row => {
        const { financialAnalysis, selectedAnalysis } = row;
        return financialAnalysis.find(
          analysis => analysis.uuid === selectedAnalysis
        ).financialAnalysisCombinedReportDocumentUuid;
      });

    const allDocumentUuids = [
      creditMemoObjectMetadata.uuid,
      ...selectedAnalysisDocumentUuids
    ];
    try {
      const convertedDocuments = await Promise.all(
        allDocumentUuids.map(documentUuid =>
          convertDocument(institutionUuid, documentUuid, `${documentUuid}.pdf`)
        )
      );

      if (convertedDocuments.some(document => document.error)) {
        setFetchingPdfContent(false);
        renderErrorToast();
        return;
      }

      const creditMemoPlaceholder = await Cms.createObjectsV2Placeholder(
        "Credit Memo.pdf",
        institutionUuid
      );
      await Cms.createCreditMemo({
        document_uuids: [...convertedDocuments.map(document => document.uuid)],
        filename: "Credit Memo.pdf",
        institutionUuid,
        fileUuid: creditMemoPlaceholder.data.uuid
      });

      await pollObjectContent(
        creditMemoPlaceholder.data.uuid,
        institutionUuid,
        2000
      );
      const { mimeType } = getFileMimeType(creditMemoPlaceholder.data.fileName);
      const previewedDocument = await Documents.preview(
        { uuid: creditMemoPlaceholder.data.uuid },
        { fileType: mimeType, fileName: creditMemoPlaceholder.data.fileName }
      );
      if (!previewedDocument) {
        setFetchingPdfContent(false);
        renderErrorToast();
        return;
      }
      setFetchingPdfContent(false);
    } catch (error) {
      setFetchingPdfContent(false);
      renderErrorToast(error);
    }
  };

  return creditNarrativeTemplates && creditNarrativeTemplates.length > 0 ? (
    <div data-testid="credit-memo">
      <Segment>
        <Header>Credit Memo</Header>
        <TemplateSelection
          templateDropdownDisabled={!!selectedTemplate?.uuid}
          selectedTemplateValue={selectedTemplate?.uuid}
          options={creditNarrativeTemplates
            .filter(
              template =>
                template.status === "Production" ||
                template.uuid === selectedTemplate?.uuid
            )
            .map(template => ({
              key: template.uuid,
              value: template.uuid,
              text: `${template.name} | Version ${template.version}`
            }))}
          editButtonDisabled={creatingCreditMemoDoc || !selectedTemplate?.uuid}
          handleChange={handleTemplateChange}
          handleEdit={handleEditTemplate}
        />
        <Divider />
        <EntityAnalysisTable
          rows={entityTableRows || []}
          rowsToShow={entityRowsToShow || []}
          handleAddRow={handleAddRow}
          handleAnalysisSelectionChange={handleAnalysisSelectionChange}
          handleUpdateEntityAnalyses={handleUpdateEntityAnalyses}
          handleSelectRow={handleSelectRow}
          handleSelectAll={handleSelectAll}
          handleRemoveRows={handleRemoveRows}
          handleSortRows={handleSortRows}
        />
        <Divider hidden />
        <div data-testid="pagination-controls">
          <PaginationControls
            pageSizeOverride={paginationSize}
            length={entityTableRows?.length}
            name={paginationName}
            lastPage={lastPage}
          />
        </div>
        <Divider />
        {institution?.risk_rating_model_type === "Single" && (
          <>
            <CreditAnalysis
              type="loan_app"
              entity={entityDetails}
              loan={loanRequestsForCreditAnalysis}
              showCreateAnalysisButton={false}
            />
            <Divider />
          </>
        )}
        <PdfDownload
          isDisabled={pdfDownloadDisabled || fetchingPdfContent}
          fetchingPdfContent={fetchingPdfContent}
          handlePdfDownload={handlePdfDownload}
        />
      </Segment>
    </div>
  ) : (
    <>
      <h3>Credit Memo</h3>
      <div
        className="ui centered grid segment"
        style={{ border: "dashed grey" }}
      >
        <div className="centered aligned six wide column">
          <h4>Please Note</h4>
          <p>{CREDIT_MEMO_NOT_FOUND_MESSAGE}</p>
        </div>
      </div>
    </>
  );
}

export default CreditMemoObj;
