import { IconButton } from "@bafsllc/ui-shared";
import { ConcatName as getEntityName } from "../../../../../../../../services/Entities";
import Tooltip from "../../../../../../../../components/Tooltip";

function BorrowerPortalLinksHeaders(handleActionUnlink) {
  return [
    {
      name: "Entity Name",
      key: "entityName",
      columnSize: "seven",
      customColumn: entityData => (
        <div>
          <a
            href={`/credit_management/entity/${entityData.uuid}`}
            className=""
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="linkify icon" />
            {getEntityName(entityData)}
          </a>
        </div>
      )
    },
    {
      name: "Entity Type",
      key: "entity_type",
      columnSize: "seven"
    },
    {
      name: "",
      key: "row_level_action",
      columnSize: "one",
      customColumn: (entityData, options) => {
        const { isDisabled, isPartiallyDisabled } = options;
        const disabled = isDisabled || isPartiallyDisabled;
        const button = (
          <div data-testid="trash-tooltip" className="ui grey">
            <IconButton
              data-testid={`trash-${entityData.uuid}`}
              iconLeft={{ icon: "trash alternate outline" }}
              size="tiny"
              onClick={() => handleActionUnlink(entityData)}
              variant="secondary"
              disabled={disabled}
            />
          </div>
        );

        return (
          <div>
            {disabled ? (
              <Tooltip
                message="Entity is not allowed to be unlinked."
                width="very"
                position="top right"
                inverted
              >
                {button}
              </Tooltip>
            ) : (
              button
            )}
          </div>
        );
      },

      sortable: false
    }
  ];
}

export default BorrowerPortalLinksHeaders;
