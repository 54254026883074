import React, { MutableRefObject } from "react";

/**
 * This custom hook is to determine whether a component is
 * out of the view port or not
 * @returns {[React.MutableRefObject<HTMLElement>, Bounds]}
 * [componentRef, isOutOfBounds] that is the Html object reference and the top, bottom, left and right position of it
 */
export function useOutOfBounds(): [MutableRefObject<any>, Bounds] {
  const componentRef = React.useRef<HTMLElement>();

  const [isOutOfBounds, setIsOutOfBounds] = React.useState<Bounds>({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const observer = new MutationObserver((mutationRecord, _) => {
    if (componentRef.current) {
      const rect = componentRef.current.getBoundingClientRect();
      const windowWidth = Math.min(
        document.documentElement.clientWidth,
        window.innerWidth
      );
      const windowHeight = Math.min(
        document.documentElement.clientHeight,
        window.innerHeight
      );
      const directions = {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      };

      if (rect.top < 0) {
        directions.top = Math.abs(0 - rect.top);
      }

      if (rect.bottom > windowHeight) {
        directions.bottom = Math.abs(windowHeight - rect.bottom);
      }

      if (rect.left < 0) {
        directions.left = Math.abs(0 - rect.left);
      }

      if (rect.right > windowWidth) {
        directions.right = Math.abs(windowWidth - rect.right);
      }

      if (
        isOutOfBounds.top !== directions.top ||
        isOutOfBounds.bottom !== directions.bottom ||
        isOutOfBounds.left !== directions.left ||
        isOutOfBounds.right !== directions.right
      ) {
        setIsOutOfBounds(directions);
      }
    }
  });

  React.useEffect(() => {
    if (componentRef.current) {
      observer.observe(componentRef.current, {
        attributes: true,
        childList: true,
        subtree: true
      });
    }

    return () => observer.disconnect();
  }, [componentRef, observer]);

  return [componentRef, isOutOfBounds];
}

interface Bounds {
  top: number;
  bottom: number;
  left: number;
  right: number;
}
