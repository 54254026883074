import _ from "lodash";

export const initialState = {
  currentMessage: "",
  lastPage: false,
  loading: false,
  messages: []
};

export default (state, action) => {
  const safeState = state ?? initialState;
  switch (action.type) {
    case "MESSAGING_POPULATE_MESSAGES":
      return {
        ...safeState,
        lastPage: action.lastPage,
        loading: false,
        messages: _.uniqBy([...safeState.messages, ...action.data], i => i.uuid)
      };

    case "MESSAGES_LOADING":
      return { ...safeState, loading: true };

    case "MESSAGING_RESET":
      return initialState;

    case "MESSAGING_UPDATE_MESSAGE":
      return { ...safeState, currentMessage: action.data };

    case "MESSAGING_ADD_MESSAGE":
      return { ...safeState, messages: [action.data, ...safeState.messages] };

    default:
      return safeState;
  }
};
