import PaymentTypes from "../Constants/paymentTypes";
import Constants from "../Constants/strings";
import { getUserGroup } from "../Auth";

const getPaymentTypeOptions = (
  advanceType,
  currentBalance,
  govGuaranteeAgencyProgram
) => {
  const loanIsFunded = currentBalance && currentBalance > 0;
  const advancedPaymentNotAllowed = advanceType === "Single" && loanIsFunded;
  let payments = PaymentTypes;

  if (advanceType !== Constants.REVOLVING) {
    payments = PaymentTypes.filter(
      option => option.text !== Constants.PAYDOWN_LOAN_TO_ZERO
    );
  }

  if (advancedPaymentNotAllowed) {
    payments = PaymentTypes.filter(
      option => option.text !== Constants.PAYMENT_TYPE_ADVANCE
    );
  }

  if (getUserGroup() === "Institution") {
    const optionsForInstitutionUsers = payments.filter(
      option => option.Institution === true
    );
    return govGuaranteeAgencyProgram !== Constants.PPP
      ? optionsForInstitutionUsers.filter(
          option => option.text !== Constants.PAYMENT_TYPE_PAYOFF
        )
      : optionsForInstitutionUsers;
  }
  return payments;
};

export default { getPaymentTypeOptions };
