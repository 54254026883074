import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form, Header, Container } from "semantic-ui-react";
import { actionCreators } from "../../reducer";
import {
  firstDrawInputs,
  secondDrawInputs,
  entityAttestationInputs,
  pppAttestationInputs
} from "../../constants/pppFormValues";
import PPPFormInputs from "../../utilities/PPPFormInputs";

const requiredFirstDrawAttestations = [
  "applicant_no_shuttered_venue_grant",
  "loan_request_is_necessary",
  "applicant_meets_revenue_test_and_size_standard"
];
const requiredSecondDrawAttestations = [
  "applicant_wont_receive_another_second_draw",
  "loan_request_is_necessary",
  "applicant_has_reduction_in_gross_receipts",
  "applicant_no_shuttered_venue_grant",
  "applicant_meets_size_standard"
];

const requiredEntityAttestations = [
  "all_payroll_employees_reside_in_us",
  "loan_officer_uuid"
];
const requiredPPPAttestations = [
  "ineligible_bad_loan",
  "ineligible_criminal_charges",
  "ineligible_felony",
  "ineligible_general",
  "applicant_has_reduction_in_gross_receipts",
  "applicant_is_eligible",
  "applicant_meets_revenue_test_and_size_standard",
  "applicant_no_shuttered_venue_grant",
  "applicant_wont_receive_another_second_draw",
  "loan_request_is_necessary"
];

const checkFields = (data, requiredFields) => {
  let isValid = true;
  for (let i = 0; i < requiredFields.length; i += 1) {
    const field = requiredFields[i];
    if (!data[field]) {
      isValid = false;
    }
  }
  return isValid;
};

export function AttestationsObj({
  pppFormFields,
  entityFormFields,
  setPPPFormFields,
  updatePPPFields,
  setEntityFormFields,
  updateEntityFields,
  setAttestationsValid,
  isSecondDraw
}) {
  const [conditionalInputs, setConditionalInputs] = useState(null);

  useEffect(() => {
    // set validity of attestations and set condititional inputs
    const nextConditionalInputs = isSecondDraw
      ? secondDrawInputs
      : firstDrawInputs;
    const conditionalRequiredInputs = isSecondDraw
      ? requiredSecondDrawAttestations
      : requiredFirstDrawAttestations;

    const isValid =
      checkFields(pppFormFields, conditionalRequiredInputs) &&
      checkFields(pppFormFields, requiredPPPAttestations) &&
      checkFields(entityFormFields, requiredEntityAttestations);

    setConditionalInputs(
      <PPPFormInputs
        inputs={nextConditionalInputs}
        fields={pppFormFields}
        update={updatePPPFields}
        set={setPPPFormFields}
      />
    );
    setAttestationsValid(isValid);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [pppFormFields, entityFormFields, setAttestationsValid, isSecondDraw]);

  const pppAttestationInputElements = (
    <PPPFormInputs
      wrapper="ppp-attestations"
      inputs={pppAttestationInputs}
      fields={pppFormFields}
      update={updatePPPFields}
      set={setPPPFormFields}
    />
  );

  const entityAttestationInputElements = (
    <PPPFormInputs
      wrapper="entity-attestations"
      inputs={entityAttestationInputs}
      fields={entityFormFields}
      update={updateEntityFields}
      set={setEntityFormFields}
    />
  );

  return (
    <Container textAlign="left">
      <Header as="h3" content="Attestations" />
      <Form autoComplete="off">
        {pppAttestationInputElements}
        {entityAttestationInputElements}
        {conditionalInputs}
      </Form>
    </Container>
  );
}

AttestationsObj.propTypes = {
  pppFormFields: PropTypes.shape(),
  entityFormFields: PropTypes.shape(),
  setPPPFormFields: PropTypes.func,
  updatePPPFields: PropTypes.func,
  setEntityFormFields: PropTypes.func,
  updateEntityFields: PropTypes.func,
  setAttestationsValid: PropTypes.func,
  isSecondDraw: PropTypes.bool
};

const mapStateToProps = state => ({
  pppFormFields: state.PPPReducer.pppFormFields,
  entityFormFields: state.PPPReducer.entityFormFields,
  isSecondDraw: state.PPPReducer.isSecondDraw
});

export const mapDispatchToProps = dispatch => ({
  setPPPFormFields: fields => dispatch(actionCreators.setPPPFormFields(fields)),
  updatePPPFields: fields => dispatch(actionCreators.updatePPPFields(fields)),
  setEntityFormFields: fields =>
    dispatch(actionCreators.setEntityFormFields(fields)),
  updateEntityFields: fields =>
    dispatch(actionCreators.updateEntityFields(fields)),
  setAttestationsValid: isValid =>
    dispatch(actionCreators.setAttestationsValid(isValid))
});

export default connect(mapStateToProps, mapDispatchToProps)(AttestationsObj);
