import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UserInfo } from "../ApiLib";

const useUserName = uuid => {
  const users = useSelector(state => state.UserInfoReducer.users);
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (uuid && !users[uuid]) {
      UserInfo.asyncFetchUserName(dispatch, uuid);
    }
  }, [dispatch, users, uuid]);

  useEffect(() => {
    setUserName(users[uuid]);
  }, [users, uuid]);

  return userName;
};

export default useUserName;
