import React, { useEffect, useState, useRef } from "react";
import { useCreatePaymentScheduleMutation } from "../../../../../../services/RTKQuery/paymentSchedules";
import {
  PaymentSchedule,
  PaymentTypes,
  PostingMethods,
  ScheduleStatuses
} from "../../../../../../services/RTKQuery/ModelTypes/paymentSchedule";

export const CreateTest = ({
  loanUuid,
  setUuidOfCreatedRecord,
  setStatusInParent
}) => {
  const statusUnknown = "❓Unknown Status";
  const statusPass = "✅ Pass";
  const statusFail = "❌ Fail";

  const [statusCreateState, setStatusCreateState] = useState(statusUnknown);

  const itemUuid = useRef("");

  const [createPaymentSchedule] = useCreatePaymentScheduleMutation();
  useEffect(() => {
    const exampleBody: Partial<PaymentSchedule> = {
      loan_uuid: loanUuid,
      institution_uuid: "institut-ions-0016-IBLS-TestBranch00",
      payment_type: PaymentTypes.P_AND_I,
      posting_method: PostingMethods.effective,
      status: ScheduleStatuses.active,
      processing_order: 0
    };

    createPaymentSchedule(exampleBody)
      .unwrap()
      .then(fulfilled => {
        itemUuid.current = fulfilled.data.uuid;
        setUuidOfCreatedRecord(itemUuid.current);
        setStatusCreateState(statusPass);
        setStatusInParent("Pass");
      })
      .catch(error => {
        console.error("Fail: useCreatePaymentScheduleMutation");
        console.error("caught error: ", error);
        setStatusCreateState(statusFail);
        setStatusInParent("Fail");
      });
  }, [
    createPaymentSchedule,
    loanUuid,
    setStatusInParent,
    setUuidOfCreatedRecord
  ]);

  return <div>{statusCreateState}</div>;
};
