import React, { useEffect, useState, useRef } from "react";
import { useCreateLoanAppSettlementSheetMutation } from "../../../../../../services/RTKQuery/loanAppSettlementSheet";
import { LoanAppSettlementSheetCreation } from "../../../../../../services/RTKQuery/types";

export const CreateTest = ({
  loanUuid,
  setUuidOfCreatedRecord,
  setStatusInParent
}) => {
  const statusUnknown = "❓Unknown Status";
  const statusPass = "✅ Pass";
  const statusFail = "❌ Fail";

  const [statusCreateState, setStatusCreateState] = useState(statusUnknown);

  const itemUuid = useRef("");

  const [createLoanAppSettlementSheetMutation] =
    useCreateLoanAppSettlementSheetMutation();
  useEffect(() => {
    const exampleBody: Partial<LoanAppSettlementSheetCreation> = {
      loan_uuid: loanUuid,
      amount: 1000,
      created_by: "John Doe",
      created_datetime: "2022-01-01",
      deleted: false,
      disbursement_date: "2022-02-01",
      include_on_loan_docs: true,
      institution_uuid: "123456789",
      item_type: "Charges and Fees",
      paid_by: "Jane Smith",
      paid_outside_of_closing: false,
      paid_to: "XYZ Company",
      reason_charges_and_fees: "Credit Report Fee",
      reason_other: "some reason"
    };

    createLoanAppSettlementSheetMutation(exampleBody)
      .unwrap()
      .then(fulfilled => {
        itemUuid.current = fulfilled.data.uuid;
        setUuidOfCreatedRecord(itemUuid.current);
        setStatusCreateState(statusPass);
        setStatusInParent("Pass");
      })
      .catch(error => {
        console.error("Fail: useCreateLoanAppSettlementSheetMutation");
        console.error("caught error: ", error);
        setStatusCreateState(statusFail);
        setStatusInParent("Fail");
      });
  }, [
    createLoanAppSettlementSheetMutation,
    loanUuid,
    setStatusInParent,
    setUuidOfCreatedRecord
  ]);

  return <div>{statusCreateState}</div>;
};
