import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Header, Input } from "semantic-ui-react";
import styled from "@emotion/styled";
import DocTable from "../DocTable";

function Docs({
  analysts,
  docs,
  parentUuid,
  readOnly = false,
  onDeleteDoc,
  onUpdateDoc,
  relType
}) {
  const [docsByType, setDocsByType] = useState({});
  const [docUuidBeingEditedOrApproved, setDocEditModalVisibility] =
    useState("");
  const [searchValue, setSearchValue] = useState("");
  const [docTypeKeys, setDocTypeKeys] = useState([]);

  useEffect(() => {
    const nextDocsByType = {};
    docs.forEach(doc => {
      if (nextDocsByType[doc.docType]) {
        nextDocsByType[doc.docType].push(doc);
      } else {
        nextDocsByType[doc.docType] = [doc];
      }
    });

    let nextDocTypeKeys = Object.keys(nextDocsByType);

    nextDocTypeKeys = nextDocTypeKeys.filter(key => {
      if (
        searchValue &&
        searchValue.length > 0 &&
        !key.toLowerCase().startsWith(searchValue)
      ) {
        delete nextDocsByType[key];
        return false;
      }
      return true;
    });

    setDocsByType(nextDocsByType);
    setDocTypeKeys(nextDocTypeKeys);
  }, [docs, searchValue]);

  if (docs.length < 1) {
    return <NoDocsBlock>No Documents Found.</NoDocsBlock>;
  }

  return (
    <>
      <Input
        icon="search"
        onChange={e => setSearchValue(e.target.value)}
        placeholder="Search Document Type"
        value={searchValue}
      />
      {docTypeKeys.map(key => (
        <div key={key} style={{ marginTop: "20px" }}>
          <Header>{key}</Header>
          <DocTable
            analysts={analysts}
            docs={docsByType[key]}
            key={key}
            docUuidBeingEditedOrApproved={docUuidBeingEditedOrApproved}
            parentUuid={parentUuid}
            setDocEditModalVisibility={setDocEditModalVisibility}
            onUpdateDoc={onUpdateDoc}
            onDeleteDoc={onDeleteDoc}
            readOnly={readOnly}
            relType={relType}
          />
        </div>
      ))}
    </>
  );
}

const NoDocsBlock = styled.p`
  font-weight: bold;
`;

Docs.propTypes = {
  analysts: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string
    })
  }),
  docs: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      description: PropTypes.string,
      file_name: PropTypes.string,
      type: PropTypes.string,
      upload_date: PropTypes.string,
      uploaded_by: PropTypes.string,
      uuid: PropTypes.string
    })
  ),
  readOnly: PropTypes.bool,
  parentUuid: PropTypes.string,
  onDeleteDoc: PropTypes.func,
  onUpdateDoc: PropTypes.func,
  relType: PropTypes.string
};

export default Docs;
