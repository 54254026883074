import { PropTypes } from "prop-types";
import { useState } from "react";
import { useDispatch } from "react-redux";
import CreditAnalysisSideSheet from "../../components/CreditAnalysisSidesheet";
import CreditAnalysisTable from "../../components/CreditAnalysisTable";
import { CREDIT_ANALYSIS_ACTIONS } from "../../reducer";
import { entityDataProp, loanDataProp } from "../../utils";

export default function CreditAnalysis({
  type,
  entity,
  loan,
  showCreateAnalysisButton = true
}) {
  const dispatch = useDispatch();

  const [isOpenCreditAnalysisSidesheet, setIsOpenCreditAnalysisSidesheet] =
    useState(false);

  const onCloseCreditAnalysisSidesheetHandler = () => {
    setIsOpenCreditAnalysisSidesheet(false);
    dispatch({
      type: CREDIT_ANALYSIS_ACTIONS.select,
      data: null
    });
  };
  const onOpenCreditAnalysisSidesheetHandler = ({
    selectedCreditAnalysis = null
  }) => {
    setIsOpenCreditAnalysisSidesheet(true);
    dispatch({
      type: CREDIT_ANALYSIS_ACTIONS.select,
      data: selectedCreditAnalysis
    });
  };

  return (
    <>
      <CreditAnalysisTable
        type={type}
        entity={entity}
        loan={loan}
        onOpenSidesheet={onOpenCreditAnalysisSidesheetHandler}
        showCreateAnalysisButton={showCreateAnalysisButton}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
        {isOpenCreditAnalysisSidesheet && loan && entity && (
          <CreditAnalysisSideSheet
            type={type}
            entity={entity}
            loan={loan}
            isOpen={isOpenCreditAnalysisSidesheet}
            onClose={onCloseCreditAnalysisSidesheetHandler}
          />
        )}
      </div>
    </>
  );
}

CreditAnalysis.propTypes = {
  type: PropTypes.string.isRequired,
  entity: entityDataProp.isRequired,
  loan: loanDataProp.isRequired,
  showCreateAnalysisButton: PropTypes.bool
};
