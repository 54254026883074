/* eslint-disable no-restricted-globals */
/* eslint-disable for-direction */
/* eslint-disable no-continue */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable yoda */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import Constants from "../../../../services/Constants/strings";

const { DEFAULT_LOCALE } = Constants;
const INPUT_TYPE_BACKSPACE = "deleteContentBackward";
const INPUT_TYPE_DELETE = "deleteContentForward";
const NEGATIVE_SIGN = "-";

function NumberInput({
  decimalPlaces = 2,
  locale = DEFAULT_LOCALE,
  onChange,
  value,
  onBlur,
  onFocus,
  min = Number.NEGATIVE_INFINITY,
  max = Number.POSITIVE_INFINITY,
  error,
  ...props
}) {
  const [endsWithDecimal, setEndsWithDecimal] = useState(false);
  const [selectionAfter, setSelectionAfter] = useState({}); // Object to force updates when string is the same.
  const [trailingZeros, setTrailingZeros] = useState(0);
  const ref = useRef();
  const refKeyDownSelectionStart = useRef(0);
  const [focused, setFocused] = useState(false);

  const stringValue = useMemo(
    () => numberToString(value, locale, endsWithDecimal, trailingZeros),
    [endsWithDecimal, locale, trailingZeros, value]
  );

  const handleBlur = evt => {
    setFocused(false);
    onBlur && onBlur(evt);
  };

  const handleFocus = evt => {
    setFocused(true);
    onFocus && onFocus(evt);
  };

  const processChangedValue = useCallback(
    (eventStrValue, currentStrValue) => {
      const { left, decimalEnd, negative, right, valid } = getNumberData(
        eventStrValue,
        locale
      );

      if (!valid) {
        return null;
      }

      setEndsWithDecimal(decimalPlaces !== 0 && decimalEnd);
      const rightNormalized = normalizeRight(
        right,
        locale,
        currentStrValue,
        decimalPlaces
      );
      const zeroCount = trailingZeroCount(rightNormalized);
      setTrailingZeros(zeroCount);
      const changedValue = parseNumber(
        left,
        rightNormalized,
        locale,
        currentStrValue,
        decimalPlaces,
        negative
      );

      return {
        changedValue,
        decimalEnd,
        zeroCount
      };
    },
    [decimalPlaces, locale]
  );

  const handleChange = useCallback(
    (evt, { value: eventStrValue, ...data }) => {
      const {
        nativeEvent: { inputType },
        target: { defaultValue: currentStrValue, selectionEnd, selectionStart }
      } = evt;

      // I am NOT a fan of this but semantic-ui react doesn't expose ref
      // forwarding and they appear to have removed what little 'reffy' support
      // that they had. So grab onto the element here.
      ref.current = evt.target;

      if (!eventStrValue) {
        setEndsWithDecimal(false);
        setSelectionAfter({});
        setTrailingZeros(0);
        onChange(evt, { ...data, value: "" });
        return;
      }

      const normalizedEventStrValue =
        eventStrValue !== "0-" ? eventStrValue : NEGATIVE_SIGN;
      let changedData = processChangedValue(
        normalizedEventStrValue,
        currentStrValue
      );
      if (changedData === null) {
        // An invalid character was entered. The field will revert to its previous
        // value but this code needs to update the position of the cursor after
        // that happens.
        setSelectionAfter({
          str: extractSelectableCharacters(
            currentStrValue,
            refKeyDownSelectionStart.current
          )
        });
        return;
      }

      const { decimalEnd, zeroCount } = changedData;
      let { changedValue } = changedData;
      let selectionOffset = 0;
      if (
        (inputType === INPUT_TYPE_BACKSPACE ||
          inputType === INPUT_TYPE_DELETE) &&
        selectionStart === selectionEnd
      ) {
        const { groups } = getLocaleData(locale);
        changedData = null;
        const removed = currentStrValue.charAt(selectionStart);
        if (inputType === INPUT_TYPE_BACKSPACE) {
          if (removed === groups) {
            const temp =
              normalizedEventStrValue.substring(0, selectionStart - 1) +
              normalizedEventStrValue.substring(selectionStart);
            changedData = processChangedValue(
              temp,
              currentStrValue,
              decimalEnd,
              zeroCount
            );
            selectionOffset = -1;
          }
        } /* istanbul ignore else */ else if (inputType === INPUT_TYPE_DELETE) {
          if (removed === groups) {
            const temp =
              normalizedEventStrValue.substring(0, selectionStart) +
              normalizedEventStrValue.substring(selectionStart + 1);

            changedData = processChangedValue(
              temp,
              currentStrValue,
              decimalEnd,
              zeroCount
            );
          }
        }

        if (changedData !== null) {
          ({ changedValue } = changedData);
        }
      }

      setSelectionAfter({
        str: extractSelectableCharacters(
          normalizedEventStrValue,
          selectionStart + selectionOffset
        )
      });

      onChange(evt, { ...data, value: changedValue });
    },
    [locale, onChange, processChangedValue]
  );

  const handleKeyDown = useCallback(({ target: { selectionStart } }) => {
    refKeyDownSelectionStart.current = selectionStart;
  }, []);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    // Position the cursor based on where it was using the pattern of
    // "selectable" characters captured while the user was typing.
    //
    // So if the | represents the cursor - backspace over the comma:
    // 12,|345
    // the result will be:
    // 1|,345
    //
    // Or delete the 3:
    // 12,|345
    // becomes:
    // 1,2|45
    //
    // Because the strings "1" and "12" respectively, were stored in state.

    // The following code will iterate over selectionAfter and compare it to the
    // current formatted string to determine where the cursor should go.
    const remaining = [...(selectionAfter.str || "")];
    let location = 0;
    let char;
    while (0 < remaining.length) {
      char = remaining.shift();

      // The current char value doesn't exist in selectionAfter so keep
      // iterating until the character is found.
      while (
        char !== stringValue.charAt(location) &&
        location < stringValue.length
      ) {
        location++;
      }

      // Move past the current character.
      location++;
    }

    ref.current.setSelectionRange(location, location);
  }, [selectionAfter, stringValue]);

  if (decimalPlaces < 0) {
    throw Error(
      `The decimalPlaces value of '${decimalPlaces}' is invalid because it is less than 0.`
    );
  }
  if (min > max) {
    throw Error(`min value of ${min} should be less than max value of ${max}.`);
  }
  const outOfRangeError = !focused ? checkOutOfRange(value, max, min) : null;
  return (
    <Form.Input
      {...props}
      error={outOfRangeError || error}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      type="text"
      value={stringValue}
    />
  );
}

const {
  as: propTypesAs,
  control: propTypesControl,
  type: propTypesType,
  value: propTypesValue,
  ...formInputPropTypes
} = Form.Input.propTypes;

NumberInput.propTypes = {
  ...formInputPropTypes,
  decimalPlaces: PropTypes.number,
  locale: PropTypes.oneOf(["en-US"]),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.oneOf([""]), PropTypes.number])
    .isRequired
};

export default NumberInput;

function allowedCharacters(locale) {
  const allowed = digitCharacters();
  const { decimal, groups } = getLocaleData(locale);
  allowed.push(decimal, groups);
  return allowed;
}

function digitCharacters() {
  const allowed = [];
  for (let i = 0; i < 10; i++) {
    allowed.push(`${i}`);
  }

  return allowed;
}

/**
 * Create a string from a source string that includes only digit characters,
 * negative sign, and the decimal character. For example "1,234.9" becomes
 * "1234.9".
 * @param {string} source
 * @param {number} end "-", digits, and decimal will be extracted up to, but not
 * including, this index.
 */
function extractSelectableCharacters(source, end, locale) {
  const { decimal } = getLocaleData(locale);
  const allowed = [...digitCharacters(), decimal, NEGATIVE_SIGN];
  let sub = "";
  let char;
  for (let i = 0; i < end; i++) {
    char = source.charAt(i);
    if (allowed.includes(char)) {
      sub += char;
    }
  }

  return sub;
}

function getLocaleData(locale) {
  const map = {
    "en-US": { decimal: ".", groups: "," }
  };

  return map[locale] || map[DEFAULT_LOCALE];
}

/**
 * Get information about a number that is stored as a string.
 * @param {string} source
 * @param {string} locale
 */
function getNumberData(source, locale) {
  const trimNumber = source.trim();
  if (trimNumber.length < 1) {
    return { left: null, right: null, valid: false };
  }

  const { decimal, groups } = getLocaleData(locale);
  const allowed = allowedCharacters(locale);
  let decimalCount = 0;
  let decimalEnd = false;
  let left = null;
  let negative = false;
  let right = null;
  let valid = true;
  let char;
  for (let i = trimNumber.length - 1; 0 <= i; i--) {
    char = trimNumber.charAt(i);

    if (i === 0 && char === NEGATIVE_SIGN) {
      negative = true;
      continue;
    }

    if (!allowed.includes(char)) {
      valid = false;
      continue;
    }

    if (char === decimal) {
      decimalCount++;
      decimalEnd = decimalEnd || i === trimNumber.length - 1;
      right = left;
      left = null;
      continue;
    }

    if (char === groups) {
      continue;
    }

    left = left || "";
    left = `${char}${left}`;
  }

  return {
    decimalEnd,
    left,
    negative,
    right,
    valid: valid && decimalCount < 2
  };
}

/**
 * Modify the string that represents the fractional part of the number for parsing.
 * @param {string} right The fractional part of the number after a change.
 * @param {string} locale The current locale.
 * @param {string} currentNumber The number currently in the field.
 * @param {number} decimalPlaces The allowed number of allowed places.
 * @returns {string | null}
 */
function normalizeRight(right, locale, currentNumber, decimalPlaces) {
  const { left: currentLeft, right: currentRight } = getNumberData(
    currentNumber,
    locale
  );

  let rightTruncated = null;
  // If the prop number value has fractional components keep them unless they
  // are removed by the user.
  if (decimalPlaces === 0) {
    if (currentRight !== null && right !== null) {
      rightTruncated =
        currentLeft !== null && currentLeft.startsWith(right)
          ? right
          : currentLeft;
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (
      currentRight !== null &&
      decimalPlaces < currentRight.length &&
      right.length <= currentRight.length
    ) {
      rightTruncated = right;
    } else {
      rightTruncated =
        right !== null
          ? right.length < decimalPlaces
            ? right
            : right.substring(0, decimalPlaces)
          : null;
    }
  }

  return rightTruncated;
}

/**
 * Convert a number to a localized string with separators.
 * @param {number} value
 * @param {string} locale
 * @param {boolean} endsWithDecimal
 * @param {number} trailingZeros
 * @returns
 */
function numberToString(value, locale, endsWithDecimal, trailingZeros) {
  if (typeof value !== "number") {
    return "";
  }

  const { decimal } = getLocaleData(locale);

  let left = new Intl.NumberFormat(locale, {
    maximumFractionDigits: 20
  }).format(value);
  left += endsWithDecimal ? decimal : "";
  left += 0 < trailingZeros && !left.includes(decimal) ? decimal : "";
  left += 0 < trailingZeros ? "0".repeat(trailingZeros) : "";
  return left;
}

/**
 * Create a number.
 * @param {string} left The integral part of the number after a change.
 * @param {string} right The fractional part of the number after a change.
 * @param {string} locale The current locale.
 * @param {string} currentNumber The number currently in the field.
 * @param {number} decimalPlaces The count of allowed places.
 * @returns {number | null}
 */
function parseNumber(
  left,
  right,
  locale,
  currentNumber,
  decimalPlaces,
  negative
) {
  const { right: currentRight } = getNumberData(currentNumber, locale);

  let parsedValue = null;
  if (decimalPlaces === 0) {
    if (currentRight !== null && right !== null) {
      // If the prop number value has fractional components keep them unless
      // they are removed by the user.
      parsedValue = parseFloat(
        `${left || 0}${getLocaleData(locale).decimal}${right}`
      );
    } else {
      // The string represents an integer number.
      parsedValue = parseInt(left || 0, 10);
    }
  } else {
    parsedValue = parseFloat(
      `${negative ? NEGATIVE_SIGN : ""}${left || "0"}${
        right !== null ? getLocaleData(locale).decimal : ""
      }${right !== null ? right : ""}`
    );
  }

  return parsedValue;
}

function trailingZeroCount(rightNormalized) {
  let zeroCount = 0;
  if (rightNormalized !== null) {
    for (let i = rightNormalized.length - 1; 0 <= i; i--) {
      if (rightNormalized[i] !== "0") {
        break;
      }

      zeroCount++;
    }
  }

  return zeroCount;
}

const checkOutOfRange = (numericValue, max, min) => {
  if (numericValue > max) {
    return `The value entered is greater than the maximum of '${max}'`;
  }
  if (numericValue < min) {
    return `The value entered is less than the minimum of '${min}'`;
  }
  return null;
};
