import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Segment, Table, Icon } from "semantic-ui-react";
import Constants from "../../services/Constants/strings";

const insertUuidIntoLink = (link, uuid) =>
  // link should include {uuid} in it such as:
  // 'mylink/{uuid}/view
  link.replace(/{uuid}/, uuid);

function reviewTable({ linkToEdit, linkToView, reviewData }) {
  const headerNames = [
    Constants.ENTITY_NAME,
    Constants.START_PERIOD,
    Constants.END_PERIOD,
    Constants.LOAN_REVIEW_OFFICER,
    Constants.REPORT_DATE,
    Constants.ORIGINAL_BORROWER_RISK_RATING,
    Constants.CURRENT_RISK_RATING,
    Constants.LOAN_REVIEW_RECC_RISK_RATING,
    Constants.INST_APPROVED_RISK_RATING,
    Constants.STATUS,
    ""
  ];

  const headers = headerNames.map(name => (
    <Table.HeaderCell key={name}>{name}</Table.HeaderCell>
  ));

  const rows = reviewData.map(row => (
    <Table.Row key={row.uuid}>
      <Table.Cell>{row.entityName}</Table.Cell>
      <Table.Cell>{row.startPeriod}</Table.Cell>
      <Table.Cell>{row.endPeriod}</Table.Cell>
      <Table.Cell>{row.analystName}</Table.Cell>
      <Table.Cell>{row.reportDate}</Table.Cell>
      <Table.Cell>{row.originalBorrowerRiskRating}</Table.Cell>
      <Table.Cell>{row.currentRiskRating}</Table.Cell>
      <Table.Cell>{row.loanReviewReccRiskRating}</Table.Cell>
      <Table.Cell>{row.instApprovedRiskRating}</Table.Cell>
      <Table.Cell>{row.status}</Table.Cell>
      <Table.Cell>
        <Link to={insertUuidIntoLink(linkToView, row.uuid)}>
          <Button
            basic
            circular
            floated="left"
            icon={<Icon name="eye" color="green" />}
            onClick={() => {}}
          />
        </Link>
        <Link to={insertUuidIntoLink(linkToEdit, row.uuid)}>
          <Button
            basic
            circular
            floated="right"
            icon={<Icon name="pencil" color="green" />}
          />
        </Link>
      </Table.Cell>
    </Table.Row>
  ));

  return (
    <Segment>
      <Table celled>
        <Table.Header>
          <Table.Row>{headers}</Table.Row>
        </Table.Header>
        <Table.Body>{rows}</Table.Body>
      </Table>
    </Segment>
  );
}

reviewTable.propTypes = {
  linkToEdit: PropTypes.string.isRequired,
  linkToView: PropTypes.string.isRequired,
  reviewData: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default reviewTable;
