import React from "react";
import { Button, Modal, Checkbox } from "semantic-ui-react";
import PropTypes from "prop-types";

class RemoveApplicationModal extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.state = {
      decline: false,
      withdraw: false
    };
  }

  // eslint-disable-next-line no-unused-vars
  onClick(event, data) {
    const { decline, withdraw } = this.state;
    if (data.label === "Decline Application") {
      this.setState({ decline: !decline, withdraw: false });
    }

    if (data.label === "Withdraw Application") {
      this.setState({ withdraw: !withdraw, decline: false });
    }
  }

  // eslint-disable-next-line no-unused-vars
  onConfirm() {
    this.props.onConfirm(this.state);
  }

  render() {
    const { open, onCancel } = this.props;

    const { decline, withdraw } = this.state;
    return (
      <Modal open={open} size="small">
        <Modal.Header>Remove Application</Modal.Header>
        <Modal.Content>
          <p>What do you want to do with this application?</p>
          <br />
          <br />
          <Checkbox
            data-testid="decline-application"
            checked={decline}
            label="Decline Application"
            onClick={(event, data) => this.onClick(event, data)}
            radio
          />
          <br />
          <br />
          <Checkbox
            data-testid="withdraw-application"
            checked={withdraw}
            label="Withdraw Application"
            onClick={(event, data) => this.onClick(event, data)}
            radio
          />
        </Modal.Content>
        <Modal.Actions>
          <Button id="cancelButton" onClick={onCancel} basic>
            Cancel
          </Button>
          <Button id="submitButton" onClick={this.onConfirm}>
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

RemoveApplicationModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default RemoveApplicationModal;
