import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Segment, Menu } from "semantic-ui-react";
import { connect } from "react-redux";
import Constants from "../../../../../../../../services/Constants/strings";
import AddDuplicateModal from "../../../AddDuplicateModal";
import Periods from "./components/Periods";
import Spreads from "./components/Spreads";
import Analysis from "./components/Analysis";
import { useModels } from "../../../../../../../../store/models/financial-spreads-models";
import { usePeriods } from "../../../../../../../../store/models/spreads";
import { addDocument } from "../../../../../../../../services/CMS";
import logger from "../../../../../../../../services/logger";

export function onError(response) {
  logger.error(response);
}

export function FinancialStatements({
  dispatch,
  analystUuid,
  showNewDialog,
  isDuplicate,
  entityData,
  initialSelectedTab = Constants.PERIODS,
  duplicateDocumentCallback
}) {
  const [selectedTab, setSelectedTab] = useState(initialSelectedTab);
  const [{ models: allProductionModels }] = useModels({
    institutionUuid: entityData.institution_uuid
  });
  const [, actions] = usePeriods({
    entityUuid: entityData.uuid,
    institutionUuid: entityData.institution_uuid
  });
  const history = useHistory();

  const handleClickAdd = period => {
    addDocument(
      dispatch,
      "",
      {
        ...period,
        institutionUuid: entityData.institution_uuid
      },
      entityData.uuid
    )
      .then(() =>
        actions.retrievePeriods({
          entityUuid: entityData.uuid,
          institutionUuid: entityData.institution_uuid
        })
      )
      .catch(onError);
  };

  const handleClickCancelDuplicate = () => {
    dispatch({
      type: "CMS_TABVIEW_BUTTON_DUPLICATE_CANCEL"
    });
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("doc_uuid")) {
      history.push(window.location.pathname);
    }
  };

  return (
    <Segment.Group>
      <Segment>
        <Menu pointing secondary color="black">
          <Menu.Item
            active={selectedTab === Constants.PERIODS}
            color="green"
            name={Constants.PERIODS}
            onClick={() => setSelectedTab(Constants.PERIODS)}
          />
          <Menu.Item
            active={selectedTab === Constants.SPREADS}
            color="green"
            name={Constants.SPREADS}
            onClick={() => setSelectedTab(Constants.SPREADS)}
          />
          <Menu.Item
            active={selectedTab === Constants.ANALYSIS}
            color="green"
            name={Constants.ANALYSIS}
            onClick={() => setSelectedTab(Constants.ANALYSIS)}
          />
        </Menu>
      </Segment>
      {selectedTab === Constants.PERIODS ? (
        <Periods
          entityData={entityData}
          analystUuid={analystUuid}
          onTabChange={setSelectedTab}
          allProductionModels={allProductionModels}
        />
      ) : null}
      {selectedTab === Constants.SPREADS ? (
        <Spreads entityData={entityData} onTabChange={setSelectedTab} />
      ) : null}
      {selectedTab === Constants.ANALYSIS ? (
        <Analysis entityData={entityData} />
      ) : null}
      <AddDuplicateModal
        openState={showNewDialog}
        allProductionModels={allProductionModels}
        close={handleClickCancelDuplicate}
        isDuplicate={isDuplicate}
        addDocumentCallback={handleClickAdd}
        duplicateDocumentCallback={duplicateDocumentCallback}
      />
    </Segment.Group>
  );
}

FinancialStatements.propTypes = {
  analystUuid: PropTypes.string.isRequired,
  showNewDialog: PropTypes.bool.isRequired,
  isDuplicate: PropTypes.bool.isRequired,
  entityData: PropTypes.shape({
    institution_uuid: PropTypes.string.isRequired,
    uuid: PropTypes.string
  }).isRequired,
  duplicateDocumentCallback: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  initialSelectedTab: PropTypes.string
};

const mapStateToProps = state => ({
  analystUuid: state.auth.userUuid,
  selectedPeriods: state.CreditManagementReducer.selectedPeriods,
  showNewDialog: state.CreditManagementReducer.showNewDialog,
  isDuplicate: state.CreditManagementReducer.isDuplicate,
  entityData: state.CreditManagementReducer.entityData
});

const ConnectedFinancialStatements =
  connect(mapStateToProps)(FinancialStatements);

export default ConnectedFinancialStatements;
