import { useEffect, useState } from "react";

import { relationshipTypes } from "../../../../../../services/Constants/relationshipTypes";
import { useLoanAppQuery } from "../../../../../../services/RTKQuery/loanApps";
import { useRelationshipsNoParticipationsCheckQuery } from "../../../../../../services/RTKQuery/relationships";
import NotificationToggle from "../../../../../../components/NotificationToggle";
import AssignUserDropdown from "../AssignUserDropdown";

function PhasesHeaderRedesign({ loanAppUuid }) {
  const [primaryBorrowerUuid, setPrimaryBorrowerUuid] = useState("");

  const { data: loanApp, isFetching: loanAppIsFetching } =
    useLoanAppQuery(loanAppUuid);
  const { data: primaryBorrower, isFetching: primaryBorrowerIsFetching } =
    useRelationshipsNoParticipationsCheckQuery({
      parent_uuid: loanAppUuid,
      primary_borrower: true,
      rel_type: relationshipTypes.BORROWER
    });

  useEffect(() => {
    if (primaryBorrowerIsFetching) {
      return;
    }

    setPrimaryBorrowerUuid(primaryBorrower?.data[0]?.child_uuid || "");
  }, [primaryBorrowerIsFetching, setPrimaryBorrowerUuid, primaryBorrower]);

  return (
    <div className="phases-header bg-white" style={{ margin: "-1rem" }}>
      <div className="ui view render">
        <div className="flex justify-end">
          <NotificationToggle
            uuid={loanAppUuid}
            message={"FOLLOW_APPLICATION"}
          />
        </div>
        <header className="mb-6 flex justify-between items-center">
          <div>
            <h1 className="mt-0 mb-4 primary-header font-semibold">
              {loanAppIsFetching ? "" : loanApp?.app_id}
            </h1>
            <a
              href={`/credit_management/entity/${primaryBorrowerUuid}`}
              target="_blank"
              className="underline text-slate-600"
            >
              <h2 className="secondary-header font-semibold">
                {loanAppIsFetching ? "" : loanApp?.borrower_name}
              </h2>
            </a>
          </div>
        </header>
        {loanApp && (
          <div className="flex justify-end">
            <AssignUserDropdown loanApp={loanApp} />
          </div>
        )}
      </div>
    </div>
  );
}

export default PhasesHeaderRedesign;
