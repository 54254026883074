import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import {
  DollarCell,
  PropertyTable
} from "../../../../../../../../../../components/Tables";
import { EntityDebts } from "../../../../../../../../../../services/ApiLib";
import { isNumber } from "../../../../../../../../../../services/ApiLib/services/Utilities";

function BalanceTable({ loan }) {
  const [loanWithExposure, setLoanWithExposure] = useState(loan);
  const balanceRows = useMemo(
    () => [
      {
        Header: "Current Contract Amount",
        accessor: "current_contract_amount",
        Cell: DollarCell
      },
      {
        Header: "Available Balance",
        accessor: "available_balance",
        Cell: DollarCell
      },
      {
        Header: `Total Exposure for Loan ${loan.bafs_account_number}`,
        accessor: "total_exposure",
        Cell: DollarCell
      },
      // TODO: [#6494] EM: Aggregate Debt
      // {
      //   Header: 'Aggregate Debt',
      //   accessor: 'aggregate_debt',
      //   Cell: DollarCell,
      // },
      {
        Header: "Original Balance",
        accessor: "original_amount",
        Cell: DollarCell
      }
    ],
    [loan.bafs_account_number]
  );

  const updateLoanWithExposure = useCallback(() => {
    EntityDebts.read({ entityUuid: loan.entity_uuid }).then(res => {
      if (!res) {
        return;
      }
      const { data } = res;
      if (!data) {
        return;
      }
      const body = { ...loan };

      // TODO: [#6494] EM: Aggregate Debt
      // const aggregateDebt = data.direct + data.indirect;
      // if (isNumber(aggregateDebt)) {
      //   body.aggregate_debt = aggregateDebt;
      // }
      body.aggregate_debt = "";

      const totalExposure = loan.current_balance + loan.available_balance;
      if (isNumber(totalExposure)) {
        body.total_exposure = totalExposure;
      }

      setLoanWithExposure({ ...body });
    });
  }, [loan]);

  useEffect(() => {
    updateLoanWithExposure();
  }, [updateLoanWithExposure]);

  return <PropertyTable columnsConfig={balanceRows} data={loanWithExposure} />;
}

BalanceTable.propTypes = {
  loan: PropTypes.shape().isRequired
};

export default BalanceTable;
