import React, { useState, useEffect, useCallback } from "react";
import { string } from "prop-types";
import {
  Container,
  Divider,
  Header,
  Message,
  Segment
} from "semantic-ui-react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { usePrevious } from "../../../../../../services/Hooks";
import { Loans } from "../../../../../../services/ApiLib";
import SnapshotsTable from "./components/snapshotsTable";
import PaginationControls, {
  paginationChanged
} from "../../../../../../components/Pagination";

function Snapshots({ loanUuid }) {
  const [snapshots, setSnapshots] = useState(false);
  const [isError, setError] = useState(null);
  const paginationName = "SnapshotPagination";
  const pagination = useSelector(
    state => state.PaginationReducer[paginationName]
  );
  const prevPagination = usePrevious(pagination);
  const [lastPage, setLastPage] = useState(true);

  const onSuccess = ({ data, metaData }) => {
    setSnapshots(data);
    setLastPage(metaData.last_page);
    setError(false);
  };

  const onError = () => {
    setError("Unable to set snapshots");
  };

  const getLoans = useCallback(() => {
    Loans.getSnapshots(
      { size: pagination.size, number: pagination.number, direction: "next" },
      loanUuid
    )
      .then(data => {
        onSuccess(data);
      })
      .catch(() => {
        onError();
      });
  }, [loanUuid, pagination]);

  useEffect(() => {
    if (paginationChanged(prevPagination, pagination)) {
      getLoans();
    }
  }, [getLoans, pagination, prevPagination]);

  useEffect(() => {
    if (snapshots && snapshots.length !== 0) {
      if (paginationChanged(prevPagination, pagination)) {
        getLoans();
      }
    }
  }, [getLoans, pagination, prevPagination, snapshots]);

  return (
    <div>
      <Header as="h1">Snapshots</Header>
      <Divider />

      <ResultsWrapper>
        <Container className="leftAlign" fluid textAlign="left">
          {isError && (
            <Segment>
              <Message
                negative
                content="An error occurred while fetching snapshots."
              />
            </Segment>
          )}
          {snapshots && (
            <Segment>
              <SnapshotsTable loans={snapshots} />
            </Segment>
          )}
        </Container>
        <Segment>
          <PaginationControls name={paginationName} lastPage={lastPage} />
        </Segment>
      </ResultsWrapper>
    </div>
  );
}

Snapshots.propTypes = {
  loanUuid: string.isRequired
};
const ResultsWrapper = styled.div`
  margin-top: 24px;
`;
export default Snapshots;
