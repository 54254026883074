import { uniqueId } from "lodash";
import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  JSXElementConstructor,
  ReactElement,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";
import type { ReactComponent } from "../../../../types/react";
import type { HandleClassNames } from "../../../../types/class-names";
import type { AllowedDropdownClassNames } from "../../../../types/modules";
import { useClickedOutside } from "../../../../dom/events/use-clicked-outside/use-clicked-outside";
import type { UseAnimationResult } from "../../../../dom/events/use-animation/use-animation";
import { useAnimation } from "../../../../dom/events/use-animation/use-animation";

/**
 * Display an animated dropdown list. The control that causes the list to show
 * and hide is provided through the `control` prop. The items displayed in the
 * list are the children of this component. See the Props interface for more
 * options.
 *
 * This abstract component is NOT meant to be used directly in an application,
 * it is meant to be composed as part of a higher order component (see other
 * components in the `dropdown` directory).
 */
export const Dropdown = forwardRef(
  (
    {
      children,
      classNames = "",
      control,
      disabled = false,
      handleControlClick: propsHandleControlClick,
      labelText,
      listHeader,
      listFooter,
      preventAutoClose = false,
      onChildSelect,
      onCollapsed,
      updateDropdownClassnames,
      variant = "floating",
      selectedKey,
      testid,
      fill = false
    }: DropdownProps,
    ref
  ) => {
    const uid = useMemo(() => uniqueId(), []);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const [menuElem, setMenuElem] = useState<HTMLDivElement | null>(null);

    const clickedOutsideDropdown = useClickedOutside(
      dropdownRef?.current as HTMLDivElement
    );
    const { startAnimateIn, startAnimateOut, ...animation } = useAnimation(
      menuElem
    ) as UseAnimationResult;
    const { show } = animation;

    // Allow parent to toggle the visibility of the Dropdown
    // Usage: someRef.current.toggleAnimation()
    useImperativeHandle(ref, () => ({
      toggleAnimation() {
        if (show) {
          startAnimateOut();
        } else {
          startAnimateIn();
        }
      }
    }));

    const menuClassNames = useMemo(
      () =>
        [
          getMenuClassNames(animation),
          variant === "floating" ? "floating" : ""
        ].join(" "),
      [animation, variant]
    );

    const handleControlClick = useCallback(
      evt => {
        const key = evt.key;
        if (
          !key ||
          key === "Enter" ||
          key === " " ||
          (key === "Escape" && show)
        ) {
          if (propsHandleControlClick && propsHandleControlClick(evt)) {
            // Prevent dropdown from closing when user selects text they
            // entered in the search input field
            return;
          }

          if (preventAutoClose && show && !clickedOutsideDropdown) {
            // Prevent dropdown from closing when user clicks a menu item
            return;
          }

          if (disabled) {
            return;
          }

          if (show) {
            startAnimateOut();
          } else {
            startAnimateIn();
          }
        }
      },
      [
        disabled,
        propsHandleControlClick,
        show,
        startAnimateIn,
        startAnimateOut,
        preventAutoClose,
        clickedOutsideDropdown
      ]
    );

    const handleChildKeyUp = useCallback<React.KeyboardEventHandler>(
      evt => {
        if (evt.key === "Enter" || evt.key === " ") {
          onChildSelect(evt);
        }
      },
      [onChildSelect]
    );

    const handleChildClick = useCallback<React.MouseEventHandler>(
      evt => {
        onChildSelect(evt);
      },
      [onChildSelect]
    );

    useEffect(() => {
      clickedOutsideDropdown && show && startAnimateOut && startAnimateOut();
    }, [clickedOutsideDropdown, show, startAnimateOut]);

    useEffect(() => {
      onCollapsed && onCollapsed(!show);
    }, [onCollapsed, show]);

    useEffect(() => {
      disabled && show && startAnimateOut && startAnimateOut();
    }, [disabled, show, startAnimateOut]);

    const initialDropdownClassNames: AllowedDropdownClassNames[] = [
      "ui",
      "dropdown",
      "menu"
    ];

    if (variant === "floating") {
      initialDropdownClassNames.push("floating");
    }

    if (disabled) {
      initialDropdownClassNames.push("disabled");
    }

    if (fill) {
      initialDropdownClassNames.push("fill");
    }

    const dropdownClassNames = getDropdownClassnames(
      animation,
      updateDropdownClassnames
        ? updateDropdownClassnames([
            ...initialDropdownClassNames,
            ...classNames.split(" ")
          ])
        : [...initialDropdownClassNames, ...classNames.split(" ")]
    );

    const handleControlContainerClick = () => {
      // Given preventAutoClose=true & menu is open
      // When a user clicks the control button (container)
      // Then close the Dropdown
      if (preventAutoClose && show) {
        startAnimateOut();
      }
    };

    return (
      <div
        aria-controls={`combobox-control-${uid}`}
        aria-expanded={show}
        {...(labelText ? { "aria-labelledby": labelText } : {})}
        data-testid={testid}
        className={dropdownClassNames.join(" ")}
        onClick={handleControlClick}
        onKeyUp={handleControlClick}
        ref={dropdownRef}
        role="combobox"
        tabIndex={0}
      >
        {preventAutoClose ? (
          <div
            onClick={handleControlContainerClick}
            data-testid="LIST_CONTROL"
            className="dropdown-list-control"
          >
            {control}
          </div>
        ) : (
          control
        )}
        <div
          className={menuClassNames}
          data-testid="LIST_CONTAINER"
          ref={elem => setMenuElem(elem)}
          style={{
            ...(animation.show
              ? { animationDelay: "0ms", display: "block" }
              : {}),
            overflow: "hidden"
          }}
        >
          {listHeader && (
            <div className="header" data-testid="LIST_HEADER_CONTAINER">
              {listHeader}
            </div>
          )}
          <ul
            className="scrolling menu scrollhint"
            id={`combobox-control-${uid}`}
            role="listbox"
          >
            {React.Children.map(children, child =>
              React.cloneElement(child, {
                className: `${
                  child.props?.className
                    ? `${child.props.className} item`
                    : "item"
                }${selectedKey === child.key ? " selected" : ""}`,
                onClick: handleChildClick,
                onKeyUp: handleChildKeyUp
              })
            )}
          </ul>

          {listFooter && (
            <div className="footer" data-testid="LIST_FOOTER_CONTAINER">
              {listFooter}
            </div>
          )}
        </div>
      </div>
    );
  }
);

export interface DropdownProps {
  /**
   * An array of `<li>` components that will be displayed in the list. Clicking
   * on a child will cause the list to close, if you want the list to remain
   * open you will need to handle the item's click event and use
   * `stopPropagation()` to prevent the event from bubbling through to the
   * control component.
   */
  children:
    | ReactElement<
        DetailedHTMLProps<HTMLAttributes<HTMLLIElement>, HTMLLIElement>
      >
    | ReactElement<
        DetailedHTMLProps<HTMLAttributes<HTMLLIElement>, HTMLLIElement>
      >[];
  classNames?: string;
  /**
   * A single component that is always visible and when clicked triggers display
   * of the list.
   */
  control: ReactElement<
    DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>,
    JSXElementConstructor<HTMLAttributes<HTMLElement>>
  >;
  testid?: string;
  /**
   * If true the list will not expand or collapse in response to button clicks.
   * If the list is displayed and the dropdown is disabled the list will
   * collapse.
   */
  disabled?: boolean;
  /**
   * When the `control` is clicked this function is invoked. Return true to
   * indicate the click has been handled and this component will take no further
   * action. Return false and this component will perform its default behavior
   * of collapsing the list.
   */
  handleControlClick?: (evt: React.MouseEvent) => boolean;
  labelText?: string;
  /**
   * One or more components that will be displayed above the list items.
   * - Clicking on components in the listHeader will cause the list to close, if
   *   you want the list to remain open you will need to handle the component's
   *   click event and use `stopPropagation()` to prevent the event from
   *   bubbling through to the control component.
   * - Default dropdown item styling will be applied if `item` is included in
   *   the listHeader's className prop.
   */
  listHeader?: ReactComponent;
  /**
   * One or more components that will be displayed below the list items.
   * - Clicking on components in the listFooter will cause the list to close, if
   *   you want the list to remain open you will need to handle the component's
   *   click event and use `stopPropagation()` to prevent the event from
   *   bubbling through to the control component.
   * - Default dropdown item styling will be applied if `item` is included in
   *   the listFooter's className prop.
   */
  listFooter?: ReactComponent;
  /**
   * When a child (an item in the list) is selected (mouse click or keyboard)
   * this callback function is invoked.
   */
  onChildSelect: (evt: React.SyntheticEvent) => void;
  /**
   * Some parents may need to know when the list is no longer visible (is
   * collapsed), this callback is invoked when that happens.
   */
  onCollapsed?: (collapsed: boolean) => void;
  /** Allow the parent to optionally manipulate the class names that are applied
   * to the dropdown. You probably don't need to use this. */
  updateDropdownClassnames?: HandleClassNames<string>;
  /**
   * Determines the appearance of the dropdown list; "floating" has a list
   * separated from the control while "select" has a list connected to the
   * control.
   */
  variant?: "floating" | "select" | "basic";
  /**
   * @deprecated The owner of this component knows which item was selected and
   * it is up to them to set the appropriate classes on the item. This is an
   * abstract class, concrete classes are responsible for enforcing limits like
   * this.
   *
   * Deprecated - this props shows if a value was previous selected, if so the
   * component should use the class "selected" to highlight the item
   */
  selectedKey?: string;
  /**
   * When fill is true, the dropdown is going to take all available width
   */
  fill?: boolean;
  /**
   * Keep Dropdown open after clicking a list item
   */
  preventAutoClose?: boolean;
}

function getDropdownClassnames(
  {
    animateIn,
    animateOut,
    show
  }: Pick<UseAnimationResult, "animateIn" | "animateOut" | "show">,
  classNames: string[] = []
): string[] {
  const names = [];
  if (show) {
    names.push("open");
  }

  if (show && animateIn) {
    names.push("active", "visible");
  }

  if (show && animateOut) {
    names.push("visible");
  }

  if (show && !animateIn && !animateOut) {
    names.push("active", "visible");
  }

  return [...classNames, ...names];
}

function getMenuClassNames({
  animateIn,
  animateOut,
  show
}: Pick<UseAnimationResult, "animateIn" | "animateOut" | "show">): string {
  const names = ["menu", "transition"];
  if (animateIn) {
    names.push("animating", "slide", "down", "in", "active");
  }

  if (animateOut) {
    names.push("animating", "slide", "down", "out");
  }

  if (show && !animateIn && !animateOut) {
    names.push("visible");
  }

  if (!show) {
    names.push("hidden");
  }

  return names.join(" ");
}
