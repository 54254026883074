import React, { useState } from "react";
import PropTypes from "prop-types";

import { Button } from "semantic-ui-react";

import AdditionalFeesTable from "./components/AdditionalFees";
import ChargeFeeEditModal from "./components/ChargeFeeEditModal";
import ChargeFeeLayout from "./components/ChargeFeeLayout";
import ChargeFeeTable from "./components/ChargeFeeTable";
import { getUserGroup } from "../../../../../../../../services/Auth";

function ChargeFee({ loan, onUpdateLoan, isMonthEnd }) {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <ChargeFeeLayout>
      <ChargeFeeTable loan={loan} />
      <>
        {(getUserGroup() === "Admin" || getUserGroup() === "BAFS") &&
          !isMonthEnd && (
            <Button
              circular
              basic
              icon="edit"
              onClick={() => setModalOpen(true)}
              data-testid="edit-button"
            />
          )}
        <ChargeFeeEditModal
          loan={loan}
          onSave={changes => onUpdateLoan({ ...changes, uuid: loan.uuid })}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      </>
      {getUserGroup() === "Admin" || getUserGroup() === "BAFS" ? (
        <AdditionalFeesTable
          institutionUuid={loan.institution_uuid}
          loanUuid={loan.uuid}
          interestRate={loan.interest_rate}
          currentBalance={loan.current_balance}
        />
      ) : undefined}
    </ChargeFeeLayout>
  );
}

ChargeFee.propTypes = {
  loan: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    institution_uuid: PropTypes.string.isRequired,
    interest_rate: PropTypes.number,
    current_balance: PropTypes.number
  }),
  onUpdateLoan: PropTypes.func,
  isMonthEnd: PropTypes.bool
};

export default ChargeFee;
