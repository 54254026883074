import { createActions } from "redux-actions";

export const initialState = {
  effective: {
    filter: "",
    historyData: [],
    lastPage: true,
    dateType: "",
    startDate: "",
    endDate: "",
    sortField: "effective_date",
    sortDirection: -1
  },
  detailed: {
    filter: "",
    historyData: [],
    lastPage: true,
    dateType: "",
    startDate: "",
    endDate: "",
    sortField: "effective_date",
    sortDirection: -1,
    showExtraDetailed: false
  },
  modal: {
    filter: "",
    historyData: [],
    lastPage: true,
    dateType: "",
    startDate: "",
    endDate: "",
    sortField: "effective_date",
    sortDirection: -1
  }
};

const createPrefixedHistoryActions = prefix =>
  createActions(
    {},
    "TRANSACTION_HISTORY_RESET",
    "TRANSACTION_HISTORY_POPULATE",
    "TRANSACTION_HISTORY_FILTER",
    "TRANSACTION_HISTORY_ERROR",
    "TRANSACTION_HISTORY_END_DATE",
    "TRANSACTION_HISTORY_START_DATE",
    "TRANSACTION_HISTORY_TYPE_DATE",
    "TRANSACTION_HISTORY_SORT_FIELD",
    "TRANSACTION_HISTORY_SORT_DIRECTION",
    "TRANSACTION_HISTORY_EXTRA_DETAILED",
    {
      prefix: `LOAN_MANAGEMENT/${prefix.toUpperCase()}`,
      namespace: "_"
    }
  );

const tableTypes = ["effective", "detailed", "modal"];
export const actionCreators = {
  ...createActions(
    {},
    "TRANSACTION_HISTORY_POPULATE",
    "TRANSACTION_HISTORY_ERROR",
    "TRANSACTION_HISTORY_RESET",
    { prefix: "LOAN_MANAGEMENT" }
  ),
  ...tableTypes.reduce(
    (types, tableType) => ({
      ...types,
      [tableType]: createPrefixedHistoryActions(tableType)
    }),
    {}
  )
};

export const sortTransactions = (transactions, sortField, sortDirection) => [
  ...transactions.sort((a, b) => {
    if (a[sortField] > b[sortField]) {
      return sortDirection;
    }
    if (a[sortField] < b[sortField]) {
      return -1 * sortDirection;
    }
    return 0;
  })
];

// eslint-disable-next-line default-param-last
export default function Reducer(state = initialState, { type, payload }) {
  switch (type) {
    case "LOAN_MANAGEMENT/TRANSACTION_HISTORY_RESET":
      return initialState;
    case "LOAN_MANAGEMENT/TRANSACTION_HISTORY_POPULATE":
      return {
        ...state,
        effective: {
          ...state.effective,
          historyData: payload.data,
          lastPage: payload.metaData.last_page
        },
        detailed: {
          ...state.detailed,
          historyData: payload.data,
          lastPage: payload.metaData.last_page
        }
      };
    case "LOAN_MANAGEMENT/MODAL_TRANSACTION_HISTORY_POPULATE":
      return {
        ...state,
        modal: {
          ...state.modal,
          historyData: sortTransactions(
            payload.data,
            state.modal.sortField,
            state.modal.sortDirection
          )
        }
      };
    case "LOAN_MANAGEMENT/MODAL_TRANSACTION_HISTORY_SORT_FIELD":
      return {
        ...state,
        modal: {
          ...state.modal,
          sortField: payload,
          sortDirection: -1,
          historyData: sortTransactions(state.modal.historyData, payload, -1)
        }
      };

    case "LOAN_MANAGEMENT/MODAL_TRANSACTION_HISTORY_SORT_DIRECTION":
      return {
        ...state,
        modal: {
          ...state.modal,
          sortDirection: payload,
          historyData: sortTransactions(
            state.modal.historyData,
            state.modal.sortField,
            payload
          )
        }
      };

    case "LOAN_MANAGEMENT/TRANSACTION_HISTORY_ERROR":
    default: // noop
  }

  for (let i = 0; i < tableTypes.length; i += 1) {
    const tableType = tableTypes[i];
    const tableTypeUpper = tableType.toUpperCase();

    switch (type) {
      case `LOAN_MANAGEMENT/${tableTypeUpper}_TRANSACTION_HISTORY_RESET`:
        return { ...state, [tableType]: initialState[tableType] };

      case `LOAN_MANAGEMENT/${tableTypeUpper}_TRANSACTION_HISTORY_POPULATE`:
        return {
          ...state,
          [tableType]: {
            ...state[tableType],
            historyData: payload.data,
            lastPage: payload.metaData.last_page
          }
        };

      case `LOAN_MANAGEMENT/${tableTypeUpper}_TRANSACTION_HISTORY_FILTER`:
        return {
          ...state,
          [tableType]: { ...state[tableType], filter: payload }
        };

      case `LOAN_MANAGEMENT/${tableTypeUpper}_TRANSACTION_HISTORY_TYPE_DATE`:
        return {
          ...state,
          [tableType]: { ...state[tableType], dateType: payload }
        };

      case `LOAN_MANAGEMENT/${tableTypeUpper}_TRANSACTION_HISTORY_START_DATE`:
        return {
          ...state,
          [tableType]: { ...state[tableType], startDate: payload }
        };

      case `LOAN_MANAGEMENT/${tableTypeUpper}_TRANSACTION_HISTORY_END_DATE`:
        return {
          ...state,
          [tableType]: { ...state[tableType], endDate: payload }
        };

      case `LOAN_MANAGEMENT/${tableTypeUpper}_TRANSACTION_HISTORY_SORT_FIELD`:
        return {
          ...state,
          [tableType]: {
            ...state[tableType],
            sortField: payload,
            sortDirection: -1
          }
        };

      case `LOAN_MANAGEMENT/${tableTypeUpper}_TRANSACTION_HISTORY_SORT_DIRECTION`:
        return {
          ...state,
          [tableType]: { ...state[tableType], sortDirection: payload }
        };

      case `LOAN_MANAGEMENT/${tableTypeUpper}_TRANSACTION_HISTORY_EXTRA_DETAILED`:
        return {
          ...state,
          [tableType]: { ...state[tableType], showExtraDetailed: payload }
        };

      // TODO: handle error
      case `LOAN_MANAGEMENT/${tableTypeUpper}_TRANSACTION_HISTORY_ERROR`:
      default:
    }
  }

  return state;
}
