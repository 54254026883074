import TypeCheck from "typecheck-extended";
import mime from "mime-types";
import ApiEndpoints from "../../../ApiEndpoints";
import { asyncFetchService } from "../../../FetchService";
import { FormatUrlV2 } from "../../../FormatUrl";
import { ConcatQueryParams } from "../Utilities";
import { getToken } from "../../../Auth";

async function asyncRead(uuid) {
  TypeCheck(uuid, "string", false);
  let url = ApiEndpoints.baseUri + ApiEndpoints.cmsEntity;
  if (uuid) {
    url = FormatUrlV2(ApiEndpoints.cmsEntity, { uuid });
  }
  return asyncFetchService("GET", url);
}
async function readSingle(memoUuid) {
  TypeCheck(memoUuid, "string");
  const url = FormatUrlV2(ApiEndpoints.creditMemoGetSingle, { memoUuid });
  const resp = await asyncFetchService("GET", url);
  return resp;
}

async function readSpreads(uuid) {
  TypeCheck(uuid, "string", false);
  let url = ApiEndpoints.baseUri + ApiEndpoints.cmsSpreadsModelv2;
  if (uuid) {
    url = FormatUrlV2(ApiEndpoints.cmsSpreadsModelv2, { uuid });
  }
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

async function readSpreadsPeriod(entityUuid) {
  TypeCheck(entityUuid, "string", true);
  const url = FormatUrlV2(ApiEndpoints.spreadsPeriodDatav2, { entityUuid });
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

async function updateFinancialAnalysis(uuid, body) {
  TypeCheck(uuid, "string", true);
  TypeCheck(body, "object", false);
  const url = FormatUrlV2(ApiEndpoints.financialAnalysisPut, { uuid });
  const rsp = await asyncFetchService("PUT", url, body);
  return rsp;
}

async function readFinancialAnalysis(uuid) {
  TypeCheck(uuid, "string", true);
  const url = FormatUrlV2(ApiEndpoints.financialAnalysisPut, { uuid });
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

async function readCreditNarrativeByInstitution(institutionUuid) {
  const url = FormatUrlV2(ApiEndpoints.cmsCreditNarrativeByInstitution, {
    institutionUuid
  });
  const queryParams = ConcatQueryParams(
    { pageNumber: 1, pageSize: 999 },
    [],
    true
  );

  const rsp = await asyncFetchService("GET", url + queryParams);
  return rsp;
}

async function createObjectsV2Placeholder(filename, institutionUuid) {
  const form = new FormData();
  form.append("fileData", new Blob(), {
    filename,
    contentType: mime.lookup(filename)
  });
  form.append("fileName", filename);
  form.append("institutionUuid", institutionUuid);
  const url = FormatUrlV2(ApiEndpoints.objectUploadsv2);
  const uploadDocResponse = await fetch(url, {
    method: "POST",
    body: form,
    headers: {
      Authorization: getToken()
    }
  });
  if (!uploadDocResponse.ok) {
    return uploadDocResponse;
  }
  const resp = await uploadDocResponse.json();
  return resp;
}

async function createCreditMemo(query) {
  const queryParams = ConcatQueryParams(query);
  const url = FormatUrlV2(ApiEndpoints.creditMemo + queryParams);
  const resp = await asyncFetchService("POST", url);
  return resp;
}

export default {
  readFinancialAnalysis,
  readSpreads,
  readSpreadsPeriod,
  updateFinancialAnalysis,
  asyncRead,
  readSingle,
  readCreditNarrativeByInstitution,
  createObjectsV2Placeholder,
  createCreditMemo
};
