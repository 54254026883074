import TypeCheck from "typecheck-extended";
import logger from "../../../../../../services/logger";

function errorToState(error, callbackData) {
  TypeCheck(callbackData.dispatch, "function");
  TypeCheck(callbackData.losScene, "enum", true, ["stepper", "phases"]);

  const { dispatch, losScene } = callbackData;
  const stepperDispatchType = "LOR_SET_ERROR";
  const phasesDispatchType = "TODO_SET_THIS_UP"; // TODO: This is needed for this to work w/ Phases
  const type =
    losScene === "stepper" ? stepperDispatchType : phasesDispatchType;

  let errorMsg = error;
  if (typeof error !== "string") {
    logger.error(error);
    errorMsg = "An Error Occurred.";
  }

  dispatch({
    errorMessage: errorMsg,
    type
  });
}

export default errorToState;
