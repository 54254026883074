import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Button, Divider, Grid, Header, Icon } from "semantic-ui-react";

import entityDataProps from "../../../services/PropTypes/entityData";
import { prettyDate } from "../../../services/DateTime";

import LightHeader from "../../LightHeader";
import { EntityModal } from "../../Modals";
import { BorderedContainer } from "../../CustomUIElements";

function IndividualDetailsView({
  entityData,
  formatPhone,
  showDetailsModal,
  loanOfficers,
  showModal,
  onCloseModal,
  onSuccessSave
}) {
  const loanOfficer = useMemo(
    () =>
      loanOfficers.find(
        officer => officer.uuid === entityData.loan_officer_uuid
      ) || { first_name: "", last_name: "" },
    [loanOfficers, entityData.loan_officer_uuid]
  );
  return (
    <BorderedContainer fluid>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Header as="h1" size="large">
              Entity Information
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Button
              basic
              circular
              icon
              floated="right"
              onClick={showModal}
              name="editModalOpen"
            >
              <Icon name="pencil alternate" color="green" />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid columns={4}>
        <Grid.Column>
          <Grid.Row>
            <LightHeader>Entity Type</LightHeader>
            <b>{entityData.entity_type}</b>
            <br />
          </Grid.Row>
          <Grid.Row>
            <LightHeader>Name</LightHeader>
            <b>{`${entityData.indiv_title || ""} ${
              entityData.first_name || ""
            } ${entityData.middle_name || ""} ${entityData.last_name || ""} ${
              entityData.indiv_suffix || ""
            }`}</b>
            <br />
          </Grid.Row>
          <Grid.Row>
            <LightHeader>DOB</LightHeader>
            <b>{prettyDate(entityData.dob)}</b>
            <br />
          </Grid.Row>
          <Grid.Row>
            <LightHeader>Gender</LightHeader>
            <b>{entityData.gender}</b>
            <br />
          </Grid.Row>
          <Grid.Row>
            <LightHeader>Citizenship</LightHeader>
            <b>
              {entityData.citizenship_state
                ? `${entityData.citizenship_state},`
                : ""}
              {`${entityData.citizenship_country || ""}`}
            </b>
            <br />
          </Grid.Row>
        </Grid.Column>
        <Grid.Column>
          <Grid.Row>
            <LightHeader>SSN</LightHeader>
            <b>{entityData.tin}</b>
            <br />
          </Grid.Row>
          <Grid.Row>
            <LightHeader>Education</LightHeader>
            <b>{entityData.education}</b>
            <br />
          </Grid.Row>
          <Grid.Row>
            <LightHeader>Occupation</LightHeader>
            <b>{entityData.occupation}</b>
            <br />
          </Grid.Row>
          <Grid.Row>
            <LightHeader>Marital Status</LightHeader>
            <b>{entityData.marital_status}</b>
            <br />
          </Grid.Row>
        </Grid.Column>
        <Grid.Column>
          <Grid.Row>
            <LightHeader>Employment Status</LightHeader>
            <b>{entityData.employment_status}</b>
            <br />
          </Grid.Row>
          <Grid.Row>
            <LightHeader>Employer</LightHeader>
            <b>{entityData.employer}</b>
            <br />
          </Grid.Row>
          <Grid.Row>
            <LightHeader>Employment Date</LightHeader>
            <b>{prettyDate(entityData.employment_date)}</b>
            <br />
          </Grid.Row>
          <Grid.Row>
            <LightHeader>Job Title</LightHeader>
            <b>{entityData.job_title || ""}</b>
            <br />
          </Grid.Row>
        </Grid.Column>
        <Grid.Column>
          <Grid.Row>
            <LightHeader>Client Since</LightHeader>
            <b>{prettyDate(entityData.member_since)}</b>
            <br />
          </Grid.Row>
          <Grid.Row>
            <LightHeader>Operating Status</LightHeader>
            <b>{entityData.operating_status || ""}</b>
            <br />
          </Grid.Row>
          <Grid.Row>
            <LightHeader>Loan Officer</LightHeader>
            <b>{`${loanOfficer.first_name} ${loanOfficer.last_name}`}</b>
            <br />
          </Grid.Row>
        </Grid.Column>
      </Grid>
      <Divider />
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header as="h1" size="large">
              Contact Information
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid columns={4}>
        <Grid.Column>
          <LightHeader>Physical Address:</LightHeader>
          <b>{entityData.phy_address_line1}</b>
          <br />
          {entityData.phy_address_line2 ? (
            <b>
              <span>
                {entityData.phy_address_line2}
                <br />
              </span>
            </b>
          ) : (
            ""
          )}
          <span>
            <b>
              {entityData.phy_address_city
                ? `${entityData.phy_address_city}, `
                : ""}
            </b>
            <b>{entityData.phy_address_state}</b>
            <br />
          </span>
          <b>{entityData.phy_address_zip}</b>
          <br />
          <b>{entityData.phy_address_country}</b>
        </Grid.Column>
        <Grid.Column>
          <LightHeader>Mailing Address</LightHeader>
          <b>{entityData.mail_address_line1}</b>
          <br />
          {entityData.mail_address_line2 ? (
            <span>
              <b>{entityData.mail_address_line2}</b>
              <br />
            </span>
          ) : (
            ""
          )}
          <span>
            <b>
              {entityData.mail_address_city
                ? `${entityData.mail_address_city}, `
                : ""}
            </b>
            <b>{entityData.mail_address_state}</b>
            <br />
          </span>
          <b>{entityData.mail_address_zip}</b>
          <br />
          <b>{entityData.mail_address_country}</b>
          <br />
        </Grid.Column>
        <Grid.Column>
          <Grid.Row>
            <LightHeader>Phone Number</LightHeader>
            <b>{formatPhone(entityData.home_phone)}</b>
            <br />
          </Grid.Row>
          <Grid.Row>
            <LightHeader>Mobile Phone Number</LightHeader>
            <b>{formatPhone(entityData.mobile_phone_no)}</b>
            <br />
          </Grid.Row>
          <Grid.Row>
            <LightHeader>Business Phone</LightHeader>
            <b>{formatPhone(entityData.phone_no)}</b>
            <br />
          </Grid.Row>
        </Grid.Column>
        <Grid.Column>
          <Grid.Row>
            <LightHeader>Personal Email</LightHeader>
            <b>{entityData.personal_email}</b>
            <br />
          </Grid.Row>
          <Grid.Row>
            <LightHeader>Work Email</LightHeader>
            <b>{entityData.email}</b>
            <br />
          </Grid.Row>
        </Grid.Column>
      </Grid>
      <Divider />
      <Grid>
        <Grid.Row>
          <Grid.Column style={{ marginTop: "6px" }}>
            <Header as="h1" size="large">
              Credit Information
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid columns={3}>
        <Grid.Column>
          <Grid.Row>
            <LightHeader>Borrower Rating</LightHeader>
            <b>{entityData.risk_rating}</b>
            <br />
          </Grid.Row>
          <Grid.Row>
            <LightHeader>Borrower Rating Date</LightHeader>
            <b>{prettyDate(entityData.risk_rating_date)}</b>
            <br />
          </Grid.Row>
        </Grid.Column>
        <Grid.Column>
          <Grid.Row>
            <LightHeader>Loan Review Type</LightHeader>
            <b>{entityData.loan_review_type}</b>
            <br />
          </Grid.Row>
          <Grid.Row>
            <LightHeader>Previous Review Date</LightHeader>
            <b>{prettyDate(entityData.previous_review_date)}</b>
            <br />
          </Grid.Row>
          <Grid.Row>
            <LightHeader>Next Review Date</LightHeader>
            <b>{prettyDate(entityData.next_review_date)}</b>
            <br />
          </Grid.Row>
        </Grid.Column>
        <Grid.Column>
          <Grid.Row>
            <LightHeader>Credit Bureau</LightHeader>
            <b>{entityData.credit_bureau}</b>
            <br />
          </Grid.Row>
          <Grid.Row>
            <LightHeader>Credit Score</LightHeader>
            <b>
              {entityData.credit_bureau === "Experian"
                ? entityData.credit_score_experian
                : ""}
            </b>
            <b>
              {entityData.credit_bureau === "Transunion"
                ? entityData.credit_score_transunion
                : ""}
            </b>
            <b>
              {entityData.credit_bureau === "Equifax"
                ? entityData.credit_score_equifax
                : ""}
            </b>
            <br />
          </Grid.Row>
          <Grid.Row>
            <LightHeader>Date of Credit Score</LightHeader>
            <b>
              {entityData.credit_bureau === "Experian"
                ? prettyDate(entityData.credit_score_experian_date)
                : ""}
            </b>
            <b>
              {entityData.credit_bureau === "Transunion"
                ? prettyDate(entityData.credit_score_transunion_date)
                : ""}
            </b>
            <b>
              {entityData.credit_bureau === "Equifax"
                ? prettyDate(entityData.credit_score_equifax_date)
                : ""}
            </b>
            <br />
          </Grid.Row>
        </Grid.Column>
      </Grid>
      {showDetailsModal ? (
        <EntityModal
          entityUuid={entityData.uuid}
          onCloseModal={onCloseModal}
          onSuccess={onSuccessSave}
          loanOfficers={loanOfficers}
          open={showDetailsModal}
        />
      ) : (
        ""
      )}
    </BorderedContainer>
  );
}

IndividualDetailsView.propTypes = {
  entityData: entityDataProps.isRequired,
  formatPhone: PropTypes.func.isRequired,
  showDetailsModal: PropTypes.bool.isRequired,
  loanOfficers: PropTypes.arrayOf(PropTypes.object).isRequired,
  showModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSuccessSave: PropTypes.func.isRequired
};

export default IndividualDetailsView;
