import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  DateCell,
  PropertyTable
} from "../../../../../../../../../../components/Tables";

function FutureDatesTable({ loan }) {
  const rows = useMemo(
    () => [
      {
        Header: "Next Review Date",
        accessor: "next_review_date",
        primary: true,
        Cell: DateCell
      },
      {
        Header: "Last Extension Date",
        accessor: "last_extension_date",
        Cell: DateCell
      },
      {
        Header: "Last Renewal Date",
        accessor: "last_renewal_date",
        Cell: DateCell
      },
      {
        Header: "Last Advance Date",
        accessor: "last_advance_date",
        Cell: DateCell
      },
      {
        Header: "Paid Off Date",
        accessor: "closed_date",
        Cell: DateCell
      }
    ],
    []
  );
  return <PropertyTable columnsConfig={rows} data={{ ...loan }} />;
}

FutureDatesTable.propTypes = {
  loan: PropTypes.shape({
    next_review_date: PropTypes.string,
    last_renewal_date: PropTypes.string,
    last_advance_date: PropTypes.string,
    last_extension_date: PropTypes.string,
    closed_date: PropTypes.string
  }).isRequired
};

export default FutureDatesTable;
