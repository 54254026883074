/* eslint-disable camelcase */
import createAPIService from "../../../FetchService/createAPIService";
import APIEndpoints from "../../../ApiEndpoints";
import mockFees from "./mock-logs";

const accountHistoryLogsService = createAPIService({
  baseUrl: APIEndpoints.baseUri,
  create: APIEndpoints.accountHistoryLogs,
  retrieve: [APIEndpoints.accountHistoryLogs, APIEndpoints.accountHistoryLog],
  update: APIEndpoints.accountHistoryLog,
  delete: APIEndpoints.accountHistoryLog,
  options: { pagination: true }
});

/** TODO:
 * The Account History Logs Service does not exist as far as I know,
 * so it is mocked below until there is a working endpoint to hit
 */
mockFees(accountHistoryLogsService);

export default accountHistoryLogsService;
