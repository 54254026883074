import { FormattedDate } from "react-intl";
import { Accordion } from "@bafsllc/ui-shared";
import NumberFormat from "react-number-format";

const AccountSubDetails = ({ title, value }) => (
  <div className="account-info">
    <div className="font-semibold text-lg">{title}</div>
    <div data-testid="account-info-value">{value || <>&mdash;</>}</div>
  </div>
);

export function AccountDetails({ loanApp, title = "Account Details" }) {
  return (
    <Accordion title={title} className="account-details">
      <div className="flex justify-between">
        <AccountSubDetails
          title="Total Proposed Debt"
          value={
            loanApp.total_amount_requested ? (
              <NumberFormat
                displayType="text"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                value={loanApp.total_amount_requested}
                prefix="$"
              />
            ) : null
          }
        />
        <AccountSubDetails title="Submitted By" value={loanApp.submitted_by} />
        <AccountSubDetails title="Scope of Analysis" value={loanApp.scope} />
        <AccountSubDetails title="App ID" value={loanApp.app_id} />
        <AccountSubDetails
          title="Expected Closing Date"
          value={
            loanApp.due_date ? <FormattedDate value={loanApp.due_date} /> : null
          }
        />
        <AccountSubDetails
          title="Date Submitted"
          value={
            loanApp.date_submitted ? (
              <FormattedDate value={loanApp.date_submitted} />
            ) : null
          }
        />
        <AccountSubDetails
          title="Date Completed"
          value={
            loanApp.completed_date ? (
              <FormattedDate value={loanApp.completed_date} />
            ) : null
          }
        />
        <AccountSubDetails title="Completed By" value={loanApp.completed_by} />
      </div>
    </Accordion>
  );
}
