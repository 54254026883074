import React from "react";
import PropTypes from "prop-types";
import { Table, Icon, Button } from "semantic-ui-react";

function PersonalPropertyTable({ data, onEdit }) {
  return (
    <>
      <div style={{ textAlign: "right" }}>
        <Button
          basic
          circular
          data-testid="edit-asset-row-btn"
          icon={<Icon name="pencil" color="green" />}
          onClick={() => onEdit(true)}
        />
      </div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Serial Number</Table.HeaderCell>
            <Table.HeaderCell>Vehicle Model</Table.HeaderCell>
            <Table.HeaderCell>Vehicle Make</Table.HeaderCell>
            <Table.HeaderCell>Vehicle Year</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row key={data.uuid}>
            <Table.Cell>{data.serial_no}</Table.Cell>
            <Table.Cell>{data.vehicle_model}</Table.Cell>
            <Table.Cell>{data.vehicle_make}</Table.Cell>
            <Table.Cell>{data.vehicle_year}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}

PersonalPropertyTable.propTypes = {
  data: PropTypes.shape().isRequired,
  onEdit: PropTypes.func
};

export default PersonalPropertyTable;
