import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Modal } from "semantic-ui-react";
import logger from "../../../../services/logger";

export const onError = rsp => logger.error(rsp);

class WarningModal extends Component {
  constructor(props) {
    super(props);

    this.onCheckbox = this.onCheckbox.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      checked: false
    };
  }

  onCheckbox() {
    const { checked } = this.state;
    this.setState({ checked: !checked });
  }

  onSubmit() {
    const { checked } = this.state;
    const { parentModalSubmit } = this.props;
    parentModalSubmit(checked);
  }

  render() {
    const { checked } = this.state;
    const { onCheckbox, onSubmit } = this;
    const { parentModalToggle, parentModalClose } = this.props;
    return (
      <Modal open={parentModalToggle}>
        <Modal.Header>Warning</Modal.Header>
        <Modal.Content>
          <b>
            Changing borrowers, owners, guarantors, credit request details,
            and/or collateral could delay the processing of this application.
            Would you like to proceed?
          </b>
          <br />
          <br />
          <Checkbox
            checked={checked}
            label="Do not warn me again about changes which could delay application processing for this credit request."
            onClick={onCheckbox}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button id="cancelButton" onClick={parentModalClose} basic>
            Cancel
          </Button>
          <Button id="submitButton" onClick={onSubmit}>
            Continue
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

WarningModal.propTypes = {
  parentModalClose: PropTypes.func.isRequired,
  parentModalToggle: PropTypes.bool.isRequired,
  parentModalSubmit: PropTypes.func.isRequired
};

export default WarningModal;
