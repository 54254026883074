import { Container, Input, Modal } from "semantic-ui-react";
import PropTypes from "prop-types";

const initialScreen =
  "Please type your email address below.\n If this email address is associated with an active Blast account,\n password reset instructions will be emailed to you.";

function PasswordResetModal({
  email,
  msg,
  onChangeEmail,
  onClose,
  onSave,
  open
}) {
  return (
    <Modal open={open} size="tiny" role="dialog">
      <Modal.Header>Reset Password</Modal.Header>
      <Modal.Content>
        <Container>{msg || initialScreen}</Container>

        {!msg && (
          <div className="mt-4">
            <Input
              fluid
              onChange={onChangeEmail}
              placeholder="Email Address"
              value={email}
            />
          </div>
        )}
      </Modal.Content>

      <Modal.Actions>
        <button
          id="cancelButton"
          className="ui button basic"
          onClick={onClose}
          role="button"
        >
          Close
        </button>

        {!msg && (
          <button
            id="submitButton"
            className="ui button"
            onClick={onSave}
            role="button"
          >
            Submit
          </button>
        )}
      </Modal.Actions>
    </Modal>
  );
}

PasswordResetModal.propTypes = {
  email: PropTypes.string.isRequired,
  msg: PropTypes.string.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default PasswordResetModal;
