/* eslint-disable no-case-declarations */
import _ from "lodash";

export const initialState = {
  creditRequests: [],
  creditRequestsObject: {},
  creditRequestMemos: {},
  lookupData: {}
};

export default function reducer(state, action) {
  const safeState = state ?? initialState;
  switch (action.type) {
    case "CMS_CREDIT_REQUEST_LOAD_MEMOS":
      return {
        ...safeState,
        creditRequestMemos: {
          ...safeState.creditRequestMemos,
          [action.request]: action.memo
        }
      };

    case "CMS_CREDIT_REQUEST_UNLOAD_REQUESTS":
      return {
        ...safeState,
        creditRequestsObject: initialState.creditRequestsObject,
        creditRequests: initialState.creditRequests
      };

    case "CMS_CREDIT_REQUEST_LOAD_REQUESTS":
      const nextCreditRequestObject = {
        ...safeState.creditRequestsObject,
        [action.data.uuid]: { ...action.data }
      };
      const nextCreditRequests = Object.keys(nextCreditRequestObject).map(
        uuid => nextCreditRequestObject[uuid]
      );
      return {
        ...safeState,
        creditRequestsObject: nextCreditRequestObject,
        creditRequests: nextCreditRequests
      };

    case "CMS_CREDIT_REQUEST_SAVE_LOOKUP_DETAIL": {
      const lookupDataCopy = _.cloneDeep(safeState.lookupData);
      const { detailKey, detailString, loanAppUuid } = action;
      _.set(lookupDataCopy, `${loanAppUuid}.${detailKey}`, detailString);

      return {
        ...safeState,
        lookupData: lookupDataCopy
      };
    }

    case "CMS_CREDIT_REQUEST_RESET_STATE":
      return initialState;

    default:
      return safeState;
  }
}
