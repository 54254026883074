import React from "react";
import { Button, Modal, Image } from "semantic-ui-react";
import PropTypes from "prop-types";

function ConfirmationModal({
  handleCancel,
  handleConfirm,
  open,
  prompt,
  yesButtonLabel,
  cancelButtonLabel = "Cancel",
  disabled = false,
  showLogo = true,
  size = "small",
  warningDetails = ""
}) {
  return (
    <Modal open={open} size={size}>
      <Modal.Header>{prompt}</Modal.Header>
      <Modal.Content image={showLogo}>
        {showLogo ? (
          <Image
            wrapped
            size="small"
            src="/images/bafs_logo_combined.svg"
            alt="Blast Logo"
          />
        ) : (
          ""
        )}
        <Modal.Description>{warningDetails}</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button content={cancelButtonLabel} basic onClick={handleCancel} />
        <Button
          data-testid="confirmation-modal-primary-button"
          content={yesButtonLabel}
          onClick={handleConfirm}
          disabled={disabled}
        />
      </Modal.Actions>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  cancelButtonLabel: PropTypes.string,
  disabled: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  prompt: PropTypes.string.isRequired,
  showLogo: PropTypes.bool,
  size: PropTypes.string,
  warningDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  yesButtonLabel: PropTypes.string.isRequired
};

export default ConfirmationModal;
