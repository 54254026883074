import ResourcesLayout, { Header } from "./components/Layout";
import ResourceTable from "./components/ResourceTable";

export { ResourcesLayout, ResourceTable };

export default {
  Layout: ResourcesLayout,
  Table: ResourceTable,
  Header
};
