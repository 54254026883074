import { useState } from "react";
import { Loan } from "../../../../../../../../../services/RTKQuery/ModelTypes/loan";
import { StateAwareFieldChangeFunction } from "./components/SideSheetBody/services/types";
import { createUpdatedLoanStateFromUserInputs } from "./services/utils";
import {
  SideSheetHeader,
  SaveButton,
  SideSheetBody,
  LoanOfficersDropdown,
  LoanClassTypeAndPurposeDropdowns,
  RequestedLoanAmountInput,
  OriginationFeeInput,
  GovGuaranteeAndAgencyProgram
} from "./components";
import { CommonMessages } from "@blast-client/constants";
import { IconButton } from "@bafsllc/ui-shared";
import { isEqual } from "lodash";
import { ParticipationDropdown } from "./components/SideSheetBody/components/ParticipationDropdown";

const { EDIT, LOAN_REQUEST } = CommonMessages;

export const EditLoanRequestSideSheet = ({
  savedLoanDetails,
  setIsOpen
}: EditLoanRequestSideSheetProps) => {
  const [saveButtonEnabled, setSaveButtonEnabled] = useState<boolean>(false);
  const [loanDetails, setLoanDetails] =
    useState<Partial<Loan>>(savedLoanDetails);
  const closeSideSheet = () => {
    setIsOpen(false);
    setSaveButtonEnabled(false);
  };
  const handleFieldChange: StateAwareFieldChangeFunction = stateUpdates => {
    const updatedLoanDetails = createUpdatedLoanStateFromUserInputs(
      savedLoanDetails,
      loanDetails,
      stateUpdates
    );
    setLoanDetails(updatedLoanDetails);
    setSaveButtonEnabled(!isEqual(updatedLoanDetails, savedLoanDetails));
  };

  return (
    <>
      <SideSheetHeader>
        <div className="col-start-1 col-end-3">
          <p>{EDIT}</p>
          <h3 className="mt-0 text-lg font-semibold">{LOAN_REQUEST}</h3>
        </div>
        <div className="col-end-7 col-span-2 justify-self-end self-center">
          <IconButton
            message={{ id: "CANCEL" }}
            onClick={closeSideSheet}
            variant="secondary"
            size="tiny"
          />
          <SaveButton
            closeSideSheet={closeSideSheet}
            loanDetails={loanDetails}
            saveButtonEnabled={saveButtonEnabled}
          />
        </div>
      </SideSheetHeader>
      <SideSheetBody>
        <LoanOfficersDropdown
          handleFieldChange={handleFieldChange}
          loanOfficer={loanDetails.loan_officer}
        />
        <LoanClassTypeAndPurposeDropdowns
          handleFieldChange={handleFieldChange}
          loanDetails={loanDetails}
          setLoanDetails={setLoanDetails}
        />
        <RequestedLoanAmountInput
          amount_requested={loanDetails.amount_requested}
          handleInputChange={handleFieldChange}
        />
        <OriginationFeeInput
          handleInputChange={handleFieldChange}
          origination_fee={loanDetails.origination_fee}
        />
        <GovGuaranteeAndAgencyProgram
          handleFieldChange={handleFieldChange}
          loanDetails={loanDetails}
          setSaveButtonEnabled={setSaveButtonEnabled}
        />
        <ParticipationDropdown
          handleFieldChange={handleFieldChange}
          participationBought={loanDetails.participation_bought}
          participationSold={loanDetails.participation_sold}
        />
      </SideSheetBody>
    </>
  );
};

interface EditLoanRequestSideSheetProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  savedLoanDetails: Partial<Loan>;
}
