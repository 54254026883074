import createCRUDSlice from "../../create-crud-slice";

const CRUD = createCRUDSlice("loanPaymentSchedules", "uuid", {
  shortName: "schedules"
});

export const { actions, reducer, selectors, initialState } = CRUD;

export default {
  actions,
  reducer,
  selectors,
  initialState
};
