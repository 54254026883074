export const FormLabel = ({
  htmlFor,
  labelText,
  required,
  testId,
  className = "font-semibold pb-2"
}: FormLabelProps) => (
  <label
    className={className}
    data-testid={testId || createId(labelText, "test-id")}
    htmlFor={htmlFor || createId(labelText, "label")}
  >
    {labelText}
    {required && <span className="text-red-700 ml-2">*</span>}
  </label>
);

interface FormLabelProps {
  htmlFor?: string;
  labelText: string;
  required?: boolean;
  testId?: string;
  className?: string;
}

export const createId = (labelText: string, type: "label" | "test-id") => {
  const formattedValue = labelText.trim().toLowerCase().replaceAll(" ", "-");
  if (type === "label") {
    return formattedValue.concat("-field");
  }
  return formattedValue.concat("-form-label");
};
