import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Table, Header, Grid, Button } from "semantic-ui-react";
import { actionCreators } from "../../reducer";
import ParticipantRow from "./ParticipantRow";

function SoldLoanParticipantsTable({
  participantData,
  participationIsSetup,
  retainedAccount,
  dispatch,
  institutionMap
}) {
  const [participants, setParticipants] = useState([]);
  const [participantsHeaders, setParticipantsHeaders] = useState([]);

  useMemo(() => {
    const participantRows = participantData.map(participant => (
      <ParticipantRow
        key={participant.institution_uuid}
        participant={participant}
        institution={institutionMap[participant.institution_uuid] || {}}
        participationIsSetup={participationIsSetup}
        triggerEditModal={() =>
          dispatch(actionCreators.prepareEditModal(participant))
        }
      />
    ));

    const retainedRow =
      participationIsSetup || !retainedAccount ? null : (
        <ParticipantRow
          key="retained"
          participant={retainedAccount}
          institution={institutionMap[retainedAccount.institution_uuid] || {}}
          participationIsSetup={participationIsSetup}
        />
      );

    setParticipants([
      ...participantRows,
      ...(retainedRow ? [retainedRow] : [])
    ]);

    setParticipantsHeaders(
      [
        "Account",
        "Investor",
        "Current Contract Amount",
        "Available Balance",
        "Current Interest Rate",
        "Percent Owned",
        participationIsSetup ? "Status" : ""
      ].map(header => (
        <Table.HeaderCell key={header || "edit"}>{header}</Table.HeaderCell>
      ))
    );
  }, [
    participantData,
    retainedAccount,
    institutionMap,
    participationIsSetup,
    dispatch
  ]);

  return (
    <>
      <Grid>
        <Grid.Row columns="2">
          <Grid.Column>
            <Header dividing as="h2">
              Participants {!participationIsSetup && "(in progress)"}
            </Header>
          </Grid.Column>
          <Grid.Column>
            {!participationIsSetup && (
              <Button
                basic
                circular
                color="green"
                floated="right"
                icon="plus"
                onClick={() =>
                  dispatch(actionCreators.toggleShowAddParticipationModal(true))
                }
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      Total Participants: {participants.length}
      <Table celled>
        <Table.Header>
          <Table.Row>{participantsHeaders}</Table.Row>
        </Table.Header>
        <Table.Body>{participants}</Table.Body>
      </Table>
      {!participationIsSetup && (
        <Grid>
          <Grid.Row columns="2">
            <Grid.Column />
            <Grid.Column textAlign="right">
              <Button
                color="green"
                disabled={participantData.length <= 0}
                onClick={() =>
                  dispatch(actionCreators.toggleShowProcessModal(true))
                }
              >
                Submit
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </>
  );
}

SoldLoanParticipantsTable.propTypes = {
  participantData: PropTypes.arrayOf(PropTypes.shape()),
  participationIsSetup: PropTypes.bool,
  dispatch: PropTypes.func,
  institutionMap: PropTypes.any,
  retainedAccount: PropTypes.shape({
    institution_uuid: PropTypes.string
  })
};

export default SoldLoanParticipantsTable;
