/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import APIEndpoints from "../../../../ApiEndpoints";

const MOCKDATA = {
  fees: [
    {
      uuid: "fee-1",
      date: "2019-10-25",
      fee: 999.99,
      loan_balance: 987987
    },
    {
      uuid: "fee-2",
      date: "2019-03-15",
      fee: 823.76,
      loan_balance: 982122
    },
    {
      uuid: "fee-3",
      date: "2018-10-01",
      fee: 234.87,
      loan_balance: 923121
    }
  ]
};

const mockFees = feesService => {
  feesService.retrieve = async (identifiers, meta) => {
    // eslint-disable-next-line no-console
    console.log(
      "MOCK REQUEST",
      "GET",
      APIEndpoints.govGuaranteeFees,
      identifiers,
      meta
    );
    const { pagination } = meta;
    const page_size = (pagination && pagination.size) || 10;
    const page_number = (pagination && pagination.number) || 0;
    const index = page_number * page_size;
    const last_page = index >= MOCKDATA.fees.length - page_size;
    return {
      data: MOCKDATA.fees.slice(index, index + page_size),
      metaData: {
        last_page,
        page_number,
        page_size
      }
    };
  };
};

export default mockFees;
