import React, { useEffect, useState } from "react";
import { useUpdatePaymentScheduleMutation } from "../../../../../../services/RTKQuery/paymentSchedules";

export const UpdateTest = ({ loanUuid, itemUuid, setStatusInParent }) => {
  const statusUnknown = "❓Unknown Status";
  const statusPass = "✅ Pass";
  const statusFail = "❌ Fail";

  const [statusUpdate, setStatusUpdate] = useState(statusUnknown);
  const [detailsText, setDetailsText] = useState("statusUnknown");

  const [updatePaymentSchedule] = useUpdatePaymentScheduleMutation();
  useEffect(() => {
    updatePaymentSchedule({
      loan_uuid: loanUuid,
      uuid: itemUuid,
      bills_remaining: 10
    })
      .unwrap()
      .then(fulfilled => {
        if (fulfilled?.data?.bills_remaining === 10) {
          setStatusUpdate(statusPass);
          setDetailsText(
            `: bills_remaining updated to: ${fulfilled.data.bills_remaining}`
          );
          setStatusInParent("Pass");
        } else {
          setStatusUpdate(statusFail);
          setStatusInParent("Fail");
        }
      })
      .catch(error => {
        console.error("Fail: useUpdatePaymentScheduleMutation", error);
        setStatusUpdate(statusFail);
      });
  }, [itemUuid, loanUuid, setStatusInParent, updatePaymentSchedule]);

  return (
    <>
      <b>{statusUpdate}</b>
      <small>{detailsText}</small>
    </>
  );
};
