import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import FetchService from "../../../FetchService";
import { FormatUrlV2 } from "../../../FormatUrl";

function add(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(callbackData, "object", false);

  const url = ApiEndpoints.baseUri + ApiEndpoints.optOut;
  FetchService("POST", url, onSuccess, onError, body, callbackData);
}

function get(onSuccess, onError, uuid, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string", false);

  const url = FormatUrlV2(ApiEndpoints.optOutGet, { parent_uuid: uuid });

  FetchService("GET", url, onSuccess, onError, null, callbackData);
}

export default { add, get };
