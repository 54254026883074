import { connect } from "react-redux";
import {
  actions,
  selectors
} from "../../../../../../../../../../store/models/loan-interest-schedules";
import ScheduleList from "./InterestScheduleList";
import { LoanInterestSchedules } from "../../../../../../../../../../services/ApiLib";
import { RemoveNullKeys } from "../../../../../../../../../../services/ApiLib/services/Utilities";
import logger from "../../../../../../../../../../services/logger";

// eslint-disable-next-line camelcase
const mapStateToProps = state => ({
  interestSchedules: selectors.schedules(state),
  pagination: selectors.pagination(state),
  error: selectors.error(state),
  tableOptions: { state: { sortBy: ["processing_order"] } }
});

const convertPhoenixId = phoenixScheduleId => phoenixScheduleId.toString();
const payloadScheduleData = scheduleData =>
  scheduleData.phoenix_schedule_id
    ? {
        ...scheduleData,
        phoenix_schedule_id: convertPhoenixId(scheduleData.phoenix_schedule_id)
      }
    : scheduleData;
export const mapDispatchToProps = (dispatch, { loan }) => ({
  onView() {
    dispatch(
      actions.retrieve(
        { loan_uuid: loan.uuid },
        { pagination: { number: 0, size: 20 } }
      )
    );
  },
  onCleanup() {
    dispatch(actions.clearPagination());
  },
  onAdd(scheduleData) {
    dispatch(
      actions.create(
        {
          loan_uuid: loan.uuid,
          institution_uuid: loan.institution_uuid,
          // TODO: figure out processing order
          processing_order: 0,
          ...RemoveNullKeys(payloadScheduleData(scheduleData))
        },
        {
          loan_uuid: loan.uuid
        }
      )
    );
  },
  onDelete(schedule) {
    dispatch(
      actions.delete({
        loan_uuid: loan.uuid,
        uuid: schedule.uuid
      })
    );
  },
  onEdit(scheduleData) {
    LoanInterestSchedules.update(
      () => {
        dispatch(
          actions.retrieve(
            { loan_uuid: loan.uuid },
            { pagination: { number: 0, size: 20 } }
          )
        );
      },
      error => logger.error(error),
      scheduleData.phoenix_schedule_id
        ? {
            ...scheduleData,
            phoenix_schedule_id: convertPhoenixId(
              scheduleData.phoenix_schedule_id
            )
          }
        : payloadScheduleData(scheduleData)
    );
  },
  onPaginationChange(pagination, filter) {
    dispatch(
      actions.retrieve({ loan_uuid: loan.uuid, ...filter }, { pagination })
    );
  },
  onDismissError() {
    dispatch(actions.clearError());
  },
  onFilter(filter) {
    dispatch(actions.retrieve({ loan_uuid: loan.uuid, ...filter }));
  }
  // TODO: implement reordering
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleList);
