import { ReactComponent } from "../../../../types/react";
import React from "react";
import { FormattedMessage } from "react-intl";
import { MessageProps } from "../../../../types/text";
import classnames from "classnames";

/**
  Provides a fomantic form input field with the proper structure and display
  As a children we should provide an input or textarea element with an id
 */
export function Field({
  className,
  children,
  labelMessage,
  labelClasses,
  labelInline = false,
  error,
  required
}: React.PropsWithChildren<FieldProps>) {
  const child = React.Children.only(children) as Exclude<
    ReactComponent,
    boolean | null | number | string | undefined
  >;

  return (
    <div
      className={`field 
        ${required ? "required" : ""} 
        ${labelInline ? "inline" : ""}
        ${error ? "error" : ""} 
        ${className}`}
      data-testid="fomantic-field"
    >
      <label
        className={classnames(labelClasses)}
        htmlFor={child.props.id ?? ""}
      >
        {"id" in labelMessage ? (
          <FormattedMessage {...labelMessage} />
        ) : (
          labelMessage.defaultMessage
        )}
      </label>
      {children}
    </div>
  );
}

export interface FieldProps {
  /** Allows a IntlMessage or displayString that's tied to the label of the field */
  labelMessage: MessageProps;
  /** If true, label will be render besides the input */
  labelInline?: boolean;
  /** If an error is passed the field border will turn red */
  error?: string;
  /** If required is set to true, the field will have a red * in front of the label */
  required?: boolean;
  /** Classname for the field */
  className?: string;
  labelClasses?: string;
}
