import _ from "lodash";

export const initialState = {
  assets: {},
  borrowers: [],
  collateral: {},
  entities: {},
  loanRequests: {}
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case "LOR_REVIEW_RESET":
      return initialState;

    case "LOR_REVIEW_POP_BORROWERS":
      return { ...state, borrowers: action.data };

    case "LOR_REVIEW_POP_ENTITIES": {
      const entities = {};
      action.data.forEach(e => {
        entities[e.uuid] = e;
      });
      return { ...state, entities };
    }

    case "LOR_REVIEW_POP_COLLATERAL": {
      const collateral = _.cloneDeep(state.collateral);
      action.data.forEach(c => {
        collateral[c.uuid] = c;
      });
      return { ...state, collateral };
    }

    case "LOR_REVIEW_POP_CREDIT_REQUESTS": {
      const loanRequests = {};
      action.data.forEach(request => {
        loanRequests[request.uuid] = request;
      });
      return { ...state, loanRequests };
    }

    case "LOR_REVIEW_POP_ASSETS": {
      return {
        ...state,
        assets: { ...state.assets, [action.data.uuid]: action.data }
      };
    }

    default:
      return state;
  }
};
