import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Assets,
  Cms,
  Messages,
  ObjectMetadata,
  Institutions,
  Schedule,
  Ticklers
} from "../../../../services/ApiLib";

import Constants from "../../../../services/Constants/strings";
import DocTrackingView from "../../../../components/DocTrackingView/DocTrackingView";
import entityDataProps from "../../../../services/PropTypes/entityData";
import HeaderBlock from "../../components/HeaderBlock";
import TabBar from "../../components/TabBar";
import { CollateralDocuments } from "../../../../components/CollateralDocuments";
import { paginationChanged } from "../../../../components/Pagination";
import DragAndDrop from "../../../../components/DragAndDrop";
// TODO: Importing from another scene is code smell, loan management reducer should
// probably be moved up the tree, perhaps a top level store dir?
import { actionCreators } from "../../../LoanManagement/scenes/LoanManagement/reducer";
import PersonalPropertyTable from "../../components/AssetsTable/personal-property";
import RealEstateTable from "../../components/AssetsTable/real-estate";
import AccountsTable from "../../components/AssetsTable/accounts";
import AssetsModal from "../../components/AssetModal";
import logger from "../../../../services/logger";
import BpAccessBlock from "../../components/BpAccessBlock";

const { populateInstitution } = actionCreators;

export function onError(response) {
  logger.error(response);
}

const schedulePaginationName = "assetMgmtSchedule";
const ticklerPaginationName = "assetMgmtTickler";

export class AssetManagementObj extends Component {
  constructor(props) {
    super(props);

    this.displayLoadError = this.displayLoadError.bind(this);
    this.dispatchEntity = this.dispatchEntity.bind(this);
    this.loadTable = this.loadTable.bind(this);
    this.onDocTrackingItem = this.onDocTrackingItem.bind(this);
    this.onSuccessDocTracking = this.onSuccessDocTracking.bind(this);
    this.onSuccessObjectData = this.onSuccessObjectData.bind(this);
    this.setAssetModalOpen = this.setAssetModalOpen.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.onSuccessTicklersMessages = this.onSuccessTicklersMessages.bind(this);
    this.state = { open: false };
  }

  componentDidMount() {
    const { dispatch, assetUuid } = this.props;
    Assets.asyncRead({ assetUuid })
      .then(({ data }) => {
        dispatch({ type: "ASSET_MANAGEMENT_POPULATE_ASSET", data });
        this.getCmsEntity(data.entity_uuid);
      })
      .catch(error => {
        onError(error ?? "unknown error");
      });
  }

  componentDidUpdate(prevProps) {
    if (
      paginationChanged(
        prevProps.ticklerPagination,
        this.props.ticklerPagination
      )
    ) {
      this.onDocTrackingItem(Constants.TICKLERS);
    }
    if (
      paginationChanged(
        prevProps.schedulePagination,
        this.props.schedulePagination
      )
    ) {
      this.onDocTrackingItem(Constants.SCHEDULE);
    }
  }

  onDocTrackingItem(name) {
    const { schedulePagination, ticklerPagination } = this.props;
    let pagination = null;
    switch (name) {
      case Constants.TICKLERS: {
        if (ticklerPagination) {
          pagination = {
            number: ticklerPagination.number,
            size: ticklerPagination.size
          };
        }
        const callbackData = { name };
        Ticklers.asyncReadAllActive(this.props.assetUuid, null, pagination)
          .then(rsp => {
            this.onSuccessDocTracking(rsp, callbackData);
          })
          .catch(() => {
            this.displayLoadError();
          });
        break;
      }

      case Constants.SCHEDULE: {
        if (schedulePagination) {
          pagination = {
            number: schedulePagination.number,
            size: schedulePagination.size
          };
        }
        const callbackData = { name };
        Schedule.get(this.props.assetUuid, pagination)
          .then(rsp => {
            this.onSuccessDocTracking(rsp, callbackData);
          })
          .catch(() => {
            this.displayLoadError();
          });
        break;
      }
      default:
    }
  }

  onSuccessDocTracking(model, { name }) {
    let docData;
    const { jwt } = this.props;
    const { onSuccessTicklersMessages, onSuccessObjectData } = this;
    if (name === Constants.TICKLERS) {
      docData = model.data;
      model.data.forEach(doc => {
        Messages.get(
          onSuccessTicklersMessages,
          onSuccessTicklersMessages,
          null,
          { parentUuid: doc.uuid, number: 0, size: 10 },
          { uuid: doc.uuid }
        );
        if (doc.objectUuid) {
          ObjectMetadata.get(
            jwt,
            onSuccessObjectData,
            onError,
            doc.objectUuid,
            null,
            null,
            { uuid: doc.uuid }
          );
        }
      });
    } else if (name === Constants.SCHEDULE) {
      docData = model.data;
    }
    this.props.dispatch({
      type: "ASSET_MANAGEMENT_UPDATE_DOC_TRACKING_ITEM",
      data: docData,
      name
    });
  }

  onSuccessObjectData(object, { uuid }) {
    this.props.dispatch({
      type: "DOC_TRACKING_TICKLERS_GET_OBJECT_DATA",
      objectMetadata: object.data || {},
      uuid
    });
  }

  onSuccessTicklersMessages(messages, { uuid }) {
    this.props.dispatch({
      type: "DOC_TRACKING_TICKLERS_GET_MESSAGES",
      messages: messages.data || [],
      uuid
    });
  }

  setAssetModalOpen(value) {
    const newState = { ...this.state, open: value };
    this.setState(newState);
  }

  getCmsEntity = uuid => {
    Cms.asyncRead(uuid)
      .then(rsp => {
        this.dispatchEntity(rsp);
      })
      .catch(error => {
        onError(error);
        this.displayLoadError();
      });
  };

  dispatchEntity({ data }) {
    const { dispatch } = this.props;
    dispatch({
      type: "ASSET_MANAGEMENT_DATA_LOAD",
      data
    });

    Institutions.asyncRead({ institutionUuid: data.institution_uuid })
      .then(({ data: institution }) => {
        dispatch({
          type: "ASSET_MANAGEMENT_POPULATE_INSTITUTION",
          institution
        });
        dispatch(populateInstitution(institution));
      })
      .catch(error => {
        onError(error);
      });
  }

  displayLoadError() {
    this.props.dispatch({
      type: "ASSET_MANAGEMENT_ERROR_LOAD_DOC_LIST",
      errorMessage: Constants.CMS_ERROR_LOAD_DOC_LIST
    });
  }

  loadTable(name) {
    const { assetUuid, dispatch, entityData } = this.props;
    switch (name) {
      case Constants.DOCUMENT_TRACKING: {
        dispatch({
          type: "ASSET_MANAGEMENT_POPULATE_TABLE",
          data: [],
          activeItem: name
        });
        const callbackData = { name: Constants.TICKLERS, assetUuid };
        Ticklers.asyncReadAllActive(assetUuid, null, null)
          .then(rsp => {
            this.onSuccessDocTracking(rsp, callbackData);
          })
          .catch(() => {
            this.displayLoadError();
          });
        break;
      }

      case Constants.ASSET_DOCUMENTS: {
        const uuid = null;
        const queryParams = {
          parentUuid: assetUuid,
          institutionUuid: entityData.institution_uuid
        };
        const pagination = { number: 0, size: 9999 };
        ObjectMetadata.asyncRead(uuid, queryParams, pagination)
          .then(({ data }) => {
            dispatch({
              activeItem: name,
              data,
              type: "ASSET_MANAGEMENT_POPULATE_TABLE"
            });
          })
          .catch(() => {
            this.displayLoadError();
          });
        break;
      }
      case Constants.REAL_ESTATE_ASSET_DETAILS: {
        Assets.readRealEstateByAssetUuid({ asset_uuid: assetUuid })
          .then(({ data }) => {
            this.props.dispatch({
              activeItem: name,
              data,
              type: "ASSET_MANAGEMENT_POPULATE_TABLE"
            });
          })
          .catch(() => {
            this.displayLoadError();
          });
        break;
      }
      case Constants.ACCOUNTS_ASSET_DETAILS: {
        Assets.readAccountByAssetUuid({ asset_uuid: assetUuid })
          .then(({ data }) => {
            this.props.dispatch({
              activeItem: name,
              data,
              type: "ASSET_MANAGEMENT_POPULATE_TABLE"
            });
          })
          .catch(() => {
            this.displayLoadError();
          });
        break;
      }
      case Constants.PERSONAL_PROPERTY_ASSET_DETAILS: {
        Assets.readPersonalPropertyByAssetUuid({ asset_uuid: assetUuid })
          .then(({ data }) => {
            this.props.dispatch({
              activeItem: name,
              data,
              type: "ASSET_MANAGEMENT_POPULATE_TABLE"
            });
          })
          .catch(() => {
            this.displayLoadError();
          });
        break;
      }
      default:
        // no default load, do nothing
        break;
    }
  }

  updateValue(value) {
    if (this.props.asset.asset_group === "Real Estate") {
      Assets.asyncRealEstateAssetUpdate(value, {
        uuid: this.props.data[0].uuid
      })
        .then(({ data }) => {
          this.props.dispatch({
            activeItem: Constants.REAL_ESTATE_ASSET_DETAILS,
            data: [data],
            type: "ASSET_MANAGEMENT_POPULATE_TABLE"
          });
        })
        .catch(() => {
          this.displayLoadError();
        });
    }
    if (
      this.props.asset.asset_group === "Personal Property" &&
      this.props.asset.asset_class === "Accounts"
    ) {
      Assets.asyncAccountAssetUpdate(value, { uuid: this.props.data[0].uuid })
        .then(({ data }) => {
          this.props.dispatch({
            activeItem: Constants.ACCOUNTS_ASSET_DETAILS,
            data: [data],
            type: "ASSET_MANAGEMENT_POPULATE_TABLE"
          });
        })
        .catch(() => {
          this.displayLoadError();
        });
    }
    if (
      this.props.asset.asset_group === "Personal Property" &&
      this.props.asset.asset_class !== "Accounts"
    ) {
      Assets.asyncPersonalPropertyAssetUpdate(value, {
        uuid: this.props.data[0].uuid
      })
        .then(({ data }) => {
          this.props.dispatch({
            activeItem: Constants.PERSONAL_PROPERTY_ASSET_DETAILS,
            data: [data],
            type: "ASSET_MANAGEMENT_POPULATE_TABLE"
          });
        })
        .catch(() => {
          this.displayLoadError();
        });
    }
  }

  render() {
    const {
      data,
      activeItem,
      asset,
      assetUuid,
      entityData,
      docTrackingItem,
      institution,
      jwt
    } = this.props;
    entityData.parent_uuid = assetUuid;
    let displayTable;
    switch (activeItem) {
      case Constants.DOCUMENT_TRACKING:
        displayTable = (
          <DocTrackingView
            parentType="Asset"
            schedulePaginationName={schedulePaginationName}
            ticklerPaginationName={ticklerPaginationName}
            data={data}
            docTrackingItem={docTrackingItem}
            entityData={entityData}
          />
        );
        break;

      case Constants.ASSET_DOCUMENTS:
        displayTable = (
          <>
            <DragAndDrop
              entityUuid={entityData.uuid}
              institutionUuid={entityData.institution_uuid}
              onUploadSuccess={() => this.loadTable(Constants.ASSET_DOCUMENTS)}
              parentRelType="Asset"
              parentUuid={asset.uuid}
              bpOrganizationUuid={entityData.bp_organization_uuid}
            />
            <CollateralDocuments
              data={data}
              type="ASSET_MANAGEMENT_UPDATE_ASSET_DOCUMENTS"
              institutionUuid={entityData.institution_uuid}
              managementType="Asset"
            />
          </>
        );
        break;
      case Constants.REAL_ESTATE_ASSET_DETAILS: {
        displayTable = (
          <RealEstateTable
            data={this.props.data[0]}
            onEdit={this.setAssetModalOpen}
          />
        );
        break;
      }
      case Constants.ACCOUNTS_ASSET_DETAILS: {
        displayTable = (
          <AccountsTable
            data={this.props.data[0]}
            onEdit={this.setAssetModalOpen}
          />
        );
        break;
      }
      case Constants.PERSONAL_PROPERTY_ASSET_DETAILS: {
        displayTable = (
          <PersonalPropertyTable
            data={this.props.data[0]}
            onEdit={this.setAssetModalOpen}
          />
        );
        break;
      }
      default:
    }
    return (
      <div>
        <AssetsModal
          asset={this.props.asset}
          modalInfo={this.props.data[0]}
          updateValue={this.updateValue}
          setAssetModalOpen={this.setAssetModalOpen}
          open={this.state.open}
        />
        <HeaderBlock
          description={asset.description || ""}
          entityData={entityData}
          institutionData={institution}
        />

        <BpAccessBlock />

        <TabBar
          activeItem={activeItem}
          jwt={jwt}
          loadTable={this.loadTable}
          assetType={{
            class: this.props.asset.asset_class,
            group: this.props.asset.asset_group
          }}
        />
        {displayTable}
      </div>
    );
  }
}

AssetManagementObj.propTypes = {
  activeItem: PropTypes.string.isRequired,
  asset: PropTypes.shape().isRequired,
  assetUuid: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
  docTrackingItem: PropTypes.string.isRequired,
  entityData: entityDataProps.isRequired,
  institution: PropTypes.shape().isRequired,
  jwt: PropTypes.string.isRequired,
  schedulePagination: PropTypes.shape({
    number: PropTypes.number,
    size: PropTypes.number
  }).isRequired,
  ticklerPagination: PropTypes.shape({
    number: PropTypes.number,
    size: PropTypes.number
  }).isRequired
};

const mapStateToProps = state => ({
  activeItem: state.AssetManagementReducer.activeItem,
  asset: state.AssetManagementReducer.asset,
  data: state.AssetManagementReducer.data,
  entityData: state.AssetManagementReducer.entityData,
  docTrackingItem: state.AssetManagementReducer.docTrackingItem,
  institution: state.LoanManagementReducer.institution,
  jwt: state.auth.jwt,
  loading: state.AssetManagementReducer.loading,
  schedulePagination: state.PaginationReducer[schedulePaginationName],
  ticklerPagination: state.PaginationReducer[ticklerPaginationName]
});

const AssetManagement = connect(mapStateToProps)(AssetManagementObj);

export default AssetManagement;
