import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import TypeCheck from "typecheck-extended";
import { Divider, Grid } from "semantic-ui-react";
import DocTitle from "../../../../components/DocTitle";

import Buttons from "../../components/buttons";
import { ConfirmationModal } from "../../../../components/Modals";

import { Decision, LoanApp } from "../../../../services/ApiLib";
import { stepList } from "../../services/constants";
import { SummarizeData } from "../../../../services/FormElements";
import logger from "../../../../services/logger";

const summaryFieldsBorrower = [
  {
    displayName: "Business Name",
    key: "company_name",
    required: true
  },
  {
    displayName: "Federal Tax ID / EIN",
    key: "tin",
    required: true
  },
  {
    displayName: "Entity Type",
    key: "entity_type",
    required: true
  },
  {
    displayName: "Industry Type",
    key: "naics_code",
    required: true
  },
  {
    displayName: "Address",
    key: "phy_address_line1",
    required: false
  },
  {
    displayName: "Suite or Apt. #",
    key: "phy_address_line2",
    required: false
  },
  {
    displayName: "City",
    key: "phy_address_city",
    required: true
  },
  {
    displayName: "State",
    key: "phy_address_state",
    required: true
  },
  {
    displayName: "Zip",
    key: "phy_address_zip",
    required: true
  },
  {
    displayName: "Phone",
    format: "phoneNumber",
    key: "phone_no",
    required: true
  },
  {
    displayName: "Email",
    key: "email",
    required: true
  },
  {
    displayName: "Website",
    key: "website",
    required: false
  },
  {
    displayName: "Principal Industry Experience",
    format: "number",
    key: "industry_experience",
    required: true
  },
  {
    displayName: "Year in Business",
    format: "number",
    key: "years_in_business",
    required: true
  },
  {
    displayName: "State of Registration",
    key: "state_of_reg",
    required: true
  },
  {
    displayName: "Gross Annual Revenue",
    format: "currency",
    key: "annual_revenue",
    required: true
  }
];

const summaryFieldsOwner = [
  {
    displayName: "First Name",
    key: "first_name",
    required: true
  },
  {
    displayName: "Last Name",
    key: "last_name",
    required: true
  },
  {
    displayName: "DOB",
    key: "dob",
    required: false
  },
  {
    displayName: "Social Security #",
    key: "tin",
    required: true
  },
  {
    displayName: "Driver's License #",
    key: "drivers_license_no",
    required: true
  },
  {
    displayName: "US Citizen or Legal Resident",
    key: "is_us_citizen",
    required: true
  },
  {
    displayName: "Rent or Own",
    key: "rent_or_own",
    required: true
  },
  {
    displayName: "Address",
    key: "phy_address_line1",
    required: true
  },
  {
    displayName: "Suite or Apt. #",
    key: "phy_address_line2",
    required: false
  },
  {
    displayName: "City",
    key: "phy_address_city",
    required: true
  },
  {
    displayName: "State",
    key: "phy_address_state",
    required: true
  },
  {
    displayName: "Zip",
    key: "phy_address_zip",
    required: true
  },
  {
    displayName: "Home Phone",
    format: "phoneNumber",
    key: "phone_no",
    required: true
  },
  {
    displayName: "Mobile Phone",
    format: "phoneNumber",
    key: "mobile_phone_no",
    required: false
  },
  {
    displayName: "Email",
    key: "email",
    required: true
  },
  {
    displayName: "TransUnion Score",
    key: "credit_score_transunion",
    required: false
  },
  {
    displayName: "Experian Score",
    key: "credit_score_experian",
    required: false
  },
  {
    displayName: "Equifax Score",
    key: "credit_score_equifax",
    required: false
  },
  {
    displayName: "Total Annual Income",
    format: "currency",
    key: "personal_annual_income",
    required: true
  }
];

const summaryFieldsLoanRequest = [
  {
    displayName: "Loan Amount",
    format: "currency",
    key: "loan_amount",
    required: true
  },
  {
    displayName: "Loan Term (Months)",
    key: "payment_period",
    required: true
  },
  {
    displayName: "Interest Rate",
    format: "percentage",
    key: "loan_rate",
    required: true
  },
  {
    displayName: "Use of Funds",
    key: "loan_purpose",
    required: true
  },
  {
    displayName: "Payment Type",
    key: "payment_type",
    required: true
  },
  {
    displayName: "Loan Type",
    key: "loan_type",
    required: true
  }
];

class AppOverview extends Component {
  constructor(props) {
    super(props);
    this.submitForDecision = this.submitForDecision.bind(this);
    this.submitForDecisionError = this.submitForDecisionError.bind(this);
    this.submitForDecisionSuccess = this.submitForDecisionSuccess.bind(this);
  }

  componentDidMount() {
    const { setCurrentPage } = this.props;
    setCurrentPage(4);
  }

  submitForDecision(onSuccess) {
    TypeCheck(onSuccess, "function");

    const {
      dispatch,
      toggleConfirmations,
      businessInfo,
      personalInfo,
      loanRequestInfo
    } = this.props;
    toggleConfirmations("submit", false);
    dispatch({
      message: "Evaluating Loan Request ...",
      messageType: "userMessage",
      type: "AUTO_DESC_MESSAGE"
    });

    const onError = this.submitForDecisionError;
    const businessUuid = businessInfo.uuid;
    const personalUuid = personalInfo.uuid;
    const loanUuid = loanRequestInfo.uuid;
    const callbackData = onSuccess;
    Decision.request(
      onSuccess,
      onError,
      businessUuid,
      personalUuid,
      loanUuid,
      callbackData
    );
  }

  submitForDecisionError(error) {
    logger.error(error); // TODO: Remove this.

    const { onError } = this.props;
    onError("Error Evaluating Decision Request");
  }

  submitForDecisionSuccess(rsp) {
    TypeCheck(rsp.data.decision_response.approved, "boolean");
    // TODO: validate that either
    // rsp.data.csi_response.data.uuid is 'string' or rsp.data.csi_response is null

    let message = "Application Was Not Approved";
    let messageType = "errorMsg";
    let phase = "Decision Failed";
    const decisionApproved = rsp.data.decision_response.approved;
    if (decisionApproved) {
      message = "✓ Decision Approved";
      messageType = "userMessage";
      phase = "Decision Approved";
    }

    // TODO: This is due tomorrow morning. Come back, and think through if this needs refactoring.
    const body = {
      // TODO: This is requiring us to manage whether this api is Dynamo of Maria (re: null vs '')
      csi_transaction_uuid: _.get(rsp, "data.csi_response.data.uuid", ""),
      phase
    };
    const { jwt, loanAppUuid, onError } = this.props;
    let onSuccess;
    LoanApp.update(jwt, onSuccess, onError, loanAppUuid, body);

    const { dispatch } = this.props;
    dispatch({
      decisionApproved,
      message,
      messageType,
      phase,
      type: "AUTO_DESC_REQUEST_STATUS"
    });
  }

  render() {
    const {
      businessInfo,
      confirmationVisibility,
      currentPageIndex,
      loading,
      loanAppInfo,
      loanRequestInfo,
      personalInfo,
      onSaveAndNext,
      toggleConfirmations
    } = this.props;
    return (
      <div>
        <DocTitle
          title={`${stepList[4]} - Auto Decision Request - BLAST Portal`}
        />
        <h1> App Overview</h1>
        <Divider />
        <Grid divided="vertically">
          <Grid.Row columns={2}>
            <Grid.Column>
              <h2>Borrower</h2>
              {!loading
                ? SummarizeData(summaryFieldsBorrower, businessInfo)
                : ""}
            </Grid.Column>
            <Grid.Column>
              <h2>Owner</h2>
              {!loading ? SummarizeData(summaryFieldsOwner, personalInfo) : ""}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={1}>
            <Grid.Column>
              <h2>Loan Request</h2>
              {!loading
                ? SummarizeData(summaryFieldsLoanRequest, loanRequestInfo)
                : ""}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Buttons
          currentPage={stepList[currentPageIndex]}
          currentPageIndex={currentPageIndex}
          disabled={loading || loanAppInfo.phase !== "New Credit Application"}
          onSaveAndNext={onSaveAndNext}
          onWithdraw={() => toggleConfirmations("withdraw", true)}
          submitForDecision={() => toggleConfirmations("submit", true)}
        />
        <ConfirmationModal
          cancelButtonLabel="Return to Editing Request"
          handleCancel={() => toggleConfirmations("submit", false)}
          handleConfirm={() =>
            this.submitForDecision(this.submitForDecisionSuccess)
          }
          open={confirmationVisibility.submit}
          prompt="Submit for Decision?"
          showLogo={false}
          warningDetails="
            Clicking submit will send this loan request to our Auto Decision Engine.
            You will not be able to edit the loan request after submitting.
          "
          yesButtonLabel="Submit for Decision"
        />
      </div>
    );
  }
}

AppOverview.propTypes = {
  currentPageIndex: PropTypes.number.isRequired,
  businessInfo: PropTypes.shape({ uuid: PropTypes.string }).isRequired,
  confirmationVisibility: PropTypes.shape({
    submit: PropTypes.bool,
    withdraw: PropTypes.bool
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  jwt: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  loanAppInfo: PropTypes.shape({
    phase: PropTypes.string
  }).isRequired,
  loanAppUuid: PropTypes.string.isRequired,
  loanRequestInfo: PropTypes.shape({ uuid: PropTypes.string }).isRequired,
  onError: PropTypes.func.isRequired,
  onSaveAndNext: PropTypes.func.isRequired,
  personalInfo: PropTypes.shape({ uuid: PropTypes.string }).isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  toggleConfirmations: PropTypes.func.isRequired
};

export default AppOverview;
