import type { DisplayString, IntlMessage } from "../../../types/text";
import type { SidebarItemSubmenuInternal } from "./sidebar-types";
import type { SidebarListItemProps } from "./sidebar-list-item";
import { SidebarListItemContent } from "./sidebar-list-item-content";

/**
 * A Sidebar menu item that displays a submenu when it is clicked.
 * @param props
 */
export function SidebarListSubmenu(props: SidebarListSubmenuProps) {
  const { href, items, submenuSelectedHref } = props;
  const submenuItems = items.map(item => (
    <SidebarListItemContent
      {...item}
      key={
        (item.message as unknown as IntlMessage).id ||
        (item.message as DisplayString).defaultMessage
      }
      onItemSelect={props.onItemSelect}
      submenu={{ expanded: href === submenuSelectedHref }}
      submenuItem
    />
  ));

  // The `key` for this JSX is set in the `<Sidebar>`.
  return (
    <>
      <SidebarListItemContent
        {...props}
        submenu={{ expanded: href === submenuSelectedHref }}
      />
      {submenuItems}
    </>
  );
}

export type SidebarListSubmenuProps = SidebarItemSubmenuInternal &
  SidebarListItemProps;
