import { useIntl } from "react-intl";
import {
  loanClassOptions,
  loanPurposeOptions,
  loanTypes
} from "../../../../../../../../../../../../../services/FormElements/components/loanOptions";
import { SideSheetDropdown } from "../SideSheetDropdown";
import { Loan } from "../../../../../../../../../../../../../services/RTKQuery/ModelTypes/loan";
import { SetStateAction } from "react";
import { StateAwareFieldChangeFunction } from "../../services/types";

export const LoanClassTypeAndPurposeDropdowns = ({
  handleFieldChange,
  loanDetails
}: LoanClassTypeAndPurposeDropdownsProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {dropdowns.map(dropdown => {
        const { label, options } = dropdown;
        const stateColumnName = getStateColumnName(label);

        return (
          <SideSheetDropdown
            handleChange={createHandleChange(
              stateColumnName,
              handleFieldChange
            )}
            key={label}
            label={formatMessage({ id: label })}
            options={options}
            required
            value={loanDetails[stateColumnName]}
          />
        );
      })}
    </>
  );
};

const dropdowns = [
  { label: "LOAN_CLASS", options: loanClassOptions },
  { label: "LOAN_TYPE", options: loanTypes },
  { label: "LOAN_PURPOSE", options: loanPurposeOptions }
];

const getStateColumnName = (dropdownLabel: string) =>
  dropdownLabel === "LOAN_PURPOSE" ? "purpose" : dropdownLabel.toLowerCase();

interface LoanClassTypeAndPurposeDropdownsProps {
  handleFieldChange: StateAwareFieldChangeFunction;
  loanDetails: Partial<Loan>;
  setLoanDetails: React.Dispatch<SetStateAction<Partial<Loan>>>;
}

const createHandleChange = (
  stateColumnName: string,
  handleFieldChange: StateAwareFieldChangeFunction
) => {
  return (value: string) => {
    handleFieldChange([{ value, stateColumnName }]);
  };
};
