import { useEffect, useState } from "react";
import { Institutions } from "../../ApiLib";

/**
 * @param {string} propertyName
 * @param {["institutions"]} propertiesToGet
 * @param {{[key: string]: AsyncProperty}} fetches
 * @returns {boolean}
 */
function okToFetchData(propertyName, propertiesToGet, fetches) {
  const includesPropertyName = propertiesToGet.includes(propertyName);
  if (!includesPropertyName) {
    return false;
  }

  const fetchStatusDoesNotExist = !fetches[propertyName];
  if (fetchStatusDoesNotExist) {
    return true;
  }

  const fetchActive = fetches[propertyName]?.status === "active";
  const fetchData = fetches[propertyName]?.data;
  const fetchError = fetches[propertyName]?.error;

  return !fetchActive && !fetchData && !fetchError;
}

/**
 * Triggers fetching of data related to institutions and reports back on the
 * fetch status. The results of the fetch (if successful) are stored in Redux
 * state in existing locations. For example if you want to get a list of all the
 * institutions:
 * - `propertiesToGet` must include 'institutions'
 * - the return value will have a property named `institutions` with fetch
 *   status
 * - after the request succeeds the result will be dispatched using
 *   'SYSTEM_ADMIN_INSTITUTIONS_ALL'
 * - the data will be in state under `SystemAdminReducer.allInstitutions`
 * @param {UseInstitutionsOptions} options
 * @returns {{[key: string]: AsyncProperty}}
 */
export default function useInstitutions({ dispatch, propertiesToGet }) {
  /**
   * @type {[{[key: string]: AsyncProperty},
   * React.Dispatch<React.SetStateAction<{[key: string]: AsyncProperty}>> ]}
   */
  const [fetches, setFetches] = useState({});

  useEffect(() => {
    if (okToFetchData("institutions", propertiesToGet, fetches)) {
      (async () => {
        setFetches(current => ({
          ...current,
          institutions: { status: "active" }
        }));
        const { data, error } = await Institutions.asyncRead({
          institutionUuid: ""
        });

        if (error) {
          setFetches(current => ({
            ...current,
            institutions: { error, status: "idle" }
          }));
        } else {
          dispatch({ data, type: "SYSTEM_ADMIN_INSTITUTIONS_ALL" });
          setFetches(current => ({
            ...current,
            institutions: { data: true, status: "idle" }
          }));
        }
      })();
    }
  }, [dispatch, fetches, propertiesToGet]);

  return fetches;
}

/**
 * @typedef AsyncProperty
 * @property {"active" | "idle"} status
 * @property {Object} [error]
 * @property {boolean} [data]
 */

/**
 * @typedef UseInstitutionsOptions
 * @property {(action: {[key: string]: any}) => void} dispatch
 * @property {Array<string>} propertiesToGet A list of properties that is used
 * by the hook to determine what data to get from the API.
 */
