/* eslint-disable camelcase */

import logger from "../../../../logger";

/* eslint-disable no-param-reassign */
const MOCKDATA = {
  fees: [
    { uuid: "fee-1", description: "Fee Name - Generic", fee: 999.99 },
    { uuid: "fee-2", description: "Fee Name - Specific", fee: 823.76 }
  ]
};

const mockFees = feesService => {
  let i = 3;
  // eslint-disable-next-line no-plusplus
  const mockUuid = () => `mock-uuid-additional-fee${i++}`;

  feesService.create = async (body, identifiers, meta) => {
    logger.debug("MOCK REQUEST", "POST", body, identifiers, meta);
    const newFee = { ...body, uuid: mockUuid() };
    MOCKDATA.fees.unshift(newFee);
    return {
      data: newFee,
      metaData: null,
      error: null
    };
  };
  feesService.retrieve = async (identifiers, meta) => {
    logger.debug("MOCK REQUEST", "GET", identifiers, meta);
    const { pagination } = meta;
    const page_size = (pagination && pagination.size) || 10;
    const page_number = (pagination && pagination.number) || 0;
    const index = page_number * page_size;
    const last_page = index >= MOCKDATA.fees.length - page_size;
    return {
      data: MOCKDATA.fees.slice(index, index + page_size),
      metaData: {
        last_page,
        page_number,
        page_size
      }
    };
  };
  feesService.update = async (body, identifiers, meta) => {
    logger.debug("MOCK REQUEST", "PUT", body, identifiers, meta);
    const fee = MOCKDATA.fees.find(feeItem => feeItem.uuid === body.uuid);

    if (fee) {
      const updatedFee = { ...fee, ...body };
      MOCKDATA.fees = MOCKDATA.fees.map(f =>
        f.uuid === fee.uuid ? updatedFee : f
      );
      return {
        data: updatedFee,
        metaData: null,
        error: null
      };
    }
    // eslint-disable-next-line no-throw-literal
    throw {
      error: "Fee not found"
    };
  };
  feesService.delete = async (identifiers, meta) => {
    logger.debug("MOCK REQUEST", "DELETE", identifiers, meta);
    const fee = MOCKDATA.fees.find(af => af.uuid === identifiers.fee_uuid);
    if (fee) {
      MOCKDATA.fees = MOCKDATA.fees.filter(af => af === fee);
      return {
        data: "ok",
        metaData: null,
        error: null
      };
    }
    // eslint-disable-next-line no-throw-literal
    throw {
      error: "Fee not found"
    };
  };
};

export default mockFees;
