import React, { forwardRef } from "react";
import { FormattedMessage } from "react-intl";
import { IconClassNames, PositionClassNames } from "../../../../types/elements";
import type { HandleClassNames } from "../../../../types/class-names";
import type { MessageProps } from "../../../../types/text";
import type {
  AllowedClassNames,
  ButtonBaseProps
} from "../button-base/button-base";
import { ButtonBase } from "../button-base/button-base";
import type {
  IconBoundedBothProps,
  IconBoundedLeftProps,
  IconBoundedRightProps,
  IconBoundedSharedProps
} from "../../../../custom/collections/icon-bounded/icon-bounded";
import { IconBounded } from "../../../../custom/collections/icon-bounded/icon-bounded";

/**
 * Display a button with an icon and an optional text message.
 */
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButtonImpl(
    { message, updateClassNames: updateButtonClassNames, ...props },
    ref
  ) {
    function isIconLeftProps(data: any): data is IconButtonPropsLeft {
      return "iconLeft" in data;
    }

    function isIconRightProps(data: any): data is IconButtonPropsRight {
      return "iconRight" in data;
    }

    function isIconButtonPropsV1(data: any): data is IconButtonPropsV1 {
      return "icon" in data;
    }

    const handleButtonClassNames: HandleClassNames<AllowedClassNames> =
      classNames => {
        const iconClassNames = classNames.reduce<typeof classNames>(
          (acc, name) => {
            if (name === "ui") {
              message && acc.push("labeled");
              acc.push(name, "icon");
            } else {
              acc.push(name);
            }

            return acc;
          },
          []
        );

        return updateButtonClassNames
          ? updateButtonClassNames(iconClassNames)
          : iconClassNames;
      };

    const iconBoundedProps: Partial<IconBoundedBothProps> = {};
    const buttonProps: Partial<IconButtonProps> = { ...props };
    if (isIconButtonPropsV1(props)) {
      // There are weird situations (like Storybook) where a props object is
      // created that implements both IconButtonPropsV1 and a new interface. If
      // new interface properties exist then ignore the IconButtonPropsV1 props.
      if (
        !(buttonProps as IconButtonPropsLeft).iconLeft &&
        !(buttonProps as IconButtonPropsRight).iconRight
      ) {
        // Convert the buttonProps from the deprecated `IconButtonPropsV1` into
        // `IconButtonPropsBoth`, `IconButtonPropsLeft`, or
        // `IconButtonPropsRight`.
        if (props.iconPosition === "right") {
          (buttonProps as IconButtonPropsRight).iconRight = {
            icon: props.icon
          };
        } else {
          (buttonProps as IconButtonPropsLeft).iconLeft = {
            icon: props.icon
          };
        }
      }

      delete (buttonProps as any).icon; // icon is not a valid <button> prop
      delete (buttonProps as any).iconPosition; // iconPosition is not a valid <button> prop
    }

    if (isIconLeftProps(buttonProps)) {
      iconBoundedProps.iconLeft = buttonProps.iconLeft;
      delete (buttonProps as any).iconLeft; // iconLeft is not a valid <button> prop
    }

    if (isIconRightProps(buttonProps)) {
      iconBoundedProps.iconRight = buttonProps.iconRight;
      delete (buttonProps as any).iconRight; // iconRight is not a valid <button> prop
    }

    (iconBoundedProps as IconBoundedSharedProps).updateIconClassNames =
      classNames => [...classNames, "button"];

    return (
      <ButtonBase
        updateClassNames={handleButtonClassNames}
        {...buttonProps}
        ref={ref}
      >
        <IconBounded {...(iconBoundedProps as IconBoundedBothProps)}>
          <span>
            {message &&
              ("id" in message ? (
                <FormattedMessage {...message} />
              ) : (
                message?.defaultMessage
              ))}
          </span>
        </IconBounded>
      </ButtonBase>
    );
  }
);

/** Props for a control that displays icons along with other content. */
export type IconButtonProps =
  | IconButtonPropsLeft
  | IconButtonPropsRight
  | IconButtonPropsBoth
  | IconButtonPropsV1;

/** Props for a control that displays an icon on its left side. */
export type IconButtonPropsLeft = IconButtonPropsCommon & IconBoundedLeftProps;

/** Props for a control that displays an icon on its right side. */
export type IconButtonPropsRight = IconButtonPropsCommon &
  IconBoundedRightProps;

/** Props for a control that displays icons on both its left and right sides. */
export type IconButtonPropsBoth = IconButtonPropsCommon & IconBoundedBothProps;

/**
 * @deprecated
 * Prefer `IconButtonPropsLeft`, `IconButtonPropsRight`, or
 * `IconButtonPropsBoth`. Props for a control that displays an icon.
 */
export interface IconButtonPropsV1 extends ButtonBaseProps {
  /** The name of the icon to display. */
  icon: IconClassNames;
  /** Which side of the button does the icon appear on? Defaults to left. */
  iconPosition?: PositionClassNames;
  /** The text message to display in the button. If the value of `id` doesn't
   * exist then the value of `defaultMessage` will be displayed. */
  message: MessageProps;
}

interface IconButtonPropsCommon extends ButtonBaseProps {
  /** The text message to display in the button. If the value of `id` doesn't
   * exist then the value of `defaultMessage` will be displayed. */
  message?: MessageProps;
}
