import React from "react";
import { connect } from "react-redux";
import { arrayOf, func, object, shape, string, bool, number } from "prop-types";
import { Button, Input, Grid, Dropdown, Modal, Form } from "semantic-ui-react";
import { LoanTransactionHistory } from "../../../../../../../../services/ApiLib";
import { ConcatQueryParams } from "../../../../../../../../services/ApiLib/services/Utilities";
import logger from "../../../../../../../../services/logger";
import { DateInput } from "../../../../../../../../components/CustomFormElements";
import PaginationControls, {
  paginationChanged
} from "../../../../../../../../components/Pagination";

import { lookupAccount } from "../..";
import { actionCreators } from "../../reducer";
import {
  HistoryTable,
  removeRecalcColumns
} from "../../components/HistoryTable";
import { getAccountNumber } from "../../../../../../../../services/TextFormatting";

export const downloadCsv = rows => {
  const header = "data:text/csv;charset=utf-8,";
  const quoted = rows.map(e => e.map(item => `"${item}"`));
  const data = quoted.map(e => e.join(",")).join("\n");
  const csv = `${header}${data}`;
  const encodedUri = encodeURI(csv);
  window.open(encodedUri);
};

const transactionArray = (type, tran) => {
  const transactionItem = [
    tran.created_datetime,
    tran.effective_date,
    null,
    tran.tran_code,
    tran.description || "None",
    tran.tran_amount,
    tran.service_fee,
    tran.principal_impact,
    tran.interest_paid,
    tran.fees_paid_amount,
    tran.principal
  ];
  const recalTransactionItem = [
    tran.recalc_fees_paid_amount,
    tran.recalc_fractional_accrued_interest,
    tran.recalc_interest_accumulated,
    tran.recalc_interest_paid,
    tran.recalc_per_diem,
    tran.recalc_principal,
    tran.recalc_principal_impact,
    tran.recalc_service_fee,
    tran.recalc_service_fee_ending_balance
  ];
  const transactionMap =
    type === "detailed"
      ? [...transactionItem, ...recalTransactionItem]
      : transactionItem;
  return transactionMap;
};
export const transactionsToCsvContent = (
  { historyData, loanNumber, borrowerName },
  type,
  headerRow
) => {
  const loanNumberRow = [`Loan Number: ${loanNumber}`];
  const borrowerNameRow = [`Borrower: ${borrowerName}`];
  return [
    loanNumberRow,
    borrowerNameRow,
    headerRow,
    ...historyData.map(tran => transactionArray(type, tran))
  ];
};
export const exportAsCsv = ({ historyData, loanNumber, borrowerName }, type) =>
  downloadCsv(
    transactionsToCsvContent(
      { historyData, loanNumber, borrowerName },
      type,
      removeRecalcColumns(type).map(c => c.header)
    ),
    "transaction_history"
  );

export const copyToClipboard = ({ historyData, loanNumber, borrowerName }) => {
  navigator.permissions
    .query({ name: "clipboard-write" })
    .then(result => {
      if (result.state === "granted" || result.state === "prompt") {
        navigator.clipboard
          .writeText(
            transactionsToCsvContent({ historyData, loanNumber, borrowerName })
          )
          .then(() => console.log("Copied to clipboard")); // eslint-disable-line no-console
      } else {
        logger.error("Unable to copy to clipboard");
      }
    })
    .catch(() => {
      logger.error("Unable to copy to clipboard");
    });
};

const dateTypeOptions = [
  { key: "Posted Date", text: "Posted Date", value: "created_datetime" },
  { key: "Effective Date", text: "Effective Date", value: "effective_date" }
];

export class TransactionHistoryTableBase extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      showModal: false
    };
    this.onSelect = this.onSelect.bind(this);
    this.updateTable = this.updateTable.bind(this);
    this.openPrintView = this.openPrintView.bind(this);
    this.lookup = this.lookup.bind(this);
  }

  componentDidMount() {
    this.lookup();
  }

  componentDidUpdate(prevProps) {
    const { type, pagination = {} } = this.props;

    const nextPagination = pagination[`${type}TransactionHistory`];
    const prevPagination = (prevProps.pagination || {})[
      `${type}TransactionHistory`
    ];

    if (paginationChanged(prevPagination, nextPagination)) {
      this.lookup();
    }
  }

  componentWillUnmount() {
    const { dispatch, type } = this.props;
    const { transactionHistoryReset } = actionCreators[type];

    dispatch(transactionHistoryReset());
  }

  async onSelect(transaction) {
    const { dispatch } = this.props;
    const { transactionHistoryPopulate } = actionCreators.modal;

    try {
      const { data } = await LoanTransactionHistory.read({
        loan_uuid: transaction.loan_uuid,
        transactionUuid: transaction.uuid,
        include_recalculation_log: true
      });
      const transactionData = (data && data.recalculation_log) || [];
      dispatch(
        transactionHistoryPopulate({
          data: transactionData.map(t => t.transaction_item)
        })
      );
      this.setState({ showModal: true });
    } catch (e) {
      logger.error(e);
    }
  }

  copy = ({ historyData, loanNumber, borrowerName }) => {
    this.textArea.select();
    copyToClipboard({ historyData, loanNumber, borrowerName });
  };

  lookup(overrides = {}) {
    const {
      dispatch,
      loanUuid,
      type,
      showExtraDetailed,
      filter,
      dateType,
      startDate,
      endDate,
      pagination,
      sortField,
      sortDirection
    } = this.props;
    const { transactionHistoryPopulate } = actionCreators[type];

    const tranCodeGroup =
      type === "detailed" && showExtraDetailed ? "all" : type;

    const queryParams = {
      tran_code_group: tranCodeGroup,
      sort_field: sortField,
      sort_direction: sortDirection
    };
    if (filter) {
      queryParams.lookup = filter;
    }
    if (dateType && startDate && endDate) {
      Object.assign(queryParams, {
        date_column: dateType,
        start_date: startDate,
        end_date: endDate
      });
    }
    if (pagination && pagination[`${type}TransactionHistory`]) {
      Object.assign(queryParams, {
        page_number: pagination[`${type}TransactionHistory`].number,
        page_size: pagination[`${type}TransactionHistory`].size
      });
    }

    Object.assign(queryParams, overrides);

    const {
      sort_field: keyValue,
      sort_direction: sortValue,
      ...withoutSort
    } = queryParams;
    const queryParamsOrder = Object.assign(withoutSort, {
      order_by:
        keyValue === "effective_date"
          ? `${keyValue},${sortValue},created_datetime,-1`
          : `${keyValue},${sortValue}`
    });

    // TODO: find out why there is no bafs_account_number
    lookupAccount(
      dispatch,
      loanUuid,
      queryParamsOrder,
      transactionHistoryPopulate
    );
  }

  openPrintView(currentPage = true) {
    const {
      filter,
      loanUuid,
      type,
      dateType,
      startDate,
      endDate,
      sortField,
      sortDirection,
      showExtraDetailed,
      pagination,
      borrowerName,
      loanData
    } = this.props;

    const params = {
      nonav: true,
      table: type,
      sortField,
      sortDirection,
      filter
    };
    if (type === "detailed") {
      params.showExtraDetailed = showExtraDetailed;
    }
    if (dateType && startDate && endDate) {
      Object.assign(params, {
        dateType,
        startDate,
        endDate
      });
    }
    const transactionHistoryType = `${type}TransactionHistory`;
    if (pagination && pagination[transactionHistoryType]) {
      Object.assign(params, {
        page_number: currentPage
          ? pagination[transactionHistoryType].number
          : 0,
        page_size: currentPage ? pagination[transactionHistoryType].size : 999
      });
    }

    if (borrowerName) {
      Object.assign(params, { borrowerName });
    }

    if (loanData) {
      Object.assign(params, {
        loanNumber: getAccountNumber(loanData).slice(3)
      });
    }

    window.open(
      `/loan_management/transaction_history/${loanUuid}/print${ConcatQueryParams(
        params
      )}`
    );
  }

  updateTable(value, updateType) {
    const { dispatch, startDate, endDate, type, dateType } = this.props;

    const {
      transactionHistoryFilter,
      transactionHistoryTypeDate,
      transactionHistoryStartDate,
      transactionHistoryEndDate,
      transactionHistoryExtraDetailed,
      transactionHistorySortField,
      transactionHistorySortDirection
    } = actionCreators[type];

    switch (updateType) {
      case "filter":
        dispatch(transactionHistoryFilter(value));
        this.lookup({ lookup: value });
        break;
      case "dateType":
        dispatch(transactionHistoryTypeDate(value));
        if (startDate && endDate) {
          this.lookup({ date_column: value });
        }
        break;
      case "startDate":
        dispatch(transactionHistoryStartDate(value));
        if (dateType && endDate) {
          this.lookup({ start_date: value });
        }
        break;
      case "endDate":
        dispatch(transactionHistoryEndDate(value));
        if (startDate && dateType) {
          this.lookup({ end_date: value });
        }
        break;
      case "showExtraDetailed":
        dispatch(transactionHistoryExtraDetailed(value));
        if (type === "detailed") {
          this.lookup({ tran_code_group: value ? "all" : type });
        }
        break;
      case "sortField":
        dispatch(transactionHistorySortField(value));
        this.lookup({ sort_field: value, sort_direction: -1 });
        break;
      case "sortDirection":
        dispatch(transactionHistorySortDirection(value));
        this.lookup({ sort_direction: value });
        break;
      default:
        break;
    }
  }

  render() {
    const {
      filter,
      historyData,
      borrowerName,
      lastPage,
      dateType,
      startDate,
      endDate,
      loanData,
      type,
      categoryFilter,
      showExtraDetailed,
      pagination,
      modalHistoryData = [],
      modalHistoryDataLastPage,
      dispatch
    } = this.props;

    const { showModal } = this.state;
    const loanNumber = getAccountNumber(loanData).slice(3);
    const modalPagination = pagination.modalTransactionHistory || {
      number: 0,
      size: 10
    };

    const controls = (
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column width={8}>
            <textarea
              ref={textarea => {
                this.textArea = textarea;
              }}
              style={{
                height: "1px",
                left: "-1000px",
                position: "absolute",
                width: "1px"
              }}
              defaultValue={transactionsToCsvContent({
                historyData,
                loanNumber,
                borrowerName
              })}
            />
            <Input
              name="searchInput"
              onChange={(e, { value }) => this.updateTable(value, "filter")}
              placeholder="Search"
              value={filter}
            />
            <Dropdown
              selection
              name="dateType"
              value={dateType}
              placeholder="Date Type"
              options={dateTypeOptions}
              onChange={(e, { value }) => this.updateTable(value, "dateType")}
            />
            <DateInput
              width={4}
              name="startDate"
              placeholder="Start Date"
              value={startDate}
              onChange={(e, { value }) => this.updateTable(value, "startDate")}
            />
            <DateInput
              width={4}
              name="endDate"
              placeholder="End Date"
              value={endDate}
              onChange={(e, { value }) => this.updateTable(value, "endDate")}
            />
          </Grid.Column>
          <Grid.Column>
            <Button
              className="copy-button"
              floated="right"
              onClick={() =>
                this.copy({ historyData, loanNumber, borrowerName })
              }
            >
              Copy
            </Button>
            <Button
              floated="right"
              onClick={() =>
                exportAsCsv({ historyData, loanNumber, borrowerName }, type)
              }
            >
              Download CSV
            </Button>
            <Button floated="right" onClick={() => this.openPrintView(false)}>
              Print All
            </Button>
            <Button floated="right" onClick={() => this.openPrintView(true)}>
              Print
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );

    return (
      <>
        <div>
          {type === "detailed" && (
            <Form.Checkbox
              label="Include Interest Accumulation Transactions"
              checked={showExtraDetailed}
              onChange={(e, { checked }) =>
                this.updateTable(checked, "showExtraDetailed")
              }
            />
          )}
          <br />
          <HistoryTable
            type={type}
            controls={controls}
            onSelect={this.onSelect}
            categoryFilter={categoryFilter}
            showRecalculatedFields={type === "detailed"}
            lookup={this.lookup}
            key={`HistoryTable.${type}`}
            onSortChange={(key, value) => this.updateTable(value, key)}
          />
          <PaginationControls
            name={`${type}TransactionHistory`}
            pagination={pagination}
            lastPage={lastPage}
          />
        </div>
        <Modal
          open={showModal}
          closeIcon
          header="Transaction Recalculations"
          content={
            <>
              <HistoryTable
                suppressActions
                historyData={modalHistoryData.slice(
                  modalPagination.number * modalPagination.size,
                  (modalPagination.number + 1) * modalPagination.size
                )}
                type="modal"
              />
              <PaginationControls
                name="modalTransactionHistory"
                pagination={pagination}
                lastPage={modalHistoryDataLastPage}
              />
            </>
          }
          onClose={() => {
            dispatch({
              name: "modalTransactionHistory",
              type: "PAGINATION_INITIALIZE"
            });
            this.setState({ showModal: false });
          }}
          size="fullscreen"
        />
      </>
    );
  }
}

TransactionHistoryTableBase.propTypes = {
  dispatch: func.isRequired,
  filter: string.isRequired,
  historyData: arrayOf(object).isRequired,
  lastPage: bool,
  loanUuid: string,
  loanData: shape({}),
  dateType: string,
  startDate: string,
  endDate: string,
  type: string,
  categoryFilter: func,
  showExtraDetailed: bool,
  sortField: string,
  sortDirection: number,
  pagination: shape({}),
  modalHistoryData: arrayOf(object),
  modalHistoryDataLastPage: bool,
  borrowerName: string
};

const mapStateToProps = (state, ownProps) => ({
  ...state.LoanManagementTransactionHistory[ownProps.type],
  loanUuid: state.LoanManagementReducer.loan.uuid,
  loanData: state.LoanManagementReducer.loan,
  pagination: state.PaginationReducer,
  modalHistoryData: (state.LoanManagementTransactionHistory.modal || {})
    .historyData,
  modalHistoryDataLastPage: (state.LoanManagementTransactionHistory.modal || {})
    .lastPage
});

const TransactionHistoryTable = connect(mapStateToProps)(
  TransactionHistoryTableBase
);
export default TransactionHistoryTable;
