import { HighFrictionDialog } from "@bafsllc/ui-shared";
import { PropTypes } from "prop-types";
import { useState, useEffect } from "react";

export default function ChangeAnalysisStatusModal({
  open,
  setModalIsOpen,
  confirmationAction
}) {
  const [renderModal, setRenderModal] = useState(false);

  useEffect(() => {
    if (!open || (open && renderModal)) {
      return;
    }
    setRenderModal(true);
  }, [renderModal, open]);

  function handleHidden() {
    setRenderModal(false);
  }

  return (
    renderModal && (
      <HighFrictionDialog
        onDismiss={result => {
          setModalIsOpen(false);
          if (result === "affirmative") {
            confirmationAction();
          }
        }}
        onHidden={handleHidden}
        confirmationMessages={[
          { message: { id: "CREDIT_ANALYSIS_STATUS_CHANGE" } },
          {
            message: { id: "CREDIT_ANALYSIS_ACTION_REVERSE" },
            classes: { contentClasses: ["font-bold"] }
          },
          { message: { id: "CREDIT_ANALYSIS_TYPE_MESSAGE" } }
        ]}
        confirmationValue="CONFIRM"
        cancelButtonText={{ id: "CANCEL" }}
        confirmButtonText={{ id: "CONFIRM" }}
        headerTitle={{ id: "CHANGE_STATUS" }}
        size="tiny"
      />
    )
  );
}

ChangeAnalysisStatusModal.propTypes = {
  open: PropTypes.bool,
  setModalIsOpen: PropTypes.func,
  confirmationAction: PropTypes.func
};
