/* eslint-disable no-shadow */
import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { string } from "prop-types";
import CollateralObj from "./Collateral";
import {
  useLoanApplicationCollateralDetails,
  useLoanApplicationGuarantors
} from "../../../../store/models/loan-applications";

function CollateralHOC({ loanAppUuid, ...props }) {
  useLoanApplicationCollateralDetails({ loanAppUuid });
  useLoanApplicationGuarantors({ loanAppUuid });

  const { auth, LorCollateralReducer, LoanOriginationReducer } = useSelector(
    ({ auth, LorCollateralReducer, LoanOriginationReducer }) => ({
      auth,
      LorCollateralReducer,
      LoanOriginationReducer
    })
  );

  const { collateralAssetPagination } = useSelector(
    state => state.PaginationReducer
  );

  const {
    assetModal,
    assets,
    collatUuid,
    collateralModal,
    collaterals,
    confirmModal,
    entityAssets,
    isAdd,
    isOpen,
    loanReqUuid
  } = LorCollateralReducer;

  const { jwt } = auth;

  const { borrowers, collateralEntities, loanApp, loanRequests } =
    LoanOriginationReducer;

  const ctProps = useMemo(
    () => ({
      ...props,
      assetModal,
      assets,
      collateralModal,
      collaterals,
      collatUuid,
      confirmModal,
      entityAssets,
      isAdd,
      isOpen,
      jwt,
      loanApp,
      loanAppUuid,
      loanRequests,
      collateralEntities,
      loanReqUuid,
      borrowers,
      collateralAssetPagination
    }),
    [
      assetModal,
      assets,
      borrowers,
      collatUuid,
      collateralEntities,
      collateralModal,
      collaterals,
      confirmModal,
      entityAssets,
      isAdd,
      isOpen,
      jwt,
      loanApp,
      loanAppUuid,
      loanReqUuid,
      loanRequests,
      props,
      collateralAssetPagination
    ]
  );

  return <CollateralObj {...ctProps} dispatch={useDispatch()} />;
}

CollateralHOC.propTypes = {
  loanAppUuid: string.isRequired
};

export default CollateralHOC;
