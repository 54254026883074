import ApiEndpoints from "../../../ApiEndpoints";
import { FormatUrlV2 } from "../../../FormatUrl";
import { asyncFetchService } from "../../../FetchService";
import store from "../../../../store/index";

function attachInstitutionFilter(url) {
  const toAddParam = url.includes("?") ? "&" : "?";
  const currentInstitutionUuid =
    store.getState()?.LoanManagementReducer?.institution?.uuid ??
    store.getState()?.LosPhasesReducer?.entityDetails?.institution_uuid;
  if (!currentInstitutionUuid) return url;
  return url.concat(
    `${toAddParam}filter[institution_uuid]=in(${currentInstitutionUuid})`
  );
}

function attachBlastPagination(url, offset, limit) {
  const toAddParam = url.includes("?") ? "&" : "?";
  return url.concat(`${toAddParam}page[offset]=${offset}&page[limit]=${limit}`);
}

async function readAllSingleRiskScales() {
  const url = FormatUrlV2(ApiEndpoints.singleRisk);
  const urlWithInstitutionFilter = attachInstitutionFilter(url);
  const resp = await asyncFetchService("GET", urlWithInstitutionFilter);
  return resp;
}

async function readAllCreditAnalysis(type, loanUUID, offset, limit) {
  const url = FormatUrlV2(ApiEndpoints.creditAnalysis, { type, loanUUID });
  const urlWithInstitutionFilter = attachInstitutionFilter(url);
  const urlWithPagination = attachBlastPagination(
    urlWithInstitutionFilter,
    offset,
    limit
  );
  const resp = await asyncFetchService("GET", urlWithPagination);
  return resp;
}

async function readCurrentCreditAnalysisByLoan(loanUUID) {
  const url = FormatUrlV2(ApiEndpoints.singleRiskCreditAnalysisByLoanUUID, {
    loanUUID
  });
  const urlWithInstitutionFilter = attachInstitutionFilter(url);
  const resp = await asyncFetchService("GET", urlWithInstitutionFilter);
  return resp;
}

async function readCurrentCreditAnalysisByEntity(entityUUID) {
  const url = FormatUrlV2(ApiEndpoints.singleRiskCreditAnalysisByEntityUUID, {
    entityUUID
  });
  const urlWithInstitutionFilter = attachInstitutionFilter(url);
  const resp = await asyncFetchService("GET", urlWithInstitutionFilter);
  return resp;
}

async function asyncAdd(body) {
  const url = FormatUrlV2(ApiEndpoints.creditAnalysis);
  const urlWithInstitutionFilter = attachInstitutionFilter(url);
  const rsp = await asyncFetchService("POST", urlWithInstitutionFilter, body);
  return rsp;
}

async function updateOne(uuid, body) {
  const url = FormatUrlV2(ApiEndpoints.creditAnalysisByUUID, { uuid });
  const urlWithInstitutionFilter = attachInstitutionFilter(url);
  const res = await asyncFetchService("PATCH", urlWithInstitutionFilter, body);
  return res;
}

async function deleteOne(uuid) {
  const url = FormatUrlV2(ApiEndpoints.creditAnalysisByUUID, { uuid });
  const urlWithInstitutionFilter = attachInstitutionFilter(url);
  const res = await asyncFetchService("DELETE", urlWithInstitutionFilter);
  return res;
}

export default {
  readAllSingleRiskScales,
  asyncAdd,
  readAllCreditAnalysis,
  updateOne,
  readCurrentCreditAnalysisByLoan,
  readCurrentCreditAnalysisByEntity,
  deleteOne
};
