import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useToast } from "@bafsllc/ui-shared";

import BafsLogo from "../../../../assets/images/bafs-logo-color.svg";

function LoginForm({
  handleLogin,
  handleFieldChange,
  openModal,
  submitEnabled,
  loading,
  loginFailed,
  errorMessage
}: LoginFormProps) {
  const dispatch = useDispatch();
  const { addToast } = useToast();

  useEffect(() => {
    if (loginFailed && errorMessage) {
      addToast({
        title: { id: "LOGIN_ERROR" },
        message: { id: errorMessage },
        variant: "error",
        options: {
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          toastId: errorMessage,
          onClose: () => {
            dispatch({ type: "LOGIN_CLEAR_ERROR" });
          }
        }
      });
    }
  }, [addToast, dispatch, loginFailed, errorMessage]);

  return (
    <div className="flex justify-center sm:items-center h-[100vh]" role="main">
      <div className="w-100vw sm:w-[420px] p-6">
        <div className="mx-auto mb-12 w-[120px]">
          <BafsLogo />
        </div>
        <form onSubmit={handleLogin} role="form">
          <input
            autoFocus
            onChange={e => handleFieldChange(e, "username")}
            placeholder="Username"
            className="w-100 p-3 mb-6 border border-neutral-300 rounded"
            disabled={loading}
          />
          <input
            onChange={e => handleFieldChange(e, "password")}
            type="password"
            placeholder="Password"
            className="w-100 p-3 mb-6 border border-neutral-300 rounded"
            disabled={loading}
          />

          <div className="flex items-center justify-end">
            <button
              type="button"
              role="button"
              className="ui button tertiary"
              onClick={openModal}
            >
              <FormattedMessage id="FORGOT_PASSWORD" />
            </button>
            <button
              type="submit"
              role="button"
              className="ui button ml-4"
              disabled={!submitEnabled || loading}
            >
              <FormattedMessage id={loading ? "SIGNING_IN" : "SIGN_IN"} />
            </button>
          </div>
        </form>

        <div className="mt-12 text-center">
          <p className="text-neutral-400 mb-1">
            Need assistance? Call 1-844-577-2237
          </p>
          <p className="text-neutral-400 text-sm ">
            &copy; 2014&ndash;2023 Business Alliance Financial Services, LLC
          </p>
        </div>
      </div>
    </div>
  );
}

interface LoginFormProps {
  handleLogin: (event) => void;
  handleFieldChange: (event, name: string) => void;
  openModal: (event) => void;
  submitEnabled: boolean;
  loading: boolean;
  loginFailed?: string;
  errorMessage?: string;
}

export default LoginForm;
