import React, { useEffect, useState, useRef } from "react";
import { useCreateEntityMutation } from "../../../../../../services/RTKQuery/entities";
import { Entity } from "../../../../../../services/RTKQuery/types";

export const CreateTest = ({
  institutionUuid,
  setUuidOfCreatedRecord,
  setStatusInParent
}) => {
  const statusUnknown = "❓Unknown Status";
  const statusPass = "✅ Pass";
  const statusFail = "❌ Fail";

  const [statusCreateState, setStatusCreateState] = useState(statusUnknown);

  const itemUuid = useRef("");

  const [createEntityMutation] = useCreateEntityMutation();
  useEffect(() => {
    const exampleBody: Partial<Entity> = {
      annual_income: 153227,
      annual_revenue: 312000,
      average_monthly_payroll: 8300,
      business_operating_status: "Active",
      // @ts-ignore
      business_start_date: "2004-01-01",
      company_name: "Weyland-Yutani Corporation",
      credit_bureau: "Experian",
      credit_score_equifax: 760,
      // @ts-ignore
      credit_score_equifax_date: "2022-01-01",
      // @ts-ignore
      dob: "1978-03-11",
      drivers_license_no: "A1234567",
      drivers_license_st: "CA",
      email: "arthur.dent@example.com",
      employer: "Weyland-Yutani Corporation",
      entity_type: "Individual",
      first_name: "Arthur",
      institution_id: 16,
      institution_uuid: institutionUuid,
      last_name: "Dent",
      // @ts-ignore
      last_tax_return_date: "2021-04-15",
      mobile_phone_no: "9876543210",
      operating_status: "Solvent",
      personal_annual_income: 153227,
      phone_no: "1234567890",
      phy_address_city: "Skaneateles",
      phy_address_country: "United States",
      phy_address_line1: "4022 Mill Road",
      phy_address_state: "NY",
      phy_address_zip: "13152",
      state_of_reg: "California",
      tin: "967864522",
      tin_type: "SSN"
    };

    createEntityMutation(exampleBody)
      .unwrap()
      .then(fulfilled => {
        // @ts-ignore
        itemUuid.current = fulfilled.data.uuid;
        setUuidOfCreatedRecord(itemUuid.current);
        setStatusCreateState(statusPass);
        setStatusInParent("Pass");
      })
      .catch(error => {
        console.error("Fail: useCreateEntityMutation");
        console.error("caught error: ", error);
        setStatusCreateState(statusFail);
        setStatusInParent("Fail");
      });
  }, [
    createEntityMutation,
    institutionUuid,
    setStatusInParent,
    setUuidOfCreatedRecord
  ]);

  return <div>{statusCreateState}</div>;
};
