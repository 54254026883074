import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import FetchService, { asyncFetchService } from "../../../FetchService";
import {
  ConcatQueryParams,
  RemoveNullKeys,
  PaginationParams
} from "../Utilities";
import { FormatUrlV2 } from "../../../FormatUrl";
import logger from "../../../logger";

function add(_, onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(callbackData, "object", false);

  const url = ApiEndpoints.baseUri + ApiEndpoints.assets;
  const convertedBody = RemoveNullKeys(body);
  FetchService("POST", url, onSuccess, onError, convertedBody, callbackData);
}

async function asyncAdd(body) {
  const url = ApiEndpoints.baseUri + ApiEndpoints.assets;
  const convertedBody = RemoveNullKeys(body);

  const rsp = await asyncFetchService("POST", url, convertedBody);
  return rsp;
}

function get(_, onSuccess, onError, uuid, queryParams, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string", false);
  TypeCheck(queryParams, "object", false);

  let url = ApiEndpoints.baseUri + ApiEndpoints.assets;
  if (uuid) {
    url = FormatUrlV2(ApiEndpoints.asset, { uuid });
  }
  if (queryParams) {
    url += ConcatQueryParams(queryParams);
  }

  FetchService("GET", url, onSuccess, onError, null, callbackData);
}

function update(_, onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");

  const url = FormatUrlV2(ApiEndpoints.asset, { uuid: body.uuid });

  const convertedBody = RemoveNullKeys(body);
  FetchService("PUT", url, onSuccess, onError, convertedBody, callbackData);
}

async function asyncUpdate(body) {
  TypeCheck(body.uuid, "string", true);

  const url = FormatUrlV2(ApiEndpoints.asset, { uuid: body.uuid });
  const convertedBody = RemoveNullKeys(body);
  const rsp = await asyncFetchService("PUT", url, convertedBody);
  return rsp;
}

async function asyncRead(filters, pagination) {
  const funcName = "====  Assets.asyncRead(filters)  ====";
  logger.debug(funcName, [filters]);
  TypeCheck(filters.assetUuid, "string", false);

  const filterCount = Object.keys(filters).length;
  if ((filters.assetUuid && filterCount > 1) || filterCount === 0) {
    logger.error("Error: Invalid Filter Combination");
    // TODO: [#5740] Put a checkable status here.
    const errorRsp = {};
    return errorRsp;
  }

  let url;
  if (filters.assetUuid) {
    const endpoint = ApiEndpoints.asset;
    let appendToUrl = ConcatQueryParams({ deleted: false });
    if (pagination) {
      appendToUrl = PaginationParams(appendToUrl, pagination);
    }
    url = FormatUrlV2(endpoint, { uuid: filters.assetUuid }) + appendToUrl;
  } else {
    const endpoint = ApiEndpoints.assets;
    let appendToUrl = ConcatQueryParams({ ...filters, deleted: false });
    if (!pagination) {
      appendToUrl = ConcatQueryParams({
        ...filters,
        deleted: false,
        page_number: 0,
        page_size: 300
      });
    }
    if (pagination) {
      appendToUrl = PaginationParams(appendToUrl, pagination);
    }
    url = FormatUrlV2(endpoint) + appendToUrl;
  }
  return asyncFetchService("GET", url);
}

async function readByEntityUuid(filter) {
  TypeCheck(filter, "object");
  const url = FormatUrlV2(ApiEndpoints.assetsGetAllByEntityUuid, {
    entityUuid: filter.entityUuid
  });
  const resp = await asyncFetchService("GET", url);
  return resp;
}

async function readRealEstateAsset(filter) {
  TypeCheck(filter, "object");
  const url = FormatUrlV2(ApiEndpoints.entityRealEstateAsset, {
    uuid: filter.asset_uuid
  });
  const resp = await asyncFetchService("GET", url);
  return resp;
}

async function readPersonalPropertyAsset(filter) {
  TypeCheck(filter, "object");
  const url = FormatUrlV2(ApiEndpoints.entityPersonalPropertyAsset, {
    uuid: filter.asset_uuid
  });
  const resp = await asyncFetchService("GET", url);
  return resp;
}

async function readAccountAsset(filter) {
  TypeCheck(filter, "object");
  const url = FormatUrlV2(ApiEndpoints.entityAccountAsset, {
    uuid: filter.asset_uuid
  });
  const resp = await asyncFetchService("GET", url);
  return resp;
}

async function readRealEstateByAssetUuid(filter) {
  TypeCheck(filter, "object");
  const url = FormatUrlV2(ApiEndpoints.entityRealEstateByAssetUuid, {
    uuid: filter.asset_uuid
  });
  const resp = await asyncFetchService("GET", url);
  return resp;
}

async function readPersonalPropertyByAssetUuid(filter) {
  TypeCheck(filter, "object");
  const url = FormatUrlV2(ApiEndpoints.entityPersonalPropertyByAssetUuid, {
    uuid: filter.asset_uuid
  });
  const resp = await asyncFetchService("GET", url);
  return resp;
}

async function readAccountByAssetUuid(filter) {
  TypeCheck(filter, "object");
  const url = FormatUrlV2(ApiEndpoints.entityAccountByAssetUuid, {
    uuid: filter.asset_uuid
  });
  const resp = await asyncFetchService("GET", url);
  return resp;
}

async function asyncRealEstateAssetUpdate(body, filter) {
  TypeCheck(filter.uuid, "string", true);
  const url = FormatUrlV2(ApiEndpoints.entityRealEstateAsset, {
    uuid: filter.uuid
  });
  const convertedBody = {
    ...RemoveNullKeys(body),
    institution_uuid: filter.institution_uuid
  };
  const rsp = await asyncFetchService("PUT", url, convertedBody);
  return rsp;
}

async function asyncPersonalPropertyAssetUpdate(body, filter) {
  TypeCheck(filter.uuid, "string", true);
  const url = FormatUrlV2(ApiEndpoints.entityPersonalPropertyAsset, {
    uuid: filter.uuid
  });

  const { securities, ...rest } = body;
  const convertedBody = {
    ...RemoveNullKeys(rest),
    institution_uuid: filter.institution_uuid
  };

  if (securities && securities.length) {
    convertedBody.securities = securities.map(security =>
      RemoveNullKeys(security)
    );
  }

  const rsp = await asyncFetchService("PUT", url, convertedBody);
  return rsp;
}

async function asyncAccountAssetUpdate(body, filter) {
  TypeCheck(filter.uuid, "string", true);
  const securities = body.securities.map(security =>
    RemoveNullKeys({
      ...security,
      number_of_shares: parseInt(security.number_of_shares, 10)
    })
  );

  const url = FormatUrlV2(ApiEndpoints.entityAccountAsset, {
    uuid: filter.uuid
  });
  const convertedBody = {
    ...RemoveNullKeys({ ...body, securities }),
    institution_uuid: filter.institution_uuid
  };
  const rsp = await asyncFetchService("PUT", url, convertedBody);
  return rsp;
}

async function asyncRealEstateAssetAdd(body, uuid, institutionUuid) {
  const url = FormatUrlV2(ApiEndpoints.addEntityRealEstateAsset);
  const convertedBody = {
    ...RemoveNullKeys(body),
    asset_uuid: uuid,
    institution_uuid: institutionUuid
  };
  const rsp = await asyncFetchService("POST", url, convertedBody);
  return rsp;
}

async function asyncPersonalPropertyAssetAdd(body, assetUuid, institutionUuid) {
  const url = FormatUrlV2(ApiEndpoints.addEntityPersonalPropertyAsset);
  const convertedBody = {
    ...RemoveNullKeys(body),
    asset_uuid: assetUuid,
    institution_uuid: institutionUuid
  };
  const rsp = await asyncFetchService("POST", url, convertedBody);
  return rsp;
}

async function asyncAccountAssetAdd(body, assetUuid, institutionUuid) {
  const securities = body.securities.map(security =>
    RemoveNullKeys({
      ...security,
      number_of_shares: parseInt(security.number_of_shares, 10)
    })
  );

  const url = FormatUrlV2(ApiEndpoints.addEntityAccountAsset);
  const convertedBody = {
    ...RemoveNullKeys({ ...body, securities }),
    asset_uuid: assetUuid,
    institution_uuid: institutionUuid
  };
  const rsp = await asyncFetchService("POST", url, convertedBody);
  return rsp;
}

export default {
  add,
  asyncAdd,
  get,
  update,
  asyncRead,
  asyncUpdate,
  readByEntityUuid,
  readRealEstateAsset,
  readPersonalPropertyAsset,
  readAccountAsset,
  readRealEstateByAssetUuid,
  readPersonalPropertyByAssetUuid,
  readAccountByAssetUuid,
  asyncPersonalPropertyAssetUpdate,
  asyncRealEstateAssetUpdate,
  asyncAccountAssetUpdate,
  asyncRealEstateAssetAdd,
  asyncPersonalPropertyAssetAdd,
  asyncAccountAssetAdd
};
