import { useState, useEffect, useCallback } from "react";

export default function useEditableRelationshipCopy(object) {
  const [edits, setEdits] = useState(null);
  useEffect(() => {
    if (
      object &&
      (!edits || object.relationship_uuid !== edits.relationship_uuid)
    ) {
      setEdits({ ...object });
    }
    if (!object && edits) {
      setEdits(null);
    }
  }, [object, edits]);

  const isChangeOnStartDate = key => key === "start_date";
  const makeEdits = useCallback(
    (key, value) => {
      if (isChangeOnStartDate(key)) {
        const change = {
          [key]: value,
          end_date: null
        };
        setEdits(editsState => ({ ...editsState, ...change }));
      } else {
        setEdits(editsState => ({ ...editsState, [key]: value }));
      }
    },
    [setEdits]
  );

  return [edits, makeEdits];
}
