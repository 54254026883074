import { blastApi, Tag } from "./blastApi";
import { ListResponse, PaginatedQueryOptions, SingleResponse } from "./types";
import { PaymentSchedule } from "./ModelTypes/paymentSchedule"; // Assuming the correct path
import {
  extractData,
  createSingleTag,
  createListTags,
  filteredQuery
} from "./utilities";

const servicePath = "/loans/v1"; // Adjust the service path if necessary

export interface PaymentScheduleQueryOptions extends PaginatedQueryOptions {
  institution_uuid?: string;
  loan_uuid?: string;
}

export interface PaymentScheduleParameters {
  uuid?: string;
  loanUuid: string;
  filters?: PaymentScheduleQueryOptions;
}

const extendedApi = blastApi.injectEndpoints({
  endpoints: build => ({
    paymentSchedule: build.query<PaymentSchedule, PaymentScheduleParameters>({
      query: options => {
        const { loanUuid, uuid } = options;
        return `${servicePath}/${loanUuid}/schedules/${uuid}`;
      },
      providesTags: result => createSingleTag(result, Tag.PaymentSchedule),
      transformResponse: (response: SingleResponse<PaymentSchedule>) =>
        extractData<PaymentSchedule>(response)
    }),
    paymentSchedules: build.query<
      ListResponse<PaymentSchedule>,
      PaymentScheduleParameters
    >({
      query: options => {
        const { loanUuid, filters } = options;
        const url = `${servicePath}/${loanUuid}/schedules`;
        return filteredQuery(url, filters);
      },
      providesTags: results => createListTags(results, Tag.PaymentSchedule)
    }),
    createPaymentSchedule: build.mutation<
      SingleResponse<PaymentSchedule>,
      Partial<PaymentSchedule>
    >({
      query: paymentScheduleData => ({
        url: `${servicePath}/${paymentScheduleData.loan_uuid}/schedules`,
        method: "POST",
        body: paymentScheduleData
      }),
      invalidatesTags: [Tag.PaymentSchedule] // Optionally invalidate payment schedule cache
    }),
    updatePaymentSchedule: build.mutation<
      SingleResponse<PaymentSchedule>,
      Partial<PaymentSchedule>
    >({
      query: body => ({
        url: `${servicePath}/${body.loan_uuid}/schedules/${body.uuid}`,
        method: "PUT",
        body: body
      }),
      invalidatesTags: [Tag.PaymentSchedule]
    }),
    deletePaymentSchedule: build.mutation<
      SingleResponse<PaymentSchedule>,
      PaymentScheduleParameters
    >({
      query: ({ loanUuid, uuid }) => ({
        url: `${servicePath}/${loanUuid}/schedules/${uuid}`,
        method: "DELETE"
      }),
      invalidatesTags: [Tag.PaymentSchedule]
    })
  }),
  overrideExisting: false
});

export const {
  usePaymentScheduleQuery,
  usePaymentSchedulesQuery,
  useCreatePaymentScheduleMutation,
  useUpdatePaymentScheduleMutation,
  useDeletePaymentScheduleMutation
} = extendedApi;
