import PropTypes from "prop-types";
import React, { Component } from "react";

import TypeCheck from "typecheck-extended";
import { connect } from "react-redux";
import { Dimmer, Loader, Header, Grid } from "semantic-ui-react";
import AddFeeModal from "./components/AddFeeModal";
import { LoanAdditionalFees } from "../../../../../../../../../../services/ApiLib";
import {
  AmortizedFeeTable,
  BillToBorrowerFeeTable,
  DisplayOnlyFeesTable
} from "./components/TableComponents";
import logger from "../../../../../../../../../../services/logger";
// TODO: [#5972] For an unknown reason this:
// import { ErrorReport } from '../../../../../../../../../../services/ErrorReporter';
// is breaking a bunch of reducer/store dependent tests.
// For now this bypasses ErrorReport, so tests will succeed.
const toConsole = (thrownError, errorMsgForUser) => {
  if (thrownError) {
    logger.error(thrownError);
  }
  logger.error(errorMsgForUser);
};
const ErrorReport = { toConsole, toModal: toConsole };

export class AdditionalFeesTableObj extends Component {
  constructor(props) {
    super(props);
    this.deleteFee = this.deleteFee.bind(this);
  }

  componentDidMount() {
    this.getAdditionalFees();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch({
      type: "LOAN_MGMT_DETAILS_RESET_STATE"
    });
  }

  getAdditionalFees() {
    const { loanUuid } = this.props;
    const queryParams = { loan_uuid: loanUuid };
    // TODO: pagination not supported by LoanAdditionalFees.read
    LoanAdditionalFees.read(queryParams).then(rsp =>
      this.loadAdditionalFees(rsp)
    );
  }

  loadAdditionalFees(rsp) {
    const { dispatch } = this.props;
    if (rsp && !rsp.error) {
      dispatch({
        fees: rsp.data,
        type: "LOAN_MGMT_DETAILS_LOAD_ADDITIONAL_FEES"
      });
    } else {
      const thrownError = rsp?.error;
      const messageForUser = "Error: Could Not Load Additional Fees";
      ErrorReport.toModal(thrownError, messageForUser);
      dispatch({
        type: "LOAN_MGMT_DETAILS_END_LOADING_STATE"
      });
    }
  }

  handleDeletedRow(rsp) {
    const { dispatch, fees } = this.props;
    if (!rsp.error) {
      const updatedFees = [];
      fees.forEach(fee => {
        if (fee.uuid !== rsp.data.uuid) {
          updatedFees.push(fee);
        }
      });
      dispatch({
        fees: updatedFees,
        type: "LOAN_MGMT_DETAILS_LOAD_ADDITIONAL_FEES"
      });
    } else {
      const thrownError = rsp.error;
      const messageForUser = "Error: Could Not Remove Fee";
      ErrorReport.toModal(thrownError, messageForUser);
      this.getAdditionalFees();
    }
  }

  deleteFee(feeUuid) {
    TypeCheck(feeUuid, "string");

    LoanAdditionalFees.remove(feeUuid).then(rsp => this.handleDeletedRow(rsp));
  }

  render() {
    const { fees, institutionUuid, loading, loanUuid } = this.props;
    if (!loading) {
      return (
        <div>
          <Grid>
            <Grid.Row columns="2">
              <Grid.Column width="6">
                <Header as="h2" size="medium">
                  Additional Fees
                </Header>
              </Grid.Column>
              <Grid.Column width="10" textAlign="right">
                <AddFeeModal
                  institutionUuid={institutionUuid}
                  loanUuid={loanUuid}
                  interestRate={this.props.interestRate}
                  currentBalance={this.props.currentBalance}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <br />
          <DisplayOnlyFeesTable fees={fees} />
          <br />
          <BillToBorrowerFeeTable fees={fees} deleteFee={this.deleteFee} />
          <br />
          <AmortizedFeeTable fees={fees} deleteFee={this.deleteFee} />
        </div>
      );
    }
    return (
      <div>
        <Dimmer active inverted>
          <Loader inverted>Loading...</Loader>
        </Dimmer>
      </div>
    );
  }
}

AdditionalFeesTableObj.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fees: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  loanUuid: PropTypes.string,
  institutionUuid: PropTypes.string,
  interestRate: PropTypes.number,
  currentBalance: PropTypes.number,
  pagination: PropTypes.shape({
    number: PropTypes.number,
    size: PropTypes.number
  })
};

const mapStateToProps = state => ({
  fees: state.LoanManagementDetailsReducer.fees,
  loading: state.LoanManagementDetailsReducer.loading,

  pagination: state.PaginationReducer.default
});

const AdditionalFeesTable = connect(mapStateToProps)(AdditionalFeesTableObj);

export default AdditionalFeesTable;
