import React, { useState } from "react";
import { Button, Grid } from "semantic-ui-react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import SplitLayout from "../../components/SplitLayout";
import CurrentRateTable from "./components/CurrentRateTable";
import VariableRateTable from "./components/VariableRateTable";
import InterestHistoryTable from "./components/InterestHistoryTable";
import InterestChangeTable from "./components/InterestChangeTable";
import InterestScheduleList from "./components/InterestScheduleList";
import InterestEditModal from "./components/InterestEditModal";

function Interest({ loan, isCoreOfRecord, onUpdateLoan, isMonthEnd }) {
  const { data } = useSelector(state => state.loanInterestSchedules);
  const [modalOpen, setModalOpen] = useState(false);
  let activeInterests;
  let active;
  if (data.length) {
    activeInterests = data.filter(item => item.status === "Active");
    if (activeInterests.length === 1) active = { ...activeInterests[0] };
  }

  return (
    <>
      <Box>
        {!isCoreOfRecord && !isMonthEnd ? (
          <Button
            circular
            basic
            icon="edit"
            onClick={() => setModalOpen(true)}
            data-testid="edit-button"
          />
        ) : (
          ""
        )}{" "}
        <InterestEditModal
          loan={loan}
          onSave={changes => onUpdateLoan({ ...changes, uuid: loan.uuid })}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      </Box>
      <SplitLayout>
        <CurrentRateTable loan={loan} />
        <InterestHistoryTable loan={loan} />
        {loan.rate_type === "Variable" && [
          <VariableRateTable
            loan={loan}
            loanInterest={active}
            isMonthEnd={isMonthEnd}
          />,
          <InterestChangeTable
            loan={loan}
            loanInterest={active}
            isMonthEnd={isMonthEnd}
          />
        ]}
      </SplitLayout>
      <Grid padded>
        <Grid.Row>
          <Grid.Column>
            <InterestScheduleList loan={loan} isMonthEnd={isMonthEnd} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-right: 16px;
`;

Interest.propTypes = {
  loan: PropTypes.shape({
    rate_type: PropTypes.string,
    core_of_record: PropTypes.bool,
    uuid: PropTypes.string
  }).isRequired,
  isCoreOfRecord: PropTypes.bool.isRequired,
  onUpdateLoan: PropTypes.func.isRequired,
  isMonthEnd: PropTypes.bool
};

export default Interest;
