import React from "react";
import NumberFormat from "react-number-format";

function PercentFormat({
  value,
  dataTestId,
  defaultValue = "-"
}: PercentFormatProps) {
  return (
    <NumberFormat
      data-testid={dataTestId}
      displayType="text"
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
      value={value}
      suffix="%"
      defaultValue={defaultValue}
    />
  );
}

interface PercentFormatProps {
  value: number;
  dataTestId?: string;
  defaultValue?: string;
}

export default PercentFormat;
