/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";
import { Button, Form } from "semantic-ui-react";

import ApiEndpoints from "../../../../services/ApiEndpoints";

// TODO: Verify the original user password is correct

class PasswordChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      passwordConfirm: ""
    };
    this.handleEntropyCheck = this.handleEntropyCheck.bind(this);
    this.handleErrorMessage = this.handleErrorMessage.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleTyping = this.handleTyping.bind(this);
  }

  handleErrorMessage(message) {
    this.setState({
      statusMessage: message,
      statusMessageStyle: {
        color: "red",
        fontWeight: "bold"
      }
    });
  }

  handleTyping({ name, value }) {
    this.setState({ [name]: value });
  }

  handleEntropyCheck() {
    let entropyScore = 0;
    const { password } = this.state;
    const upperAlphas = password.match(/[A-Z]/) || "";
    if (upperAlphas.length > 0) {
      entropyScore += 1;
    }
    const lowerAlphas = password.match(/[a-z]/) || "";
    if (lowerAlphas.length > 0) {
      entropyScore += 1;
    }
    const digits = password.match(/\d/) || "";
    if (digits.length > 0) {
      entropyScore += 1;
    }
    const specials = password.match(/[^\w]|_/) || "";
    // Note: Non-word regex matches on many unicode chars ( i.e. accented letters)
    if (specials.length > 0) {
      entropyScore += 1;
    }
    if (password.length < 8) {
      entropyScore = 0;
    } else if (password.length > 19) {
      entropyScore += 3;
    }
    this.handlePasswordChange(password, entropyScore);
  }

  handlePasswordChange(password, entropyScore) {
    if (this.state.password !== this.state.passwordConfirm) {
      this.handleErrorMessage(
        "Your New Password and Password Confirmation fields do not match."
      );
      return;
    }
    if (entropyScore < 3) {
      this.handleErrorMessage(
        "Please check that your password meets the requirements listed above."
      );
      return;
    }
    let url =
      ApiEndpoints.baseUri + ApiEndpoints.authBase + ApiEndpoints.authUsers;
    url += this.props.userUuid;
    const myData = {
      password
    };
    const fetchParams = {
      method: "PUT",
      body: JSON.stringify(myData),
      headers: {
        Authorization: this.props.jwt,
        "Content-Type": "application/json"
      }
    };
    fetch(url, fetchParams)
      .then(response => {
        if (response.ok) {
          this.setState({
            statusMessage: "Password Successfully Updated",
            statusMessageStyle: {
              color: "green",
              fontWeight: "bold"
            },
            password: "",
            passwordConfirm: ""
          });
        } else {
          this.setState({
            statusMessage: "Error Updating Password",
            statusMessageStyle: {
              color: "red",
              fontWeight: "bold"
            }
          });
        }
      })
      .catch(() => {
        this.setState({
          statusMessage: "Error Submitting Password Change",
          statusMessageStyle: {
            color: "red",
            fontWeight: "bold"
          }
        });
      });
  }

  render() {
    let submitDisabled = true;
    if (this.state.password.length > 7) {
      submitDisabled = false;
    }
    return (
      <div>
        <h3>Change Password</h3>
        <p>
          All passwords must be at least 8 characters long. If your password is
          less than 20 characters, it must also include at least 3 of the
          following 4 character types: Lowercase, Uppercase, Number, and/or
          Symbol.
          <br />
          <span style={this.state.statusMessageStyle}>
            {this.state.statusMessage}
          </span>
        </p>
        <Form onSubmit={this.handleEntropyCheck}>
          <Form.Input
            label="New Password"
            name="password"
            value={this.state.password}
            placeholder="New Password"
            type="password"
            onChange={(event, data) => this.handleTyping(data)}
          />
          <Form.Input
            label="Confirm New Password"
            name="passwordConfirm"
            value={this.state.passwordConfirm}
            placeholder="Confirm New Password"
            type="password"
            onChange={(event, data) => this.handleTyping(data)}
          />
          <Button type="submit" disabled={submitDisabled}>
            Change Password
          </Button>
        </Form>
      </div>
    );
  }
}

PasswordChange.propTypes = {
  jwt: PropTypes.string.isRequired,
  userUuid: PropTypes.string.isRequired
};

export default PasswordChange;
