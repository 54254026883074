import TypeCheck from "typecheck-extended";
import ApiEndpoints from "../../../ApiEndpoints";
import FetchService, { asyncFetchService } from "../../../FetchService";
import logger from "../../../logger";
import { FormatUrlV2 } from "../../../FormatUrl";
import {
  ConcatQueryParams,
  RemoveNullKeys,
  PaginationParams
} from "../Utilities";

function get(onSuccess, onError, uuid, queryParams, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string", false);
  TypeCheck(queryParams, "object", false);
  TypeCheck(callbackData, "object", false);

  let params = "";
  if (queryParams) {
    params = ConcatQueryParams(queryParams);
  }

  let url = ApiEndpoints.baseUri + ApiEndpoints.loans + params;
  if (uuid) {
    url = FormatUrlV2(ApiEndpoints.loans, { uuid }) + params;
  }
  FetchService("GET", url, onSuccess, onError, null, callbackData);
}

function add(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  const url = ApiEndpoints.baseUri + ApiEndpoints.loans;
  const convertedBody = RemoveNullKeys(body);
  FetchService("POST", url, onSuccess, onError, convertedBody, callbackData);
}

async function asyncRemove(uuid) {
  TypeCheck(uuid, "string", true);
  const url = FormatUrlV2(ApiEndpoints.loans, { uuid });
  const rsp = await asyncFetchService("DELETE", url);
  return rsp;
}

function finalize(onSuccess, onError, loanUuid, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  let url = ApiEndpoints.baseUri + ApiEndpoints.loanFinalize;
  if (loanUuid) {
    url = FormatUrlV2(ApiEndpoints.loanFinalize, { loan_uuid: loanUuid });
  }
  const convertedBody = RemoveNullKeys(body);
  FetchService("POST", url, onSuccess, onError, convertedBody, callbackData);
}

function update(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  const url = FormatUrlV2(ApiEndpoints.loansPut, { uuid: body.uuid });
  const convertedBody = RemoveNullKeys(body);

  FetchService("PUT", url, onSuccess, onError, convertedBody, callbackData);
}

async function asyncRead(filters, pagination = null) {
  const funcName = "====  Loans.asyncRead(filters)  ====";
  logger.debug(funcName, [filters]);
  TypeCheck(filters.loanUuid, "string", false);
  const filterCount = Object.keys(filters).length;
  if ((filters.loanUuid && filterCount > 1) || filterCount === 0) {
    logger.error("Error: Invalid Filter Combination");
    // TODO: [#5740] Put a checkable status here.
    const errorRsp = {};
    return errorRsp;
  }
  let url;
  if (filters.loanUuid) {
    const endpoint = ApiEndpoints.loan;
    const appendToUrl = ConcatQueryParams({ deleted: false });
    url = FormatUrlV2(endpoint, { uuid: filters.loanUuid }) + appendToUrl;
  } else if (pagination) {
    const endpoint = ApiEndpoints.loans;
    let params = ConcatQueryParams({ ...filters });
    params = PaginationParams(params, pagination);
    url = FormatUrlV2(endpoint) + params;
  } else {
    const endpoint = ApiEndpoints.loans;
    const appendToUrl = ConcatQueryParams({ ...filters, deleted: false });
    url = FormatUrlV2(endpoint) + appendToUrl;
  }
  return asyncFetchService("GET", url);
}

async function asyncLoansGetAllByLoanAppUuid({ loanAppUuid }) {
  TypeCheck(loanAppUuid, "string", true);
  const url = FormatUrlV2(ApiEndpoints.loansGetAllByLoanAppUuid, {
    loan_app_uuid: loanAppUuid
  });
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

async function asyncUpdate(filters, body) {
  TypeCheck(filters.loanUuid, "string", true);

  const url = FormatUrlV2(ApiEndpoints.loansPut, { uuid: filters.loanUuid });
  const convertedBody = RemoveNullKeys(body);

  const rsp = await asyncFetchService("PUT", url, convertedBody);
  return rsp;
}

function runAllNightlyProcess(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  const url = ApiEndpoints.baseUri + ApiEndpoints.nightlyProcess;
  const convertedBody = RemoveNullKeys(body);
  FetchService("POST", url, onSuccess, onError, convertedBody, callbackData);
}

function runBillsNightlyProcess(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  const url = ApiEndpoints.baseUri + ApiEndpoints.nightlyBills;
  const convertedBody = RemoveNullKeys(body);
  FetchService("POST", url, onSuccess, onError, convertedBody, callbackData);
}

async function undoNightlyAccruals(body) {
  const url = ApiEndpoints.baseUri + ApiEndpoints.undoNightlyAccruals;
  const convertedBody = RemoveNullKeys(body);
  const rsp = await asyncFetchService("POST", url, convertedBody, "", false);
  return rsp;
}

async function getSnapshots(pagination, uuid) {
  TypeCheck(uuid, "string", false);
  const queryParams = {
    month_end_parent_uuid: uuid,
    include_snapshot: true,
    order_by: "month_end_date,-1"
  };
  let params = "";
  params = ConcatQueryParams(queryParams);
  params = PaginationParams(params, pagination);
  const url =
    FormatUrlV2(ApiEndpoints.getSnapshots, { loan_uuid: uuid }) + params;
  const resp = await asyncFetchService("GET", url);
  return resp;
}

async function searchLoans(queryParams, signal, pagination) {
  TypeCheck(queryParams, "object", false);
  TypeCheck(signal, "object", false);
  let params = "";
  if (queryParams) {
    params = ConcatQueryParams(queryParams);
  }
  params = PaginationParams(params, pagination);
  const url = ApiEndpoints.baseUri + ApiEndpoints.loans + params;
  const resp = await asyncFetchService("GET", url, null, signal);
  return resp;
}

async function asyncAdd(body) {
  const url = ApiEndpoints.baseUri + ApiEndpoints.loans;
  const convertedBody = RemoveNullKeys(body);
  const rsp = await asyncFetchService("POST", url, convertedBody);
  return rsp;
}

async function asyncAddBlank(filters) {
  TypeCheck(filters.loanUuid, "string", true);

  const url = FormatUrlV2(ApiEndpoints.loan_apps_create_blank, {
    loan_app_uuid: filters.loanUuid
  });
  return asyncFetchService("POST", url, null);
}

async function readCountByClass(classType) {
  const funcName = "====  Loans.readCountByClass(filters)  ====";
  logger.debug(funcName);
  TypeCheck(classType, "string", true);

  const url = FormatUrlV2(ApiEndpoints.loansGetCountbyClass, { classType });

  const rsp = await asyncFetchService("GET", url, null, null, false);

  return rsp;
}

async function readByEntityAndInstitution(entityUuid, institutionUuid) {
  TypeCheck(entityUuid, "string", true);
  TypeCheck(institutionUuid, "string", true);

  const url = FormatUrlV2(ApiEndpoints.loansGetAllByEntityAndInstUuid, {
    entityUuid,
    institutionUuid
  });

  const rsp = await asyncFetchService("GET", url);

  return rsp;
}

async function isParticipationBought(loanUuid = "", loanDetails = undefined) {
  TypeCheck(loanUuid, "string", false);
  TypeCheck(loanDetails, "object", false);

  let loanData;
  if (loanDetails) {
    loanData = loanDetails;
  } else if (loanUuid) {
    const url = FormatUrlV2(ApiEndpoints.loan, { uuid: loanUuid });
    const loanRsp = await asyncFetchService("GET", url);
    loanData = loanRsp ? loanRsp.data : loanData;
  } else {
    logger.error("Error: Must pass loanUuid or loanDetails.");
  }

  const noLeadParticipation =
    loanData?.participation_bought &&
    (loanData.participation_parent_uuid == null ||
      loanData.participation_parent_uuid === "");

  if (loanData && loanData.participation_bought) {
    return {
      investorInstitutionUuid: loanData.institution_uuid,
      leadLoanUuid: loanData.participation_parent_uuid,
      noLeadParticipation,
      participationBought: true
    };
  }

  return {
    investorInstitutionUuid: "",
    leadLoanUuid: "",
    noLeadParticipation: false,
    participationBought: false
  };
}

export default {
  add,
  asyncAdd,
  asyncAddBlank,
  asyncRead,
  asyncLoansGetAllByLoanAppUuid,
  asyncUpdate,
  finalize,
  get,
  getSnapshots,
  isParticipationBought,
  readByEntityAndInstitution,
  readCountByClass,
  runAllNightlyProcess,
  runBillsNightlyProcess,
  undoNightlyAccruals,
  searchLoans,
  update,
  asyncRemove
};
