/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Modal } from "semantic-ui-react";
import IndividualContactInfo from "../Individual";
import OtherContactInfo from "../Other";

export default function ContactInfoContent({ entityType }) {
  const dispatch = useDispatch();

  function handleConvertAddress() {
    dispatch({
      type: "ENTITY_MODAL_TOGGLE_ADDRESS"
    });
  }

  return entityType === "Individual" ? (
    <Modal.Content>
      <IndividualContactInfo handleConvertAddress={handleConvertAddress} />
    </Modal.Content>
  ) : (
    <Modal.Content>
      <OtherContactInfo handleConvertAddress={handleConvertAddress} />
    </Modal.Content>
  );
}

ContactInfoContent.propTypes = {
  entityType: PropTypes.string.isRequired
};
