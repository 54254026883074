import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import FetchService, { asyncFetchService } from "../../../FetchService";
import logger from "../../../logger";
import {
  ConcatQueryParams,
  PaginationParams,
  RemoveNullKeys
} from "../Utilities";
import { FormatUrlV2 } from "../../../FormatUrl";

// This is deprecated. Use async Institutions.asyncRead() instead.
function get(onSuccess, onError, pagination, uuid, queryParams, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string", false);
  TypeCheck(queryParams, "object", false);
  const queryParamsExist = queryParams && Object.keys(queryParams).length > 0;

  if (uuid && queryParamsExist) {
    onError("Error: Invalid Filter Combination");
  }

  let appendToUrl = "";

  const baseUrl = uuid
    ? FormatUrlV2(ApiEndpoints.institution, { uuid })
    : FormatUrlV2(ApiEndpoints.institutions);

  if (queryParamsExist) {
    appendToUrl = ConcatQueryParams(queryParams);
  }
  if (pagination) {
    appendToUrl = PaginationParams(appendToUrl, pagination);
  }
  const url = baseUrl + appendToUrl;

  FetchService("GET", url, onSuccess, onError, null, callbackData);
}

async function asyncRead(filters) {
  const funcName = "====  Institutions.asyncRead(filters)  ====";
  logger.debug(funcName, [filters]);
  TypeCheck(filters.institutionUuid, "string", false);

  const filterCount = Object.keys(filters).length;
  if (filters.institutionUuid && filterCount > 1) {
    logger.error("Error: Invalid Filter Combination");
    // TODO: [#5740] Put a checkable status here.
    const errorRsp = {};
    return errorRsp;
  }

  let url;
  if (filters.institutionUuid) {
    const endpoint = ApiEndpoints.institution;
    url = FormatUrlV2(endpoint, { uuid: filters.institutionUuid });
  } else {
    const endpoint = ApiEndpoints.institutions;
    const appendToUrl = ConcatQueryParams(filters);
    url = FormatUrlV2(endpoint) + appendToUrl;
  }
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

async function asyncUpdate(body) {
  const url = FormatUrlV2(ApiEndpoints.institution, { uuid: body.uuid });
  const convertedBody = RemoveNullKeys(body);
  delete convertedBody.institution_id;
  const rsp = await asyncFetchService("PUT", url, convertedBody, null, false);
  return rsp;
}

function update(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");

  const url = FormatUrlV2(ApiEndpoints.institution, { uuid: body.uuid });
  const convertedBody = RemoveNullKeys(body);
  delete convertedBody.institution_id;

  FetchService("PUT", url, onSuccess, onError, convertedBody, callbackData);
}

function add(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");

  const url = ApiEndpoints.baseUri + ApiEndpoints.institutions;
  const convertedBody = RemoveNullKeys(body);

  FetchService("POST", url, onSuccess, onError, convertedBody, callbackData);
}

export default {
  add,
  get,
  asyncRead,
  asyncUpdate,
  update
};
