import React from "react";
import { Table } from "semantic-ui-react";

const tableHeaders = [
  "BLAST Account Number",
  "Institution Account Number",
  "Current Balance",
  "Available Balance",
  "Total Exposure",
  "Account Type",
  "Loan Class",
  "Loan Type",
  "Month End Date"
];

function ResultsHeader() {
  return (
    <>
      {tableHeaders.map(header => (
        <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
      ))}
    </>
  );
}

export default ResultsHeader;
