import React from "react";
import { FormattedMessage } from "react-intl";
import { AnimatedDialog } from "../animated-dialog/animated-dialog";
import {
  DialogAnimationProps,
  DialogComponentProps,
  DialogDismissProps
} from "../../../../types/modules";
import { Button } from "../../../elements/button/button/button";
import { MessageProps } from "../../../../types/text";

/**
 * A basic dialog that displays a preformatted component along with a single
 * button to dismiss the dialog.
 *
 * This is an animated dialog so it must remain mounted until the onHidden prop
 * function has been invoked.
 */
export function ComponentDialog(
  props: ComponentDialogProps | ButtonsComponentDialogProps
) {
  const { header, children, ...animatedDialogProps } = props;

  return (
    <AnimatedDialog {...animatedDialogProps}>
      <h1 className="header">
        {"id" in header ? (
          <FormattedMessage {...header} />
        ) : (
          header.defaultMessage
        )}
      </h1>
      <div className="scrolling content">
        <div className="description">{children}</div>
      </div>
      {isComponentDialogProps(props) ? (
        <div className="actions mt-25">
          <button
            autoFocus
            className="ui button primary"
            disabled={props.actionDisabled}
            onClick={() => props.onDismiss && props.onDismiss("close")}
          >
            {"id" in props.action ? (
              <FormattedMessage {...props.action} />
            ) : (
              props.action.defaultMessage
            )}
          </button>
        </div>
      ) : (
        <div className="actions mt-25">
          {props.buttons.map(
            ({ message, isPrimary, ref: buttonRef, uid, ...buttonProps }) => (
              <Button
                {...buttonProps}
                key={uid}
                message={message}
                onClick={() => {
                  if (buttonProps.onClick) {
                    buttonProps.onClick();
                  } else if (props.onDismiss) {
                    props.onDismiss("close", uid);
                  }
                }}
                ref={buttonRef as React.Ref<HTMLButtonElement>}
                variant={!isPrimary ? "secondary" : undefined}
              />
            )
          )}
        </div>
      )}
    </AnimatedDialog>
  );
}

function isComponentDialogProps(
  props: ComponentDialogProps | ButtonsComponentDialogProps
): props is ComponentDialogProps {
  return "action" in props;
}

export interface ComponentDialogProps
  extends DialogComponentProps,
    DialogAnimationProps,
    DialogDismissProps,
    React.PropsWithChildren<unknown> {
  /** The text to display on the button. */
  action: MessageProps;
  /** If true the action button will be disabled. Defaults to false (enabled). */
  actionDisabled?: boolean;
  /** The text to display in the header of the dialog. */
  header: MessageProps;
}

export interface ButtonsComponentDialogProps
  extends Omit<
    ComponentDialogProps,
    "action" | "actionDisabled" | "onDismiss"
  > {
  /** An array of buttons that would be rendered at the end of the Dialog */
  buttons: (Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    "className" | "onClick"
  > & {
    message: MessageProps;
    isPrimary?: boolean;
    uid: string | number;
    onClick?: () => void;
  })[];
  /** An optional action that takes place after the user dismiss the dialog, it's action parameter can be affirmative, close or negative */
  onDismiss?: (
    action: "affirmative" | "close" | "negative",
    uid: string | number
  ) => void;
}
