export const defaultAssetModalData = {
  acquisition_date: "",
  collateral_type: "",
  market_value: 0,
  description: ""
};

export const defaultInsurancesData = [
  {
    policy_number: "",
    insurance_company_name: ""
  }
];
