const floodZones = [
  { key: "A", value: "A", text: "A" },
  { key: "A1-30", value: "A1-30", text: "A1-30" },
  { key: "A99", value: "A99", text: "A99" },
  { key: "AE", value: "AE", text: "AE" },
  { key: "AH", value: "AH", text: "AH" },
  { key: "AO", value: "AO", text: "AO" },
  { key: "AR", value: "AR", text: "AR" },
  { key: "B", value: "B", text: "B" },
  { key: "B/X", value: "B/X", text: "B/X" },
  { key: "C", value: "C", text: "C" },
  { key: "D", value: "D", text: "D" },
  { key: "V", value: "V", text: "V" },
  { key: "V1-30", value: "V1-30", text: "V1-30" },
  { key: "VE", value: "VE", text: "VE" },
  { key: "X", value: "X", text: "X" },
  { key: "X500", value: "X500", text: "X500" }
];

const floodInsuranceRequiredZones = [
  "A",
  "A1-30",
  "AE",
  "A99",
  "AH",
  "AO",
  "AR",
  "V",
  "V1-30",
  "VE"
];

export { floodZones, floodInsuranceRequiredZones };
