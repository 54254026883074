import React from "react";
import {
  AnimationStage,
  DialogComponentProps
} from "../../../../types/modules";
import { addAnimationClasses } from "../classes/classes";

/**
 * A low-level fomantic styled Overlay that displays the children
 * on top of it and showing the background content.
 *
 * This is exclusively to be use in the Dialogs components
 */
export function Overlay({
  children,
  stage,
  classes
}: React.PropsWithChildren<OverlayProps>) {
  const getContainerClasses = () => (classes ? classes.containerClasses : []);
  return (
    <div className="dim dimmable dimmed">
      <div
        className={addAnimationClasses(
          ["ui", "page", "dimmer", "overlay", ...getContainerClasses()],
          stage
        ).join(" ")}
        id="modal-dimmer"
      >
        {children}
      </div>
    </div>
  );
}

export interface OverlayProps extends DialogComponentProps {
  /** Controls the stage the animation is in, fading in or fading out are the options  */
  stage?: AnimationStage;
}
