import PropTypes from "prop-types";
import React, { Component } from "react";
import TypeCheck from "typecheck-extended";
import {
  Button,
  Dimmer,
  Grid,
  Header,
  Loader,
  Modal,
  Form,
  Message
} from "semantic-ui-react";
import { connect } from "react-redux";

import ApiEndpoints from "../../../../services/ApiEndpoints";
import FetchService, {
  FetchFileService
} from "../../../../services/FetchService";
import { FormatUrlV2 } from "../../../../services/FormatUrl";
import { getDateTime, dateFromString } from "../../../../services/DateTime";
import { permCheck, RefreshToken } from "../../../../services/Auth";

import DocumentDetails from "./components/DocumentDetails";
import TicklerDetails from "./components/TicklerDetails";
import {
  Assets,
  Entities,
  Loans,
  ObjectMetadata,
  Ticklers
} from "../../../../services/ApiLib";
import logger from "../../../../services/logger";

export function onError(rsp) {
  logger.error("Error: ", rsp); // TODO: Handle errors properly
}

// TODO: This file should be calling `ApiLib`s instead of FetchService,
// and those `ApiLib`s should not require a jwt parameter.

export class DocApprovalModalObj extends Component {
  constructor(props) {
    super(props);

    this.loadingStatus = this.loadingStatus.bind(this);
    this.onDecline = this.onDecline.bind(this);
    this.onPreviewDocument = this.onPreviewDocument.bind(this);
    this.onSuccessAssetGet = this.onSuccessAssetGet.bind(this);
    this.onSuccessEntityGet = this.onSuccessEntityGet.bind(this);
    this.onSuccessLoanGet = this.onSuccessLoanGet.bind(this);
    this.onSaveMetadata = this.onSaveMetadata.bind(this);
    this.onSuccessMetadata = this.onSuccessMetadata.bind(this);
    this.onSuccessTickler = this.onSuccessTickler.bind(this);
    this.getCurrentStatus = this.getCurrentStatus.bind(this);
    this.changeOpenReason = this.changeOpenReason.bind(this);
    this.updateSearchValue = this.updateSearchValue.bind(this);
    this.updateAssetsLoansValue = this.updateAssetsLoansValue.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    try {
      this.onModalOpen();
      RefreshToken();
    } catch (e) {
      this.onError(e);
    }
  }

  componentWillUnmount() {
    try {
      const { dispatch } = this.props;
      dispatch({ type: "DOC_APPROVAL_RESET_STATE" });
      RefreshToken();
    } catch (e) {
      this.onError(e);
    }
  }

  onError(error) {
    const errorMessage = error.error;
    const { dispatch } = this.props;
    dispatch({
      type: "DOC_APPROVAL_UPDATE_ERROR",
      error: errorMessage
    });
  }

  onModalOpen() {
    const { docDetails, dispatch } = this.props;
    let { institutionUuid } = this.props;
    let uuid;
    let jwt;
    if (institutionUuid) {
      logger.warn(
        "WARNING: institutionUuid passed as prop, but should come from document metadata."
      );
    } else {
      ({ institutionUuid } = docDetails);
    }
    Entities.get(jwt, this.onSuccessEntityGet, onError, uuid, {
      institution_uuid: institutionUuid,
      page_number: 0,
      page_size: 9999
    });
    Loans.get(this.onSuccessLoanGet, onError, uuid, {
      entity_uuid: docDetails.entityUuid,
      page_number: 0,
      page_size: 300
    });
    Assets.get(jwt, this.onSuccessAssetGet, onError, uuid, {
      entity_uuid: docDetails.entityUuid,
      page_number: 0,
      page_size: 9999
    });
    dispatch({
      type: "DOC_APPROVAL_UPDATE_DOC_DETAILS",
      name: "entityUuid",
      value: docDetails.entityUuid
    });
  }

  onSuccessAssetGet(assets) {
    TypeCheck(assets.data, "array");

    const { dispatch } = this.props;
    dispatch({
      type: "DOC_APPROVAL_LOAD_ASSETS",
      assets: assets.data
    });
  }

  onSuccessEntityGet(entities) {
    TypeCheck(entities.data, "array");

    const { dispatch } = this.props;
    dispatch({
      type: "DOC_APPROVAL_LOAD_ENTITIES",
      entities: entities.data
    });
  }

  onSuccessLoanGet(loans) {
    TypeCheck(loans.data, "array");

    const { dispatch } = this.props;
    dispatch({
      type: "DOC_APPROVAL_LOAD_LOANS",
      loans: loans.data
    });
  }

  async onSaveMetadata() {
    const { onSuccessMetadata } = this;
    const { form, docDetails, onSave, approvalType, ticklerDetails } =
      this.props;

    if (!form.parentUuid) {
      this.onError({
        error: "Select correct values for Assets/Loans as applicable!"
      });
      return;
    }

    let { uuid } = docDetails;
    let callback = onSave;
    if (approvalType === "Tickler") {
      if (ticklerDetails.objectDetails) {
        uuid = ticklerDetails.objectDetails.objectUuid;
      } else {
        uuid = ticklerDetails.objectUuid;
      }
      form.approved = true;
      callback = onSuccessMetadata;
    }

    if (permCheck(["doc_approval", "admin", "doc_approval_unfiltered"])) {
      form.approved = true;
      form.status = "Approved";
    }
    const ticklerUuid = form.uuid;
    delete form.objectUuid;

    let result;
    try {
      result = await ObjectMetadata.updateAsync(uuid, form);
    } catch (e) {
      onError(e);
    }

    callback(result, { ticklerUuid });
  }

  onSuccessMetadata(rsp) {
    const { onSave, userUuid, ticklerDetails } = this.props;

    const body = {
      state: "Satisfied",
      status: "Satisfied",
      approvedBy: userUuid,
      approvedDate: dateFromString(new Date())
    };
    const url = FormatUrlV2(ApiEndpoints.tickler, {
      uuid: ticklerDetails.uuid
    });
    FetchService("PUT", url, onSave, onError, body, { decline: false });

    onSave(rsp);
  }

  async onDecline() {
    const { ticklerDetails } = this.props;
    const ticklerBody = {
      state: "Active",
      status: this.getCurrentStatus(),
      objectUuid: ""
    };
    const ticklerFilters = { uuid: ticklerDetails.uuid };
    // For reasons unknown to me, objectUuid str is also here:
    // `ticklerDetails.objectDetails.uuid`, except that location
    // is not specified in the props.
    const { objectUuid } = ticklerDetails;
    const metadataBody = { deleted: true };

    let errorMsg = "Error updating tickler details.";
    try {
      const ticklerRsp = await Ticklers.asyncUpdate(
        ticklerFilters,
        ticklerBody
      );
      if (!ticklerRsp.data || !Object.keys(ticklerRsp.data).length) {
        throw new Error(errorMsg, { cause: ticklerRsp });
      }
      logger.debug("ticklerRsp: ", ticklerRsp);

      errorMsg = "Error updating Document details.";
      const objectMetadataRsp = await ObjectMetadata.updateAsync(
        objectUuid,
        metadataBody
      );
      if (
        !objectMetadataRsp.data ||
        !Object.keys(objectMetadataRsp.data).length
      ) {
        // This indicates a data state where the clearwater.document_ticklers record has been updated,
        // but the clearwater.document_metadata_v4 has not been updated. We do not have a system in
        // place for rolling back this type of chained write to get our data back in sync.
        errorMsg = "Error updating document details.";
        throw new Error(errorMsg, { cause: objectMetadataRsp });
      }
      logger.debug("objectMetadataRsp: ", objectMetadataRsp);

      this.onSuccessTickler();
    } catch (err) {
      this.onError({ error: errorMsg });
      logger.error(err.message || errorMsg, err.cause || "");
    }
  }

  onInputChange(reason) {
    TypeCheck(reason, "string");

    const { dispatch } = this.props;
    dispatch({
      type: "DOC_APPROVAL_UPDATE_REASONS",
      value: reason
    });
  }

  onPreviewDocument() {
    const { docDetails } = this.props;
    this.loadingStatus();
    const objectUuid = docDetails.uuid;
    const url = FormatUrlV2(ApiEndpoints.objectDownloads, { uuid: objectUuid });
    FetchFileService("GET", url, () => this.loadingStatus(), onError, null, {
      fileName: "Preview.pdf"
    });
  }

  onSuccessTickler() {
    const { dispatch, onSave, reason, ticklerDetails, docDetails } = this.props;
    const { institutionUuid } = docDetails;

    const body = {
      parent_uuid: ticklerDetails.uuid,
      message: reason,
      institution_uuid: institutionUuid,
      date: getDateTime()
    };
    const url = ApiEndpoints.baseUri + ApiEndpoints.messagesV3;
    FetchService("POST", url, onSave, onError, body, { decline: true });
    dispatch({
      type: "DOC_APPROVAL_OPEN_REASON"
    });
    this.onInputChange("");
  }

  getCurrentStatus() {
    const { ticklerDetails } = this.props;
    let status = "";
    const currDate = new Date();
    const dueDate = new Date(ticklerDetails.dueDate);
    const dueDatePlusPastDuePeriod =
      dueDate.getDate() + ticklerDetails.pastDuePeriodInDays;

    if (currDate < dueDate) {
      status = "Pending Upload";
    } else if (
      currDate >= dueDate &&
      currDate < dueDate.setDate(dueDatePlusPastDuePeriod)
    ) {
      status = "Grace Period";
    } else {
      status = "Past Due";
    }

    return status;
  }

  loadingStatus() {
    const { dispatch } = this.props;
    dispatch({
      type: "DOC_APPROVAL_UPDATE_LOADING_STATUS"
    });
  }

  changeOpenReason() {
    const { dispatch } = this.props;
    dispatch({
      type: "DOC_APPROVAL_OPEN_REASON"
    });
  }

  updateSearchValue(data) {
    const { docDetails } = this.props;
    const { institutionUuid } = docDetails;
    Entities.read({
      lookup: data.searchQuery,
      institution_uuid: institutionUuid
    })
      .then(r => this.onSuccessEntityGet(r))
      .catch(err => this.onError(err));
  }

  updateAssetsLoansValue(data) {
    let uuid;
    let jwt;

    Loans.get(this.onSuccessLoanGet, onError, uuid, { entity_uuid: data });
    Assets.get(jwt, this.onSuccessAssetGet, onError, uuid, {
      entity_uuid: data
    });
  }

  render() {
    const {
      approvalType,
      commentAdd,
      commentFormField,
      commentOnChange,
      loading,
      messages,
      messagesNames,
      onClose,
      open,
      openReason,
      reason,
      ticklerDetails,
      error
    } = this.props;
    let doc = (
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "50%",
          top: "50%",
          marginTop: "-25px"
        }}
      >
        <Header textAlign="center" as="h1">
          Click Here To Download The Document
        </Header>
      </div>
    );
    if (loading) {
      doc = (
        <Dimmer active>
          <Loader indeterminate>Preparing File</Loader>
        </Dimmer>
      );
    }

    return (
      <Modal dimmer onClose={() => onClose()} open={open} size="fullscreen">
        <Modal.Header>{`${approvalType} Approval`}</Modal.Header>
        <Modal.Content>
          <Grid width={2} divided>
            <Grid.Row>
              <Grid.Column width={5}>
                {approvalType === "Tickler" ? (
                  <TicklerDetails
                    commentAdd={commentAdd}
                    commentFormField={commentFormField}
                    commentOnChange={commentOnChange}
                    docDetails={ticklerDetails}
                    messages={messages}
                    messagesNames={messagesNames}
                  />
                ) : (
                  ""
                )}
                <DocumentDetails
                  updateAssetsLoansValue={this.updateAssetsLoansValue}
                  updateSearchValue={this.updateSearchValue}
                />

                <Grid divided="vertically">
                  <Grid.Row>
                    {error && (
                      <Grid.Column columns={2}>
                        <Message negative content={error} />
                      </Grid.Column>
                    )}
                  </Grid.Row>
                  {approvalType === "Tickler" ? (
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <Button
                          color="orange"
                          fluid
                          onClick={this.changeOpenReason}
                        >
                          Decline
                        </Button>
                      </Grid.Column>
                      <Grid.Column>
                        <Button fluid onClick={this.onSaveMetadata}>
                          {permCheck([
                            "doc_approval",
                            "admin",
                            "doc_approval_unfiltered"
                          ])
                            ? "Approve"
                            : "Save"}
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  ) : (
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <Button fluid basic onClick={() => onClose({})}>
                          Cancel
                        </Button>
                      </Grid.Column>
                      <Grid.Column>
                        <Button fluid onClick={this.onSaveMetadata}>
                          {permCheck([
                            "doc_approval",
                            "admin",
                            "doc_approval_unfiltered"
                          ])
                            ? "Approve"
                            : "Save"}
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  {openReason ? (
                    <Grid.Row columns={1}>
                      <Grid.Column width="16">
                        <Form>
                          <Form.TextArea
                            onChange={e => this.onInputChange(e.target.value)}
                            placeholder="Reason of Denial"
                            value={reason}
                          />
                        </Form>
                        <br />
                        <Button onClick={this.onDecline}>Submit</Button>
                      </Grid.Column>
                    </Grid.Row>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid.Column>
              <Grid.Column width={11} onClick={this.onPreviewDocument}>
                {doc}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

DocApprovalModalObj.defaultProps = {
  commentAdd: undefined,
  commentFormField: undefined,
  commentOnChange: undefined,
  institutionUuid: undefined,
  messages: [],
  messagesNames: {},
  ticklerDetails: {
    asset: "",
    category: "",
    comments: [],
    description: "",
    docName: ""
  }
};

DocApprovalModalObj.propTypes = {
  dispatch: PropTypes.func.isRequired,

  approvalType: PropTypes.string.isRequired,

  commentAdd: PropTypes.func,
  commentFormField: PropTypes.string,
  commentOnChange: PropTypes.func,
  docDetails: PropTypes.shape({
    approvalCategory: PropTypes.string,
    approved: PropTypes.bool,
    asset: PropTypes.string,
    category: PropTypes.string,
    docCategory: PropTypes.string,
    docDate: PropTypes.string,
    docDescription: PropTypes.string,
    docType: PropTypes.string,
    documentName: PropTypes.string,
    entity: PropTypes.string,
    entityUuid: PropTypes.string,
    fileName: PropTypes.string,
    institutionUuid: PropTypes.string,
    loan: PropTypes.string,
    objectUuid: PropTypes.string,
    parentRelType: PropTypes.string,
    parentUuid: PropTypes.string,
    periodEnd: PropTypes.string,
    periodStart: PropTypes.string,
    status: PropTypes.string,
    uploadedDate: PropTypes.string,
    uuid: PropTypes.string
  }).isRequired,
  form: PropTypes.shape({
    asset: PropTypes.string,
    category: PropTypes.string,
    docDate: PropTypes.string,
    docDescription: PropTypes.string,
    docType: PropTypes.string,
    entity: PropTypes.string,
    loan: PropTypes.string,
    periodEnd: PropTypes.string,
    periodStart: PropTypes.string,
    status: PropTypes.string,
    uploadedDate: PropTypes.string,
    uuid: PropTypes.string,
    approved: PropTypes.bool,
    parentUuid: PropTypes.string,
    objectUuid: PropTypes.string
  }).isRequired,
  institutionUuid: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  messages: PropTypes.arrayOf(PropTypes.object),
  messagesNames: PropTypes.objectOf(PropTypes.string),
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  openReason: PropTypes.bool.isRequired,
  reason: PropTypes.string.isRequired,
  ticklerDetails: PropTypes.shape({
    asset: PropTypes.string,
    category: PropTypes.string,
    comments: PropTypes.arrayOf(PropTypes.object),
    description: PropTypes.string,
    docName: PropTypes.string,
    entityUuid: PropTypes.string,
    objectUuid: PropTypes.string,
    uuid: PropTypes.string,
    dueDate: PropTypes.string,
    pastDuePeriodInDays: PropTypes.number,
    objectDetails: PropTypes.shape({
      objectUuid: PropTypes.string
    })
  }),
  userUuid: PropTypes.string.isRequired,
  error: PropTypes.objectOf(PropTypes.string)
};

const mapStateToProps = state => ({
  form: state.DocApprovalModalReducer.form,
  loading: state.DocApprovalModalReducer.loading,
  openReason: state.DocApprovalModalReducer.openReason,
  reason: state.DocApprovalModalReducer.reason,
  userUuid: state.auth.userUuid,
  error: state.DocApprovalModalReducer.error
});

const DocApprovalModal = connect(mapStateToProps)(DocApprovalModalObj);

export default DocApprovalModal;
