import TypeCheck from "typecheck-extended";

function GenerateUrlParams(filters, hasPermissionBafs, institutionUuid) {
  /*
    This is used, only here, in lieu using FormatUrlV2 service with ApiEndpoints service.
    That system requires predefining set UUID replacements, but the filtering on this page
    requires different URL permutations of three filtering parameter options.
  */
  TypeCheck(filters.filterSelectedInstitution, "string");
  TypeCheck(filters.filterSelectedParentRelType, "string");
  TypeCheck(filters.filterSelectedStatus, "string");
  TypeCheck(hasPermissionBafs, "boolean");
  TypeCheck(institutionUuid, "string", false);

  const {
    filterSelectedInstitution,
    filterSelectedParentRelType,
    filterSelectedStatus
  } = filters;

  const paramStrings = [];

  if (!hasPermissionBafs) {
    paramStrings.push(`institutionUuid=${institutionUuid}`);
  } else if (
    filterSelectedInstitution &&
    filterSelectedInstitution !== "Show All"
  ) {
    paramStrings.push(`institutionUuid=${filterSelectedInstitution}`);
  }

  if (
    filterSelectedParentRelType &&
    filterSelectedParentRelType !== "Show All"
  ) {
    paramStrings.push(`parentRelType=${filterSelectedParentRelType}`);
  }

  if (filterSelectedStatus && filterSelectedStatus !== "Show All") {
    paramStrings.push(`status=${filterSelectedStatus}`);
  }

  let params = "";
  const paramCount = paramStrings.length;
  if (paramCount > 0) {
    params = "?";
    paramStrings.forEach((param, i) => {
      params += param;
      if (i < paramCount - 1) {
        params += "&";
      }
    });
  }

  return params;
}

export default GenerateUrlParams;
