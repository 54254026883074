import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Button } from "semantic-ui-react";
import styled from "@emotion/styled";
import {
  LoanBills,
  Loans,
  LoanPaymentSchedules
} from "../../../../../../../../services/ApiLib";
import logger from "../../../../../../../../services/logger";
import NextPaymentsTable from "./components/NextPaymentsTable";
import PaymentEditModal from "./components/PaymentEditModal";
import NumberOfPaymentsTable from "./components/NumberOfPaymentsTable";
import PartialPaymentTable from "./components/PartialPaymentTable";
import PastDueTable from "./components/PastDueTable";
import PastDueHistoryTable from "./components/PastDueHistoryTable";
import ScheduleList from "./components/ScheduleList";
import DeferPaymentModal from "./components/DeferPaymentModal";

function Payments({
  loan,
  isMonthEnd,
  onUpdateLoan,
  nextScheduledPaymentAmount,
  paymentFrequency,
  dispatch
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const setBillsToSkipped = async () => {
    const { data: activeBills, error } = await LoanBills.read({
      loanUuid: loan.uuid,
      is_active: true
    });
    if (error) {
      logger.error("Error reading loan bills:");
      logger.error(error);
      return;
    }
    for (let i = 0; i < activeBills.length; i += 1) {
      const bill = activeBills[i];
      bill.status = "Skipped";
      LoanBills.asyncUpdate({ loanUuid: loan.uuid, billUuid: bill.uuid }, bill);
    }
  };

  const updateLoan = async ({ deferDate, paymentsRemaining }) => {
    const body = {
      ...loan,
      is_in_deferral: true,
      deferral_count: loan.deferral_count ? loan.deferral_count + 1 : 1
    };

    if (deferDate) {
      body.deferral_end_date = deferDate;
      body.due_date = deferDate;
      body.next_payment_date = deferDate;
    }

    if (paymentsRemaining || paymentsRemaining === 0) {
      body.payments_remaining = paymentsRemaining;
    }

    const { data: nextLoan, error } = await Loans.asyncUpdate(
      { loanUuid: loan.uuid },
      body
    );

    if (error) {
      logger.error("Error updating loans:");
      logger.error(error);
      return;
    }

    dispatch({
      type: "LOAN_MANAGEMENT/POPULATE_LOAN",
      payload: nextLoan
    });
  };

  const updatePaymentSchedulesStatus = async deferDate => {
    const { data: paymentSchedules, error } = await LoanPaymentSchedules.read(
      loan.uuid
    );
    if (error) {
      logger.error("Error getting payment schedules:");
      logger.error(error);
      return;
    }
    const activePaymentSchedules = paymentSchedules.filter(
      schedule => schedule.status === "Active"
    );
    const nextPaymentSchedules = [];
    for (let i = 0; i < activePaymentSchedules.length; i += 1) {
      const paymentSchedule = activePaymentSchedules[i];
      paymentSchedule.next_payment_date = deferDate;

      nextPaymentSchedules.push(paymentSchedule);

      const { error: paymentScheduleUpdateError } =
        LoanPaymentSchedules.updateV2(
          loan.uuid,
          paymentSchedule.uuid,
          paymentSchedule
        );
      if (paymentScheduleUpdateError) {
        logger.error("Error updating payment schedules:");
        logger.error(paymentScheduleUpdateError);
      }
    }
    dispatch({
      type: "LOAN_PAYMENT_SCHEDULES/POPULATE_PAYMENT_SCHEDULES",
      payload: nextPaymentSchedules
    });
  };

  const deferLoan = ({ deferDate, paymentsRemaining }) => {
    updatePaymentSchedulesStatus(deferDate);
    setBillsToSkipped();
    updateLoan({ deferDate, paymentsRemaining });

    dispatch({
      type: "PAYMENT_REDUCER_SET_DEFER_MODAL_IS_OPEN",
      deferModalIsOpen: false
    });
  };

  return (
    <>
      <Box>
        {!isMonthEnd && (
          <Button
            circular
            basic
            icon="edit"
            onClick={() => setModalOpen(true)}
            data-testid="edit-button"
          />
        )}{" "}
        <PaymentEditModal
          loan={loan}
          onSave={changes => onUpdateLoan({ ...changes, uuid: loan.uuid })}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      </Box>
      <Grid padded>
        <Grid.Row columns="2">
          <Grid.Column>
            <NextPaymentsTable
              loan={loan}
              nextScheduledPaymentAmount={nextScheduledPaymentAmount}
              paymentFrequency={paymentFrequency}
              openModal={() =>
                dispatch({
                  type: "PAYMENT_REDUCER_SET_DEFER_MODAL_IS_OPEN",
                  deferModalIsOpen: true
                })
              }
            />
            {!loan.is_in_deferral && !isMonthEnd && (
              <Button
                floated="right"
                onClick={() =>
                  dispatch({
                    type: "PAYMENT_REDUCER_SET_DEFER_MODAL_IS_OPEN",
                    deferModalIsOpen: true
                  })
                }
              >
                Defer Payment
              </Button>
            )}
          </Grid.Column>
          <Grid.Column>
            <NumberOfPaymentsTable loan={loan} />
            <PartialPaymentTable loan={loan} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="2">
          <Grid.Column width={6}>
            <PastDueTable loan={loan} />
          </Grid.Column>
          <Grid.Column width={10}>
            <PastDueHistoryTable loan={loan} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ScheduleList loan={loan} isMonthEnd={isMonthEnd} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <DeferPaymentModal onSubmit={deferLoan} loan={loan} />
    </>
  );
}

Payments.propTypes = {
  loan: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    deferral_count: PropTypes.number,
    is_in_deferral: PropTypes.bool,
    core_of_record: PropTypes.bool
  }).isRequired,
  nextScheduledPaymentAmount: PropTypes.number,
  paymentFrequency: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  onUpdateLoan: PropTypes.func,
  isMonthEnd: PropTypes.bool
};
const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-right: 16px;
`;

export default Payments;
