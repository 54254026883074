export const creditBureaus = [
  { text: "Equifax", value: "creditScoreEquifax" },
  { text: "Experian", value: "creditScoreExperian" },
  { text: "Transunion", value: "creditScoreTransunion" }
];

export const bureauNameToKey = {
  Equifax: "credit_score_equifax",
  Experian: "credit_score_experian",
  Transunion: "credit_score_transunion"
};
