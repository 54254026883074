import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import { prettyDate } from "../../../../services/DateTime";
import { EditButton } from "../../../CustomFormElements";
import { permCheck } from "../../../../services/Auth";

const tableHeaders = [
  "State",
  "Document Name",
  "Description",
  "Start Date",
  "Next Due Date",
  "End Date",
  "Frequency",
  "Notification Period",
  "Past Due Period"
];

function ScheduleTable(props) {
  const headers = tableHeaders.map(header => (
    <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
  ));
  headers.push(<Table.HeaderCell key="edit" />);

  const { onEditModal } = props;

  const tableRows = props.data.map(obj => (
    <Table.Row key={obj.uuid}>
      <Table.Cell>{obj.currentState}</Table.Cell>
      <Table.Cell>{obj.documentName}</Table.Cell>
      <Table.Cell>{obj.description}</Table.Cell>
      <Table.Cell>{prettyDate(obj.startDate)}</Table.Cell>
      <Table.Cell>{prettyDate(obj.nextDueDate)}</Table.Cell>
      <Table.Cell>{prettyDate(obj.endDate)}</Table.Cell>
      <Table.Cell>{obj.frequency}</Table.Cell>
      <Table.Cell>{obj.notificationPeriodInDays}</Table.Cell>
      <Table.Cell>{obj.pastDuePeriodInDays}</Table.Cell>
      <Table.Cell collapsing>
        {permCheck([
          "put_doc_schedule",
          "put_doc_schedule_unfiltered",
          "admin"
        ]) && (
          <EditButton
            data-testid="schedule-edit-button"
            onClick={() => onEditModal(obj.uuid)}
          />
        )}
      </Table.Cell>
    </Table.Row>
  ));

  return (
    <Table compact celled selectable>
      <Table.Header>
        <Table.Row>{headers}</Table.Row>
      </Table.Header>
      <Table.Body>{tableRows}</Table.Body>
    </Table>
  );
}

ScheduleTable.defaultProps = {
  data: []
};

ScheduleTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  onEditModal: PropTypes.func.isRequired
};

export default ScheduleTable;
