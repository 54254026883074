/* This component's onResultSelect callback returns an object similar to the following:
event, {
  key: "211130",
  value: "211130",
  text: "Natural Gas Extraction",
  title: "Natural Gas Extraction",
}
*/

import React, { Component } from "react";
import { arrayOf, func, number, oneOfType, shape, string } from "prop-types";
import { Search } from "semantic-ui-react";
import _ from "lodash";

export default class SearchComponent extends Component {
  constructor(props) {
    super(props);
    // TODO: This component is using local state, consider ways of making this
    // re-usable while using a reducer
    this.state = { isLoading: false, results: [], value: "" };
    this.resetComponent = this.resetComponent.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentDidMount() {
    this.resetComponent();
  }

  handleSearchChange(e, { value }) {
    const { searchableData } = this.props;
    this.setState({ isLoading: true, value });

    setTimeout(() => {
      if (this.state.value.length < 1) {
        this.resetComponent();
      }

      const re = new RegExp(_.escapeRegExp(this.state.value), "i");
      const isMatch = result => re.test(result.title) || re.test(result.value);

      this.setState({
        isLoading: false,
        results: _.filter(searchableData, isMatch)
      });
    }, 300);
  }

  resetComponent() {
    this.setState({ isLoading: false, results: [], value: "" });
  }

  render() {
    const { onResultSelect, searchableData, ...passProps } = this.props;
    const { isLoading, results, value } = this.state;

    return (
      <Search
        loading={isLoading}
        onResultSelect={onResultSelect}
        onSearchChange={_.debounce(this.handleSearchChange, 500, {
          leading: true
        })}
        results={results}
        value={value}
        {...passProps}
      />
    );
  }
}

SearchComponent.propTypes = {
  onResultSelect: func.isRequired,
  searchableData: arrayOf(
    shape({
      key: oneOfType([number, string]).isRequired,
      text: string.isRequired,
      title: string.isRequired,
      value: oneOfType([number, string]).isRequired
    })
  ).isRequired
};
