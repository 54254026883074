import React from "react";
import { createPortal } from "react-dom";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { MessageProps } from "../../../types/text";
import "./style.css";

/**
 * Shows content in a flyout that slides in from the right side of the screen. Comes in one-third,half,two-third and full page width sizes. Defaults to one-third width.
 */
export function SideSheet({
  closeBtnAriaLabel = {
    id: "CLOSE_SIDESHEET",
    defaultMessage: "Close Side Sheet"
  },
  children,
  className = "",
  isOpen,
  onClose,
  size = "one-third"
}: React.PropsWithChildren<SideSheetProps>): React.ReactPortal {
  const { formatMessage } = useIntl();

  const container = (
    <>
      {isOpen && (
        <div className={`icon-button-container flex fixed z-10 top-0 ${size}`}>
          <button
            className="p-5 font-semibold"
            onClick={onClose}
            aria-label={formatMessage(closeBtnAriaLabel)}
          >
            <FontAwesomeIcon icon={faXmark} className="text-white !text-2xl" />
          </button>
        </div>
      )}

      <div className="ui dimmable dimmed">
        <div
          className={
            isOpen ? "ui simple dimmer overlayed-content-container" : undefined
          }
          onClick={onClose}
        />
        <div
          className={`ui right sidebar ${size} overlay ${
            isOpen ? "visible" : ""
          } sidebar-container ${className}`}
          data-testid="open-side-sheet-div"
        >
          <div className="sidebar-content-container">
            {isOpen ? children : null}
          </div>
        </div>
      </div>
    </>
  );
  return createPortal(
    container,
    document.getElementById("side-sheet-root") as HTMLElement
  );
}

export interface SideSheetProps {
  /**  Allows consumer component to override the aria-label value for the close button. Important for a11y  */
  closeBtnAriaLabel?: MessageProps;
  /**  If true the SideSheet will be displayed. */
  isOpen: boolean;
  /** handler to close the sidesheet */
  onClose: () => void;
  /** Prop to alter sidesheet width.   */
  size?: "one-third" | "two-third" | "half" | "full";
  className?: string;
}
