/*
Takes a dispatch parameter, and returns
user details to selected reducer
*/

import ApiEndpoints from "../ApiEndpoints";

function notifySaveStatus(saveMessage, dispatchParams) {
  dispatchParams.dispatch({
    type: dispatchParams.dispatchCallback,
    saveMessage
  });
}

export function GetUserData(dispatchParams, fetchParams) {
  const url =
    ApiEndpoints.baseUri +
    ApiEndpoints.authBase +
    ApiEndpoints.authUsers +
    fetchParams.userUuid;
  const params = {
    method: "GET",
    headers: {
      Authorization: fetchParams.jwt,
      "Content-Type": "application/json"
    }
  };
  fetch(url, params)
    .then(response => response.json())
    .then(data => {
      dispatchParams.dispatch({
        type: dispatchParams.dispatchCallback,
        data
      });
    })
    .catch(() =>
      notifySaveStatus("Error Getting Current Settings", dispatchParams)
    );
}

export function PutUserData(dispatchParams, fetchParams, notificationSettings) {
  const url =
    ApiEndpoints.baseUri +
    ApiEndpoints.authBase +
    ApiEndpoints.authUsers +
    fetchParams.userUuid;
  const myData = {
    user_metadata: {
      web_notifications: notificationSettings.web,
      email_notifications: notificationSettings.email
    }
  };
  const params = {
    method: "PUT",
    body: JSON.stringify(myData),
    headers: {
      Authorization: fetchParams.jwt,
      "Content-Type": "application/json"
    }
  };
  fetch(url, params).then(response => {
    let saveMessage = "";
    if (response.ok) {
      saveMessage = "Successfully Saved";
    } else {
      saveMessage =
        "Error Saving Setting. Please log out and log in to try again.";
    }
    notifySaveStatus(saveMessage, dispatchParams);
  });
}
