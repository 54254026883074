import { SelectMenu } from "@bafsllc/ui-shared";
import { SetStateAction } from "react";

export const SideSheetDropdown = ({
  disabled = false,
  handleChange,
  label,
  options,
  required = false,
  setSelectedValue,
  value
}: RequiredSideSheetDropdownProps) => {
  const handleFieldChange = option => {
    if (setSelectedValue) {
      option ? setSelectedValue(option.value) : setSelectedValue("");
    }
    handleChange(option?.value);
  };

  return (
    <SelectMenu
      dataArray={options}
      dataLabelKey="text"
      dataValueKey="value"
      isClearable
      isDisabled={disabled}
      key={label}
      labelText={label}
      onChange={handleFieldChange}
      required={required}
      value={value}
    />
  );
};

interface RequiredSideSheetDropdownProps {
  disabled?: boolean;
  handleChange: (option: string) => void;
  label: string;
  options: {
    text: string;
    value: string;
  }[];
  required?: boolean;
  setSelectedValue?: React.Dispatch<SetStateAction<string>>;
  value: string | null;
}
