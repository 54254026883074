import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  DefaultCell,
  PropertyTable
} from "../../../../../../../../../../components/Tables";
import BalloonCell from "./BalloonCell";

function LoanToValueTable({ loan }) {
  const rows = useMemo(
    () => [
      {
        Header: "Loan to Value",
        accessor: "loan_to_value",
        primary: true,
        Cell: DefaultCell
      },
      {
        Header: "Amortization Period",
        accessor: "amortization_period",
        Cell: DefaultCell
      },
      {
        Header: "Advance Type",
        accessor: "advance_type",
        Cell: DefaultCell
      },
      {
        Header: "Loan Term",
        accessor: "loan_term",
        Cell: DefaultCell
      },
      {
        Header: "Balloon",
        accessor: "balloon",
        Cell: BalloonCell
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: DefaultCell
      }
    ],
    []
  );

  return <PropertyTable columnsConfig={rows} data={loan} />;
}

LoanToValueTable.propTypes = {
  loan: PropTypes.shape({
    loan_to_value: PropTypes.string,
    amortization_period: PropTypes.number,
    advance_type: PropTypes.string,
    loan_term: PropTypes.number,
    balloon: PropTypes.bool
  }).isRequired
};

export default LoanToValueTable;
