import React from "react";
import PropTypes from "prop-types";
import { DollarFormat } from "../../CustomUIElements";
import Constant from "../../../services/Constants/strings";

function DollarCell({ cell }) {
  if (cell.value === Constant.NotApplicable) {
    return Constant.NotApplicable;
  }
  return cell.value !== null &&
    cell.value !== undefined &&
    cell.value !== Constant.NotApplicable ? (
    <DollarFormat value={cell.value} />
  ) : (
    "—"
  );
}

DollarCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.node
    ])
  })
};

export default DollarCell;
