/* eslint-disable import/prefer-default-export */
import typecheck from "typecheck-extended";

/**
 * @typedef Collateral
 * @property {string} uuid
 */

/**
 * Set collateral modal information.
 *
 * @param {string} loanUuid
 * @param {string} assetUuid
 * @param {string} collateralUuid
 * @param {{[assetUuid: string]: {}}} assets
 * @param {{[loanUuid: string]: Collateral[]}} collaterals
 * @param {({}) => void} dispatch
 */
export function setModal(
  loanUuid,
  assetUuid,
  collateralUuid,
  assets,
  collaterals,
  dispatch
) {
  typecheck(loanUuid, "string");
  typecheck(assetUuid, "string");
  typecheck(collateralUuid, "string");

  if (!collaterals[loanUuid]) {
    // eslint-disable-next-line no-console
    console.trace(
      `CollateralObj: How did execution get here? The loan '${loanUuid}' should have collateral already.`
    );
  }

  const loanCollaterals = collaterals[loanUuid] || [];
  const filteredLoanCollaterals = loanCollaterals.filter(
    c => c.uuid === collateralUuid
  );
  const collateral = filteredLoanCollaterals.length
    ? filteredLoanCollaterals[0]
    : {};

  const asset = assets[assetUuid];
  dispatch({
    type: "LOR_COLLATERAL_REDUCER_SET_MODAL",
    collateral,
    asset
  });
}
