// eslint-disable-next-line import/prefer-default-export
export const concealTin = (tin = "", entityType = "") => {
  const lastFourTinDigits = tin?.slice(-4);
  const concealedTin =
    entityType === "Individual"
      ? `xxx-xx-${lastFourTinDigits}`
      : `xx-xxx${lastFourTinDigits}`;

  return concealedTin;
};
