import classNames from "classnames";
import React, { useState } from "react";

/** Returns an Accordion */
export function Accordion({
  title,
  subtitle,
  children,
  className
}: {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  className?: string;
}) {
  const [active, setActive] = useState(false);

  return (
    <div
      className={classNames(
        "accordion border rounded p-4 bg-neutral-100 border-neutral-300",
        { active },
        className
      )}
      data-testid="accordion-container"
    >
      <div
        className="accordion-title cursor-pointer"
        onClick={() => setActive(!active)}
      >
        <h3 className="!m-0 flex justify-between items-center font-semibold text-slate-600 text-2xl">
          <div>{title}</div>
          <div className="flex">
            {subtitle && (
              <div className="text-lg font-light mr-4">{subtitle}</div>
            )}
            <i
              className={classNames(
                "dropdown icon text-2xl",
                active ? "halfway rotated" : ""
              )}
            ></i>
          </div>
        </h3>
      </div>
      {active && (
        <div className="accordion-content p-4 bg-[#ffffff] border-neutral-300 border rounded mt-6">
          {children}
        </div>
      )}
    </div>
  );
}
