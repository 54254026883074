import PropTypes from "prop-types";
import React from "react";
import { Button, Table, Checkbox } from "semantic-ui-react";
import { prettyDate } from "../../../../../../services/DateTime";
import { PrettyCurrency } from "../../../../../../services/PrettyNumber";
import UuidLookups from "../../../../../../components/UuidLookups";

export const columns = [
  {
    key: "disbursement_date",
    name: "Disbursement Date"
  },
  {
    key: "paid_to",
    name: "Paid to"
  },
  {
    key: "reason_charges_and_fees",
    name: "Name"
  },
  {
    key: "amount",
    name: "Amount"
  },
  {
    key: "created_by",
    name: "Created By"
  },
  {
    key: "created_datetime",
    name: "Created Date"
  },
  {
    key: "paid_outside_of_closing",
    name: "Paid Outside of Closing"
  },
  {
    key: "include_on_loan_docs",
    name: "Include on Loan Docs"
  },
  {
    key: "editDeleteGlyphs",
    name: ""
  }
];

function FeesChargesTable({ docs, onEdit, onToggle }) {
  const totalAmount = docs.reduce((n, { amount }) => n + amount || 0, 0);
  const truncateReason = input =>
    input.length > 25 ? `${input.substring(0, 25)}...` : input;

  return (
    <div>
      <Table size="small" textAlign="left">
        <Table.Header>
          <Table.Row>
            {columns.map(column => (
              <Table.HeaderCell key={column.key}>
                {column.name}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {docs.map(doc => (
            <Table.Row key={doc.uuid}>
              <Table.Cell>{doc[columns[0].key]}</Table.Cell>
              <Table.Cell>{doc[columns[1].key]}</Table.Cell>
              <Table.Cell>
                {doc[columns[2].key] === "Other"
                  ? `Other: ${truncateReason(doc.reason_other)}`
                  : doc[columns[2].key]}
              </Table.Cell>
              <Table.Cell>
                {PrettyCurrency(parseFloat(doc[columns[3].key]) || 0, false)}
              </Table.Cell>
              <Table.Cell>
                <UuidLookups lookupType="user" uuid={doc[columns[4].key]} />
              </Table.Cell>
              <Table.Cell>
                {prettyDate(doc[columns[5].key], true) || "--"}
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Checkbox
                  data-testid="toggle-btn-outside"
                  checked={doc[columns[6].key] || false}
                  toggle
                  onChange={(ev, { checked }) => {
                    onToggle(doc.uuid, columns[6].key, checked);
                  }}
                />
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Checkbox
                  data-testid="toggle-btn-include-onloan"
                  checked={doc[columns[7].key] || false}
                  toggle
                  onChange={(ev, { checked }) => {
                    onToggle(doc.uuid, columns[7].key, checked);
                  }}
                />
              </Table.Cell>

              <Table.Cell textAlign="right">
                <Button
                  basic
                  circular
                  color="green"
                  icon="edit"
                  data-testid="fees-edit-btn"
                  onClick={() => onEdit(doc)}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell>
              <h5 data-testid="total-fees-amount">
                Total Fees and Charges:
                {"  "}
                {PrettyCurrency(parseFloat(totalAmount) || 0, false)}
              </h5>
            </Table.HeaderCell>
            <Table.HeaderCell colSpan="8" />
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
}

FeesChargesTable.propTypes = {
  docs: PropTypes.arrayOf(PropTypes.object).isRequired,
  onEdit: PropTypes.func,
  onToggle: PropTypes.func
};

export default FeesChargesTable;
