import PropTypes from "prop-types";

function PaymentsCell({ row }) {
  const loan = row?.original;

  return (
    <span>
      {loan.total_payments ? loan.total_payments - loan.payments_remaining : 0}
    </span>
  );
}

PaymentsCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      total_payments: PropTypes.number,
      payments_remaining: PropTypes.number
    })
  }).isRequired
};

export default PaymentsCell;
