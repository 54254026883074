import React from "react";
import PropTypes from "prop-types";
import { Table, Header } from "semantic-ui-react";
import { ConcatName } from "../../../../../../../../../../../../services/Entities";

export default function GuarantorsCoborrowersTable({
  relLookupObj,
  guarantors,
  coborrowers
}) {
  let guarantorsTable = null;
  let coborrowersTable = null;
  if (guarantors.length > 0) {
    guarantorsTable = (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="1">Name</Table.HeaderCell>
            <Table.HeaderCell width="1">Related To</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {guarantors.map(guarantor => {
            const guarantorObj = relLookupObj[guarantor.child_uuid];
            return (
              guarantorObj && (
                <Table.Row key={guarantorObj.uuid}>
                  <Table.Cell>
                    <a
                      href={`/credit_management/entity/${guarantorObj.uuid}`}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      {ConcatName(guarantorObj)}
                    </a>
                  </Table.Cell>
                  <Table.Cell>
                    <a
                      href={`/loan_management/${guarantor.relatesTo.uuid}/details/balance`}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      {guarantor.relatesTo.institution_account_number}
                    </a>
                  </Table.Cell>
                </Table.Row>
              )
            );
          })}
        </Table.Body>
      </Table>
    );
  }

  if (coborrowers.length > 0) {
    coborrowersTable = (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="1">Name</Table.HeaderCell>
            <Table.HeaderCell width="1">Related To</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {coborrowers.map(coborrower => {
            const coborrowerObj = relLookupObj[coborrower.child_uuid];
            return (
              coborrowerObj && (
                <Table.Row key={coborrowerObj.uuid}>
                  <Table.Cell>
                    <a
                      href={`/credit_management/entity/${coborrowerObj.uuid}`}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      <i className="linkify icon" />
                      {ConcatName(coborrowerObj)}
                    </a>
                  </Table.Cell>
                  <Table.Cell>
                    <a
                      href={`/loan_management/${coborrower.relatesTo.uuid}/details/balance`}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      {coborrower.relatesTo.institution_account_number}
                    </a>
                  </Table.Cell>
                </Table.Row>
              )
            );
          })}
        </Table.Body>
      </Table>
    );
  }

  return !guarantors || !coborrowers ? null : (
    <div>
      <Header>Guarantors</Header>
      {guarantorsTable}
      <Header>Co-Borrowers</Header>
      {coborrowersTable}
    </div>
  );
}

GuarantorsCoborrowersTable.propTypes = {
  guarantors: PropTypes.arrayOf(PropTypes.object).isRequired,
  coborrowers: PropTypes.arrayOf(PropTypes.object).isRequired,
  relLookupObj: PropTypes.shape({
    first_name: PropTypes.string,
    middle_name: PropTypes.string,
    last_name: PropTypes.string,
    tin: PropTypes.string
  })
};
