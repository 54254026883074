import { blastApi, Tag } from "./blastApi";
import { ListResponse, PaginatedQueryOptions } from "./types";
import { UserInfo, UserInfoPermission } from "./ModelTypes/userInfo";

import { createSingleTag, filteredQuery, createListTags } from "./utilities";

const servicePath = "/auth/v1";

export interface UserPermissionsQueryOptions extends PaginatedQueryOptions {
  institutionUuid: string;
  permissions: string;
}

export interface UsersInfoQueryOptions extends PaginatedQueryOptions {
  institution_uuid: string;
  first_name: string;
  last_name: string;
  username: string;
  has_lending_authority: boolean;
}

const extendedApi = blastApi.injectEndpoints({
  endpoints: build => ({
    userInfo: build.query<UserInfo, string>({
      query: uuid => `${servicePath}/user_info/${uuid}`,
      providesTags: result => createSingleTag(result, Tag.UserInfo),
      transformResponse: (response: UserInfo) => response
    }),
    usersInfo: build.query<ListResponse<UserInfo>, UsersInfoQueryOptions>({
      query: filters => filteredQuery(`${servicePath}/user_info`, filters),
      providesTags: result => createListTags(result, Tag.UserInfo)
    }),
    userPermissionsInfo: build.query<
      ListResponse<UserInfoPermission>,
      UserPermissionsQueryOptions
    >({
      query: filters =>
        filteredQuery(`${servicePath}/users_permissions`, filters),
      providesTags: results => createListTags(results, Tag.UserInfo)
    })
  }),
  overrideExisting: false
});

export const {
  useUserInfoQuery,
  useUsersInfoQuery,
  useUserPermissionsInfoQuery
} = extendedApi;
