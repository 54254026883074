import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import EditDialog from "./components/EditDialog";

function EditDocButtonObj(props) {
  return <EditDialog docDetails={props.docDetails} />;
}

EditDocButtonObj.propTypes = {
  docDetails: PropTypes.shape({
    tsn: PropTypes.string,
    doc_category: PropTypes.string,
    description: PropTypes.string,
    doc_subcategory: PropTypes.string,
    upload_date: PropTypes.string,
    acct_no: PropTypes.string,
    uuid: PropTypes.string,
    branch_no: PropTypes.string,
    tax_id: PropTypes.string,
    approved: PropTypes.bool,
    name: PropTypes.string
  }).isRequired
};

const EditDocButton = connect()(EditDocButtonObj);

export default EditDocButton;
