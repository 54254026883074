import PropTypes from "prop-types";
import React, { Component } from "react";
import TypeCheck from "typecheck-extended";
import { Header, Icon, Segment } from "semantic-ui-react";

import ErrorReporter from "../../services/ErrorReporter";
import { FileUpload, Loans } from "../../services/ApiLib";
import { checkValidFiles } from "../../services/ApiLib/services/Utilities";

export function onError(error) {
  ErrorReporter("Could not upload document.", error);
}

class DragAndDrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      uploading: false
    };

    this.fileUploadSuccess = this.fileUploadSuccess.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  onDragOver(event) {
    event.preventDefault();
    this.setHover(true);
  }

  onDragLeave(event) {
    event.preventDefault();
    this.setHover(false);
  }

  async onDrop(event) {
    event.preventDefault();
    const eventCopy = {
      dataTransfer: {
        files: { ...event.dataTransfer.files }
      }
    };
    const {
      entityUuid,
      institutionUuid,
      parentRelType,
      parentUuid,
      docCategory,
      applyRestrictions,
      bpOrganizationUuid
    } = this.props;

    this.setHover(false);
    const shouldUpload = applyRestrictions
      ? checkValidFiles(event.dataTransfer.files)
      : true;

    if (shouldUpload) {
      this.setUploading(true);

      let docInstitutionUuid = institutionUuid;
      if (parentRelType === "Loan") {
        const { participationBought, investorInstitutionUuid } =
          await Loans.isParticipationBought(parentUuid);

        if (participationBought) {
          docInstitutionUuid = investorInstitutionUuid;
        }
      }

      const onSuccessCallback = this.fileUploadSuccess;
      const objDetails = {
        entityUuid,
        institutionUuid: docInstitutionUuid,
        parentRelType,
        parentUuid,
        docCategory,
        bpOrganizationUuid
      };
      FileUpload(eventCopy, objDetails, onSuccessCallback, onError);
    } else {
      this.setUploading(false);
      ErrorReporter(
        "Could not upload the document, the file is of invalid type or size",
        "File is not of allowed type or size"
      );
    }
  }

  setHover(hover) {
    TypeCheck(hover, "boolean");
    this.setState({
      hover
    });
  }

  setUploading(uploading) {
    TypeCheck(uploading, "boolean");
    this.setState({
      uploading
    });
  }

  setBgColor() {
    const { hover, uploading } = this.state;
    if (uploading) {
      return "yellow";
    }
    if (hover) {
      return "olive";
    }
    return "grey";
  }

  fileUploadSuccess(data, callbackData) {
    const { onUploadSuccess } = this.props;
    onUploadSuccess(data, callbackData);
    this.setUploading(false);
  }

  render() {
    const { size } = this.props;
    const { hover, uploading } = this.state;
    const { onDragLeave, onDragOver, onDrop } = this;
    const iconName = hover ? "upload" : "file alternate outline";
    const bgColor = this.setBgColor();

    return (
      <Segment color={bgColor} inverted={uploading || hover} size={size}>
        <div
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
          onDrop={onDrop}
          style={{
            border: "dashed",
            padding: "2% 3%",
            color: "grey",
            textAlign: "center"
          }}
        >
          {uploading ? (
            <Icon name="spinner" size="large" loading />
          ) : (
            <Icon name={iconName} size="large" />
          )}
          <Header as="h1" size="tiny" color="grey">
            Drag + Drop
          </Header>
        </div>
      </Segment>
    );
  }
}

DragAndDrop.defaultProps = {
  size: "massive",
  applyRestrictions: true
};

DragAndDrop.propTypes = {
  entityUuid: PropTypes.string.isRequired,
  docCategory: PropTypes.string,
  institutionUuid: PropTypes.string.isRequired,
  onUploadSuccess: PropTypes.func.isRequired,
  parentRelType: PropTypes.string.isRequired,
  parentUuid: PropTypes.string.isRequired,
  bpOrganizationUuid: PropTypes.string,
  size: PropTypes.string,
  applyRestrictions: PropTypes.bool
};

export default DragAndDrop;
