const Constants = {
  ACCOUNT_DOCUMENTS: "Account Documents",
  ACCOUNT_NUMBER: "Acct. No.",
  ACCOUNT_NUMBER_FULL: "Account Number",
  ACCOUNT_TYPE: "Account Type",
  ACCOUNTING_METHOD: "Accounting Method",
  ACTIVITIES: "Activities",
  ACQUISITION_DATE: "Acquisition Date",
  ADD_TO_VIEW: "Add to View",
  ADD_INTEREST_SCHEDULE: "Add Interest Schedule",
  ADDRESS: "Address",
  ADDRESS_LINE_2: "Address Line 2",
  AGGREGATE_DEBT: "Aggregate Debt",
  AMOUNT_REQUESTED: "Amount Requested",
  DATE: "Date",

  AMOUNTS_PRINCIPAL_AMOUNT: "Principal Amount",
  AMOUNTS_INTEREST_AMOUNT: "Interest Amount",
  AMOUNTS_LATE_FEE_AMOUNT: "Late Fee Amount",
  AMOUNTS_CURRENT_BALANCE: "Current Balance",
  AMOUNTS_TRANSACTION_AMOUNT: "Transaction Amount",

  ANALYSIS: "Analysis",
  ANALYSIS_ID: "Analysis ID",
  ANALYSIS_CREATION_DATE: "Analysis Creation Date",
  ANALYSIS_PERIOD: "Analysis Period",
  ANALYSIS_TYPE: "Analysis Type",
  ANALYST_NAME: "Analyst Name",
  ANALYST: "Analyst",
  ANNUAL_REVENUE: "Annual Revenue",
  ANNUAL_REVENUE_IS_REQUIRED: "Annual Revenue is required",
  ANNUAL_REVENUE_MUST_BE_INT:
    "Annual Revenue must consist of whole numbers, no commas",
  APP_ID: "App ID",
  ASSETS: "Assets",
  ASSET_DOCUMENTS: "Asset Documents",
  REAL_ESTATE_ASSET_DETAILS: "Real Estate Asset",
  ACCOUNTS_ASSET_DETAILS: "Accounts Asset",
  PERSONAL_PROPERTY_ASSET_DETAILS: "Personal Property Asset",

  AVAILABLE_BALANCE: "Available Balance",
  BLAST_ACCOUNT_NUMBER: "BLAST Account Number",
  BUSINESS_START_DATE: "Business Start Date",
  BUSINESS_START_DATE_IS_REQUIRED: "Business Start Date is required",
  CANCEL: "Cancel",
  CAPLINE: "CapLine",
  CATEGORY: "Category",
  CURRENT_BALANCE: "Current Balance",
  BORROWER: "Borrower",
  BORROWERS: "Borrower(s)",
  BORROWER_NAME: "Borrower Name",
  BORROWER_EDIT_STATUS: "Borrower Edit Status",
  BORROWER_PORTAL_EDIT_STATUS: "Borrower Portal Edit Status",
  BORROWER_PORTAL_LINKS: "Borrower Portal Links",
  BORROWER_PORTAL_ORGANIZATION: "Borrower Portal Organization",
  CITY: "City",
  CLASS: "Class",
  CLASS_TYPE: "Class/Type",
  COBORROWER: "Co-Borrower",
  COBORROWERS: "co-borrowers",
  COBORROWERSTRING: "coBorrowers",
  CREDIT_MEMO_NOT_FOUND_MESSAGE:
    "This loan does not have any Credit Memo Templates available at this time. Please contact your institution administrator to set-up the Credit Memo Templates and make them available to this loan. After a Credit Memo Template is made available to this loan, a form will appear on this page that will allow you to select a Credit Memo Template, select an analysis for each entity, then download a PDF with all the entity analysis documents together.",
  CREDIT_MEMO_ADD_ENTITY_MESSAGE:
    "Add Entities to the table by clicking on Add Existing button then clicking the Entities from the list.",
  GURANTORSSTRING: "guarantors",
  OWNERSSTRING: "owners",
  OFFICERSSTRING: "officers",
  OTHERCAPACITIESSTRING: "otherCapacities",
  OWNERENTITYDATA: "owner_entity_data",
  SIGNERENTITYDATA: "signer_entity_data",
  OFFICERENTITYDATA: "officer_entity_data",
  ENTITYDATA: "entity_data",
  COMPLETED: "Completed",
  COMPLETE: "Complete",
  COPY_NOTES: "Copy Notes",
  COPY_WORSKHEETS: "Copy Worksheets",
  CORS: "CORS",
  COUNTY_OF_REGISTRATION: "County of Registration",
  COUNTRY: "Country",
  COUNTY: "County",
  CURRENT_RISK_RATING: "Current Borrower Rating",
  CURRENT_CONTRACT_AMOUNT: "Current Contract Amount",
  CREATED_DATE: "Created Date",
  CREDIT_CARDS: "Credit Cards",
  CREDIT_DOCUMENTS: "Credit Documents",
  CREDIT_MEMO: "Credit Memo",
  CREDIT_REQUEST: "Credit Request",
  CREDIT_SCORE: "Credit Score",
  DATE_FORMAT_ERROR: "Not a valid date, date must be in format 1/1/2016",
  DEAL_RATING: "Credit Risk Rating",
  DEFAULT_LOCALE: "en-US",
  DELETE: "Delete",
  DESCRIPTION: "Description",
  DETAILS: "Details",
  DISPLAY_ENTITY_DETAILS: "Display Entity Details",
  DOCUMENT: "Document",
  DOCUMENT_ID: "Document ID",
  DOCUMENT_TRACKING: "Document Tracking",
  CREDIT_ANALYSIS: "Credit Analysis",
  DUPLICATE: "Duplicate",
  EDIT: "Edit",
  EMAIL: "Email",
  END_DATE: "End Date",
  END_PERIOD: "End Period",
  ENTITY_NAME: "Entity Name",
  ENTITY_TYPE: "Entity Type",
  FACILITY_GRADE: "Collateral Grade",
  FEDERAL_TAX_ID_EIN: "Federal Tax ID / EIN",
  FINANCIAL_ANALYSIS_ERROR_TITLE: "Error Generating Report",
  FINANCIAL_ANALYSIS_ERROR_MESSAGE:
    "There was an error generating the financial analysis. Please try again.",
  FINANCIAL_STATEMENTS: "Financial Statements",
  FINANCIAL_MODEL: "Financial Model",
  FINANCIAL: "Financial Analysis",
  FISCAL_YEAR_END: "Fiscal Year End",
  FISCAL_YEAR_END_IS_REQUIRED: "Fiscal Year End is required",
  FORMAT_MONTH_YEAR: "Format must be mm/yy",
  FULL_LIMITED: "Full vs. Limited",
  GUARANTEED_AMOUNT: "Guaranteed Amount",
  GROUP: "Group",
  HIDE_ARCHIVE: "Hide Archive",
  IN_PROGRESS: "In Progress",
  INDUSTRY: "Industry",
  INDUSTRY_TYPE: "Industry Type",
  INST_APPROVED_RISK_RATING: "Inst Approved Borrower Rating",
  INSTITUTION: "Institution",
  INSTITUTIONS: "Institutions",
  INSTITUTION_ACCOUNT_NUMBER: "Institution Account Number",
  INSTITUTION_APPROVED_RISK_RATING: "Institution Approved Borrower Rating",
  IS_OPERATING_ENTITY: "Is Operating Entity",
  INSTITUTION_DECISION: "Institution Decision",
  IS_GUARANTOR: "Is Guarantor?",
  INTEREST_ONLY: "Interest Only",
  LAST_REVIEW_DATE: "Last Review Date",
  LOADING: "Loading",
  LOANS: "Loans",
  LOAN_CLASS: "Loan Class",
  LOAN_OFFICER: "Loan Officer",
  LOAN_REVIEW_OFFICER: "Loan Review Officer",
  LOAN_REVIEW_RECC_RISK_RATING: "Loan Review Recc Borrower Rating",
  LOAN_REVIEW_RECCOMENDED_RISK_RATING:
    "Loan Review Recommended Borrower Rating",
  LOAN_TYPE: "Loan Type",
  LOCK_MODAL_PERIOD_HEADER: "Locking Period",
  LOCK_MODAL_PERIOD_MESSAGE: "Are you sure you want to lock this period?",
  LOCK_MODAL_PERIOD_WARNING:
    "A locked period can't be unlocked. You can duplicate a locked period to create a new unlocked period.\nOnce a period is locked you can't edit the Statement Information for the period and you can't edit the period in the spread.\n\n",
  LOCK_MODAL_ANALYSIS_HEADER: "Locking Analysis",
  LOCK_MODAL_ANALYSIS_MESSAGE: "Are you sure you want to lock this analysis?",
  LOCK_MODAL_ANALYSIS_WARNING:
    "A locked analysis can't be unlocked. \nOnce an analysis is locked you can't edit the Statement Information for the analysis.\n\n",
  LOCKED: "Locked",
  LOR_COMMENTS_PLACEHOLDER:
    "Please describe what the borrower is doing with the loan proceeds, as well as any additional information needed to help underwrite this credit.",
  OPINION: "Opinion",
  ORIGINAL_BORROWER_RISK_RATING: "Original Borrower Borrower Rating",
  NAICS_CODE: "NAICS Code",
  MOBILE_PHONE: "Phone Number (Mobile)",
  NAME: "Name",
  NEW: "New",
  NEXT_REVIEW_DATE: "Next Review Date",
  NEXT_RATE_CHANGE: "Next Rate Change",
  RATE_CONSTRAINT: "Rate Constraint",
  MAX_RATE_CHANGE: "Max Rate Change",
  CALCULATION_METHOD: "Calculation Method",
  RATE_ADJUSTMENT: "Rate Adjustment",
  PAYDOWN_LOAN_TO_ZERO: "Paydown Loan To 0",
  PARTICIPATION: "Participation",
  PAST_DUE: "Past Due",
  PERIOD: "Period",
  PERIODS: "Periods",
  PERIOD_START_DATE: "Period Start Date",
  PERIOD_END_DATE: "Period End Date",

  PHASE_NEW_CREDIT_APPLICATION: "New Credit Application",
  PHASE_INITIAL_REVIEW: "Initial Review",
  PHASE_FINANCIAL_ANALYSIS: "Financial Analysis",
  PHASE_UNDERWRITING: "Underwriting",
  PHASE_PENDING_DECISION: "Pending Decision",
  PHASE_PRE_CLOSING_CONDITIONS: "Pre-Closing Conditions",
  PHASE_DOC_PREP: "Doc. Prep",
  PHASE_CREDIT_ADMIN: "Credit Admin",
  PHASE_THIRD_PARTY_ACT: "3rd Party Activities",
  PHASE_COMPLETE: "Complete",

  PAYMENT_TYPE_REGULAR_PAYMENT: "Regular Payment",
  PAYMENT_TYPE_PRINCIPAL_ONLY_PAYMENT: "Principal Only Payment",
  PAYMENT_TYPE_INTEREST_PAYMENT: "Interest Payment",
  PAYMENT_TYPE_LATE_FEE_PAYMENT: "Late Fee Payment",
  PAYMENT_TYPE_ADVANCE: "Advance",
  PAYMENT_TYPE_MANUAL_PRINCIPAL_INCREASE: "Manual Principal Increase",
  PAYMENT_TYPE_MANUAL_PRINCIPAL_DECREASE: "Manual Principal Decrease",
  PAYMENT_TYPE_MANUAL_ACCRUED_INTEREST_INCREASE:
    "Manual Accrued Interest Increase",
  PAYMENT_TYPE_MANUAL_ACCRUED_INTEREST_DECREASE:
    "Manual Accrued Interest Decrease",
  PAYMENT_TYPE_INTEREST_RATE_CHANGE: "Interest Rate Change",
  PAYMENT_TYPE_PAYOFF: "Payoff",
  PAYMENT_TYPE_PREPAYMENT_PENALTY_PAYMENT: "Prepayment Penalty Payment",
  PAYMENT_TYPE_MISC_INCOME_FEE_PAYMENT: "Misc Income Fee Payment",
  PAYMENT_TYPE_PREPAYMENT_PENALTY_ASSESSMENT: "Prepayment Penalty Assessment",
  PAYMENT_TYPE_MISC_INCOME_FEE_ASSESSMENT: "Misc Income Fee Assessment",

  PHONE_NUMBER: "Phone Number",
  POST_TRANSACTIONS: "Post Transactions",

  RATE_DETAILS: "Rate Details",
  RATE_TYPE: "Rate Type",

  RELATIONSHIP_REVIEW: "Relationship Review",
  RELATIONSHIPS: "Relationships",
  REPORT_DATE: "Report Date",
  REVIEW_END_DATE: "Review End Date",
  REVIEW_START_PERIOD: "Review Start Period",
  REVOLVING: "Revolving",
  RISK_RATING: "Borrower Rating",
  RISK: "Risk",
  SCHEDULE: "Schedule",
  SEARCH_FOR_SOMETHING: "Search for Something...",
  SELECT: "Select",
  SELECT_FINANCIAL_REPORTS: "Select Financial Reports",
  SOURCE_DOCUMENT: "Source Document",
  SOURCE_DOC: "Source Doc",
  SPREADS: "Spreads",
  SPREAD_ID: "Spread ID",
  SPREAD_CREATION_DATE: "Spread Creation Date",
  START_DATE: "Start Date",
  START_PERIOD: "Start Period",
  STATE_OF_REGISTRATION: "State of Registration",
  STATE: "State",
  STATEMENT_DATE: "Statement Date",
  STATEMENT_TYPE: "Statement Type",
  STATUS: "Status",
  STRATEGIC: "Strategic",
  STRUCTURAL: "Structural",
  SUBCATEGORY: "Subcategory",
  SUBMIT: "Submit",
  TAX_REPORTING_FORM: "Tax Reporting Form",
  TICKLER_ID: "Tickler ID",
  TICKLERS: "Ticklers",
  TIN: "TIN",
  TIN_FORMAT_ERROR: "Invalid TIN",
  TOTAL_EXPOSURE: "Total Exposure",
  TYPE: "Type",
  UNDERWRITING_REC: "Underwriting Rec",
  VALIDATION_DEFAULT_ADDRESS: "An Address is required",
  VALIDATION_DEFAULT_CITY: "A City is required",
  VALIDATION_DEFAULT_COUNTRY: "A Country is required",
  VALIDATION_DEFAULT_EMAIL: "An email address is required",
  VALIDATION_DEFAULT_ENTITY_NAME: "An entity name is required",
  VALIDATION_DEFAULT_FIRSTNAME: "A first name is required",
  VALIDATION_DEFAULT_LASTNAME: "A last name is required",
  VALIDATION_DEFAULT_MIDDLENAME: "A middle name is required",
  VALIDATION_DEFAULT_PHONE: "A phone number is required",
  VALIDATION_DEFAULT_STATE: "Please select a state",
  VALIDATION_DEFAULT_ZIP: "Zip code is required",
  VALIDATION_ERROR_ANNUALINCOME:
    "Please enter an annual income as a whole number",
  VALIDATION_ERROR_CITY: "Please enter a valid city",
  VALIDATION_ERROR_DATE: "Must be a date in the format mm/dd/yyyy",
  VALIDATION_ERROR_EMAIL: "Please enter a valid email",
  VALIDATION_ERROR_NAME: "No special characters allowed",
  VALIDATION_ERROR_PHONE: "Please enter a 10 digit phone number",
  VALIDATION_ERROR_STATE: "Please enter a state in the format of 'ST'",
  VALIDATION_ERROR_YEAR: "Please enter a year as YYYY",
  VALIDATION_ERROR_ZIP: "Please enter a valid zip code",
  VALIDATION_DEFAULT_ANNUALINCOME: "Please enter annual income",
  VALIDATION_DEFAULT_CITIZEN: "Please enter US Citizen status",
  VALIDATION_DEFAULT_COUNTY: "Please enter a county/parish",
  VALIDATION_DEFAULT_DOB: "Date of birth is required",
  VALIDATION_DEFAULT_ENTITY_TYPE: "Please select an entity type",
  VALIDATION_DEFAULT_LICENSE_STATE: "A driver's license state is required",
  VALIDATION_DEFAULT_LICENSE: "A driver's license number is required",
  VALIDATION_DEFAULT_RENT_OWN: "Please indicate whether you rent or own",
  VALIDATION_DEFAULT_TIN: "TIN is required",
  VALUE: "Value",
  VIEW_ARCHIVE: "View Archive",
  VIEW_REPORT: "View Report",
  VIEW_STATEMENTS: "View Statements",
  YEARS_IN_BUSINESS: "Years in Business",
  YEARS_IN_BUSINESS_IS_REQUIRED: "Years in business is required",
  YEARS_IN_BUSINESS_MUST_BE_INT: "Years in business must be a whole number",
  WEBSITE: "Website",
  ZIP_CODE: "Zip Code",

  FORWARD_BUTTON: "Save and Next",
  BACK_BUTTON: "Save and Back",
  SAVE_BUTTON: "Save",

  CMS_ERROR_DELETE: "Can not delete document.",
  CMS_ERROR_LOAD_DOC_LIST: "Can not get document list.",
  CMS_ERROR_ADD_DUP_FAIL_UNKNOWN:
    "Failed to add document. Reason unknown. Please retry.",
  CMS_ERROR_ADD_DUP_FAIL401:
    "Failed to add document, not logged in or insufficient permissions.",
  CMS_ERROR_ADD_DUP_FAIL500:
    "Failed to add document, data invalid or server error, please check data and retry.",
  CMS_DELETE_PERIOD_WARNING:
    "Are you sure you want to delete this statement period?",
  CMS_DELETE_PERIOD_WARNING_DETAILS:
    "If you choose yes, all data will be deleted from the system. Any new statements for this borrower will need to be re-entered in its entirety.",
  CMS_DELETE_MODAL_CONFIRM_BUTTON: "Yes, Delete",
  CMS_ENTITY_DATA_EDIT_ERROR_500:
    "Network or server error. Please check data and connection, then retry",
  CMS_ENTITY_DATA_EDIT_ERROR_401:
    "Authentication error. Please check log-in and retry",
  CMS_ENTITY_DATA_EDIT_ERROR_UNKNOWN: "Failed to edit. Please retry.",

  PPP: "PPP",
  SEVEN_A: "7a",
  FIVE_ZERO_FOUR: "504",
  OTHER: "Other",
  EXPRESS: "Express",
  COMMUNITY_FACILITIES: "Community Facilities",
  BUSINESS_AND_INDUSTRY: "Business & Industry",
  SBA_KEY: "SBA",
  USDA_KEY: "USDA",
  SBA_VALUE: "sba",
  USDA_VALUE: "usda",
  ACTIVE: "Active",
  ACTIVE_CHARGE_OFF: "Active Charge-Off",
  NON_ACCRUAL: "Non-Accrual",
  INACTIVE_CHARGE_OFF: "Inactive Charge-Off",
  PAID_OFF: "Paid Off",
  Physical_State: "State",
  DEFERRED: "Deferred",
  IN_LIQUIDATION: "In Liquidation",
  PAID_IN_FULL: "Paid-In-Full",
  TRANSFERRED: "Transferred",
  PURCHASED_BY_SBA: "Purchased by SBA",
  FULLY_UNDISBURSED: "Fully Undisbursed",
  minInputValueCount: 3,
  loanSchedulePaginationName: "loanMgmtSchedule",
  loanTicklerPaginationName: "loanMgmtTickler",
  creditSchedulePaginationName: "creditMgmtSchedule",
  creditTicklerPaginationName: "creditMgmtTickler",
  allowedFileType: "[PDF, JPEG/JPG/PNG, DOC/DOCX ,XLS/XLSX ,CSV, TXT]",
  maxFileUploadSize: "300MB",
  NotApplicable: "N/A",
  AppSubmitted: "Submitted",
  AppWithdrawn: "Withdrawn",
  RECOMMEND_APPROVE: "Recommend to Approve",
  NO_RECOMMENDATION: "No Recommendation",
  RECOMMEND_DECLINE: "Recommend to Decline"
};

export default Constants;
