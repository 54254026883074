import { useCallback, useState } from "react";

/**
 * Returns the current page and page size state together
 * with a memoized onPageChange and onPageSizeChange functions
 */
export function usePagination(props?: UsePaginationOptions) {
  const { initialPage = 1, defaultPageSize = 10 } = props || {};
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const onPageChange = useCallback(
    (newCurrentPage: number) => {
      setCurrentPage(newCurrentPage);
    },
    [setCurrentPage]
  );
  const onPageSizeChange = useCallback(
    (newPageSize: number) => {
      setPageSize(newPageSize);
    },
    [setPageSize]
  );

  /**
   * This function should NOT be used with any data retrieved from an API unless that API is unable to paginate for us
   */
  const frontendPaginate = useCallback(
    (list: any[]) => {
      return list.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    },
    [currentPage, pageSize]
  );

  return {
    currentPage,
    pageSize,
    frontendPaginate,
    onPageChange,
    onPageSizeChange
  };
}

interface UsePaginationOptions {
  /** Starting page number, in this case 1 */
  initialPage?: number;
  /** Default page size, in this case 10 */
  defaultPageSize?: number;
}
