import React, { ReactPortal } from "react";
import ReactDOM from "react-dom";
import {
  AnimationStage,
  DialogComponentProps,
  OnAnimationEnd
} from "../../../../types/modules";
import { addAnimationClasses } from "../classes/classes";
import { Overlay } from "../overlay/overlay";

/**
 * A low-level fomantic styled dialog that displays the children as its
 * contents.
 * This is exclusively to be used as a base for the other Dialog components and not meant to be used directly
 */
export function Dialog({
  children,
  onAnimationEnd,
  size,
  stage,
  classes
}: React.PropsWithChildren<DialogProps>): ReactPortal {
  const getContentClasses = () =>
    classes ? classes.contentClasses : ["global-modal"];
  const container = (
    <Overlay stage={stage}>
      <div
        className={addAnimationClasses(
          ["ui", size ?? "", "modal", ...getContentClasses()],
          stage
        ).join(" ")}
        data-testid="modal-dialog"
        id="modal-dialog"
        onAnimationEnd={() =>
          onAnimationEnd && onAnimationEnd(stage as AnimationStage)
        }
      >
        {children}
      </div>
    </Overlay>
  );
  return ReactDOM.createPortal(
    container,
    document.getElementById("modal-root") as HTMLElement
  );
}

export interface DialogProps extends DialogComponentProps {
  /** Action to be executed after the dialog finish the animation sequence */
  onAnimationEnd?: OnAnimationEnd;
  /** Controls the stage the animation is in, fading in or fading out are the options  */
  stage?: AnimationStage;
}
