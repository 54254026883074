import { xorBy } from "lodash";
import { createContext, useEffect, useMemo, useState } from "react";
import { node } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useFlags } from "flagsmith/react";
import "../../types/notifications";
import logger from "../../services/logger";
import { Notifications } from "../../services/ApiLib";
import { CheckAuth } from "../../services/Auth";

export const NotificationsContext = createContext({
  /** @type {NotificationItem[]} */
  notifications: []
});

/**
 * This provider polls for notifications from the server and updates its
 * collection of notifications when they change. Prefer using this provider with
 * a `useContext` hook and `NotificationsContext` to determine when
 * notifications have changed, this will reduce unnecessary rerendering that
 * might be caused when working directly with Redux state.
 * @param {Object} props
 * @param {typeof React.Children} props.children
 */
export default function NotificationsProvider({ children }) {
  const flags = useFlags(["blast_notification_polling_enabled"]);
  const pollingEnabled = flags.blast_notification_polling_enabled.enabled;

  /** @type {NotificationItem[]} */
  const defaultNotifications = [];
  const [notifications, setNotifications] = useState(defaultNotifications);
  const dispatch = useDispatch();
  /** @type {NotificationsReducer} */
  const selected = useSelector(state => state.NotificationsReducer);

  const { includeRead, notifications: stateNotifications } = selected;

  /* This useEffect manages polling for notification changes on the server. */
  useEffect(() => {
    function notificationsCallback() {
      if (!CheckAuth()) {
        return;
      }
      Notifications.get(
        ({ data }) => dispatch({ type: "NOTIFICATIONS_UPDATE", data }),
        message => logger.error(message),
        null,
        { include_read: includeRead }
      );
    }

    // Get the initial data right away.
    (async () => notificationsCallback())();

    if (pollingEnabled) {
      logger.debug("NotificationsProvider: start polling for notifications.");

      // Start a timer to poll for data.
      const notificationsInterval = window.setInterval(
        notificationsCallback,
        5000
      );

      return () => {
        clearInterval(notificationsInterval);
        logger.debug("NotificationsProvider: stop polling for notifications.");
      };
    }
  }, [dispatch, pollingEnabled, includeRead]);

  /* This useEffect controls updating the context's `notifications` only when
  the contents of the array in Redux state has changed. */
  useEffect(() => {
    setNotifications(current => {
      // Every time a request for notifications is made a new array of
      // notifications is created in Redux state. The downside is that the
      // actual data in the array (the notifications themselves) may not have
      // changed. Use `xorBy` to compare the current context array of
      // notifications with the new one from state, if both arrays have the
      // same notifications the context's `notifications` value will NOT
      // change. This allows clients of NotificationsProvider to only render
      // when `notifications` in the context's value changes.
      const diff = xorBy(current, stateNotifications, "uuid");
      const same = !diff.length;
      if (same) {
        return current;
      }

      logger.debug(
        "NotificationsProvider setNotifications: updating notifications."
      );
      return stateNotifications;
    });
  }, [stateNotifications, setNotifications]);

  const value = useMemo(() => ({ notifications }), [notifications]);

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
}

NotificationsProvider.propTypes = {
  children: node
};
