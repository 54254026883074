import { ReactTable, reactTableSortCaseInsensitive } from "@bafsllc/ui-shared";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useMemo } from "react";
import Constants from "../../../../../../../../services/Constants/strings";
import CalculationMethodCell from "./CustomCells/CalculationMethodCell";
import RateDetailsCell from "./CustomCells/RateDetailsCell";
import DateCell from "./CustomCells/DateCell";
import RateConstraintCell from "./CustomCells/RateConstraintCell";
import MaxRateChangeCell from "./CustomCells/MaxRateChangeCell";
import ActionsCell from "./CustomCells/ActionCell";

const InterestScheduleTable = ({
  interestSchedules,
  isFetching = false,
  onClickEdit
}) => {
  const { formatMessage } = useIntl();
  const columns = useMemo(
    () => [
      {
        Header: Constants.RATE_TYPE,
        accessor: "rate_type",
        width: 100,
        sortType: reactTableSortCaseInsensitive
      },
      {
        Header: Constants.RATE_DETAILS,
        accessor: "rateDetailsCell",
        Cell: RateDetailsCell,
        disableSortBy: true
      },
      {
        Header: Constants.DATE,
        accessor: "dateCell",
        Cell: DateCell,
        disableSortBy: true
      },
      {
        Header: Constants.RATE_ADJUSTMENT,
        width: 140,
        accessor: "change_period",
        disableSortBy: true,
        sortType: reactTableSortCaseInsensitive
      },
      {
        Header: Constants.NEXT_RATE_CHANGE,
        accessor: "next_change_date",
        disableSortBy: true,
        sortType: reactTableSortCaseInsensitive
      },
      {
        Header: Constants.RATE_CONSTRAINT,
        accessor: "rateConstraintCell",
        disableSortBy: true,
        Cell: RateConstraintCell
      },
      {
        Header: Constants.MAX_RATE_CHANGE,
        accessor: "maxRateChangeCell",
        disableSortBy: true,
        Cell: MaxRateChangeCell
      },
      {
        Header: Constants.CALCULATION_METHOD,
        accessor: "calculationMethodCell",
        disableSortBy: true,
        Cell: CalculationMethodCell
      },
      {
        Header: "",
        accessor: "actionsCell",
        width: 60,
        disableSortBy: true,
        Cell: ActionsCell,
        onClickEdit
      }
    ],
    [onClickEdit]
  );

  return (
    <ReactTable
      columns={columns}
      height={450}
      data={interestSchedules}
      isFetching={isFetching}
      emptyDataTitle={formatMessage({ id: "INTEREST_SCHEDULES_NO_DATA_TITLE" })}
      emptyDataSubtitle={formatMessage({
        id: "INTEREST_SCHEDULES_NO_DATA_SUBTITLE"
      })}
      sortByAccessorId="rate_type"
      sortByAccessorDesc={false}
    />
  );
};

export default InterestScheduleTable;

InterestScheduleTable.propTypes = {
  interestSchedules: PropTypes.arrayOf(PropTypes.shape({})),
  isFetching: PropTypes.bool,
  onClickEdit: PropTypes.func
};
