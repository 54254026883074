const collateralGroupClassTypeObj = {
  "Real Estate": {
    "Real Estate": [
      "1-4 Dwelling Secondary",
      "1-4 Family Investment Property",
      "1-4 Family Rental Property - No Slab",
      "1-4 Family Rental Property - On Slab",
      "Car Wash",
      "Church",
      "Convenience Store",
      "Day Care Facility",
      "Dwelling 1-4 Family",
      "Dwelling Mft. Home",
      "Farm Land",
      "Hotel",
      "Land/Lot",
      "Manufacturing",
      "Mini Storage Buildings",
      "Mobile Home",
      "Multi Purpose",
      "MultiFamily",
      "Office",
      "Other",
      "Restaurant",
      "Retail",
      "Warehouse"
    ]
  },
  "Personal Property": {
    Accounts: ["CDs", "DDA Accounts", "Savings Account"],
    Equipment: [
      "18 Wheeler",
      "Blanket UCC",
      "Farm Equipment",
      "Motor Home",
      "Title/Motor Vehicle",
      "Titled/Marine",
      "Titled/Trailer",
      "UCC Equipment",
      "UCC Fixture"
    ],
    Inventory: ["Business Inventory", "Crops", "Other Livestock"],
    Minerals: ["Timber", "Oil and Gas"],
    "Receivables and Notes": ["Business Receivables"],
    Securities: ["Bonds", "Publicly Traded Stocks", "Privately Held Stocks"],
    Unsecured: ["Unsecured"],
    Other: ["UCC/General Intangibles", "Life Insurance"]
  }
};

export default collateralGroupClassTypeObj;
