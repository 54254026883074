import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { arrayOf, func, number, shape, string } from "prop-types";
import { Button, Table } from "semantic-ui-react";

import { map } from "lodash";
import {
  dateFromStringNoTime,
  prettyDate,
  getDateString,
  getLastDayOfPreviousMonth,
  getLastDaysBetweenMonths
} from "../../services/DateTime";
import { actionCreators } from "./reducer";
import EditMonthEndDate from "./components";

export const convertDateArrayToObject = arr => {
  const dates = map(arr, el => ({
    key: el,
    value: el,
    text: el
  }));
  return dates;
};

/** function that gets the valid end dates for months between two dates,
* the logic is to get the date of the month that is one less from the current selected date
and the end date cannot be beyond the current month of the today's date */
export const getValidDates = meFinalizedThroughDate => {
  const setThroughDate = dateFromStringNoTime(meFinalizedThroughDate);
  const setThroughDateString = getDateString(setThroughDate);
  const currentDate = new Date();
  const lastDateOfPreviousMonth = getLastDayOfPreviousMonth(setThroughDate);
  const dates = getLastDaysBetweenMonths(setThroughDate, currentDate);
  return { dates, lastDateOfPreviousMonth, setThroughDateString };
};

export function MonthEndFinalization({
  coreOfRecordInstitutions,
  coreOfRecordLoanInstitutions,
  dispatch
}) {
  const [values, setValues] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [validDates, setValidDates] = useState(null);

  const getDates = meFinalizedThroughDate => {
    const { dates, lastDateOfPreviousMonth, setThroughDateString } =
      getValidDates(meFinalizedThroughDate);
    setValidDates([
      ...convertDateArrayToObject(dates),
      ...convertDateArrayToObject([
        lastDateOfPreviousMonth,
        setThroughDateString
      ])
    ]);
  };
  const onClose = () => {
    setModalOpen(false);
  };
  const onSave = ({ body, coreOfRecord }) => {
    setModalOpen(false);
    dispatch(actionCreators.updateInstitution({ body, coreOfRecord }));
  };

  useEffect(() => {
    dispatch(actionCreators.loadInstitutions());
  }, [dispatch]);

  const openEditModal = finalizedData => {
    setValues({ ...finalizedData });
    setModalOpen(true);
    if (finalizedData.meFinalizedThroughDate) {
      getDates(finalizedData.meFinalizedThroughDate);
    } else {
      setValidDates([]);
    }
  };
  const institutions = useMemo(
    () =>
      coreOfRecordInstitutions
        .map(inst => ({
          ...inst,
          coreOfRecord: "Yes"
        }))
        .concat(
          coreOfRecordLoanInstitutions.map(inst => ({
            ...inst,
            coreOfRecord: "Only Select Loans"
          }))
        )
        .sort((a, b) => (a.name < b.name ? -1 : 1)),
    [coreOfRecordInstitutions, coreOfRecordLoanInstitutions]
  );

  return (
    <div>
      <h1 className="mb-6">Month End Finalization</h1>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Institution</Table.HeaderCell>
            <Table.HeaderCell>Institution ID</Table.HeaderCell>
            <Table.HeaderCell>Core of Record*</Table.HeaderCell>
            <Table.HeaderCell>Finalized Through</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {institutions.map(institution => (
            <Table.Row key={`me_finalization.${institution.uuid}`}>
              <Table.Cell>{institution.name}</Table.Cell>
              <Table.Cell>{institution.institution_id}</Table.Cell>
              <Table.Cell>{institution.coreOfRecord}</Table.Cell>
              <Table.Cell>
                <span className="flex justify-between items-center">
                  <span>
                    {institution.me_finalized_through
                      ? prettyDate(institution.me_finalized_through)
                      : "No date set"}{" "}
                  </span>
                  <Button
                    primary
                    size="small"
                    data-testid="open-modal"
                    onClick={() =>
                      openEditModal({
                        institutionUuid: institution.uuid,
                        meFinalizedThroughDate:
                          institution.me_finalized_through,
                        institutionName: institution.name,
                        coreOfRecord: institution.coreOfRecord
                      })
                    }
                  >
                    Change
                  </Button>
                </span>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <p>
        * Note: Institutions not listed here are not set as Core of Record and
        they have no Core of Record loans.
      </p>
      {values && modalOpen && validDates && (
        <EditMonthEndDate
          data-testid="custom-element"
          {...values}
          onClose={onClose}
          onSave={onSave}
          open={modalOpen}
          validDates={validDates}
        />
      )}
    </div>
  );
}

const institutionShape = shape({
  uuid: string.isRequired,
  name: string.isRequired,
  institution_id: number,
  me_finalized_through: string
});

MonthEndFinalization.propTypes = {
  coreOfRecordInstitutions: arrayOf(institutionShape).isRequired,
  coreOfRecordLoanInstitutions: arrayOf(institutionShape).isRequired,
  dispatch: func
};

const mapStateToProps = state => ({
  ...state.MonthEndFinalizationReducer
});

export default connect(mapStateToProps)(MonthEndFinalization);
