import { forwardRef } from "react";
import type {
  IconButtonPropsLeft,
  IconButtonPropsRight
} from "../../../elements/button/icon-button/icon-button";
import { IconButton } from "../../../elements/button/icon-button/icon-button";
import type { DropdownProps } from "../dropdown/dropdown";
import { Dropdown } from "../dropdown/dropdown";

/**
 * Display a dropdown list with a button control. Clicking the button controls
 * the display state of the list, showing or hiding it.
 */
export const ButtonDropdown = forwardRef(
  (
    {
      button,
      disabled = false,
      dropdownIcon = true,
      ...props
    }: ButtonDropdownProps,
    ref
  ) => {
    const nextButton: IconButtonPropsRight = {
      ...button,
      disabled,
      iconRight: dropdownIcon ? { icon: "dropdown" } : undefined
    };

    const dropdownProps: DropdownProps = {
      ...props,
      control: <IconButton {...nextButton} />,
      disabled
    };

    return <Dropdown ref={ref} {...dropdownProps} />;
  }
);

/**
 * Props for a control that displays a dropdown list with a button as its
 * control.
 */
export interface ButtonDropdownProps extends Omit<DropdownProps, "control"> {
  /**
   * Defines the button that will be displayed as the dropdown's control. The
   * button will always display a "dropdown" icon to the right of the text. An
   * optional icon can be displayed to the left of the text.
   */
  button: Omit<
    IconButtonPropsLeft,
    "as" | "children" | "disabled" | "iconLeft"
  > & {
    iconLeft?: IconButtonPropsLeft["iconLeft"];
  };
  dropdownIcon?: boolean;
  arrowIcon?: boolean;
}
