import * as React from "react";
import { Form, Input } from "semantic-ui-react";
import PropTypes from "prop-types";
import "./index.css";

/*
Note: this component requires a method like the one below in the parent:
********************************************************************
import { dateStringToISO } from '../services/DateTime';
handleChangeDate(rdpEvent, name) {
  const formattedDate = dateStringToISO(rdpEvent);
  this.props.dispatch({
    name,
    type: 'CMS_LOAN_MODAL_UPDATE_INFO',
    value: formattedDate,
  });
}
*/

/* TODO: the rdpEvent is in the format:
Wed Dec 03 1969 18:00:00 GMT-0600 (Central Standard Time)

Change onChange to convert this to a more reasonable format rather than doing this everywhere
*/

function dateToString(date) {
  if (!date) {
    return "";
  }

  if (typeof date === "string") {
    return date;
  }

  if (typeof date === "object" && "format" in date) {
    return date.format("Y-MM-DD");
  }

  throw Error(
    `SemanticDatePicker: unknown date type.\r\n${JSON.stringify(date)}`
  );
}

function SemanticDatePicker({
  date,
  label,
  name,
  onChange,
  id = label,
  placeholder = null,
  required = false,
  ...props
}) {
  const handleChange = React.useCallback(
    ({ target: { value } }) => onChange(value),
    [onChange]
  );

  const inputProps = {
    className: "cm-date-picker",
    label,
    labelPosition: "left corner",
    name,
    id,
    onChange: handleChange,
    placeholder,
    required,
    type: "date",
    value: dateToString(date)
  };

  return (
    <div {...props}>
      <Form.Field control={Input} {...inputProps} />
    </div>
  );
}

SemanticDatePicker.defaultProps = {
  name: ""
};

SemanticDatePicker.propTypes = {
  className: PropTypes.string,
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ format: PropTypes.func })
  ]),
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool
};

export default SemanticDatePicker;
