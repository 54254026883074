import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actionCreators } from "../../scenes/LoanOriginationStepper/reducer";
import CreditRequest from "./CreditRequest";

const mapStateToProps = ({
  LoanOriginationReducer: {
    entityDebt,
    errorMessage,
    interestSchedules,
    loanApp,
    loanRequests,
    paymentSchedules
  },
  LosPhasesReducer: { entityDetails }
}) => ({
  entityDebt,
  errorMessage,
  interestSchedules,
  loanApp,
  entityDetails,
  loanRequests,
  paymentSchedules
});

export const mapDispatchToProps = dispatch => ({
  onViewCreditRequest: loanAppUuid =>
    dispatch(actionCreators.loadLoanApp(loanAppUuid)),
  addLoan: loanApp => dispatch(actionCreators.addLoan(loanApp)),
  deleteLoan: e => dispatch(actionCreators.deleteLoan(e)),
  radioChange: e => dispatch(actionCreators.radioChange(e)),
  handleChange: (e, table) => dispatch(actionCreators.handleChange(e, table)),
  handleChangeNumber: (e, table) =>
    dispatch(actionCreators.handleChangeNumber(e, table)),
  updateLoanApp: e => dispatch(actionCreators.updateLoanApp(e)),
  updateMultiplePropsOnLoanApp: e =>
    dispatch(actionCreators.updateMultiplePropsOnLoanApp(e))
});

const CreditRequestObject = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditRequest);

export default withRouter(CreditRequestObject);
