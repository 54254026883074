/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from "react";
import { useFlags } from "flagsmith/react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import TypeCheck from "typecheck-extended";
import { NavLink, useHistory } from "react-router-dom";
import {
  Checkbox,
  Header,
  Grid,
  Menu,
  Segment,
  Button
} from "semantic-ui-react";
import { IconButton as PrepareDocButton } from "@bafsllc/ui-shared";
import moment from "moment";
import { actionCreators } from "../../../LoanOriginationStepper/reducer";
import { ProgressBar } from "../../../../../../components/CustomUIElements";
import { ConfirmationModal } from "../../../../../../components/Modals";
import BorrowersModal from "../../../LoanOriginationStepper/scenes/StepTabs/components/BorrowersModal";
import {
  Entities,
  Relationships,
  LoanApp,
  Loans,
  User,
  Users,
  Institutions,
  Messages,
  EntityAnalyses
} from "../../../../../../services/ApiLib";
import {
  getToken,
  permCheck,
  isBafs,
  CheckAuth
} from "../../../../../../services/Auth";
import NotificationBell from "../../../../../../components/NotificationBell";
import { ConcatName } from "../../../../../../services/Entities";
import { getDateTime, prettyDate } from "../../../../../../services/DateTime";
import logger from "../../../../../../services/logger";
import Constants from "../../../../../../services/Constants/strings";
import { DOC_PREP_PRIV_PERMS } from "../../../../../../services/Constants/PermissionSets";
import { PrettyCurrency } from "../../../../../../services/PrettyNumber";
import { relationshipTypes } from "../../../../../../services/Constants/relationshipTypes";
import {
  getPhasesOptions,
  shouldShowConfirmationModal,
  phaseChangeCopy,
  readOnlyPhases
} from "../../../../../../services/FormElements/components/phaseOptions";
import { FinalizationModal, HeaderInputs } from "./components";
import { getBlastClientApplicationBaseUrl } from "../../../../../../components/BlastEmbed/getBaseUrl";
import { actions as loanAppActions } from "../../../../../../store/models/loan-applications";
import RemoveApplicationModal from "../../../../../../components/Modals/components/RemoveApplicationModal";
import EngageBAFSModal from "../../../../../../components/Modals/components/EngageBAFSModal";
import { environment } from "../../../../../../environments/environment";
import { useToast } from "@bafsllc/ui-shared";

const progressArray = [
  Constants.PHASE_NEW_CREDIT_APPLICATION,
  Constants.PHASE_INITIAL_REVIEW,
  Constants.PHASE_FINANCIAL_ANALYSIS,
  Constants.PHASE_UNDERWRITING,
  Constants.PHASE_PENDING_DECISION,
  Constants.PHASE_PRE_CLOSING_CONDITIONS,
  Constants.PHASE_DOC_PREP,
  Constants.PHASE_CREDIT_ADMIN,
  Constants.PHASE_COMPLETE
];

export function parsePathForTabNames(pathname) {
  TypeCheck(pathname, "string");

  let path = pathname;
  if (path[path.length - 1] === "/") {
    const stopIndex = path.length - 1;
    path = path.substring(0, stopIndex);
  }

  const pgs = path.split("/");
  const tab = pgs[pgs.length - 2];
  const secondaryTab = pgs[pgs.length - 1];
  return [tab, secondaryTab];
}

export function PhasesHeader({ loanAppUuid }) {
  const {
    loanApp,
    totalLoanRequest,
    entityDetails,
    confirmModal,
    borrowers,
    entities,
    isOpen,
    selectedBorrower,
    showDetails,
    institution,
    institutionIsBAFSManaged,
    submittedUser,
    assignedUser,
    hasAppManagement,
    loanRequest,
    relUuid,
    completedByUser
  } = useSelector(({ LosPhasesReducer }) => LosPhasesReducer);

  const { jwt, userUuid } = useSelector(({ auth }) => auth);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [needsSplit, setNeedsSplit] = useState(false);
  const [isPPPRound2, setIsPPPRound2] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToast();
  const authToken = getToken();

  const onError = useCallback(
    rsp => {
      dispatch({
        type: "LOS_PHASES_ERROR",
        error: rsp.data
      });
      logger.error(rsp);
    },
    [dispatch]
  );

  const setEntities = useCallback(
    rsp => {
      rsp.data.forEach(e => {
        dispatch({
          type: "LOS_PHASES_SET_ENTITY",
          uuid: e.uuid,
          entity: e
        });
      });
    },
    [dispatch]
  );

  const onSelect = useCallback(
    uuid => {
      dispatch({
        type: "LOS_PHASES_UPDATE_SELECTED_BORROWER",
        selectedBorrower: uuid
      });

      dispatch(loanAppActions.updatePrimaryBorrower({ uuid }));
    },
    [dispatch]
  );

  const setEntityDetails = useCallback(
    (rsp, shouldRedirect = false) => {
      dispatch({
        type: "LOS_PHASES_POPULATE_ENTITY_DETAILS",
        data: rsp.data
      });
      if (shouldRedirect) {
        history.push("/application-phases");
      }
    },
    [dispatch, history]
  );

  const getEntityDetails = useCallback(
    rsp => {
      const entities = rsp.map(b => b.child_uuid);
      const primaryEntity = rsp.filter(b => b.primary_borrower === true)[0];
      if (entities.length) {
        Entities.get(jwt, setEntities, onError, null, { uuidList: entities });
      }
      if (primaryEntity) {
        Entities.get(jwt, setEntityDetails, onError, primaryEntity.child_uuid);
        onSelect(primaryEntity.uuid);
      }
    },

    [jwt, onError, onSelect, setEntities, setEntityDetails]
  );

  const setBorrowers = useCallback(
    rsp => {
      dispatch({
        type: "LOS_PHASES_SET_BORROWERS",
        newBorrowers: rsp.data
      });
      dispatch({
        type: "LOR_ENTITY_DETAILS_SET_BORROWERS",
        newBorrowers: rsp.data
      });
      getEntityDetails(rsp.data);
    },
    [dispatch, getEntityDetails]
  );

  const onDeleteBorrower = () => {
    dispatch(loanAppActions.removeBorrower({ relationship_uuid: relUuid }));
    toggleModal();
    openConfirmModal("");
  };

  const getBorrowers = useCallback(() => {
    Relationships.get(
      { rel_type: relationshipTypes.BORROWER, parent_uuid: loanAppUuid },
      jwt,
      setBorrowers,
      onError,
      loanAppUuid
    );
  }, [jwt, loanAppUuid, onError, setBorrowers]);

  const onSuccessLoans = useCallback(
    ({ data }) => {
      setNeedsSplit(data.length > 1);
      if (data.length === 1) {
        if (data[0].ppp_round_2) {
          setIsPPPRound2(true);
        }
      }
      dispatch({
        type: "LOS_PHASES_SET_LOAN_REQUEST",
        loanRequest: data[0]
      });
      dispatch({
        type: "LOS_PHASES_SET_TOTAL_LOAN",
        totalLoanRequest: data
      });
    },
    [dispatch]
  );

  const onSuccessEntities = useCallback(
    rsp => {
      dispatch({
        type: "LOS_PHASES_SET_ENTITY_DETAILS",
        entityDetails: rsp.data
      });
    },
    [dispatch]
  );

  const onSuccessRel = useCallback(
    rsp => {
      if (rsp.data && rsp.data.length === 0) {
        return;
      }
      Entities.get(
        authToken,
        onSuccessEntities,
        onError,
        rsp.data[0].child_uuid
      );
    },

    [authToken, onError, onSuccessEntities]
  );

  const resolveName = useCallback(
    (userData, nameType) => {
      let wholeName;
      if (nameType === "INSTITUTION_NAME") {
        dispatch({
          type: "LOS_PHASES_SET_INSTITUTION_CORE_OF_RECORD",
          wholeName: userData.data.core_of_record
        });

        // TODO: this should be called from a different method that actually
        // explains what it's doing.
        dispatch({
          type: "LOS_PHASES_SET_INSTITUTION_IS_BAFS_MANAGED",
          institutionIsBAFSManaged: userData.data.bafs_services
        });
        dispatch({
          type: "LOS_PHASES_SET_INSTITUTION",
          institution: userData.data
        });
        wholeName = userData.data.name;
      } else {
        wholeName = ConcatName(userData);
      }
      dispatch({
        type: `LOS_PHASES_SET_${nameType}`,
        wholeName: ` ${wholeName}`
      });
    },
    [dispatch]
  );

  const generateDetails = useCallback(() => {
    if (loanApp) {
      const { submitted_by_user: userUuid } = loanApp;
      const nameType = "SUBMITTED_USER";
      if (userUuid) {
        Users.asyncRead({ userUuid })
          .then((...args) => {
            const entityData = args[0];
            resolveName(entityData, nameType);
          })
          .catch((...args) => {
            onError(...args);
          });
      } else {
        dispatch({
          type: "LOS_PHASES_SET_SUBMITTED_USER",
          wholeName: userUuid
        });
      }
      if (loanApp.institution_contact) {
        User.get(
          resolveName,
          onError,
          loanApp.institution_contact,
          "INSTITUTION_USER"
        );
      } else {
        dispatch({
          type: "LOS_PHASES_SET_INSTITUTION_USER",
          wholeName: loanApp.institution_contact
        });
      }
      if (loanApp.assigned_to_user) {
        Users.asyncRead({ userUuid: loanApp.assigned_to_user })
          .then((...args) => {
            const entityData = args[0];
            resolveName(entityData, "ASSIGNED_USER");
          })
          .catch((...args) => {
            onError(...args);
          });
      } else {
        dispatch({
          type: "LOS_PHASES_SET_ASSIGNED_USER",
          wholeName: loanApp.assigned_to_user
        });
      }
      if (loanApp.completed_by_user) {
        Users.asyncRead({ userUuid: loanApp.completed_by_user })
          .then((...args) => {
            const entityData = args[0];
            resolveName(entityData, "COMPLETED_BY_USER");
          })
          .catch((...args) => {
            onError(...args);
          });
      } else {
        dispatch({
          type: "LOS_PHASES_SET_COMPLETED_BY_USER",
          wholeName: loanApp.completed_by_user
        });
      }
      if (loanApp.institution_uuid) {
        Institutions.get(
          resolveName,
          onError,
          { number: 1, direction: "next", size: 1 },
          loanApp.institution_uuid,
          {},
          "INSTITUTION_NAME"
        );
      } else {
        dispatch({ type: "LOS_PHASES_SET_INSTITUTION_NAME", wholeName: "" });
      }
    }
  }, [dispatch, loanApp, onError, resolveName]);

  const onSuccessLoanApp = useCallback(
    rsp => {
      const { phase } = rsp?.data || rsp?.loan_app_data_update || {};
      let isReadOnlyPhase = readOnlyPhases.includes(phase);

      if (
        phase === Constants.PHASE_DOC_PREP &&
        permCheck(DOC_PREP_PRIV_PERMS)
      ) {
        isReadOnlyPhase = false;
      }

      dispatch({
        type: "LOS_PHASES_SET_READ_ONLY_PHASE",
        readOnlyPhase: isReadOnlyPhase
      });
      dispatch({
        type: "LOS_PHASES_SET_LOAN_APP",
        loanApp: rsp?.data || rsp?.loan_app_data_update
      });
      generateDetails();

      if (
        rsp?.new_loan_apps?.length &&
        rsp?.loan_app_data_updates?.length &&
        rsp?.loan_data_updates?.length
      ) {
        const [existingLoanApp] = rsp.loan_app_data_updates;
        const { uuid: existingLoanAppUuid } = existingLoanApp;

        const existingLoanDetails = rsp.loan_data_updates.find(
          loan => loan.loan_app_uuid === existingLoanAppUuid
        );

        const { institution_uuid: institutionUuid } = existingLoanDetails;

        EntityAnalyses.asyncRead({
          institutionUuid,
          loanUuid: existingLoanAppUuid
        })
          .then(entityAnalysesResp => {
            if (entityAnalysesResp?.rows?.length) {
              const existingEntityAnalysis = { ...entityAnalysesResp.rows[0] };
              delete existingEntityAnalysis.lastModifiedAt;
              delete existingEntityAnalysis.uuid;
              Promise.all(
                rsp.new_loan_apps.map(loanApp => {
                  const { uuid: newLoanAppUuid } = loanApp;
                  return EntityAnalyses.asyncAdd({
                    ...existingEntityAnalysis,
                    loanUuid: newLoanAppUuid
                  });
                })
              ).catch(err => {
                onError(err);
              });
            }
          })
          .catch(err => {
            onError(err);
          });
      }
    },

    [dispatch, generateDetails, onError]
  );

  const onPhaseChange = (e, { value }) => {
    const phase = value;
    if (shouldShowConfirmationModal(phase)) {
      setConfirmationModal(makeConfirmationModal(phase));
    } else {
      LoanApp.update(authToken, onSuccessPhaseChange, onError, loanApp.uuid, {
        phase,
        assigned_to_user: ""
      });
      const { pathname } = window.location;
      const tab = parsePathForTabNames(pathname);
      if (
        tab[1] === "credit_memo" &&
        progressArray.indexOf(phase) <
          progressArray.indexOf(Constants.PHASE_UNDERWRITING)
      ) {
        const newUrl = `/los/${loanAppUuid}/phases/app_details`;
        history.push(newUrl);
      }
    }
  };

  const onSuccessPhaseChange = response => {
    onSuccessLoanApp(response);
    postMessage(response);
  };

  const onPostMessageSuccess = res => {
    dispatch({ type: "MESSAGING_ADD_MESSAGE", data: res.data });
  };

  const startTransaction = () => {
    const url = new URL(getBlastClientApplicationBaseUrl());
    url.pathname = "/csi-host/";
    url.searchParams.append("institution_uuid", loanApp.institution_uuid);
    url.searchParams.append("loan_application_uuid", loanApp.uuid);
    url.searchParams.append("entity_uuid", entityDetails.uuid);
    window.open(url, "_blank");
  };

  const postMessage = resp => {
    const {
      loanAppUuid: parentUuid,
      entityDetails: { institution_uuid: institutionUuid }
    } = useSelector(({ LosPhasesReducer }) => LosPhasesReducer);

    const stage = resp.data?.phase || resp.loan_app_data_update?.phase;
    const timeStamp = moment(new Date()).format("MM/DD/YYYY hh:mm:ss a");
    const message = `Phase change to ${stage} - By USER_NAME - ${timeStamp}`;
    Messages.post(
      onPostMessageSuccess,
      onError,
      parentUuid,
      institutionUuid,
      message,
      "LOS_PHASE_CHANGE_MESSAGE"
    );
  };

  const updatePhaseChange = phase => {
    if (phase) {
      loanApp.phase = phase;
    }
    LoanApp.update(authToken, () => null, onError, loanApp.uuid, {
      assigned_to_user: ""
    });
  };

  const handleConfirmationConfirm = (
    contractDate,
    needsContractDate,
    phase
  ) => {
    if (!contractDate && needsContractDate) {
      return;
    }
    const strUpdateBody = {
      phase,
      assigned_to_user: ""
    };
    if (phase === "Complete") {
      strUpdateBody.completed_by_user = userUuid;
      strUpdateBody.completed_date = getDateTime();
    }
    LoanApp.update(
      authToken,
      onSuccessPhaseChange,
      onError,
      loanApp.uuid,
      strUpdateBody
    );
    dispatch({
      type: "LOS_PHASES_SET_READ_ONLY",
      readOnly: true
    });

    setConfirmationModal(null);
  };

  const toggleModal = () => {
    getBorrowers();
    dispatch({
      type: "LOS_PHASES_TOGGLE_MODAL"
    });
  };

  const openConfirmModal = relUuid => {
    dispatch({
      type: "LOS_PHASES_UPDATE_SELECTED_RELATIONSHIP",
      relUuid
    });
  };

  const makeFinalizationModal = () => {
    setConfirmationModal(
      <FinalizationModal
        data-testid="finalize-modal"
        updatePhaseChange={updatePhaseChange}
        loanRequest={loanRequest}
        handleCancel={() => setConfirmationModal(null)}
      />
    );
  };

  const makeConfirmationModal = phase => {
    const contractDate = null;
    const { yes, message, needsContractDate } = phaseChangeCopy(
      phase,
      needsSplit
    );
    return (
      <ConfirmationModal
        data-testid="phase-modal"
        open
        yesButtonLabel={yes}
        showLogo={false}
        handleCancel={() => setConfirmationModal(null)}
        handleConfirm={() => {
          handleConfirmationConfirm(contractDate, needsContractDate, phase);
          if (needsSplit || phase === Constants.PHASE_COMPLETE) {
            history.push("/application-phases");
          }
          updatePhaseChange(phase || null);
        }}
        prompt={phase}
        warningDetails={message}
        size="tiny"
      />
    );
  };

  const assignUser = () => {
    let body;
    if (hasAppManagement) {
      body = { assigned_to_user: userUuid };
    } else {
      body = { institution_contact: userUuid };
    }
    if (loanApp.phase === "Complete") {
      body.phase = "Credit Admin";
      body.completed_by_user = "";
    }
    LoanApp.update(authToken, onSuccessLoanApp, onError, loanApp.uuid, body);
  };

  const onConfirm = (state = {}) => {
    if (state.withdraw) {
      withdrawApplication(loanApp);
    }

    if (state.decline) {
      declineApplication(loanApp);
    }
  };

  const withdrawApplication = loanApp => {
    LoanApp.update(authToken, onSuccessLoanApp, onError, loanApp.uuid, {
      phase: "Complete",
      status: "Withdrawn"
    });
    setConfirmationModal(null);

    history.push({ pathname: "/application-phases" });
  };

  const declineApplication = loanApp => {
    LoanApp.update(authToken, onSuccessLoanApp, onError, loanApp.uuid, {
      phase: "Complete",
      status: "Declined"
    });
    setConfirmationModal(null);

    history.push({ pathname: "/application-phases" });
  };

  // eslint-disable-next-line class-methods-use-this
  const getPrintLoanData = loanApp => {
    const token = CheckAuth();

    // eslint-disable-next-line no-undef
    if (typeof visualize === "undefined" || !visualize || !token) {
      return;
    }

    // eslint-disable-next-line no-undef
    visualize.config({
      auth: {
        token: token.token,
        preAuth: true,
        tokenName: "pp"
      }
    });

    const institutionUUID = loanApp.institution_uuid;
    const domain = environment.jasperDomain;
    const url = `https://${domain}/jasperserver-pro/rest_v2/reports/public/BLAST_Reports/Loan_Data_Sheet.pdf?App_ID=${loanApp.app_id}&Institution_UUID=${institutionUUID}`;
    return url;
  };

  const element = progressArray.indexOf(loanApp.phase) < 0;
  const loanRequestKeys = Object.keys(totalLoanRequest);
  const result = loanRequestKeys.reduce(
    (accumulator, req) => totalLoanRequest[req].original_amount + accumulator,
    0
  );

  const disabledPhases = [
    Constants.PHASE_NEW_CREDIT_APPLICATION,
    Constants.PHASE_INITIAL_REVIEW,
    Constants.PHASE_FINANCIAL_ANALYSIS,
    Constants.PHASE_UNDERWRITING,
    Constants.PHASE_PENDING_DECISION
  ];

  const flags = useFlags(["enable_los_document_page_button"]);
  const docPrepPackageButtonIsEnabled =
    flags.enable_los_document_page_button.enabled;

  useEffect(() => {
    getBorrowers();
  }, [getBorrowers]);

  useEffect(() => {
    const hasAppManagement =
      permCheck(["application_management"]) &&
      (permCheck(["loan_apps_rw_priv"]) || permCheck(["loan_apps_rw"]));

    dispatch({
      type: "LOS_PHASES_APP_MGMT_PERM",
      hasAppManagement
    });
  }, [dispatch]);

  useEffect(() => {
    Loans.get(onSuccessLoans, logger.error, null, {
      loan_app_uuid: loanAppUuid,
      deleted: false
    });
  }, [loanAppUuid, onSuccessLoans]);

  useEffect(() => {
    actionCreators.loadLoanApp(loanAppUuid);

    LoanApp.get(undefined, onSuccessLoanApp, onError, loanAppUuid);

    Relationships.get(
      {
        rel_type: relationshipTypes.BORROWER,
        parent_uuid: loanAppUuid,
        primary_borrower: true
      },
      authToken,
      onSuccessRel,
      onError,
      loanAppUuid
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, loanAppUuid]);

  return (
    <Segment>
      <Grid padded="horizontally">
        <Grid.Row columns="2">
          <Grid.Column floated="left" width="9" textAlign="left">
            <Header as="h1">
              {ConcatName(entityDetails)}
              <Header.Subheader>
                Total Proposed Debt:{" "}
                {PrettyCurrency(parseFloat(result) || 0, false)}
              </Header.Subheader>
            </Header>
            <ConfirmationModal
              open={confirmModal}
              yesButtonLabel="Delete"
              showLogo={false}
              handleCancel={() => openConfirmModal("")}
              handleConfirm={() => onDeleteBorrower()}
              prompt="Are you sure you want to delete this Borrower?"
              size="tiny"
            />
            <BorrowersModal
              borrowers={borrowers}
              entities={entities}
              open={isOpen}
              selectedBorrower={selectedBorrower}
              onDelete={openConfirmModal}
              onSelect={onSelect}
              onCancel={toggleModal}
            />
            {!readOnlyPhases.includes(loanApp.phase) ? (
              <Button onClick={toggleModal} size="small">
                Manage Borrowers
              </Button>
            ) : null}
          </Grid.Column>
          <Grid.Column textAlign="right" width="4">
            <Checkbox
              checked={showDetails}
              toggle
              label="Display Application Details"
              onClick={() => dispatch({ type: "LOS_PHASES_SHOW_DETAILS" })}
            />
            <Header
              as="h3"
              textAlign="right"
              size="medium"
              style={{ marginTop: "1rem" }}
              content={institution.name}
            />
            {institutionIsBAFSManaged ? (
              <Button
                basic
                size="tiny"
                data-testid="phasesheader-withdraw-application-button"
                onClick={() =>
                  setConfirmationModal(
                    <EngageBAFSModal
                      data-testid="engage-bafs-modal"
                      open
                      institution={institution}
                      loanApp={loanApp}
                      headerText="Request BAFS Services"
                      onCancel={() => setConfirmationModal(null)}
                      onSuccess={() => {
                        addToast({
                          id: "MESSAGE_SENT",
                          title: {
                            id: "BAFS_SERVICES_REQUEST_SENT_SUCCESS_TITLE",
                            defaultMessage: "Success"
                          },
                          message: {
                            id: "BAFS_SERVICES_REQUEST_SENT_SUCCESS_MESSAGE",
                            defaultMessage: "Your message was sent."
                          },
                          variant: "success"
                        });
                      }}
                    />
                  )
                }
              >
                Request BAFS Services
              </Button>
            ) : null}
            <Button
              size="tiny"
              basic
              data-testid="phasesheader-withdraw-application-button"
              onClick={() =>
                setConfirmationModal(
                  <RemoveApplicationModal
                    data-testid="modal"
                    open
                    onCancel={() => setConfirmationModal(null)}
                    onConfirm={onConfirm}
                    loanApp={loanApp}
                    withdrawApplication={withdrawApplication}
                    declineApplication={declineApplication}
                  />
                )
              }
            >
              Remove Application
            </Button>
            <br />
            <br />
            {isBafs() ? (
              disabledPhases.includes(loanApp.phase) ? (
                <Button size="tiny" basic disabled readOnly>
                  Print Loan Data
                </Button>
              ) : (
                <a
                  href={getPrintLoanData(loanApp)}
                  data-testid="print-loan-data"
                >
                  <Button size="tiny" basic>
                    Print Loan Data
                  </Button>
                </a>
              )
            ) : null}
          </Grid.Column>
        </Grid.Row>
        {showDetails ? (
          <Grid.Row columns={3}>
            <Grid.Column rows={4}>
              <b>View Entity: </b>
              <a
                className=""
                rel="noopener noreferrer"
                href={`/credit_management/entity/${entityDetails.uuid}`}
                target="_blank"
              >
                <i className="linkify icon" />
                {ConcatName(entityDetails)}
              </a>
              <br />
              <b>Submitted by:</b>
              {submittedUser}
              <br />
              <b>App ID:</b>
              {loanApp.app_id ? ` ${loanApp.app_id}` : ""}
            </Grid.Column>
            <Grid.Column>
              <HeaderInputs
                userUuid={userUuid}
                assignedUser={assignedUser}
                options={getPhasesOptions(loanApp.phase)}
                onPhaseChange={onPhaseChange}
                makeFinalizationModal={makeFinalizationModal}
                assignUser={assignUser}
                loanApp={loanApp}
                hasAppManagement={hasAppManagement}
              />
            </Grid.Column>
            <Grid.Column>
              <b>Expected Closing Date:</b>
              {loanApp.due_date ? ` ${prettyDate(loanApp.due_date)}` : ""}
              <br />
              <b>Date Submitted:</b>
              {loanApp.date_submitted
                ? ` ${prettyDate(loanApp.date_submitted)}`
                : ""}
              <br />
              <b>Date Completed:</b>
              {loanApp.completed_date
                ? ` ${prettyDate(loanApp.completed_date)}`
                : " - "}
              <br />
              <b>Completed By:</b>
              {loanApp.completed_by_user ? ` ${completedByUser}` : " - "}
              <br />
              <b>Scope of Analysis:</b>
              {loanApp.scope ? ` ${loanApp.scope}` : ""}
            </Grid.Column>
          </Grid.Row>
        ) : (
          ""
        )}
      </Grid>
      <ProgressBar
        progressSteps={progressArray}
        progressName={loanApp.phase || ""}
        phase={element}
      />
      <Menu pointing secondary color="green">
        {loanApp.phase === Constants.PHASE_PRE_CLOSING_CONDITIONS && (
          <Menu.Item
            key="Start-Transaction"
            name="Start Transaction"
            id="start-transaction"
            onClick={startTransaction}
          >
            <Button primary>Start Transaction</Button>
          </Menu.Item>
        )}
        {loanApp.phase === Constants.PHASE_DOC_PREP && (
          <Menu.Item
            key="Start-Transaction"
            name="Start Transaction"
            id="start-transaction"
            onClick={startTransaction}
          >
            <Button primary>Start Transaction</Button>
          </Menu.Item>
        )}
        {loanApp.phase === Constants.PHASE_CREDIT_ADMIN && (
          <Menu.Item
            key="Start-Transaction"
            name="Start Transaction"
            id="start-transaction"
            onClick={startTransaction}
          >
            <Button primary>Start Transaction</Button>
          </Menu.Item>
        )}
        {loanApp.phase === Constants.PHASE_PRE_CLOSING_CONDITIONS &&
          docPrepPackageButtonIsEnabled && (
            <Menu.Item
              key="Prepare-Document-Package"
              name="Prepare Document Package"
              id="prepare-document-package"
            >
              <PrepareDocButton
                message={{ id: "PREPARE_DOCUMENT_PACKAGE" }}
                primary
                icon="layer group"
                onClick={() => {
                  const url = new URL(getBlastClientApplicationBaseUrl());
                  url.pathname = `/los/${loanApp.uuid}/prepare-documents`;
                  window.open(url);
                }}
                disabled={!permCheck(["csi_read_write", "admin"])}
              />
            </Menu.Item>
          )}
        {loanApp.phase === Constants.PHASE_DOC_PREP &&
          docPrepPackageButtonIsEnabled && (
            <Menu.Item
              key="Prepare-Document-Package"
              name="Prepare Document Package"
              id="prepare-document-package"
            >
              <PrepareDocButton
                message={{ id: "PREPARE_DOCUMENT_PACKAGE" }}
                primary
                onClick={() => {
                  const url = new URL(getBlastClientApplicationBaseUrl());
                  url.pathname = `/los/${loanApp.uuid}/prepare-documents`;
                  window.open(url);
                }}
                disabled={!permCheck(["csi_read_write", "admin"])}
              />
            </Menu.Item>
          )}
        {isPPPRound2 ? (
          <Menu.Item
            as={NavLink}
            exact={false}
            to={`/los/${loanApp.uuid}/phases/ppp`}
            key="PPP Loans"
            link
            name="PPP Loans"
          />
        ) : null}
        {loanApp.uuid ? (
          <div className="item right">
            <NotificationBell uuid={loanApp.uuid} />
          </div>
        ) : null}
      </Menu>
      {confirmationModal}
    </Segment>
  );
}

PhasesHeader.propTypes = {
  loanAppUuid: PropTypes.string
};

export default PhasesHeader;
