const entityTypes = [
  { key: 1, text: "Corporation", value: "Corporation" },
  { key: 2, text: "General Partnership", value: "General Partnership" },
  { key: 3, text: "Government", value: "Government" },
  { key: 4, text: "Individual", value: "Individual" },
  {
    key: 5,
    text: "Limited Liability Company",
    value: "Limited Liability Company"
  },
  {
    key: 6,
    text: "Limited Liability Partnership",
    value: "Limited Liability Partnership"
  },
  { key: 7, text: "Limited Partnership", value: "Limited Partnership" },
  { key: 8, text: "Non-Profit", value: "Non-Profit" },
  {
    key: 9,
    text: "Professional Association",
    value: "Professional Association"
  },
  { key: 10, text: "Single Member LLC", value: "Single Member LLC" },
  { key: 11, text: "Sole Proprietorship", value: "Sole Proprietorship" },
  { key: 12, text: "Trust", value: "Trust" },
  {
    key: 13,
    text: "Professional Limited Liability Company",
    value: "Professional Limited Liability Company"
  },
  {
    key: 14,
    text: "Professional Limited Liability Partnership",
    value: "Professional Limited Liability Partnership"
  }
];

export default entityTypes;
