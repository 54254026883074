import React, { useEffect, useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Checkbox, Segment, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import {
  DateCell,
  DropdownCell,
  DollarCell,
  DefaultCell
} from "../../../../../../../../components/Tables";
import Resources from "../../components/Resources";
import LoanBillsService from "../../../../../../../../services/ApiLib/services/LoanBills";
import PaginationControls from "../../../../../../../../components/Pagination";

const paginationName = "billsPage";

const billOptions = ["Skipped", "Waived", "Satisfied"].map(option => ({
  key: option,
  value: option,
  text: option
}));

const getOptionsByValue = value =>
  ["Upcoming", "Due", "Past Due"].includes(value)
    ? [...billOptions, { key: value, value, text: value }]
    : [{ key: value, value, text: value }];

function BillsObj({ loan: { uuid: loanUuid }, pagination: { number, size } }) {
  const [bills, setBills] = useState([]);
  const [includePastBills, setIncludePastBills] = useState(false);
  const [error, setError] = useState();
  const [lastPage, setLastPage] = useState(false);

  const loadBills = useCallback(() => {
    const sucessFn = response => {
      setBills(response.data);
      setLastPage(response.metaData.last_page);
    };
    const errorFn = () => {
      setError("Unable to fetch bills");
    };
    const queryParams = {
      ...(!includePastBills && { is_active: true }),
      sort_col: "bill_number",
      sort_order: -1
    };
    LoanBillsService.get(
      sucessFn,
      errorFn,
      {
        number,
        direction: "next",
        size
      },
      loanUuid,
      null,
      queryParams
    );
  }, [number, size, loanUuid, includePastBills]);

  const updateBillStatus = useCallback(
    (value, row) => {
      LoanBillsService.asyncUpdate(
        { loanUuid, billUuid: row.original.uuid },
        { status: value }
      )
        .then(() => {
          loadBills();
        })
        .catch(() => {
          setError("Unable to update bill status");
        });
    },
    [loadBills, loanUuid]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Bill Number",
        accessor: "bill_number",
        Cell: DefaultCell
      },
      {
        Header: "Bill Sub",
        accessor: "bill_sub",
        Cell: DefaultCell
      },
      {
        Header: "Bill type",
        accessor: "bill_type",
        Cell: DefaultCell
      },
      {
        Header: "Due Date",
        accessor: "due_date",
        Cell: DateCell
      },
      {
        Header: "Created Date",
        accessor: "effective_created_date",
        Cell: DateCell
      },
      {
        Header: "Posting Method",
        accessor: "posting_method",
        Cell: DefaultCell
      },
      {
        Header: "Payment Type",
        accessor: "payment_type",
        Cell: DefaultCell
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: DollarCell
      },
      {
        Header: "Amount Satisfied",
        accessor: "amount_satisfied",
        Cell: DollarCell
      },
      {
        Header: "Total Interest Due",
        accessor: "interest_due_total",
        Cell: DollarCell
      },
      {
        Header: "Interest Due Satisfied",
        accessor: "interest_due_satisfied",
        Cell: DollarCell
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: DropdownCell,
        customProps: {
          getOptionsByValue,
          onChange: ({ value }, cell) => updateBillStatus(value, cell.row)
        }
      }
    ],
    [updateBillStatus]
  );

  useEffect(() => {
    loadBills();
  }, [loadBills]);

  return (
    <Segment>
      <Resources.Layout heading="Bills">
        <Resources.Header>
          {error && <Message error header="Error" content={error} />}
          <Checkbox
            toggle
            label="Include Past Bills"
            checked={includePastBills}
            onChange={(ev, { checked }) => setIncludePastBills(checked)}
          />
        </Resources.Header>
        <Resources.Table items={bills} columns={columns} />
        <Segment>
          <PaginationControls name={paginationName} lastPage={lastPage} />
        </Segment>
      </Resources.Layout>
    </Segment>
  );
}

BillsObj.defaultProps = {
  pagination: {
    number: 0,
    size: 10
  }
};

BillsObj.propTypes = {
  loan: PropTypes.shape({ uuid: PropTypes.string }),
  pagination: PropTypes.shape({
    number: PropTypes.number,
    size: PropTypes.number
  })
};

const mapStateToProps = state => ({
  pagination: state.PaginationReducer[paginationName]
});

const Bills = connect(mapStateToProps)(BillsObj);

export default Bills;
