// @ts-nocheck
import { useEffect, useRef, useState } from "react";
import { createPopper } from "@popperjs/core";

export const Tooltip = ({
  children,
  content,
  inverted = false,
  position = "top",
  trigger = "hover",
  className = "",
  contentClassName = "",
  disabled = false,
  arrow = true,
  small = false
}: TooltipProps) => {
  const [showPopover, setShowPopover] = useState(false);
  const triggerRef = useRef(null);
  const popoverRef = useRef(null);
  const popperInstanceRef = useRef(null);

  useEffect(() => {
    if (showPopover && triggerRef.current && popoverRef.current) {
      const popperInstance = createPopper(
        triggerRef.current,
        popoverRef.current,
        {
          placement: position,
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 8]
              }
            },
            {
              name: "preventOverflow",
              options: {
                padding: 8
              }
            }
          ],
          container: document.body
        }
      );

      popperInstanceRef.current = popperInstance;

      return () => {
        popperInstance.destroy();
        popperInstanceRef.current = null;
      };
    }
  }, [showPopover, position]);

  const handleMouseEnter = () => {
    if (trigger === "hover") {
      // Without setTimeout there is a positioning flicker, since React needs
      // one more render loop to grab PopperJS' positioning.
      setTimeout(() => setShowPopover(true));
    }
  };

  const handleMouseLeave = () => {
    if (trigger === "hover") {
      // Without setTimeout sometimes tooltips stick on the screen,
      // since React needs one more render loop to get in sync with PopperJS.
      setTimeout(() => setShowPopover(false));
    }
  };

  const handleClick = () => {
    if (trigger === "click") {
      setShowPopover(!showPopover);
    }
  };

  // Conditional settings:
  const invert = inverted
    ? "text-white bg-black opacity-90"
    : "bg-slate-50 dark:bg-gray-800 border border-neutral-200";
  const sm = small ? "text-xs py-1 px-2" : "py-3 px-4";

  return (
    <>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        ref={triggerRef}
        className={className}
      >
        {children}
      </div>
      {showPopover && !disabled && (
        <>
          <div
            className={`tooltip absolute z-[100] max-w-64 rounded-lg shadow-md ${invert} ${sm}`}
            ref={popoverRef}
            role="tooltip"
          >
            <div className={contentClassName}>{content}</div>
            {arrow && <div data-popper-arrow className="tooltip-arrow" />}
          </div>
        </>
      )}
    </>
  );
};

export interface TooltipProps {
  /** The children elements are rendered as the trigger element. */
  children: any;
  /** Content is a string, or JSX that goes inside the tooltip. */
  content: any;
  /** Inverted true is a dark tooltip with white text. */
  inverted?: boolean;
  /** Position of the tooltip relative to the trigger element. */
  position?: "top" | "bottom" | "left" | "right";
  /** How the tooltip is triggered. `hover` or `click` */
  trigger?: "hover" | "click";
  /** CSS classes for the parent element, which wraps the target. */
  className?: string;
  /** CSS classes for the tooltip element itself */
  contentClassName?: string;
  /** Hide or show the tooltip. Removes it from the DOM when not in use. */
  disabled?: boolean;
  /** Whether or not to show a little arrow pointing at the trigger element. */
  arrow?: boolean;
  /** Triggers some built-in styles to give you a smaller tooltip size */
  small?: boolean;
}
