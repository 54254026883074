import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  Button,
  Checkbox,
  Divider,
  Grid,
  Header,
  Icon,
  Table
} from "semantic-ui-react";
import typecheck from "typecheck-extended";

import { Assets, Collateral, Loans } from "../../../../services/ApiLib";
import {
  CollateralLtvAndCoverageRatio,
  getOriginationDscrLtv,
  getTransformedCollateral
} from "../../../../services/Formulae";
import { collateralsToState } from "../../services/AddToState/services/collateralToState/services/collateralToState";
import { PrettyCurrency } from "../../../../services/PrettyNumber";

import CollateralAddEdit from "../../../../components/CollateralAddEdit";
import { BorderedContainer } from "../../../../components/CustomUIElements";
import { EditButton } from "../../../../components/CustomFormElements";
import { ConfirmationModal } from "../../../../components/Modals";
import { actionCreators } from "../../scenes/LoanOriginationStepper/reducer";
import {
  getAdditionalAssetData,
  updateAdditionalAssetData,
  addAdditionalAssetData
} from "./util";
import logger from "../../../../services/logger";
import { setModal } from "./Collateral-functions";
import sortArrByDateKey from "../../../../utils/sortArrByDateKey";
import PaginationControls from "../../../../components/Pagination";

const headers = [
  "Primary",
  "Description",
  "Asset Group",
  "Asset Class",
  "Asset Type",
  "Proposed Market Value",
  "Amount of Prior Liens",
  "Cost",
  "Advance Rate",
  "Cross Collateralized",
  "Lien Position",
  "Has Grantor",
  "Value Date",
  "Value Source",
  ""
];

/*
  Note: All type enforcement in this class has been retroactively added with no regard
  to the actual intent of the code or backend schema. It is solely based on how the original
  author is using the parameters and may or may not be valid.
*/

/**
 * @typedef {Object} Collateral
 * @property {boolean} is_primary
 * @property {string} uuid
 */

/**
 * @param {Object} props
 * @param {Object.<string, Collateral[]>} props.collaterals
 * @param {string} props.loanAppUuid
 */
function CollateralObj({
  assetModal,
  assets,
  collateralEntities,
  collateralModal,
  collaterals,
  collatUuid,
  confirmModal,
  dispatch,
  entityAssets,
  isAdd,
  isOpen,
  loanApp,
  loanAppUuid,
  loanRequests,
  loanReqUuid,
  readOnly,
  collateralAssetPagination
}) {
  const [modalEntityUuid, setModalEntityUuid] = useState(null);
  const [openAssetsForm, setOpenAssetsForm] = useState(false);
  const [additionalAssetData, setAdditionalAssetsData] = useState({ data: "" });
  const [assetDataFound, setAssetsData] = useState();
  /** @type string */
  const [modalLoanUuid, setModalLoanUuid] = useState(null);
  const paginationName = "collateralAssetPagination";
  const { number, size, lastPage } = collateralAssetPagination ?? {};

  const { LoanOriginationReducer } = useSelector(
    // eslint-disable-next-line no-shadow
    ({ LoanOriginationReducer }) => ({ LoanOriginationReducer })
  );
  const { borrowers } = LoanOriginationReducer;

  const hasLoanApp = !!loanApp?.uuid;
  useEffect(() => {
    dispatch({ type: "LOR_UPDATE_ACTIVE_STEPS", activeStepsNum: 4 });
    dispatch({ type: "LOR_POPULATE_LOANAPP_UUID", loanAppUuid });

    if (!hasLoanApp) {
      dispatch(actionCreators.loadLoanApp(loanAppUuid));
    }

    Object.keys(loanRequests).forEach(loanRequestUuid => {
      collateralsToState(
        loanRequestUuid,
        { page_number: number || 0, page_size: size || 300 },
        dispatch,
        "stepper"
      );
    });
  }, [dispatch, hasLoanApp, loanAppUuid, loanRequests, number, size]);

  const onError = useCallback(
    error => {
      logger.error(error); // This will be updated on a later pr which creates a global error handler
      dispatch({
        type: "LOR_COLLATERAL_REDUCER_UPDATE_ERROR",
        error
      });
    },
    [dispatch]
  );

  const resetAssetData = () => {
    setAdditionalAssetsData({ data: "" });
  };

  const updatePolicyNumber = ({ name, value }) => {
    const insurancePolicies = value.split(",", value.length);
    const newState = { ...additionalAssetData.data, [name]: insurancePolicies };
    setAdditionalAssetsData({ data: { ...newState } });
  };

  const updateValue = ({ name, value }) => {
    if (name === "life_insurance_policy_number") {
      updatePolicyNumber({ name, value });
    } else {
      const newState = { ...additionalAssetData.data, [name]: value };
      setAdditionalAssetsData({ data: { ...newState } });
    }
  };

  async function onCheckboxClick(loanReq) {
    typecheck(loanReq.ltv_policy_exception, "boolean");

    if (readOnly) {
      return;
    }
    const newLoanReq = {
      ...loanReq,
      ltv_policy_exception: !loanReq.ltv_policy_exception
    };
    try {
      const updatedLoan = await Loans.asyncUpdate(
        { loanUuid: newLoanReq.uuid },
        newLoanReq
      );
      if (updatedLoan.data) {
        dispatch({
          type: "LOR_POPULATE_CREDIT_REQUESTS",
          data: [updatedLoan.data]
        });
      }
      if (updatedLoan.error) {
        onError(updatedLoan.error);
      }
    } catch (err) {
      onError(err);
    }
  }

  function onSuccessAsset(rsp) {
    typecheck(rsp.data.uuid, "string");
    dispatch({
      type: "LOR_COLLATERAL_REDUCER_UPDATE_ASSETS",
      assets: rsp.data,
      uuid: rsp.data.uuid
    });
    dispatch({
      type: "LOR_REDUCER_COLLATERAL_MODAL_SET_ASSET",
      asset: rsp.data,
      value: null
    });
    getAdditionalAssetData(
      rsp.data.uuid,
      rsp.data.asset_group,
      rsp.data.asset_class,
      onError,
      setAdditionalAssetsData,
      setAssetsData
    );
  }

  function onSuccessAssets(rsp, { entityUuid }) {
    typecheck(rsp.data, "array");
    typecheck(entityUuid, "string");

    dispatch({
      type: "LOR_COLLATERAL_REDUCER_POPULATE_COLLATERAL_ENTITY_ASSETS",
      entityAssets: rsp.data,
      entityUuid
    });
  }

  async function getAssets(entityUuid) {
    typecheck(entityUuid, "string");

    try {
      const fetchedAssets = await Assets.asyncRead(
        { entity_uuid: entityUuid },
        false
      );
      if (fetchedAssets.data) {
        onSuccessAssets(fetchedAssets, { entityUuid });
      }
      if (fetchedAssets.error) {
        onError(fetchedAssets.error);
      }
    } catch (err) {
      onError(err);
    }
  }

  function openModal(modalType, loanUuid, asset, collat) {
    typecheck(modalType, "string");
    typecheck(loanUuid, "string");

    let isModalAdd = false;
    if (modalType === "isAdd") {
      isModalAdd = true;
      dispatch({
        type: "LOR_COLLATERAL_REDUCER_UPDATE_COLLATERAL_MODAL",
        payload: {
          loan_uuid: loanUuid,
          institution_uuid: loanApp.institution_uuid
        }
      });
    } else {
      setModal(
        loanUuid,
        asset.uuid,
        collat.uuid,
        assets,
        collaterals,
        dispatch
      );
      getAssets(asset.entity_uuid);
      getAdditionalAssetData(
        asset.uuid,
        asset.asset_group,
        asset.asset_class,
        onError,
        setAdditionalAssetsData,
        setAssetsData
      );
    }

    setModalLoanUuid(loanUuid);

    dispatch({
      type: "LOR_COLLATERAL_REDUCER_TOGGLE_MODAL",
      isAdd: isModalAdd
    });
  }

  const closeModal = useCallback(() => {
    dispatch({
      type: "LOR_COLLATERAL_REDUCER_CLOSE_MODAL"
    });
    setOpenAssetsForm(false);
    setAssetsData(false);
  }, [dispatch]);

  const { data } = additionalAssetData;

  const handleUpdateAdditionalAssetData = useCallback(
    (group, assetClass) =>
      updateAdditionalAssetData(group, assetClass, data, loanApp, onError, () =>
        closeModal()
      ),
    [data, loanApp, onError, closeModal]
  );
  const handleAddAdditionalAssetData = useCallback(
    (group, assetClass) =>
      addAdditionalAssetData(
        group,
        assetClass,
        data,
        assetModal,
        loanApp,
        onError,
        () => closeModal()
      ),
    [data, assetModal, loanApp, onError, closeModal]
  );

  function assetInputChange(e, { name, value }) {
    typecheck(name, "string");
    if (name === "entity_uuid") {
      getAssets(value);
      setModalEntityUuid(value);
      dispatch({
        type: "LOR_REDUCER_COLLATERAL_MODAL_SET_ASSET",
        asset: assetModal || {},
        value: null
      });
    } else {
      dispatch({
        type: "LOR_COLLATERAL_REDUCER_UPDATE_ASSET_MODAL",
        name,
        value
      });
    }
  }

  function assetSelectChange(e, { value }) {
    typecheck(value, "string");

    let asset = {};
    entityAssets.forEach(a => {
      if (a.uuid === value) {
        asset = a;
      }
    });
    dispatch({
      type: "LOR_REDUCER_COLLATERAL_MODAL_SET_ASSET",
      asset,
      value
    });
  }

  const collateralInputChange = useCallback(
    (e, { name, value }) => {
      // The state managed here really needs to be better designed, the same data
      // is being kept in two places.
      typecheck(name, "string");
      dispatch({
        type: "LOR_COLLATERAL_REDUCER_UPDATE_COLLATERAL_MODAL",
        payload: { [name]: value }
      });

      if (collaterals[modalLoanUuid]) {
        const index = collaterals[modalLoanUuid].findIndex(
          collateral => collateral.asset_uuid === assetModal.uuid
        );
        const nextCollaterals = collaterals[modalLoanUuid].map((c, i) =>
          i !== index ? c : { ...c, [name]: value }
        );
        dispatch({
          collaterals: nextCollaterals,
          type: "LOR_COLLATERAL_REDUCER_UPDATE_COLLATERAL",
          uuid: modalLoanUuid
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assetModal.uuid, dispatch, modalLoanUuid]
  );

  function showCollateralForm() {
    setOpenAssetsForm(false);
  }
  function openAssets() {
    setOpenAssetsForm(true);
  }
  function toggleModal(modalCollatUuid, modalLoanReqUuid) {
    typecheck(modalCollatUuid, "string");
    typecheck(modalLoanReqUuid, "string");

    dispatch({
      type: "LOR_COLLATERAL_REDUCER_TOGGLE_CONFIRMATION_MODAL",
      collatUuid: modalCollatUuid,
      loanReqUuid: modalLoanReqUuid
    });
  }

  async function updateLoanReq(loanUuid, modalData) {
    typecheck(loanUuid, "string");
    const { originationDscr, originalLtv, loan } = getOriginationDscrLtv(
      loanUuid,
      modalData,
      loanRequests,
      collaterals,
      assets
    );
    const newLoan = {
      ...loan,
      origination_dscr: originationDscr,
      original_ltv: originalLtv
    };
    try {
      const updatedLoan = await Loans.asyncUpdate(
        { loanUuid: newLoan.uuid },
        newLoan
      );
      if (updatedLoan.error) {
        onError(updatedLoan.error);
      }
    } catch (err) {
      onError(err);
    }
  }

  function onSuccessDeleteCollat() {
    dispatch({
      type: "LOR_COLLATERAL_REDUCER_REMOVE_COLLATERAL",
      collatUuid,
      loanReqUuid
    });
    // This is to send a dispatch to update the LoanOriginationReducer collaterals value
    dispatch({
      type: "loanApplications/collateral/delete_collateral",
      payload: {
        collatUuid,
        loanReqUuid
      }
    });
    updateLoanReq(loanReqUuid, { collat: { uuid: collatUuid }, asset: {} });
  }

  async function deleteCollat() {
    try {
      const removedCollateral = await Collateral.asyncRemove(collatUuid);
      if (removedCollateral.data) {
        onSuccessDeleteCollat();
      }
      if (removedCollateral.error) {
        onError(removedCollateral.error);
      }
    } catch (err) {
      onError(err);
    }
  }

  function onSuccessAddCollat(rsp, modalData) {
    typecheck(rsp.data.loan_uuid, "string");
    updateLoanReq(rsp.data.loan_uuid, modalData);
    collateralsToState(rsp.data.loan_uuid, {}, dispatch, "stepper");
  }

  async function onSuccessPostCollat(rsp, { collat }) {
    typecheck(rsp.data.uuid, "string");
    typecheck(collat, "object");

    const newCollat = { ...collat };
    newCollat.asset_uuid = rsp.data.uuid;
    try {
      const newCollateral = await Collateral.asyncAdd(newCollat);
      if (newCollateral.data) {
        newCollateral.data.market_value = rsp.data.market_value;
        onSuccessAddCollat(newCollateral, {
          collat: newCollat,
          asset: newCollateral.data
        });
      }
      if (newCollateral.error) {
        onError(newCollateral.error);
      }
    } catch (err) {
      onError(err);
    }
  }

  async function postForm() {
    if (assetModal?.uuid) {
      try {
        const newCollateralModal = {
          ...collateralModal,
          asset_uuid: assetModal.uuid
        };
        const newAssets = await Assets.asyncUpdate(assetModal);
        if (newAssets.data) {
          onSuccessAsset(newAssets);
        }
        if (newAssets.error) {
          onError(newAssets.error);
        }
        const newCollateral = await Collateral.asyncAdd(newCollateralModal);
        if (newCollateral.data) {
          onSuccessAddCollat(newCollateral, {
            collat: newCollateralModal,
            asset: assetModal
          });
        }
        if (newCollateral.error) {
          onError(newCollateral.error);
        }
      } catch (err) {
        onError(err);
      }
    } else {
      try {
        const newAssetModal = {
          ...assetModal,
          institution_uuid: loanApp.institution_uuid,
          entity_uuid: modalEntityUuid
        };
        const newAsset = await Assets.asyncAdd(newAssetModal);
        if (newAsset.data) {
          getAdditionalAssetData(
            newAsset.data.uuid,
            newAsset.data.asset_group,
            newAsset.data.asset_class,
            onError,
            setAdditionalAssetsData,
            setAssetsData
          );
          onSuccessPostCollat(newAsset, { collat: collateralModal });
          dispatch({
            type: "LOR_REDUCER_COLLATERAL_MODAL_SET_ASSET",
            asset: newAsset.data,
            value: null
          });
        }
        if (newAsset.error) {
          onError(newAsset.error);
        }
      } catch (err) {
        onError(err);
      }
    }
    if (assetModal?.asset_group !== "Other") {
      openAssets();
    } else {
      closeModal();
    }
  }

  async function putForm() {
    try {
      const newAssets = await Assets.asyncUpdate(assetModal);
      if (newAssets.data) {
        onSuccessAsset(newAssets);
      }
      if (newAssets.error) {
        onError(newAssets.error);
      }
      const newCollateral = await Collateral.asyncUpdate(collateralModal);
      if (newCollateral.data) {
        onSuccessAddCollat(newCollateral, {
          collat: collateralModal,
          asset: assetModal
        });
      }

      if (newCollateral.error) {
        onError(newCollateral.error);
      }
    } catch (err) {
      onError(err);
    }
    if (assetModal?.asset_group !== "Other") {
      openAssets();
    } else {
      closeModal();
    }
  }

  async function updateIsPrimary() {
    const loanCollaterals = collaterals[collateralModal.loan_uuid];
    let collateral = [];
    let onSuccess;
    if (loanCollaterals) {
      collateral = loanCollaterals.filter(c => c.is_primary === true);
    }
    if (isAdd) {
      onSuccess = postForm;
    } else {
      onSuccess = putForm;
    }
    if (collateral[0]) {
      const firstCollateral = { ...collateral[0], is_primary: false };
      try {
        const { data: newCollateral, error } = await Collateral.asyncUpdate(
          firstCollateral
        );

        if (newCollateral) {
          onSuccess();
        }
        if (error) {
          onError(error);
        }
      } catch (err) {
        onError(err);
      }
    } else {
      onSuccess();
    }
  }

  function onSave() {
    if (collateralModal.is_primary) {
      updateIsPrimary();
    } else if (isAdd) {
      postForm();
    } else {
      putForm();
    }
  }

  // This will send a dispatch to sync LorCollateralReducer with
  // LoanOriginationReducer on asset CRUD

  if (!loanApp || !loanApp.uuid) {
    return null;
  }

  const color = readOnly ? "grey" : "green";
  const tableHeaders = headers.map(header => (
    <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
  ));

  const transformedLoanRequests = loanRequests;
  const transformedCollateral = getTransformedCollateral(collaterals);

  const { perLoan } = CollateralLtvAndCoverageRatio(
    assets,
    transformedCollateral,
    transformedLoanRequests
  );
  const loanValues = {};
  perLoan.forEach(loan => {
    loanValues[loan.uuid] = loan;
  });

  const sortedLoanRequests = sortArrByDateKey(
    Object.values(loanRequests),
    "created_datetime"
  );

  const tables = sortedLoanRequests.map((loanReq, index) => {
    let tableRows;
    if (collaterals[loanReq.uuid] && assets) {
      tableRows = collaterals[loanReq.uuid].map(collat => {
        const asset = assets[collat.asset_uuid] || "";
        return (
          <Table.Row key={collat.uuid}>
            <Table.Cell>
              {collat.is_primary ? <Icon name="check" color="green" /> : ""}
            </Table.Cell>
            <Table.Cell>{asset.description}</Table.Cell>
            <Table.Cell>{asset.asset_group}</Table.Cell>
            <Table.Cell>{asset.asset_class}</Table.Cell>
            <Table.Cell>{asset.asset_type}</Table.Cell>
            <Table.Cell>{PrettyCurrency(asset.market_value || 0)}</Table.Cell>
            <Table.Cell>
              {collat.prior_liens ? collat.prior_liens.toFixed(2) : 0}
            </Table.Cell>
            <Table.Cell>{PrettyCurrency(asset.cost || 0)}</Table.Cell>
            <Table.Cell>{`${parseFloat(
              collat.advance_rate ? collat.advance_rate * 100 : 0
            )}%`}</Table.Cell>
            <Table.Cell>
              {collat.cross_collateralized ? "Yes" : "No"}
            </Table.Cell>
            <Table.Cell>{collat.lien_position}</Table.Cell>
            <Table.Cell>{collat.has_grantor ? "Yes" : "No"}</Table.Cell>
            <Table.Cell>{asset.value_date}</Table.Cell>
            <Table.Cell>{asset.value_source}</Table.Cell>
            <Table.Cell singleLine>
              <EditButton
                disabled={readOnly}
                onClick={() => openModal("", loanReq.uuid, asset, collat)}
                data-testid="edit-collateral-button"
              />
              <Button
                basic
                circular
                data-testid="delete-collateral-icon"
                disabled={readOnly}
                icon
                onClick={() => toggleModal(collat.uuid, loanReq.uuid)}
              >
                <Icon name="close" color={color} />
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      });
    }

    let ltv = 0;
    let coverageRatio = 0;
    if (loanReq.uuid && loanValues[loanReq.uuid]) {
      ltv = loanValues[loanReq.uuid].loanReqLtv;
      coverageRatio = loanValues[loanReq.uuid].loanReqDscr;
    }

    return (
      <div key={`${loanReq.uuid}`}>
        <Header as="h1" size="medium">
          {`Collateral Associated with Loan Request ${
            index + 1
          } - ${PrettyCurrency(loanReq.original_amount || 0, false)}`}
        </Header>
        <Grid>
          <Grid.Row columns="4">
            <Grid.Column>
              <b>{`LTV for Loan # ${index + 1} is ${(ltv * 100).toFixed(
                2
              )} %`}</b>
            </Grid.Column>
            <Grid.Column>
              <b>
                <Checkbox
                  disabled={readOnly}
                  label="LTV Policy Exception"
                  onClick={() => onCheckboxClick(loanReq)}
                  checked={loanReq.ltv_policy_exception}
                />
              </b>
            </Grid.Column>
            <Grid.Column>
              <b>{`Coverage Ratio for Loan #${
                index + 1
              } is ${coverageRatio}`}</b>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button
                basic
                circular
                data-testid="add-collateral-icon"
                disabled={readOnly}
                icon
                floated="right"
                onClick={() => openModal("isAdd", loanReq.uuid)}
              >
                <Icon name="plus" color={color} />
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Table key={loanReq.uuid}>
          <Table.Header>
            <Table.Row>{tableHeaders}</Table.Row>
          </Table.Header>
          <Table.Body>{tableRows}</Table.Body>
        </Table>
      </div>
    );
  });

  const primaryCollateral =
    collaterals[modalLoanUuid] &&
    collaterals[modalLoanUuid].filter(
      collateral => collateral.is_primary === true
    );
  const isPrimaryCollateral = primaryCollateral?.length
    ? primaryCollateral
    : false;

  return (
    <BorderedContainer width="100%">
      {isAdd || assetModal ? (
        <CollateralAddEdit
          additionalAssetData={additionalAssetData.data}
          allowedEntities={collateralEntities}
          asset={assetModal}
          assetInputChange={assetInputChange}
          assetSelectChange={assetSelectChange}
          cancelCallback={closeModal}
          checkboxChange={collateralInputChange}
          collateral={collateralModal}
          collateralInputChange={collateralInputChange}
          collaterals={collaterals}
          data-testid="collateral-add-edit"
          entityAssets={entityAssets}
          institutionUuid={loanApp.institution_uuid}
          isAdd={isAdd}
          isOpen={isOpen}
          isPrimaryCollateral={isPrimaryCollateral}
          modalEntityUuid={modalEntityUuid}
          onError={onError}
          openAssetsForm={openAssetsForm}
          parentUuid={loanAppUuid}
          resetAssetData={resetAssetData}
          showCollateralForm={showCollateralForm}
          submitCallback={onSave}
          updateAdditionalAssetData={
            assetDataFound
              ? handleUpdateAdditionalAssetData
              : handleAddAdditionalAssetData
          }
          updateValue={updateValue}
          borrowers={borrowers}
        />
      ) : null}
      <ConfirmationModal
        data-testid="collateral-confirmation-modal"
        handleCancel={() => toggleModal("", "")}
        handleConfirm={deleteCollat}
        prompt="Delete Collateral"
        open={confirmModal}
        warningDetails="Are you sure you want to delete this Collateral?"
        yesButtonLabel="Delete"
      />
      <Grid>
        <Grid.Row columns="3">
          <Grid.Column>
            <Header as="h2" size="medium">
              {/*
              {`Overall LTV is ${overall.overallLtv * 100} %`}
              -- values removed for now as additional information pending
              */}
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header as="h2" size="medium">
              {/*
              {`Overall Coverage Ratio is ${overall.overallCoverage}`}
               -- values removed for now values removed for now as additional information pending
              */}
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider section />
      {tables}
    </BorderedContainer>
  );
}

CollateralObj.propTypes = {
  assetModal: PropTypes.shape(),
  assets: PropTypes.objectOf(PropTypes.object).isRequired,
  collateralModal: PropTypes.shape({
    loan_uuid: PropTypes.string,
    asset_uuid: PropTypes.string,
    institution_uuid: PropTypes.string,
    entity_uuid: PropTypes.string,
    is_primary: PropTypes.bool
  }).isRequired,
  collaterals: PropTypes.objectOf(PropTypes.array).isRequired,
  collatUuid: PropTypes.string.isRequired,
  confirmModal: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  entityAssets: PropTypes.arrayOf(PropTypes.object).isRequired,
  isAdd: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loanApp: PropTypes.shape().isRequired,
  loanAppUuid: PropTypes.string.isRequired,
  loanRequests: PropTypes.objectOf(PropTypes.object).isRequired,
  loanReqUuid: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  collateralEntities: PropTypes.objectOf(PropTypes.bool),
  collateralAssetPagination: PropTypes.shape({
    number: PropTypes.number,
    size: PropTypes.number,
    lastPage: PropTypes.bool
  })
};

export default CollateralObj;
