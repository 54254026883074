import { useState } from "react";
import { useIntl } from "react-intl";
import {
  DialogSubtext,
  DialogSubtextProps
} from "../dialog-subtext/dialog-subtext";
import { MessageProps } from "../../../../types/text";
import {
  ButtonsComponentDialogProps,
  ComponentDialog
} from "../component-dialog/component-dialog";
import { DialogDismissProps } from "../../../../types/modules";

/**
 * A basic dialog that displays a preformatted component along with a single
 * button to dismiss the dialog and a text field to input a confirm message.
 *
 * This is an animated dialog so it must remain mounted until the onHidden prop
 * function has been invoked.
 */
export function HighFrictionDialog({
  confirmationValue = "CONFIRM",
  onDismiss,
  onHidden,
  cancelButtonText = { id: "CANCEL" },
  confirmButtonText = { id: "SUBMIT" },
  headerTitle = { id: "FRICTION_DIALOG_ARE_YOU_SURE" },
  confirmationMessages = [
    { message: { id: "FRICTION_DIALOG_ENTER_MESSAGE_YES" } }
  ],
  children,
  size,
  compareFunction = (input, confirmationValue) =>
    input.toLowerCase() === confirmationValue.toLowerCase()
}: HighFrictionDialogProps) {
  const [hide, setHide] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const { formatMessage } = useIntl();

  const handleDismiss: HighFrictionDialogProps["onDismiss"] = (status, uid) => {
    setHide(true);
    onDismiss?.(uid === "submit" ? "affirmative" : "negative");
  };

  return (
    <ComponentDialog
      buttons={[
        {
          message: cancelButtonText,
          uid: "cancel"
        },
        {
          message: confirmButtonText,
          disabled: !confirmed,
          isPrimary: true,
          uid: "submit"
        }
      ]}
      header={headerTitle}
      hide={hide}
      onDismiss={handleDismiss}
      onHidden={onHidden}
      size={size}
    >
      <>
        {children}
        {confirmationMessages?.map((message: DialogSubtextProps, index) => {
          return <DialogSubtext {...message} key={index} />;
        })}
        <div className="ui input">
          <input
            aria-label="Enter Text"
            autoFocus={true}
            onChange={({ target }) =>
              setConfirmed(compareFunction(target.value, confirmationValue))
            }
            type="text"
            placeholder={formatMessage({ id: "TYPE_CONFIRM" })}
          />
        </div>
      </>
    </ComponentDialog>
  );
}

export interface HighFrictionDialogProps
  extends Omit<
      ButtonsComponentDialogProps,
      "buttons" | "children" | "header" | "hide" | "onDismiss"
    >,
    DialogDismissProps {
  /** An array of DialogSubtext that would be render as a warning for the user. DialogSubtext is just an Interface of type
   * {
   *  content: MessageProps;
   *  classes?: { containerClasses?: string[]; contentClasses: string[] };
   *  }
   */
  confirmationMessages?: DialogSubtextProps[];
  /** This is the value the user has to type in to continue with the action */
  confirmationValue?: string;
  /** The message rendered for the cancel button  */
  cancelButtonText?: MessageProps;
  /** The message rendered for the confirm button  */
  confirmButtonText?: MessageProps;
  /** The Message to display in the header of the dialog. */
  headerTitle?: MessageProps;
  /** A react element that would be rendered just after the Header */
  children?: React.ReactNode;
  compareFunction?: (input: string, confirmationValue: string) => boolean;
}
