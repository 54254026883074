import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Input
} from "semantic-ui-react";

import { Payoff } from "../../../../../../services/ApiLib";
import {
  prettyDate,
  dateFromString
} from "../../../../../../services/DateTime";
import formatAsCurrency from "../../../../../../services/CurrencyFormatting";
import logger from "../../../../../../services/logger";

export class PayoffCalculatorObj extends React.Component {
  constructor(props) {
    super(props);

    this.clearReceipt = this.clearReceipt.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onError = this.onError.bind(this);
    this.onSuccessPayoff = this.onSuccessPayoff.bind(this);
    this.submitPayoff = this.submitPayoff.bind(this);
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: "PAYOFF_REDUCER_RESET_STATE"
    });
  }

  onDateChange(date) {
    this.props.dispatch({
      type: "PAYOFF_REDUCER_UPDATE_DATE",
      date
    });
  }

  onError(error) {
    logger.error(error); // This will be updated on a later pr which creates a global error handler
    this.props.dispatch({
      type: "PAYOFF_REDUCER_UPDATE_ERROR",
      error
    });
  }

  onSuccessPayoff(rsp) {
    const receipt = {
      curBal: rsp.data.current_balance,
      interest: rsp.data.interest,
      fees: rsp.data.fees,
      payoff: rsp.data.payoff,
      payAheadBalance: rsp.data.pay_ahead_balance
    };
    this.props.dispatch({
      type: "PAYOFF_REDUCER_UPDATE_PAYOFF_RECEIPT",
      receipt
    });
    this.clearReceipt();
  }

  clearReceipt() {
    this.props.dispatch({
      type: "PAYOFF_REDUCER_UPDATE_DISPLAY_RECEIPT",
      displayReceipt: this.props.displayReceipt
    });
  }

  submitPayoff() {
    const { date, jwt, loanUuid } = this.props;
    const params = { uuid: loanUuid, effectiveDate: dateFromString(date) };
    Payoff.get(jwt, this.onSuccessPayoff, this.onError, params, loanUuid);
  }

  render() {
    const { date, displayReceipt, receipt } = this.props;
    const { curBal, interest, fees, payoff, payAheadBalance } = receipt;
    if (displayReceipt) {
      return (
        <Container>
          <Grid>
            <Grid.Row>
              <Header as="h2">
                The payoff balance as of {prettyDate(date)} is{" "}
                {formatAsCurrency(payoff)}.
              </Header>
            </Grid.Row>
            <Grid.Row columns="2">
              <Grid.Column width="6">
                <Header as="h3">Current Balance:</Header>
              </Grid.Column>
              <Grid.Column width="6">
                <Header as="h3">{formatAsCurrency(curBal)}</Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
              <Grid.Column width="6">
                <Header as="h3">Interest:</Header>
              </Grid.Column>
              <Grid.Column width="6">
                <Header as="h3">{formatAsCurrency(interest)}</Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
              <Grid.Column width="6">
                <Header as="h3">Fees:</Header>
              </Grid.Column>
              <Grid.Column width="6">
                <Header as="h3">{formatAsCurrency(fees)}</Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
              <Grid.Column width="6">
                <Header as="h3">Payoff:</Header>
              </Grid.Column>
              <Grid.Column width="6">
                <Header as="h3">{formatAsCurrency(payoff)}</Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
              <Grid.Column width="6">
                <Header as="h3">Pay Ahead Balance:</Header>
              </Grid.Column>
              <Grid.Column width="6">
                <Header as="h3">{formatAsCurrency(payAheadBalance)}</Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      );
    }
    return (
      <div>
        <Header as="h1">Loan Payoff Calculator</Header>
        <Divider />
        <Container>
          <Grid>
            <Grid.Row columns="2" textAlign="left">
              <Grid.Column width="4" verticalAlign="middle">
                <Header as="h2" size="medium">
                  Payoff Date:
                </Header>
              </Grid.Column>
              <Grid.Column width="4">
                <Input
                  type="date"
                  onChange={e => this.onDateChange(e.target.value)}
                  name="payment_date"
                  value={date}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="1">
              <Grid.Column width="8">
                <Button
                  color="blue"
                  disabled={!date}
                  fluid
                  size="large"
                  onClick={this.submitPayoff}
                >
                  Calculate Payoff
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}

PayoffCalculatorObj.propTypes = {
  date: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  displayReceipt: PropTypes.bool.isRequired,
  jwt: PropTypes.string.isRequired,
  loanUuid: PropTypes.string.isRequired,
  receipt: PropTypes.shape({
    fees: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    curBal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    interest: PropTypes.number,
    payoff: PropTypes.number,
    payAheadBalance: PropTypes.number
  }).isRequired
};

const mapStateToProps = state => ({
  date: state.PayoffCalculatorReducer.date,
  displayReceipt: state.PayoffCalculatorReducer.displayReceipt,
  jwt: state.auth.jwt,
  receipt: state.PayoffCalculatorReducer.receipt
});

const PayoffCalculator = connect(mapStateToProps)(PayoffCalculatorObj);

export default PayoffCalculator;
