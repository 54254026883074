import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Form, Pagination, Message } from "semantic-ui-react";
import AcctHistoryTable from "./components/AcctHistoryTable";

function AcctHistory({
  logs,
  error,
  pagination,
  tableOptions,
  onView,
  onCleanup,
  onFilterChange,
  onPaginationChange,
  onDismissError
}) {
  const [{ searchQuery, startDate, endDate }, setFilters] = useState({});

  const handleFilterChange = ev => {
    const { name: key, value } = ev.target;
    setFilters(currentFilters => {
      const newFilters = { ...currentFilters, [key]: value };
      onFilterChange(newFilters);
      return newFilters;
    });
  };

  useEffect(() => {
    // This can be used to load data when the component mounts/unmounts
    if (onView) onView();
    return onCleanup;
  }, [onView, onCleanup]);

  const showPagination =
    pagination &&
    (pagination.number !== 0 ||
      (pagination.number === 0 && !pagination.isLastPage));

  return (
    <Grid padded>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                name="searchQuery"
                id="searchQuery"
                icon="search"
                label="Search..."
                value={searchQuery || ""}
                onChange={handleFilterChange}
              />
              <Form.Input
                name="startDate"
                id="startDate"
                type="date"
                label="Start Date Range"
                value={startDate || ""}
                onChange={handleFilterChange}
              />
              <Form.Input
                name="endDate"
                id="endDate"
                type="date"
                label="End Date Range"
                value={endDate || ""}
                onChange={handleFilterChange}
              />
            </Form.Group>
          </Form>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {error && (
            <Message
              error
              header="Error"
              content={error}
              onDismiss={onDismissError}
            />
          )}
          <AcctHistoryTable logs={logs} tableOptions={tableOptions} />
          {showPagination ? (
            <Pagination
              boundaryRange={0}
              activePage={pagination.number + 1}
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              siblingRange={1}
              totalPages={pagination.number + (pagination.isLastPage ? 1 : 2)}
              floated="right"
              onPageChange={(ev, { activePage }) => {
                onPaginationChange(
                  { ...pagination, number: activePage - 1 },
                  { searchQuery, startDate, endDate }
                );
              }}
            />
          ) : null}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

AcctHistory.propTypes = {
  logs: PropTypes.arrayOf(PropTypes.shape()),
  pagination: PropTypes.shape({
    number: PropTypes.number,
    size: PropTypes.number,
    isLastPage: PropTypes.bool
  }),
  error: PropTypes.string,
  tableOptions: PropTypes.shape(),
  onView: PropTypes.func,
  onCleanup: PropTypes.func,
  onFilterChange: PropTypes.func,
  onPaginationChange: PropTypes.func,
  onDismissError: PropTypes.func
};

export default AcctHistory;
