import { configureStore } from "@reduxjs/toolkit";
import thunk from "./middleware/thunk-with-payload-middleware";
import createServiceMiddleware from "./middleware/api-service-middleware";
import rootReducer from "./reducers";
import apiServices from "../services/APIServices";
import { blastApi } from "../services/RTKQuery/blastApi";

export const middleware = [thunk, createServiceMiddleware(apiServices)];

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
      .concat(blastApi.middleware)
      .concat(...middleware)
});

// export the single instance of store
export default store;
