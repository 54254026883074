export const formatLoanOfficerOptions = (
  loanOfficers: LoanOfficer[]
): any[] => {
  return loanOfficers.map(user => ({
    text: `${user.first_name} ${user.last_name}`,
    value: user.uuid
  }));
};

export interface LoanOfficer {
  avatar: string;
  first_name: string;
  has_lending_authority: boolean;
  last_name: string;
  username: string;
  uuid: string;
}

export const createLoanOfficersWithLendingAuthority = (
  loanOfficerNames: { first_name: string; last_name: string }[]
): LoanOfficer[] => {
  let loanOfficerNumberCount = 0;
  return loanOfficerNames.map(loanOfficerNameObject => {
    const firstName = loanOfficerNameObject.first_name;
    const lastName = loanOfficerNameObject.last_name;
    const loanOfficer = {
      avatar: null,
      first_name: firstName,
      has_lending_authority: true,
      last_name: lastName,
      username: `${firstName.toLowerCase()}.${lastName.toLowerCase()}@bafs.com`,
      uuid: `SAMPLE_UUID_${loanOfficerNumberCount}`
    };
    loanOfficerNumberCount += 1;
    return loanOfficer;
  });
};
