import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "semantic-ui-react";

function CheckboxCell({
  row,
  column,
  toggleAllRowsSelected // generated from useSelectRow hook
}) {
  return (
    <Checkbox
      data-testid={`${row?.original?.uuid}-select-row`}
      onChange={data => {
        if (column?.singleSelection) {
          toggleAllRowsSelected(false);

          if (row.toggleRowSelected(!row.isSelected) && column?.onSelect) {
            column?.onSelect(data);
          }
        } else if (row.toggleRowSelected() && column?.onSelect) {
          column?.onSelect(data);
        }
      }}
      checked={row.isSelected || false}
    />
  );
}

CheckboxCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.any,
    column: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    onLock: PropTypes.func
  }).isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string.isRequired
    }),
    toggleRowSelected: PropTypes.func,
    isSelected: PropTypes.bool
  }).isRequired,
  column: PropTypes.shape({
    onSelect: PropTypes.func,
    singleSelection: PropTypes.bool
  }),
  toggleAllRowsSelected: PropTypes.func,
  singleSelection: PropTypes.bool
};

export default CheckboxCell;
