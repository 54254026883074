import React from "react";
import { Form, Dropdown } from "semantic-ui-react";
import { func } from "prop-types";
import { EditModal } from "../../../../../../../../../../../../components/Modals";
import {
  DateInput,
  DollarField
} from "../../../../../../../../../../../../components/CustomFormElements";
import { dateFromString } from "../../../../../../../../../../../../services/DateTime";
import Constants from "../../../../../../../../../../../../services/Constants/strings";
import { RemoveEmptyStrings } from "../../../../../../../../../../../../services/ApiLib/services/Utilities";
import Layout from "./Layout";

const { INTEREST_ONLY } = Constants;

const propTypes = {
  onSave: func
};
const statusOptions = [
  { key: "Active", value: "Active", text: "Active" },
  { key: "Completed", value: "Completed", text: "Completed" },
  { key: "Upcoming", value: "Upcoming", text: "Upcoming" }
];

const paymentTypeOptions = [
  { key: "P&I", value: "P&I", text: "P&I" },
  { key: INTEREST_ONLY, value: INTEREST_ONLY, text: INTEREST_ONLY },
  { key: "Maturity", value: "Maturity", text: "Maturity" },
  { key: "Principal", value: "Principal", text: "Principal" }
];

const postingMethodOptions = [
  { key: "Effective", value: "Effective", text: "Effective" },
  { key: "Due", value: "Due", text: "Due" }
];

const paymentFrequencyTypeOptions = [
  { key: "Day(s)", value: "Day(s)", text: "Day(s)" },
  { key: "Week(s)", value: "Week(s)", text: "Week(s)" },
  { key: "Month(s)", value: "Month(s)", text: "Month(s)" },
  { key: "Year(s)", value: "Year(s)", text: "Year(s)" },
  { key: "DOM 29", value: "DOM 29", text: "DOM 29" },
  { key: "DOM 30", value: "DOM 30", text: "DOM 30" },
  { key: "EOM(s)", value: "EOM(s)", text: "EOM(s)" },
  { key: "EOQ", value: "EOQ", text: "EOQ" },
  { key: "15 & EOM", value: "15 & EOM", text: "15 & EOM" }
];

function AddScheduleItemModal({ onSave, ...props }) {
  const handleSave = values => {
    if (Object.keys(values).length) {
      const updatedValues = RemoveEmptyStrings(values);
      onSave(updatedValues);
    }
  };
  return (
    <EditModal
      header="Add Payment Schedule Item"
      size="small"
      onSave={handleSave}
      required={{
        posting_method: {
          condition: "Variable",
          required: ["Effective", "Due"]
        },
        status: {
          condition: "Variable",
          required: ["Active", "Completed", "Upcoming"]
        }
      }}
      {...props}
    >
      {({ onChange, values }) => (
        <>
          <Layout>
            <Form.Field
              clearable
              control={Dropdown}
              label="Status"
              name="status"
              onChange={(ev, { name, value }) => onChange({ name, value })}
              options={statusOptions}
              required
              selection
              value={values.status}
            />
            <Form.Field
              control={Dropdown}
              label="Payment Type"
              name="payment_type"
              clearable
              options={paymentTypeOptions}
              selection
              value={values.payment_type}
              onChange={(ev, { name, value }) => {
                if (value === INTEREST_ONLY) {
                  onChange({ name: "posting_method", value: "Due" });
                }
                onChange({ name, value });
              }}
            />
            <DateInput
              asForm
              label="First Payment Date"
              onChange={(_, { name, value }) =>
                onChange({ name, value: dateFromString(value) })
              }
              name="first_payment_date"
              value={values.first_payment_date}
            />
            <DateInput
              asForm
              label="Next Payment Date"
              onChange={(_, { name, value }) =>
                onChange({ name, value: dateFromString(value) })
              }
              name="next_payment_date"
              value={values.next_payment_date}
            />
            <DollarField
              label="Next Scheduled Payment Amount"
              name="next_payment_amount"
              onChange={onChange}
              value={values.next_payment_amount}
            />
            <Form.Input
              type="number"
              label="Bills Remaining"
              name="bills_remaining"
              onChange={({ target: { name, value } }) =>
                onChange({ name, value: Number(value) })
              }
              value={values.bills_remaining || ""}
            />
            <Form.Input
              type="number"
              label="Total No. of Payments"
              name="total_number_of_payments"
              onChange={({ target: { name, value } }) =>
                onChange({ name, value: Number(value) })
              }
              value={values.total_number_of_payments || ""}
            />
            <Form.Field
              clearable
              control={Dropdown}
              label="Posting Method"
              disabled={values.payment_type === INTEREST_ONLY}
              name="posting_method"
              onChange={(ev, { name, value }) => onChange({ name, value })}
              options={postingMethodOptions}
              required
              selection
              value={values.posting_method || ""}
            />
            <Form.Input
              type="number"
              label="Payment Frequency"
              name="payment_frequency"
              action={
                <Dropdown
                  name="payment_frequency_type"
                  button
                  basic
                  floating
                  options={paymentFrequencyTypeOptions}
                  value={values.payment_frequency_type}
                  onChange={(ev, { name, value }) => onChange({ name, value })}
                />
              }
              placeholder="frequency"
              value={values.payment_frequency || ""}
              onChange={(ev, { name, value }) =>
                onChange({ name, value: Number(value) })
              }
              min="1"
            />
          </Layout>
          * Required Field
        </>
      )}
    </EditModal>
  );
}
AddScheduleItemModal.propTypes = propTypes;
export default AddScheduleItemModal;
