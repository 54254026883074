import { createActions } from "redux-actions";
import { LoanPaymentSchedules as scheduleService } from "../../../../../../../../services/ApiLib";

export const initialState = {
  paymentSchedules: [],
  pagination: {},
  error: "",
  tableOptions: { sortBy: ["processing_order"] }
};

const defaultPagination = { number: 0, size: 20 };
export const actionCreators = createActions(
  {
    loadPaymentSchedules:
      (uuid, pagination = defaultPagination, filter = {}) =>
      dispatch => {
        filter["order_by"] = "first_payment_date";
        scheduleService.read(uuid, null, filter, pagination).then(schedules => {
          dispatch(actionCreators.populatePaymentSchedules(schedules.data));
          dispatch(
            actionCreators.populatePagination({
              ...pagination,
              isLastPage: schedules.metaData.last_page
            })
          );
        });
      },
    onCleanup: () => dispatch => {
      dispatch(actionCreators.populatePagination(defaultPagination));
    },
    onAddSchedule: (loan, scheduleData) => dispatch => {
      const body = {
        loan_uuid: loan.uuid,
        institution_uuid: loan.institution_uuid,
        processing_order: 0,
        ...scheduleData
      };
      scheduleService.create(loan.uuid, body).then(() => {
        dispatch(actionCreators.loadPaymentSchedules(loan.uuid));
      });
    },
    onEditSchedule: (loan, body) => dispatch => {
      scheduleService.updateV2(loan.uuid, body.uuid, body).then(() => {
        dispatch(actionCreators.loadPaymentSchedules(loan.uuid));
      });
    },
    onDelete: (loanUuid, scheduleUuid) => dispatch => {
      scheduleService.remove(
        () => {
          dispatch(actionCreators.loadPaymentSchedules(loanUuid));
        },
        () => {},
        { loan_uuid: loanUuid, uuid: scheduleUuid }
      );
    }
  },
  "POPULATE_PAYMENT_SCHEDULES",
  "POPULATE_PAGINATION",
  "CLEAR_ERROR",
  {
    prefix: "LOAN_PAYMENT_SCHEDULES"
  }
);

// eslint-disable-next-line default-param-last
export default function PaymentScheduleReducer(state = initialState, action) {
  const { type } = action;
  const payload = action.payload ? action.payload : action;

  switch (type) {
    case "LOAN_PAYMENT_SCHEDULES/POPULATE_PAYMENT_SCHEDULES":
      return {
        ...state,
        paymentSchedules: payload
      };

    case "LOAN_PAYMENT_SCHEDULES/POPULATE_PAGINATION":
      return {
        ...state,
        pagination: payload
      };

    case "LOAN_PAYMENT_SCHEDULES/CLEAR_ERROR":
      return {
        ...state,
        error: ""
      };

    default:
      return state;
  }
}
