import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import { asyncFetchService } from "../../../FetchService";
import { FormatUrlV2 } from "../../../FormatUrl";

async function get(loanUuid) {
  TypeCheck(loanUuid, "string", false);
  let url = "";
  if (loanUuid) {
    url = FormatUrlV2(ApiEndpoints.creditRiskReviewGetAllForLoan, {
      loanUuid
    });
  }
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

async function add(body) {
  const url = FormatUrlV2(ApiEndpoints.creditRiskReviewPost, {});

  const rsp = await asyncFetchService("POST", url, body);
  return rsp;
}
export default {
  get,
  add
};
