/* eslint-env browser */
import ApiEndpoints from "../../../../services/ApiEndpoints";
import FormatUrl from "../../../../services/FormatUrl";

/*
Update Five Years Later:
Surely, this code is completely broken and not in use anymore. It relies on
a csrfToken - which we were using in Django, but not anymore.

We were getting csrfToken from Django & declaring it as a global const in
/revival/html/default.html.
*/

// TODO: Handle Errors

const initialState = {
  docs: [],
  errorMessage: "",
  filters: {
    category: "",
    institution: "",
    subcategory: "",
    tin: "",
    name: ""
  },
  formData: {
    category: "",
    institution: "",
    subcategory: "",
    tin: "",
    name: ""
  },
  paginationParams: {
    currentPage: 1,
    pageCount: 0,
    rowCount: 0,
    rowEnd: 0,
    rowsPerPage: 10,
    rowStart: 0
  },
  loading: true
};

// eslint-disable-next-line default-param-last
export default function DocMgmtReducer(state = initialState, action) {
  switch (action.type) {
    case "DOCS_POPULATE": {
      return { ...state, docs: [...state.docs, action.docs] };
    }

    case "DOCS_LOADING_TOGGLE": {
      const loadingStateCopy = { ...state.loading };
      const newLoadingState = !loadingStateCopy;
      return {
        ...state,
        loading: newLoadingState
      };
    }

    case "DOCS_ERROR_MESSAGE": {
      return {
        ...state,
        errorMessage: action.errorMessage
      };
    }

    case "DOCS_PAGINATION_ROWS": {
      const paginationParamsCopy = { ...state.paginationParams };
      paginationParamsCopy.rowsPerPage = action.rowsPerPage;
      paginationParamsCopy.currentPage = 1;
      return {
        ...state,
        paginationParams: paginationParamsCopy
      };
    }

    case "DOCS_PAGINATION_PARAMS": {
      const paginationParamsCopy = { ...state.paginationParams };
      paginationParamsCopy[action.param] = action.paramValue;
      return {
        ...state,
        paginationParams: paginationParamsCopy
      };
    }

    case "DOCS_APPROVE": {
      const url = FormatUrl(ApiEndpoints.docsApprove, action.uuid);
      const fetchParams = {
        method: "POST",
        body: {},
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": window.csrfToken
        },
        credentials: "same-origin"
      };
      if (!action.testing) {
        fetch(url, fetchParams);
      }
      return {
        ...state,
        docs: [
          ...state.docs.filter(docDetails => docDetails.uuid !== action.uuid)
        ]
      };
    }

    case "DOCS_REJECT": {
      const url = FormatUrl(ApiEndpoints.docsReject, action.uuid);
      const myData = {
        review_notes: action.review_notes
      };
      const fetchParams = {
        method: "POST",
        body: JSON.stringify(myData),
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": window.csrfToken
        },
        credentials: "same-origin"
      };
      if (!action.testing) {
        fetch(url, fetchParams);
      }
      return {
        ...state,
        docs: [
          ...state.docs.filter(docDetails => docDetails.uuid !== action.uuid)
        ]
      };
    }

    case "DOCS_EDIT_SAVE_VALUE_TYPING": {
      const docIndex = state.docs.findIndex(x => x.uuid === action.uuid);
      const newState = { ...state };
      newState.docs[docIndex][action.fieldName] = action.inputValue;
      const myData = {
        field: action.fieldName,
        fieldValue: action.inputValue
      };
      const url = FormatUrl(ApiEndpoints.docsEdit, action.uuid);
      const fetchParams = {
        method: "POST",
        body: JSON.stringify(myData),
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": window.csrfToken
        },
        credentials: "same-origin"
      };
      if (!action.testing) {
        fetch(url, fetchParams);
      }
      return { ...state, docs: [...newState.docs] };
    }

    case "DOCS_EDIT_UNDO_TYPING": {
      const newState = { ...state };
      const docIndex = state.docs.findIndex(x => x.uuid === action.data.uuid);
      newState.docs.splice(docIndex, 1, state.restoreState);
      const url = FormatUrl(ApiEndpoints.docsReset, action.data.uuid);
      const fetchParams = {
        method: "POST",
        body: JSON.stringify(state.restoreState),
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": window.csrfToken
        },
        credentials: "same-origin"
      };
      if (!action.testing) {
        fetch(url, fetchParams);
      }
      return { ...state, docs: [...newState.docs] };
    }

    case "DOCS_EDIT_START_RESTORE_STATE": {
      return {
        ...state,
        restoreState: JSON.parse(JSON.stringify(action.data))
      };
    }

    case "DOCS_EDIT_RESET_RESTORE_STATE": {
      return { ...state, restoreState: undefined };
    }

    case "DOCS_STATE_CLEAR_DOCS": {
      return {
        ...state,
        docs: []
      };
    }

    case "DOCS_REVERT_TO_INITIAL_STATE": {
      return initialState;
    }

    case "DOCS_UPDATE_FORM_DATA": {
      const formDataCopy = { ...state.formData };
      formDataCopy[action.filter] = action.filterValue;
      return {
        ...state,
        formData: formDataCopy
      };
    }

    case "DOCS_APPLY_FILTER": {
      const formDataCopy = { ...state.formData };
      const initialStateCopy = { ...initialState };
      return {
        ...state,
        filters: formDataCopy,
        formData: initialStateCopy.formData
      };
    }

    case "DOCS_CLEAR_FILTER": {
      const filtersCopy = { ...state.filters };
      filtersCopy[action.filter] = "";
      return {
        ...state,
        filters: filtersCopy
      };
    }

    default:
      return state;
  }
}
