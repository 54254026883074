import { ClearableDropdown, Field } from "@bafsllc/ui-shared";
import { PropTypes } from "prop-types";

export default function ScaleDropdown({
  singleRiskScalesList,
  onDropdownChange,
  selectedSingleRiskScale,
  isViewMode
}) {
  const handleScaleSelect = e => {
    const selectedScale = singleRiskScalesList.find(
      scale => scale.uuid === e.target.getAttribute("value")
    );
    onDropdownChange({
      name: "selectedSingleRiskScale",
      value: selectedScale
    });
    onDropdownChange({
      name: "selectedSingleRiskScaleItem",
      value: null
    });
  };

  return (
    <Field labelMessage={{ id: "SCALE" }} required>
      <ClearableDropdown
        id="single_scale_uuid"
        onChildSelect={e => {
          handleScaleSelect(e);
        }}
        selectedValue={{
          defaultMessage:
            selectedSingleRiskScale &&
            `${selectedSingleRiskScale?.name} | Version ${selectedSingleRiskScale?.version}`
        }}
        onClear={() => {
          onDropdownChange({ name: "selectedSingleRiskScale", value: null });
          onDropdownChange({
            name: "selectedSingleRiskScaleItem",
            value: null
          });
        }}
        placeholder={{ id: "CREDIT_ANALYSIS_DROPDOWN_PLACEHOLDER" }}
        fill
        disabled={isViewMode}
      >
        {singleRiskScalesList
          ? singleRiskScalesList.map(scale => (
              <li key={scale.uuid} name="single_scale_uuid" value={scale.uuid}>
                {`${scale.name} | v${scale.version}`}
              </li>
            ))
          : ""}
      </ClearableDropdown>
    </Field>
  );
}

ScaleDropdown.propTypes = {
  singleRiskScalesList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      version: PropTypes.number
    })
  ).isRequired,
  onDropdownChange: PropTypes.func.isRequired,
  selectedSingleRiskScale: PropTypes.shape({
    name: PropTypes.string,
    version: PropTypes.number
  }).isRequired,
  isViewMode: PropTypes.bool
};
