import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "semantic-ui-react";

import ButtonStyles from "../../ButtonStyles";

import Constants from "../../../../../../services/Constants/strings";
import loadDocs from "../../../../../../services/DocMgmt";

import ApproveDocButton from "./components/row/components/ApproveDocButton";
import EditDocButton from "./components/row/components/EditDocButton";
import Filter from "./components/filter";
import PaginationControls from "../../../../../../components/Pagination";
import RejectDocDialog from "./components/row/components/RejectDocDialog";
import UserNotification from "./components/UserNotification";
import ViewDocButton from "./components/row/components/ViewDocButton";

function DocMgmtTableObj({
  docs,
  errorMessage,
  filters,
  formData,
  jwt,
  loading
}) {
  const dispatch = useDispatch();
  const { pagination, paginationParams } = useSelector(state => ({
    pagination: state.PaginationReducer.default,
    paginationParams: state.DocMgmtReducer.paginationParams
  }));
  const [lastPage, setLastPage] = useState(false);

  const reloadDocs = useCallback(() => {
    dispatch({
      type: "DOCS_ERROR_MESSAGE",
      errorMessage: ""
    });
    dispatch({
      type: "DOCS_STATE_CLEAR_DOCS"
    });
    loadDocs(dispatch, filters, pagination, jwt);
  }, [dispatch, filters, jwt, pagination]);

  useEffect(() => {
    reloadDocs();
    if (paginationParams.currentPage >= paginationParams.pageCount) {
      setLastPage(true);
    }
    return () => {
      dispatch({
        type: "DOCS_REVERT_TO_INITIAL_STATE"
      });
      dispatch({
        type: "PAGINATION_CLEAR_STATE"
      });
    };
  }, [
    pagination,
    dispatch,
    reloadDocs,
    paginationParams.currentPage,
    paginationParams.pageCount
  ]);

  function updateFormData(filter, data) {
    const filterValue = data.value;
    dispatch({
      type: "DOCS_UPDATE_FORM_DATA",
      filter,
      filterValue
    });
  }

  function filterApply() {
    dispatch({
      type: "DOCS_APPLY_FILTER"
    });
  }

  function removeFilter(filter) {
    dispatch({
      type: "DOCS_CLEAR_FILTER",
      filter
    });
  }

  let loadingIconClass = "fa fa-circle-o-notch fa-spin fa-3x fa-fw";
  if (!loading) {
    loadingIconClass = "";
  }
  return (
    <div>
      {errorMessage ? (
        <UserNotification callback={reloadDocs} message={errorMessage} />
      ) : (
        <span />
      )}
      <i className={loadingIconClass} aria-hidden="true" />
      <Filter
        callbackFilterApply={filterApply}
        callbackUpdateFormData={updateFormData}
        callbackFilterRemove={removeFilter}
        filters={filters}
        formData={formData}
      />
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{Constants.INSTITUTION}</Table.HeaderCell>
            <Table.HeaderCell>{Constants.CATEGORY}</Table.HeaderCell>
            <Table.HeaderCell>{Constants.SUBCATEGORY}</Table.HeaderCell>
            <Table.HeaderCell>{Constants.TIN}</Table.HeaderCell>
            <Table.HeaderCell>{Constants.ENTITY_NAME}</Table.HeaderCell>
            <Table.HeaderCell>{Constants.DESCRIPTION}</Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell />
            <Table.HeaderCell />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {docs.map(doc => (
            <Table.Row key={doc.uuid}>
              <Table.Cell>{doc.branch_no}</Table.Cell>
              <Table.Cell>{doc.doc_category}</Table.Cell>
              <Table.Cell>{doc.doc_subcategory}</Table.Cell>
              <Table.Cell>{doc.tax_id}</Table.Cell>
              <Table.Cell>{doc.name}</Table.Cell>
              <Table.Cell>{doc.description}</Table.Cell>
              <Table.Cell style={ButtonStyles.buttonRow}>
                <ViewDocButton docDetails={doc} />
              </Table.Cell>
              <Table.Cell style={ButtonStyles.buttonRow}>
                <ApproveDocButton docDetails={doc} />
              </Table.Cell>
              <Table.Cell style={ButtonStyles.buttonRow}>
                <RejectDocDialog uuid={doc.uuid} />
              </Table.Cell>
              <Table.Cell style={ButtonStyles.buttonRow}>
                <EditDocButton docDetails={doc} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <PaginationControls length={docs.length} lastPage={lastPage} />
    </div>
  );
}

DocMgmtTableObj.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  docs: PropTypes.arrayOf(PropTypes.object).isRequired,
  filters: PropTypes.shape({
    category: PropTypes.string,
    institution: PropTypes.string,
    name: PropTypes.string,
    subcategory: PropTypes.string,
    tin: PropTypes.string
  }).isRequired,
  formData: PropTypes.shape({
    category: PropTypes.string,
    institution: PropTypes.string,
    name: PropTypes.string,
    subcategory: PropTypes.string,
    tin: PropTypes.string
  }).isRequired,
  jwt: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired
};

export default DocMgmtTableObj;
