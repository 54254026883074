import React from "react";
import { useIntl } from "react-intl";
import type {
  AllIconClassNames,
  IconClassNames,
  SizeClassNames
} from "../../../types/elements";
import { MessageProps } from "../../../types/text";
import { HandleClassNames } from "../../../types/class-names";
import { getMessage } from "../../../util/text/message";

/**
 * A fomantic-ui icon.
 */
export const IconWithRef = React.forwardRef<HTMLElement, IconProps>(
  function IconImplAdapter(props, ref) {
    // Because Storybook can't extract prop type information from components
    // wrapped in `React.forwardRef` this module's code has been split into an
    // IconImpl component that does all the work and is exported for use only by
    // Storybook and an IconWithRef component that provides the ref
    // implementation.
    return <IconImpl {...props} implRef={ref} />;
  }
);

/**
 * A fomantic-ui icon.
 *
 * This component is meant for use only with Storybook and must not be exported
 * from ui-shared.
 * @see IconWithRef
 */
export function IconImpl({
  ariaLabel,
  disabled,
  icon,
  implRef,
  invertColor = false,
  onKeyUp,
  onIconSelect,
  size,
  updateClassNames,
  ...props
}: IconImplProps) {
  const { formatMessage } = useIntl();

  function handleClick(evt: React.MouseEvent<HTMLElement>) {
    if (disabled) {
      return;
    }

    onIconSelect && onIconSelect(evt);
  }

  function handleKeyUp(evt: React.KeyboardEvent<HTMLElement>) {
    if (disabled) {
      return;
    }

    if (evt.key === "Backspace" || evt.key === "Enter") {
      onIconSelect && onIconSelect(evt);
    }
    onKeyUp && onKeyUp(evt);
  }

  const initialClassNames: AllIconClassNames[] = ["icon", icon];
  invertColor && initialClassNames.push("inverted");
  size && size !== "medium" && initialClassNames.push(size);
  const className = (
    updateClassNames ? updateClassNames(initialClassNames) : initialClassNames
  ).join(" ");

  const iProps: React.HTMLAttributes<HTMLElement> = { ...props };

  if (onIconSelect && !disabled) {
    iProps.tabIndex = 0;
  }

  if (ariaLabel) {
    iProps["aria-label"] = getMessage(ariaLabel, formatMessage);
  }

  return (
    <i
      {...iProps}
      className={className}
      aria-disabled={disabled}
      role={onIconSelect ? "button" : iProps.role}
      onClick={handleClick}
      onKeyUp={handleKeyUp}
      ref={implRef}
    ></i>
  );
}

export interface IconProps
  extends Omit<React.HTMLAttributes<HTMLElement>, "className" | "onClick"> {
  /** A label for the icon that will not be visible. */
  ariaLabel?: MessageProps;
  /** If true the icon is not interactive. */
  disabled?: boolean;
  /** The icon to display. */
  icon: IconClassNames;
  /** Inverts the color? */
  invertColor?: boolean;
  /**
   * Invoked when the icon is clicked or the Enter or Backspace key is pressed.
   */
  onIconSelect?: (
    evt: React.MouseEvent | React.KeyboardEvent | KeyboardEvent | MouseEvent
  ) => void;
  /** Defaults to a "medium" size button. */
  size?: SizeClassNames;
  /** Optionally manipulate the icon's classnames. */
  updateClassNames?: HandleClassNames<AllIconClassNames>;
}

/**
 * This interface must not be exported from ui-shared.
 */
interface IconImplProps extends IconProps {
  /**
   * Storybook can not create documentation from components wrapped in
   * `React.forwardedRef` so this prop will provided the ref to the impl
   * component.
   */
  implRef?: React.ForwardedRef<HTMLElement>;
}
