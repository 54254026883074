import PropTypes from "prop-types";
import React, { Component } from "react";
import TypeCheck from "typecheck-extended";
import { Button } from "semantic-ui-react";
import { connect } from "react-redux";
import DocTitle from "../../../../components/DocTitle";

import EntitySearch from "../../../../components/EntitySearch";

import {
  CreditRequests,
  Entities,
  LoanApp,
  Relationships
} from "../../../../services/ApiLib";
import { isBafs } from "../../../../services/Auth";
import { stepList } from "../../services/constants";
import {
  relationshipParentChildTypes,
  relationshipTypes
} from "../../../../services/Constants/relationshipTypes";
import serializeRelationshipType from "../../../../services/Constants/serializeRelationshipType";

export class EntitySelectionObj extends Component {
  constructor(props) {
    super(props);
    this.addBusinessToRequest = this.addBusinessToRequest.bind(this);
    this.addIndividualToRequest = this.addIndividualToRequest.bind(this);
    this.addInstitutionToRequest = this.addInstitutionToRequest.bind(this);
    this.createNewLoanApp = this.createNewLoanApp.bind(this);
    this.dispatchEntityDetails = this.dispatchEntityDetails.bind(this);
    this.markRelationshipCreated = this.markRelationshipCreated.bind(this);
    this.processCreateNewLoanApp = this.processCreateNewLoanApp.bind(this);
  }

  markRelationshipCreated(rsp, relType) {
    TypeCheck(relType, "string");

    const { dispatch } = this.props;
    dispatch({
      relType: serializeRelationshipType(relType),
      type: "AUTO_DESC_MARK_REL_SUCCESS"
    });
  }

  processCreateNewLoanApp({ data }) {
    TypeCheck(data.app_id, "string");
    TypeCheck(data.uuid, "string");
    TypeCheck(data.institution_uuid, "string");

    const {
      businessInfo,
      dispatchEntireForm,
      formatRspAndDispatch,
      jwt,
      loanAppInfo,
      onError,
      personalInfo
    } = this.props;

    dispatchEntireForm(data, "loanAppInfo");

    const onSuccess = this.markRelationshipCreated;
    const borrowerUuid = businessInfo.uuid;
    const ownerUuid = personalInfo.uuid;
    const relationshipBorrower = {
      child_type: relationshipParentChildTypes.ENTITY,
      child_uuid: borrowerUuid,
      institution_uuid: data.institution_uuid,
      parent_type: relationshipParentChildTypes.ENTITY,
      parent_uuid: data.uuid,
      rel_type: relationshipTypes.BORROWER
    };
    const relationshipOwner = {
      child_type: relationshipParentChildTypes.ENTITY,
      child_uuid: ownerUuid,
      institution_uuid: data.institution_uuid,
      parent_type: relationshipParentChildTypes.ENTITY,
      parent_uuid: borrowerUuid,
      rel_type: relationshipTypes.OWNER
    };

    Relationships.add(
      relationshipOwner,
      jwt,
      onSuccess,
      onError,
      relationshipOwner.rel_type
    );
    Relationships.add(
      relationshipBorrower,
      jwt,
      onSuccess,
      onError,
      relationshipBorrower.rel_type
    );

    const body = {
      app_id: data.app_id,
      institution_uuid: loanAppInfo.institution_uuid,
      loan_app_uuid: data.uuid,
      loan_type: "Unsecured Loan"
    };
    const callbackData = { whichForm: "loanRequestInfo" };
    CreditRequests.add(jwt, formatRspAndDispatch, onError, body, callbackData);
  }

  createNewLoanApp() {
    const { businessInfo, jwt, loanAppInfo, onError, personalInfo, userUuid } =
      this.props;

    onError("");
    if (
      !loanAppInfo.institution_uuid ||
      !personalInfo.uuid ||
      !businessInfo.uuid
    ) {
      onError("Error: Please select a Borrower and Owner before continuing.");
      return;
    }
    const body = {
      institution_uuid: loanAppInfo.institution_uuid,
      source: "Auto Decision",
      submitted_by_user: userUuid
    };
    LoanApp.add(jwt, this.processCreateNewLoanApp, onError, body);
  }

  dispatchEntityDetails(rsp, whichForm) {
    TypeCheck(rsp.data, "object");
    TypeCheck(whichForm, "enum", true, ["businessInfo", "personalInfo"]);

    const { dispatchEntireForm } = this.props;
    dispatchEntireForm(rsp.data, whichForm);

    if (!isBafs()) {
      this.addInstitutionToRequest(rsp.data.institution_uuid);
    }
  }

  addBusinessToRequest(entityUuid) {
    TypeCheck(entityUuid, "string");

    const onSuccess = this.dispatchEntityDetails;
    const callbackData = "businessInfo";
    const { jwt, onError } = this.props;
    Entities.get(jwt, onSuccess, onError, entityUuid, null, callbackData);
  }

  addIndividualToRequest(entityUuid) {
    TypeCheck(entityUuid, "string");

    const onSuccess = this.dispatchEntityDetails;
    const callbackData = "personalInfo";
    const { jwt, onError } = this.props;
    Entities.get(jwt, onSuccess, onError, entityUuid, null, callbackData);
  }

  addInstitutionToRequest(institutionUuid) {
    TypeCheck(institutionUuid, "string");

    const { dispatchFormField, loanAppInfo, onError } = this.props;
    const prevSelectedInstitutionUuid = loanAppInfo.institution_uuid;

    onError("");
    dispatchFormField("loanAppInfo", "institution_uuid", institutionUuid);
    if (
      prevSelectedInstitutionUuid &&
      prevSelectedInstitutionUuid !== institutionUuid
    ) {
      onError("Error: Borrower and Owner must be from same institution.");
    }
  }

  render() {
    const { disabled, onError } = this.props;
    return (
      <div>
        <DocTitle
          title={`${stepList[0]} - Auto Decision Request - BLAST Portal`}
        />
        <h3>Select a Business</h3>
        <EntitySearch
          onError={onError}
          selectedEntityCallback={this.addBusinessToRequest}
          selectedInstitutionCallback={this.addInstitutionToRequest}
        />
        <h3>Select an Individual</h3>
        <EntitySearch
          onError={onError}
          selectedEntityCallback={this.addIndividualToRequest}
          selectedInstitutionCallback={this.addInstitutionToRequest}
        />
        <Button
          content="Start Application"
          disabled={disabled}
          onClick={this.createNewLoanApp}
          primary
        />
      </div>
    );
  }
}

EntitySelectionObj.propTypes = {
  dispatch: PropTypes.func.isRequired,

  disabled: PropTypes.bool.isRequired,
  dispatchEntireForm: PropTypes.func.isRequired,
  dispatchFormField: PropTypes.func.isRequired,
  formatRspAndDispatch: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,

  businessInfo: PropTypes.shape({ uuid: PropTypes.string }).isRequired,
  loanAppInfo: PropTypes.shape({ institution_uuid: PropTypes.string })
    .isRequired,
  personalInfo: PropTypes.shape({
    credit_bureau: PropTypes.string,
    uuid: PropTypes.string
  }).isRequired,

  jwt: PropTypes.string.isRequired,
  userUuid: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  businessInfo: state.AutoDecisionReducer.businessInfo,
  loanAppInfo: state.AutoDecisionReducer.loanAppInfo,
  personalInfo: state.AutoDecisionReducer.personalInfo,

  jwt: state.auth.jwt,
  userUuid: state.auth.userUuid
});

const EntitySelection = connect(mapStateToProps)(EntitySelectionObj);

export default EntitySelection;
