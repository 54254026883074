/* eslint-disable no-use-before-define */
import { RefreshToken, getToken } from "../../Auth";
import buildURL, { findAmbiguousTemplates } from "./build-url";

/* Example config to pass to createCRUDAPIService
{
  baseUrl: 'https://example.com',
  create: '/cats',
  retrieve: ['/cats', '/cats/{catId}'],
  update: '/cats/{catId}',
  delete: '/cats/{catId}',
  options: { pagination: true }

  subServices: {
    comments: {
      create: '/cats/{catId}/comments',
      retrieve: ['/cats/{catId}/comments', '/cats/{catId}/comments/{commentId}'],
      update: '/cats/{catId}/comments/{commentId}',
      delete: '/cats/{catId}/comments/{commentId}',
      options: { pagination: true }
    },
  },
}
*/

const createCRUDAPIService = (
  config,
  // allows for mocking of fetch
  fetchMethod = fetch
) => {
  const { baseUrl, subServices, options, ...methods } = config;

  const createAPIMethod = createMethodFactory(baseUrl, fetchMethod, options);

  const service = Object.entries(methods).reduce(
    (serviceObj, [name, urlTemplates]) => {
      const templates = [].concat(urlTemplates); // turn string into array of string
      return { ...serviceObj, [name]: createAPIMethod(name, templates) };
    },
    {}
  );

  if (subServices) {
    Object.entries(subServices).forEach(([name, subconfig]) => {
      service[name] = createCRUDAPIService(
        { baseUrl, ...subconfig },
        fetchMethod
      );
    });
  }

  return service;
};

const createMethodFactory =
  (baseUrl, fetchMethod, { pagination = false } = {}) =>
  (name, templates) => {
    throwErrorIfTemplateIsAmbiguous(templates);

    const apiFetch = async (method, identifiers, meta, body) => {
      const headers = new Headers({
        "Content-Type": "application/json",
        Authorization: getToken()
      });
      if (method !== "GET") {
        RefreshToken(); // ???: is this nessecary every non-get request?
      }
      const url = buildURL(templates, baseUrl, identifiers, meta, {
        pagination
      });
      const fetchOptions = {
        method,
        headers
      };
      if (body) {
        fetchOptions.body = JSON.stringify(body);
      }
      const response = await fetchMethod(url, fetchOptions);
      if (!response.ok) {
        throw await response.json();
      }
      return response.json();
    };

    return {
      async create(body, identifiers, meta) {
        return apiFetch("POST", identifiers, meta, body);
      },
      async retrieve(identifiers, meta) {
        return apiFetch("GET", identifiers, meta);
      },
      async update(body, identifiers, meta) {
        return apiFetch("PUT", identifiers, meta, body);
      },
      async patch(body, identifiers, meta) {
        return apiFetch("PATCH", identifiers, meta, body);
      },
      async delete(identifiers, meta) {
        return apiFetch("DELETE", identifiers, meta);
      }
    }[name];
  };

const throwErrorIfTemplateIsAmbiguous = templates => {
  const ambigiousTemplates = findAmbiguousTemplates(templates);
  if (ambigiousTemplates.length) {
    throw Error(
      `Ambigious Template matching:\n${listWords(ambigiousTemplates)}`
    );
  }
};

const listWords = array =>
  array.length > 1
    ? `${array.slice(0, -1).join(", ")} and ${array[array.length - 1]}`
    : array[0];

export default createCRUDAPIService;
