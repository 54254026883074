import React from "react";
import { Button, Header, Grid } from "semantic-ui-react";
import PropTypes from "prop-types";

import LightHeader from "../../../../../../components/LightHeader";
import DragAndDrop from "../../../../../../components/DragAndDrop";

const parentUuid = "shared00-docs-453e-aae7-4a59714f3846";
const institutionUuid = "7693013a-2ded-4d32-9364-d9894df5925f";
const entityUuid = "B01owner-0001-4e7b-8f5a-e9bbc4f0f-v2";
const parentRelType = "Entity";

function DataTemplate(props) {
  const { category, onUploadSuccess, docCat, templates, removeTemplate } =
    props;

  return (
    <div>
      <Grid>
        <Grid.Row>
          <LightHeader hx="h6">
            Data Management:
            {` ${category} `}
            Template
          </LightHeader>
        </Grid.Row>
        <Grid.Row columns="2" style={{ padding: 0 }} verticalAlign="middle">
          <Grid.Column float="left">
            <Header as="h6">
              {`${
                templates[docCat] !== undefined
                  ? templates[docCat].fileName
                  : ""
              }`}
            </Header>
          </Grid.Column>
          {templates[docCat] && templates[docCat].fileName && (
            <Grid.Column float="right">
              <Button
                basic
                color="green"
                circular
                icon="times"
                onClick={() => removeTemplate(templates[docCat])}
              />
            </Grid.Column>
          )}
        </Grid.Row>
        <Grid.Row style={{ padding: 0, paddingTop: ".5em" }}>
          <DragAndDrop
            applyRestrictions={false}
            institutionUuid={institutionUuid}
            parentUuid={parentUuid}
            entityUuid={entityUuid}
            parentRelType={parentRelType}
            onUploadSuccess={onUploadSuccess}
            docCategory={docCat}
          />
        </Grid.Row>
      </Grid>
    </div>
  );
}

DataTemplate.propTypes = {
  category: PropTypes.string.isRequired,
  docCat: PropTypes.string.isRequired,
  onUploadSuccess: PropTypes.func.isRequired,
  removeTemplate: PropTypes.func.isRequired,
  templates: PropTypes.objectOf(PropTypes.object).isRequired
};

export default DataTemplate;
