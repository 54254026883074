import { useIntl } from "react-intl";
import { EditorConfig } from "../document-editor/types";
import { DocumentEditor } from "../document-editor/document-editor";
import { DownloadButton } from "../../../../fomantic/elements/button/download-button/download-button";
import { SignedEditorConfigurationType } from "../document-editor";

export function DocumentPreview({
  buttonProps = undefined,
  downloadUrl,
  filename,
  hideDownloadButton = false,
  method = "GET",
  ooUrl,
  signConfigurationHook: useSignConfiguration,
  signConfigurationHookArgs,
  token
}: DocumentPreviewProps) {
  const { formatMessage } = useIntl();

  const { config, error } = useSignConfiguration({
    ...signConfigurationHookArgs,
    token
  });

  return (
    <>
      <DocumentEditor config={config} error={error} ooUrl={ooUrl} />

      {!hideDownloadButton && (
        <DownloadButton
          url={downloadUrl}
          method={method}
          token={token}
          filename={filename}
          message={{ id: "DOWNLOAD_DOCUMENT" }}
          {...buttonProps}
          aria-label={`${formatMessage({ id: "DOWNLOAD_DOCUMENT" })}`}
        >
          {filename}
        </DownloadButton>
      )}
    </>
  );
}

export interface DocumentPreviewProps {
  buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  downloadUrl: string;
  filename: string;
  hideDownloadButton?: boolean;
  method?: "GET" | "POST";
  ooUrl: string;
  signConfigurationHook: (configData: SignedEditorConfigurationType) => {
    config: EditorConfig | null;
    error: string | null;
  };
  signConfigurationHookArgs: Omit<SignedEditorConfigurationType, "token">;
  token: string;
}
