import React from "react";
import { connect } from "react-redux";
import { Container, Segment, Form } from "semantic-ui-react";
import PropTypes from "prop-types";
import { actionCreators } from "../reducer";
import { ppp2483Inputs } from "../constants/pppFormValues";
import PPPFormInputs from "../utilities/PPPFormInputs";

export function PPP2483Obj({
  pppFormFields,
  loanFormFields,
  setPPPFormFields,
  setLoanFormFields,
  updateLoanFields,
  updatePPPFields
}) {
  const loanInputElements = (
    <PPPFormInputs
      inputs={[
        {
          name: "amount_requested",
          label: "Loan Amount Requested",
          type: "float"
        }
      ]}
      fields={loanFormFields}
      update={updateLoanFields}
      set={setLoanFormFields}
    />
  );

  const pppInputElements = (
    <PPPFormInputs
      inputs={ppp2483Inputs}
      fields={pppFormFields}
      update={updatePPPFields}
      set={setPPPFormFields}
    />
  );

  return (
    <Segment>
      <Container textAlign="left">
        <Form autoComplete="off">
          {loanInputElements}
          {pppInputElements}
        </Form>
      </Container>
    </Segment>
  );
}

PPP2483Obj.propTypes = {
  loanFormFields: PropTypes.shape(),
  pppFormFields: PropTypes.shape(),
  setPPPFormFields: PropTypes.func,
  setLoanFormFields: PropTypes.func,
  updateLoanFields: PropTypes.func,
  updatePPPFields: PropTypes.func
};

const mapStateToProps = state => ({
  loanFormFields: state.PPPReducer.loanFormFields,
  pppFormFields: state.PPPReducer.pppFormFields
});

export const mapDispatchToProps = dispatch => ({
  setPPPFormFields: fields => dispatch(actionCreators.setPPPFormFields(fields)),
  setLoanFormFields: fields =>
    dispatch(actionCreators.setLoanFormFields(fields)),
  updateLoanFields: fields => dispatch(actionCreators.updateLoanFields(fields)),
  updatePPPFields: fields => dispatch(actionCreators.updatePPPFields(fields))
});
export default connect(mapStateToProps, mapDispatchToProps)(PPP2483Obj);
