import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import FetchService from "../../../FetchService";
import { ConcatQueryParams, RemoveNullKeys } from "../Utilities";
import { FormatUrlV2 } from "../../../FormatUrl";

function get(onSuccess, onError, queryParams, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(queryParams, "object", false);

  let url = ApiEndpoints.baseUri + ApiEndpoints.loanCodeRelationships;
  if (queryParams) {
    url += ConcatQueryParams(queryParams);
  }

  FetchService("GET", url, onSuccess, onError, null, callbackData);
}

function add(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  const url = ApiEndpoints.baseUri + ApiEndpoints.loanCodeRelationships;
  const convertedBody = RemoveNullKeys(body);
  FetchService("POST", url, onSuccess, onError, convertedBody, callbackData);
}

function remove(onSuccess, onError, relationshipUuid, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(relationshipUuid, "string");
  const url = FormatUrlV2(ApiEndpoints.loanCodeRelationship, {
    uuid: relationshipUuid
  });
  FetchService("DELETE", url, onSuccess, onError, null, callbackData);
}

export default {
  get,
  add,
  remove
};
