import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import FetchService, { asyncFetchService } from "../../../FetchService";
import logger from "../../../logger";
import {
  ConcatQueryParams,
  PaginationParams,
  RemoveNullKeys
} from "../Utilities";
import { FormatUrlV2 } from "../../../FormatUrl";

function get(
  onSuccess,
  onError,
  pagination,
  loanUuid,
  transactionUuid,
  queryParams,
  callbackData
) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(loanUuid, "string");
  TypeCheck(transactionUuid, "string", false);
  TypeCheck(queryParams, "object", false);

  if (
    transactionUuid &&
    queryParams &&
    queryParams.include_recalculation_log == null
  ) {
    onError("Error: Invalid Filter Combination");
  }

  let appendToUrl = "";
  if (transactionUuid) {
    appendToUrl += `/${transactionUuid}`;
  }
  if (queryParams) {
    appendToUrl += ConcatQueryParams(queryParams);
  }
  if (pagination) {
    appendToUrl = PaginationParams(appendToUrl, pagination);
  }
  const url =
    FormatUrlV2(ApiEndpoints.loanTransactionHistories, {
      loan_uuid: loanUuid
    }) + appendToUrl;

  FetchService("GET", url, onSuccess, onError, null, callbackData);
}

function update(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  const url = FormatUrlV2(ApiEndpoints.loanTransactionHistory, {
    loan_uuid: body.loan_uuid,
    uuid: body.uuid
  });
  const convertedBody = RemoveNullKeys(body);
  delete convertedBody.uuid_id;

  FetchService("PUT", url, onSuccess, onError, convertedBody, callbackData);
}

function add(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  const url = FormatUrlV2(ApiEndpoints.loanTransactionHistories, {
    loan_uuid: body.loan_uuid
  });
  const convertedBody = RemoveNullKeys(body);

  FetchService("POST", url, onSuccess, onError, convertedBody, callbackData);
}

async function create(body, usingDirectPost) {
  TypeCheck(body, "object");
  TypeCheck(usingDirectPost, "boolean");

  const endPoint = usingDirectPost
    ? ApiEndpoints.loanPostDirectTransaction
    : ApiEndpoints.loanTransactionHistories;

  const url = FormatUrlV2(endPoint, { loan_uuid: body.loan_uuid });
  const convertedBody = RemoveNullKeys(body);
  const rsp = await asyncFetchService("POST", url, convertedBody);
  return rsp;
}

function remove(
  onSuccess,
  onError,
  loanUuid,
  transactionUuid,
  institutionUuid
) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(loanUuid, "string");
  TypeCheck(transactionUuid, "string");
  TypeCheck(institutionUuid, "string");
  const url = FormatUrlV2(ApiEndpoints.loanTransactionHistoryReversal, {
    loan_uuid: loanUuid,
    uuid: transactionUuid
  });
  const body = { institution_uuid: institutionUuid };
  FetchService("POST", url, onSuccess, onError, body);
}

function removeV2(onSuccess, onError, loanUuid, transactionUuid, queryParams) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(loanUuid, "string");
  TypeCheck(transactionUuid, "string");
  TypeCheck(queryParams, "object", false);

  let appendToUrl = "";
  if (queryParams) {
    appendToUrl += ConcatQueryParams(queryParams);
  }

  const url =
    FormatUrlV2(ApiEndpoints.loanTransactionHistoryReversalV2, {
      loan_uuid: loanUuid,
      uuid: transactionUuid
    }) + appendToUrl;
  FetchService("DELETE", url, onSuccess, onError);
}

async function read(filters) {
  const { loan_uuid, transactionUuid, ...restFilters } = filters; // eslint-disable-line camelcase
  const funcName = "====  LoanTransactionHistory.read(filters)  ====";
  logger.debug(funcName, [filters]);
  TypeCheck(loan_uuid, "string");
  TypeCheck(transactionUuid, "string", false);

  const filterCount = Object.keys(restFilters).length;
  if (
    (filters.transactionUuid && filterCount > 1) ||
    (filters.transactionUuid &&
      filterCount === 1 &&
      !("include_recalculation_log" in filters)) ||
    filterCount === 0
  ) {
    logger.error("Error: Invalid Filter Combination");
    // TODO: [#5740] Put a checkable status here.
    const errorRsp = {};
    return errorRsp;
  }

  let url;
  if (filters.transactionUuid) {
    const endpoint = ApiEndpoints.loanTransactionHistory;
    const appendToUrl = ConcatQueryParams(restFilters);
    // eslint-disable-next-line camelcase
    url =
      FormatUrlV2(endpoint, { loan_uuid, uuid: transactionUuid }) + appendToUrl;
  } else {
    const endpoint = ApiEndpoints.loanTransactionHistories;
    const appendToUrl = ConcatQueryParams(restFilters);
    // eslint-disable-next-line camelcase
    url = FormatUrlV2(endpoint, { loan_uuid }) + appendToUrl;
  }

  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

export default {
  get,
  update,
  add,
  remove,
  removeV2,
  read,
  create
};
