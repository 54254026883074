import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import { Button } from "semantic-ui-react";
import { maskValue } from "../../services/ApiLib/services/Utilities";

function MaskText({ text = "" }) {
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  const maskedText = useMemo(() => {
    if (visible) {
      return text;
    }

    return maskValue(text);
  }, [text, visible]);

  return (
    <div>
      <Button
        data-testid="maskedTextButton"
        circular
        size="tiny"
        icon
        onClick={toggleVisibility}
      >
        <FontAwesome name="eye" />
      </Button>
      <span className="ml-1" data-testid="maskedText">
        {maskedText}
      </span>
    </div>
  );
}

MaskText.defaultProps = {
  text: ""
};

MaskText.propTypes = {
  text: PropTypes.string
};

export default MaskText;
