/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { Grid, Form } from "semantic-ui-react";
import {
  PercentInput,
  DollarField,
  DateInput,
  NumberInput
} from "../../../../../../../../../components/CustomFormElements";
import { EditModal } from "../../../../../../../../../components/Modals";
import { forceValue } from "../../../../../../../../../services/FormElements";
import accrualBasisOptions from "../../../../../../../../../services/Constants/accrualBasisOptions";

function InterestEditModal({ loan, onSave, ...props }) {
  const handleSave = values => {
    if (Object.keys(values).length) {
      onSave(values);
    }
  };
  const {
    interest_rate,
    rate_type,
    accrual_basis,
    per_diem,
    interest_accrued,
    interest_accrued_mtd,
    interest_accrued_ytd,
    interest_accrued_ltd,
    interest_paid_ytd,
    interest_paid_ltd,
    rate_floor,
    rate_ceiling,
    max_rate_decrease,
    max_rate_increase,
    rate_index,
    previous_rate_change_date,
    next_rate_change_date,
    number_of_rate_changes,
    uuid
  } = loan;
  const rateTypeOptions = [
    { key: "Variable", value: "Variable", text: "Variable" },
    { key: "Fixed", value: "Fixed", text: "Fixed" }
  ];
  const variableRateIndexOptions = [
    { key: "1 Month CMT", value: "1 Month CMT", text: "1 Month CMT" },
    { key: "3 Month CMT", value: "3 Month CMT", text: "3 Month CMT" },
    { key: "6 Month CMT", value: "6 Month CMT", text: "6 Month CMT" },
    { key: "1 Yr CMT", value: "1 Yr CMT", text: "1 Yr CMT" },
    { key: "2 Yr CMT", value: "2 Yr CMT", text: "2 Yr CMT" },
    { key: "3 Yr CMT", value: "3 Yr CMT", text: "3 Yr CMT" },
    { key: "5 Yr CMT", value: "5 Yr CMT", text: "5 Yr CMT" },
    { key: "7 Yr CMT", value: "7 Yr CMT", text: "7 Yr CMT" },
    { key: "10 Yr CMT", value: "10 Yr CMT", text: "10 Yr CMT" },
    { key: "20 Yr CMT", value: "20 Yr CMT", text: "20 Yr CMT" },
    { key: "30 Yr CMT", value: "30 Yr CMT", text: "30 Yr CMT" },
    { key: "Fixed", value: "Fixed", text: "Fixed" },
    { key: "WSJP", value: "WSJP", text: "WSJP" },
    { key: "SBA PEG", value: "SBA PEG", text: "SBA PEG" }
  ];
  return (
    <EditModal
      key={uuid}
      size="small"
      onSave={handleSave}
      defaultValues={{
        interest_rate,
        rate_type,
        accrual_basis,
        per_diem,
        interest_accrued,
        interest_accrued_mtd,
        interest_accrued_ytd,
        interest_accrued_ltd,
        interest_paid_ytd,
        interest_paid_ltd,
        rate_floor,
        rate_ceiling,
        max_rate_decrease,
        max_rate_increase,
        rate_index,
        previous_rate_change_date,
        next_rate_change_date,
        number_of_rate_changes
      }}
      header="Edit Interest"
      {...props}
    >
      {({ onChange, values }) => (
        <Grid padded>
          <Grid.Row columns="3">
            <Grid.Column>
              <PercentInput
                name="interest_rate"
                value={forceValue(values.interest_rate)}
                label="Current Rate"
                scale={3}
                onChange={(e, { name, value }) => onChange({ name, value })}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Select
                onChange={(e, { name, value }) => onChange({ name, value })}
                options={rateTypeOptions}
                name="rate_type"
                label="Rate Type"
                value={forceValue(values.rate_type)}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Select
                onChange={(e, { name, value }) => onChange({ name, value })}
                options={accrualBasisOptions}
                name="accrual_basis"
                label="Accrual Basis"
                value={forceValue(values.accrual_basis)}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>
              <DollarField
                name="per_diem"
                value={forceValue(values.per_diem)}
                label="Per Diem"
                onChange={onChange}
              />
            </Grid.Column>
            <Grid.Column>
              <DollarField
                onChange={onChange}
                name="interest_accrued"
                label="Accrued Interest"
                value={forceValue(values.interest_accrued)}
              />
            </Grid.Column>
            <Grid.Column>
              <DollarField
                name="interest_accrued_mtd"
                value={forceValue(values.interest_accrued_mtd)}
                label="Accrued Interest MTD"
                onChange={onChange}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>
              <DollarField
                onChange={onChange}
                name="interest_accrued_ytd"
                label="Accrued Interest YTD"
                value={forceValue(values.interest_accrued_ytd)}
              />
            </Grid.Column>
            <Grid.Column>
              <DollarField
                onChange={onChange}
                name="interest_accrued_ltd"
                label="Accrued Interest LTD"
                value={forceValue(values.interest_accrued_ltd)}
              />
            </Grid.Column>
            <Grid.Column>
              <DollarField
                onChange={onChange}
                name="interest_paid_ytd"
                label="Interest Paid YTD"
                value={forceValue(values.interest_paid_ytd)}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="3">
            <Grid.Column>
              <DollarField
                onChange={onChange}
                name="interest_paid_ltd"
                label="Interest Paid LTD"
                value={forceValue(values.interest_paid_ltd)}
              />
            </Grid.Column>
          </Grid.Row>
          {values.rate_type === "Variable" && (
            <>
              <Grid.Row columns="3">
                <Grid.Column>
                  <PercentInput
                    name="rate_floor"
                    value={forceValue(values.rate_floor)}
                    label="Rate Floor"
                    onChange={(e, { name, value }) => onChange({ name, value })}
                  />
                </Grid.Column>
                <Grid.Column>
                  <PercentInput
                    name="rate_ceiling"
                    value={forceValue(values.rate_ceiling)}
                    label="Rate Ceiling"
                    onChange={(e, { name, value }) => onChange({ name, value })}
                  />
                </Grid.Column>

                <Grid.Column>
                  <PercentInput
                    onChange={(e, { name, value }) => onChange({ name, value })}
                    name="max_rate_increase"
                    data-testid="increase"
                    scale={3}
                    label="Max Change Rate Increase"
                    placeholder="0.000%"
                    value={forceValue(values.max_rate_increase)}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="3">
                <Grid.Column>
                  <PercentInput
                    onChange={(e, { name, value }) => onChange({ name, value })}
                    name="max_rate_decrease"
                    data-testid="decrease"
                    scale={3}
                    placeholder="0.000%"
                    label="Max Change Rate Decrease"
                    value={forceValue(values.max_rate_decrease)}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Select
                    onChange={(e, { name, value }) => onChange({ name, value })}
                    options={variableRateIndexOptions}
                    name="rate_index"
                    label="Variable Rate Index"
                    value={forceValue(values.rate_index)}
                  />
                </Grid.Column>
                <Grid.Column>
                  <DateInput
                    asForm
                    onChange={(e, { name, value }) => onChange({ name, value })}
                    name="previous_rate_change_date"
                    label="Previous Rate Change Date"
                    value={forceValue(values.previous_rate_change_date)}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="3">
                <Grid.Column>
                  <DateInput
                    asForm
                    onChange={(e, { name, value }) => onChange({ name, value })}
                    name="next_rate_change_date"
                    label="Next Rate Change Date"
                    value={forceValue(values.next_rate_change_date)}
                  />
                </Grid.Column>
                <Grid.Column>
                  <NumberInput
                    onChange={(e, { name, value }) => onChange({ name, value })}
                    name="number_of_rate_changes"
                    label="Number Of Rate Changes LTD"
                    value={forceValue(values.number_of_rate_changes)}
                  />
                </Grid.Column>
              </Grid.Row>
            </>
          )}
        </Grid>
      )}
    </EditModal>
  );
}

InterestEditModal.propTypes = {
  loan: PropTypes.shape(),
  onSave: PropTypes.func
};

export default InterestEditModal;
