/* eslint-disable no-case-declarations */

import { useState } from "react";
import { useDispatch } from "react-redux";
import { getFileMimeType } from "../../../../services/Constants/AllowedDocumentTypes";
import { Documents } from "../../../../services/ApiLib";
import { useIframeSetup } from "../../../../components/BlastEmbed/hooks";
import logger from "../../../../services/logger";
import { COMPONENT_NAME } from "./constants";

const useBlastActions = ({ filters, pagination }) => {
  const [cureModalOpen, setCureModalOpen] = useState(false);
  const [cureModalTickler, setCureModalTickler] = useState(null);
  const [cureModalDocDetails, setCureModalDocDetails] = useState(null);
  const [cureModalUsers, setCureModalUsers] = useState(null);
  const [paginationLastPage, setPaginationLastPage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { action, dispatch: dispatchToBlast } = useIframeSetup(COMPONENT_NAME);
  const dispatch = useDispatch();
  const unsetCureModalData = () => {
    setCureModalTickler(null);
    setCureModalDocDetails(null);
    setCureModalUsers(null);
    setCureModalOpen(false);
  };

  const setCureModalData = ({ tickler, docDetails, users }) => {
    setCureModalTickler(tickler);
    setCureModalDocDetails(docDetails);
    setCureModalUsers(users);
    setCureModalOpen(true);
  };

  /**
   *
   * @param {object} data
   * @param {string} [data.type]
   */
  function onUpdate(data) {
    // A variety of unknown things in the iframe may attempt to use message
    // passing between the iframe and host, for example the React Dev Tools do
    // this. If the message data doesn't have a type property it will be
    // ignored.
    // eslint-disable-next-line no-prototype-builtins
    if (!data.hasOwnProperty("type")) {
      return;
    }

    switch (data.type) {
      case "INITIATE_CONNECTION":
        dispatchToBlast({
          type: "DOC_MGMT_TICKLERS_UPDATE_PAGINATION",
          pagination
        });
        const filterKeys = Object.keys(filters);
        if (filterKeys.length > 0) {
          filterKeys.forEach(key => {
            dispatchToBlast({
              type: "DOC_MGMT_TICKLERS_FILTER_UPDATE_VALUE",
              filterKey: key,
              selectedValue: filters[key]
            });
          });
        }
        break;
      case "DISCONNECT":
        break;

      case "DOC_MGMT_TICKLERS_DOWNLOAD_DOCUMENT":
        const { mimeType } = getFileMimeType(data.fileName);
        Documents.preview(
          { uuid: data.objectUuid },
          { fileType: mimeType, fileName: data.fileName }
        );
        break;

      case "DOC_MGMT_TICKLERS_TOGGLE_CURE_MODAL":
        const { tickler, open, docDetails, users } = data;
        if (open) {
          dispatch({
            type: "DOC_APPROVAL_ADD_DOC_DETAILS",
            docDetails
          });
          setCureModalData({
            tickler,
            docDetails,
            users
          });
        } else {
          unsetCureModalData();
        }
        break;

      case "DOC_MGMT_TICKLERS_SET_LAST_PAGE":
        setPaginationLastPage(data.lastPage);
        break;

      case "DOC_MGMT_TICKLERS_SET_ERROR":
        setErrorMessage(data.errorMessage);
        break;

      case "DOC_MGMT_TICKLERS_OPEN_LINK":
        window.open(data.linkLocation, "_blank");
        break;

      default: {
        logger.debug(`useBlastAction: unknown data type '${data.type}'`);
        break;
      }
    }
  }

  return {
    onUpdate,
    dispatchToBlast,
    action,
    unsetCureModalData,
    cureModalOpen,
    cureModalTickler,
    cureModalDocDetails,
    cureModalUsers,
    paginationLastPage,
    errorMessage
  };
};

export default useBlastActions;
