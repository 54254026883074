import store from "../../../../store";
import logger from "../../../logger";

const ErrorReporterV1 = (errorMessage, apiError) => {
  store.dispatch({
    errorMessage,
    type: "ERRORS_REPORT"
  });
  logger.error(apiError);
};

export default ErrorReporterV1;
