import React, { useEffect, useRef, useState } from "react";
import {
  useLoanAppSettlementSheetsQuery,
  LoanAppSettlementSheetParams
} from "../../../../../../services/RTKQuery/loanAppSettlementSheet";

export const ReadManyTest = ({ loanUuid, itemUuid, setStatusInParent }) => {
  const statusUnknown = "❓Unknown Status";
  const statusPass = "✅ Pass";
  const statusFail = "❌ Fail";

  const statusReadManyRef = useRef(statusUnknown);
  const [detailsText, setDetailsText] = useState("");

  const filtersStepFour: LoanAppSettlementSheetParams = {
    uuids: {
      loan_uuid: loanUuid,
      item_uuid: itemUuid
    }
  };
  const { data: settlementSheetRsp } =
    useLoanAppSettlementSheetsQuery(filtersStepFour);
  useEffect(() => {
    // This shouldn't be needed, but if it's removed, the test will
    // rerun after the DELETE test is run and then fail.
    if (statusReadManyRef.current === statusPass) {
      return;
    }
    let createdRecord;
    if (settlementSheetRsp && settlementSheetRsp.data.length > 0) {
      for (const record of settlementSheetRsp.data) {
        if (record.uuid === itemUuid) {
          createdRecord = record;
          break;
        }
      }
    }
    // This str is repeated in two tests
    const newReasonStr = "I'm just a reason, yes I'm only a reason.";
    const isReasonUpdated = createdRecord?.reason_other === newReasonStr;
    if (createdRecord && isReasonUpdated) {
      setStatusInParent("Pass");
      setDetailsText(
        `: reason_other matches updated value in record ${createdRecord.uuid}`
      );
      statusReadManyRef.current = statusPass;
    } else {
      console.error(
        "createdRecord?.reason_other =",
        createdRecord?.reason_other
      );
      statusReadManyRef.current = statusFail;
      console.error("Fail: useLoanAppSettlementSheetsQuery");
    }
  }, [itemUuid, setStatusInParent, settlementSheetRsp]);
  return (
    <>
      <b>{statusReadManyRef.current}</b>
      <small>{detailsText}</small>
    </>
  );
};
