import { useMemo, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { SideSheet, Button } from "@bafsllc/ui-shared";
import {
  Message,
  Segment,
  Header,
  Divider,
  Container
} from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { getToken } from "../../../../services/Auth";
import ApiEndpoints from "../../../../services/ApiEndpoints/index";
import { usePeriods } from "../../../../store/models/spreads";
import "./index.css";

const { cmsInstitutionConfig, baseUri } = ApiEndpoints;

export const FLASH_SPREADS_BASE_URL = "https://widget.flashspread.com";

export const FORM_TYPE_MAPPINGS = {
  "Forms::Form1120S": "form-1120s",
  "Forms::Form1120": "form-1120",
  "Forms::Form1065": "form-1065",
  "Forms::Form1040": "form-1040"
};

export function CorrectionsSideSheet({
  periodUuid,
  entityUuid,
  institutionUuid,
  onClose
}) {
  const [{ periods }, actions] = usePeriods({ entityUuid, institutionUuid });
  const currentPeriod = useMemo(
    () => periods.find(({ uuid }) => uuid === periodUuid),
    [periods, periodUuid]
  );
  const type = useMemo(
    () => FORM_TYPE_MAPPINGS[currentPeriod?.autoSpreadsFormType],
    [currentPeriod?.autoSpreadsFormType]
  );
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentPeriod && !token && !error) {
      fetch(
        `${baseUri}${cmsInstitutionConfig}${currentPeriod?.institutionUuid}`,
        {
          headers: {
            accept: "application/json",
            authorization: getToken()
          }
        }
      )
        .then(response => response.json())
        .then(result => setToken(result.flashSpreadsWidgetToken))
        .catch(err => setError(err));
    }
    if (currentPeriod?.autoSpreadsStatus === "Ready for Use") {
      setLoading(false);
      onClose();
    }
  }, [token, setToken, error, currentPeriod, onClose]);

  const loadData = useCallback(() => {
    setLoading(true);
    actions.updatePeriod({
      entityUuid: currentPeriod?.entityUuid,
      uuid: currentPeriod?.uuid,
      autoSpreadsStatus: "Ready for Use"
    });
  }, [actions, currentPeriod?.entityUuid, currentPeriod?.uuid]);

  return (
    <SideSheet
      isOpen={Boolean(periodUuid)}
      size="full"
      onClose={() => onClose()}
    >
      <div className="corrections-side-sheet-title">
        <Header as="h2" floated="left">
          <FormattedMessage id="AUTO_SPREADS_CORRECTION_MODAL_TITLE" />
        </Header>
        <Header as="h2" floated="right">
          <Button
            type="button"
            variant="secondary"
            message={{ id: "CLOSE" }}
            onClick={event => {
              event.preventDefault();
              onClose();
            }}
          />
          <Button
            type="button"
            variant="primary"
            disabled={loading}
            message={{
              id: loading ? "LOADING_BUTTON" : "AUTO_SPREADS_LOAD_DATA_BUTTON"
            }}
            onClick={event => {
              event.preventDefault();
              loadData();
            }}
          />
        </Header>
        <Divider clearing />
        {error ? (
          <Container textAlign="center">
            <Message negative content={error.message} />
          </Container>
        ) : null}
      </div>
      {token && type ? (
        <Segment vertical className="corrections-side-sheet-iframe">
          <iframe
            src={`${FLASH_SPREADS_BASE_URL}/v2/document/${currentPeriod?.autoSpreadsDocumentId}?token=${token}`}
            title="Statement Information"
            height="100%"
            width="100%"
            align="right"
          />
        </Segment>
      ) : (
        <Message content="Loading..." />
      )}
    </SideSheet>
  );
}

CorrectionsSideSheet.propTypes = {
  periodUuid: PropTypes.string,
  entityUuid: PropTypes.string,
  institutionUuid: PropTypes.string,
  onClose: PropTypes.func
};
