import { DatePicker } from "@bafsllc/ui-shared";
import { PropTypes } from "prop-types";
import { Form } from "semantic-ui-react";

export default function PeriodDatepickers({
  onDropdownChange,
  selectedPeriodStart,
  selectedPeriodEnd,
  isViewMode
}) {
  return (
    <Form.Group widths="equal" size="large">
      <DatePicker
        required
        labelMessage={{ id: "PERIOD_START" }}
        onDateChange={selectedDate => {
          onDropdownChange({
            name: "selectedPeriodStart",
            value: selectedDate
          });
        }}
        name="period_start"
        value={selectedPeriodStart}
        disabled={isViewMode}
      />
      <DatePicker
        required
        labelMessage={{ id: "PERIOD_END" }}
        onDateChange={selectedDate => {
          onDropdownChange({
            name: "selectedPeriodEnd",
            value: selectedDate
          });
        }}
        name="period_end"
        value={selectedPeriodEnd}
        disabled={isViewMode}
      />
    </Form.Group>
  );
}

PeriodDatepickers.propTypes = {
  onDropdownChange: PropTypes.func.isRequired,
  selectedPeriodStart: PropTypes.string.isRequired,
  selectedPeriodEnd: PropTypes.string.isRequired,
  isViewMode: PropTypes.bool.isRequired
};
