/* eslint-disable camelcase */
// TODO: #6037 Deprecated File. Verify nothing uses this and remove it and any corresponding tests.
import React from "react";
import PropTypes from "prop-types";
import { Segment, Radio, Form } from "semantic-ui-react";
import styled from "@emotion/styled";
import {
  DollarField,
  PercentInput
} from "../../../../../../../../../../components/CustomFormElements";
import { EditModal } from "../../../../../../../../../../components/Modals";

function ChargeFeeEditModal({ loan, onSave, ...props }) {
  const handleSave = values => {
    if (Object.keys(values).length) {
      onSave(values);
    }
  };
  const {
    fee_fixed_value,
    fee_based_on_unsatisfied,
    fee_calc_method,
    fee_max_threshold,
    fee_min_threshold,
    fee_percent_value,
    uuid
  } = loan;
  const feeCalcMethodOptions = [
    { key: "Fixed", value: "Fixed", text: "Fixed" },
    { key: "Percent", value: "Percent", text: "Percent" },
    { key: "Higher", value: "Higher", text: "Higher" },
    { key: "Lower", value: "Lower", text: "Lower" }
  ];
  return (
    <EditModal
      key={uuid}
      onSave={handleSave}
      defaultValues={{
        fee_fixed_value,
        fee_calc_method,
        fee_based_on_unsatisfied,
        fee_max_threshold,
        fee_min_threshold,
        fee_percent_value
      }}
      header="Edit Charge and Fees"
      {...props}
    >
      {({ onChange, values }) => (
        <Segment padded basic>
          <Box>
            <Radio
              toggle
              label="Based on Unsatisfied"
              name="fee_based_on_unsatisfied"
              onChange={(e, { name, value }) => {
                const feeValue = !value;
                onChange({ name, value: feeValue });
              }}
              checked={values.fee_based_on_unsatisfied}
              value={values.fee_based_on_unsatisfied}
            />
          </Box>
          <Form.Select
            onChange={(e, { name, value }) => onChange({ name, value })}
            options={feeCalcMethodOptions}
            name="fee_calc_method"
            label="Late Fee Type"
            value={values.fee_calc_method}
          />
          {values.fee_calc_method !== "Fixed" && (
            <>
              <DollarField
                name="fee_min_threshold"
                value={values.fee_min_threshold}
                label="Minimum Fee Amount"
                onChange={onChange}
              />
              <DollarField
                name="fee_max_threshold"
                value={values.fee_max_threshold}
                label="Maximum Fee Amount"
                onChange={onChange}
              />
              <PercentInput
                name="fee_percent_value"
                value={values.fee_percent_value}
                label="Fee Percent Value"
                onChange={(e, { name, value }) => onChange({ name, value })}
              />
            </>
          )}
          {values.fee_calc_method !== "Percent" && (
            <DollarField
              name="fee_fixed_value"
              value={values.fee_fixed_value}
              label="Fixed Amount as Currency"
              onChange={onChange}
            />
          )}
        </Segment>
      )}
    </EditModal>
  );
}

const Box = styled.div`
  margin-bottom: 16px;
`;

ChargeFeeEditModal.propTypes = {
  loan: PropTypes.shape(),
  onSave: PropTypes.func
};

export default ChargeFeeEditModal;
