import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "semantic-ui-react";

function EditButton({
  onClick,
  // eslint-disable-next-line no-unused-vars
  color: colorProp,
  disabled = false,
  floated = null,
  ...buttonProps
}) {
  const [color] = useState(disabled ? "grey" : "green");
  return (
    <Button
      basic
      disabled={disabled}
      icon={<Icon color={color} name="pencil" />}
      circular
      onClick={onClick}
      floated={floated}
      {...buttonProps}
    />
  );
}

EditButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  floated: PropTypes.string
};

export default EditButton;
