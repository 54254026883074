import React from "react";
import { Button, Modal } from "semantic-ui-react";
import PropTypes from "prop-types";
import EntitySearch from "../../../../../../components/EntitySearch";
import { relationshipTypes } from "../../../../../../services/Constants/relationshipTypes";

const relTypeModal = {};
relTypeModal[relationshipTypes.OWNER] = relationshipTypes.OWNER;
relTypeModal[relationshipTypes.OFFICER] = relationshipTypes.OFFICER;
relTypeModal[relationshipTypes.OTHER_CAPACITIES] =
  relationshipTypes.OTHER_CAPACITIES;
relTypeModal[relationshipTypes.GUARANTOR] = relationshipTypes.GUARANTOR;
relTypeModal[relationshipTypes.COBORROWER] = relationshipTypes.COBORROWER;

function RelationshipModal(props) {
  const {
    onClose,
    onError,
    relType,
    onSave,
    onSelectEntity,
    open,
    institutionUuid
  } = props;
  return (
    <Modal open={open} size="small">
      <Modal.Header>{`Add ${relTypeModal[relType]}`}</Modal.Header>
      <Modal.Content>
        <b>Search TIN/Name</b>
        <br />
        <EntitySearch
          selectedEntityCallback={onSelectEntity}
          onError={onError}
          allowAdd
          overrideInstitution={institutionUuid}
          institutionUuid={institutionUuid}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button id="cancelButton" basic onClick={() => onClose("")}>
          Cancel
        </Button>
        <Button id="submitButton" onClick={() => onSave()}>
          Select
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

RelationshipModal.propTypes = {
  onError: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSelectEntity: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  relType: PropTypes.string.isRequired,
  institutionUuid: PropTypes.string.isRequired
};

export default RelationshipModal;
