import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Pagination,
  Checkbox,
  Label,
  Message
} from "semantic-ui-react";
import {
  PercentCell,
  DateCell,
  DefaultCell
} from "../../../../../../../../../../components/Tables";
import { isBafs } from "../../../../../../../../../../services/Auth";
import Resources from "../../../../components/Resources";
import AddInterestScheduleItemModal from "./components/AddInterestScheduleItemModal";

const noop = () => {};

function InterestScheduleList({
  interestSchedules,
  isMonthEnd,
  pagination,
  error,
  onView,
  onCleanup,
  onAdd = noop,
  onEdit,
  onDelete,
  onPaginationChange,
  onFilter,
  onReorderItem,
  onDismissError,
  tableOptions,
  loan
}) {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editRow, setEditSchedule] = useState({});
  const [includeCompleted, setIncludeCompleted] = useState(false);
  const [includeDeleted, setIncludeDeleted] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "Status",
        accessor: "status",
        Cell: DefaultCell
      },
      {
        Header: "Interest Type",
        accessor: "interest_type",
        Cell: DefaultCell
      },
      {
        Header: "Schedule Start Date",
        accessor: "start_date",
        Cell: DateCell
      },
      {
        Header: "Schedule End Date",
        accessor: "end_date",
        Cell: DateCell
      },
      {
        Header: "Rate Type",
        accessor: "rate_type",
        Cell: DefaultCell
      },
      {
        Header: "Index",
        accessor: "rate_index",
        Cell: DefaultCell
      },
      {
        Header: "Margin",
        accessor: "margin",
        Cell: PercentCell
      },
      {
        Header: "Current Rate",
        accessor: "interest_rate",
        Cell: PercentCell
      },
      {
        Header: "Accrual Basis",
        accessor: "accrual_basis",
        Cell: DefaultCell
      },
      {
        Header: "Order",
        id: "processing_order",
        accessor: "processing_order",
        Cell: DefaultCell,
        show: false
      }
    ],
    []
  );

  useEffect(() => {
    // This can be used to load data when the component mounts/unmounts
    if (onView) onView();
    return onCleanup;
  }, [onView, onCleanup]);

  const showPagination =
    pagination &&
    (pagination.number !== 0 ||
      (pagination.number === 0 && !pagination.isLastPage));

  const addButton = isBafs() ? (
    <Button
      data-testid="add-button"
      circular
      basic
      icon="add"
      onClick={() => setOpen(true)}
    />
  ) : null;

  return isMonthEnd ? (
    <Resources.Layout heading="Schedule">
      <Label>Schedules not displayed for month-end snapshots.</Label>
    </Resources.Layout>
  ) : (
    <Resources.Layout heading="Interest Schedule">
      <Resources.Header>
        {error && (
          <Message
            error
            header="Error"
            content={error}
            onDismiss={onDismissError}
          />
        )}
        {onFilter ? (
          <Checkbox
            toggle
            label="Include Completed Schedules"
            checked={includeCompleted}
            onChange={(ev, { checked }) => {
              onFilter({
                include_completed: checked,
                include_deleted: includeDeleted
              });
              setIncludeCompleted(checked);
            }}
          />
        ) : null}
        {onFilter ? (
          <Checkbox
            toggle
            label="Include Deleted Schedules"
            checked={includeDeleted}
            onChange={(ev, { checked }) => {
              onFilter({
                include_deleted: checked,
                include_completed: includeCompleted
              });
              setIncludeDeleted(checked);
            }}
          />
        ) : null}
        {addButton}
        <AddInterestScheduleItemModal
          key="interest-schedule-add-modal"
          onSave={onAdd}
          defaultValues={{ end_date: loan?.maturity_date }}
          open={open}
          onClose={() => setOpen(false)}
        />
        {openEdit ? (
          <AddInterestScheduleItemModal
            key="interest-schedule-edit-modal"
            data-testid="interest-schedule-edit-modal"
            defaultValues={editRow}
            header="Edit Interest Schedule"
            onSave={onEdit}
            open={openEdit}
            onClose={() => {
              setEditSchedule({});
              setOpenEdit(false);
            }}
          />
        ) : (
          ""
        )}
      </Resources.Header>
      <Resources.Table
        items={interestSchedules}
        columns={columns}
        tableOptions={tableOptions}
        onDelete={isBafs() ? onDelete : null}
        onEdit={
          isBafs()
            ? row => {
                setEditSchedule({
                  ...row,
                  change_frequency: row.change_frequency?.toString()
                });
                setOpenEdit(true);
              }
            : null
        }
        onReorderItem={onReorderItem}
      />
      {showPagination ? (
        <Pagination
          boundaryRange={0}
          activePage={pagination.number + 1}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          siblingRange={1}
          totalPages={pagination.number + (pagination.isLastPage ? 1 : 2)}
          floated="right"
          onPageChange={(ev, { activePage }) => {
            onPaginationChange(
              { ...pagination, number: activePage - 1 },
              {
                include_completed: includeCompleted,
                include_deleted: includeDeleted
              }
            );
          }}
        />
      ) : null}
    </Resources.Layout>
  );
}

InterestScheduleList.propTypes = {
  interestSchedules: PropTypes.arrayOf(PropTypes.shape()),
  pagination: PropTypes.shape({
    number: PropTypes.number,
    size: PropTypes.number,
    isLastPage: PropTypes.bool
  }),
  tableOptions: PropTypes.shape(),
  error: PropTypes.string,
  loan: PropTypes.shape({
    maturity_date: PropTypes.string
  }),
  onView: PropTypes.func,
  onCleanup: PropTypes.func,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onPaginationChange: PropTypes.func,
  onFilter: PropTypes.func,
  onReorderItem: PropTypes.func,
  onDismissError: PropTypes.func,
  isMonthEnd: PropTypes.bool
};

export default InterestScheduleList;
