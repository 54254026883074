import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

function DollarFormat({ value, dataTestId, defaultValue = "-" }) {
  return (
    <NumberFormat
      data-testid={dataTestId}
      displayType="text"
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
      value={value}
      prefix="$"
      defaultValue={defaultValue}
    />
  );
}

DollarFormat.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dataTestId: PropTypes.string
};

export default DollarFormat;
