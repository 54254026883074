import TypeCheck from "typecheck-extended";
import ApiEndpoints from "../../../ApiEndpoints";
import FetchService, { asyncFetchService } from "../../../FetchService";
import { FormatUrlV2 } from "../../../FormatUrl";
import { ConcatQueryParams } from "../Utilities";

export function rspToCallback(rsp, callbacks) {
  TypeCheck(rsp, "object");
  TypeCheck(callbacks.onSuccess, "function");
  callbacks.onSuccess(rsp, callbacks.callbackData);
}

function add(jwt, onSuccess, onError, body, uuid, callbackData, queryParams) {
  TypeCheck(jwt, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(body, "object");
  TypeCheck(uuid, "string");
  let params = "";
  if (queryParams) {
    params = ConcatQueryParams(queryParams);
  }
  const url =
    FormatUrlV2(ApiEndpoints.loanTransactionHistories, { loan_uuid: uuid }) +
    params;
  const callbacks = { callbackData, onSuccess };
  FetchService("POST", url, rspToCallback, onError, body, callbacks);
}

async function asyncAdd(body, uuid, queryParams) {
  TypeCheck(body, "object");
  TypeCheck(uuid, "string");
  let params = "";
  if (queryParams) {
    params = ConcatQueryParams(queryParams);
  }
  const url =
    FormatUrlV2(ApiEndpoints.loanTransactionHistories, { loan_uuid: uuid }) +
    params;

  const rsp = await asyncFetchService("POST", url, body);
  return rsp;
}

function get(jwt, onSuccess, onError, uuid, callbackData, filters) {
  TypeCheck(jwt, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string", false);
  TypeCheck(callbackData, "object", false);
  TypeCheck(filters, "object", false);

  let params = "";
  if (filters) {
    params = ConcatQueryParams(filters);
  }
  const url = FormatUrlV2(ApiEndpoints.loan, { uuid }) + params;
  const callbacks = { callbackData, onSuccess };
  FetchService("GET", url, rspToCallback, onError, null, callbacks);
}

async function asyncGet(uuid, filters) {
  TypeCheck(uuid, "string", false);
  TypeCheck(filters, "object", false);
  let params = "";
  if (filters) {
    params = ConcatQueryParams(filters);
  }
  const url = FormatUrlV2(ApiEndpoints.loan, { uuid }) + params;
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

async function asyncPreviewAdd(body, uuid, queryParams) {
  TypeCheck(body, "object");
  TypeCheck(uuid, "string");
  let params = "";
  if (queryParams) {
    params = ConcatQueryParams(queryParams);
  }

  const url =
    FormatUrlV2(ApiEndpoints.previewLoanTransactionHistory, {
      loan_uuid: uuid
    }) + params;
  const rsp = await asyncFetchService("POST", url, body, null, false);
  return rsp;
}

export default {
  add,
  get,
  asyncPreviewAdd,
  asyncAdd,
  asyncGet
};
