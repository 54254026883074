import { connect } from "react-redux";
import Characteristics from "./Characteristics";
import { actionCreators } from "../../../../reducer";

const mapStateToProps = ({
  LoanManagementReducer: { codeOptions, selectedCodeOptions }
}) => ({
  codeOptions,
  selectedCodeOptions
});

export const mapDispatchToProps = (dispatch, { onUpdateLoan, loan }) => ({
  onSave(loanPatch) {
    const loanBody = { ...loanPatch };
    dispatch(actionCreators.updateCodeOptions(loanPatch["5300_code"], loan));
    delete loanBody["5300_code"];
    onUpdateLoan(loanBody);
  },
  onViewModal() {
    dispatch(actionCreators.onViewCharacteristicsModal(loan.uuid));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Characteristics);
