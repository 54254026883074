const buttonStyles = {
  smallIcon: {
    fontSize: "1.0em",
    padding: 0,
    margin: 0
  },
  smallButton: {
    fontSize: "1.0em",
    padding: 0,
    margin: 0,
    color: "rgba(1,164,156, 1)"
  },
  buttonRow: {
    wordWrap: "break-word",
    whiteSpace: "normal"
  }
};

export default buttonStyles;
