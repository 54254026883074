export const SHOW_ALL_TEXT = "Show All";

export const REQUEST_FILTER_STATUS = [
  { key: SHOW_ALL_TEXT, value: SHOW_ALL_TEXT, text: "Show All Statuses" },
  {
    key: "Draft Application",
    value: "Draft Application",
    text: "Draft Application"
  },
  { key: "Pending Review", value: "Pending Review", text: "Pending Review" },
  { key: "Lender Review", value: "Lender Review", text: "Lender Review" },
  { key: "Accepted", value: "Accepted", text: "Accepted" },
  { key: "Submitted", value: "Submitted", text: "Submitted" },
  { key: "Approved", value: "Approved", text: "Approved" },
  { key: "Boarded", value: "Boarded", text: "Boarded" },
  { key: "Complete", value: "Complete", text: "Complete" },
  { key: "Incomplete", value: "Incomplete", text: "Incomplete" },
  { key: "Withdrawn", value: "Withdrawn", text: "Withdrawn" },
  { key: "Declined", value: "Declined", text: "Declined" }
];

export const REQUEST_FILTER_SCOPE = [
  { key: SHOW_ALL_TEXT, value: SHOW_ALL_TEXT, text: "Show All Scopes" },
  { key: "fn", text: "Full Narrative", value: "Full Narrative" },
  { key: "fns", text: "Full Narrative SBA", value: "Full Narrative SBA" },
  { key: "ln", text: "Limited Narrative", value: "Limited Narrative" },
  { key: "lns", text: "Limited Narrative SBA", value: "Limited Narrative SBA" },
  { key: "sc", text: "Small Loan", value: "Small Loan" },
  { key: "do", text: "Docs Only", value: "Docs Only" }
];

export const REQUEST_FILTER_SCOPE_NO_SHOW_ALL = REQUEST_FILTER_SCOPE.filter(
  scope => scope.key !== SHOW_ALL_TEXT
);

export const REQUEST_FILTER_SCOPE_PPP = [
  { key: SHOW_ALL_TEXT, value: SHOW_ALL_TEXT, text: "Show All Scopes" },
  { key: "Full Narrative", value: "Full Narrative", text: "Full Narrative" },
  {
    key: "Limited Narrative",
    value: "Limited Narrative",
    text: "Limited Narrative"
  },
  { key: "PPP", value: "PPP", text: "PPP" },
  { key: "Small Loan", value: "Small Loan", text: "Small Loan" }
];

export const REQUEST_FILTER_PHASE = [
  { key: SHOW_ALL_TEXT, value: SHOW_ALL_TEXT, text: "Show All Phases" },
  {
    key: "New Credit Application",
    value: "New Credit Application",
    text: "New Credit Application"
  },
  { key: "Initial Review", value: "Initial Review", text: "Initial Review" },
  {
    key: "Financial Analysis",
    value: "Financial Analysis",
    text: "Financial Analysis"
  },
  { key: "Underwriting", value: "Underwriting", text: "Underwriting" },
  {
    key: "3rd Party Activities",
    value: "3rd Party Activities",
    text: "3rd Party Activities"
  },
  {
    key: "Pending Decision",
    value: "Pending Decision",
    text: "Pending Decision"
  },
  {
    key: "Pre-Closing Conditions",
    value: "Pre-Closing Conditions",
    text: "Pre-Closing Conditions"
  },
  { key: "Doc. Prep", value: "Doc. Prep", text: "Doc. Prep" },
  { key: "Credit Admin", value: "Credit Admin", text: "Credit Admin" },
  { key: "Complete", value: "Complete", text: "Complete" }
];

export const REQUEST_FILTER_ORIGIN = [
  { key: SHOW_ALL_TEXT, value: SHOW_ALL_TEXT, text: "Show All Origins" },
  { key: "Borrower Portal", value: "Borrower Portal", text: "Borrower Portal" },
  { key: "BLAST", value: "BLAST", text: "BLAST" }
];

// This excludes Borrower, Institution, assigned_to, total_amount_requested
export const HEADER_LOAN_COLUMNS = {
  "Est. Close Date": "due_date",
  "App Id": "app_id",
  Scope: "scope",
  "Date Created": "created_datetime",
  "Date Submitted": "date_submitted",
  Phase: "phase",
  Status: "status"
};
