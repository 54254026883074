import React, { Component } from "react";

import _ from "lodash";
import PropTypes from "prop-types";
import TypeCheck from "typecheck-extended";
import {
  Button,
  Checkbox,
  Radio,
  Segment,
  Table,
  Icon
} from "semantic-ui-react";
import { connect } from "react-redux";
import { FormatCurrency } from "num-format";
import { Link, withRouter } from "react-router-dom";
import Constants from "../../../../../../../../services/Constants/strings";
import ACTIVE_PHASES from "../../../../../../../../services/Constants/activePhases";
import { ConcatName } from "../../../../../../../../services/Entities";
import {
  LoanApp,
  Users,
  Cms,
  CreditRequests
} from "../../../../../../../../services/ApiLib";
import { prettyDate } from "../../../../../../../../services/DateTime";
import PaginationControls, {
  paginationChanged
} from "../../../../../../../../components/Pagination";
import logger from "../../../../../../../../services/logger";

const paginationName = "creditManagementCreditRequests";

const tableHeaders = [
  "Select",
  "App Id",
  "Total Amount Requested",
  "Application Phase",
  "Application Status",
  "Date Submitted",
  "Submitted By"
];

export class CreditRequestObj extends Component {
  constructor(props) {
    super(props);

    this.creditReqSelectRow = this.creditReqSelectRow.bind(this);
    this.dispatchLookupDetail = this.dispatchLookupDetail.bind(this);
    this.loadCreditRequestsSuccess = this.loadCreditRequestsSuccess.bind(this);
    this.lookupUserAddToReducer = this.lookupUserAddToReducer.bind(this);
    this.onSuccessCreditMemo = this.onSuccessCreditMemo.bind(this);
    this.createNewLoanApp = this.createNewLoanApp.bind(this);
    this.onErrorLoanApp = this.onErrorLoanApp.bind(this);
    this.navigateToLos = this.navigateToLos.bind(this);
    this.state = {
      showArchivedRequests: false,
      createLoanAppDisabled: false,
      lastPage: false
    };
  }

  componentDidMount() {
    this.loadCreditRequests();
  }

  componentDidUpdate(prevProps) {
    const { entityUuid, pagination, dispatch } = this.props;
    if (
      paginationChanged(prevProps.pagination, pagination) ||
      entityUuid !== prevProps.entityUuid
    ) {
      dispatch({ type: "CMS_CREDIT_REQUEST_UNLOAD_REQUESTS" });
      this.loadCreditRequests();
    }
  }

  componentWillUnmount() {
    this.clearState();
  }

  /* eslint-disable-next-line */
  onError(rsp) {
    logger.error("Error: ", rsp); // TODO: Handle errors properly
  }

  onSuccessCreditMemo(reportData, { creditReqResp }) {
    CreditRequests.asyncUpdate(
      { uuid: creditReqResp.data.uuid },
      { credit_memo_uuid: reportData.data.uuid }
    )
      .then(resp => {
        logger.debug(resp); // TODO: What is this doing?
      })
      .catch(error => {
        this.onError(error);
      });
  }

  onErrorLoanApp(error) {
    logger.error("error", error);
    this.setState({ createLoanAppDisabled: false });
  }

  createAppLink = row =>
    row.status === Constants.AppSubmitted &&
    row.phase !== Constants.AppWithdrawn
      ? `/los/${row.uuid}/phases/app_details`
      : `/los/${row.uuid}/stepper/entity_details`;

  navigateToLos(response) {
    const createdLoanAppUuid = response.data.loan_app.uuid;
    const { history } = this.props;
    history.push(`/los/${createdLoanAppUuid}/stepper/entity_details`);
  }

  createNewLoanApp() {
    const { entityUuid, entityData } = this.props;
    this.setState({ createLoanAppDisabled: true });
    const body = {
      institution_uuid: entityData.institution_uuid,
      source: "LOS",
      entity_uuid: entityUuid
    };

    LoanApp.asyncAddDefault(body)
      .then(resp => {
        this.navigateToLos(resp);
      })
      .catch(error => {
        this.onErrorLoanApp(error);
      });
  }

  clearState() {
    const { dispatch } = this.props;
    dispatch({ type: "CMS_CREDIT_REQUEST_RESET_STATE" });
  }

  loadCreditRequestsSuccess(rsp) {
    TypeCheck(rsp.data, "object");

    this.setState({ lastPage: rsp.metaData ? rsp.metaData.last_page : false });

    const { dispatch } = this.props;
    const { data } = rsp;
    dispatch({ type: "CMS_CREDIT_REQUEST_LOAD_REQUESTS", data });
    this.loadCreditMemos([rsp.data]);
    this.lookupDetails([rsp.data]);
  }

  loadCreditRequests() {
    const { entityUuid, pagination } = this.props;
    const jwt = "Not Used in this ApiLib";
    const uuid = undefined;
    const callbackData = undefined;
    const filters = { entityUuid, include_totals: true };
    const onSuccess = this.loadCreditRequestsSuccess;
    LoanApp.get(
      jwt,
      onSuccess,
      this.onError,
      uuid,
      callbackData,
      filters,
      pagination
    );
  }

  dispatchLookupDetail(detailKey, detailString, loanAppUuid) {
    TypeCheck(detailKey, "string");
    TypeCheck(detailString, "string");
    TypeCheck(loanAppUuid, "string");

    const { dispatch } = this.props;
    dispatch({
      detailKey,
      detailString,
      loanAppUuid,
      type: "CMS_CREDIT_REQUEST_SAVE_LOOKUP_DETAIL"
    });
  }

  lookupUserAddToReducer(userData, callbackData) {
    TypeCheck(callbackData.loanAppUuid, "string");
    TypeCheck(callbackData.detailKey, "string");

    const { detailKey, loanAppUuid } = callbackData;
    const detailString = ConcatName(userData);
    this.dispatchLookupDetail(detailKey, detailString, loanAppUuid);
  }

  lookupUserFail(rsp, callbackData) {
    TypeCheck(callbackData.loanAppUuid, "string");

    const { loanAppUuid } = callbackData;
    const callbackDataUserNotFound = {
      detailKey: "userFullName",
      detailString: "User Not Found",
      loanAppUuid
    };
    const userData = { first_name: " ", last_name: "User Not Found" };
    this.lookupUserAddToReducer(userData, callbackDataUserNotFound);
  }

  lookupUser(userUuid, loanAppUuid) {
    TypeCheck(userUuid, "string", false);
    TypeCheck(loanAppUuid, "string");

    const onSuccess = this.lookupUserAddToReducer;
    const { onError } = this;
    const callbackData = { loanAppUuid, detailKey: "userFullName" };
    if (!userUuid) {
      const rsp = undefined;
      this.lookupUserFail(rsp, callbackData);
      return;
    }

    Users.asyncRead({ userUuid })
      .then((...args) => {
        const userData = args[0];
        onSuccess(userData, callbackData);
      })
      .catch((...args) => {
        onError(...args);
      });
  }

  lookupDetails(loanApps) {
    // Not all data needed for the Credit Request table can be found in the loan_apps table
    // This should lookup any additional details needed and store them in a central place
    // in state keyed by the loan app's uuid
    loanApps.forEach(app => {
      this.lookupUser(app.submitted_by_user, app.uuid);
    });
  }

  loadCreditMemos(creditRequests) {
    TypeCheck(creditRequests, "array");
    const { dispatch } = this.props;
    creditRequests.forEach(item => {
      if (item.credit_memo_uuid) {
        Cms.readSingle(item.credit_memo_uuid)
          .then(({ data }) => {
            dispatch({
              type: "CMS_CREDIT_REQUEST_LOAD_MEMOS",
              request: item.uuid,
              memo: data
            });
          })
          .catch(() => {
            logger.error("An error occurred");
          });
      }
    });
  }

  creditReqSelectRow(e, row) {
    this.props.dispatch({
      type: "CMS_CREDIT_REQUEST_SELECT_ROW",
      value: row.uuid,
      rowData: row
    });
  }

  render() {
    const loadingIcon = <Icon name="spinner" size="large" loading />;
    const { creditRequests } = this.props;

    const headers = tableHeaders.map(header => (
      <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
    ));

    const tableRows = creditRequests.map(row => {
      // TODO: If these completely null entries exist, we need a way for the user to delete them.
      if (
        !row.app_id &&
        !row.loan_class &&
        !row.loan_type &&
        !row.loan_amount &&
        !row.status &&
        !row.underwriter_recommendation &&
        !row.institution_decision
      ) {
        return null;
      }

      if (!ACTIVE_PHASES[row.phase] && !this.state.showArchivedRequests) {
        return null;
      }

      const { lookupData } = this.props;
      return (
        <Table.Row key={row.uuid}>
          <Table.Cell>
            <Radio
              name="selection"
              value={row.uuid}
              checked={row.uuid === this.props.statementSelected}
              onClick={e => this.creditReqSelectRow(e, row)}
            />
          </Table.Cell>
          <Table.Cell>
            {/* TODO: Why doesn't this link to the actual app page? */}
            <a
              href={this.createAppLink(row)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {row.app_id}
            </a>
          </Table.Cell>
          <Table.Cell>
            {row.total_amount_requested
              ? FormatCurrency(row.total_amount_requested)
              : "No Requests"}
          </Table.Cell>
          <Table.Cell>{row.phase}</Table.Cell>
          <Table.Cell>{row.status}</Table.Cell>
          <Table.Cell>{prettyDate(row.date_submitted) || "-"}</Table.Cell>
          <Table.Cell>
            {_.get(lookupData, `${row.uuid}.userFullName`, loadingIcon)}
          </Table.Cell>
        </Table.Row>
      );
    });

    const { creditReqModalInfo, statementSelected } = this.props;

    return (
      <Segment.Group>
        <Segment>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                onClick={this.createNewLoanApp}
                disabled={this.state.createLoanAppDisabled}
              >
                New Loan App
              </Button>
              {statementSelected && creditReqModalInfo.credit_memo_uuid ? (
                <div>
                  <Link
                    to={`/credit_management/memo/${creditReqModalInfo.credit_memo_uuid}`}
                  >
                    <Button>Edit Credit Memo</Button>
                  </Link>
                  <Link
                    to={`/credit_management/memo/${creditReqModalInfo.credit_memo_uuid}/view`}
                  >
                    <Button>View Credit Memo</Button>
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
            <div>
              <Checkbox
                label="Include Archived Requests"
                toggle
                onChange={(e, { checked }) =>
                  this.setState({ showArchivedRequests: checked })
                }
              />
            </div>
          </div>
        </Segment>
        <Segment>
          <Table celled>
            <Table.Header>
              <Table.Row>
                {headers}
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>{tableRows}</Table.Body>
          </Table>
        </Segment>
        <Segment>
          <PaginationControls
            name={paginationName}
            lastPage={this.state.lastPage}
          />
        </Segment>
      </Segment.Group>
    );
  }
}

CreditRequestObj.defaultProps = {
  statementSelected: undefined
};

CreditRequestObj.propTypes = {
  creditRequestMemos: PropTypes.shape({}).isRequired,
  creditRequests: PropTypes.arrayOf(PropTypes.object).isRequired,
  creditReqModalInfo: PropTypes.shape({
    appId: PropTypes.string,
    creditReqClass: PropTypes.string,
    amountRequested: PropTypes.number,
    type: PropTypes.string,
    status: PropTypes.string,
    underwritingRec: PropTypes.string,
    institutionDecision: PropTypes.string,
    credit_memo_uuid: PropTypes.string
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  entityUuid: PropTypes.string.isRequired,
  entityData: PropTypes.shape({
    institution_uuid: PropTypes.string,
    uuid: PropTypes.string
  }).isRequired,
  lookupData: PropTypes.objectOf(PropTypes.object).isRequired,
  statementSelected: PropTypes.string,
  pagination: PropTypes.shape({
    number: PropTypes.number,
    size: PropTypes.number
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  })
};

const mapStateToProps = state => ({
  analystUuid: state.auth.userUuid,
  creditRequestMemos: state.CreditManagementRequestsReducer.creditRequestMemos,
  creditRequests: state.CreditManagementRequestsReducer.creditRequests,
  lookupData: state.CreditManagementRequestsReducer.lookupData,
  // IDK what's going on with the reducers
  creditReqModalInfo: state.CreditManagementReducer.creditReqModalInfo,
  entityData: state.CreditManagementReducer.entityData,
  memoModelStartedBlank: state.CreditManagementReducer.memoModelStartedBlank,
  statementSelected: state.CreditManagementReducer.statementSelected,
  pagination: state.PaginationReducer[paginationName]
});

const CreditRequest = withRouter(connect(mapStateToProps)(CreditRequestObj));

export default CreditRequest;
