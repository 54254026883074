/*

This Intercom service is for relaying messages between tabs,
potentially bi-directional.

Both parent and child should have their own instance of this class.

While you must specify scope, it can generally just be window.

Include a callbackForMessagesReceived if you want to send
received data to the current component.

ex:
componentDidMount() {
  this.intercomInstance.setupIntercom(window, callbackForMessagesReceived);
}

******************************************
Use of the send() method directly is not
advised, unless you don't care
if your message gets through.
*******************************************

*/

import TypeCheck from "typecheck-extended";

export default class Intercom {
  constructor(callback) {
    TypeCheck(callback, "function", false);
    this.state = {
      timer: null,
      destination: {},
      callback: () => {}
    };
    this.receiveMessage = this.receiveMessage.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.send = this.send.bind(this);
    if (callback) {
      this.state = { ...this.state, callback };
    }
    window.addEventListener("message", this.receiveMessage, false);
  }

  openNewTab(uri) {
    TypeCheck(uri, "string", true);
    const childTab = window.open(uri);
    this.state = { ...this.state, destination: childTab };
    return childTab;
  }

  receiveMessage(message) {
    TypeCheck(message, "object", true);
    if (message.source === window) {
      return "message from self";
    }
    const params =
      typeof message.data === "string"
        ? JSON.parse(message.data)
        : message.data;
    params.received = params.received || false;
    if (params.received) {
      window.clearTimeout(this.state.timer);
      this.state = { ...this.state, timer: null };
      return "confirmation received";
    }
    this.send({ received: true }, message.source);
    return this.state.callback(params);
  }

  send(params, sendTo) {
    TypeCheck(params, "object", true);
    TypeCheck(sendTo, "object", false);
    if (sendTo) {
      this.state = { ...this.state, destination: sendTo };
    }
    const { destination } = this.state;
    destination.postMessage(params, "*");
    return "message sent";
  }

  sendMessage(params, sendTo) {
    TypeCheck(sendTo, "object", false);
    TypeCheck(params, "object", true);
    this.state = {
      ...this.state,
      timer: window.setInterval(() => {
        this.send(params, sendTo);
      }, 2000)
    };
    return "success";
  }
}
