import TypeCheck from "typecheck-extended";

import errorToState from "../../../errorToState";
import { Assets } from "../../../../../../../../services/ApiLib";
import { getToken } from "../../../../../../../../services/Auth";

export function dispatchAssets(rsp, callbackData) {
  TypeCheck(rsp.data, "object");
  TypeCheck(callbackData.dispatch, "function");
  TypeCheck(callbackData.losScene, "string");

  const { dispatch, losScene } = callbackData;
  const stepperDispatchType = "LOR_COLLATERAL_REDUCER_UPDATE_ASSETS";
  const phasesDispatchType = "TODO_SET_THIS_UP"; // TODO: This is needed for this to work w/ Phases
  const type =
    losScene === "stepper" ? stepperDispatchType : phasesDispatchType;

  dispatch({ assets: rsp.data, uuid: rsp.data.uuid, type });
  // dispatch to loan origination reducer and update the collateral associated with that asset
  dispatch({
    type: "loanApplications/collateral/sync_assets",
    payload: {
      asset: rsp.data,
      uuid: rsp.data.uuid
    }
  });
}

export function assetsToState(assetUuid, dispatch, losScene) {
  TypeCheck(assetUuid, "string");
  TypeCheck(dispatch, "function");
  TypeCheck(losScene, "enum", true, ["phases", "stepper"]);

  const jwt = getToken();
  const callbackData = { dispatch, losScene };

  const onSuccess = dispatchAssets;
  const onError = errorToState;

  Assets.get(jwt, onSuccess, onError, assetUuid, null, callbackData);
}
