import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Icon, Table } from "semantic-ui-react";
import { FormatCurrency } from "num-format";
import moment from "moment";

function LoanPremiumDiscountHistory({ loanPremiumDiscountHistory }) {
  const [expandedMonths, setExpandedMonths] = useState({});

  const expandMonth = month => {
    setExpandedMonths({ ...expandedMonths, [month]: true });
  };
  const collapseMonth = month => {
    setExpandedMonths({ ...expandedMonths, [month]: false });
  };

  const historyByMonth = useMemo(
    () =>
      loanPremiumDiscountHistory.reduce((allHistory, entry) => {
        const yearMonth = entry.transaction_date.substr(0, 7);
        const newHistory = { ...allHistory };

        if (allHistory[yearMonth]) {
          newHistory[yearMonth].day_entries.push(entry);
          // earned amount = transaction amt
          newHistory[yearMonth].transaction_total += entry.transaction_amount;
          if (entry.is_month_end) {
            // unearned premium = end of month (or day) balance.
            newHistory[yearMonth].unearned_total = entry.ending_balance;
          }
        } else {
          newHistory[yearMonth] = {
            key: yearMonth,
            month: moment(entry.transaction_date).format("MMMM YYYY"),
            transaction_total: entry.transaction_amount,
            // we may not have a month end entry yet but entries are sorted by date.
            unearned_total: entry.ending_balance,
            day_entries: [entry]
          };
        }
        return newHistory;
      }, {}),
    [loanPremiumDiscountHistory]
  );

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell style={{ borderLeft: "none" }}>
            Month
          </Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Earned Premium</Table.HeaderCell>
          <Table.HeaderCell>Unearned Premium</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {Object.values(historyByMonth).map(hist => [
          <Table.Row key={hist.key}>
            <Table.Cell
              collapsing
              celled={false}
              onClick={() => {
                if (expandedMonths[hist.month]) {
                  collapseMonth(hist.month);
                } else {
                  expandMonth(hist.month);
                }
              }}
            >
              <Icon
                circular
                name={
                  expandedMonths[hist.month] ? "chevron down" : "chevron right"
                }
              />
            </Table.Cell>
            <Table.Cell collapsing style={{ borderLeft: "none" }}>
              {hist.month}
            </Table.Cell>
            <Table.Cell />
            <Table.Cell>
              {FormatCurrency(hist.transaction_total, false)}
            </Table.Cell>
            <Table.Cell>
              {FormatCurrency(hist.unearned_total, false)}
            </Table.Cell>
          </Table.Row>,
          expandedMonths[hist.month] &&
            hist.day_entries.map(day => (
              <Table.Row key={day.transaction_date}>
                <Table.Cell colspan="2" />
                <Table.Cell>
                  {moment(day.transaction_date).format("L")}
                </Table.Cell>
                <Table.Cell>
                  {FormatCurrency(day.transaction_amount, false)}
                </Table.Cell>
                <Table.Cell>
                  {FormatCurrency(day.ending_balance, false)}
                </Table.Cell>
              </Table.Row>
            ))
        ])}
      </Table.Body>
    </Table>
  );
}

LoanPremiumDiscountHistory.propTypes = {
  loanPremiumDiscountHistory: PropTypes.arrayOf(PropTypes.shape)
};

export default LoanPremiumDiscountHistory;
