import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Input } from "semantic-ui-react";

import DocTypeSelects from "../../../../../../../../components/DocTypeSelectsSemantic";
import FilterChip from "./components/FilterChip";

export class FilterClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilterOptions: false,
      filterApplied: false
    };
    this.applyFilter = this.applyFilter.bind(this);
    this.toggleFilterOptions = this.toggleFilterOptions.bind(this);
  }

  toggleFilterOptions() {
    this.setState(prev => ({
      showFilterOptions: !prev.showFilterOptions
    }));
  }

  applyFilter() {
    this.setState({
      filterApplied: true
    });
    this.props.callbackFilterApply();
  }

  render() {
    const activeFilters = this.props.filters;
    return (
      <div>
        {Object.keys(activeFilters).map(key => {
          if (this.state.filterApplied && activeFilters[key]) {
            return (
              <FilterChip
                callbackFilterRemove={this.props.callbackFilterRemove}
                filterValue={activeFilters[key]}
                filterKey={key}
                key={key}
              />
            );
          }
          return null;
        })}
        {this.state.showFilterOptions ? (
          <span>
            <Button onClick={() => this.toggleFilterOptions()}>
              Hide Options
            </Button>

            <Input
              placeholder="Institution"
              value={this.props.formData.institution}
              onChange={(event, data) =>
                this.props.callbackUpdateFormData("institution", data)
              }
            />

            <Input
              placeholder="TIN"
              value={this.props.formData.tin}
              onChange={(event, data) =>
                this.props.callbackUpdateFormData("tin", data)
              }
            />

            <Input
              placeholder="Entity Name"
              value={this.props.formData.name}
              onChange={(event, data) =>
                this.props.callbackUpdateFormData("name", data)
              }
            />

            <DocTypeSelects
              docCat={this.props.formData.category}
              docSubCat={this.props.formData.subcategory}
              callbackFilterParamChange={this.props.callbackUpdateFormData}
            />

            <Button onClick={() => this.applyFilter()}>Apply</Button>
          </span>
        ) : (
          <Button onClick={() => this.toggleFilterOptions()}>
            Show Filter
          </Button>
        )}
      </div>
    );
  }
}

FilterClass.propTypes = {
  filters: PropTypes.shape({
    category: PropTypes.string,
    institution: PropTypes.string,
    name: PropTypes.string,
    subcategory: PropTypes.string,
    tin: PropTypes.string
  }).isRequired,
  formData: PropTypes.shape({
    category: PropTypes.string,
    institution: PropTypes.string,
    name: PropTypes.string,
    subcategory: PropTypes.string,
    tin: PropTypes.string
  }).isRequired,
  callbackUpdateFormData: PropTypes.func.isRequired,
  callbackFilterRemove: PropTypes.func.isRequired,
  callbackFilterApply: PropTypes.func.isRequired
};

const Filter = connect()(FilterClass);

export default Filter;
