import React from "react";
import { Segment, Table, Button, Icon } from "semantic-ui-react";

import PaginationControls from "../../../../components/Pagination";

const paginationName = "institutionsSystemAdmin";

const headers = ["Institution", "Username", "Email", ""];

export function SftpObj() {
  const tableHeaders = headers.map(header => (
    <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
  ));
  const tableRows = [];

  return (
    <div>
      <Segment>
        <Button
          basic
          circular
          icon
          floated="right"
          style={{ marginBottom: "10px" }}
        >
          <Icon name="plus" color="green" />
        </Button>
        <Table compact celled selectable>
          <Table.Header>
            <Table.Row>{tableHeaders}</Table.Row>
          </Table.Header>
          <Table.Body>{tableRows}</Table.Body>
        </Table>
        <PaginationControls length={tableRows.length} name={paginationName} />
      </Segment>
    </div>
  );
}

export default SftpObj;
