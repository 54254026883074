import { PropTypes } from "prop-types";
import logger from "../../services/logger";

export function onError(rsp) {
  logger.error("Error: ", rsp); // TODO: Handle errors properly
}

export const CREDIT_ANALYSIS_STATUS_TAG_COLOR = {
  Current: "green",
  Draft: "gray",
  Expired: "red",
  Historical: "yellow",
  Recommendation: "blue"
};

export const CREDIT_ANALYSIS_ACTIONS_IDS = {
  EDIT: "EDIT",
  PROMOTE: "PROMOTE",
  DELETE: "DELETE",
  VIEW: "VIEW",
  NEW_VERSION: "NEW_VERSION"
};

export const CREDIT_ANALYSIS_ACTIONS_BY_STATUS = {
  Draft: [CREDIT_ANALYSIS_ACTIONS_IDS.EDIT, CREDIT_ANALYSIS_ACTIONS_IDS.DELETE]
};

export const getEntityName = ({ entityData }) =>
  entityData &&
  (entityData?.company_name
    ? entityData.company_name
    : `${entityData?.first_name ?? ""} ${entityData?.middle_name ?? ""} ${
        entityData?.last_name ?? ""
      }`);

export const loanDataProp = PropTypes.shape({
  uuid: PropTypes.string,
  loan_class: PropTypes.string,
  institution_account_number: PropTypes.string,
  loan_type: PropTypes.string,
  app_id: PropTypes.string,
  phase: PropTypes.string,
  status: PropTypes.string
});

export const entityDataProp = PropTypes.shape({
  uuid: PropTypes.string,
  company_name: PropTypes.string,
  first_name: PropTypes.string,
  middle_name: PropTypes.string,
  last_name: PropTypes.string
});

export const creditAnalysisFormObjectProp = PropTypes.shape({
  selectedSingleRiskScale: PropTypes.shape({
    name: PropTypes.string,
    version: PropTypes.number,
    single_risk_scale_items: PropTypes.arrayOf(
      PropTypes.shape({
        rating: PropTypes.number,
        name: PropTypes.string,
        passing_grade: PropTypes.bool,
        description: PropTypes.string
      })
    )
  }),
  selectedSingleRiskScaleItem: PropTypes.shape({
    rating: PropTypes.number,
    name: PropTypes.string,
    passing_grade: PropTypes.bool,
    description: PropTypes.string
  }),
  selectedPurpose: PropTypes.string,
  selectedApprovalAuthority: PropTypes.string,
  selectedPeriodStart: PropTypes.string,
  selectedPeriodEnd: PropTypes.string,
  enteredJustification: PropTypes.string
}).isRequired;
