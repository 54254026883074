import React from "react";
import { Grid, Header, Segment } from "semantic-ui-react";
import { shape, string } from "prop-types";
import { ConcatName } from "../../../../services/Entities";

import entityDataProps from "../../../../services/PropTypes/entityData";

function HeaderBlock({ entityData, institutionData, description }) {
  return (
    <>
      <h1 className="mb-6">Asset Management</h1>
      <Segment>
        <Grid>
          <Grid.Row columns="2">
            <Grid.Column textAlign="left" verticalAlign="bottom" width="10">
              <Header as="h1">{entityData && ConcatName(entityData)}</Header>
            </Grid.Column>
            <Grid.Column textAlign="right" verticalAlign="bottom" width="6">
              <Header as="h2" size="medium">
                {institutionData.name}
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="1">
            <Grid.Column textAlign="left" verticalAlign="bottom" width="10">
              <Header as="h2" size="small">
                {`${entityData.entity_type} ${description}`}
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
}

HeaderBlock.propTypes = {
  description: string.isRequired,
  entityData: entityDataProps.isRequired,
  institutionData: shape().isRequired
};

export default HeaderBlock;
