import PropTypes from "prop-types";
import React from "react";
import { Button, Icon, Table, Header } from "semantic-ui-react";
import { FormatCurrency } from "num-format";
import { orderBy } from "lodash";
import UuidLookups from "../../../../../../../../../../../../components/UuidLookups";
import { prettyDate } from "../../../../../../../../../../../../services/DateTime";

import EditFeeModal from "../EditFeeModal";
import feeReadableReason from "./feeReadableReason";

function AmortizedFeesTable({ fees, deleteFee }) {
  let filteredFee = fees.filter(fee => fee?.fee_type === "Amortized");
  filteredFee = orderBy(filteredFee, ["created_datetime"], ["desc"]);
  return (
    <div className="overflow-x-auto">
      <Header>Amortized Fees</Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell key="amortization_method">
              Amortization Method
            </Table.HeaderCell>
            <Table.HeaderCell key="original_book_value">
              Original Book Value
            </Table.HeaderCell>
            <Table.HeaderCell key="current_book_value">
              Current Book Value
            </Table.HeaderCell>
            <Table.HeaderCell key="prev_period_amortization_amount">
              Amortized Last Period
            </Table.HeaderCell>
            <Table.HeaderCell key="amortization_start_date">
              Amortization Start Date
            </Table.HeaderCell>
            <Table.HeaderCell key="amortized_through">
              Amortized Through
            </Table.HeaderCell>
            <Table.HeaderCell key="original_life_of_loan">
              Amortize Fee
            </Table.HeaderCell>
            <Table.HeaderCell key="remaining_life_of_loan">
              Periods Remaining to Amortize Fee
            </Table.HeaderCell>
            <Table.HeaderCell key="amortized_reason">Fee Name</Table.HeaderCell>
            <Table.HeaderCell key="description">Description</Table.HeaderCell>
            <Table.HeaderCell key="transaction code">
              Amortization Transaction Code{" "}
            </Table.HeaderCell>
            <Table.HeaderCell key="Original Loan Amortization Term">
              Original Loan Amortization Term
            </Table.HeaderCell>
            <Table.HeaderCell key="Remaining Loan Amortization Term">
              Remaining Loan Amortization Term
            </Table.HeaderCell>
            <Table.HeaderCell key="created_by">Created By</Table.HeaderCell>
            <Table.HeaderCell key="created_datetime">
              Created Date
            </Table.HeaderCell>
            <Table.HeaderCell key="edit">Edit</Table.HeaderCell>
            <Table.HeaderCell key="delete">Delete</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {filteredFee.map(fee => (
            <Table.Row key={fee?.uuid}>
              <Table.Cell>{fee?.amortization_method}</Table.Cell>
              <Table.Cell>
                {FormatCurrency(fee?.original_book_value ?? 0, false)}
              </Table.Cell>
              <Table.Cell>
                {FormatCurrency(fee?.current_book_value ?? 0, false)}
              </Table.Cell>
              <Table.Cell>
                {FormatCurrency(
                  fee?.prev_period_amortization_amount ?? 0,
                  false
                )}
              </Table.Cell>
              <Table.Cell>
                {fee?.amortization_start_date
                  ? prettyDate(fee?.amortization_start_date)
                  : "Not Found"}
              </Table.Cell>
              <Table.Cell>
                {fee?.amortized_through
                  ? prettyDate(fee?.amortized_through)
                  : "Not Found"}
              </Table.Cell>
              <Table.Cell>{fee?.original_life_of_loan}</Table.Cell>
              <Table.Cell>{fee?.remaining_life_of_loan}</Table.Cell>
              <Table.Cell>{feeReadableReason(fee)}</Table.Cell>
              <Table.Cell>
                {fee?.description?.length > 10
                  ? `${fee?.description.substring(0, 10)}...`
                  : fee?.description}
              </Table.Cell>
              <Table.Cell>{fee?.amortization_tran_code}</Table.Cell>
              <Table.Cell>{fee?.original_loan_amortization_term}</Table.Cell>
              <Table.Cell>{fee?.remaining_loan_amortization_term}</Table.Cell>
              <Table.Cell>
                <UuidLookups lookupType="user" uuid={fee?.created_by} />
              </Table.Cell>
              <Table.Cell>
                {fee?.created_datetime
                  ? prettyDate(fee?.created_datetime)
                  : "Not Found"}
              </Table.Cell>
              <Table.Cell>
                <EditFeeModal fee={fee} />
              </Table.Cell>
              <Table.Cell>
                <Button
                  basic
                  circular
                  icon
                  onClick={() => deleteFee(fee?.uuid)}
                >
                  <Icon name="delete" />
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}

AmortizedFeesTable.propTypes = {
  fees: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteFee: PropTypes.func.isRequired
};

export default AmortizedFeesTable;
