import React, { useEffect, useState } from "react";
import { useEntityQuery } from "../../../../../../services/RTKQuery/entities";

export const ReadSingleTest = ({ entityUuid, setStatusInParent }) => {
  const statusUnknown = "❓Unknown Status";
  const statusPass = "✅ Pass";
  const statusFail = "❌ Fail";
  const statusLoading = "Loading ...";

  const [statusReadSingle, setStatus] = useState(statusUnknown);
  const [detailsText, setDetailsText] = useState("");

  const { data: entityData } = useEntityQuery(entityUuid);
  useEffect(() => {
    setStatus(statusLoading);
    if (entityData?.uuid && entityUuid === entityData?.uuid) {
      setStatus(statusPass);
      setDetailsText(`:  Matched record on: ${entityData?.uuid}`);
      setStatusInParent("Pass");
    } else {
      console.error("Fail: useEntityQuery");
      setStatus(statusFail);
    }
  }, [entityUuid, entityData?.uuid, setStatusInParent]);

  return (
    <>
      <b>{statusReadSingle}</b>
      <small>{detailsText}</small>
    </>
  );
};
