import { blastApi, Tag } from "./blastApi";
import {
  Entity,
  HardDeleteDataRsp,
  ListResponse,
  PaginatedQueryOptions,
  SingleResponse
} from "./types";

import {
  filteredQuery,
  extractData,
  createListTags,
  createSingleTag
} from "./utilities";

export const entitiesBasePath = "/entities/v2";

export interface EntityQueryOptions extends PaginatedQueryOptions {
  lookup?: string;
  //
  bp_organization_uuid?: string;
  company_name?: string;
  institution_uuid?: string;
  list_column?: string;
  list_items?: string;
  tin?: string;
}

const extendedApi = blastApi.injectEndpoints({
  endpoints: build => ({
    entity: build.query<Entity, string>({
      query: uuid => `${entitiesBasePath}/entities/${uuid}`,
      providesTags: result => createSingleTag(result, Tag.Entity),
      transformResponse: (response: SingleResponse<Entity>) =>
        extractData<Entity>(response)
    }),
    entities: build.query<ListResponse<Entity>, EntityQueryOptions>({
      query: filters => filteredQuery(`${entitiesBasePath}/entities`, filters),
      providesTags: results => createListTags(results, Tag.Entity)
    }),
    createEntity: build.mutation<Entity, Partial<Entity>>({
      query: entity => ({
        url: `${entitiesBasePath}/entities`,
        method: "POST",
        body: entity
      }),
      invalidatesTags: [Tag.Entity]
    }),
    deleteEntity: build.mutation<SingleResponse<HardDeleteDataRsp>, string>({
      query: uuid => ({
        url: `${entitiesBasePath}/entities/${uuid}`,
        method: "DELETE"
      }),
      invalidatesTags: [Tag.Entity]
    }),
    updateEntity: build.mutation<SingleResponse<Entity>, Partial<Entity>>({
      query: entity => ({
        url: `${entitiesBasePath}/entities/${entity.uuid}`,
        method: "PUT",
        body: entity
      }),
      invalidatesTags: [Tag.Entity]
    })
  }),
  overrideExisting: false
});

export const {
  useCreateEntityMutation,
  useDeleteEntityMutation,
  useEntitiesQuery,
  useEntityQuery,
  useUpdateEntityMutation
} = extendedApi;
