import React, {
  InputHTMLAttributes,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState
} from "react";
import { Text } from "@blast-client/types";
import { getFormattedTextComponent } from "../../../../util/text/text";
import type { DropdownProps } from "../dropdown/dropdown";
import {
  ButtonDropdown,
  ButtonDropdownProps
} from "../button-dropdown/button-dropdown";

/**
 * A dropdown that includes an `<input>` above the list that can be used sort
 * the list items with the most relevant at the top.
 *
 * This abstract component is not meant to be used directly in an application,
 * it is meant to be composed as part of a higher order component (see the
 * SearchListDropdown component in the `list` directory).
 */
export const SearchDropdown = forwardRef(
  (
    {
      customHeader,
      headerProps,
      onInput,
      placeholder,
      ...props
    }: SearchDropdownProps,
    ref
  ) => {
    const [search, setSearch] = useState<string | null>("");
    const inputMouseDown = useRef(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleControlClick = useCallback<
      Required<DropdownProps>["handleControlClick"]
    >(evt => {
      // The Dropdown pays attention to key events. If a keypress results in a
      // string with a space in it (" ") Dropdown will treat that as a request by
      // the user to close the dropdown. However if the user is entering test in
      // the search input field we want to ignore most (all?) key events.
      if ("key" in evt) {
        if (evt.key === "Enter" || evt.key === "Escape") {
          return false;
        }

        if (inputRef.current === evt.target) {
          return true;
        }
      }

      // If the user starts dragging the mouse in the input box, say to select
      // some or all of the text, and they release the mouse button outside the
      // input box over the dropdown control, a click event will happen in the
      // Dropdown component.
      //
      // The default behavior when Dropdown is clicked in this scenario is to
      // collapse the list, not really what the user wants. So if the user started
      // selecting in the input (onMouseDown) and lifted up outside then return
      // `true` which will prevent the Dropdown from collapsing the list.
      const handled = inputMouseDown.current;
      inputMouseDown.current = false;
      return handled;
    }, []);

    const searchBarProps = useMemo<Partial<SearchDropdownProps>>(
      () => ({
        onClick: (evt: React.MouseEvent) => {
          evt.stopPropagation();
        },
        onInput: evt => {
          setSearch(evt?.currentTarget?.value ?? null);
          onInput(evt);
        },
        onMouseDown: () => {
          inputMouseDown.current = true;
        },
        onMouseUp: () => {
          inputMouseDown.current = false;
        },
        placeholder
      }),
      [onInput, placeholder]
    );

    function handleCollapsed(collapsed: boolean) {
      // Enable parent to know when the list is no longer visible
      if (props?.onCollapsed) {
        props?.onCollapsed(collapsed);
      }

      if (collapsed) {
        inputMouseDown.current = false;
        setSearch("");
        onInput(null);
      }
    }

    return (
      <ButtonDropdown
        {...props}
        ref={ref}
        handleControlClick={handleControlClick}
        listHeader={
          <>
            <SearchBar
              {...searchBarProps}
              ref={inputRef}
              value={search ?? ""}
            />
            {customHeader ? customHeader : null}
            {headerProps?.icon ? (
              <div className="dropdown-list-header">
                <div className="list-header-with-icon">
                  <i className={`${headerProps.icon} icon`}></i>
                  {getFormattedTextComponent(headerProps)}
                </div>
              </div>
            ) : headerProps ? (
              <div className="dropdown-list-header">
                {getFormattedTextComponent(headerProps)}
              </div>
            ) : null}
          </>
        }
        onCollapsed={handleCollapsed}
      />
    );
  }
);

const SearchBar = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement>
>(({ type, ...props }, ref) => {
  return (
    <div className="ui icon search input">
      <i className="search icon"></i>
      <input {...props} ref={ref} role="search" type={type} />
    </div>
  );
});

export interface SearchDropdownProps
  extends Omit<ButtonDropdownProps, "onCollapsed"> {
  /**
   * Optional custom header component that will be displayed below the Search component.
   * If both JSX and headerProps are given, then the custom header will display above the default style header
   * If neither are given, nothing will show above the list
   */
  customHeader?: React.ReactElement;
  /**
   * Optional header component above the list items that follows established design patterns.
   * Can be with or without an icon.
   * The icon string is the icon class that defines which icon is being shown.
   */
  headerProps?: Text & { icon?: string };
  /**
   * Invoked with the current value as the user types in the search input. The
   * value of the `event` parameter will be null when the contents of the search
   * input have been programmatically cleared.
   */
  // onInput: FormEventHandler<HTMLInputElement>;
  onInput: (event: React.FormEvent<HTMLInputElement> | null) => void;
  /**
   * Some parents may need to know when the list is no longer visible (is
   * collapsed), this callback is invoked when that happens.
   */
  onCollapsed?: (collapsed: boolean) => void;
  /**
   * Display text that will be shown in the search input when the user has not
   * entered any characters.
   */
  placeholder?: InputHTMLAttributes<HTMLInputElement>["placeholder"];
  /**
   * Any additional classes to be added to the container
   * */
  parentClassNames?: string;
}
