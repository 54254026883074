import React from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";

import { Dropdown } from "../../../../fomantic/modules/dropdown/dropdown/dropdown";

export function UserSettings({
  hideMyAccountOption = false,
  logout,
  user,
  portalName
}: UserSettingsProps) {
  const history = useHistory();

  if (!logout) {
    return null;
  }

  const onMyAccountClick = () => {
    if (portalName === "borrower-portal") {
      history.push("/my-account/profile");
    } else {
      // This component loads in a number of applications
      // however, My Account "/settings" page resides in commotion
      // which prevents us from using history.push
      window.location.pathname = "/settings";
    }
  };

  const onLogoutClick = () => {
    logout();
  };

  const handleChildSelect = (evt: React.SyntheticEvent) => {
    switch (evt.currentTarget.id) {
      case "item-1":
        onMyAccountClick();
        break;
      case "item-2":
        onLogoutClick();
        break;
      default:
        break;
    }
  };

  const items = [
    <li id="item-2" key="SIGN_OUT_BUTTON_TEXT">
      <span>
        <i className="icon sign out alternate" />
        <FormattedMessage id="SIGN_OUT_BUTTON_TEXT" />
      </span>
    </li>
  ];

  /*TODO https://dev.azure.com/BAFSLLC/BLAST/_workitems/edit/5543 hide "my account" until ugm completion  */
  if (!hideMyAccountOption) {
    items.unshift(
      <li id="item-1" key="MY_ACCOUNT">
        <span>
          <i className="icon user" />
          <FormattedMessage id="MY_ACCOUNT" />
        </span>
      </li>
    );
  }

  function renderDisplayName() {
    if (!user) return "";
    const { givenName, surname, email } = user;
    const name = `${givenName || ""} ${surname || ""}`;
    return name.trim() || email;
  }

  return (
    <div className="user-settings">
      <Dropdown
        control={
          <span className="flex items-center" style={{ transition: "inherit" }}>
            <span className="mr-2">{renderDisplayName()}</span>
            <FontAwesomeIcon icon={faSortDown} style={{ marginTop: "-4px" }} />
          </span>
        }
        onChildSelect={handleChildSelect}
        variant="floating"
      >
        {items}
      </Dropdown>
    </div>
  );
}

export interface UserSettingsProps {
  /** When true the user account settings option will not be shown; defaults to
   * false. */
  hideMyAccountOption?: boolean;
  portalName: "blast" | "borrower-portal";
  logout?: () => void;
  user?: {
    /** First name in the US */
    givenName: string;
    /** Last name in the US */
    surname: string;
    userUuid?: string;
    institutionUuid?: string;
    email?: string;
  };
}
