import React, { useEffect, useState, useRef } from "react";
import { useCreateInterestScheduleMutation } from "../../../../../../services/RTKQuery/interestSchedules";
import { InterestSchedule } from "../../../../../../services/RTKQuery/ModelTypes/interestSchedule"; // Assuming the correct path

export const CreateTest = ({
  institutionUuid,
  loanUuid,
  setUuidOfCreatedRecord,
  setStatusInParent
}) => {
  const statusUnknown = "❓Unknown Status";
  const statusPass = "✅ Pass";
  const statusFail = "❌ Fail";

  const [statusCreateState, setStatusCreateState] = useState(statusUnknown);

  const itemUuid = useRef("");

  const [interestScheduleMutationTrigger] = useCreateInterestScheduleMutation();
  useEffect(() => {
    const exampleBody: Partial<InterestSchedule> = {
      // We can define enums inside an interface in a way where they'll
      // work when used with the string value of the enum. Since we have
      // not done that in all places, I'll ts-ignore these for now.
      // @ts-ignore
      accrual_basis: "Actual/Actual",
      change_frequency: 12,
      // @ts-ignore
      change_period: "Month(s)",
      deleted: false,
      end_date: "2022-12-31",
      institution_uuid: institutionUuid,
      interest_rate: 0.0714,
      // @ts-ignore
      interest_rate_method: "Simple",
      loan_uuid: loanUuid,
      margin: 0.5,
      max_rate_decrease: 0.005,
      max_rate_increase: 0.005,
      next_change_date: "2023-01-01",
      phoenix_import: false,
      processing_order: 1,
      rate_ceiling: 5,
      rate_floor: 2,
      rate_index: "WSJP",
      // @ts-ignore
      rate_type: "Variable",
      start_date: "2022-01-01",
      // When working with preexisting data, "Active" status is likely to fail
      // as there is a backend check to ensure only one active schedule per loan.
      // @ts-ignore
      status: "Upcoming"
    };

    interestScheduleMutationTrigger(exampleBody)
      .unwrap()
      .then(fulfilled => {
        itemUuid.current = fulfilled.data.uuid;
        setUuidOfCreatedRecord(itemUuid.current);
        setStatusCreateState(statusPass);
        setStatusInParent("Pass");
      })
      .catch(error => {
        console.error("Fail: useCreateInterestScheduleMutation");
        console.error("caught error: ", error);
        setStatusCreateState(statusFail);
        setStatusInParent("Fail");
      });
  }, [
    institutionUuid,
    interestScheduleMutationTrigger,
    loanUuid,
    setStatusInParent,
    setUuidOfCreatedRecord
  ]);

  return <div>{statusCreateState}</div>;
};
