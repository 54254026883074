import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import styled from "@emotion/styled";
import { FormatCurrency } from "num-format";
import { ConcatName as getEntityName } from "../../../../../../services/Entities";
import DragAndDrop from "../DragAndDrop";
import Docs from "./Docs";

function EntityDocsTable({
  analysts,
  docs,
  entity,
  institutionUuid,
  parentUuid,
  index,
  readOnly = false,
  onUploadSuccess,
  onUpdateDoc,
  onDeleteDoc,
  relType,
  defaultCategory
}) {
  const [collapsed, setCollapsed] = useState(!readOnly);
  const [name, setName] = useState(null);
  const [title, setTitle] = useState(null);
  const iconName = collapsed ? "folder outline" : "folder open outline";

  useEffect(() => {
    // get the name and title based on entity properties
    let nextName;
    if ("original_amount" in entity) {
      nextName = `Loan Request ${index} - ${
        entity.original_amount != null
          ? FormatCurrency(entity.original_amount)
          : "N/A"
      }`;
    } else if (entity.description) {
      nextName = entity.description;
    } else {
      nextName = getEntityName(entity);
    }

    setTitle(
      entity.tin
        ? `${nextName} | ${entity?.entity_type} | ${entity.tin}`
        : nextName
    );
    setName(nextName);
  }, [index, entity]);

  return (
    <DragAndDrop
      institutionUuid={institutionUuid}
      entityUuid={entity.entity_uuid || entity.uuid}
      parentUuid={parentUuid}
      docCategory={defaultCategory}
      parentRelType="Entity"
      onUploadSuccess={onUploadSuccess}
      readOnly={readOnly}
      bpOrganizationUuid={entity.bp_organization_uuid}
    >
      <Header onClick={readOnly ? null : () => setCollapsed(!collapsed)}>
        <Icon name={iconName} size="large" />
        {title}
      </Header>
      <DnDMessage>
        <span>Drag + Drop files for </span>
        <span>{name}</span>
      </DnDMessage>
      {!collapsed ? (
        <Docs
          analysts={analysts}
          docs={docs}
          parentUuid={parentUuid}
          onUpdateDoc={onUpdateDoc}
          onDeleteDoc={onDeleteDoc}
          readOnly={readOnly}
          relType={relType}
        />
      ) : null}
    </DragAndDrop>
  );
}

const Header = styled.h3`
  display: flex;
  cursor: pointer;
  line-height: 1.5;
  align-items: "center";
  margin-bottom: 1em;
  color: #777;
  font-size: 12pt;

  & > i {
    margin-right: 0.5em;
  }
`;

const DnDMessage = styled.p`
  color: #666;
  text-align: center;
  font-size: 11px;
  border: 2px dashed #dedede;
  padding: 10px;
  border-radius: 5px;
`;

EntityDocsTable.propTypes = {
  analysts: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string
    })
  }),
  docs: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      description: PropTypes.string,
      file_name: PropTypes.string,
      type: PropTypes.string,
      upload_date: PropTypes.string,
      uploaded_by: PropTypes.string,
      uuid: PropTypes.string
    })
  ),
  entity: PropTypes.shape({
    description: PropTypes.string,
    original_amount: PropTypes.number,
    tin: PropTypes.string,
    uuid: PropTypes.string,
    entity_uuid: PropTypes.string,
    bp_organization_uuid: PropTypes.string
  }),
  institutionUuid: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  parentUuid: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  onUploadSuccess: PropTypes.func.isRequired,
  onDeleteDoc: PropTypes.func.isRequired,
  onUpdateDoc: PropTypes.func.isRequired,
  relType: PropTypes.string,
  defaultCategory: PropTypes.string
};

export default EntityDocsTable;
