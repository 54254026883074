import { useIntl } from "react-intl";
import { SideSheetDropdown } from "../../../SideSheetDropdown";
import { govGuaranteeOptions } from "../../../../../../../../../../../../../../../services/FormElements/components/loanOptions";
import { StateAwareFieldChangeFunction } from "../../../../services/types";

export const GovernmentalGuaranteeDropdown = ({
  handleFieldChange,
  value = ""
}: GovernmentalGuaranteeDropdownProps) => {
  const { formatMessage } = useIntl();
  const handleChange = (newValue: string) => {
    const stateUpdates = [
      { value: newValue, stateColumnName: "gov_guarantee_program" },
      newValue
        ? { value: true, stateColumnName: "gov_guarantee" }
        : { value: false, stateColumnName: "gov_guarantee" },
      !newValue && {
        value: undefined,
        stateColumnName: "gov_guarantee_agency_program"
      }
    ];
    handleFieldChange(stateUpdates);
  };
  return (
    <SideSheetDropdown
      handleChange={handleChange}
      label={formatMessage({ id: "GOVERNMENTAL_GUARANTEE" })}
      options={govGuaranteeOptions}
      value={value}
    />
  );
};

interface GovernmentalGuaranteeDropdownProps {
  handleFieldChange: StateAwareFieldChangeFunction;
  value?: string;
}
