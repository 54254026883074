import React, { useState } from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { ConcatName } from "../../../../../../../../../../../../services/Entities";
import { prettyDate } from "../../../../../../../../../../../../services/DateTime";
import { EditButton } from "../../../../../../../../../../../../components/CustomFormElements";
import { SignerModal } from "../../../../../../../../../../../../components/Modals";
import useEditableRelationshipCopy from "../../../../../../../../../../../../components/Modals/components/RelationshipModals/hooks/useEditableRelationshipCopy";
import { RemoveNullKeys } from "../../../../../../../../../../../../services/ApiLib/services/Utilities";
import {
  EntityRelationships,
  Relationships
} from "../../../../../../../../../../../../services/ApiLib";
import logger from "../../../../../../../../../../../../services/logger";

function onError(rsp) {
  logger.error("Error: ", rsp);
}
export default function SignerTable({ authorizedSigner }) {
  const dispatch = useDispatch();
  const { entityUuid } = useSelector(state => ({
    entityUuid: state.CreditManagementReducer.entityData.uuid
  }));
  const [relationshipToEdit, setRelationshipToEdit] = useState(null);
  const [edits, makeEdit] = useEditableRelationshipCopy(relationshipToEdit);

  function handleRelationshipToEdit(relationship) {
    setRelationshipToEdit(relationship);
  }

  function handleEntityRelationshipUpdates(key, value) {
    makeEdit(key, value);
  }

  const updateEntityRelationship = async data => {
    const newData = RemoveNullKeys(data);
    const body = { ...newData };
    delete body.signer_entity_data;
    delete body.entity_data;
    delete body.relationship_uuid;

    try {
      await Relationships.asyncUpdate(data.relationship_uuid, body);
      const entityData = await EntityRelationships.asyncRead(
        entityUuid,
        "Authorized Signer"
      );
      dispatch({
        type: "CMS_REL_SET_AUTHORIZED_SIGNER",
        relationshipSigner: entityData.data
      });
    } catch (e) {
      onError(e);
    }
  };

  function handleSaveEntityRelationshipEdit(data) {
    updateEntityRelationship(data);
    handleRelationshipToEdit(null);
  }

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Signer Name</Table.HeaderCell>
            <Table.HeaderCell>Start Date</Table.HeaderCell>
            <Table.HeaderCell>End Date</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {authorizedSigner.map((signer, index) => (
            <Table.Row key={"signer".concat("", index)}>
              <Table.Cell>
                <a
                  href={`/credit_management/entity/${signer.signer_entity_data.uuid}`}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <i className="linkify icon" />
                  {ConcatName(signer.signer_entity_data)}
                </a>
              </Table.Cell>
              <Table.Cell>
                {signer.start_date ? prettyDate(signer.start_date) : ""}
              </Table.Cell>
              <Table.Cell>
                {signer.end_date ? prettyDate(signer.end_date) : ""}
              </Table.Cell>
              <Table.Cell collapsing>
                <EditButton
                  onClick={() => {
                    handleRelationshipToEdit(signer);
                  }}
                  aria-label="edit signer"
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {relationshipToEdit && edits && (
        <SignerModal
          form={edits}
          onClose={() => handleRelationshipToEdit(null)}
          onSave={() => handleSaveEntityRelationshipEdit(edits)}
          updateForm={(event, { name: key, value }) =>
            handleEntityRelationshipUpdates(key, value)
          }
        />
      )}
    </>
  );
}

SignerTable.propTypes = {
  authorizedSigner: PropTypes.arrayOf(PropTypes.object).isRequired
};
