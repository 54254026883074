export default function GenerateSelectOptions(model) {
  const selectOptions = { ...model };
  Object.keys(selectOptions).forEach(selectField => {
    selectOptions[selectField].forEach((option, i) => {
      selectOptions[selectField][i] = {
        key: option,
        value: option,
        text: option
      };
    });
  });
  return selectOptions;
}
