import _ from "lodash";

const initialState = {
  data: {},
  showDetailsModal: false
};

export default function CreditManagementDetailsReducer(state, action) {
  const safeState = state ?? initialState;
  switch (action.type) {
    case "CMS_DETAILS_SHOW_MODAL":
      return { ...safeState, showDetailsModal: action.showDetailsModal };

    case "CMS_DETAILS_UPDATE_DATA_VALUES": {
      const newData = _.cloneDeep(safeState.data);
      newData[action.name] = action.value;
      return { ...safeState, data: newData };
    }

    default:
      return safeState;
  }
}
