import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { Users } from "../../../../../../../../../../../../../services/ApiLib";
import { getInstitutionUuid } from "../../../../../../../../../../../../../services/Auth";
import { formatLoanOfficerOptions } from "./services/utils";
import { SideSheetDropdown } from "../SideSheetDropdown";
import { StateAwareFieldChangeFunction } from "../../services/types";

export const LoanOfficersDropdown = ({
  handleFieldChange,
  loanOfficer
}: LoanOfficersDropdownProps) => {
  const { formatMessage } = useIntl();
  const [loanOfficers, setLoanOfficers] =
    useState<{ text: string; value: string }[]>();
  const [selectedValue, setSelectedValue] = useState(loanOfficer);

  useEffect(() => {
    const fetchLoanOfficers = async () => {
      try {
        const { data } = await Users.asyncRead({
          institution_uuid: getInstitutionUuid(),
          has_lending_authority: true
        });
        const formattedUsersList = formatLoanOfficerOptions(data);
        setLoanOfficers(formattedUsersList);
      } catch (error) {
        console.error(errorFetchingLoanOfficersMessage, error);
      }
    };
    fetchLoanOfficers();
  }, []);

  const handleChange = (option: string) => {
    handleFieldChange([{ value: option, stateColumnName: "loan_officer" }]);
  };

  return (
    <SideSheetDropdown
      handleChange={handleChange}
      key={"LOAN_OFFICER"}
      label={formatMessage({ id: "LOAN_OFFICER" })}
      options={loanOfficers}
      required
      setSelectedValue={setSelectedValue}
      value={selectedValue}
    />
  );
};

interface LoanOfficersDropdownProps {
  handleFieldChange: StateAwareFieldChangeFunction;
  loanOfficer: string;
}

export const errorFetchingLoanOfficersMessage =
  "Error fetching loan officers: ";
