import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Table, Segment, Grid, Radio, Select } from "semantic-ui-react";
import { useTable, useRowSelect } from "react-table";
import PaginationControls from "../../../../../../../../../../components/Pagination";

function PaginatedTable({
  columns,
  filterOptions,
  data,
  onSelectedRowChange,
  handleViewArchives,
  rightButtons,
  leftButtons,
  tableName
}) {
  const { pagination } = useSelector(state => ({
    pagination: state.PaginationReducer[tableName]
  }));
  const [filterBy, setFilterBy] = useState("");

  const currentPageRows = useMemo(() => {
    if (!data) {
      return null;
    }

    if (!pagination) {
      return data;
    }
    const { size: pageSize, number: pageNumber } = pagination;
    const startIndex = pageNumber * pageSize;
    const endIndex =
      (pageNumber + 1) * pageSize < data.length
        ? (pageNumber + 1) * pageSize
        : data.length;

    return data.slice(startIndex, endIndex);
  }, [data, pagination]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds }
  } = useTable({ columns, data: currentPageRows }, useRowSelect);

  useEffect(() => {
    let selectedRows = [];

    if (Array.isArray(selectedFlatRows)) {
      selectedRows = selectedFlatRows.map(row => row.original);
    }

    onSelectedRowChange?.(selectedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSelectedRowChange, selectedRowIds]);

  return (
    <>
      <Segment>
        <Grid>
          <Grid.Row columns="2" verticalAlign="middle">
            <Grid.Column textAlign="left" width={6}>
              {leftButtons}
            </Grid.Column>
            <Grid.Column floated="right" textAlign="right" width={9}>
              {rightButtons}
              <span style={{ padding: "1em " }} />
              <Select
                onChange={v => setFilterBy(v)}
                options={filterOptions}
                placeholder="Filter by..."
                value={filterBy}
              />
              <span style={{ padding: "1em " }} />
              <Radio
                data-testid="view-archive-toggle"
                label="View Archives"
                onClick={handleViewArchives}
                toggle
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment>
        <Table compact celled selectable {...getTableProps()}>
          <Table.Header>
            {headerGroups.map(headerGroup => (
              <Table.Row {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Table.HeaderCell
                    textAlign={column.textAlign}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Header>
          <Table.Body {...getTableBodyProps()}>
            {rows.map(
              row =>
                prepareRow(row) || (
                  <Table.Row
                    data-testid="table-row"
                    {...row.getRowProps()}
                    positive={row.isSelected}
                  >
                    {row.cells.map(cell => (
                      <Table.Cell
                        data-testid={`period-cell-${cell.column.id}`}
                        textAlign={cell.column.textAlign}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                )
            )}
          </Table.Body>
        </Table>

        <PaginationControls length={data.length} name={tableName} />
      </Segment>
    </>
  );
}

PaginatedTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()),
  data: PropTypes.arrayOf(PropTypes.shape()),
  onSelectedRowChange: PropTypes.func,
  tableName: PropTypes.string.isRequired,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string
    })
  ),
  handleViewArchives: PropTypes.func,
  rightButtons: PropTypes.shape(),
  leftButtons: PropTypes.shape()
};

export default PaginatedTable;
