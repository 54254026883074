import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";
import ScheduleForm from "../ScheduleForm";

function NewModal(props) {
  const { onCloseNewModal, onSave, modalIsValid } = props;
  return (
    <Modal open={props.newModal} size="large">
      <Modal.Header>New Document Schedule</Modal.Header>
      <Modal.Content>
        <ScheduleForm docType={props.docType} />
      </Modal.Content>
      <Modal.Actions>
        <Button basic data-testid="close-new-modal" onClick={onCloseNewModal}>
          Cancel
        </Button>
        <Button
          disabled={!modalIsValid}
          color="green"
          data-testid="add-new-modal"
          onClick={() => onSave("newModal")}
        >
          Save and Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

NewModal.propTypes = {
  docType: PropTypes.string.isRequired,
  newModal: PropTypes.bool.isRequired,
  onCloseNewModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  modalIsValid: PropTypes.bool
};

const mapStateToProps = state => ({
  modalIsValid: state.DocTrackingViewReducer.modalIsValid
});

export default connect(mapStateToProps)(NewModal);
