import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";

export default function ScopeDropdown({
  readOnly,
  onChange,
  scopeOptions,
  value
}) {
  const displayValue = useMemo(() => {
    if (scopeOptions && value) {
      let nextDisplayValue = "N/A";
      for (let i = 0; i < scopeOptions.length; i += 1) {
        const scopeOption = scopeOptions[i];
        if (scopeOption.value === value) {
          nextDisplayValue = scopeOption.text;
          break;
        }
      }
      return nextDisplayValue;
    }
    return "Loading";
  }, [scopeOptions, value]);

  return readOnly ? (
    displayValue
  ) : (
    <Dropdown
      disabled={readOnly}
      fluid
      data-testid="scope-dropdown"
      selection
      name="scope"
      onChange={onChange}
      options={scopeOptions}
      placeholder="Scope"
      value={value}
    />
  );
}

ScopeDropdown.propTypes = {
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  scopeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string
    })
  ),
  value: PropTypes.string
};
