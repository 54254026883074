export const initialState = {
  selectedEntityUuid: "",
  selectedInstitution: ""
};

// eslint-disable-next-line default-param-last
export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case "ENT_MANAGEMENT_SELECT_ENTITY": {
      return { ...state, selectedEntityUuid: action.value };
    }
    case "ENT_MGMT_SELECT_INST": {
      return { ...state, selectedInstitution: action.value };
    }
    case "ENT_MGMT_CLEAR_ENTITY": {
      return { ...initialState };
    }
    default:
      return state;
  }
}
