import TypeCheck from "typecheck-extended";
import ApiEndpoints from "../ApiEndpoints";

export function FormatUrlV2(url, uuids, baseUrl = ApiEndpoints.baseUri) {
  TypeCheck(url, "string");
  TypeCheck(uuids, "object", false);
  TypeCheck(baseUrl, "string", false);

  if (!uuids) {
    return baseUrl + url;
  }

  let cpUrl = url;
  Object.keys(uuids).forEach(uuidType => {
    const replaceStr = `{${uuidType}}`;
    const uuidReplace = new RegExp(replaceStr);
    cpUrl = cpUrl.replace(uuidReplace, uuids[uuidType]);
  });
  return baseUrl + cpUrl;
}

export function FormatUrlBlastServices(
  url,
  uuids,
  baseUrl = ApiEndpoints.baseUri
) {
  TypeCheck(url, "string");
  TypeCheck(uuids, "object", false);
  TypeCheck(baseUrl, "string", false);

  if (!uuids) {
    return baseUrl + url;
  }

  let filter = "?";
  Object.keys(uuids).forEach(uuidType => {
    filter += `filter[${uuidType}]=${uuids[uuidType]}&`;
  });
  return baseUrl + url + filter.substring(0, filter.length - 1);
}

export default function formatUrl(url, uuid) {
  return url.replace(/{uuid}/g, uuid);
}

export function currentPage() {
  const url = window.location;
  const paths = url.pathname.split("/");
  const urlEnd = paths[paths.length - 1];
  return urlEnd;
}
