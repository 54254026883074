import { Menu, Segment } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { isBafs } from "../../../../../../services/Auth";

const menuItems = [
  {
    key: "App Details",
    href: uuid => `/los/${uuid}/phases/app_details`
  },
  {
    key: "Documents",
    href: uuid => `/los/${uuid}/phases/documents`
  },
  {
    key: "SettlementSheet",
    href: uuid => `/los/${uuid}/phases/settlement_sheet`
  },
  {
    key: "CreditMemo",
    href: uuid => `/los/${uuid}/phases/credit_memo`
  },
  {
    key: "Messages/Activity",
    href: uuid => `/los/${uuid}/phases/messages_activities`
  }
];

function PhasesPrimaryMenu({ loanAppUuid }) {
  const filterMenu = menuItems.filter(
    item => !(item.key === "SettlementSheet" && !isBafs())
  );

  return (
    <Segment>
      <Menu pointing secondary color="green">
        {filterMenu.map(item => (
          <Menu.Item
            as={NavLink}
            exact={false}
            to={item.href(loanAppUuid)}
            key={item.key}
            link
            name={item.key}
          />
        ))}
      </Menu>
    </Segment>
  );
}

export default PhasesPrimaryMenu;
