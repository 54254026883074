import React, { useState } from "react";
import { Modal, Button } from "semantic-ui-react";
import PropTypes from "prop-types";

import EntitySearch from "../../../../../../components/EntitySearch";

function AddGuarantorModal({
  open = false,
  institutionUuid,
  onCancel,
  onAddThirdPartyGuarantor
}) {
  const [guarantor, setGuarantor] = useState();
  const [errorMsg, setErrorMsg] = useState();

  return (
    <Modal open={open} size="small">
      <Modal.Header>Add Guarantor</Modal.Header>
      <Modal.Content>
        <b>Search TIN/Name</b>
        <br />
        {!errorMsg && institutionUuid ? (
          <EntitySearch
            onError={setErrorMsg}
            allowAdd
            selectedEntityCallback={setGuarantor}
            overrideInstitution={institutionUuid}
            institutionUuid={institutionUuid}
          />
        ) : (
          <div style={{ color: "red" }}>{errorMsg}</div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button basic id="cancelButton" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          primary
          disabled={!guarantor || errorMsg}
          onClick={() => {
            onAddThirdPartyGuarantor(guarantor);
            setGuarantor(null);
          }}
        >
          Add Guarantor
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

AddGuarantorModal.propTypes = {
  open: PropTypes.bool,
  institutionUuid: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onAddThirdPartyGuarantor: PropTypes.func.isRequired
};

export default AddGuarantorModal;
