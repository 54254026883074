import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";
import EditDialogContent from "./components/EditDialogContent";
import { EditButton } from "../../../../../../../../../../../../components/CustomFormElements";

/**
 * Dialog with action buttons. The actions are passed in as an array of React objects,
 * in this example [FlatButtons](/#/components/flat-button).
 *
 * You can also close this dialog by clicking outside the dialog, or with the 'Esc' key.
 */
class EditDialogClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.handleUndo = this.handleUndo.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleRestoreStateClear = this.handleRestoreStateClear.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleOpen() {
    this.setState({ open: true });
    this.handleRestoreStateStart();
  }

  handleRestoreStateStart() {
    this.props.dispatch({
      type: "DOCS_EDIT_START_RESTORE_STATE",
      data: this.props.docDetails
    });
  }

  handleRestoreStateClear() {
    this.props.dispatch({
      type: "DOCS_EDIT_RESET_RESTORE_STATE"
    });
  }

  handleUndo() {
    this.props.dispatch({
      type: "DOCS_EDIT_UNDO_TYPING",
      data: { uuid: this.props.docDetails.uuid }
    });
    this.handleClose();
  }

  handleClose() {
    this.setState({ open: false });
    this.handleRestoreStateClear();
  }

  handleChange({ value }, action, fieldName) {
    this.props.dispatch({
      type: action,
      inputValue: value,
      fieldName,
      uuid: this.props.docDetails.uuid
    });
  }

  handleSelectChange(field, { value }) {
    let fieldName = "doc_subcategory";
    if (field === "category") {
      fieldName = "doc_category";
    }
    this.props.dispatch({
      type: "DOCS_EDIT_SAVE_VALUE_TYPING",
      inputValue: value,
      fieldName,
      uuid: this.props.docDetails.uuid
    });
  }

  render() {
    return (
      <div>
        <EditButton onClick={this.handleOpen} />

        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          className="ui.modal"
        >
          <Modal.Header>Edit Document</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <EditDialogContent
                changeCallBack={this.handleChange}
                changeSelectCallBack={this.handleSelectChange}
                docDetails={this.props.docDetails}
              />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={this.handleUndo}>
              Undo Changes
            </Button>
            <Button positive onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

EditDialogClass.propTypes = {
  dispatch: PropTypes.func.isRequired,
  docDetails: PropTypes.shape({
    tsn: PropTypes.string,
    doc_category: PropTypes.string,
    description: PropTypes.string,
    doc_subcategory: PropTypes.string,
    upload_date: PropTypes.string,
    acct_no: PropTypes.string,
    uuid: PropTypes.string,
    branch_no: PropTypes.string,
    tax_id: PropTypes.string,
    approved: PropTypes.bool,
    name: PropTypes.string
  }).isRequired
};

const EditDialog = connect()(EditDialogClass);

export default EditDialog;
