import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Pagination,
  Checkbox,
  Message,
  Label
} from "semantic-ui-react";
import {
  DollarCell,
  DateCell,
  DefaultCell
} from "../../../../../../../../../../components/Tables";
import { isBafs } from "../../../../../../../../../../services/Auth";
import Resources from "../../../../components/Resources";
import AddScheduleItemModal from "./components/AddScheduleItemModal";
import EditScheduleItemModal from "./components/EditScheduleItemModal";

const noop = () => {};

function ScheduleList({
  paymentSchedules,
  isMonthEnd,
  pagination,
  error,
  onView,
  onCleanup,
  onAdd = noop,
  onEdit,
  onDelete,
  onPaginationChange,
  onReorderItem,
  onDismissError,
  tableOptions,
  onToggleChange
}) {
  const [open, setOpen] = useState(false);
  const [scheduleToEdit, setscheduleToEdit] = useState();
  const [activeFilter, setToggler] = useState(false);
  const [deletedFilter, setDeletedFilter] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "Status",
        accessor: "status",
        Cell: DefaultCell
      },
      {
        Header: "Payment Type",
        accessor: "payment_type",
        Cell: DefaultCell
      },
      {
        Header: "Next Payment Date",
        accessor: "next_payment_date",
        Cell: DateCell
      },
      {
        Header: "Next Scheduled Payment Amount",
        accessor: "next_payment_amount",
        Cell: DollarCell
      },
      {
        Header: "Payment Frequency",
        accessor: schedule =>
          schedule.payment_frequency && schedule.payment_frequency_type
            ? `${schedule.payment_frequency} ${schedule.payment_frequency_type}`
            : "—",
        Cell: DefaultCell
      },
      {
        Header: "Bills Remaining",
        accessor: "bills_remaining",
        Cell: DefaultCell
      },
      {
        Header: "Total No. of Payments",
        accessor: "total_number_of_payments",
        Cell: DefaultCell
      },
      {
        Header: "Order",
        id: "processing_order",
        accessor: "processing_order",
        show: false
      },
      {
        Header: "Posting Method",
        accessor: "posting_method",
        Cell: DefaultCell
      },
      {
        Header: "Schedule Start Date",
        accessor: "first_payment_date",
        Cell: DateCell
      }
    ],
    []
  );

  useEffect(() => {
    // This can be used to load data when the component mounts/unmounts
    if (onView) onView();
    return onCleanup;
  }, [onView, onCleanup]);

  const showPagination =
    pagination &&
    (pagination.number !== 0 ||
      (pagination.number === 0 && !pagination.isLastPage));

  const onSpawnEditModal = schedule => {
    setscheduleToEdit(schedule);
  };

  const addButton = isBafs() ? (
    <Button
      circular
      basic
      icon="add"
      data-testid="add-button"
      onClick={() => setOpen(true)}
    />
  ) : null;

  return isMonthEnd ? (
    <Resources.Layout heading="Schedule">
      {" "}
      <Label>Schedules not displayed for month-end snapshots.</Label>
    </Resources.Layout>
  ) : (
    <Resources.Layout heading="Schedule">
      <Resources.Header>
        {error && (
          <Message
            error
            header="Error"
            content={error}
            onDismiss={onDismissError}
          />
        )}
        {onToggleChange ? (
          <Checkbox
            toggle
            label="Include Completed Schedules"
            checked={activeFilter}
            onChange={() => {
              onToggleChange(
                {
                  include_completed: !activeFilter,
                  include_deleted: deletedFilter
                },
                { number: 0, size: 20 }
              );
              setToggler(!activeFilter);
            }}
          />
        ) : null}
        {onToggleChange ? (
          <Checkbox
            toggle
            label="Include Deleted Schedules"
            checked={deletedFilter}
            onChange={() => {
              onToggleChange(
                {
                  include_deleted: !deletedFilter,
                  include_completed: activeFilter
                },
                { number: 0, size: 20 }
              );
              setDeletedFilter(!deletedFilter);
            }}
          />
        ) : null}
        {addButton}
        <AddScheduleItemModal
          onSave={onAdd}
          open={open}
          defaultValues={scheduleToEdit}
          onClose={() => setOpen(false)}
        />
        {scheduleToEdit && (
          <EditScheduleItemModal
            header="Edit Payment Schedule Item"
            onSave={values => onEdit({ ...values })}
            defaultOpen
            data-testid="edit-modal"
            defaultValues={scheduleToEdit}
            onClose={() => setscheduleToEdit(undefined)}
          />
        )}
      </Resources.Header>
      <Resources.Table
        items={paymentSchedules}
        columns={columns}
        tableOptions={tableOptions}
        onDelete={isBafs() ? onDelete : null}
        onEdit={isBafs() ? onSpawnEditModal : null}
        onReorderItem={onReorderItem}
      />
      {showPagination ? (
        <Pagination
          boundaryRange={0}
          activePage={(pagination.number || 0) + 1}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          siblingRange={1}
          totalPages={
            (pagination.number || 0) + (pagination.isLastPage ? 1 : 2)
          }
          floated="right"
          onPageChange={(ev, { activePage }) => {
            onPaginationChange(
              { ...pagination, number: activePage - 1 },
              {
                include_completed: activeFilter,
                include_deleted: deletedFilter
              }
            );
          }}
        />
      ) : null}
    </Resources.Layout>
  );
}

ScheduleList.propTypes = {
  paymentSchedules: PropTypes.arrayOf(PropTypes.shape()),
  pagination: PropTypes.shape({
    number: PropTypes.number,
    size: PropTypes.number,
    isLastPage: PropTypes.bool
  }),
  tableOptions: PropTypes.shape(),
  error: PropTypes.string,
  onView: PropTypes.func,
  onCleanup: PropTypes.func,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onPaginationChange: PropTypes.func,
  onReorderItem: PropTypes.func,
  onDismissError: PropTypes.func,
  onToggleChange: PropTypes.func,
  isMonthEnd: PropTypes.bool
};

export default ScheduleList;
