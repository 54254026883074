import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import DocTypeSelects from "../../../../../../../../../../../../../../components/DocTypeSelectsSemantic";

function EditDialogContentObj(props) {
  return (
    <div>
      <DocTypeSelects
        docCat={props.docDetails.doc_category}
        docSubCat={props.docDetails.doc_subcategory}
        callbackFilterParamChange={props.changeSelectCallBack}
      />

      <Form>
        <Form.Group widths="equal">
          <Form.Input
            focus
            label="TIN"
            defaultValue={props.docDetails.tax_id}
            onChange={(event, data) =>
              props.changeCallBack(
                data,
                "DOCS_EDIT_SAVE_VALUE_TYPING",
                "tax_id"
              )
            }
          />
          <Form.Input
            focus
            label="Account No."
            defaultValue={props.docDetails.acct_no}
            onChange={(event, data) =>
              props.changeCallBack(
                data,
                "DOCS_EDIT_SAVE_VALUE_TYPING",
                "acct_no"
              )
            }
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            focus
            label="Entity Name"
            defaultValue={props.docDetails.name}
            onChange={(event, data) =>
              props.changeCallBack(data, "DOCS_EDIT_SAVE_VALUE_TYPING", "name")
            }
          />
          <Form.Input
            focus
            label="Tickler ID"
            defaultValue={props.docDetails.tsn}
            onChange={(event, data) =>
              props.changeCallBack(data, "DOCS_EDIT_SAVE_VALUE_TYPING", "tsn")
            }
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.TextArea
            label="Description"
            defaultValue={props.docDetails.description}
            onChange={(event, data) =>
              props.changeCallBack(
                data,
                "DOCS_EDIT_SAVE_VALUE_TYPING",
                "description"
              )
            }
          />
        </Form.Group>
      </Form>
    </div>
  );
}

EditDialogContentObj.propTypes = {
  changeCallBack: PropTypes.func.isRequired,
  changeSelectCallBack: PropTypes.func.isRequired,
  docDetails: PropTypes.shape({
    tsn: PropTypes.string,
    doc_category: PropTypes.string,
    description: PropTypes.string,
    doc_subcategory: PropTypes.string,
    upload_date: PropTypes.string,
    acct_no: PropTypes.string,
    uuid: PropTypes.string,
    branch_no: PropTypes.string,
    tax_id: PropTypes.string,
    approved: PropTypes.bool,
    name: PropTypes.string
  }).isRequired
};

const EditDialogContent = connect()(EditDialogContentObj);

export default EditDialogContent;
