import React, { useEffect } from "react";
import { Header } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { sortBy, filter } from "lodash";
import { ConcatName as getEntityName } from "../../../../services/Entities";
import { useLoanApplicationDocuments } from "../../../../store/models/loan-applications";
import EntityDocsTable from "./components/EntityDocsTable";
import { actionCreators } from "../../scenes/LoanOriginationStepper/reducer";
import sortWithDirection from "../../../../utils/sortWithDirection";

const documentRelTypes = [
  { relType: "borrowers", title: "Borrower", defaultCategory: "Credit" },
  { relType: "owners", title: "Owners", defaultCategory: "Credit" },
  {
    relType: "ownerGuarantors",
    title: "Owner/Guarantor",
    defaultCategory: "Credit"
  },
  {
    relType: "thirdPartyGuarantors",
    title: "thirdPartyGuarantors",
    defaultCategory: "Credit"
  },
  { relType: "affiliates", title: "Affiliate", defaultCategory: "Credit" },
  {
    relType: "holdingsSubsidiaries",
    title: "Holdings Subsidiaries",
    defaultCategory: "Credit"
  },
  { relType: "assets", title: "Collateral", defaultCategory: "Asset" },
  {
    relType: "loanRequests",
    title: "Loan Application",
    defaultCategory: "Loan"
  }
];

const typeToHeader = {
  borrowers: <Header className="document-header">Borrowers</Header>,
  affiliates: <Header className="document-header">Affiliates</Header>,
  holdingsSubsidiaries: (
    <Header className="document-header">Holdings & Subsidiaries</Header>
  ),
  owners: <Header className="document-header">Owners</Header>,
  ownerGuarantors: <Header className="document-header">Guarantors</Header>,
  thirdPartyGuarantors: (
    <Header className="document-header">Third Party Guarantors</Header>
  ),
  assets: <Header className="document-header mt-3">Assets</Header>,
  loanRequests: <Header className="document-header mt-3">Loan Requests</Header>
};

function Documents({ loanAppUuid, readOnly }) {
  const [
    {
      documents,
      loanApp,
      loanRequests,
      borrowers,
      owners,
      affiliates,
      ownerGuarantors,
      thirdPartyGuarantors,
      collaterals,
      analysts,
      docsReady,
      holdingsSubsidiaries
    },
    {
      retrieveLoanApplicationDocuments,
      updateLoanApplicationDocument,
      deleteLoanApplicationDocument
    }
  ] = useLoanApplicationDocuments({ loanAppUuid });
  const dispatch = useDispatch();
  useEffect(() => {
    if (loanApp && loanApp.uuid) {
      return;
    }
    dispatch(actionCreators.loadLoanApp(loanAppUuid));
  }, [loanApp, loanAppUuid, dispatch, collaterals]);

  const findEntity = (relType, entityUuid) => {
    switch (relType) {
      case "borrowers": {
        const entityRel = borrowers.find(b => b.child_uuid === entityUuid);
        return entityRel && entityRel.entity;
      }
      case "affiliates": {
        return affiliates.find(a => a.uuid === entityUuid);
      }
      case "holdingsSubsidiaries": {
        return holdingsSubsidiaries.find(a => a.uuid === entityUuid);
      }
      case "ownerGuarantors": {
        const entityRel =
          ownerGuarantors &&
          ownerGuarantors.find(b => b.entity_uuid === entityUuid);
        return entityRel && entityRel.entity;
      }
      case "thirdPartyGuarantors": {
        const entityRel =
          thirdPartyGuarantors &&
          thirdPartyGuarantors.find(b => b.entity_uuid === entityUuid);
        return entityRel && entityRel.entity;
      }
      case "assets": {
        const flatCollaterals = [].concat(...collaterals);
        const collateral = flatCollaterals.find(
          a => a.asset_uuid === entityUuid
        );
        return collateral && collateral.asset;
      }
      case "owners": {
        const owner = owners.find(o => o.owner_entity_data.uuid === entityUuid);
        return owner && owner.owner_entity_data;
      }
      case "loanRequests": {
        const loanRequest = loanRequests.find(a => a.uuid === entityUuid);
        return loanRequest;
      }
      default:
        return null;
    }
  };
  let count = 0;

  const hasShownHeader = {
    borrowers: false,
    assets: false,
    loanRequests: false
  };

  const getHeader = relType => {
    if (!hasShownHeader[relType]) {
      hasShownHeader[relType] = true;
      return typeToHeader[relType];
    }
    return null;
  };

  return documentRelTypes.map(({ relType, title, defaultCategory }) => {
    const entityDocMap = () => {
      if (relType === "loanRequests") {
        const sortedDocuments = {};
        // eslint-disable-next-line array-callback-return
        loanRequests.map(i => {
          sortedDocuments[i.uuid] = documents?.loanRequests[i.uuid];
        });
        return sortedDocuments;
      }
      return documents[relType];
    };

    const entityDocObj = entityDocMap();
    const sortedEntityDocMap = Object.keys(entityDocObj).sort((a, b) => {
      const current = findEntity(relType, a);
      const nextEntity = findEntity(relType, b);
      switch (relType) {
        case "loanRequests": {
          const dateA = new Date(current?.created_datetime).getTime();
          const dateB = new Date(nextEntity?.created_datetime).getTime();

          return sortWithDirection(dateA, dateB, "ascending");
        }
        case "assets": {
          return current?.description.localeCompare(nextEntity?.description);
        }
        default: {
          return getEntityName(current).localeCompare(
            getEntityName(nextEntity)
          );
        }
      }
    });

    return sortedEntityDocMap.map(entityUuid => {
      const docs = entityDocObj[entityUuid];
      const index = relType === "loanRequests" ? (count += 1) : 1;
      const entity = findEntity(relType, entityUuid);
      if (!entity) {
        return null;
      }
      const name =
        relType === "assets"
          ? entity.description
          : `${getEntityName(entity)} | ${entity.tin}`;
      const getSortedDocuments = () => {
        const unCategorized = filter(docs, o => o.docType === "Uncategorized");
        const categorizedDocuments = sortBy(
          filter(docs, o => o.docType !== "Uncategorized"),
          ["docType"]
        );
        return [...unCategorized, ...categorizedDocuments];
      };
      const header = getHeader(relType);
      if (!docsReady) {
        return null;
      }

      return (
        <div className="document-section" key={relType + entityUuid}>
          {header}
          <EntityDocsTable
            analysts={analysts}
            key={`${relType}-${entityUuid}`}
            title={`${title}: ${name}`}
            index={index}
            docs={getSortedDocuments()}
            relType={relType}
            defaultCategory={defaultCategory}
            entity={entity}
            institutionUuid={loanApp.institution_uuid || ""}
            parentUuid={entityUuid}
            readOnly={readOnly}
            onUploadSuccess={() =>
              retrieveLoanApplicationDocuments({ loanAppUuid })
            }
            onUpdateDoc={docDetails => {
              updateLoanApplicationDocument({ relType, ...docDetails });
              retrieveLoanApplicationDocuments({ loanAppUuid });
            }}
            onDeleteDoc={async docUuid => {
              await deleteLoanApplicationDocument({ uuid: docUuid });
              retrieveLoanApplicationDocuments({ loanAppUuid });
            }}
          />
        </div>
      );
    });
  });
}

Documents.propTypes = {
  loanAppUuid: PropTypes.string,
  readOnly: PropTypes.bool
};

export default Documents;
