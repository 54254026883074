import { MessageProps } from "../../../../../lib/types/text";
import { FormattedMessage } from "react-intl";

/**
 * When the user clicks on the label, the checkbox is toggled.
 * @see {@link https://fomantic-ui.com/modules/checkbox.html#toggle}
 */
export function Toggle({ message, ...props }: ToggleProps) {
  return (
    <div className="ui toggle checkbox">
      <input {...props} type="checkbox" />

      {message && (
        <label htmlFor={props.id}>
          <FormattedMessage {...message} />
        </label>
      )}
    </div>
  );
}

/**
 * A fomantic-ui toggle checkbox, optionally supports a label.
 */
export interface ToggleProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type" | "id"> {
  /** The required id of the toggle. */
  id: string;
  /** The label for the toggle. */
  message?: MessageProps;
}
