import PropTypes from "prop-types";
import React, { Component } from "react";
import TypeCheck from "typecheck-extended";
import { Form } from "formsy-semantic-ui-react";
import DocTitle from "../../../../components/DocTitle";

import Buttons from "../../components/buttons";
import { LongSelect } from "../../../../components/CustomFormElements";

import entityTypes from "../../../../services/Constants/entityTypes";
import {
  forceValue,
  NAICCodes,
  semanticUIStates,
  validationErrors,
  validationLabel
} from "../../../../services/FormElements";
import { stepList } from "../../services/constants";

export class BusinessInfoObj extends Component {
  constructor(props) {
    super(props);
    this.updateForm = this.updateForm.bind(this);
  }

  componentDidMount() {
    const { setCurrentPage } = this.props;
    setCurrentPage(1);
  }

  updateForm(event, { name, value }) {
    TypeCheck(name, "string");

    const { dispatchFormField } = this.props;
    dispatchFormField("businessInfo", name, value);
  }

  render() {
    const {
      businessInfo,
      currentPageIndex,
      formIsValid,
      loading,
      onSave,
      onWithdraw,
      onSaveAndNext,
      toggleFormValidation
    } = this.props;
    const hiddenStyle = {
      display: "none",
      height: "0px !important",
      margin: "0000 !important",
      padding: "0000 !important",
      width: "0px !important"
    };
    return (
      <div>
        <DocTitle
          title={`${stepList[1]} - Auto Decision Request - BLAST Portal`}
        />
        <h2>Business Information:</h2>
        <Form
          autoComplete="off"
          onInvalidSubmit={() => {
            toggleFormValidation(false);
          }}
          onValidSubmit={() => {
            toggleFormValidation(true);
          }}
        >
          <Form.Group widths="equal">
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Company Name"
              name="company_name"
              onChange={this.updateForm}
              required
              type="text"
              validationErrors={validationErrors}
              value={forceValue(businessInfo.company_name)}
            />
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Federial Tax ID/EIN"
              name="tin"
              onChange={this.updateForm}
              required
              type="text"
              validationErrors={validationErrors}
              value={forceValue(businessInfo.tin)}
            />
            <Form.Select
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Entity Type"
              name="entity_type"
              onChange={this.updateForm}
              options={entityTypes}
              required
              validationErrors={validationErrors}
              value={forceValue(businessInfo.entity_type)}
            />
          </Form.Group>

          <Form.Input
            errorLabel={validationLabel("below")}
            name="hidden_naics_field"
            required
            style={hiddenStyle}
            type="text"
            validationErrors={validationErrors}
            value={forceValue(businessInfo.naics_code)}
          />
          <Form.Group widths="equal">
            <LongSelect
              label="Industry Type"
              errorLabel={validationLabel()}
              required
              name="naics_code"
              onChange={this.updateForm}
              options={NAICCodes}
              value={forceValue(businessInfo.naics_code).toString()}
              validationError={
                forceValue(businessInfo.naics_code).toString().length < 2 &&
                !formIsValid
              }
            />
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Address"
              name="phy_address_line1"
              onChange={this.updateForm}
              required
              type="text"
              validationErrors={validationErrors}
              value={forceValue(businessInfo.phy_address_line1)}
            />
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Suite or Apt. #"
              name="phy_address_line2"
              onChange={this.updateForm}
              required={false}
              type="text"
              validationErrors={validationErrors}
              value={forceValue(businessInfo.phy_address_line2)}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="City"
              name="phy_address_city"
              onChange={this.updateForm}
              required
              type="text"
              validations="isAlphanumeric"
              validationErrors={validationErrors}
              value={forceValue(businessInfo.phy_address_city)}
            />
            <Form.Select
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="State"
              name="phy_address_state"
              onChange={this.updateForm}
              options={semanticUIStates}
              required
              search
              validations="isWords"
              validationErrors={validationErrors}
              value={forceValue(businessInfo.phy_address_state)}
            />
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Zip"
              name="phy_address_zip"
              onChange={this.updateForm}
              required
              type="text"
              validationErrors={validationErrors}
              validations="isInt"
              value={forceValue(businessInfo.phy_address_zip)}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Business Phone #"
              name="phone_no"
              onChange={this.updateForm}
              required
              type="text"
              validationErrors={validationErrors}
              value={forceValue(businessInfo.phone_no)}
            />
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Business Email"
              name="email"
              onChange={this.updateForm}
              required
              type="text"
              validationErrors={validationErrors}
              validations="isEmail"
              value={forceValue(businessInfo.email)}
            />
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Business Website"
              name="website"
              onChange={this.updateForm}
              required={false}
              type="text"
              validationErrors={validationErrors}
              value={forceValue(businessInfo.website)}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Principal Industry Experience"
              name="industry_experience"
              onChange={this.updateForm}
              required
              type="number"
              validationErrors={validationErrors}
              validations="isNumeric"
              value={forceValue(businessInfo.industry_experience)}
            />
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Years In Business"
              name="years_in_business"
              onChange={this.updateForm}
              required
              type="number"
              validationErrors={validationErrors}
              validations="isNumeric"
              value={forceValue(businessInfo.years_in_business)}
            />
            <Form.Select
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="State of Registration"
              name="state_of_reg"
              onChange={this.updateForm}
              options={semanticUIStates}
              required
              search
              validationErrors={validationErrors}
              value={forceValue(businessInfo.state_of_reg)}
            />
            <Form.Input
              autoComplete="off"
              disabled={loading}
              errorLabel={validationLabel()}
              instantValidation
              label="Gross Annual Revenue"
              name="annual_revenue"
              onChange={this.updateForm}
              required
              type="number"
              validationErrors={validationErrors}
              validations="isNumeric"
              value={forceValue(businessInfo.annual_revenue)}
            />
          </Form.Group>

          <Buttons
            currentPage={stepList[currentPageIndex]}
            currentPageIndex={currentPageIndex}
            disabled={loading}
            onSave={onSave}
            onSaveAndNext={onSaveAndNext}
            onWithdraw={onWithdraw}
          />
        </Form>
        <p>* Required</p>
      </div>
    );
  }
}

BusinessInfoObj.propTypes = {
  currentPageIndex: PropTypes.number.isRequired,
  businessInfo: PropTypes.shape({
    annual_revenue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    company_name: PropTypes.string,
    email: PropTypes.string,
    entity_type: PropTypes.string,
    industry_experience: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    naics_code: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    phone_no: PropTypes.string,
    phy_address_city: PropTypes.string,
    phy_address_line1: PropTypes.string,
    phy_address_line2: PropTypes.string,
    phy_address_state: PropTypes.string,
    phy_address_zip: PropTypes.string,
    state_of_reg: PropTypes.string,
    tin: PropTypes.string,
    website: PropTypes.string,
    years_in_business: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired,
  dispatchFormField: PropTypes.func.isRequired,
  formIsValid: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onSaveAndNext: PropTypes.func.isRequired,
  onWithdraw: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  toggleFormValidation: PropTypes.func.isRequired
};

const BusinessInfo = BusinessInfoObj;

export default BusinessInfo;
