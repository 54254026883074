import { ClearableDropdown, Field } from "@bafsllc/ui-shared";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";

export default function ApprovalAuthorityDropdown({
  onDropdownChange,
  selectedApprovalAuthority,
  isViewMode
}) {
  return (
    <Field labelMessage={{ id: "APPROVAL_AUTHORITY_REQUIRED" }} required>
      <ClearableDropdown
        id="approval_authority"
        onChildSelect={e => {
          onDropdownChange({
            name: "selectedApprovalAuthority",
            value: e.target.getAttribute("value")
          });
        }}
        selectedValue={{
          id:
            selectedApprovalAuthority?.split(" ").join("_").toUpperCase() ??
            "CREDIT_ANALYSIS_DROPDOWN_PLACEHOLDER"
        }}
        onClear={() => {
          onDropdownChange({
            name: "selectedApprovalAuthority",
            value: null
          });
        }}
        placeholder={{ id: "CREDIT_ANALYSIS_DROPDOWN_PLACEHOLDER" }}
        fill
        disabled={isViewMode}
      >
        <li key="committee_approval" value="Committee Approval">
          <FormattedMessage id="COMMITTEE_APPROVAL" />
        </li>
        <li key="loan_officer" value="Loan Officer Approval">
          <FormattedMessage id="LOAN_OFFICER_APPROVAL" />
        </li>
        <li key="board_approval" value="Board Approval">
          <FormattedMessage id="BOARD_APPROVAL" />
        </li>
      </ClearableDropdown>
    </Field>
  );
}

ApprovalAuthorityDropdown.propTypes = {
  onDropdownChange: PropTypes.func.isRequired,
  selectedApprovalAuthority: PropTypes.string.isRequired,
  isViewMode: PropTypes.bool.isRequired
};
