/* eslint-env browser */
import TC from "typecheck-extended";

import { RefreshToken, getToken } from "../../../Auth";
import downloadFile from "../downloadFile";
import logger from "../../../logger";

// TODO: [#5972] For an unknown reason this:
// import { ErrorReport } from '../../../ErrorReporter';
// is breaking a bunch of reducer/store dependent tests.
// For now this bypasses ErrorReport, so tests will succeed.
const toConsole = (thrownError, errorMsgForUser) => {
  if (thrownError) {
    logger.error(thrownError);
  }
  logger.error(errorMsgForUser);
};
export const ErrorReport = { toConsole };

/*
  This replaces FetchService which requires callbacks.
  As with FetchService, this service should not be used
  outside of an ApiLib.

  Example ApiLib usage.

    async function get(uuid, queryParams) {
      TypeCheck(uuid, 'string', false);
      TypeCheck(queryParams, 'object', false);

      let params = '';
      if (queryParams) {
        params = ConcatQueryParams(queryParams);
      }

      const url = FormatUrlV2(endpoint, uuids);
      if (queryParams) {
        url += ConcatQueryParams(queryParams);
      }

      const rsp = await asyncFetchService('GET', url);
      return rsp;
    }

  Example ApiLib Consumption

    libName.get()
      .then((rsp) => { handleRsp(rsp) })
k
*/

const errorsToConsole = error => {
  try {
    error.json().then(
      errorJSON => ErrorReport.toConsole(errorJSON, "asyncFetchService Error"),
      () => ErrorReport.toConsole(error, "asyncFetchService Error")
    );
  } catch (err) {
    ErrorReport.toConsole(error, "asyncFetchService Error");
  }
};

async function asyncFetchService(
  method,
  url,
  body = null,
  signal = null,
  suppressErrors = true
) {
  TC(method, "enum", true, ["DELETE", "GET", "PATCH", "POST", "PUT"]);
  TC(url, "string", true);
  TC(body, "object", false);
  TC(signal, "object", false);

  if (method !== "GET") RefreshToken();

  try {
    const response = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken()
      },
      ...(signal ? { signal } : {}),
      ...(body ? { body: JSON.stringify(body) } : {})
    });

    if ([200, 201, 204].includes(response.status)) return await response.json();

    if (typeof response === "object" && !response.ok) {
      if (!suppressErrors) throw response;
      const data = await response.json();
      ErrorReport.toConsole(data, "asyncFetchService Error");

      return {
        ...data,
        statusCode: response?.status,
        statusText: response?.statusText
      };
    }

    const errorData = await response.json();

    try {
      if (!suppressErrors) throw errorData;
      return errorsToConsole(errorData);
    } catch (err) {
      throw new Error(response);
    }
  } catch (error) {
    // TODO: [# ] Decide if we are ok with suppressing these errors to the console.
    if (!suppressErrors) throw error;
    errorsToConsole(error);
    return error;
  }
}

export async function asyncFetchFileService(
  method,
  url,
  body,
  callbackData,
  type
) {
  TC(method, "string");
  TC(url, "string");
  TC(body, "object", false);
  TC(callbackData, "object", false); // Returned (unprocessed) to success/error callbacks.
  TC(type, "string", false);

  const validMethods = ["GET", "POST", "PUT"];
  if (!validMethods.includes(method)) {
    throw new Error(`asyncFetchFileService Error: (${method}) is not allowed.`);
  }

  const fetchParams = {
    method,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json"
    }
  };

  if (body) {
    if (method === "GET") {
      throw new Error("FetchService Error: Body not allowed on GET Requests");
    }
    fetchParams.body = JSON.stringify(body);
  }
  let response;
  try {
    response = await fetch(url, fetchParams);
  } catch (e) {
    throw new Error(e);
  }

  if (!response.ok) {
    logger.error(response);
    throw new Error(
      `Unable to retrieve file. File UUID: ${callbackData.objectUuid}`
    );
  }
  return downloadFile({ response, callbackData, type });
}

export default asyncFetchService;
