import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  PropertyTable,
  PercentCell,
  DollarCell,
  DefaultCell,
  DateCell
} from "../../../../../../../../../../components/Tables";
import Constants from "../../../../../../../../../../services/Constants/strings";

function CurrentRateTable({ loan }) {
  const showNonAccruedInterestHeader =
    !!loan.interest_accrued_nonaccrual || loan.status === Constants.NON_ACCRUAL;
  const columns = useMemo(
    () => [
      {
        Header: "Current Rate",
        accessor: "interest_rate",
        Cell: PercentCell,
        primary: true
      },
      {
        Header: "Rate Type",
        accessor: "rate_type",
        Cell: DefaultCell
      },
      {
        Header: "Interest Rate Method",
        accessor: "interest_rate_method",
        Cell: DefaultCell
      },
      {
        Header: "Accrual Basis",
        accessor: "accrual_basis",
        Cell: DefaultCell
      },
      {
        Header: "Per Diem",
        accessor: "per_diem",
        Cell: DollarCell
      },
      {
        Header: "Accrued Interest",
        accessor: "interest_accrued",
        Cell: DollarCell
      },
      ...(showNonAccruedInterestHeader
        ? [
            {
              Header: "Non-Accrued Interest",
              accessor: "interest_accrued_nonaccrual",
              Cell: DollarCell
            }
          ]
        : []),
      {
        Header: "Accrued through date",
        accessor: "accrued_through_date",
        Cell: DateCell
      },
      {
        Header: "Interest Paid To Date",
        accessor: "interest_paid_to_date",
        Cell: DateCell
      }
    ],
    [showNonAccruedInterestHeader]
  );

  return <PropertyTable columnsConfig={columns} data={loan} />;
}

CurrentRateTable.propTypes = {
  loan: PropTypes.shape({
    interest_rate: PropTypes.number,
    rate_type: PropTypes.string,
    accrual_basis: PropTypes.string,
    per_diem: PropTypes.number,
    interest_accrued: PropTypes.number,
    interest_accrued_nonaccrual: PropTypes.number,
    status: PropTypes.string
  }).isRequired
};

export default CurrentRateTable;
