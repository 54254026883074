import PropTypes from "prop-types";
import React from "react";
import { Table, Header } from "semantic-ui-react";
import { FormatCurrency } from "num-format";
import { orderBy } from "lodash";
import UuidLookups from "../../../../../../../../../../../../components/UuidLookups";
import { prettyDate } from "../../../../../../../../../../../../services/DateTime";
import feeReadableReason from "./feeReadableReason";

function DisplayOnlyFeesTable({ fees }) {
  let filteredFee = fees.filter(fee => fee?.fee_type === "Display Only");
  filteredFee = orderBy(filteredFee, ["created_datetime"], ["desc"]);
  return (
    <div>
      <Header>Display Only Fees</Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell key="fee_amount">Fee Amount</Table.HeaderCell>
            <Table.HeaderCell key="fee_reason">Fee Reason</Table.HeaderCell>
            <Table.HeaderCell key="description">Description</Table.HeaderCell>
            <Table.HeaderCell key="created_by">Created By</Table.HeaderCell>
            <Table.HeaderCell key="created_datetime">
              Created Date
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {filteredFee.map(fee => (
            <Table.Row key={fee?.uuid}>
              <Table.Cell>
                {FormatCurrency(fee?.fee_amount ?? 0, false)}
              </Table.Cell>
              <Table.Cell>{feeReadableReason(fee)}</Table.Cell>
              <Table.Cell>
                {fee?.description?.length > 10
                  ? `${fee?.description.substring(0, 10)}...`
                  : fee?.description}
              </Table.Cell>
              <Table.Cell>
                <UuidLookups lookupType="user" uuid={fee?.created_by} />
              </Table.Cell>

              <Table.Cell>
                {fee?.created_datetime
                  ? prettyDate(fee?.created_datetime)
                  : "Not Found"}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}

DisplayOnlyFeesTable.propTypes = {
  fees: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DisplayOnlyFeesTable;
