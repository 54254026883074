import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  Table,
  Message,
  Loader,
  Dimmer,
  Segment
} from "semantic-ui-react";
import { Payments } from "../../../../../../services/ApiLib";
import PaymentType from "../../../../../../services/PaymentType";
import Constants from "../../../../../../services/Constants/strings";
import {
  dateFromString,
  getDateString,
  getUTCDate
} from "../../../../../../services/DateTime";
import {
  DateInput,
  NumberInput
} from "../../../../../../components/CustomFormElements";
import currenyFormatting from "../../../../../../services/CurrencyFormatting";
import { permCheck } from "../../../../../../services/Auth";
import PreviewTransactionResult from "./components/PreviewTransactionResult";

const details = {
  printAmt: Constants.AMOUNTS_PRINCIPAL_AMOUNT,
  intAmt: Constants.AMOUNTS_INTEREST_AMOUNT,
  lateFeeAmt: Constants.AMOUNTS_LATE_FEE_AMOUNT,
  curBal: Constants.AMOUNTS_CURRENT_BALANCE,
  tranAmt: Constants.AMOUNTS_TRANSACTION_AMOUNT
};

const membersChoiceInstitutionUuId = "f8722741-f8ca-4ddf-9a6c-333b53d2462d";

const currentDate = getDateString(new Date());
export class PaymentObj extends React.Component {
  constructor(props) {
    super(props);
    this.clearReceipt = this.clearReceipt.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onError = this.onError.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onNumberChange = this.onNumberChange.bind(this);
    this.updatePaymentReceipt = this.updatePaymentReceipt.bind(this);
    this.submitPayment = this.submitPayment.bind(this);
    this.getLoanInfo = this.getLoanInfo.bind(this);
    this.previewPaymentResponse = this.previewPaymentResponse.bind(this);
    this.state = {
      newReceipt: null,
      paymentOptions: [],
      disablePreviewSubmit: false,
      disableSubmit: false,
      hasPreviewCredentials: false
    };
  }

  componentDidMount() {
    const { advanceType, currentBalance, govGuaranteeAgencyProgram } =
      this.props;
    const nextPaymentOptions = PaymentType.getPaymentTypeOptions(
      advanceType,
      currentBalance,
      govGuaranteeAgencyProgram
    ).map(option => {
      const { Institution, ...nextOption } = option;
      return nextOption;
    });
    const hasPreviewCredentials =
      permCheck(["preview_rw_tx_v2"]) ||
      permCheck(["preview_rw_tx_v2_priv"]) ||
      permCheck(["admin"]);
    const hasPostV2Credentials = permCheck(["recalc_testing"]);
    this.setState({
      paymentOptions: nextPaymentOptions,
      hasPreviewCredentials,
      hasPostV2Credentials
    });
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch({
      type: "PAYMENT_REDUCER_RESET_STATE"
    });
  }

  onDateChange(paymentDate) {
    const { dispatch } = this.props;
    dispatch({
      type: "PAYMENT_REDUCER_UPDATE_DATE",
      paymentDate
    });
  }

  onError(error) {
    this.setState({ disableSubmit: false, disablePreviewSubmit: false });
    let errorMessage = error.error;
    if (error.error && typeof error.error === "object") {
      errorMessage = "Error Saving Transaction";
    }
    const { dispatch } = this.props;
    dispatch({
      type: "PAYMENT_REDUCER_UPDATE_ERROR",
      error: errorMessage || "Server Error: Could not submit payment"
    });
  }

  onInputChange(value, field) {
    const { dispatch } = this.props;
    dispatch({
      type: "PAYMENT_REDUCER_UPDATE_FIELD",
      field,
      value
    });
  }

  onNumberChange(value) {
    const { dispatch } = this.props;
    dispatch({
      type: "PAYMENT_REDUCER_UPDATE_AMOUNT",
      tran_amount: value
    });
  }

  async getLoanInfo() {
    const { loanUuid, dispatch } = this.props;
    try {
      const fetchedPayments = await Payments.asyncGet(loanUuid);
      if (fetchedPayments.data) {
        dispatch({
          type: "LOAN_MANAGEMENT/POPULATE_LOAN",
          payload: fetchedPayments.data
        });
      }
    } catch (err) {
      this.onError(err);
    }
  }

  /**
   * TODO: Add functionality to create receipt from backend, right now this will
   * redirect to transactionHistory
   */
  updatePaymentReceipt(rsp, field) {
    this.setState({ disableSubmit: false, disablePreviewSubmit: false });
    const receipt = {
      printAmt: rsp.data.PrinAmt,
      intAmt: rsp.data.IntAmt,
      lateFeeAmt: rsp.data.LateFeeAmt,
      curBal: rsp.data.CurBal,
      tranAmt: rsp.data.TranAmt
    };
    const { dispatch } = this.props;
    dispatch({
      type: "PAYMENT_REDUCER_UPDATE_FIELD",
      field,
      value: receipt
    });
    this.clearReceipt();
  }

  clearReceipt() {
    const { dispatch, displaySuccess } = this.props;
    dispatch({
      type: "PAYMENT_REDUCER_UPDATE_DISPLAY_SUCCESS",
      displaySuccess
    });
    this.getLoanInfo();
    dispatch({ type: "PAYMENT_REDUCER_RESET_FORM" });
  }

  async submitPayment() {
    this.setState({ disableSubmit: true, disablePreviewSubmit: true });
    const {
      form,
      loanUuid,
      enteredBy,
      loanInstitutionUuid,
      accruedThroughDate
    } = this.props;
    const { hasPostV2Credentials } = this.state;
    form.effective_date = dateFromString(form.effective_date);
    form.institution_uuid = loanInstitutionUuid;
    form.entered_by = enteredBy;

    const effectiveDateStr = dateFromString(form.effective_date);
    const accruedThroughDateStr = dateFromString(accruedThroughDate);

    if (
      loanInstitutionUuid === membersChoiceInstitutionUuId &&
      getUTCDate(-1, effectiveDateStr, "-") !== accruedThroughDateStr
    ) {
      this.onError({
        error:
          "Post transaction not allowed on Members Choice. Can only post non-backdated transactions"
      });
      return;
    }

    try {
      if (hasPostV2Credentials) {
        const newPayment = await Payments.asyncPreviewAdd(form, loanUuid, {
          write: true
        });
        this.setState({
          newReceipt: newPayment.data
        });
        this.clearReceipt();
      } else {
        const newPayment = await Payments.asyncAdd(form, loanUuid, {
          include_phx: false
        });
        this.updatePaymentReceipt(newPayment, "receipt");
      }
    } catch (err) {
      this.onError(err);
    }
  }

  async previewPaymentResponse() {
    this.setState({ disableSubmit: true, disablePreviewSubmit: true });
    const { form, loanUuid, enteredBy, loanInstitutionUuid } = this.props;
    form.effective_date = dateFromString(form.effective_date);
    form.institution_uuid = loanInstitutionUuid;
    form.entered_by = enteredBy;

    try {
      const newPayment = await Payments.asyncPreviewAdd(form, loanUuid, {
        write: false
      });
      this.setState({
        newReceipt: newPayment.data
      });
    } catch (err) {
      this.onError(err);
    }
  }

  render() {
    const {
      form,
      currentBalance,
      feesAccrued,
      receipt,
      displayReceipt,
      displaySuccess,
      availableBalance,
      error,
      accruedThroughDate
    } = this.props;
    form.effective_date = form.effective_date || currentDate;
    const {
      paymentOptions,
      disablePreviewSubmit,
      disableSubmit,
      hasPreviewCredentials,
      newReceipt,
      hasPostV2Credentials
    } = this.state;
    if (displaySuccess)
      return <Redirect from="payments" to="transaction-history" />;
    if (displayReceipt) {
      return (
        <Container fluid>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Header as="h1">Details</Header>
                </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {Object.keys(receipt).map(rowName => (
                <Table.Row key={rowName}>
                  <Table.Cell>
                    <Header size="small">{details[rowName]}</Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Header size="small">{receipt[rowName]}</Header>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <Button onClick={this.clearReceipt}>Make A New Payment</Button>
        </Container>
      );
    }
    return (
      <div>
        <Header as="h1">Transactions</Header>
        <Divider />
        <Container fluid>
          <Dimmer.Dimmable as={Segment}>
            <Grid>
              <Grid.Row>
                {error && (
                  <Grid.Column width="10">
                    <Message negative content={error} />
                  </Grid.Column>
                )}
              </Grid.Row>
              <Grid.Row columns="2">
                <Grid.Column width="4">
                  <Header size="medium">Current Balance:</Header>
                </Grid.Column>
                <Grid.Column width="4">
                  <Header size="medium">
                    {currenyFormatting(`${currentBalance}`)}
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="2">
                <Grid.Column width="4">
                  <Header size="medium">Available Balance:</Header>
                </Grid.Column>
                <Grid.Column width="4">
                  <Header size="medium">
                    {currenyFormatting(`${availableBalance || 0}`)}
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="2">
                <Grid.Column width="4">
                  <Header size="medium">Fees Accrued:</Header>
                </Grid.Column>
                <Grid.Column width="4">
                  <Header size="medium">
                    {currenyFormatting(`${feesAccrued || 0}`)}
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="2">
                <Grid.Column width="4">
                  <Header size="medium">Interest Accrued Through</Header>
                </Grid.Column>
                <Grid.Column width="4">
                  <Header size="medium">{accruedThroughDate}</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="2">
                <Grid.Column width="4" verticalAlign="middle">
                  <Header size="medium">Transaction Type:</Header>
                </Grid.Column>
                <Grid.Column width="4">
                  <Form.Select
                    onChange={(e, { value }) =>
                      this.onInputChange(value, "tran_code")
                    }
                    options={paymentOptions}
                    value={paymentOptions[form.tran_code]}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="2">
                <Grid.Column width="4" verticalAlign="middle">
                  <Header size="medium">Value</Header>
                </Grid.Column>
                <Grid.Column width="4">
                  <NumberInput
                    onChange={(e, { value }) => this.onNumberChange(value)}
                    decimalPlaces={2}
                    name="payment_amount"
                    value={form.tran_amount}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="2">
                <Grid.Column width="4" verticalAlign="middle">
                  <Header size="medium">Effective Date</Header>
                </Grid.Column>
                <Grid.Column width="4">
                  <DateInput
                    onChange={e => this.onDateChange(e.target.value)}
                    name="effective_date"
                    max={currentDate}
                    value={form.effective_date}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="2">
                <Grid.Column width="4" verticalAlign="middle">
                  <Header size="medium">Additional Comments</Header>
                </Grid.Column>
                <Grid.Column width="4">
                  <Form.Input
                    onChange={e =>
                      this.onInputChange(e.target.value, "description")
                    }
                    placeholder="Comments"
                    value={form.description}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="1">
                <Grid.Column width="4">
                  <Button
                    fluid
                    disabled={disableSubmit}
                    onClick={this.submitPayment}
                  >
                    {hasPostV2Credentials
                      ? "Post Transaction with New Recalc"
                      : "Submit Transaction"}
                  </Button>
                </Grid.Column>
                {hasPreviewCredentials && (
                  <Grid.Column width="4">
                    <Button
                      fluid
                      disabled={disablePreviewSubmit}
                      onClick={this.previewPaymentResponse}
                    >
                      Backdated Transaction Process Preview
                    </Button>
                  </Grid.Column>
                )}
              </Grid.Row>
              {newReceipt && Object.keys(newReceipt).length > 1 && (
                <>
                  <Grid.Row columns="1">
                    <Grid.Column width="8">
                      <Button
                        onClick={() =>
                          this.setState({
                            disablePreviewSubmit: false,
                            disableSubmit: false,
                            newReceipt: null
                          })
                        }
                      >
                        Clear Data
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns="1">
                    <Grid.Column width="8">
                      <PreviewTransactionResult
                        transactionPreviewData={newReceipt}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </>
              )}
            </Grid>
            <Dimmer active={disableSubmit}>
              <Loader />
            </Dimmer>
          </Dimmer.Dimmable>
        </Container>
      </div>
    );
  }
}

PaymentObj.propTypes = {
  currentBalance: PropTypes.number.isRequired,
  availableBalance: PropTypes.number.isRequired,
  accruedThroughDate: PropTypes.string.isRequired,
  feesAccrued: PropTypes.number.isRequired,
  advanceType: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  displayReceipt: PropTypes.bool.isRequired,
  displaySuccess: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    description: PropTypes.string,
    tran_amount: PropTypes.number,
    effective_date: PropTypes.string,
    tran_code: PropTypes.string,
    entered_by: PropTypes.string,
    institution_uuid: PropTypes.string
  }).isRequired,
  loanUuid: PropTypes.string.isRequired,
  loanInstitutionUuid: PropTypes.string.isRequired,
  enteredBy: PropTypes.string.isRequired,
  error: PropTypes.string,
  receipt: PropTypes.shape({
    printAmt: PropTypes.string,
    intAmt: PropTypes.string,
    lateFeeAmt: PropTypes.string,
    curBal: PropTypes.string,
    tranAmt: PropTypes.string
  }).isRequired,
  // Added just for v2 submission to test that it works, should be removed/merged into receipt
  // but details will be ironed out in a future ticket
  govGuaranteeAgencyProgram: PropTypes.string
};

const mapStateToProps = state => ({
  currentBalance: state.LoanManagementReducer.loan.current_balance,
  availableBalance: state.LoanManagementReducer.loan.available_balance,
  loanInstitutionUuid: state.LoanManagementReducer.loan.institution_uuid,
  accruedThroughDate: state.LoanManagementReducer.loan.accrued_through_date,
  feesAccrued: state.LoanManagementReducer.loan.fees_accrued,
  advanceType: state.LoanManagementReducer.loan.advance_type,
  displayReceipt: state.PaymentsReducer.displayReceipt,
  displaySuccess: state.PaymentsReducer.displaySuccess,
  error: state.PaymentsReducer.error,
  form: state.PaymentsReducer.form,
  receipt: state.PaymentsReducer.receipt,
  enteredBy: state.auth.userUuid,
  govGuaranteeAgencyProgram:
    state.LoanManagementReducer.loan.gov_guarantee_agency_program
});

const Payment = connect(mapStateToProps)(PaymentObj);

export default Payment;
