import { useUpdateLoanMutation } from "../../../../../../../../../../../../../services/RTKQuery/loans";
import { IconButton } from "@bafsllc/ui-shared";
import { Loan } from "../../../../../../../../../../../../../services/RTKQuery/ModelTypes/loan";

export const SaveButton = ({
  closeSideSheet,
  loanDetails,
  saveButtonEnabled
}: SaveButtonProps) => {
  const [updateLoan] = useUpdateLoanMutation();

  const filterNullValues = (obj: Partial<Loan>) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v !== null)
    );
  };

  return (
    <IconButton
      message={{ id: "SAVE" }}
      onClick={async () => {
        closeSideSheet();
        try {
          const filteredLoanDetails = filterNullValues(loanDetails);
          await updateLoan(filteredLoanDetails);
        } catch (err) {
          console.error(failedToUpdateLoanMessage, err);
        }
      }}
      variant="primary"
      size="tiny"
      disabled={!saveButtonEnabled}
    />
  );
};

interface SaveButtonProps {
  closeSideSheet: () => void;
  loanDetails: Partial<Loan>;
  saveButtonEnabled: boolean;
}

export const failedToUpdateLoanMessage = "Failed to update loan: ";
