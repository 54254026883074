import { environment } from "../../environments/environment";
import ApiEndpoints from "../../services/ApiEndpoints";

const { localBlastUrl, productionBlastUrl, localOnlyOfficeUrl } = ApiEndpoints;

// TODO get prod URL for blast-client legacy app
// https://github.com/bafsllc/blast-client/issues/2039
export const getBaseUrl = () =>
  process.env.NODE_ENV === "development" ? localBlastUrl : productionBlastUrl;

export const getCSIBaseUrl = () =>
  process.env.NODE_ENV === "development" && localBlastUrl;

/**
 * Get the protocol & domain for only office
 * @returns {string} The Only office URL
 */

export const getOnlyOfficeUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return localOnlyOfficeUrl;
  }
  const { hostname, protocol } = window.location;
  const domainParts = hostname.split(".");
  const tld = domainParts.pop();
  const primaryDomain = domainParts.pop();
  const domain = ["ds", primaryDomain, tld].join(".");
  return `${protocol}//${domain}`;
};

/**
 * Get the protocol & domain for a blast-client api application
 * @returns {string} The API URL
 */
export function getBlastAPIBaseUrl() {
  const { hostname, protocol } = window.location;
  const domainParts = hostname.split(".");
  const tld = domainParts.pop();
  const primaryDomain = domainParts.pop();
  const domain = ["api", primaryDomain, tld].join(".");
  return `${protocol}//${domain}`;
}

/**
 * Get the protocol & domain for a blast-client application.
 * @param {object} [options] Options to be used while constructing the URL.
 * @param {string} [options.protocol] If not provided the default protocol will be used.
 * @returns {string} The domain.
 */
export function getBlastClientApplicationBaseUrl(options) {
  const { protocol, hostname } = window.location;
  let domainParts = hostname.split(".");
  const isProduction = process.env.NODE_ENV === "production";

  if (!isProduction) {
    // This is localhost.
    if (domainParts.length === 1) {
      return `${protocol}//${hostname}`;
    }
    if (domainParts.length === 2) {
      domainParts.splice(0, 0, "x");
    }
    if (domainParts.length >= 3) {
      // Given a domain like `blast.localbuild.bafs` the output will be
      // `blast.localbuild.bafs`. Another example `tdecu.blast.bafsllc.com` the
      // output is `tdecu.portal.bafsllc.com`.
      let { length } = domainParts;
      let reverseOverrideParts = [];
      if (environment.blastClientDomain) {
        reverseOverrideParts = environment.blastClientDomain
          .split(".")
          .reverse();
        length =
          length < reverseOverrideParts.length
            ? reverseOverrideParts.length
            : length;
      }

      const reverseDomainParts = domainParts.reverse();

      // Start with the TLD and work towards the subdomains, that way any
      // subdomains in window.location beyond those in the environment variable
      // will be preserved.
      for (let i = 0; i < length; i += 1) {
        const dp = i < reverseDomainParts.length ? reverseDomainParts[i] : null;
        const op =
          i < reverseOverrideParts.length ? reverseOverrideParts[i] : null;
        if (i < reverseDomainParts.length) {
          reverseDomainParts.splice(i, 1, op || dp);
        } else {
          reverseDomainParts.push(op);
        }
      }

      domainParts = reverseDomainParts.reverse();
    }
  }

  return `${(options && options.protocol) || "https"}://${domainParts.join(
    "."
  )}`;
}

export default getBlastClientApplicationBaseUrl;
