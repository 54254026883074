import createAPIService from "../../../../FetchService/createAPIService";
import APIEndpoints from "../../../../ApiEndpoints";
import { getBlastClientApplicationBaseUrl } from "../../../../../components/BlastEmbed/getBaseUrl";

const baseUri = getBlastClientApplicationBaseUrl();

const service = createAPIService({
  baseUrl: baseUri,
  create: [APIEndpoints.financialAnalysisReport]
});

export default service;
