import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";

export const defaultSelectionContent = "Please make a selection";

function AnalysisSelection({
  analysisList,
  defaultAnalysis,
  onAnalysisChange
}) {
  const [selectedAnalysis, setSelectedAnalysis] = useState(
    defaultAnalysis || ""
  );

  const options = analysisList.map(analysis => ({
    key: analysis.uuid,
    value: analysis.uuid,
    text: `${
      analysis.startDate ? `${analysis.startDate} |` : ""
    } ${analysis.uuid.substring(0, 5)}`
  }));

  return (
    <div data-testid="analysis-selection">
      <Dropdown
        icon="chevron down"
        className="analysis-selection-dropdown"
        placeholder="Please make a selection"
        fluid
        name="analysisSelection"
        id="analysisSelection"
        data-testid="analysis-selection-dropdown"
        value={selectedAnalysis}
        options={options}
        selection
        onChange={(e, { value }) => {
          setSelectedAnalysis(value);
          onAnalysisChange(value);
        }}
      />
    </div>
  );
}

AnalysisSelection.propTypes = {
  analysisList: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      startDate: PropTypes.string
    })
  ),
  defaultAnalysis: PropTypes.string,
  onAnalysisChange: PropTypes.func
};

export default AnalysisSelection;
