import { ClearableDropdown, Field } from "@bafsllc/ui-shared";
import { PropTypes } from "prop-types";

export default function PrimaryBorrowerDropdown({ entityName }) {
  return (
    <Field labelMessage={{ id: "PRIMARY_BORROWER" }} required>
      <ClearableDropdown
        id="primary_borrower_dropdown"
        selectedValue={{
          defaultMessage: entityName
        }}
        placeholder={{ id: "CREDIT_ANALYSIS_DROPDOWN_PLACEHOLDER" }}
        fill
        disabled
      >
        <li name="entity_uuid">{entityName}</li>
      </ClearableDropdown>
    </Field>
  );
}

PrimaryBorrowerDropdown.propTypes = {
  entityName: PropTypes.string.isRequired
};
