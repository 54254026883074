import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import "react-pdf/dist/umd/Page/AnnotationLayer.css";
import { Button, Loader } from "semantic-ui-react";
import { Documents } from "../../../../../../services/ApiLib";
import {
  FILE_MIME_TYPES_MAP,
  getFileMimeType
} from "../../../../../../services/Constants/AllowedDocumentTypes";
import getFileBlobUrl from "../../../../../../services/FetchService/services/getFileBlobUrl";

export function DownloadFile({ docUuid, fileName }) {
  const getDocument = () => {
    const fileData = getFileMimeType(fileName);
    const callbackData = {
      fileName: `loan_document.${fileData.fileType}`,
      fileType: fileData.mimeType
    };
    Documents.download({ uuid: docUuid }, callbackData);
  };

  return (
    <Button color="green" onClick={getDocument}>
      Download File
    </Button>
  );
}

export const ViewerContainer = ({ children }) => {
  return (
    <div
      data-testid="view-container"
      className="bg-neutral-200 flex justify-center items-center h-100"
    >
      {children}
    </div>
  );
};

function PdfViewer({ uuid, fileName }) {
  const [docUrl, setDocUrl] = useState("");
  const [docUrlFetched, setDocUrlFetched] = useState(false);
  const { mimeType } = getFileMimeType(fileName);

  const isPdf = mimeType === FILE_MIME_TYPES_MAP.pdf.mimeType;

  const getUrl = useCallback(async () => {
    if (!docUrl && isPdf) {
      try {
        const result = await getFileBlobUrl(uuid, mimeType, fileName);

        setDocUrl(result);
      } finally {
        setDocUrlFetched(true);
      }
    }
  }, [docUrl, fileName, isPdf, mimeType, uuid]);

  const errorFetchingPdfUrl = isPdf && docUrlFetched && !docUrl;

  useEffect(() => {
    getUrl();
  }, [getUrl]);

  if (!isPdf || errorFetchingPdfUrl) {
    return (
      <ViewerContainer>
        <DownloadFile docUuid={uuid} fileName={fileName} />
      </ViewerContainer>
    );
  }

  if (!docUrl && isPdf) {
    return (
      <ViewerContainer>
        <Loader active>Loading PDF...</Loader>
      </ViewerContainer>
    );
  }

  return <iframe src={docUrl} />;
}

PdfViewer.propTypes = {
  uuid: PropTypes.string,
  fileName: PropTypes.string
};

DownloadFile.propTypes = {
  docUuid: PropTypes.string,
  fileName: PropTypes.string
};

ViewerContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default PdfViewer;
