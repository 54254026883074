import createCRUDSlice from "../../create-crud-slice";

const CRUD = createCRUDSlice("acctHistoryLogs", "uuid", { shortName: "logs" });

export const { actions, reducer, selectors, initialState } = CRUD;

export default {
  actions,
  reducer,
  selectors,
  initialState
};
