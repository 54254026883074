import React, { useState, useEffect } from "react";
import { func, string, bool, arrayOf, shape } from "prop-types";
import { Button, Modal, Form } from "semantic-ui-react";
import {
  DateInput,
  NumberInput
} from "../../../../../../../../components/CustomFormElements";

const defaultTransactionData = {
  effective_date: "",
  tran_code: "",
  principal_impact: "",
  interest_paid: "",
  fees_paid_amount: "",
  principal: "",
  tran_amount: ""
};

const checkFormInvalid = form =>
  Object.keys(defaultTransactionData).some(key => form[key] === "");

const getTransactionAmount = form =>
  (form.principal_impact || 0) +
  (form.interest_paid || 0) +
  (form.fees_paid_amount || 0);

function AddTransactionModal({ open, paymentOptions, onSubmit, onCancel }) {
  const [form, setForm] = useState(defaultTransactionData);
  useEffect(() => {
    setForm(defaultTransactionData);
  }, [open]);

  const handleChange = (event, { name, value }) => {
    setForm({ ...form, [name]: value });
  };

  const calculateTransactionAmount = () => {
    setForm({ ...form, tran_amount: getTransactionAmount(form) });
  };
  return (
    <Modal open={open} size="small" onClose={onCancel}>
      <Modal.Header>Post Transaction</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths="equal">
            <DateInput
              asForm
              onChange={handleChange}
              name="effective_date"
              label="Effective Date"
              value={form.effective_date}
            />
            <Form.Select
              label="Transaction Type"
              onChange={handleChange}
              options={paymentOptions}
              name="tran_code"
              value={paymentOptions[form.tran_code]}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <NumberInput
              onChange={handleChange}
              decimalPlaces={2}
              name="principal_impact"
              label="Principal Amount"
              onBlur={calculateTransactionAmount}
              value={form.principal_impact}
            />
            <NumberInput
              label="Interest Amount"
              onChange={handleChange}
              decimalPlaces={2}
              name="interest_paid"
              onBlur={calculateTransactionAmount}
              value={form.interest_paid}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <NumberInput
              onChange={handleChange}
              decimalPlaces={2}
              name="fees_paid_amount"
              label="Fee Amount"
              onBlur={calculateTransactionAmount}
              value={form.fees_paid_amount}
            />
            <NumberInput
              onChange={handleChange}
              decimalPlaces={2}
              name="principal"
              label="New Current Balance"
              value={form.principal}
            />
          </Form.Group>
          <Form.Group>
            <NumberInput
              onChange={() => {}}
              readOnly
              decimalPlaces={2}
              name="tran_amount"
              label="Transaction Amount"
              value={form.tran_amount}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel} basic>
          Cancel
        </Button>
        <Button
          color="orange"
          onClick={() => onSubmit(form)}
          disabled={checkFormInvalid(form)}
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default AddTransactionModal;

AddTransactionModal.propTypes = {
  open: bool,
  paymentOptions: arrayOf(
    shape({
      key: string,
      text: string,
      value: string,
      Institution: bool
    })
  ),
  onSubmit: func,
  onCancel: func
};
