import React from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu
} from "semantic-ui-react";
import FontAwesome from "react-fontawesome";
import { FormattedMessage } from "react-intl";

function ActionsCell({ row, column }) {
  const interestSchedule = row?.original;

  const onEdit = () => {
    column?.onClickEdit(interestSchedule);
  };

  return (
    <div className="flex justify-center">
      <Dropdown
        icon={<FontAwesome name="ellipsis-h" />}
        floating
        direction="left"
        className="ui tiny basic icon button"
      >
        <DropdownMenu className="floating ActionsCell-menu" direction="right">
          <DropdownItem
            data-testid="edit-interest-schedule-row-dropdown-item"
            onClick={onEdit}
          >
            <FormattedMessage id="EDIT" />
          </DropdownItem>
          <Divider className="bg-neutral-200 !m-0" />
          <DropdownItem
            data-testid="delete-interest-schedule-row-dropdown-item"
            onClick={() => {}}
          >
            <FormattedMessage id="DELETE" />
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default ActionsCell;

ActionsCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({})
  }),
  column: PropTypes.shape({
    onClickEdit: PropTypes.func
  })
};
