import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  DollarCell,
  PropertyTable
} from "../../../../../../../../../../components/Tables";

function IncomeTable({ loan }) {
  const rows = useMemo(
    () => [
      {
        Header: "Interest Income",
        accessor: "interest_paid_ltd",
        primary: true,
        Cell: DollarCell
      }
    ],
    []
  );
  return <PropertyTable columnsConfig={rows} data={{ ...loan }} />;
}

IncomeTable.propTypes = {
  loan: PropTypes.shape({}).isRequired
};

export default IncomeTable;
