import React, { useEffect, useMemo, useState } from "react";
import { Form, Label } from "semantic-ui-react";
import { func, shape } from "prop-types";
import { DollarField } from "../CustomFormElements";
import { forceValue, semanticUIStates } from "../../services/FormElements";
import { floodZones, floodInsuranceRequiredZones } from "./flood-zones";

function RealEstateForm({ realEstate, updateValue }) {
  const [deedTrustee, setDeedTrustee] = useState(
    realEstate.deed_trustee ? realEstate.deed_trustee : []
  );
  const setDeedsValue = ({ name, value }) => {
    const updatedTrustee = [{ ...deedTrustee[0], [name]: value }];
    setDeedTrustee(updatedTrustee);
    updateValue({ name: "deed_trustee", value: updatedTrustee });
  };

  const requiresFloodInsurance = useMemo(() => {
    if (floodInsuranceRequiredZones.indexOf(realEstate.flood_zone) > -1) {
      return true;
    }
    return false;
  }, [realEstate.flood_zone]);

  useEffect(() => {
    if (realEstate?.flood_zone) {
      updateValue({
        name: "flood_required",
        value: !!requiresFloodInsurance
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realEstate?.flood_zone, requiresFloodInsurance]);

  return (
    <Form>
      <h2> Real Estate Information:</h2>
      <DollarField
        label="Appraisal Amount"
        name="appraisal_amount"
        onChange={updateValue}
        value={realEstate?.appraisal_amount}
      />
      <Form.Input
        autoComplete="off"
        label="Address 1"
        name="address1"
        onChange={(ev, data) => updateValue(data)}
        value={forceValue(realEstate.address1)}
      />
      <Form.Input
        autoComplete="off"
        label="Address 2"
        name="address2"
        onChange={(ev, data) => updateValue(data)}
        value={forceValue(realEstate.address2)}
      />
      <Form.Input
        autoComplete="off"
        label="City"
        name="city"
        onChange={(ev, data) => updateValue(data)}
        value={forceValue(realEstate.city)}
      />
      <Form.Input
        autoComplete="off"
        label="County"
        name="county"
        onChange={(ev, data) => updateValue(data)}
        value={forceValue(realEstate.county)}
      />
      <Form.Select
        autoComplete="off"
        label="State"
        name="state"
        options={semanticUIStates}
        onChange={(ev, data) => updateValue(data)}
        value={forceValue(realEstate.state)}
      />
      <Form.Input
        autoComplete="off"
        label="Zip Code"
        name="zip_code"
        onChange={(ev, data) => updateValue(data)}
        value={forceValue(realEstate.zip_code)}
      />
      <Form.Input
        autoComplete="off"
        label="Zip Plus4"
        name="zip_plus4"
        onChange={(ev, data) => updateValue(data)}
        value={forceValue(realEstate.zip_plus4)}
      />
      <Form.TextArea
        name="legal_description"
        label="Legal Description"
        value={forceValue(realEstate?.legal_description)}
        onChange={(ev, data) => updateValue(data)}
        maxLength="4096"
        placeholder="Max length 4096"
      />

      <Form.Select
        className={requiresFloodInsurance && "!mb-0"}
        autoComplete="off"
        label="Flood Zone"
        name="flood_zone"
        options={floodZones}
        onChange={(ev, data) => updateValue(data)}
        value={forceValue(realEstate.flood_zone)}
      />

      {requiresFloodInsurance && (
        <div className="my-2">
          <Label basic color="blue">
            Flood Zone {realEstate.flood_zone} requires Flood Insurance.
          </Label>
        </div>
      )}

      <Form.Checkbox
        style={{
          ...(requiresFloodInsurance && { opacity: ".5" })
        }}
        label="Flood Insurance Required"
        name="flood_required"
        onChange={(ev, data) =>
          updateValue({ name: data.name, value: data.checked })
        }
        checked={realEstate?.flood_required || requiresFloodInsurance}
        readOnly={requiresFloodInsurance}
      />

      <Form.Checkbox
        label="Is Primary Residence"
        name="is_primary_residence"
        checked={realEstate?.is_primary_residence}
        onChange={(ev, data) =>
          updateValue({ name: data.name, value: data.checked })
        }
      />
      <>
        {" "}
        <h2 className="ui header"> Deed of Trust Trustee</h2>
        {realEstate && realEstate.deed_trustee && (
          <>
            <Form.Group>
              <Form.Input
                autoComplete="off"
                label="Name"
                name="deeds_trustee_first_name"
                onChange={(ev, data) => setDeedsValue(data)}
                value={forceValue(
                  realEstate?.deed_trustee[0]?.deeds_trustee_first_name
                )}
              />
              <Form.Input
                autoComplete="off"
                label="Primary Address Line"
                name="deeds_trustee_primary_address_line"
                onChange={(ev, data) => setDeedsValue(data)}
                value={forceValue(
                  realEstate?.deed_trustee[0]
                    ?.deeds_trustee_primary_address_line
                )}
              />
              <Form.Input
                autoComplete="off"
                label="City"
                name="deeds_trustee_city"
                onChange={(ev, data) => setDeedsValue(data)}
                value={forceValue(
                  realEstate?.deed_trustee[0]?.deeds_trustee_city
                )}
              />
              <Form.Select
                autoComplete="off"
                label="State"
                name="deeds_trustee_state"
                options={semanticUIStates}
                onChange={(ev, data) => setDeedsValue(data)}
                value={forceValue(
                  realEstate?.deed_trustee[0]?.deeds_trustee_state
                )}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                autoComplete="off"
                label="Postal Code"
                name="deeds_trustee_postalCode"
                onChange={(ev, data) => setDeedsValue(data)}
                value={forceValue(
                  realEstate?.deed_trustee[0]?.deeds_trustee_postalCode
                )}
              />
            </Form.Group>
          </>
        )}
      </>
    </Form>
  );
}

RealEstateForm.propTypes = {
  realEstate: shape().isRequired,
  updateValue: func.isRequired
};
export default RealEstateForm;
