import React, { useEffect, useState } from "react";
import {
  useLoanAppSettlementSheetQuery,
  LoanAppSettlementSheetParams
} from "../../../../../../services/RTKQuery/loanAppSettlementSheet";

export const ReadSingleTest = ({ loanUuid, itemUuid, setStatusInParent }) => {
  const statusUnknown = "❓Unknown Status";
  const statusPass = "✅ Pass";
  const statusFail = "❌ Fail";
  const statusLoading = "Loading ...";

  const [statusReadSingle, setStatus] = useState(statusUnknown);
  const [detailsText, setDetailsText] = useState("");

  const filters: LoanAppSettlementSheetParams = {
    uuids: {
      loan_uuid: loanUuid,
      item_uuid: itemUuid
    }
  };

  const { data: loanAppSettlementSheetData } =
    useLoanAppSettlementSheetQuery(filters);
  useEffect(() => {
    setStatus(statusLoading);
    if (
      loanAppSettlementSheetData?.uuid &&
      itemUuid === loanAppSettlementSheetData?.uuid
    ) {
      setStatus(statusPass);
      setDetailsText(
        `:  Matched record on: ${loanAppSettlementSheetData?.uuid}`
      );
      setStatusInParent("Pass");
    } else {
      console.error("Fail: useLoanAppSettlementSheetQuery");
      setStatus(statusFail);
    }
  }, [itemUuid, loanAppSettlementSheetData?.uuid, setStatusInParent]);

  return (
    <>
      <b>{statusReadSingle}</b>
      <small>{detailsText}</small>
    </>
  );
};
