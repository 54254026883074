export function logoutWarningText(minToExpiration: number) {
  /*
       A previous version gave users a countdown clock, but
       having sessionCheckInterval on a 1 sec delay triggers a
       "webpage is using significant energy" notice in Safari.
    */
  const minRemainingRoundedDown = Math.floor(minToExpiration);
  // TODO: [#6567] This text is outside of a translation file.
  const mainText =
    "Due to inactivity, you will be automatically logged out in ";
  let timeMessage = `${minRemainingRoundedDown} minutes.`;
  if (minRemainingRoundedDown < 1) {
    timeMessage = "less than a minute.";
  } else if (minRemainingRoundedDown === 1) {
    timeMessage = "1 minute.";
  }
  return mainText + timeMessage;
}

export function msToFractionalMin(ms: number, precision: number = 2) {
  const secs = ms / 1000;
  const fractionalMin = secs / 60;
  const roundedMin = parseFloat(fractionalMin.toString()).toFixed(precision);
  return Number(roundedMin);
}
