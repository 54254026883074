import classNames from "classnames";
import React from "react";

/** Returns a
 *  Panel */
export function Panel({
  children,
  className
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={classNames(
        "panel border rounded p-4 border-neutral-300 mb-4",
        className
      )}
      data-testid="panel-container"
    >
      <div className="p-4">{children}</div>
    </div>
  );
}
