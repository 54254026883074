import React from "react";
import { Route } from "react-router-dom";

import AssetManagement from "./scenes/AssetManagement";

function AssetManagementObj() {
  return (
    <div>
      <Route
        path="/asset_management/:uuid"
        render={({ match }) => (
          <AssetManagement assetUuid={match.params.uuid} />
        )}
      />
    </div>
  );
}

export default AssetManagementObj;
