import { useEffect } from "react";
import PropTypes from "prop-types";

/**
 * Update the title of the HTML document.
 * @param {DocTitleProps} props
 * @returns {JSX.Element || null}
 */
export default function DocTitle({ children, title }) {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return children || null;
}

DocTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object
  ]),
  title: PropTypes.string
};

/**
 * @typedef DocTitleProps
 * @property {JSX.Element} [children]
 * @property {string} title
 */
