/* eslint-disable camelcase */
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Divider } from "semantic-ui-react";
import BorderedContainer from "../../../../components/CustomUIElements/components/BorderedContainer";
import BorrowerTabs from "./components/BorrowerTabs";
import PersonalDetails from "./components/PersonalDetails";
import BasicInformation from "./components/BasicInformation";
import ContactInfo from "./components/ContactInfo";
import CreditInfo from "./components/CreditInfo";
import EntityRelationshipsList from "./components/EntityRelationshipsList";
import { relationshipTypes } from "../../../../services/Constants/relationshipTypes";

function EntityDetailsView({
  borrowers,
  selectedBorrowerUuid,
  owners,
  officers,
  otherCapacities,
  affiliates,
  holdingsSubsidiaries,
  readOnly,

  onSelectedBorrowerUuidChange,
  onAddBorrower,
  onBasicInfoEdit,
  onContactInfoEdit,
  onCreditInfoEdit,

  onAddEntityRelationship,
  onEditEntityRelationship,
  onRemoveEntityRelationship
}) {
  useEffect(() => {
    const primary = borrowers.find(b => b.primary_borrower);
    if (primary && onSelectedBorrowerUuidChange) {
      onSelectedBorrowerUuidChange(primary.uuid);
    }
  }, [borrowers, onSelectedBorrowerUuidChange]);

  const selectedBorrower = borrowers.find(b => b.uuid === selectedBorrowerUuid);
  if (!selectedBorrower || !selectedBorrower.entity) {
    return null; // TODO: render spinner maybe?
  }
  return (
    <BorderedContainer fluid>
      <BorrowerTabs
        borrowers={borrowers}
        selectedBorrower={selectedBorrower}
        readOnly={readOnly}
        onSelectedBorrowerChange={({ uuid }) =>
          onSelectedBorrowerUuidChange(uuid)
        }
        onAddBorrower={onAddBorrower}
      />
      <PersonalDetails entity={selectedBorrower.entity} readOnly={readOnly} />
      <BasicInformation
        entity={selectedBorrower.entity}
        readOnly={readOnly}
        onBasicInfoEdit={onBasicInfoEdit}
      />

      <Divider />

      <ContactInfo
        entity={selectedBorrower.entity}
        readOnly={readOnly}
        onContactInfoEdit={onContactInfoEdit}
      />

      <Divider />

      <CreditInfo
        entity={selectedBorrower.entity}
        readOnly={readOnly}
        onCreditInfoEdit={onCreditInfoEdit}
      />

      <Divider />

      <EntityRelationshipsList
        heading="Owners"
        singularLabel="Owner"
        entityRelationships={owners}
        entityKey="owner_entity_data"
        readOnly={readOnly}
        renderSummary={entityRelationship =>
          `Ownership Percentage: ${
            entityRelationship.percent_owned
              ? entityRelationship.percent_owned * 100
              : "--"
          }%`
        }
        onAddEntityRelationship={() =>
          onAddEntityRelationship(relationshipTypes.OWNER)
        }
        onEditEntityRelationship={owner =>
          onEditEntityRelationship(owner, relationshipTypes.OWNER)
        }
        onRemoveEntityRelationship={onRemoveEntityRelationship}
      />

      <Divider />

      <EntityRelationshipsList
        heading="Officers"
        singularLabel="Officer"
        entityRelationships={officers}
        entityKey="officer_entity_data"
        readOnly={readOnly}
        renderSummary={entityRelationship =>
          `Title: ${entityRelationship.title || "--"}`
        }
        onAddEntityRelationship={() =>
          onAddEntityRelationship(relationshipTypes.OFFICER)
        }
        onEditEntityRelationship={officer =>
          onEditEntityRelationship(officer, relationshipTypes.OFFICER)
        }
        onRemoveEntityRelationship={onRemoveEntityRelationship}
      />

      <Divider />

      <EntityRelationshipsList
        heading="Other Capacities"
        singularLabel="Other Capacity"
        entityRelationships={otherCapacities}
        entityKey="entity_data"
        readOnly={readOnly}
        createUniqueKey={rel => `${rel.title}-${rel.entity_data.uuid}`}
        renderSummary={entityRelationship =>
          `Relationship Type: ${entityRelationship.title || "--"}`
        }
        onAddEntityRelationship={() =>
          onAddEntityRelationship(relationshipTypes.OTHER_CAPACITIES)
        }
        onEditEntityRelationship={owner =>
          onEditEntityRelationship(owner, relationshipTypes.OTHER_CAPACITIES)
        }
        onRemoveEntityRelationship={onRemoveEntityRelationship}
      />

      <Divider />

      <EntityRelationshipsList
        heading="Affiliates"
        singularLabel="Affiliate"
        entityRelationships={affiliates}
        readOnly
      />

      <Divider />

      <EntityRelationshipsList
        heading="Holdings & Subsidiaries"
        singularLabel="Holding & Subsidiary"
        entityRelationships={holdingsSubsidiaries}
        readOnly
      />
    </BorderedContainer>
  );
}

EntityDetailsView.propTypes = {
  borrowers: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      primary_borrower: PropTypes.bool,
      entity: PropTypes.shape({
        entity_type: PropTypes.string
      })
    })
  ).isRequired,
  selectedBorrowerUuid: PropTypes.string,
  owners: PropTypes.arrayOf(PropTypes.shape({})),
  officers: PropTypes.arrayOf(PropTypes.shape({})),
  otherCapacities: PropTypes.arrayOf(PropTypes.shape({})),
  affiliates: PropTypes.arrayOf(PropTypes.shape({})),
  holdingsSubsidiaries: PropTypes.arrayOf(PropTypes.shape({})),
  readOnly: PropTypes.bool,

  onSelectedBorrowerUuidChange: PropTypes.func,
  onAddBorrower: PropTypes.func,
  onBasicInfoEdit: PropTypes.func,
  onContactInfoEdit: PropTypes.func,
  onCreditInfoEdit: PropTypes.func,

  onAddEntityRelationship: PropTypes.func,
  onEditEntityRelationship: PropTypes.func,
  onRemoveEntityRelationship: PropTypes.func
};

export default EntityDetailsView;
