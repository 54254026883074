import { pickBy, get } from "lodash";
import logger from "../../../services/logger";

const actionTypes = ["create", "retrieve", "update", "patch", "delete"];

const successMap = {
  create: "created",
  retrieve: "updated",
  update: "updated",
  delete: "deleted",
  patch: "updated"
};

const filterNullishProps = obj =>
  pickBy(obj, value => value !== undefined && value !== null);

const createServiceMiddleware =
  servicesMap =>
  ({ dispatch }) =>
  next =>
  action => {
    next(action);

    // if action is an error, don't process it here
    if (action.error) {
      return;
    }

    const isCRUDAction = actionTypes.some(type =>
      action.type.toLowerCase().endsWith(`/${type}`)
    );
    const { meta: { service: specialAction, ...meta } = {} } = action;

    if (isCRUDAction || specialAction) {
      const base = action.type.split("/");
      const type = base.pop();
      const baseActionType = base.join("/");
      const serviceKey = base.join(".");
      const service = get(servicesMap, serviceKey);

      if (service) {
        const { data: body, identifiers } = action.payload;
        const args =
          type === "create" || type === "update" || type === "patch"
            ? [body, identifiers, meta]
            : [identifiers, meta];

        const serviceResult =
          specialAction && service[specialAction]
            ? service[specialAction](action.payload, meta)
            : service[type](...args);
        // eslint-disable-next-line consistent-return
        return Promise.resolve(serviceResult)
          .then((result = {}) => {
            if (Array.isArray(result)) {
              dispatch(
                filterNullishProps({
                  type: `${baseActionType}/${
                    successMap[type] || `${type}_success`
                  }`,
                  payload: result,
                  meta: null,
                  originAction: action
                })
              );
            } else {
              const { data, metaData, ...root } = result;
              dispatch(
                filterNullishProps({
                  type: `${baseActionType}/${
                    successMap[type] || `${type}_success`
                  }`,
                  payload: data || root,
                  meta: metaData,
                  originAction: action
                })
              );
            }
          })
          .catch(reason => {
            if (process.env.NODE_ENV === "development") {
              logger.error(
                `createServiceMiddleware: '${baseActionType}/${
                  specialAction ? `${type}_error` : type
                }'; caught error=`,
                reason
              );
            }
            dispatch(
              filterNullishProps({
                type: `${baseActionType}/${
                  specialAction ? `${type}_error` : type
                }`,
                payload:
                  reason.error || reason.detail || reason.message || reason,
                meta: reason.metaData,
                error: true,
                originAction: action
              })
            );
          });
      }
    }
  };

export default createServiceMiddleware;
