import Constants from "../../../../../services/Constants/strings";

export const ACTIVE_ITEM_TO_ROUTE = {
  [Constants.LOANS]: "loans",
  [Constants.DETAILS]: "details",
  [Constants.RELATIONSHIPS]: "relationships",
  [Constants.ASSETS]: "assets",
  [Constants.CREDIT_DOCUMENTS]: "credit-documents",
  [Constants.CREDIT_REQUEST]: "credit-request",
  [Constants.DOCUMENT_TRACKING]: "document-tracking",
  [Constants.FINANCIAL_STATEMENTS]: "financial-statements",
  [Constants.BORROWER_PORTAL_LINKS]: "borrower-portal-links"
};

export const ROUTE_TO_ACTIVE_ITEM = {
  loans: Constants.LOANS,
  details: Constants.DETAILS,
  relationships: Constants.RELATIONSHIPS,
  assets: Constants.ASSETS,
  "credit-documents": Constants.CREDIT_DOCUMENTS,
  "credit-request": Constants.CREDIT_REQUEST,
  "document-tracking": Constants.DOCUMENT_TRACKING,
  "financial-statements": Constants.FINANCIAL_STATEMENTS,
  "borrower-portal-links": Constants.BORROWER_PORTAL_LINKS
};

export const PAGE_SIZE_OPTIONS = [5, 10, 25, 50, 100];
