import React from "react";
import PropTypes from "prop-types";

import { Modal, Button } from "semantic-ui-react";
import { ConcatName } from "../../../../../../services/Entities";

function DeleteGuarantorModal({
  open,
  guarantor,
  onCancel,
  onDeleteThirdPartyGuarantor
}) {
  if (!guarantor) {
    return null;
  }
  return (
    <Modal open={open}>
      <Modal.Header>Delete Third Party Guarantor</Modal.Header>
      <Modal.Content>
        <div>
          {`Are you sure you want to delete
          ${ConcatName(guarantor.entity)}
          as a third party guarantor?`}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="button1"
          color="red"
          onClick={() => onDeleteThirdPartyGuarantor(guarantor)}
        >
          Delete
        </Button>
        <Button basic id="button2" onClick={onCancel}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

DeleteGuarantorModal.propTypes = {
  open: PropTypes.bool,
  guarantor: PropTypes.shape({ entity: PropTypes.shape({}) }),
  onCancel: PropTypes.func,
  onDeleteThirdPartyGuarantor: PropTypes.func
};

export default DeleteGuarantorModal;
