import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Header, Table } from "semantic-ui-react";
import { FormatCurrency } from "num-format";
import moment from "moment";

function LoanPremiumDiscountDetails({
  loanPremiumDiscount,
  loanPremiumDiscountHistory
}) {
  // ideally we'd use the is_month_end
  const loanPremiumDiscountHistoryEOM = useMemo(() => {
    const lastMonthDays = loanPremiumDiscountHistory
      ? loanPremiumDiscountHistory.reduce(
          (months, entry) => ({
            ...months,
            [entry.transaction_date.substr(0, 7)]:
              months[entry.transaction_date.substr(0, 7)] || entry
          }),
          {}
        )
      : {};
    return Object.values(lastMonthDays);
  }, [loanPremiumDiscountHistory]);

  if (loanPremiumDiscount) {
    return (
      <>
        <Table basic compact>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Header as="h5">Type:</Header>
                {loanPremiumDiscount.category}
              </Table.Cell>
              <Table.Cell>
                <Header as="h5">Premium Discount Amount:</Header>
                {FormatCurrency(
                  loanPremiumDiscount.premium_discount_starting_amount,
                  false
                )}
              </Table.Cell>
              <Table.Cell>
                <Header as="h5">Balance:</Header>
                {FormatCurrency(
                  loanPremiumDiscount.premium_discount_balance,
                  false
                )}
              </Table.Cell>
              <Table.Cell>
                <Header as="h5">Amortization Calculation Method:</Header>
                Average Life
              </Table.Cell>
              <Table.Cell>
                <Header as="h5">Amortization Start Date:</Header>
                {moment(loanPremiumDiscount.amortization_start_date).format(
                  "L"
                )}
              </Table.Cell>
              <Table.Cell>
                <Header as="h5">Amortization End Date:</Header>
                {moment(loanPremiumDiscount.amortization_end_date).format("L")}
              </Table.Cell>
              <Table.Cell>
                <Header as="h5">Daily Per Diem:</Header>
                {FormatCurrency(loanPremiumDiscount.per_diem, false)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Month</Table.HeaderCell>
              <Table.HeaderCell>Earned Premium</Table.HeaderCell>
              <Table.HeaderCell>Unearned Premium</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {loanPremiumDiscountHistoryEOM &&
              loanPremiumDiscountHistoryEOM.map(entry => (
                <Table.Row key={entry.transaction_date}>
                  <Table.Cell>
                    {moment(entry.transaction_date).format("MMMM YYYY")}
                  </Table.Cell>
                  <Table.Cell>
                    {FormatCurrency(entry.earned_mtd, false)}
                  </Table.Cell>
                  <Table.Cell>
                    {FormatCurrency(entry.ending_balance, false)}
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </>
    );
  }
  return null;
}

LoanPremiumDiscountDetails.propTypes = {
  loanPremiumDiscount: PropTypes.shape(),
  loanPremiumDiscountHistory: PropTypes.arrayOf(PropTypes.shape())
};

export default LoanPremiumDiscountDetails;
