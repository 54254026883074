import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import FetchService, { asyncFetchService } from "../../../FetchService";
import {
  ConcatQueryParams,
  PaginationParams,
  RemoveNullKeys
} from "../Utilities";
import { FormatUrlV2 } from "../../../FormatUrl";
import logger from "../../../logger";

function get(onSuccess, onError, pagination, uuid, queryParams, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string", false);
  TypeCheck(queryParams, "object", false);

  if (uuid && queryParams) {
    onError("Error: Invalid Filter Combination");
  }

  let appendToUrl = "";
  if (uuid) {
    appendToUrl += `/${uuid}`;
  }
  if (queryParams) {
    appendToUrl = ConcatQueryParams(queryParams);
  }
  if (pagination) {
    appendToUrl = PaginationParams(appendToUrl, pagination);
  }
  const url =
    ApiEndpoints.baseUri + ApiEndpoints.participationServicers + appendToUrl;

  FetchService("GET", url, onSuccess, onError, null, callbackData);
}

function update(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  const url = FormatUrlV2(ApiEndpoints.participationServicer, {
    uuid: body.uuid
  });
  const convertedBody = RemoveNullKeys(body);
  delete convertedBody.participation_servicers_id;

  FetchService("PUT", url, onSuccess, onError, convertedBody, callbackData);
}

function add(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  const url = ApiEndpoints.baseUri + ApiEndpoints.participationServicers;
  const convertedBody = RemoveNullKeys(body);

  FetchService("POST", url, onSuccess, onError, convertedBody, callbackData);
}

async function create(body) {
  const url = ApiEndpoints.baseUri + ApiEndpoints.participationServicers;
  const convertedBody = RemoveNullKeys(body);
  const rsp = await asyncFetchService("POST", url, convertedBody);
  return rsp;
}

async function read(filters = {}) {
  const funcName = "==== ParticipationServicers.read(filters)  ====";
  logger.debug(funcName, [filters]);
  TypeCheck(filters.participationUuid, "string", false);

  const filterCount = Object.keys(filters).length;
  if (filters.participationUuid && filterCount > 1) {
    logger.error("Error: Invalid Filter Combination");
    // TODO: [#5740] Put a checkable status here.
    const errorRsp = {};
    return errorRsp;
  }

  let url;
  if (filters.participationUuid) {
    url = FormatUrlV2(ApiEndpoints.participationServicer, {
      uuid: filters.participationUuid
    });
  } else {
    const appendToUrl = ConcatQueryParams(filters);
    url = FormatUrlV2(ApiEndpoints.participationServicers) + appendToUrl;
  }
  const rsp = await asyncFetchService("GET", url);

  return rsp;
}

async function asyncUpdate(filters, body) {
  TypeCheck(filters.participationUuid, "string", true);

  const url = FormatUrlV2(ApiEndpoints.participationServicer, {
    uuid: filters.participationUuid
  });
  const convertedBody = RemoveNullKeys(body);

  const rsp = await asyncFetchService("PUT", url, convertedBody);
  return rsp;
}

export default {
  get,
  update,
  add,
  asyncUpdate,
  read,
  create
};
