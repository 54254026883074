import React, { Key } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { MessageProps } from "../../types/text";

/**
 * **Prefer using a `react-intl` React component!** Given a MessageProps object
 * return a string that can be used for display. This should only be used for
 * items like aria values.
 * @param {MessageProps} descriptor The props that will be used to generate the
 * text message.
 * @param {(md: MessageDescriptor) => string} formatter A formatter, typically
 * from react-intl's `useIntl` hook, that will be used to generate the string.
 */
export function getMessage(
  descriptor: MessageProps,
  formatter: (md: MessageDescriptor) => string
) {
  if ("id" in descriptor) {
    return formatter(descriptor);
  }

  return descriptor.defaultMessage;
}

/**
 * @deprecated ABSOLUTELY DO NOT USE THIS! YOUR COMPONENT SHOULD EXPLICITLY
 * STATE WHAT TYPE OF STRING IT ACCEPTS IN ITS PROPS. EXPECT THIS CODE TO BE
 * REMOVED SOON.
 */
export function formatDescriptionMessageArray(
  descriptionText: {
    key: Key;
    message: MessageProps;
    tagName: React.ElementType;
  }[]
) {
  return descriptionText.map(({ key, message, tagName }) =>
    getFormattedMessageComponent({ ...message, key }, { tagName: tagName })
  );
}

/**
 * @deprecated ABSOLUTELY DO NOT USE THIS! YOUR COMPONENT SHOULD EXPLICITLY
 * STATE WHAT TYPE OF STRING IT ACCEPTS IN ITS PROPS. EXPECT THIS CODE TO BE
 * REMOVED SOON.
 */
function getFormattedMessageComponent(
  message: (MessageProps & { key: Key }) | (MessageProps & { key: Key })[],
  options: {
    tagName?: Record<string, React.ElementType<any>>["tagName"];
  }
): JSX.Element {
  if (Array.isArray(message)) {
    return getMessageMap(message);
  }

  if ("id" in message) {
    return <FormattedMessage {...message} />;
  }

  if ("defaultMessage" in message) {
    const props = message.key ? { key: message.key } : null;
    return options?.tagName ? (
      React.createElement(options.tagName, props, message.defaultMessage)
    ) : (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>{message.defaultMessage}</>
    );
  }

  return <> </>;
}

/**
 * @deprecated ABSOLUTELY DO NOT USE THIS! YOUR COMPONENT SHOULD EXPLICITLY
 * STATE WHAT TYPE OF STRING IT ACCEPTS IN ITS PROPS. EXPECT THIS CODE TO BE
 * REMOVED SOON.
 */
function getMessageMap(
  text: (MessageProps & { key: Key })[],
  options?: {
    tagName: Record<string, React.ElementType<any>>["tagName"];
  }
): JSX.Element {
  if (!text.length) {
    return <> </>;
  }

  return (
    <>
      {text.map((x, i) => {
        return (
          <p>
            {getFormattedMessageComponent(x, {
              ...options
            })}
          </p>
        );
      })}
    </>
  );
}
