import PropTypes from "prop-types";
import { prettyDate } from "../../../services/DateTime";

function DateCell({ cell }) {
  if (cell.value !== undefined && cell.value !== null) {
    return prettyDate(cell.value);
  }
  return <>&mdash;</>;
}

DateCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  })
};

export default DateCell;
