import _ from "lodash";

export const initialState = {
  fees: [],
  loading: true
};

// eslint-disable-next-line default-param-last
export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case "LOAN_MGMT_DETAILS_LOAD_ADDITIONAL_FEES":
      return {
        ...state,
        fees: action.fees,
        loading: false
      };

    case "LOAN_MGMT_DETAILS_END_LOADING_STATE":
      return {
        ...state,
        loading: false
      };

    case "LOAN_MGMT_DETAILS_ADD_SINGLE_FEE":
      return {
        ...state,
        fees: [...state.fees, action.fee],
        loading: false
      };

    case "LOAN_MGMT_DETAILS_UPDATE_FEE": {
      const updatedFees = _.cloneDeep(state.fees);
      const updateIndex = _.findIndex(updatedFees, ["uuid", action.fee.uuid]);
      updatedFees[updateIndex] = { ...action.fee };
      return {
        ...state,
        fees: updatedFees
      };
    }

    case "LOAN_MGMT_DETAILS_RESET_STATE":
      return initialState;

    default:
      return state;
  }
}
