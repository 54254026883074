import React, { useState, useEffect } from "react";
import { Button, Modal, Message } from "semantic-ui-react";
import PropTypes from "prop-types";
import moment from "moment";
import { DateInput } from "../../../../../../components/CustomFormElements/index";
import { dateFromString } from "../../../../../../services/DateTime";
import { Loans } from "../../../../../../services/ApiLib";
import logger from "../../../../../../services/logger";

function NightlyProcessModal({ open, closeModal, currentlyOpenInstitutionId }) {
  const [processingDate, setProcessingDate] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    setProcessingDate("");
    setError("");
  }, [open]);
  const handleNightlyProcess = () => {
    const body = {
      institution_uuid: currentlyOpenInstitutionId,
      first_processing_date: processingDate
    };

    Loans.runAllNightlyProcess(
      () => {
        closeModal();
      },
      err => {
        logger.error(err);
        setError("Some error occurred");
      },
      body
    );
  };

  return (
    <Modal open={open} key="nightlyProcessModal" size="small">
      <Modal.Header>Nightly Process</Modal.Header>
      <Modal.Content>
        <DateInput
          label="Processing Date"
          onChange={e => {
            setProcessingDate(dateFromString(e.target.value));
          }}
          name="processing_date"
          value={processingDate}
          max={moment().subtract(1, "days").format("YYYY-MM-DD")}
        />
        {error && <Message color="red">{error}</Message>}
      </Modal.Content>
      <Modal.Actions>
        <Button id="cancel" onClick={closeModal} basic>
          Cancel
        </Button>
        <Button
          id="submit"
          disabled={!processingDate}
          onClick={handleNightlyProcess}
          primary
        >
          Run All Processes
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

NightlyProcessModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  currentlyOpenInstitutionId: PropTypes.string.isRequired
};

export default NightlyProcessModal;
