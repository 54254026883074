import { Route, useParams } from "react-router-dom";
import { Divider } from "semantic-ui-react";
import { Panel } from "@bafsllc/ui-shared";
import { PrepareDocsTabs, TabObject } from "../../components/PrepareDocsTabs";
import { LoanRequestInfo } from "./LoanRequestInfo/index";
import { LoanRequestApps } from "./LoanRequestApps/index";
import { TermsForm } from "../../components/TermsForm";
import { Payments } from "../../components/Payments";
import { PrepareDocsDetailsTab } from "../../components/PrepareDocsTabs/components/details";
import { InterestSchedules } from "../../components/InterestSchedules";

const TABS: TabObject[] = [
  { name: "Terms", slug: "terms" },
  { name: "Interest", slug: "interest" },
  { name: "Payments", slug: "payments" },
  { name: "Details", slug: "details" }
];

function LoanData() {
  const { uuid } = useParams<{ uuid: string; tab: string }>();
  const baseRoute = `/los/:uuid/prepare-documents/loan-data`;

  return (
    <div className="render">
      <h3 className="mt-0 text-lg font-semibold">Loan Data</h3>

      <Divider />

      <Panel className="shadow-md bg-neutral-100">
        <LoanRequestInfo />
      </Panel>

      <Panel className="shadow-md">
        <LoanRequestApps />
      </Panel>

      <PrepareDocsTabs
        tabs={TABS}
        // This baseRoute MUST contain the UUID as a string, since it generates the <Link />
        baseRoute={`/los/${uuid}/prepare-documents/loan-data`}
        relativeRoute=""
        style="underline"
      >
        <Route path={`${baseRoute}/${TABS[0].slug}`} component={TermsForm} />
        <Route
          path={`${baseRoute}/${TABS[1].slug}`}
          component={InterestSchedules}
        />
        <Route path={`${baseRoute}/${TABS[2].slug}`} component={Payments} />
        <Route
          path={`${baseRoute}/${TABS[3].slug}`}
          component={PrepareDocsDetailsTab}
        />
      </PrepareDocsTabs>
    </div>
  );
}

export default LoanData;
