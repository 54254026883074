import { Field } from "../../../fomantic/collections/form/field/field";
import { MessageProps } from "../../../types/text";
import { formatISO9075, parse } from "date-fns";
import { ChangeEvent } from "react";
import classnames from "classnames";

/**
 * Provides a multi-line form field.
 * It uses Field as a wrapper and it can take all the date-picker element props
 */
export function DatePicker({
  labelMessage,
  name,
  value,
  onDateChange,
  required,
  className,
  ...props
}: DatePickerProps) {
  // returns true if a valid date is passed
  const validateDate = (iso9075Date: string) => {
    return !isNaN(new Date(iso9075Date.toString()).getTime());
  };

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!value) {
      onDateChange("");
      return;
    }
    const noTimeZoneDate = value.includes("-")
      ? parse(value, "yyyy-MM-dd", new Date())
      : new Date(value);
    if (validateDate(value)) {
      onDateChange(formatISO9075(noTimeZoneDate, { representation: "date" }));
    }
  };

  return (
    <Field
      labelMessage={{ ...labelMessage }}
      error={
        value && !validateDate(value as string)
          ? "The entered date is not valid"
          : ""
      }
      className="text-sm font-semibold mb-1 flex flex-col"
      labelClasses="pb-2"
    >
      <input
        id={`${name}-date-picker`}
        name={name}
        type="date"
        onChange={handleDateChange}
        min={props.min ?? "0001-01-01"}
        max={props.max ?? "9999-12-31"}
        className={classnames(
          "px-4 py-2 border border-neutral-300 rounded-md w-full bg-white",
          ...(className ? [className] : [])
        )}
        {...props}
        value={value}
      />
    </Field>
  );
}

/**
 * Interface for the DatePicker props
 */
export interface DatePickerProps
  extends Omit<React.ComponentPropsWithoutRef<"input">, "onChange"> {
  /** Used to build the date-picker label display text */
  labelMessage: MessageProps;
  /** Defines the date-picker name to be referenced in the form data and to create the id of the element */
  name: Required<React.TextareaHTMLAttributes<HTMLInputElement>>["name"];
  /** Using useState, we pass from the parent the setValue to validate and set the proper formatted date */
  onDateChange: (iso9075Date: string) => void;
}
