import { SortValue } from "../../../../types/collections";

/**
 * This is the component that controls which icons should render in each
 * SortingHeader column and shouldn't be used outside SortingTable context
 */
export function SortingIcons({ header, sortState }: SortingIconsProps) {
  if (sortState.key === "hide") {
    return <div />;
  }

  if (sortState.sort === "none" || sortState.key !== header.key)
    return (
      <div className="sort-icons">
        <i className="icon sort down mb-5" />
      </div>
    );

  const asc = sortState.sort === "asc" ? "bold" : "";
  const desc = sortState.sort === "desc" ? "bold" : "";

  return (
    <div className="sort-icons">
      <i className={`icon sort up ${asc}`} />
      <i className={`icon sort down ${desc}`} />
    </div>
  );
}

type SortState = {
  key: string;
  sort: SortValue;
};

type SortingIconsProps = {
  header: any;
  sortState: SortState;
};
