import moment from "moment";

export function getDate(dateOffset, startingDate = null, separator = "/") {
  // Takes an offset in days and an optional date object as the starting point
  // Returns a formatted string
  const slash = separator;
  const date = startingDate ? new Date(startingDate) : new Date();
  date.setDate(date.getDate() + dateOffset);
  let dd = date.getDate();
  let mm = date.getMonth() + 1; // January is 0 because, JavaScript.
  const yyyy = date.getFullYear();

  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }

  const formattedDate = yyyy + slash + mm + slash + dd;
  return formattedDate;
}

export function getUTCDate(dateOffset, startingDate = null, separator = "/") {
  // Takes an offset in days and an optional date object as the starting point
  // Returns a formatted string
  const slash = separator;
  const date = startingDate ? new Date(startingDate) : new Date();
  date.setUTCDate(date.getUTCDate() + dateOffset);
  let dd = date.getUTCDate();
  let mm = date.getUTCMonth() + 1; // January is 0 because, JavaScript.
  const yyyy = date.getUTCFullYear();

  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }

  const formattedDate = yyyy + slash + mm + slash + dd;
  return formattedDate;
}

export function getFirstDayOfMonth() {
  const today = new Date();
  let mm = today.getMonth() + 1; // January is 0 because, JavaScript.
  const yyyy = today.getFullYear();

  if (mm < 10) {
    mm = `0${mm}`;
  }

  const date = `${yyyy}/${mm}/01`;
  return date;
}

export function fixDateInput(dateWithDashes) {
  const dateWithSlashes = dateWithDashes.replace(/-/g, "/");
  return dateWithSlashes;
}

export function getTime() {
  const colon = ":";
  const today = new Date();
  const hours = today.getUTCHours();
  const minutes = today.getUTCMinutes();
  const seconds = today.getUTCSeconds();

  const time = hours + colon + minutes + colon + seconds;
  return time;
}

export function getDateTime(dateOffset = 0) {
  const space = " ";
  const delimiter = "-";
  const colon = ":";

  const today = new Date();
  today.setDate(today.getDate() + dateOffset);
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0 because, JavaScript.
  const yyyy = today.getFullYear();

  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }

  const hours = today.getUTCHours();
  const minutes = today.getUTCMinutes();
  const seconds = today.getUTCSeconds();

  const date = yyyy + delimiter + mm + delimiter + dd;
  const time = hours + colon + minutes + colon + seconds;

  const formattedDate = date + space + time;
  return formattedDate;
}

export function formatDateTimeString(dateTimeString) {
  const formatter = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  });
  const date = new Date(dateTimeString);

  return formatter.format(date);
}

export function dateStringToISO(dateString) {
  // Please don't use this going forward, see dateFromString below
  return new Date(dateString).toJSON().split("T")[0];
}

export function dateValidation(dateString) {
  return (
    typeof dateString === "string" &&
    dateString.length >= 8 &&
    !Number.isNaN(Date.parse(new Date(dateString))) &&
    new Date(dateString).getUTCFullYear() > 999
  );
}

export function TimeSpan(startDate, endDate) {
  // Returns the number of DAYS between start and end dates. 'now' = today's date

  function createNewDateObj(date) {
    const newDate = new Date(date);
    let hour = newDate.getHours();
    const dt = new Date();
    const timez = dt.getTimezoneOffset();
    hour += timez / 60;
    newDate.setHours(hour);
    return newDate;
  }

  function todayDate() {
    const newDate = new Date();
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }
  const start = startDate !== "now" ? createNewDateObj(startDate) : todayDate();
  const end = endDate !== "now" ? createNewDateObj(endDate) : todayDate();

  const msPerDay = 1000 * 60 * 60 * 24;
  const daysSpan = (end - start) / msPerDay;
  return Math.round(daysSpan);
}

export const dateFromString = date => {
  /*
  Takes either a Date object or a string with a date and returns date as a string
  without timezone interference, or off by one issues.
  This format is the same as what is used in mysql and should be used for sending to the API
  */
  if (!(date instanceof Date) && !dateValidation(date)) {
    return "";
  }

  let d;
  if (typeof date === "string") {
    d = new Date(date);
  } else {
    d = date;
  }
  return new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate()))
    .toISOString()
    .split("T")[0];
};

export function dateFromStringNoTime(stringDate) {
  // Expects a string date with no time/TZ, returns Date obj
  // Used for converting from database Date format where time/TZ is not present
  if (!dateValidation(stringDate)) {
    return "";
  }
  const dateArr = stringDate.split(/[-/]/);
  dateArr[0] = parseInt(dateArr[0], 10);
  dateArr[1] = parseInt(dateArr[1], 10);
  dateArr[2] = parseInt(dateArr[2], 10);

  let date;
  if (dateArr[0] > 1000) {
    // Assume format is year-month-day
    date = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
  } else if (dateArr[2] > 1000) {
    // Format is month-day-year
    date = new Date(dateArr[2], dateArr[0] - 1, dateArr[1]);
  } else {
    date = "";
  }

  return date;
}

export function prettyDate(dateString, includeTime = false) {
  if (dateValidation(dateString)) {
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const hasHoursAndMinutes =
      (hours || hours === 0) && (minutes || minutes === 0);
    if (hasHoursAndMinutes && includeTime) {
      return date.toLocaleString();
    }

    let dd = date.getUTCDate();
    let mm = date.getUTCMonth() + 1; // January is 0 because, JavaScript.
    const yyyy = date.getUTCFullYear();
    if (dd < 10) {
      dd = `0${dd}`;
    }
    if (mm < 10) {
      mm = `0${mm}`;
    }
    return `${mm}/${dd}/${yyyy}`;
  }
  return "";
}

export function isTodayOrYesterday(dateString) {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  switch (dateFromString(dateString)) {
    case dateFromString(today):
      return "Today";
    case dateFromString(yesterday):
      return "Yesterday";
    default:
      return "";
  }
}

export function relativeFriendlyDate(dateString) {
  // Returns friendly format:
  // "Today, Monday, July 8",
  // "Yesterday, Sunday, July 7",
  // or "Saturday, July 6" for days not today or yesterday.
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];
  const relative = isTodayOrYesterday(dateString);
  let prefix = "";
  if (relative !== "") {
    prefix = `${relative}, `;
  }
  const date = new Date(dateString);
  const monthName = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
    date
  );
  return `${prefix}${days[date.getDay()]}, ${monthName}, ${date.getDate()}`;
}

export function friendlyTimeFromDateString(dateString) {
  let hours = new Date(dateString).getHours();
  let amPm = "am";
  if (hours > 12) {
    hours -= 12;
    amPm = "pm";
  }
  return `${hours}:${`0${new Date(dateString).getMinutes()}`.slice(
    -2
  )} ${amPm}`;
}

export function rawDateToLocal(dateString) {
  // return Date object with GMT timezone to handle times without a timezone from the backend
  return new Date(`${dateString}.000Z`);
}

export function getDateString(date) {
  return moment(date).format("YYYY-MM-DD");
}

export function getLastDaysBetweenMonths(startDate, endDate) {
  const dateStart = moment(startDate);
  const dateEnd = moment(endDate);
  const interim = dateStart.clone();
  const timeValues = [];

  while (dateEnd > interim || interim.format("M") === dateEnd.format("M")) {
    timeValues.push(interim.endOf("month").format("YYYY-MM-DD"));
    interim.add(1, "month");
  }
  return timeValues;
}

export function getLastDayOfPreviousMonth(date) {
  return moment(date)
    .subtract(1, "months")
    .endOf("months")
    .format("YYYY-MM-DD");
}
