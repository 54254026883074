import { get } from "lodash";
import { useContext } from "react";
import { SortingTableContext } from "./sorting-table-context";
import { Tooltip } from "../../../elements/Tooltip";

/**
 * This is the body that SortingTable uses and shouldn't be used outside SortingTable context
 */
export function SortingBody() {
  const {
    tableData,
    idProperty,
    headers,
    disabledProperty,
    partiallyDisabledProperty,
    sortState,
    ...ctx
  } = useContext(SortingTableContext);

  const handleChildCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ("onChecklistChanged" in ctx && "checkList" in ctx) {
      const { checkList, onChecklistChanged } = ctx;
      if (event.target.checked) {
        const checkListUpdated = [...checkList, event.target.name];
        onChecklistChanged(checkListUpdated);
      } else {
        const tempCheckList = JSON.parse(JSON.stringify(checkList));
        const index = tempCheckList.indexOf(event.target.name);
        if (index > -1) {
          tempCheckList.splice(index, 1);
          onChecklistChanged([...tempCheckList]);
        }
      }
    }
  };

  return (
    <>
      {tableData.map(data => {
        const isDisabled = disabledProperty && data[disabledProperty];
        const isPartiallyDisabled =
          partiallyDisabledProperty && data[partiallyDisabledProperty];
        const disabled = isDisabled || isPartiallyDisabled;

        const checkbox = (ctx: { checkList: string | any[] }) => {
          return (
            <input
              data-testid={data[idProperty]}
              id={data[idProperty]}
              name={data[idProperty]}
              disabled={disabled}
              onChange={handleChildCheckBox}
              checked={ctx?.checkList?.indexOf(data[idProperty]) !== -1}
              type="checkbox"
              value=""
            />
          );
        };

        return (
          <div
            data-testid={`row-${data[idProperty]}`}
            key={`row-${data[idProperty]}`}
            className={`ui grid sorting-row items-center !mb-0 ${
              isDisabled ? "disabled-row" : ""
            }`}
          >
            {"checkList" in ctx && (
              <div className="one wide column">
                {disabled ? (
                  <Tooltip
                    content="Entity is not allowed to be unlinked."
                    position="top"
                    inverted
                  >
                    <div>{checkbox(ctx)}</div>
                  </Tooltip>
                ) : (
                  checkbox(ctx)
                )}
              </div>
            )}
            {headers.map(header => {
              const options = {
                isDisabled,
                isPartiallyDisabled
              };
              return (
                <div
                  data-testid={`col-${header.key}-${data[idProperty]}`}
                  key={`col-${header.key}-${data[idProperty]}`}
                  className={`${header.columnSize} wide column ${
                    sortState.key === header.key && sortState.sort !== "none"
                      ? "sorted"
                      : ""
                  }${header.key === "actions" ? "actions-column" : ""}`}
                >
                  {header.customColumn
                    ? header.customColumn(data, options)
                    : get(data, header.key)}
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
}
