/**
 * @deprecated Replace with new ui-shared breadcrumbs component when finished.
 */
export function LOSBreadcrumbs({ loanApp, pageName }) {
  return (
    <>
      <nav className="ui breadcrumb p-0 !mb-3">
        <ol className="!mt-0">
          <li>
            <a className="section" href="/application-phases">
              LOS Current Requests
            </a>
            <span className="divider">
              <i className="caret right icon"></i>
            </span>
          </li>
          <li>
            <a
              className="section"
              href={`/los/${loanApp.uuid}/phases/app_details`}
            >
              {loanApp.app_id}
            </a>
            <span className="divider">
              <i className="caret right icon"></i>
            </span>
          </li>
          <li>
            <span className="section blue">{pageName}</span>
          </li>
        </ol>
      </nav>
      <div className="mb-6"></div>
    </>
  );
}
