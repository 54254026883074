import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Grid, Header as SemanticHeader } from "semantic-ui-react";
import ResourceTable from "../ResourceTable";

export const Header = ({ children }) => children;

function Layout({ children, heading }) {
  const headerComponent = React.Children.toArray(children).find(
    c => c.type === Header
  );

  const tableComponent = React.Children.toArray(children).find(
    c => c.type === ResourceTable
  );

  const otherComponents = React.Children.toArray(children).filter(
    c => c.type !== Header && c.type !== ResourceTable
  );

  return (
    <Grid>
      <HeaderRow columns="2">
        <Grid.Column width={3}>
          <Heading as="h3" dividing>
            {heading}
          </Heading>
        </Grid.Column>
        <Grid.Column width={13}>
          <Box>{headerComponent}</Box>
        </Grid.Column>
      </HeaderRow>
      <Grid.Row>
        <Grid.Column>{tableComponent}</Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>{otherComponents}</Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.node
};

const Heading = styled(SemanticHeader)`
  display: flex;
  align-items: flex-end;
`;

const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  && > * {
    margin-left: 1em;
  }
`;

const HeaderRow = styled(Grid.Row)`
  /* override semantic-ui-react styles with specificity */
  &&& {
    display: flex;
    align-items: flex-end;
    padding-bottom: 0;
  }
`;

Layout.Header = Header;

export default Layout;
