/**
 *
 * @param {a} any
 * @param {b} any
 * @param {ascending} Boolean
 *
 * @returns sorted array
 */
function sortWithDirection(a, b, sortDirection) {
  const direction = sortDirection === "ascending" ? 1 : -1;

  if (a > b) return direction;
  if (a < b) return -1 * direction;
  return 0;
}

export default sortWithDirection;
