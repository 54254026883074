import { useState, useEffect } from "react";

export const useCurrentBalance = ({
  participants,
  participant: selectedParticipant = null
}) => {
  // update the "balance used" numbers
  const [usedBalance, setUsedBalance] = useState(0);

  useEffect(() => {
    let nextUsedBalance = 0;
    for (let i = 0; i < participants.length; i += 1) {
      if (
        selectedParticipant &&
        participants[i].index === selectedParticipant.index
      ) {
        nextUsedBalance += selectedParticipant.current_contract_amount;
      } else {
        nextUsedBalance += participants[i].current_contract_amount;
      }
    }
    setUsedBalance(nextUsedBalance);
  }, [participants, selectedParticipant]);
  return usedBalance;
};

export const useAvailableInstitutions = ({
  institutionUuid,
  investors,
  isEdit,
  participants
}) => {
  const [institutionOptions, setInstitutionOptions] = useState([]);

  useEffect(() => {
    // update the available institutions:
    // the original loan's institution, and any already-used institutions should be removed
    const usedInstitutions = {
      [institutionUuid]: true
    };
    for (let i = 0; i < participants.length; i += 1) {
      const p = participants[i];
      usedInstitutions[p.institution_uuid] = true;
    }
    const nextInstitutionOptions = isEdit
      ? investors
      : investors.filter(elem => !usedInstitutions[elem.investor.uuid]);
    setInstitutionOptions(nextInstitutionOptions);
  }, [institutionUuid, investors, isEdit, participants]);
  return institutionOptions;
};

export default { useCurrentBalance, useAvailableInstitutions };
