import TC from "typecheck-extended";

import store from "../../../../store";
import logger from "../../../logger";

// Don't directly consume this. Use ErrorReporterV2.
function errorReportHandler(thrownError, messageForUser, errorDisplay) {
  TC(messageForUser, "string");
  TC(errorDisplay, "enum", true, ["toConsole", "toPage", "toModal"]);

  if (errorDisplay === "toPage" || errorDisplay === "toModal") {
    store.dispatch({
      errorDisplay,
      errorMessage: messageForUser,
      type: "ERRORS_REPORT_V2"
    });
  }

  logger.error(messageForUser, thrownError);
}

export default errorReportHandler;
