import React, { useState, useMemo, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { useToast } from "@bafsllc/ui-shared";
import { newlyCreatedSpreadSelector } from "../../../../../../../../../../store/models/spreads";
import { usePeriods } from "../../../../../../../../../../store/models/spreads/hooks";
import {
  DateCell,
  CheckboxCell,
  LockCell,
  DefaultCell
} from "../../../../../../../../../../components/Tables";
import {
  FrictionModal,
  ErrorNotificationModal
} from "../../../../../../../../../../components/Modals";
import AddDuplicateModal from "../../../../../AddDuplicateModal";
import Constants from "../../../../../../../../../../services/Constants/strings";
import {
  addDocument,
  editDocument
} from "../../../../../../../../../../services/CMS";
import { renderFinancialAnalysisTemplate } from "../../../../../../../../../../services/CMSRS";
import { getBlastClientApplicationBaseUrl } from "../../../../../../../../../../components/BlastEmbed/getBaseUrl";
import PaginatedTable from "../PaginatedTable";
import { ifFinancialModelExists } from "../../../../../../../../../../services/Constants/Models";
import AutoSpreadsStatus from "../../../../../../../../../../components/AutoSpreads/components/StatusColumn/index";
import { CorrectionsSideSheet } from "../../../../../../../../../../components/AutoSpreads/components/CorrectionsSideSheet";
import logger from "../../../../../../../../../../services/logger";
import AutoSpreadsStatusHeader from "./AutoSpreadsStatusHeader";

const onError = logger.error;

const {
  SELECT,
  SPREADS,
  PERIOD_START_DATE,
  PERIOD_END_DATE,
  STATEMENT_TYPE,
  PERIOD,
  SOURCE_DOC,
  ANALYST,
  FINANCIAL_MODEL,
  LOCKED,
  LOCK_MODAL_PERIOD_HEADER,
  LOCK_MODAL_PERIOD_MESSAGE,
  LOCK_MODAL_PERIOD_WARNING
} = Constants;

function Periods({
  entityData,
  analystUuid,
  onTabChange,
  initialSelectedRows = [],
  allProductionModels
}) {
  const { uuid: entityUuid, institution_uuid: institutionUuid } = entityData;

  const [selectedRows, setSelectedRows] = useState(initialSelectedRows);
  const [newDialogIsOpen, setNewDialogIsOpen] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [viewArchives, setViewArchives] = useState(false);
  const [disableCreateSpread, setDisableCreateSpread] = useState(false);
  const [isLockModalOpen, setIsLockModalOpen] = useState(false);
  const [lockingPeriod, setLockingPeriod] = useState();
  const [openErrorModal, setOnErrorModal] = useState(null);
  const [isFinancialAnalysisRendered, setIsFinancialAnalysisRendered] =
    useState(false);
  const [isCreatingSpread, setIsCreatingSpread] = useState(false);
  const [periodForCorrection, setPeriodForCorrection] = useState(null);
  const [{ periods }, actions] = usePeriods({ entityUuid, institutionUuid });
  const newlyCreatedSpread = useSelector(newlyCreatedSpreadSelector);
  const { addToast } = useToast();
  const dispatch = useDispatch();

  const firstSelectedPeriod =
    Array.isArray(selectedRows) && selectedRows.length > 0 && selectedRows[0];

  const renderToast = useCallback(
    variant => {
      const idMap = {
        error: {
          title: Constants.FINANCIAL_ANALYSIS_ERROR_TITLE,
          message: Constants.FINANCIAL_ANALYSIS_ERROR_MESSAGE
        }
      };
      addToast({
        id: "BASIC_TOAST",
        title: {
          id: idMap[variant].title,
          defaultMessage: "Error Rendering Template"
        },
        message: {
          id: idMap[variant].message,
          defaultMessage:
            "There was an error creating the financial analysis template. Please try again."
        },
        variant
      });
    },
    [addToast]
  );

  const renderFATemplate = useCallback(async () => {
    try {
      const response = await renderFinancialAnalysisTemplate(
        newlyCreatedSpread.uuid,
        institutionUuid
      );
      if (response?.financialAnalysisDocumentUuid) {
        setIsFinancialAnalysisRendered(true);
      }
    } catch (e) {
      renderToast("error");
      setIsCreatingSpread(false);
    }
  }, [newlyCreatedSpread, institutionUuid, renderToast, setIsCreatingSpread]);

  const columns = useMemo(
    () => [
      {
        Header: SELECT,
        id: "selected",
        textAlign: "center",
        accessor: "selected",
        onSelect: data => {
          setSelectedRows(data);
        },
        Cell: CheckboxCell
      },
      {
        Header: PERIOD_START_DATE,
        id: "startDate",
        textAlign: "center",
        accessor: "startDate",
        Cell: DateCell
      },
      {
        Header: PERIOD_END_DATE,
        id: "endDate",
        textAlign: "center",
        accessor: "endDate",
        Cell: DateCell
      },
      {
        Header: STATEMENT_TYPE,
        id: "statementType",
        accessor: "statementType",
        Cell: DefaultCell
      },
      {
        Header: PERIOD,
        id: "period",
        accessor: "period",
        Cell: DefaultCell
      },
      {
        Header: SOURCE_DOC,
        id: "sourceDocument",
        accessor: "sourceDocument",
        Cell: DefaultCell
      },
      {
        Header: ANALYST,
        id: "analyst",
        accessor: row =>
          row.user?.first_name
            ? `${row.user?.first_name} ${row.user?.last_name}`
            : row.analystUuid,
        Cell: DefaultCell
      },
      {
        Header: FINANCIAL_MODEL,
        id: "financialModelUuid",
        accessor: row =>
          ifFinancialModelExists(row.FinancialSpreadsModelsModel),
        Cell: DefaultCell
      },
      {
        Header: AutoSpreadsStatusHeader,
        accessor: "autoSpreadsStatus",
        onClickError: () => setOnErrorModal(true),
        onClickValidate: rowOriginal => {
          setPeriodForCorrection(rowOriginal);
        },
        Cell: AutoSpreadsStatus
      },
      {
        Header: LOCKED,
        id: "locked",
        onLock: rowOriginal => {
          setIsLockModalOpen(true);
          setLockingPeriod(rowOriginal);
        },
        textAlign: "center",
        accessor: originalRow => originalRow.status === "Completed",
        Cell: LockCell
      }
    ],
    [
      setIsLockModalOpen,
      setLockingPeriod,
      setSelectedRows,
      setPeriodForCorrection
    ]
  );

  const filteredPeriods = useMemo(() => {
    if (periods) {
      const orderedPeriods = [...periods];
      orderedPeriods.sort(
        (period1, period2) =>
          new Date(period2.endDate) - new Date(period1.endDate)
      );
      return viewArchives
        ? orderedPeriods
        : orderedPeriods.filter(period => period.archive === 0);
    }
    return null;
  }, [periods, viewArchives]);

  useEffect(() => {
    if (newlyCreatedSpread) {
      renderFATemplate();
    }
  }, [newlyCreatedSpread, renderFATemplate]);

  useEffect(() => {
    if (newlyCreatedSpread && isFinancialAnalysisRendered) {
      const domainParts = window.location.hostname.split(".");
      domainParts.shift();
      const blastBaseUrl = getBlastClientApplicationBaseUrl();
      const spreadUrl = `${blastBaseUrl}/spreads/${institutionUuid}/${newlyCreatedSpread.uuid}`;
      const newWindow = window.open(spreadUrl);
      if (newWindow) {
        onTabChange(SPREADS);
      } else {
        console.warn("Popups are blocked, redirecting to spread"); // eslint-disable-line no-console
        window.location.assign(spreadUrl);
      }
      setIsCreatingSpread(false);
    }
  }, [
    newlyCreatedSpread,
    institutionUuid,
    onTabChange,
    isFinancialAnalysisRendered
  ]);

  useEffect(() => {
    const uniqueFinancialModels = new Set(
      selectedRows.map(row => row?.financialModelUuid)
    );
    setDisableCreateSpread(
      !(
        uniqueFinancialModels.size === 1 &&
        selectedRows.every(selectedRow => selectedRow?.archive === 0)
      ) || isCreatingSpread
    );
  }, [selectedRows, isCreatingSpread]);

  const addPeriod = periodData => {
    addDocument(dispatch, "", periodData, entityUuid)
      .then(() => actions.retrievePeriods({ entityUuid, institutionUuid }))
      .catch(onError);
  };

  const editPeriod = ({ FinancialSpreadsModelsModel, ...periodData }) => {
    editDocument(dispatch, "", periodData, entityUuid)
      .then(() => actions.updatePeriod({ entityUuid, ...periodData }))
      .catch(onError);
  };

  const handleAddPeriod = async data => {
    addPeriod({ ...data, institutionUuid });
    setNewDialogIsOpen(false);
  };

  const handleDuplicatePeriod = async data => {
    const duplicatePeriodData = { ...data };
    delete duplicatePeriodData.user;
    addPeriod({
      ...duplicatePeriodData,
      institutionUuid: entityData.institution_uuid
    });
    setNewDialogIsOpen(false);
    setIsDuplicate(false);
  };

  const handleEditPeriod = async data => {
    editPeriod({ ...data, institutionUuid });
    setNewDialogIsOpen(false);
  };

  const handleClickNew = () => {
    setIsDuplicate(false);
    setIsUpdate(false);
    // duplicate cancel dispatch ensures that no cached data is in the form
    dispatch({ type: "CMS_TABVIEW_BUTTON_DUPLICATE_CANCEL" });
    setNewDialogIsOpen(true);
  };

  const handleClickEdit = () => {
    setIsDuplicate(false);
    setIsUpdate(true);
    const rowData = firstSelectedPeriod;
    dispatch({ type: "CMS_ADD_DUP_SET_DUP_FORM_DATA", dupFormData: rowData });
    setNewDialogIsOpen(true);
  };

  const handleClickDuplicate = () => {
    // prepare form data...
    const rowData = { ...selectedRows[0], status: "In Progress" };
    delete rowData.uuid;

    // dispatch form data...
    dispatch({ type: "CMS_ADD_DUP_SET_DUP_FORM_DATA", dupFormData: rowData });

    // open form...
    setIsDuplicate(true);
    setIsUpdate(false);
    setNewDialogIsOpen(true);
  };

  const handleClickCreateSpread = () => {
    if (!isCreatingSpread) {
      setIsCreatingSpread(true);
      const {
        financialModelUuid,
        FinancialSpreadsModelsModel: { templateUuid }
      } = firstSelectedPeriod;

      actions.createSpread({
        analystUuid,
        entityUuid,
        institutionUuid,
        periodUuids: selectedRows.map(({ uuid }) => uuid),
        financialModelUuid,
        templateUuid
      });
    }
  };

  const handleClickArchive = () => {
    actions.updatePeriod({
      entityUuid,
      uuid: firstSelectedPeriod.uuid,
      archive: 1
    });
  };

  const handleClickUnarchive = () => {
    actions.updatePeriod({
      entityUuid,
      uuid: firstSelectedPeriod.uuid,
      archive: 0
    });
  };

  const handleViewArchives = (e, { checked }) => {
    setViewArchives(checked);
  };

  const handleLockModalSubmit = () => {
    actions.updatePeriod({
      entityUuid,
      uuid: lockingPeriod.uuid,
      status: "Completed"
    });
    setIsLockModalOpen(false);
  };

  return filteredPeriods ? (
    <>
      <PaginatedTable
        columns={columns}
        data={filteredPeriods}
        onSelectedRowChange={setSelectedRows}
        handleViewArchives={handleViewArchives}
        filterOptions={[{ text: "Clear Filter", value: "" }]}
        tableName="periodsTable"
        leftButtons={
          <>
            <Button disabled={selectedRows.length > 0} onClick={handleClickNew}>
              New
            </Button>
            <Button
              className="ml-2"
              disabled={
                !(
                  selectedRows.length === 1 &&
                  firstSelectedPeriod?.status !== "Completed" &&
                  selectedRows[0]?.archive === 0
                )
              }
              onClick={handleClickEdit}
            >
              Edit
            </Button>
            <Button
              className="ml-2"
              disabled={selectedRows.length !== 1}
              onClick={handleClickDuplicate}
            >
              Duplicate
            </Button>
            <Button
              className="ml-2"
              disabled={
                !(selectedRows.length === 1 && selectedRows[0]?.archive === 0)
              }
              onClick={handleClickArchive}
            >
              Archive
            </Button>
            <Button
              className="ml-2"
              disabled={
                !(selectedRows.length === 1 && selectedRows[0]?.archive === 1)
              }
              onClick={handleClickUnarchive}
            >
              Un-Archive
            </Button>
          </>
        }
        rightButtons={
          <Button
            disabled={disableCreateSpread}
            onClick={handleClickCreateSpread}
          >
            <i
              className={`${isCreatingSpread ? "spinner icon loading" : ""}`}
              data-testid="create-spread-spinner"
            />
            Create Spread
          </Button>
        }
      />

      <CorrectionsSideSheet
        periodUuid={periodForCorrection?.uuid}
        entityUuid={entityUuid}
        institutionUuid={institutionUuid}
        onClose={() => setPeriodForCorrection(null)}
      />

      <AddDuplicateModal
        openState={newDialogIsOpen}
        allProductionModels={allProductionModels}
        close={() => {
          setNewDialogIsOpen(false);
          setSelectedRows([]);
          setIsDuplicate(false);
          setIsUpdate(false);
        }}
        isDuplicate={isDuplicate}
        isUpdate={isUpdate}
        addDocumentCallback={data => handleAddPeriod(data, entityUuid)}
        duplicateDocumentCallback={data =>
          handleDuplicatePeriod(data, entityUuid)
        }
        editDocumentCallback={data => handleEditPeriod(data, entityUuid)}
      />
      <FrictionModal
        open={isLockModalOpen}
        header={LOCK_MODAL_PERIOD_HEADER}
        message={LOCK_MODAL_PERIOD_MESSAGE}
        warning={LOCK_MODAL_PERIOD_WARNING}
        validateString="CONFIRM"
        onCancel={() => {
          setIsLockModalOpen(false);
        }}
        onSubmit={handleLockModalSubmit}
        confirmLabelText="Submit"
      />
      <ErrorNotificationModal
        openModal={openErrorModal}
        header="Autofill Error"
        onCancel={() => setOnErrorModal(false)}
      />
    </>
  ) : (
    "Loading..."
  );
}

Periods.propTypes = {
  entityData: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    institution_uuid: PropTypes.string.isRequired
  }).isRequired,
  analystUuid: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
  initialSelectedRows: PropTypes.arrayOf(PropTypes.shape({})),
  allProductionModels: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
      version: PropTypes.number
    })
  )
};

export default Periods;
