import React from "react";
import Constants from "../../../Constants/strings";

const phases = [
  Constants.PHASE_INITIAL_REVIEW,
  Constants.PHASE_FINANCIAL_ANALYSIS,
  Constants.PHASE_UNDERWRITING,
  Constants.PHASE_PENDING_DECISION,
  Constants.PHASE_PRE_CLOSING_CONDITIONS,
  Constants.PHASE_DOC_PREP,
  Constants.PHASE_CREDIT_ADMIN
];

const phasesOptions = [];

phases.forEach(phase => {
  phasesOptions.push({ key: phase, value: phase, text: phase });
});

export const getDisabledPhases = currentPhase => {
  switch (currentPhase) {
    case Constants.PHASE_DOC_PREP:
      return [
        Constants.PHASE_INITIAL_REVIEW,
        Constants.PHASE_FINANCIAL_ANALYSIS,
        Constants.PHASE_UNDERWRITING,
        Constants.PHASE_PENDING_DECISION,
        Constants.PHASE_PRE_CLOSING_CONDITIONS
      ];
    case Constants.PHASE_CREDIT_ADMIN:
      return [
        Constants.PHASE_INITIAL_REVIEW,
        Constants.PHASE_FINANCIAL_ANALYSIS,
        Constants.PHASE_UNDERWRITING,
        Constants.PHASE_PENDING_DECISION,
        Constants.PHASE_PRE_CLOSING_CONDITIONS,
        Constants.PHASE_DOC_PREP
      ];
    case Constants.PHASE_PRE_CLOSING_CONDITIONS:
      return [
        Constants.PHASE_INITIAL_REVIEW,
        Constants.PHASE_FINANCIAL_ANALYSIS,
        Constants.PHASE_UNDERWRITING,
        Constants.PHASE_PENDING_DECISION
      ];
    case Constants.PHASE_PENDING_DECISION:
      return [Constants.PHASE_DOC_PREP, Constants.PHASE_CREDIT_ADMIN];
    default:
      return [
        Constants.PHASE_PRE_CLOSING_CONDITIONS,
        Constants.PHASE_DOC_PREP,
        Constants.PHASE_CREDIT_ADMIN
      ];
  }
};

export const shouldShowConfirmationModal = phase => {
  const confirmationModalPhases = [
    Constants.PHASE_PRE_CLOSING_CONDITIONS,
    Constants.PHASE_DOC_PREP,
    Constants.PHASE_CREDIT_ADMIN,
    Constants.PHASE_COMPLETE
  ];
  return confirmationModalPhases.includes(phase);
};

export const getPhasesOptions = phase => {
  const disabledPhases = getDisabledPhases(phase);

  const newOpts = phasesOptions.map(phaseOpt => ({
    ...phaseOpt,
    disabled: disabledPhases.includes(phaseOpt.value)
  }));

  return newOpts;
};

export const readOnlyPhases = [
  Constants.PHASE_DOC_PREP,
  Constants.PHASE_CREDIT_ADMIN
];

export const phaseChangeCopy = (phase, needsSplit) => {
  switch (phase) {
    case Constants.PHASE_PRE_CLOSING_CONDITIONS:
      if (needsSplit) {
        return {
          message: (
            <>
              <p>
                <strong>
                  Are you sure you want to change the phase to Pre-Closing
                  Conditions?
                </strong>
              </p>
              <p>
                Once this change is made, the phase cannot be changed to any
                earlier step in the process. Are you sure you want to continue?
              </p>

              <p>
                Additionally, changing the phase to Pre-Closing Conditions will
                convert this single application into multiple applications --
                one per loan request. This cannot be undone. Are you sure you
                want to continue?
              </p>
            </>
          ),
          yes: "Change phase to Pre-Closing Conditions"
        };
      }
      return {
        message: (
          <>
            <p>
              <strong>
                Are you sure you want to change the phase to Pre-Closing
                Conditions?
              </strong>
            </p>

            <p>
              Once this change is made, the phase cannot be changed to any
              earlier step in the process, and the details of the Application
              (Borrowers, Credit Request, Guarantors, and Collateral) will
              become read-only. Are you sure you want to continue?
            </p>
          </>
        ),
        yes: "Change phase to Pre-Closing Conditions"
      };
    case Constants.PHASE_DOC_PREP:
      return {
        message: (
          <>
            <p>
              <strong>
                Are you sure you want to change the phase to Doc. Prep?
              </strong>
            </p>
            <p>
              Once this change is made, the phase cannot be changed to any
              earlier step in the process, and the details of the Application
              (Borrowers, Credit Request, Guarantors, and Collateral) will
              become read-only. Are you sure you want to continue?
            </p>
          </>
        ),
        yes: "Change phase to Doc Prep"
      };

    case Constants.PHASE_CREDIT_ADMIN:
      return {
        message: (
          <>
            <p>
              <strong>
                Are you sure you want to change the phase to Credit Admin?
              </strong>
            </p>
            <p>
              Once this change is made, the phase cannot be changed to any
              earlier step in the process. Are you sure you want to continue?
            </p>
          </>
        ),
        yes: "Change phase to Credit Admin"
      };

    default:
      return {
        message: `Are you sure you want to change the phase to ${phase}`,
        yes: `Change phase to ${phase}`
      };
  }
};

export default phasesOptions;
