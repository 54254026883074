import React, { useEffect, useRef, useState } from "react";
import {
  useEntitiesQuery,
  EntityQueryOptions
} from "../../../../../../services/RTKQuery/entities";

export const ReadManyTest = ({
  entityUuid,
  institutionUuid,
  setStatusInParent
}) => {
  const statusUnknown = "❓Unknown Status";
  const statusPass = "✅ Pass";
  const statusFail = "❌ Fail";

  const testAlreadyPassedRef = useRef(false);
  const [statusReadMany, setStatusReadMany] = useState(statusUnknown);
  const [detailsText, setDetailsText] = useState("");

  const filters: EntityQueryOptions = {
    institution_uuid: institutionUuid,
    company_name: "Weyland-Yutani Corporation",
    page_size: 999999
  };
  const { data: entitiesApiRsp } = useEntitiesQuery(filters);
  useEffect(() => {
    // This shouldn't be needed, but if it's removed, the test will
    // rerun after the DELETE test is run and then fail.
    if (testAlreadyPassedRef.current) {
      return;
    }
    let createdRecord;
    const listOfEntities = entitiesApiRsp?.data;
    if (listOfEntities && listOfEntities.length > 0) {
      for (const record of listOfEntities) {
        if (record.uuid === entityUuid) {
          createdRecord = record;
          break;
        }
      }
    }
    // This str is repeated in two tests
    const newEmail = "adent@bafs.com";
    const isRecordUpdated = createdRecord?.email === newEmail;
    if (createdRecord && isRecordUpdated) {
      setStatusInParent("Pass");
      setDetailsText(
        `: email matches updated value in record ${createdRecord.uuid}`
      );
      testAlreadyPassedRef.current = true;
      setStatusReadMany(statusPass);
    } else {
      console.error(
        "createdRecord && isRecordUpdated check failed. See entitiesApiRsp: ",
        entitiesApiRsp
      );
      setStatusReadMany(statusFail);
      if (entitiesApiRsp?.metaData?.last_page === false) {
        setDetailsText(
          ": Not all records in database considered during this test."
        );
        console.warn(
          "Not all records in database considered during this test."
        );
      }
      testAlreadyPassedRef.current = false;
      console.error("Fail: useEntitiesQuery");
    }
  }, [entitiesApiRsp, entityUuid, setStatusInParent]);
  return (
    <>
      <b>{statusReadMany}</b>
      <small>{detailsText}</small>
    </>
  );
};
