import CommotionMessages from "../../../../assets/messages/common.en-US.json";
const {
  AG,
  ASSET_IMPROVEMENT,
  ASSET_PURCHASE,
  BUSINESS_CREDIT_CARD,
  BUSINESS_LOC,
  BUSINESS_TERM,
  BUSINESS_USE_VEHICLE,
  C_D,
  C_I,
  COMMERCIAL_CONSTRUCTION,
  CONSTRUCTION,
  CRE,
  CURRENT_ASSET_BASED_LOC,
  DOMESTIC,
  EQUIPMENT,
  FARM_LAND,
  FARM_PRODUCTION,
  GRANT_ANTICIPATION_NOTE,
  INTL,
  LETTER_OF_CREDIT,
  LOC_SECURED_OTHER,
  LOT_DEVELOPMENT,
  NON_OWNER_OCCUPIED,
  NON_RATED_MUNICIPAL_INVESTMENT,
  OTHER,
  OWNER_OCCUPIED,
  PARTICIPATION_BOUGHT,
  PARTICIPATION_SOLD,
  RAW_LAND,
  REFINANCE_DEBT,
  RESIDENTIAL_CONSTRUCTION,
  RESIDENTIAL_INV,
  SECURED,
  TERM_SECURED_OTHER,
  UNSECURED,
  VACANT_LAND_LOT,
  WORKING_CAPITAL
} = CommotionMessages;

// TODO: API also supports the following:
// 'USDA/SBA Fixed Rate', 'USDA/SBA Variable Rate'
export const loanClassOptions = [
  { text: CRE, value: CRE },
  { text: C_D, value: C_D },
  { text: AG, value: AG },
  { text: C_I, value: C_I },
  { text: UNSECURED, value: UNSECURED },
  { text: OTHER, value: OTHER },
  { text: LETTER_OF_CREDIT, value: LETTER_OF_CREDIT },
  { text: BUSINESS_CREDIT_CARD, value: BUSINESS_CREDIT_CARD }
];

export const loanPurposeOptions = [
  { text: REFINANCE_DEBT, value: REFINANCE_DEBT },
  { text: ASSET_PURCHASE, value: ASSET_PURCHASE },
  { text: ASSET_IMPROVEMENT, value: ASSET_IMPROVEMENT },
  { text: WORKING_CAPITAL, value: WORKING_CAPITAL },
  { text: CONSTRUCTION, value: CONSTRUCTION }
];

// see loanTypeByClass below to make use of classCat filtering or loanType below to use as options
// do not export loanTypes directly, it will cause React errors.
export const loanTypes = [
  {
    classCategory: CRE,
    text: RESIDENTIAL_INV,
    value: RESIDENTIAL_INV,
    key: RESIDENTIAL_INV
  },
  {
    classCategory: CRE,
    text: OWNER_OCCUPIED,
    value: OWNER_OCCUPIED,
    key: OWNER_OCCUPIED
  },
  {
    classCategory: CRE,
    text: NON_OWNER_OCCUPIED,
    value: NON_OWNER_OCCUPIED,
    key: NON_OWNER_OCCUPIED
  },
  {
    classCategory: CRE,
    text: VACANT_LAND_LOT,
    value: VACANT_LAND_LOT,
    key: VACANT_LAND_LOT
  },
  {
    classCategory: C_D,
    text: RAW_LAND,
    value: RAW_LAND,
    key: RAW_LAND
  },
  {
    classCategory: C_D,
    text: LOT_DEVELOPMENT,
    value: LOT_DEVELOPMENT,
    key: LOT_DEVELOPMENT
  },
  {
    classCategory: C_D,
    text: COMMERCIAL_CONSTRUCTION,
    value: COMMERCIAL_CONSTRUCTION,
    key: COMMERCIAL_CONSTRUCTION
  },
  {
    classCategory: C_D,
    text: RESIDENTIAL_CONSTRUCTION,
    value: RESIDENTIAL_CONSTRUCTION,
    key: RESIDENTIAL_CONSTRUCTION
  },
  {
    classCategory: AG,
    text: FARM_LAND,
    value: FARM_LAND,
    key: FARM_LAND
  },
  {
    classCategory: AG,
    text: FARM_PRODUCTION,
    value: FARM_PRODUCTION,
    key: FARM_PRODUCTION
  },
  {
    classCategory: C_I,
    text: CURRENT_ASSET_BASED_LOC,
    value: CURRENT_ASSET_BASED_LOC,
    key: CURRENT_ASSET_BASED_LOC
  },
  {
    classCategory: C_I,
    text: EQUIPMENT,
    value: EQUIPMENT,
    key: EQUIPMENT
  },
  {
    classCategory: C_I,
    text: BUSINESS_USE_VEHICLE,
    value: BUSINESS_USE_VEHICLE,
    key: BUSINESS_USE_VEHICLE
  },
  {
    classCategory: C_I,
    text: LOC_SECURED_OTHER,
    value: LOC_SECURED_OTHER,
    key: LOC_SECURED_OTHER
  },
  {
    classCategory: C_I,
    text: TERM_SECURED_OTHER,
    value: TERM_SECURED_OTHER,
    key: TERM_SECURED_OTHER
  },
  {
    classCategory: UNSECURED,
    text: BUSINESS_TERM,
    value: BUSINESS_TERM,
    key: BUSINESS_TERM
  },
  {
    classCategory: UNSECURED,
    text: BUSINESS_LOC,
    value: BUSINESS_LOC,
    key: BUSINESS_LOC
  },
  {
    classCategory: LETTER_OF_CREDIT,
    text: DOMESTIC,
    value: DOMESTIC,
    key: DOMESTIC
  },
  {
    classCategory: LETTER_OF_CREDIT,
    text: INTL,
    value: INTL,
    key: INTL
  },
  {
    classCategory: OTHER,
    text: NON_RATED_MUNICIPAL_INVESTMENT,
    value: NON_RATED_MUNICIPAL_INVESTMENT,
    key: NON_RATED_MUNICIPAL_INVESTMENT
  },
  {
    classCategory: OTHER,
    text: GRANT_ANTICIPATION_NOTE,
    value: GRANT_ANTICIPATION_NOTE,
    key: GRANT_ANTICIPATION_NOTE
  },
  {
    classCategory: BUSINESS_CREDIT_CARD,
    text: SECURED,
    value: SECURED,
    key: SECURED
  },
  {
    classCategory: BUSINESS_CREDIT_CARD,
    text: UNSECURED,
    value: UNSECURED,
    key: UNSECURED
  }
];

export const loanType = loanTypes.map(item => ({
  text: item.text,
  value: item.value,
  key: item.key
}));

export const loanTypeByClass = classItem => {
  const options = [];
  loanTypes.forEach(item => {
    if (item.classCategory === classItem) {
      options.push({
        key: item.key,
        text: item.key,
        value: item.value
      });
    }
  });
  return options;
};

export const rateType = [
  { text: "Variable", value: "Variable" },
  { text: "Fixed", value: "Fixed" }
];

export const rateIndex = [
  { text: "1 Month CMT", value: "1 Month CMT" },
  { text: "3 Month CMT", value: "3 Month CMT" },
  { text: "6 Month CMT", value: "6 Month CMT" },
  { text: "1 Yr CMT", value: "1 Yr CMT" },
  { text: "2 Yr CMT", value: "2 Yr CMT" },
  { text: "3 Yr CMT", value: "3 Yr CMT" },
  { text: "5 Yr CMT", value: "5 Yr CMT" },
  { text: "7 Yr CMT", value: "7 Yr CMT" },
  { text: "10 Yr CMT", value: "10 Yr CMT" },
  { text: "20 Yr CMT", value: "20 Yr CMT" },
  { text: "30 Yr CMT", value: "30 Yr CMT" },
  { text: "Fixed", value: "Fixed" },
  { text: "WSJP", value: "WSJP" },
  { text: "SBA PEG", value: "SBA PEG" }
];

export const adjustmentPeriod = [
  { text: "Daily", value: "Day(s)" },
  { text: "Weekly", value: "Week(s)" },
  { text: "Monthly", value: "Month(s)" },
  { text: "Yearly", value: "Year(s)" },
  { text: "DOM 29", value: "DOM 29" },
  { text: "DOM 30", value: "DOM 30" },
  { text: "End Of Month", value: "EOM(s)" },
  { text: "End Of Quarter", value: "EOQ" },
  { text: "15 & End Of Month", value: "15 & EOM" }
];

export const paymentType = [
  { text: "Principal & Interest (P&I)", value: "P&I" },
  { text: "Interest Only", value: "Interest Only" },
  { text: "Principal", value: "Principal" },
  { text: "Maturity", value: "Maturity" }
];

export const paymentPeriod = [
  { text: "Day(s)", value: "Day(s)" },
  { text: "Week(s)", value: "Week(s)" },
  { text: "Month(s)", value: "Month(s)" },
  { text: "Year(s)", value: "Year(s)" },
  { text: "DOM 29", value: "DOM 29" },
  { text: "DOM 30", value: "DOM 30" },
  { text: "EOM(s)", value: "EOM(s)" },
  { text: "EOQ", value: "EOQ" },
  { text: "15 & EOM", value: "15 & EOM" }
];

export const acctTypeOptions = [
  { text: "Consumer", value: "Consumer" },
  { text: "Commercial", value: "Commercial" }
];

export const loanParticipationOptions = [
  { text: PARTICIPATION_BOUGHT, value: PARTICIPATION_BOUGHT },
  { text: PARTICIPATION_SOLD, value: PARTICIPATION_SOLD }
];

export const loanStatusOptions = [
  { text: "Active", value: "Active" },
  { text: "Active Charge-Off", value: "Active Charge-Off" },
  { text: "Non-Accrual", value: "Non-Accrual" },
  { text: "Inactive Charge-Off", value: "Inactive Charge-Off" },
  { text: "Paid Down", value: "Paid Down" },
  { text: "Paid Off", value: "Paid Off" }
];

const facGrades = "A B C D E F".split(" ");
export const loanFacilityGrade = [];
facGrades.forEach(letter => {
  loanFacilityGrade.push({
    key: letter,
    text: letter,
    value: letter
  });
});

const range = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const dealRatings = [];
range.forEach(num => {
  dealRatings.push({
    key: num,
    text: num,
    value: num
  });
});

export const govGuaranteeOptions = [
  { text: "SBA", value: "sba" },
  { text: "USDA", value: "usda" }
];

export const govGuaranteeAgencyProgramOptions = [
  { text: "504", value: "504" },
  { text: "7a", value: "7a" },
  { text: "Business & Industry", value: "Business & Industry" },
  { text: "Community Facilities", value: "Community Facilities" },
  { text: "Express", value: "Express" },
  { text: "PPP", value: "PPP" },
  { text: "Other", value: "Other" },
  { text: "CapLine", value: "CapLine" }
];

export const serializeLoanClassesData = ({ data, loan_class, loan_type }) => {
  const defaultData = {
    loanClass: null,
    classOptions: [],
    loanType: null,
    typeOptions: null
  };

  if (!data) {
    return defaultData;
  }

  const serializedLoanClassesData = data.reduce((acc, curr) => {
    const result = {
      ...acc,
      classOptions: [
        ...acc.classOptions,
        {
          key: curr.uuid,
          text: curr.name,
          value: curr.name
        }
      ]
    };

    if (loan_class && curr.name === loan_class) {
      result.loanClass = curr;
    }

    // Loan Types
    if (result.loanClass) {
      result.typeOptions = result.loanClass.loan_types.map(lt => ({
        key: lt.uuid,
        text: lt.name,
        value: lt.name // lt.core_loan_type_code || lt.uuid
      }));

      if (loan_type) {
        result.assetType = result.loanClass?.loan_types.find(
          lt => lt.name === loan_type
        );
      }
    }

    return result;
  }, defaultData);

  return serializedLoanClassesData;
};

export const serializeLoanPurposesData = ({ data, loan_purpose }) => {
  const defaultData = {
    loanPurpose: null,
    purposeOptions: []
  };

  if (!data) {
    return defaultData;
  }

  const serializedLoanPurposesData = data.reduce((acc, curr) => {
    const result = {
      ...acc,
      purposeOptions: [
        ...acc.purposeOptions,
        {
          key: curr.uuid,
          text: curr.loan_purpose,
          value: curr.loan_purpose
        }
      ]
    };

    if (loan_purpose && curr.loan_purpose === loan_purpose) {
      result.loanPurpose = curr;
    }

    return result;
  }, defaultData);

  return serializedLoanPurposesData;
};
