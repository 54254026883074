import React from "react";
import PropTypes from "prop-types";
import { Grid, GridColumn, GridRow, Segment } from "semantic-ui-react";

import DropdownFilter from "../../../../components/Dropdown/DropdownFilter";
import InstitutionDropdownFilter from "../../../../components/Dropdown/InstitutionDropdownFilter";

function CombinedTicklerFilters({ onChange, options, loader, values }) {
  return (
    <Segment>
      <Grid>
        <GridRow columns="4" className="LOS">
          <GridColumn>
            <InstitutionDropdownFilter
              filterUpdate={onChange}
              selectedInstitution={values.institution}
              filterValue="filterSelectedInstitution"
              placeholder="Filter by Institution"
              search
            />
          </GridColumn>
          <GridColumn>
            <DropdownFilter
              onChange={onChange}
              options={options.parentRelType}
              loader={loader}
              value={values.parentRelType}
              filterValue="filterSelectedParentRelType"
              placeholder="Filter by Type"
            />
          </GridColumn>
          <GridColumn>
            <DropdownFilter
              onChange={onChange}
              options={options.status}
              loader={loader}
              value={values.status}
              filterValue="filterSelectedStatus"
              placeholder="Filter by Status"
            />
          </GridColumn>
        </GridRow>
      </Grid>
    </Segment>
  );
}

CombinedTicklerFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    parentRelType: PropTypes.array.isRequired,
    status: PropTypes.array.isRequired
  }).isRequired,
  values: PropTypes.shape({
    institution: PropTypes.string.isRequired,
    parentRelType: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired
  }).isRequired,
  loader: PropTypes.node.isRequired
};

export default CombinedTicklerFilters;
