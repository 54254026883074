import { UserInfo } from "../ApiLib";

export default async function FetchUserNameV2(users, dispatch, userUuid) {
  const ifUserExists = Object.keys(users).indexOf(userUuid) !== -1;
  if (!ifUserExists) {
    const userName = await UserInfo.asyncFetchUserName(dispatch, userUuid);
    return userName;
  }
  return users[userUuid];
}
