import { blastApi, Tag } from "./blastApi";
import { ListResponse, PaginatedQueryOptions, SingleResponse } from "./types";
import { Loan } from "./ModelTypes/loan";

import {
  extractData,
  createSingleTag,
  createListTags,
  filteredQuery
} from "./utilities";

const servicePath = "/loans/v1/";

export interface LoanQueryOptions extends PaginatedQueryOptions {
  institutionUuid?: string;
  institution_uuid?: string;
  loan_app_uuid?: string;
  lookup?: string;
}

const extendedApi = blastApi.injectEndpoints({
  endpoints: build => ({
    loan: build.query<Loan, string>({
      query: uuid => `${servicePath}${uuid}`,
      providesTags: result => createSingleTag(result, Tag.Loan),
      transformResponse: (response: SingleResponse<Loan>) =>
        extractData<Loan>(response)
    }),
    loans: build.query<ListResponse<Loan>, LoanQueryOptions>({
      query: filters => filteredQuery(servicePath, filters),
      providesTags: results => createListTags(results, Tag.Loan)
    }),
    createLoan: build.mutation<SingleResponse<Loan>, Partial<Loan>>({
      query: loanData => ({
        url: `${servicePath}`,
        method: "POST",
        body: loanData
      }),
      invalidatesTags: [Tag.Loan]
    }),
    updateLoan: build.mutation<SingleResponse<Loan>, Partial<Loan>>({
      query: loanData => ({
        url: `${servicePath}${loanData.uuid}`,
        method: "PUT", // this should really be a PATCH, but our API isn't structured like that
        body: loanData
      }),
      invalidatesTags: [Tag.Loan]
    }),
    deleteLoan: build.mutation<SingleResponse<Loan>, string>({
      query: uuid => ({
        url: `${servicePath}${uuid}`,
        method: "DELETE"
      }),
      invalidatesTags: [Tag.Loan]
    })
  }),
  overrideExisting: false
});

export const {
  useLoanQuery,
  useLoansQuery,
  useCreateLoanMutation,
  useUpdateLoanMutation,
  useDeleteLoanMutation
} = extendedApi;
