export const formValueTypes = {
  lender_email_2484: "string",
  lender_email_promissory_note: "string",
  received_eidl: "bool",
  ppp_first_draw_loan_amount: "float",
  ppp_first_draw_sba_loan_number: "string",
  period_1_quarter: "select",
  period_1_revenue: "float",
  period_2_quarter: "select",
  period_2_revenue: "float",
  refinance_of_eidl_amount: "float",
  refinance_of_eidl_loan_number: "string",
  sba_status: "string",
  ineligible_bad_loan: "radio",
  ineligible_criminal_charges: "radio",
  ineligible_felony: "radio",
  ineligible_general: "radio",
  applicant_has_reduction_in_gross_receipts: "radio",
  applicant_is_eligible: "checkbox",
  applicant_meets_revenue_test_and_size_standard: "radio",
  applicant_no_shuttered_venue_grant: "radio",
  applicant_wont_receive_another_second_draw: "radio",
  loan_request_is_necessary: "radio",
  is_franchise: "radio",
  franchise_code: "string",
  is_sba_listed_franchise: "radio",
  all_payroll_employees_reside_in_us: "radio"
};

const quarterOptions = [
  {
    key: "1",
    value: "1",
    text: "Q1"
  },
  {
    key: "2",
    value: "2",
    text: "Q2"
  },
  {
    key: "3",
    value: "3",
    text: "Q3"
  },
  {
    key: "4",
    value: "4",
    text: "Q4"
  },
  {
    key: "5",
    value: "5",
    text: "Annual"
  }
];

export const ppp2483Inputs = [
  {
    name: "received_eidl",
    label: "Received EIDL"
  },
  {
    name: "ppp_first_draw_loan_amount",
    label: "PPP First Draw Loan Amount"
  },
  {
    name: "ppp_first_draw_sba_loan_number",
    label: "PPP First Draw SBA Loan Number",
    max: 15
  },
  {
    name: "period_1_quarter",
    label: "Period 1 Quarter",
    options: quarterOptions
  },
  {
    name: "period_1_revenue",
    label: "Period 1 Revenue"
  },
  {
    name: "period_2_quarter",
    label: "Period 2 Quarter",
    options: quarterOptions
  },
  {
    name: "period_2_revenue",
    label: "Period 2 Revenue"
  },
  {
    name: "refinance_of_eidl_amount",
    label: "Refinance Of EIDL Amount"
  },
  {
    name: "refinance_of_eidl_loan_number",
    label: "Refinance Of EIDL Loan Number",
    max: 50
  },
  {
    name: "sba_status",
    label: "SBA Status",
    readOnly: true
  }
].map(input => ({ ...input, type: formValueTypes[input.name] }));

export const pppAttestationInputs = [
  {
    name: "applicant_is_eligible",
    label:
      "The Applicant has certified to the Lender that the Applicant is eligible under the Paycheck Protection Program Rules."
  },
  {
    name: "ineligible_bad_loan",
    label:
      "The Applicant has certified to the Lender that neither the Applicant nor any of its owners, nor any business owned or controlled by any of them, ever obtained a direct or guaranteed loan from SBA or any other Federal agency that is (a) currently delinquent, or (b) has defaulted in the last 7 years and caused a loss to the government."
  },
  {
    name: "ineligible_criminal_charges",
    label:
      "The Applicant has represented to the Lender that neither the Applicant (if an individual) nor any individual owning 20% or more of the equity of the Applicant is presently incarcerated or, for any felony, presently subject to an indictment, criminal information, arraignment, or other means by which formal criminal charges are brought in any jurisdiction."
  },
  {
    name: "ineligible_felony",
    label:
      "The Applicant has represented to the Lender that neither the Applicant (if an individual) nor any individual owning 20% or more of the equity of the Applicant has within the last 5 years, for any felony involving fraud, bribery, embezzlement, or a false statement in a loan application or an application for federal financial assistance, or within the last year for any other felony: 1) been convicted; 2) pleaded guilty; 3) pleaded nolo contendere; or 4) commenced any form of parole or probation (including probation before judgment)."
  },
  {
    name: "ineligible_general",
    label:
      "The Applicant has certified to the Lender that neither the Applicant nor any owner is presently suspended, debarred, proposed for debarment, declared ineligible, voluntarily excluded from participation in this transaction by any Federal department or agency, or presently involved in any bankruptcy."
  }
].map(input => ({ ...input, type: formValueTypes[input.name] }));

export const entityAttestationInputs = [
  // `all_payroll_employees_reside_in_us` is the same as `all_employees_residency` in SBA response
  {
    name: "all_payroll_employees_reside_in_us",
    label:
      "The Applicant has certified that the principal place of residence for all employees included in the Applicant’s payroll calculation is the United States."
  },
  {
    name: "is_sba_listed_franchise",
    label:
      "The Applicant has represented that the franchise is listed in SBA’s Franchise Directory."
  },
  {
    name: "is_franchise",
    label: "The Applicant has represented to the Lender that it is a franchise."
  },
  {
    name: "franchise_code",
    label: "Franchise Code",
    hidden: fields => !!fields.is_franchise
  }
].map(input => ({ ...input, type: formValueTypes[input.name] }));

export const firstDrawInputs = [
  {
    name: "applicant_no_shuttered_venue_grant",

    label:
      "The Applicant has certified to the Lender that (1) it has not and will not receive a Shuttered Venue Operator grant from SBA; (2) the President, the Vice President, the head of an Executive department, or a Member of Congress, or the spouse of such person as determined under applicable common law, does not directly or indirectly hold a controlling interest in the Applicant, with such terms having the meanings provided in Section 322 of the Economic Aid to Hard-Hit Small Businesses, Nonprofits, and Venues Act; and (3) the Applicant is not an issuer, the securities of which are listed on an exchange registered as a national securities exchange under section 6 of the Securities Exchange Act of 1934 (15 U.S.C. 78f)."
  },
  {
    name: "loan_request_is_necessary",
    label:
      "The Applicant has certified to the Lender that (1) it was in operation on February 15, 2020, has not permanently closed, and was either an eligible self-employed individual, independent contractor, or sole proprietorship with no employees or had employees for whom it paid salaries and payroll taxes or paid independent contractors, as reported on Form(s) 1099-MISC; (2) current economic uncertainty makes this loan request necessary to support the ongoing operations of the Applicant; (3) the funds will be used to retain workers and maintain payroll, or make payments for mortgage interest, rent, utilities, covered operations expenditures, covered property damage costs, covered supplier costs, and covered worker protection expenditures; and (4) the Applicant has not and will not receive another loan under the Paycheck Protection Program, section 7(a)(36) of the Small Business Act (15 U.S.C. 636(a)(36)) (this does not include Paycheck Protection Program second draw loans, section 7(a)(37) of the Small Business Act (15 U.S.C. 636(a)(37))."
  },
  {
    name: "applicant_meets_revenue_test_and_size_standard",

    label:
      "The Applicant has certified to the Lender that the Applicant, together with its affiliates (if applicable), (1) is an independent contractor, self-employed individual, or sole proprietor with no employees; (2) if not a housing cooperative, eligible 501(c)(6) organization, or eligible destination marketing organization, employs no more than the greater of 500 employees or, if applicable, meets the size standard in number of employees established by the SBA in 13 C.F.R. 121.201 for the Applicant’s industry; (3) if a housing cooperative, eligible 501(c)(6) organization, or eligible destination marketing organization, employs no more than 300 employees; (4) if NAICS 72, employs no more than 500 employees per physical location; (5) if a news organization that is majority owned or controlled by a NAICS code 511110 or 5151 business or nonprofit public broadcasting entity with a trade or business under NAICS code 511110 or 5151, employs no more than 500 employees (or, if applicable, the size standard in number of employees established by SBA in 13 C.F.R. 121.201 for the Applicant’s industry) per location; or (6) is a small business under the applicable revenue-based size standards established by SBA in 13 C.F.R. 121.201 for the Applicant’s industry or under the SBA alternative size standard"
  }
].map(input => ({ ...input, type: formValueTypes[input.name] }));

export const secondDrawInputs = [
  {
    name: "applicant_wont_receive_another_second_draw",

    label:
      "The Applicant has certified to the Lender that the Applicant Has not and will not receive another Second Draw Paycheck Protection Program Loan"
  },
  {
    name: "loan_request_is_necessary",

    label:
      "The Applicant has certified to the Lender that (1) it was in operation on February 15, 2020, has not permanently closed,and was either an eligible self-employed individual, independent contractor, or sole proprietorship with no employees or had employees for whom it paid salaries and payroll taxes or paid independent contractors, as reported onForm(s) 1099-MISC; (2) current economic uncertainty makes this loan request necessary to support the ongoing operations of theApplicant; (3) it received a First Draw Paycheck Protection Program Loan and, before the Second Draw PPP Loan is disbursed, will have used the full loan amount (including any increase) of the First Draw Paycheck Protection Program Loan only for eligible expenses; (4) the funds will be used to retain workers and maintain payroll, or make payments for mortgage interest, rent, utilities, covered operations expenditures, covered property damage costs, covered supplier costs, and covered worker protection expenditures."
  },

  {
    name: "applicant_has_reduction_in_gross_receipts",
    label:
      "The Applicant has certified to the Lender That it has realized a reduction in gross receipts in excess of 25% relative to the relevant comparison time period. For loans greater than $150,000 (or if the loan is $150,000 or less and the Applicant provides documentation of revenue reduction with its application), the Applicant has provided documentation to the Lender substantiating the decline in gross receipts and the Lender has confirmed the dollar amount and percentage of theApplicant’s decline in gross receipts by performing a good faith review, in a reasonable time, of the Applicant's Calculations And of the gross receipts documentation provided by the Applicant. For loans $150,000 or less that have not provided documentation, the Applicant Has certified to the Lender that it will provide documentation substantiating the decline in gross receipts on or before seeking loan forgiveness or upon SBA request."
  },
  {
    name: "applicant_no_shuttered_venue_grant",

    label:
      "The Applicant has certified to the Lender that (1) it has not and will not receive a ShutteredVenu Operator grant fromSBA ; (2) the President, the Vice President,the head of an Executive department, or a Member of Congress, or the spouse of such person as determined under applicable common law, does not directly or indirectly hold a controlling interest in the Applicant, with such terms having the meanings provided in Section 322 of the EconomicAid to Hard-Hit Small Businesses, Nonprofits, and Venues Act; (3) the Applicant is not an issuer, the securities of which are listed on an exchange registered as a national securities exchange under section 6 of the Securities Exchange Act of 1934(15 U.S.C. 78f); (4) the Applicant is not a business concern or entity (a) for which an entity created or organized under the laws of the People’s Republic of China or the Special Administrative Region ofHong Kong, or that has significant operations in the People’s Republicof China or the Special Administrative Region ofHong Kong, owns or holds, directly or indirectly, not less than 20 percent of the economic interest of the business concern or entity, including a sequity shares or a capital or profit interest in a limited liability company or partnership; or (b) that retains, as a member of the board of directors of the business concern, a person who is a resident of the People’s Republic of China; (5) the Applicant Is not required to submit a registration statement under section 2 of the Foreign Agents Registration Act of 1938 (22 U.S.C.612); and (6) the Applicantis not a business concern or entity primarily engaged in political or lobbying activities, including any entity that is organized for researcher for engaging in advocacy in areas such as public policy or political strategy or otherwise describe itself as think tank in any public documents."
  },
  {
    name: "applicant_meets_revenue_test_and_size_standard",
    label:
      "The Applicant has certified to the Lender that the Applicant, together with its affiliates (if applicable), (1) is an independent contractor, self-employed individual, or sole proprietor with no employees; (2) employs no more than 300 employees; (3) if NAICS72, employs no more than 300 employees per physical location; or (4) if a news organization that is majority owned or controlled by a NAICS code 511110 or 5151 business or a non profit public broadcasting entity with a trade or business under NAICS code 511110 or 5151, employs no more than 300 employees per location."
  }
].map(input => ({ ...input, type: formValueTypes[input.name] }));
