/* eslint-env browser */
import { useCallback, useEffect } from "react";
import { Container } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import DocTitle from "../../../components/DocTitle";
import Tabs from "../Tabs";

function onError(rsp) {
  console.error("Error: ", rsp);
}

const AdvancedSearch = () => {
  const dispatch = useDispatch();
  const EntityManagementReducer = useSelector(
    state => state.EntityManagementReducer
  );

  const selectedEntityUuid = EntityManagementReducer?.selectedEntityUuid;
  // const selectedInstitution = EntityManagementReducer?.selectedInstitution;

  const clearSelectEntity = useCallback(() => {
    if (selectedEntityUuid) {
      dispatch({
        type: "ENT_MGMT_CLEAR_ENTITY"
      });
    }
  }, [dispatch, selectedEntityUuid]);

  useEffect(() => {
    clearSelectEntity();
  }, [clearSelectEntity]);

  return (
    <DocTitle title="CORE Search - BLAST Portal">
      <h1 className="mb-6">Entity Management</h1>
      <Container className="leftAlign" fluid textAlign="left">
        <Tabs />
        <div className="bg-white !rounded-br-5 !rounded-bl-5 p-3 border border-neutral-300 !border-t-0">
          CORE SEARCH
        </div>
      </Container>
    </DocTitle>
  );
};

export default AdvancedSearch;
