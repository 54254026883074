import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Form, Search } from "semantic-ui-react";

import DocumentNames from "../../../../../../services/Constants/DocumentCategories";
// import { dateStringToISO } from '../../../../../../services/DateTime';

const frequencyData = [
  { text: "Once", value: "Once" },
  { text: "Daily", value: "Daily" },
  { text: "Weekly", value: "Weekly" },
  { text: "Biweekly", value: "Biweekly" },
  { text: "Monthly", value: "Monthly" },
  { text: "Quarterly", value: "Quarterly" },
  { text: "Every 4 Months", value: "Every 4 Months" },
  { text: "Every 6 Months", value: "Every 6 Months" },
  { text: "Annually", value: "Annually" },
  { text: "Every 3 Years", value: "Every 3 Years" },
  { text: "Every 5 Years", value: "Every 5 Years" },
  { text: "Every 10 Years", value: "Every 10 Years" }
];

const requiredInputs = [
  "endDate",
  "frequency",
  "currentState",
  "nextDueDate",
  "notificationPeriodInDays",
  "pastDuePeriodInDays",
  "startDate"
];

const dropDown = [
  { text: "Active", value: "Active" },
  { text: "Inactive", value: "Inactive" }
];

class ScheduleFormObj extends Component {
  constructor(props) {
    super(props);

    this.onInputChange = this.onInputChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onChangeReportDate = this.onChangeReportDate.bind(this);
    this.setModalIsValid = this.setModalIsValid.bind(this);
  }

  componentDidMount() {
    const { form } = this.props;
    this.setModalIsValid(form);
  }

  onSearch(e) {
    this.props.dispatch({
      type: "DOC_TRACKING_SCHEDULE_UPDATE_SEARCH",
      search: e.target.value.toLowerCase()
    });
  }

  onSelect(e) {
    this.props.dispatch({
      type: "DOC_TRACKING_SCHEDULE_UPDATE_DOCUMENT_NAME",
      documentName: e.target.innerText
    });
  }

  onChangeReportDate({ target }, key) {
    const { form } = this.props;
    const nextForm = { ...form, [key]: target.value };
    this.setModalIsValid(nextForm);
    this.props.dispatch({
      type: "DOC_TRACKING_SCHEDULE_UPDATE_DATE_RANGE",
      key,
      date: target.value
    });
  }

  onInputChange(name, value) {
    const { form } = this.props;
    const nextForm = { ...form, [name]: value };
    this.setModalIsValid(nextForm);

    this.props.dispatch({
      type: "DOC_TRACKING_SCHEDULE_INPUT_CHANGE",
      name,
      value
    });
  }

  setModalIsValid(form) {
    let modalIsValid = true;
    for (let i = 0; i < requiredInputs.length; i += 1) {
      const input = requiredInputs[i];
      if (!form[input] && form[input] !== 0) {
        modalIsValid = false;
      }
    }

    this.props.dispatch({
      type: "DOC_TRACKING_SCHEDULE_MODAL_IS_VALID",
      modalIsValid
    });
  }

  render() {
    const {
      endDate,
      nextDueDate,
      startDate,
      frequency,
      notificationPeriodInDays,
      pastDuePeriodInDays,
      currentState,
      description
    } = this.props.form;

    let docFilter = "";
    if (this.props.docType === "Credit") docFilter = "Credit File";
    else if (this.props.docType === "Loan") docFilter = "Loan File";
    else docFilter = "Collateral File";

    let results = [];
    results = DocumentNames.filter(obj => obj.doc_category === docFilter);
    const { search } = this.props;
    if (this.props.search) {
      results = results.filter(obj =>
        obj.doc_subcategory.toLowerCase().includes(search.toLowerCase())
      );
    }
    results = results.map((obj, index) => ({
      id: `${obj.doc_subcategory}_${index}`,
      title: obj.doc_subcategory
    }));

    return (
      <Form>
        <Form.Group>
          <Grid.Column style={{ margin: "1%" }}>
            <b>Document Name</b>
            <Form.Field
              as={Search}
              onResultSelect={this.onSelect}
              onSearchChange={this.onSearch}
              results={results}
              value={this.props.search}
            />
          </Grid.Column>
          <Grid.Column style={{ margin: "1%" }}>
            <Form.Field
              control={Form.Input}
              label="Start Date*"
              type="date"
              onChange={e => this.onChangeReportDate(e, "startDate")}
              placeholder="Start date"
              value={startDate || ""}
            />
          </Grid.Column>
          <Grid.Column style={{ margin: "1%" }}>
            <Form.Field
              control={Form.Input}
              label="End Date*"
              type="date"
              onChange={e => this.onChangeReportDate(e, "endDate")}
              placeholder="End date"
              value={endDate || ""}
            />
          </Grid.Column>
          <Grid.Column style={{ margin: "1%" }}>
            <Form.Select
              label="Frequency*"
              id="frequency"
              onChange={(e, { value }) =>
                this.onInputChange("frequency", value)
              }
              options={frequencyData}
              value={frequency}
            />
          </Grid.Column>
          <Grid.Column style={{ margin: "1%" }}>
            <Form.Field
              control={Form.Input}
              type="date"
              label="Next Due Date*"
              onChange={e => this.onChangeReportDate(e, "nextDueDate")}
              placeholder="Next Due date"
              value={nextDueDate || ""}
            />
          </Grid.Column>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            control={Form.Input}
            label="Notification Period (days)*"
            id="notificationPeriodInDays"
            onChange={e => {
              const value = parseInt(e.target.value, 10) || 0;
              this.onInputChange("notificationPeriodInDays", value);
            }}
            value={notificationPeriodInDays}
          />
          <Form.Field
            control={Form.Input}
            type="text"
            label="Past Due Period (days)*"
            id="pastDuePeriodInDays"
            onChange={e => {
              const value = parseInt(e.target.value, 10) || 0;
              this.onInputChange("pastDuePeriodInDays", value);
            }}
            value={pastDuePeriodInDays}
          />
          <Form.Select
            label="Current State*"
            id="currentState"
            onChange={(e, { value }) =>
              this.onInputChange("currentState", value)
            }
            options={dropDown}
            value={currentState}
          />
        </Form.Group>
        <Form.Group>
          <Form.Field
            control={Form.Input}
            label="Description"
            id="description"
            onChange={e => this.onInputChange("description", e.target.value)}
            value={description}
            widths="8"
          />
        </Form.Group>
      </Form>
    );
  }
}

ScheduleFormObj.propTypes = {
  dispatch: PropTypes.func.isRequired,
  docType: PropTypes.string.isRequired,
  form: PropTypes.shape({
    documentName: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    frequency: PropTypes.string,
    nextDueDate: PropTypes.string,
    notificationPeriodInDays: PropTypes.number,
    pastDuePeriodInDays: PropTypes.number,
    currentState: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  search: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  form: state.DocTrackingViewReducer.form,
  search: state.DocTrackingViewReducer.search
});

const ScheduleForm = connect(mapStateToProps)(ScheduleFormObj);

export default ScheduleForm;
