import PropTypes from "prop-types";

const CalculationMethodCell = ({ row }) => {
  return (
    <div className="flex flex-col">
      <div>
        <b>Type:</b> {row?.original?.interest_rate_method || ""}
      </div>
      <div>
        <b>Accrual:</b> {row?.original?.accrual_basis || ""}
      </div>
    </div>
  );
};

export default CalculationMethodCell;

CalculationMethodCell.propTypes = {
  row: PropTypes.shape({})
};
