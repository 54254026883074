import React from "react";
import { Divider } from "semantic-ui-react";
import PropTypes from "prop-types";

function underlineHeader(props) {
  return (
    <Divider
      style={{
        borderTop: props.borderTop,
        width: props.width
      }}
    />
  );
}

underlineHeader.defaultProps = {
  borderTop: "2px solid #30dada",
  width: "30%"
};

underlineHeader.propTypes = {
  borderTop: PropTypes.string,
  width: PropTypes.string
};

export default underlineHeader;
