import React from "react";
import { bool, string } from "prop-types";
import { Button } from "semantic-ui-react";

import { Notifications } from "../../services/ApiLib";
import ErrorReporter from "../../services/ErrorReporter";

class NotificationBell extends React.Component {
  constructor(props) {
    super(props);
    this.state = { subscribed: false };
  }

  componentDidMount() {
    this.getSubscription();
  }

  subscribe = () => {
    const { uuid } = this.props;
    const { subscribed } = this.state;
    if (subscribed) {
      Notifications.unsubscribe(
        () => this.setState({ subscribed: false }),
        () => ErrorReporter("Unsubscribe failed."),
        uuid
      );
    } else {
      Notifications.subscribe(
        () => this.setState({ subscribed: true }),
        () => ErrorReporter("Subscribe failed."),
        uuid
      );
    }
  };

  getSubscription = () => {
    const { uuid } = this.props;
    Notifications.getSubscription(
      ({ data }) => this.setState({ subscribed: !!data }),
      () => ErrorReporter("Failed to update notification subscription status."),
      uuid
    );
  };

  render() {
    const { notSubscribedIcon, subscribedIcon, ...remainingProps } = this.props;
    const { subscribed } = this.state;
    return (
      <Button
        icon={subscribed ? subscribedIcon : notSubscribedIcon}
        onClick={this.subscribe}
        circular
        {...remainingProps}
      />
    );
  }
}

NotificationBell.defaultProps = {
  notSubscribedIcon: "slash bell",
  subscribedIcon: "bell"
};

NotificationBell.propTypes = {
  basic: bool,
  size: string,
  notSubscribedColor: string,
  notSubscribedIcon: string,
  subscribedColor: string,
  subscribedIcon: string,
  uuid: string.isRequired
};

export default NotificationBell;
