/* eslint-env browser */
import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Confirm,
  Select,
  Grid,
  Menu,
  Radio,
  Segment
} from "semantic-ui-react";
import PropTypes from "prop-types";

import EditModal from "./components/ScheduleTable/components/EditModal";
import {
  ObjectMetadata,
  Ticklers,
  Messages,
  Schedule,
  Documents,
  User
} from "../../services/ApiLib";
import NewModal from "./components/ScheduleTable/components/NewModal";
import Constants from "../../services/Constants/strings";
import TicklerTable from "./components/TicklerTable";
import ScheduleTable from "./components/ScheduleTable";
import { dateFromString } from "../../services/DateTime";
import { ConcatName } from "../../services/Entities";
import PaginationControls from "../Pagination";
import {
  CREDIT_MANAGEMENT_ACTION_TYPES,
  LOAN_MANAGEMENT_ACTION_TYPES,
  ASSET_MANAGEMENT_ACTION_TYPES
} from "./constants";
import logger from "../../services/logger";
import { permCheck } from "../../services/Auth";

export function onError(response) {
  logger.error(response);
}

const ticklerStatus = {
  Satisfied: "Overridden",
  Waived: "Waived"
};

const docTrackingType = {
  Entity: CREDIT_MANAGEMENT_ACTION_TYPES(),
  Loan: LOAN_MANAGEMENT_ACTION_TYPES(),
  Asset: ASSET_MANAGEMENT_ACTION_TYPES()
};

function DocTrackingView({
  data: docTrackingData,
  parentType,
  docTrackingItem,
  entityData,
  schedulePaginationName,
  ticklerPaginationName
}) {
  const [docTrackingLastPage, setDocTrackingLastPage] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [newModal, setNewModal] = useState(false);
  const [filterData, setFilterData] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [comment, setComment] = useState("");

  const dispatch = useDispatch();

  const {
    form,
    userUuid,
    messages,
    objectMetadata,
    rowUuid,
    togglerState,
    schedulePagination,
    ticklerPagination
  } = useSelector(state => ({
    form: state.DocTrackingViewReducer.form,
    editModal: state.DocTrackingViewReducer.editModal,
    userUuid: state.auth.userUuid,
    messages: state.DocTrackingViewReducer.messages,
    objectMetadata: state.DocTrackingViewReducer.objectMetadata,
    rowUuid: state.DocTrackingViewReducer.rowUuid,
    togglerState: state.DocTrackingViewReducer.togglerState,
    schedulePagination: state.PaginationReducer[schedulePaginationName],
    ticklerPagination: state.PaginationReducer[ticklerPaginationName]
  }));
  const {
    institution_uuid: institutionUuid,
    company_name: companyName,
    uuid: entityUuid
  } = entityData;

  const parentUuid =
    parentType === "Entity" ? entityData.uuid : entityData.parent_uuid;
  const actionTypes = docTrackingType[parentType];

  function displayLoadError() {
    dispatch({
      type: "CMS_ERROR_LOAD_DOC_LIST",
      errorMessage: Constants.CMS_ERROR_LOAD_DOC_LIST
    });
  }

  const loadDocuments = useCallback(
    async name => {
      let pagination = null;
      switch (name) {
        case Constants.TICKLERS: {
          if (ticklerPagination) {
            pagination = {
              number: ticklerPagination.number,
              size: ticklerPagination.size
            };
          }
          try {
            let ticklers;
            if (togglerState) {
              ticklers = await Ticklers.asyncReadAll(
                parentUuid,
                null,
                pagination
              );
            } else {
              ticklers = await Ticklers.asyncReadAllActive(
                parentUuid,
                null,
                pagination
              );
            }
            ticklers.data.forEach(async doc => {
              const ticklerMessages = await Messages.read(null, {
                parentUuid: doc.uuid,
                number: 0,
                size: 10
              });
              dispatch({
                type: "DOC_TRACKING_TICKLERS_GET_MESSAGES",
                messages: ticklerMessages ? ticklerMessages.data : [],
                uuid: doc.uuid
              });
              if (doc.objectUuid) {
                try {
                  const fetchedObject = await ObjectMetadata.asyncRead(
                    doc.objectUuid,
                    { institutionUuid: doc.institutionUuid },
                    null
                  );
                  dispatch({
                    type: "DOC_TRACKING_TICKLERS_GET_OBJECT_DATA",
                    objectMetadata: fetchedObject.data,
                    uuid: doc.objectUuid
                  });
                } catch (err) {
                  onError(err);
                }
              }
            });
            setDocTrackingLastPage(ticklers.metaData.last_page);
            dispatch({
              type: actionTypes.types.docTrackingItemUpdate,
              data: ticklers.data,
              name
            });
          } catch (err) {
            displayLoadError();
          }
          break;
        }

        case Constants.SCHEDULE: {
          if (schedulePagination) {
            pagination = {
              number: schedulePagination.number,
              size: schedulePagination.size
            };
          }
          try {
            const schedule = await Schedule.get(parentUuid, pagination);
            setDocTrackingLastPage(schedule.metaData.last_page);
            dispatch({
              type: actionTypes.types.docTrackingItemUpdate,
              data: schedule.data,
              name
            });
          } catch (err) {
            onError(err);
          }
          break;
        }
        default:
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [parentUuid, togglerState, schedulePagination, ticklerPagination]
  );

  // Pull Document Tracking information
  useEffect(() => {
    loadDocuments(docTrackingItem);
  }, [docTrackingItem, loadDocuments, schedulePagination, ticklerPagination]);

  async function onComment() {
    try {
      const messageRsp = await Messages.asyncPost({
        parent_uuid: rowUuid,
        message: comment,
        institution_uuid: institutionUuid
      });
      dispatch({
        type: "DOC_TRACKING_TICKLERS_ADD_MESSAGE",
        uuid: rowUuid,
        message: messageRsp.data
      });
      setComment("");
    } catch (err) {
      onError(err);
    }
  }

  async function onConfirm() {
    let state;
    if (confirmModal) {
      state = "Active";
    } else {
      state = "Inactive";
    }
    try {
      const ticklerRsp = await Ticklers.asyncReadAll();
      for (let i = 0; i < ticklerRsp.data.length; i += 1) {
        const doc = ticklerRsp.data[i];
        Ticklers.asyncUpdate(doc.uuid, { currentState: state });
      }
    } catch (err) {
      onError(err);
    }

    try {
      docTrackingData.forEach(async schedule => {
        const updatedSchedule = await Schedule.asyncUpdate(schedule.uuid, {
          currentState: state
        });
        dispatch({
          type: actionTypes.types.saveEditModal,
          form: updatedSchedule.data,
          rowUuid: schedule.uuid
        });
      });
      setShowWarning(!showWarning);
      setConfirmModal(!confirmModal);
    } catch (err) {
      onError(err);
    }
  }

  function getCurrentStatus(dueDate, gracePeriod) {
    let status = "";
    if (new Date() < new Date(dueDate)) status = "Pending Upload";
    else if (
      new Date() >= new Date(dueDate) &&
      new Date() <
        new Date(dueDate).setDate(new Date(dueDate).getDate() + gracePeriod)
    )
      status = "Grace Period";
    else status = "Past Due";

    return status;
  }

  async function onDelete(TicklerRowUuid, objectUuid, dueDate, gracePeriod) {
    try {
      await Ticklers.asyncUpdate(
        { uuid: TicklerRowUuid },
        { objectUuid: "", status: getCurrentStatus(dueDate, gracePeriod) }
      );
      dispatch({
        type: actionTypes.types.ticklerUpload,
        objectUuid,
        rowUuid: TicklerRowUuid
      });
      dispatch({
        type: "DOC_TRACKING_UPDATE_OBJECT_METADATA",
        objectUuid,
        rowUuid: TicklerRowUuid
      });
      loadDocuments(Constants.TICKLERS);
    } catch (err) {
      onError(err);
    }
  }

  async function onDropdownChange(value, TicklerRowUuid) {
    const body = { state: value, status: ticklerStatus[value] };
    try {
      await Ticklers.asyncUpdate({ uuid: TicklerRowUuid }, body);
      dispatch({
        type: actionTypes.types.ticklerDropDown,
        rowUuid: TicklerRowUuid,
        value
      });
    } catch (err) {
      onError(err);
    }
  }

  function onEditModal(scheduleRowUuid) {
    const scheduleFormData = docTrackingData.filter(
      obj => obj.uuid === scheduleRowUuid
    )[0];
    setEditModal(true);
    dispatch({
      type: "DOC_TRACKING_SCHEDULE_UPDATE_EDIT_MODAL",
      form: scheduleFormData,
      rowUuid: scheduleRowUuid,
      editModal
    });
  }

  async function onOpenDocument(objectUuid, ticklerRowUuid) {
    try {
      await Documents.download(
        { uuid: objectUuid },
        { fileName: objectMetadata[ticklerRowUuid].fileName }
      );
    } catch (err) {
      onError(err);
    }
  }

  async function onSave(modal) {
    const formData = {
      ...form,
      startDate: dateFromString(form.startDate),
      endDate: dateFromString(form.endDate),
      nextDueDate: dateFromString(form.nextDueDate),
      documentType: actionTypes.docType,
      parentRelType: parentType,
      entityUuid
    };

    try {
      if (modal === "editModal") {
        delete formData.uuid;
        formData.cqImport = false;
        if (formData.institutionDocumentDefaultsUuid === null) {
          delete formData.institutionDocumentDefaultsUuid;
        }
        const updatedSchedule = await Schedule.asyncUpdate(rowUuid, formData);
        dispatch({
          type: actionTypes.types.saveEditModal,
          form: { ...updatedSchedule.data },
          rowUuid
        });
        setEditModal(false);
      } else {
        formData.parentUuid = parentUuid;
        formData.entityUuid = entityUuid;
        formData.institutionUuid = institutionUuid;
        const addedSchedule = await Schedule.asyncAdd(formData);
        dispatch({
          type: actionTypes.types.saveNewModal,
          form: addedSchedule.data
        });
        setNewModal(false);
      }
    } catch (err) {
      onError(err);
    }
  }

  async function onShowModal(ticklerRowUuid) {
    messages[ticklerRowUuid].forEach(async msg => {
      try {
        const userInfo = await User.asyncGetUserInfo(msg.user_uuid);
        const name = ConcatName({
          first_name: userInfo.first_name,
          last_name: userInfo.last_name
        });
        dispatch({
          type: "DOC_TRACKING_TICKLERS_ADD_NAMES",
          name,
          uuid: msg.uuid
        });
      } catch (err) {
        const name = "Not Found";
        dispatch({
          type: "DOC_TRACKING_TICKLERS_ADD_NAMES",
          name,
          uuid: msg.uuid
        });
      }
    });
    setShowModal(!showModal);
    dispatch({
      type: "DOC_TRACKING_UPDATE_MODAL",
      rowUuid: ticklerRowUuid,
      showModal
    });
  }

  async function onUpload(event, ticklerRowUuid) {
    if (!event.target.files || !event.target.files[0]) {
      return;
    }
    try {
      const fileName = event.target.files[0].name;
      const docs = await ObjectMetadata.asyncDocUpload(
        institutionUuid,
        event.target.files[0]
      );
      const body = { objectUuid: docs.data.uuid, status: "Pending Approval" };

      const updatedTickler = await Ticklers.asyncUpdate(
        { uuid: ticklerRowUuid },
        body
      );
      const {
        documentName,
        description,
        parentUuid: ticklerParentUuid,
        status
      } = updatedTickler.data;
      const category = parentType === "Entity" ? "Credit" : parentType;
      const objectBody = {
        category,
        description: description || "No description",
        docType: documentName,
        documentName,
        entityUuid,
        fileName,
        parentRelType: parentType,
        parentUuid: ticklerParentUuid,
        uploadedBy: userUuid,
        status: "Pending Approval"
      };
      await ObjectMetadata.updateAsync(docs.data.uuid, objectBody);
      dispatch({
        type: actionTypes.types.ticklerUpload,
        objectUuid: docs.data.objectUuid,
        rowUuid: ticklerRowUuid,
        status
      });
      dispatch({
        type: "DOC_TRACKING_TICKLER_UPDATE_DOCUMENT_UPLOAD",
        fileName,
        objectUuid: docs.data.objectUuid,
        rowUuid: ticklerRowUuid,
        status
      });
    } catch (err) {
      onError(err);
    }
  }

  async function onTicklersToggle() {
    try {
      let ticklerFetched;
      if (togglerState) {
        ticklerFetched = await Ticklers.asyncReadAllActive(parentUuid, null, {
          number: 0,
          size: 10
        });
        dispatch({ type: "DOC_TRACKING_TICKLER_TOGGLE_UPDATE" });
      } else {
        ticklerFetched = await Ticklers.asyncReadAll(parentUuid, null, {
          number: 0,
          size: 10
        });
        dispatch({ type: "DOC_TRACKING_TICKLER_TOGGLE_UPDATE" });
      }
      dispatch({
        type: actionTypes.types.ticklerToggle,
        data: ticklerFetched.data,
        activeItem: Constants.DOCUMENT_TRACKING
      });
      ticklerFetched.data.forEach(async doc => {
        try {
          const ticklerMessages = await Messages.readByParentUuid(doc.uuid);
          dispatch({
            type: "DOC_TRACKING_TICKLERS_GET_MESSAGES",
            messages: ticklerMessages ? ticklerMessages.data : [],
            uuid: doc.uuid
          });
        } catch (err) {
          dispatch({
            type: "DOC_TRACKING_TICKLERS_GET_MESSAGES",
            messages: [],
            uuid: doc.uuid
          });
        }
      });
    } catch (err) {
      dispatch({
        type: actionTypes.types.ticklerToggle,
        data: [],
        activeItem: Constants.DOCUMENT_TRACKING
      });
    }
  }

  let ticklerData = docTrackingData || [];
  let table;
  let button;
  let options = ticklerData.map(obj => obj.status);
  options = options.filter((v, i, a) => a.indexOf(v) === i);
  options = options.map(obj => ({
    key: obj || "option",
    text: obj,
    value: obj
  }));
  options.push({ key: "Clear Filter", text: "Clear Filter", value: "clear" });

  if (filterData && filterData !== "clear") {
    ticklerData = docTrackingData.filter(v => v.status === filterData);
  }

  if (docTrackingItem === Constants.TICKLERS) {
    table = (
      <div>
        <TicklerTable
          data={ticklerData}
          comment={comment}
          commentUpdate={({ value }) => setComment(value)}
          onCloseModal={() => {
            setComment("");
            setShowModal(false);
            dispatch({
              type: "DOC_TRACKING_RESET_ROW"
            });
          }}
          onDropdownChange={onDropdownChange}
          onComment={onComment}
          onDelete={onDelete}
          onOpenDocument={onOpenDocument}
          onShowModal={onShowModal}
          onUpload={onUpload}
          showModal={showModal}
          rowUuid={rowUuid}
        />
        <PaginationControls
          lastPage={docTrackingLastPage}
          name={ticklerPaginationName}
        />
      </div>
    );
    button = (
      <Grid>
        <Grid.Row textAlign="left" columns={2}>
          <Grid.Column>
            <Select
              data-testid="filter-doc-tracking"
              onChange={(e, { value }) => setFilterData(value)}
              options={options}
              placeholder="Filter by..."
              value={filterData && filterData !== "clear" ? filterData : null}
            />
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Checkbox
              data-testid="past-tickler-toggle"
              checked={togglerState}
              toggle
              onClick={onTicklersToggle}
              label="Include Past Ticklers"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  } else if (docTrackingItem === Constants.SCHEDULE) {
    table = (
      <div>
        <ScheduleTable data={docTrackingData} onEditModal={onEditModal} />
        <PaginationControls
          lastPage={docTrackingLastPage}
          name={schedulePaginationName}
        />
      </div>
    );
    button = (
      <Grid>
        <Grid.Row columns="2">
          <Grid.Column textAlign="left">
            <Button
              data-testid="doc-tracking-new-modal-button"
              onClick={() => {
                setNewModal(true);
                dispatch({
                  type: "DOC_TRACKING_RESET_ROW"
                });
              }}
            >
              New
            </Button>
          </Grid.Column>
          <Grid.Column textAlign="right" verticalAlign="middle">
            <Radio
              checked={confirmModal}
              label="Disable All"
              onClick={() => setShowWarning(!showWarning)}
              data-testid="disable-schedule-toggle"
              toggle
            />
            <Confirm
              content={`You are disabling all of the schedule and ticklers for ${companyName}.
                  Are you really, really, sure you want to do this?`}
              header="Disable All"
              onCancel={() => setShowWarning(!showWarning)}
              onConfirm={onConfirm}
              open={showWarning}
              data-testid="disable-confirm-modal"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  return (
    <Segment.Group>
      <Segment>
        <Menu pointing secondary color="black">
          <Menu.Item
            active={docTrackingItem === Constants.TICKLERS}
            color="green"
            name={Constants.TICKLERS}
            onClick={() => loadDocuments(Constants.TICKLERS)}
          />
          <Menu.Item
            active={docTrackingItem === Constants.SCHEDULE}
            color="green"
            name={Constants.SCHEDULE}
            onClick={() => loadDocuments(Constants.SCHEDULE)}
          />
        </Menu>
        {button}
      </Segment>
      <Segment>{table}</Segment>
      {permCheck([
        "post_doc_schedule",
        "post_doc_schedule_unfiltered",
        "admin"
      ]) && (
        <NewModal
          newModal={newModal}
          onCloseNewModal={() => setNewModal(false)}
          onSave={onSave}
          docType={actionTypes.docType}
        />
      )}
      <EditModal
        editModal={editModal}
        onCloseEditModal={() => setEditModal(false)}
        onEditSave={onSave}
        scheduleUuid={rowUuid}
        docType={actionTypes.docType}
      />
    </Segment.Group>
  );
}

DocTrackingView.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  docTrackingItem: PropTypes.string,
  entityData: PropTypes.shape().isRequired,
  parentType: PropTypes.string.isRequired,
  schedulePaginationName: PropTypes.string.isRequired,
  ticklerPaginationName: PropTypes.string.isRequired
};

export default DocTrackingView;
