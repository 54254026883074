import { FormattedMessage } from "react-intl";
import { MessageProps } from "../../../../types/text";

/**
 * A gray box that holds a left-aligned heading and description, and children. Per the component's name, the children will often be (but do not have to be) form-related.
 */
export function FormSection({
  children,
  description,
  heading,
  "data-testid": testId
}: React.PropsWithChildren<FormSectionProps>) {
  return (
    <div className="ui message" data-testid={testId}>
      <div className="ui grid">
        <div className="four wide column">
          <h3 className="header">
            {"id" in heading ? (
              <FormattedMessage {...heading} />
            ) : (
              heading.defaultMessage
            )}
          </h3>
          {description && (
            <span className="ui description">
              {"id" in description ? (
                <FormattedMessage {...description} />
              ) : (
                description.defaultMessage
              )}
            </span>
          )}
        </div>
        <div className="twelve wide column">{children}</div>
      </div>
    </div>
  );
}

export interface FormSectionProps {
  /** A description for the heading. If the value of `id` doesn't
   * exist then the value of `defaultMessage` will be displayed. */
  description?: MessageProps;
  /** A heading. If the value of `id` doesn't
   * exist then the value of `defaultMessage` will be displayed. */
  heading: MessageProps;
  /** This prop is required for this component's tests */
  "data-testid"?: string;
}
