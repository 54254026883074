import "font-awesome/css/font-awesome.min.css";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DocTitle from "../../../../components/DocTitle";

import DocMgmtTable from "./components/DocMgmtTable";

export function DocMgmtPageObj(props) {
  return (
    <DocTitle title="LOR Approval - BLAST Portal">
      <DocMgmtTable
        currentPage={props.paginationParams.currentPage}
        docs={props.docs}
        errorMessage={props.errorMessage}
        filters={props.filters}
        formData={props.formData}
        jwt={props.jwt}
        loading={props.loading}
        pageCount={props.paginationParams.pageCount}
        rowCount={props.paginationParams.rowCount}
        rowEnd={props.paginationParams.rowEnd}
        rowsPerPage={props.paginationParams.rowsPerPage}
        rowStart={props.paginationParams.rowStart}
      />
    </DocTitle>
  );
}

DocMgmtPageObj.propTypes = {
  docs: PropTypes.arrayOf(PropTypes.object).isRequired,
  errorMessage: PropTypes.string.isRequired,
  filters: PropTypes.shape({
    category: PropTypes.string,
    institution: PropTypes.string,
    name: PropTypes.string,
    subcategory: PropTypes.string,
    tin: PropTypes.string
  }).isRequired,
  formData: PropTypes.shape({
    category: PropTypes.string,
    institution: PropTypes.string,
    name: PropTypes.string,
    subcategory: PropTypes.string,
    tin: PropTypes.string
  }).isRequired,
  jwt: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  paginationParams: PropTypes.shape({
    currentPage: PropTypes.number,
    pageCount: PropTypes.number,
    rowCount: PropTypes.number,
    rowEnd: PropTypes.number,
    rowsPerPage: PropTypes.number,
    rowStart: PropTypes.number,
    totalPages: PropTypes.number
  }).isRequired
};

const mapStateToProps = state => ({
  docs: state.DocMgmtReducer.docs,
  errorMessage: state.DocMgmtReducer.errorMessage,
  filters: state.DocMgmtReducer.filters,
  formData: state.DocMgmtReducer.formData,
  jwt: state.auth.jwt,
  loading: state.DocMgmtReducer.loading,
  paginationParams: state.DocMgmtReducer.paginationParams
});

const DocMgmtPage = connect(mapStateToProps)(DocMgmtPageObj);

export default DocMgmtPage;
