import React from "react";
import { Modal } from "semantic-ui-react";
import PropTypes from "prop-types";

function BusyModal({ header, message, open }) {
  return (
    <Modal open={open} size="small">
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <p>{message}</p>
      </Modal.Content>
    </Modal>
  );
}

BusyModal.propTypes = {
  header: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
};

export default BusyModal;
