import React from "react";
import { Route } from "react-router-dom";
import DocTitle from "../../components/DocTitle";

import CreditManagementMain from "./scenes/MainLanding";

function CreditManagement() {
  return (
    <div>
      <DocTitle title="Entity Management - BLAST Portal">
        <Route
          path="/credit_management/entity/:uuid"
          render={({ match }) => (
            <CreditManagementMain entityUuid={match.params.uuid} />
          )}
        />
      </DocTitle>
    </div>
  );
}

export default CreditManagement;
