import React from "react";
import PropTypes from "prop-types";
import { Menu, Icon } from "semantic-ui-react";

function BorrowerTabs({
  borrowers,
  selectedBorrower,
  readOnly,
  onSelectedBorrowerChange,
  onAddBorrower
}) {
  return (
    <Menu pointing secondary>
      {borrowers.map(borrower => (
        <Menu.Item
          key={borrower.uuid}
          active={selectedBorrower.uuid === borrower.uuid}
          name={borrower.primary_borrower ? "Primary Borrower" : "Co-Borrower"}
          onClick={() => onSelectedBorrowerChange(borrower)}
        />
      ))}
      <Menu.Item key="plus">
        <Icon
          disabled={readOnly}
          name="plus"
          color={readOnly ? "grey" : "green"}
          onClick={onAddBorrower}
          className="cursor-pointer"
          aria-label="add borrower"
        />
      </Menu.Item>
    </Menu>
  );
}

BorrowerTabs.propTypes = {
  borrowers: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      primary_borrower: PropTypes.bool
    })
  ),
  selectedBorrower: PropTypes.shape({
    uuid: PropTypes.string
  }),
  readOnly: PropTypes.bool,
  onSelectedBorrowerChange: PropTypes.func,
  onAddBorrower: PropTypes.func
};

export default BorrowerTabs;
