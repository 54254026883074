import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Modal, Form, Header, Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import relationshipsUpdate from "../../services/relationshipsUpdate";
import { ConcatName } from "../../../../../../../../services/Entities";
import { ErrorReport } from "../../../../../../../../services/ErrorReporter";
import {
  Entities,
  Relationships,
  Cms
} from "../../../../../../../../services/ApiLib";
import {
  relationshipParentChildTypes,
  relationshipTypes
} from "../../../../../../../../services/Constants/relationshipTypes";
import Constants from "../../../../../../../../services/Constants/strings";

import { EntityModal } from "../../../../../../../../components/Modals";
import logger from "../../../../../../../../services/logger";

function onError(rsp) {
  logger.error("Error: ", rsp); // TODO: Handle errors properly
}

export class LoanRelAddModalObj extends Component {
  constructor() {
    super();
    this.state = {
      entityModalOpen: false
    };

    this.handleAddModalClose = this.handleAddModalClose.bind(this);
    this.handleChangeForm = this.handleChangeForm.bind(this);
    this.handleCreateNewEntity = this.handleCreateNewEntity.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.onCloseEntityModal = this.onCloseEntityModal.bind(this);
    this.onSuccessEntityPost = this.onSuccessEntityPost.bind(this);
    this.saveRelationship = this.saveRelationship.bind(this);
    this.selectedEntity = this.selectedEntity.bind(this);
    this.selectedEntityType = this.selectedEntityType.bind(this);
    this.setCoborrowerDetails = this.setCoborrowerDetails.bind(this);
    this.setGuarantorDetails = this.setGuarantorDetails.bind(this);
    this.lookupEntityUuid = this.lookupEntityUuid.bind(this);
    this.updateDetails = this.updateDetails.bind(this);
    this.onError = this.onError.bind(this);
  }

  onError(thrownError) {
    if (this.props) {
      const { activeItem } = this.props;
      const messageForUser = `Error: Could not save relationship for ${activeItem}.`;
      ErrorReport.toModal(thrownError, messageForUser);
    } else {
      logger.error("onError(thrownError): ", thrownError);
    }
  }

  onSuccessEntityPost() {
    if (this.props.activeItem === Constants.COBORROWERS) {
      relationshipsUpdate(
        this.props.activeItem,
        this.props.loanUuid,
        this.props.jwt,
        this.setCoborrowerDetails
      );
    } else if (this.props.activeItem === relationshipTypes.GUARANTOR) {
      relationshipsUpdate(
        this.props.activeItem,
        this.props.loanUuid,
        this.props.jwt,
        this.setGuarantorDetails
      );
    }

    this.handleAddModalClose();
    this.onCloseEntityModal();
  }

  onCloseEntityModal() {
    this.setState({
      entityModalOpen: false
    });
    this.props.dispatch({
      type: "ENT_MGMT_CLEAR_ENTITY"
    });
    this.props.dispatch({
      type: "LOAN_MGMT_SELECTED_ENTITY",
      selectedEntity: ""
    });
  }

  setCoborrowerDetails({ data }) {
    this.updateDetails(data);

    this.props.dispatch({
      type: "LOAN_MGMT_SET_COBORROWER_DETAILS",
      loanCoborrowers: data
    });
  }

  setGuarantorDetails({ data }) {
    this.updateDetails(data);

    this.props.dispatch({
      type: "LOAN_MGMT_SET_GUARANTOR_DETAILS",
      loanGuarantors: data
    });
  }

  async lookupEntityUuid(childUuid, relUuid) {
    try {
      const cmsEntity = await Cms.asyncRead(childUuid);
      if (cmsEntity.data) {
        this.props.dispatch({
          type: "LOAN_MGMT_SET_LOOKUP_DETAILS",
          relUuid,
          entityData: cmsEntity.data
        });
      }
    } catch (err) {
      this.onError(err);
    }
  }

  updateDetails(loanRelationships) {
    loanRelationships.forEach(loanRel => {
      this.lookupEntityUuid(loanRel.child_uuid, loanRel.uuid);
    });
  }

  async updateSearchValue(data) {
    this.props.dispatch({
      type: "LOAN_MGMT_UPDATE_SEARCH_STRING",
      searchString: data.searchQuery
    });
    const uuids = {
      lookupString: data.searchQuery,
      institutionUuid: this.props.institutionUuid
    };
    try {
      const entityFetched = await Entities.asyncSearchByInstitutionUuid(uuids);
      if (entityFetched.data) {
        this.props.dispatch({
          type: "LOAN_MGMT_LOAD_ENTITIES",
          data: entityFetched.data
        });
      }
    } catch (err) {
      onError(err);
    }
  }

  selectedEntity(data) {
    this.props.dispatch({
      type: "LOAN_MGMT_SELECTED_ENTITY",
      selectedEntity: data.value
    });
  }

  selectedEntityType(data) {
    this.props.dispatch({
      type: "LOAN_MGMT_SELECTED_ENTITY_TYPE",
      newEntityType: data.value
    });
  }

  saveRelationship(childUuid) {
    const { parentUuid, institutionUuid, activeItem } = this.props;
    let singularRelType;
    if (activeItem === Constants.COBORROWERS) {
      singularRelType = relationshipTypes.BORROWER;
    } else {
      singularRelType = activeItem;
    }

    let type;

    switch (this.props.activeItem) {
      case Constants.COBORROWERS: {
        type = "LOAN_MGMT_SET_COBORROWER_DETAILS";
        break;
      }

      case relationshipTypes.GUARANTOR: {
        type = "LOAN_MGMT_SET_GUARANTOR_DETAILS";
        break;
      }

      default:
        break;
    }

    const body = {
      parent_uuid: parentUuid,
      child_uuid: childUuid,
      institution_uuid: institutionUuid,
      rel_type: singularRelType,
      amount_or_percent: "percent",
      full_vs_limited: "Full",
      amount_guaranteed: 0,
      percent_guaranteed: 0,
      parent_type: relationshipParentChildTypes.LOAN,
      child_type: relationshipParentChildTypes.ENTITY
    };

    const callbackData = { dispatchType: type };
    const jwt = "deprecated and now ignored";
    Relationships.add(
      body,
      jwt,
      this.onSuccessEntityPost,
      this.onError,
      callbackData
    );
  }

  handleAddModalClose() {
    this.props.dispatch({
      type: "LOAN_MGMT_CLOSE_ADD_MODAL"
    });
    this.props.dispatch({
      type: "LOAN_MGMT_SELECTED_ENTITY",
      selectedEntity: ""
    });
    this.props.dispatch({
      type: "LOAN_MGMT_LOAD_ENTITIES",
      data: []
    });
    this.props.dispatch({
      type: "LOAN_MGMT_CLEAR_SEARCH_STRING"
    });
  }

  handleSelect() {
    if (this.props.selectedEntity !== "newEntity") {
      this.saveRelationship(this.props.selectedEntity);
      this.handleAddModalClose();
    }
  }

  handleNext() {
    if (this.props.newEntityType !== "select entity type") {
      this.handleAddModalClose();
      this.setState({
        entityModalOpen: true
      });
    }
  }

  modalContent() {
    const entityOptions = () => {
      const { entities, activeItem } = this.props;
      const mapTinValue = entities.map((entity, index) => ({
        key: index,
        text: `${entity.tin} | ${ConcatName(entity)}`,
        value: entity.uuid
      }));
      const entityType =
        activeItem === Constants.COBORROWERS
          ? Constants.COBORROWERSTRING
          : Constants.GURANTORSSTRING;
      const isNotEmpty = this.props[entityType].length !== 0;
      if (isNotEmpty) {
        return mapTinValue.filter(
          elem =>
            !this.props[entityType].find(
              ({ child_uuid: childUuid }) => elem.value === childUuid
            )
        );
      }
      return mapTinValue;
    };

    const entityFilterOptions = this.props.entities ? entityOptions() : [];

    let moreAvailable = false;

    if (entityFilterOptions.length > 9) {
      entityFilterOptions.length = 9;
      moreAvailable = true;
    }

    return (
      <Modal.Content>
        <Form>
          <Header size="small" color="blue" textAlign="left">
            Search TIN/Name:
          </Header>
          {this.toggleCreateEntityButton(moreAvailable)}
          <Dropdown
            icon="search"
            placeholder="Enter Entity Name"
            search
            scrolling
            fluid
            selection
            options={entityFilterOptions}
            data-testid="loan-rel-dropdown"
            onSearchChange={(e, data) => this.updateSearchValue(data)}
            onChange={(e, data) => this.selectedEntity(data)}
            selectOnBlur={false}
          />
        </Form>
      </Modal.Content>
    );
  }

  handleCreateNewEntity() {
    this.setState({
      entityModalOpen: true
    });
  }

  toggleCreateEntityButton(entityOverflow) {
    const { entitySearchString, selectedEntity } = this.props;

    if (entitySearchString && !selectedEntity) {
      if (entityOverflow) {
        return <div>&nbsp;More Results Available</div>;
      }
      if (!entityOverflow) {
        return (
          <Button color="green" onClick={this.handleCreateNewEntity}>
            Create New Entity
          </Button>
        );
      }
    }
    return null;
  }

  modalActions() {
    return (
      <Modal.Actions>
        <Button
          basic
          key="cancel"
          content="Cancel"
          onClick={() => this.handleAddModalClose()}
        />
        <Button
          color="green"
          key="select"
          content="Select"
          onClick={() => this.handleSelect()}
        />
      </Modal.Actions>
    );
  }

  // Methods for the EntityModal are below
  handleChangeForm(name, value) {
    this.props.dispatch({
      type: "ENT_MGMT_DATA_ENTRY",
      value,
      name
    });
  }

  saveEntityFormData(entityData, isMailingAddress) {
    const body = {
      ...entityData,
      institution_uuid: this.props.institutionUuid
    };

    if (isMailingAddress) {
      body.mail_address_city = body.phy_address_city;
      body.mail_address_country = "USA";
      body.mail_address_line1 = body.phy_address_line1;
      body.mail_address_line2 = body.phy_address_line2;
      body.mail_address_state = body.phy_address_state;
      body.mail_address_zip = body.phy_address_zip;
    }

    const cleanEntityData = Object.keys(body).reduce((acc, key) => {
      if (body[key] !== null && body[key] !== undefined) {
        acc[key] = body[key];
      }
      return acc;
    }, {});

    Entities.add(this.onSuccessEntityPost, onError, cleanEntityData);
  }

  render() {
    const { newEntityType, loanRelAddModalOpen, institutionUuid } = this.props;
    const { entityModalOpen } = this.state;
    return (
      <div>
        <Modal
          open={loanRelAddModalOpen}
          size="mini"
          onClose={this.handleAddModalClose}
        >
          {this.modalContent()}
          {this.modalActions()}
        </Modal>
        {entityModalOpen ? (
          <EntityModal
            open
            add
            selectedInstitution={institutionUuid}
            newEntityType={newEntityType}
            onSuccess={this.onSuccessEntityPost}
            onCloseModal={this.onCloseEntityModal}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

LoanRelAddModalObj.defaultProps = {
  entitySearchString: undefined,
  entities: undefined
};

LoanRelAddModalObj.propTypes = {
  activeItem: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  entities: PropTypes.arrayOf(PropTypes.object),
  entitySearchString: PropTypes.string,
  institutionUuid: PropTypes.string.isRequired,
  jwt: PropTypes.string.isRequired,
  loanRelAddModalOpen: PropTypes.bool.isRequired,
  newEntityType: PropTypes.string.isRequired,
  parentUuid: PropTypes.string.isRequired,
  selectedEntity: PropTypes.string.isRequired,
  loanUuid: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  activeItem: state.LoanManagementRelationshipsReducer.activeItem,
  editModalStepNumber: state.EntityManagementReducer.editModalStepNumber,
  entities: state.LoanManagementRelationshipsReducer.entities,
  entitySearchString:
    state.LoanManagementRelationshipsReducer.entitySearchString,
  institutionUuid: state.LoanManagementReducer.loan.institution_uuid,
  isMailingAddress: state.EntityManagementReducer.isMailingAddress,
  jwt: state.auth.jwt,
  coBorrowers: state.LoanManagementRelationshipsReducer.coBorrowers,
  guarantors: state.LoanManagementRelationshipsReducer.guarantors,
  loanRelAddModalOpen:
    state.LoanManagementRelationshipsReducer.loanRelAddModalOpen,
  loanUuid: state.LoanManagementReducer.loan.uuid,
  newEntityType: state.LoanManagementRelationshipsReducer.newEntityType,
  parentUuid: state.LoanManagementReducer.loan.uuid,
  selectedEntity: state.LoanManagementRelationshipsReducer.selectedEntity
});

const LoanRelAddModal = connect(mapStateToProps)(LoanRelAddModalObj);

export default LoanRelAddModal;
