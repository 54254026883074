import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Modal,
  Grid,
  Header,
  Label,
  Table,
  Button,
  Icon
} from "semantic-ui-react";
import { Form as Formsy } from "formsy-semantic-ui-react";
import { omit } from "lodash";

import {
  opinionOptions,
  statementTypeOptions,
  sourceDocumentOptions,
  sourceDocumentOptionsProjection,
  periodOptions,
  accountingMethodOptions
} from "../../../../../../services/FormElements/components/CreditManagementOptions";
import SemanticDatePicker from "../../../../../../components/SemanticDatePicker";
import Constants from "../../../../../../services/Constants/strings";
import {
  financialModelInfo,
  ifFinancialModelExists
} from "../../../../../../services/Constants/Models";
import { prettyDate } from "../../../../../../services/DateTime";

const AUTO_SPREAD_FORMS = [
  "Tax Return - Form 1040",
  "Tax Return - Form 1120",
  "Tax Return - Form 1120S",
  "Tax Return - Form 1065"
];

export class AddDuplicateModalObj extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.disableButton = this.disableButton.bind(this);
    this.enableButton = this.enableButton.bind(this);
    this.updateSelectedDocument = this.updateSelectedDocument.bind(this);
    this.state = { submitEnabled: true };
  }

  handleChange({ value }, item) {
    const { dispatch } = this.props;
    dispatch({
      type: "CMS_ADD_DUP_DATA_ENTRY",
      value,
      item
    });
  }

  handleChangeDate(value, name) {
    const { dispatch } = this.props;
    dispatch({
      type: "CMS_ADD_DUP_DATA_ENTRY",
      value,
      item: name
    });
  }

  checkIfFinancialModelNameExists = dupFormData => {
    if (dupFormData.financialModelUuid) {
      if (financialModelInfo[dupFormData.financialModelUuid]) {
        return financialModelInfo[dupFormData.financialModelUuid].name;
      }
      return false;
    }
    return false;
  };

  disableButton() {
    this.setState({ submitEnabled: false });
  }

  enableButton() {
    this.setState({ submitEnabled: true });
  }

  updateSelectedDocument(doc) {
    const { dispatch } = this.props;
    let docName = `${prettyDate(doc.uploadDate)}-${doc.docType}`;
    if (doc.description !== "") {
      docName = `${docName}-${doc.description}`;
    }

    dispatch({
      type: "CMS_UPDATE_SELECTED_DOCUMENT",
      docName
    });

    dispatch({
      type: "CMS_ADD_DUP_DATA_ENTRY",
      value: doc.uuid,
      item: "documentUuid"
    });
  }

  render() {
    const {
      addDocumentCallback,
      addDupFormError,
      dupFormData,
      duplicateDocumentCallback,
      editDocumentCallback,
      docName,
      documents,
      isDuplicate,
      isUpdate,
      allProductionModels,
      openState,
      close
    } = this.props;
    function getModelOptions() {
      if (!allProductionModels) return [];

      const isSelectedModelListed =
        !dupFormData.FinancialSpreadsModelsModel ||
        dupFormData.FinancialSpreadsModelsModel.status === "Production";
      const selectedModel = dupFormData.financialModelUuid
        ? {
            uuid: dupFormData.financialModelUuid,
            ...dupFormData.FinancialSpreadsModelsModel
          }
        : null;
      return [
        ...(isSelectedModelListed ? [] : [selectedModel]),
        ...allProductionModels
      ].map(model => {
        let hasIcon = "";

        if (
          model.autoSpreadsEnabled &&
          AUTO_SPREAD_FORMS.includes(dupFormData.sourceDocument)
        ) {
          hasIcon = "cogs";
        }

        return {
          text: ifFinancialModelExists(model),
          value: model.uuid,
          icon: hasIcon
        };
      });
    }
    const { submitEnabled } = this.state;
    const generateKey = pre => `${pre}_${new Date().getTime()}`;

    let submitFunc;

    if (isDuplicate) {
      submitFunc = duplicateDocumentCallback;
    } else if (isUpdate) {
      submitFunc = editDocumentCallback;
    } else {
      submitFunc = addDocumentCallback;
    }

    let opinionAttribsOptions;
    let opinionAttribsValue;
    let opinionAttribsDisabled;
    let opinionAttribsRequired;
    if (dupFormData.sourceDocument === "Audited") {
      opinionAttribsOptions = opinionOptions;
      opinionAttribsValue = dupFormData.opinion;
      opinionAttribsDisabled = false;
      opinionAttribsRequired = true;
    } else {
      opinionAttribsOptions = [{ text: "None", value: "None" }];
      opinionAttribsValue = "None";
      opinionAttribsDisabled = true;
      opinionAttribsRequired = true;
    }
    const selectedDoc = docName;
    const tableRows = [];
    documents.map(doc => {
      const row = (
        <Table.Row
          active={dupFormData.documentUuid === doc.uuid}
          id={doc.uuid}
          key={generateKey(doc.uuid)}
          onClick={() => this.updateSelectedDocument(doc)}
        >
          <Table.Cell>{doc.docType}</Table.Cell>
          <Table.Cell>{prettyDate(doc.uploadDate)}</Table.Cell>
          <Table.Cell>{doc.description}</Table.Cell>
        </Table.Row>
      );
      tableRows.push(row);
      return tableRows;
    });
    let enabled = true;
    if (submitEnabled) {
      enabled = false;
    }

    function SubmitButton(props) {
      if (props.isDuplicate) {
        return (
          <Button
            name="duplicateSubmit"
            disabled={enabled}
            onClick={() => {
              duplicateDocumentCallback(
                omit(dupFormData, "FinancialSpreadsModelsModel")
              );
              close();
            }}
          >
            Duplicate
          </Button>
        );
      }
      if (props.isUpdate) {
        return (
          <Button
            name="editSubmit"
            disabled={enabled}
            onClick={() => {
              editDocumentCallback(dupFormData);
              close();
            }}
          >
            Save
          </Button>
        );
      }
      return (
        <Button
          name="addSubmit"
          disabled={enabled}
          onClick={() => {
            addDocumentCallback(dupFormData);
            close();
          }}
        >
          Add
        </Button>
      );
    }

    return (
      <Modal size="large" open={openState} onClose={close}>
        <Modal.Header>Statement Information</Modal.Header>
        <Modal.Content scrolling>
          <Formsy
            onValidSubmit={submitFunc}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
            className="django-cms-adddup-left-align"
          >
            <Grid>
              <Grid.Row columns="3">
                <Grid.Column width="5">
                  <Formsy.Select
                    name="statementType"
                    id="statementType"
                    label={Constants.ANALYSIS_TYPE}
                    options={statementTypeOptions}
                    placeholder={<>&mdash;Please make selection&mdash;</>}
                    value={dupFormData.statementType}
                    onChange={(e, data) =>
                      this.handleChange(data, "statementType")
                    }
                    required
                  />
                </Grid.Column>
                <Grid.Column width="5">
                  <Formsy.Select
                    name="sourceDocument"
                    id="sourceDocument"
                    label={Constants.STATEMENT_TYPE}
                    options={
                      dupFormData.statementType === "Projection"
                        ? sourceDocumentOptionsProjection
                        : sourceDocumentOptions
                    }
                    placeholder={<>&mdash;Please make selection&mdash;</>}
                    value={dupFormData.sourceDocument}
                    onChange={(e, data) =>
                      this.handleChange(data, "sourceDocument")
                    }
                  />
                </Grid.Column>
                <Grid.Column width="5">
                  <SemanticDatePicker
                    date={dupFormData.statementDate}
                    label={Constants.STATEMENT_DATE}
                    name="statementDate"
                    id="statementDate"
                    onChange={e => this.handleChangeDate(e, "statementDate")}
                    placeholder="choose a date"
                    required
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="3">
                <Grid.Column width="5">
                  <Formsy.Select
                    name="period"
                    id="period"
                    label={Constants.PERIOD}
                    options={periodOptions}
                    placeholder={<>&mdash;Please make selection&mdash;</>}
                    value={dupFormData.period}
                    onChange={(e, data) => this.handleChange(data, "period")}
                    required
                  />
                </Grid.Column>
                <Grid.Column width="5">
                  <SemanticDatePicker
                    label={Constants.START_DATE}
                    date={dupFormData.startDate}
                    name="startDate"
                    id="startDate"
                    onChange={e => this.handleChangeDate(e, "startDate")}
                    required
                  />
                </Grid.Column>
                <Grid.Column width="5">
                  <SemanticDatePicker
                    label={Constants.END_DATE}
                    date={dupFormData.endDate}
                    name="endDate"
                    id="endDate"
                    onChange={e => this.handleChangeDate(e, "endDate")}
                    required
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row width="3">
                <Grid.Column width="5">
                  <Formsy.Select
                    name="accountingMethod"
                    id="accountingMethod"
                    label={Constants.ACCOUNTING_METHOD}
                    options={accountingMethodOptions}
                    placeholder={<>&mdash;Please make selection&mdash;</>}
                    value={dupFormData.accountingMethod}
                    onChange={(e, data) =>
                      this.handleChange(data, "accountingMethod")
                    }
                    required
                  />
                </Grid.Column>
                <Grid.Column width="5">
                  <Formsy.Select
                    name="opinion"
                    id="opinion"
                    label={Constants.OPINION}
                    options={opinionAttribsOptions}
                    placeholder={<>&mdash;Please make selection&mdash;</>}
                    value={opinionAttribsValue}
                    disabled={opinionAttribsDisabled}
                    onChange={(e, data) => this.handleChange(data, "opinion")}
                    required={opinionAttribsRequired}
                  />
                </Grid.Column>
                <Grid.Column width="5">
                  {isDuplicate &&
                  this.checkIfFinancialModelNameExists(dupFormData) ? (
                    <div style={{ marginLeft: "1em" }}>
                      <Header size="tiny">Financial Model</Header>
                      {dupFormData.financialModelUuid
                        ? financialModelInfo[dupFormData.financialModelUuid]
                            .name
                        : ""}
                    </div>
                  ) : (
                    <Formsy.Select
                      name="financialModelUuid"
                      options={getModelOptions()}
                      placeholder={<>&mdash;Please make selection&mdash;</>}
                      label="Financial Model"
                      onChange={(e, data) =>
                        this.handleChange(data, "financialModelUuid")
                      }
                      required
                      value={dupFormData.financialModelUuid}
                    />
                  )}
                  <Header as="h6">
                    <Icon name="info circle" color="grey" />
                    Only models with the automation icon are configured for the
                    spread automation feature. Supported statements include
                    1040, 1120, 1120s and 1065 tax forms.
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="12">
                <Grid.Column width="6" textAlign="left">
                  <Header textAlign="left" as="h5">
                    Selected Document:
                  </Header>
                  <span>{selectedDoc}</span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width="15" />
              </Grid.Row>
            </Grid>
          </Formsy>
          <Table selectable width="15">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Document Type</Table.HeaderCell>
                <Table.HeaderCell>Doc Date</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{tableRows}</Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          {addDupFormError ? (
            <Label color="red" size="large" basic>
              {addDupFormError}
            </Label>
          ) : (
            ""
          )}
          <Button name="cancel" onClick={close}>
            Cancel
          </Button>
          {SubmitButton({ isDuplicate, isUpdate })}
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  dupFormData: state.CreditManagementReducer.dupFormData,
  addDupFormError: state.CreditManagementReducer.addDupFormError,
  docName: state.CreditManagementReducer.docName,
  documents: state.CreditManagementReducer.documents,
  jwt: state.auth.jwt
});

AddDuplicateModalObj.propTypes = {
  addDocumentCallback: PropTypes.func.isRequired,
  duplicateDocumentCallback: PropTypes.func.isRequired,
  editDocumentCallback: PropTypes.func,
  docName: PropTypes.string,
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDuplicate: PropTypes.bool,
  isUpdate: PropTypes.bool,
  dupFormData: PropTypes.shape({
    accountingMethod: PropTypes.string,
    documentUuid: PropTypes.string,
    endDate: PropTypes.string,
    financialModelUuid: PropTypes.string,
    opinion: PropTypes.string,
    period: PropTypes.string,
    sourceDocument: PropTypes.string,
    startDate: PropTypes.string,
    statementDate: PropTypes.string,
    statementType: PropTypes.string,
    FinancialSpreadsModelsModel: PropTypes.object
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  allProductionModels: PropTypes.arrayOf(PropTypes.object),

  openState: PropTypes.bool,
  close: PropTypes.func.isRequired,
  addDupFormError: PropTypes.string
};

AddDuplicateModalObj.defaultProps = {
  isDuplicate: false,
  isUpdate: false,
  openState: false
};

const AddDuplicateModal = connect(mapStateToProps)(AddDuplicateModalObj);

export default AddDuplicateModal;
