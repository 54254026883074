import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import FetchService, { asyncFetchService } from "../../../FetchService";
import { ConcatQueryParams, RemoveNullKeys } from "../Utilities";
import { FormatUrlV2 } from "../../../FormatUrl";
import logger from "../../../logger";

async function read(filters = {}) {
  const funcName = "==== ParticipationInvestors.read(filters)  ====";
  logger.debug(funcName, [filters]);
  TypeCheck(filters.parentUuid, "string", false);

  const filterCount = Object.keys(filters).length;
  if ((filters.parentUuid && filterCount > 1) || filterCount === 0) {
    logger.error("Error: Invalid Filter Combination");
    // TODO: [#5740] Put a checkable status here.
    const errorRsp = {};
    return errorRsp;
  }

  let url;
  if (filters.parentUuid) {
    url = FormatUrlV2(ApiEndpoints.participationInvestor, {
      uuid: filters.parentUuid
    });
  } else {
    const appendToUrl = ConcatQueryParams(filters);
    url = FormatUrlV2(ApiEndpoints.participationInvestors) + appendToUrl;
  }

  const rsp = await asyncFetchService("GET", url);

  return rsp;
}

async function asyncUpdate(filters, body) {
  TypeCheck(filters.parentUuid, "string", true);

  const url = FormatUrlV2(ApiEndpoints.participationInvestor, {
    uuid: filters.parentUuid
  });
  const convertedBody = RemoveNullKeys(body);

  const rsp = await asyncFetchService("PUT", url, convertedBody);
  return rsp;
}

async function asyncAdd(filters, body) {
  TypeCheck(filters.parentUuid, "string", true);

  const url = FormatUrlV2(ApiEndpoints.participationInvestor, {
    uuid: filters.parentUuid
  });
  const convertedBody = RemoveNullKeys(body);

  const rsp = await asyncFetchService("POST", url, convertedBody);
  return rsp;
}

function get(onSuccess, onError, uuid) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string", false);

  const url = FormatUrlV2(ApiEndpoints.participationInvestor, { uuid });

  FetchService("GET", url, onSuccess, onError);
}

function update(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  const url = FormatUrlV2(ApiEndpoints.participationInvestorUpdate, {
    parent_loan_uuid: body.participation_parent_uuid,
    participation_loan_uuid: body.uuid
  });
  const convertedBody = RemoveNullKeys(body);

  FetchService("PUT", url, onSuccess, onError, convertedBody, callbackData);
}

function add(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  const url = FormatUrlV2(ApiEndpoints.participationInvestor, {
    uuid: body.participation_parent_uuid
  });
  const convertedBody = RemoveNullKeys(body);

  FetchService("POST", url, onSuccess, onError, convertedBody, callbackData);
}

export default {
  asyncUpdate,
  read,
  update,
  add,
  get,
  asyncAdd
};
