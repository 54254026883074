import React from "react";
import { Segment } from "semantic-ui-react";
import PropTypes from "prop-types";
import Documents from "../../../../components/Documents";

function PhasesDocuments({ loanAppUuid }) {
  return (
    <div>
      <Segment>
        <Documents loanAppUuid={loanAppUuid} readOnly={false} />
      </Segment>
    </div>
  );
}

PhasesDocuments.propTypes = {
  loanAppUuid: PropTypes.string.isRequired
};

export default PhasesDocuments;
