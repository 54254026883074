import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "semantic-ui-react";

export function ToggleCell({
  cell: {
    value,
    column: { id }
  },
  onToggle,
  readOnly
}) {
  return readOnly ? (
    <p>{value[id] ? "Yes" : "No"}</p>
  ) : (
    <Checkbox
      toggle
      onChange={(ev, { checked }) => onToggle(value, checked)}
      checked={value[id] || false}
      label={value[id] ? "Yes" : "No"}
    />
  );
}
ToggleCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.any,
    column: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }).isRequired,
  readOnly: PropTypes.bool,
  onToggle: PropTypes.func.isRequired
};

export function RowToggleCell({
  cell: {
    value,
    column: { id }
  },
  onToggle,
  readOnly
}) {
  return (
    <Checkbox
      toggle
      onChange={(ev, { checked }) => onToggle(id, checked)}
      checked={value || false}
      readOnly={readOnly}
    />
  );
}

RowToggleCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.any,
    column: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }).isRequired,
  readOnly: PropTypes.bool,
  onToggle: PropTypes.func.isRequired
};
