import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { StateWithAuth } from "./types";

import APIEndpoints from "../ApiEndpoints";

export enum Tag {
  Entity = "Entity",
  InstitutionAssetGroups = "InstitutionAssetGroups",
  InstitutionLoanClasses = "InstitutionLoanClasses",
  InstitutionLoanPurposes = "InstitutionLoanPurposes",
  InterestSchedule = "InterestSchedule",
  Loan = "Loan",
  LoanApp = "LoanApp",
  LoanAppSettlementSheet = "LoanAppSettlementSheet",
  PaymentSchedule = "PaymentSchedule",
  Relationship = "Relationship",
  UserInfo = "UserInfo"
}

const baseQueryWithRetries = retry(
  fetchBaseQuery({
    baseUrl: APIEndpoints.baseUri,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as StateWithAuth).auth.jwt;
      if (token) {
        headers.set("Authorization", token);
      }

      return headers;
    },
    mode: "cors"
  }),
  {
    maxRetries: 3
  }
);

export const blastApi = createApi({
  reducerPath: "blastApi",
  baseQuery: baseQueryWithRetries,
  tagTypes: Object.keys(Tag),
  endpoints: () => ({})
});
