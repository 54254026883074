import React from "react";
import PropTypes from "prop-types";
import { Form, Checkbox } from "semantic-ui-react";
import { readableNumFormat } from "../..";
import { PercentInput } from "../../../../components/CustomFormElements";
import parseStringAsNumber from "../../../ParseStringAsNumber";

const getRadioLabel = radioVal => {
  if (radioVal === true) {
    return "Yes";
  }
  if (radioVal === false) {
    return "No";
  }
  if (typeof radioVal !== "string") {
    return radioVal.toString();
  }
  return radioVal;
};

const restrictValues = ({ val, max, min }) => {
  if (!val) {
    return val;
  }
  if (val > max) {
    return max;
  }
  if (val < min) {
    return min;
  }
  return val;
};

const getRadioInput = ({ input, handleSet, handleUpdate, fields }) => {
  let { options } = input;
  const { stacked } = input;
  if (!options) {
    options = [true, false];
  }
  const radioButtons = [];
  for (let j = 0; j < options.length; j += 1) {
    const radioVal = options[j];
    radioButtons.push(
      <Form.Radio
        label={getRadioLabel(radioVal)}
        onClick={() => {
          handleSet({
            ...fields,
            [input.name]: radioVal
          });

          handleUpdate({
            ...fields,
            [input.name]: radioVal
          });
        }}
        name={input.name}
        id={`${input.name}-${radioVal}`}
        data-testid={`${input.name}-${radioVal}`}
        key={`${input.name}-${radioVal}`}
        checked={fields[input.name] === radioVal}
      />
    );
  }
  if (stacked) {
    return (
      <div style={{ marginBottom: "20px" }} key={input.name}>
        <b>{input.label}</b>
        <div>{radioButtons}</div>
      </div>
    );
  }
  return (
    <div>
      <b>{input.label}</b>
      <Form.Group key={input.name} inline>
        {radioButtons}
      </Form.Group>
    </div>
  );
};

function FormInput({ set, update, fields, input }) {
  const handleSet = input.set ? input.set : set;
  const handleUpdate = input.update ? input.update : update;
  const { max, min } = input;

  if (input.hidden && !input.hidden(fields)) {
    return null;
  }

  switch (input.type) {
    case "date":
      return (
        <Form.Input
          onChange={(e, { value }) => {
            handleSet({
              ...fields,
              [input.name]: value
            });

            handleUpdate({
              ...fields,
              [input.name]: value
            });
          }}
          value={fields[input.name]}
          label={<b>{input.label}</b>}
          key={input.name}
          type="date"
          data-testid={input.name}
          id={input.name}
          name={input.name}
        />
      );
    case "bool":
      return (
        <Form.Radio
          checked={fields[input.name]}
          data-testid={input.name}
          id={input.name}
          toggle
          key={input.name}
          onClick={() => {
            handleSet({
              ...fields,
              [input.name]: !fields[input.name]
            });

            handleUpdate({
              ...fields,
              [input.name]: !fields[input.name]
            });
          }}
          label={input.label}
          readOnly={input.readOnly}
        />
      );

    case "checkbox":
      return (
        <div key={input.name}>
          <Checkbox
            checked={fields[input.name]}
            data-testid={input.name}
            key={input.name}
            onClick={() => {
              handleSet({
                ...fields,
                [input.name]: !fields[input.name]
              });

              handleUpdate({
                ...fields,
                [input.name]: !fields[input.name]
              });
            }}
            label={input.label}
            style={{ fontWeight: 700 }}
            readOnly={input.readOnly}
          />
          <br />
          <br />
        </div>
      );

    case "radio":
      return getRadioInput({
        input,
        handleSet,
        handleUpdate,
        fields
      });

    case "select": {
      return (
        <Form.Select
          onChange={(e, { value }) => {
            handleSet({
              ...fields,
              [input.name]: value
            });

            handleUpdate({
              ...fields,
              [input.name]: value
            });
          }}
          options={input.options}
          value={fields[input.name]}
          label={<b>{input.label}</b>}
          key={input.name}
          data-testid={input.name}
          id={input.name}
          name={input.name}
          {...(!input.options ? { disabled: true } : {})}
        />
      );
    }

    case "percent":
      return (
        <PercentInput
          readOnly={input.readOnly}
          disabled={input.readOnly}
          label={input.label}
          key={input.name}
          name={input.name}
          data-testid={input.name}
          id={input.name}
          onBlur={() => {
            handleUpdate({
              [input.name]: fields[input.name]
            });
          }}
          onChange={(e, value) => {
            const val = parseStringAsNumber(value.value);
            handleSet({
              ...fields,
              [input.name]: restrictValues({ val, max, min })
            });
          }}
          value={
            readableNumFormat(fields[input.name])
              ? readableNumFormat(fields[input.name])
              : ""
          }
          max={max || 10000000}
          min={min || 0}
        />
      );
    case "int":
    case "float":
      return (
        <Form.Input
          readOnly={input.readOnly}
          disabled={input.readOnly}
          label={<b>{input.label}</b>}
          key={input.name}
          name={input.name}
          data-testid={input.name}
          id={input.name}
          onBlur={() => {
            handleUpdate({
              [input.name]: fields[input.name]
            });
          }}
          onChange={e => {
            const { target } = e;
            if (
              target.value.toString()[target.value.toString().length - 1] ===
              "."
            ) {
              handleSet({
                ...fields,
                [input.name]: target.value.toString()
              });
            } else {
              const val = parseStringAsNumber(target.value);
              handleSet({
                ...fields,
                [input.name]: restrictValues({ val, max, min })
              });
            }
          }}
          value={readableNumFormat(fields[input.name])}
          max={max || 10000000}
          min={min || 0}
        />
      );

    case "string":
      return (
        <Form.Input
          readOnly={input.readOnly}
          disabled={input.readOnly}
          label={<b>{input.label}</b>}
          key={input.name}
          name={input.name}
          data-testid={input.name}
          id={input.name}
          onBlur={() => {
            handleUpdate({
              [input.name]: fields[input.name]
            });
          }}
          onChange={({ target }) => {
            const val = target.value;
            handleSet({
              ...fields,
              [input.name]: val
            });
          }}
          value={fields[input.name] || ""}
          maxLength={max || 10000000}
          minLength={min || 0}
        />
      );

    default:
      throw new Error(`Could not find input type: ${input.type}`);
  }
}

FormInput.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    max: PropTypes.number,
    min: PropTypes.number,
    set: PropTypes.func,
    update: PropTypes.func,
    hidden: PropTypes.bool,
    options: PropTypes.array
  }).isRequired,
  set: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired
};

export default FormInput;
