import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table, Grid, Segment, Checkbox } from "semantic-ui-react";

import Constants from "../../../../../../../../services/Constants/strings";
import { isBafs } from "../../../../../../../../services/Auth";
import LoanModal from "./components/LoanModal";
import { dateStringToISO } from "../../../../../../../../services/DateTime";

import PaginationControls from "../../../../../../../../components/Pagination";
import { Loans } from "../../../../../../../../services/ApiLib";
import LoanTableRows from "./components/LoanTableRows";
import CreditRatings from "../../../../../../../../services/ApiLib/services/CreditRatings";
import { CREDIT_ANALYSIS_ACTIONS } from "../../../../../../../../components/CreditAnalysis/reducer";
import logger from "../../../../../../../../services/logger";

export function onError(rsp) {
  logger.error("Error: ", rsp); // TODO: Handle errors properly
}

const paginationName = "creditManagementLoans";
const tableHeaders = [
  Constants.BLAST_ACCOUNT_NUMBER,
  Constants.INSTITUTION_ACCOUNT_NUMBER,
  Constants.CURRENT_BALANCE,
  Constants.AVAILABLE_BALANCE,
  Constants.TOTAL_EXPOSURE,
  Constants.ACCOUNT_TYPE,
  Constants.LOAN_CLASS,
  Constants.LOAN_TYPE,
  Constants.FACILITY_GRADE,
  Constants.DEAL_RATING,
  Constants.STATUS,
  Constants.PARTICIPATION
];

function LoansComponent() {
  const [lastPage, setLastPage] = useState(false);
  const dispatch = useDispatch();

  const {
    entityInstitution,
    loanData,
    showClosedLoans,
    loanModal,
    loanModalNew,
    loanModalInfo,
    entityUuid,
    institutionUuid,
    pagination,
    creditAnalysisList,
    entityBpOrgUuid
  } = useSelector(state => ({
    loanData: state.CreditManagementReducer.data,
    showClosedLoans: state.CreditManagementReducer.showClosedLoans,
    loanModal: state.CreditManagementReducer.loanModal,
    loanModalNew: state.CreditManagementReducer.loanModalNew,
    loanModalInfo: state.CreditManagementReducer.loanModalInfo,
    entityUuid: state.CreditManagementReducer.entityData.uuid,
    institutionUuid: state.CreditManagementReducer.entityData.institution_uuid,
    entityInstitution: state.CreditManagementReducer.entityInstitution,
    pagination: state.PaginationReducer[paginationName],
    creditAnalysisList:
      state.CreditAnalysisManagementReducer.creditAnalysisList,
    entityBpOrgUuid:
      state.CreditManagementReducer.entityData.bp_organization_uuid
  }));

  const getLoansStatus = useCallback(() => {
    if (!showClosedLoans) {
      return {
        list_column: "status",
        list_items: "Active,Active Charge-Off,Non-Accrual,Paid Down"
      };
    }
    return null;
  }, [showClosedLoans]);

  const loanTablePopulate = useCallback(async () => {
    const queryParams = {
      entity_uuid: entityUuid,
      institution_uuid: institutionUuid,
      finalized: true,
      ...getLoansStatus()
    };
    try {
      const loans = await Loans.asyncRead(queryParams, pagination);
      setLastPage(loans.metaData.last_page);
      dispatch({
        type: "CMS_UPDATE_LOAN",
        data: loans.data
      });
      dispatch({
        activeItem: "Loans",
        data: loans.data,
        type: "CMS_POPULATE_TABLE"
      });
    } catch (err) {
      onError(err);
    }
  }, [dispatch, entityUuid, institutionUuid, getLoansStatus, pagination]);

  function changeDateLoan(rdpEvent, name) {
    if (rdpEvent) {
      dispatch({
        type: "CMS_LOAN_MODAL_UPDATE_INFO",
        payload: { [name]: dateStringToISO(rdpEvent) }
      });
    }
  }

  function toggleLoanModal(obj, newItem = false) {
    dispatch({
      type: "CMS_TOGGLE_LOAN_MODAL",
      newItem,
      obj
    });
  }

  useEffect(() => {
    loanTablePopulate();
  }, [loanTablePopulate, pagination]);

  useEffect(() => {
    const readCurrentAnalysis = async () => {
      try {
        const { data } = await CreditRatings.readCurrentCreditAnalysisByEntity(
          entityUuid
        );
        dispatch({
          type: CREDIT_ANALYSIS_ACTIONS.readCurrentAnalysisByEntity,
          data
        });
      } catch (err) {
        onError(err);
      }
    };
    if (entityUuid) readCurrentAnalysis();
  }, [dispatch, entityUuid]);

  function loanModalUpdateNumber(e, loanModalData) {
    let intValue = loanModalData.value;
    if (loanModalData.name === "deal_rating") {
      intValue = parseInt(loanModalData.value, 10);
    }
    dispatch({
      type: "CMS_LOAN_MODAL_UPDATE_INFO",
      payload: { [loanModalData.name]: intValue }
    });
  }

  function loanModalUpdateInfo(payload) {
    if (payload) {
      dispatch({
        type: "CMS_LOAN_MODAL_UPDATE_INFO",
        payload
      });
    }
  }

  async function addLoan() {
    // TODO: When the backend can handle empty strings remove this and send empty strings.

    const cleanLoanData = Object.keys(loanModalInfo).reduce((acc, key) => {
      if (loanModalInfo[key] !== null && loanModalInfo[key] !== "") {
        acc[key] = loanModalInfo[key];
      }
      return acc;
    }, {});

    try {
      if (loanModalNew) {
        const body = {
          ...cleanLoanData,
          entity_uuid: entityUuid,
          bp_organization_uuid: entityBpOrgUuid,
          institution_uuid: institutionUuid,
          phoenix_import: false,
          finalized: true,
          contract_date: loanModalInfo.contract_date
            ? dateStringToISO(loanModalInfo.contract_date)
            : null,
          maturity_date: loanModalInfo.maturityDate
            ? dateStringToISO(loanModalInfo.maturity_date)
            : null
        };
        await Loans.asyncAdd(body);
        dispatch({
          type: "CMS_LOAN_MODAL_RESET"
        });
      } else {
        const { uuid } = loanModalInfo;
        const body = {
          ...cleanLoanData
        };
        await Loans.asyncUpdate({ loanUuid: uuid }, body);
        dispatch({
          type: "CMS_LOAN_MODAL_RESET"
        });
      }
      loanTablePopulate();
    } catch (err) {
      onError(err);
    }
  }

  async function removeLoan(loanUuid) {
    try {
      await Loans.asyncRemove(loanUuid);
    } catch (err) {
      onError(err);
    }
  }

  function onClosedLoansClick() {
    dispatch({
      name: paginationName,
      type: "PAGINATION_INITIALIZE"
    });
    dispatch({
      type: "CMS_LOAN_TABLE_FILTER_CLOSED_LOANS"
    });
  }

  const headers = tableHeaders.map(header => (
    <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
  ));

  const buildLoanWithCurrentCreditAnalysis = ({ loan, creditAnalyses }) => {
    const currentCreditAnalysisByLoan = creditAnalyses?.filter(
      analysis => analysis.loan_uuid === loan.uuid
    )[0];
    return {
      ...loan,
      creditRiskRating:
        currentCreditAnalysisByLoan?.single_risk_scale_item?.rating
    };
  };

  const loanDataWithCreditRiskRating = useMemo(
    () =>
      loanData?.map(loan =>
        buildLoanWithCurrentCreditAnalysis({
          loan,
          creditAnalyses: creditAnalysisList.data
        })
      ),
    [loanData, creditAnalysisList.data]
  );

  const isCrrmInstitution = !!entityInstitution?.risk_rating_model_type;

  const tableRows = (
    <LoanTableRows
      loans={loanDataWithCreditRiskRating}
      toggleLoanModal={toggleLoanModal}
    />
  );

  const loanModalInfoWithCreditRiskRating = useMemo(
    () =>
      buildLoanWithCurrentCreditAnalysis({
        loan: loanModalInfo,
        creditAnalyses: creditAnalysisList.data
      }),
    [loanModalInfo, creditAnalysisList.data]
  );

  let loanModalRender = "";
  if (loanModal) {
    loanModalRender = (
      <LoanModal
        institutionUuid={institutionUuid}
        open={loanModal}
        toggleLoanModal={toggleLoanModal}
        loanModalNew={loanModalNew}
        loanModalInfo={loanModalInfoWithCreditRiskRating}
        loanModalUpdateInfo={loanModalUpdateInfo}
        changeDateLoan={changeDateLoan}
        loanModalUpdateNumber={loanModalUpdateNumber}
        addLoan={addLoan}
        isCrrmInstitution={isCrrmInstitution}
      />
    );
  }

  return (
    <Segment.Group>
      {loanModalRender}
      <Segment>
        <Grid>
          <Grid.Row columns="2">
            <Grid.Column textAlign="left">
              {isBafs() && (
                <Button
                  data-testid="loans-toggle-modal"
                  onClick={() => toggleLoanModal({}, true)}
                >
                  New
                </Button>
              )}
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Checkbox
                data-testid="include-close-loans-checkbox"
                onClick={onClosedLoansClick}
                label="Include Closed Loans"
                toggle
                checked={showClosedLoans}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment>
        <Table celled>
          <Table.Header>
            <Table.Row>
              {headers}
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>{tableRows}</Table.Body>
        </Table>
      </Segment>
      <Segment>
        <PaginationControls name={paginationName} lastPage={lastPage} />
      </Segment>
    </Segment.Group>
  );
}

export default LoansComponent;
