import { isBafs, permCheck } from "../..";

export default function getUserGroup() {
  if (permCheck(["admin"])) {
    return "Admin";
  }

  if (isBafs()) {
    return "BAFS";
  }

  return "Institution";
}
