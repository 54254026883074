import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  DefaultCell,
  PropertyTable
} from "../../../../../../../../../../components/Tables";
import PaymentsCell from "./PaymentsCell";

function NumberOfPayments({ loan }) {
  const rows = useMemo(
    () => [
      {
        Header: "Number of Payments Made",
        accessor: "total_payments",
        Cell: PaymentsCell
      },
      {
        Header: "Number of Payments Remaining",
        accessor: "payments_remaining",
        Cell: DefaultCell
      }
    ],
    []
  );

  return <PropertyTable columnsConfig={rows} data={loan} />;
}

NumberOfPayments.propTypes = {
  loan: PropTypes.shape({
    total_payments: PropTypes.number,
    payments_remaining: PropTypes.number
  }).isRequired
};

export default NumberOfPayments;
