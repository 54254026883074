/* eslint-env browser */
import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { DecodeToken, SetToken } from "@bafsllc/auth-shared";

import Constants from "../../services/Constants";
import {
  Login as LoginApi,
  PasswordResetLib,
  Institutions
} from "../../services/ApiLib";
import PasswordResetModal from "./components/passwordResetModal";
import LoginForm from "./components/loginForm";
import logger from "../../services/logger";
import { registerAll } from "../../services/Register3rdParties";
import { ToastProvider } from "@bafsllc/ui-shared";

export function onError(...rsp) {
  logger.error("Error: ", ...rsp); // TODO: Handle errors properly
}

const { CORS } = Constants;

function Login({ auth }) {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e = null) => {
    if (e) e.preventDefault();
    if (!username || !password) return;
    setLoading(true);
    const res = await LoginApi.asyncAdd({ username, password });
    setLoading(false);
    if (res.error) {
      const errorMessage = await res.error.json?.();
      dispatchError(res.error.statusText, errorMessage?.error || "");
    } else {
      onSuccessToken(res.data);
    }
  };

  const handleFieldChange = (e, type) => {
    if (type === "username") {
      setUsername(e.target.value);
    } else if (type === "password") {
      setPassword(e.target.value);
    }
  };

  const handleCloseError = () => {
    dispatchError("", "");
  };

  const openModal = () => {
    dispatch({
      type: "LOGIN_TOGGLE_MODAL"
    });
  };

  const onChangeEmail = (e, { value }) => {
    dispatch({
      type: "LOGIN_HANDLE_CHANGE",
      value
    });
  };

  const onSave = () => {
    PasswordResetLib.add(onSuccessReset, onError, { email: auth.email });
  };

  const onSuccessInstLookup = ({ data }) => {
    dispatch({
      type: "AUTH_GET_INST_DATA",
      data
    });
  };

  const onSuccessReset = ({ data }) => {
    dispatch({
      type: "LOGIN_PASSWORD_RESET_MESSAGE",
      msg: data
    });
  };

  const onSuccessToken = async response => {
    SetToken(response.token);
    dispatch({
      type: "GET_LOGIN",
      data: response
    });

    const decodedToken = DecodeToken(response.token);
    registerAll(decodedToken);
    try {
      const res = await Institutions.asyncRead({
        institutionUuid: decodedToken.institution_uuid
      });
      onSuccessInstLookup(res);
    } catch (e) {
      onError(e);
    }
  };

  const dispatchError = (error, details) => {
    let errorMsg = details;
    if (!errorMsg || errorMsg === "") {
      switch (error) {
        case "UNAUTHORIZED":
          errorMsg = "Incorrect Username or Password.";
          break;
        case CORS:
          errorMsg =
            "An unexpected connection issue occurred. Try again in a few minutes.";
          break;
        default:
          errorMsg = "There was a problem. Please try again in a few minutes.";
          break;
      }
    }
    dispatch({
      type: "LOGIN_ERROR"
    });
    dispatch({
      type: "LOGIN_ERROR_MSG",
      data: errorMsg
    });
    onError(error, details);
  };

  if (auth.authenticated) {
    const urlParams = new URLSearchParams(window.location.search);
    const redirect = urlParams.get("redirect");

    if (redirect) {
      const redirectUrl = new URL(redirect);
      if (redirectUrl?.host === window.location.host) {
        window.location.assign(redirect);
        return null;
      }
    }

    return <Redirect to="/" />;
  }

  return (
    <ToastProvider>
      <PasswordResetModal
        email={auth.email}
        msg={auth.msg}
        onClose={openModal}
        onChangeEmail={onChangeEmail}
        onSave={onSave}
        open={auth.openModal}
      />
      <LoginForm
        handleLogin={handleLogin}
        handleFieldChange={handleFieldChange}
        handleCloseError={handleCloseError}
        loginFailed={auth.loginFailed}
        errorMessage={auth.errorMessage}
        openModal={openModal}
        submitEnabled={username !== "" && password !== ""}
        loading={loading}
      />
    </ToastProvider>
  );
}

Login.propTypes = {
  auth: PropTypes.shape({
    authenticated: PropTypes.bool,
    loginFailed: PropTypes.string,
    email: PropTypes.string,
    userUuid: PropTypes.string,
    openModal: PropTypes.bool,
    msg: PropTypes.string,
    errorMessage: PropTypes.string
  }).isRequired
};

const mapStateToProps = state => ({ auth: state.auth });

const LoginPage = connect(mapStateToProps)(Login);
export default LoginPage;
