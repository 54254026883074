export const initialState = {
  creditAnalysisList: {},
  selectedCreditAnalysis: null,
  singleRiskScalesList: []
};

export const CREDIT_ANALYSIS_ACTIONS = {
  create: "CREDIT_ANALYSIS_CREATE",
  delete: "CREDIT_ANALYSIS_DELETE",
  list: "CREDIT_ANALYSIS_LIST",
  readSingleScales: "CREDIT_ANALYSIS_READ_SINGLE_SCALES",
  readCurrentAnalysisByEntity: "READ_CURRENT_ANALYSIS_FOR_ENTITY",
  readCurrentAnalysisByLoan: "READ_CURRENT_ANALYSIS_FOR_LOAN",
  resetState: "CREDIT_ANALYSIS_RESET_STATE",
  select: "CREDIT_ANALYSIS_SELECT",
  update: "CREDIT_ANALYSIS_UPDATE"
};

export default function reducer(state, action) {
  const safeState = state ?? initialState;
  switch (action.type) {
    case CREDIT_ANALYSIS_ACTIONS.create: {
      const analysisList = safeState.creditAnalysisList?.data ?? [];
      analysisList.push(action.data);
      return {
        ...safeState,
        creditAnalysisList: {
          ...safeState.creditAnalysisList,
          data: [...analysisList]
        },
        selectedCreditAnalysis: { ...action.data }
      };
    }

    case CREDIT_ANALYSIS_ACTIONS.delete: {
      const deletedUuid = action.data;
      const newCreditAnalysisList = safeState.creditAnalysisList.data.filter(
        creditAnalysis => creditAnalysis.uuid !== deletedUuid
      );
      return {
        ...safeState,
        creditAnalysisList: {
          data: newCreditAnalysisList,
          metaData: action.metaData
        }
      };
    }

    case CREDIT_ANALYSIS_ACTIONS.list: {
      return {
        ...safeState,
        creditAnalysisList: {
          data: [...action.data],
          metaData: action.metaData
        }
      };
    }

    case CREDIT_ANALYSIS_ACTIONS.readSingleScales: {
      return {
        ...safeState,
        singleRiskScalesList: action.data
      };
    }

    case CREDIT_ANALYSIS_ACTIONS.readCurrentAnalysisByEntity: {
      return {
        ...safeState,
        creditAnalysisList: {
          ...safeState.creditAnalysisList,
          data: [...action.data]
        }
      };
    }

    case CREDIT_ANALYSIS_ACTIONS.readCurrentAnalysisByLoan: {
      return {
        ...safeState,
        selectedCreditAnalysis: { ...action.data }
      };
    }

    case CREDIT_ANALYSIS_ACTIONS.resetState:
      return initialState;

    case CREDIT_ANALYSIS_ACTIONS.select: {
      return {
        ...safeState,
        selectedCreditAnalysis: { ...action.data }
      };
    }

    case CREDIT_ANALYSIS_ACTIONS.update: {
      const newCreditAnalysisList = safeState.creditAnalysisList.data.map(
        item => {
          if (item.uuid === action.data.uuid) {
            return action.data;
          }
          return item;
        }
      );
      return {
        ...safeState,
        selectedCreditAnalysis: { ...action.data },
        creditAnalysisList: {
          ...safeState.creditAnalysisList,
          data: [...newCreditAnalysisList]
        }
      };
    }

    default:
      return safeState;
  }
}
