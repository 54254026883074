import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { useFinancialAnalysis } from "../../../../../../../../../../store/models/spreads";
import Constants from "../../../../../../../../../../services/Constants/strings";
import { ifFinancialModelExists } from "../../../../../../../../../../services/Constants/Models";
import { getFileMimeType } from "../../../../../../../../../../services/Constants/AllowedDocumentTypes";
import { convertDocument } from "../../../../../../../../../../services/CMS";
import { Documents } from "../../../../../../../../../../services/ApiLib";
import {
  DateCell,
  CheckboxCell,
  LockCell
} from "../../../../../../../../../../components/Tables";
import PaginatedTable from "../PaginatedTable";
import { getBlastClientApplicationBaseUrl } from "../../../../../../../../../../components/BlastEmbed/getBaseUrl";
import { FrictionModal } from "../../../../../../../../../../components/Modals";
import logger from "../../../../../../../../../../services/logger";
import DownloadCell from "./DownloadCell";

const {
  SELECT,
  ANALYSIS_ID,
  ANALYST,
  DOCUMENT_ID,
  ANALYSIS_CREATION_DATE,
  FINANCIAL_MODEL,
  COMPLETE,
  LOCK_MODAL_ANALYSIS_HEADER,
  LOCK_MODAL_ANALYSIS_MESSAGE,
  LOCK_MODAL_ANALYSIS_WARNING
} = Constants;

function Analysis({ entityData }) {
  const blastServiceUrl = getBlastClientApplicationBaseUrl();
  const { uuid: entityUuid, institution_uuid: institutionUuid } = entityData;
  const [{ financialAnalysis }, actions] = useFinancialAnalysis({
    entityUuid,
    institutionUuid
  });
  const [selectedRow, setSelectedRow] = useState();
  const [viewArchives, setViewArchives] = useState(false);
  const [isLockModalOpen, setIsLockModalOpen] = useState(false);
  const [fetchingDocumentUuid, setFetchingDocumentUuid] = useState();
  const [lockingAnalysis, setLockingAnalysis] = useState();

  const handlePreviewClick = useCallback(
    async rowOriginal => {
      if (!fetchingDocumentUuid) {
        setFetchingDocumentUuid(rowOriginal.uuid);
        const { fileName, uuid: pdfUuid } = await convertDocument(
          institutionUuid,
          rowOriginal?.financialAnalysisCombinedReportDocumentUuid,
          "Financial-Analysis-Report.pdf"
        );

        const { mimeType } = getFileMimeType(fileName);

        try {
          await Documents.preview({ uuid: pdfUuid }, { mimeType, fileName });
          setFetchingDocumentUuid("");
        } catch (err) {
          logger.error(err);
          setFetchingDocumentUuid("");
        }
      }
    },
    [fetchingDocumentUuid, institutionUuid]
  );

  const handleClickEdit = () => {
    window.open(
      `${blastServiceUrl}/document-editor/${selectedRow.financialAnalysisCombinedReportDocumentUuid}?institutionUuid=${institutionUuid}`,
      "_blank"
    );
  };

  const handleClickArchive = () => {
    actions.updateSpread({ ...selectedRow, archived: true });
  };

  const handleViewArchives = (e, { checked }) => {
    setViewArchives(checked);
  };

  const handleLockModalSubmit = () => {
    actions.updateSpread({
      ...lockingAnalysis,
      financialAnalysisStatus: "Completed"
    });
    setIsLockModalOpen(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: SELECT,
        id: "selected",
        textAlign: "center",
        accessor: "selected",
        singleSelection: true,
        Cell: CheckboxCell
      },
      {
        Header: ANALYSIS_ID,
        id: "uuid",
        accessor: "uuid"
      },
      {
        Header: ANALYST,
        id: "analystUuid",
        accessor: row =>
          row.user?.first_name
            ? `${row.user?.first_name} ${row.user?.last_name}`
            : row.analystUuid
      },
      {
        Header: DOCUMENT_ID,
        id: "financialAnalysisCombinedReportDocumentUuid",
        accessor: "financialAnalysisCombinedReportDocumentUuid"
      },
      {
        Header: ANALYSIS_CREATION_DATE,
        id: "createdDatetime",
        accessor: row => row?.analysisCreatedAt,
        Cell: DateCell
      },
      {
        Header: FINANCIAL_MODEL,
        id: "financialModelUuid",
        accessor: row => ifFinancialModelExists(row.FinancialSpreadsModelsModel)
      },
      {
        Header: COMPLETE,
        id: "completed",
        textAlign: "center",
        accessor: row => row.financialAnalysisStatus === "Completed",
        onLock: rowOriginal => {
          setIsLockModalOpen(true);
          setLockingAnalysis(rowOriginal);
        },
        Cell: LockCell
      },
      {
        Header: "",
        id: "download",
        fetchingDocumentUuid,
        handlePreviewClick,
        Cell: DownloadCell
      }
    ],
    [fetchingDocumentUuid, handlePreviewClick]
  );

  const filteredFinancialAnalysis = useMemo(() => {
    if (financialAnalysis) {
      return viewArchives
        ? financialAnalysis
        : financialAnalysis.filter(analysis => !analysis.archived);
    }
    return null;
  }, [financialAnalysis, viewArchives]);

  return filteredFinancialAnalysis ? (
    <>
      <PaginatedTable
        columns={columns}
        data={filteredFinancialAnalysis}
        onSelectedRowChange={row => setSelectedRow(...row)}
        handleViewArchives={handleViewArchives}
        filterOptions={[{ text: "Clear Filter", value: "" }]}
        tableName="analysisTable"
        leftButtons={
          <>
            <Button
              disabled={
                !(
                  selectedRow &&
                  !selectedRow?.archived &&
                  selectedRow?.financialAnalysisStatus !== "Completed"
                )
              }
              onClick={handleClickEdit}
            >
              Edit
            </Button>
            <Button
              disabled={!(selectedRow && !selectedRow?.archived)}
              onClick={handleClickArchive}
            >
              Archive
            </Button>
          </>
        }
      />
      <FrictionModal
        open={isLockModalOpen}
        header={LOCK_MODAL_ANALYSIS_HEADER}
        message={LOCK_MODAL_ANALYSIS_MESSAGE}
        warning={LOCK_MODAL_ANALYSIS_WARNING}
        validateString="CONFIRM"
        onCancel={() => {
          setIsLockModalOpen(false);
        }}
        onSubmit={handleLockModalSubmit}
        confirmLabelText="Submit"
      />
    </>
  ) : (
    "Loading..."
  );
}

Analysis.propTypes = {
  entityData: PropTypes.shape({
    uuid: PropTypes.string,
    institution_uuid: PropTypes.string
  }).isRequired
};

export default Analysis;
