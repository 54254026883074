import PropTypes from "prop-types";
import { Checkbox } from "semantic-ui-react";

import { permCheck } from "../../../../../../services/Auth";

function BorrowerVisibilityToggle({ doc, onToggle }) {
  return permCheck(["doc_bp_visibility", "admin"]) ? (
    <Checkbox
      data-testid="visible-checkbox"
      checked={doc.borrowerVisibility}
      toggle
      label={doc.borrowerVisibility ? "On" : "Off"}
      onClick={() => onToggle(doc)}
    />
  ) : (
    <Checkbox
      data-testid="un-visible-checkbox"
      checked={doc.borrowerVisibility}
      toggle
      label={doc.borrowerVisibility ? "On" : "Off"}
      disabled
    />
  );
}

BorrowerVisibilityToggle.propTypes = {
  doc: PropTypes.shape({
    docCategory: PropTypes.string,
    description: PropTypes.string,
    fileName: PropTypes.string,
    docType: PropTypes.string,
    uploadDate: PropTypes.string,
    uploadedBy: PropTypes.string,
    uuid: PropTypes.string,
    borrowerVisibility: PropTypes.bool
  }).isRequired,
  onToggle: PropTypes.func.isRequired
};

export default BorrowerVisibilityToggle;
