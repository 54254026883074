import { useEffect } from "react";

import _ from "lodash";
import jwtDecode from "jwt-decode";

import { authUrl } from "./settings";

import type { TokenPayload } from "./types/types";

export const SetToken = (token: string) => {
  window.localStorage.setItem("authToken", token);
};

export function DecodeToken(): TokenPayload | null {
  const encodedToken = window.localStorage.getItem("authToken") || "";
  if (!encodedToken) {
    return null;
  }
  const decodedToken: TokenPayload = jwtDecode(encodedToken);
  return decodedToken;
}

export const getToken = () => window.localStorage.getItem("authToken") || "";

export function getTimeToExpiration(timeUnit: string = "min") {
  const decodedToken = DecodeToken();
  if (!decodedToken) {
    return 0;
  }

  const { exp } = decodedToken;
  const msSinceEpoch = new Date().getTime();
  const secsSinceEpoch = msSinceEpoch / 1000;
  const secsToExpiration = exp - secsSinceEpoch;
  if (timeUnit === "min") {
    const roundedMinToExp = (secsToExpiration / 60).toFixed(2);
    return parseFloat(roundedMinToExp);
  } else if (timeUnit === "secs") {
    return secsToExpiration;
  }
  throw new Error(
    `Unsupported timeUnit, '${timeUnit}', when calling: getTimeToExpiration()`
  );
}

export async function getNewTombstoneToken() {
  return fetch(authUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken()
    }
  });
}

export async function refreshTombstoneToken() {
  try {
    const tokenRsp = await getNewTombstoneToken();
    const rspBody = await tokenRsp.json();
    if (tokenRsp.status === 200) {
      SetToken(rspBody.token);
    }
    return tokenRsp.status;
  } catch {
    return 1;
  }
}

export function throttledRefreshTokenCallback() {
  refreshTombstoneToken();
}

const refreshTokenWait = 1000 * 60 * 5;

export function throttledRefreshTokenFactory(
  callback = throttledRefreshTokenCallback,
  waitMS = refreshTokenWait,
  options = {
    trailing: true
  }
) {
  return _.throttle(callback, waitMS, options);
}

const throttledRefreshToken = throttledRefreshTokenFactory();

export function TokenRefreshEventListeners(): null {
  useEffect(() => {
    window.addEventListener("keydown", throttledRefreshToken);
    window.addEventListener("mousemove", throttledRefreshToken);
    window.addEventListener("click", throttledRefreshToken);

    return () => {
      window.removeEventListener("keydown", throttledRefreshToken);
      window.removeEventListener("mousemove", throttledRefreshToken);
      window.removeEventListener("click", throttledRefreshToken);
    };
  }, []);
  return null;
}
