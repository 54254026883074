import { blastApi, Tag } from "./blastApi";
import { SingleResponse } from "./types";

import {
  InstitutionAssetGroups,
  InstitutionLoanClasses,
  InstitutionLoanPurposes
} from "./ModelTypes/institutions";

import { extractData } from "./utilities";

const servicePath = "/institutions/v1";

function createInstitutionSpecificValuesTag<
  T extends {
    institution_uuid: string;
  }
>(tagType: Tag, institutionSpecificValueData: T) {
  return [
    {
      type: tagType,
      // Handles the (rare) case of incomplete or empty data in the response
      id: institutionSpecificValueData?.institution_uuid || "default"
    },
    tagType
  ];
}

const extendedApi = blastApi.injectEndpoints({
  endpoints: build => ({
    institutionAssetGroupClasses: build.query<InstitutionAssetGroups, string>({
      query: institutionUuud =>
        `${servicePath}/${institutionUuud}/asset_classes`,
      providesTags: result =>
        createInstitutionSpecificValuesTag(Tag.InstitutionAssetGroups, result),
      transformResponse: (response: SingleResponse<InstitutionAssetGroups>) =>
        extractData<InstitutionAssetGroups>(response)
    }),
    institutionLoanClasses: build.query<InstitutionLoanClasses, string>({
      query: institutionUuud =>
        `${servicePath}/${institutionUuud}/loan_classes`,
      providesTags: result =>
        createInstitutionSpecificValuesTag(Tag.InstitutionLoanClasses, result),
      transformResponse: (response: SingleResponse<InstitutionLoanClasses>) =>
        extractData<InstitutionLoanClasses>(response)
    }),
    institutionLoanPurposes: build.query<InstitutionLoanPurposes, string>({
      query: institutionUuud =>
        `${servicePath}/${institutionUuud}/loan_purposes`,
      providesTags: result =>
        createInstitutionSpecificValuesTag(Tag.InstitutionLoanPurposes, result),
      transformResponse: (response: SingleResponse<InstitutionLoanPurposes>) =>
        extractData<InstitutionLoanPurposes>(response)
    })
  }),
  overrideExisting: false
});

export const {
  useInstitutionAssetGroupClassesQuery,
  useInstitutionLoanClassesQuery,
  useInstitutionLoanPurposesQuery
} = extendedApi;
