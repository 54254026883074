import TypeCheck from "typecheck-extended";
import ApiEndpoints from "../../../ApiEndpoints";

async function asyncAdd({ username, password }) {
  TypeCheck(username, "string", true);
  TypeCheck(password, "string", true);

  const formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);
  try {
    const response = await fetch(
      ApiEndpoints.baseUri + ApiEndpoints.authBase + ApiEndpoints.authLogin,
      {
        method: "POST",
        body: formData
      }
    );

    if (!response.ok) {
      return { error: response };
    }
    try {
      const json = await response.json();
      if (json) {
        return { data: json };
      }
    } catch (e) {
      return { error: e };
    }
  } catch (error) {
    return { error, statusText: "CORS" };
  }
  return {};
}

export default {
  asyncAdd
};
