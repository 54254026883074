import { createActions } from "redux-actions";
import { Institutions, Loans } from "../../services/ApiLib";

export const initialState = {
  coreOfRecordInstitutions: [],
  coreOfRecordLoanInstitutions: []
};

export const actionCreators = createActions(
  {
    loadInstitutions: () => dispatch => {
      Institutions.get(
        ({ data }) => {
          const coreOfRecordInst = data.filter(inst => inst.core_of_record);
          dispatch(
            actionCreators.populateCoreOfRecordInstitutions(coreOfRecordInst)
          );

          const nonCoreOfRecordInst = data.filter(inst => !inst.core_of_record);
          nonCoreOfRecordInst.forEach(inst => {
            Loans.get(
              ({ data: loanData }) => {
                if (loanData.length > 0) {
                  dispatch(
                    actionCreators.populateCoreOfRecordLoanInstitution(inst)
                  );
                }
              },
              error => {
                dispatch(actionCreators.loadInstitutionsError(error));
              },
              null,
              {
                page_size: 1,
                core_of_record: true,
                institution_uuid: inst.uuid
              }
            );
          });
        },
        error => dispatch(actionCreators.loadInstitutionsError(error))
      );
    },
    updateInstitution:
      ({ body, coreOfRecord }) =>
      (dispatch, getState) => {
        const state = getState();
        const { coreOfRecordInstitutions, coreOfRecordLoanInstitutions } =
          state.MonthEndFinalizationReducer;
        Institutions.update(
          () => {
            if (coreOfRecord === "Yes") {
              const newData = coreOfRecordInstitutions.map(institution => {
                if (institution.uuid === body.uuid) {
                  const updatedDate = {
                    ...institution,
                    me_finalized_through: body.me_finalized_through
                  };
                  return updatedDate;
                }
                return institution;
              });
              dispatch(
                actionCreators.populateCoreOfRecordInstitutions(newData)
              );
            } else {
              const newData = coreOfRecordLoanInstitutions.map(institution => {
                if (institution.uuid === body.uuid) {
                  const updatedDate = {
                    ...institution,
                    me_finalized_through: body.me_finalized_through
                  };
                  return updatedDate;
                }
                return institution;
              });
              dispatch(
                actionCreators.populateCoreOfRecordLoanInstitution(newData)
              );
            }
          },
          error => {
            dispatch(actionCreators.loadInstitutionsError(error));
          },
          body
        );
      }
  },
  "POPULATE_CORE_OF_RECORD_INSTITUTIONS",
  "POPULATE_CORE_OF_RECORD_LOAN_INSTITUTION",
  "LOAD_INSTITUTIONS_ERROR",
  {
    prefix: "MONTH_END_FINALIZATION"
  }
);

// eslint-disable-next-line default-param-last
export default function Reducer(state = initialState, { type, payload }) {
  switch (type) {
    case "MONTH_END_FINALIZATION/POPULATE_CORE_OF_RECORD_INSTITUTIONS": {
      return { ...state, coreOfRecordInstitutions: payload };
    }
    case "MONTH_END_FINALIZATION/POPULATE_CORE_OF_RECORD_LOAN_INSTITUTION": {
      return {
        ...state,
        coreOfRecordLoanInstitutions: [
          ...state.coreOfRecordLoanInstitutions,
          ...(state.coreOfRecordLoanInstitutions.filter(
            corli => corli.uuid === payload.uuid
          ).length
            ? []
            : [payload])
        ]
      };
    }
    case "MONTH_END_FINALIZATION/LOAD_INSTITUTONS_ERROR":
    default:
      return state;
  }
}
