import { useDispatch } from "react-redux";
import { Sidebar } from "@bafsllc/ui-shared";
import { Link } from "react-router-dom";
import { userCanViewPage } from "../../services/Auth";

export default function Navigation() {
  const dispatch = useDispatch();

  /**
   * @param {string[]} routes
   */
  function handleOnCanUserViewRoutes(routes) {
    return routes.filter(route => userCanViewPage(route));
  }

  /**
   * Some items in the list shouldn't navigate the browser, they should cause
   * other actions to happen. This handler is invoked when any item in the main
   * menu is selected.
   * @param {React.SyntheticEvent} evt
   * @param {string} href
   */
  function handleItemSelect(evt, pathObject) {
    if (pathObject.href === "/notifications") {
      evt.preventDefault();
      dispatch({ type: "NOTIFICATIONS_PANEL" });
    }
  }

  /**
   * Return null for hrefs that are links to other applications outside
   * commotion.
   * @param {string} href
   */
  function handleGetAnchorElement(href) {
    let anchor = <Link to={href} />;

    if (
      href === "/institution-admin" ||
      href === "/borrower-portal-admin" ||
      href === "/reports-indexing-service"
    ) {
      anchor = null;
    }

    return anchor;
  }

  return (
    <Sidebar
      onCanUserViewRoutes={handleOnCanUserViewRoutes}
      onGetAnchorElement={handleGetAnchorElement}
      onItemSelect={handleItemSelect}
    />
  );
}
