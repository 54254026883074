import React from "react";
import PropTypes from "prop-types";

function DescriptionCell({ row, value: description = "" }) {
  const uuid = row?.original?.uuid || "";

  return (
    <a
      href={`/asset_management/${uuid}/`}
      rel="noreferrer noopener"
      target="_blank"
      className="!break-all text-left !text-wrap"
    >
      {description}
    </a>
  );
}

export default DescriptionCell;

DescriptionCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape()
  }),
  value: PropTypes.string
};
