import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import { Button, Modal, Form } from "semantic-ui-react";

class RejectDocClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleTyping = this.handleTyping.bind(this);
    this.handleRejection = this.handleRejection.bind(this);
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleTyping({ value }) {
    this.setState({ description: value });
  }

  handleRejection() {
    this.props.dispatch({
      type: "DOCS_REJECT",
      review_notes: this.state.description,
      uuid: this.props.uuid
    });
    this.handleClose();
  }

  render() {
    return (
      <div>
        <Button basic circular icon onClick={this.handleOpen}>
          <FontAwesome name="remove" />
        </Button>
        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          className="ui.modal"
        >
          <Modal.Header>Reject This Document?</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                <Form.TextArea
                  label="Rejection Reason"
                  rows="3"
                  placeholder="Please describe the reason for rejecting this document."
                  onChange={(event, data) => this.handleTyping(data)}
                />
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={this.handleClose}>
              Cancel
            </Button>
            <Button positive onClick={this.handleRejection}>
              Confirm
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

RejectDocClass.propTypes = {
  dispatch: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired
};

const RejectDoc = connect()(RejectDocClass);

export default RejectDoc;
