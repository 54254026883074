import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Button, Grid, Header, Icon } from "semantic-ui-react";

import { BorderedContainer } from "../../../../components/CustomUIElements";
import DataTemplate from "./components/DataTemplate";
import ServicingDetails from "./components/ServicingDetails";
import { ObjectMetadata } from "../../../../services/ApiLib";
import ParticipationEditModal from "./components/ParticipationEditModal";
import logger from "../../../../services/logger";

export class SystemWideDetailsObj extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showServicingDetails: false,
      showDataTemplates: false
    };
    this.onCollapse = this.onCollapse.bind(this);
    this.onUploadSuccess = this.onUploadSuccess.bind(this);
    this.objectGetOnSuccess = this.objectGetOnSuccess.bind(this);
    this.removeTemplate = this.removeTemplate.bind(this);
    this.getTemplates = this.getTemplates.bind(this);
    this.onRemovalSuccess = this.onRemovalSuccess.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.getTemplates();
  }

  onCollapse(name) {
    const { state } = this;
    const current = state[name];
    this.setState({ [name]: !current });
  }

  onUploadSuccess({ data }) {
    this.props.dispatch({
      type: "SYSTEM_ADMIN_STORE_DATA_TEMPLATES",
      obj: data
    });
  }

  onRemovalSuccess({ data }, { template }) {
    this.props.dispatch({
      type: "SYSTEM_ADMIN_UPDATE_DATA_TEMPLATES",
      obj: data,
      template
    });
  }

  getTemplates() {
    ObjectMetadata.get(this.props.jwt, this.objectGetOnSuccess, err => {
      logger.warn(err);
    });
  }

  objectGetOnSuccess({ data }) {
    data.forEach(obj => {
      if (
        obj.docCategory === "template_entity" ||
        obj.docCategory === "template_collateral" ||
        obj.docCategory === "template_loans" ||
        obj.docCategory === "template_guarantors" ||
        obj.docCategory === "template_collateral" ||
        obj.docCategory === "template_transaction"
      ) {
        this.props.dispatch({
          type: "SYSTEM_ADMIN_STORE_DATA_TEMPLATES",
          obj
        });
      }
    });
    this.props.dispatch({
      type: "SYSTEM_ADMIN_DATA_TEMPLATES_LOADED"
    });
  }

  removeTemplate(info) {
    // (relationshipUuid, onSuccess, onError, callbackData)
    ObjectMetadata.remove(
      info.uuid,
      this.onRemovalSuccess,
      err => {
        logger.warn(err);
      },
      { template: info.docCategory }
    );
  }

  openModal() {
    this.props.dispatch({
      type: "SYSTEM_ADMIN_MODAL_OPEN"
    });
  }

  closeModal() {
    this.props.dispatch({
      type: "SYSTEM_ADMIN_MODAL_CLOSE"
    });
    this.getTemplates();
  }

  renderContent(showSectionBool, stateName, title) {
    const dataTemplates = [
      { name: "Entities", docCat: "template_entity" },
      { name: "Loans", docCat: "template_loans" },
      { name: "Guarantors", docCat: "template_guarantors" },
      { name: "Collateral", docCat: "template_collateral" },
      { name: "Transaction Processing", docCat: "template_transaction" }
    ];
    return (
      <Grid padded>
        <Grid.Row columns="3">
          <Grid.Column float="left" width="1">
            <Button
              id={
                title === "Data Templates"
                  ? "dataTemplates"
                  : "showServicingDetails"
              }
              circular
              icon={stateName ? "chevron down" : "chevron up"}
              onClick={() => this.onCollapse(showSectionBool)}
            />
          </Grid.Column>
          <Grid.Column textAlign="left" width="9">
            <Header as="h2">{title}</Header>
          </Grid.Column>
          {showSectionBool === "showServicingDetails" && (
            <Grid.Column>
              <Button
                basic
                circular
                icon
                floated="right"
                onClick={() => this.openModal()}
              >
                <Icon name="pencil alternate" color="green" />
              </Button>
            </Grid.Column>
          )}
        </Grid.Row>
        {this.props.templates !== undefined && title === "Data Templates"
          ? stateName && (
              <Grid.Row columns="5">
                {dataTemplates.map(template => (
                  <Grid.Column key={template.name + template.docCat}>
                    <DataTemplate
                      category={template.name}
                      docCat={template.docCat}
                      onUploadSuccess={this.onUploadSuccess}
                      templates={this.props.templates}
                      removeTemplate={this.removeTemplate}
                    />
                  </Grid.Column>
                ))}
              </Grid.Row>
            )
          : stateName && (
              <Grid.Row>
                <ServicingDetails />
              </Grid.Row>
            )}
      </Grid>
    );
  }

  render() {
    const { participationEditModalOpen } = this.props;
    return (
      <BorderedContainer fluid padding="3%">
        {this.renderContent(
          "showServicingDetails",
          this.state.showServicingDetails,
          "BAFS Participation Servicing Details"
        )}
        {this.props.dataTemplatesDoneLoading &&
          this.renderContent(
            "showDataTemplates",
            this.state.showDataTemplates,
            "Data Templates"
          )}
        {participationEditModalOpen ? (
          <ParticipationEditModal open onClose={() => this.closeModal()} />
        ) : (
          ""
        )}
      </BorderedContainer>
    );
  }
}

SystemWideDetailsObj.propTypes = {
  dispatch: PropTypes.func.isRequired,
  jwt: PropTypes.string.isRequired,
  dataTemplatesDoneLoading: PropTypes.bool.isRequired,
  templates: PropTypes.objectOf(PropTypes.object).isRequired,
  participationEditModalOpen: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  jwt: state.auth.jwt,
  templates: state.SystemAdminReducer.templates,
  dataTemplatesDoneLoading: state.SystemAdminReducer.dataTemplatesDoneLoading,
  participationEditModalOpen:
    state.SystemAdminReducer.participationEditModalOpen
});

const SystemWideDetails = connect(mapStateToProps)(SystemWideDetailsObj);

export default SystemWideDetails;
