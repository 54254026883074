import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import FetchService, { asyncFetchService } from "../../../FetchService";
import logger from "../../../logger";
import {
  ConcatQueryParams,
  PaginationParams,
  RemoveNullKeys
} from "../Utilities";
import { FormatUrlV2 } from "../../../FormatUrl";
import Loans from "../Loans";

function add(relationship, jwt, onSuccess, onError, callbackData) {
  TypeCheck(relationship.child_uuid, "string");
  TypeCheck(relationship.institution_uuid, "string");
  TypeCheck(relationship.parent_uuid, "string");
  TypeCheck(relationship.rel_type, "string");
  TypeCheck(relationship.parent_type, "string");
  TypeCheck(relationship.child_type, "string");
  TypeCheck(jwt, "string", false);
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");

  const body = { ...relationship };
  const url = ApiEndpoints.baseUri + ApiEndpoints.relationships;
  FetchService("POST", url, onSuccess, onError, body, callbackData);
}

async function asyncAdd(body) {
  const url = ApiEndpoints.baseUri + ApiEndpoints.relationships;
  const convertedBody = RemoveNullKeys(body);
  const rsp = await asyncFetchService("POST", url, convertedBody);
  return rsp;
}

function remove(relationshipUuid, jwt, onSuccess, onError, callbackData) {
  TypeCheck(relationshipUuid, "string");
  TypeCheck(jwt, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");

  const url = FormatUrlV2(ApiEndpoints.relationshipsPut, {
    uuid: relationshipUuid
  });

  let body;
  FetchService("DELETE", url, onSuccess, onError, body, callbackData);
}

function get(relationship, jwt, onSuccess, onError, callbackData, pagination) {
  TypeCheck(relationship.child_uuid, "string", false);
  TypeCheck(relationship.parent_uuid, "string", false);
  TypeCheck(relationship.rel_type, "string", false);
  TypeCheck(jwt, "string", false);
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(pagination, "object", false);

  let queryParams = ConcatQueryParams(relationship);

  if (pagination) {
    queryParams = PaginationParams(queryParams, pagination);
  }

  const url = ApiEndpoints.baseUri + ApiEndpoints.relationships + queryParams;
  FetchService("GET", url, onSuccess, onError, null, callbackData);
}

async function asyncRead(filters) {
  const funcName = "====  Relationships.asyncRead(filters)  ====";
  logger.debug(funcName, [filters]);
  TypeCheck(filters.child_uuid, "string", false);
  TypeCheck(filters.parent_uuid, "string", false);
  TypeCheck(filters.rel_type, "string", false);

  const requestedLoanUuid = filters.parent_uuid;
  const updatedFilters = { ...filters };

  if (!filters.bypassParticipationsCheck) {
    const { participationBought, leadLoanUuid, noLeadParticipation } =
      await Loans.isParticipationBought(requestedLoanUuid);

    if (participationBought && !noLeadParticipation) {
      updatedFilters.parent_uuid = leadLoanUuid;
    }
  } else {
    logger.debug("Bypassing Participations Check");
  }

  const queryParams = ConcatQueryParams(updatedFilters);
  const url = ApiEndpoints.baseUri + ApiEndpoints.relationships + queryParams;
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

function update(relationshipUuid, jwt, onSuccess, onError, body, callbackData) {
  TypeCheck(relationshipUuid, "string");
  TypeCheck(jwt, "string");
  TypeCheck(onSuccess, "function", false);
  TypeCheck(onError, "function");
  TypeCheck(body, "object");

  let onSuccessCallback;

  if (onSuccess === undefined) {
    onSuccessCallback = () => {};
  }

  const url = FormatUrlV2(ApiEndpoints.relationshipsPut, {
    uuid: relationshipUuid
  });
  FetchService(
    "PUT",
    url,
    onSuccess || onSuccessCallback,
    onError,
    RemoveNullKeys(body),
    callbackData
  );
}

async function asyncUpdate(relationshipUuid, body) {
  const url = FormatUrlV2(ApiEndpoints.relationshipsPut, {
    uuid: relationshipUuid
  });
  return asyncFetchService("PUT", url, body);
}

async function asyncReadRelationshipReview(uuid) {
  const url = FormatUrlV2(ApiEndpoints.relationshipReviewGetAllForEntity, uuid);
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

async function asyncReadRelationshipByType(parentUuid, type) {
  const funcName =
    "====  Relationships.asyncReadRelationshipByType(parentUuid, type)  ====";
  logger.debug(funcName, [parentUuid, type]);
  TypeCheck(parentUuid, "string", true);
  TypeCheck(type, "string", true);

  const urlParams = { parentUuid };
  const requestedLoanUuid = parentUuid;
  const { leadLoanUuid, participationBought, noLeadParticipation } =
    await Loans.isParticipationBought(requestedLoanUuid);

  if (participationBought && !noLeadParticipation) {
    urlParams.parentUuid = leadLoanUuid;
  }

  let url;
  if (type === "Co-borrower") {
    url = FormatUrlV2(
      ApiEndpoints.relationshipsGetCoborrowersByParentUuid,
      urlParams
    );
  } else if (type === "Guarantor") {
    url = FormatUrlV2(
      ApiEndpoints.relationshipsGetGuarantorsByParentUuid,
      urlParams
    );
  }

  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

export default {
  add,
  get,
  update,
  asyncRead,
  remove,
  asyncUpdate,
  asyncReadRelationshipByType,
  asyncReadRelationshipReview,
  asyncAdd
};
