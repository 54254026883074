import React from "react";
import PropTypes from "prop-types";
import { Form, Dropdown } from "semantic-ui-react";
import {
  NumberInput,
  PercentInput
} from "../../../../../../components/CustomFormElements";

const guaranteedAmtOptions = [
  { text: "Percent Guaranteed (%)", value: "percent" },
  { text: "Amount Guaranteed ($)", value: "amount" }
];

function GuaranteedAmountCell({
  cell: { value: guarantor },
  readOnly,
  onAmountChange,
  onAmountTypeChange
}) {
  return (
    <Form>
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start"
        }}
      >
        <Dropdown
          onChange={(event, { value }) => onAmountTypeChange(guarantor, value)}
          options={guaranteedAmtOptions}
          placeholder="--"
          disabled={
            !guarantor.relationship_uuid ||
            guarantor.full_vs_limited !== "Limited" ||
            readOnly
          }
          value={guarantor.amount_or_percent || null}
          style={{ minWidth: "11em" }}
        />
        {guarantor.amount_or_percent === "amount" ? (
          <NumberInput
            inline
            onChange={(event, { value }) =>
              onAmountChange(guarantor, Number(value))
            }
            data-testid="NumberInput"
            type="number"
            placeholder="0"
            style={{ marginLeft: "1em", maxWidth: "8em" }}
            disabled={
              !guarantor.relationship_uuid ||
              guarantor.full_vs_limited !== "Limited" ||
              readOnly
            }
            value={guarantor.amount_guaranteed || ""}
          />
        ) : (
          <PercentInput
            onChange={(event, { value }) => onAmountChange(guarantor, value)}
            data-testid="PercentInput"
            placeholder="100"
            style={{ marginLeft: "1em", maxWidth: "8em" }}
            disabled={
              !guarantor.relationship_uuid ||
              guarantor.full_vs_limited !== "Limited" ||
              readOnly
            }
            value={guarantor.percent_guaranteed}
            max={99.99}
            inline
          />
        )}
      </span>
    </Form>
  );
}

GuaranteedAmountCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.any,
    column: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }).isRequired,
  readOnly: PropTypes.bool,
  onAmountChange: PropTypes.func.isRequired,
  onAmountTypeChange: PropTypes.func.isRequired
};

export default GuaranteedAmountCell;
