/**
 * @summary Light validation inspired by Chromium (ValidityState-typeMismatch-email.js)
 * @param {string} email
 * @returns boolean
 */
const isEmail = (email = "") => {
  if (!email) {
    return false;
  }

  if (email < 5) {
    return false;
  }

  const re = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}\b/;
  return re.test(email);
};

export default isEmail;
