import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import { SearchableDropdown } from "../../../fomantic/modules/dropdown/searchable-dropdown/searchable-dropdown";
import { Search } from "../../../fomantic/modules/search/search";

/**
 * A component that renders the Institution Switcher.
 */
export function InstitutionSwitcher({ isBafsUser, institution, user }: Props) {
  const history = useHistory();

  const { institutions, institutionSelected, onInstitutionSelected } =
    institution;

  const userInstitutionUuid = user?.institutionUuid;

  const [searchValue, setSearchValue] = useState<string>("");
  const [update, setUpdate] = useState(false);

  // Added this logic to force the component to update the ListHeader,
  // after the institution is changed automatically by changing the browser URL.
  useEffect(() => {
    if (institutionSelected && !update) {
      setUpdate(!update);
    }
  }, [institutionSelected, update]);

  const onClear = () => {
    onInstitutionSelected(null);
  };

  const handleSearch = (inputValue: string) => {
    setSearchValue(inputValue);
  };

  const handleInstitutionSelect = ({ target }: { target: any }) => {
    const { hash, search } = history.location;
    const nextParams = new URLSearchParams(search);

    let pathname = "/";

    const selectedKey = target.attributes.getNamedItem("data-item-key").value;

    const institution = institutions?.filter(item => item.uuid === selectedKey);

    if (institution && institution[0]) {
      onInstitutionSelected(institution[0]);
      setSearchValue("");

      pathname = `/${selectedKey}`;
    } else {
      onClear();
    }

    history.push({
      pathname,
      hash,
      search: nextParams.toString()
    });

    setUpdate(!update);
  };

  useEffect(() => {
    if (!isBafsUser && !institutionSelected) {
      const nonBafsUserInstitution = institutions?.filter(
        item => item.uuid === userInstitutionUuid
      );
      if (nonBafsUserInstitution) {
        onInstitutionSelected(nonBafsUserInstitution[0]);
      }
    }
  }, [
    isBafsUser,
    institutions,
    institutionSelected,
    userInstitutionUuid,
    onInstitutionSelected
  ]);

  const handleControlClick = (evt: React.SyntheticEvent) => {
    evt.stopPropagation();
  };

  const ListHeader = useCallback(() => {
    return (
      <>
        <div className="header-search" onClick={handleControlClick}>
          <Search
            handleSearchFunction={handleSearch}
            name="searchable-dropdown"
            placeholder="Switch institutions..."
            variant="institution-admin"
          />
        </div>
        {institutionSelected?.uuid && (
          <div
            data-item-key={institutionSelected?.uuid}
            onClick={handleInstitutionSelect}
            className={"header-item selected"}
          >
            {institutionSelected?.name}
            {institutionSelected?.institution_id && (
              <span className="right">
                {institutionSelected?.institution_id}
              </span>
            )}
          </div>
        )}
        <div
          data-item-key=""
          onClick={handleInstitutionSelect}
          className={`header-item ${!institutionSelected ? "selected" : ""}`}
        >
          <FormattedMessage id="ALL_INSTITUTIONS" />
        </div>
      </>
    );
    // This eslint-disable was added because the component Header needs to be rerendered
    // only after the handleOnChildSelect is called.
    // If not, it will rerender the header when the user types in the Search input.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  const renderInstitutions = (): JSX.Element | JSX.Element[] => {
    if (!institutions) {
      return <div />;
    }

    const institutionsNotSelected = institutions.filter(
      inst => inst.uuid !== institutionSelected?.uuid
    );

    const institutionsFiltered = searchValue
      ? institutionsNotSelected.filter(
          inst =>
            `${inst.institution_id}`.includes(searchValue) ||
            inst.name.toLowerCase().includes(searchValue)
        )
      : institutionsNotSelected;

    const items = institutionsFiltered.map(inst => {
      return (
        <li
          data-item-key={inst.uuid}
          key={inst.uuid}
          className="institution-switcher-filter-item"
        >
          {inst.name}
          <span className="right">{inst.institution_id}</span>
        </li>
      );
    });

    return items;
  };

  return (
    <SearchableDropdown
      testid="institutions-dropdown"
      disabled={!isBafsUser || !institutions || institutions.length === 1}
      listHeader={<ListHeader />}
      placeholder={{ id: "ALL_INSTITUTIONS" }}
      selectedKey={institutionSelected?.uuid || ""}
      selectedValue={{ defaultMessage: institutionSelected?.name || "" }}
      onChildSelect={handleInstitutionSelect}
    >
      {renderInstitutions()}
    </SearchableDropdown>
  );
}
export interface InstitutionProps {
  institution_id: number;
  name: string;
  uuid: string;
}

export interface InstitutionSwitcherProps {
  institutions?: InstitutionProps[] | null;
  institutionSelected: InstitutionProps | null;
  onInstitutionSelected: (institution: InstitutionProps | null) => void;
}

export interface Props {
  isBafsUser: boolean;
  institution: InstitutionSwitcherProps;
  user?: {
    /** First name in the US */
    givenName: string;
    /** Last name in the US */
    surname: string;
    userUuid?: string;
    institutionUuid?: string;
  };
}
