import React from "react";
import PropTypes from "prop-types";

import { Dropdown } from "semantic-ui-react";

function DropdownFilter({
  onChange,
  options,
  loader,
  value,
  filterValue,
  placeholder,
  search
}) {
  return options.length > 0 ? (
    <Dropdown
      data-testid={`${filterValue}-dropdown`}
      onChange={(e, data) => onChange(data.value, filterValue)}
      options={options}
      placeholder={placeholder}
      selection
      value={value}
      search={search}
    />
  ) : (
    loader
  );
}

DropdownFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  loader: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  filterValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  search: PropTypes.bool
};

export default DropdownFilter;
