export default {
  annual_revenue: 0,
  business_start_date: "",
  company_name: "",
  county_of_reg: "",
  credit_score_equifax: 0,
  credit_score_experian: 0,
  credit_score_transunion: 0,
  dob: "",
  drivers_license_no: "",
  drivers_license_st: "",
  duns_number: "",
  email: "",
  entity_type: "",
  fico_sbss: 0,
  first_name: "",
  fiscal_year_end: "",
  industry_experience: 0,
  industry_type: "",
  institution_uuid: 0,
  is_operating_entity: false,
  is_us_citizen: false,
  last_name: "",
  mail_address_city: "",
  mail_address_country: "",
  mail_address_line1: "",
  mail_address_line2: "",
  mail_address_state: "",
  mail_address_zip: "",
  middle_name: "",
  mobile_phone_no: "",
  naics_code: 0,
  next_review_date: "",
  loan_officer_uuid: "loan_uuid_00001",
  personal_annual_income: 0,
  phone_no: "",
  phy_address_city: "",
  phy_address_country: "",
  phy_address_line1: "",
  phy_address_line2: "",
  phy_address_state: "",
  phy_address_zip: "",
  previous_review_date: "",
  rent_or_own: "",
  risk_rating: 0,
  state_of_reg: "",
  tax_reporting_form: "",
  tin_type: "",
  tin: "",
  uuid: "",
  website: "",
  years_in_business: 0
};
