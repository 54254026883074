import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Form, Grid, Header, Icon } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import {
  adjustmentPeriod,
  paymentPeriod,
  paymentType,
  rateIndex,
  rateType,
  readableNumFormat,
  forceValue
} from "../../../../../../services/FormElements";
import { BorderedContainer } from "../../../../../../components/CustomUIElements";
import { PercentInput } from "../../../../../../components/CustomFormElements";
import { userCanViewPage } from "../../../../../../services/Auth";
import accrualBasisOptions from "../../../../../../services/Constants/accrualBasisOptions";
import {
  PaymentScheduleProps,
  LoanRequestsProps,
  InterestScheduleProps
} from "./PropTypes";
import {
  useInstitutionLoanClassesQuery,
  useInstitutionLoanPurposesQuery
} from "../../../../../../services/RTKQuery/institutions";
import {
  serializeLoanClassesData,
  serializeLoanPurposesData
} from "../../../../../..//services/FormElements/components/loanOptions";

const permissionsMap = {
  /**
   * TODO These links were intentionally added with issue #5723, and removed
   * with issue #7066.
   * I am leaving the logic in place for when we add them back, and disallowing
   * the links here in the meantime.
   *
   * https://github.com/bafsllc/clearwater/issues/5723
   * https://github.com/bafsllc/clearwater/issues/7066
   */
  "/loan_management/:uuid/details/interest": {
    Admin: false,
    BAFS: false,
    Institution: false
  },
  "/loan_management/:uuid/details/payments": {
    Admin: false,
    BAFS: false,
    Institution: false
  }
};

function LoanForm({
  deleteLoan,
  index,
  loanRequestUuid,
  interestSchedule,
  loanRequest,
  paymentSchedule,
  radioChange,
  handleChange,
  handleChangeNumber,
  originalAmount,
  setOriginalAmount,
  accrualValue
}) {
  const { pathname } = useLocation();
  const { data: loanClasses } = useInstitutionLoanClassesQuery(
    loanRequest?.institution_uuid
  );

  const { data: loanPurposes } = useInstitutionLoanPurposesQuery(
    loanRequest?.institution_uuid
  );

  const serializedLoanClassesData = useMemo(() => {
    return serializeLoanClassesData({
      data: loanClasses?.loan_classes,
      loan_class: loanRequest?.loan_class,
      loan_type: loanRequest?.loan_type
    });
  }, [
    loanClasses?.loan_classes,
    loanRequest?.loan_class,
    loanRequest?.loan_type
  ]);

  const serializedLoanPurposesData = useMemo(() => {
    return serializeLoanPurposesData({
      data: loanPurposes?.loan_purposes,
      loan_purpose: loanRequest?.purpose
    });
  }, [loanPurposes?.loan_purposes, loanRequest?.purpose]);

  return (
    <BorderedContainer>
      <Form autoComplete="off">
        <Grid padded>
          <Grid.Row verticalAlign="bottom">
            <Grid.Column>
              {deleteLoan && (
                <Icon
                  circular
                  color="red"
                  data-id={loanRequestUuid}
                  link
                  name="minus"
                  onClick={e => deleteLoan(e)}
                  style={{ float: "left" }}
                />
              )}
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as="h2" textAlign="left">
                {`Loan ${index + 1}`}
              </Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns="4">
            <Grid.Column>
              <Form.Select
                data-id={loanRequestUuid}
                label="Loan Class"
                name="loan_class"
                onChange={(e, data) => handleChange(data, "loanRequest")}
                options={serializedLoanClassesData?.classOptions}
                placeholder="—Please make selection—"
                value={loanRequest?.loan_class || ""}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Select
                data-id={loanRequestUuid}
                label="Loan Purpose"
                name="purpose"
                onChange={(e, data) => handleChange(data, "loanRequest")}
                options={serializedLoanPurposesData?.purposeOptions}
                placeholder="—Please make selection—"
                value={loanRequest?.purpose || ""}
              />
            </Grid.Column>

            <Grid.Column>
              <Form.Input
                data-id={loanRequestUuid}
                label="Requested Loan Amount"
                name="original_amount"
                onChange={({ target }) =>
                  setOriginalAmount(readableNumFormat(target.value))
                }
                onBlur={({ target }) =>
                  handleChangeNumber(
                    {
                      id: loanRequestUuid,
                      name: target.name,
                      value: target.value
                    },
                    "loanRequest"
                  )
                }
                placeholder="Dollar Amount"
                value={originalAmount}
              />
            </Grid.Column>
            <Grid.Column>
              <PercentInput
                data-id={loanRequestUuid}
                label="Requested Interest Rate"
                scale={3}
                name="original_rate"
                onChange={(e, data) =>
                  handleChangeNumber(
                    { ...data, id: loanRequestUuid },
                    "loanRequest"
                  )
                }
                placeholder="0.000"
                value={loanRequest?.original_rate || ""}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns="4">
            <Grid.Column>
              <Form.Select
                data-id={loanRequestUuid}
                label="Loan Type"
                name="loan_type"
                onChange={(e, data) => handleChange(data, "loanRequest")}
                disabled={!loanRequest?.loan_class}
                options={serializedLoanClassesData?.typeOptions}
                placeholder="—Please make selection—"
                value={loanRequest?.loan_type || ""}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Select
                data-id={loanRequestUuid}
                label="Rate Type"
                name="rate_type"
                onChange={(e, data) => {
                  handleChange(data, "loanRequest");
                  handleChange(data, "interestSchedule");
                }}
                options={rateType}
                placeholder="—Please make selection—"
                value={loanRequest?.rate_type || ""}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Select
                disabled={loanRequest?.rate_type === "Fixed"}
                data-id={loanRequestUuid}
                label="Rate Index"
                name="rate_index"
                onChange={(e, data) => {
                  handleChange(data, "loanRequest");
                  handleChange(data, "interestSchedule");
                }}
                options={rateIndex}
                placeholder="—Please make selection—"
                value={loanRequest?.rate_index || ""}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Select
                disabled={loanRequest?.rate_type === "Fixed"}
                data-id={loanRequestUuid}
                label="Adjustment Period"
                name="change_period"
                onChange={(e, data) => handleChange(data, "interestSchedule")}
                options={adjustmentPeriod}
                placeholder="—Please make selection—"
                value={interestSchedule.change_period || ""}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns="4" verticalAlign="bottom">
            <Grid.Column>
              <Form.Field
                checked={loanRequest?.origination_fee_type === "dollar"}
                data-id={loanRequestUuid}
                inline
                control="input"
                label="Dollar Fee"
                name="origination_fee_type"
                onChange={radioChange}
                type="radio"
                value="dollar"
              />
              <Form.Field
                checked={loanRequest?.origination_fee_type === "percentage"}
                data-id={loanRequestUuid}
                inline
                control="input"
                label="Percent Fee"
                name="origination_fee_type"
                onChange={radioChange}
                type="radio"
                value="percentage"
              />

              {loanRequest?.origination_fee_type === "percentage" ? (
                <PercentInput
                  label="Origination Fee"
                  data-id={loanRequestUuid}
                  name="origination_fee"
                  onChange={(e, data) =>
                    handleChangeNumber(
                      { ...data, id: loanRequestUuid },
                      "loanRequest"
                    )
                  }
                  placeholder="Percentage"
                  value={loanRequest?.origination_fee || ""}
                />
              ) : (
                <Form.Input
                  label="Origination Fee"
                  data-id={loanRequestUuid}
                  name="origination_fee"
                  onChange={(e, data) => handleChange(data, "loanRequest")}
                  placeholder="Dollar Amount"
                  type="number"
                  value={loanRequest?.origination_fee || ""}
                />
              )}
            </Grid.Column>

            <Grid.Column>
              <PercentInput
                disabled={loanRequest?.rate_type === "Fixed"}
                data-id={loanRequestUuid}
                label="Variable Rate Margin"
                name="margin"
                scale={3}
                onChange={(e, data) =>
                  handleChangeNumber(
                    { ...data, id: loanRequestUuid },
                    "interestSchedule"
                  )
                }
                placeholder="Percentage"
                value={interestSchedule?.margin || ""}
              />
            </Grid.Column>
            <Grid.Column>
              <PercentInput
                disabled={loanRequest?.rate_type === "Fixed"}
                data-id={loanRequestUuid}
                label="Variable Rate Floor"
                name="rate_floor"
                scale={3}
                onChange={(e, data) =>
                  handleChangeNumber(
                    { ...data, id: loanRequestUuid },
                    "loanRequest"
                  )
                }
                placeholder="Percentage"
                value={loanRequest?.rate_floor || ""}
              />
            </Grid.Column>
            <Grid.Column>
              <PercentInput
                disabled={loanRequest?.rate_type === "Fixed"}
                data-id={loanRequestUuid}
                name="rate_ceiling"
                scale={3}
                label="Variable Rate Ceiling"
                onChange={(e, data) =>
                  handleChangeNumber(
                    { ...data, id: loanRequestUuid },
                    "loanRequest"
                  )
                }
                placeholder="Percentage"
                value={loanRequest?.rate_ceiling || ""}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="4">
            <Grid.Column>
              <Form.Select
                data-id={loanRequestUuid}
                onChange={(e, data) => {
                  handleChange(data, "loanRequest");
                  handleChange(
                    { ...data, id: loanRequestUuid },
                    "interestSchedule"
                  );
                }}
                options={accrualBasisOptions}
                name="accrual_basis"
                placeholder="—Please make selection—"
                label="Accrual Basis"
                value={forceValue(accrualValue)}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="4">
            <Grid.Column>
              <Form.Select
                data-id={loanRequestUuid}
                label="Payment Type"
                name="payment_type"
                onChange={(e, data) => handleChange(data, "paymentSchedule")}
                options={paymentType}
                placeholder="—Please make selection—"
                value={paymentSchedule.payment_type || ""}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Select
                data-id={loanRequestUuid}
                label="Payment Period"
                name="payment_frequency_type"
                onChange={(e, data) => handleChange(data, "paymentSchedule")}
                options={paymentPeriod}
                placeholder="—Please make selection—"
                value={paymentSchedule.payment_frequency_type || ""}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                data-id={loanRequestUuid}
                label="# of Payments"
                name="total_number_of_payments"
                placeholder="Number"
                onChange={(e, data) =>
                  handleChangeNumber(data, "paymentSchedule")
                }
                type="number"
                value={paymentSchedule.total_number_of_payments || ""}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                data-id={loanRequestUuid}
                label="Amortization Term Months"
                name="amortization_period"
                placeholder="Number of months"
                onChange={(e, data) => handleChange(data, "loanRequest")}
                type="number"
                value={loanRequest?.amortization_period || ""}
              />
            </Grid.Column>
            <Grid.Column width={16}>
              <Form.Input
                data-id={loanRequestUuid}
                label="Description"
                name="description"
                placeholder="Please describe the terms of this loan request."
                onChange={(e, data) => handleChange(data, "loanRequest")}
                value={loanRequest?.description || ""}
              />
              <Form.Checkbox
                data-id={loanRequestUuid}
                label="Balloon Payment"
                name="balloon"
                checked={loanRequest?.balloon}
                onChange={(ev, data) =>
                  handleChange(
                    {
                      name: data.name,
                      value: data.checked,
                      id: loanRequestUuid
                    },
                    "loanRequest"
                  )
                }
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {userCanViewPage(
              "/loan_management/:uuid/details/payments",
              permissionsMap
            ) && (
              <Grid.Column width="5">
                <Link
                  to={{
                    pathname: `/loan_management/${loanRequestUuid}/details/payments`,
                    state: {
                      backlink: pathname,
                      backtext: "Return to credit requests"
                    }
                  }}
                >
                  Go to full payment schedules list
                </Link>
              </Grid.Column>
            )}
            {userCanViewPage(
              "/loan_management/:uuid/details/interest",
              permissionsMap
            ) && (
              <Grid.Column width="5">
                <Link
                  to={{
                    pathname: `/loan_management/${loanRequestUuid}/details/interest`,
                    state: {
                      backlink: pathname,
                      backtext: "Return to credit requests"
                    }
                  }}
                >
                  Go to full interest schedules list
                </Link>
              </Grid.Column>
            )}
            {userCanViewPage("/loan_management/:uuid/details/balance") && (
              <Grid.Column width="5" floated="right" textAlign="right">
                <Link
                  to={{
                    pathname: `/loan_management/${loanRequestUuid}/details/balance`
                  }}
                  target="_blank"
                  className="text-sm"
                >
                  View in Loan Management
                </Link>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Form>
    </BorderedContainer>
  );
}

LoanForm.propTypes = {
  deleteLoan: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleChangeNumber: PropTypes.func.isRequired,
  loanRequestUuid: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  paymentSchedule: PaymentScheduleProps.isRequired,
  loanRequest: LoanRequestsProps.isRequired,
  interestSchedule: InterestScheduleProps.isRequired,
  radioChange: PropTypes.func.isRequired,
  accrualValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  originalAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setOriginalAmount: PropTypes.func.isRequired
};

export default LoanForm;
