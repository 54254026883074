import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import { FormatCurrency, FormatPercent } from "num-format";
import { EditButton } from "../../../../../../../../components/CustomFormElements";

function ParticipantRow({
  participant,
  institution,
  participationIsSetup,
  triggerEditModal
}) {
  return !participant.institution_uuid ? null : (
    <Table.Row key={participant.institution_uuid || "retained"}>
      <Table.Cell>
        {participationIsSetup ? (
          <a
            href={`/loan_management/${participant.uuid}/details/balance`}
            rel="noreferrer noopener"
            target="_blank"
          >
            {participant.bafs_account_number}
          </a>
        ) : (
          participant.bafs_account_number
        )}
      </Table.Cell>
      <Table.Cell>{institution.name}</Table.Cell>
      <Table.Cell>
        {FormatCurrency(participant.current_contract_amount || 0, false)}
      </Table.Cell>
      <Table.Cell>
        {FormatCurrency(participant.available_balance || 0, false)}
      </Table.Cell>
      <Table.Cell>
        {`${FormatPercent(participant.interest_rate || 0, 3)}`}
      </Table.Cell>
      <Table.Cell>
        {`${FormatPercent(participant.participation_percentage_owned || 0, 3)}`}
      </Table.Cell>
      <Table.Cell>
        {participationIsSetup || !triggerEditModal ? (
          participant.status
        ) : (
          <EditButton floated="left" onClick={triggerEditModal} />
        )}
      </Table.Cell>
    </Table.Row>
  );
}

ParticipantRow.propTypes = {
  participant: PropTypes.objectOf(PropTypes.any),
  institution: PropTypes.objectOf(PropTypes.any),
  participationIsSetup: PropTypes.bool,
  triggerEditModal: PropTypes.func
};

export default ParticipantRow;
