export const paginationDefaults = {
  number: 0,
  size: 10
};

export const initialState = {
  default: { size: 0, number: 0 }
};

export default function Reducer(state, action) {
  const safeState = state ?? initialState;
  switch (action.type) {
    case "PAGINATION_CLEAR_STATE":
      return initialState;

    case "PAGINATION_INITIALIZE":
      return {
        ...safeState,
        [action.name]: {
          number: paginationDefaults.number,
          size: action.pageSizeOverride || paginationDefaults.size
        }
      };

    case "PAGINATION_UPDATE_NUMBER":
      return {
        ...safeState,
        [action.name]: {
          number: action.number,
          size: safeState[action.name].size
        }
      };

    case "PAGINATION_UPDATE_SIZE":
      return {
        ...safeState,
        [action.name]: { number: paginationDefaults.number, size: action.size }
      };

    case "PAGINATION_UPDATE_LAST_PAGE":
      return {
        ...safeState,
        [action.name]: { ...safeState[action.name], lastPage: action.lastPage }
      };

    default:
      return safeState;
  }
}
