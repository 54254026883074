import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Segment, Table, Button, Icon, Grid } from "semantic-ui-react";
import ErrorReporter from "../../../../services/ErrorReporter";

import { Institutions } from "../../../../services/ApiLib";
import PaginationControls, {
  paginationChanged
} from "../../../../components/Pagination";
import InstitutionModal from "./components/InstitutionsModal";
import NightlyProcessModalOpen from "./components/NightlyProcessModal";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const paginationName = "default";

const headers = [
  "Institution",
  "Institution ID",
  "Institutional Admin",
  "Core of Record",
  "",
  ""
];

export default function InstitutionsObj() {
  const dispatch = useDispatch();
  const { pagination, institutions, institution } = useSelector(reduxState => ({
    pagination: reduxState.PaginationReducer[paginationName],
    institutions: reduxState.SystemAdminReducer.institutions,
    institution: reduxState.SystemAdminReducer.institution
  }));

  const prevPagination = usePrevious(pagination);

  const [institutionModalOpen, setInstitutionModalOpen] = useState(false);
  const [nightlyProcessModalOpen, setNightlyProcessModalOpen] = useState(false);
  const [currentlyOpenInstitutionId, setCurrentlyOpenInstitutionId] =
    useState("");

  const openModal = data => {
    setInstitutionModalOpen(true);
    dispatch({ type: "SET_SYSTEM_ADMIN_INSTITUTION", institution: data });
  };

  const cancelModal = () => {
    setInstitutionModalOpen(false);
    dispatch({ type: "SET_SYSTEM_ADMIN_INSTITUTION", institution: null });
  };

  const addInstitution = body => {
    Institutions.add(
      ({ data }) => dispatch({ data, type: "SYSTEM_ADMIN_INSTITUTION_NEW" }),
      () => ErrorReporter("Could not add Institution"),
      body
    );
  };

  const updateInstitution = body => {
    Institutions.update(
      ({ data }) => dispatch({ data, type: "SYSTEM_ADMIN_INSTITUTION" }),
      () => ErrorReporter("Could not update Institution"),
      body
    );
  };

  const onSave = edit => {
    if (edit) {
      updateInstitution(institution);
    } else {
      addInstitution(institution);
    }
    cancelModal();
  };

  const getInstitutions = all => {
    let tempPagination = pagination;
    if (all) {
      tempPagination = { number: 0, size: 999 };
    }
    const type = all
      ? "SYSTEM_ADMIN_INSTITUTIONS_ALL"
      : "SYSTEM_ADMIN_INSTITUTIONS";
    Institutions.get(
      ({ data }) => {
        dispatch({ data, type });
      },
      () => ErrorReporter("Could not fetch Institutions"),
      tempPagination
    );
  };

  useEffect(() => {
    getInstitutions(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paginationChanged(prevPagination, pagination)) {
      getInstitutions(false);
    }
  });

  const tableHeaders = headers.map(header => (
    <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
  ));

  let tableBody = null;

  if (institutions && institutions.length > 0) {
    tableBody = institutions.map(inst => (
      <Table.Row key={inst.uuid}>
        <Table.Cell>
          <span>{inst.name}</span>
          <i style={{ paddingLeft: "10px", color: "grey" }}>
            {inst.institution_city ? `${inst.institution_city}, ` : ""}
            {inst.institution_state}
          </i>
        </Table.Cell>
        <Table.Cell>{inst.institution_id}</Table.Cell>
        <Table.Cell>{inst.institution_admin}</Table.Cell>
        <Table.Cell>{inst.core_of_record ? "YES" : "NO"}</Table.Cell>
        <Table.Cell>
          <Button
            name="run_nightly_build"
            className="btn btn-default"
            onClick={() => {
              setCurrentlyOpenInstitutionId(inst.uuid);
              setNightlyProcessModalOpen(true);
            }}
          >
            Run Nightly Process
          </Button>
        </Table.Cell>
        <Table.Cell width="1">
          <Grid>
            <Grid.Column textAlign="center">
              <Button
                basic
                circular
                icon
                onClick={() => openModal(inst)}
                name="editModalOpen"
              >
                <Icon name="pencil alternate" color="green" />
              </Button>
            </Grid.Column>
          </Grid>
        </Table.Cell>
      </Table.Row>
    ));
  }

  return (
    <div>
      {institutionModalOpen ? (
        <InstitutionModal
          institution={institution}
          onCancel={cancelModal}
          onSave={onSave}
          edit={!!institution.uuid}
        />
      ) : (
        ""
      )}
      <Segment>
        <Button
          name="newInstitutionButton"
          floated="right"
          onClick={() =>
            openModal({
              name: "",
              core_of_record: false,
              daily_transaction_file: false,
              tax_id: "",
              institution_type: "",
              routing_number: "",
              charter_number: "",
              regulator: "",
              institution_primary_address: "",
              institution_primary_address_2: "",
              institution_city: "",
              institution_state: "",
              institution_postal_code: "",
              mailing_primary_address: "",
              mailing_primary_address_2: "",
              mailing_city: "",
              mailing_state: "",
              mailing_postal_code: "",
              non_standard_file: false
            })
          }
          style={{ marginBottom: "10px" }}
        >
          New
        </Button>
        <Table compact>
          <Table.Header>
            <Table.Row>{tableHeaders}</Table.Row>
          </Table.Header>
          <Table.Body>{tableBody}</Table.Body>
        </Table>
        <PaginationControls
          length={institutions.length}
          name={paginationName}
          // TODO: Add pagination support to institutions endpoint:
          // https://github.com/bafsllc/clearwater/issues/7349
          lastPage={institutions.length < pagination.size}
        />
      </Segment>
      <NightlyProcessModalOpen
        open={nightlyProcessModalOpen}
        closeModal={() => {
          setNightlyProcessModalOpen(false);
        }}
        currentlyOpenInstitutionId={currentlyOpenInstitutionId}
      />
    </div>
  );
}
