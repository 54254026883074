/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/prefer-default-export */
import { jsPDF } from "jspdf";
import { parseISO } from "date-fns";
import logger from "../../logger";

const DEFAULT_FONT_NAME = "times"; // We're just hoping every computer has this font...
const DEFAULT_FONT_SIZE_POINTS = 12;
const DEFAULT_SIDE_MARGIN = 1;
const DEFAULT_UNIT = "in";
const DEFAULT_VERTICAL_MARGIN = 1;
const PAGE_HEIGHT = 11;
const PAGE_WIDTH = 8.5;

/**
 * Create and save a PDF document containing the messages provided. Handles all
 * the expected craziness that usually happens when rendering a document like
 * units and measuring text size.
 * @param {Message[]} messages
 * @param {((progress: number, state?: "render" | "save") => void) | null}
 * [onProgress] An optional callback to get file preparation progress.
 * @param {string} [fileName] The name of the file that will be saved; no path,
 * must include the file extension '.pdf'. Defaults to 'messages.pdf.'
 * @return {Promise<undefined | Error>} Resolves after the file has been saved.
 */
export async function createMessagesPdf(
  messages,
  onProgress = null,
  fileName = "application_notes.pdf"
) {
  return new Promise(resolve => {
    const processor = processMessageFactory({
      bottomMargin: DEFAULT_VERTICAL_MARGIN,
      lineHeightFactor: 1.4,
      leftMargin: DEFAULT_SIDE_MARGIN,
      pageHeight: PAGE_HEIGHT,
      pageWidth: PAGE_WIDTH,
      rightMargin: DEFAULT_SIDE_MARGIN,
      topMargin: DEFAULT_VERTICAL_MARGIN
    });

    let taskCount = 0;
    const totalTasks = messages.length + 3;

    /**
     * @param {"render" | "save"} [status]
     */
    function raiseProgress(status) {
      taskCount += 1;
      onProgress && onProgress((100 / totalTasks) * taskCount, status);
    }

    raiseProgress();

    // eslint-disable-next-line new-cap
    new jsPDF({
      format: [PAGE_HEIGHT, PAGE_WIDTH],
      unit: DEFAULT_UNIT
      // As of v2.5.1 the `advancedAPI` does not allow you to render pages 2 and greater correctly.
    }).compatAPI(doc => {
      raiseProgress();

      /** @type {Error} */
      let error;
      let top = DEFAULT_VERTICAL_MARGIN;
      for (let i = 0; i < messages.length; i += 1) {
        const msg = messages[i];
        try {
          top = processor(doc, msg, top);
        } catch (err) {
          logger.error(
            "createMessagesPdf: error processing the messages; err=",
            err
          );
          error = err;
          break;
        }

        if (error) {
          break;
        }

        raiseProgress("render");
      }

      if (!error) {
        raiseProgress("save");
        doc.save(fileName);
      }

      resolve(error);
    });
  });
}

/**
 * Produces `processMessage` that can be used to add a message to a PDF
 * document.
 * @param {PageOptions} options
 */
function processMessageFactory({
  bottomMargin,
  lineHeightFactor,
  leftMargin,
  pageHeight,
  pageWidth,
  rightMargin,
  topMargin
}) {
  /**
   * Add a message to the PDF document.
   * @param {jsPDF} doc
   * @param {Message & {userName: string}} message
   * @param {number} [top]
   */
  return function processMessage(doc, message, top = 0) {
    const textOptions = {
      lineHeightFactor
    };
    const lineHeight =
      getFontSizeInches(DEFAULT_FONT_SIZE_POINTS) * lineHeightFactor;
    const contentBottom = pageHeight - bottomMargin;

    /**
     * Adds a single line to the PDF document. If the line won't fit on the page
     * it adds a new page.
     * @param {number} lineTop Where the top of line will be drawn on the page.
     * @param {string | {parts: string[], beforeRender: BeforeRender}} options
     * @returns {number}
     */
    function addLine(lineTop, options) {
      let nextLineTop = lineTop;
      let lineBottom = nextLineTop + lineHeight;
      if (contentBottom < lineBottom) {
        doc.addPage();
        nextLineTop = topMargin;
        lineBottom = nextLineTop + lineHeight;
      }

      if (typeof options === "string") {
        doc.text(options, leftMargin, nextLineTop, textOptions);
      } else {
        let left = leftMargin;
        options.parts.forEach((part, i) => {
          const nextLeft = options.beforeRender(part, i, left);
          doc.text(part, left, nextLineTop, textOptions);
          left = nextLeft;
        });
      }

      return lineBottom;
    }

    // ---------- Add the sender information. ----------
    doc
      .setFontSize(DEFAULT_FONT_SIZE_POINTS)
      .setFont(DEFAULT_FONT_NAME, "normal")
      .setTextColor("#000");

    let nextLineTop = addLine(top, {
      parts: [`${message.userName} `, "added a message:"],
      beforeRender: (part, index, left) => {
        // Set this before measuring, looks like `getStringUnitWidth` doesn't
        // properly take the `fontStyle` option into account.
        doc.setFont(DEFAULT_FONT_NAME, index === 0 ? "bold" : "normal");

        // The `width` is returned in units relative to the font. `width`
        // needs to be converted to inches.
        const width = doc.getStringUnitWidth(part, {
          fontName: DEFAULT_FONT_NAME,
          fontSize: DEFAULT_FONT_SIZE_POINTS,
          fontStyle: index === 0 ? "bold" : "normal"
        });

        return left + (width * DEFAULT_FONT_SIZE_POINTS) / 72;
      }
    });

    // Add space after the sender line.
    nextLineTop += 0.05;

    // ---------- Add the message text. ----------
    doc
      .setFontSize(DEFAULT_FONT_SIZE_POINTS)
      .setFont(DEFAULT_FONT_NAME, "normal")
      .setTextColor("#000");

    /** @type {string[]} */
    const contentLines = doc.splitTextToSize(
      message.message,
      pageWidth - (leftMargin + rightMargin),
      {
        fontName: DEFAULT_FONT_NAME,
        fontSize: DEFAULT_FONT_SIZE_POINTS,
        fontStyle: "normal"
      }
    );

    contentLines.forEach(line => {
      nextLineTop = addLine(nextLineTop, line);
    });

    // Add space after the message text.
    nextLineTop += 0.05;

    // ---------- Add the date. ----------
    doc
      .setFontSize(DEFAULT_FONT_SIZE_POINTS - 2)
      .setFont(DEFAULT_FONT_NAME, "normal")
      .setTextColor("#404040");

    const messageDate = new Intl.DateTimeFormat("en-US", {
      dateStyle: "full",
      hour12: true,
      timeStyle: "short"
    }).format(parseISO(message.date));

    // Intl returns surrogate pair characters (even for single byte Latin
    // characters) and the PDF writer interprets the two bytes that make up a
    // single character as the character and a space. Fortunately JS has a
    // built-in way to fix that `normalize()` which we use here.
    nextLineTop = addLine(nextLineTop, messageDate.normalize("NFKD"));

    // ---------- Done rendering the message to the PDF. ----------

    // Add space after the last content line.
    nextLineTop += 0.25;

    return nextLineTop;
  };
}

/**
 * @param {number} fontSizePoints
 */
function getFontSizeInches(fontSizePoints) {
  return fontSizePoints / 72;
}

/**
 * Used while writing a line to the PDF document. This is invoked immediately
 * before the next `part` is written to the document allowing changes to be made
 * (for example to the font style).
 * @callback BeforeRender
 * @param {string} part The string that will be written to the document.
 * @param {number} partIndex The index in the array of parts provided to
 * `addLine`.
 * @param {number} left Where to start writing the left side of `part` relative
 * to the page.
 * @returns {number} The left edge for the next part to be rendered (e.g. the
 * right edge of the part just written).
 */

/**
 * @typedef Message
 * @property {string} date
 * @property {string} message
 * @property {string} userName
 */

/**
 * @typedef PageOptions
 * @property {number} bottomMargin
 * @property {number} lineHeightFactor
 * @property {number} leftMargin
 * @property {number} pageHeight
 * @property {number} pageWidth
 * @property {number} rightMargin
 * @property {number} topMargin
 */
