import { FormattedMessage } from "react-intl";
import { MessageProps } from "../../../../types/text";

/*
 * Creates a formatted message wrapped in a paragraph with provided class names
 * This is exclusively to be use in the Dialogs components
 */
export function DialogSubtext({
  message,
  classes
}: React.PropsWithChildren<DialogSubtextProps>) {
  const getContentClasses = () => (classes ? classes.contentClasses : []);
  return (
    <p className={getContentClasses().join(" ")}>
      {"id" in message ? (
        <FormattedMessage {...message} />
      ) : (
        message.defaultMessage
      )}
    </p>
  );
}

export interface DialogSubtextProps {
  /** The message that prints to the user */
  message: MessageProps;
  /** An object containing the classes we want to apply to the container (containerClasses) and the content (contentClasses) */
  classes?: { containerClasses?: string[]; contentClasses: string[] };
}
