import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";
import Form from "../Form";

export class InstitutionsModalObj extends Component {
  constructor(props) {
    super(props);
    this.updateStore = this.updateStore.bind(this);
    this.state = {
      foundTin: null
    };
  }

  updateStore(nextInstitution) {
    const { dispatch, institution, allInstitutions } = this.props;
    if (nextInstitution.tax_id) {
      const savedVal = nextInstitution.tax_id.replace(/-/g, "");
      const foundTin =
        allInstitutions
          .filter(a => a.institution_id !== institution.institution_id)
          .find(i => i.tax_id && i.tax_id.replace(/-/g, "") === savedVal) ||
        null;
      this.setState({ foundTin });
    }
    dispatch({
      type: "UPDATE_SYSTEM_ADMIN_INSTITUTION",
      institution: { ...institution, ...nextInstitution }
    });
  }

  render() {
    const { onCancel, onSave, edit, institution } = this.props;
    return (
      <Modal open key="addEditInstitutionModal" size="large">
        <Modal.Header>
          {edit ? "Edit Institution" : "New Institution"}
        </Modal.Header>
        <Modal.Content>
          <Form
            institution={institution}
            updateStore={this.updateStore}
            foundTin={this.state.foundTin}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onCancel} id="cancel" basic>
            Cancel
          </Button>
          <Button
            color="green"
            onClick={() => onSave(edit)}
            id="save"
            disabled={!!this.state.foundTin}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

InstitutionsModalObj.propTypes = {
  dispatch: PropTypes.func.isRequired,
  institution: PropTypes.objectOf(PropTypes.any).isRequired,
  edit: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  allInstitutions: PropTypes.arrayOf(PropTypes.object).isRequired
};

const mapStateToProps = state => ({
  institution: state.SystemAdminReducer.institution,
  allInstitutions: state.SystemAdminReducer.allInstitutions
});

export default connect(mapStateToProps)(InstitutionsModalObj);
