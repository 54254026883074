import React, { useState } from "react";
import { Form, Button, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import { FrictionModal } from "../../../../../../../../components/Modals";
import CreditMemoTooltip from "../CreditMemoTooltip";

export const buttonText = "Change Template";
export const modalMessage =
  "Are you sure you want to change the template for this Credit Memo?";
export const modalWarning =
  "All work on the narrative will be erased. This action cannot be reversed.";

export const editButtonText = "Edit Narrative";

function TemplateSelection({
  templateDropdownDisabled,
  selectedTemplateValue,
  options,
  editButtonDisabled,
  handleChange,
  handleEdit
}) {
  const [frictionModalOpen, setFrictionModalOpen] = useState(false);

  const handleChangeTemplate = (e, { value }) => {
    handleChange(value);
  };
  const handleModalSubmit = () => {
    setFrictionModalOpen(false);
    handleChange();
  };

  return (
    <div data-testid="template-selection">
      <Form>
        <Form.Field>
          <label>Select credit memo template</label>
          <Form.Select
            data-testid="template-dropdown"
            fluid
            onChange={handleChangeTemplate}
            options={options}
            placeholder="Please make a selection"
            selection
            value={selectedTemplateValue || ""}
            width={8}
            disabled={templateDropdownDisabled}
          />
        </Form.Field>
        <Form.Field width={5}>
          <CreditMemoTooltip />
        </Form.Field>
        <Button
          data-testid="change-template-button"
          primary
          disabled={!selectedTemplateValue}
          onClick={() => {
            setFrictionModalOpen(true);
          }}
        >
          <Icon name="close" /> {buttonText}
        </Button>
        <Button
          data-testid="edit-template-button"
          primary
          disabled={editButtonDisabled}
          onClick={handleEdit}
        >
          <Icon name="pencil" /> {editButtonText}
        </Button>
      </Form>
      <FrictionModal
        open={frictionModalOpen}
        header={buttonText}
        message={modalMessage}
        warning={modalWarning}
        validateString="CONFIRM"
        onCancel={() => {
          setFrictionModalOpen(false);
        }}
        onSubmit={handleModalSubmit}
        confirmLabelText="Next"
      />
    </div>
  );
}

TemplateSelection.propTypes = {
  templateDropdownDisabled: PropTypes.bool,
  selectedTemplateValue: PropTypes.string,
  options: PropTypes.objectOf(PropTypes.object),
  editButtonDisabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired
};

export default TemplateSelection;
