export const initialState = {
  assetModal: {},
  assets: {},
  collateralModal: {},
  collaterals: {},
  collatUuid: "",
  confirmModal: false,
  error: "",
  entities: [],
  entityAssets: [],
  isAdd: false,
  isOpen: false,
  loanApp: {},
  loanRequests: [],
  loanReqUuid: ""
};

// eslint-disable-next-line default-param-last
const LorCollateralReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOR_COLLATERAL_REDUCER_UPDATE_LOAN_REQUESTS":
      return { ...state, loanRequests: action.loanRequests };

    case "LOR_COLLATERAL_REDUCER_UPDATE_COLLATERAL":
      return {
        ...state,
        collaterals: { ...state.collaterals, [action.uuid]: action.collaterals }
      };

    case "LOR_COLLATERAL_REDUCER_UPDATE_ERROR":
      return { ...state, error: action.error };

    case "LOR_COLLATERAL_REDUCER_UPDATE_ASSETS":
      return {
        ...state,
        assets: { ...state.assets, [action.uuid]: action.assets }
      };

    case "LOR_COLLATERAL_REDUCER_TOGGLE_MODAL":
      return {
        ...state,
        isAdd: action.isAdd,
        isOpen: !state.isOpen
      };

    case "LOR_COLLATERAL_REDUCER_UPDATE_COLLATERAL_MODAL":
      return {
        ...state,
        collateralModal: { ...state.collateralModal, ...action.payload }
      };

    case "LOR_COLLATERAL_REDUCER_UPDATE_ASSET_MODAL":
      return {
        ...state,
        assetModal: { ...state.assetModal, [action.name]: action.value }
      };
    case "LOR_COLLATERAL_REDUCER_CLEAR_ASSET_MODAL":
      return {
        ...state,
        assetModal: { ...state.assetModal, [action.name]: action.value }
      };

    case "LOR_COLLATERAL_REDUCER_CLOSE_MODAL":
      return {
        ...state,
        entityAssets: [],
        assetModal: {},
        collateralModal: {},
        entities: [],
        isAdd: false,
        isOpen: false
      };

    case "LOR_COLLATERAL_REDUCER_UPDATE_ENTITIES": {
      const newEntities = [...state.entities, ...action.entities];
      const entities = {};
      newEntities.forEach(e => {
        entities[e.uuid] = e;
      });
      return {
        ...state,
        entities: Object.values(entities)
      };
    }

    case "LOR_COLLATERAL_REDUCER_SET_MODAL":
      return {
        ...state,
        collateralModal: action.collateral,
        assetModal: action.asset
      };

    case "LOR_COLLATERAL_REDUCER_POPULATE_COLLATERAL_ENTITY_ASSETS":
      return {
        ...state,
        entityAssets: action.entityAssets,
        assetModal: { ...state.assetModal, entity_uuid: action.entityUuid }
      };

    case "LOR_REDUCER_COLLATERAL_MODAL_SET_ASSET":
      return { ...state, assetModal: action.asset };

    case "LOR_COLLATERAL_REDUCER_TOGGLE_CONFIRMATION_MODAL":
      return {
        ...state,
        confirmModal: !state.confirmModal,
        collatUuid: action.collatUuid,
        loanReqUuid: action.loanReqUuid
      };

    case "LOR_COLLATERAL_REDUCER_REMOVE_COLLATERAL": {
      const newCollat = [...state.collaterals[action.loanReqUuid]];
      const collaterals = newCollat.filter(
        collat => collat.uuid !== action.collatUuid
      );
      return {
        ...state,
        collaterals: {
          ...state.collaterals,
          [action.loanReqUuid]: collaterals
        },
        collatUuid: "",
        loanReqUuid: "",
        confirmModal: false
      };
    }

    default:
      return state;
  }
};

export default LorCollateralReducer;
