import React from "react";
import PropTypes from "prop-types";
import { Header, Form, Segment, Dropdown } from "semantic-ui-react";
import { EditModal } from "../../../../../../../../../../../../components/Modals";
import {
  DateInput,
  PercentInput
} from "../../../../../../../../../../../../components/CustomFormElements";
import Layout from "./Layout";

const statusOptions = [
  { key: "Active", value: "Active", text: "Active" },
  { key: "Completed", value: "Completed", text: "Completed" },
  { key: "Upcoming", value: "Upcoming", text: "Upcoming" }
];

const rateTypeOptions = [
  { key: "Variable", value: "Variable", text: "Variable" },
  { key: "Fixed", value: "Fixed", text: "Fixed" }
];

const indexOptions = [
  { key: "1 Month CMT", value: "1 Month CMT", text: "1 Month CMT" },
  { key: "3 Month CMT", value: "3 Month CMT", text: "3 Month CMT" },
  { key: "6 Month CMT", value: "6 Month CMT", text: "6 Month CMT" },
  { key: "1 Yr CMT", value: "1 Yr CMT", text: "1 Yr CMT" },
  { key: "2 Yr CMT", value: "2 Yr CMT", text: "2 Yr CMT" },
  { key: "3 Yr CMT", value: "3 Yr CMT", text: "3 Yr CMT" },
  { key: "5 Yr CMT", value: "5 Yr CMT", text: "5 Yr CMT" },
  { key: "7 Yr CMT", value: "7 Yr CMT", text: "7 Yr CMT" },
  { key: "10 Yr CMT", value: "10 Yr CMT", text: "10 Yr CMT" },
  { key: "20 Yr CMT", value: "20 Yr CMT", text: "20 Yr CMT" },
  { key: "30 Yr CMT", value: "30 Yr CMT", text: "30 Yr CMT" },
  { key: "Fixed", value: "Fixed", text: "Fixed" },
  { key: "WSJP", value: "WSJP", text: "WSJP" },
  { key: "SBA PEG", value: "SBA PEG", text: "SBA PEG" }
];

const paymentFrequencyTypeOptions = [
  { key: "Day(s)", value: "Day(s)", text: "Day(s)" },
  { key: "Week(s)", value: "Week(s)", text: "Week(s)" },
  { key: "Month(s)", value: "Month(s)", text: "Month(s)" },
  { key: "Year(s)", value: "Year(s)", text: "Year(s)" },
  { key: "DOM 29", value: "DOM 29", text: "DOM 29" },
  { key: "DOM 30", value: "DOM 30", text: "DOM 30" },
  { key: "EOM(s)", value: "EOM(s)", text: "EOM(s)" },
  { key: "EOQ", value: "EOQ", text: "EOQ" },
  { key: "15 & EOM", value: "15 & EOM", text: "15 & EOM" }
];

const interestTypeOptions = [
  { key: "simple", value: "Simple", text: "Simple" },
  {
    key: "compound",
    value: "Compound",
    text: "Compound",
    disabled: true
  }
];

const accrualBasisOptions = [
  { key: "30/360", value: "30/360", text: "30/360" },
  { key: "30/365", value: "30/365", text: "30/365" },
  { key: "Actual/Actual", value: "Actual/Actual", text: "Actual/Actual" },
  { key: "Actual/360", value: "Actual/360", text: "Actual/360" },
  { key: "Actual/365", value: "Actual/365", text: "Actual/365" }
];

const defaultInterestSchduleItem = {
  status: statusOptions[0].value,
  rate_type: rateTypeOptions[0].value,
  interest_rate_method: interestTypeOptions[0].value
};

const rateTypeChange = {
  Fixed: {
    rate_type: "Fixed",
    rate_index: undefined,
    margin: undefined,
    next_change_date: undefined,
    change_frequency: undefined,
    change_period: undefined
  },
  Variable: { rate_type: "Variable" }
};

function AddScheduleItemModal({
  defaultValues = defaultInterestSchduleItem,
  header = "Add Interest Schedule",
  ...props
}) {
  return (
    <EditModal
      header={header}
      size="small"
      defaultValues={defaultValues}
      required={{
        accrual_basis: {},
        end_date: {},
        interest_rate: {},
        interest_rate_method: {},
        rate_type: {
          condition: "Variable",
          required: [
            "rate_index",
            "margin",
            "next_change_date",
            "change_frequency",
            "change_period"
          ]
        },
        start_date: {},
        status: {}
      }}
      {...props}
    >
      {({ onChange, values, onMultipleChanges }) => {
        const isVariable = values.rate_type === "Variable";

        return (
          <Segment padded basic>
            <Layout>
              <Header as="h4">Rate Information</Header>

              <Form.Field
                control={Dropdown}
                label="Schedule Status"
                name="status"
                options={statusOptions}
                value={values.status}
                selection
                onChange={(ev, { name, value }) => onChange({ name, value })}
              />
              <Form.Field
                control={Dropdown}
                label="Rate Type"
                name="rate_type"
                options={rateTypeOptions}
                value={values.rate_type}
                selection
                onChange={(ev, { value }) =>
                  onMultipleChanges(rateTypeChange[value])
                }
              />
              <Form.Field
                control={Dropdown}
                disabled={!isVariable}
                label="Index"
                name="rate_index"
                clearable
                options={indexOptions}
                value={values.rate_index || ""}
                selection
                onChange={(ev, { name, value }) => onChange({ name, value })}
              />

              <PercentInput
                label="Margin"
                name="margin"
                onChange={(e, { name, value }) => onChange({ name, value })}
                value={values.margin}
                disabled={!isVariable}
                scale={3}
              />
              <DateInput
                asForm
                label="Next Rate Change"
                name="next_change_date"
                onChange={(ev, { name, value }) => onChange({ name, value })}
                value={values.next_change_date}
                disabled={!isVariable}
              />
              <Form.Input
                type="number"
                label="Then every"
                name="change_frequency"
                action={
                  <Dropdown
                    name="change_period"
                    button
                    basic
                    floating
                    options={paymentFrequencyTypeOptions}
                    onChange={(ev, { name, value }) =>
                      onChange({ name, value })
                    }
                    pointing="top"
                    value={values.change_period || ""}
                  />
                }
                onChange={(ev, { name, value }) => onChange({ name, value })}
                disabled={!isVariable}
                value={values.change_frequency || ""}
              />

              <PercentInput
                label="Current Rate"
                name="interest_rate"
                onChange={(e, { name, value }) => onChange({ name, value })}
                value={values.interest_rate}
                scale={3}
              />
              <DateInput
                asForm
                label="Begin Date"
                name="start_date"
                onChange={(ev, { name, value }) => onChange({ name, value })}
                value={values.start_date}
              />

              <DateInput
                asForm
                label="End Date"
                name="end_date"
                onChange={(ev, { name, value }) => onChange({ name, value })}
                value={values.end_date}
              />

              <Header as="h4">Calculation Information</Header>

              <Form.Field
                control={Dropdown}
                label="Interest Type"
                name="interest_rate_method"
                options={interestTypeOptions}
                value={values.interest_rate_method}
                selection
                onChange={(ev, { name, value }) => onChange({ name, value })}
              />
              <Form.Field
                control={Dropdown}
                label="Accrual Basis"
                name="accrual_basis"
                options={accrualBasisOptions}
                value={values.accrual_basis}
                selection
                onChange={(ev, { name, value }) => onChange({ name, value })}
              />
            </Layout>
          </Segment>
        );
      }}
    </EditModal>
  );
}

AddScheduleItemModal.propTypes = {
  defaultValues: PropTypes.object,
  header: PropTypes.string
};

export default AddScheduleItemModal;
