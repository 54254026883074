import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import { asyncFetchService } from "../../../FetchService";
import { FormatUrlV2 } from "../../../FormatUrl";
import { RemoveNullKeys } from "../Utilities";

async function get(parentUuid, pagination) {
  TypeCheck(parentUuid, "string", false);
  let params = "";
  if (pagination) {
    params = `&page_number=${pagination.number}&page_size=${pagination.size}`;
  }
  let url = "";
  if (parentUuid) {
    url = FormatUrlV2(ApiEndpoints.schedulesGetAll, { parentUuid }) + params;
  }
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

async function asyncUpdate(uuid, body) {
  TypeCheck(uuid, "string", true);
  TypeCheck(body, "object", true);

  const url = FormatUrlV2(ApiEndpoints.schedule, { uuid });
  const convertedBody = RemoveNullKeys(body);
  const rsp = await asyncFetchService("PUT", url, convertedBody);
  return rsp;
}

async function asyncAdd(body) {
  TypeCheck(body, "object", true);

  const url = ApiEndpoints.baseUri + ApiEndpoints.schedules;
  const convertedBody = RemoveNullKeys(body);

  const rsp = await asyncFetchService("POST", url, convertedBody);
  return rsp;
}

export default {
  get,
  asyncUpdate,
  asyncAdd
};
