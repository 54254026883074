import { PmtFrequencyTypes } from "./sharedTypes";

export enum PaymentTypes {
  P_AND_I = "P&I",
  INTEREST_ONLY = "Interest Only",
  MATURITY = "Maturity",
  PRINCIPAL = "Principal"
}

export enum PostingMethods {
  effective = "Effective",
  due = "Due"
}

export enum ScheduleStatuses {
  active = "Active",
  completed = "Completed",
  deleted = "Deleted",
  upcoming = "Upcoming"
}

export class PaymentSchedule {
  uuid: string;
  institution_uuid: string;
  loan_uuid: string;
  first_payment_date: Date;
  next_payment_date: Date;
  next_payment_amount: number;
  bills_remaining: number;
  payment_frequency: number;
  payment_frequency_type: PmtFrequencyTypes;
  payment_type: PaymentTypes;
  posting_method: PostingMethods;
  status: ScheduleStatuses;
  created_datetime: Date;
  processing_order: number;
  total_number_of_payments: number;
  phoenix_import: boolean;
  phx_pmt_sched_id_no: number;
  deleted: boolean;
}
