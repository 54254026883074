import React from "react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";
import TypeCheck from "typecheck-extended";

/**
 * Children of ModalWrappingRow will be contained in grids, columns, and rows to
 * achieve the desired layout.
 * @param {object} props
 * @param {*} props.children The child react components.
 * @param {number} props.columns The number of columns to display the children
 * in. If there are more children than columns then a new "row" will be started.
 * @returns A React component.
 */
export default function ModalWrappingRow({ children, columns }) {
  TypeCheck(columns, "number");

  const arrChildren = React.Children.toArray(children);
  const rows = [];
  while (arrChildren.length) {
    rows.push(arrChildren.splice(0, columns));
  }

  return (
    <Grid.Row>
      <Grid.Column>
        <Grid>
          {rows.map((row, i) => (
            <Grid.Row columns={columns} key={`modal-wrapping-row-row-${i}`}>
              {row.map((column, j) => (
                <Grid.Column key={`modal-wrapping-row-column-${j}`}>
                  {column}
                </Grid.Column>
              ))}
            </Grid.Row>
          ))}
        </Grid>
      </Grid.Column>
    </Grid.Row>
  );
}

ModalWrappingRow.propTypes = {
  children: PropTypes.node,
  columns: PropTypes.number
};
