import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import { FormatUrlV2 } from "../../../FormatUrl";
import FetchService, { asyncFetchService } from "../../../FetchService";
import { ConcatQueryParams } from "../Utilities";
import logger from "../../../logger";

function get(onSuccess, onError, entityUuid, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(entityUuid, "string", false);

  const url = FormatUrlV2(ApiEndpoints.entityIndebtedness, { entityUuid });

  FetchService("GET", url, onSuccess, onError, null, callbackData);
}

async function read(filters) {
  const funcName = "====  EntityDebts.read(filters)  ====";
  logger.debug(funcName, [filters]);
  TypeCheck(filters.entityUuid, "string", false);

  const filterCount = Object.keys(filters).length;
  if ((filters.entityUuid && filterCount > 1) || filterCount === 0) {
    logger.error("Error: Invalid Filter Combination");
    // TODO: [#5740] Put a checkable status here.
    const errorRsp = {};
    return errorRsp;
  }

  let url;
  if (filters.entityUuid) {
    const endpoint = ApiEndpoints.entityIndebtedness;
    url = FormatUrlV2(endpoint, { entityUuid: filters.entityUuid });
  } else {
    const endpoint = ApiEndpoints.entityIndebtedness;
    const appendToUrl = ConcatQueryParams(filters);
    url = FormatUrlV2(endpoint) + appendToUrl;
  }

  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

const notExportingDefaultHere = true;

export default {
  get,
  read,
  notExportingDefaultHere
};
