import React from "react";
import PropTypes from "prop-types";
import { Select } from "semantic-ui-react";

function DropdownCell({ cell, onChange, getOptionsByValue, options }) {
  return (
    <Select
      onChange={(_, value) => onChange(value, cell)}
      options={options || getOptionsByValue(cell.value)}
      placeholder="--"
      value={cell.value}
    />
  );
}

DropdownCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  }),
  onChange: PropTypes.func,
  getOptionsByValue: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
      text: PropTypes.string
    })
  )
};

export default DropdownCell;
