import PropTypes from "prop-types";
import { PercentFormat } from "../../../../../../../../../components/CustomUIElements";

const MaxRateChangeCell = ({ row }) => {
  let maxRateDecrease = row?.original?.max_rate_decrease || "";
  let maxRateIncrease = row?.original?.max_rate_increase || "";

  if (maxRateDecrease != "") {
    maxRateDecrease = `%${parseFloat(maxRateDecrease)}`;
  }

  if (maxRateIncrease != "") {
    maxRateIncrease = `%${parseFloat(maxRateIncrease)}`;
  }

  return (
    <div className="flex flex-col">
      <div>
        <p>
          <b>Decrease:</b> <PercentFormat value={maxRateDecrease} />
        </p>
      </div>
      <div>
        <p>
          <b>Increase:</b> <PercentFormat value={maxRateIncrease} />
        </p>
      </div>
    </div>
  );
};

export default MaxRateChangeCell;

MaxRateChangeCell.propTypes = {
  row: PropTypes.shape({})
};
