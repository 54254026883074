import { relationshipTypesData } from "./RelationshipTypesData";

const relationshipMap = {};

relationshipTypesData.forEach(typeString => {
  relationshipMap[typeString] = typeString;
  relationshipMap[typeString.toLowerCase()] = typeString;
  relationshipMap[
    typeString.toLowerCase().replace(/ /g, "_").replace("-", "_")
  ] = typeString;
  relationshipMap[
    typeString.toLowerCase().replace(/ /g, "_").replace("-", "")
  ] = typeString;
  relationshipMap[typeString.toLowerCase().replace(/ /g, "_")] = typeString;
  relationshipMap[typeString.toLowerCase().replace(/ /g, "").replace("-", "")] =
    typeString;
  relationshipMap[
    typeString.toLowerCase().replace(/ /g, "").replace("-", "_")
  ] = typeString;
  relationshipMap[typeString.toLowerCase().replace(/ /g, "")] = typeString;
});

function serializeRelationshipType(key) {
  if (!key) {
    throw new Error(`No relationship type passed: ${key}`);
  }
  const lowercaseKey = key.toLowerCase();
  if (relationshipMap[lowercaseKey]) {
    return relationshipMap[lowercaseKey];
  }
  throw new Error(`Invalid Relationship Type: ${lowercaseKey}`);
}

export default serializeRelationshipType;
