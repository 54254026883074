import createAPIService from "../../FetchService/createAPIService";
import APIEndpoints from "../../ApiEndpoints";
import { relationshipTypes } from "../../Constants/relationshipTypes";

const service = createAPIService({
  baseUrl: APIEndpoints.baseUri,
  create: APIEndpoints.loan_apps,
  retrieve: [APIEndpoints.loan_apps, APIEndpoints.loan_app],
  update: APIEndpoints.loan_app,
  delete: APIEndpoints.loan_app,
  options: { pagination: true },

  subServices: {
    analysts: {
      retrieve: [APIEndpoints.userInfo]
    },
    borrowers: {
      retrieve: [
        `${APIEndpoints.relationships}?rel_type=${relationshipTypes.BORROWER}&parent_uuid={loanAppUuid}`
      ],
      options: { pagination: true }
    },
    entities: {
      create: APIEndpoints.entities,
      retrieve: [APIEndpoints.entities, APIEndpoints.entity],
      update: APIEndpoints.entity,
      delete: APIEndpoints.entity
    },
    entityRelationships: {
      retrieve: APIEndpoints.entityRelationships
    },
    relationships: {
      create: APIEndpoints.relationships,
      retrieve: [APIEndpoints.relationships, APIEndpoints.relationship],
      update: APIEndpoints.relationship,
      delete: APIEndpoints.relationship
    },
    loanRequests: {
      create: APIEndpoints.creditRequests,
      retrieve: [APIEndpoints.loansGetAllByLoanAppUuid],
      update: APIEndpoints.creditRequest,
      delete: APIEndpoints.creditRequest
    },
    loansRelationships: {
      retrieve: [APIEndpoints.loansRelationships]
    },
    holdingsSubsidiaries: {
      retrieve: APIEndpoints.relationshipsGetOwnersByChildUuid
    },
    documents: {
      retrieve: [
        APIEndpoints.objectMetadataGetAll,
        APIEndpoints.objectMetadata
      ],
      update: APIEndpoints.objectMetadata,
      delete: APIEndpoints.objectDownloads
    },
    collaterals: {
      retrieve: [APIEndpoints.collaterals, APIEndpoints.collateral],
      update: APIEndpoints.collateral,
      delete: APIEndpoints.collateral
    },
    assets: {
      create: APIEndpoints.assets,
      retrieve: [APIEndpoints.assets, APIEndpoints.asset],
      update: APIEndpoints.asset,
      delete: APIEndpoints.asset
    }
  }
});

export default service;
