/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import APIEndpoints from "../../../../ApiEndpoints";
import logger from "../../../../logger";

const MOCKDATA = {
  logs: [
    {
      uuid: "log-uuid-0003",
      effective_date: "2019-12-02",
      entered_by: "Charlie Brown",
      description: "Tried to kick ball",
      principal: 34323,
      recalc_principal: 1341
    },
    {
      uuid: "log-uuid-0002",
      effective_date: "2019-11-03",
      entered_by: "Lucy van Pelt",
      description: "Tricked the old blockhead",
      principal: 30000,
      recalc_principal: 4000
    },
    {
      uuid: "log-uuid-0001",
      effective_date: "2018-06-11",
      entered_by: "Peppermint Patty",
      description: "Discovered Netflix",
      principal: 122424,
      recalc_principal: 2039
    }
  ]
};

const mockLogs = logService => {
  logService.retrieve = async (identifiers, meta) => {
    logger.debug(
      "MOCK REQUEST",
      "GET",
      APIEndpoints.accountHistoryLogs,
      identifiers,
      meta
    );
    const { pagination } = meta;
    const page_size = (pagination && pagination.size) || 10;
    const page_number = (pagination && pagination.number) || 0;
    const index = page_number * page_size;
    const last_page = index >= MOCKDATA.logs.length - page_size;
    return {
      data: MOCKDATA.logs.slice(index, index + page_size),
      metaData: {
        last_page,
        page_number,
        page_size
      }
    };
  };
};

export default mockLogs;
