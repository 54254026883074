import Relationships from "../../../Relationships";
import getByEntityUuidSuccess from "../getByEntityUuidSuccess";
import { relationshipTypes } from "../../../../../Constants/relationshipTypes";

function getByEntityUuid(origLoanAppsParams) {
  /*
    This func is exported, but it should not be used directly,
    but rather used to extend the funtionality of LoanApp.get.

    The backend API does not allow directly querying `/loans/v1/loan_apps`
    with an entity_uuid or entityUuid parameter. A call must fist be made to
    `/relationships/v2/relationships` to find the loan apps, then the loan apps
    looked up.

    Note: Unlike LoanApp.get() when used without and entityUUID query,
    this WILL NOT lookup all loan apps and send them to your onSuccess all at once.
    This is to be consumed by an onSuccess function that adds each loan app to state
    as it async recieves the response.
  */

  const relationship = {
    child_uuid: origLoanAppsParams.filters.entityUuid,
    rel_type: relationshipTypes.BORROWER
  };
  const jwt = "Deprecated in this API";
  const onSuccess = getByEntityUuidSuccess;
  const { onError } = origLoanAppsParams;
  const { pagination } = origLoanAppsParams;
  const callbackData = origLoanAppsParams;

  Relationships.get(
    relationship,
    jwt,
    onSuccess,
    onError,
    callbackData,
    pagination
  );
}

export default getByEntityUuid;
