import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";
import { Button } from "semantic-ui-react";

function ApproveDocButtonObj(props) {
  return (
    <Button
      basic
      circular
      icon
      onClick={() =>
        props.dispatch({
          type: "DOCS_APPROVE",
          uuid: props.docDetails.uuid
        })
      }
    >
      <FontAwesome name="check" />
    </Button>
  );
}

ApproveDocButtonObj.propTypes = {
  dispatch: PropTypes.func.isRequired,
  docDetails: PropTypes.shape({
    tsn: PropTypes.string,
    doc_category: PropTypes.string,
    description: PropTypes.string,
    doc_subcategory: PropTypes.string,
    upload_date: PropTypes.string,
    acct_no: PropTypes.string,
    uuid: PropTypes.string,
    branch_no: PropTypes.string,
    tax_id: PropTypes.string,
    approved: PropTypes.bool,
    name: PropTypes.string
  }).isRequired
};

const ApproveDocButton = connect()(ApproveDocButtonObj);

export default ApproveDocButton;
