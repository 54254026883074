/* eslint-disable no-unused-vars */
import { createActions } from "redux-actions";

import {
  Institutions,
  ParticipationInvestors
} from "../../../../../../services/ApiLib";
import logger from "../../../../../../services/logger";

const defaultParticipantValues = {
  current_contract_amount: 0.0,
  average_daily_balance_mtd: 0.0,
  fees_accrued: 0.0,
  fees_accrued_ltd: 0.0,
  fees_accrued_ytd: 0.0,
  fractional_accrued_interest: 0.0,
  interest_accrued: 0.0,
  interest_accrued_ltd: 0,
  interest_accrued_mtd: 0,
  interest_accrued_ytd: 0,
  interest_paid_ltd: 0,
  interest_paid_ytd: 0.0,
  past_due_amount: 0.0,
  per_diem: 0,
  phoenix_import: false,
  prev_payment_amount: 0.0,
  principal_paid_ltd: 0,
  principal_paid_ytd: 0.0,
  service_fee_accrued: 0,
  service_fee_paid_ltd: 0,
  service_fee_paid_ytd: 0,
  interest_rate: 0,
  investor: {}
};

export const initialState = {
  investors: [],
  error: "",
  selectedInvestor: {},
  showProcessModal: false,
  showAddParticipationModal: false,
  showEditParticipationModal: false,
  investorAddSuccess: false,
  investorAddError: false,
  participationBuyersToBe: [],
  participationInvestors: [],
  feesRolledDown: false,
  participant: { ...defaultParticipantValues },
  retainedAccount: { ...defaultParticipantValues },
  institutionMap: {}
};
export const initialFormState = {
  participant: { ...defaultParticipantValues },
  error: ""
};

export const actionCreators = createActions(
  {
    loadInstitutions: () => dispatch => {
      Institutions.get(
        ({ data }) => {
          const formedData = [];
          const nextInstitutionMap = {};
          data.forEach(elem => {
            const formData = {
              key: elem.name,
              text: elem.name,
              value: elem.name,
              name: elem.name,
              uuid: elem.uuid,
              investor: elem
            };
            formedData.push(formData);
            nextInstitutionMap[elem.uuid] = elem;
          });
          dispatch(actionCreators.populateInvestors(formedData));
          dispatch(actionCreators.populateInstitutionMap(nextInstitutionMap));
        },
        ({ error }) => dispatch(actionCreators.participationError({ error })),
        null,
        null,
        null,
        null
      );
    },
    loadParticipantInvestors: uuid => dispatch => {
      ParticipationInvestors.get(
        data => {
          dispatch(actionCreators.updateParticipationInvestors(data));
        },
        error => {
          logger.error(error);
        },
        uuid
      );
    },
    postParticipationInvestors:
      (participationBuyersToBe, retainedAccount, loan) => async dispatch => {
        const loans = [...participationBuyersToBe, retainedAccount].map(
          participationLoan => {
            // strip any unneeded properties from the loan
            const {
              investor,
              index,
              participation_bought: participationBought,
              participation_sold: participationSold,
              ...nextLoan
            } = { ...participationLoan };
            return nextLoan;
          }
        );
        try {
          const res = await ParticipationInvestors.asyncAdd(
            { parentUuid: loan.uuid },
            { loans }
          );
          if (res.error) {
            logger.error(res);
            dispatch(actionCreators.investorAddError(true));
          } else {
            dispatch(actionCreators.investorAddError(false));
            dispatch(actionCreators.investorAddSuccess(true));
            dispatch(actionCreators.toggleShowProcessModal(false));
            if (res.data.new_loan_entries) {
              dispatch(
                actionCreators.updateParticipationInvestors(
                  res.data.new_loan_entries
                )
              );
            }
          }
        } catch (error) {
          logger.error(error);
          dispatch(actionCreators.investorAddError(true));
        }
      },
    addInvestor:
      (participantInvestor, participationBuyersToBe, loan) => dispatch => {
        if (!participantInvestor) {
          return;
        }
        const nextParticipationBuyersToBe = [
          ...participationBuyersToBe,
          {
            ...participantInvestor,
            index: participationBuyersToBe.length
          }
        ];
        dispatch(
          actionCreators.editParticipationBuyer(nextParticipationBuyersToBe)
        );
        dispatch(
          actionCreators.calculateRetainedAccount(
            nextParticipationBuyersToBe,
            loan
          )
        );
        dispatch(actionCreators.resetForm());
      },
    prepareEditModal: participant => dispatch => {
      dispatch(actionCreators.toggleShowEditParticipationModal(true));
      dispatch(actionCreators.updateSelectedInvestor(participant.investor));
      dispatch(actionCreators.setParticipant(participant));
    },
    prepareSelectedInvestor:
      (participantInvestor, participationBuyersToBe, loan) => dispatch => {
        const filteredBuyerList = participationBuyersToBe.map(p => {
          if (p.index === participantInvestor.index) {
            return participantInvestor;
          }
          return p;
        });
        dispatch(actionCreators.editParticipationBuyer(filteredBuyerList));

        dispatch(
          actionCreators.calculateRetainedAccount(filteredBuyerList, loan)
        );
        dispatch(actionCreators.resetForm());
      },
    setupRetainedAccount: loan => dispatch => {
      const retainedAccount = {
        current_balance: loan.current_balance,
        original_amount: loan.original_amount,
        current_contract_amount: loan.current_contract_amount,
        available_balance: loan.available_balance,
        interest_rate: loan.interest_rate,
        institution_uuid: loan.institution_uuid,
        institution_id: loan.institution_id,

        participation_percentage_owned: 1,
        bafs_account_number: `${loan.bafs_account_number}retained`,
        rate_adjustment: 0,
        participation_sold: false,
        participation_bought: true,
        gets_fee_roll_downs: true,
        investor: {
          institution_uuid: loan.institution_uuid,
          name: "Retained Account",
          value: "Retained Account"
        }
      };
      dispatch(actionCreators.updateRetainedAccount(retainedAccount));
    },
    calculateRetainedAccount: (participants, loan) => dispatch => {
      let retainedCurrentBalance = loan.current_balance;
      let retainedOriginalAmount = loan.original_amount;
      let retainedCurrentContractAmount = loan.current_contract_amount;
      let retainedPercentOwned = 1;
      let retainedAvailableBalance = loan.available_balance;

      participants.forEach(p => {
        retainedCurrentBalance -= p.current_balance;
        retainedCurrentContractAmount -= p.current_contract_amount;
        retainedPercentOwned -= p.participation_percentage_owned;
        retainedAvailableBalance -= p.available_balance;
        retainedOriginalAmount -= p.original_amount;
      });

      const nextRetainedAccount = {
        current_balance: retainedCurrentBalance,
        original_amount: retainedOriginalAmount,
        current_contract_amount: retainedCurrentContractAmount,
        participation_percentage_owned: retainedPercentOwned,
        available_balance: retainedAvailableBalance
      };
      dispatch(actionCreators.updateRetainedAccount(nextRetainedAccount));
    },

    throwInterestError: loan => dispatch => {
      dispatch(
        actionCreators.participationError(
          `Interest rate cannot exceed the rate of the original loan (${
            loan.interest_rate * 100
          }%).`
        )
      );
      dispatch(
        actionCreators.updateParticipant({
          rate_adjustment: 0,
          interest_rate: loan.interest_rate
        })
      );
    }
  },
  "UPDATE_PARTICIPANT",
  "SET_ROLLED_DOWN",
  "TOGGLE_SHOW_PROCESS_MODAL",
  "TOGGLE_SHOW_ADD_PARTICIPATION_MODAL",
  "TOGGLE_SHOW_EDIT_PARTICIPATION_MODAL",
  "UPDATE_SELECTED_INVESTOR",
  "POPULATE_INVESTORS",
  "ADD_PARTICIPATION_BUYER_TO_LIST",
  "EDIT_PARTICIPATION_BUYER",
  "UPDATE_PARTICIPATION_INVESTORS",
  "RESET_PARTICIPATION_BUYER_LIST",
  "INVESTOR_ADD_SUCCESS",
  "INVESTOR_ADD_ERROR",
  "RESET_FORM",
  "PARTICIPATION_ERROR",
  "UPDATE_RETAINED_ACCOUNT",
  "SET_PARTICIPANT",
  "POPULATE_INSTITUTION_MAP",
  {
    prefix: "PARTICIPATION"
  }
);

// eslint-disable-next-line default-param-last
export default function ParticipationReducer(state = initialState, action) {
  switch (action.type) {
    case "PARTICIPATION/POPULATE_INSTITUTION_MAP":
      return { ...state, institutionMap: action.payload };
    case "PARTICIPATION/UPDATE_RETAINED_ACCOUNT":
      return {
        ...state,
        retainedAccount: { ...state.retainedAccount, ...action.payload }
      };
    case "PARTICIPATION/SET_PARTICIPANT":
      return { ...state, participant: action.payload };
    case "PARTICIPATION/UPDATE_PARTICIPANT":
      return {
        ...state,
        participant: { ...state.participant, ...action.payload }
      };
    case "PARTICIPATION/SET_ROLLED_DOWN":
      return { ...state, feesRolledDown: action.payload };
    case "PARTICIPATION/TOGGLE_SHOW_PROCESS_MODAL":
      return { ...state, showProcessModal: action.payload };
    case "PARTICIPATION/TOGGLE_SHOW_ADD_PARTICIPATION_MODAL":
      return {
        ...state,
        showAddParticipationModal: action.payload,
        ...initialFormState
      };
    case "PARTICIPATION/TOGGLE_SHOW_EDIT_PARTICIPATION_MODAL":
      return {
        ...state,
        showEditParticipationModal: action.payload,
        ...initialFormState
      };
    case "PARTICIPATION/UPDATE_SELECTED_INVESTOR":
      return {
        ...state,
        selectedInvestor: { ...state.selectedInvestor, ...action.payload }
      };
    case "PARTICIPATION/POPULATE_INVESTORS":
      return { ...state, investors: action.payload };
    case "PARTICIPATION/ADD_PARTICIPATION_BUYER_TO_LIST":
      return {
        ...state,
        participationBuyersToBe: [
          ...state.participationBuyersToBe,
          {
            ...action.payload,
            index: state.participationBuyersToBe.length
          }
        ]
      };
    case "PARTICIPATION/EDIT_PARTICIPATION_BUYER":
      return { ...state, participationBuyersToBe: action.payload };
    case "PARTICIPATION/RESET_PARTICIPATION_BUYER_LIST":
      return { ...state, participationBuyersToBe: [] };
    case "PARTICIPATION/UPDATE_PARTICIPATION_INVESTORS":
      return { ...state, participationInvestors: action.payload };
    case "PARTICIPATION/INVESTOR_ADD_SUCCESS":
      return { ...state, investorAddSuccess: action.payload };
    case "PARTICIPATION/INVESTOR_ADD_ERROR":
      return { ...state, investorAddError: action.payload };
    case "PARTICIPATION/RESET_FORM":
      return { ...state, ...initialFormState };
    case "PARTICIPATION/PARTICIPATION_ERROR":
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
