/* eslint-disable camelcase */
import React from "react";
import { Form } from "semantic-ui-react";
import {
  creditBureaus,
  loanReviewTypes
} from "../../../../../../../services/FormElements/components/entityModalOptions";
import { DateInput } from "../../../../../../CustomFormElements";
import ratings from "../ratings/ratings";
import useEntity from "../../../hooks/useEntity/useEntity";

function IndividualCreditInfo() {
  const {
    credit_bureau,
    creditScoreDate,
    creditScoreValue,
    handleChangeNumber,
    handleInputChange,
    loan_review_type,
    next_review_date,
    previous_review_date,
    risk_rating_date,
    risk_rating
  } = useEntity();

  return (
    <Form>
      <Form.Group>
        <DateInput
          asForm
          width={9}
          label="Borrower Rating Date"
          value={risk_rating_date || ""}
          name="risk_rating_date"
          onChange={handleInputChange}
        />
        <Form.Select
          name="risk_rating"
          label="Borrower Rating"
          options={ratings()}
          width={9}
          value={risk_rating || ""}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group>
        <DateInput
          asForm
          width={6}
          label="Previous Review Date"
          name="previous_review_date"
          value={previous_review_date || ""}
          onChange={handleInputChange}
        />
        <DateInput
          asForm
          width={6}
          label="Next Review Date"
          value={next_review_date || ""}
          name="next_review_date"
          onChange={handleInputChange}
        />
        <Form.Select
          name="loan_review_type"
          label="Loan Review Type"
          width={6}
          options={loanReviewTypes}
          value={loan_review_type || ""}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Select
          name="credit_bureau"
          label="Credit Bureau"
          options={creditBureaus}
          width={6}
          value={credit_bureau}
          onChange={handleInputChange}
        />
        <Form.Input
          name="credit_score"
          label="Credit Score"
          width={6}
          value={creditScoreValue || ""}
          onChange={handleChangeNumber}
          disabled={!credit_bureau}
        />
        <DateInput
          asForm
          width={6}
          label="Date of Credit Score"
          value={creditScoreDate || ""}
          name="credit_score_date"
          onChange={handleInputChange}
          disabled={!credit_bureau}
        />
      </Form.Group>
    </Form>
  );
}

export default IndividualCreditInfo;
