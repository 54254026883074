import TypeCheck from "typecheck-extended";

function stripChars(value, stripCharType) {
  TypeCheck(value, "string");
  TypeCheck(stripCharType, "enum", true, [
    "non alpha",
    "non integer",
    "commas"
  ]);

  let strippedValue = value;
  switch (stripCharType) {
    case "non alpha":
      strippedValue = value.match(/[A-Za-z]/g).join("");
      return strippedValue;

    case "non integer":
      strippedValue = value.match(/[0-9]/g).join("");
      return strippedValue;

    case "commas":
      strippedValue = value.split(",").join("");
      return strippedValue;

    default:
      return strippedValue;
  }
}

export default stripChars;
