const initialState = {
  users: {}
};

// eslint-disable-next-line default-param-last
const UserInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_INFO_UPDATE": {
      const users = { ...state.users, [action.userUuid]: action.userName };
      return { ...state, users };
    }

    case "USER_INFO_LOOKING_UP_USER": {
      const users = { ...state.users, [action.userUuid]: "Couldn't find user" };
      return { ...state, users };
    }

    default: {
      return state;
    }
  }
};
export default UserInfoReducer;
