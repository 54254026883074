import React, { forwardRef } from "react";
import { FormattedMessage } from "react-intl";
import { MessageProps } from "../../../../types/text";
import { ButtonBase } from "../button-base/button-base";
import type { ButtonBaseProps } from "../button-base/button-base";

/**
 * Display a button with a text message.
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function ButtonImpl({ message, ...props }, ref) {
    return (
      <ButtonBase {...props} ref={ref}>
        {message &&
          ("id" in message ? (
            <FormattedMessage {...message} />
          ) : (
            message.defaultMessage
          ))}
      </ButtonBase>
    );
  }
);

export interface ButtonProps extends ButtonBaseProps {
  /** The text message to display in the button. If the value of `id` doesn't
   * exist then the value of `defaultMessage` will be displayed. */
  message?: MessageProps;
}
