import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  RowToggleCell,
  PercentCell,
  PropertyTable,
  DefaultCell
} from "../../../../../../../../../../components/Tables";
import { FrictionModal } from "../../../../../../../../../../components/Modals";
import { BasisPointsCell } from "../../../../../../../../components/BasisPoints";

/**
 * Sets the participation field based on participation_bought and participation_sold
 */
export const getParticipationVals = nextData => {
  let changes = {};
  if ("participation_bought" in nextData) {
    const isParticipationBought = nextData.participation_bought;
    const participation = isParticipationBought ? "Active" : "None";
    changes = {
      participation
    };
    if (isParticipationBought) {
      changes.participation_sold = false;
    }
  } else if ("participation_sold" in nextData) {
    const isParticipationSold = nextData.participation_sold;
    const participation = isParticipationSold ? "Active" : "None";
    changes = {
      participation
    };
    if (isParticipationSold) {
      changes.participation_bought = false;
    }
  }
  return { ...nextData, ...changes };
};

function AdminCharacteristics({ loan, onUpdate, isCoreOfRecord, isMonthEnd }) {
  const rows = useMemo(
    () => [
      {
        Header: "BAFS Credit Admin Fee",
        accessor: "credit_admin_fee",
        Cell: BasisPointsCell,
        format: "long"
      },
      {
        Header: "Premium/Discount",
        accessor: "premium_discount",
        Cell: RowToggleCell,
        validate: "CONFIRM",
        enableOnly: true
      },
      {
        Header: "Core Of Record",
        accessor: "core_of_record",
        Cell: RowToggleCell,
        validate: "CONFIRM"
      },
      {
        Header: "Government Guaranteed",
        accessor: "gov_guarantee",
        Cell: RowToggleCell,
        validate: "CONFIRM",
        enableOnly: true
      },
      {
        Header: "1098 Reportable",
        accessor: "reportable_on_1098",
        Cell: RowToggleCell,
        validate: true
      },
      {
        Header: "Participation Sold",
        accessor: "participation_sold",
        Cell: RowToggleCell,
        validate: "CONFIRM"
      },
      {
        Header: "Participation Bought",
        accessor: "participation_bought",
        Cell: RowToggleCell,
        validate: "CONFIRM"
      },
      {
        Header: "Fully Amortized",
        accessor: "fully_amortized",
        Cell: RowToggleCell,
        validate: "CONFIRM"
      },
      {
        Header: "App. of Payments",
        accessor: "payment_processing_order",
        Cell: DefaultCell
      },
      {
        Header: "Payment Posting",
        accessor: "posting_method",
        Cell: DefaultCell
      },
      {
        Header: "Overpayment Option",
        accessor: "overpayment_split_options",
        Cell: DefaultCell
      },
      {
        Header: "Bill Satisfaction",
        accessor: "min_bill_satisfaction_percent",
        Cell: PercentCell
      }
    ],
    []
  );

  const [
    { changes, message, header, validateString, warning } = {},
    setConfirmData
  ] = useState();

  const frameLoanObject = useCallback(() => {
    let tableData = {
      ...loan,
      participation:
        loan.participation === "Active" || loan.participation === "Pending"
    };

    // Check the core_of_record field in the loan data. If this value is not null,
    // use it to determine the current state of the toggle. If it is null, then
    // check the core_of_record field in the institution data , for the
    // institution that the loan is part of.
    if (isCoreOfRecord !== undefined && loan.core_of_record === null) {
      tableData = { ...tableData, core_of_record: isCoreOfRecord };
    }

    return tableData;
  }, [isCoreOfRecord, loan]);

  const handleUpdate = useCallback(
    data => {
      /**
       * @param {{ [key: string]: any; uuid: string; }} data
       */
      let nextData = data;
      nextData = getParticipationVals(nextData);
      onUpdate(nextData);
    },
    [onUpdate]
  );

  const confirmModalOpen = !!changes;

  const confirmUpdate = (key, value, row) => {
    const { Header, validate, enableOnly } = row;
    const msg = `Are you sure you want to ${
      value ? "enable" : "disable"
    } ${Header} for this loan?`;

    setConfirmData({
      changes: { [key]: value, uuid: loan.uuid },
      message: msg,
      header: Header,
      warning: enableOnly ? "This action cannot be reversed" : undefined,
      validateString: typeof validate === "string" ? validate : ""
    });
  };

  const handleToggle = (key, value) => {
    const row = rows.find(r => r.accessor === key);
    if (isMonthEnd) {
      return;
    }
    if (row.enableOnly && value !== true) return;
    if (row.validate) {
      confirmUpdate(key, value, row);
    } else {
      handleUpdate({ [key]: value, uuid: loan.uuid });
    }
  };

  return (
    <>
      <PropertyTable
        columnsConfig={rows}
        data={frameLoanObject()}
        onToggle={handleToggle}
      />
      <FrictionModal
        open={confirmModalOpen}
        header={header || ""}
        message={message || ""}
        warning={warning}
        validateString={validateString}
        onCancel={() => {
          setConfirmData();
        }}
        onSubmit={() => {
          handleUpdate({ ...changes, uuid: loan.uuid });
          setConfirmData();
        }}
        confirmLabelText={validateString ? "Next" : "Confirm"}
      />
    </>
  );
}

AdminCharacteristics.propTypes = {
  loan: PropTypes.shape({
    uuid: PropTypes.string,
    credit_admin_fee: PropTypes.number,
    premium_discount: PropTypes.bool,
    core_of_record: PropTypes.bool,
    gov_guarantee: PropTypes.bool,
    reportable_on_1098: PropTypes.bool,
    participation: PropTypes.string,
    participation_bought: PropTypes.bool,
    payment_processing_order: PropTypes.string,
    posting_method: PropTypes.string,
    is_pay_ahead: PropTypes.bool,
    min_bill_satisfaction_percent: PropTypes.number
  }).isRequired,
  onUpdate: PropTypes.func,
  isCoreOfRecord: PropTypes.bool,
  isMonthEnd: PropTypes.bool
};

export default AdminCharacteristics;
