import TypeCheck from "typecheck-extended";
import ApiEndpoints from "../../../ApiEndpoints";
import { asyncFetchService } from "../../../FetchService";
import logger from "../../../logger";
import { FormatUrlV2 } from "../../../FormatUrl";
import { ConcatQueryParams, RemoveNullKeys } from "../Utilities";

async function read(filters) {
  const funcName = "====  Loans.read(filters)  ====";
  logger.debug(funcName, [filters]);
  TypeCheck(filters.uuid, "string", false);

  const filterCount = Object.keys(filters).length;
  if ((filters.uuid && filterCount > 1) || filterCount === 0) {
    logger.error("Error: Invalid Filter Combination");
    // TODO: [#5740] Put a checkable status here.
    const errorRsp = {};
    return errorRsp;
  }

  let url;
  if (filters.uuid) {
    const endpoint = ApiEndpoints.loanPPP;
    url = FormatUrlV2(endpoint, { uuid: filters.uuid });
  } else {
    const endpoint = ApiEndpoints.loansPPP;
    const appendToUrl = ConcatQueryParams(filters);

    url = FormatUrlV2(endpoint) + appendToUrl;
  }

  const rsp = await asyncFetchService("GET", url);

  return rsp;
}

async function asyncUpdate(filters, body) {
  TypeCheck(filters.uuid, "string", true);

  const url = FormatUrlV2(ApiEndpoints.loanPPP, { uuid: filters.uuid });
  const convertedBody = RemoveNullKeys(body);

  const rsp = await asyncFetchService("PUT", url, convertedBody, null, false);
  return rsp;
}

export default {
  read,
  asyncUpdate
};
