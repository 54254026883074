/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import { useTable } from "react-table";
import { ToggleCell, DeleteCell } from "../../../../../../components/Tables";
import Constants from "../../../../../../services/Constants/strings";
import { ConcatName as getEntityName } from "../../../../../../services/Entities";
import GuarantorTypeDropDownCell from "./GuarantorTypeDropDownCell";
import GuaranteedAmountCell from "./GuaranteedAmountCell";

const columns = [
  {
    Header: Constants.NAME,
    accessor: ({ entity }) => entity && getEntityName(entity)
  },
  { Header: Constants.TIN, accessor: "entity.tin" },
  {
    Header: Constants.IS_GUARANTOR,
    accessor: v => v,
    id: "is_guarantor",
    Cell: ToggleCell
  },
  {
    Header: Constants.TYPE,
    accessor: v => v,
    Cell: GuarantorTypeDropDownCell
  },
  {
    Header: Constants.GUARANTEED_AMOUNT,
    accessor: v => v,
    Cell: GuaranteedAmountCell
  }
];

const columnsWithDeleteCell = [
  ...columns,
  {
    Header: "",
    id: "delete",
    accessor: v => v,
    Cell: DeleteCell,
    collapsing: true
  }
];

function GuarantorsTable({
  guarantors = [],
  thirdParty = false,
  tableOptions,
  readOnly = false,
  onIsGuarantorChange,
  onGuarantorTypeChange,
  onAmountChange,
  onAmountTypeChange
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      ...tableOptions,
      columns: thirdParty ? columnsWithDeleteCell : columns,
      data: guarantors,
      readOnly,
      onToggle: onIsGuarantorChange,
      onGuarantorTypeChange,
      onAmountChange,
      onAmountTypeChange,
      onDelete: guar => onIsGuarantorChange(guar, false)
    });

  return (
    <Table
      {...getTableProps({ compact: true, style: { marginBottom: "2em" } })}
    >
      <Table.Header>
        {headerGroups.map(
          headerGroup =>
            headerGroup.getHeaderGroupProps && (
              <Table.Row {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Table.HeaderCell
                    {...column.getHeaderProps({
                      collapsing: column.collapsing
                    })}
                  >
                    {column.render("Header")}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            )
        )}
      </Table.Header>
      <Table.Body {...getTableBodyProps()}>
        {rows.map(
          row =>
            prepareRow(row) || (
              <Table.Row {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <Table.Cell
                    {...cell.getCellProps({
                      className: cell.column.className,
                      collapsing: cell.column.collapsing
                    })}
                  >
                    {cell.render("Cell")}
                  </Table.Cell>
                ))}
              </Table.Row>
            )
        )}
      </Table.Body>
    </Table>
  );
}

GuarantorsTable.propTypes = {
  guarantors: PropTypes.arrayOf(PropTypes.shape({})),
  thirdParty: PropTypes.bool,
  readOnly: PropTypes.bool,
  tableOptions: PropTypes.shape({}),
  onIsGuarantorChange: PropTypes.func,
  onGuarantorTypeChange: PropTypes.func,
  onAmountChange: PropTypes.func,
  onAmountTypeChange: PropTypes.func
};

export default GuarantorsTable;
