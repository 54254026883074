import { Button, Modal, Form, Icon } from "semantic-ui-react";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import Constants from "../../../../../../../../../../services/Constants/strings";
import {
  FormInput,
  forceValue
} from "../../../../../../../../../../services/FormElements";
import { serializeAssetData } from "../../../../../../../../../../services/Constants/assetsOptions";
import { defaultAssetModalData, defaultInsurancesData } from "../../constants";
import { useInstitutionAssetGroupClassesQuery } from "apps/frontend/commotion/src/services/RTKQuery/institutions";

function AssetsModal({
  data = defaultAssetModalData,
  onChange,
  setOpen,
  open,
  onSave
}) {
  // Local State
  const [valid, setValid] = useState();
  const [insurances, setInsurances] = useState(
    Array.isArray(data?.insurance_data) && data.insurance_data.length
      ? data.insurance_data
      : defaultInsurancesData
  );
  const assetDescription = data?.description || "";
  const isNewAsset = useMemo(() => !data?.uuid, [data?.uuid]);

  const {
    data: assetClasses
    // TODO: Potentially show skeleton loader for better UI/UX based on:
    // isError, isFetching, isLoading, isSuccess
  } = useInstitutionAssetGroupClassesQuery(data?.institution_uuid);

  useEffect(() => {
    setValid(!!assetDescription.length);
  }, [assetDescription]);

  const setInsurancesValue = (index, { name, value }) => {
    setInsurances(prev =>
      prev.map((item, i) => (i !== index ? item : { ...item, [name]: value }))
    );
  };

  const handleOnChange = useCallback(() => {
    onChange(prev => ({
      ...prev,
      // Include insurance data if it's not the default data
      ...(_.isEqual(insurances, defaultInsurancesData)
        ? {}
        : { insurance_data: insurances })
    }));
  }, [insurances, onChange]);

  const isValidPreviousSecurity = () => {
    const newInsurance =
      insurances.length === 1
        ? insurances[0]
        : insurances[insurances.length - 1];
    return newInsurance.insurance_company_name && newInsurance.policy_number;
  };
  const addInsurancesFields = () => {
    setInsurances(prev => [...prev, ...defaultInsurancesData]);
  };
  const removeInsurancesFields = index => {
    const newFormValues = [...insurances];
    newFormValues.splice(index, 1);
    setInsurances(newFormValues);
    handleOnChange({ ...data, insurance_data: newFormValues });
  };
  const makeFormInput = useCallback(
    input => (
      <FormInput
        key={input?.name}
        input={input}
        set={onChange}
        update={() => null}
        fields={data}
      />
    ),
    [data, onChange]
  );

  useEffect(() => {
    handleOnChange();
  }, [handleOnChange]);

  const serializedAssetData = useMemo(() => {
    return serializeAssetData({
      data: assetClasses?.asset_groups,
      asset_group: data?.asset_group,
      asset_class: data?.asset_class,
      asset_type: data?.asset_type
    });
  }, [
    assetClasses?.asset_groups,
    data?.asset_group,
    data?.asset_class,
    data?.asset_type
  ]);

  if (!assetClasses?.asset_groups) {
    return null;
  }

  return (
    <Modal open={open} closeOnDimmerClick>
      <Modal.Header data-testid="AssetModal-header">
        {isNewAsset ? (
          <FormattedMessage id="ASSET_ADD" />
        ) : (
          <FormattedMessage id="ASSET_EDIT" />
        )}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group style={{ alignItems: "center" }} widths="equal">
            {[
              {
                name: "description",
                type: "string",
                label: Constants.DESCRIPTION
              },
              {
                name: "asset_group",
                type: "select",
                label: Constants.GROUP,
                options: serializedAssetData.groupOptions
              },
              {
                name: "asset_class",
                type: "select",
                label: Constants.CLASS,
                options: serializedAssetData.classOptions
              },
              {
                name: "asset_type",
                type: "select",
                label: Constants.TYPE,
                options: serializedAssetData.typeOptions
              }
            ].map(makeFormInput)}
          </Form.Group>
          <Form.Group style={{ alignItems: "center" }} widths="equal">
            {[
              {
                name: "market_value",
                type: "float",
                label: Constants.VALUE
              },

              {
                name: "acquisition_date",
                type: "date",
                label: Constants.ACQUISITION_DATE
              }
            ].map(makeFormInput)}
          </Form.Group>
          <h3 className="ui header">Insurance Policies</h3>
          {insurances.length && isValidPreviousSecurity() && (
            <div className="flex items-center justify-end">
              <Icon
                onClick={() => addInsurancesFields()}
                className="cursor-pointer"
                name="plus"
                circular
              />
            </div>
          )}
          {insurances.map((insurance, index) => (
            <React.Fragment key={`insurance-${index}`}>
              {index !== 0 && (
                <div className="flex items-center justify-end">
                  <Icon
                    onClick={() => removeInsurancesFields(index)}
                    name="close"
                    className="cursor-pointer"
                    circular
                  />
                </div>
              )}
              <Form.Group widths="equal">
                <Form.Input
                  autoComplete="off"
                  label="Insurance Policy Number"
                  name="policy_number"
                  onChange={(ev, value) => setInsurancesValue(index, value)}
                  value={forceValue(insurance.policy_number)}
                />
                <Form.Input
                  autoComplete="off"
                  label="Insurance company name"
                  name="insurance_company_name"
                  onChange={(ev, value) => setInsurancesValue(index, value)}
                  value={forceValue(insurance.insurance_company_name)}
                />
              </Form.Group>
            </React.Fragment>
          ))}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          data-testid="cancel-asset-btn"
          onClick={() => {
            onChange(defaultAssetModalData);
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          data-testid="save-asset-btn"
          disabled={!valid}
          color="green"
          onClick={async () => {
            await onSave();
            setOpen(false);
          }}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

AssetsModal.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.shape().isRequired,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired
};

export default AssetsModal;
