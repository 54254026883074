import React from "react";
import { Button, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

export default function DownloadCell({ row, column }) {
  return (
    <Button
      basic
      circular
      data-testid="financial-analysis-download"
      icon={
        <Icon
          data-testid="preview-icon"
          name={
            row?.original.uuid === column?.fetchingDocumentUuid
              ? "spinner"
              : "eye"
          }
          color="green"
          loading={row?.original.uuid === column?.fetchingDocumentUuid}
        />
      }
      onClick={() => column?.handlePreviewClick(row?.original)}
    />
  );
}

DownloadCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  column: PropTypes.shape({
    fetchingDocumentUuid: PropTypes.string.isRequired,
    handlePreviewClick: PropTypes.func.isRequired
  }).isRequired
};
