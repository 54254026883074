import PropTypes from "prop-types";

const DateCell = ({ row }) => {
  return (
    <div className="flex flex-col">
      <div>
        <p>
          <b>Start:</b> {row?.original?.start_date || ""}
        </p>
      </div>
      <div>
        <p>
          <b>End:</b> {row?.original?.end_date || ""}
        </p>
      </div>
    </div>
  );
};

export default DateCell;

DateCell.propTypes = {
  row: PropTypes.shape({})
};
