/* eslint-disable import/prefer-default-export */
import { Assets } from "../../../../services/ApiLib";

/**
 * Get supplemental data that is related to the asset's group or group + class.
 * @param {string} uuid The asset's UUID.
 * @param {"Personal Property" | "Real Estate"} group The `asset_group`.
 * @param {(error) => void} onError
 * @param {({data: object}) => void} setAdditionalAssetsData
 */
export async function getAdditionalAssetData(
  uuid,
  group,
  assetClass,
  onError,
  setAdditionalAssetsData,
  setAssetsData
) {
  if (group === "Real Estate") {
    try {
      const assetData = await Assets.readRealEstateByAssetUuid({
        asset_uuid: uuid
      });
      if (assetData.error) {
        setAssetsData(false);
        onError(assetData.error);
      } else {
        setAdditionalAssetsData({ data: assetData.data[0] || "" });
        setAssetsData(true);
      }
    } catch (err) {
      setAssetsData(false);
      onError(err);
    }
  }
  if (group === "Personal Property" && assetClass !== "Accounts") {
    try {
      const assetData = await Assets.readPersonalPropertyByAssetUuid({
        asset_uuid: uuid
      });
      if (assetData.error) {
        setAssetsData(false);
        onError(assetData.error);
      } else {
        setAdditionalAssetsData({ data: assetData.data[0] || "" });
        setAssetsData(true);
      }
    } catch (err) {
      setAssetsData(false);
      onError(err);
    }
  }
  if (group === "Personal Property" && assetClass === "Accounts") {
    try {
      const assetData = await Assets.readAccountByAssetUuid({
        asset_uuid: uuid
      });
      if (assetData.error) {
        setAssetsData(false);
        onError(assetData.error);
      } else {
        setAdditionalAssetsData({ data: assetData.data[0] || "" });
        setAssetsData(true);
      }
    } catch (err) {
      setAssetsData(false);
      onError(err);
    }
  }
}

/**
 * Update asset data related to the asset group or asset group + class.
 * @param {string} uuid The asset's UUID.
 * @param {"Personal Property" | "Real Estate"} group The `asset_group`.
 * @param {object} assetData The supplemental data to send to the server.
 * @param {(error) => void} onError
 * @param {() => void} [onComplete] A callback invoked after the function has finished the update.
 */
export async function updateAdditionalAssetData(
  group,
  assetClass,
  additionalAssetData,
  loanApp,
  onError,
  onComplete
) {
  if (group === "Real Estate") {
    try {
      const assetData = await Assets.asyncRealEstateAssetUpdate(
        additionalAssetData,
        {
          uuid: additionalAssetData.uuid,
          institution_uuid: loanApp.institution_uuid
        }
      );
      if (assetData.error) {
        onError(assetData.error);
      }
    } catch (err) {
      onError(err);
    }
  }
  if (group === "Personal Property" && assetClass !== "Accounts") {
    try {
      const assetData = await Assets.asyncPersonalPropertyAssetUpdate(
        additionalAssetData,
        {
          uuid: additionalAssetData.uuid,
          institution_uuid: loanApp.institution_uuid
        }
      );
      if (assetData.error) {
        onError(assetData.error);
      }
    } catch (err) {
      onError(err);
    }
  }
  if (group === "Personal Property" && assetClass === "Accounts") {
    try {
      const assetData = await Assets.asyncAccountAssetUpdate(
        additionalAssetData,
        {
          uuid: additionalAssetData.uuid,
          institution_uuid: loanApp.institution_uuid
        }
      );
      if (assetData.error) {
        onError(assetData.error);
      }
    } catch (err) {
      onError(err);
    }
  }

  // eslint-disable-next-line no-unused-expressions
  onComplete && onComplete();
}

/**
 * Supply additional asset data that depends on the asset's group + class.
 * @param {"Other" | "Personal Property" | "Real Estate"} group The asset's group.
 * @param {string} assetClass The asset's class.
 * @param {object} additionalAssetData The data to be stored, shape depends on the asset group + class.
 * @param {object} assetModal
 * @param {string} assetModal.uuid The asset's UUID.
 * @param {object} loanApp
 * @param {string} loanApp.institution_uuid The institution UUID.
 * @param {(err) => void} onError
 * @param {(result) => void} onComplete
 */
export async function addAdditionalAssetData(
  group,
  assetClass,
  additionalAssetData,
  assetModal,
  loanApp,
  onError,
  onComplete
) {
  if (group === "Real Estate") {
    try {
      const assetData = await Assets.asyncRealEstateAssetAdd(
        additionalAssetData,
        assetModal.uuid,
        loanApp.institution_uuid
      );
      if (assetData.error) {
        onError(assetData.error);
      }
    } catch (err) {
      onError(err);
    }
  }
  if (group === "Personal Property" && assetClass !== "Accounts") {
    try {
      const assetData = await Assets.asyncPersonalPropertyAssetAdd(
        additionalAssetData,
        assetModal.uuid,
        loanApp.institution_uuid
      );
      if (assetData.error) {
        onError(assetData.error);
      }
    } catch (err) {
      onError(err);
    }
  }
  if (group === "Personal Property" && assetClass === "Accounts") {
    try {
      const assetData = await Assets.asyncAccountAssetAdd(
        additionalAssetData,
        assetModal.uuid,
        loanApp.institution_uuid
      );
      if (assetData.error) {
        onError(assetData.error);
      }
    } catch (err) {
      onError(err);
    }
  }

  // eslint-disable-next-line no-unused-expressions
  onComplete && onComplete();
}
