import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import TypeCheck from "typecheck-extended";
import { connect } from "react-redux";

import { Button, Dropdown, Grid, Icon } from "semantic-ui-react";

export function paginationChanged(prevPagination, currentPagination) {
  if (!prevPagination && currentPagination) {
    return true;
  }
  if (
    _.get(prevPagination, "number", 0) !==
      _.get(currentPagination, "number", 0) ||
    _.get(prevPagination, "size", 0) !== _.get(currentPagination, "size", 0)
  ) {
    return true;
  }
  return false;
}

/*
  TODO: Before implementing passing a `dispatch` into another function/service
  (as done below), our team should decide if we are ok with using `dispatch` like this.
*/
export function resetPagination(dispatch) {
  dispatch({ type: "PAGINATION_CLEAR_STATE" });
}

export const emptyPagination = { size: 0, number: 0 };

export class PaginationControlsObj extends Component {
  constructor(props) {
    super(props);
    this.changePageNumber = this.changePageNumber.bind(this);
    this.changePageSize = this.changePageSize.bind(this);
  }

  componentDidMount() {
    this.initalizePaginaton();
  }

  componentDidUpdate(prevProps) {
    const { name } = this.props;
    const { name: prevName } = prevProps;
    if (name !== prevName) {
      this.initalizePaginaton();
    }
  }

  initalizePaginaton() {
    const { dispatch, name, pageSizeOverride } = this.props;
    dispatch({
      name,
      pageSizeOverride,
      type: "PAGINATION_INITIALIZE"
    });
  }

  changePageNumber(increment) {
    TypeCheck(increment, "number");

    const { dispatch, name, pagination } = this.props;
    const { number } = pagination[name];
    dispatch({
      type: "PAGINATION_UPDATE_NUMBER",
      number: number + increment,
      name
    });
  }

  changePageSize(size) {
    TypeCheck(size, "number");

    const { dispatch, name } = this.props;
    dispatch({
      type: "PAGINATION_UPDATE_SIZE",
      size,
      name
    });
  }

  render() {
    // TODO: The upper end of these page size ranges was added to help with LOR Doc Approval.
    //       Those sizes may no longer be needed/appropriate.
    const {
      name,
      pagination,
      lastPage,
      length = 0,
      showPageNumber
    } = this.props;
    const number = _.get(pagination, `${name}.number`, 0);
    const size = _.get(pagination, `${name}.size`, 0);

    // NOTE: For backward compatibility using length to determine last page if
    //       lastPage prop is not passed.Not all apis support last_page flag.

    /** When using length if number of records on a page is equal to pagesize then
     * last page determination is wrong in some cases.
     *    for eg. lets assume there are 5 records in db and page size size is 5,
     *    then for the first page:
     *            => length < (number + 1) * size
     *            => 5<(0+1)*5
     *            => 5<5 which is always false
     *    This is wrong since we only have 5 records in the db which means only 1 page.
     *    The first page should be the lastpage.
     */
    const isLastPage =
      lastPage === null || lastPage === undefined
        ? length <= (number + 1) * size
        : lastPage;

    const pageSizes = [5, 10, 20, 40, 60, 80, 100];
    const pageSizeOptions = pageSizes.map(pgSize => ({
      key: pgSize,
      text: pgSize,
      value: pgSize
    }));

    return (
      <div>
        <Grid columns={3}>
          <Grid.Row style={{ alignItems: "center" }}>
            <Grid.Column>
              <span className="text-sm mr-1 font-semibold">Page Size:</span>
              <Dropdown
                disabled={!size}
                onChange={(e, data) => this.changePageSize(data.value)}
                options={pageSizeOptions}
                value={size}
              />
            </Grid.Column>
            <Grid.Column style={{ textAlign: "center" }}>
              {showPageNumber && (
                <>
                  <strong>Page Number: </strong>
                  <span id="page-number-value" className="text-sm">
                    {number + 1}
                  </span>
                </>
              )}
            </Grid.Column>
            <Grid.Column style={{ textAlign: "right" }}>
              <Button
                disabled={!number || !size}
                className="mr-2"
                icon
                size="tiny"
                onClick={() => this.changePageNumber(-1)}
              >
                <Icon name="chevron left" />
              </Button>
              <Button
                disabled={!size || isLastPage}
                icon
                size="tiny"
                onClick={() => this.changePageNumber(1)}
              >
                <Icon name="chevron right" />
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

PaginationControlsObj.defaultProps = {
  name: "default",
  pageSizeOverride: undefined
};

PaginationControlsObj.propTypes = {
  pagination: PropTypes.objectOf(
    PropTypes.shape({
      number: PropTypes.number.isRequired,
      size: PropTypes.number.isRequired
    })
  ).isRequired,
  name: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  pageSizeOverride: PropTypes.number,
  lastPage: PropTypes.bool,
  length: PropTypes.number,
  showPageNumber: PropTypes.bool
};

const mapStateToProps = state => ({
  pagination: state.PaginationReducer
});

const PaginationControls = connect(mapStateToProps)(PaginationControlsObj);

export default PaginationControls;
