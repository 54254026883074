import React, { useEffect, useState } from "react";
import { useDeletePaymentScheduleMutation } from "../../../../../../services/RTKQuery/paymentSchedules";

export const DeleteTest = ({ loanUuid, itemUuid, setStatusInParent }) => {
  const statusUnknown = "❓Unknown Status";
  const statusPass = "✅ Pass";
  const statusFail = "❌ Fail";

  const [statusReadSingle, setStatus] = useState(statusUnknown);
  const [detailsText, setDetailsText] = useState("statusUnknown");

  const [deletePaymentSchedule] = useDeletePaymentScheduleMutation();
  useEffect(() => {
    deletePaymentSchedule({
      loanUuid: loanUuid,
      uuid: itemUuid
    })
      .unwrap()
      .then(fulfilled => {
        if (
          fulfilled?.data?.uuid === itemUuid &&
          fulfilled?.data?.deleted === true
        ) {
          setStatus(statusPass);
          setDetailsText(`: Soft deleted item: ${itemUuid}`);
          setStatusInParent("Pass");
        } else {
          console.error("delete fulfilled: ", fulfilled);
          setStatus(statusFail);
          setDetailsText(`: Did NOT delete item: ${itemUuid}`);
          setStatusInParent("Fail");
        }
      })
      .catch(error => {
        console.error("Fail: useDeletePaymentScheduleMutation", error);
        setStatus(statusFail);
        setStatusInParent("Fail");
      });
  }, [deletePaymentSchedule, loanUuid, setStatusInParent, itemUuid]);

  return (
    <>
      <b>{statusReadSingle}</b>
      <small>{detailsText}</small>
    </>
  );
};
