/*
Used when presenting USD amounts returned to the browser as numbers or unformatted strings.

Copied from currency formatting in revival, only formatAsCurrency copied
*/
export default function formatAsCurrency(number) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2
  });
  return formatter.format(number);
}
