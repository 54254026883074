export const statementTypeOptions = [
  { text: "Historical", value: "Historical" },
  { text: "Projection", value: "Projection" },
  { text: "Analyst Adjusted", value: "Analyst Adjusted" }
];
export const periodOptions = [
  { text: "12 Months", value: "12 Months" },
  { text: "3 Months", value: "3 Months" },
  { text: "1 Month", value: "1 Month" },
  { text: "11 Months", value: "11 Months" },
  { text: "10 Months", value: "10 Months" },
  { text: "9 Months", value: "9 Months" },
  { text: "8 Months", value: "8 Months" },
  { text: "7 Months", value: "7 Months" },
  { text: "6 Months", value: "6 Months" },
  { text: "5 Months", value: "5 Months" },
  { text: "4 Months", value: "4 Months" },
  { text: "2 Months", value: "2 Months" }
];
export const sourceDocumentOptions = [
  { text: "Company Prepared", value: "Company Prepared" },
  { text: "Compilation", value: "Compilation" },
  { text: "Reviewed", value: "Reviewed" },
  { text: "Audited", value: "Audited" },
  { text: "Tax Return - Form 1040", value: "Tax Return - Form 1040" },
  { text: "Tax Return - Form 1041", value: "Tax Return - Form 1041" },
  { text: "Tax Return - Form 1065", value: "Tax Return - Form 1065" },
  { text: "Tax Return - Form 1120", value: "Tax Return - Form 1120" },
  { text: "Tax Return - Form 1120S", value: "Tax Return - Form 1120S" },
  { text: "Tax Return - Form 990", value: "Tax Return - Form 990" }
];
export const sourceDocumentOptionsProjection = [
  { text: "Projection", value: "Projection" }
];
export const opinionOptions = [
  { text: "Unqualified", value: "Unqualified" },
  { text: "Qualified", value: "Qualified" },
  { text: "Disclaimer", value: "Disclaimer" },
  { text: "Adverse", value: "Adverse" }
];
export const accountingMethodOptions = [
  { text: "Accrual", value: "Accrual" },
  { text: "Cash", value: "Cash" }
];
