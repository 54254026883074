import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Header } from "semantic-ui-react";

import LightHeader from "../../../../../../components/LightHeader";
import ParticipationServicers from "../../../../../../services/ApiLib/services/ParticipationServicers";
import { getInstitutionUuid, isBafs } from "../../../../../../services/Auth";
import logger from "../../../../../../services/logger";

function ServicingDetailsObj() {
  const dispatch = useDispatch();
  const { participationServicer } = useSelector(reduxState => ({
    participationServicer: reduxState.SystemAdminReducer.participationServicer
  }));
  const updateServicer = useCallback(async () => {
    try {
      const participationServicers = await ParticipationServicers.read();
      let servicer = {};
      if (isBafs()) {
        servicer = participationServicers.data.find(
          pServicer => pServicer.institution_uuid === getInstitutionUuid()
        );
      }
      dispatch({
        type: "SYSTEM_ADMIN_UPDATE_SERVICER",
        participationServicer: servicer
      });
    } catch (err) {
      logger.error(err);
    }
  }, [dispatch]);

  useEffect(() => {
    updateServicer();
  }, [updateServicer]);

  function genField(title, value) {
    let displayValue;
    if (value === undefined || value === null) {
      displayValue = "—";
    } else {
      displayValue = value;
    }
    return (
      <Grid.Column>
        <LightHeader>{title}</LightHeader>
        <b>{displayValue}</b>
      </Grid.Column>
    );
  }

  function concatAddress(addressType) {
    let address = "";
    address = (
      <b>
        {participationServicer[`${addressType}_primary_address`]}
        {participationServicer[`${addressType}_primary_address_2`] ? (
          <br />
        ) : (
          ""
        )}
        {participationServicer[`${addressType}_primary_address_2`]
          ? participationServicer[`${addressType}_primary_address_2`]
          : ""}
        <br />
        {participationServicer[`${addressType}_city`]
          ? participationServicer[`${addressType}_city`].concat(", ")
          : ""}
        {participationServicer[`${addressType}_state`]}
        <br />
        {participationServicer[`${addressType}_postal_code`]}
      </b>
    );
    return address;
  }

  function concatName(nameType) {
    let name;
    name = participationServicer
      ? participationServicer[`${nameType}_first_name`]
      : undefined;
    name = participationServicer[`${nameType}_last_name`]
      ? name.concat(" ", participationServicer[`${nameType}_last_name`])
      : undefined;
    return name;
  }

  const wiringAddress = concatAddress("wiring");
  const mailingAddress = concatAddress("mailing");
  const primaryName = concatName("primary");
  const secondaryName = concatName("secondary");

  return (
    <Grid columns={7}>
      <Grid.Row>
        {genField("Primary Contact", primaryName)}
        {genField("Primary Contact Email", participationServicer.primary_email)}
        {genField("Primary Contact Phone", participationServicer.primary_phone)}
        {genField("Charter Number", participationServicer.charter_number)}
        {genField("TIN", participationServicer.tin)}
        {genField("Routing Number", participationServicer.routing_number)}
        {genField("Mailing Address", mailingAddress)}
      </Grid.Row>
      <Grid.Row>
        {genField("Secondary Contact", secondaryName)}
        {genField(
          "Secondary Contact Email",
          participationServicer.secondary_email
        )}
        {genField(
          "Secondary Contact Phone",
          participationServicer.secondary_phone
        )}
        {genField("Website", participationServicer.website)}
      </Grid.Row>
      <Header size="small">Wiring Instructions</Header>
      <Grid.Row>
        {genField("Remit To", participationServicer.remit_to)}
        {genField("Institution Address", wiringAddress)}
      </Grid.Row>
      <Grid.Row>
        {genField("ABA/SWIFT Number", participationServicer.aba_number)}
        {genField(
          "Account Number",
          participationServicer.wiring_account_number
        )}
        {genField("For Credit To", participationServicer.credit_to)}
        {genField(
          "For Further Credit To",
          participationServicer.further_credit_to
        )}
        {genField(
          "Account Number (For Further Credit To)",
          participationServicer.credit_to_account_number
        )}
      </Grid.Row>
      <Header size="small">ACH Instructions</Header>
      <Grid.Row>
        {genField(
          "ABA/Routing Number",
          participationServicer.ach_routing_number
        )}
        {genField("Account Number", participationServicer.ach_account_number)}
        {genField("Account Type", participationServicer.ach_account_type)}
      </Grid.Row>
    </Grid>
  );
}

export default ServicingDetailsObj;
