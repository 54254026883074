import { Loan } from "../../../../../../../../../../../../../services/RTKQuery/ModelTypes/loan";
import { SetStateAction } from "react";
import { AgencyProgramDropdown } from "./components/AgencyProgramDropdown";
import { GovernmentalGuaranteeDropdown } from "./components/GovernmentalGuaranteeDropdown";
import { StateAwareFieldChangeFunction } from "../../services/types";

export const GovGuaranteeAndAgencyProgram = ({
  handleFieldChange,
  loanDetails
}: GovGuaranteeAndAgencyProgramProps) => {
  return (
    <>
      <GovernmentalGuaranteeDropdown
        handleFieldChange={handleFieldChange}
        value={loanDetails.gov_guarantee_program}
      />
      {loanDetails.gov_guarantee_program ? (
        <AgencyProgramDropdown
          handleFieldChange={handleFieldChange}
          value={loanDetails.gov_guarantee_agency_program}
        />
      ) : (
        <>
          <AgencyProgramDropdown disabled />
        </>
      )}
    </>
  );
};

interface GovGuaranteeAndAgencyProgramProps {
  handleFieldChange: StateAwareFieldChangeFunction;
  loanDetails: Partial<Loan>;
  setSaveButtonEnabled: React.Dispatch<SetStateAction<boolean>>;
}
