import React, { useState } from "react";

import {
  codeText,
  summaryBlockStyle,
  tableStyle,
  trStyle
} from "../../../../appStatusStyles";

import { CreateTest } from "./create";
import { ReadSingleTest } from "./readSingle";
import { UpdateTest } from "./update";
import { ReadManyTest } from "./readMany";
import { DeleteTest } from "./delete";

// const Placeholder = ({ ...everything }) => <>Placeholder</>;
// const ReadSingleTest = Placeholder;
// const UpdateTest = Placeholder;
// const ReadManyTest = Placeholder;
// const DeleteTest = Placeholder;

/* Test Setup Data - Hardcoded UUIDs must already be in the database
  These tests will write records to the database. If the cleanup fails,
  it'll be dumping garbage data into the DB. Only run this on records
  tied to the BAFS Bank testing institution:
+----------------+-----------+--------------------------------------+
| institution_id | name      | uuid                                 |
+----------------+-----------+--------------------------------------+
| 16             | BAFS Bank | institut-ions-0016-IBLS-TestBranch00 |
+----------------+-----------+--------------------------------------+
*/
const institutionUuid = "institut-ions-0016-IBLS-TestBranch00";
const loanUuid = "02aa1d9b-b17f-45f2-8585-354e7ded1d58";

export const InterestSchedulesStatus = () => {
  const [newRecordUuid, setRecordUuid] = useState("");
  const [statusCreate, setStatusCreate] = useState("Waiting...");
  const [statusReadSingle, setStatusReadSingle] = useState("Waiting...");
  const [statusUpdate, setStatusUpdate] = useState("Waiting...");
  const [statusReadMany, setStatusReadMany] = useState("Waiting...");
  const [statusDelete, setStatusDelete] = useState("Waiting...");

  function setUuidOfCreatedRecord(uuid: string) {
    if (!newRecordUuid) {
      setRecordUuid(uuid);
    }
  }

  function headerWithStatus() {
    const headerTxt = "Interest Schedules";
    if (
      statusCreate == "Pass" &&
      statusReadSingle == "Pass" &&
      statusUpdate == "Pass" &&
      statusReadMany == "Pass" &&
      statusDelete == "Pass"
    ) {
      return <h3>✅ {headerTxt}</h3>;
    } else if (
      statusCreate == "Fail" ||
      statusReadSingle == "Fail" ||
      statusUpdate == "Fail" ||
      statusReadMany == "Fail" ||
      statusDelete == "Fail"
    ) {
      return <h3>❌ {headerTxt}</h3>;
    }
    return <h3>⏲️ {headerTxt}</h3>;
  }

  return (
    <>
      {headerWithStatus()}
      <div style={summaryBlockStyle}>
        <table style={tableStyle}>
          <tbody>
            <tr style={trStyle}>
              <td>
                <b>Create:</b>
              </td>
              <td>
                <code style={codeText}>
                  {"useCreateInterestScheduleMutation()"}
                </code>
              </td>
              <td>
                {!newRecordUuid ? (
                  <CreateTest
                    institutionUuid={institutionUuid}
                    loanUuid={loanUuid}
                    setStatusInParent={setStatusCreate}
                    setUuidOfCreatedRecord={setUuidOfCreatedRecord}
                  />
                ) : (
                  <>
                    <b>✅ Pass:</b>{" "}
                    <small>Record Created: {newRecordUuid}</small>
                  </>
                )}
              </td>
            </tr>
            <tr style={trStyle}>
              <td>
                <b>Read - Single:</b>
              </td>
              <td>
                <code style={codeText}>{"useInterestScheduleQuery()"}</code>
              </td>
              <td>
                {newRecordUuid ? (
                  <ReadSingleTest
                    loanUuid={loanUuid}
                    scheduleUuid={newRecordUuid}
                    setStatusInParent={setStatusReadSingle}
                  />
                ) : (
                  "Waiting on CreateTest to finish ..."
                )}
              </td>
            </tr>
            <tr style={trStyle}>
              <td>
                <b>Update:</b>
              </td>
              <td>
                <code style={codeText}>
                  {"useUpdateInterestScheduleMutation()"}
                </code>
              </td>
              <td>
                {statusReadSingle === "Pass" ? (
                  <UpdateTest
                    loanUuid={loanUuid}
                    scheduleUuid={newRecordUuid}
                    setStatusInParent={setStatusUpdate}
                  />
                ) : (
                  "Waiting on ReadSingleTest to finish ..."
                )}
              </td>
            </tr>
            <tr style={trStyle}>
              <td>
                <b>Read - Many:</b>
              </td>
              <td>
                <code style={codeText}>{"useInterestSchedulesQuery()"}</code>
              </td>
              <td>
                {statusUpdate === "Pass" ? (
                  <ReadManyTest
                    institutionUuid={institutionUuid}
                    loanUuid={loanUuid}
                    scheduleUuid={newRecordUuid}
                    setStatusInParent={setStatusReadMany}
                  />
                ) : (
                  "Waiting on UpdateTest to finish ..."
                )}
              </td>
            </tr>
            <tr style={trStyle}>
              <td>
                <b>Delete:</b>
              </td>
              <td>
                <code style={codeText}>
                  {"useDeleteInterestScheduleMutation()"}
                </code>
              </td>
              <td>
                {statusReadMany === "Pass" ? (
                  <DeleteTest
                    loanUuid={loanUuid}
                    scheduleUuid={newRecordUuid}
                    setStatusInParent={setStatusDelete}
                  />
                ) : (
                  "Waiting on ReadManyTest to finish ..."
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
