import { Dialog } from "../../../fomantic/modules/modals/dialog/dialog";
import { createId, FormLabel } from "../FormLabel";

export const NumberInput = ({
  className = "",
  handleInputChange,
  labelText,
  name,
  onBlur,
  placeholderText,
  required = false,
  testId = "",
  disabled = false,
  value
}: NumberInputProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Ensure only numeric values are accepted
    const newValue = parseFloat(event.target.value);
    if (!isNaN(newValue)) {
      handleInputChange(event);
    }
  };

  return (
    <div className={`flex flex-col flex-1 ${className}`}>
      <FormLabel labelText={labelText} required={required} />
      <div className="border border-neutral-300 flex flex-row rounded">
        <input
          className="flex-1 py-2 pr-2 pl-1"
          id={createId(labelText, "label")}
          name={name}
          onChange={handleChange}
          placeholder={placeholderText || labelText}
          type="text"
          data-testid={testId}
          value={value}
          onBlur={onBlur}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export interface NumberInputProps {
  className?: string;
  disabled?: boolean;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelText: string;
  name: string;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholderText?: string;
  required?: boolean;
  testId?: string;
  value: number;
}
