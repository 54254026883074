import classNames from "classnames";

/**
 * A horizontal sequence of tabs that allow the user to activate a single tab.
 * If optional children are provided they will be rendered below the tabs.
 */
export function Tabs({
  tabs,
  renderButton,
  selectedTab,
  children,
  style = "tabular"
}: React.PropsWithChildren<TabsProps>) {
  const className =
    style === "tabular" ? "tabular attached" : "secondary pointing";
  return (
    <>
      <div className={classNames("ui top menu", className)} role="tablist">
        {tabs.map((currentTab, index) =>
          renderButton({
            active: selectedTab === currentTab,
            center: index !== 0 && index !== tabs.length - 1,
            index,
            currentTab
          })
        )}
      </div>
      <div>{children}</div>
    </>
  );
}

/** A component implements this interface to allow the user to activate one tab at a time. */
export interface TabsProps {
  /** Array of string used for the tabs name */
  tabs: string[];
  /** Choose the display style of the tabs */
  style?: "tabular" | "underline";
  /** The default selected tab */
  selectedTab: string;
  /** Should be a function that renders a tab  */
  renderButton: (tabData: TabData) => JSX.Element;
}

export interface TabData {
  /** True if the tab is active, false otherwise */
  active: boolean;
  /** True if the tabs is centered, false otherwise */
  center: boolean;
  /** The tab clicked by the user */
  currentTab: string;
  /** Index number of the tab */
  index: number;
}
