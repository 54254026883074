import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Header, Modal, TextArea } from "semantic-ui-react";
import { Notifications } from "../../../../services/ApiLib";
import logger from "../../../../services/logger";

export const onError = rsp => logger.error(rsp);

function ErrorNotificationModal(props) {
  const { onCancel, header, openModal } = props;
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    // Make request to backend.
    const payload = {
      message,
      subject: "Failed Auto Spread Integration"
    };
    setLoading(true);

    await Notifications.postInternalMessage(
      () => {
        setLoading(false);
        setMessage("");
        onCancel();
      },
      () => setError("Could not send message"),
      payload
    );
  };

  const handleCancel = () => {
    setError("");
    setMessage("");
    onCancel();
  };

  return (
    <Modal open={openModal} size="large">
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Header>Message</Header>
        <TextArea
          style={{ width: "100%" }}
          rows="7"
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button id="cancelButton" onClick={handleCancel} basic>
          Cancel
        </Button>
        <Button disabled={loading} id="submitButton" onClick={handleSubmit}>
          {loading ? "Loading..." : "Send"}
        </Button>
        {error !== "" ? <p>{error}</p> : null}
      </Modal.Actions>
    </Modal>
  );
}

ErrorNotificationModal.propTypes = {
  header: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  openModal: PropTypes.bool
};

export default ErrorNotificationModal;
