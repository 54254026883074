import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";

function LockCell({ value, column, row }) {
  return (
    <Icon
      data-testid="lock-cell"
      name={value ? "lock" : "unlock"}
      link
      disabled={column?.disabled || value}
      onClick={() => {
        if (!value) {
          column?.onLock(row?.original);
        }
      }}
      size="large"
    />
  );
}

LockCell.propTypes = {
  value: PropTypes.any,
  column: PropTypes.shape({
    disabled: PropTypes.bool,
    onLock: PropTypes.func
  }),
  row: PropTypes.shape({
    original: PropTypes.object // adjust according to the structure of original object
  })
};

export default LockCell;
