import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { getToken, isBafs } from "../../services/Auth";
import { getBlastClientApplicationBaseUrl } from "./getBaseUrl";

const getParamString = params =>
  new URLSearchParams({
    ...params,
    isBafsUser: isBafs()
  }).toString();

function BlastIframeImpl({ queryParams, urlPath, onUpdate }, ref) {
  const token = useMemo(() => getToken(), []);
  const baseUrl = useMemo(
    () => `${getBlastClientApplicationBaseUrl()}/legacy${urlPath}`,
    [urlPath]
  );
  const url = useMemo(
    () => `${baseUrl}?${getParamString(queryParams)}#${token}`,
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [queryParams, token]
  );

  useEffect(() => {
    window.addEventListener("message", onUpdate);
    return () => {
      window.removeEventListener("message", onUpdate);
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [onUpdate]);

  return (
    <div
      style={{ height: "85%", paddingBottom: "75vh", backgroundColor: "white" }}
      className="ui active embed"
    >
      <div className="embed">
        <iframe
          scrolling="yes"
          src={url}
          title="Blast Embed"
          width="100%"
          ref={ref}
          height="100%"
          frameBorder="0"
        />
      </div>
    </div>
  );
}

const BlastIframe = React.forwardRef(BlastIframeImpl);

BlastIframeImpl.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  queryParams: PropTypes.shape().isRequired,
  urlPath: PropTypes.string.isRequired
};

export default BlastIframe;
