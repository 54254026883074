import { Toggle } from "@bafsllc/ui-shared";
import React, { useEffect } from "react";
import { useState } from "react";
import { Notifications } from "../../services/ApiLib";
import ErrorReporter from "../../services/ErrorReporter";

function NotificationToggle({ uuid, message }) {
  const [isSubscribed, setIsSubscribed] = useState(false);

  const subscribe = () => {
    if (isSubscribed) {
      Notifications.unsubscribe(
        () => setIsSubscribed(false),
        () => ErrorReporter("Unsubscribe failed."),
        uuid
      );
    } else {
      Notifications.subscribe(
        () => setIsSubscribed(true),
        () => ErrorReporter("Subscribed failed"),
        uuid
      );
    }
  };

  useEffect(() => {
    const getSubscription = () => {
      Notifications.getSubscription(
        ({ data }) => setIsSubscribed(!!data),
        () =>
          ErrorReporter("Failed to update notification subscription status."),
        uuid
      );
    };

    getSubscription();
  }, [uuid]);

  return (
    <Toggle
      checked={isSubscribed}
      data-testid="notification-toggle"
      name="notification_subscription"
      onClick={() => subscribe()}
      message={{ id: message }}
    />
  );
}

export default NotificationToggle;
