import createCRUDSlice from "../../create-crud-slice";

const CRUD = createCRUDSlice("fees");

export const { actions, reducer, selectors, initialState } = CRUD;

export default {
  actions,
  reducer,
  selectors,
  initialState
};
