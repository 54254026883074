import TypeCheck from "typecheck-extended";
import ApiEndpoints from "../../../ApiEndpoints";
import FetchService from "../../../FetchService";
import { ConcatQueryParams, PaginationParams } from "../Utilities";

function get(onSuccess, onError, uuid, queryParams, pagination, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string", false);
  TypeCheck(queryParams, "object", false);

  if (uuid && queryParams) {
    onError("Error: Invalid Filter Combination");
    return;
  }

  let params = "";
  let url = "";
  if (uuid) {
    url = `${ApiEndpoints.baseUri}${ApiEndpoints.notifications}/${uuid}`;
  } else {
    if (queryParams) {
      params = ConcatQueryParams(queryParams);
    }
    if (pagination) {
      params = PaginationParams(params, pagination);
    }
    url = `${ApiEndpoints.baseUri}${ApiEndpoints.notifications}/${params}`;
  }

  FetchService("GET", url, onSuccess, onError, null, callbackData);
}

function markRead(onSuccess, onError, uuid, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string", false);

  const url = `${ApiEndpoints.baseUri}${ApiEndpoints.notifications}/${uuid}`;

  FetchService("PUT", url, onSuccess, onError, null, callbackData);
}

async function postInternalMessage(onSuccess, onError, payload) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(payload, "object");

  const url = `${ApiEndpoints.baseUri}${ApiEndpoints.internalNotifications}`;

  await FetchService("POST", url, onSuccess, onError, payload);
}

function subscribe(onSuccess, onError, uuid, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string");

  const url = `${ApiEndpoints.baseUri}${ApiEndpoints.subscriptions}/`;

  FetchService(
    "POST",
    url,
    onSuccess,
    onError,
    { item_uuid: uuid },
    callbackData
  );
}

function unsubscribe(onSuccess, onError, uuid, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string");

  const url = `${ApiEndpoints.baseUri}${ApiEndpoints.subscriptions}/${uuid}`;

  FetchService("DELETE", url, onSuccess, onError, null, callbackData);
}

function getSubscription(onSuccess, onError, uuid, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string");

  const url = `${ApiEndpoints.baseUri}${ApiEndpoints.subscriptions}/${uuid}`;

  FetchService("GET", url, onSuccess, onError, null, callbackData);
}

export default {
  get,
  getSubscription,
  postInternalMessage,
  markRead,
  subscribe,
  unsubscribe
};
