export const initialState = {
  openModal: false,
  selectedDocument: {},
  collateralDocsFilter: ""
};

export default function CollateralDocumentsReducer(state, action) {
  const safeState = state ?? initialState;
  switch (action.type) {
    case "COLLATERAL_DOCUMENTS_SELECT_DOCUMENT":
      return {
        ...safeState,
        openModal: !safeState.openModal,
        selectedDocument: action.obj
      };
    case "CMS_COLLATERAL_DOCUMENTS_SEARCH_FILTER":
      return { ...safeState, collateralDocsFilter: action.searchInfo };
    default:
      return safeState;
  }
}
