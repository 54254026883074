import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import PropTypes from "prop-types";
import TypeCheck from "typecheck-extended";

import {
  Entities,
  Institutions
} from "../../../../../../../../services/ApiLib";
import { ConcatName } from "../../../../../../../../services/Entities";
import { concealTin } from "../../../../../../../../services/FormatTin";
import logger from "../../../../../../../../services/logger";
import createEmailOptions from "./SideSheet/createEmailOptions";
import { userFeedbackEnum } from "./constants";

const defaults = {
  currentLinkedEntities: [],
  linkedEntitiesTotalCount: 0,
  // Side Sheet
  bpUsers: [],
  bpOrgUuIdToLink: "",
  signUpData: null,
  emailOptions: [],
  linkButtonDisabled: true
};

export const dropdownOptionsTransformer = entity => {
  let searchableText = entity?.tin || "";
  const entityName = ConcatName(entity) || "";
  if (entityName) {
    searchableText += ` | ${entityName}`;
  }
  if (entity?.entity_type) {
    searchableText += ` | ${entity?.entity_type}`;
  }

  return {
    key: entity?.uuid,
    value: entity?.uuid,
    searchableText,
    displayText: entityName,
    entityType: entity?.entity_type,
    concealedTin: concealTin(entity?.tin, entity?.entity_type)
  };
};

export function transformEntitiesResponse(entities, entityData) {
  TypeCheck(entities, "array");
  TypeCheck(entityData, "object");

  const reformattedEntitiesList = [];
  entities.forEach(entity => {
    const reformattedEntity = dropdownOptionsTransformer(entity);
    if (entity.uuid !== entityData.uuid && !entity.bp_organization_uuid) {
      reformattedEntitiesList.push(reformattedEntity);
    }
  });
  return reformattedEntitiesList;
}

export async function handleEntityNameInputChangeInternal(
  onChangeEvent,
  updateDropdownListItems,
  entityData
) {
  TypeCheck(onChangeEvent, "object", false); // this is an event
  TypeCheck(updateDropdownListItems, "function");
  TypeCheck(entityData.institution_uuid, "string");

  if (!onChangeEvent || !onChangeEvent.currentTarget) {
    updateDropdownListItems([]);
    return;
  }

  const institutionUuid = entityData.institution_uuid;
  const newSearchString = onChangeEvent.currentTarget.value;
  // This is an arbitrary page_size to try to help with the problem of communicating
  // to the user whether or not all of the results to which they have access for a
  // given query have been populated in the search box.
  const filters = {
    institution_uuid: institutionUuid,
    page_size: 200,
    lookup: newSearchString
  };
  const entitiesRsp = await Entities.read(filters);
  const reformattedEntitiesList = transformEntitiesResponse(
    entitiesRsp.data,
    entityData
  );
  updateDropdownListItems(reformattedEntitiesList);
}

export const BPLinksContext = createContext(defaults);

function useBPLinksContext(entityData) {
  const bpLinksContext = React.useContext(BPLinksContext);

  const {
    bpOrganizationUuid,
    entityUuid,
    institutionUuid,
    updateCurrentLinkedEntities,
    updateLinkedEntitiesTotalCount,
    updateDropdownListItems,
    updateEntityData,
    // Side Sheet
    bpUsers,
    updateBpUsers,
    updateEmailOptions
  } = bpLinksContext;

  const [institutionData, setInstitutionData] = useState({});

  const updateInstitutionData = useCallback(data => {
    setInstitutionData(data);
  }, []);

  const fetchInstitutionData = useCallback(async () => {
    if (!institutionUuid) {
      return;
    }

    try {
      const institution = await Institutions.asyncRead({ institutionUuid });
      updateInstitutionData(institution?.data || {});
    } catch (error) {
      logger.error("Error fetching institution data:", error);
    }
  }, [institutionUuid, updateInstitutionData]);

  useEffect(() => {
    fetchInstitutionData();
  }, [fetchInstitutionData]);

  const fetchEntities = useCallback(async () => {
    if (!institutionUuid) {
      return;
    }

    // eslint-disable-next-line arrow-body-style
    const removeCurrentEntityAndLinkedEntities = (entities = []) => {
      return entities.filter(
        entity => entity.uuid !== entityUuid && !entity.bp_organization_uuid
      );
    };

    const entities = await Entities.asyncReadByInstitutionUuid(institutionUuid);

    let dropdownFormattedEntitiesData = [];
    const entitiesData = entities?.data || [];

    if (entitiesData.length > 0) {
      dropdownFormattedEntitiesData =
        removeCurrentEntityAndLinkedEntities(entitiesData);
      dropdownFormattedEntitiesData = dropdownFormattedEntitiesData.map(
        dropdownOptionsTransformer
      );
    }

    updateDropdownListItems(dropdownFormattedEntitiesData);
  }, [entityUuid, institutionUuid, updateDropdownListItems]);

  const fetchLinkedEntities = useCallback(
    async (pageNumber, pageSize) => {
      if (!bpOrganizationUuid) {
        return [];
      }

      const entities = await Entities.read({
        bp_organization_uuid: bpOrganizationUuid,
        ...(pageNumber && { page_number: pageNumber - 1 }),
        ...(pageSize && { page_size: pageSize })
      });
      const { count = 0 } = entities?.metaData || {};
      const bpLinks = await Entities.getAllBpLinksByBpOrgId(bpOrganizationUuid);
      const borrowerPortalEntityUuids = new Set();
      bpLinks.data.forEach(link => {
        if (link.origin === "BORROWER_PORTAL") {
          borrowerPortalEntityUuids.add(link.entity_uuid);
        }
      });
      const entitiesData = Array.isArray(entities?.data)
        ? entities.data.map(entity => ({
            ...entity,
            entityName: ConcatName(entity),
            partiallyDisabled: borrowerPortalEntityUuids.has(entity.uuid)
          }))
        : [];

      updateCurrentLinkedEntities(entitiesData);
      updateLinkedEntitiesTotalCount(count);

      return entitiesData;
    },
    [
      bpOrganizationUuid,
      updateCurrentLinkedEntities,
      updateLinkedEntitiesTotalCount
    ]
  );

  const linkEntitiesToOrg = async (uuids = [], bpOrgUuid = "") => {
    const promises = uuids.map(async uuid => [
      await Entities.createBpOrgLink(uuid, bpOrgUuid)
    ]);

    try {
      await Promise.all(promises);

      fetchLinkedEntities();
      fetchEntities();
    } catch (error) {
      logger.error(error);
    }
  };

  // Side Sheet
  const fetchBpUsers = useCallback(
    async (lookUpString = undefined) => {
      if (!institutionUuid) {
        return [];
      }

      try {
        const response = await Entities.bpUsersGetAll(
          institutionUuid,
          lookUpString
        );
        updateBpUsers(response?.data || []);
        return response?.data || [];
      } catch (response) {
        updateBpUsers([]);
        return [];
      }
    },
    [institutionUuid, updateBpUsers]
  );

  useEffect(() => {
    updateEntityData(entityData);
  }, [entityData, updateEntityData]);

  useEffect(() => {
    updateEmailOptions(createEmailOptions(bpUsers));
  }, [bpUsers, updateEmailOptions]);

  const handleEntityNameInputChange = onChangeEvent =>
    handleEntityNameInputChangeInternal(
      onChangeEvent,
      updateDropdownListItems,
      entityData
    );

  return {
    ...bpLinksContext,
    fetchEntities,
    fetchLinkedEntities,
    linkEntitiesToOrg,
    // Side Sheet
    bpUsers,
    fetchBpUsers,
    institutionData,
    fetchInstitutionData,
    handleEntityNameInputChange
  };
}

function BPLinksProvider({ children, defaultValue = {} }) {
  const [currentLinkedEntities, setCurrentLinkedEntities] = useState([]);
  const [linkedEntitiesTotalCount, updateLinkedEntitiesTotalCount] =
    useState(0);
  const [dropdownListItems, setDropdownListItems] = useState([]);
  const [entityUuid, setEntityUuid] = useState("");
  const [bpOrganizationUuid, setBpOrganizationUuid] = useState("");
  const [institutionUuid, setInstitutionUuid] = useState("");
  // Side Sheet
  const [bpUsers, setBpUsers] = useState(
    defaultValue?.bpUsers || defaults.bpUsers
  );
  const [emailOptions, setEmailOptions] = useState(
    defaultValue?.emailOptions || defaults.emailOptions
  );
  const [bpOrgUuIdToLink, setBpOrgUuidToLink] = useState(
    defaultValue?.bpOrgUuIdToLink || defaults.bpOrgUuIdToLink
  );
  const [signUpData, setSignUpData] = useState(defaults.signUpData);
  const [linkButtonDisabled, setLinkButtonDisabled] = useState(
    defaultValue?.linkButtonDisabled !== undefined
      ? defaultValue.linkButtonDisabled
      : defaults.linkButtonDisabled
  );
  const [userFeedbackKey, setUserFeedbackKey] = useState("");
  const [isLoading, setIsLoading] = useState(defaultValue?.isLoading || false);

  const updateIsLoading = useCallback(value => {
    setIsLoading(value);
  }, []);

  const updateUserFeedbackKey = useCallback(value => {
    setUserFeedbackKey(value);
  }, []);

  const updateEmailOptions = useCallback(value => {
    setEmailOptions(value);
  }, []);

  const updateBpOrgUuidToLink = useCallback(value => {
    setBpOrgUuidToLink(value);
  }, []);

  const updateSignUpData = useCallback(value => {
    setSignUpData(value);
  }, []);

  const updateDropdownListItems = useCallback(value => {
    setDropdownListItems(value);
  }, []);

  const updateEntityData = useCallback(entity => {
    setEntityUuid(entity?.uuid);
    setInstitutionUuid(entity?.institution_uuid);
    setBpOrganizationUuid(entity?.bp_organization_uuid);
  }, []);

  const updateCurrentLinkedEntities = useCallback(value => {
    setCurrentLinkedEntities(value);
  }, []);

  // Side Sheet
  const updateLinkButtonDisabled = useCallback(value => {
    setLinkButtonDisabled(value);
  }, []);

  const updateBpUsers = useCallback(value => {
    setBpUsers(value);
  }, []);

  const resetBpSideSheetData = useCallback(() => {
    setBpUsers(defaultValue?.bpUsers || defaults.bpUsers);
    setEmailOptions(defaultValue?.emailOptions || defaults.emailOptions);
    setBpOrgUuidToLink(defaults.bpOrgUuIdToLink);
    setSignUpData(defaults.signUpData);
    setLinkButtonDisabled(
      defaultValue?.linkButtonDisabled !== undefined
        ? defaultValue.linkButtonDisabled
        : defaults.linkButtonDisabled
    );
  }, [
    defaultValue?.bpUsers,
    defaultValue?.emailOptions,
    defaultValue.linkButtonDisabled
  ]);

  const userFeedback = useMemo(() => {
    if (!userFeedbackKey || userFeedbackEnum[userFeedbackKey] === undefined) {
      return null;
    }

    return {
      ...userFeedbackEnum[userFeedbackKey]
    };
  }, [userFeedbackKey]);

  useEffect(
    () =>
      function cleanup() {
        resetBpSideSheetData();
      },
    [resetBpSideSheetData]
  );

  const value = useMemo(
    () => ({
      bpOrganizationUuid,
      entityUuid,
      institutionUuid,
      dropdownListItems,
      currentLinkedEntities,
      linkedEntitiesTotalCount,
      updateEntityData,
      updateCurrentLinkedEntities,
      updateLinkedEntitiesTotalCount,
      updateDropdownListItems,
      // Side Sheet
      bpUsers,
      emailOptions,
      bpOrgUuIdToLink,
      signUpData,
      linkButtonDisabled,
      userFeedback,
      updateEmailOptions,
      updateBpOrgUuidToLink,
      updateSignUpData,
      updateBpUsers,
      updateLinkButtonDisabled,
      updateUserFeedbackKey,
      resetBpSideSheetData,
      isLoading,
      updateIsLoading
    }),
    [
      bpOrganizationUuid,
      entityUuid,
      institutionUuid,
      dropdownListItems,
      currentLinkedEntities,
      linkedEntitiesTotalCount,
      updateEntityData,
      updateCurrentLinkedEntities,
      updateLinkedEntitiesTotalCount,
      updateDropdownListItems,
      // Side Sheet
      bpUsers,
      emailOptions,
      bpOrgUuIdToLink,
      signUpData,
      linkButtonDisabled,
      userFeedback,
      updateEmailOptions,
      updateBpOrgUuidToLink,
      updateSignUpData,
      updateBpUsers,
      updateLinkButtonDisabled,
      updateUserFeedbackKey,
      resetBpSideSheetData,
      isLoading,
      updateIsLoading
    ]
  );

  useEffect(() => {
    updateEmailOptions(createEmailOptions(bpUsers));
  }, [bpUsers, updateEmailOptions]);

  return (
    <BPLinksContext.Provider value={value}>{children}</BPLinksContext.Provider>
  );
}

export { useBPLinksContext, BPLinksProvider };

BPLinksProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  defaultValue: PropTypes.shape({
    // Side Sheet
    bpUsers: PropTypes.array,
    linkButtonDisabled: PropTypes.bool,
    emailOptions: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
          .isRequired,
        value: PropTypes.string.isRequired
      })
    )
  })
};
