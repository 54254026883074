import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HighFrictionDialog, IconButton } from "@bafsllc/ui-shared";
import { Entities } from "../../../../services/ApiLib";
import { isBafs } from "../../../../services/Auth";

function BpAccessBlock() {
  const dispatch = useDispatch();
  const { asset, institution, bpAccessControls } = useSelector(
    ({ AssetManagementReducer }) => AssetManagementReducer
  );
  const [showModal, setShowModal] = useState(false);

  const isBafsUser = isBafs();
  const hasBorrowerPortalService =
    institution?.borrower_portal_service || false;

  const getBpAccessControls = useCallback(() => {
    if (asset?.uuid && (hasBorrowerPortalService || isBafsUser)) {
      Entities.bpAccessControlsGetByRecordUuid(asset?.uuid).then(response => {
        dispatch({
          type: "ASSET_MANAGEMENT_BP_ACCESS_CONTROLS",
          bpAccessControls: response
        });
      });
    }
  }, [dispatch, asset?.uuid, hasBorrowerPortalService, isBafsUser]);

  const updateBpAccessControlsEditableByBorrower = () => {
    Entities.bpAccessControlsUpdateEditableByBorrower(
      bpAccessControls?.uuid,
      asset?.uuid,
      institution?.uuid,
      false
    ).then(() => {
      setShowModal(false);
      getBpAccessControls();
    });
  };

  useEffect(() => {
    getBpAccessControls();
  }, [getBpAccessControls]);

  if (!hasBorrowerPortalService && !isBafsUser) {
    return null;
  }

  return (
    <>
      <div className="flex items-center justify-end">
        <IconButton
          disabled={!bpAccessControls?.editable_by_borrower}
          message={{ id: "BORROWER_EDIT_ASSET" }}
          iconLeft={{
            icon: bpAccessControls?.editable_by_borrower ? "unlock" : "lock"
          }}
          size="tiny"
          variant="secondary"
          onClick={() => {
            setShowModal(true);
          }}
        />
      </div>

      {showModal && (
        <HighFrictionDialog
          affirmative={{ id: "CONFIRM" }}
          negative={{ id: "CANCEL" }}
          header={{ id: "BP_ORGANIZATION" }}
          confirmationMessages={[
            { message: { id: "BORROWER_EDIT_CONF_MSG" } },
            { message: { id: "FRICTION_DIALOG_ENTER_MESSAGE" } }
          ]}
          onHidden={() => {}}
          onDismiss={dismissVariant => {
            if (dismissVariant === "affirmative") {
              updateBpAccessControlsEditableByBorrower();
            }
          }}
        />
      )}
    </>
  );
}

export default BpAccessBlock;
