/* eslint-disable camelcase */
import React from "react";
import moment from "moment";
import { Form } from "semantic-ui-react";
import { SelectMenu } from "@bafsllc/ui-shared";
import PropTypes from "prop-types";
import { DateInput } from "../../../../../../CustomFormElements";
import {
  businessTypes,
  businessOperatingStatuses,
  loanReviewTypes,
  taxReportFormIndivid,
  taxReportingForms,
  fyeOptions
} from "../../../../../../../services/FormElements/components/entityModalOptions";
import SemanticSearch from "../../../../../../SemanticSearch";
import {
  IndustrySearchKeyNAICs,
  NAICCodes,
  usStatesHash
} from "../../../../../../../services/FormElements";
import IdentificationNumber from "../../../../../../CustomFormElements/components/IdentificationNumberInput/identification-number";
import useEntity from "../../../hooks/useEntity/useEntity";

function OtherEntityInfo({ add }) {
  const {
    annual_revenue,
    business_operating_status,
    business_start_date,
    company_name,
    county_of_reg,
    entity_type,
    fiscal_year_end,
    handleChangeNumber,
    handleInputChange,
    is_operating_entity,
    loan_officer_uuid,
    loan_review_type,
    loanOfficers,
    member_since,
    naics_code,
    state_of_reg,
    tax_reporting_form,
    tin
  } = useEntity();

  return (
    <Form>
      <Form.Group size="large">
        <Form.Input
          name="company_name"
          label="Entity Name"
          width={9}
          value={company_name || ""}
          onChange={handleInputChange}
        />
        <Form.Select
          name="loan_officer_uuid"
          label="Loan Officer"
          onChange={handleInputChange}
          options={loanOfficers || []}
          value={loan_officer_uuid || ""}
          width={9}
        />
      </Form.Group>
      <Form.Group>
        {add ? (
          ""
        ) : (
          <Form.Select
            name="entity_type"
            label="Entity Type"
            options={businessTypes}
            width={6}
            value={entity_type || ""}
            onChange={handleInputChange}
          />
        )}

        <SelectMenu
          labelText="State of Registration"
          inputId="state-of-registration-field"
          className={`${add ? "nine" : "six"} wide field`}
          name="state_of_reg"
          dataMap={usStatesHash}
          value={state_of_reg || ""}
          onChange={data => {
            handleInputChange("", {
              name: "state_of_reg",
              value: data?.value || ""
            });
          }}
        />

        <Form.Input
          // {TODO Change to select when we have all the counties}
          name="county_of_reg"
          label="County of Registration"
          width={add ? 9 : 6}
          value={county_of_reg || ""}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Field width={6}>
          <label htmlFor="industry_search">Industry Type Search:</label>
          <SemanticSearch
            id="industry_search"
            name="industry_type"
            width={9}
            searchableData={IndustrySearchKeyNAICs}
            onResultSelect={(e, { result }) => {
              const code = parseInt(result.value, 10);
              handleInputChange(e, { name: "naics_code", value: code });
              handleInputChange(e, {
                name: "industry_type",
                value: NAICCodes[code]
              });
            }}
          />
        </Form.Field>
        <Form.Input
          width={3}
          label="NAICS Code"
          value={naics_code || ""}
          readOnly
        />
        <Form.Input
          width={3}
          label="Industry Type"
          value={naics_code ? NAICCodes[naics_code] : ""}
          readOnly
        />
        <Form.Checkbox
          style={{ paddingTop: "2.3em" }}
          name="is_operating_entity"
          label="Is Operating Entity"
          checked={is_operating_entity === null ? false : is_operating_entity}
          onChange={(e, { name, checked }) =>
            handleInputChange(e, { name, value: checked })
          }
        />
      </Form.Group>
      <Form.Group>
        <IdentificationNumber
          id="tin"
          label="TIN"
          name="tin"
          numberType="tin"
          onChange={handleInputChange}
          value={tin || ""}
          width={6}
        />
        <Form.Select
          name="loan_review_type"
          label="Loan Review Type"
          options={loanReviewTypes}
          width={6}
          value={loan_review_type || ""}
          onChange={handleInputChange}
        />
        <Form.Input
          label="Annual Revenue"
          name="annual_revenue"
          width={6}
          value={
            annual_revenue
              ? annual_revenue
                  .toString()
                  .replace(/(\s)/g, "")
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
              : ""
          }
          onChange={handleChangeNumber}
        />
      </Form.Group>
      <Form.Group>
        <Form.Select
          name="tax_reporting_form"
          label="Tax Reporting Form"
          options={
            entity_type === "Individual"
              ? taxReportFormIndivid
              : taxReportingForms
          }
          width={6}
          value={tax_reporting_form || ""}
          onChange={handleInputChange}
        />
        <DateInput
          asForm
          label="Business Start Date"
          name="business_start_date"
          width={6}
          value={business_start_date || ""}
          onChange={handleInputChange}
        />
        <Form.Input
          label="Years in Business"
          name="years_in_business"
          disabled
          width={6}
          value={
            business_start_date
              ? moment(new Date()).format("YYYY") -
                moment(business_start_date).format("YYYY")
              : ""
          }
        />
      </Form.Group>
      <Form.Group>
        <Form.Select
          label="Fiscal Year End"
          name="fiscal_year_end"
          onChange={handleInputChange}
          options={fyeOptions}
          value={fiscal_year_end || ""}
          width={6}
        />
        <Form.Select
          name="business_operating_status"
          label="Business Operating Status"
          width={6}
          options={businessOperatingStatuses}
          value={business_operating_status || ""}
          onChange={handleInputChange}
        />
        <DateInput
          asForm
          name="member_since"
          label="Client Since"
          width={6}
          value={member_since || ""}
          onChange={handleInputChange}
        />
      </Form.Group>
    </Form>
  );
}

OtherEntityInfo.propTypes = {
  add: PropTypes.bool.isRequired
};

export default OtherEntityInfo;
