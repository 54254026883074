import React from "react";
import { useIntl } from "react-intl";
import type {
  IconBoundedBothProps,
  IconBoundedLeftProps,
  IconBoundedRightProps,
  IconBoundedSharedProps
} from "../../../custom/collections/icon-bounded/icon-bounded";
import { IconBounded } from "../../../custom/collections/icon-bounded/icon-bounded";
import { IconClassNames } from "../../../types/elements";
import { MessageProps } from "../../../types/text";
import { getMessage } from "../../../util/text/message";

/**
 * A fomantic-ui label that can optionally contain an icon. Also supports a
 * label that can be "closed" with a close icon.
 * @see {@link @blast-client/ui.Label} for the Label that is applied to a form
 * element.
 */
export function Label({
  color = "gray",
  containerClasses = "",
  icon,
  onClick,
  ...props
}: LabelProps) {
  const { formatMessage } = useIntl();
  const rootClassNames: string[] = [containerClasses, "ui ui ui", color];

  if ((props as LabelCircularProps).variant) {
    rootClassNames.push((props as LabelCircularProps).variant as string);
  }

  const isClosable = !!(props as LabelCloseProps).onClose;
  let isIconLeft = true;
  if (!isClosable) {
    isIconLeft = (props as LabelRightProps).iconPosition !== "right";
    if (icon && (props as LabelRightProps).iconPosition) {
      rootClassNames.push(`${(props as LabelRightProps).iconPosition} icon`);
    }
  } else {
    isIconLeft = true;
  }

  rootClassNames.push("label");

  function handleOnClick(evt: React.MouseEvent<HTMLElement, MouseEvent>) {
    if (onClick) {
      onClick(evt);
      return;
    }

    evt.stopPropagation();
  }

  const message = isMessageProps(props)
    ? getMessage(props.message, formatMessage)
    : undefined;

  let iconLeft: IconBoundedBothProps["iconLeft"] | undefined;
  let iconRight: IconBoundedBothProps["iconRight"] | undefined;
  if (icon) {
    isIconLeft ? (iconLeft = { icon }) : (iconRight = { icon });
  }

  if (isClosable) {
    iconRight = {
      ariaLabel: { id: "CLOSE" },
      icon: "close",
      onIconSelect: (props as LabelCloseProps).onClose
    };
  }

  let iconBoundedProps: IconBoundedSharedProps | null = null;
  if (iconLeft) {
    iconBoundedProps = iconBoundedProps ?? {};
    (iconBoundedProps as IconBoundedLeftProps).iconLeft = iconLeft;
  }

  if (iconRight) {
    iconBoundedProps = iconBoundedProps ?? {};
    (iconBoundedProps as IconBoundedRightProps).iconRight = iconRight;
  }

  return (
    <div
      aria-label={isAriaProps(props) ? props.ariaLabel : message}
      className={rootClassNames.join(" ")}
      onClick={handleOnClick}
    >
      {iconBoundedProps ? (
        <IconBounded {...(iconBoundedProps as IconBoundedBothProps)}>
          {message}
        </IconBounded>
      ) : (
        message
      )}
    </div>
  );
}

export type LabelProps = (LabelAriaProps | LabelMessageProps) &
  (LabelCloseProps | LabelRightProps) &
  (LabelColorProps | LabelCircularProps) &
  LabelContainerProps;

export interface LabelBaseProps {
  /** The icon to display. */
  icon?: IconClassNames;
  /** If no onClick handler is provided then the event from clicking on the
   * label will have `stopPropagation` invoked. */
  onClick?: React.MouseEventHandler;
}

export interface LabelAriaProps extends Omit<LabelBaseProps, "message"> {
  /** If no message is provided (an icon only label) then a label for the icon
   * that will not be visible. */
  ariaLabel: string;
}

export interface LabelCircularProps extends Omit<LabelBaseProps, "color"> {
  /**
   * The colors available for labels. Matches
   * libs/style/src/fomantic-styles/themes/bafs/elements/label.variables. Note
   * that "salmon" only applies to `circular` labels.
   */
  color?: typeof labelCircularColors[number];
  /** If provided a "close" button will be displayed. When the close button is
   * selected onClose will be invoked. */
  variant?: "circular";
}

export interface LabelContainerProps extends LabelBaseProps {
  containerClasses?: string;
}

export interface LabelCloseProps extends LabelBaseProps {
  /** If provided a "close" button will be displayed. When the close button is
   * selected onClose will be invoked. */
  onClose?: (
    evt: React.MouseEvent | React.KeyboardEvent | MouseEvent | KeyboardEvent
  ) => void;
}

export interface LabelColorProps extends LabelBaseProps {
  /**
   * The colors available for labels. Matches
   * libs/style/src/fomantic-styles/themes/bafs/elements/label.variables. Note
   * that "salmon" only applies to `circular` labels.
   */
  color?: typeof labelColors[number];
}

export interface LabelMessageProps extends LabelBaseProps {
  /** The text message to display in the button. If the value of `id` doesn't
   * exist then the value of `defaultMessage` will be displayed. */
  message: MessageProps;
}

export interface PageLabelsProps extends LabelMessageProps, LabelColorProps {}

export interface LabelRightProps extends LabelBaseProps {
  /* If not provided the icon will be to the left of any text, when provided the
  icon is to the right. */
  iconPosition?: "right";
}

/**
 * The colors available for labels. Matches
 * libs/style/src/fomantic-styles/themes/bafs/elements/label.variables. Note
 * that "salmon" is only found in `labelCircularColors`.
 */
export const labelColors = ["blue", "gray", "green", "red", "yellow"] as const;

/**
 * The colors available for circular labels. Note that "salmon" only applies to
 * `circular` labels.
 */
export const labelCircularColors = [...labelColors, "salmon"] as const;

function isAriaProps(props: {}): props is LabelAriaProps {
  return "ariaLabel" in props;
}

function isMessageProps(props: {}): props is LabelMessageProps {
  return "message" in props;
}
