import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Grid, Header as SemanticHeader } from "semantic-ui-react";

function GovtGuarLayout({ children, heading }) {
  const nodes = React.Children.toArray(children);
  return (
    <Grid padded>
      <Grid.Row columns="2">
        <Grid.Column width={3}>
          <Heading as="h3" dividing>
            {heading}
          </Heading>
        </Grid.Column>
        <Grid.Column textAlign="right" width={13}>
          {nodes[0]}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>{nodes[1]}</Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>{nodes.slice(2)}</Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

const Heading = styled(SemanticHeader)`
  display: flex;
  align-items: flex-end;
`;

GovtGuarLayout.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.node
};

export default GovtGuarLayout;
