import React from "react";
import PropTypes from "prop-types";
import { prettyDate } from "../../../../../../../../../../../services/DateTime";

function PrettyDateCell({ value = "" }) {
  if (value) {
    return <span>{prettyDate(value)}</span>;
  }

  return null;
}

export default PrettyDateCell;

PrettyDateCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape()
  }),
  value: PropTypes.string
};
