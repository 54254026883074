import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import { Button, Menu } from "semantic-ui-react";
import { connect } from "react-redux";

import { WarningModal } from "../../../../../../../../components/Modals";
import { OptOut } from "../../../../../../../../services/ApiLib";
import logger from "../../../../../../../../services/logger";

const menuItems = [
  {
    key: "Borrowers",
    href: uuid => `/los/${uuid}/phases/app_details/borrowers`
  },
  {
    key: "Credit Request",
    href: uuid => `/los/${uuid}/phases/app_details/credit_request`
  },
  {
    key: "Guarantors",
    href: uuid => `/los/${uuid}/phases/app_details/guarantors`
  },
  {
    key: "Collateral",
    href: uuid => `/los/${uuid}/phases/app_details/collateral`
  },
  {
    key: "Related Details",
    href: uuid => `/los/${uuid}/phases/app_details/related_details`
  }
];

export class MenuBarObj extends React.Component {
  constructor(props) {
    super(props);

    this.setOptOut = this.setOptOut.bind(this);
    this.setReadOnly = this.setReadOnly.bind(this);
    this.setWarningModal = this.setWarningModal.bind(this);
    this.addOptOut = this.addOptOut.bind(this);
    this.matchUserAndLoanOptOuts = this.matchUserAndLoanOptOuts.bind(this);
  }

  componentDidMount() {
    const { loanAppUuid } = this.props;
    OptOut.get(this.matchUserAndLoanOptOuts, logger.error, loanAppUuid);
  }

  setOptOut(optOut = true) {
    const { dispatch } = this.props;

    dispatch({
      type: "LOS_PHASES_SET_OPT_OUT",
      optOut
    });
    if (optOut) {
      this.setReadOnly(false);
    }
  }

  setWarningModal(warningModal) {
    const { dispatch } = this.props;
    dispatch({
      type: "LOS_PHASES_SET_WARNING_MODAL",
      warningModal
    });
  }

  setReadOnly(readOnly) {
    const { dispatch } = this.props;
    dispatch({
      type: "LOS_PHASES_SET_READ_ONLY",
      readOnly
    });
  }

  matchUserAndLoanOptOuts({ data }) {
    // determine if the current user/loan app combination has opted out of
    // edit warning modals
    const { userUuid } = this.props;
    let optOut = false;
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i += 1) {
        const optOutData = data[i];
        if (optOutData.user_uuid === userUuid) {
          optOut = true;
          break;
        }
      }
    }
    this.setOptOut(optOut);
  }

  addOptOut(onSuccess = () => {}) {
    const { loanAppUuid } = this.props;
    OptOut.add(
      () => {
        this.setOptOut(true);
        onSuccess();
      },
      logger.error,
      {
        parent_uuid: loanAppUuid
      }
    );
  }

  render() {
    const { loanAppUuid, readOnly, readOnlyPhase, optOut, warningModal } =
      this.props;
    return (
      <div>
        <WarningModal
          parentModalToggle={warningModal}
          parentModalClose={() => this.setWarningModal(false)}
          parentModalSubmit={(optOutSet = false) => {
            this.setReadOnly(false);
            if (optOutSet) {
              // if opt out is set, wait for the request to go through before
              // closing modal
              this.addOptOut(() => this.setWarningModal(false));
            } else {
              // if no opt out, just close the modal after setting read only
              this.setWarningModal(false);
            }
          }}
        />
        <Menu pointing secondary style={{ marginBottom: "1rem" }}>
          {menuItems.map(item => (
            <Menu.Item
              as={NavLink}
              exact={false}
              to={item.href(loanAppUuid)}
              key={item.key}
              link
              name={item.key}
              // read only should be re-instated on tab change unless the user
              // has explicitly opted out
              onClick={() => this.setReadOnly(!optOut)}
            />
          ))}
          {readOnly && !readOnlyPhase && (
            <Menu.Item position="right">
              <Button
                circular
                color="green"
                icon="pencil"
                onClick={() => this.setWarningModal(true)}
              />
            </Menu.Item>
          )}
        </Menu>
      </div>
    );
  }
}

MenuBarObj.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loanAppUuid: PropTypes.string.isRequired,
  optOut: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  readOnlyPhase: PropTypes.bool.isRequired,
  userUuid: PropTypes.string.isRequired,
  warningModal: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  optOut: state.LosPhasesReducer.optOut,
  readOnly: state.LosPhasesReducer.readOnly,
  readOnlyPhase: state.LosPhasesReducer.readOnlyPhase,
  warningModal: state.LosPhasesReducer.warningModal,
  userUuid: state.auth.userUuid
});

const MenuBar = connect(mapStateToProps)(MenuBarObj);

export default MenuBar;
