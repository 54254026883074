import { DocumentEditorProvider } from "./document-editor-provider";
import { EditorConfig } from "./types";

export function DocumentEditor({ config, error, ooUrl }: DocumentEditorProps) {
  if (error) {
    return <p>{error}</p>;
  }

  if (!config) {
    return <p>Loading...</p>;
  }

  return (
    <DocumentEditorProvider
      editorId="document-editor"
      scriptUrl={`${ooUrl}/web-apps/apps/api/documents/api.js`}
      config={config}
    />
  );
}

export interface DocumentEditorProps {
  config: EditorConfig | null;
  error?: string | null;
  ooUrl: string;
}
