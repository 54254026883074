import React from "react";
import { Button, Checkbox, Icon, Modal, Table } from "semantic-ui-react";
import PropTypes from "prop-types";
import { ConcatName } from "../../../../../../../../services/Entities";

function BorrowersModal(props) {
  const {
    borrowers,
    entities,
    onCancel,
    onDelete,
    onSelect,
    open,
    selectedBorrower
  } = props;
  const tableRows = borrowers.map(b => (
    <Table.Row key={b.uuid}>
      <Table.Cell>
        <Checkbox
          checked={selectedBorrower === b.uuid}
          onClick={() => onSelect(b.uuid)}
        />
      </Table.Cell>
      <Table.Cell>
        {ConcatName(entities[b.child_uuid] || {})}
        {selectedBorrower !== b.uuid ? (
          <Button
            basic
            circular
            icon
            floated="right"
            onClick={() => onDelete(b.uuid)}
          >
            <Icon name="times" color="green" />
          </Button>
        ) : (
          ""
        )}
      </Table.Cell>
    </Table.Row>
  ));
  return (
    <Modal open={open} size="small">
      <Modal.Header>Manage Borrowers</Modal.Header>
      <Modal.Content>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Primary</Table.HeaderCell>
              <Table.HeaderCell>Entity</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{tableRows}</Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button id="cancelButton" color="grey" onClick={onCancel}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

BorrowersModal.propTypes = {
  borrowers: PropTypes.arrayOf(PropTypes.object).isRequired,
  entities: PropTypes.objectOf(PropTypes.object).isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedBorrower: PropTypes.string.isRequired
};

export default BorrowersModal;
