import { connect } from "react-redux";
import Payments from "./Payments";
import Constant from "../../../../../../../../services/Constants/strings";

const mapStateToProps = (state, { loan, isMonthEnd }) => {
  const { paymentSchedules } = state.PaymentScheduleReducer;
  let nextScheduledPaymentAmount;
  let activePayments;
  let paymentFrequency;
  if (paymentSchedules.length) {
    activePayments = paymentSchedules.filter(item => item.status === "Active");
    if (activePayments.length === 1) {
      nextScheduledPaymentAmount = activePayments[0].next_payment_amount;
      paymentFrequency = `${activePayments[0].payment_frequency} ${activePayments[0].payment_frequency_type}`;
    }
  }

  return {
    loan,
    nextScheduledPaymentAmount: isMonthEnd
      ? Constant.NotApplicable
      : nextScheduledPaymentAmount,
    paymentFrequency: isMonthEnd ? Constant.NotApplicable : paymentFrequency
  };
};

export default connect(mapStateToProps)(Payments);
