import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";

export function FilterChipObj(props) {
  return (
    <div>
      <strong>{`${props.filterKey}: `}</strong>
      {` ${props.filterValue} `}
      <Button
        onClick={() => props.callbackFilterRemove(props.filterKey, "")}
        size="mini"
      >
        x
      </Button>
    </div>
  );
}

FilterChipObj.propTypes = {
  callbackFilterRemove: PropTypes.func.isRequired,
  filterKey: PropTypes.string.isRequired,
  filterValue: PropTypes.string.isRequired
};

const FilterChip = connect()(FilterChipObj);

export default FilterChip;
