import { useState } from "react";
import _ from "lodash";
import { SortValue } from "../../../../types/collections";

/**
 * This is component responsible of handling the state of the sort and the checkbox list
 * shouldn't be used outside SortingTable context
 */
export function useSortingTable() {
  const [checkList, onChecklistChanged] = useState<string[]>([]);
  const [sortState, onSortStateChanged] = useState<{
    key: string;
    sort: SortValue;
  }>({
    key: "none",
    sort: "none"
  });

  /**
   * This function should NOT be used with any table data retrieved from an API unless that API is unable to sort for us
   */
  const frontendSort = (list: any[]) => {
    let tableList = list;
    if (sortState.sort === "none") {
      tableList = list;
    } else {
      const sorted = _.orderBy(tableList, [sortState.key], [sortState.sort]);
      tableList = sorted;
    }
    return tableList;
  };

  return {
    sortState,
    onSortStateChanged,
    checkList,
    onChecklistChanged,
    frontendSort
  };
}
