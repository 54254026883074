import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { DocApprovalModal } from "../../../../components/Modals";
import logger from "../../../../services/logger";

function TicklerDocApprovalModal({
  dispatchToBlast,
  cureModalTickler,
  cureModalOpen,
  cureModalDocDetails,
  cureModalUsers,
  unsetCureModalData
}) {
  const [commentValue, setCommentValue] = useState("");

  const handleClose = useCallback(
    e => {
      logger.debug("TicklerDocApprovalModal: decline e=", e);

      dispatchToBlast({
        content: { status: "DECLINE" },
        type: "DOC_MGMT_TICKLERS_TOGGLE_CURE_MODAL_DISMISSED"
      });

      unsetCureModalData(e);
    },
    [dispatchToBlast, unsetCureModalData]
  );

  const handleSave = useCallback(
    response => {
      // TODO fix up cure/doc approval modal in Commotion
      // https://github.com/bafsllc/clearwater/issues/7590
      logger.debug("TicklerDocApprovalModal: save response=", response);

      dispatchToBlast({
        content: { status: response.status >= 400 ? "ERROR" : "APPROVE" },
        type: "DOC_MGMT_TICKLERS_TOGGLE_CURE_MODAL_DISMISSED"
      });

      unsetCureModalData(response);
    },
    [dispatchToBlast, unsetCureModalData]
  );

  return (
    cureModalOpen &&
    cureModalTickler && (
      <DocApprovalModal
        approvalType="Tickler"
        commentAdd={() => {
          dispatchToBlast({
            type: "DOC_MGMT_TICKLERS_POST_MESSAGE",
            content: commentValue,
            parentUuid: cureModalTickler.uuid,
            institutionUuid: cureModalDocDetails.institutionUuid
          });
        }}
        commentFormField={commentValue}
        commentOnChange={e => {
          setCommentValue(e.value || "");
        }}
        dimmer
        docDetails={cureModalDocDetails || {}}
        messages={cureModalTickler.messages}
        messagesNames={cureModalUsers}
        onClose={handleClose}
        onSave={handleSave}
        open
        ticklerDetails={cureModalTickler}
      />
    )
  );
}

TicklerDocApprovalModal.propTypes = {
  dispatchToBlast: PropTypes.func,
  cureModalTickler: PropTypes.shape({
    uuid: PropTypes.string,
    messages: PropTypes.arrayOf(PropTypes.object)
  }),
  cureModalOpen: PropTypes.bool,
  cureModalDocDetails: PropTypes.shape({
    institutionUuid: PropTypes.string
  }),
  cureModalUsers: PropTypes.PropTypes.shape({}),
  unsetCureModalData: PropTypes.func
};

export default TicklerDocApprovalModal;
