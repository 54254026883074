import {
  AddPanel,
  IconButton,
  Label,
  PaginationView,
  SortingTable,
  ConfirmationDialog,
  useSortingTable,
  usePagination
} from "@bafsllc/ui-shared";
import { PropTypes } from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Segment } from "semantic-ui-react";
import CreditRatings from "../../../../services/ApiLib/services/CreditRatings";
import { CREDIT_ANALYSIS_ACTIONS } from "../../reducer";
import { entityDataProp, loanDataProp, onError } from "../../utils";
import getCreditAnalysisHeaders from "./headers";

export default function CreditAnalysisTable({
  onOpenSidesheet,
  entity,
  loan,
  type,
  showCreateAnalysisButton
}) {
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedCreditAnalysisToDelete, setSelectedCreditAnalysisToDelete] =
    useState(null);
  const { sortState, onSortStateChanged } = useSortingTable();
  const { currentPage, pageSize, onPageChange, onPageSizeChange } =
    usePagination({ defaultPageSize: 6 });

  const { creditAnalysisList, selectedCreditAnalysisGlobal } = useSelector(
    state => ({
      creditAnalysisList:
        state.CreditAnalysisManagementReducer?.creditAnalysisList,
      selectedCreditAnalysisGlobal:
        state.CreditAnalysisManagementReducer?.selectedCreditAnalysis
    })
  );

  const creditAnalysisTablePopulate = useCallback(async () => {
    let creditAnalysisData;
    try {
      const { data, metaData } = await CreditRatings.readAllCreditAnalysis(
        type,
        loan.uuid,
        pageSize * (currentPage - 1),
        pageSize
      );

      creditAnalysisData = data.map(analysis => ({
        ...analysis,
        entity,
        loan: [].concat(loan).find(item => item.uuid === analysis.loan_uuid)
      }));
      dispatch({
        type: CREDIT_ANALYSIS_ACTIONS.list,
        data: creditAnalysisData,
        metaData
      });
    } catch (err) {
      onError(err);
    }
  }, [dispatch, loan, entity, type, currentPage, pageSize]);

  useEffect(() => {
    creditAnalysisTablePopulate();
  }, [creditAnalysisTablePopulate, selectedCreditAnalysisGlobal]);

  const buildCreditAnalysisForUI = useCallback(
    ({ creditAnalysis, mode }) => {
      const singleRiskScaleItem =
        creditAnalysis?.single_risk_scale?.single_risk_scale_items?.find(
          scaleItem => scaleItem.uuid === creditAnalysis.scale_item_uuid
        );
      return {
        ...creditAnalysis,
        entity,
        loan: []
          .concat(loan)
          .find(item => item.uuid === creditAnalysis.loan_uuid),
        single_risk_scale_item: singleRiskScaleItem,
        mode
      };
    },
    [entity, loan]
  );

  const handleDeleteCreditAnalysis = async ({ creditAnalysis }) => {
    try {
      await CreditRatings.deleteOne(creditAnalysis.uuid);
      dispatch({
        type: CREDIT_ANALYSIS_ACTIONS.delete,
        data: creditAnalysis.uuid,
        metaData: {
          ...creditAnalysisList.metaData,
          count: creditAnalysisList.metaData.count - 1
        }
      });
    } catch (error) {
      onError(error);
    }
    setSelectedCreditAnalysisToDelete(null);
  };

  const handleOpenDeleteModal = ({ creditAnalysis }) => {
    setOpenDeleteModal(true);
    setSelectedCreditAnalysisToDelete(creditAnalysis);
  };

  const handleActionSelect = useCallback(
    (event, creditAnalysis) => {
      switch (event.target.getAttribute("value")) {
        case "Edit":
          onOpenSidesheet({
            selectedCreditAnalysis: buildCreditAnalysisForUI({
              creditAnalysis,
              mode: "edit"
            })
          });
          break;
        case "Delete":
          handleOpenDeleteModal({ creditAnalysis });
          break;
        case "View":
          onOpenSidesheet({
            selectedCreditAnalysis: buildCreditAnalysisForUI({
              creditAnalysis,
              mode: "view"
            })
          });
          break;
        default:
          // TODO: Default handler
          break;
      }
    },
    [buildCreditAnalysisForUI, onOpenSidesheet]
  );

  const headers = useMemo(
    () =>
      getCreditAnalysisHeaders({
        type,
        handleActionSelect
      }),
    [handleActionSelect, type]
  );

  return (
    <Segment.Group>
      <Segment>
        <div
          className="page-region-titles"
          style={{ display: "flex", alignItems: "center" }}
        >
          <h2
            className="page-region-title"
            style={{ margin: "0px 10px 0px 0px" }}
          >
            <FormattedMessage id="CREDIT_RISK_RATING_ANALYSIS" />
          </h2>
          <Label
            color="blue"
            message={{ id: "SINGLE_RISK_RATING" }}
            style={{ marginLeft: "15px" }}
          />
          <div
            className="ui grid fluid container"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="sixteen wide column right aligned">
              {" "}
              {showCreateAnalysisButton && (
                <IconButton
                  onClick={onOpenSidesheet}
                  disabled={false}
                  message={{ id: "CREATE_NEW_ANALYSIS" }}
                  iconLeft={{ icon: "plus" }}
                />
              )}
            </div>
          </div>
        </div>
      </Segment>
      <Segment>
        {openDeleteModal && (
          <ConfirmationDialog
            affirmative={{ id: "CONFIRM" }}
            negative={{ id: "CANCEL" }}
            header={{ id: "DELETE_CREDIT_ANALYSIS_MODAL_HEADER" }}
            content={{ id: "DELETE_CREDIT_ANALYSIS_MODAL_CONTENT" }}
            onDismiss={action => {
              setOpenDeleteModal(false);
              if (action === "affirmative")
                handleDeleteCreditAnalysis({
                  creditAnalysis: selectedCreditAnalysisToDelete
                });
            }}
            hide={!openDeleteModal}
          />
        )}
        <SortingTable
          list={creditAnalysisList?.data ?? []}
          headers={headers}
          idProperty="period"
          sortState={sortState}
          onSortStateChanged={onSortStateChanged}
        />
        {creditAnalysisList?.data?.length > 0 ? (
          <PaginationView
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            pageSizeOptions={[6, 12, 24, 48, 96]}
            pageSize={pageSize}
            total={creditAnalysisList?.metaData?.count ?? 0}
            current={currentPage}
          />
        ) : (
          <AddPanel
            title={{ id: "CREDIT_ANALYSIS_ADD_PANEL_TITLE" }}
            description={{ id: "CREDIT_ANALYSIS_ADD_PANEL_DESCRIPTION" }}
          />
        )}
      </Segment>
    </Segment.Group>
  );
}

CreditAnalysisTable.propTypes = {
  onOpenSidesheet: PropTypes.func.isRequired,
  entity: entityDataProp.isRequired,
  loan: loanDataProp.isRequired,
  type: PropTypes.string.isRequired,
  showCreateAnalysisButton: PropTypes.bool.isRequired
};
