/**
 * An Array of credit rating values.
 * @returns {{ key: number; text: number; value: number }[] } The array of rating values.
 */
export default function ratings() {
  const riskRating = [];
  for (let i = 1; i <= 10; i += 1) {
    riskRating.push({ key: i, text: i, value: i });
  }
  return riskRating;
}
