/* eslint-disable camelcase */
import React from "react";
import { Form } from "semantic-ui-react";
import { creditBureaus } from "../../../../../../../services/FormElements/components/entityModalOptions";
import { DateInput } from "../../../../../../CustomFormElements";
import ratings from "../ratings/ratings";
import useEntity from "../../../hooks/useEntity/useEntity";

function OtherCreditInfo() {
  const {
    credit_bureau,
    creditScoreValue,
    duns_number,
    fico_sbss,
    handleChangeNumber,
    handleInputChange,
    next_review_date,
    previous_review_date,
    risk_rating
  } = useEntity();

  return (
    <Form>
      <Form.Group>
        <DateInput
          asForm
          width={6}
          label="Previous Review Date"
          name="previous_review_date"
          value={previous_review_date || ""}
          onChange={handleInputChange}
        />
        <DateInput
          asForm
          width={6}
          label="Next Review Date"
          value={next_review_date || ""}
          name="next_review_date"
          onChange={handleInputChange}
        />
        <Form.Select
          name="risk_rating"
          label="Borrower Rating"
          options={ratings()}
          width={6}
          value={risk_rating}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Input
          name="duns_number"
          label="Duns Number"
          width={9}
          value={duns_number || ""}
          onChange={handleInputChange}
        />
        <Form.Input
          name="fico_sbss"
          label="FICO SBSS"
          width={9}
          value={fico_sbss || ""}
          onChange={handleChangeNumber}
        />
      </Form.Group>
      <Form.Group>
        <Form.Select
          name="credit_bureau"
          label="Credit Bureau"
          options={creditBureaus}
          width={9}
          value={credit_bureau}
          onChange={handleInputChange}
        />
        <Form.Input
          key="credit_score"
          name="credit_score"
          label="Credit Score"
          width={9}
          value={creditScoreValue || ""}
          onChange={handleChangeNumber}
          disabled={!credit_bureau}
        />
      </Form.Group>
    </Form>
  );
}

export default OtherCreditInfo;
