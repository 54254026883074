import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { Form, Popup } from "semantic-ui-react";
import { isNumber } from "../../../../services/ApiLib/services/Utilities";

function PercentInput({
  onBlurCallback,
  onChange,
  name,
  label,
  scale,
  disabled,
  placeholder,
  value,
  style,
  max,
  ...props
}) {
  const labelRef = useRef();
  const [finalValue, setFinalValue] = useState(
    isNumber(Number(value)) ? Number(value) * 100 : ""
  );

  useEffect(() => {
    setFinalValue(value * 100);
  }, [value]);

  const [hitMax, setHitMax] = useState(false);
  const scalePercent = num => Number((num / 100).toFixed(6));

  const handleValueChange = values => {
    if (!isNumber(Number(values.floatValue))) {
      setFinalValue(null);
      return;
    }
    let nextValue = scalePercent(values.floatValue);
    if (nextValue > max) {
      nextValue = max;
      setHitMax(true);
    } else if (nextValue !== max) {
      setHitMax(false);
    }

    setFinalValue(nextValue * 100);
    onChange(values, {
      name,
      value: nextValue
    });
  };
  const handleFocus = () => {
    if (!isNumber(Number(value)) || Number(value) === 0) {
      setFinalValue(null);
    }
  };
  const handleBlur = e => {
    if (e.target.value === "") {
      setFinalValue(0);
    }
    if (onBlurCallback) {
      onBlurCallback(scalePercent(finalValue));
    }
  };
  return (
    <>
      <Popup open={hitMax} context={labelRef}>
        <Popup.Header>Value too large</Popup.Header>
        <Popup.Content>
          <p style={{ color: "#D24F15" }}>{`Value must be less than ${
            max * 100
          }`}</p>
        </Popup.Content>
      </Popup>
      <Form.Field
        control={NumberFormat}
        name={name}
        decimalScale={scale || 2}
        allowLeadingZeros={false}
        suffix="%"
        onFocus={handleFocus}
        onBlur={handleBlur}
        label={
          <label htmlFor={name} ref={labelRef}>
            {label}
          </label>
        }
        placeholder={disabled ? "" : placeholder || "0.00%"}
        disabled={disabled}
        value={finalValue}
        onValueChange={handleValueChange}
        style={style}
        {...props}
      />
    </>
  );
}

PercentInput.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  scale: PropTypes.number,
  style: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  max: PropTypes.number,
  placeholder: PropTypes.string,
  onBlurCallback: PropTypes.func
};

export default PercentInput;
