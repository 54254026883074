import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Header, Modal, Select } from "semantic-ui-react";

import useRelationshipFormIsValid from "../../hooks/useRelationshipFormIsValid";
import { DateInput, PercentInput } from "../../../../../CustomFormElements";
import { ConcatName } from "../../../../../../services/Entities";
import { forceValue } from "../../../../../../services/FormElements";

const votingRightsOptions = [
  { key: "y", text: "Yes", value: true },
  { key: "n", text: "No", value: false }
];

function OwnerModal(props) {
  const { form, onClose, onSave, updateForm } = props;
  const requiredFields = [
    "owner_entity_data",
    "percent_owned",
    "relationship_uuid"
  ];

  const { isValid } = useRelationshipFormIsValid({ form, requiredFields });

  return (
    <Modal open key={form.relationship_uuid}>
      <Modal.Header>Edit Owner</Modal.Header>
      <Modal.Content>
        <Header as="h4">{`${forceValue(
          ConcatName(form.owner_entity_data || {})
        )} | ${forceValue(
          form.owner_entity_data ? form.owner_entity_data.tin : ""
        )}`}</Header>
        <br />
        <Form>
          <Form.Group>
            <PercentInput
              data-testid="pi_owner_percent"
              label="Ownership Percentage"
              name="percent_owned"
              onChange={updateForm}
              required
              value={forceValue(form.percent_owned)}
              placeholder="0.0000"
              decimalScale={4}
              max={1}
            />
            <Form.Input
              data-testid="owner_voting_rights"
              control={Select}
              options={votingRightsOptions}
              selectOnBlur={false}
              label="Voting Rights"
              name="voting_rights"
              onChange={updateForm}
              required
              value={forceValue(form.voting_rights)}
            />
            <DateInput
              data-testid="owner_start_date"
              asForm
              label="Start Date"
              name="start_date"
              onChange={updateForm}
              value={forceValue(form.start_date)}
            />
            <DateInput
              data-testid="owner_end_date"
              asForm
              label="End Date"
              name="end_date"
              disabled={!form.start_date}
              onChange={updateForm}
              min={form.start_date}
              value={forceValue(form.end_date)}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="cancelButton"
          secondary
          onClick={() => onClose({})}
          data-testid="owner_modal_cancel"
        >
          Cancel
        </Button>
        <Button
          id="submitButton"
          primary
          disabled={!isValid}
          onClick={onSave}
          data-testid="owner_modal_save"
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

OwnerModal.propTypes = {
  form: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired
};

export default OwnerModal;
