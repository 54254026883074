import React from "react";
import { func, shape, string, arrayOf } from "prop-types";
import { Table, Icon, Button } from "semantic-ui-react";
import { ConcatName as getEntityName } from "../../../../../services/Entities";

function BeneficialsTable({
  data,
  onOpen,
  type,
  onRemoveBeneficials,
  permission
}) {
  return (
    <>
      <div style={{ textAlign: "right" }}>
        {permission.readWrite && (
          <Button
            basic
            circular
            data-testid="open-modal-btn"
            icon={<Icon name="pencil" color="green" />}
            onClick={() => onOpen(type)}
          />
        )}
      </div>
      <Table celled data-testid="test">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Individual Entity</Table.HeaderCell>
            <Table.HeaderCell>Business Entity</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map(
            ({ individualData, businessData, uuid, relType }) =>
              relType === type && (
                <Table.Row key={uuid}>
                  <Table.Cell>{getEntityName(individualData)}</Table.Cell>
                  <Table.Cell>{getEntityName(businessData)}</Table.Cell>
                  <Table.Cell>
                    {" "}
                    {(permission.readWrite || permission.delete) && (
                      <Button
                        basic
                        circular
                        data-testid="beneficials-table-remove-button"
                        floated="right"
                        icon={<Icon name="times" color="red" />}
                        onClick={() => onRemoveBeneficials(uuid)}
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
              )
          )}
        </Table.Body>
      </Table>
    </>
  );
}

BeneficialsTable.propTypes = {
  data: arrayOf(shape()).isRequired,
  onOpen: func.isRequired,
  type: string.isRequired,
  onRemoveBeneficials: func.isRequired,
  permission: shape()
};

export default BeneficialsTable;
