import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Header, Segment, Step } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { ConcatName } from "../../../../../../services/Entities";
import {
  Entities,
  Relationships,
  LoanApp,
  Institutions
} from "../../../../../../services/ApiLib";
import { relationshipTypes } from "../../../../../../services/Constants/relationshipTypes";

import BorrowersModal from "./components/BorrowersModal";
import { BorderedContainer } from "../../../../../../components/CustomUIElements";
import { ConfirmationModal } from "../../../../../../components/Modals";
import NotificationBell from "../../../../../../components/NotificationBell";
import { actionCreators } from "../../reducer";
import { actions as loanAppActions } from "../../../../../../store/models/loan-applications";
import RemoveApplicationModal from "../../../../../../components/Modals/components/RemoveApplicationModal";

import { getInstitutionUuid } from "../../../../../../services/Auth";
import EngageBAFSModal from "../../../../../../components/Modals/components/EngageBAFSModal";
import logger from "../../../../../../services/logger";

export class StepTabsObj extends Component {
  constructor(props) {
    super(props);
    this.activeSteps = this.activeSteps.bind(this);
    this.getBorrowers = this.getBorrowers.bind(this);
    this.getEntityDetails = this.getEntityDetails.bind(this);
    this.getLoanApp = this.getLoanApp.bind(this);
    this.getInstitution = this.getInstitution.bind(this);
    this.onDeleteBorrower = this.onDeleteBorrower.bind(this);
    this.onError = this.onError.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.openConfirmModal = this.openConfirmModal.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.setBorrowers = this.setBorrowers.bind(this);
    this.setEntities = this.setEntities.bind(this);
    this.setEntityDetails = this.setEntityDetails.bind(this);
    this.withdrawApplication = this.withdrawApplication.bind(this);
    this.declineApplication = this.declineApplication.bind(this);
    this.state = { modal: null };
  }

  componentDidMount() {
    this.getBorrowers();
    this.getLoanApp();
    this.getInstitution();
  }

  componentDidUpdate(prevProps) {
    const { loanAppUuid } = this.props;
    if (prevProps.loanAppUuid !== loanAppUuid) {
      this.getLoanApp();
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(loanAppActions.resetState());
    dispatch({
      type: "LOR_RESET"
    });
  }

  onDeleteBorrower() {
    const { relUuid, dispatch } = this.props;
    const { toggleModal, openConfirmModal } = this;
    dispatch(loanAppActions.removeBorrower({ relationship_uuid: relUuid }));
    toggleModal();
    openConfirmModal("");
  }

  onError(rsp) {
    const { dispatch } = this.props;
    dispatch({
      type: "LOR_STEP_TAB_UPDATE_ERROR",
      error: rsp
    });
    logger.error("Error: ", rsp); // TODO: Handle errors properly
  }

  onSelect(uuid) {
    const { dispatch } = this.props;
    dispatch({
      type: "LOR_STEP_TAB_UPDATE_SELECTED_BORROWER",
      selectedBorrower: uuid
    });

    dispatch(loanAppActions.updatePrimaryBorrower({ uuid }));
  }

  getLoanApp() {
    const { loanAppUuid, dispatch } = this.props;
    dispatch(actionCreators.loadLoanApp(loanAppUuid));
  }

  getInstitution() {
    const { dispatch } = this.props;
    const { onError } = this;

    Institutions.get(
      institution => {
        dispatch({
          type: "LOS_PHASES_SET_INSTITUTION_IS_BAFS_MANAGED",
          institutionIsBAFSManaged: institution.data.bafs_services
        });
        dispatch({
          type: "LOS_PHASES_SET_INSTITUTION",
          institution: institution.data
        });
      },
      onError,
      { number: 1, direction: "next", size: 1 },
      getInstitutionUuid(),
      {}
    );
  }

  getBorrowers() {
    const { jwt, loanAppUuid } = this.props;
    const { onError, setBorrowers } = this;
    Relationships.get(
      { rel_type: relationshipTypes.BORROWER, parent_uuid: loanAppUuid },
      jwt,
      setBorrowers,
      onError,
      loanAppUuid
    );
  }

  getEntityDetails(rsp) {
    const { jwt } = this.props;
    const { onError, onSelect, setEntityDetails, setEntities } = this;
    const entities = rsp.map(b => b.child_uuid);
    const primaryEntity = rsp.filter(b => b.primary_borrower === true)[0];
    if (entities.length) {
      Entities.get(jwt, setEntities, onError, null, { uuidList: entities });
    }
    if (primaryEntity) {
      Entities.get(jwt, setEntityDetails, onError, primaryEntity.child_uuid);
      onSelect(primaryEntity.uuid);
    }
  }

  setBorrowers(rsp) {
    const { dispatch } = this.props;
    dispatch({
      type: "LOR_STEP_TAB_SET_BORROWERS",
      newBorrowers: rsp.data
    });
    dispatch({
      type: "LOR_ENTITY_DETAILS_SET_BORROWERS",
      newBorrowers: rsp.data
    });
    this.getEntityDetails(rsp.data);
  }

  setEntities(rsp) {
    const { dispatch } = this.props;
    rsp.data.forEach(e => {
      dispatch({
        type: "LOR_STEP_TAB_SET_ENTITY",
        uuid: e.uuid,
        entity: e
      });
    });
  }

  onConfirm(state = {}) {
    const { loanAppUuid } = this;
    if (state.withdraw) {
      this.withdrawApplication(loanAppUuid);
    }

    if (state.decline) {
      this.declineApplication(loanAppUuid);
    }
  }

  setEntityDetails(rsp, shouldRedirect = false) {
    const { history, dispatch } = this.props;
    dispatch({
      type: "LOR_STEP_TAB_POPULATE_ENTITY_DETAILS",
      data: rsp.data
    });
    if (shouldRedirect) {
      history.push("/application-phases");
    }
  }

  activeSteps(number) {
    const { dispatch } = this.props;
    dispatch({
      activeStepsNum: number,
      type: "LOR_UPDATE_ACTIVE_STEPS"
    });
  }

  toggleModal() {
    const { dispatch } = this.props;
    this.getBorrowers();
    dispatch({
      type: "LOR_STEP_TAB_TOGGLE_MODAL"
    });
  }

  openConfirmModal(relUuid) {
    const { dispatch } = this.props;
    dispatch({
      type: "LOR_STEP_TAB_UPDATE_SELECTED_RELATIONSHIP",
      relUuid
    });
  }

  withdrawApplication(loanAppUuid) {
    this.setState({ modal: null });
    // pass undefined instead of token to LoanApp.update.
    // token is only needed for backward compatibility
    LoanApp.update(
      undefined,
      data => this.setEntityDetails(data, true),
      this.onError,
      loanAppUuid,
      { phase: "Complete", status: "Withdrawn" }
    );
  }

  declineApplication(loanAppUuid) {
    this.setState({ modal: null });
    // pass undefined instead of token to LoanApp.update.
    // token is only needed for backward compatibility
    LoanApp.update(
      undefined,
      data => this.setEntityDetails(data, true),
      this.onError,
      loanAppUuid,
      { phase: "Complete", status: "Declined" }
    );
  }

  render() {
    const {
      onDeleteBorrower,
      onSelect,
      toggleModal,
      openConfirmModal,
      declineApplication,
      withdrawApplication
    } = this;
    const { modal } = this.state;
    const {
      activeStep,
      borrowers,
      entities,
      entityDetails,
      errorMessage,
      isOpen,
      loanAppUuid,
      confirmModal,
      selectedBorrower,
      institution,
      institutionIsBAFSManaged,
      addToast
    } = this.props;
    return !loanAppUuid ? (
      "Navigation Loading"
    ) : (
      <BorderedContainer padding="1em" margin="0 0 1% 0" width="unset">
        <ConfirmationModal
          open={confirmModal}
          yesButtonLabel="Delete"
          showLogo={false}
          handleCancel={() => openConfirmModal("")}
          handleConfirm={() => onDeleteBorrower()}
          prompt="Are you sure you want to delete this Borrower?"
          size="tiny"
        />
        <BorrowersModal
          borrowers={borrowers}
          entities={entities}
          open={isOpen}
          selectedBorrower={selectedBorrower}
          onDelete={openConfirmModal}
          onSelect={onSelect}
          onCancel={toggleModal}
        />
        {modal}
        {errorMessage && <Header color="red">{errorMessage}</Header>}
        <Header as="h3" textAlign="left">
          New Credit Request
          <Button
            basic
            size="tiny"
            floated="right"
            secondary
            compact
            onClick={() =>
              this.setState({
                modal: (
                  <RemoveApplicationModal
                    open
                    data-testid="modal"
                    onCancel={() => this.setState({ modal: null })}
                    onConfirm={this.onConfirm}
                    loanAppUuid={loanAppUuid}
                    withdrawApplication={withdrawApplication}
                    declineApplication={declineApplication}
                  />
                )
              })
            }
            content="Remove Application"
          />
          {institutionIsBAFSManaged ? (
            <Button
              basic
              size="tiny"
              floated="right"
              compact
              onClick={() =>
                this.setState({
                  modal: (
                    <EngageBAFSModal
                      data-testid="engage-bafs-modal"
                      open
                      institution={institution}
                      loanApp={{ app_id: "", uuid: loanAppUuid }}
                      headerText="Request BAFS Services"
                      onCancel={() => this.setState({ modal: null })}
                      onSuccess={() => {
                        addToast({
                          id: "MESSAGE_SENT",
                          title: {
                            id: "BAFS_SERVICES_REQUEST_SENT_SUCCESS_TITLE",
                            defaultMessage: "Success"
                          },
                          message: {
                            id: "BAFS_SERVICES_REQUEST_SENT_SUCCESS_MESSAGE",
                            defaultMessage: "Your message was sent."
                          },
                          variant: "success"
                        });
                      }}
                    />
                  )
                })
              }
              content="Request BAFS Services"
            />
          ) : null}
          <Header.Subheader>
            <b>{ConcatName(entityDetails)}</b>
          </Header.Subheader>
        </Header>
        <Button onClick={toggleModal} size="small">
          Manage Borrowers
        </Button>
        <Segment textAlign="center" basic>
          <Step.Group>
            <Step
              active={activeStep > 0}
              as={Link}
              onClick={() => {
                this.activeSteps(1);
              }}
              title="Entity Details"
              to={`/los/${loanAppUuid}/stepper/entity_details`}
            />
            <Step
              active={activeStep > 1}
              as={Link}
              onClick={() => {
                this.activeSteps(2);
              }}
              title="Credit Requests"
              to={`/los/${loanAppUuid}/stepper/credit_requests`}
            />
            <Step
              active={activeStep > 2}
              as={Link}
              onClick={() => {
                this.activeSteps(3);
              }}
              title="Guarantors"
              to={`/los/${loanAppUuid}/stepper/guarantors`}
            />
            <Step
              active={activeStep > 3}
              as={Link}
              onClick={() => {
                this.activeSteps(4);
              }}
              title="Collateral"
              to={`/los/${loanAppUuid}/stepper/collateral`}
            />
            <Step
              active={activeStep > 4}
              as={Link}
              onClick={() => {
                this.activeSteps(5);
              }}
              title="Documents"
              to={`/los/${loanAppUuid}/stepper/documents`}
            />
            <Step
              active={activeStep > 5}
              as={Link}
              onClick={() => {
                this.activeSteps(6);
              }}
              title="Review/Submit"
              to={`/los/${loanAppUuid}/stepper/review_submit`}
            />
          </Step.Group>
          <br />
          <Button.Group floated="right">
            <Button
              as={Link}
              color="blue"
              basic
              floated="right"
              size="mini"
              to={`/los/${loanAppUuid}/stepper/messages`}
            >
              Messages
            </Button>
            <NotificationBell uuid={loanAppUuid} />
          </Button.Group>
          <br />
        </Segment>
      </BorderedContainer>
    );
  }
}

StepTabsObj.defaultProps = {
  entityDetails: {},
  entities: {}
};

StepTabsObj.propTypes = {
  activeStep: PropTypes.number.isRequired,
  borrowers: PropTypes.arrayOf(PropTypes.object).isRequired,
  confirmModal: PropTypes.bool.isRequired,
  entities: PropTypes.objectOf(PropTypes.object),
  entityDetails: PropTypes.shape(),
  dispatch: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  jwt: PropTypes.string.isRequired,
  loanAppUuid: PropTypes.string.isRequired,
  relUuid: PropTypes.string.isRequired,
  selectedBorrower: PropTypes.string.isRequired,
  history: PropTypes.shape({
    length: PropTypes.number,
    push: PropTypes.func
  }).isRequired,
  addToast: PropTypes.func,
  institutionIsBAFSManaged: PropTypes.bool.isRequired,
  institution: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  borrowers: state.LorStepTabReducer.borrowers,
  confirmModal: state.LorStepTabReducer.confirmModal,
  entities: state.LorStepTabReducer.entities,
  entityDetails: state.LorStepTabReducer.entityDetails,
  errorMessage: state.LoanOriginationReducer.errorMessage,
  isOpen: state.LorStepTabReducer.isOpen,
  relUuid: state.LorStepTabReducer.relUuid,
  selectedBorrower: state.LorStepTabReducer.selectedBorrower,
  jwt: state.auth.jwt,
  institutionIsBAFSManaged: state.LosPhasesReducer.institutionIsBAFSManaged,
  institution: state.LosPhasesReducer.institution
});

const StepTabs = connect(mapStateToProps)(StepTabsObj);

export default StepTabs;
