import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  DateCell,
  DollarCell,
  PropertyTable
} from "../../../../../../../../../../components/Tables";
import PastDueDateCell from "./PastDueDateCell";

function PastDueTable({ loan }) {
  const columnsConfig = useMemo(
    () => [
      {
        Header: "# of Days Past Due",
        accessor: "numDaysPastDue",
        primary: true,
        Cell: PastDueDateCell
      },
      {
        Header: "Past Due Date",
        accessor: "past_due_date",
        Cell: DateCell
      },
      {
        Header: "Past Due Amount",
        accessor: "past_due_amount",
        Cell: DollarCell
      }
    ],
    []
  );

  return (
    <PropertyTable
      columnsConfig={columnsConfig}
      data={
        loan.is_past_due
          ? loan
          : { ...loan, past_due_date: null, past_due_amount: 0 }
      }
    />
  );
}

PastDueTable.propTypes = {
  loan: PropTypes.shape({
    past_due_date: PropTypes.string,
    past_due_amount: PropTypes.number,
    is_past_due: PropTypes.bool
  }).isRequired
};

export default PastDueTable;
