import { blastApi, Tag } from "./blastApi";
import {
  PaginatedQueryOptions,
  ListResponse,
  SingleResponse,
  HardDeleteDataRsp
} from "./types";
import {
  Relationship,
  RelType,
  ChildType,
  ParentType
} from "./ModelTypes/relationships";

import {
  createListTags,
  createSingleTag,
  extractData,
  filteredQuery
} from "./utilities";

const servicePath = "/relationships/v2";

interface RelationshipsQueryOptions extends PaginatedQueryOptions {
  institution_uuid?: string;
  parent_uuid?: string;
  child_uuid?: string;
  rel_type?: RelType;
  child_type?: ChildType;
  parent_type?: ParentType;
  primary_borrower?: boolean;
}

const extendedApi = blastApi.injectEndpoints({
  endpoints: build => ({
    createRelationship: build.mutation<
      SingleResponse<Relationship>,
      Partial<Relationship>
    >({
      query: relationship => ({
        url: `${servicePath}/relationships`,
        method: "POST",
        body: relationship
      }),
      invalidatesTags: [Tag.Relationship]
    }),
    updateRelationship: build.mutation<
      SingleResponse<Relationship>,
      Partial<Relationship>
    >({
      query: relationship => ({
        url: `${servicePath}/relationships/${relationship.uuid}`,
        method: "PUT",
        body: relationship
      }),
      invalidatesTags: [Tag.Relationship]
    }),
    deleteRelationship: build.mutation<
      SingleResponse<HardDeleteDataRsp>,
      string
    >({
      query: uuid => ({
        url: `${servicePath}/relationships/${uuid}`,
        method: "DELETE"
      }),
      invalidatesTags: [Tag.Relationship]
    }),
    relationship: build.query<Relationship, string>({
      query: uuid => `${servicePath}/relationships/${uuid}`,
      providesTags: result => createSingleTag(result, Tag.Relationship),
      transformResponse: (response: SingleResponse<Relationship>) =>
        extractData<Relationship>(response)
    }),
    // NOTE: If you need to get the relationships for a participation loan record,
    // first pull that record, and instead use participation_parent_uuid as
    // the relationship parent_uuid (if it exists; there can be no-lead participations)
    relationshipsNoParticipationsCheck: build.query<
      ListResponse<Relationship>,
      RelationshipsQueryOptions
    >({
      query: filters => filteredQuery(`${servicePath}/relationships`, filters),
      providesTags: results => createListTags(results, Tag.Relationship)
    })
  }),
  overrideExisting: false
});

export const {
  useCreateRelationshipMutation,
  useUpdateRelationshipMutation,
  useDeleteRelationshipMutation,
  useRelationshipQuery,
  useRelationshipsNoParticipationsCheckQuery
} = extendedApi;
