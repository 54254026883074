import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  PercentCell,
  PropertyTable
} from "../../../../../../../../../../components/Tables";
import Constant from "../../../../../../../../../../services/Constants/strings";

function VariableRateTable({ loan, loanInterest, isMonthEnd }) {
  const rows = useMemo(
    () => [
      {
        Header: "Variable Rate Index",
        accessor: "rate_index",
        primary: true
      },
      {
        Header: "Rate Floor",
        accessor: "rate_floor",
        Cell: PercentCell
      },
      {
        Header: "Rate Ceiling",
        accessor: "rate_ceiling",
        Cell: PercentCell
      },
      {
        Header: "Max Change in Rate Decrease",
        accessor: "max_rate_decrease",
        Cell: PercentCell
      },
      {
        Header: "Max Change in Rate Increase",
        accessor: "max_rate_increase",
        Cell: PercentCell
      },
      {
        Header: "Margin",
        accessor: "margin",
        Cell: PercentCell
      }
    ],
    []
  );

  const newLoanInterest = isMonthEnd
    ? {
        ...loanInterest,
        margin: Constant.NotApplicable
      }
    : loanInterest;
  return (
    <PropertyTable
      columnsConfig={rows}
      data={{ ...loan, ...newLoanInterest }}
    />
  );
}

VariableRateTable.propTypes = {
  loan: PropTypes.shape({
    rate_index: PropTypes.string,
    rate_floor: PropTypes.number,
    rate_ceiling: PropTypes.number,
    max_rate_decrease: PropTypes.number,
    max_rate_increase: PropTypes.number,
    margin: PropTypes.string
  }).isRequired,
  loanInterest: PropTypes.shape({
    margin: PropTypes.number
  }),
  isMonthEnd: PropTypes.bool
};

export default VariableRateTable;
