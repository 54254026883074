/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Form, Grid, Segment, Dropdown } from "semantic-ui-react";
import { useSelector } from "react-redux";
import {
  PercentInput,
  DateInput,
  DollarField
} from "../../../../../../../../../../components/CustomFormElements";
import { EditModal } from "../../../../../../../../../../components/Modals";
import { BasisPointsField } from "../../../../../../../../components/BasisPoints";

import { Users } from "../../../../../../../../../../services/ApiLib";

import ModalWrappingRow from "./ModalWrappingRow";
import Constants from "../../../../../../../../../../services/Constants/strings";
import { prettyDate } from "../../../../../../../../../../services/DateTime";
import SemanticSearch from "../../../../../../../../../../components/SemanticSearch";
import {
  IndustrySearchKeyNAICs,
  NAICCodes
} from "../../../../../../../../../../services/FormElements";
import CommotionMessages from "../../../../../../../../../../assets/messages/common.en-US.json";
import { useInstitutionAssetGroupClassesQuery } from "../../../../../../../../../../services/RTKQuery/institutions";
import { serializeAssetTypesData } from "../../../../../../../../../../services/Constants/assetsOptions";

const { SINGLE, MULTIPLE, REVOLVING } = CommotionMessages;
export const advanceTypeOptions = [
  { key: SINGLE, value: SINGLE, text: SINGLE },
  { key: MULTIPLE, value: MULTIPLE, text: MULTIPLE },
  { key: REVOLVING, value: REVOLVING, text: REVOLVING }
];

const balloonOption = [
  { key: "Yes", value: true, text: "Yes" },
  { key: "No", value: false, text: "No" }
];

const appOfPaymentsOptions = [
  { key: "option-1", value: "F, I, P", text: "F, I, P" },
  { key: "option-2", value: "I, F, P", text: "I, F, P" },
  { key: "option-3", value: "I, P, F", text: "I, P, F" }
];

const postingMethodOptions = [
  { key: "Effective", value: "Effective", text: "Effective" },
  {
    key: "Due",
    value: "Due",
    text: "Due"
  }
];

const paymentTypeOptions = [
  {
    key: "Pay Ahead and Drop Next Bill",
    value: "Pay Ahead and Drop Next Bill",
    text: "Pay Ahead and Drop Next Bill"
  },
  {
    key: "Payment to Principal",
    value: "Payment to Principal",
    text: "Payment to Principal"
  },
  {
    key: "Simple Pay Ahead",
    value: "Simple Pay Ahead",
    text: "Simple Pay Ahead"
  },
  {
    key: "Follow Processing Order",
    value: "Follow Processing Order",
    text: "Follow Processing Order"
  }
];

const statusOptions = [
  { key: Constants.ACTIVE, value: Constants.ACTIVE, text: Constants.ACTIVE },
  {
    key: Constants.NON_ACCRUAL,
    value: Constants.NON_ACCRUAL,
    text: Constants.NON_ACCRUAL
  },
  {
    key: Constants.INACTIVE_CHARGE_OFF,
    value: Constants.INACTIVE_CHARGE_OFF,
    text: Constants.INACTIVE_CHARGE_OFF
  },
  {
    key: Constants.PAID_OFF,
    value: Constants.PAID_OFF,
    text: Constants.PAID_OFF
  },
  {
    key: Constants.ACTIVE_CHARGE_OFF,
    value: Constants.ACTIVE_CHARGE_OFF,
    text: Constants.ACTIVE_CHARGE_OFF
  }
];
export const formatCodeOptions = options => {
  const codeOptions = [];
  Object.values(options).forEach(option => {
    codeOptions.push({
      key: option.code_5300,
      text: `${option.code_5300} ${option.description_5300}`,
      value: option.uuid
    });
  });
  return codeOptions;
};

function officerToOptions(officers) {
  return officers.map(officer => ({
    key: officer.uuid,
    text: `${officer.first_name} ${officer.last_name}`,
    value: officer.uuid
  }));
}

function CharacteristicsEditModal({
  codeOptions,
  loan,
  selectedCodeOptions,
  isCoreOfRecord,
  ...props
}) {
  const {
    deal_rating,
    purpose,
    loan_officer,
    loan_term,
    balloon,
    estimated_balloon_amount,
    amortization_period,
    collateral_code,
    advance_type,
    credit_admin_fee,
    overpayment_split_options,
    payment_processing_order,
    posting_method,
    min_bill_satisfaction_percent,
    status,
    closed_date,
    uuid,
    institution_uuid,
    total_payments,
    bill_notification_period,
    bill_grace_period,
    naics_code,
    institution_account_number
  } = loan;

  const { data: assetClasses } =
    useInstitutionAssetGroupClassesQuery(institution_uuid);
  const serializedAssetTypesData = useMemo(() => {
    return serializeAssetTypesData({
      data: assetClasses?.asset_groups
    });
  }, [assetClasses?.asset_groups]);

  const [loanOfficerOptions, setLoanOfficerOptions] = useState([]);

  useEffect(() => {
    async function fetchOfficers() {
      const response = await Users.asyncRead({
        institution_uuid,
        has_lending_authority: true
      });
      if (response?.data) {
        setLoanOfficerOptions(officerToOptions(response.data));
      }
    }
    fetchOfficers();
  }, [institution_uuid]);

  const { selectedCreditAnalysis } = useSelector(state => ({
    selectedCreditAnalysis:
      state?.CreditAnalysisManagementReducer?.selectedCreditAnalysis
  }));
  const creditRiskRating =
    selectedCreditAnalysis?.[0]?.single_risk_scale_item?.rating;

  return (
    <EditModal
      key={uuid}
      size="large"
      defaultValues={{
        deal_rating,
        purpose,
        "5300_code": selectedCodeOptions
          ? Object.keys(selectedCodeOptions)
          : [],
        loan_officer,
        collateral_code,
        amortization_period,
        advance_type,
        credit_admin_fee,
        closed_date,
        payment_processing_order,
        posting_method,
        overpayment_split_options,
        status,
        min_bill_satisfaction_percent,
        loan_term,
        balloon,
        estimated_balloon_amount,
        total_payments,
        bill_notification_period,
        bill_grace_period,
        naics_code,
        institution_account_number
      }}
      header="Edit Characteristics"
      {...props}
    >
      {({ onChange, values }) => (
        <Segment padded basic>
          <Grid divided="vertically">
            <ModalWrappingRow columns={4}>
              <Form.Input
                type="string"
                label="Loan Number"
                name="institution_account_number"
                value={values.institution_account_number || ""}
                onChange={(ev, { name, value }) => onChange({ name, value })}
              />
              <Form.Input
                type="number"
                label="Credit Risk Rating"
                name="deal_rating"
                value={creditRiskRating || values.deal_rating}
                readOnly={creditRiskRating}
                onChange={(ev, { name, value }) =>
                  creditRiskRating
                    ? null
                    : onChange({ name, value: Number(value) })
                }
              />
              <Form.Input
                label="Purpose"
                name="purpose"
                value={values.purpose || ""}
                onChange={(ev, { name, value }) => onChange({ name, value })}
              />
              <Form.Field
                control={Dropdown}
                label="Loan Officer"
                name="loan_officer"
                options={loanOfficerOptions}
                value={values.loan_officer || ""}
                selection
                onChange={(ev, { name, value }) => onChange({ name, value })}
              />
            </ModalWrappingRow>
            <ModalWrappingRow columns={1}>
              <Form.Dropdown
                label="5300 Code"
                multiple
                search
                selection
                name="5300_code"
                options={formatCodeOptions(codeOptions)}
                value={values["5300_code"] || []}
                onChange={(ev, { name, value }) => onChange({ name, value })}
              />
            </ModalWrappingRow>

            <ModalWrappingRow columns={1}>
              <Form.Group>
                <Form.Field width={6}>
                  <label htmlFor="industry_search">Industry Type Search:</label>
                  <SemanticSearch
                    id="industry_search"
                    name="industry_type"
                    width={9}
                    searchableData={IndustrySearchKeyNAICs}
                    onResultSelect={(e, { result }) => {
                      const code = parseInt(result.value, 10);
                      onChange({ name: "naics_code", value: code });
                    }}
                  />
                </Form.Field>
                <Form.Input
                  width={6}
                  name="naics_code"
                  label="NAICS Code"
                  value={values.naics_code || ""}
                  readOnly
                />
                <Form.Input
                  width={6}
                  label="Industry Type"
                  name="industry_title"
                  value={values.naics_code ? NAICCodes[values.naics_code] : ""}
                  readOnly
                />
              </Form.Group>
            </ModalWrappingRow>

            <ModalWrappingRow columns={2}>
              <Form.Dropdown
                label="Collateral Code"
                search
                selection
                name="collateral_code"
                options={serializedAssetTypesData.typeOptions}
                value={values.collateral_code || ""}
                onChange={(ev, { name, value }) => onChange({ name, value })}
              />
              <Form.Input
                label="Amortization Period"
                name="amortization_period"
                value={values.amortization_period || ""}
                onChange={(ev, { name, value }) => onChange({ name, value })}
              />
            </ModalWrappingRow>

            <ModalWrappingRow columns={3}>
              <Form.Input
                type="number"
                label="Term"
                name="loan_term"
                value={values.loan_term || ""}
                onChange={(ev, { name, value }) =>
                  onChange({ name, value: Number(value) })
                }
              />
              <Form.Field
                control={Dropdown}
                label="Balloon"
                name="balloon"
                options={balloonOption}
                value={!!values.balloon}
                selection
                onChange={(ev, { name, value }) => onChange({ name, value })}
              />
              <DollarField
                name="estimated_balloon_amount"
                value={values.estimated_balloon_amount || 0}
                label="Balloon Amount"
                onChange={onChange}
              />
              <Form.Field
                control={Dropdown}
                label="Advance Type"
                name="advance_type"
                options={advanceTypeOptions}
                value={values.advance_type || ""}
                selection
                onChange={(ev, { name, value }) => onChange({ name, value })}
              />
              <BasisPointsField
                label="BAFS Credit Admin"
                name="credit_admin_fee"
                placeholder=""
                value={values.credit_admin_fee}
                onChange={onChange}
              />
              <Form.Field
                control={Dropdown}
                label="Application of Payments"
                name="payment_processing_order"
                options={appOfPaymentsOptions}
                value={values.payment_processing_order}
                selection
                onChange={(ev, { name, value }) => onChange({ name, value })}
              />
              <Form.Field
                control={Dropdown}
                label="Payment Posting"
                name="posting_method"
                clearable
                options={postingMethodOptions}
                value={values.posting_method || ""}
                selection
                onChange={(ev, { name, value }) => onChange({ name, value })}
              />
              <Form.Field
                control={Dropdown}
                label="Overpayment Option"
                name="overpayment_split_options"
                clearable
                options={paymentTypeOptions}
                value={values.overpayment_split_options}
                selection
                onChange={(ev, { name, value }) => onChange({ name, value })}
              />
              <PercentInput
                label="Bill Satisfaction"
                name="min_bill_satisfaction_percent"
                value={values.min_bill_satisfaction_percent || ""}
                onChange={(e, { name, value }) => onChange({ name, value })}
                scale={3}
              />
              <Form.Field
                control={Dropdown}
                label="Status"
                name="status"
                clearable
                options={statusOptions}
                value={values.status}
                selection
                onChange={(ev, { name, value }) => onChange({ name, value })}
              />
              <DateInput
                asForm
                label="Date Closed"
                name="closed_date"
                value={values.closed_date}
                onChange={(e, { name, value }) => onChange({ name, value })}
                max={prettyDate(new Date())}
              />
              <Form.Input
                type="number"
                label="Bill Notification Period (in Days)"
                name="bill_notification_period"
                value={values.bill_notification_period || ""}
                onChange={(ev, { name, value }) =>
                  onChange({ name, value: Number(value) })
                }
              />
              <Form.Input
                type="number"
                label="Fee Grace Period"
                name="bill_grace_period"
                value={values.bill_grace_period || ""}
                onChange={(ev, { name, value }) =>
                  onChange({ name, value: Number(value) })
                }
              />
              <Form.Input
                type="number"
                label="Total Payments"
                name="total_payments"
                value={values.total_payments || ""}
                onChange={(ev, { name, value }) =>
                  onChange({ name, value: Number(value) })
                }
              />
            </ModalWrappingRow>
          </Grid>
        </Segment>
      )}
    </EditModal>
  );
}
CharacteristicsEditModal.propTypes = {
  codeOptions: PropTypes.shape(PropTypes.object),
  loan: PropTypes.shape({
    uuid: PropTypes.string,
    status: PropTypes.string,
    closed_date: PropTypes.string,
    deal_rating: PropTypes.number,
    purpose: PropTypes.string,
    collateral_code: PropTypes.string,
    "5300_code": PropTypes.string,
    loan_term: PropTypes.number,
    balloon: PropTypes.bool,
    estimated_balloon_amount: PropTypes.number,
    core_of_record: PropTypes.bool,
    loan_officer: PropTypes.string,
    amortization_period: PropTypes.number,
    advance_type: PropTypes.string,
    credit_admin_fee: PropTypes.number,
    payment_processing_order: PropTypes.string,
    posting_method: PropTypes.string,
    overpayment_split_options: PropTypes.string,
    min_bill_satisfaction_percent: PropTypes.number,
    institution_uuid: PropTypes.string,
    total_payments: PropTypes.number,
    bill_notification_period: PropTypes.number,
    bill_grace_period: PropTypes.number,
    naics_code: PropTypes.number,
    institution_account_number: PropTypes.string
  }).isRequired,
  onSave: PropTypes.func,
  isCoreOfRecord: PropTypes.bool,
  selectedCodeOptions: PropTypes.shape(PropTypes.object)
};

export default CharacteristicsEditModal;
