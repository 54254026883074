import React from "react";
import { Row, IndeterminateCheckboxProps } from "../types";
import IndeterminateCheckbox from "../IndeterminateCheckbox";

const calculateEnabledRows = (
  rows: Row[],
  isRowSelectable: (row: Row) => boolean
) => {
  let num = 0;
  rows.forEach((row: Row) => {
    if (isRowSelectable(row)) {
      num += 1;
    }
  });

  return num;
};

export type IndeterminateCheckboxHeaderProps = {
  toggleRowSelected: (
    event: React.ChangeEvent<HTMLInputElement>,
    bool: boolean
  ) => void;
  rows: Row[];
  selectedFlatRows: Row[];
  isRowSelectable: (row: Row) => boolean;
};

export const IndeterminateCheckboxHeader = ({
  toggleRowSelected,
  rows,
  selectedFlatRows,
  isRowSelectable
}: IndeterminateCheckboxHeaderProps): React.ReactElement<IndeterminateCheckboxProps> => {
  const numEnabledRows = calculateEnabledRows(rows, isRowSelectable);
  const checked =
    rows && numEnabledRows > 0 && numEnabledRows === selectedFlatRows.length;
  const title = "Toggle All Rows Selected";
  const indeterminate = !checked && selectedFlatRows.length > 0;
  const disabled = numEnabledRows === 0;

  const overriddenOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    rows.forEach((row: Row) => {
      if (isRowSelectable(row)) {
        toggleRowSelected(row.id, event.currentTarget.checked);
      }
    });
  };

  const modifiedToggleAllRowsProps = {
    onChange: overriddenOnChange,
    checked,
    title,
    indeterminate,
    disabled
  };

  return <IndeterminateCheckbox {...modifiedToggleAllRowsProps} />;
};
