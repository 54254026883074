import React, { forwardRef, MouseEvent } from "react";
import { Button } from "../button/button";
import { IconButton } from "../icon-button/icon-button";
import type { ButtonProps } from "../button/button";
import type { IconButtonProps } from "../icon-button/icon-button";

/**
 * When the button is clicked the user will be navigated to the location of the
 * `to` prop.
 *
 * Based on the type of props used either a `<Button>` or `<IconButton>` will be
 * displayed.
 *
 * If a handler function is provided with the `onClick` prop it will be invoked
 * first. If for some reason the button click should NOT cause a navigation
 * invoke the event's `preventDefault` function and the browser will not
 * navigate.
 */
export const NavButton = forwardRef<
  HTMLButtonElement,
  NavButtonProps | NavIconButtonProps
>(function NavButtonImpl({ onClick, to, ...props }, ref) {
  function isIconButtonProps(props: any): props is IconButtonProps {
    return "icon" in props || "iconLeft" in props || "iconRight" in props;
  }

  function handleClick(evt: MouseEvent<HTMLButtonElement>) {
    onClick && onClick(evt);

    if (!evt.isDefaultPrevented()) {
      window.location.assign(to);
    }
  }

  return isIconButtonProps(props) ? (
    <IconButton {...props} onClick={handleClick} />
  ) : (
    <Button {...props} onClick={handleClick} />
  );
});

export type NavButtonProps = ButtonProps & NavProps;

export type NavIconButtonProps = IconButtonProps & NavProps;

export interface NavProps {
  /** The browser will be navigated to this location when the user clicks the button. */
  to: string;
}
