import React from "react";
import { useToast } from "@bafsllc/ui-shared";
import StepTabs from "../StepTabs";

function StepTabsWithToast(props) {
  const { addToast } = useToast();
  return <StepTabs addToast={addToast} {...props} />;
}

export default StepTabsWithToast;
