import React, { useEffect, useState, useRef } from "react";
import { useCreateLoanMutation } from "../../../../../../services/RTKQuery/loans";
import { Loan } from "../../../../../../services/RTKQuery/ModelTypes/loan";

export const CreateTest = ({
  institutionUuid,
  setUuidOfCreatedRecord,
  setStatusInParent
}) => {
  const statusUnknown = "❓Unknown Status";
  const statusPass = "✅ Pass";
  const statusFail = "❌ Fail";

  const [statusCreateState, setStatusCreateState] = useState(statusUnknown);

  const itemUuid = useRef("");

  const [createLoan] = useCreateLoanMutation();
  useEffect(() => {
    const exampleBody: Partial<Loan> = {
      institution_uuid: institutionUuid
    };

    createLoan(exampleBody)
      .unwrap()
      .then(fulfilled => {
        itemUuid.current = fulfilled.data.uuid;
        setUuidOfCreatedRecord(itemUuid.current);
        setStatusCreateState(statusPass);
        setStatusInParent("Pass");
      })
      .catch(error => {
        console.error("Fail: useCreateLoanMutation");
        console.error("caught error: ", error);
        setStatusCreateState(statusFail);
        setStatusInParent("Fail");
      });
  }, [createLoan, institutionUuid, setStatusInParent, setUuidOfCreatedRecord]);

  return <div>{statusCreateState}</div>;
};
