import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import FetchService, {
  asyncFetchService,
  asyncObjectUpload
} from "../../../FetchService";
import {
  ConcatQueryParams,
  enforceBodyShape,
  PaginationParams,
  RemoveNullKeys
} from "../Utilities";
import { FormatUrlV2 } from "../../../FormatUrl";
import logger from "../../../logger";

export const allowedShape = {
  // They keys 'asset' and 'entity' were in this list, but if a future
  // frontend user sends either key, the SQL backend will not support it.
  // Currently, the backend allows the frontend to continue to use the
  // old DynamoDB APIs and silently transforms the data on CRUD actions to
  // use SQL instead, but those two keys are not supported in translations.
  approvalCategory: "string",
  approved: "boolean",
  approvedBy: "string",
  approvedDate: "string",
  archived: "boolean",
  category: "string",
  borrowerVisibility: "boolean",
  deleted: "boolean",
  description: "string",
  docCategory: "string",
  docDate: "string",
  docName: "string",
  docType: "string",
  documentName: "string",
  docYear: "string",
  entityUuid: "string",
  fileName: "string",
  fromActiveView: "boolean",
  institutionUuid: "string",
  loan: "string",
  objectUuid: "string",
  parentRelType: "string",
  parentUuid: "string",
  periodEnd: "string",
  periodStart: "string",
  showInLoanApps: "boolean",
  source: "string",
  status: "string",
  uploadDate: "string",
  uploadOrigin: "string",
  uploadedBy: "string",
  uuid: "string",
  fileSize: "number",
  hasPassword: "boolean",
  bpOrganizationUuid: "string"
};

function add() {
  throw new Error(
    "ObjMetadata's created on object upload. Don't attempt to create it separately."
  );
}

function get(
  jwt,
  onSuccess,
  onError,
  uuid,
  queryParams,
  pagination,
  callbackData
) {
  TypeCheck(jwt, "string", false);
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string", false);
  TypeCheck(queryParams, "object", false);

  if (uuid && queryParams) {
    onError("Error: Invalid Filter Combination");
    return;
  }

  let params = "";
  let url = "";
  if (uuid) {
    url = FormatUrlV2(ApiEndpoints.objectMetadata, { uuid });
  } else {
    if (queryParams) {
      params = ConcatQueryParams(queryParams);
    }
    if (pagination) {
      logger.warn("WARNING: Object.get does not support paginaton.");
      params = PaginationParams(params, pagination, true);
    }
    url = ApiEndpoints.baseUri + ApiEndpoints.objectMetadataGetAll + params;
  }

  FetchService("GET", url, onSuccess, onError, null, callbackData);
}

function update(onSuccess, onError, objectUuid, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(objectUuid, "string", false);

  const url = FormatUrlV2(ApiEndpoints.objectMetadata, { uuid: objectUuid });

  const ammendedCallbackData = {
    ...callbackData,
    objParentUuid: body.parentUuid
  };

  const nextBody = { ...body };
  delete nextBody.httpHeaders;
  delete nextBody.url;

  const allowedBody = enforceBodyShape(nextBody, allowedShape);
  FetchService(
    "PUT",
    url,
    onSuccess,
    onError,
    allowedBody,
    ammendedCallbackData
  );
}

async function updateAsync(objectUuid, body) {
  TypeCheck(objectUuid, "string", false);
  const url = FormatUrlV2(ApiEndpoints.objectMetadata, { uuid: objectUuid });
  const nextBody = { ...body };
  delete nextBody.httpHeaders;
  delete nextBody.url;

  const allowedBody = enforceBodyShape(nextBody, allowedShape);
  const convertedBody = RemoveNullKeys(allowedBody);
  const resp = await asyncFetchService("PUT", url, convertedBody);
  return resp;
}

function remove(objectUuid, onSuccess, onError, callbackData) {
  TypeCheck(objectUuid, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");

  const url = FormatUrlV2(ApiEndpoints.objectDownloads, { uuid: objectUuid });

  let body;
  FetchService("DELETE", url, onSuccess, onError, body, callbackData);
}

async function asyncRead(uuid, queryParams, pagination) {
  TypeCheck(uuid, "string", false);
  TypeCheck(queryParams, "object", false);
  TypeCheck(pagination, "object", false);

  let params = "";
  let url = "";
  if (uuid) {
    if (queryParams) {
      params = ConcatQueryParams(queryParams);
    }
    url = FormatUrlV2(ApiEndpoints.objectMetadata, { uuid }) + params;
  } else {
    if (queryParams) {
      params = ConcatQueryParams(queryParams);
    }
    if (pagination) {
      logger.warn("WARNING: Object.get does not support paginaton.");
      params = PaginationParams(params, pagination, true);
    }
    url = ApiEndpoints.baseUri + ApiEndpoints.objectMetadataGetAll + params;
  }
  const resp = await asyncFetchService("GET", url, null);
  return resp;
}

async function asyncDocUpload(institutionUuid, fileObj) {
  TypeCheck(institutionUuid, "string", true);
  TypeCheck(fileObj, "object", true);

  const url = FormatUrlV2(ApiEndpoints.objectUploadsWithInstitution, {
    institutionUuid
  });
  const rsp = await asyncObjectUpload(url, fileObj);

  return rsp;
}

async function asyncGetMetaData(queryParams, pagination) {
  TypeCheck(queryParams, "object", false);
  TypeCheck(pagination, "object", false);
  let params = "";
  let url = "";
  if (queryParams) {
    params = ConcatQueryParams(queryParams);
  }
  params = PaginationParams(params, pagination, true);
  url = FormatUrlV2(ApiEndpoints.objectV4Metadata) + params;
  const resp = await asyncFetchService("GET", url, null);
  return resp;
}
export default {
  add,
  get,
  update,
  remove,
  asyncRead,
  updateAsync,
  asyncDocUpload,
  asyncGetMetaData
};
