import React from "react";
import { Header, Segment } from "semantic-ui-react";
import PropTypes from "prop-types";
import SettlementSheet from "../../../../components/SettlementSheet";

function SettlementSheets({ loanAppUuid }) {
  return (
    <div>
      <Segment>
        <Header>Settlements</Header>
        <SettlementSheet loanAppUuid={loanAppUuid} readOnly={false} />
      </Segment>
    </div>
  );
}

SettlementSheets.propTypes = {
  loanAppUuid: PropTypes.string.isRequired
};

export default SettlementSheets;
