import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Table, Loader, Segment, Dimmer } from "semantic-ui-react";

import Constants from "../../../../../../../../services/Constants/strings";
import tableHeaders from "./services/tableHeaders";
import FinancialStatements from "./components/FinancialStatements";
import Financial from "./components/Financial";
import { Cms } from "../../../../../../../../services/ApiLib";
import logger from "../../../../../../../../services/logger";

const onError = () => {
  logger.debug("Handle errors properly");
};

export class TableViewObj extends React.Component {
  constructor(props) {
    super(props);
    this.editUUIDs = this.editUUIDs.bind(this);
    this.farStatusChange = this.farStatusChange.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
  }

  handleRowClick(event, row) {
    this.props.dispatch({
      type: "CMS_TABLEVIEW_SELECT_ITEM",
      value: row.uuid,
      rowData: row
    });
  }

  async farStatusChange(event, { value }, row) {
    const { dispatch } = this.props;
    const newRow = { ...row };
    switch (value) {
      case "Completed": {
        newRow.archived = false;
        newRow.editable = false;
        newRow.status = "Completed";
        break;
      }

      case "Archived": {
        newRow.archived = true;
        newRow.editable = false;
        newRow.status = "Archived";
        break;
      }

      case "In Progress": {
        newRow.archived = false;
        newRow.editable = true;
        newRow.status = "In Progress";
        break;
      }

      default: {
        // this shouldn't be possible
        break;
      }
    }
    delete newRow.uuid;

    try {
      const updatedRow = await Cms.updateFinancialAnalysis(row.uuid, newRow);
      if (updatedRow.data) {
        dispatch({
          type: "CMS_FAR_UPDATE_STATUS",
          row: updatedRow.data
        });
      }
    } catch (err) {
      onError(err);
    }
  }

  editUUIDs(uuid, financialModelUuid) {
    const { dispatch } = this.props;
    dispatch({
      type: "CMS_FINANCIAL_STATEMENTS_SELECT_PERIOD",
      uuid,
      financialModelUuid
    });
  }

  render() {
    const { financialModelUuid, selectedPeriods } = this.props;
    const headers = tableHeaders[this.props.activeItem] || [];

    const displayTableHeaders = headers.map(header => (
      <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
    ));

    const displayTableBody = this.props.data.map(row => {
      let cellInfo;
      switch (this.props.activeItem) {
        case Constants.FINANCIAL_STATEMENTS: {
          let disabled = false;
          if (
            financialModelUuid.length !== 0 &&
            financialModelUuid !== row.financialModelUuid &&
            selectedPeriods.length !== 0
          ) {
            disabled = true;
          }
          cellInfo = (
            <FinancialStatements
              data={row}
              disabled={disabled}
              handleRowClick={this.handleRowClick}
              editUUIDs={this.editUUIDs}
              key={row.uuid}
              statementSelected={this.props.statementSelected}
              selectedPeriods={selectedPeriods}
            />
          );
          break;
        }

        case Constants.FINANCIAL: {
          if (!row.archived || this.props.farIncludeArchived) {
            cellInfo = (
              <Financial
                data={row}
                handleChange={this.farStatusChange}
                handleRowClick={this.handleRowClick}
                key={row.uuid}
                statementSelected={this.props.statementSelected}
              />
            );
          }
          break;
        }

        default:
      }
      return cellInfo;
    });
    return (
      <div>
        <Segment>
          <Dimmer inverted active={this.props.loadingTable}>
            <Loader inline="centered" inverted>
              {Constants.LOADING}
            </Loader>
          </Dimmer>
          <Table compact celled selectable>
            <Table.Header>
              <Table.Row key="1">{displayTableHeaders}</Table.Row>
            </Table.Header>
            <Table.Body>{displayTableBody}</Table.Body>
          </Table>
        </Segment>
      </div>
    );
  }
}

TableViewObj.propTypes = {
  selectedPeriods: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      col1: PropTypes.string,
      col2: PropTypes.string,
      col3: PropTypes.string,
      col4: PropTypes.string,
      col5: PropTypes.string
    })
  ).isRequired,
  activeItem: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  farIncludeArchived: PropTypes.bool.isRequired,
  financialModelUuid: PropTypes.string.isRequired,
  loadingTable: PropTypes.bool,
  statementSelected: PropTypes.string
};

TableViewObj.defaultProps = {
  statementSelected: "",
  loadingTable: false
};

const mapStateToProps = state => ({
  activeItem: state.CreditManagementReducer.activeItem,
  farIncludeArchived: state.CreditManagementReducer.farIncludeArchived,
  financialModelUuid: state.CreditManagementReducer.financialModelUuid,
  loadingTable: state.CreditManagementReducer.loadingTable,
  selectedPeriods: state.CreditManagementReducer.selectedPeriods,
  selectedRowData: state.CreditManagementReducer.selectedRowData,
  showClosedLoans: state.CreditManagementReducer.showClosedLoans
});

const TableView = connect(mapStateToProps)(TableViewObj);

export default TableView;
