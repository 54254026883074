import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import { Institutions } from "../../services/ApiLib";
import { isBafs } from "../../services/Auth";
import DropdownFilter from "./DropdownFilter";
import logger from "../../services/logger";

function InstitutionDropdownFilter({
  filterUpdate,
  filterValue,
  placeholder,
  selectedInstitution,
  search
}) {
  const [institutionOptions, setInstitutionOptions] = useState([]);

  useEffect(() => {
    async function getInstitutionOptions() {
      try {
        const institutions = await Institutions.asyncRead({});
        const options = [
          {
            key: "Show All Institutions",
            value: "Show All",
            text: "Show All Institutions"
          }
        ];
        institutions.data.forEach(inst => {
          options.push({
            key: inst.uuid,
            value: inst.uuid,
            text: search ? `${inst.institution_id} - ${inst.name}` : inst.name
          });
        });
        setInstitutionOptions(options);
      } catch (err) {
        logger.error("Error:", err);
      }
    }
    getInstitutionOptions();
  }, [search]);

  if (isBafs() && institutionOptions.length) {
    return (
      <DropdownFilter
        onChange={filterUpdate}
        options={institutionOptions}
        loader={<Icon name="spinner" size="large" loading />}
        value={selectedInstitution}
        filterValue={filterValue}
        placeholder={placeholder}
        search={search}
      />
    );
  }
  return <div />;
}

InstitutionDropdownFilter.propTypes = {
  filterUpdate: PropTypes.func.isRequired,
  filterValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  selectedInstitution: PropTypes.string.isRequired,
  search: PropTypes.bool
};

export default InstitutionDropdownFilter;
