import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { prettyDate } from "../../../../../../../../../../services/DateTime";

function DeferralEndDateCell({ value, column }) {
  if (value !== undefined && value !== null) {
    return (
      <div>
        <div>{prettyDate(value)}</div>
        <Button circular basic icon="edit" onClick={column?.openModal} />
      </div>
    );
  }

  return <>&mdash;</>;
}

DeferralEndDateCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  column: PropTypes.shape()
};

export default DeferralEndDateCell;
