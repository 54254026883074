import React, { LegacyRef, useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useOutOfBounds } from "../../../util/viewport";

/**
 * Renders a series of buttons and a dropdown that together allow to navigate trough items inside a table.
 * Should be used always together with a SortingTable component
 */
export function PaginationView(props: PaginationViewProps) {
  const {
    className = "",
    current,
    defaultCurrent = 1,
    defaultPageSize = 10,
    pageSize,
    pageSizeOptions = [10, 20, 50, 100],
    total = 0,
    pageButtonsQty = 5,
    onPageChange,
    onPageSizeChange
  } = props;

  const [currentPage, setCurrentPage] = React.useState(
    current || defaultCurrent
  );

  const [pageSizeState, setPageSizeState] = React.useState(
    pageSize || defaultPageSize
  );

  useEffect(() => {
    if (current) {
      setCurrentPage(current);
    }
  }, [current]);

  useEffect(() => {
    if (pageSize) {
      setPageSizeState(pageSize);
    }
  }, [pageSize]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (!onPageChange) return;
    onPageChange(page);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSizeState(newPageSize);
    if (onPageSizeChange) {
      onPageSizeChange(newPageSize);
    }
    setCurrentPage(1);
    if (onPageChange) {
      onPageChange(1);
    }
  };

  return (
    <div className={`pagination ${className}`}>
      <PaginationMenu
        current={currentPage}
        defaultCurrent={defaultCurrent}
        pageSize={pageSizeState}
        defaultPageSize={pageSizeState}
        total={total}
        onPageChange={handlePageChange}
        pageButtonsQty={pageButtonsQty}
      />

      <PaginationOptions
        pageSize={pageSizeState}
        defaultPageSize={pageSizeState}
        pageSizeOptions={pageSizeOptions}
        onPageSizeChange={handlePageSizeChange}
      />
    </div>
  );
}

export interface PaginationViewProps {
  // Custom className for styling
  className?: string;
  //Current page number
  current?: number;
  /** Default current page number (1) */
  defaultCurrent?: number;
  /** Default number of data items per page (10) */
  defaultPageSize?: number;
  /** Number of data items per page */
  pageSize?: number;
  /** Specify the sizeChanger options [10, 20, 50, 100] */
  pageSizeOptions?: number[];
  /** How many page number buttons wanted */
  pageButtonsQty?: number;
  /** Total number of data items (0) */
  total?: number;
  /** Called when the page number or pageSize is changed, and it takes the resulting page number and pageSize as its arguments */
  onPageChange?: (page: number) => void;
  /**  Called when pageSize is changed */
  onPageSizeChange?: (pageSize: number) => void;
}

/**
 * Renders a set of buttons allowing the user to navigate trough the pages.
 * Targeting back, forth and the closest pages of the current page the user is located
 */
const PaginationMenu = ({
  current: _current = 1,
  defaultCurrent = 1,
  pageSize: _pageSize,
  defaultPageSize = 10,
  total = 0,
  pageButtonsQty = 5,
  onPageChange
}: PaginationViewProps) => {
  const current = _current || defaultCurrent;
  const pageSize = _pageSize || defaultPageSize;

  const MAX_PAGE_COUNT = useMemo(() => {
    return Math.ceil(total / pageSize);
  }, [total, pageSize]);

  const MIN_PAGE_BUTTON = 1;
  const MAX_PAGE_BUTTON = useMemo(() => {
    return MAX_PAGE_COUNT > pageButtonsQty ? pageButtonsQty : MAX_PAGE_COUNT;
  }, [MAX_PAGE_COUNT, pageButtonsQty]);

  const [pageListStart, setPageListStart] = React.useState(MIN_PAGE_BUTTON);
  const [pageListEnd, setPageListEnd] = React.useState(MAX_PAGE_BUTTON);

  useEffect(() => {
    let buttonsLeftOfCurrent = Math.floor(MAX_PAGE_BUTTON / 2);
    let buttonsRightOfCurrent = Math.floor(MAX_PAGE_BUTTON / 2);

    if (!(MAX_PAGE_BUTTON % 2)) {
      // Handling even number of pages [1] [2] [*3*] [4]
      buttonsRightOfCurrent -= 1;
    }

    let additionalButtonsOnRight = 0; // if current page is the first one, we want more pages to the right and vice-versa
    let additionalButtonsOnLeft = 0;

    if (current - buttonsLeftOfCurrent < MIN_PAGE_BUTTON) {
      additionalButtonsOnRight = buttonsLeftOfCurrent;
      buttonsLeftOfCurrent = (MIN_PAGE_BUTTON - current) * -1;
      additionalButtonsOnRight =
        additionalButtonsOnRight - buttonsLeftOfCurrent;
    }

    if (current + buttonsRightOfCurrent > MAX_PAGE_COUNT) {
      additionalButtonsOnLeft = buttonsRightOfCurrent;
      buttonsRightOfCurrent = MAX_PAGE_COUNT - current;
      additionalButtonsOnLeft = additionalButtonsOnLeft - buttonsRightOfCurrent;
    }

    buttonsLeftOfCurrent +=
      current - (buttonsLeftOfCurrent + additionalButtonsOnLeft) >=
      MIN_PAGE_BUTTON
        ? additionalButtonsOnLeft
        : 0;

    buttonsRightOfCurrent +=
      current + (buttonsRightOfCurrent + additionalButtonsOnRight) <=
      MAX_PAGE_COUNT
        ? additionalButtonsOnRight
        : 0;

    setPageListStart(current - buttonsLeftOfCurrent);
    setPageListEnd(current + buttonsRightOfCurrent);
  }, [
    current,
    MAX_PAGE_BUTTON,
    MAX_PAGE_COUNT,
    setPageListStart,
    setPageListEnd
  ]);

  const handlePreviousClick: React.MouseEventHandler<HTMLAnchorElement> =
    event => {
      event.preventDefault();
      const page = current - 1;
      if (page < pageListStart && pageListStart > 1) {
        setPageListStart(pageListStart - 1);
        setPageListEnd(pageListEnd - 1);
      }
      if (page >= 1) {
        if (!onPageChange) return;
        onPageChange(page);
      }
    };

  const handleNextClick: React.MouseEventHandler<HTMLAnchorElement> = event => {
    event.preventDefault();
    const page = current + 1;

    if (page > pageListEnd && pageListEnd < Math.ceil(total / pageSize)) {
      setPageListStart(pageListStart + 1);
      setPageListEnd(pageListEnd + 1);
    }
    if (page <= Math.ceil(total / pageSize)) {
      if (!onPageChange) return;
      onPageChange(page);
    }
  };

  const handlePageClick =
    (page: number): React.MouseEventHandler<HTMLAnchorElement> =>
    event => {
      event.preventDefault();
      if (!onPageChange) return;
      onPageChange(page);
    };

  return (
    <div className="ui menu pagination-menu">
      <a href="./#" className="icon item" onClick={handlePreviousClick}>
        <i className="left chevron icon"></i>
      </a>
      {Array.from({ length: pageListEnd - pageListStart + 1 }, (_, i) => (
        <a
          href="./#"
          key={i}
          className={`item ${current === pageListStart + i ? "active" : ""}`}
          onClick={handlePageClick(pageListStart + i)}
        >
          {pageListStart + i}
        </a>
      ))}
      <a href="./#" className="icon item" onClick={handleNextClick}>
        <i className="right chevron icon"></i>
      </a>
      {total > 0 && (
        <span className="results item">
          <FormattedMessage
            id="FROM_TO_OF_TOTAL_ITEMS"
            description="example: 11 - 20 of 100 items"
            defaultMessage="{from} - {to} of {total} items"
            values={{
              from: pageSize * (current - 1) + 1,
              to: pageSize * current > total ? total : pageSize * current,
              total
            }}
          />
        </span>
      )}
    </div>
  );
};

/**
 * Renders an small dropdown where the total of items per page can be selected
 */
const PaginationOptions = ({
  pageSize,
  pageSizeOptions = [10, 20, 50, 100],
  defaultPageSize,
  onPageSizeChange
}: PaginationViewProps) => {
  const [pageSizeValue, setPageSizeValue] = React.useState(
    pageSize || defaultPageSize
  );

  useEffect(() => {
    if (pageSize) {
      setPageSizeValue(pageSize);
    }
  }, [pageSize]);

  const handleSizeChange = (newPageSize: number) => {
    setPageSizeValue(newPageSize);
    if (!onPageSizeChange) return;
    onPageSizeChange(newPageSize);
  };

  const [dropdownOpened, setDropdownOpened] = React.useState(false);

  const handleDropdownClick = () => {
    setDropdownOpened(!dropdownOpened);
  };

  const [componentRef, outOfBounds] = useOutOfBounds();

  const renderUpward = outOfBounds.bottom > 0;

  return (
    <div className="ui list horizontal pagination-options">
      <span className="item label">
        <FormattedMessage id="VIEW" />
      </span>

      <div
        className={`ui selection dropdown item ${
          renderUpward ? "upward" : ""
        } ${dropdownOpened ? "active visible" : ""}`}
        onClick={handleDropdownClick}
      >
        <input type="hidden" name="pageSize" />
        <i className="dropdown icon"></i>
        <div className="text">{pageSizeValue}</div>
        <div
          ref={componentRef as LegacyRef<HTMLDivElement>}
          className={`scrollhint menu ${
            dropdownOpened ? "transition visible" : ""
          }`}
        >
          {pageSizeOptions.map(option => (
            <div
              key={option}
              className={`item ${pageSizeValue === option ? "active" : ""}`}
              onClick={() => handleSizeChange(Number(option))}
            >
              {option}
            </div>
          ))}
        </div>
      </div>

      <span className="item label">
        <FormattedMessage id="ITEMS_PER_PAGE" />
      </span>
    </div>
  );
};
