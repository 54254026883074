import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";
import { Button } from "semantic-ui-react";

function ViewDocButtonObj(props) {
  return (
    <a
      href={`/docs/viewer/${props.docDetails.uuid}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      <Button basic circular icon>
        <FontAwesome name="eye" />
      </Button>
    </a>
  );
}

ViewDocButtonObj.propTypes = {
  docDetails: PropTypes.shape({
    tsn: PropTypes.string,
    doc_category: PropTypes.string,
    description: PropTypes.string,
    doc_subcategory: PropTypes.string,
    upload_date: PropTypes.string,
    acct_no: PropTypes.string,
    uuid: PropTypes.string,
    branch_no: PropTypes.string,
    tax_id: PropTypes.string,
    approved: PropTypes.bool,
    name: PropTypes.string
  }).isRequired
};

const ViewDocButton = connect()(ViewDocButtonObj);

export default ViewDocButton;
