import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

function InstitutionForm({ institution, onInputChange }) {
  return (
    <Form>
      <Form.Group>
        <Form.Input
          name="institution_name"
          label="Institution"
          width={16}
          value={institution.institution_name || ""}
          onChange={onInputChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Input
          name="primary_first_name"
          label="Primary Contact First Name"
          width={4}
          value={institution.primary_first_name || ""}
          onChange={onInputChange}
        />
        <Form.Input
          name="primary_last_name"
          label="Primary Contact Last Name"
          width={4}
          value={institution.primary_last_name || ""}
          onChange={onInputChange}
        />
        <Form.Input
          name="primary_email"
          label="Primary Contact Email"
          width={4}
          value={institution.primary_email || ""}
          onChange={onInputChange}
        />
        <Form.Input
          name="primary_phone"
          label="Primary Contact Phone"
          width={4}
          value={institution.primary_phone || ""}
          onChange={onInputChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Input
          name="secondary_first_name"
          label="Secondary Contact First Name"
          width={4}
          value={institution.secondary_first_name || ""}
          onChange={onInputChange}
        />
        <Form.Input
          name="secondary_last_name"
          label="Secondary Contact Last Name"
          width={4}
          value={institution.secondary_last_name || ""}
          onChange={onInputChange}
        />
        <Form.Input
          name="secondary_email"
          label="Secondary Contact Email"
          width={4}
          value={institution.secondary_email || ""}
          onChange={onInputChange}
        />
        <Form.Input
          name="secondary_phone"
          label="Secondary Contact Phone"
          width={4}
          value={institution.secondary_phone || ""}
          onChange={onInputChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Input
          name="website"
          label="Website"
          width={8}
          value={institution.website || ""}
          onChange={onInputChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Input
          name="mailing_primary_address"
          label="Mailing Address Line 1"
          width={8}
          value={institution.mailing_primary_address || ""}
          onChange={onInputChange}
        />
        <Form.Input
          name="mailing_primary_address_2"
          label="Mailing Address Line 2"
          width={8}
          value={institution.mailing_primary_address_2 || ""}
          onChange={onInputChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Input
          name="mailing_city"
          label="City"
          width={6}
          value={institution.mailing_city || ""}
          onChange={onInputChange}
        />
        <Form.Input
          name="mailing_state"
          label="State"
          width={6}
          value={institution.mailing_state || ""}
          onChange={onInputChange}
        />
        <Form.Input
          name="mailing_postal_code"
          label="ZIP"
          width={6}
          value={institution.mailing_postal_code || ""}
          onChange={onInputChange}
        />
      </Form.Group>
    </Form>
  );
}

InstitutionForm.propTypes = {
  institution: PropTypes.objectOf(PropTypes.string).isRequired,
  onInputChange: PropTypes.func.isRequired
};

export default InstitutionForm;
