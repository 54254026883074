import { Loan } from "../../../../../../../../../../services/RTKQuery/ModelTypes/loan";
import { StateUpdate } from "../components/SideSheetBody/services/types";

export const createUpdatedLoanStateFromUserInputs = (
  savedLoanDetails: Partial<Loan>,
  loanDetails: Partial<Loan>,
  userSelections: StateUpdate[]
): Partial<Loan> => {
  const partiallyUpdatedState = {
    ...loanDetails,
    ...userSelections.reduce((acc, userSelection) => {
      const { value, stateColumnName } = userSelection;
      if (value !== undefined) {
        acc[stateColumnName] = value;
      }
      return acc;
    }, {})
  };
  const fullyUpdatedState = handlePropertiesWithUndefinedValue(
    savedLoanDetails,
    userSelections,
    partiallyUpdatedState
  );
  return fullyUpdatedState;
};

const handlePropertiesWithUndefinedValue = (
  savedLoanDetails: Partial<Loan>,
  userSelections: StateUpdate[],
  partiallyUpdatedState: Partial<Loan>
) => {
  const newState = Object.assign(partiallyUpdatedState, {});
  userSelections.map(userSelection => {
    const { stateColumnName, value } = userSelection;
    if (value === undefined) {
      if (propertyIsNotInOriginalLoanData(stateColumnName, savedLoanDetails)) {
        delete newState[stateColumnName];
        return;
      }
      if (propertyHasStringTypeValue(stateColumnName)) {
        newState[stateColumnName] = "";
        return;
      }
      if (propertyHasNumberTypeValue(stateColumnName))
        newState[stateColumnName] = 0;
    }
  });
  return newState;
};

const propertyIsNotInOriginalLoanData = (
  columnName: string,
  savedLoanDetails: Partial<Loan>
) => {
  return !(columnName in savedLoanDetails);
};

const propertyHasStringTypeValue = (columnName: string) => {
  return [
    "loan_class",
    "loan_officer",
    "loan_type",
    "purpose",
    "gov_guarantee_agency_program",
    "gov_guarantee_program",
    "origination_fee_type"
  ].includes(columnName);
};

const propertyHasNumberTypeValue = (columnName: string) => {
  return ["amount_requested", "origination_fee"].includes(columnName);
};
