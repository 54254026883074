import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Header, List } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import { FormatCurrency, FormatPercent } from "num-format";
import { isNumber } from "lodash";
import {
  readableNumFormat,
  forceValue
} from "../../../../../../services/FormElements";
import { BorderedContainer } from "../../../../../../components/CustomUIElements";
import { userCanViewPage } from "../../../../../../services/Auth";
import {
  PaymentScheduleProps,
  LoanRequestsProps,
  InterestScheduleProps
} from "./PropTypes";
import { prettyDate } from "../../../../../../services/DateTime";

const permissionsMap = {
  /**
   * TODO These links were intentionally added with issue #5723, and removed
   * with issue #7066.
   * I am leaving the logic in place for when we add them back, and disallowing
   * the links here in the meantime.
   *
   * https://github.com/bafsllc/clearwater/issues/5723
   * https://github.com/bafsllc/clearwater/issues/7066
   */
  "/loan_management/:uuid/details/interest": {
    Admin: false,
    BAFS: false,
    Institution: false
  },
  "/loan_management/:uuid/details/payments": {
    Admin: false,
    BAFS: false,
    Institution: false
  }
};

function LoanTable({ index, interestSchedule, loanRequest, paymentSchedule }) {
  const loanRequestUuid = loanRequest?.uuid || null;
  const [originalAmount, setOriginalAmount] = useState(
    loanRequest?.original_amount || ""
  );

  const { pathname } = useLocation();

  const setCurrency = number => {
    if (!isNumber(number)) {
      const setToNum = parseFloat(number.split(",").join(""));

      return number !== ""
        ? FormatCurrency(setToNum, false)
        : FormatCurrency(0, false);
    }

    return FormatCurrency(number, false);
  };

  useEffect(() => {
    if (loanRequest?.original_amount) {
      setOriginalAmount(readableNumFormat(loanRequest?.original_amount));
    }
  }, [loanRequest?.original_amount]);

  const accrualValue =
    loanRequest?.accrual_basis !== interestSchedule.accrual_basis
      ? ""
      : loanRequest?.accrual_basis;

  return (
    <BorderedContainer>
      <Grid padded>
        <Grid.Row verticalAlign="bottom">
          <Grid.Column width={8}>
            <Header as="h2" textAlign="left">
              {`Loan Request ${index + 1}`}
            </Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns="4">
          <Grid.Column>
            <List>
              <List.Item>
                <List.Header>Loan Class</List.Header>
                {loanRequest?.loan_class || ""}
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List>
              <List.Item>
                <List.Header>Loan Purpose</List.Header>
                {loanRequest?.purpose || ""}
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List>
              <List.Item>
                <List.Header>Requested Loan Amount</List.Header>
                {setCurrency(originalAmount)}
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List>
              <List.Item>
                <List.Header>Requested Interest Rate</List.Header>
                {FormatPercent(loanRequest?.original_rate || 0, 3)}
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns="4">
          <Grid.Column>
            <List>
              <List.Item>
                <List.Header>Loan Type</List.Header>
                {loanRequest?.loan_type || ""}
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List>
              <List.Item>
                <List.Header>Rate Type</List.Header>
                {loanRequest?.rate_type || ""}
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List>
              <List.Item>
                <List.Header>Rate Index</List.Header>
              </List.Item>
              {loanRequest?.rate_index || ""}
            </List>
          </Grid.Column>
          <Grid.Column>
            <List>
              <List.Item>
                <List.Header>Adjustment Period</List.Header>
                {interestSchedule?.change_period || ""}
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns="4" verticalAlign="bottom">
          <Grid.Column>
            <List>
              <List.Item>
                <List.Header>Origination Fee</List.Header>
                {loanRequest?.origination_fee || ""}
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List>
              <List.Item>
                <List.Header>Variable Rate Margin</List.Header>

                {interestSchedule.margin
                  ? FormatPercent(interestSchedule.margin, 3)
                  : ""}
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List>
              <List.Item>
                <List.Header>Variable Rate Floor</List.Header>
                {loanRequest?.rate_floor
                  ? FormatPercent(loanRequest?.rate_floor, 3)
                  : ""}
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List>
              <List.Item>
                <List.Header>Variable Rate Ceiling</List.Header>
                {loanRequest?.rate_ceiling
                  ? FormatPercent(loanRequest?.rate_ceiling, 3)
                  : ""}
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="4">
          <Grid.Column>
            <List>
              <List.Item>
                <List.Header>Accrual Basis</List.Header>
                {forceValue(accrualValue)}
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="4">
          <Grid.Column>
            <List>
              <List.Item>
                <List.Header>Payment Type</List.Header>
                {paymentSchedule.payment_type || ""}
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List>
              <List.Item>
                <List.Header>Payment Period</List.Header>
                {paymentSchedule.payment_frequency_type || ""}
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List>
              <List.Item>
                <List.Header># of Payments</List.Header>
                {paymentSchedule.total_number_of_payments || ""}
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List>
              <List.Item>
                <List.Header>Amortization Term Months</List.Header>
                {loanRequest?.amortization_period || ""}
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <List>
              <List.Item>
                <List.Header>Description</List.Header>
                {loanRequest?.description || ""}
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <List>
              <List.Item>
                <List.Header>Created</List.Header>
                {prettyDate(loanRequest?.created_datetime)}
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="4">
          <Grid.Column>
            <List>
              <List.Item>
                <List.Header>Balloon Payment</List.Header>
                {loanRequest?.balloon || ""}
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {userCanViewPage(
            "/loan_management/:uuid/details/payments",
            permissionsMap
          ) && (
            <Grid.Column width="5">
              <Link
                to={{
                  pathname: `/loan_management/${loanRequestUuid}/details/payments`,
                  state: {
                    backlink: pathname,
                    backtext: "Return to credit requests"
                  }
                }}
              >
                Go to full payment schedules list
              </Link>
            </Grid.Column>
          )}
          {userCanViewPage(
            "/loan_management/:uuid/details/interest",
            permissionsMap
          ) && (
            <Grid.Column width="5">
              <Link
                to={{
                  pathname: `/loan_management/${loanRequestUuid}/details/interest`,
                  state: {
                    backlink: pathname,
                    backtext: "Return to credit requests"
                  }
                }}
              >
                Go to full interest schedules list
              </Link>
            </Grid.Column>
          )}
          {userCanViewPage("/loan_management/:uuid/details/balance") && (
            <Grid.Column width="5" floated="right" textAlign="right">
              <Link
                to={{
                  pathname: `/loan_management/${loanRequestUuid}/details/balance`
                }}
                target="_blank"
              >
                <i className="linkify icon" />
                View in Loan Management
              </Link>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </BorderedContainer>
  );
}

LoanTable.propTypes = {
  index: PropTypes.number.isRequired,
  paymentSchedule: PaymentScheduleProps.isRequired,
  loanRequest: LoanRequestsProps.isRequired,
  interestSchedule: InterestScheduleProps.isRequired
};

export default LoanTable;
