import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Button, Icon } from "semantic-ui-react";

import ConfirmationModal from "../../../../../../../../components/Modals/components/ConfirmationModal";
import { LoanTransactionHistory } from "../../../../../../../../services/ApiLib/index";
import { prettyDate } from "../../../../../../../../services/DateTime";
import { permCheck } from "../../../../../../../../services/Auth";
import logger from "../../../../../../../../services/logger";

export class TransRevButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasRecalPerm: permCheck(["admin", "recalc_testing"]),
      modalOpen: false
    };
    this.cancel = this.cancel.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  cancel() {
    this.setState({ modalOpen: false });
  }

  confirm() {
    const { lookup, tran } = this.props;
    const { hasRecalPerm } = this.state;
    this.setState({ modalOpen: false });
    if (hasRecalPerm) {
      LoanTransactionHistory.removeV2(
        () => lookup(),
        () => logger.error("issue removing transaction from history"),
        tran.loan_uuid,
        tran.uuid,
        { effective_date: tran.effective_date }
      );
    } else {
      LoanTransactionHistory.remove(
        () => lookup(),
        () => logger.error("issue removing transaction from history"),
        tran.loan_uuid,
        tran.uuid,
        tran.institution_uuid
      );
    }
  }

  render() {
    const { tran } = this.props;
    const { modalOpen, hasRecalPerm } = this.state;
    return (
      <>
        <Button
          circular
          basic
          icon={<Icon name="close" color="green" />}
          onClick={() => this.setState({ modalOpen: true })}
        />
        {modalOpen ? (
          <ConfirmationModal
            handleCancel={this.cancel}
            handleConfirm={this.confirm}
            showLogo={false}
            yesButtonLabel={
              hasRecalPerm ? "Delete Transaction V2" : "Delete Transaction"
            }
            prompt="Transaction Deletion"
            open
            warningDetails={`You are about to reverse a transaction, dated ${prettyDate(
              tran.effective_date
            )}, with amount of ${
              tran.tran_amount
            } This will cause the account to be recalculated and will likely result in adjustment transactions. Are you sure you want to continue?`}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

TransRevButton.propTypes = {
  lookup: PropTypes.func.isRequired,
  tran: PropTypes.shape().isRequired
};

export default connect()(TransRevButton);
