/* eslint no-use-before-define: ["error", { "functions": false }] */

import TypeCheck from "typecheck-extended";
import ApiEndpoints from "../../../ApiEndpoints";
import FetchService, { asyncFetchService } from "../../../FetchService";
import logger from "../../../logger";
import { FormatUrlV2 } from "../../../FormatUrl";
import {
  RemoveNullKeys,
  ConcatQueryParams,
  PaginationParams
} from "../Utilities";
import getByEntityUuid from "./services/getByEntityUuid";

export function rspToCallback(rsp, callbacks) {
  TypeCheck(rsp, "object");
  TypeCheck(callbacks.onSuccess, "function", false);
  if (callbacks.onSuccess) {
    callbacks.onSuccess(rsp, callbacks.callbackData);
  }
}

function add(jwt, onSuccess, onError, body, callbackData) {
  TypeCheck(jwt, "string", false);
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(body, "object");
  const url = ApiEndpoints.baseUri + ApiEndpoints.loan_apps;
  const callbacks = { callbackData, onSuccess };
  FetchService("POST", url, rspToCallback, onError, body, callbacks);
}

async function asyncAddDefault(body) {
  TypeCheck(body, "object");

  const convertedBody = RemoveNullKeys(body);
  const url = ApiEndpoints.baseUri + ApiEndpoints.loan_apps_create_default;
  return asyncFetchService("POST", url, convertedBody);
}

function get(jwt, onSuccess, onError, uuid, callbackData, filters, pagination) {
  TypeCheck(jwt, "string", false);
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string", false);
  TypeCheck(callbackData, "object", false);
  TypeCheck(filters, "object", false);
  TypeCheck(pagination, "object", false);

  if (filters && filters.entityUuid) {
    // See Note on getByEntityUuid()
    const origLoanAppsParams = {
      callbackData,
      filters,
      onError,
      onSuccess,
      pagination,
      uuid
    };
    getByEntityUuid(origLoanAppsParams);
    return;
  }

  let params = "";
  if (filters) {
    params = ConcatQueryParams(filters);
  }
  if (pagination) {
    params = PaginationParams(params, pagination);
  }

  let url;
  if (uuid) {
    url = FormatUrlV2(ApiEndpoints.loan_app, { uuid });
  } else {
    url = ApiEndpoints.baseUri + ApiEndpoints.loan_apps + params;
  }

  const callbacks = { callbackData, onSuccess };
  FetchService("GET", url, rspToCallback, onError, null, callbacks);
}

async function read(filters) {
  const funcName = "====  LoanApp.read(filters)  ====";
  logger.debug(funcName, [filters]);
  TypeCheck(filters.loanAppUuid, "string", false);

  const filterCount = Object.keys(filters).length;
  if ((filters.loanAppUuid && filterCount > 1) || filterCount === 0) {
    logger.error("Error: Invalid Filter Combination");
    // TODO: [#5740] Put a checkable status here.
    const errorRsp = {};
    return errorRsp;
  }

  let url;
  /**
   * order_by receives an object containing the header the user wishes to sort by
   * and replaces 'ascending' with 1, and 'descending' with -1 so that it matches
   * this format: &order_by=phase,1,institution_id,-1
   */
  const newOrderObject = {};
  if (filters.order_by) {
    const orderKeys = Object.keys(filters.order_by);
    for (let i = 0; i < orderKeys.length; i += 1) {
      if (filters.order_by[orderKeys[i]] === "ascending") {
        newOrderObject[orderKeys[i]] = 1;
      }
      if (filters.order_by[orderKeys[i]] === "descending") {
        newOrderObject[orderKeys[i]] = -1;
      }
    }
  }
  const filtersWithoutOrderBy = { ...filters };
  delete filtersWithoutOrderBy.order_by;
  if (filtersWithoutOrderBy.loanAppUuid) {
    const endpoint = ApiEndpoints.loan_app;
    url = FormatUrlV2(endpoint, { uuid: filtersWithoutOrderBy.loanAppUuid });
  } else {
    const endpoint = ApiEndpoints.loan_apps;
    let appendToUrl;
    if (filtersWithoutOrderBy.pagination) {
      const filtersWithoutPagination = { ...filtersWithoutOrderBy };
      delete filtersWithoutPagination.pagination;
      appendToUrl = PaginationParams(
        ConcatQueryParams(filtersWithoutPagination),
        filtersWithoutOrderBy.pagination
      );
    } else {
      appendToUrl = ConcatQueryParams(filtersWithoutOrderBy);
    }
    if (filters.order_by) {
      let orderByString = "&order_by=";
      const orderKeys = Object.keys(newOrderObject);
      for (let i = 0; i < orderKeys.length; i += 1) {
        if (i < orderKeys.length - 1) {
          orderByString += `${orderKeys[i]},${newOrderObject[orderKeys[i]]},`;
        }
        if (i === orderKeys.length - 1) {
          orderByString += `${orderKeys[i]},${newOrderObject[orderKeys[i]]}`;
        }
      }
      appendToUrl += orderByString;
    }
    url = FormatUrlV2(endpoint) + appendToUrl;
  }

  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

function getTotalAndCount(jwt, onSuccess, onError, uuid, callbackData) {
  TypeCheck(jwt, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string", false);
  const params = uuid ? `?institution_uuid=${uuid}` : "";
  const url =
    ApiEndpoints.baseUri + ApiEndpoints.loan_apps_total_count + params;
  const callbacks = { callbackData, onSuccess };
  FetchService("GET", url, rspToCallback, onError, null, callbacks);
}

async function asyncGetTotalAndCount(uuid) {
  TypeCheck(uuid, "string", false);
  const params = uuid ? `?institution_uuid=${uuid}` : "";
  const url =
    ApiEndpoints.baseUri + ApiEndpoints.loan_apps_total_count + params;
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

function update(jwt, onSuccess, onError, uuid, body, callbackData) {
  TypeCheck(onSuccess, "function", false);
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string");
  TypeCheck(body, "object");
  const callbacks = { callbackData, onSuccess };
  const url = FormatUrlV2(ApiEndpoints.loan_app, { uuid });
  FetchService("PUT", url, rspToCallback, onError, body, callbacks);
}

async function asyncUpdate(filters, body) {
  TypeCheck(filters.uuid, "string", true);
  TypeCheck(body, "object");
  TypeCheck(filters, "object");

  const url = FormatUrlV2(ApiEndpoints.loan_app, { uuid: filters.uuid });
  const convertedBody = RemoveNullKeys(body);

  return asyncFetchService("PUT", url, convertedBody, null, false);
}

async function asyncAddRelatedDetails(filters, body) {
  TypeCheck(filters.loanAppUuid, "string", true);
  TypeCheck(body, "object");
  TypeCheck(filters, "object");

  const url = FormatUrlV2(ApiEndpoints.loanRelatedDetails, {
    loan_uuid: filters.loanAppUuid
  });
  const convertedBody = RemoveNullKeys(body);

  return asyncFetchService("POST", url, convertedBody, null, false);
}

async function asyncGetRelatedDetails(filters) {
  TypeCheck(filters.loanAppUuid, "string", true);
  TypeCheck(filters, "object");

  const url = FormatUrlV2(ApiEndpoints.loanRelatedDetails, {
    loan_uuid: filters.loanAppUuid
  });
  const customPaginatedUrl =
    url + ConcatQueryParams({ page_number: 0, page_size: 9999 });
  return asyncFetchService("GET", customPaginatedUrl);
}

async function asyncRemove(uuid, loanUuid) {
  TypeCheck(uuid, "string", true);
  TypeCheck(loanUuid, "string", true);
  const url = FormatUrlV2(ApiEndpoints.loanRelatedDetail, {
    loan_uuid: loanUuid,
    uuid
  });
  const rsp = await asyncFetchService("DELETE", url);
  return rsp;
}

export default {
  add,
  asyncAddDefault,
  get,
  getTotalAndCount,
  asyncGetTotalAndCount,
  read,
  update,
  asyncUpdate,
  asyncRemove,
  asyncAddRelatedDetails,
  asyncGetRelatedDetails
};
