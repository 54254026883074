import React from "react";
import PropTypes from "prop-types";
import { FormInput } from "../../../../../../../services/FormElements";

function PPPFormInputs({ set, update, fields, inputs, wrapper = null }) {
  return (
    <>
      {inputs.map(input =>
        wrapper ? (
          <div style={{ marginBottom: "10px" }} key={wrapper + input.name}>
            <FormInput
              key={input.name}
              input={input}
              set={set}
              update={update}
              fields={fields}
            />
          </div>
        ) : (
          <FormInput
            key={input.name}
            input={input}
            set={set}
            update={update}
            fields={fields}
          />
        )
      )}
    </>
  );
}

PPPFormInputs.propTypes = {
  fields: PropTypes.shape(),
  inputs: PropTypes.arrayOf(PropTypes.shape()),
  set: PropTypes.func,
  update: PropTypes.func,
  wrapper: PropTypes.string
};

export default PPPFormInputs;
