import React from "react";
import { Route } from "react-router-dom";
import DocTitle from "../../components/DocTitle";
import CurrentRequests from "./scenes";

function LoanReviewRequestsObj() {
  return (
    <div>
      <DocTitle title="Loan Review Requests - BLAST Portal">
        <Route
          exact
          path="/loan-review-requests"
          render={() => <CurrentRequests source="Loan Review" />}
        />
      </DocTitle>
    </div>
  );
}

export default LoanReviewRequestsObj;
