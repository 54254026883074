import React, { useEffect, useState, useRef } from "react";
import { useUpdateLoanAppSettlementSheetMutation } from "../../../../../../services/RTKQuery/loanAppSettlementSheet";

export const UpdateTest = ({ loanUuid, itemUuid, setStatusInParent }) => {
  const statusUnknown = "❓Unknown Status";
  const statusPass = "✅ Pass";
  const statusFail = "❌ Fail";

  const [statusUpdate, setStatusUpdate] = useState(statusUnknown);
  const [detailsText, setDetailsText] = useState("statusUnknown");

  const [updateLoanAppSettlementSheetMutation] =
    useUpdateLoanAppSettlementSheetMutation();
  useEffect(() => {
    const newReasonStr = "I'm just a reason, yes I'm only a reason.";
    updateLoanAppSettlementSheetMutation({
      loan_uuid: loanUuid,
      uuid: itemUuid,
      reason_other: newReasonStr
    })
      .unwrap()
      .then(fulfilled => {
        if (fulfilled?.data?.reason_other === newReasonStr) {
          setStatusUpdate(statusPass);
          setDetailsText(
            `: reason_other updated to: ${fulfilled.data.reason_other}`
          );
          setStatusInParent("Pass");
        } else {
          setStatusUpdate(statusFail);
          setStatusInParent("Fail");
        }
      })
      .catch(error => {
        console.error("Fail: useUpdateLoanAppSettlementSheetMutation", error);
        setStatusUpdate(statusFail);
      });
  }, [
    itemUuid,
    loanUuid,
    setStatusInParent,
    updateLoanAppSettlementSheetMutation
  ]);

  return (
    <>
      <b>{statusUpdate}</b>
      <small>{detailsText}</small>
    </>
  );
};
