import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Segment,
  Table,
  Form,
  Icon,
  Checkbox,
  Button
} from "semantic-ui-react";
import { isBafs } from "../../../services/Auth";
import { prettyDate } from "../../../services/DateTime";
import {
  Entities,
  LoanApp,
  Institutions,
  Relationships
} from "../../../services/ApiLib";
import PaginationControls, {
  emptyPagination
} from "../../../components/Pagination";
import { ConcatName } from "../../../services/Entities";
import ErrorReporter from "../../../services/ErrorReporter";
import { relationshipTypes } from "../../../services/Constants/relationshipTypes";
import FetchUserNameV2 from "../../../services/UserInfo";
import DropdownFilter from "../../../components/Dropdown/DropdownFilter";
import InstitutionDropdownFilter from "../../../components/Dropdown/InstitutionDropdownFilter";
import { DollarFormat } from "../../../components/CustomUIElements";
import {
  HEADER_LOAN_COLUMNS,
  REQUEST_FILTER_STATUS,
  REQUEST_FILTER_PHASE,
  REQUEST_FILTER_SCOPE,
  SHOW_ALL_TEXT,
  REQUEST_FILTER_ORIGIN
} from "../constants";

const paginationName = "mainLanding";

const headers = [
  "Est. Close Date",
  "App Id",
  "Borrower",
  "Institution",
  "Amount",
  "Scope",
  "Date Created",
  "Date Submitted",
  "Assigned To",
  "Phase",
  "Status"
];

const initialFilter = {
  status: "",
  phase: "",
  scope: "",
  institution_uuid: "",
  origin: ""
};

export function CurrentRequestsObj({ source }) {
  const [lookup, setLookup] = useState("");
  const [showWithdrawnApplications, setShowWithdrawnApplications] =
    useState(false);
  const [sortType, setSortType] = useState({
    "Date Created": "descending"
  });
  const [lastPage, setLastPage] = useState(true);
  const [filters, setFilter] = useState(initialFilter);

  const dispatch = useDispatch();
  const {
    entities,
    institutionIds,
    requests,
    users,
    pagination = emptyPagination,
    header,
    hasBorrowerPortal
  } = useSelector(state => ({
    entities: state.CurrentRequestMainReducer.entities,
    institutionIds: state.CurrentRequestMainReducer.institutionIds,
    header: state.CurrentRequestMainReducer.header,
    pagination: state.PaginationReducer[paginationName],
    requests: state.CurrentRequestMainReducer.requests,
    users: state.UserInfoReducer.users,
    hasBorrowerPortal: state.auth.institutionHasBp
  }));

  async function updateEntity(rsp, { uuid }, app) {
    try {
      if (rsp.data[0]) {
        const fetchedEntity = await Entities.read({
          entityUuid: rsp.data[0].child_uuid
        });
        if (
          app.borrower_name === null ||
          app.borrower_name !== ConcatName(fetchedEntity.data)
        ) {
          await LoanApp.asyncUpdate(
            { uuid: app.uuid },
            { borrower_name: ConcatName(fetchedEntity.data) }
          );
        }
        dispatch({
          type: "CURRENT_REQUEST_SAVE_ENTITY_NAME",
          key: uuid,
          value: ConcatName(fetchedEntity.data)
        });
      } else {
        dispatch({
          type: "CURRENT_REQUEST_SAVE_ENTITY_NAME",
          key: uuid,
          value: ConcatName({ company_name: "Company Not Found" })
        });
      }
    } catch (err) {
      ErrorReporter(err);
    }
  }

  async function fetchInstitutionsAndRelationships(app) {
    try {
      const { data } = await Institutions.asyncRead({
        institutionUuid: app.institution_uuid
      });
      dispatch({
        type: "CURRENT_REQUEST_SAVE_INSTITUTION_ID",
        key: app.uuid,
        value: data.institution_id
      });
      const fetchedRelationship = await Relationships.asyncRead({
        bypassParticipationsCheck: true,
        parent_uuid: app.uuid,
        primary_borrower: true,
        rel_type: relationshipTypes.BORROWER
      });
      updateEntity(fetchedRelationship, { uuid: app.uuid }, app);
      if (app.assigned_to_user) {
        const fetchedUserName = await FetchUserNameV2(
          users,
          dispatch,
          app.assigned_to_user
        );
        if (fetchedUserName.error) {
          ErrorReporter(fetchedUserName.error);
        }
      }
    } catch (err) {
      ErrorReporter(err);
    }
  }

  const getData = useCallback(
    async (params = {}) => {
      const convertedOrderBy = {};
      const sortKeys = Object.keys(sortType);
      for (let i = 0; i < sortKeys.length; i += 1) {
        convertedOrderBy[HEADER_LOAN_COLUMNS[sortKeys[i]]] =
          sortType[sortKeys[i]];
      }
      const finalParams = {
        ...params,
        include_totals: true,
        source,
        pagination,
        order_by: convertedOrderBy
      };
      if (!showWithdrawnApplications) {
        finalParams.show = "active_only";
      }
      try {
        const loanApp = await LoanApp.read(finalParams);
        setLastPage(loanApp.metaData.last_page);
        dispatch({
          type: "CURRENT_REQUEST_MAIN_SAVE_DATA",
          requests: loanApp.data
        });
        await Promise.all(
          loanApp.data.map(app => fetchInstitutionsAndRelationships(app))
        );
      } catch (err) {
        ErrorReporter(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination, showWithdrawnApplications, sortType]
  );

  function filterUpdate(value, filter) {
    const newFilter = { ...filters, [filter]: value };
    setFilter(newFilter);
    dispatch({
      type: "PAGINATION_UPDATE_NUMBER",
      name: paginationName,
      number: 0
    });
  }

  useEffect(() => {
    const currentFilter = lookup ? { lookup } : {};
    Object.keys(filters).forEach(prop => {
      if (filters[prop] && filters[prop] !== SHOW_ALL_TEXT) {
        currentFilter[prop] = filters[prop];
      }
    });
    getData(currentFilter);

    return () => {
      dispatch({
        type: "CURRENT_REQUEST_MAIN_SAVE_DATA",
        requests: []
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, getData, lookup, showWithdrawnApplications, sortType]);

  function toggleSortType(type) {
    if (!HEADER_LOAN_COLUMNS[type]) {
      return;
    }
    if (sortType[type] === "ascending") {
      setSortType({
        [type]: "descending"
      });
    } else {
      setSortType({
        [type]: "ascending"
      });
    }
  }

  function openInNewTab(url) {
    window.open(url, "_blank");
  }

  function generateLink(loanRequest) {
    const stepperStatuses = [
      "Draft Application",
      "Pending Review",
      "Lender Review",
      "Accepted"
    ];
    if (
      !stepperStatuses.includes(loanRequest.status) &&
      loanRequest.phase !== "New Credit Application"
    ) {
      const url = `/los/${loanRequest.uuid}/phases/app_details`;
      openInNewTab(url);
    } else {
      const url = `/los/${loanRequest.uuid}/stepper/entity_details`;
      openInNewTab(url);
    }
  }

  function filterResults(value) {
    if (value === lookup) {
      return;
    }
    setLookup(value);
    dispatch({
      type: "PAGINATION_UPDATE_NUMBER",
      name: paginationName,
      number: 0
    });
  }

  function convertToIcon(sortTypeValue) {
    if (sortTypeValue === "ascending") {
      return "arrow up";
    }
    if (sortTypeValue === "descending") {
      return "arrow down";
    }
    return "arrows alternate vertical";
  }

  // FORGIVE: If we add more differences beyond removing BAFS-only cols for Institution tables,
  // I'd recommend a different solution to managing the diff. For now, this is appropriate.
  const tableHeaders = headers.map(specificHeader =>
    !isBafs() && specificHeader === "Institution" ? null : (
      <Table.HeaderCell
        data-testid={`${HEADER_LOAN_COLUMNS[specificHeader]}-current-request-table-header-cell`}
        key={specificHeader}
        onClick={() => toggleSortType(specificHeader)}
      >
        {specificHeader}
        {HEADER_LOAN_COLUMNS[specificHeader] && (
          <Icon name={convertToIcon(sortType[specificHeader])} />
        )}
      </Table.HeaderCell>
    )
  );

  const tableBody = requests.map(request => (
    <Table.Row
      data-testid="current-request-table-row"
      key={request.uuid}
      onClick={() => generateLink(request)}
    >
      <Table.Cell>{prettyDate(request.due_date) || "--"}</Table.Cell>
      <Table.Cell>{request.app_id}</Table.Cell>
      <Table.Cell>{entities[request.uuid]}</Table.Cell>
      {isBafs() && <Table.Cell>{institutionIds[request.uuid]}</Table.Cell>}
      <Table.Cell>
        <DollarFormat value={request.total_amount_requested} />
      </Table.Cell>
      <Table.Cell>{request.scope}</Table.Cell>
      <Table.Cell>
        {prettyDate(request.created_datetime, true) || "--"}
      </Table.Cell>
      <Table.Cell>
        {prettyDate(request.date_submitted, true) || "--"}
      </Table.Cell>
      <Table.Cell>{users[request.assigned_to_user] || "None"}</Table.Cell>
      <Table.Cell>{request.phase}</Table.Cell>
      <Table.Cell>{request.status}</Table.Cell>
    </Table.Row>
  ));

  const pageTitle = source === "LOS" ? "LOS Current Requests" : "Loan Review";

  return (
    <div className="LOS">
      <h1 className="mb-6">{pageTitle}</h1>
      <Segment>
        <Grid>
          <Grid.Row columns="equal">
            {source === "LOS" && (
              <Grid.Column verticalAlign="middle" width={2}>
                <div className="text-sm font-semibold">
                  Applications for Review: {header?.Total?.count || "--"}
                </div>
              </Grid.Column>
            )}

            {isBafs() && (
              <Grid.Column>
                <InstitutionDropdownFilter
                  filterUpdate={filterUpdate}
                  selectedInstitution={filters.institution_uuid}
                  filterValue="institution_uuid"
                  placeholder="Filter by Institution"
                  search
                />
              </Grid.Column>
            )}

            <Grid.Column>
              <DropdownFilter
                onChange={filterUpdate}
                options={REQUEST_FILTER_PHASE}
                loader={<Icon name="spinner" size="large" loading />}
                value={filters.phase}
                filterValue="phase"
                placeholder="Filter by Phase"
              />
            </Grid.Column>
            <Grid.Column>
              <DropdownFilter
                onChange={filterUpdate}
                options={REQUEST_FILTER_STATUS}
                loader={<Icon name="spinner" size="large" loading />}
                value={filters.status}
                filterValue="status"
                placeholder="Filter by Status"
              />
            </Grid.Column>
            {source === "LOS" && (
              <Grid.Column>
                <DropdownFilter
                  onChange={filterUpdate}
                  options={REQUEST_FILTER_SCOPE}
                  loader={<Icon name="spinner" size="large" loading />}
                  value={filters.scope}
                  filterValue="scope"
                  placeholder="Filter by Scope"
                />
              </Grid.Column>
            )}
            {source === "LOS" && (isBafs() || hasBorrowerPortal) && (
              <Grid.Column>
                <DropdownFilter
                  onChange={filterUpdate}
                  options={REQUEST_FILTER_ORIGIN}
                  loader={<Icon name="spinner" size="large" loading />}
                  value={filters.origin}
                  filterValue="origin"
                  placeholder="Filter by Origin"
                />
              </Grid.Column>
            )}
            <Grid.Column floated="right" textAlign="right" width={2}>
              <Button
                data-testid="los-clear-filter"
                onClick={() => {
                  setFilter(initialFilter);
                  getData();
                }}
              >
                Clear Filters
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <Segment className="pt-6">
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column style={{ display: "flex" }}>
              <Form.Input
                data-testid="current-request-search-filter"
                placeholder="App-Id, Borrower"
                type="text"
                value={lookup}
                onChange={({ target }) => filterResults(target.value)}
              />
              {lookup.length > 0 && (
                <Icon
                  data-testid="current-request-search-reset-icon"
                  style={{
                    marginLeft: "-20px",
                    marginTop: "11px",
                    zIndex: "1"
                  }}
                  name="x"
                  onClick={() => filterResults("")}
                />
              )}
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Checkbox
                data-testid="current-request-toggle-withdraw-applications"
                checked={showWithdrawnApplications}
                toggle
                onClick={() =>
                  setShowWithdrawnApplications(!showWithdrawnApplications)
                }
                label="Include Completed Applications"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Table className="ui sortable">
          <Table.Header>
            <Table.Row>{tableHeaders}</Table.Row>
          </Table.Header>
          <Table.Body className="cursor-pointer">{tableBody}</Table.Body>
        </Table>
      </Segment>
      <Segment>
        <PaginationControls lastPage={lastPage} name={paginationName} />
      </Segment>
    </div>
  );
}

CurrentRequestsObj.propTypes = {
  source: PropTypes.string.isRequired
};

export default CurrentRequestsObj;
