import React from "react";
import { Label } from "semantic-ui-react";

export const validationErrors = {
  isAlphanumeric: "Only letters and numbers allowed",
  isDefaultRequiredValue: "Field is Required",
  isEmail: "Invalid email address",
  isInt: "Only whole numbers allowed",
  isNumeric: "Only numbers allowed",
  isWords: "No numbers or special characters allowed"
};

export const validationLabel = pointing => (
  <Label color="red" pointing={pointing || true} />
);
