export const displayAndBorrowerFeeType = [
  {
    key: "Abstract Fee",
    text: "Abstract Fee",
    value: "Abstract Fee"
  },
  {
    key: "Appraisal Fee",
    text: "Appraisal Fee",
    value: "Appraisal Fee"
  },
  {
    key: "Closing Protection Letter",
    text: "Closing Protection Letter",
    value: "Closing Protection Letter"
  },
  {
    key: "Credit Report Fee",
    text: "Credit Report Fee",
    value: "Credit Report Fee"
  },
  {
    key: "Document Preparation Fee",
    text: "Document Preparation Fee",
    value: "Document Preparation Fee"
  },
  {
    key: "EDR Fee",
    text: "EDR Fee",
    value: "EDR Fee"
  },
  {
    key: "ELT Fee",
    text: "ELT Fee",
    value: "ELT Fee"
  },
  {
    key: "e-Recording Fee",
    text: "e-Recording Fee",
    value: "e-Recording Fee"
  },
  {
    key: "Escrow Fee",
    text: "Escrow Fee",
    value: "Escrow Fee"
  },
  {
    key: "Flood Determination Fee",
    text: "Flood Determination Fee",
    value: "Flood Determination Fee"
  },
  {
    key: "Origination Fee",
    text: "Origination Fee",
    value: "Origination Fee"
  },
  {
    key: "Other",
    text: "Other",
    value: "Other"
  },
  {
    key: "Policy Guaranty Fee",
    text: "Policy Guaranty Fee",
    value: "Policy Guaranty Fee"
  },
  {
    key: "Real Estate Commission",
    text: "Real Estate Commission",
    value: "Real Estate Commission"
  },
  {
    key: "Recording Fees",
    text: "Recording Fees",
    value: "Recording Fees"
  },
  {
    key: "Settlement or Closing Fee",
    text: "Settlement or Closing Fee",
    value: "Settlement or Closing Fee"
  },
  {
    key: "Survey Fee",
    text: "Survey Fee",
    value: "Survey Fee"
  },
  {
    key: "Tax Certificate Fee",
    text: "Tax Certificate Fee",
    value: "Tax Certificate Fee"
  },
  {
    key: "Tax Service Fee",
    text: "Tax Service Fee",
    value: "Tax Service Fee"
  },
  {
    key: "Title Insurance and Endorsements",
    text: "Title Insurance and Endorsements",
    value: "Title Insurance and Endorsements"
  },
  {
    key: "Title Opinion Fee",
    text: "Title Opinion Fee",
    value: "Title Opinion Fee"
  },
  {
    key: "TT&L",
    text: "TT&L",
    value: "TT&L"
  },
  {
    key: "UCC Search/Filing Fees",
    text: "UCC Search/Filing Fees",
    value: "UCC Search/Filing Fees"
  }
];

export const amortizedFeeNameReason = [
  {
    key: "Discount on Retained",
    text: "Discount on Retained",
    value: "Discount on Retained"
  },
  {
    key: "Servicing Asset",
    text: "Servicing Asset",
    value: "Servicing Asset"
  },
  {
    key: "Other",
    text: "Other",
    value: "Other"
  }
];

export const depositFeeOptions = [
  {
    key: "Appraisal Fee",
    text: "Appraisal Fee",
    value: "Appraisal Fee"
  },
  {
    key: "SBA Guarantee Fee",
    text: "SBA Guarantee Fee",
    value: "SBA Guarantee Fee"
  },
  {
    key: "Underwriting",
    text: "Underwriting",
    value: "Underwriting"
  },
  {
    key: "Environmental Fee",
    text: "Environmental Fee",
    value: "Environmental Fee"
  },
  {
    key: "Other",
    text: "Other",
    value: "Other"
  }
];
