import React from "react";
import { FormattedMessage } from "react-intl";
import { IntlMessage, MessageProps } from "../../../types/text";

/**
 * React component that automatically returns a string from props that implement
 * DisplayString or IntlMessage. If props includes `id` then a formatted message
 * will always be returned.
 */
export function Message(props: MessageProps) {
  return isIntlMessage(props) ? (
    <FormattedMessage {...props} />
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{props.defaultMessage}</>
  );
}

function isIntlMessage(props: MessageProps): props is IntlMessage {
  return "id" in props;
}
