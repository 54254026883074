import TypeCheck from "typecheck-extended";
import ALLOWED_MIME_TYPES from "../../../Constants/AllowedDocumentTypes";
import logger from "../../../logger";

export function PaginationParams(curParams, pagination, dynamoDb) {
  TypeCheck(curParams, "string");
  TypeCheck(pagination.number, "number");
  TypeCheck(pagination.direction, "enum", false, ["next", "previous"]);
  TypeCheck(pagination.size, "number");
  TypeCheck(dynamoDb, "boolean", false);

  let params = curParams;
  if (params) {
    params += "&";
  } else {
    params = "?";
  }

  if (dynamoDb) {
    params += `page_number=${pagination.number}&page_size=${pagination.size}`;
    logger.warn(
      "WARNING: You are using snake_case paginaiton params w/ DynamoDB.",
      params
    );
  } else {
    params += `page_number=${pagination.number}&page_size=${pagination.size}`;
  }

  if (pagination.direction) {
    params += `&query_direction=${pagination.direction}`;
  }

  return params;
}

export function ConcatQueryParams(
  params,
  excludedKeys = [],
  isExtraParam = false
) {
  TypeCheck(params, "object");

  const paramKeys = Object.keys(params);

  if (paramKeys.length === 0) {
    return "";
  }

  let paramString = isExtraParam ? "&" : "?";
  let firstParam = true;
  paramKeys.forEach(key => {
    if (!excludedKeys.includes(key)) {
      if (!firstParam) {
        paramString += "&";
      }
      firstParam = false;
      paramString += `${key}=${encodeURIComponent(params[key])}`;
    }
  });

  return paramString;
}

export function RspToCallback(rsp, callbacks) {
  TypeCheck(rsp, "object");
  TypeCheck(callbacks.onSuccess, "function");

  callbacks.onSuccess(rsp, callbacks.callbackData);
}

export function RemoveNullKeys(obj) {
  TypeCheck(obj, "object");

  // Note: This does not handle multilevel objects
  const objCopy = { ...obj };
  Object.keys(objCopy).forEach(key => {
    // isNaN is not useful to all possible scenarios,
    // so self comparison is best way to solve if is NaN
    // eslint-disable-next-line no-self-compare
    if (objCopy[key] === null || objCopy[key] !== objCopy[key]) {
      delete objCopy[key];
    }
  });
  return objCopy;
}

export function RemoveEmptyStrings(obj) {
  TypeCheck(obj, "object");

  // Note: This does not handle multilevel objects
  const objCopy = { ...obj };
  Object.keys(objCopy).forEach(key => {
    // isNaN is not useful to all possible scenarios,
    // so self comparison is best way to solve if is NaN
    // eslint-disable-next-line no-self-compare
    if (objCopy[key] === "") {
      delete objCopy[key];
    }
  });
  return objCopy;
}

export function coerceTypes(obj, schema) {
  TypeCheck(obj, "object");
  TypeCheck(schema, "object");

  const objCopy = { ...obj };
  Object.keys(objCopy).forEach(key => {
    if (schema[key] && (objCopy[key] || objCopy[key] === 0)) {
      switch (schema[key]) {
        case "int":
          objCopy[key] = parseInt(objCopy[key], 10);
          break;

        case "float":
          objCopy[key] = parseFloat(objCopy[key]);
          break;

        case "string":
          objCopy[key] = objCopy[key].toString();
          break;

        default:
          break;
      }
    }
  });
  return objCopy;
}

export function enforceBodyShape(body, schema) {
  TypeCheck(body, "object");
  TypeCheck(schema, "object");

  const allowedKeys = Object.keys(schema);
  Object.keys(body).forEach(bodyKey => {
    if (!allowedKeys.includes(bodyKey)) {
      // ErrorReporter('Invalid data. Could not save.'); // <-- This kills CircleCI
      const thrownErrorMsg = `Can not save. Invalid key: ${bodyKey}`;
      throw new Error(thrownErrorMsg);
    }
  });
  return body;
}

/**
 * Is a value a number? As far as this function is concerned as value of `NaN`
 * is NOT a number.
 * @param {[any, T=]} args Arguments to the function.
 *  1. `value` the value to test.
 *  1. `[notNumberResult]` an optional param to provide if value is not a number.
 * @returns {boolean | T=} True if the number is a value, Otherwise the value
 * determined by the notNumberValue param.
 * @template T
 */
export function isNumber(...args) {
  const notResult = args.length < 2 ? false : args[1];
  const [value] = args;

  if (value === null) {
    return notResult;
  }

  if (typeof value !== "number") {
    return notResult;
  }

  return Number.isNaN(value) ? notResult : true;
}

/*
 * Allowed file types for upload are, [PDF, JPEG/JPG/PNG, DOC/DOCX ,XLS/XLSX ,CSV, TXT]
 * Maximum file size allowed to be uploaded is 300MB
 * */
export const checkValidFiles = files => {
  const incorrectFileType = [];
  const incorrectFileSize = [];
  Object.values(files).forEach(({ type, name, size }) => {
    if (!ALLOWED_MIME_TYPES.includes(type)) {
      incorrectFileType.push(name);
    }
    if (size / 1024 / 1024 > 300) {
      incorrectFileSize.push(name);
    }
  });
  return incorrectFileType.length === 0 && incorrectFileSize.length === 0;
};

/**
 *
 * @param {string | number} value
 * @param {number} defaultMaskLength
 * @returns asterisks based on length of the value or defaultMaskLength
 */
export const maskValue = (value, defaultMaskLength = 11) => {
  let text = "";

  if (typeof value === "string") {
    text = value;
  }

  if (typeof value === "number") {
    text = value.toString();
  }

  return "*".repeat(text ? text.length : defaultMaskLength);
};

export function encodeQuerySpecialChars(queryParam) {
  TypeCheck(queryParam, "string");

  const replacementMap = { "+": "%2b" };

  let cleanedParam = queryParam;
  Object.keys(replacementMap).forEach(charToReplace => {
    cleanedParam = cleanedParam.replaceAll(
      charToReplace,
      replacementMap[charToReplace]
    );
  });
  return cleanedParam;
}
