import { ClearableDropdown, Field } from "@bafsllc/ui-shared";
import { PropTypes } from "prop-types";
import { loanDataProp } from "../../../../utils";

export default function LoanDropdown({
  name,
  uuid,
  id,
  entityName,
  subField1,
  subField2,
  type,
  loan,
  ...props
}) {
  return (
    <Field labelMessage={{ id: "LOAN" }} required>
      <ClearableDropdown
        id={type}
        selectedValue={{
          defaultMessage:
            type === "loan" && loan
              ? loan?.institution_account_number
              : loan?.app_id
        }}
        placeholder={{ id: "CREDIT_ANALYSIS_DROPDOWN_PLACEHOLDER" }}
        fill
        disabled
      >
        <li {...props} key={uuid} name={name} value={uuid} className="item">
          <div>
            <span>
              <i className="check circle icon" />
            </span>
            <div>
              <span>
                <b>{id}</b>
              </span>
              <span>
                <p>{entityName}</p>
              </span>
              <span>
                <p>{`${subField1 ?? "-"} | ${subField2 ?? "-"}`}</p>
              </span>
            </div>
          </div>
        </li>
      </ClearableDropdown>
    </Field>
  );
}

LoanDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired,
  subField1: PropTypes.string.isRequired,
  subField2: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  loan: loanDataProp
};
