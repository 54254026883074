import { useUserInfoQuery } from "../../../../../../../../../services/RTKQuery/usersInfo";
import {
  DollarFormat,
  PercentFormat
} from "../../../../../../../../../components/CustomUIElements";
import { Panel, IconButton } from "@bafsllc/ui-shared";
import { LoanDetails } from "./components/LoanDetails";

export const LoanRequestPanel = ({ count, loanReq, handleOnEdit }) => {
  const { data: user, isFetching: fetchingUser } = useUserInfoQuery(
    loanReq.loan_officer
  );

  const loanReqOriginationFee =
    loanReq?.origination_fee_type === "dollar" ? (
      <DollarFormat value={loanReq.origination_fee} />
    ) : (
      <PercentFormat value={loanReq.origination_fee} />
    );

  let userName = "";
  if (!fetchingUser) {
    if (user) {
      userName = `${user?.first_name} ${user?.last_name}`;
    }
  }

  return (
    <Panel>
      <div className="flex-auto">
        <div className="grid grid-cols-2 gap-4">
          <div className="col-start-1 col-end-3">
            <h3 className="mt-0 text-lg font-semibold">
              Loan Request {count} {loanReq.loan_term}
            </h3>
          </div>
          <div className="col-end-7 col-span-2">
            <IconButton
              icon="pencil"
              message={{ id: "EDIT" }}
              onClick={handleOnEdit}
              variant="secondary"
              size="tiny"
            />
          </div>
        </div>
      </div>
      <div className="flex mt-6">
        <div className="flex-auto w-64">
          <div className="grid grid-cols-3 gap-4">
            <LoanDetails title="Loan Officer">
              {fetchingUser ? "Loading..." : userName}
            </LoanDetails>
            <LoanDetails title="Loan Class">{loanReq.loan_class}</LoanDetails>
            <LoanDetails title="Loan Type">{loanReq.loan_type}</LoanDetails>
            <LoanDetails title="Loan Purpose">{loanReq.purpose}</LoanDetails>
            <LoanDetails title="Requested Loan Amount">
              <DollarFormat value={loanReq.amount_requested} />
            </LoanDetails>
            <LoanDetails title="Origination Fee">
              {loanReqOriginationFee}
            </LoanDetails>
            <LoanDetails title="Governmental Guarantee">
              {loanReq.gov_guarantee}
            </LoanDetails>
            <LoanDetails title="Agency Program">
              {loanReq.gov_guarantee_program}
            </LoanDetails>
            <LoanDetails title="Participation">
              {loanReq.participation}
            </LoanDetails>
          </div>
        </div>
        <div className="flex-auto w-64">
          <LoanDetails title="Description">{loanReq.description}</LoanDetails>
        </div>
      </div>
    </Panel>
  );
};
