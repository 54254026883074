/* eslint-disable camelcase */
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Message, Divider, Radio } from "semantic-ui-react";
import { ConfirmationModal } from "../../../../../../components/Modals";
import {
  ParticipantModal,
  ParticipationBought,
  ParticipationSold
} from "./components";
import { actionCreators } from "./reducer";
import { actionCreators as loanActionCreators } from "../../reducer";

export class ParticipationObj extends React.Component {
  constructor(props) {
    super(props);
    this.submitParticpantModal = this.submitParticpantModal.bind(this);
    this.submitParticipationRequest =
      this.submitParticipationRequest.bind(this);
  }

  componentDidMount() {
    const { loadInstitutions, loadParticipantInvestors, setupRetainedAccount } =
      actionCreators;
    const { dispatch, loan } = this.props;
    dispatch(loadInstitutions());
    dispatch(loadParticipantInvestors(loan.uuid));
    dispatch(setupRetainedAccount(loan));
  }

  submitParticpantModal(isEdit) {
    const {
      selectedInvestor,
      loan,
      dispatch,
      participant,
      participationBuyersToBe
    } = this.props;
    const {
      toggleShowAddParticipationModal,
      toggleShowEditParticipationModal,
      participationError,
      addInvestor,
      prepareSelectedInvestor
    } = actionCreators;
    const participantAmount = participant.current_contract_amount;
    if (
      loan.participation !== "None" &&
      participantAmount <= 0.9 * loan.current_contract_amount
    ) {
      dispatch(participationError(null));
      dispatch(toggleShowAddParticipationModal(false));
      dispatch(toggleShowEditParticipationModal(false));
      const participantInvestor = {
        ...participant,
        investor: selectedInvestor.investor
      };

      if (!isEdit) {
        dispatch(
          addInvestor(participantInvestor, participationBuyersToBe, loan)
        );
      } else {
        dispatch(
          prepareSelectedInvestor(
            participantInvestor,
            participationBuyersToBe,
            loan
          )
        );
      }
    } else {
      dispatch(
        participationError(
          "All fields required, or Dollar Amount exceeds 90% of balance."
        )
      );
    }
  }

  submitParticipationRequest() {
    const { loan, dispatch, participationBuyersToBe, retainedAccount } =
      this.props;
    const { toggleShowProcessModal, postParticipationInvestors } =
      actionCreators;
    dispatch(
      loanActionCreators.updateLoan({
        uuid: loan.uuid,
        participation: "Active"
      })
    );
    dispatch(
      postParticipationInvestors(participationBuyersToBe, retainedAccount, loan)
    );
    dispatch(toggleShowProcessModal(false));
  }

  render() {
    const {
      showProcessModal,
      showAddParticipationModal,
      showEditParticipationModal,
      investorInterestRate,
      selectedInvestor,
      investors,
      error,
      loan,
      investorAddSuccess,
      investorAddError,
      dispatch,
      participationBuyersToBe,
      participationInvestors,
      participant,
      feesRolledDown,
      retainedAccount,
      institutionMap
    } = this.props;
    const {
      toggleShowProcessModal,
      toggleShowAddParticipationModal,
      toggleShowEditParticipationModal,
      setRolledDown
    } = actionCreators;
    const {
      participation,
      bafs_account_number,
      institution_uuid,
      uuid: loanUuid
    } = loan;

    const participationIsSetup =
      (loan.participation_sold && participationInvestors.length > 0) ||
      investorAddSuccess;

    const notParticipant = !(participation === "Active") &&
      participationInvestors.length === 0 &&
      participation !== "Pending" && (
        <Container>
          <p>This loan is not currently setup as a participation.</p>
          <p>
            If you would like to make this a participation, you can do so on the{" "}
            <Link to={`/loan_management/${loanUuid}/details/characteristics`}>
              Details tab
            </Link>
            .
          </p>
        </Container>
      );

    return (
      <>
        <div>
          {notParticipant}
          {!loan.participation_bought &&
            !participationIsSetup &&
            !notParticipant && (
              <Radio
                toggle
                checked={feesRolledDown}
                onChange={() => dispatch(setRolledDown(!feesRolledDown))}
                label="Allow Investors to Get Late Fees?"
              />
            )}
          <ConfirmationModal
            open={showProcessModal}
            showLogo={false}
            handleCancel={() => {
              dispatch(toggleShowProcessModal(false));
            }}
            handleConfirm={() => this.submitParticipationRequest()}
            prompt="Set Up Participation"
            yesButtonLabel="Request Participation"
            warningDetails={
              <p>
                Would you like to participate out Account #{bafs_account_number}
                ?
                <br />
                <br />
                This action cannot be undone.
              </p>
            }
          />
          {investorAddSuccess && (
            <Message positive content="Added investors to loan." />
          )}
          {investorAddError && (
            <Message negative content="Unable to add investors to loan." />
          )}
          <ParticipantModal
            open={showAddParticipationModal || showEditParticipationModal}
            isEdit={showEditParticipationModal}
            handleCancel={() => {
              dispatch(toggleShowEditParticipationModal(false));
              dispatch(toggleShowAddParticipationModal(false));
            }}
            handleConfirm={isEdit => this.submitParticpantModal(isEdit)}
            institutionUuid={institution_uuid}
            error={error}
            dispatch={dispatch}
            selectedInvestor={selectedInvestor}
            investorInterestRate={investorInterestRate}
            investors={investors}
            currentBalance={loan.current_contract_amount}
            participants={participationBuyersToBe}
            participant={participant}
            feesRolledDown={feesRolledDown}
            loan={loan}
          />
        </div>
        <Divider />
        <div>
          {loan.participation_sold && (
            <ParticipationSold
              participationIsSetup={participationIsSetup}
              participantData={participationInvestors}
              participationBuyersToBe={participationBuyersToBe}
              retainedAccount={retainedAccount}
              loan={loan}
              dispatch={dispatch}
              institutionMap={institutionMap}
            />
          )}
          {loan.participation_bought && loan.participation_parent_uuid && (
            <ParticipationBought loan={loan} />
          )}
        </div>
      </>
    );
  }
}

ParticipationObj.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loan: PropTypes.shape({
    participation: PropTypes.string,
    bafs_account_number: PropTypes.string,
    current_contract_amount: PropTypes.number.isRequired,
    current_balance: PropTypes.number.isRequired,
    institution_uuid: PropTypes.string.isRequired,
    interest_rate: PropTypes.number.isRequired,
    uuid: PropTypes.string.isRequired,
    participation_sold: PropTypes.bool.isRequired,
    participation_bought: PropTypes.bool,
    participation_parent_uuid: PropTypes.string
  }),
  showProcessModal: PropTypes.bool.isRequired,
  showAddParticipationModal: PropTypes.bool.isRequired,
  showEditParticipationModal: PropTypes.bool.isRequired,
  investorInterestRate: PropTypes.number,
  selectedInvestor: PropTypes.shape({
    value: PropTypes.string,
    text: PropTypes.string,
    key: PropTypes.string,
    investor: PropTypes.object,
    index: PropTypes.number,
    participantInfo: PropTypes.object
  }),
  investors: PropTypes.arrayOf(PropTypes.object).isRequired,
  participationBuyersToBe: PropTypes.arrayOf(PropTypes.object),
  participationInvestors: PropTypes.arrayOf(PropTypes.object),
  investorAddSuccess: PropTypes.bool.isRequired,
  investorAddError: PropTypes.bool.isRequired,
  feesRolledDown: PropTypes.bool,
  error: PropTypes.string,
  participant: PropTypes.shape({
    current_contract_amount: PropTypes.number
  }),
  retainedAccount: PropTypes.shape({}),
  institutionMap: PropTypes.shape({})
};

const mapStateToProps = state => ({
  loan: state.LoanManagementReducer.loan,
  participation: state.LoanManagementReducer.loan.participation,
  bafs_account_number: state.LoanManagementReducer.loan.bafs_account_number,
  current_balance: state.LoanManagementReducer.loan.current_balance,
  institution_uuid: state.LoanManagementReducer.loan.institution_uuid,
  interest_rate: state.LoanManagementReducer.loan.interest_rate,
  showProcessModal: state.ParticipationReducer.showProcessModal,
  showAddParticipationModal:
    state.ParticipationReducer.showAddParticipationModal,
  showEditParticipationModal:
    state.ParticipationReducer.showEditParticipationModal,
  investorInterestRate: state.ParticipationReducer.investorInterestRate,
  selectedInvestor: state.ParticipationReducer.selectedInvestor,
  investors: state.ParticipationReducer.investors,
  participationBuyersToBe: state.ParticipationReducer.participationBuyersToBe,
  participationInvestors: state.ParticipationReducer.participationInvestors,
  investorAddSuccess: state.ParticipationReducer.investorAddSuccess,
  investorAddError: state.ParticipationReducer.investorAddError,
  error: state.ParticipationReducer.error,
  participant: state.ParticipationReducer.participant,
  feesRolledDown: state.ParticipationReducer.feesRolledDown,
  retainedAccount: state.ParticipationReducer.retainedAccount,
  institutionMap: state.ParticipationReducer.institutionMap
});

const Participation = connect(mapStateToProps)(ParticipationObj);
export default Participation;
