import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import FetchService from "../../../FetchService";
import { FormatUrlV2 } from "../../../FormatUrl";
import logger from "../../../logger";

function request(
  onSuccess,
  onError,
  businessUuid,
  personalUuid,
  loanUuid,
  callbackData
) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");

  let uuids;
  const endpoint = ApiEndpoints.decisionHandlerEndpoint;
  const url = FormatUrlV2(endpoint, uuids);
  const body = {
    business_uuid: businessUuid,
    personal_uuid: personalUuid,
    loan_uuid: loanUuid
  };

  FetchService("POST", url, onSuccess, onError, body, callbackData);
}

function get() {
  logger.warn(
    "There is no decision.get(). Did you mean to check CSI for docs?"
  );
}

export default { request, get };
