export const relationshipTypesData = [
  "Affiliate",
  "Authorized",
  "Borrower",
  "Dba",
  "Elected",
  "Grantor",
  "Guarantor",
  "Guarantees",
  "Holdings Subsidiaries",
  "Lender",
  "Loan Officer",
  "Officer",
  "Other Capacities",
  "Authorized Signer",
  "Owner",
  "Related Party",
  "Signer",
  "Trustee",
  "document",
  "Co-borrower"
];

export const relationshipParentChildTypesData = [
  "loan",
  "entity",
  "document",
  "loan_app",
  "collateral",
  "institution",
  "asset"
];

export default { relationshipTypesData, relationshipParentChildTypesData };
