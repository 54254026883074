import PropTypes from "prop-types";
import { Popup } from "semantic-ui-react";

/** @deprecated please use Tooltip from @bafsllc/ui-shared */
function Tooltip({ children, message, width, position, inverted = false }) {
  return (
    <Popup
      data-testid="tooltip"
      content={message}
      trigger={children}
      wide={width}
      position={position}
      inverted={inverted}
    />
  );
}

Tooltip.propTypes = {
  children: PropTypes.object,
  message: PropTypes.string,
  width: PropTypes.string,
  position: PropTypes.string
};

export default Tooltip;
