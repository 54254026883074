import Constants from "../../../../../../../../services/Constants/strings";
import { relationshipTypes } from "../../../../../../../../services/Constants/relationshipTypes";
import { Relationships as RelationshipsApi } from "../../../../../../../../services/ApiLib";
import logger from "../../../../../../../../services/logger";

function onError(rsp) {
  logger.error("Error: ", rsp); // TODO: Handle errors properly
}

export default function relationshipsUpdate(
  activeItem,
  loanUuid,
  jwt,
  onSuccess
) {
  switch (activeItem) {
    case Constants.COBORROWERS: {
      RelationshipsApi.asyncRead({
        parent_uuid: loanUuid,
        rel_type: relationshipTypes.BORROWER,
        primary_borrower: false
      })
        .then(result => {
          onSuccess(result);
        })
        .catch(error => {
          onError(error);
        });
      break;
    }

    case relationshipTypes.GUARANTOR: {
      RelationshipsApi.asyncRead({
        parent_uuid: loanUuid,
        rel_type: relationshipTypes.GUARANTOR
      })
        .then(result => {
          onSuccess(result);
        })
        .catch(error => {
          onError(error);
        });
      break;
    }

    default:
      break;
  }
}
