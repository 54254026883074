import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SideSheet } from "@bafsllc/ui-shared";
import { useFlags } from "flagsmith/react";
import NotificationsSidebarImpl from "./components/NotificationsSidebarObj";
import { CheckAuth } from "../../services/Auth";
import "../../types/notifications";
import { Notifications } from "../../services/ApiLib";
import logger from "../../services/logger";

export default function NotificationsSidebar() {
  /** @type {NotificationsReducer} */
  const { displayPanel, includeRead } = useSelector(
    state => state.NotificationsReducer
  );
  const dispatch = useDispatch();
  const flags = useFlags(["blast_notification_polling_enabled"]);
  const pollingEnabled = flags.blast_notification_polling_enabled.enabled;

  useEffect(() => {
    if (displayPanel && CheckAuth() && !pollingEnabled) {
      Notifications.get(
        ({ data }) => dispatch({ type: "NOTIFICATIONS_UPDATE", data }),
        message => logger.error(message),
        null,
        { include_read: includeRead }
      );
    }
  }, [displayPanel, pollingEnabled, includeRead, dispatch]);

  return (
    <SideSheet
      isOpen={displayPanel}
      onClose={() => {
        dispatch({ type: "NOTIFICATIONS_PANEL" });
      }}
      size="one-third"
      className="!p-0"
    >
      <NotificationsSidebarImpl />
    </SideSheet>
  );
}
