import { useState, useEffect } from "react";

export default function useRelationshipFormIsValid({ requiredFields, form }) {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (!requiredFields) return;
    let formIsValid = true;
    for (let i = 0; i < requiredFields.length; i += 1) {
      const field = requiredFields[i];
      if (
        form[field] === undefined ||
        form[field] === null ||
        form[field] === ""
      ) {
        formIsValid = false;
        break;
      }
    }
    setIsValid(formIsValid);
  }, [form, requiredFields]);

  return { isValid };
}
