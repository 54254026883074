import React from "react";
import { Header } from "semantic-ui-react";
import PropTypes from "prop-types";

const dataLabelStyle = { fontWeight: "400" };

function LightHeader(props) {
  return (
    <Header
      as={props.hx || "h5"}
      color={props.color || "grey"}
      style={dataLabelStyle}
      {...props}
    >
      {props.children}
    </Header>
  );
}

LightHeader.defaultProps = {
  children: undefined,
  color: undefined,
  hx: undefined
};

LightHeader.propTypes = {
  children: PropTypes.string,
  color: PropTypes.string,
  hx: PropTypes.string
};

export default LightHeader;
