import flagsmith from "flagsmith";
import * as Sentry from "@sentry/react";

export const registerSentry = (userUuid, institutionUuid, name) => {
  Sentry.setUser({
    id: userUuid,
    institution_uuid: institutionUuid,
    name
  });
  Sentry.setTag({ institution_uuid: institutionUuid });
};

export const registerHeap = (userUuid, institutionUuid, name) => {
  if (window.heap) {
    window.heap.identify(userUuid);
    window.heap.addUserProperties({
      institution_uuid: institutionUuid,
      name
    });
  }
};

export const registerFlagsmith = (userUuid, institutionUuid, name) => {
  flagsmith.identify(userUuid, {
    institution_uuid: institutionUuid,
    name
  });
};

export const registerAll = token => {
  if (!token || !token?.uuid || !token?.institution_uuid) {
    return;
  }

  const name = `${token.first_name || ""} ${token.last_name || ""}`.trim();

  registerSentry(token.uuid, token.institution_uuid, name);
  registerFlagsmith(token.uuid, token.institution_uuid, name);
  registerHeap(token.uuid, token.institution_uuid, name);
};
