export const formIsValid = ({ participant, selectedInvestor }) =>
  !participant.bafs_account_number ||
  !participant.institution_account_number ||
  !selectedInvestor ||
  !selectedInvestor.investor ||
  participant.current_contract_amount <= 0;

/**
 * Update dollar amounts in proportion to current_contract_amount
 */
export const updateAmounts = (amount, loan) => {
  const percentOwned = amount / loan.current_contract_amount;
  return {
    participation_percentage_owned: percentOwned,
    available_balance: loan.available_balance * percentOwned,
    balance_at_last_renewal: loan.balance_at_last_renewal * percentOwned,
    current_balance: loan.current_balance * percentOwned,
    original_amount: loan.original_amount * percentOwned,
    total_advance_amount: loan.total_advance_amount * percentOwned
  };
};

export default { formIsValid, updateAmounts };
