import { FormattedMessage } from "react-intl";

import { Dropdown } from "../dropdown/dropdown";
import { DropdownProps } from "../dropdown/dropdown";
import { IconWithRef } from "../../../elements/icon/icon";
import { ReactComponent } from "../../../../types/react";
import { MessageProps } from "../../../../types/text";

/**
 * This component renders a dropdown with a item list that can be filtered by the search input.
 * */
export function SearchableDropdown({
  children,
  disabled,
  placeholder = { id: "PLEASE_MAKE_SELECTION" },
  selectedKey,
  selectedValue,
  ...props
}: SearchableDropdownProps) {
  const formattedPlaceholder =
    "id" in placeholder ? (
      <FormattedMessage {...placeholder} />
    ) : (
      placeholder.defaultMessage
    );

  const formattedSelectedValue =
    selectedValue &&
    ("id" in selectedValue ? (
      <FormattedMessage {...selectedValue} />
    ) : (
      selectedValue.defaultMessage
    ));

  const SearchableDropdownControl = () => (
    <div className="control">
      <span
        className="headerTabularInactive text-neutral-600"
        data-testid="searchable-control-header"
      >
        {selectedKey === "" ? "BAFS" : <FormattedMessage id="INSTITUTIONS" />}
      </span>
      <div className="searchable-control-selection">
        <span
          className="searchable-control-name"
          data-testid="searchable-control-selection"
        >
          {selectedKey === "" ? formattedPlaceholder : formattedSelectedValue}
        </span>
        {!disabled && <IconWithRef icon="dropdown" />}
      </div>
    </div>
  );

  const dropdownProps: DropdownProps = {
    ...props,
    control: <SearchableDropdownControl />,
    disabled,
    selectedKey: selectedKey,
    variant: "floating",
    updateDropdownClassnames: classNames => [...classNames, "searchable"],
    children
  };

  return <Dropdown {...dropdownProps} />;
}

export interface SearchableDropdownProps
  extends Omit<DropdownProps, "control"> {
  /**
   * One or more components that will be displayed above the list items.
   * - Clicking on components in the listHeader will cause the list to close, if
   *   you want the list to remain open you will need to handle the component's
   *   click event and use `stopPropagation()` to prevent the event from
   *   bubbling through to the control component.
   * - Default dropdown item styling will be applied if `item` is included in
   *   the listHeader's className prop.
   */
  listHeader?: ReactComponent;
  /**Determines the text that will be visible on the control when no option is selected*/
  placeholder?: MessageProps;
  /**Determines the key (UUID, ID, ...) selected */
  selectedKey?: string;
  /**Displays the value of the dropdown. The type of this property is determined by the component consuming the `SearchableDropdown`*/
  selectedValue: MessageProps | null;
}
