import PropTypes from "prop-types";
import React from "react";
import { Button, Header, Modal } from "semantic-ui-react";
import { connect } from "react-redux";

export function ErrorModalObj(props) {
  const { dispatch, errorDisplay, errorMessage, errorTriggered } = props;

  // TODO: [#5740] On removing the V1 function, this should be changed to:
  // if (errorDisplay === 'modal')
  if (!errorTriggered || errorDisplay === "toPage") {
    return "";
  }

  let displayMessage = errorMessage;
  if (typeof errorMessage === "object") {
    displayMessage =
      errorMessage.message ||
      errorMessage.detail ||
      "Error message not available.";
  }

  return (
    <Modal
      onClose={() => dispatch({ type: "ERRORS_CLEAR" })}
      open={errorTriggered}
      size="small"
    >
      <Header color="red" content="Error" />
      <Modal.Content>
        <p>{displayMessage}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Close"
          onClick={() => dispatch({ type: "ERRORS_CLEAR" })}
          primary
        />
      </Modal.Actions>
    </Modal>
  );
}

ErrorModalObj.defaultProps = {
  errorDisplay: ""
};

ErrorModalObj.propTypes = {
  dispatch: PropTypes.func.isRequired,
  errorDisplay: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  errorTriggered: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  errorDisplay: state.ErrorReporter.errorDisplay,
  errorMessage: state.ErrorReporter.errorMessage,
  errorTriggered: state.ErrorReporter.errorTriggered
});

const ErrorModal = connect(mapStateToProps)(ErrorModalObj);

export default ErrorModal;
