import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import TC from "typecheck-extended";
import { Button, Form, Segment, Modal } from "semantic-ui-react";
import { LoanAdditionalFees } from "../../../../../../../../../../../../services/ApiLib";
import {
  AmortizedForm,
  BillToBorrowerForm,
  DisplayOnlyTableForm
} from "../Forms";

export class AddFeeModalObj extends Component {
  constructor(props) {
    super(props);
    this.saveChanges = this.saveChanges.bind(this);
    this.toggleModalVisibility = this.toggleModalVisibility.bind(this);
    this.updateValues = this.updateValues.bind(this);
    this.state = {
      fee: "",
      errorMsg: "",
      modalVisible: false
    };
  }

  toggleModalVisibility() {
    const { modalVisible } = this.state;
    this.setState({ modalVisible: !modalVisible });
    this.setState({ fee: "" });
  }

  updateValues({ name, value }) {
    let transCode = "";
    if (value === "Discount on Retained") {
      transCode = "1000";
    }
    if (value === "Servicing Asset") {
      transCode = "2000";
    }
    if (value === "Other") {
      transCode = "3000";
    }
    if (name === "amortized_reason") {
      const newState = {
        ...this.state.fee,
        [name]: value,
        amortization_tran_code: transCode
      };
      this.setState({ fee: { ...newState } });
    } else {
      const newState = { ...this.state.fee, [name]: value };
      this.setState({ fee: { ...newState } });
    }
    if (name === "fee_type") {
      this.setState({ fee: { [name]: value } });
    }
  }

  notifyUser(errorMsg) {
    TC(errorMsg, "string");
    this.setState({ errorMsg });
  }

  updateUI(rsp) {
    TC(rsp.data, "object");
    const { dispatch } = this.props;
    dispatch({
      fee: rsp.data,
      type: "LOAN_MGMT_DETAILS_ADD_SINGLE_FEE"
    });
    this.toggleModalVisibility();
  }

  saveChanges() {
    const { institutionUuid, loanUuid } = this.props;
    const body = {
      ...this.state.fee,
      institution_uuid: institutionUuid,
      loan_uuid: loanUuid
    };
    // TODO: #5740 figure out error handling with `asyncFetchService`s
    //       Right now, rsp.error will never be triggered – even on errors.
    LoanAdditionalFees.create(body)
      .then(rsp => this.updateUI(rsp))
      .catch(() => this.notifyUser("Error: Could Not Create Fee"));
  }

  render() {
    const { fee, modalVisible } = this.state;
    return (
      <>
        <Button
          onClick={this.toggleModalVisibility}
          circular
          icon="plus"
          color="green"
        />
        <strong>Add New Fee</strong>
        <Modal
          closeIcon
          onClose={this.toggleModalVisibility}
          open={modalVisible}
        >
          <Modal.Header>Add New Fee</Modal.Header>
          <Modal.Content image>
            <Segment padded basic>
              <Form onSubmit={() => this.saveChanges()} className="add-form">
                <Form.Select
                  label="Fee Type"
                  selection
                  name="fee_type"
                  options={[
                    { key: "Amortized", value: "Amortized", text: "Amortized" },
                    {
                      key: "Display Only",
                      value: "Display Only",
                      text: "Display Only"
                    },
                    {
                      key: "Bill to Borrower",
                      value: "Bill to Borrower",
                      text: "Bill to Borrower"
                    }
                  ]}
                  required
                  value={fee.fee_type || ""}
                  onChange={(ev, data) => this.updateValues(data)}
                />
                {fee.fee_type === "Amortized" && (
                  <Form.Select
                    label="Amortization Method"
                    required
                    selection
                    name="amortization_method"
                    options={[
                      {
                        key: "Level Yield",
                        value: "Level Yield",
                        text: "Level Yield"
                      }
                    ]}
                    value={fee.amortization_method}
                    onChange={(ev, data) => this.updateValues(data)}
                  />
                )}
                {fee.fee_type === "Amortized" && (
                  <AmortizedForm
                    updateValues={this.updateValues}
                    fee={fee}
                    interestRate={this.props.interestRate}
                    currentBalance={this.props.currentBalance}
                  />
                )}
                {fee.fee_type === "Bill to Borrower" && (
                  <BillToBorrowerForm
                    updateValues={this.updateValues}
                    fee={fee}
                  />
                )}
                {fee.fee_type === "Display Only" && (
                  <DisplayOnlyTableForm
                    updateValues={this.updateValues}
                    fee={fee}
                  />
                )}
                {/*  TODO: #5740 This should be part of the error msg standardization.  */}
                <h3 style={{ color: "red" }}>{this.state.errorMsg}</h3>

                <Button
                  type="submit"
                  content="Save Changes"
                  disabled={
                    !fee.fee_type ||
                    (fee.fee_type === "Amortized" && !fee.amortization_method)
                  }
                />
              </Form>
            </Segment>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

AddFeeModalObj.propTypes = {
  dispatch: PropTypes.func.isRequired,
  institutionUuid: PropTypes.string.isRequired,
  loanUuid: PropTypes.string.isRequired,
  interestRate: PropTypes.number,
  currentBalance: PropTypes.number
};

const AddFeeModal = connect()(AddFeeModalObj);

export default AddFeeModal;
