import React, { useEffect, useState } from "react";
import { useLoansQuery } from "../../../../../../services/RTKQuery/loans";

export const ReadManyTest = ({ institutionUuid, setStatusInParent }) => {
  const statusUnknown = "❓Unknown Status";
  const statusPass = "✅ Pass";
  const statusFail = "❌ Fail";
  const statusLoading = "Loading ...";

  const [statusReadSingle, setStatus] = useState(statusUnknown);
  const [detailsText, setDetailsText] = useState("");

  const loans = useLoansQuery({
    institution_uuid: institutionUuid
  });
  const loansData = loans?.data?.data;

  useEffect(() => {
    setStatus(statusLoading);
    if (loansData?.[0]?.institution_uuid === institutionUuid) {
      setStatus(statusPass);
      setDetailsText(`:  Matched records on: ${institutionUuid}`);
      setStatusInParent("Pass");
    } else {
      console.error("Fail: useLoansQuery");
      setStatus(statusFail);
    }
  }, [institutionUuid, loansData, setStatusInParent]);

  return (
    <>
      <b>{statusReadSingle}</b>
      <small>{detailsText}</small>
    </>
  );
};
