import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { useClickedOutside } from "../../../../../../src/lib/dom/events/use-clicked-outside/use-clicked-outside";
import {
  NotificationItem,
  NotificationProps
} from "./notification-item/notification-item";

export function Notifications({
  notifications,
  onDismissed,
  onNotificationClicked
}: NotificationsProps) {
  const [visible, setVisible] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const popupRef = useRef<HTMLDivElement | null>(null);
  const dismissed = useClickedOutside(ref.current as HTMLDivElement);

  useEffect(() => {
    if (!dismissed || !visible) {
      return;
    }

    if (visible && onDismissed) {
      onDismissed();
    }

    setVisible(false);
  }, [dismissed, visible, onDismissed]);

  const unreadCount = notifications
    ? notifications.reduce((count, x) => count + (x.unread ? 1 : 0), 0)
    : 0;

  return (
    <div
      ref={ref}
      className={classnames("ui dropdown notifications", { visible })}
      onClick={ev => {
        setVisible(!visible);
      }}
    >
      <i className="bell icon"></i>
      {unreadCount ? (
        <span className="top right unread-count icon">{unreadCount}</span>
      ) : null}

      {visible ? (
        <div
          className="ui segment popup bottom right transition notification-pop padded visible"
          ref={popupRef}
        >
          <div className="ui">
            <h4 className="ui header pt-5 pb-5">
              <FormattedMessage
                id="NOTIFICATIONS_TITLE"
                values={{ count: notifications ? notifications.length : 0 }}
              />
            </h4>
            {notifications &&
              notifications.map(item => (
                <NotificationItem
                  key={item.noteUuid}
                  item={item}
                  onItemClicked={onNotificationClicked}
                />
              ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export interface NotificationsProps {
  notifications?: NotificationProps[] | null;
  onDismissed?: () => void;
  onNotificationClicked?: (item: NotificationProps) => void;
}
