import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { min } from "lodash";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Message
} from "semantic-ui-react";
import {
  getDate,
  prettyDate,
  dateFromStringNoTime
} from "../../../../../../services/DateTime";
import { Loans } from "../../../../../../services/ApiLib";
import NightlyProcessTable from "./NightlyProcessTable";
import { ConfirmationModal } from "../../../../../../components/Modals";
import { isBafs } from "../../../../../../services/Auth";

function NightlyProcess({ loan, onViewLoanManagement }) {
  const [refreshLoan, setRefreshLoan] = useState(false);
  const [disableSubmit, setDisabled] = useState(false);
  const [isError, setError] = useState(null);
  const lastRunDateBillsFees = dateFromStringNoTime(
    loan.last_run_date_bills_fees
  );
  const accruedThroughDate = dateFromStringNoTime(loan.accrued_through_date);
  const currentDate = new Date(min([lastRunDateBillsFees, accruedThroughDate]));
  const processingDate = getDate(1, currentDate, "-");
  const billProcessingDate = getDate(1, lastRunDateBillsFees, "-");
  const accruedProcessingDate = getDate(0, accruedThroughDate, "-");
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);

  useEffect(() => {
    if (refreshLoan) {
      onViewLoanManagement(loan.uuid);
    }
  }, [loan.uuid, onViewLoanManagement, refreshLoan]);

  const onSuccess = () => {
    setDisabled(false);
    setRefreshLoan(true);
  };
  const onError = ({ error }) => {
    setDisabled(false);
    setError(error);
  };
  const runAllNightlyProcesses = () => {
    setDisabled(true);
    setRefreshLoan(false);
    setError(null);
    const body = {
      loan_uuid: loan.uuid,
      first_processing_date: processingDate
    };
    Loans.runAllNightlyProcess(onSuccess, onError, body);
  };
  const runBillNightlyProcesses = () => {
    setDisabled(true);
    setRefreshLoan(false);
    setError(null);
    const body = {
      loan_uuid: loan.uuid,
      processing_date: billProcessingDate
    };
    Loans.runBillsNightlyProcess(onSuccess, onError, body);
  };
  async function undoNightlyAccruals() {
    setDisabled(true);
    setRefreshLoan(false);
    setError(null);
    const body = {
      loan_uuid: loan.uuid,
      processing_date: accruedProcessingDate
    };

    try {
      await Loans.undoNightlyAccruals(body);
      setDisabled(false);
      setRefreshLoan(true);
    } catch (error) {
      onError({ error: "cannot undo one day of accrual" });
    }
  }

  return (
    <div>
      <Header as="h1">Nightly Process</Header>
      <Divider />
      <Container>
        <Grid>
          <Grid.Row>
            {isError && (
              <Grid.Column width="16">
                <Message negative content={isError} />
              </Grid.Column>
            )}
            {refreshLoan && (
              <Grid.Column width="16">
                <Message positive content="Task Completed Successfully!" />
              </Grid.Column>
            )}
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <Button
                data-testid="btn-nightly-process"
                fluid
                disabled={disableSubmit}
                onClick={runAllNightlyProcesses}
              >
                {`Run All Nightly Processes for ${prettyDate(processingDate)}`}
              </Button>
            </Grid.Column>
            <Grid.Column width={6}>
              <Button
                data-testid="btn-bills-process"
                fluid
                disabled={disableSubmit}
                onClick={runBillNightlyProcesses}
              >
                {`Run Nightly Bills/Fees Process for ${prettyDate(
                  billProcessingDate
                )}`}
              </Button>
            </Grid.Column>
            <Grid.Column width={4}>
              {isBafs() && (
                <Button
                  data-testid="btn-undo-accruals"
                  fluid
                  color="red"
                  disabled={disableSubmit}
                  onClick={() => setConfirmModalIsOpen(true)}
                >
                  Undo One Day of Accruals
                </Button>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <NightlyProcessTable loan={loan} />
          </Grid.Row>
        </Grid>
        <ConfirmationModal
          open={confirmModalIsOpen}
          yesButtonLabel="Undo"
          showLogo={false}
          handleCancel={() => setConfirmModalIsOpen(false)}
          handleConfirm={() => {
            undoNightlyAccruals();
            setConfirmModalIsOpen(false);
          }}
          prompt="Undo One day of Accrual"
          warningDetails="Are you sure you want to undo this data?"
          size="tiny"
        />
      </Container>
    </div>
  );
}

NightlyProcess.propTypes = {
  loan: PropTypes.shape({
    uuid: PropTypes.string,
    accrued_through_date: PropTypes.string,
    last_run_date_bills_fees: PropTypes.string
  }).isRequired,
  onViewLoanManagement: PropTypes.func
};
export default NightlyProcess;
