import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import FetchService, { asyncFetchService } from "../../../FetchService";
import { RemoveNullKeys } from "../Utilities";
import { FormatUrlV2 } from "../../../FormatUrl";

function remove(onSuccess, onError, uuid, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string", false);

  let url = ApiEndpoints.baseUri + ApiEndpoints.user;
  if (uuid) {
    url = FormatUrlV2(ApiEndpoints.user, { uuid });
  }

  FetchService("DELETE", url, onSuccess, onError, null, callbackData);
}

// This is deprecated. There should not be a "user" and a "users" service.
// Instead use async Users.read()
function get(onSuccess, onError, uuid, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string", false);

  let url = ApiEndpoints.baseUri + ApiEndpoints.user;
  if (uuid) {
    url = FormatUrlV2(ApiEndpoints.user, { uuid });
  }

  FetchService("GET", url, onSuccess, onError, null, callbackData);
}

async function asyncGetUserInfo(uuid) {
  TypeCheck(uuid, "string", true);
  const url = FormatUrlV2(ApiEndpoints.userInfo, { uuid });
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

function update(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");

  const url = FormatUrlV2(ApiEndpoints.user, { uuid: body.uuid });
  const convertedBody = RemoveNullKeys(body);

  FetchService("PUT", url, onSuccess, onError, convertedBody, callbackData);
}

export default {
  get,
  update,
  delete: remove,
  asyncGetUserInfo
};
