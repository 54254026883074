import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { readableNumFormat } from "../../../../../../services/FormElements";
import LoanTable from "./LoanTable";
import LoanForm from "./LoanForm";

function Loan({
  deleteLoan,
  index,
  loanRequestUuid,
  interestSchedule,
  loanRequest,
  paymentSchedule,
  radioChange,
  handleChange,
  handleChangeNumber,
  readOnly
}) {
  const [originalAmount, setOriginalAmount] = useState(
    loanRequest?.original_amount || ""
  );

  useEffect(() => {
    if (loanRequest?.original_amount) {
      setOriginalAmount(readableNumFormat(loanRequest?.original_amount));
    }
  }, [loanRequest?.original_amount]);

  const accrualValue =
    loanRequest?.accrual_basis !== interestSchedule?.accrual_basis
      ? ""
      : loanRequest?.accrual_basis;

  if (readOnly) {
    return (
      <LoanTable
        index={index}
        interestSchedule={interestSchedule}
        loanRequest={loanRequest}
        paymentSchedule={paymentSchedule}
      />
    );
  }

  return (
    <LoanForm
      deleteLoan={deleteLoan}
      index={index}
      accrualValue={accrualValue}
      loanRequestUuid={loanRequestUuid}
      interestSchedule={interestSchedule}
      loanRequest={loanRequest}
      paymentSchedule={paymentSchedule}
      radioChange={radioChange}
      handleChange={handleChange}
      setOriginalAmount={setOriginalAmount}
      originalAmount={originalAmount}
      handleChangeNumber={handleChangeNumber}
    />
  );
}

Loan.propTypes = {
  deleteLoan: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleChangeNumber: PropTypes.func.isRequired,
  loanRequestUuid: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  interestSchedule: PropTypes.shape({
    accrual_basis: PropTypes.string,
    change_frequency: PropTypes.number,
    change_period: PropTypes.string,
    deleted: PropTypes.bool,
    end_date: PropTypes.string,
    institution_uuid: PropTypes.string,
    interest_rate: PropTypes.number,
    interest_rate_method: PropTypes.string,
    loan_uuid: PropTypes.string,
    margin: PropTypes.number,
    next_change_date: PropTypes.string,
    processing_order: PropTypes.number,
    rate_loanKey: PropTypes.string,
    rate_type: PropTypes.string,
    start_date: PropTypes.string,
    status: PropTypes.string,
    uuid: PropTypes.string
  }).isRequired,
  loanRequest: PropTypes.shape({
    amortization_period: PropTypes.number,
    fee: PropTypes.string,
    accrual_basis: PropTypes.string,
    original_amount: PropTypes.number,
    loan_class: PropTypes.string,
    loan_type: PropTypes.string,
    purpose: PropTypes.string,
    original_rate: PropTypes.number,
    rate_type: PropTypes.string,
    original_type: PropTypes.string,
    origination_fee: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    origination_fee_type: PropTypes.string,
    rate_loanKey: PropTypes.string,
    rate_ceiling: PropTypes.number,
    rate_floor: PropTypes.number,
    rate_index: PropTypes.string,
    uuid: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  paymentSchedule: PropTypes.shape({
    uuid: PropTypes.string,
    institution_uuid: PropTypes.string,
    loan_uuid: PropTypes.string,
    first_payment_date: PropTypes.string,
    next_payment_date: PropTypes.string,
    next_payment_amount: PropTypes.number,
    payments_remaining: PropTypes.number,
    payment_frequency: PropTypes.number,
    payment_frequency_type: PropTypes.string,
    payment_type: PropTypes.string,
    posting_method: PropTypes.string,
    status: PropTypes.string,
    created_datetime: PropTypes.string,
    processing_order: PropTypes.number,
    total_number_of_payments: PropTypes.number,
    phoenix_import: PropTypes.bool,
    phx_pmt_sched_id_no: PropTypes.number,
    deleted: PropTypes.bool
  }),
  radioChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired
};

export default Loan;
