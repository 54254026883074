import { GetArgs, Logr } from "./types";

const levelToInt: Record<LogLevel, number> = {
  debug: 2,
  info: 1,
  log: 0,
  warn: -1,
  error: -2
};
const KEY_NAME = "logr-level";

/**
 * Create a log with an optional category.
 * @description The logr is used to log information to the console, in the
 * future it may provide log information to other storage services such as a
 * remote endpoint.
 *
 * The logr can be configured so that only certain types of messages are logged.
 * This can be controlled globally or by category. There are five logging levels
 * from most to least verbose: debug, info, log, warn, and error. When setting a
 * level it will cause messages with its level and less verbose to be logged. So
 * setting the level "log" means that messages of type log, warn, and error will
 * be logged; debug and info will be ignored.
 *
 * There are two ways to configure the log level: localStorage and URL
 * parameter; in both cases the key name is `logr-level`. The level can be
 * controlled globally by setting a single level as the value, or by a pair
 * consisting of a category and a log level separated by a "pipe" (|) character.
 *
 * A global level example: `logr-level=debug` A category level example:
 * `logr-level=app|debug` A multiple category example:
 * `logr-level=app|debug,ctx|info
 *
 * A logr-level specified in the URL overrides a level specified in
 * localStorage. The localStorage level is read when the logr is created. The
 * URL level is read for every log message.
 *
 * By default error messages are always logged.
 * @param category A string that defines the log category, usually three letters
 * like "app" or "ctx".
 * @returns A logr object.
 */
export function logr(category?: string): Logr {
  // The string in localStorage in a browser or process.env.LOG_LEVEL in Node can be
  //  - just a LogLevel value: "info"
  //  - a key value pair of a category and LogLevel: "app|info"
  //  - multiple comma-separated key value pairs of a category and LogLevel:
  //    "app|info,state|debug"

  let windowExists = false;
  try {
    windowExists = typeof window !== "undefined";
  } catch {
    // Nothing to do here;
  }

  let globalExists = false;
  try {
    globalExists = typeof global !== "undefined";
  } catch {
    // Nothing to do here.
  }

  let sLogLevel: string | null = "info";
  if (windowExists) {
    sLogLevel = localStorage.getItem(KEY_NAME) || null;
  }

  if (globalExists && "process" in global && typeof process !== "undefined") {
    const { LOG_LEVEL = "info" } = process.env;
    sLogLevel = LOG_LEVEL;
  }

  const level = extractLogLevel(sLogLevel, category);
  const messagePrefix = category ? `[${category.toUpperCase()}] ` : "";

  return Object.freeze({
    /**
     * Logs a debug (verbose) message.
     * @param args A GetArgs function is preferred since it will only be invoked
     * if the message can be logged at the current level.
     */
    debug: args => {
      log(level, "debug", args, messagePrefix, category);
    },

    /**
     * Logs an info message.
     * @param args A GetArgs function is preferred since it will only be invoked
     * if the message can be logged at the current level.
     */
    info: args => {
      log(level, "info", args, messagePrefix, category);
    },

    /**
     * Logs a message (info level).
     * @param args A GetArgs function is preferred since it will only be invoked
     * if the message can be logged at the current level.
     */
    log: args => {
      log(level, "log", args, messagePrefix, category);
    },

    /**
     * Logs a warn message.
     * @param args A GetArgs function is preferred since it will only be invoked
     * if the message can be logged at the current level.
     */
    warn: args => {
      log(level, "warn", args, messagePrefix, category);
    },

    /**
     * Logs an error message.
     * @param args A GetArgs function is preferred since it will only be invoked
     * if the message can be logged at the current level.
     */
    error: args => {
      log(level, "error", args, messagePrefix, category);
    }
  });
}

function extractLogLevel(level: string | null, category?: string): LogLevel {
  if (!level) {
    return "warn";
  }

  const lowerLevel = level.toLowerCase();
  // console.log(`extractLogLevel: enter; lowerLevel='${lowerLevel}'`);

  // "info"
  if (Object.keys(levelToInt).some(x => x === lowerLevel)) {
    return lowerLevel as LogLevel;
  }

  let pairs: string[] = [];
  if (lowerLevel.includes(",")) {
    pairs = lowerLevel.split(",");
  } else if (lowerLevel.includes("|")) {
    pairs = [lowerLevel];
  }

  // console.log(`extractLogLevel: pairs=%o`, pairs);

  // The set of pairs can contain a default level for any categories that
  // are not specifically called out.
  let defaultLevel: LogLevel = "warn";
  if (pairs.length) {
    let defaultIndex = -1;
    if (
      Object.keys(levelToInt).some(
        x => -1 < (defaultIndex = pairs.findIndex(y => x === y))
      )
    ) {
      defaultLevel = pairs[defaultIndex] as LogLevel;
      // console.log(`extractLogLevel: levelToInt defaultIndex=${defaultIndex}`);
    }
  }

  if (category) {
    // app|info
    // app|info,state|debug
    const lowerCategory = category.toLowerCase();
    // console.log(`extractLogLevel: lowerCategory='${lowerCategory}'`);

    const result = pairs.find(x => x.split("|")[0] === lowerCategory);
    if (result) {
      const categoryLevel = result.split("|")[1] as LogLevel;
      // console.log(
      //   `extractLogLevel: exit categoryLevel='${categoryLevel}', category='${category}'`
      // );
      return categoryLevel;
    }
  }

  // console.log(
  //   `extractLogLevel: exit; defaultLevel='${defaultLevel}', category='${category}'`
  // );
  return defaultLevel;
}

function log(
  level: LogLevel | null,
  msgLevel: LogLevel,
  args: GetArgs | string,
  messagePrefix: string,
  category?: string
) {
  // A log level in the URL params overrides any other log level.
  if (!loggable(paramLogLevel(category) || level, msgLevel)) {
    return;
  }

  try {
    let cArgs: string | any[];
    if (typeof args === "function") {
      cArgs = args();
    } else {
      cArgs = args;
    }

    if (Array.isArray(cArgs)) {
      if (!cArgs.length) {
        return;
      }

      const [first, ...rArgs] = cArgs;
      let arrArgs: any[];
      if (typeof first === "string") {
        arrArgs = [messagePrefix + first, ...rArgs];
      } else {
        arrArgs = [messagePrefix, ...cArgs];
      }

      console[msgLevel](...arrArgs);
    } else {
      console[msgLevel](messagePrefix + cArgs);
    }
  } catch {
    // No logging an error while logging.
  }
}

function loggable(level: LogLevel | null = "error", msgLevel: LogLevel) {
  return level === null ? true : levelToInt[msgLevel] <= levelToInt[level];
}

function paramLogLevel(category?: string): LogLevel | null {
  // The string in the query params is just like localStorage and can be
  //  - just a LogLevel value: "info"
  //  - a key value pair of a category and LogLevel: "app|info"
  //  - multiple comma-separated key value pairs: "app|info,state|debug"
  if (typeof window === "undefined") {
    return null;
  }
  const params = new URLSearchParams(window.location.search);
  if (!params.has(KEY_NAME)) {
    return null;
  }

  return extractLogLevel(params.get(KEY_NAME), category);
}

type LogLevel = "debug" | "info" | "log" | "warn" | "error";
