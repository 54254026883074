import React from "react";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import PropTypes from "prop-types";

function NavTab({ children, href = "" }) {
  const history = useHistory();
  const location = useLocation();
  const active = !!matchPath(location.pathname, { path: href });
  return (
    <Menu.Item
      style={{
        paddingLeft: "15px",
        paddingRight: "15px"
      }}
      onClick={e => {
        e.preventDefault();
        history.push(href);
      }}
      active={active}
      href={href}
      link
    >
      {children}
    </Menu.Item>
  );
}

NavTab.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string
};

export default NavTab;
