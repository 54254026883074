import { useParams } from "react-router-dom";
import {
  useLoansQuery,
  LoanQueryOptions
} from "../../../../../../../services/RTKQuery/loans";
import { useState } from "react";
import { EditLoanRequestSideSheet } from "./scenes/EditLoanRequestSideSheet";
import { LoanRequestPanel } from "./components/LoanRequestPanel";
import { SideSheet } from "@bafsllc/ui-shared";

export const LoanRequestApps = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { uuid: loanAppUuid } = useParams() as { uuid: string };
  const filters: LoanQueryOptions = {
    loan_app_uuid: loanAppUuid
  };
  const { data: loans, isFetching: fetchingLoanRequests } =
    useLoansQuery(filters);

  if (fetchingLoanRequests) {
    return <h3>Loading...</h3>;
  }

  const handleOnEdit = () => {
    setIsOpen(true);
  };

  let count = 0;
  const displayLoanReqs = loans?.data?.map(loanReq => {
    count += 1;
    return (
      <LoanRequestPanel
        count={count}
        key={loanReq.uuid}
        handleOnEdit={handleOnEdit}
        loanReq={loanReq}
      />
    );
  });

  if (displayLoanReqs?.length > 0) {
    return (
      <>
        {displayLoanReqs}
        <SideSheet
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          size="two-third"
        >
          <EditLoanRequestSideSheet
            isOpen={isOpen}
            savedLoanDetails={loans.data[0]}
            setIsOpen={setIsOpen}
          />
        </SideSheet>
      </>
    );
  } else {
    return <h2>No Loan Requests Found</h2>;
  }
};
