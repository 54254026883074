export const initialState = {
  displayPanel: false,
  includeRead: false,
  notifications: [],
  unreadCount: 0
};

export default (state, action) => {
  const safeState = state ?? initialState;
  switch (action.type) {
    case "NOTIFICATIONS_UPDATE":
      return {
        ...safeState,
        notifications: action.data,
        unreadCount: action.data?.filter(n => n.read === false)?.length
      };

    case "NOTIFICATIONS_TOGGLE_READ":
      return { ...safeState, includeRead: !safeState.includeRead };

    case "NOTIFICATIONS_PANEL":
      return { ...safeState, displayPanel: !safeState.displayPanel };

    default:
      return safeState;
  }
};
