import { TimeSpan } from "../../../../../../../../../../services/DateTime";

// eslint-disable-next-line camelcase
const PastDueDateCell = ({ row }) => {
  const loan = row?.original;
  // eslint-disable-next-line camelcase
  if (loan?.past_due_date) {
    return TimeSpan(loan?.past_due_date, "now");
  }
  return "-";
};

export default PastDueDateCell;
