import TypeCheck from "typecheck-extended";
import ApiEndpoints from "../../../ApiEndpoints";
import { asyncFetchService } from "../../../FetchService";
import { ConcatQueryParams, RemoveNullKeys } from "../Utilities";
import { FormatUrlV2 } from "../../../FormatUrl";

async function asyncRead(filters) {
  TypeCheck(filters.institutionUuid, "string", false);
  TypeCheck(filters.entityUuid, "string", false);
  TypeCheck(filters.loanUuid, "string", false);
  TypeCheck(filters.creditMemoTemplateUuid, "string", false);

  const paramsToExclude = ["entityUuid", "loanUuid", "creditMemoTemplateUuid"];
  const partialQueryParams = ConcatQueryParams(
    { ...filters, pageNumber: 1, pageSize: 999 },
    paramsToExclude
  );
  const queryParams =
    partialQueryParams +
    paramsToExclude.reduce(
      (paramString, param) =>
        paramString +
        (filters?.[param] ? `&filter=${param}=${filters[param]}` : ""),
      ""
    );
  const url = ApiEndpoints.baseUri + ApiEndpoints.entityAnalyses + queryParams;
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

async function asyncAdd(body) {
  const url = ApiEndpoints.baseUri + ApiEndpoints.entityAnalyses;
  const convertedBody = RemoveNullKeys(body);
  const rsp = await asyncFetchService("POST", url, convertedBody);
  return rsp;
}

async function asyncUpdate(uuid, body) {
  const url = FormatUrlV2(ApiEndpoints.entityAnalysesPut, { uuid });
  return asyncFetchService("PATCH", url, body);
}

export default {
  asyncRead,
  asyncAdd,
  asyncUpdate
};
