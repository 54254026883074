export const COMPONENT_NAME = "ticklers-exceptions";

export const filterOptionsParentRelType = [
  { key: "Show All", value: "Show All", text: "Show All Types" },
  { key: "Entity", value: "Entity", text: "Entity" },
  { key: "Loan", value: "Loan", text: "Loan" },
  { key: "Asset", value: "Asset", text: "Asset" }
];

export const filterOptionsStatus = [
  {
    key: "Show All",
    text: "Show All Statuses",
    value: "Show All"
  },
  {
    key: "Pending Upload",
    text: "Pending Upload",
    value: "Pending Upload"
  },
  {
    key: "Pending Approval",
    text: "Pending Approval",
    value: "Pending Approval"
  },
  {
    key: "Grace Period",
    text: "Grace Period",
    value: "Grace Period"
  },
  {
    key: "Past Due",
    text: "Past Due",
    value: "Past Due"
  },
  {
    key: "Overridden",
    text: "Overridden",
    value: "Overridden"
  },
  {
    key: "Satisfied",
    text: "Satisfied",
    value: "Satisfied"
  },
  {
    key: "Inactive",
    text: "Inactive",
    value: "Inactive"
  }
];
