/* eslint-disable camelcase */
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  ConfirmationModal,
  OwnerModal,
  OtherModal,
  OfficerModal,
  EntityModal
} from "../../../../components/Modals";
import RelationshipModal from "./components/RelationshipsModal";
import { relationshipTypes } from "../../../../services/Constants/relationshipTypes";
import useEditableRelationshipCopy from "../../../../components/Modals/components/RelationshipModals/hooks/useEditableRelationshipCopy";
import { useLoanApplicationEntityDetails } from "../../../../store/models/loan-applications";
import EntityDetailsView from "./EntityDetailsView";

function EntityDetails({ loanAppUuid, readOnly }) {
  const [
    {
      borrowers = [],
      selectedBorrowerUuid,
      owners,
      officers,
      otherCapacities,
      affiliates,
      holdingsSubsidiaries
    },
    {
      updateSelectedBorrowerUuid,
      addBorrower,
      updateBorrower,
      addEntityRelationship,
      updateEntityRelationship,
      removeEntityRelationship,
      openEntityModal,
      showErrors
    }
  ] = useLoanApplicationEntityDetails({ loanAppUuid });

  const selectedBorrower =
    borrowers.find(b => b.uuid === selectedBorrowerUuid) || {};
  const selectedBorrowerEntity =
    selectedBorrower && selectedBorrower.entity ? selectedBorrower.entity : {};

  const [borrowerModalOpen, setBorrowerModalOpen] = useState(false);
  const [addEntityModalRelType, setAddEntityModalRelType] = useState();

  const [relationshipUuidToAdd, setRelationshipUuidToAdd] = useState(null);
  const [relationshipToEdit, setRelationshipToEdit] = useState(null);
  const [relTypeToEdit, setRelTypeToEdit] = useState(null);
  const [relationshipToDelete, setRelationshipToDelete] = useState(null);
  // eslint-disable-next-line no-use-before-define
  const [edits, makeEdit] = useEditableRelationshipCopy(relationshipToEdit);

  function handleSaveEntityRelationshipAdd() {
    if (addEntityModalRelType === relationshipTypes.COBORROWER) {
      addBorrower({ entityUuid: relationshipUuidToAdd });
    } else {
      addEntityRelationship({
        entityUuid: relationshipUuidToAdd,
        relType: addEntityModalRelType
      });
    }
    setAddEntityModalRelType(null);
  }

  function handleRelationshipToEdit(relationship, relType) {
    setRelationshipToEdit(relationship);
    setRelTypeToEdit(relType);
  }

  function handleEntityRelationshipUpdates(key, value) {
    makeEdit(key, value);
  }

  function handleSaveEntityRelationshipEdit(data, relationship, relType) {
    updateEntityRelationship(data, relationship, relType);
    handleRelationshipToEdit(null, null);
  }

  function handleDeleteRelationship() {
    removeEntityRelationship(relationshipToDelete);
    setRelationshipToDelete(null, null);
  }

  function handleEditBorrower(stepNumber) {
    openEntityModal(stepNumber);
    setBorrowerModalOpen(true);
  }

  return (
    <>
      <EntityDetailsView
        borrowers={borrowers}
        selectedBorrowerUuid={selectedBorrowerUuid}
        owners={owners}
        officers={officers}
        otherCapacities={otherCapacities}
        affiliates={affiliates}
        holdingsSubsidiaries={holdingsSubsidiaries}
        readOnly={readOnly}
        onSelectedBorrowerUuidChange={updateSelectedBorrowerUuid}
        onAddBorrower={() =>
          setAddEntityModalRelType(relationshipTypes.COBORROWER)
        }
        onBasicInfoEdit={() => handleEditBorrower(1)}
        onContactInfoEdit={() => handleEditBorrower(2)}
        onCreditInfoEdit={() => handleEditBorrower(3)}
        onAddEntityRelationship={entityType =>
          setAddEntityModalRelType(entityType)
        }
        onEditEntityRelationship={(entityRel, entityType) =>
          handleRelationshipToEdit(entityRel, entityType)
        }
        onRemoveEntityRelationship={setRelationshipToDelete}
      />

      {addEntityModalRelType ? (
        <RelationshipModal
          onError={err => showErrors({ [addEntityModalRelType]: err })}
          onClose={() => setAddEntityModalRelType(null)}
          onSave={handleSaveEntityRelationshipAdd}
          onSelectEntity={entityUuid => setRelationshipUuidToAdd(entityUuid)}
          open={!!addEntityModalRelType}
          relType={addEntityModalRelType}
          institutionUuid={selectedBorrowerEntity.institution_uuid || ""}
        />
      ) : null}
      {selectedBorrowerEntity.uuid && borrowerModalOpen ? (
        <EntityModal
          entityUuid={selectedBorrowerEntity.uuid}
          open={borrowerModalOpen}
          onCloseModal={() => setBorrowerModalOpen(false)}
          onSuccess={({ data }) => updateBorrower(data)}
        />
      ) : null}
      <ConfirmationModal
        open={!!relationshipToDelete}
        onClose={() => setRelationshipToDelete(null)}
        handleCancel={() => setRelationshipToDelete(null)}
        handleConfirm={() => handleDeleteRelationship(relationshipToDelete)}
        prompt="Delete Relationship"
        warningDetails="Are you sure you want to delete this Relationship?"
        yesButtonLabel="Delete"
      />
      {relationshipToEdit &&
      edits &&
      relTypeToEdit === relationshipTypes.OWNER ? (
        <OwnerModal
          form={edits}
          onClose={() => handleRelationshipToEdit(null, null)}
          onSave={() =>
            handleSaveEntityRelationshipEdit(
              edits,
              relationshipToEdit,
              relationshipTypes.OWNER
            )
          }
          updateForm={(event, { name: key, value }) =>
            handleEntityRelationshipUpdates(key, value)
          }
        />
      ) : null}
      {relationshipToEdit &&
      edits &&
      relTypeToEdit === relationshipTypes.OFFICER ? (
        <OfficerModal
          form={edits}
          onClose={() => handleRelationshipToEdit(null, null)}
          onSave={() =>
            handleSaveEntityRelationshipEdit(
              edits,
              relationshipToEdit,
              relationshipTypes.OFFICER
            )
          }
          updateForm={(event, { name: key, value }) =>
            handleEntityRelationshipUpdates(key, value)
          }
        />
      ) : null}
      {relationshipToEdit &&
      edits &&
      relTypeToEdit === relationshipTypes.OTHER_CAPACITIES ? (
        <OtherModal
          form={edits}
          onClose={() => handleRelationshipToEdit(null, null)}
          onSave={() =>
            handleSaveEntityRelationshipEdit(
              edits,
              relationshipToEdit,
              relationshipTypes.OTHER_CAPACITIES
            )
          }
          updateForm={(event, { name: key, value }) =>
            handleEntityRelationshipUpdates(key, value)
          }
        />
      ) : null}
    </>
  );
}

EntityDetails.propTypes = {
  loanAppUuid: PropTypes.string,
  readOnly: PropTypes.bool
};

export default EntityDetails;
