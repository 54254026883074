/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { Grid, Form, Dropdown } from "semantic-ui-react";
import styled from "@emotion/styled";
import { DollarFormat } from "../../../../../../../../../../components/CustomUIElements";
import { EditModal } from "../../../../../../../../../../components/Modals";
import Constants from "../../../../../../../../../../services/Constants/strings";
import { PercentInput } from "../../../../../../../../../../components/CustomFormElements";

const govGuaranteeProgramOptions = [
  {
    key: Constants.SBA_KEY,
    value: Constants.SBA_VALUE,
    text: Constants.SBA_KEY
  },
  {
    key: Constants.USDA_KEY,
    value: Constants.USDA_VALUE,
    text: Constants.USDA_KEY
  }
];
const govGuaranteeAgencyProgram = [
  { key: Constants.PPP, value: Constants.PPP, text: Constants.PPP },
  { key: Constants.SEVEN_A, value: Constants.SEVEN_A, text: Constants.SEVEN_A },
  {
    key: Constants.FIVE_ZERO_FOUR,
    value: Constants.FIVE_ZERO_FOUR,
    text: Constants.FIVE_ZERO_FOUR
  },
  { key: Constants.EXPRESS, value: Constants.EXPRESS, text: Constants.EXPRESS },
  {
    key: Constants.COMMUNITY_FACILITIES,
    value: Constants.COMMUNITY_FACILITIES,
    text: Constants.COMMUNITY_FACILITIES
  },
  {
    key: Constants.BUSINESS_AND_INDUSTRY,
    value: Constants.BUSINESS_AND_INDUSTRY,
    text: Constants.BUSINESS_AND_INDUSTRY
  },
  { key: Constants.OTHER, value: Constants.OTHER, text: Constants.OTHER },
  { key: Constants.CAPLINE, value: Constants.CAPLINE, text: Constants.CAPLINE }
];
const sbaStatusOptions = [
  { key: "", value: "", text: "" },
  {
    key: Constants.DEFERRED,
    value: Constants.DEFERRED,
    text: Constants.DEFERRED
  },
  {
    key: Constants.IN_LIQUIDATION,
    value: Constants.IN_LIQUIDATION,
    text: Constants.IN_LIQUIDATION
  },
  {
    key: Constants.PAID_IN_FULL,
    value: Constants.PAID_IN_FULL,
    text: Constants.PAID_IN_FULL
  },
  {
    key: Constants.TRANSFERRED,
    value: Constants.TRANSFERRED,
    text: Constants.TRANSFERRED
  },
  {
    key: Constants.PURCHASED_BY_SBA,
    value: Constants.PURCHASED_BY_SBA,
    text: Constants.PURCHASED_BY_SBA
  },
  {
    key: Constants.FULLY_UNDISBURSED,
    value: Constants.FULLY_UNDISBURSED,
    text: Constants.FULLY_UNDISBURSED
  }
];

function GovtGuarEditModal({ loan, onSave, ...props }) {
  const handleSave = values => {
    if (Object.keys(values).length) {
      onSave({
        ...values,
        gov_guarantee_fee: values.gov_guarantee_fee / 10000
      });
    }
  };
  const {
    current_balance,
    gov_guarantee_percentage,
    gov_guarantee_program,
    gov_guarantee_fee,
    gov_guarantee_program_number,
    gov_guarantee_agency_program,
    guaranteed_portion_sold,
    gov_guarantee_sba_status,
    gov_guarantee_other_agency_program,
    uuid
  } = loan;
  return (
    <EditModal
      key={uuid}
      onSave={handleSave}
      defaultValues={{
        current_balance,
        gov_guarantee_percentage,
        gov_guarantee_program,
        gov_guarantee_fee: (gov_guarantee_fee * 10000).toFixed(2),
        gov_guarantee_program_number,
        gov_guarantee_agency_program,
        guaranteed_portion_sold,
        gov_guarantee_sba_status,
        gov_guarantee_other_agency_program
      }}
      header="Government Guarantee Details"
      {...props}
    >
      {({ onChange, values }) => (
        <Grid padded>
          <Grid.Row columns="2">
            <Grid.Column>
              <Form.Field>
                <label>Guaranteed Balance</label>
                <Box>
                  <DollarFormat
                    value={current_balance * values.gov_guarantee_percentage}
                  />
                </Box>
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field
                control={Dropdown}
                label="Guarantee Program"
                name="gov_guarantee_program"
                options={govGuaranteeProgramOptions}
                value={values.gov_guarantee_program}
                selection
                onChange={(ev, { name, value }) => onChange({ name, value })}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="2">
            <Grid.Column>
              <PercentInput
                data-testid="pi_gov_guarantee_percentage"
                label="Guaranteed Percentage"
                scale={3}
                name="gov_guarantee_percentage"
                onChange={(ev, { name, value }) =>
                  onChange({ name, value: Number(value) }, "govtguar")
                }
                placeholder="0.00"
                value={values.gov_guarantee_percentage || ""}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                label="Program Loan Number"
                name="gov_guarantee_program_number"
                value={values.gov_guarantee_program_number || ""}
                onChange={(ev, { name, value }) => onChange({ name, value })}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="2">
            <Grid.Column>
              <Form.Input
                type="number"
                label="Guarantee Fee"
                name="gov_guarantee_fee"
                value={values.gov_guarantee_fee}
                onChange={(ev, { name, value }) =>
                  onChange({ name, value: Number(value) })
                }
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Select
                control={Dropdown}
                label="Agency Program"
                name="gov_guarantee_agency_program"
                options={govGuaranteeAgencyProgram}
                value={values.gov_guarantee_agency_program}
                selection
                onChange={(ev, { name, value }) => onChange({ name, value })}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="2">
            <Grid.Column>
              <PercentInput
                data-testid="pi_guaranteed_portion_sold"
                label="Guaranteed Portion Sold"
                scale={3}
                name="guaranteed_portion_sold"
                onChange={(ev, { name, value }) =>
                  onChange({ name, value: Number(value) }, "govtguar")
                }
                placeholder="0.00"
                value={values.guaranteed_portion_sold || ""}
              />
            </Grid.Column>
            {values.gov_guarantee_program === Constants.SBA_VALUE && (
              <Grid.Column>
                <Form.Select
                  control={Dropdown}
                  label="SBA Status"
                  name="gov_guarantee_sba_status"
                  options={sbaStatusOptions}
                  value={values.gov_guarantee_sba_status}
                  selection
                  onChange={(ev, { name, value }) => onChange({ name, value })}
                />
              </Grid.Column>
            )}
            {values.gov_guarantee_agency_program === Constants.OTHER && (
              <Grid.Column
                style={{
                  marginTop:
                    values.gov_guarantee_program === Constants.SBA_VALUE
                      ? "1em"
                      : "0em"
                }}
              >
                <Form.Input
                  label="Other Agency Program"
                  name="gov_guarantee_other_agency_program"
                  value={values.gov_guarantee_other_agency_program || ""}
                  onChange={(ev, { name, value }) => onChange({ name, value })}
                />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      )}
    </EditModal>
  );
}

GovtGuarEditModal.propTypes = {
  loan: PropTypes.shape({
    uuid: PropTypes.string,
    current_balance: PropTypes.number,
    gov_guarantee_percentage: PropTypes.number,
    gov_guarantee_program: PropTypes.string,
    gov_guarantee_agency_program: PropTypes.string,
    gov_guarantee_other_agency_program: PropTypes.string,
    guaranteed_portion_sold: PropTypes.number,
    gov_guarantee_sba_status: PropTypes.string,
    gov_guarantee_fee: PropTypes.number,
    gov_guarantee_program_number: PropTypes.string
  }).isRequired,
  onSave: PropTypes.func
};

const Box = styled.div`
  padding: 0.8em;
  font-size: 1em;
`;

export default GovtGuarEditModal;
