import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  loanRequestsSelector,
  settlementSheet
} from "../../../../store/models/loan-applications";
import { SettlementSheets } from "../../../../services/ApiLib";
import { actionCreators } from "../../scenes/LoanOriginationStepper/reducer";
import SettlementTable from "./components/SettlementTable";
import { ErrorReport } from "../../../../services/ErrorReporter";

function SettlementSheet({ loanAppUuid }) {
  const loanRequests = useSelector(loanRequestsSelector);
  const settlementSheets = useSelector(settlementSheet);
  const dispatch = useDispatch();

  function onError(thrownError) {
    const messageForUser = "Error: Could not view/save settlement data.";
    ErrorReport.toModal(thrownError, messageForUser);
  }

  const getSettlementSheets = useCallback(() => {
    const pushData = (loanRequestUuid, resp) =>
      dispatch({
        settlementSheets: { [loanRequestUuid]: resp },
        type: "LOR_SETTELMENT_SHEET_GET"
      });
    try {
      loanRequests.map(async loanRequest => {
        const resp = await SettlementSheets.asyncGet({
          loanUuid: loanRequest.uuid
        });
        if (resp) {
          pushData(loanRequest.uuid, resp.data);
        }
      });
    } catch (err) {
      onError(err);
    }
  }, [dispatch, loanRequests]);

  useEffect(() => {
    dispatch(actionCreators.getLoanApp(loanAppUuid));
  }, [dispatch, loanAppUuid]);

  useEffect(() => {
    getSettlementSheets();
  }, [getSettlementSheets]);

  const getReasonOther = addData => {
    if (!addData.reason_other) return null;
    let reasonOther = "";
    if (
      addData?.reason_deposits === "Other" ||
      addData?.reason_charges_and_fees === "Other"
    ) {
      reasonOther = addData.reason_other;
    }
    return {
      reason_other: reasonOther
    };
  };

  async function addSettlement(loanRequest, addData) {
    const settlementBody = {
      ...addData,
      institution_uuid: loanRequest.institution_uuid,
      item_type: addData?.reason_deposits ? "Deposit" : "Charges and Fees",
      ...getReasonOther(addData)
    };

    try {
      const resp = await SettlementSheets.asyncAdd(
        { loanUuid: loanRequest.uuid },
        settlementBody
      );
      if (resp.data) {
        const newSettlementData = resp.data;
        dispatch({
          type: "LOR_SETTELMENT_SHEET_ADD",
          settlementSheet: newSettlementData,
          uuid: loanRequest.uuid
        });
      }
    } catch (err) {
      onError(err);
    }
  }

  async function updateSettlement(loanRequest, updateData) {
    const settlementBody = {
      ...updateData,
      ...getReasonOther(updateData)
    };

    try {
      const resp = await SettlementSheets.asyncUpdate(
        { loanUuid: loanRequest.uuid, itemUuid: updateData.uuid },
        settlementBody
      );
      if (resp.data) {
        const updatedSettlementData = resp.data;
        dispatch({
          type: "LOR_SETTELMENT_SHEET_UPDATE",
          settlementSheet: updatedSettlementData,
          settlementUuid: updateData.uuid,
          uuid: loanRequest.uuid
        });
      }
    } catch (err) {
      onError(err);
    }
  }

  let count = 0;
  return loanRequests.map(loanRequest => {
    count += 1;
    const settlementData = settlementSheets[loanRequest.uuid];
    return (
      settlementData && (
        <div key={loanRequest.uuid}>
          <SettlementTable
            key={`${loanRequest.uuid}`}
            index={count}
            settlementData={settlementData}
            entity={loanRequest}
            onAddSettlement={addData => {
              addSettlement(loanRequest, addData);
            }}
            onUpdateSettlement={updateData => {
              updateSettlement(loanRequest, updateData);
            }}
          />
        </div>
      )
    );
  });
}

SettlementSheet.propTypes = {
  loanAppUuid: PropTypes.string
};

export default SettlementSheet;
