import { createElement, forwardRef } from "react";
import type { HandleClassNames } from "../../../../types/class-names";
import type {
  AllIconClassNames,
  ButtonClassNames,
  ButtonSizeClassNames,
  ButtonVariantClassNames,
  PositionClassNames,
  SizeClassNames,
  SpacingClassNames,
  WidthClassNames
} from "../../../../types/elements";
import type { MenuClassNames } from "../../../../types/collections";

/**
 * This component is not intended for use directly in an app. It is responsible
 * for giving a button the proper appearance. The only way to set the content of
 * this button is through the `children` prop and a "real" button probably wants
 * to have more limitations over how content is set.
 */
export const ButtonBase = forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<ButtonBaseProps>
>(function ButtonBaseImpl(
  {
    as = "button",
    children,
    size = "medium",
    updateClassNames,
    variant,
    testId,
    ...props
  },
  ref
) {
  const variantMap: Record<
    Required<ButtonBaseProps>["variant"],
    MappedVariantClassNames
  > = {
    secondary: "basic",
    tertiary: "tertiary",
    primary: "primary"
  };

  const classNames: (
    | ButtonClassNames
    | MappedVariantClassNames
    | SizeClassNames
  )[] = [size, "ui", variantMap[variant || "primary"], "button", "bold"];

  return createElement(
    as,
    {
      className: (updateClassNames
        ? updateClassNames(classNames as AllowedClassNames[])
        : classNames
      ).join(" "),
      ref,
      "data-testid": testId,
      ...props
    },
    children
  );
});

export type AllowedClassNames =
  | AllIconClassNames
  | ButtonClassNames
  | Extract<MenuClassNames, "item">
  | PositionClassNames
  | ButtonSizeClassNames
  | SpacingClassNames
  | WidthClassNames;

export interface ButtonBaseProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "className"> {
  /** The tag to use for the button. Defaults to `button`. */
  as?: keyof HTMLElementTagNameMap;
  /** Defaults to a "medium" size button. */
  size?: ButtonSizeClassNames;
  /** Classes provided by HOCs. */
  updateClassNames?: HandleClassNames<AllowedClassNames>;
  /** Defaults to a "standard" button. */
  variant?: ButtonVariantClassNames;
  testId?: string;
}

/** These are existing class names that should be applied to a variant but do
 * not match the variant names. */
type MappedVariantClassNames = "basic" | "primary" | "tertiary";
