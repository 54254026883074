import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import logger from "../../services/logger";
import BlastIframe from "./BlastIframe";
import { getBlastClientApplicationBaseUrl } from "./getBaseUrl";

function BlastEmbed({ action, queryParams, urlPath, onUpdate = () => null }) {
  const embed = useRef(null);
  const baseUrl = getBlastClientApplicationBaseUrl();

  useEffect(() => {
    if (embed && embed.current) {
      embed.current.contentWindow.postMessage(action, baseUrl);
    }
  }, [baseUrl, action]);

  return (
    <BlastIframe
      queryParams={queryParams}
      urlPath={urlPath}
      onUpdate={e => {
        if (e && e.data && e.origin === baseUrl) {
          logger.debug("BlastEmbed: data=", e.data);
          onUpdate(e.data);
        }
      }}
      ref={embed}
    />
  );
}

BlastEmbed.propTypes = {
  action: PropTypes.shape({
    context: PropTypes.string,
    data: PropTypes.any
  }),
  queryParams: PropTypes.objectOf(PropTypes.string),
  urlPath: PropTypes.string.isRequired,
  onUpdate: PropTypes.func
};
export default BlastEmbed;
