import { AnimationStage } from "../../../../types/modules";

/**
 * Update an array of fomantic-ui class names with the classes needed for a
 * certain animation stage.
 * This is exclusively to be use in the Dialogs components
 * @param nextStage The stage the animated modal component is moving into this
 * stage.
 * @param classes The array will have fomantic-ui animation classes added to the
 * array.
 * @returns The input array.
 */
export function addAnimationClasses(
  classes: string[],
  nextStage?: AnimationStage
) {
  if (nextStage) {
    classes.push("transition", "fade");
  } else {
    classes.push("visible");
  }

  if (nextStage === "fadeIn") {
    classes.push("visible", "in");
  } else if (nextStage === "fadeOut") {
    classes.push("visible", "out");
  }

  return classes;
}
