/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Header } from "semantic-ui-react";
import { useSelector } from "react-redux";
import BeneficialTable from "./Table";
import AddRelatedDetailsModal from "./AddRelatedDetailsModal";
import { LoanApp, Entities } from "../../../../services/ApiLib";
import { permCheck } from "../../../../services/Auth";
import logger from "../../../../services/logger";

const OWNER = "Beneficial Owner";
const CERTIFIER = "Beneficial Owner Certifier";
const MANAGER = "Beneficial Owner Manager";
const SIGNERS = "Signer";
function RelatedDetails({ loanAppUuid }) {
  const [relType, setOpen] = useState(null);
  const [relatedDetailsTables, setTableData] = useState([]);
  const { loanApp } = useSelector(state => ({
    loanApp: state.LosPhasesReducer.loanApp
  }));
  const setModalOpen = values => setOpen(values);
  const deletePerm = permCheck([
    "related_details_delete_priv",
    "related_details_delete"
  ]);
  const readWritePerm = permCheck([
    "related_details_rw_priv",
    "related_details_rw"
  ]);
  const readPerm = permCheck([
    "related_details_read_priv",
    "related_details_read"
  ]);
  useEffect(() => {
    LoanApp.asyncGetRelatedDetails({ loanAppUuid })
      .then(({ data }) => {
        if (data) {
          data.map(
            async ({
              individual_entity_uuid,
              business_entity_uuid,
              rel_type,
              uuid
            }) => {
              const { data: individualData } = await Entities.read({
                entityUuid: individual_entity_uuid
              });
              const { data: businessData } = await Entities.read({
                entityUuid: business_entity_uuid
              });
              if (!businessData.error && !individualData.error) {
                const newData = [
                  {
                    individualData,
                    businessData,
                    relType: rel_type,
                    uuid
                  }
                ];
                setTableData(prevData => [...prevData, ...newData]);
              }
            }
          );
        }
      })
      .catch(error => {
        logger.error(error);
      });
  }, [loanAppUuid]);
  const onAddRelatedDetail = ({ individualEntity, businessEntity }) => {
    LoanApp.asyncAddRelatedDetails(
      { loanAppUuid },
      {
        individual_entity_uuid: individualEntity,
        business_entity_uuid: businessEntity,
        institution_uuid: loanApp.institution_uuid,
        rel_type: relType
      }
    )
      .then(async ({ data }) => {
        const { data: individualData } = await Entities.read({
          entityUuid: data.individual_entity_uuid
        });
        const { data: businessData } = await Entities.read({
          entityUuid: data.business_entity_uuid
        });
        if (!businessData.error && !individualData.error) {
          const newData = [
            {
              individualData,
              businessData,
              relType,
              uuid: data.uuid
            }
          ];
          setTableData(prevData => [...prevData, ...newData]);
        }
      })
      .catch(error => {
        logger.error(error);
      });
  };
  const onRemoveBeneficials = uuid => {
    LoanApp.asyncRemove(uuid, loanAppUuid)
      .then(() => {
        const newData = relatedDetailsTables.filter(g => g.uuid !== uuid);
        setTableData(newData);
      })
      .catch(error => {
        logger.error(error);
      });
  };
  return (
    <div>
      {relatedDetailsTables && (
        <>
          <Header as="h3">Beneficial Owners</Header>
          <BeneficialTable
            data={relatedDetailsTables}
            onOpen={setModalOpen}
            type={OWNER}
            onRemoveBeneficials={onRemoveBeneficials}
            permission={{
              read: readPerm,
              delete: deletePerm,
              readWrite: readWritePerm
            }}
          />
          <Header as="h3">Beneficial Owner Certifiers</Header>
          <BeneficialTable
            data={relatedDetailsTables}
            onOpen={setModalOpen}
            type={CERTIFIER}
            onRemoveBeneficials={onRemoveBeneficials}
            permission={{
              read: readPerm,
              delete: deletePerm,
              readWrite: readWritePerm
            }}
          />
          <Header as="h3">Beneficial Managers</Header>
          <BeneficialTable
            data={relatedDetailsTables}
            onOpen={setModalOpen}
            type={MANAGER}
            onRemoveBeneficials={onRemoveBeneficials}
            permission={{
              read: readPerm,
              delete: deletePerm,
              readWrite: readWritePerm
            }}
          />
          <Header as="h3">Signer</Header>
          <BeneficialTable
            data={relatedDetailsTables}
            onOpen={setModalOpen}
            type={SIGNERS}
            onRemoveBeneficials={onRemoveBeneficials}
            permission={{
              read: readPerm,
              delete: deletePerm,
              readWrite: readWritePerm
            }}
          />
          <AddRelatedDetailsModal
            open={relType}
            institutionUuid={loanApp.institution_uuid}
            onCancel={setModalOpen}
            onAddRelatedDetail={onAddRelatedDetail}
          />
        </>
      )}
    </div>
  );
}

RelatedDetails.propTypes = {
  loanAppUuid: PropTypes.string
};

export default RelatedDetails;
