import PropTypes from "prop-types";
import { PercentFormat } from "../../../../../../../../../components/CustomUIElements";

const RateDetailsCell = ({ row }) => {
  const data = row?.original;
  if (data?.rate_type === "Fixed") {
    return (
      <div className="flex flex-col">
        <div>
          <b>Rate:</b> <PercentFormat value={data?.interest_rate} />
        </div>
      </div>
    );
  }

  if (data?.rate_type === "Variable") {
    return (
      <div className="flex flex-col">
        <div>
          <b>Index:</b> {data?.rate_index}
        </div>
        <div>
          <b>Rate Margin:</b> <PercentFormat value={data?.margin} />
        </div>
        <div>
          <b>Rate:</b> <PercentFormat value={data?.interest_rate} />
        </div>
      </div>
    );
  }
  return null;
};

export default RateDetailsCell;

RateDetailsCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({})
  })
};
