import React from "react";
import { string, number, shape, arrayOf } from "prop-types";
import { Table } from "semantic-ui-react";
import ResultHeader from "./resultsHeader";
import ResultRow from "./resultsRow";

function SnapshotsTable({ loans }) {
  return (
    <Table compact celled selectable>
      <Table.Header>
        <Table.Row>
          <ResultHeader data-testid="results-header" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <ResultRow loans={loans} data-testid="results-row" />
      </Table.Body>
    </Table>
  );
}

SnapshotsTable.propTypes = {
  loans: arrayOf(
    shape({
      account_type: string,
      bafs_account_number: string,
      current_balance: number,
      available_balance: number,
      total_exposure: number,
      uuid: string,
      institution_account_number: string,
      loan_class: string,
      loan_type: string
    })
  )
};

export default SnapshotsTable;
