import React from "react";

function WelcomePage() {
  return (
    <div>
      <h1>Welcome to the BLAST Portal!</h1>
      <p>Please select a link from the sidebar to begin.</p>
    </div>
  );
}

export default WelcomePage;
