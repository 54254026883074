import React, { useState } from "react";
import RTKStatus from "./scenes/RTKStatus";

import { Button } from "@bafsllc/ui-shared";

export const AppStatus: React.FC = () => {
  const currentEnv = process.env.NODE_ENV;
  const allowedEnvironments = ["development", "test", "staging"];
  const [runRTKTests, toggleRunRTKTests] = useState(false);

  if (!allowedEnvironments.includes(currentEnv.toLowerCase())) {
    return (
      <>
        <h1>App Status</h1>
        <p>
          This status page is currently disabled in Production environments.
        </p>
      </>
    );
  }

  return (
    <div>
      <h1>App Status</h1>
      <b>Current Environment: </b> {currentEnv} <br />
      <p>
        This page will contain an up or down status indicator for various
        aspects of the app. It is still under active development and is not yet
        nearly fully representative of the overall app status.
      </p>
      <p>
        Where needed, there are hardcoded UUIDs. These should always be from the
        BAFS Testing institution.{" "}
        <b>
          <i>Never use a UUID from a real institution!</i>
        </b>
      </p>
      {/* We could organize these groups better, but it's probably fine for now */}
      {runRTKTests ? (
        <>
          <Button
            message={{ defaultMessage: "Close RTK Tests" }}
            onClick={() => toggleRunRTKTests(!runRTKTests)}
            size="tiny"
          />

          <RTKStatus />
        </>
      ) : (
        <Button
          message={{ defaultMessage: "Run RTK Tests" }}
          onClick={() => toggleRunRTKTests(!runRTKTests)}
          size="tiny"
        />
      )}
    </div>
  );
};
