const capOptions = [
  { key: "gu", text: "Guarantor", value: "Guarantor" },
  { key: "gh", text: "Guarantee Holder", value: "Guarantee Holder" },
  { key: "c", text: "Co-Signer", value: "Co-Signer" },
  { key: "gr", text: "Grantor", value: "Grantor" },
  { key: "a", text: "Agent", value: "Agent" },
  { key: "aif", text: "Attorney in Fact", value: "Attorney in Fact" },
  { key: "j", text: "Joint Applicant", value: "Joint Applicant" },
  { key: "tb", text: "Trust Beneficiory", value: "Trust Beneficiory" },
  { key: "o", text: "Other", value: "Other" }
];

export default capOptions;
