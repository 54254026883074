import createAPIService from "../../../FetchService/createAPIService";
import APIEndpoints from "../../../ApiEndpoints";

const service = createAPIService({
  baseUrl: APIEndpoints.baseUri,
  create: APIEndpoints.loanInterestSchedules,
  retrieve: [
    APIEndpoints.loanInterestSchedules,
    APIEndpoints.loanInterestSchedule
  ],
  update: APIEndpoints.loanInterestSchedule,
  delete: APIEndpoints.loanInterestSchedule,
  options: { pagination: true }
});

const { retrieve } = service;
service.retrieve = (identifiers, meta) => retrieve(identifiers, meta);

export default service;
