/* eslint-disable camelcase */
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import React from "react";
import { Form, Header } from "semantic-ui-react";
import { semanticUIStates } from "../../../../../../../services/FormElements";
import useEntity from "../../../hooks/useEntity/useEntity";

function OtherContactInfo({ handleConvertAddress }) {
  const {
    email,
    handleInputChange,
    isMailingAddress,
    mail_address_city,
    mail_address_line1,
    mail_address_line2,
    mail_address_state,
    mail_address_zip,
    mobile_phone_no,
    phone_no,
    phy_address_city,
    phy_address_line1,
    phy_address_line2,
    phy_address_state,
    phy_address_zip,
    website
  } = useEntity();

  return (
    <Form>
      <Header size="small" content="Physical Address" />
      <Form.Group size="large">
        <Form.Input
          name="phy_address_line1"
          label="Address"
          width={8}
          value={phy_address_line1 || ""}
          onChange={handleInputChange}
        />
        <Form.Input
          name="phy_address_line2"
          label="Address Line 2"
          width={8}
          value={phy_address_line2 || ""}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Input
          name="phy_address_city"
          label="City"
          width={4}
          value={phy_address_city || ""}
          onChange={handleInputChange}
        />
        <Form.Select
          name="phy_address_state"
          label="State"
          options={semanticUIStates}
          search
          width={4}
          value={phy_address_state || ""}
          onChange={handleInputChange}
        />
        <Form.Input
          name="phy_address_zip"
          label="Zip"
          width={4}
          value={phy_address_zip || ""}
          onChange={handleInputChange}
        />
        <Form.Input
          name="phy_address_country"
          label="Country"
          disabled
          value="US"
          width={4}
        />
      </Form.Group>
      <Form.Group>
        <Form.Checkbox
          name="is_mailing_address"
          label="Use Physical Address as Mailing Address"
          checked={isMailingAddress}
          width={12}
          onChange={handleConvertAddress}
        />
      </Form.Group>
      <Header size="small" content="Mailing Address" />
      <Form.Group>
        <Form.Input
          name="mail_address_line1"
          disabled={isMailingAddress}
          label="Address"
          width={8}
          value={mail_address_line1 || ""}
          onChange={handleInputChange}
        />
        <Form.Input
          name="mail_address_line2"
          disabled={isMailingAddress}
          label="Address Line 2"
          width={8}
          value={mail_address_line2 || ""}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Input
          name="mail_address_city"
          disabled={isMailingAddress}
          label="City"
          width={4}
          value={mail_address_city || ""}
          onChange={handleInputChange}
        />
        <Form.Select
          name="mail_address_state"
          disabled={isMailingAddress}
          label="State"
          options={semanticUIStates}
          search
          width={4}
          value={mail_address_state || ""}
          onChange={handleInputChange}
        />
        <Form.Input
          name="mail_address_zip"
          disabled={isMailingAddress}
          label="Zip"
          width={4}
          value={mail_address_zip || ""}
          onChange={handleInputChange}
        />
        <Form.Input
          name="country"
          label="Country"
          disabled
          width={4}
          value="US"
        />
      </Form.Group>
      <Form.Group>
        <Form.Field width={9}>
          <label>Phone Number</label>
          <NumberFormat
            format="(###) ###-####"
            name="phone_no"
            value={phone_no || ""}
            onChange={e =>
              handleInputChange(e, { name: "phone_no", value: e.target.value })
            }
          />
        </Form.Field>
        <Form.Field width={9}>
          <label>Phone Number (Mobile)</label>
          <NumberFormat
            format="(###) ###-####"
            name="mobile_phone_no"
            value={mobile_phone_no || ""}
            onChange={e =>
              handleInputChange(e, {
                name: "mobile_phone_no",
                value: e.target.value
              })
            }
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Input
          name="email"
          label="Email"
          width={9}
          value={email || ""}
          onChange={handleInputChange}
        />
        <Form.Input
          name="website"
          label="Website"
          width={9}
          value={website || ""}
          onChange={handleInputChange}
        />
      </Form.Group>
    </Form>
  );
}

OtherContactInfo.propTypes = {
  handleConvertAddress: PropTypes.func.isRequired
};

export default OtherContactInfo;
