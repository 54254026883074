import TypeCheck from "typecheck-extended";

/*
  NOTE: This assumes the phone number will use United States formatting
        and that the string to be formatted DOES NOT include a country code.
*/
function FormatPhoneNumber(number) {
  TypeCheck(number, "string");

  const strippedNum = number.replace(/\D/g, "");
  const areaCode = strippedNum.substring(0, 3);
  const exchangeNum = strippedNum.substring(3, 6);
  const lineNum = strippedNum.substring(6, 10);

  const formattedNumber = `(${areaCode}) ${exchangeNum}-${lineNum}`;
  return formattedNumber;
}

export default FormatPhoneNumber;
