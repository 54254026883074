import React from "react";
import PropTypes from "prop-types";
import { Table, Button, Icon } from "semantic-ui-react";
import { useTable, useSortBy } from "react-table";

function ResourceTable({
  items = [],
  columns,
  tableOptions,
  celled,
  padded,
  compact = true,
  onDelete,
  onEdit,
  onReorderItem
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: items,
        ...tableOptions
      },
      useSortBy
    );

  return (
    <Table
      {...getTableProps()}
      compact={compact}
      celled={celled}
      padded={padded}
    >
      <Table.Header>
        {headerGroups.map(headerGroup => (
          <Table.Row {...headerGroup.getHeaderGroupProps()}>
            {onReorderItem ? <Table.HeaderCell collapsing /> : null}
            {headerGroup.headers.map(column => (
              <Table.HeaderCell {...column.getHeaderProps()}>
                {column.render("Header")}
              </Table.HeaderCell>
            ))}
            {onEdit ? <Table.HeaderCell collapsing /> : null}
            {onDelete ? <Table.HeaderCell collapsing /> : null}
          </Table.Row>
        ))}
      </Table.Header>
      <Table.Body {...getTableBodyProps()}>
        {rows.map(
          row =>
            prepareRow(row) || (
              <Table.Row {...row.getRowProps()}>
                {onReorderItem ? (
                  <Table.Cell collapsing>
                    <Button
                      basic
                      icon={<Icon name="caret square up" size="large" />}
                      onClick={() => onReorderItem(row.original, -1)}
                      style={{ boxShadow: "none" }}
                    />
                  </Table.Cell>
                ) : null}
                {row.cells.map(cell => (
                  <Table.Cell
                    {...cell.getCellProps({
                      collapsing: cell.column.collapsing
                    })}
                  >
                    {cell.render("Cell", {
                      ...(cell.column.customProps || {})
                    })}
                  </Table.Cell>
                ))}
                {onEdit ? (
                  <Table.Cell collapsing>
                    <Button
                      data-testid="edit-button"
                      icon="edit"
                      onClick={() => onEdit(row.original)}
                      circular
                      basic
                    />
                  </Table.Cell>
                ) : null}
                {onDelete ? (
                  <Table.Cell collapsing>
                    <Button
                      icon="delete"
                      data-testid="delete-button"
                      onClick={() => onDelete(row.original)}
                      circular
                      basic
                    />
                  </Table.Cell>
                ) : null}
              </Table.Row>
            )
        )}
      </Table.Body>
    </Table>
  );
}

ResourceTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.elementType,
      id: PropTypes.string,
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      Cell: PropTypes.elementType,
      collapsing: PropTypes.bool
    })
  ),
  tableOptions: PropTypes.shape(),
  celled: PropTypes.bool,
  padded: PropTypes.bool,
  compact: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onReorderItem: PropTypes.func
};

export default ResourceTable;
