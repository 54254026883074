import React from "react";
import { Form } from "semantic-ui-react";
import { func, shape } from "prop-types";
import { forceValue, semanticUIStates } from "../../services/FormElements";
import { floodZones } from "./flood-zones";

function PersonalPropertyForm({ personalProperty, updateValue }) {
  const floodRequiredBooleanNumber = Number(personalProperty?.flood_required);
  const floodRequiredCheckboxValueInput = Number.isNaN(
    floodRequiredBooleanNumber
  )
    ? 0
    : floodRequiredBooleanNumber;
  return (
    <Form>
      <h2> Personal Property Information:</h2>
      <Form.Group widths="equal">
        <Form.Input
          autoComplete="off"
          label="Serial Number"
          name="serial_no"
          onChange={(ev, data) => updateValue(data)}
          value={forceValue(personalProperty.serial_no)}
        />
        <Form.Input
          autoComplete="off"
          label="Vehicle Model"
          name="vehicle_model"
          onChange={(ev, data) => updateValue(data)}
          value={forceValue(personalProperty.vehicle_model)}
        />
        <Form.Input
          autoComplete="off"
          label="Vehicle Make"
          name="vehicle_make"
          onChange={(ev, data) => updateValue(data)}
          value={forceValue(personalProperty.vehicle_make)}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Select
          autoComplete="off"
          label="Flood Zone"
          name="flood_zone"
          options={floodZones}
          onChange={(ev, data) => updateValue(data)}
          value={forceValue(personalProperty.flood_zone)}
        />
        <Form.Input
          autoComplete="off"
          label="Vehicle Year"
          name="vehicle_year"
          onChange={(ev, data) => updateValue(data)}
          value={forceValue(personalProperty.vehicle_year)}
        />
        <Form.Select
          autoComplete="off"
          label="State"
          name="state"
          options={semanticUIStates}
          onChange={(ev, data) => updateValue(data)}
          value={forceValue(personalProperty.state)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Checkbox
          label="Flood Insurance Required"
          name="flood_required"
          checked={personalProperty?.flood_required}
          readOnly
          disabled
          value={floodRequiredCheckboxValueInput}
        />
      </Form.Group>
    </Form>
  );
}

PersonalPropertyForm.propTypes = {
  personalProperty: shape().isRequired,
  updateValue: func.isRequired
};

export default PersonalPropertyForm;
