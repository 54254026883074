import React from "react";
import PropTypes from "prop-types";
import SplitLayout from "../../components/SplitLayout";
import { IncomeTable, AveragesTable } from "./components/ProfitabilityTables";

function Profitability({ loan }) {
  return (
    <SplitLayout>
      <IncomeTable loan={loan} />
      <AveragesTable loan={loan} />
    </SplitLayout>
  );
}

Profitability.propTypes = {
  loan: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    current_balance: PropTypes.number
  }).isRequired
};

export default Profitability;
