import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import type { AllIconClassNames } from "../../../types/elements";
import type { MessageProps } from "../../../types/text";
import type { IconProps } from "../../../fomantic/elements/icon/icon";
import { IconWithRef } from "../../../fomantic/elements/icon/icon";
import { getMessage } from "../../../util/text/message";

/**
 * Renders the display of a collection of children components bounded by two
 * icons. Note that this component does not wrap it's children with a containing
 * element because many of the fomantic-ui styles associated with a component
 * like this expect icons and other elements to be children of a specific
 * element and/or class.
 * @see
 * {@link ../../../fomantic/modules/dropdown/multi-select/multi-select-control.tsx}
 * for an example.
 */
export function IconBounded({
  children,
  placeholder,
  updateIconClassNames,
  ...props
}: React.PropsWithChildren<
  (IconBoundedLeftProps | IconBoundedRightProps | IconBoundedBothProps) &
    IconBoundedSharedProps
>) {
  function isIconLeftProps(
    props: Partial<IconBoundedBothProps>
  ): props is IconBoundedLeftProps {
    return "iconLeft" in props;
  }

  function isIconRightProps(
    props: Partial<IconBoundedBothProps>
  ): props is IconBoundedRightProps {
    return "iconRight" in props;
  }

  const { formatMessage } = useIntl();

  const noChildrenComponent = placeholder ? (
    "defaultMessage" in placeholder ? (
      placeholder.defaultMessage
    ) : (
      <FormattedMessage {...placeholder} />
    )
  ) : null;

  function getIcon(args: GetIconArg) {
    if (!args) {
      return null;
    }

    const { aligned, ...iconProps } = args;

    const iconClassNames: AllIconClassNames[] = [];
    if (aligned === "right") {
      iconClassNames.push("right");
    }

    const nextIconProps: IconProps = {
      ...iconProps,
      updateClassNames: names =>
        updateIconClassNames
          ? updateIconClassNames([...names, ...iconClassNames], iconProps)
          : [...names, ...iconClassNames]
    };

    if (iconProps.ariaLabel) {
      nextIconProps["aria-label"] = getMessage(
        iconProps.ariaLabel,
        formatMessage
      );
    }

    return <IconWithRef {...nextIconProps} />;
  }

  return (
    <>
      {isIconLeftProps(props) && getIcon(props.iconLeft)}
      {React.Children.count(children) !== 0 ? (
        children
      ) : (
        <span className="default text">{noChildrenComponent}</span>
      )}
      {isIconRightProps(props) &&
        props.iconRight &&
        getIcon({ ...props.iconRight, aligned: "right" })}
    </>
  );
}

interface GetIconArg extends IconProps {
  aligned?: "right";
}

export interface IconBoundedSharedProps {
  /** Message displayed when no children are provided. */
  placeholder?: MessageProps;
  /** Allow the parent to optionally manipulate the class names that are applied
   * to an icon. */
  updateIconClassNames?: (
    classNames: AllIconClassNames[],
    props: IconProps
  ) => AllIconClassNames[];
}

export interface IconBoundedLeftProps {
  /** The icon to display to the left of the items. */
  iconLeft: IconProps;
}

export interface IconBoundedRightProps {
  /** The icon to display to the right of the items. */
  iconRight?: IconProps;
}

export type IconBoundedBothProps = Required<IconBoundedLeftProps> &
  Required<IconBoundedRightProps>;
