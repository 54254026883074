import { SideSheetDropdown } from "../SideSheetDropdown";
import { useIntl } from "react-intl";
import { loanParticipationOptions } from "../../../../../../../../../../../../../services/FormElements";
import { StateAwareFieldChangeFunction } from "../../services/types";
import {
  PARTICIPATION_BOUGHT,
  PARTICIPATION_SOLD
} from "../../../../../../../../../../../../../assets/messages/common.en-US.json";

export const ParticipationDropdown = ({
  handleFieldChange,
  participationBought,
  participationSold
}: ParticipationDropdownProps) => {
  const { formatMessage } = useIntl();
  let participationType = null;

  if (participationBought) {
    participationType = PARTICIPATION_BOUGHT;
  } else if (participationSold) {
    participationType = PARTICIPATION_SOLD;
  }

  const handleChange = (value: string) => {
    const isBought = value === "Participation Bought";
    const isSold = value === "Participation Sold";

    handleFieldChange([
      {
        value: isBought || isSold ? "Active" : "None",
        stateColumnName: "participation"
      },
      { value: isBought, stateColumnName: "participation_bought" },
      { value: isSold, stateColumnName: "participation_sold" }
    ]);
  };

  return (
    <SideSheetDropdown
      handleChange={handleChange}
      label={formatMessage({ id: "PARTICIPATION" })}
      options={loanParticipationOptions}
      value={participationType}
    />
  );
};

interface ParticipationDropdownProps {
  handleFieldChange: StateAwareFieldChangeFunction;
  participationBought: boolean;
  participationSold: boolean;
}
