import type { SidebarItemInternal } from "./sidebar-types";
import type { SidebarListItemContentProps } from "./sidebar-list-item-content";
import { SidebarListItemContent } from "./sidebar-list-item-content";

/**
 * A selectable item displayed in the Sidebar.
 * @param props
 */
export function SidebarListItem(props: SidebarListItemProps) {
  return <SidebarListItemContent {...props} />;
}

export type SidebarListItemProps = SidebarItemInternal &
  Pick<
    SidebarListItemContentProps,
    "key" | "onItemSelect" | "onGetAnchorElement" | "expanded"
  >;
