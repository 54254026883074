import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Menu, Segment } from "semantic-ui-react";
import Constants from "../../../../../../services/Constants/strings";
import {
  getUserGroup,
  permCheck,
  isBafs,
  getInstitutionUuid
} from "../../../../../../services/Auth";
import { ACTIVE_ITEM_TO_ROUTE } from "../../constants";
import { Institutions } from "../../../../../../services/ApiLib";

function TabBar({ activeItem }) {
  const isInstitution = getUserGroup() === "Institution";
  const history = useHistory();
  const match = useRouteMatch();
  const institutionUuid = getInstitutionUuid();

  const [institution, setInstitution] = useState(null);

  useEffect(() => {
    const fetchInstitution = async () => {
      const response = await Institutions.asyncRead({ institutionUuid });
      setInstitution(response);
    };

    fetchInstitution();
  }, [institutionUuid]);

  const hasBorrowerPortalService =
    institution?.data?.borrower_portal_service === 1;
  const hasFinancialStatementsPermissions = permCheck([
    "financial_statements_read",
    "financial_statements_rw",
    "admin"
  ]);
  const hasBpLinksPermissions = permCheck([
    "bp_links_read",
    "bp_links_rw",
    "bp_links_rw_unfiltered",
    "admin"
  ]);

  const showFinancialStatements =
    !isInstitution || hasFinancialStatementsPermissions;
  const showBorrowerPortalLinks =
    isBafs() ||
    hasBpLinksPermissions ||
    (isInstitution && hasBorrowerPortalService && hasBpLinksPermissions);

  return (
    <Segment data-testid="tabs" className="overflow-x-auto">
      <Menu pointing secondary color="green">
        <Menu.Item
          data-testid="menu-item"
          active={activeItem === Constants.LOANS}
          name={Constants.LOANS}
          onClick={() => {
            history.push(
              `${match.url}/${ACTIVE_ITEM_TO_ROUTE[Constants.LOANS]}`
            );
          }}
        />
        <Menu.Item
          data-testid="menu-item"
          active={activeItem === Constants.DETAILS}
          name={Constants.DETAILS}
          onClick={() => {
            history.push(
              `${match.url}/${ACTIVE_ITEM_TO_ROUTE[Constants.DETAILS]}`
            );
          }}
        />
        <Menu.Item
          data-testid="menu-item"
          active={activeItem === Constants.RELATIONSHIPS}
          name={Constants.RELATIONSHIPS}
          onClick={() => {
            history.push(
              `${match.url}/${ACTIVE_ITEM_TO_ROUTE[Constants.RELATIONSHIPS]}`
            );
          }}
        />
        <Menu.Item
          data-testid="menu-item"
          active={activeItem === Constants.ASSETS}
          name={Constants.ASSETS}
          onClick={() => {
            history.push(
              `${match.url}/${ACTIVE_ITEM_TO_ROUTE[Constants.ASSETS]}`
            );
          }}
        />
        <Menu.Item
          data-testid="menu-item"
          active={activeItem === Constants.CREDIT_DOCUMENTS}
          name={Constants.CREDIT_DOCUMENTS}
          onClick={() => {
            history.push(
              `${match.url}/${ACTIVE_ITEM_TO_ROUTE[Constants.CREDIT_DOCUMENTS]}`
            );
          }}
        />
        <Menu.Item
          data-testid="menu-item"
          active={activeItem === Constants.CREDIT_REQUEST}
          name={Constants.CREDIT_REQUEST}
          onClick={() => {
            history.push(
              `${match.url}/${ACTIVE_ITEM_TO_ROUTE[Constants.CREDIT_REQUEST]}`
            );
          }}
        />
        <Menu.Item
          data-testid="menu-item"
          active={activeItem === Constants.DOCUMENT_TRACKING}
          name={Constants.DOCUMENT_TRACKING}
          onClick={() => {
            history.push(
              `${match.url}/${
                ACTIVE_ITEM_TO_ROUTE[Constants.DOCUMENT_TRACKING]
              }`
            );
          }}
        />
        {showFinancialStatements && (
          <Menu.Item
            data-testid="menu-item"
            active={activeItem === Constants.FINANCIAL_STATEMENTS}
            name={Constants.FINANCIAL_STATEMENTS}
            onClick={() => {
              history.push(
                `${match.url}/${
                  ACTIVE_ITEM_TO_ROUTE[Constants.FINANCIAL_STATEMENTS]
                }`
              );
            }}
          />
        )}
        {showBorrowerPortalLinks && (
          <Menu.Item
            data-testid="menu-item"
            active={activeItem === Constants.BORROWER_PORTAL_LINKS}
            name={Constants.BORROWER_PORTAL_LINKS}
            onClick={() => {
              history.push(
                `${match.url}/${
                  ACTIVE_ITEM_TO_ROUTE[Constants.BORROWER_PORTAL_LINKS]
                }`
              );
            }}
          />
        )}
      </Menu>
    </Segment>
  );
}

TabBar.propTypes = {
  activeItem: PropTypes.string.isRequired
};

export default TabBar;
