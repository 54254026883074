import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import GovtGuarDetails from "./components/GovtGuarDetails";
import GovtGuarLayout from "./components/GovtGuarLayout";
import GovtGuarEditModal from "./components/GovtGuarEditModal";
import { FrictionModal } from "../../../../../../../../components/Modals";

function GovtGuar({ loan, onUpdateLoan, isMonthEnd }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [changesToConfirm, setChangesToConfirm] = useState(null);
  const confirmOpen = changesToConfirm !== null;

  return (
    <GovtGuarLayout heading="Details">
      <>
        {!isMonthEnd && (
          <Button
            circular
            basic
            icon="edit"
            onClick={() => setModalOpen(true)}
            data-testid="edit-button"
          />
        )}
        <GovtGuarEditModal
          loan={loan}
          onSave={changes =>
            setChangesToConfirm({ ...changes, uuid: loan.uuid })
          }
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
        <FrictionModal
          open={confirmOpen}
          header="Edit Details"
          message="Are you sure you want to edit?"
          validateString="CONFIRM"
          onCancel={() => {
            setChangesToConfirm(null);
            setModalOpen(true);
          }}
          onSubmit={() => {
            onUpdateLoan(changesToConfirm);
            setChangesToConfirm(null);
          }}
        />
      </>
      <GovtGuarDetails loan={loan} />
    </GovtGuarLayout>
  );
}

GovtGuar.propTypes = {
  loan: PropTypes.shape({
    uuid: PropTypes.string.isRequired
  }),
  onUpdateLoan: PropTypes.func,
  isMonthEnd: PropTypes.bool
};

export default GovtGuar;
