/* eslint-env browser */
import { useState, useEffect, useMemo } from "react";
import {
  array,
  arrayOf,
  bool,
  func,
  object,
  objectOf,
  shape,
  string,
  oneOfType
} from "prop-types";
import {
  Divider,
  Form,
  Header,
  Modal,
  Grid,
  TextArea,
  Label,
  Button
} from "semantic-ui-react";
import NumberFormat from "react-number-format";
import TypeCheck from "typecheck-extended";
import RealEstateForm from "./real-estate-form";
import PersonalPropertyForm from "./personal-property-form";
import AccountsForm from "./account-form";
import { forceValue } from "../../services/FormElements";
import { dateFromString } from "../../services/DateTime";
import { getInstitutionUuid } from "../../services/Auth";
import { DateInput, PercentInput } from "../CustomFormElements";
import EntitySearch from "../EntitySearch";
import { PrettyCurrency } from "../../services/PrettyNumber";
import { serializeAssetData } from "../../services/Constants/assetsOptions";
import { useInstitutionAssetGroupClassesQuery } from "../../services/RTKQuery/institutions";
import logger from "../../services/logger";

export const formatAssets = (assets, collateralMap = {}) => {
  TypeCheck(assets, "array");
  if (assets) {
    const assetGroup = assets
      .filter(asset => !collateralMap[asset.uuid])
      .map(asset => {
        const { uuid } = asset;
        const description = asset.description || "";
        return {
          key: uuid,
          // TODO: I don't know why this substring is here. Char limits should be enforced as the
          // user types with visual feedback rather than silently truncating what the user entered.
          text: description.substring(0, 41),
          value: uuid
        };
      });
    assetGroup.push({ key: "new", value: "new", text: "Add New Collateral" });
    return assetGroup;
  }
  return [];
};

const valueSource = [
  { key: "Appraisal", value: "Appraisal", text: "Appraisal" },
  { key: "Evaluations", value: "Evaluations", text: "Evaluations" },
  { key: "Book Value", value: "Book Value", text: "Book Value" },
  {
    key: "Chattel Appraisal",
    value: "Chattel Appraisal",
    text: "Chattel Appraisal"
  },
  { key: "NADA / KBB", value: "NADA / KBB", text: "NADA / KBB" },
  { key: "Other", value: "Other", text: "Other" }
];

/**
 * @typedef {Object} Collateral
 * @property {boolean} is_primary
 * @property {string} uuid
 */

/**
 *
 *
 * @param {Object} props
 * @param {Array<Collateral> | false} props.isPrimaryCollateral
 */
function CollateralModal({
  asset,
  isPrimaryCollateral,
  institutionUuid,
  assetInputChange,
  assetSelectChange,
  cancelCallback,
  collateral,
  collaterals,
  collateralInputChange,
  entityAssets,
  isAdd,
  isOpen,
  modalEntityUuid,
  submitCallback,
  onError,
  borrowers,
  openAssetsForm,
  additionalAssetData,
  updateValue,
  resetAssetData,
  showCollateralForm,
  updateAdditionalAssetData
}) {
  if (!institutionUuid) {
    return null;
  }

  const {
    data: assetClasses
    // TODO: Potentially show skeleton loader for better UI/UX based on:
    // isError, isFetching, isLoading, isSuccess
  } = useInstitutionAssetGroupClassesQuery(institutionUuid);

  const checkIfPrimary = isAdd
    ? isPrimaryCollateral && isPrimaryCollateral.length > 0
    : !collateral.is_primary &&
      isPrimaryCollateral.length > 0 &&
      isPrimaryCollateral.uuid !== collateral.uuid;
  const [lienPositions, setLienPositions] = useState([]);
  const [collateralMap, setCollateralMap] = useState({});

  const serializedAssetData = useMemo(() => {
    return serializeAssetData({
      data: assetClasses?.asset_groups,
      asset_group: asset?.asset_group,
      asset_class: asset?.asset_class,
      asset_type: asset?.asset_type
    });
  }, [
    assetClasses?.asset_groups,
    asset?.asset_group,
    asset?.asset_class,
    asset?.asset_type
  ]);

  useEffect(() => {
    let borrowersCollateral = false;
    if (!borrowers) {
      return;
    }
    for (let i = 0; i < borrowers.length; i += 1) {
      if (asset.entity_uuid === borrowers[i].child_uuid) {
        borrowersCollateral = true;
      }
    }
    collateralInputChange(null, {
      name: "has_grantor",
      value: !borrowersCollateral
    });
  }, [asset, borrowers, collateralInputChange]);

  useEffect(() => {
    const allLienPositions = [];
    for (let i = 1; i < 11; i += 1) {
      allLienPositions.push({ value: `${i}`, text: `${i}` });
    }
    setLienPositions(allLienPositions);
  }, []);

  useEffect(() => {
    const existingCollaterals = collaterals[collateral.loan_uuid] || [];
    const nextCollateralMap = {};
    for (let i = 0; i < existingCollaterals.length; i += 1) {
      nextCollateralMap[existingCollaterals[i].asset_uuid] = true;
    }
    setCollateralMap(nextCollateralMap);
  }, [collateral.loan_uuid, collaterals]);

  const valid =
    !!asset.entity_uuid &&
    !!asset.asset_class &&
    !!asset.asset_group &&
    !!asset.asset_type &&
    !!asset.description;

  const discountValue =
    (asset.market_value || 0) * collateral.advance_rate -
      (collateral.prior_liens || 0) || 0;

  return (
    <Modal open={isOpen}>
      <Modal.Content>
        {!openAssetsForm && (
          <Form>
            <Modal.Description>
              <Header as="h1" color="green">
                {isAdd ? "Add Collateral" : "Edit Collateral"}
              </Header>
            </Modal.Description>
            <Divider />
            <EntitySearch
              data-testid="entity-uuid-search"
              selectedEntityCallback={uuid =>
                assetInputChange(null, {
                  name: "entity_uuid",
                  value: forceValue(uuid)
                })
              }
              entityLabel="Associated Entity"
              addEntityLabel="Create Grantor"
              overrideInstitution={getInstitutionUuid()}
              institutionUuid={institutionUuid}
              allowAdd
              asset={asset}
              onError={onError}
              isEdit={!isAdd}
            />
            <Form.Select
              disabled={(!asset.entity_uuid && !modalEntityUuid) || !isAdd}
              label="Asset"
              data-test-id="asset-select"
              name="uuid"
              options={formatAssets(entityAssets || [], { ...collateralMap })}
              onChange={assetSelectChange}
              search
              value={forceValue(asset.uuid || "new")}
            />
            <Form.Group>
              <Form.Checkbox
                label="Is Primary Collateral"
                name="is_primary"
                disabled={checkIfPrimary}
                onChange={(e, { name, checked }) =>
                  collateralInputChange(e, { name, value: checked })
                }
                checked={collateral.is_primary}
              />
              {checkIfPrimary && (
                <Label basic color="red" pointing="left">
                  Primary Collateral already assigned for this loan
                </Label>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Select
                label="Value Source"
                name="value_source"
                onChange={assetInputChange}
                options={valueSource}
                value={forceValue(asset.value_source)}
              />
              <Form.Select
                label="Group"
                name="asset_group"
                onChange={(e, { name, value }) => {
                  assetInputChange(e, { name, value });
                  resetAssetData();
                }}
                options={serializedAssetData?.groupOptions}
                value={forceValue(asset.asset_group)}
              />
              <Form.Select
                label="Class"
                name="asset_class"
                onChange={assetInputChange}
                options={serializedAssetData?.classOptions}
                value={forceValue(asset.asset_class)}
              />
              <Form.Select
                label="Collateral Type"
                name="asset_type"
                onChange={assetInputChange}
                options={serializedAssetData?.typeOptions}
                value={forceValue(asset.asset_type)}
              />
            </Form.Group>
            <Form.Group>
              <Grid.Column style={{ marginRight: "1%" }}>
                <span>
                  <b>Market Value</b>
                </span>
                <NumberFormat
                  data-testid="collateral-market-value"
                  thousandSeparator
                  decimalScale={2}
                  name="market_value"
                  label="Market Value"
                  style={{ marginTop: "3px" }}
                  width={2}
                  value={forceValue(asset.market_value)}
                  onChange={e =>
                    assetInputChange("", {
                      name: "market_value",
                      value: parseFloat(e.target.value.split(",").join(""))
                    })
                  }
                />
              </Grid.Column>
              <Grid.Column style={{ marginRight: "1%" }}>
                <PercentInput
                  label="Advance Rate"
                  name="advance_rate"
                  icon="percent"
                  onChange={(e, { name, value }) =>
                    collateralInputChange(e, { name, value })
                  }
                  value={forceValue(collateral.advance_rate)}
                />
              </Grid.Column>
              <Grid.Column style={{ marginRight: "1%" }}>
                <span>
                  <b>Amount of Prior Liens</b>
                </span>
                <NumberFormat
                  data-testid="prior-liens-input"
                  thousandSeparator
                  decimalScale={2}
                  name="prior_liens"
                  label="Amount of Prior Liens"
                  style={{ marginTop: "3px" }}
                  width={4}
                  value={forceValue(collateral.prior_liens)}
                  onChange={e =>
                    collateralInputChange("", {
                      name: "prior_liens",
                      value: parseFloat(e.target.value.split(",").join(""))
                    })
                  }
                />
              </Grid.Column>
              <Grid.Column style={{ marginRight: "1%" }}>
                <span>
                  <b>Discount Value</b>
                </span>
                <Label width={4} style={{ marginTop: "7px" }} size="large">
                  <Label.Detail data-testid="collateral-discount-value">
                    {PrettyCurrency(discountValue, false)}
                  </Label.Detail>
                </Label>
              </Grid.Column>
            </Form.Group>
            <Form.Group>
              <Grid.Column style={{ marginRight: "1%" }}>
                <span>
                  <b>Cost</b>
                </span>
                <NumberFormat
                  thousandSeparator
                  decimalScale={2}
                  name="cost"
                  label="Cost"
                  style={{ height: "60%" }}
                  width={4}
                  value={forceValue(asset.cost)}
                  onChange={e =>
                    assetInputChange("", {
                      name: "cost",
                      value: parseFloat(e.target.value.split(",").join(""))
                    })
                  }
                />
              </Grid.Column>
              <Form.Select
                label="Cross-Collateralized"
                name="cross_collateralized"
                onChange={collateralInputChange}
                options={[
                  { text: "Yes", value: true },
                  { text: "No", value: false }
                ]}
                value={forceValue(collateral.cross_collateralized)}
              />
              <Form.Select
                label="Lien Position"
                name="lien_position"
                onChange={(e, { name, value }) =>
                  collateralInputChange(e, { name, value })
                }
                options={lienPositions}
                value={collateral.lien_position}
              />
              <DateInput
                label="Value Date"
                onChange={e =>
                  assetInputChange(null, {
                    value: dateFromString(e.target.value),
                    name: "value_date"
                  })
                }
                name="value_date"
                asForm
                value={forceValue(asset.value_date)}
              />
              <DateInput
                label="Acquisition Date"
                onChange={e =>
                  assetInputChange(null, {
                    value: dateFromString(e.target.value),
                    name: "acquisition_date"
                  })
                }
                name="acquisition_date"
                asForm
                value={forceValue(asset.acquisition_date)}
              />
            </Form.Group>
            <Grid.Row columns={1}>
              <Grid.Column width={16}>
                <span>
                  <b>Description</b>
                </span>
                <br />
                <TextArea
                  label="Description"
                  name="description"
                  rows={3}
                  onChange={assetInputChange}
                  value={forceValue(asset.description)}
                />
              </Grid.Column>
            </Grid.Row>
            <br />
            <Grid.Row>
              <Grid.Column>
                <Form.Checkbox
                  label="Abundance of Caution"
                  name="abundance_of_caution"
                  onChange={(e, { name, checked }) =>
                    collateralInputChange(e, { name, value: checked })
                  }
                  checked={collateral.abundance_of_caution}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form.Checkbox
                  label="Has Grantor? (Collateral owner is not a borrower or coborrower)"
                  name="has_grantor"
                  readOnly
                  disabled
                  checked={collateral.has_grantor}
                />
              </Grid.Column>
            </Grid.Row>
          </Form>
        )}
        {openAssetsForm && asset.asset_group === "Real Estate" && (
          <RealEstateForm
            realEstate={additionalAssetData}
            updateValue={updateValue}
          />
        )}
        {openAssetsForm &&
          asset.asset_group === "Personal Property" &&
          asset.asset_class !== "Accounts" && (
            <PersonalPropertyForm
              personalProperty={additionalAssetData}
              updateValue={updateValue}
            />
          )}
        {openAssetsForm &&
          asset.asset_group === "Personal Property" &&
          asset.asset_class === "Accounts" && (
            <AccountsForm
              accountDetail={additionalAssetData}
              updateValue={updateValue}
            />
          )}
      </Modal.Content>
      <Modal.Actions>
        {!openAssetsForm && (
          <>
            <Button basic onClick={cancelCallback}>
              Cancel
            </Button>
            <Button
              disabled={!valid}
              onClick={submitCallback}
              primary
              data-testid="collateral-modal-primary-button"
            >
              {asset.asset_group === "Other" ? "Save" : "Next"}
            </Button>
          </>
        )}
        {openAssetsForm && (
          <>
            <Button onClick={showCollateralForm}>Back</Button>
            <Button
              onClick={() =>
                updateAdditionalAssetData(asset.asset_group, asset.asset_class)
              }
              primary
              data-testid="collateral-modal-primary-button"
            >
              Save
            </Button>
          </>
        )}
      </Modal.Actions>
    </Modal>
  );
}

CollateralModal.defaultProps = {
  onError: logger.error
};

CollateralModal.propTypes = {
  asset: shape().isRequired,
  institutionUuid: string.isRequired,
  isPrimaryCollateral: (props, propName, componentName) => {
    let result;
    if (typeof props[propName] === "boolean") {
      if (props[propName]) {
        result = new Error(
          `Invalid prop '${propName}' supplied to ${componentName}. A boolean type must have the value of 'false'. Validation failed.`
        );
      }
    } else if (!Array.isArray(props[propName])) {
      result = new Error(
        `Invalid prop '${propName}' supplied to ${componentName}. Must be 'false' or an array of objects. Validation failed.`
      );
    }

    return result;
  },
  assetInputChange: func.isRequired,
  assetSelectChange: func.isRequired,
  cancelCallback: func.isRequired,
  collateral: shape().isRequired,
  collateralInputChange: func.isRequired,
  entityAssets: arrayOf(object).isRequired,
  isAdd: bool.isRequired,
  isOpen: bool.isRequired,
  modalEntityUuid: string,
  submitCallback: func.isRequired,
  collaterals: objectOf(array).isRequired,
  onError: func,
  borrowers: arrayOf(shape()),
  openAssetsForm: bool.isRequired,
  additionalAssetData: oneOfType([shape(), string]).isRequired,
  updateValue: func.isRequired,
  resetAssetData: func.isRequired,
  showCollateralForm: func.isRequired,
  updateAdditionalAssetData: func.isRequired
};

export default CollateralModal;
