/* eslint-disable no-console */
import TypeCheck from "typecheck-extended";
import ApiEndpoints from "../../../ApiEndpoints";
import FetchService, { asyncFetchService } from "../../../FetchService";
import logger from "../../../logger";
import {
  coerceTypes,
  ConcatQueryParams,
  encodeQuerySpecialChars,
  RemoveNullKeys
} from "../Utilities";
import { FormatUrlV2 } from "../../../FormatUrl";

export const schemaToForce = {
  annual_revenue: "float",
  credit_score_equifax: "int",
  credit_score_experian: "int",
  credit_score_transunion: "int",
  fico_sbss: "int",
  industry_experience: "int",
  institution_id: "int",
  naics_code: "int",
  personal_annual_income: "float",
  phoenix_rim_no: "int",
  risk_rating: "int",
  years_in_business: "int"
};

function add(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(body, "object");

  let uuids;
  const endpoint = ApiEndpoints.entities;
  const url = FormatUrlV2(endpoint, uuids);

  let convertedBody = RemoveNullKeys(body);
  convertedBody = coerceTypes(convertedBody, schemaToForce);
  FetchService("POST", url, onSuccess, onError, convertedBody, callbackData);
}

// This is deprecated. Use async Entities.read() for new code
function get(jwt, onSuccess, onError, uuid, queryParams, callbackData) {
  TypeCheck(jwt, "string", false);
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(uuid, "string", false);
  TypeCheck(queryParams, "object", false);

  if (uuid && queryParams) {
    onError("Error: Invalid Filter Combination");
  }

  const uuidList =
    queryParams && queryParams.uuidList ? queryParams.uuidList : null;
  let url = ApiEndpoints.baseUri;
  url = uuidList
    ? url + ApiEndpoints.entitiesList
    : url + ApiEndpoints.entities;
  if (uuid) {
    url = FormatUrlV2(ApiEndpoints.entity, { uuid });
  }
  if (queryParams) {
    url += ConcatQueryParams(queryParams, ["uuidList"]);
  }

  let callType = "GET";
  let body;
  if (uuidList) {
    callType = "POST";
    body = { uuid_list: uuidList };
  }

  FetchService(callType, url, onSuccess, onError, body, callbackData);
}

async function read(filters) {
  const funcName = "====  Entities.read(filters)  ====";
  logger.debug(funcName, [filters]);
  TypeCheck(filters, "object", true);
  TypeCheck(filters.entityUuid, "string", false);

  const filterCount = Object.keys(filters).length;
  if ((filters.entityUuid && filterCount > 1) || filterCount === 0) {
    throw Error(
      'Entities.read(): Invalid filter combination. Either call with { entityUuid: "[UUID]" } to lookup a single entity, or pass filters to lookup many.'
    );
  }

  let url;
  if (filters.entityUuid) {
    const endpoint = ApiEndpoints.entity;
    url = FormatUrlV2(endpoint, { uuid: filters.entityUuid });
  } else {
    const endpoint = ApiEndpoints.entities;
    const appendToUrl = ConcatQueryParams(filters);
    url = FormatUrlV2(endpoint) + appendToUrl;
  }

  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

function update(jwt, onSuccess, onError, body, callbackData) {
  TypeCheck(jwt, "string", false);
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");

  const url = FormatUrlV2(ApiEndpoints.entity, { uuid: body.uuid });
  const callbacks = { callbackData, onSuccess };

  let convertedBody = RemoveNullKeys(body);
  convertedBody = coerceTypes(convertedBody, schemaToForce);
  FetchService("PUT", url, onSuccess, onError, convertedBody, callbacks);
}

async function asyncUpdate(filters, body) {
  TypeCheck(filters.uuid, "string", true);

  const url = FormatUrlV2(ApiEndpoints.entity, { uuid: filters.uuid });

  const convertedBody = RemoveNullKeys(body);

  const rsp = await asyncFetchService("PUT", url, convertedBody, null, false);
  return rsp;
}

async function asyncReadByInstitutionUuid(institutionUuid) {
  logger.warn(
    "DEPRECATION NOTICE: asyncReadByInstitutionUuid() is deprecated. Use read() instead."
  );
  const url = FormatUrlV2(ApiEndpoints.entitiesGetAllByInstitutionUuid, {
    institutionUuid
  });
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

async function asyncSearchByInstitutionUuid(uuids) {
  const url = FormatUrlV2(ApiEndpoints.entityLookupAndFilterByInstUuid, uuids);
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

async function bpUsersGetAll(institutionUuid = "", lookupString = "") {
  // We might want need the fix from AB#6973 applied here as well, like this:
  // const reformattedEmail = encodeQuerySpecialChars(lookupString);

  const url = FormatUrlV2(ApiEndpoints.entitiesGetAllBPUsers, {
    institutionUuid,
    lookupString // might need `lookupString: reformattedEmail,` here as applied in AB#6973
  });
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

async function checkBpOrgLinkEmailEligibility(email = "") {
  const reformattedEmail = encodeQuerySpecialChars(email);
  const url = FormatUrlV2(ApiEndpoints.entitiesBpUsersValidateEmail, {
    email: reformattedEmail
  });
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

async function getBpUserByOrgUuid(
  institutionUuid = "",
  bpOrganizationUuid = ""
) {
  const url = FormatUrlV2(ApiEndpoints.entitiesBpUsersUser, {
    institutionUuid,
    bpOrganizationUuid
  });

  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

async function bpAccessControlsGetByRecordUuid(recordUuid = "") {
  const url = FormatUrlV2(ApiEndpoints.bpAccessControlsGetByRecordUuid, {
    recordUuid
  });

  const rsp = await asyncFetchService("GET", url);
  // NOTE: Response should return an object not an array
  return {
    ...rsp,
    data:
      rsp?.data && Array.isArray(rsp.data) && rsp.data.length !== 0
        ? rsp?.data[0]
        : null
  };
}

async function bpAccessControlsUpdateEditableByBorrower(
  uuid,
  recordUuid,
  institutionUuid,
  editableByBorrower
) {
  const url = FormatUrlV2(ApiEndpoints.bpAccessControlsGetByRecordUuid, {
    recordUuid
  });
  const body = {
    uuid,
    institution_uuid: institutionUuid,
    editable_by_borrower: editableByBorrower
  };
  const rsp = await asyncFetchService("PUT", url, body);
  return rsp;
}

async function sendBpUserSignupEmail(entityUuid = "", body = {}) {
  const url = FormatUrlV2(ApiEndpoints.entitiesBpUsersSendSignupEmail, {
    uuid: entityUuid
  });
  const rsp = await asyncFetchService("POST", url, body);
  return rsp;
}

async function getBpOrgLink(entityUuid) {
  const url = FormatUrlV2(ApiEndpoints.entityBpOrgLink, { entityUuid });
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

async function createBpOrgLink(entityUuid, bpOrganizationUuid) {
  const url = FormatUrlV2(ApiEndpoints.entityBpOrgLink, { entityUuid });
  const body = {
    bp_organization_uuid: bpOrganizationUuid
  };
  const rsp = await asyncFetchService("POST", url, body);
  return rsp;
}

async function deleteBpOrgLink(entityUuid) {
  const url = FormatUrlV2(ApiEndpoints.entityBpOrgLink, { entityUuid });
  const rsp = await asyncFetchService("DELETE", url);
  return rsp;
}

async function getAllBpLinksByBpOrgId(bpOrganizationUuid) {
  const url = FormatUrlV2(ApiEndpoints.entitiesGetAllBpLinks, {
    bp_organization_uuid: bpOrganizationUuid
  });
  const rsp = await asyncFetchService("GET", url);
  return rsp;
}

export default {
  add,
  get,
  read,
  update,
  asyncUpdate,
  asyncReadByInstitutionUuid,
  asyncSearchByInstitutionUuid,
  bpUsersGetAll,
  checkBpOrgLinkEmailEligibility,
  getAllBpLinksByBpOrgId,
  getBpUserByOrgUuid,
  bpAccessControlsGetByRecordUuid,
  bpAccessControlsUpdateEditableByBorrower,
  sendBpUserSignupEmail,
  getBpOrgLink,
  createBpOrgLink,
  deleteBpOrgLink
};
