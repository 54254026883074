/* eslint-disable camelcase */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import { useTable } from "react-table";
import { DefaultCell } from "../../../../../../../../../../components/Tables";

const periods = ["ytd", "ltd"];
const occurrenceCategories = [15, 30, 60, 90, 120];

function PastDueHistoryTable({ loan }) {
  const columns = useMemo(
    () => [
      {
        Header: "No. of Times Past Due",
        id: "num of times past due",
        accessor: () => periods.map(p => p.toUpperCase()),
        Cell: DefaultCell
      },
      ...occurrenceCategories.map(occCat => ({
        Header: `${occCat}`,
        id: occCat,
        accessor: l =>
          periods.map(period => l[`late_${occCat}_${period}`] || 0),
        Cell: DefaultCell
      })),
      {
        Header: "Total Occurrences",
        id: "total occurrences",
        accessor: l =>
          periods.map(period =>
            occurrenceCategories
              .map(occ => l[`late_${occ}_${period}`])
              .reduce((numsum, num) => numsum + num || 0, 0)
          ),
        Cell: DefaultCell
      }
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: [loan] });

  return (
    <Table {...getTableProps()} textAlign="center" basic compact>
      <Table.Header>
        {headerGroups.map(headerGroup => (
          <Table.Row {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <Table.HeaderCell {...column.getHeaderProps()}>
                {column.render("Header")}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        ))}
      </Table.Header>
      <Table.Body {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          const rowProps = row.getRowProps();
          return periods.map((period, i) => (
            <Table.Row {...rowProps} key={period}>
              {row.cells.map(cell => (
                <Table.Cell {...cell.getCellProps()}>
                  {cell.value[i]}
                </Table.Cell>
              ))}
            </Table.Row>
          ));
        })}
      </Table.Body>
    </Table>
  );
}

PastDueHistoryTable.propTypes = {
  loan: PropTypes.shape({
    late_15_ltd: PropTypes.number,
    late_15_ytd: PropTypes.number,
    late_30_ltd: PropTypes.number,
    late_30_ytd: PropTypes.number,
    late_60_ltd: PropTypes.number,
    late_60_ytd: PropTypes.number,
    late_90_ltd: PropTypes.number,
    late_90_ytd: PropTypes.number,
    late_120_ltd: PropTypes.number,
    late_120_ytd: PropTypes.number
  }).isRequired
};

export default PastDueHistoryTable;
