import PropTypes from "prop-types";
import { PercentFormat } from "../../../../../../../../../components/CustomUIElements";

const RateConstraintCell = ({ row }) => {
  let rateFloor = row?.original?.rate_floor || "";
  let rateCeiling = row?.original?.rate_ceiling || "";

  if (rateFloor != "") {
    rateFloor = `%${parseFloat(rateFloor)}`;
  }

  if (rateCeiling != "") {
    rateCeiling = `%${parseFloat(rateCeiling)}`;
  }

  return (
    <div className="flex flex-col">
      <div>
        <p>
          <b>Floor:</b> <PercentFormat value={rateFloor} />
        </p>
      </div>
      <div>
        <p>
          <b>Ceiling:</b> <PercentFormat value={rateCeiling} />
        </p>
      </div>
    </div>
  );
};

export default RateConstraintCell;

RateConstraintCell.propTypes = {
  row: PropTypes.shape({})
};
