import React from "react";
import { Form, Header, Container } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators } from "../../reducer";
import useEntity from "../../../../../../../../components/Modals/components/EntityModal/hooks/useEntity/useEntity";
import PPPFormInputs from "../../utilities/PPPFormInputs";

function LoanOfficerDropdown() {
  const { entityFormFields } = useSelector(state => ({
    entityFormFields: state.PPPReducer.entityFormFields
  }));
  const { institution_uuid: institutionUuid, uuid: entityUuid } =
    entityFormFields;
  const { loanOfficers } = useEntity({ entityUuid, institutionUuid }) ?? {};

  const dispatch = useDispatch();

  const setEntityFormFields = fields =>
    dispatch(actionCreators.setEntityFormFields(fields));
  const updateEntityFields = fields =>
    dispatch(actionCreators.updateEntityFields(fields));

  return (
    <Container textAlign="left">
      <Header as="h3" content="Loan Officer" />
      <Form autoComplete="off">
        <PPPFormInputs
          wrapper="ppp-loan-officer"
          inputs={[
            {
              name: "loan_officer_uuid",
              label: "Loan Officer",
              type: "select",
              options: loanOfficers
            }
          ]}
          fields={entityFormFields}
          update={updateEntityFields}
          set={setEntityFormFields}
        />
      </Form>
    </Container>
  );
}

export default LoanOfficerDropdown;
