import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import CollateralTable from "../../../../components/CollateralTable";
import CollateralAddEdit from "../../../../../../components/CollateralAddEdit";
import { ConfirmationModal } from "../../../../../../components/Modals";
import { isBafs } from "../../../../../../services/Auth";

function Collateral({
  loanUuid,
  institutionUuid,
  assets,
  data,
  entityAssets,
  collateralModalData,
  collateralModalAsset,
  collateralModalIsAdd,
  collateralModalOpen,
  collateralDeleteModalIsOpen,
  onViewCollateralTable,
  onDeleteAssetModalToggle,
  onEditCollateralModalToggle,
  onAddCollateralModalToggle,
  onCancelDeleteCollateralAsset,
  onDeleteCollateralAsset,
  onCollateralEditorAssetChange,
  onAssetSelectChange,
  onCollateralEditorCollateralChange,
  onCollateralEditorSave,
  openAssetsForm,
  setCloseAssetsForm,
  additionalAssetData,
  setAdditonalAssetValue,
  resetAdditionalAssets,
  updateAdditionalAssetData,
  assetDataFound,
  addAdditionalAssetData
}) {
  const [isPrimaryCollateral, setPrimaryCollateral] = useState(false);
  const resetAssetData = () => {
    resetAdditionalAssets();
  };
  function showCollateralForm() {
    setCloseAssetsForm();
  }
  const updateValue = ({ name, value }) => {
    setAdditonalAssetValue({ name, value });
  };
  useEffect(() => {
    if (loanUuid) {
      onViewCollateralTable(loanUuid);
    }
  }, [loanUuid, onViewCollateralTable]);
  useEffect(() => {
    if (data) {
      const primaryCollateral = data.filter(
        collat => collat.is_primary === true
      );
      setPrimaryCollateral(primaryCollateral);
    }
  }, [data]);

  return (
    <>
      <CollateralTable
        assets={assets}
        collateralData={data}
        deleteCallback={onDeleteAssetModalToggle}
        editCallback={onEditCollateralModalToggle}
        optionalHeaderComponent={
          <Button
            basic
            circular
            color="green"
            floated="right"
            icon="plus"
            onClick={onAddCollateralModalToggle}
          />
        }
        showDeleteTest={isBafs}
      />

      <ConfirmationModal
        handleCancel={onCancelDeleteCollateralAsset}
        handleConfirm={onDeleteCollateralAsset}
        prompt="Delete"
        open={collateralDeleteModalIsOpen}
        yesButtonLabel="Delete"
        warningDetails="Are you sure you want to delete this item?"
      />
      <CollateralAddEdit
        asset={collateralModalAsset}
        isPrimaryCollateral={isPrimaryCollateral}
        institutionUuid={institutionUuid}
        assetInputChange={onCollateralEditorAssetChange}
        assetSelectChange={onAssetSelectChange}
        cancelCallback={onEditCollateralModalToggle}
        checkboxChange={onCollateralEditorCollateralChange}
        collateral={collateralModalData}
        collaterals={{}} // empty object removes filtering of existing collateral items
        collateralInputChange={onCollateralEditorCollateralChange}
        entityAssets={entityAssets}
        isAdd={collateralModalIsAdd}
        isOpen={collateralModalOpen}
        submitCallback={onCollateralEditorSave}
        openAssetsForm={openAssetsForm}
        additionalAssetData={additionalAssetData}
        updateValue={updateValue}
        resetAssetData={resetAssetData}
        showCollateralForm={showCollateralForm}
        updateAdditionalAssetData={
          assetDataFound ? updateAdditionalAssetData : addAdditionalAssetData
        }
      />
    </>
  );
}

Collateral.propTypes = {
  loanUuid: PropTypes.string.isRequired,
  institutionUuid: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  assets: PropTypes.shape().isRequired,
  entityAssets: PropTypes.arrayOf(PropTypes.object).isRequired,

  collateralModalIsAdd: PropTypes.bool.isRequired,
  collateralModalOpen: PropTypes.bool.isRequired,
  collateralDeleteModalIsOpen: PropTypes.bool.isRequired,
  collateralModalAsset: PropTypes.shape().isRequired,
  collateralModalData: PropTypes.shape().isRequired,

  onViewCollateralTable: PropTypes.func,
  onAddCollateralModalToggle: PropTypes.func,
  onDeleteAssetModalToggle: PropTypes.func,
  onEditCollateralModalToggle: PropTypes.func,
  onCancelDeleteCollateralAsset: PropTypes.func,
  onDeleteCollateralAsset: PropTypes.func,
  onCollateralEditorAssetChange: PropTypes.func,
  onAssetSelectChange: PropTypes.func,
  onCollateralEditorCollateralChange: PropTypes.func,
  onCollateralEditorSave: PropTypes.func,
  openAssetsForm: PropTypes.bool,
  setCloseAssetsForm: PropTypes.func,
  additionalAssetData: PropTypes.shape(),
  setAdditonalAssetValue: PropTypes.func,
  resetAdditionalAssets: PropTypes.func,
  updateAdditionalAssetData: PropTypes.func,
  assetDataFound: PropTypes.bool,
  addAdditionalAssetData: PropTypes.func
};

export default Collateral;
