import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import ObjectMetadata from "../ObjectMetadata";
import { FormatUrlV2 } from "../../../FormatUrl";
import { ObjectUpload } from "../../../FetchService";

export function updateObjectMetadata(
  data,
  { objMetadataBody, onSuccess, onError }
) {
  TypeCheck(data.data.uuid, "string");
  TypeCheck(objMetadataBody, "object");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");

  const objectUuid = data.data.uuid;
  const body = objMetadataBody;
  let callbackData;
  ObjectMetadata.update(onSuccess, onError, objectUuid, body, callbackData);
}

function FileUpload(event, objDetails, onSuccess, onError) {
  TypeCheck(event.dataTransfer.files, "object");
  TypeCheck(objDetails.approvalCategory, "enum", false, [
    "Auto Decision",
    "LOS"
  ]); // TODO: Ask BAFS
  TypeCheck(objDetails.entityUuid, "string"); // TODO: Does this really need to be required?
  TypeCheck(objDetails.institutionUuid, "string");
  TypeCheck(objDetails.docCategory, "string", false);
  TypeCheck(objDetails.parentRelType, "enum", true, [
    "Asset",
    "Entity",
    "Loan"
  ]);
  TypeCheck(objDetails.parentUuid, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");

  const { parentRelType, parentUuid, docCategory } = objDetails;
  const { bpOrganizationUuid } = objDetails;

  const { institutionUuid } = objDetails;
  const url = FormatUrlV2(ApiEndpoints.objectUploadsWithInstitution, {
    institutionUuid
  });

  const { entityUuid } = objDetails;
  const category = parentRelType === "Entity" ? "Credit" : parentRelType;

  Object.values(event.dataTransfer.files).forEach(file => {
    const objMetadataBody = {
      category: docCategory || category,
      docType: "Uncategorized",
      entityUuid,
      fileName: file.name,
      parentRelType,
      parentUuid,
      status: "Pending Approval",
      uploadOrigin: "BLAST",
      borrowerVisibility: false,
      bpOrganizationUuid
    };

    const onUploadSuccess = updateObjectMetadata;
    const fileObj = file;
    const callbackData = { objMetadataBody, onSuccess, onError };
    ObjectUpload(
      `${url}&which_metadata_table=document_metadata_v4`,
      onUploadSuccess,
      onError,
      fileObj,
      callbackData
    );
  });
}

export default FileUpload;
