import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  DollarCell,
  PropertyTable
} from "../../../../../../../../../../components/Tables";

function BalanceInfoTable({ loan }) {
  const balanceInfoColumns = useMemo(
    () => [
      {
        Header: "Balance at Last Renewal",
        accessor: "balance_at_last_renewal",
        Cell: DollarCell
      },
      {
        Header: "Average Current Balance MTD",
        accessor: "average_daily_balance_mtd",
        Cell: DollarCell
      },
      {
        Header: "Today's Payoff Balance",
        id: "uuid",
        accessor({ current_balance, interest_accrued, fees_accrued }) {
          // eslint-disable-line camelcase
          return [current_balance, interest_accrued, fees_accrued] // eslint-disable-line camelcase
            .reduce((sum, value) => sum + (value || 0), 0);
        },
        Cell: DollarCell
      }
    ],
    []
  );
  return <PropertyTable columnsConfig={balanceInfoColumns} data={loan} />;
}

BalanceInfoTable.propTypes = {
  loan: PropTypes.shape().isRequired
};

export default BalanceInfoTable;
