import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Modal, Select } from "semantic-ui-react";

import useRelationshipFormIsValid from "../../hooks/useRelationshipFormIsValid";
import { DateInput } from "../../../../../CustomFormElements";
import { ConcatName } from "../../../../../../services/Entities";
import {
  forceValue,
  otherCapacitiesOptions
} from "../../../../../../services/FormElements";

function OtherModal(props) {
  const { form, onClose, onSave, updateForm } = props;

  const requiredFields = ["title", "start_date"];

  const { isValid } = useRelationshipFormIsValid({ form, requiredFields });

  return (
    <Modal open key={form.relationship_uuid}>
      <Modal.Header>Edit Other Capacities</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group>
            <Form.Input
              label="TIN"
              readOnly
              value={forceValue(form.entity_data ? form.entity_data.tin : "")}
            />
            <Form.Input
              label="Name"
              readOnly
              value={forceValue(ConcatName(form.entity_data || {}))}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              control={Select}
              options={otherCapacitiesOptions}
              label="Relationship Type"
              required
              value={forceValue(form.title)}
              onChange={updateForm}
              name="title"
            />
            <DateInput
              asForm
              label="Start Date"
              name="start_date"
              onChange={updateForm}
              required
              value={forceValue(form.start_date)}
            />
            <DateInput
              asForm
              label="End Date"
              name="end_date"
              onChange={updateForm}
              value={forceValue(form.end_date)}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button id="cancelButton" color="grey" onClick={() => onClose({})}>
          Cancel
        </Button>
        <Button
          id="submitButton"
          color="green"
          disabled={!isValid}
          onClick={() => onSave()}
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

OtherModal.propTypes = {
  form: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired
};

export default OtherModal;
