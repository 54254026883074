import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  DateCell,
  PropertyTable,
  DefaultCell
} from "../../../../../../../../../../components/Tables";
import Constant from "../../../../../../../../../../services/Constants/strings";

function InteresetChangeTable({ loan, loanInterest, isMonthEnd }) {
  const rows = useMemo(
    () => [
      {
        Header: "Rate Change Frequency",
        accessor: "change_frequency",
        Cell: DefaultCell
      },
      {
        Header: "Rate Change Period",
        accessor: "change_period",
        Cell: DefaultCell
      },
      {
        Header: "Number of Rate Changes LTD",
        accessor: "number_of_rate_changes",
        Cell: DefaultCell
      },
      {
        Header: "Previous Rate Change Date",
        accessor: "previous_rate_change_date",
        Cell: DateCell
      },
      {
        Header: "Next Rate Change Date",
        accessor: "next_change_date",
        Cell: DateCell
      }
    ],
    []
  );

  const newLoanInterest = isMonthEnd
    ? {
        ...loanInterest,
        change_frequency: Constant.NotApplicable,
        change_period: Constant.NotApplicable,
        next_change_date: Constant.NotApplicable
      }
    : loanInterest;
  return (
    <PropertyTable
      columnsConfig={rows}
      data={{ ...loan, ...newLoanInterest }}
    />
  );
}

InteresetChangeTable.propTypes = {
  loan: PropTypes.shape({
    rate_index: PropTypes.string,
    rate_floor: PropTypes.number,
    rate_ceiling: PropTypes.number,
    max_rate_decrease: PropTypes.number,
    margin: PropTypes.string,
    number_of_rate_changes: PropTypes.number,
    previous_rate_change_date: PropTypes.instanceOf(Date)
  }).isRequired,
  loanInterest: PropTypes.shape({
    change_frequency: PropTypes.number,
    change_period: PropTypes.string,
    next_change_date: PropTypes.instanceOf(Date)
  }),
  isMonthEnd: PropTypes.bool
};

export default InteresetChangeTable;
