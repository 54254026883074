import React from "react";
import { func, shape } from "prop-types";
import { Table, Button, Icon } from "semantic-ui-react";
import { isEmpty } from "lodash";

function AccountsTable({ data, onEdit }) {
  return (
    <>
      <div style={{ textAlign: "right" }}>
        <Button
          basic
          circular
          data-testid="edit-asset-row-btn"
          icon={<Icon name="pencil" color="green" />}
          onClick={() => onEdit(true)}
        />
      </div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Account Number</Table.HeaderCell>
            <Table.HeaderCell>Account Type</Table.HeaderCell>
            <Table.HeaderCell>Institution Name</Table.HeaderCell>
            <Table.HeaderCell>Institution Address</Table.HeaderCell>
            <Table.HeaderCell>Institution City</Table.HeaderCell>
            <Table.HeaderCell>Institution State</Table.HeaderCell>
            <Table.HeaderCell>Institution Postal Code</Table.HeaderCell>
            <Table.HeaderCell>Insurance Policy</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row key={data.uuid}>
            <Table.Cell>{data.account_number}</Table.Cell>
            <Table.Cell>{data.account_type}</Table.Cell>
            <Table.Cell>{data.institution_name}</Table.Cell>
            <Table.Cell>{data.institution_address}</Table.Cell>
            <Table.Cell>{data.institution_city}</Table.Cell>
            <Table.Cell>{data.institution_state}</Table.Cell>
            <Table.Cell>{data.institution_postal_code}</Table.Cell>
            <Table.Cell>
              {data.life_insurance_policy_number.map(number => (
                <span>{number}</span>
              ))}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <h2 className="ui header"> Securities</h2>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Number of shares</Table.HeaderCell>
            <Table.HeaderCell>Issuer Name</Table.HeaderCell>
            <Table.HeaderCell>Certificate Number</Table.HeaderCell>
            <Table.HeaderCell>Securities Type</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {!isEmpty(data.securities) &&
          data.securities.map((security, index) => (
            <Table.Body key={index}>
              <Table.Row key={security.uuid}>
                <Table.Cell>{security.number_of_shares}</Table.Cell>
                <Table.Cell>{security.issuer_name}</Table.Cell>
                <Table.Cell>{security.certificate_number}</Table.Cell>
                <Table.Cell>{security.securities_type}</Table.Cell>
              </Table.Row>
            </Table.Body>
          ))}
      </Table>
    </>
  );
}

AccountsTable.propTypes = {
  data: shape().isRequired,
  onEdit: func.isRequired
};

export default AccountsTable;
