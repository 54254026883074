import { connect } from "react-redux";
import { actionCreators } from "../../reducer";
import AccountDocuments from "./AccountDocuments";

const mapStateToProps = state => ({
  data: state.LoanManagementReducer.data
});

const mapDispatchToProps = dispatch => ({
  onViewCreditAccountDocuments: ({ loanUuid, institutionUuid }) => {
    dispatch(
      actionCreators.loadAccountDocuments({ loanUuid, institutionUuid })
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountDocuments);
