import React from "react";
import { Route, useParams } from "react-router-dom";
import { useFlags } from "flagsmith/react";

import AppDetails from "./scenes/AppDetails";
import Documents from "./scenes/Documents";
import SettlementSheets from "./scenes/SettlementSheet";
import CreditMemo from "./scenes/CreditMemo";
import PhasesPPPTab from "./scenes/PPP";
import Messages from "../../components/Messages";
import PhasesHeader from "./components/PhasesHeader";

import PhasesHeaderRedesign from "./components/PhasesHeaderRedesign";
import PhasesPrimaryMenu from "./components/PhasesPrimaryMenu";

export const routeDetails = [
  {
    component: uuid => <AppDetails loanAppUuid={uuid} />,
    key: "App Details",
    path: "/los/:uuid/phases/app_details"
  },
  {
    component: uuid => <Documents loanAppUuid={uuid} />,
    key: "Documents",
    path: "/los/:uuid/phases/documents"
  },
  {
    component: uuid => <SettlementSheets loanAppUuid={uuid} />,
    key: "SettlementSheet",
    path: "/los/:uuid/phases/settlement_sheet"
  },
  {
    component: () => <CreditMemo />,
    key: "CreditMemo",
    path: "/los/:uuid/phases/credit_memo"
  },
  {
    component: uuid => <Messages loanAppUuid={uuid} />,
    key: "Messages/Activity",
    path: "/los/:uuid/phases/messages_activities"
  },
  {
    component: uuid => <PhasesPPPTab loanAppUuid={uuid} />,
    key: "PPP Loans",
    path: "/los/:uuid/phases/ppp"
  }
];

function LoanOriginationPhases() {
  const { uuid } = useParams();

  const flags = useFlags(["blast_new_los_phases_design_enabled"]);
  const useNewHeader = flags.blast_new_los_phases_design_enabled.enabled;

  return (
    <div>
      {useNewHeader ? (
        <PhasesHeaderRedesign loanAppUuid={uuid} />
      ) : (
        <PhasesHeader loanAppUuid={uuid} />
      )}
      <PhasesPrimaryMenu loanAppUuid={uuid} />
      {routeDetails.map(item => (
        <Route
          key={item.key}
          path={item.path}
          component={({ match, location }) =>
            item.component(match.params.uuid, location.pathname)
          }
        />
      ))}
    </div>
  );
}

export default LoanOriginationPhases;
