import { combineReducers } from "redux";

import AlertReducer from "../components/Alert/reducer";
import AssetManagementReducer from "../scenes/AssetManagement/scenes/AssetManagement/reducer";
import Auth from "../scenes/login/reducer";
import AutoDecisionReducer from "../scenes/AutoDecision/reducer";
import CollateralDocumentsReducer from "../components/CollateralDocuments/reducer";
import CreditManagementDetailsReducer from "../scenes/CreditManagement/scenes/MainLanding/components/TabView/components/Details/reducer";
import CreditAnalysisManagementReducer from "../components/CreditAnalysis/reducer";
import CreditManagementReducer from "../scenes/CreditManagement/scenes/MainLanding/reducer";
import CreditManagementRequestsReducer from "../scenes/CreditManagement/scenes/MainLanding/components/TabView/components/CreditRequest/reducer";
import CurrentRequestMainReducer from "../scenes/CurrentRequest/scenes/reducer";
import DocApprovalModalReducer from "../components/Modals/components/DocApprovalModal/reducer";
import DocMgmtReducer from "../scenes/DocMgmt/scenes/LorDocApproval/DocMgmtReducer";
import DocTrackingViewReducer from "../components/DocTrackingView/reducer";
import EntityManagementReducer from "../scenes/EntityManagement/reducer";
import EntityModalReducer from "../components/Modals/components/EntityModal/reducer";
import ErrorReporter from "../services/ErrorReporter/reducer";
import LoanManagementDetailsReducer from "../scenes/LoanManagement/scenes/LoanManagement/scenes/Details/reducer";
import LoanManagementReducer from "../scenes/LoanManagement/scenes/LoanManagement/reducer";
import LoanManagementRelationshipsReducer from "../scenes/LoanManagement/scenes/LoanManagement/scenes/Relationships/reducer";
import LoanManagementTransactionHistory from "../scenes/LoanManagement/scenes/LoanManagement/scenes/TransactionHistory/reducer";
import PaymentScheduleReducer from "../scenes/LoanManagement/scenes/LoanManagement/scenes/Details/scenes/Payments/reducer";
import LoanOriginationReducer from "../scenes/LOS/scenes/LoanOriginationStepper/reducer";
import LorCollateralReducer from "../scenes/LOS/components/Collateral/reducer";
import LorEntityDetailsReducer from "../scenes/LOS/components/EntityDetails/reducer";
import LorMessagesReducer from "../scenes/LOS/components/Messages/reducer";
import LorReviewReducer from "../scenes/LOS/scenes/LoanOriginationStepper/scenes/StepTabs/scenes/ReviewSubmit/reducer";
import LorStepTabReducer from "../scenes/LOS/scenes/LoanOriginationStepper/scenes/StepTabs/reducer";
import LorSettlementSheetReducer from "../scenes/LOS/components/SettlementSheet/reducer";
import LosPhasesReducer from "../scenes/LOS/scenes/LoanOriginationPhases/reducer";
import MessagingReducer from "../components/MessagingInterface/reducer";
import MonthEndFinalizationReducer from "../scenes/MonthEndFinalization/reducer";
import NotificationsReducer from "../components/Navigation/reducer";
import PaginationReducer from "../components/Pagination/reducer";
import PasswordResetReducer from "../scenes/PasswordReset/reducer";
import PaymentsReducer from "../scenes/LoanManagement/scenes/LoanManagement/scenes/Payments/reducer";
import PayoffCalculatorReducer from "../scenes/LoanManagement/scenes/LoanManagement/scenes/PayoffCalculator/reducer";
import ParticipationReducer from "../scenes/LoanManagement/scenes/LoanManagement/scenes/Participation/reducer";
import PPPReducer from "../scenes/LOS/scenes/LoanOriginationPhases/scenes/PPP/reducer";
import CreditMemoReducer from "../scenes/LOS/scenes/LoanOriginationPhases/scenes/CreditMemo/reducer";
import Settings from "../scenes/Settings/reducer";
import SystemAdminReducer from "../scenes/SystemAdmin/reducer";
import UuidLookupsReducer from "../components/UuidLookups/reducer";
import UserInfoReducer from "../services/UserInfo/reducer";

import { reducer as loanPaymentSchedulesReducer } from "./models/loan-payment-schedules";
import { reducer as loanInterestSchedulesReducer } from "./models/loan-interest-schedules";
import { reducer as feesReducer } from "./models/fees";
import { reducer as govtGuarFeesReducer } from "./models/govt-guar-fees";
import { reducer as acctHistoryLogsReducer } from "./models/account-history-logs";
import {
  name as loanApplications,
  reducer as loanApplicationsReducer
} from "./models/loan-applications";
import { name as spreads, reducer as spreadsReducer } from "./models/spreads";
import {
  name as financialSpreadsModels,
  reducer as modelsReducer
} from "./models/financial-spreads-models";

import { blastApi } from "../services/RTKQuery/blastApi";

const reducerMap = {
  AlertReducer,
  AssetManagementReducer,
  auth: Auth,
  AutoDecisionReducer,
  CollateralDocumentsReducer,
  CreditManagementDetailsReducer,
  CreditAnalysisManagementReducer,
  CreditManagementReducer,
  CreditManagementRequestsReducer,
  CreditMemoReducer,
  CurrentRequestMainReducer,
  DocApprovalModalReducer,
  DocMgmtReducer,
  DocTrackingViewReducer,
  EntityManagementReducer,
  EntityModalReducer,
  ErrorReporter,
  LoanManagementDetailsReducer,
  LoanManagementReducer,
  LoanManagementRelationshipsReducer,
  LoanManagementTransactionHistory,
  LoanOriginationReducer,
  LorCollateralReducer,
  LorEntityDetailsReducer,
  LorMessagesReducer,
  LorReviewReducer,
  LorStepTabReducer,
  LorSettlementSheetReducer,
  LosPhasesReducer,
  MessagingReducer,
  MonthEndFinalizationReducer,
  NotificationsReducer,
  PaginationReducer,
  PasswordResetReducer,
  PaymentsReducer,
  PaymentScheduleReducer,
  PayoffCalculatorReducer,
  ParticipationReducer,
  PPPReducer,
  settings: Settings,
  SystemAdminReducer,
  UserInfoReducer,
  UuidLookupsReducer,

  loanPaymentSchedules: loanPaymentSchedulesReducer,
  loanInterestSchedules: loanInterestSchedulesReducer,
  fees: feesReducer,
  govtGuarFees: govtGuarFeesReducer,
  acctHistoryLogs: acctHistoryLogsReducer,
  [loanApplications]: loanApplicationsReducer,
  [spreads]: spreadsReducer,
  [financialSpreadsModels]: modelsReducer,
  [blastApi.reducerPath]: blastApi.reducer
};

const rootReducer = combineReducers(reducerMap);

export default rootReducer;
