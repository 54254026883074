import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Divider, Menu } from "semantic-ui-react";

const menuItems = [
  {
    key: "2483 Data",
    href: uuid => `/los/${uuid}/phases/ppp/2483`
  },
  {
    key: "Underwriting",
    href: uuid => `/los/${uuid}/phases/ppp/underwriting`
  },
  {
    key: "2484 Data",
    href: uuid => `/los/${uuid}/phases/ppp/2484`
  }
];

function PPPMenuBar({ loanAppUuid }) {
  return (
    <div>
      <Menu pointing secondary color="green">
        {menuItems.map(item => (
          <Menu.Item
            as={NavLink}
            exact={false}
            to={item.href(loanAppUuid)}
            key={item.key}
            link
            name={item.key}
          />
        ))}
      </Menu>
      <Divider />
    </div>
  );
}

PPPMenuBar.propTypes = {
  loanAppUuid: PropTypes.string
};

export default PPPMenuBar;
