import { Row } from "../types";
import IndeterminateCheckbox from "../IndeterminateCheckbox";

export interface IndeterminateCheckboxCellProps {
  row: Row;
  disabled: boolean;
}

export const IndeterminateCheckboxCell = ({
  row,
  disabled
}: IndeterminateCheckboxCellProps) => {
  return (
    <IndeterminateCheckbox
      {...row.getToggleRowSelectedProps()}
      disabled={disabled}
    />
  );
};
