import { blastApi, Tag } from "./blastApi";
import {
  SingleResponse,
  ListResponse,
  LoanAppSettlementSheet,
  LoanAppSettlementSheetCreation,
  LoanAppSettlementSheetQueryOptions
} from "./types";

import {
  filteredQuery,
  extractData,
  createListTags,
  createSingleTag
} from "./utilities";

export const servicePath = "/loans/v1";

export interface LoanAppSettlementSheetUrlUuids {
  loan_uuid: string;
  item_uuid?: string;
}
export interface LoanAppSettlementSheetParams {
  uuids?: LoanAppSettlementSheetUrlUuids;
  queryOptions?: LoanAppSettlementSheetQueryOptions;
  body?: Partial<LoanAppSettlementSheet>;
}

const extendedApi = blastApi.injectEndpoints({
  endpoints: build => ({
    loanAppSettlementSheet: build.query<
      LoanAppSettlementSheet,
      LoanAppSettlementSheetParams
    >({
      query: ({ uuids }) =>
        `${servicePath}/loan_apps/${uuids.loan_uuid}/settlement_sheet_items/${uuids.item_uuid}`,
      providesTags: result =>
        createSingleTag(result, Tag.LoanAppSettlementSheet),
      transformResponse: (response: SingleResponse<LoanAppSettlementSheet>) =>
        extractData<LoanAppSettlementSheet>(response)
    }),
    loanAppSettlementSheets: build.query<
      ListResponse<LoanAppSettlementSheet>,
      LoanAppSettlementSheetParams
    >({
      query: ({ uuids, queryOptions }) =>
        filteredQuery(
          `${servicePath}/loan_apps/${uuids.loan_uuid}/settlement_sheet_items`,
          queryOptions
        ),
      providesTags: results =>
        createListTags(results, Tag.LoanAppSettlementSheet)
    }),
    createLoanAppSettlementSheet: build.mutation<
      SingleResponse<LoanAppSettlementSheet>,
      Partial<LoanAppSettlementSheetCreation>
    >({
      query: SettlementSheetData => ({
        url: `${servicePath}/loan_apps/${SettlementSheetData.loan_uuid}/settlement_sheet_items`,
        method: "POST",
        body: SettlementSheetData
      }),
      invalidatesTags: [Tag.LoanAppSettlementSheet]
    }),
    updateLoanAppSettlementSheet: build.mutation<
      SingleResponse<LoanAppSettlementSheet>,
      Partial<LoanAppSettlementSheet>
    >({
      query: SettlementSheetData => ({
        url: `${servicePath}/loan_apps/${SettlementSheetData.loan_uuid}/settlement_sheet_items/${SettlementSheetData.uuid}`,
        method: "PUT",
        body: SettlementSheetData
      }),
      invalidatesTags: [Tag.LoanAppSettlementSheet]
    }),
    deleteLoanAppSettlementSheet: build.mutation<
      SingleResponse<LoanAppSettlementSheet>,
      LoanAppSettlementSheetUrlUuids
    >({
      query: ({ loan_uuid, item_uuid }) => ({
        url: `${servicePath}/loan_apps/${loan_uuid}/settlement_sheet_items/${item_uuid}`,
        method: "DELETE"
      }),
      invalidatesTags: [Tag.LoanAppSettlementSheet]
    })
  }),
  overrideExisting: false
});

export const {
  useCreateLoanAppSettlementSheetMutation,
  useDeleteLoanAppSettlementSheetMutation,
  useLoanAppSettlementSheetsQuery,
  useLoanAppSettlementSheetQuery,
  useUpdateLoanAppSettlementSheetMutation
} = extendedApi;
