import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Dropdown, Form, Input, Select, Segment } from "semantic-ui-react";

import { dateStringToISO } from "../../../../../../services/DateTime";
import SemanticDatePicker from "../../../../../SemanticDatePicker";
import DocumentCategories from "../../../../../../services/Constants/DocumentCategories";
import { getAccountNumber } from "../../../../../../services/TextFormatting";

const categoryOptions = [
  { text: "Credit", value: "Credit" },
  { text: "Loan", value: "Loan" },
  { text: "Asset", value: "Asset" }
];

const sources = [
  "",
  "Accountant",
  "Appraiser",
  "Architect",
  "Attorney",
  "BAFS",
  "Borrower",
  "Financial Institution",
  "Guarantor",
  "Insurance Agent",
  "Investment Company",
  "Lead Institution",
  "Real Estate Agent",
  "SBA",
  "Secretary of State",
  "Surveyor",
  "Title Company",
  "USDA",
  "Vendor"
].map(source => ({ text: source, value: source }));

const docTypeListCollateral = [];
const docTypeListLoan = [];
const docTypeListCredit = [];

DocumentCategories.forEach(doc => {
  if (doc.doc_category === "Credit File") {
    docTypeListCredit.push({
      text: doc.doc_subcategory,
      value: doc.doc_subcategory
    });
  } else if (doc.doc_category === "Collateral File") {
    docTypeListCollateral.push({
      text: doc.doc_subcategory,
      value: doc.doc_subcategory
    });
  } else if (doc.doc_category === "Loan File") {
    docTypeListLoan.push({
      text: doc.doc_subcategory,
      value: doc.doc_subcategory
    });
  }
});

const getDocumentTypeOptions = value => {
  if (value === "Credit") {
    return docTypeListCredit;
  }
  if (value === "Loan") {
    return docTypeListLoan;
  }
  if (value === "Asset") {
    return docTypeListCollateral;
  }
  return [];
};

export class DocumentDetailsObj extends Component {
  constructor(props) {
    super(props);

    this.onInputChange = this.onInputChange.bind(this);
    this.onChangeReportDate = this.onChangeReportDate.bind(this);
    this.loadDocumentTypes = this.loadDocumentTypes.bind(this);

    const {
      form: { docCategory }
    } = this.props;
    this.state = { documentTypeOptions: getDocumentTypeOptions(docCategory) };
  }

  onInputChange(name, value) {
    const { dispatch } = this.props;
    dispatch({
      type: "DOC_APPROVAL_UPDATE_DOC_DETAILS",
      name,
      value: name === "parentRelType" && value === "Credit" ? "Entity" : value
    });
    if (name === "docCategory") {
      this.loadDocumentTypes(value);
    }
  }

  onChangeReportDate(rdpEvent, key) {
    const { dispatch } = this.props;
    dispatch({
      type: "DOC_APPROVAL_UPDATE_DATE_RANGE",
      key,
      date: dateStringToISO(rdpEvent)
    });
  }

  loadDocumentTypes(value) {
    const documentTypeOptions = getDocumentTypeOptions(value);
    this.setState({ documentTypeOptions });
  }

  render() {
    const { form } = this.props;
    const {
      description,
      status,
      docCategory,
      docType,
      docDate,
      parentRelType,
      parentUuid,
      periodStart,
      periodEnd,
      source,
      uploadDate,
      entityUuid
    } = form;

    const entityFilterOptions = this.props.entities.map(entity => {
      if (entity.entity_type === "Individual") {
        return {
          text: `${entity.tin} | ${entity.first_name} ${entity.last_name}`,
          value: entity.uuid
        };
      }
      return {
        text: `${entity.tin} | ${entity.company_name}`,
        value: entity.uuid
      };
    });

    const assetFilterOptions = this.props.assets.map(asset => ({
      text: `${asset.description} | ${asset.asset_class}-${asset.asset_type}`,
      value: asset.uuid
    }));

    const loanFilterOptions = this.props.loans.map(loan => ({
      text: `${loan.loan_class}-${loan.loan_type}${getAccountNumber(loan)}`,
      value: loan.uuid
    }));

    const { documentTypeOptions } = this.state;
    return (
      <div style={{ margin: "2% 0 2% 0" }}>
        <Segment attached color="grey" size="large">
          Document Details - Approval
        </Segment>
        <Form className="attached fluid segment">
          <Form.Field>
            <b>Entity</b>
            <br />
            <Dropdown
              type="text"
              icon="search"
              options={entityFilterOptions}
              fluid
              search
              onSearchChange={(e, data) => this.props.updateSearchValue(data)}
              selection
              onBlur={(e, { value }) =>
                this.props.updateAssetsLoansValue(value)
              }
              onChange={(e, { value }) => {
                this.onInputChange("entityUuid", value);
                this.onInputChange("parentUuid", value);
              }}
              value={entityUuid}
            />
          </Form.Field>
          <Form.Field>
            <b>Category</b>
            <br />
            <Dropdown
              search
              fluid
              placeholder="Search Category"
              onChange={(e, { value }) => {
                this.onInputChange("docCategory", value);
                this.onInputChange("parentRelType", value);
                this.onInputChange(
                  "parentUuid",
                  value === "Credit" ? entityUuid : ""
                );
              }}
              options={categoryOptions}
              value={docCategory}
            />
          </Form.Field>
          <Form.Field>
            <b>Document Type</b>
            <br />
            <Dropdown
              search
              fluid
              placeholder="Search Document"
              value={docType}
              onChange={(e, { value }) => this.onInputChange("docType", value)}
              options={documentTypeOptions}
            />
          </Form.Field>

          <Form.Field>
            <b>Asset</b>
            <br />
            <Dropdown
              disabled={docCategory !== "Asset"}
              type="text"
              icon="search"
              placeholder="Search Asset"
              options={assetFilterOptions}
              fluid
              search
              selection
              onChange={(e, { value }) => {
                this.onInputChange("parentUuid", value);
              }}
              value={parentRelType === "Asset" ? parentUuid : ""}
            />
          </Form.Field>

          <Form.Field>
            <b>Loan</b>
            <br />
            <Dropdown
              disabled={docCategory !== "Loan"}
              type="text"
              icon="search"
              placeholder="Search Loan"
              options={loanFilterOptions}
              fluid
              search
              selection
              onChange={(e, { value }) => {
                this.onInputChange("parentUuid", value);
              }}
              value={parentRelType === "Loan" ? parentUuid : ""}
            />
          </Form.Field>

          <Form.Group>
            <Form.Field width="8">
              <SemanticDatePicker
                date={periodStart}
                label="Period Start"
                onChange={e => this.onChangeReportDate(e, "periodStart")}
                value={periodStart}
              />
            </Form.Field>
            <Form.Field width="8">
              <SemanticDatePicker
                date={periodEnd}
                label="Period End"
                onChange={e => this.onChangeReportDate(e, "periodEnd")}
                value={periodEnd}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field width="8">
              <SemanticDatePicker
                date={docDate}
                label="Document Date"
                onChange={e => this.onChangeReportDate(e, "docDate")}
                value={docDate}
              />
            </Form.Field>
            <Form.Field width="8">
              <SemanticDatePicker
                date={uploadDate}
                label="Uploaded Date"
                onChange={e => this.onChangeReportDate(e, "uploadDate")}
                value={uploadDate}
              />
            </Form.Field>
          </Form.Group>

          <Form.Field>
            <b>Document Description</b>
            <br />
            <Input
              defaultValue={description}
              onChange={e => this.onInputChange("description", e.target.value)}
            />
          </Form.Field>

          <Form.Field>
            <b>Source:</b>
            <br />
            <Select
              search
              fluid
              placeholder="Source"
              value={source}
              onChange={(e, { value }) => this.onInputChange("source", value)}
              options={sources}
            />
          </Form.Field>

          <p>
            <b>Status</b>
            <br />
            {status}
          </p>
        </Form>
      </div>
    );
  }
}

DocumentDetailsObj.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
  entities: PropTypes.arrayOf(PropTypes.object).isRequired,
  form: PropTypes.shape({
    category: PropTypes.string,
    documentName: PropTypes.string,
    description: PropTypes.string,
    comments: PropTypes.arrayOf(PropTypes.object),
    status: PropTypes.string,
    docCategory: PropTypes.string,
    docType: PropTypes.string,
    docDate: PropTypes.string,
    parentRelType: PropTypes.string,
    parentUuid: PropTypes.string,
    periodStart: PropTypes.string,
    periodEnd: PropTypes.string,
    source: PropTypes.string,
    uploadDate: PropTypes.string,
    entityUuid: PropTypes.string
  }).isRequired,
  loans: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateSearchValue: PropTypes.func,
  updateAssetsLoansValue: PropTypes.func
};

const mapStateToProps = state => ({
  assets: state.DocApprovalModalReducer.assets,
  entities: state.DocApprovalModalReducer.entities,
  entityData: state.CreditManagementReducer.entityData,
  form: state.DocApprovalModalReducer.form,
  loans: state.DocApprovalModalReducer.loans
});

const DocumentDetails = connect(mapStateToProps)(DocumentDetailsObj);

export default DocumentDetails;
