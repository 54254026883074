import PropTypes from "prop-types";
import React from "react";
import { Header, Label } from "semantic-ui-react";

import Documents from "../../../../../../components/Documents";
import Constant from "../../../../../../../../services/Constants/strings";
import { BorderedContainer } from "../../../../../../../../components/CustomUIElements";

function StepperDocuments({ loanAppUuid, readOnly }) {
  return (
    <BorderedContainer fluid>
      <Header as="h3">Documents</Header>
      <Label>
        <p> Allowed file types for upload are, {Constant.allowedFileType}</p>
        <p>
          Maximum file size allowed to be uploaded is{" "}
          {Constant.maxFileUploadSize}
        </p>
      </Label>

      <Documents loanAppUuid={loanAppUuid} readOnly={readOnly} />
    </BorderedContainer>
  );
}

StepperDocuments.propTypes = {
  loanAppUuid: PropTypes.string.isRequired,
  readOnly: PropTypes.bool
};

export default StepperDocuments;
