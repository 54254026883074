import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./index.css";
import logger from "../../../../services/logger";

function StatusWidget({ name, statusURL, version }) {
  const [status, setStatus] = useState({});
  useEffect(() => {
    async function getStatusAPI() {
      try {
        let response = {};
        let serviceStatus = {};

        if (version) {
          response = { status: 200, statusText: "frontend", ok: true };
          serviceStatus = { ...version };
        } else {
          response = await fetch(statusURL);

          if (response.json) {
            serviceStatus = await response.json();
          } else {
            logger.error("Response is malformed:", response);
            return;
          }
        }

        setStatus({
          ...serviceStatus,
          statusCode: response.status || 555,
          statusText: response.statusText || "",
          statusColor: response.ok ? "green" : "#b00"
        });
      } catch (error) {
        logger.error("Error in getStatusAPI:", error);
      }
    }

    getStatusAPI();
  }, [statusURL, version]);

  return (
    <div className="bsp ui segment">
      <div
        className="bsp_dot"
        style={{ backgroundColor: status.statusColor }}
        title={`${status.statusCode || ""} - ${status.statusText || ""}`}
      />
      <p className="font-semibold">{name}</p>
      <p className="monospace">
        {status.commit}
        {status.buildDate && ` — ${status.buildDate}`}
      </p>
    </div>
  );
}

StatusWidget.propTypes = {
  name: PropTypes.string.isRequired,
  statusURL: PropTypes.string,
  version: PropTypes.objectOf(PropTypes.string)
};

export default StatusWidget;
