import { FormattedMessage, MessageDescriptor } from "react-intl";

/**
 * A component used to display content within a toast.
 * It has three different variants: "error" | "info" | "success"
 * So, the app can displays warn alerts to the user.
 */
export function ToastBoxDisplay({
  message,
  title,
  toastClassNames = "toast-box",
  variant = "info"
}: ToastBoxDisplayProps) {
  function getToastColor(variant: ToastVariant): string {
    return {
      error: "red",
      info: "blue",
      success: "green",
      warning: "yellow"
    }[variant];
  }

  return (
    <div className={toastClassNames}>
      <div role={"alert"} className={`${getToastColor(variant)} ui message`}>
        <div className="content">
          <div className="ui header">
            <FormattedMessage {...title} />
          </div>
          <div className="message">
            {message.id ? (
              <FormattedMessage {...message} />
            ) : (
              message.defaultMessage
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

/* Toast Module types */
export type ToastVariant = "error" | "info" | "success" | "warning";

export interface ToastBoxDisplayProps {
  /** The main text within a Toast displayed directly under the title */
  message: MessageDescriptor;
  /** User has dismissed the toast message. */
  onCloseToast?: (id: string) => void;
  /** The title displayed in a header at the top of the Toast */
  title: MessageDescriptor;
  /** The class depending on toast animation (in / out) */
  toastClassNames?: string;
  /** A semantic indication of the type of message. */
  variant?: ToastVariant;
}
