import { useEffect, useState } from "react";

/**
 * Updates every time a click event happens outside of the provided element
 * instances or their children.
 * @param elem If an element that is NOT one of these elements, or any of their
 * children, are clicked the hook result will toggle to true.
 * @returns True for one update cycle then back to false when an element outside
 * the provided element instances is clicked.
 */

type MaybeHTMLElement = HTMLElement | undefined;

export function useClickedOutside(...elem: MaybeHTMLElement[]): boolean {
  const [outside, setOutside] = useState(false);

  useEffect(() => {
    const handler = (evt: MouseEvent) => {
      let nextOutside = true;
      let parent: HTMLElement = (evt as any).target;
      while (parent) {
        if (matchWithParent(elem, parent)) {
          nextOutside = false;
          break;
        }

        parent = parent.parentElement as HTMLElement;
      }

      if (outside !== nextOutside) {
        setOutside(nextOutside);
      }
    };

    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, [elem, outside]);

  useEffect(() => {
    // This hook toggles to true and then back to false on the next cycle.
    if (outside) {
      setOutside(false);
    }
  }, [outside]);

  return outside;
}

function matchWithParent(elem: MaybeHTMLElement[], parent: HTMLElement) {
  return elem.some(x => x === parent);
}
