import PropTypes from "prop-types";
import React, { useCallback, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Button, Icon, Table } from "semantic-ui-react";
import { Documents, ObjectMetadata } from "../../../../../../services/ApiLib";

import {
  DocEditModal,
  ConfirmationModal
} from "../../../../../../components/Modals";
import BorrowerVisibilityToggle from "./BorrowerVisibilityToggle";
import { actionCreators } from "../../../../scenes/LoanOriginationStepper/reducer";
import logger from "../../../../../../services/logger";
import { formatDateTimeString } from "../../../../../../services/DateTime";

export const columns = [
  {
    key: "fileName",
    name: "File Name"
  },
  {
    key: "description",
    name: "Description"
  },
  {
    key: "docCategory",
    name: "Category"
  },
  {
    key: "docType",
    name: "Type"
  },
  {
    key: "periodStart",
    name: "Period Start Date"
  },
  {
    key: "periodEnd",
    name: "Period End Date"
  },
  {
    key: "uploadDate",
    name: "Upload Date"
  },
  {
    key: "uploadedBy",
    name: "Uploaded By"
  },
  {
    key: "editDeleteGlyphs",
    name: "Edit/Delete"
  }
];

function sortDescending(a, b) {
  return a.uploadDate < b.uploadDate ? 1 : -1;
}

function DocTable({
  analysts,
  docs,
  readOnly = false,
  onUpdateDoc,
  onDeleteDoc,
  relType
}) {
  const [docToEdit, setDocToEdit] = useState(null);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [docToDelete, setDocToDelete] = useState(null);
  const dispatch = useDispatch();

  const documentShape = useMemo(
    () => (readOnly ? columns.slice(0, -1) : columns),
    [readOnly]
  );

  const onError = response => {
    logger.error(response);
  };

  const onToggle = async doc => {
    try {
      const res = await ObjectMetadata.updateAsync(doc.uuid, {
        borrowerVisibility: !doc.borrowerVisibility
      });

      dispatch(actionCreators.updateDocument(relType, res.data));
    } catch (error) {
      onError(error);
    }
  };

  const analystName = useCallback(
    ({ uploadedBy }) => {
      if (!analysts || !uploadedBy || !analysts[uploadedBy]) {
        return "";
      }

      const { firstName, lastName } = analysts[uploadedBy];
      let name = firstName;
      if (lastName) {
        name = `${name || ""} ${lastName}`;
      }

      return name;
    },
    [analysts]
  );

  const onPreview = async object => {
    const { uuid } = object;
    try {
      await Documents.preview(
        { uuid },
        {
          fileName: object.fileName
        }
      );
    } catch (e) {
      onError(e);
    }
  };

  docs.sort(sortDescending).map(doc => {
    const newColumn = {
      key: "borrowerVisibility",
      name: "Borrower Visibility"
    };
    const borrowerVisibility = documentShape.find(a => a.key === newColumn.key);
    if (doc.bpOrganizationUuid) {
      if (borrowerVisibility) {
        return documentShape;
      }
      documentShape.splice(8, 0, newColumn);

      return documentShape;
    }

    if (borrowerVisibility) {
      documentShape.splice(8, 1);
    }

    return documentShape;
  });

  return (
    <div>
      <Table size="large" striped textAlign="right" celled>
        <Table.Header>
          <Table.Row>
            {documentShape.map(column => (
              <Table.HeaderCell key={column.key}>
                {column.name}
                {column.key === "uploadDate" ? (
                  <Icon name="arrow down" />
                ) : null}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {docs.sort(sortDescending).map(doc => (
            <Table.Row key={doc.uuid}>
              <Table.Cell onClick={() => onPreview(doc)}>
                <i className="linkify icon" />
                {doc[documentShape[0].key]}
              </Table.Cell>
              <Table.Cell>{doc[documentShape[1].key]}</Table.Cell>
              <Table.Cell>{doc[documentShape[2].key]}</Table.Cell>
              <Table.Cell>{doc[documentShape[3].key]}</Table.Cell>
              <Table.Cell>{doc[documentShape[4].key]}</Table.Cell>
              <Table.Cell>{doc[documentShape[5].key]}</Table.Cell>
              <Table.Cell>
                {formatDateTimeString(doc[documentShape[6].key])}
              </Table.Cell>
              <Table.Cell>{analystName(doc)}</Table.Cell>
              {doc.bpOrganizationUuid ? (
                <Table.Cell width={1}>
                  <BorrowerVisibilityToggle doc={doc} onToggle={onToggle} />
                </Table.Cell>
              ) : null}
              {readOnly ? null : (
                <Table.Cell width={1}>
                  <>
                    <Button
                      basic
                      circular
                      color="green"
                      icon="edit"
                      data-testid="doc-edit-btn"
                      onClick={() => setDocToEdit(doc)}
                    />
                    <Button
                      basic
                      circular
                      color="green"
                      data-testid="doc-delete-btn"
                      icon="close"
                      onClick={() => {
                        setDocToDelete(doc.uuid);
                        setConfirmModalIsOpen(true);
                      }}
                    />
                  </>
                </Table.Cell>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <DocEditModal
        data-testid="doc-edit-modal"
        docDetails={docToEdit}
        onClose={() => setDocToEdit(null)}
        onSave={docDetails => {
          onUpdateDoc(docDetails);
          setDocToEdit(null);
        }}
      />
      <ConfirmationModal
        open={confirmModalIsOpen}
        yesButtonLabel="Delete"
        showLogo={false}
        handleCancel={() => setConfirmModalIsOpen(false)}
        handleConfirm={() => {
          if (docToDelete) {
            onDeleteDoc(docToDelete);
          }
          setConfirmModalIsOpen(false);
        }}
        prompt="Delete Document"
        warningDetails="Are you sure you want to delete this Document?"
        size="tiny"
      />
    </div>
  );
}

DocTable.propTypes = {
  analysts: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string
    })
  }),
  docs: PropTypes.arrayOf(
    PropTypes.shape({
      docCategory: PropTypes.string,
      description: PropTypes.string,
      fileName: PropTypes.string,
      docType: PropTypes.string,
      uploadDate: PropTypes.string,
      uploadedBy: PropTypes.string,
      uuid: PropTypes.string
    })
  ).isRequired,
  readOnly: PropTypes.bool,
  onUpdateDoc: PropTypes.func,
  onDeleteDoc: PropTypes.func,
  relType: PropTypes.string
};

export default DocTable;
