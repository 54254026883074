import { SideSheet, TertiaryMenu } from "@bafsllc/ui-shared";
import { PropTypes } from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreditRatings from "../../../../services/ApiLib/services/CreditRatings";
import { CREDIT_ANALYSIS_ACTIONS } from "../../reducer";
import { entityDataProp, loanDataProp, onError } from "../../utils";
import CreditAnalysisSummaryTab from "./CreditAnalysisSummaryTab";
import CreditAnalysisDocumentsTab from "./CreditAnalysisDocumentsTab";
import CreditAnalysisSideSheetHeader from "./Header";
import { environment } from "../../../../environments/environment";

export default function CreditAnalysisSideSheet({
  type,
  loan,
  entity,
  isOpen,
  onClose
}) {
  const dispatch = useDispatch();
  const { institutionUuid, selectedCreditAnalysis, singleRiskScalesList } =
    useSelector(state => ({
      institutionUuid:
        state?.LoanManagementReducer?.institution?.uuid ??
        state?.LosPhasesReducer?.entityDetails?.institution_uuid,
      selectedCreditAnalysis:
        state.CreditAnalysisManagementReducer.selectedCreditAnalysis,
      singleRiskScalesList:
        state.CreditAnalysisManagementReducer.singleRiskScalesList
    }));

  const getSingleRiskScalesData = useCallback(async () => {
    try {
      const { data } = await CreditRatings.readAllSingleRiskScales();
      dispatch({
        type: CREDIT_ANALYSIS_ACTIONS.readSingleScales,
        data
      });
    } catch (error) {
      onError(error);
    }
  }, [dispatch]);

  useEffect(() => {
    getSingleRiskScalesData();
  }, [getSingleRiskScalesData]);

  /* the second condition after the "or" is for allowing the
   generateDocument function to trigger a patch (EDIT) instead of a post (CREATE)
   for analyses that have a status where the analysis is not editable. This is needed because
   the generateDocument function needs to run a patch to attach a template to render
   the document with.
  */
  const isEditMode = selectedCreditAnalysis?.mode === "edit";

  const [creditAnalysisFormObject, setCreditAnalysisFormObject] = useState({
    selectedSingleRiskScale: selectedCreditAnalysis?.single_risk_scale,
    selectedSingleRiskScaleItem: selectedCreditAnalysis?.single_risk_scale_item,
    selectedPurpose: selectedCreditAnalysis?.purpose,
    selectedApprovalAuthority: selectedCreditAnalysis?.approval_authority,
    selectedPeriodStart: selectedCreditAnalysis?.period_start?.split("T")[0],
    selectedPeriodEnd: selectedCreditAnalysis?.period_end?.split("T")[0],
    enteredJustification: selectedCreditAnalysis?.justification
  });

  // Summary tab is the default active tab
  const [activeTabId, setActiveTabId] = useState(1);

  const items = useMemo(
    () => [
      {
        active: true,
        id: 1,
        key: "summary_tab",
        message: {
          id: "SUMMARY"
        }
      },
      {
        active: false,
        id: 2,
        key: "documents_tab",
        message: {
          id: "DOCUMENTS"
        }
      }
    ],
    []
  );

  const nextItems = items.map(item => ({
    ...item,
    active: item.id === activeTabId
  }));

  const handleItemSelect = (evt, item) => {
    setActiveTabId(item.id);
  };

  const saveCreditAnalysis = async (event, templateUuid = null) => {
    try {
      const creditAnalysisToSend = {
        institution_uuid: institutionUuid,
        single_scale_uuid:
          creditAnalysisFormObject.selectedSingleRiskScale?.uuid,
        entity_uuid: entity.uuid,
        scale_item_uuid:
          creditAnalysisFormObject.selectedSingleRiskScaleItem?.uuid,
        loan_uuid: loan.uuid,
        purpose: creditAnalysisFormObject?.selectedPurpose,
        approval_authority: creditAnalysisFormObject?.selectedApprovalAuthority,
        justification: creditAnalysisFormObject?.enteredJustification,
        period_start: creditAnalysisFormObject?.selectedPeriodStart,
        period_end: creditAnalysisFormObject?.selectedPeriodEnd,
        analysis_type: "Single"
      };

      /* template_uuid is sent in the patch request to explicitly
      trigger a re-render of the document */
      if (templateUuid) {
        creditAnalysisToSend.template_uuid = templateUuid;
      }

      /* the check for templateUuid after the "or" is for allowing the
   generateDocument function to trigger a patch (EDIT) instead of a post (CREATE)
   for analyses that have a status where the analysis is not editable. This is needed because
   the generateDocument function needs to run a patch to attach a template to render
   the document with.
  */

      const response =
        isEditMode || templateUuid
          ? await CreditRatings.updateOne(
              selectedCreditAnalysis.uuid,
              creditAnalysisToSend
            )
          : await CreditRatings.asyncAdd(creditAnalysisToSend);

      const selectedScale = singleRiskScalesList.find(
        scale => scale.uuid === response?.data?.single_scale_uuid
      );
      if (response) {
        const formattedData = {
          ...response?.data,
          single_risk_scale: selectedScale,
          entity,
          loan,
          mode: "edit"
        };
        dispatch({
          data: formattedData,
          type: isEditMode
            ? CREDIT_ANALYSIS_ACTIONS.update
            : CREDIT_ANALYSIS_ACTIONS.create
        });
      }
    } catch (err) {
      onError("Failed to fetch/update credit analysis", err);
    }
  };

  const generateDocument = event => {
    const templateUuid = environment.creditAnalysisTemplateUuid ?? null;
    if (templateUuid) {
      saveCreditAnalysis(event, templateUuid);
    }
  };

  return (
    <SideSheet isOpen={isOpen} size="two-third" onClose={onClose}>
      <CreditAnalysisSideSheetHeader
        type={type}
        entity={entity}
        loan={loan}
        onClose={onClose}
        saveCreditAnalysis={saveCreditAnalysis}
        generateDocument={generateDocument}
      />
      <TertiaryMenu items={nextItems} onItemSelect={handleItemSelect} />

      <div style={{ marginTop: "24px" }}>
        {activeTabId === 1 && (
          <CreditAnalysisSummaryTab
            type={type}
            entity={entity}
            loan={loan}
            creditAnalysisFormObject={creditAnalysisFormObject}
            setCreditAnalysisFormObject={setCreditAnalysisFormObject}
          />
        )}
        {activeTabId === 2 && (
          <CreditAnalysisDocumentsTab generateDocument={generateDocument} />
        )}
      </div>
    </SideSheet>
  );
}

CreditAnalysisSideSheet.propTypes = {
  type: PropTypes.string,
  loan: loanDataProp,
  entity: entityDataProp,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};
