import React from "react";
import type { HandleClassNames } from "../../../../types/class-names";
import type { AllowedMenuClassNames } from "../../../../types/collections";

/**
 * This is an abstract component meant to be used as a base to build concrete
 * implementations of menus. It accepts menu items of any type and constructs a
 * basic menu using a `<ul>` element as its container. It also supports semantic
 * HTML for menus that are used for navigation.
 */
export function MenuBase<Item>({
  items,
  navigation,
  renderItem,
  updateMenuClassnames
}: MenuBaseProps<Item>) {
  const className = (
    updateMenuClassnames ? updateMenuClassnames(["ui", "menu"]) : ["ui", "menu"]
  ).join(" ");

  const children = (
    <ul className={className}>{items.map(item => renderItem(item, "item"))}</ul>
  );

  return React.createElement(
    navigation ? "nav" : "div",
    { className: "menu-container", "data-testid": "menu-container" },
    children
  );
}

export interface MenuBaseProps<Item> {
  /** The items will be displayed as items in the menu. */
  items: Item[];
  /** Set to true if the menu will navigate the application to different pages
   * or routes; defaults to false. */
  navigation?: boolean;
  /** Invoked to generate a component for each item in the menu. The menu is
   * contained in a `<ul>` element so usually items should be contained in
   * `<li>` elements. */
  renderItem: (item: Item, className?: string) => JSX.Element;
  /** If provided the handler will be invoked with the current menu class names
   * in an array and it may return a modified array of class names. */
  updateMenuClassnames?: HandleClassNames<AllowedMenuClassNames>;
}
