import React from "react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";

function SplitLayout({ children }) {
  const nodes = React.Children.toArray(children);
  return (
    <Grid padded>
      <Grid.Row columns="2">
        <Grid.Column>{nodes[0]}</Grid.Column>
        <Grid.Column>{nodes[1]}</Grid.Column>
      </Grid.Row>
      <Grid.Row columns="2">
        <Grid.Column>{nodes[2]}</Grid.Column>
        <Grid.Column>{nodes[3]}</Grid.Column>
      </Grid.Row>
      {nodes.length === 3 ? (
        <Grid.Row columns="1">
          <Grid.Column>{nodes[4]}</Grid.Column>
        </Grid.Row>
      ) : (
        <Grid.Row columns="2">
          <Grid.Column>{nodes[4]}</Grid.Column>
          <Grid.Column>{nodes[5]}</Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row columns="1">
        <Grid.Column>{nodes.slice(6)}</Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

SplitLayout.propTypes = {
  children: PropTypes.node
};

export default SplitLayout;
