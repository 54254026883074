import { IconButton } from "@bafsllc/ui-shared";
import { InterestScheduleSideSheet } from "./components/InterestScheduleSideSheet";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useLoansQuery,
  LoanQueryOptions
} from "../../../../../../services/RTKQuery/loans";
import {
  useInterestSchedulesQuery,
  InterestScheduleParameters
} from "../../../../../../services/RTKQuery/interestSchedules";
import InterestScheduleTable from "./components/InterestScheduleTable";

export const InterestSchedules = () => {
  const useParamsRsp = useParams<{ uuid: string; tab: string }>();
  const { uuid } = useParamsRsp;
  const loanAppUuid = uuid;
  const [isOpen, setIsOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [interestSchedule, setInterestSchedule] = useState({});

  const filters: LoanQueryOptions = {
    loan_app_uuid: loanAppUuid
  };
  const { data: loans } = useLoansQuery(filters);
  const interestFilters: InterestScheduleParameters = {
    loanUuid: loans?.data[0]?.uuid
  };
  const { data: resp, isFetching: isFetchingInterestSchedules } =
    useInterestSchedulesQuery(interestFilters);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpenEditModal = selectedInterestSchedule => {
    setInterestSchedule(selectedInterestSchedule);
    setIsOpen(true);
    setIsEditMode(true);
  };

  const handleOpenModal = () => {
    setInterestSchedule({});
    setIsOpen(true);
    setIsEditMode(false);
  };

  return (
    <div>
      <div className="flex justify-end mb-3">
        <IconButton
          data-testid="Add Interest Schedule"
          icon="plus"
          message={{ id: "Add Interest Schedule" }}
          onClick={handleOpenModal}
          variant="primary"
          size="medium"
        />
      </div>
      <InterestScheduleSideSheet
        isOpen={isOpen}
        onClose={handleClose}
        isEditMode={isEditMode}
        interestSchedule={interestSchedule}
      />
      <InterestScheduleTable
        isFetching={isFetchingInterestSchedules}
        interestSchedules={resp?.data || []}
        onClickEdit={handleOpenEditModal}
      />
    </div>
  );
};
