import _ from "lodash";

const ticklerStatus = {
  Satisfied: "Overridden",
  Waived: "Waived"
};

const initialState = {
  activeItem: "",
  asset: {},
  data: [],
  docTrackingItem: "",
  entityData: {},
  institution: {}
};

export default function AssetManagementReducer(state, action) {
  const safeState = state ?? initialState;
  switch (action.type) {
    case "ASSET_MANAGEMENT_POPULATE_ASSET": {
      return { ...safeState, asset: action.data };
    }
    case "ASSET_MANAGEMENT_DATA_LOAD": {
      const workingCopy = { ...safeState.entityData };
      Object.keys(action.data).forEach(x => {
        if (workingCopy[x] == null) {
          workingCopy[x] = action.data[x];
        }
      });
      return { ...safeState, entityData: workingCopy };
    }

    case "ASSET_MANAGEMENT_POPULATE_TABLE": {
      return {
        ...safeState,
        activeItem: action.activeItem,
        data: action.data
      };
    }

    case "ASSET_MANAGEMENT_UPDATE_DOC_TRACKING_ITEM":
      return { ...safeState, data: action.data, docTrackingItem: action.name };

    case "ASSET_MANAGEMENT_UPDATE_TICKLERS_DATA": {
      const newData = _.cloneDeep(safeState.data);
      let index;
      newData.forEach((obj, i) => {
        if (obj.uuid === action.rowUuid) {
          index = i;
        }
      });
      newData[index].state = action.value;
      newData[index].status = ticklerStatus[action.value];
      return { ...safeState, data: newData };
    }

    case "ASSET_MANAGEMENT_TICKLER_UPDATE_DOCUMENT_UPLOAD": {
      const newData = _.cloneDeep(safeState.data);
      let index;
      newData.forEach((obj, i) => {
        if (obj.uuid === action.rowUuid) {
          index = i;
        }
      });
      newData[index].objectUuid = action.objectUuid;
      newData[index].status = action.status;
      return {
        ...safeState,
        data: newData
      };
    }

    case "ASSET_MANAGEMENT_TICKLER_DELETE_DOCUMENT_UPLOAD": {
      const newData = _.cloneDeep(safeState.data);
      let index;
      newData.forEach((obj, i) => {
        if (obj.uuid === action.rowUuid) {
          index = i;
        }
      });
      delete newData[index].file;
      return {
        ...safeState,
        data: newData
      };
    }

    case "ASSET_MANAGEMENT_SCHEDULE_EDIT_MODAL_SAVE": {
      const newData = _.cloneDeep(safeState.data);
      let index;
      newData.forEach((obj, i) => {
        if (obj.uuid === action.rowUuid) {
          index = i;
        }
      });
      newData[index] = action.form;
      return {
        ...safeState,
        data: newData
      };
    }

    case "ASSET_MANAGEMENT_SCHEDULE_NEW_MODAL_SAVE": {
      const newData = _.cloneDeep(safeState.data);
      newData.push(action.form);
      return {
        ...safeState,
        data: newData
      };
    }

    case "ASSET_MANAGEMENT_SCHEDULE_UPDATE_TOGGLE": {
      const copyData = _.cloneDeep(safeState.data);
      for (let i = 0; i < copyData.length; i += 1) {
        copyData[i].state = action.state;
      }
      return {
        ...safeState,
        data: copyData
      };
    }

    case "ASSET_MANAGEMENT_UPDATE_ASSET_DOCUMENTS": {
      const newData = _.cloneDeep(safeState.data);
      let index;
      newData.forEach((obj, i) => {
        if (obj.uuid === action.data.uuid) {
          index = i;
        }
      });
      newData[index] = action.data;
      return {
        ...safeState,
        data: newData
      };
    }

    case "ASSET_MANAGEMENT_UPDATE_SINGLE_ASSET_DOCUMENT": {
      const documents = state.data;
      const { doc } = action;
      const newDocuments = documents.map(document => {
        if (document.uuid === doc.uuid) {
          let newDoc = { ...document };
          newDoc = doc;

          return newDoc;
        }

        return document;
      });
      return { ...state, data: newDocuments };
    }

    case "ASSET_MANAGEMENT_BP_ACCESS_CONTROLS":
      return {
        ...safeState,
        bpAccessControls: action.bpAccessControls?.data
      };

    case "ASSET_MANAGEMENT_POPULATE_INSTITUTION":
      return { ...safeState, institution: action.institution };

    default:
      return safeState;
  }
}
