import Select, { SingleValue } from "react-select";
import { OptionType } from "../..";
import { CommonMessages } from "@blast-client/constants";

const { DOLLAR_AMOUNT, PERCENTAGE } = CommonMessages;

export const FormatDropdown = ({ onChange, value }: FormatDropdownProps) => (
  <Select
    classNames={{
      control: () => "!border-neutral-300 !text-sm",
      option: () => "!text-xs"
    }}
    isSearchable={false}
    name="format"
    onChange={onChange}
    options={formatOptions}
    value={value}
  />
);

interface FormatDropdownProps {
  onChange: (option: SingleValue<OptionType>) => void;
  value: SingleValue<OptionType> | undefined;
}

export const formatOptions = [
  {
    label: DOLLAR_AMOUNT,
    value: DOLLAR_AMOUNT
  },
  {
    label: PERCENTAGE,
    value: PERCENTAGE
  }
];
