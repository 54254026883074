import TypeCheck from "typecheck-extended";

import ApiEndpoints from "../../../ApiEndpoints";
import FetchService, { asyncFetchService } from "../../../FetchService";
import {
  ConcatQueryParams,
  PaginationParams,
  RemoveNullKeys,
  coerceTypes
} from "../Utilities";
import logger from "../../../logger";
import { FormatUrlV2 } from "../../../FormatUrl";

export const schemaToForce = {
  amount: "float",
  amount_satisfied: "float",
  bill_number: "int",
  bill_sub: "int",
  bill_type: "string",
  created_datetime: "string",
  due_date: "string",
  fee_type: "string",
  institution_uuid: "string",
  loan_uuid: "string",
  payment_type: "string",
  posting_method: "string",
  status: "string",
  uuid: "string"
};

// This is deprecated. Use async LoanBills.read() instead.
function get(
  onSuccess,
  onError,
  pagination,
  loanUuid,
  billUuid,
  queryParams,
  callbackData
) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(loanUuid, "string", true);
  TypeCheck(billUuid, "string", false);
  TypeCheck(queryParams, "object", false);

  if (billUuid && queryParams) {
    onError("Error: Invalid Filter Combination");
  }

  let appendToUrl = "";
  if (queryParams) {
    appendToUrl = ConcatQueryParams(queryParams);
  }
  if (pagination) {
    appendToUrl = PaginationParams(appendToUrl, pagination);
  }
  let url =
    FormatUrlV2(ApiEndpoints.loanBills, { loan_uuid: loanUuid }) + appendToUrl;
  if (billUuid) {
    url =
      FormatUrlV2(ApiEndpoints.loanBills, {
        loan_uuid: loanUuid,
        bill_uuid: billUuid
      }) + appendToUrl;
  }
  FetchService("GET", url, onSuccess, onError, null, callbackData);
}

async function read(filters) {
  const funcName = "====  LoanBills.read(filters)  ====";
  logger.debug(funcName, [filters]);
  TypeCheck(filters.loanUuid, "string", true);
  TypeCheck(filters.billUuid, "string", false);

  const filterCount = Object.keys(filters).length;
  if ((filters.billUuid && filterCount > 2) || filterCount === 0) {
    logger.error("Error: Invalid Filter Combination");
    // TODO: [#5740] Put a checkable status here.
    const errorRsp = {};
    return errorRsp;
  }

  let url;
  if (filters.billUuid) {
    url = FormatUrlV2(ApiEndpoints.loanBill, {
      loan_uuid: filters.loanUuid,
      bill_uuid: filters.billUuid
    });
  } else {
    const appendToUrl = ConcatQueryParams(filters);
    url =
      FormatUrlV2(ApiEndpoints.loanBills, { loan_uuid: filters.loanUuid }) +
      appendToUrl;
  }

  const rsp = await asyncFetchService("GET", url);

  return rsp;
}

async function asyncUpdate(filters, body) {
  TypeCheck(filters.loanUuid, "string", true);
  TypeCheck(filters.billUuid, "string", true);

  const url = FormatUrlV2(ApiEndpoints.loanBill, {
    loan_uuid: filters.loanUuid,
    bill_uuid: filters.billUuid
  });
  const convertedBody = RemoveNullKeys(body);

  const rsp = await asyncFetchService("PUT", url, convertedBody);
  return rsp;
}

function update(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  const url = FormatUrlV2(ApiEndpoints.loanBill, {
    loan_uuid: body.loan_uuid,
    bill_uuid: body.uuid
  });
  const convertedBody = RemoveNullKeys(body);

  FetchService("PUT", url, onSuccess, onError, convertedBody, callbackData);
}

function add(onSuccess, onError, body, callbackData) {
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  const url = FormatUrlV2(ApiEndpoints.loanBills, {
    loan_uuid: body.loan_uuid
  });
  let convertedBody = RemoveNullKeys(body);
  convertedBody = coerceTypes(convertedBody, schemaToForce);
  FetchService("POST", url, onSuccess, onError, convertedBody, callbackData);
}

export default {
  get,
  update,
  add,
  read,
  asyncUpdate
};
