import React from "react";
import PropTypes from "prop-types";
import { Header, Modal, Form, Button } from "semantic-ui-react";
import { FormInput } from "../../../../../../../../../../../../services/FormElements";
import { Relationships } from "../../../../../../../../../../../../services/ApiLib";
import logger from "../../../../../../../../../../../../services/logger";

function onError(rsp) {
  logger.error("Error: ", rsp); // TODO: Handle errors properly
}

function EditModal({
  editModalEntity,
  valid,
  open,
  update,
  relType,
  subHead = "",
  inputs,
  setModalOpen,
  setModalEntity
}) {
  return (
    open &&
    editModalEntity && (
      <Modal open key="editRelationshipModal" size="large">
        <Modal.Header>{relType ? `Edit ${relType}` : "Edit"}</Modal.Header>

        <Modal.Content>
          <Header as="h4">{subHead}</Header>
          <br />
          <Form>
            <Form.Group style={{ alignItems: "center" }} widths="equal">
              {inputs.map(input => (
                <FormInput
                  key={input.name}
                  input={input}
                  set={setModalEntity}
                  // updates are handled by the "Save" button, so just a void function here
                  update={() => null}
                  fields={editModalEntity}
                />
              ))}
            </Form.Group>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button onClick={() => setModalOpen(false)} id="cancel">
            Cancel
          </Button>
          <Button
            color="green"
            onClick={async () => {
              const body = { ...editModalEntity };
              delete body.owner_entity_data;
              delete body.entity_data;
              delete body.officer_entity_data;
              delete body.tin;
              delete body.relationship_uuid;

              try {
                await Relationships.asyncUpdate(
                  editModalEntity.relationship_uuid,
                  body
                );
                update(editModalEntity);
                setModalOpen(false);
              } catch (e) {
                onError(e);
              }
            }}
            disabled={!valid}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  );
}

EditModal.propTypes = {
  editModalEntity: PropTypes.shape(),
  valid: PropTypes.bool,
  open: PropTypes.bool,
  update: PropTypes.func,
  relType: PropTypes.string,
  subHead: PropTypes.string,
  inputs: PropTypes.arrayOf(PropTypes.shape()),
  setModalOpen: PropTypes.func,
  setModalEntity: PropTypes.func
};

export default EditModal;
