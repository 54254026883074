import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import NumberFormat from "react-number-format";

function DollarField({ children, label, name, onChange, ...props }) {
  return (
    <Form.Field>
      <label htmlFor={name}>{children || label}</label>
      <NumberFormat
        id={name}
        name={name}
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale
        prefix="$"
        onValueChange={({ floatValue }) =>
          onChange({ name, value: floatValue })
        }
        {...props}
      />
    </Form.Field>
  );
}

DollarField.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func
};

export default DollarField;
