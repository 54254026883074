import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  bool,
  func,
  number,
  object,
  objectOf,
  oneOfType,
  string
} from "prop-types";
import { Button, Grid, Header, Icon, Segment } from "semantic-ui-react";
import CollateralStep from "../../../../../../components/Collateral";
import GuarantorsStep from "../Guarantors";
import CreditRequest from "../../../../../../components/CreditRequest";
import EntityDetails from "../../../../../../components/EntityDetails";
import DocumentsStep from "../Documents";
import { LoanApp } from "../../../../../../../../services/ApiLib";
import ErrorReporter from "../../../../../../../../services/ErrorReporter";
import { getToken } from "../../../../../../../../services/Auth";

export const activeStepsNum = 6;

export class ReviewSubmit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      borrower: false,
      creditRequest: false,
      collateral: false,
      guarantors: false,
      documents: false
    };

    this.onCollapse = this.onCollapse.bind(this);
    this.submit = this.submit.bind(this);
    this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
  }

  componentDidMount() {
    const { dispatch, loanAppUuid } = this.props;
    dispatch({ type: "LOR_POPULATE_LOANAPP_UUID", loanAppUuid });
    dispatch({ type: "LOR_UPDATE_ACTIVE_STEPS", activeStepsNum });
  }

  onCollapse(name) {
    const { state } = this;
    const current = state[name];
    this.setState({ [name]: !current });
  }

  onSubmitSuccess() {
    const { history, loanAppUuid } = this.props;
    const newUrl = `/los/${loanAppUuid}/phases/app_details`;
    history.push(newUrl);
  }

  submit() {
    const { loanAppUuid, userUuid } = this.props;
    LoanApp.update(
      getToken(),
      this.onSubmitSuccess,
      ErrorReporter,
      loanAppUuid,
      {
        status: "Submitted",
        phase: "Initial Review",
        submitted_by_user: userUuid
      }
    );
  }

  render() {
    const { loanAppUuid } = this.props;
    const { collateral, creditRequest, borrower, guarantors, documents } =
      this.state;

    const submitButton = (
      <Button
        color="green"
        icon
        floated="right"
        labelPosition="right"
        onClick={this.submit}
      >
        <Icon name="check" />
        Submit
      </Button>
    );

    const submitOrBack = (
      <div>
        {submitButton}
        <Button
          as={Link}
          icon
          floated="left"
          labelPosition="left"
          to={`/los/${loanAppUuid}/stepper/documents`}
        >
          <Icon name="left arrow" />
          Back
        </Button>
      </div>
    );

    return (
      <div>
        <Segment>
          <Grid>
            <Grid.Row columns="2">
              <Grid.Column float="left">
                <Header as="h1">Review/Submit</Header>
              </Grid.Column>
              <Grid.Column float="right" textAlign="right">
                {submitButton}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment>
          <Grid padded>
            <Grid.Row columns="3">
              <Grid.Column float="left" width="1">
                <Button
                  id="borrower"
                  circular
                  icon={borrower ? "chevron down" : "chevron up"}
                  onClick={() => this.onCollapse("borrower")}
                />
              </Grid.Column>
              <Grid.Column textAlign="left" width="9">
                <Header as="h2">Borrowers</Header>
              </Grid.Column>
              <Grid.Column float="right" textAlign="right">
                <Button
                  as={Link}
                  icon="pencil"
                  circular
                  color="green"
                  to={`/los/${loanAppUuid}/stepper/entity_details`}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {borrower ? <EntityDetails loanAppUuid={loanAppUuid} readOnly /> : ""}
        </Segment>
        <Segment>
          <Grid padded>
            <Grid.Row columns="3">
              <Grid.Column float="left" width="1">
                <Button
                  id="creditRequest"
                  circular
                  icon={creditRequest ? "chevron down" : "chevron up"}
                  onClick={() => this.onCollapse("creditRequest")}
                />
              </Grid.Column>
              <Grid.Column float="left" width="9">
                <Header as="h2">Credit Request</Header>
              </Grid.Column>
              <Grid.Column float="right" textAlign="right">
                <Button
                  as={Link}
                  icon="pencil"
                  circular
                  to={`/los/${loanAppUuid}/stepper/credit_requests`}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {creditRequest ? (
            <CreditRequest loanAppUuid={loanAppUuid} readOnly />
          ) : (
            ""
          )}
        </Segment>
        <Segment>
          <Grid padded>
            <Grid.Row columns="3">
              <Grid.Column float="left" width="1">
                <Button
                  id="guarantors"
                  circular
                  icon={guarantors ? "chevron down" : "chevron up"}
                  onClick={() => this.onCollapse("guarantors")}
                />
              </Grid.Column>
              <Grid.Column float="left" width="9">
                <Header as="h2">Guarantors</Header>
              </Grid.Column>
              <Grid.Column float="right" textAlign="right">
                <Button
                  as={Link}
                  icon="pencil"
                  circular
                  color="green"
                  to={`/los/${loanAppUuid}/stepper/guarantors`}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {guarantors ? (
            <GuarantorsStep loanAppUuid={loanAppUuid} readOnly />
          ) : (
            ""
          )}
        </Segment>
        <Segment>
          <Grid padded>
            <Grid.Row columns="3">
              <Grid.Column float="left" width="1">
                <Button
                  id="collateral"
                  circular
                  icon={collateral ? "chevron down" : "chevron up"}
                  onClick={() => this.onCollapse("collateral")}
                />
              </Grid.Column>
              <Grid.Column float="left" width="9">
                <Header as="h2">Collateral</Header>
              </Grid.Column>
              <Grid.Column float="right" textAlign="right">
                <Button
                  as={Link}
                  icon="pencil"
                  circular
                  color="green"
                  to={`/los/${loanAppUuid}/stepper/collateral`}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {collateral ? (
            <CollateralStep
              loanAppUuid={loanAppUuid}
              frictionModal={false}
              readOnly
            />
          ) : (
            ""
          )}
        </Segment>
        <Segment>
          <Grid padded>
            <Grid.Row columns="3">
              <Grid.Column float="left" width="1">
                <Button
                  id="documents"
                  circular
                  icon={documents ? "chevron down" : "chevron up"}
                  onClick={() => this.onCollapse("documents")}
                />
              </Grid.Column>
              <Grid.Column float="left" width="9">
                <Header as="h2">Documents</Header>
              </Grid.Column>
              <Grid.Column float="right" textAlign="right">
                <Button
                  as={Link}
                  icon="pencil"
                  circular
                  color="green"
                  to={`/los/${loanAppUuid}/stepper/documents`}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {documents ? (
            <DocumentsStep loanAppUuid={loanAppUuid} readOnly />
          ) : (
            ""
          )}
        </Segment>
        {submitOrBack}
      </div>
    );
  }
}

ReviewSubmit.propTypes = {
  dispatch: func.isRequired,
  loanAppUuid: string.isRequired,
  history: objectOf(oneOfType([string, number, bool, object, func])).isRequired,
  userUuid: string.isRequired
};

const mapStateToProps = state => ({
  jwt: state.auth.jwt,
  userUuid: state.auth.userUuid
});

export default withRouter(connect(mapStateToProps)(ReviewSubmit));
