import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { accountTypeOptions } from "../../../../../../../../services/FormElements";

function AchInstructionsForm({ institution, onInputChange }) {
  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Input
          name="aba_number"
          label="ABA/Routing Number"
          value={institution.aba_number || ""}
          onChange={onInputChange}
        />
        <Form.Input
          name="ach_account_number"
          label="Account Number"
          value={institution.ach_account_number || ""}
          onChange={onInputChange}
        />
        <Form.Select
          name="ach_account_type"
          label="Account Type "
          options={accountTypeOptions}
          search
          value={institution.ach_account_type || ""}
          onChange={onInputChange}
        />
      </Form.Group>
    </Form>
  );
}

AchInstructionsForm.propTypes = {
  institution: PropTypes.objectOf(PropTypes.string).isRequired,
  onInputChange: PropTypes.func.isRequired
};

export default AchInstructionsForm;
