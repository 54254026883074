import ApiEndpoints from "../ApiEndpoints";
import { dateFromString } from "../DateTime";
import Constants from "../Constants/strings";
import { FormatUrlV2 } from "../FormatUrl";
import FetchService from "../FetchService";
import { getToken } from "../Auth";
import { getBlastAPIBaseUrl } from "../../components/BlastEmbed/getBaseUrl";
import logger from "../logger";

const baseUri = getBlastAPIBaseUrl();

// TODO: Deprecated code, used by 3 actions in cms reducer, do not add code here.
// use MainLanding.loadTable instead, or better yet do requests inside of table components
// TODO: Move to new fetchService.
// TODO: From this call loadAnalystName
export function loadCMSTable(dispatch, jwt, name, entityUuid) {
  const params = "?page_size=500";
  let url;
  const uuids = {
    entityUuid
  };

  switch (name) {
    case "Financial Statements": {
      url = FormatUrlV2(ApiEndpoints.spreadsPeriodDatav2, uuids) + params;
      break;
    }
    case "Financial": {
      url = `${ApiEndpoints.baseJsonServer}/data`; // TODO: Replace json server w/production endpoint
      break;
    }
    case "Credit Request": {
      url = `${ApiEndpoints.baseJsonServer}/credit_request`; // TODO: Replace json server w/production endpoint
      break;
    }
    default:
      // no default load, do nothing
      break;
  }

  const fetchParams = {
    method: "GET",
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json"
    }
  };
  fetch(url, fetchParams)
    .then(response => {
      if (response.status === 200) {
        return response.json();
      }
      throw response.error;
    })
    .then(data => {
      dispatch({
        activeItem: name,
        type: "CMS_POPULATE_TABLE",
        data: data.rows
      });
    })
    .catch(() => {
      dispatch({
        type: "CMS_ERROR_LOAD_DOC_LIST",
        errorMessage: Constants.CMS_ERROR_LOAD_DOC_LIST
      });
    });
}

export function prepareFormData(formData, entityUuid) {
  const formDataCopy = { ...formData };
  formDataCopy.statementDate = dateFromString(formDataCopy.statementDate);
  formDataCopy.startDate = dateFromString(formDataCopy.startDate);
  formDataCopy.endDate = dateFromString(formDataCopy.endDate);
  delete formDataCopy.uuid;
  formDataCopy.entityUuid = entityUuid;
  if (!formDataCopy.opinion) delete formDataCopy.opinion;
  return formDataCopy;
}

export async function addDocument(dispatch, jwt, formData, entityUuid) {
  const uuids = {
    entityUuid
  };
  const url = FormatUrlV2(ApiEndpoints.spreadsPeriodDatav2, uuids);
  const formDataCopy = prepareFormData(formData, entityUuid);
  formDataCopy.type = "basePct";
  delete formDataCopy.analyst;

  const fetchParams = {
    method: "POST",
    body: JSON.stringify(formDataCopy),
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json"
    }
  };
  const response = await fetch(url, fetchParams);
  if (response.status !== 201) {
    const errorMsg = response.body;
    switch (response.status) {
      case 400:
      case 500:
        dispatch({
          type: "CMS_ERROR_ADD_DUP_FAIL",
          errorMessage: errorMsg || Constants.CMS_ERROR_ADD_DUP_FAIL500
        });
        throw new Error(errorMsg || response.error);
      case 401:
        dispatch({
          type: "CMS_ERROR_ADD_DUP_FAIL",
          errorMessage: errorMsg || Constants.CMS_ERROR_ADD_DUP_FAIL401
        });
        throw new Error(errorMsg || response.error);
      default:
        dispatch({
          type: "CMS_ERROR_ADD_DUP_FAIL",
          errorMessage: errorMsg || Constants.CMS_ERROR_ADD_DUP_FAIL_UNKNOWN
        });
        throw new Error(errorMsg || response.error);
    }
  }

  const res = await response.json();

  if (res.error) {
    throw new Error(res.error);
  }
  return res;
}

export async function editDocument(dispatch, jwt, formData, entityUuid) {
  const periodUuid = formData.uuid;
  const uuids = {
    periodUuid
  };
  const url = FormatUrlV2(ApiEndpoints.spreadsPeriodDatumv2, uuids);
  const formDataCopy = prepareFormData(formData, entityUuid);
  delete formDataCopy.user;

  const fetchParams = {
    method: "PATCH",
    body: JSON.stringify(formDataCopy),
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json"
    }
  };
  const response = await fetch(url, fetchParams);
  if (response.status !== 200) {
    const errorMsg = response.body;
    switch (response.status) {
      case 400:
      case 500:
        dispatch({
          type: "CMS_ERROR_ADD_DUP_FAIL",
          errorMessage: errorMsg || Constants.CMS_ERROR_ADD_DUP_FAIL500
        });
        throw new Error(errorMsg || response.error);
      case 401:
        dispatch({
          type: "CMS_ERROR_ADD_DUP_FAIL",
          errorMessage: errorMsg || Constants.CMS_ERROR_ADD_DUP_FAIL401
        });
        throw new Error(errorMsg || response.error);
      default:
        dispatch({
          type: "CMS_ERROR_ADD_DUP_FAIL",
          errorMessage: errorMsg || Constants.CMS_ERROR_ADD_DUP_FAIL_UNKNOWN
        });
        throw new Error(errorMsg || response.error);
    }
  }

  const res = await response.json();

  if (res.error) {
    throw res.error;
  }
  return res;
}

export function getDocument(dispatch, jwt, periodUuid, entityUuid) {
  const uuids = {
    entityUuid,
    periodUuid
  };
  const url = FormatUrlV2(ApiEndpoints.spreadsPeriodDatumv2, uuids);
  const fetchParams = {
    method: "GET",
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json"
    }
  };
  fetch(url, fetchParams)
    .then(response => {
      if (response.ok) {
        dispatch({
          type: "CMS_GET_SUCCESSFUL",
          dispatch,
          jwt
        });
      } else {
        throw response.error;
      }
    })
    .catch(() =>
      dispatch({
        type: "CMS_ERROR_GET",
        errorMessage: Constants.CMS_ERROR_GET
      })
    );
}

export function delDocument(dispatch, jwt, periodUuid, entityUuid) {
  const uuids = {
    entityUuid,
    periodUuid
  };
  const url = FormatUrlV2(ApiEndpoints.spreadsPeriodDatumv2, uuids);
  const fetchParams = {
    method: "DELETE",
    headers: {
      Authorization: getToken()
    }
  };
  fetch(url, fetchParams)
    .then(response => {
      if (response.status === 200) {
        dispatch({
          type: "CMS_DELETE_SUCCESSFUL",
          dispatch,
          jwt
        });
      } else {
        throw response.error;
      }
    })
    .catch(() =>
      dispatch({
        type: "CMS_ERROR_DELETE",
        errorMessage: Constants.CMS_ERROR_DELETE
      })
    );
}

export function editEntity(jwt, entityData) {
  return dispatch => {
    function editSuccess() {
      dispatch({
        type: "CMS_ENTITY_DATA_EDIT_MODAL_SUCCESS",
        open: false
      });
    }
    function editError(error) {
      logger.debug(error);
      switch (error.status) {
        case 400 || 500:
          dispatch({
            type: "CMS_ENTITY_DATA_EDIT_ERROR",
            errorMessage: Constants.CMS_ENTITY_DATA_EDIT_ERROR_500
          });
          break;
        case 401:
          dispatch({
            type: "CMS_ENTITY_DATA_EDIT_ERROR",
            errorMessage: Constants.CMS_ENTITY_DATA_EDIT_ERROR_401
          });
          break;
        default:
          dispatch({
            type: "CMS_ENTITY_DATA_EDIT_ERROR",
            errorMessage: Constants.CMS_ENTITY_DATA_EDIT_ERROR_UNKNOWN
          });
          break;
      }
    }
    const uuids = { uuid: entityData.uuid };
    const url = FormatUrlV2(ApiEndpoints.cmsEntity, uuids);
    const body = {
      tin: entityData.tin,
      entity_type: entityData.entity_type,
      fiscal_year_end: entityData.fiscal_year_end
    };
    FetchService("PUT", url, editSuccess, editError, body, null);
  };
}

export async function convertDocument(
  institutionUuid,
  documentUuid,
  outputFileName
) {
  const form = {
    institutionUuid,
    documentUuid,
    outputFileName
  };
  const url = FormatUrlV2(ApiEndpoints.convertDocument, {}, baseUri);
  const fetchParams = {
    method: "POST",
    body: JSON.stringify(form),
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json"
    }
  };

  const response = await fetch(url, fetchParams);

  if (!response.ok) {
    throw Error(response.statusText);
  } else {
    const res = await response.json();
    if (res.error) {
      throw res.error;
    }
    return res;
  }
}
