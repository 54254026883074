import { MaskedText, MaskedTextPartial, Text } from "../../types/text";
import React, { Key } from "react";
import { FormattedMessage } from "react-intl";

const DEFAULT_MASK_CHARACTER = "*";

/**
 * Mask text for display.
 * @param text The text to be masked.
 * @param options Options that configure the mask.
 */
export function maskText(
  text: string,
  options?: {} | MaskedText | MaskedTextPartial
): string {
  if (!options || !("maskSide" in options)) {
    return text;
  }

  let maskCharacter =
    options.maskCharacter &&
    options.maskCharacter.length &&
    options.maskCharacter[0]
      ? options.maskCharacter[0]
      : DEFAULT_MASK_CHARACTER;

  if (options.maskSide === "all") {
    return maskCharacter.repeat(text.length);
  }

  const maskedLength = text.length - options.unmaskedCount;
  if (maskedLength < 1) {
    return text;
  }

  let left = "";
  let right = "";
  if (options.maskSide === "start") {
    // "0123456" maskedLength=4
    // left = "****"
    // right = "456"
    left = maskCharacter?.repeat(maskedLength) ?? "";
    right = text.substring(maskedLength);
  } else {
    // "0123456" maskedLength=4
    // left = "012"
    // right = "****"
    left = text.substring(0, text.length - maskedLength);
    right = maskCharacter?.repeat(maskedLength) ?? "";
  }

  return left + right;
}

/**
 * Returns a React component with the provided text. If plain text or display
 * text is provided the text will be returned in a React Fragment unless a
 * tgaName is also provided in which case the text will be wrapped in that tag.
 * @param text Describes the text information to be displayed.
 * @param [options.key] An optional key that will be set on the component.
 * returned.
 * @param [options.tagName] The type of tag that will surround the text.
 */
export function getFormattedTextComponent(
  text: string | Text | (string | Text)[],
  options: {
    key?: Key;
    tagName?: Record<string, React.ElementType<any>>["tagName"];
  } = {}
): JSX.Element {
  if (typeof text === "string") {
    const props = options.key ? { key: options.key } : null;
    return options?.tagName ? (
      React.createElement(options.tagName, props, text)
    ) : (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>{text}</>
    );
  }

  if (Array.isArray(text)) {
    return getTextMap(text);
  }

  if (text.messageId) {
    return (
      <FormattedMessage
        {...options}
        id={text.messageId}
        values={text.messageValues || {}}
      />
    );
  }

  if (text.displayText) {
    const props = options.key ? { key: options.key } : null;
    return options?.tagName ? (
      React.createElement(options.tagName, props, text.displayText)
    ) : (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>{text.displayText}</>
    );
  }

  return <> </>;
}

function getTextMap(
  text: (string | Text)[],
  options: {
    key?: Key;
    tagName?: Record<string, React.ElementType<any>>["tagName"];
  } = {}
): JSX.Element {
  if (!text.length) {
    return <> </>;
  }

  return (
    <>
      {text.map((x, i) => {
        return (
          <p>
            {getFormattedTextComponent(x, {
              ...options,
              key: `${options.key ? options.key : ""}${
                options.key ? "-" : ""
              }${i}`
            })}
          </p>
        );
      })}
    </>
  );
}

export const replaceSpacesWithHyphens = (string: string) => {
  return string.replace(/\s/g, "-");
};
