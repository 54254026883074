const ALLOWED_MIME_TYPES = [
  "application/pdf", // .pdf
  "application/msword", // .doc
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
  "image/jpeg", // .jpg; .jpeg
  "image/png", // .png
  "application/vnd.ms-excel", // .xls
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
  "text/csv", // csv
  "text/plain" // text
];
export default ALLOWED_MIME_TYPES;

export const FILE_MIME_TYPES_MAP = {
  pdf: { mimeType: "application/pdf" }, // .pdf
  doc: { mimeType: "application/msword" }, // .doc
  docx: {
    mimeType:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  }, // .docx
  jpg: { mimeType: "image/jpeg" }, // .jpg; .jpeg
  jpeg: { mimeType: "image/jpeg" },
  png: { mimeType: "image/png" }, // .png
  xls: { mimeType: "application/vnd.ms-excel" }, // .xls
  xlsx: {
    mimeType:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  }, // .xlsx
  csv: { mimeType: "text/csv" }, // csv
  txt: { mimeType: "text/plain" } // text
};

/**
 * Below function takes care of following filename usecases.
 *""                           -->   ""
 "name"                        -->   ""
 "name.txt"                    -->   "txt"
 ".htpasswd"                   -->   ""
 "name.with.many.dots.myext"   -->   "myext
 */

export const getFileMimeType = fileName => {
  const extension =
    fileName &&
    fileName.slice((Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1);
  const data = {
    fileType: extension,
    mimeType: FILE_MIME_TYPES_MAP[extension]
      ? FILE_MIME_TYPES_MAP[extension].mimeType
      : ""
  };
  return data;
};
